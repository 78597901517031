<template>
	<v-dialog v-model="dialogModificarSubpasos" persistent max-width="75%">
		<v-card>
			<v-card-title>Modificar Subpasos</v-card-title>
			<v-card-text>
				<v-row>
					<v-col cols="6">
						<v-autocomplete
							v-model="filtros.idsOTD"
							:items="articulos"
							item-text="articulo"
							item-value="idTrabajoOrdeDetalle"
							label="Artículo"
							chips
							multiple
							deletable-chips
							clearable
							hide-details
							@change="getSubpasos()"
						></v-autocomplete>
					</v-col>
					<v-col cols="6" class="pt-5">
						<v-autocomplete
							v-model="filtros.idArea"
							:items="areas"
							item-text="nombre"
							item-value="idArea"
							label="Areas"
							clearable
							hide-details
							@change="getSubpasos()"
						></v-autocomplete>
					</v-col>
				</v-row>
				<v-row>
					<v-col cols="12">
						<v-data-table
							:items="subpasos"
							:headers="subpasosHeaders"
							:loading="loading || switching"
							:key="`table-${tablekey}`"
						>
							<template v-slot:item.activo="{ item }">
								<v-chip
									:color="!!item.activo ? 'green' : 'red'"
									outlined
									class="font-weight-bold"
								>
									{{ item.activo ? "Activo" : "Desactivado" }}
								</v-chip>
							</template>
							<template v-slot:item.subpaso="{ item }">
								<p class="mb-0 py-2">{{ getNombreSubpaso(item) }}</p>
							</template>
							<template v-slot:item.nota="{ item }">
								<div
									v-if="!!item.modoEdit == false"
									class="d-flex justify-center py-2"
								>
									<p class="mb-0 mr-3">{{ item.nota }}</p>
									<v-icon
										small
										@click="
											item.modoEdit = true;
											item.nuevaNota = item.nota;
											tablekey++;
										"
										:disabled="switching || loading"
										>mdi-pencil</v-icon
									>
								</div>
								<div
									v-else-if="!!item.modoEdit == true"
									class="py-2"
								>
									<v-textarea
										v-model="item.nuevaNota"
										outlined
										auto-grow
										rows="1"
										hide-details
									></v-textarea>
									<div class="d-flex justify-center">
										<v-btn
											small
											color="success"
											text
											@click="
												item.modoEdit = false;
												item.nuevaNota = '';
												tablekey++;
											"
											>Cancelar</v-btn
										>
										<v-btn
											small
											color="error"
											text
											@click="guardarNota(item)"
											>Guardar</v-btn
										>
									</div>
								</div>
							</template>
							<template v-slot:item.actions="{ item }">
								<v-tooltip
									:nudge-left="50"
									bottom
									color="black"
									class="white--text"
									v-if="!!item.activo"
								>
									<template v-slot:activator="{ on }">
										<v-icon
											v-on="on"
											small
											@click="switchSubpaso(item, false)"
											:disabled="switching || loading"
										>
											mdi-close-thick
										</v-icon>
									</template>
									<span class="white--text">Desactivar</span>
								</v-tooltip>
								<v-tooltip
									:nudge-left="50"
									bottom
									color="black"
									class="white--text"
									v-else
								>
									<template v-slot:activator="{ on }">
										<v-icon
											v-on="on"
											small
											@click="switchSubpaso(item, true)"
											:disabled="switching || loading"
										>
											mdi-check-bold
										</v-icon>
									</template>
									<span class="white--text">Activar</span>
								</v-tooltip>
							</template>
						</v-data-table>
					</v-col>
				</v-row>
			</v-card-text>
			<v-card-actions>
				<v-spacer></v-spacer>
				<v-btn
					color="success"
					text
					@click="cerrarDialogModificarSubpasos()"
					>Cerrar</v-btn
				>
			</v-card-actions>
		</v-card>
	</v-dialog>
</template>

<script>
import axios from "axios";
export default {
	props: {
		idTrabajoOrden: {
			type: Number,
			default: 0,
		},
	},
	data() {
		return {
			switching: false,
			tablekey: 0,
			subpasosHeaders: [
				{
					text: "Estado",
					align: "center",
					sortable: false,
					value: "activo",
					width: "10%",
				},
				{
					text: "Pieza a Producir",
					align: "center",
					sortable: false,
					value: "articuloProducir",
					width: "16%",
				},
				{
					text: "Artículo de Operación",
					align: "center",
					sortable: false,
					value: "articuloOperacion",
					width: "16%",
				},
				{
					text: "Área",
					align: "center",
					sortable: false,
					value: "area",
					width: "16%",
				},
				{
					text: "Subpaso",
					align: "center",
					sortable: false,
					value: "subpaso",
					width: "16%",
				},
				{
					text: "Nota",
					align: "center",
					sortable: false,
					value: "nota",
					width: "20%",
				},
				{
					text: "Acción",
					align: "center",
					sortable: false,
					value: "actions",
					width: "5%",
				},
			],
			articulos: [],
			areas: [],
			filtros: {
				idArea: null,
				idsOTD: null,
			},
			defaultFiltros: {
				idArea: null,
				idsOTD: null,
			},
			dialogModificarSubpasos: false,
			subpasos: [],
			loading: false,
		};
	},
	// mounted() {
	// 	this.initialize();
	// },
	methods: {
		guardarNota(item) {
			axios
			    .put(`/TrabajoOrdenes/OperacionNota/${item.idOperacion}?nota=${item.nuevaNota}`)
			    .then( () => {
			        this.getSubpasos();
			    })
			    .catch( error => {
                    item.modoEdit = false;
                    item.nuevaNota = '';
                    this.tablekey++;
			        console.log(error);
			    })
		},
		getNombreSubpaso: (value) =>
			`[${value.codigoSubpaso}] - ${value.descripcionSubpaso}`,
		showDialog() {
			this.initialize();
			this.dialogModificarSubpasos = true;
		},
		nombreArticulo: (value) =>
			value.codigo != null
				? `[${value.codigo}] - ${value.nombre}`
				: `${value.nombre}`,
		initialize() {
			this.getAreas();
			this.getArticulos();
			this.getSubpasos();
		},
		getArticulos() {
			axios
				.get(`/TrabajoOrdenes/OrdenTrabajoDetallesOT/${this.idTrabajoOrden}`)
				.then((response) => {
					this.articulos = response.data;
				})
				.catch((error) => {
					console.log(error);
				});
		},
		getAreas() {
			axios
				.get("/Areas")
				.then((response) => {
					this.areas = response.data;
				})
				.catch((error) => {
					console.log(error);
				});
		},
		getSubpasos() {
			this.loading = true;
			let url = `/TrabajoOrdenes/GetOperacionesOpcionalesOT/${this.idTrabajoOrden}`;
			if(this.filtros.idsOTD?.length>0){
				this.filtros.idsOTD.forEach( (idotd,idx) => {
					url += `${idx==0?'?':'&'}idsOTD=${idotd}`;
				});
			}
			axios
				.get(url,
					{
						params: {
							idArea: this.filtros.idArea
						},
					}
				)
				.then((response) => {
					this.subpasos = response.data;
					this.loading = false;
				})
				.catch((error) => {
					console.log(error);
					this.loading = false;
				});
		},
		cerrarDialogModificarSubpasos() {
			this.dialogModificarSubpasos = false;
			this.filtros = Object.assign({}, this.defaultFiltros);
			this.subpasos = [];
			this.$emit("cerrar");
		},
		switchSubpaso(item, value) {
			this.switching = true;
			axios
				.put(`/TrabajoOrdenes/OperacionOpcionalEstado/${item.idOperacion}?activa=${value}`)
				.then(() => {
					this.switching = false;
					this.getSubpasos();
				})
				.catch((error) => {
					this.switching = false;
					console.log(error);
				});
		},
	},
};
</script>