<template>
  <div>
    {{chronometer}}
  </div>
</template>


<script>
import moment from "moment";

export default {
  data: () => ({
    seconds: 0,
    interval: null,
  }),
  props:{
    time:{
      type: Number,
      required: true,
      default: 0,
    }
  },
  computed:{
    chronometer() {
      return this.$utils.strings.toTime(this.seconds);
    }
  },
  created(){
    try {
      var datetime = moment(this.time);
      this.seconds = Math.trunc(
        (moment().valueOf() - datetime.valueOf()) / 1000
      );
      if (isNaN(this.seconds)) this.seconds = 0;
      this.start();

    } catch (error) {
      this.start();
    }
  },
  methods:{
    start(){
      if(this.interval != null) return;

      this.interval = setInterval(() => {
        this.seconds += 1;
      },1000);
    },
    stop(){
      if(this.interval == null || typeof this.interval == 'undefined') return;
      clearInterval(this.interval);
      this.interval = null;
    }
  }
}
</script>
