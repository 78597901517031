<template>
    <v-text-field
        v-model="tokenInput"
        :label="label"
        :clearable="!lockedState"
        :readonly="lockedState"
        :append-icon="lockedState?appendIcon1:appendIcon2"
        @click:append="lockedState=!lockedState"
        @input="emitChanges"
        :error-messages="errorMessages"
        @focus="focusFunc()"
    ></v-text-field>
</template>

<script>
export default {
    props: {
        label: {
            type: String,
            default: ""
        },
        value: {
            type: String,
            default: ""
        },
        appendIcon1:{
            type: String,
            default: ""
        },
        appendIcon2:{
            type: String,
            default: ""
        },
        locked: {
            type: Boolean,
            default: true,
        },
        focus: {
            type: Function,
            default: () =>{}
        },
        errorMessages:{
            type: String,
            default: ''
        }
    },
    data(){
        return {
            tokenInput: this.value,
            lockedState: this.locked
        }
    },
    methods: {
        emitChanges(valor){
            this.$emit("input",valor);
        },
        focusFunc(){
            this.$emit("focus");
        }
    }
};
</script>