<template>
	<v-col cols="12" v-if="permisoVista('catsubpasos', 'r')">
		<v-dialog v-model="dialog" persistent max-width="30%">
			<v-card>
				<v-card-title>
					{{
						editedItem.idCatSubpaso == -1 ? "Crear Nuevo" : "Editar"
					}}
					Subpaso
				</v-card-title>
				<v-card-text>
					<v-row>
						<v-col cols="12">
							<v-text-field
								v-model="editedItem.codigo"
								label="Código"
								:error-messages="errorCodigo"
								@focus="errorCodigo = ''"
							></v-text-field>
						</v-col>
						<v-col cols="12">
							<v-text-field
								v-model="editedItem.descripcion"
								label="Nombre"
								:error-messages="errorNombre"
								@focus="errorNombre = ''"
							></v-text-field>
						</v-col>
					</v-row>
				</v-card-text>
				<v-card-actions>
					<v-spacer></v-spacer>
					<v-btn
						color="success"
						text
						:disabled="saving"
						@click="cerrarDialog()"
						>Cancelar</v-btn
					>
					<v-btn
						color="error"
						text
						:disabled="saving"
						:loading="saving"
						@click="save()"
						>Guardar</v-btn
					>
				</v-card-actions>
			</v-card>
		</v-dialog>
		<v-row>
			<v-col cols="12" class="pa-0 d-flex justify-space-between">
				<v-col cols="4">
					<v-btn
						color="primary"
						@click="showDialogCrear()"
						v-if="permisoVista('catsubpasos', 'c')"
					>
						Crear Nuevo Subpaso
					</v-btn>
				</v-col>
				<v-col cols="6">
					<v-text-field
						v-model="search"
						append-icon="mdi-magnify"
						label="Buscar"
						hide-details
						clearable
					></v-text-field>
				</v-col>
			</v-col>
		</v-row>
		<v-row>
			<v-col cols="12">
				<v-data-table
					:items="subpasos"
					:headers="subpasosHeaders"
					:loading="loading"
					:search="search"
				>
					<template v-slot:item.actions="{ item }">
						<v-tooltip
							bottom
							v-if="permisoVista('catsubpasos', 'u')"
							color="black"
							class="white--text"
						>
							<template v-slot:activator="{ on }">
								<v-icon
									v-on="on"
									small
									@click="showDialogEdit(item)"
								>
									edit
								</v-icon>
							</template>
							<span class="white--text">Editar</span>
						</v-tooltip>
					</template>
				</v-data-table>
			</v-col>
		</v-row>
	</v-col>
</template>

<script>
import axios from "axios";
export default {
	data() {
		return {
			search: null,
			errorCodigo: "",
			errorNombre: "",
			loading: false,
			subpasos: [],
			subpasosHeaders: [
				{
					text: "Código",
					align: "center",
					value: "codigo",
				},
				{
					text: "Nombre",
					align: "center",
					value: "descripcion",
				},
				{
					text: "Accciones",
					align: "center",
					value: "actions",
				},
			],
			editedItem: {
				idCatSubpaso: -1,
				codigo: "",
				descripcion: "",
			},
			defaultEditedItem: {
				idCatSubpaso: -1,
				codigo: "",
				descripcion: "",
			},
			dialog: false,
			saving: false,
		};
	},
	mounted() {
		this.initialize();
	},
	methods: {
		validate() {
			this.errorCodigo = "";
			this.errorNombre = "";

			if (
				this.editedItem.codigo == "" ||
				this.editedItem.codigo == null
			) {
				this.errorCodigo = "Debe introducir el Código";
			}
			if (
				this.editedItem.descripcion == "" ||
				this.editedItem.descripcion == null
			) {
				this.errorNombre = "Debe introducir el Nombre";
			}

			return this.errorCodigo == "" && this.errorNombre == "";
		},
		save() {
			if (!this.validate()) return;
			this.saving = true;

			axios({
				method: this.editedItem.idCatSubpaso == -1 ? "POST" : "PUT",
				url:
					this.editedItem.idCatSubpaso == -1
						? "/CatSubpaso/CrearCatSubpaso"
						: `/CatSubpaso/ActualizarCatSubpaso/${this.editedItem.idCatSubpaso}`,
				data: {
					codigo: this.editedItem.codigo,
					nombre: this.editedItem.descripcion,
				},
			})
				.then(() => {
					this.saving = false;
					this.cerrarDialog();
					this.getSubpasos();
				})
				.catch((error) => {
					if (error.response) {
						if (error.response.status == 409) {
                            this.errorCodigo = "El Código o Nombre ya existe";
                            this.errorNombre = "El Código o Nombre ya existe";	
						}
					}
					this.saving = false;
					console.log(error);
				});
		},
		showDialogCrear() {
			this.editedItem = Object.assign(
				{},
				this.editedItem,
				this.defaultEditedItem
			);
			this.dialog = true;
		},
		showDialogEdit(item) {
			this.editedItem = Object.assign({}, this.editedItem, item);
			this.dialog = true;
		},
		cerrarDialog() {
			this.errorCodigo = "";
			this.errorNombre = "";
			this.dialog = false;
			this.editedItem = Object.assign(
				{},
				this.editedItem,
				this.defaultEditedItem
			);
		},
		initialize() {
			this.getSubpasos();
		},
		getSubpasos() {
			this.loading = true;
			axios
				.get("/CatSubpaso")
				.then((response) => {
					this.subpasos = response.data;
				})
				.catch((error) => {
					console.log(error);
				})
				.finally(() => {
					this.loading = false;
				});
		},
	},
};
</script>