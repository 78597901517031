<template>
  <!-- <v-container> -->
    <v-row justify-sm="center">
      <v-card 
        class="mt-4"
        :width="anchoMaximo"
        fill
      >
        <v-tabs show-arrows v-model="tab" :background-color="colorTabs" centered dark icons-and-text>
          <v-tabs-slider></v-tabs-slider>

          <v-tab v-tooltip.bottom="modoAyuda && $help.perfil.procesosCurso" href="#tab-1" v-if="mostrarVista('lotes')" @click="updateLotes()">
            Procesos<br>en Curso
            <v-icon>mdi-progress-wrench</v-icon>
          </v-tab>

          <v-tab v-tooltip.bottom="modoAyuda && $help.perfil.configuracion" href="#tab-2" @click="configUsuario">
            Configuración<br>Usuario
            <v-icon>mdi-account-cog</v-icon>
          </v-tab>

        </v-tabs>

        <v-tabs-items v-model="tab">
          <v-tab-item :key="1" :value="'tab-1'" v-if="mostrarVista('lotes')">
            <v-card flat>
              <v-card-text>
                <PerfilLotes ref="lotes"></PerfilLotes>
              </v-card-text>
            </v-card>
          </v-tab-item>

          <v-tab-item :key="2" :value="'tab-2'">
            <v-card flat>
              <v-card-text>
                <ListaConfigUser ref="usuarioConfig" />
              </v-card-text>
            </v-card>
          </v-tab-item>
        </v-tabs-items>

      </v-card>
    </v-row>
  <!-- </v-container> -->
</template>

<script>
import Lotes from './Perfil/Lotes.vue';
import ListaConfigUser from './Perfil/ListaConfigUser.vue';
import { mapState } from 'vuex';

export default {
  data() {
    return {
      tab: null,
      text:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat."
    };
  },
  methods:{
    updateLotes(){
      if(typeof this.$refs.lotes === 'undefined') return;
      this.$refs.lotes.initialize();
    },
    configUsuario(){
      if( typeof this.$refs.usuarioConfig === 'undefined' ) return;
      this.$refs.usuarioConfig.initialize();
    },
  },
  computed: {
    ...mapState(["anchoMaximo","colorTabs","modoAyuda"])
  },
  components: {
    PerfilLotes: Lotes,
    ListaConfigUser
  }
};
</script>