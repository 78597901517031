<template>
	<v-col cols="12">
		<v-dialog v-model="dialog" persistent max-width="40%">
			<v-card>
				<v-card-text class="pb-0">
					<p class="text-center headline pt-4 pb-0 mb-0">
						Contraseña cambiada correctamente
					</p>
					<lottie
						:options="checkOptions"
						:height="200"
						:width="200"
						class="py-0"
					/>
				</v-card-text>
				<v-card-actions class="pt-0">
					<v-btn color="primary" block large @click="logout"
						>Aceptar</v-btn
					>
				</v-card-actions>
			</v-card>
		</v-dialog>

		<v-form v-model="valid" ref="form" lazy-validation>
			<v-row class="d-flex justify-center">
				<v-col cols="8">
					<div class="headline">Cambiar contraseña</div>
				</v-col>
				<v-col cols="8">
					<v-text-field
						v-model="passwordItem.anteriorPassword"
						:append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'"
						prepend-icon="mdi-lock"
						label="Password anterior*"
						:type="show1 ? 'text' : 'password'"
						required
						:rules="[rules.required, rules.longPass]"
						@click:append="show1 = !show1"
					></v-text-field>
				</v-col>
				<v-col cols="8">
					<v-text-field
						v-model="passwordItem.nuevaPassword"
						:append-icon="show2 ? 'mdi-eye' : 'mdi-eye-off'"
						prepend-icon="mdi-lock"
						label="Nuevo password*"
						:type="show2 ? 'text' : 'password'"
						required
						:rules="[
							rules.required,
							rules.longPass,
							rules.distinct,
						]"
						@click:append="show2 = !show2"
					></v-text-field>
				</v-col>
				<v-col cols="8">
					<v-text-field
						v-model="passwordItem.confirmPassword"
						:append-icon="show3 ? 'mdi-eye' : 'mdi-eye-off'"
						prepend-icon="mdi-lock"
						label="Confirmar Nuevo password*"
						:type="show3 ? 'text' : 'password'"
						required
						:rules="[
							rules.required,
							rules.longPass,
							rules.similar,
						]"
						@click:append="show3 = !show3"
					></v-text-field>
					<p class="mt-2 mb-0">*campos requeridos</p>
				</v-col>
				<v-col cols="8" class="d-flex justify-end">
					<v-btn color="primary mx-2" @click="save"> Guardar </v-btn>
				</v-col>
			</v-row>
		</v-form>
	</v-col>
</template>

<script>
import axios from "axios";
import Lottie from "vue-lottie";
import * as checkData from "@/assets/check.json";

export default {
	components: {
		lottie: Lottie,
	},
	data() {
		return {
			checkOptions: {
				animationData: checkData.default,
				loop: false,
				speeed: 1,
			},
			dialog: false,
			show1: false,
			show2: false,
			show3: false,
			valid: false,
			passwordItem: {
				anteriorPassword: "",
				nuevaPassword: "",
				confirmPassword: ""
			},
			defaultPasswordItem: {
				anteriorPassword: "",
				nuevaPassword: "",
				confirmPassword: ""
			},
			rules: {
				required: (value) => !!value || "Campo requerido.",
				longPass: (value) =>
					value.length >= 8 || "Minimo 8 caracteres.",
				distinct: () =>
					this.passwordItem.anteriorPassword !==
					this.passwordItem.nuevaPassword
						? true
						: "Introduzca una contraseña diferente a la anterior",
				similar: () =>
					this.passwordItem.nuevaPassword ===
					this.passwordItem.confirmPassword
						? true
						: "Las contraseñas no coinciden.",
			},
		};
	},
	methods: {
		save() {
			if (!this.validaPws()) return;
			if (!this.valid) return;
			axios
				.put("/Usuarios/CambiarPassword", {
					anteriorPassword: this.passwordItem.anteriorPassword,
					nuevaPassword: this.passwordItem.nuevaPassword,
				})
				.then(() => {
					this.dialog = true;
					this.reset();
				})
				.catch((error) => {
					console.log(error);
				});
		},
		reset() {
			this.passwordItem = Object.assign({}, this.defaultPasswordItem);
		},
		validaPws() {
			if (
				this.passwordItem.anteriorPassword !==
				this.passwordItem.nuevaPassword
			) {
				return true;
			} else {
				return false;
			}
		},
		logout() {
			this.$store.dispatch("logout").then(() => {
				this.$router.push("/");
			});
		},
	},
};
</script>

<style>
</style>