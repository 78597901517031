<template>
  <v-dialog v-model="show" persistent max-width="1200">
    <v-card v-if="item">
      <v-card-title class="d-flex justify-space-between align-center">
        <span class="headline">Art&iacute;culo {{item.codigo}}</span>
        <section>
          <div class="d-flex flex-column justify-center" v-if="item.condicion">
            <v-icon color="primary">mdi-check</v-icon>
            <span>{{item.estado}}</span>
          </div>
          <div class="d-flex flex-column justify-center" v-else>
            <v-icon color="warning">mdi-alert</v-icon>
            <span>{{item.estado}}</span>
          </div>
        </section>
      </v-card-title>
      <v-card-text>
        <v-dialog v-model="agregarComponente" max-width="800px" persistent>
          <v-card>
            <v-card-title>
              Agregar Componente
            </v-card-title>
            <v-card-text>
              <v-row>
                <v-col cols="12" md="12" sm="12">
                  <v-autocomplete
                    v-model="agregarItem.idArticuloComponente"
                    label="Artículo Componente"
                    :items="articulosFiltradosComponentes"
                    :item-text="nombreArticulo"
                    item-value="idArticulo"
                  >
                  </v-autocomplete>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12" md="12" sm="12">
                  <v-text-field
                    v-model="agregarItem.cantidad"
                    label="Cantidad"
                    type="number"
                  >
                  </v-text-field>
                </v-col>
                <v-col cols="12" md="12" sm="12">
                  <ul>
                    <li class="red--text" v-for="(e,idx) in errores" :key="'error-'+idx">{{e}}</li>
                  </ul>
                </v-col>
              </v-row>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="red" text @click="agregar">Guardar</v-btn>
              <v-btn color="primary" text @click="resetAgregar">Cancelar</v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
        <v-dialog v-model="eliminarComponente" max-width="800px" persistent>
          <v-card>
            <v-card-title>
              Eliminar Componente
            </v-card-title>
            <v-card-text>
              ¿Esta seguro que desea eliminar {{eliminarItem.articulo}} como Componente de {{item.nombre}}?
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="red" text @click="eliminar">Eliminar</v-btn>
              <v-btn color="primary" text @click="resetEliminar">Cancelar</v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
        <div>
          <section>{{item.articuloTipo}} | {{item.articuloCategoria}}</section>
          <section v-if="articulosFamilias.length" class="d-flex justify-end">
            <v-chip
              v-for="af in articulosFamilias"
              :key="af.idsArticuloFamilias"
              class="ma-2"
              color="primary"
            >{{af.nombre}}</v-chip>
          </section>
          <section v-else class="d-flex justify-end">
            <v-chip :disabled="true" class="ma-2">Sin familias</v-chip>
          </section>
        </div>
        <v-row>
          <v-col cols="12" md="7">
            <section class="details">
              <div class="details-header d-flex justify-center align-center">
                <span class="overline">Detalles</span>
              </div>
              <div class="detail d-flex justify-space-between mt-5">
                <span class="body-2">Nombre</span>
                <span class="body-2">{{item.nombre}}</span>
              </div>
              <div class="detail d-flex justify-space-between pt-1">
                <span class="body-2">C&oacute;digo</span>
                <span class="body-2">{{item.codigo}}</span>
              </div>
              <div class="detail d-flex justify-space-between pt-1">
                <span class="body-2">Unidad</span>
                <span class="body-2">{{item.unidad}}</span>
              </div>
              <div v-if="item.costo && permisoVista('articulos','$')">
                <div class="detail d-flex justify-space-between pt-1">
                  <span class="body-2">Costo m&iacute;nimo</span>
                  <span class="body-2">{{item.costo.min || 'N/A'}}</span>
                </div>
                <div class="detail d-flex justify-space-between pt-1">
                  <span class="body-2">Costo m&aacute;ximo</span>
                  <span class="body-2">{{item.costo.max || 'N/A'}}</span>
                </div>
              </div>
            </section>
            <section class="mt-5 d-flex flex-column">
              <div class="details-header d-flex justify-center align-center mb-5">
                <span class="overline">Almacenes</span>
              </div>
              <span v-if="item.almacenes.length == 0">* No cuenta con almacenes</span>
              <div v-else class="clickable-details container-almacenes">
                <!-- <div
                  class="d-flex justify-space-between detail pr-2 pl-2"
                  v-for="al in item.almacenes"
                  :key="al.idAlmacen"
                  @click="fetchArticulosAlmacen(al.idAlmacen, item.idArticulo)"
                >
                  <span class="body-2 clickable">{{al.nombre}}</span>
                  <span class="body-2 clickable">{{al.total}} {{al.unidad}}</span>
                </div> -->
                <div>
                  <v-expansion-panels elevation="0">
                    <v-expansion-panel
                    v-for="(al, x) in item.almacenes"
                    :key="`panel-${x}`"
                    @click="fetchArticulosAlmacen(al.idAlmacen, item.idArticulo)"
                    >
                      <v-expansion-panel-header>
                        <v-row>
                          <v-col cols="6">
                            <div class="text-left"><span class="body-2 clickable">{{al.nombre}}</span></div>
                          </v-col>
                          <v-col cols="6">
                            <div class="text-right"><span class="body-2 clickable">{{al.total}} {{al.unidad}}</span></div>
                          </v-col>
                        </v-row>
                      </v-expansion-panel-header>
                      <v-expansion-panel-content v-for="(subs, x) in al.subalmacen" :key="`subalmacen-${x}`">
                        <v-row>
                          <v-col cols="6">
                            <div class="text-left"><span class="body-2 clickable">{{'['+subs.clave+'] - '}} {{subs.nombre}}</span></div>
                          </v-col>
                          <v-col cols="6">
                            <div class="text-right"><span class="body-2 clickable">{{subs.cantidad}} {{subs.unidad}}</span></div>
                          </v-col>
                        </v-row>
                      </v-expansion-panel-content>
                    </v-expansion-panel>
                  </v-expansion-panels>
                </div>
              </div>
              <div v-if="item.almacenes.length" class="d-flex justify-space-between pr-2 pl-2 mt-3 warehouses-total-row">
                <span class="body-2">Total</span>
                <span class="body-2">{{item.almacenes.reduce((prev, curr) => prev + curr.total, 0)}} {{item.almacenes[0].unidad}}</span>
              </div>
            </section>
          </v-col>
          <v-col cols="12" md="5">
            <div class="details-header--green d-flex justify-center align-center mb-5">
              <span class="overline" v-if="almacenSeleccionado.length > 0">{{almacenSeleccionado[0].nombreAlmacen}}</span>
            </div>
            <section v-if="almacenSeleccionado.length > 0">
              <v-expansion-panels accordion>
                <v-expansion-panel
                v-for="almacen in almacenSeleccionado" :key="almacen"
                >
                <!-- 

                  v-for="(qr,i) in almacen.qrs"
                  :key="i"

                 -->
                  <v-expansion-panel-header>[{{almacen.codigoSubAlmacen == null ? almacen.codigoAlmacen : almacen.codigoSubAlmacen}}] - {{almacen.nombreSubAlmacen == null ? almacen.nombreAlmacen : almacen.nombreSubAlmacen}}</v-expansion-panel-header>
                  <v-expansion-panel-content>
                    <v-virtual-scroll
                    :items="almacen.qrs"
                    height="200"
                    item-height="64"
                    >
                    <template v-slot:default="{ item }">
                      <v-list-item :key="item">
                        <v-list-item-content>
                          <v-list-item-title v-if="item.codigoElemento != null">Código QR: {{item.codigoElemento}}</v-list-item-title>
                          <!-- <v-list-item-subtitle v-if="item.codigoElemento != null">
                            Código QR: {{item.codigoElemento}}
                          </v-list-item-subtitle> -->
                          <v-list-item-subtitle>
                            Cantidad: {{ item.cantidad }} {{ item.unidad }}
                          </v-list-item-subtitle>
                        </v-list-item-content>
                      </v-list-item>
                    </template>
                  </v-virtual-scroll>
                  </v-expansion-panel-content>
                </v-expansion-panel>
              </v-expansion-panels>
            </section>
            <span v-else>* No cuenta con stock</span>
          </v-col>
        </v-row>
        <div v-if="item.produccion">
          <div class="text-center">
            <div class="text-h5">Componentes</div>
          </div>
          <v-data-table
            :items="item.desgloses"
            :headers="headersComponents"
          >
            <template v-slot:top>
              <v-btn
                color="primary"
                dark
                class="mb-2"
                v-on="on"
                @click="agregarComponente = true"
              >Nuevo Componente</v-btn>
              <v-spacer />
            </template>
            <template v-slot:[`item.cantidad`]="{ item }">
              {{item.cantidad.toFixed(2)}} {{item.unidad}}
            </template>
            <template v-slot:[`item.actions`]="{ item }">
              <v-tooltip bottom color="black" class="white--text">
          <template v-slot:activator="{ on }">
        <v-icon v-on="on"
                small
                class="mr-2"
                @click="mostrarEliminarComponente(item)"
              >delete</v-icon>
               </template>
          <span class="white--text">Eliminar</span>
        </v-tooltip>
            </template>
          </v-data-table>
        </div>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="primary" text @click="regresarAction">Cerrar</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import axios from "axios";

export default {
  name: "vista-previa",
  props: {
    show: {
      type: Boolean,
      required: false,
      default: false
    },
    toggleShow: {
      type: Function,
      required: false
    },
    item: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      agregarComponente: false,
      eliminarComponente: false,
      eliminarItem: {
        articulo:"",
        cantidad:0,
        idArticulo: -1,
      },
      agregarItem:{
        idArticuloComponente : -1,
        cantidad: 0.0,
      },
      errores:[],
      _articulosFamilias: [],
      _articulosProduccion: [],
      almacenSeleccionado: [],
      headersComponents:[
        {
          text: "Artículo",
          align: "center",
          sortable: true,
          value: "articulo"
        },
        {
          text: "Cantidad",
          align: "center",
          sortable: true,
          value: "cantidad"
        },
        {
          text: "Acciones",
          align: "center",
          sortable: false,
          value: "actions"
        },
      ]
    };
  },
  methods: {
    nombreArticulo: element => element.codigo+' - '+element.nombre,
    resetEliminar(){
      this.eliminarComponente = false;
      this.eliminarItem.articulo = "";
      this.eliminarItem.idArticulo = -1;
      this.eliminarItem.cantidad = 0.0;
    },
    eliminar(){
      axios
        .put("/Inventario/EliminarComponente",{
          IdArticulo: this.item.idArticulo,
          IdArticuloComponente: this.eliminarItem.idArticulo
        })
        .then(response => {
          this.eliminarComponente = false;
          this.actualizarComponentes();
        })
        .catch(error => {
          console.log(error);
        });
    },
    resetAgregar(){
      this.agregarComponente = false;
      this.agregarItem.idArticuloComponente = -1;
      this.agregarItem.cantidad = 0.0;
    },
    validFloat(value) {
      return isNaN(parseFloat(value)) ? 0 : parseFloat(value);
    },
    agregar(){
      this.errores = [];
      if(this.agregarItem.idArticuloComponente <= 0){
        this.errores.push("Falta seleccionar un Artículo Componenete");
      }
      if(this.validFloat(this.agregarItem.cantidad) <= 0.0){
        this.errores.push("La Cantidad debe ser Mayor a Cero");
      }

      if(this.errores.length > 0) return;

      axios
        .post("/Inventario/AgregarComponente",{
          IdArticulo: this.item.idArticulo,
          IdArticuloComponente: this.agregarItem.idArticuloComponente,
          Cantidad: this.validFloat(this.agregarItem.cantidad)
        })
        .then( () => {
          this.resetAgregar();
          this.actualizarComponentes();
        })
        .catch(error => {
          console.log(error);
        });
    },
    actualizarComponentes(){
      axios
        .get("/Inventario/ListarComponentes/"+this.item.idArticulo)
        .then(response => {
          this.item.desgloses = null;
          this.item.desgloses = [];
          this.item.desgloses = response.data;
        })
        .catch(error => {
          console.log(error);
        })
    },
    mostrarEliminarComponente(item){
      this.eliminarItem = Object.assign({},item);
      this.eliminarComponente = true;
    },
    fetchArticulosProduccion(){
      axios
        .get("/Articulos/ArticulosProduccion")
        .then(response => {
          this._articulosProduccion = response.data;
        })
        .catch(error => {
          console.log(error);
        })
    },
    fetchArticulosFamilias() {
      axios
        .get("/articuloFamilias")
        .then(res => {
          this._articulosFamilias = res.data
        })
        .catch(err => console.error(err));
    },
    fetchArticulosAlmacen(idAlmacen, idArticulo) {
      axios
        .get(`/almacenes/${idAlmacen}/articulos/${idArticulo}`)
        .then(res => {
          this.almacenSeleccionado = res.data;
        })
        .catch(err => console.error(err));
    },
    regresarAction() {
      this.almacenSeleccionado = [];
      this.toggleShow();
    }
  },
  mounted() {
    this.fetchArticulosFamilias();
    this.fetchArticulosProduccion();
  },
  computed: {
    articulosFiltradosComponentes(){
      if(this.item.desgloses == null) return [];
      
      return this._articulosProduccion.filter(a => {
        return a.idArticulo != this.item.idArticulo
      }).filter(b => {
        return this.item.desgloses.findIndex(d => d.idArticulo == b.idArticulo) === -1;  
      });
    },
    articulosFamilias() {
      return this._articulosFamilias.find(x => this.item.idsArticuloFamilias.includes(x)) || [];
    }
  },
  watch: {
    show: {
      handler(val) {
        if (val) {
          let idArticulo = this.item.idArticulo;
          let idAlmacen =
            this.item.almacenes.length > 0
              ? this.item.almacenes[0].idAlmacen
              : null;
          if (idAlmacen) this.fetchArticulosAlmacen(idAlmacen, idArticulo);
        }
      }
    }
  }
};
</script>

<style>
:root {
  --primary-color: #1976d2;
  --secondary-color: #385F73;
}
.details:hover {
  cursor: default;
}
.detail {
  transition: all 0.2s ease-in-out;
}
.detail:hover {
  color: var(--primary-color);
}
.detail:hover > span.clickable {
  transform: scale(1.1);
}
.details-header {
  background: var(--primary-color);
  color: white;
}
.details-header--green {
  background: var(--secondary-color);
  color: white;
}
.clickable-details:hover {
  cursor: pointer;
}
.container-almacenes {
  height: 120px;
  overflow-y: scroll;
  overflow-x: hidden;
}
.warehouses-total-row {
  color: var(--secondary-color);
}
</style>