import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VProgressLinear } from 'vuetify/lib/components/VProgressLinear';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';
import { VTooltip } from 'vuetify/lib/components/VTooltip';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.permisoVista('listas', 'r'))?_c(VCol,{attrs:{"cols":"12"}},[_c(VDialog,{attrs:{"persistent":"","max-width":"95%"},model:{value:(_vm.dialogDetalles),callback:function ($$v) {_vm.dialogDetalles=$$v},expression:"dialogDetalles"}},[_c(VCard,[_c(VCardTitle,[_vm._v("Detalles de "+_vm._s(_vm.editedItem.area))]),_c(VCardText,[_c(VRow,[_c(VCol,{attrs:{"cols":"12"}},[_c(VTextField,{attrs:{"label":"Buscar","prepend-inner-icon":"mdi-magnify","hide-details":"","clearable":"","disabled":_vm.loadingDetalles},model:{value:(_vm.searchDetalle),callback:function ($$v) {_vm.searchDetalle=$$v},expression:"searchDetalle"}})],1)],1),_c(VRow,[_c(VCol,{attrs:{"cols":"12"}},[_c(VDataTable,{attrs:{"items":_vm.detalles,"headers":_vm.detallesHeaders,"loading":_vm.loadingDetalles,"search":_vm.searchDetalle},scopedSlots:_vm._u([{key:"item.porcentaje",fn:function(ref){
var item = ref.item;
return [_c('p',{staticClass:"mb-0",class:_vm.colorAvance(item.porcentaje)},[_vm._v(" "+_vm._s(item.porcentaje.toFixed(2))+" % ")])]}},{key:"item.piezasPlaneadas",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.getPiezasText(item,'piezasPlaneadas'))+" ")]}},{key:"item.piezasProducidas",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.getPiezasText(item,'piezasProducidas'))+" ")]}},{key:"item.diferencia",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.getPiezasText(item,'diferencia'))+" ")]}}],null,true)})],1)],1)],1),_c(VCardActions,[_c(VSpacer),_c(VBtn,{attrs:{"color":"success","text":""},on:{"click":function($event){return _vm.closeDialogDetalles()}}},[_vm._v("Cerrar")])],1)],1)],1),_c(VRow,[_c(VCol,{attrs:{"cols":"12"}},[_c(VDataTable,{attrs:{"items":_vm.datos,"headers":_vm.datosHeaders,"loading":_vm.loading},scopedSlots:_vm._u([{key:"item.porcentaje",fn:function(ref){
var item = ref.item;
return [_c('p',{staticClass:"mb-0",class:_vm.colorAvance(item.porcentaje)},[_vm._v(" "+_vm._s(item.porcentaje.toFixed(2))+" % ")]),_c('p',{staticClass:"mb-0 pt-2"},[_c(VProgressLinear,{attrs:{"color":_vm.colorAvance(item.porcentaje).split('--')[0],"buffer-value":"0","value":item.porcentaje,"stream":""}})],1)]}},{key:"item.avance",fn:function(ref){
var item = ref.item;
return [(item.avance.length == 0)?_c('p',{staticClass:"mb-0"},[_vm._v("N/D")]):_c('BarraAvance',{attrs:{"itemTitle":"Artículo","itemText":"nombre","borderColor":"#ffffff","height":30},model:{value:(item.avance),callback:function ($$v) {_vm.$set(item, "avance", $$v)},expression:"item.avance"}})]}},{key:"item.estado",fn:function(ref){
var item = ref.item;
return [(item.estado.length == 0)?_c('p',{staticClass:"mb-0"},[_vm._v("N/D")]):_vm._l((item.estado),function(item,idx){return _c('p',{key:("estado-" + idx),staticClass:"mb-0"},[_vm._v(" • "+_vm._s(item)+" ")])})]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c(VTooltip,{staticClass:"white--text",attrs:{"bottom":"","color":"black"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c(VIcon,_vm._g({attrs:{"small":""},on:{"click":function($event){return _vm.showDialogDetalles(item)}}},on),[_vm._v(" mdi-eye ")])]}}],null,true)},[_c('span',{staticClass:"white--text"},[_vm._v("Ver Detalles")])])]}}],null,true)})],1)],1)],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }