<template>
	<v-col cols="12" v-if="permisoVista('preciohistorico', 'r')">
		<v-dialog v-model="dialogNueva" persistent max-width="30%">
			<v-card>
				<v-card-text>
					<p class="text-h5 pt-4 font-weight-medium">
						Nueva solicitud de modificación de precios
					</p>
					<v-row>
						<v-col cols="12">
							<v-autocomplete
								v-model="editedItem.idArticulo"
								:items="articulos"
								:item-text="nombreArticulo"
								item-value="idArticulo"
								label="Artículo"
								clearable
								:error-messages="errores[0]"
								@focus="errores[0] = ''"
							></v-autocomplete>
						</v-col>
						<v-col cols="12" class="pt-0">
							<v-text-field
								v-model="editedItem.costo"
								label="Costo"
								prefix="$"
								:rules="[rules.costo]"
								:error-messages="errores[1]"
								@blur="
									() => {
										if (
											!isNaN(this.editedItem.costo) &&
											this.editedItem.costo != null
										) {
											this.editedItem.costo = parseFloat(
												editedItem.costo
											).toFixed(2);
										}
									}
								"
								@focus="errores[1] = ''"
							></v-text-field>
						</v-col>
						<v-col cols="12" class="pt-0">
							<VueCtkDateTimePicker
								v-model="editedItem.desde"
								label="Desde"
								:max-date="maxDate"
								format="YYYY-MM-DD HH:mm"
								formatted="YYYY-MM-DD HH:mm"
								noHeader
								overlay
								buttonNowTranslation="Ahora"
							/>
						</v-col>
						<v-col cols="12">
							<VueCtkDateTimePicker
								v-model="editedItem.hasta"
								label="[Opcional] Hasta"
								:max-date="maxDate"
								format="YYYY-MM-DD HH:mm"
								formatted="YYYY-MM-DD HH:mm"
								noHeader
								overlay
								buttonNowTranslation="Ahora"
							/>
						</v-col>
					</v-row>
					<v-row v-if="typeof errores[2] == 'string'">
						<ul class="py-4">
							<li class="red--text">
								{{ errores[2] }}
							</li>
						</ul>
					</v-row>
				</v-card-text>
				<v-card-actions>
					<v-spacer></v-spacer>
					<v-btn
						text
						color="success"
						:disabled="saving"
						@click="closeDialogs()"
						>Cancelar</v-btn
					>
					<v-btn
						text
						color="error"
						:disabled="saving"
						:loading="saving"
						@click="crearSolicitud()"
						>Crear</v-btn
					>
				</v-card-actions>
			</v-card>
		</v-dialog>
		<v-dialog v-model="dialogEliminar" persistent max-width="30%">
			<v-card>
				<v-card-text class="pb-0">
					<p class="text-h5 pt-4 font-weight-medium">
						¿Está seguro que desea eliminar esta solicitud?
					</p>
					<p>Esta acción no se puede revertir y será permanente.</p>
				</v-card-text>
				<v-card-actions>
					<v-spacer></v-spacer>
					<v-btn
						text
						color="success"
						:disabled="deleting"
						@click="closeDialogs()"
						>Cancelar</v-btn
					>
					<v-btn
						text
						color="error"
						:disabled="deleting"
						:loading="deleting"
						@click="eliminar()"
						>Eliminar</v-btn
					>
				</v-card-actions>
			</v-card>
		</v-dialog>
		<v-dialog v-model="dialogAutorizar" persistent max-width="32%">
			<v-card>
				<v-card-text class="pb-0">
					<p class="text-h5 pt-4 font-weight-medium">
						¿Está seguro de autorizar esta solicitud?
					</p>
					<p style="font-size: 16px !important">
						Se aplicará el
						<strong class="fuente1"
							>costo ${{ selectedItem.costo.toFixed(2) }}</strong
						>
						al artículo
						<strong class="fuente1">{{
							selectedItem.articulo
						}}</strong>
						desde la fecha
						<strong class="fuente1">{{
							selectedItem.desde
						}}</strong>
						<span
							style="font-size: 16px !important"
							v-if="selectedItem.hasta != null"
						>
							hasta la fecha
							<strong class="fuente1">{{
								selectedItem.hasta
							}}</strong></span
						>
					</p>
				</v-card-text>
				<v-card-actions>
					<v-spacer></v-spacer>
					<v-btn
						text
						color="success"
						:disabled="autorizando"
						@click="closeDialogs()"
						>Cancelar</v-btn
					>
					<v-btn
						text
						color="error"
						:disabled="autorizando"
						:loading="autorizando"
						@click="autorizar()"
						>Autorizar</v-btn
					>
				</v-card-actions>
			</v-card>
		</v-dialog>
		<v-row>
			<v-col class="pt-1 pb-0" cols="3">
				<v-select
					v-model="filtros.autorizado"
					:items="[
						{
							value: true,
							name: 'Si',
						},
						{
							value: false,
							name: 'No',
						},
					]"
					item-text="name"
					item-value="value"
					label="Autorizado"
					clearable
					@change="getSolicitudes()"
				></v-select>
			</v-col>
			<v-col class="pt-1 pb-0" cols="3">
				<v-autocomplete
					v-model="filtros.idArticulo"
					:items="articulos"
					:item-text="nombreArticulo"
					item-value="idArticulo"
					label="Artículo"
					clearable
					@change="getSolicitudes()"
				></v-autocomplete>
			</v-col>
			<v-col class="pt-3 pb-0" cols="3">
				<VueCtkDateTimePicker
					v-model="filtros.desde"
					label="Desde"
					format="YYYY-MM-DD HH:mm"
					formatted="YYYY-MM-DD HH:mm"
					noHeader
					overlay
					buttonNowTranslation="Ahora"
					@input="getSolicitudes()"
				/>
			</v-col>
			<v-col class="pt-3 pb-0" cols="3">
				<VueCtkDateTimePicker
					v-model="filtros.hasta"
					label="Hasta"
					format="YYYY-MM-DD HH:mm"
					formatted="YYYY-MM-DD HH:mm"
					noHeader
					overlay
					buttonNowTranslation="Ahora"
					@input="getSolicitudes()"
				/>
			</v-col>
		</v-row>
		<v-row>
			<v-col cols="4" class="pt-0">
				<v-btn
					v-if="permisoVista('preciohistorico', 'c')"
					color="primary"
					:disabled="loading"
					@click="showDialogNuevaSolicitud"
					>Nueva solicitud</v-btn
				>
			</v-col>
		</v-row>
		<v-data-table
			:items="solicitudes"
			:headers="headers"
			:loading="loading"
			:footer-props="{
				disableItemsPerPage: true,
				disablePagination: this.loading,
			}"
		>
			<template v-slot:item.costo="{ item }">
				${{ item.costo.toFixed(2) }}
			</template>
			<template v-slot:item.hasta="{ item }">
				{{ item.hasta != null ? item.hasta : "N/A" }}
			</template>
			<template v-slot:item.autorizo="{ item }">
				<p class="mb-0" v-if="item.autorizo != null">
					{{ item.autorizo }}
				</p>
				<p class="mb-0 warning--text" v-else>Sin autorizar</p>
			</template>
			<template v-slot:item.fechaAutorizacion="{ item }">
				{{
					item.fechaAutorizacion != null
						? item.fechaAutorizacion
						: "N/A"
				}}
			</template>
			<template v-slot:item.actions="{ item }">
				<v-tooltip
					
					bottom
					color="black"
					class="white--text"
					v-if="
						permisoVista('preciohistorico', 'u') &&
						item.fechaAutorizacion == null
					"
				>
					<template v-slot:activator="{ on }">
						<v-icon
							v-on="on"
							small
							@click="showDialogAutorizar(item)"
						>
							mdi-check-bold
						</v-icon>
					</template>
					<span class="white--text">Autorizar</span>
				</v-tooltip>
				<v-tooltip
					
					bottom
					color="black"
					class="white--text"
					v-if="
						permisoVista('preciohistorico', 'd') &&
						item.fechaAutorizacion == null
					"
				>
					<template v-slot:activator="{ on }">
						<v-icon
							v-on="on"
							small
							@click="showDialogEliminar(item)"
						>
							mdi-delete
						</v-icon>
					</template>
					<span class="white--text">Eliminar Solicitud</span>
				</v-tooltip>
			</template>
		</v-data-table>
	</v-col>
</template>

<script>
import axios from "axios";
import moment from 'moment';
export default {
	data() {
		return {
			editedItem: {
				idArticulo: null,
				costo: null,
				desde: null,
				hasta: null,
			},
			defaultEditedItem: {
				idArticulo: null,
				costo: null,
				desde: null,
				hasta: null,
			},
			selectedItem: {
				idArticuloPrecioModificacion: 0,
				solicitante: "",
				fechaSolicitud: "",
				costo: 0,
				desde: "",
				hasta: "",
				idArticulo: 0,
				articulo: "",
				autorizo: "",
				fechaAutorizacion: "",
			},
			defaultSelectedItem: {
				idArticuloPrecioModificacion: 0,
				solicitante: "",
				fechaSolicitud: "",
				costo: 0,
				desde: "",
				hasta: "",
				idArticulo: 0,
				articulo: "",
				autorizo: "",
				fechaAutorizacion: "",
			},
			maxDate: null,
			dialogNueva: false,
			dialogAutorizar: false,
			dialogEliminar: false,
			idArticuloPrecioModificacion: null,
			filtros: {
				autorizado: null,
				idArticulo: null,
				desde: null,
				hasta: null,
			},
			saving: false,
			deleting: false,
			autorizando: false,
			loading: false,
			errores: [],
			solicitudes: [],
			articulos: [],
			headers: [
				{
					text: "Artículo",
					align: "center",
					sortable: true,
					value: "articulo",
				},
				{
					text: "Costo",
					align: "center",
					sortable: true,
					value: "costo",
				},
				{
					text: "Desde",
					align: "center",
					sortable: true,
					value: "desde",
				},
				{
					text: "Hasta",
					align: "center",
					sortable: true,
					value: "hasta",
				},
				{
					text: "Solicitante",
					align: "center",
					sortable: true,
					value: "solicitante",
				},
				{
					text: "Fecha Solicitud",
					align: "center",
					sortable: true,
					value: "fechaSolicitud",
				},
				{
					text: "Autorizó",
					align: "center",
					sortable: true,
					value: "autorizo",
				},
				{
					text: "Fecha Autorización",
					align: "center",
					sortable: true,
					value: "fechaAutorizacion",
				},
				{
					text: "Acciones",
					align: "center",
					sortable: true,
					value: "actions",
				},
			],
			rules: {
				costo: (value) => {
					return !isNaN(value) && parseFloat(value) >= 0
						? true
						: "Costo invalido";
				},
			},
		};
	},
	computed: {
		headersFiltrados() {
			return this.permisoVista("preciohistorico", "$")
				? this.headers
				: this.headers.filter((head) => head.value != "costo");
		},
	},
	mounted() {
		this.initialize();
	},
	methods: {
		validar() {
			this.errores = [];

			if (this.editedItem.idArticulo == null) {
				this.errores[0] = "Debe seleccionar un Artículo";
			}

			if (this.editedItem.costo == null) {
				this.errores[1] = "Debe indicar el costo del Artículo";
			}

			if (this.editedItem.desde == null) {
				this.errores[2] =
					"Debe indicar desde que fecha se realizará la modificación de precios";
			}

			return this.errores.length == 0;
		},
		crearSolicitud() {
			if (!this.validar()) return;
			const obj = Object.assign({}, this.editedItem);
			obj.costo = parseFloat(obj.costo);
			this.saving = true;
			axios
				.post("/Inventario/SolicitarArticuloPrecioModificacion", obj)
				.then(() => {
					this.saving = false;
					this.closeDialogs();
					this.getSolicitudes();
				})
				.catch((error) => {
					this.saving = false;
					console.log(error);
				});
		},
		showDialogNuevaSolicitud() {
			this.maxDate = moment().format("YYYY-MM-DD HH:mm");
			this.dialogNueva = true;
		},
		nombreArticulo: (value) =>
			value.codigo != null
				? `[${value.codigo}] - ${value.nombre}`
				: `${value.nombre}`,
		showDialogEliminar(item) {
			this.idArticuloPrecioModificacion =
				item.idArticuloPrecioModificacion;
			this.dialogEliminar = true;
		},
		showDialogAutorizar(item) {
			this.selectedItem = Object.assign({}, item);
			this.idArticuloPrecioModificacion =
				item.idArticuloPrecioModificacion;
			this.dialogAutorizar = true;
		},
		eliminar() {
			this.deleting = true;
			axios
				.put(
					`/Inventario/EliminarSolicitudPrecioModificacion/${this.idArticuloPrecioModificacion}`
				)
				.then(() => {
					this.deleting = false;
					this.closeDialogs();
					this.getSolicitudes();
				})
				.catch((error) => {
					this.deleting = false;
					console.log(error);
				});
		},
		autorizar() {
			this.autorizando = true;
			axios
				.put(
					`/Inventario/AutorizarArticuloPrecioModificacion/${this.idArticuloPrecioModificacion}`
				)
				.then(() => {
					this.autorizando = false;
					this.closeDialogs();
					this.getSolicitudes();
				})
				.catch((error) => {
					this.autorizando = false;
					console.log(error);
				});
		},
		closeDialogs() {
			this.dialogNueva = false;
			this.dialogAutorizar = false;
			this.dialogEliminar = false;
			this.editedItem = Object.assign({}, this.defaultEditedItem);
			this.idArticuloPrecioModificacion = null;
			this.errores = [];
			this.selectedItem = Object.assign({}, this.defaultSelectedItem);
		},
		initialize() {
			this.getArticulos();
			this.getSolicitudes();
		},
		getArticulos() {
			axios
				.get("/Articulos/ArticulosNombres")
				.then((response) => {
					this.articulos = response.data;
				})
				.catch((error) => {
					console.log(error);
				});
		},
		getSolicitudes() {
			this.loading = true;
			axios
				.get("/Inventario/GetSolicitudesArticuloPrecioModificacion", {
					params: {
						Autorizado: this.filtros.autorizado,
						IdArticulo: this.filtros.idArticulo,
						Desde: this.filtros.desde,
						Hasta: this.filtros.hasta,
					},
				})
				.then((response) => {
					this.loading = false;
					this.solicitudes = response.data;
				})
				.catch((error) => {
					this.loading = false;
					console.log(error);
				});
		},
	},
};
</script>
<style scoped>
.fuente1 {
	font-size: 14px !important;
}
</style>