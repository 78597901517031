import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VSystemBar } from 'vuetify/lib/components/VSystemBar';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.permisoVista('briarbol', 'r'))?_c(VCol,{staticClass:"pa-0",attrs:{"cols":"12"}},[_c('OrganizationChart',{staticClass:"customNode",attrs:{"id":"chart","datasource":_vm.datos,"pan":"","zoom":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var nodeData = ref.nodeData;
return [_c(VCard,{staticClass:"mx-auto",attrs:{"loading":_vm.loading,"min-height":"250","max-height":"250","min-width":"200","max-width":"200"}},[_c(VSystemBar,{staticClass:"rounded-t-lg",attrs:{"height":10,"color":nodeData.color != null && nodeData.color}}),_c('div',{staticClass:"text-center d-flex flex-column align-center justify-center"},[_c('Avatar',{key:_vm.x,attrs:{"image":nodeData.imagen == null ? false : true,"url":nodeData.imagen == null ? nodeData.name : nodeData.imagen,"name":nodeData.name}})],1),_c(VCardTitle,{staticClass:"text-center d-flex flex-column align-center justify-center"},[_vm._v(_vm._s(nodeData.name))]),_c(VCardText,[_c('div',{staticClass:"mt-n2 text-subtitle-1"},[_vm._v(" "+_vm._s(nodeData.title)+" ")])])],1)]}}],null,false,2125514468)})],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }