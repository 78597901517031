<template>
  <v-col cols="12" class="px-8" v-if="permisoVista('contador','r')">
    <v-row>
      <v-col cols="4" md="4" sm="4">
        <v-autocomplete
          v-model="minutos"
          label="Desde (Obligatorio)"
          :items="rangos"
          @change="ejecutar"
        ></v-autocomplete>
      </v-col>
      <v-col cols="4" md="4" sm="4">
        <v-autocomplete
          v-model="bloque"
          label="Bloques de Tiempo (Obligatorio)"
          :items="bloques"
          @change="ejecutar"
        ></v-autocomplete>
      </v-col>
      <v-col cols="4" md="4" sm="4">
        <v-autocomplete
          v-model="idMaquinaIns"
          label="Máquina (Obligatorio)"
          :items="maquinas"
          item-value="idMaquinaIns"
          :item-text="nombreMaquina"
          clearable
          @change="ejecutar"
        ></v-autocomplete>
      </v-col>
    </v-row>
    <v-row v-if="realizarPeticion" class="px-0 ma-0">
      <v-col cols="12" class="ma-0 text-right text-subtitle-2">
        Proxima Actualización en {{tiempo}}
      </v-col>
    </v-row>
    <v-progress-linear
      v-if="loading"
			color="primary"
			indeterminate
		></v-progress-linear>

    <table
      v-if="estados.length > 0"
      :style="'width:100%;border: 0 px solid #666666;'"
    >
      <tbody>
        <tr style="border: 0 px solid #666666;">
          <v-tooltip
            v-for="(estado,idx) in estados"
            :key="`e-${idx}`"
            
            top
            color="black"
            class="white--text"
          >
            <template v-slot:activator="{ on, attrs }">
              <td
                v-bind="attrs"
                v-on="on"
                :class="`text-center ${(estado.estado ? 'green' : 'red')}`"
                :style="`border: 0 px solid #666666; padding-top: 10px; padding-bottom: 10px; background-color: ${(estado.estado ? 'green' : 'red')}; width:'${dimension}'; height: 20px;`"
              ></td>
            </template>
            <span class="white--text">
              <strong>Desde </strong>{{estado.desde}}
              <br>
              <strong>Hasta: </strong>{{estado.hasta}}
            </span>
          </v-tooltip>
        </tr>
      </tbody>
    </table>
    <v-chip class="mr-2 mt-2" v-for="(e,idx) in etiquetas" :key="'e-'+idx">{{e.etiqueta}}</v-chip>
    <apexchart type="line" height="350" :options="chartOptions" :series="datos"></apexchart>
  </v-col>
</template>

<script>
import axios from "axios";
import moment from "moment";

export default {
  data: () => ({
    areas:[],
    idArea: -1,
    minutos:15,
    rangos:[
      { 
        value:5,
        text: "Últimos 5 Minutos"
      },
      {
        value:10,
        text: "Últimos 10 Minutos"
      },
      {
        value:15,
        text: "Últimos 15 Minutos"
      },
      {
        value:30,
        text: "Últimos 30 Minutos"
      },
      {
        value:60, 
        text: "Ultima Hora"
      },
      {
        value:180, 
        text: "Últimas 3 Horas"
      },
      {
        value:720, 
        text: "Últimas 12 Horas"
      },
      {
        value:1440, 
        text: "Ultimas 24 Horas"
      }
    ],
    bloque: 1,
    bloques:[
      {
        value:1,
        text: "1 Minuto"
      },
      {
        value:2,
        text: "2 Minutos"
      },
      {
        value:5,
        text: "5 Minutos"
      },
      {
        value:10,
        text: "10 Minutos"
      },
      {
        value:30,
        text: "30 Minutos"
      },
      {
        value:60,
        text: "1 Hora"
      },
    ],
    maquinas:[],
    idMaquinaIns:-1,
    datos: [],
    chartOptions: {
      chart: {
        height: 350,
        type: 'line',
      },
      dataLabels: {
        enabled: true,
      },
      stroke: {
        curve: 'straight'
      },
      grid: {
        borderColor: '#e7e7e7',
        row: {
          colors: ['#f3f3f3', 'transparent'], // takes an array which will be repeated on columns
          opacity: 0.5
        },
      },
      markers: {
        size: 1
      },
      xaxis: {
        type: 'datetime',
        labels: {
          datetimeUTC: false
        }
      },
      tooltip: {
        x: {
          formatter: function (val) {
            return moment(val).format("YYYY-MM-DD HH:mm:ss");
          }
        }
      },
    },
    intervalo: null,
    segundos: 0,
    loading: false,
    estados:[],
    etiquetas:[],
  }),
  methods:{
    nombreMaquina: maquina => `${maquina.marca} ${maquina.modelo} - [${maquina.nombre}]`,
    getAreas(){
      axios
      .get("/Areas")
      .then(response => {
        this.areas = response.data.filter(a => a.idArea > 1);
      })
      .catch(error => {
        console.log(error);
      })
    },
    getMaquinasInstancias(){
      axios
				.get("/MaquinaInstancias")
				.then((response) => {
					this.maquinas = response.data.filter(a => a.idArea > 1 && a.esMaquina == true);
				})
				.catch((error) => {
					console.log(error);
				});
    },
    ejecutar(){
      if(this.realizarPeticion){
        this.getDatos();
      }
      else {
        this.datos = null;
      }
    },
    getDatos(){
      if(!this.realizarPeticion) return;
      this.loading = true;
      this.segundos = 30;
      axios
      .get(this.url)
      .then(response => {
        this.loading = false;
        this.datos = response.data.datos;
        this.estados = response.data.estado;
      })
      .catch(error => {
        this.loading = false;
        console.log(error);
      });
      
     axios
      .get(this.url2)
      .then(response => {
        this.etiquetas = response.data;
      })
      .catch(error => {
        console.log(error);
      });
   },
  },
  computed:{
    url(){
      return `/Lecturas/ReporteGraficaSensor?IdMaquinaIns=${this.idMaquinaIns}&Desde=${this.minutos}&Intervalo=${this.bloque}`;
    },
    url2(){
      return `/Lecturas/ListarEtiquetas?IdMaquinaIns=${this.idMaquinaIns}&Desde=${this.minutos}&Intervalo=${this.bloque}`;
    },
    realizarPeticion(){
      return (this.$utils.isValid(this.idMaquinaIns) && this.idMaquinaIns > 0);
    },
    tiempo() {
      return this.$utils.strings.toTime(this.segundos);
    },
    dimension(){
      if(this.estados.length == 0) return "100%";
      return (100.0/this.estados.length).toFixed(2) + "%";
    },
  },
  mounted(){
    this.getAreas();
    this.getMaquinasInstancias();
    this.intervalo = setInterval(() => {
      if(this.segundos == 0){
        this.getDatos();
      }
      else{
        this.segundos -= 1;
      }
    },1000);
  }
}
</script>