<template>
	<v-container fluid>
		<v-row>
      <v-col cols="4">
				<datePicker
					v-model="filtros.desde"
					format="YYYY-MM-DD"
					:maxDate="filtros.hasta"
					label="Desde (Obligatorio)"
					clearable
					:disabled="loading"
					@input="getData()"
				></datePicker>
			</v-col>
			<v-col cols="4">
				<datePicker
					v-model="filtros.hasta"
					format="YYYY-MM-DD"
					:minDate="filtros.desde"
					label="Hasta (Obligatorio)"
					clearable
					:disabled="loading"
					@input="getData()"
				></datePicker>
			</v-col>
      <v-col cols="4">
        <v-autocomplete
          v-model="filtros.idUsuario"
          label="Surtida Por (Obligatorio)"
          item-text="nombre"
					item-value="idUsuario"
          :items="usuarios"
          hide-details
					clearable
					:disabled="loading || loadingUsuarios"
          :loading="loadingUsuarios"
          @change="getData()"
        >
        </v-autocomplete>
      </v-col>
			<v-col cols="6">
				<v-autocomplete
					v-model="filtros.idCliente"
					label="Cliente"
					:items="clientes"
					item-text="nombre"
					item-value="idCliente"
					hide-details
					clearable
					:disabled="loading || loadingClientes"
					:loading="loadingClientes"
					@change="getData()"
				></v-autocomplete>
			</v-col>
			<v-col cols="6">
				<v-autocomplete
					v-model="filtros.estadoProduccion"
					label="Estado de Producción"
					:items="estadosProduccion"
					hide-details
					clearable
					:disabled="loading"
					@change="getData()"
				></v-autocomplete>
			</v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        <apexchart v-if="!loading" type="bar" height="350" :options="chartOptions" :series="series"></apexchart>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        <v-data-table
          :headers="headers"
          :items="ots"
          :items-per-page="-1"
          hide-default-footer
        >
          <template v-slot:[`item.virgen`]="{ item }">
            {{item.virgen.toFixed(2)}}%
          </template>
          <template v-slot:[`item.objetivo`]="{ item }">
            {{item.objetivo.toFixed(2)}}%
          </template>
          <template v-slot:[`item.variacion`]="{ item }">
            {{item.variacion.toFixed(2)}}%
          </template>
        </v-data-table>
        <div
          v-if="ots.length > 0"
          class="text-right text-subtitle-1"
        >
          Promedio: {{promedio.toFixed(2)}}%
          <br>
          Desviación Estandar: {{desvStd.toFixed(2)}}% 
        </div>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import axios from "axios";
export default {
	data() {
		return {
			estadosProduccion: [
				{
					text: "En Progreso",
					value: 1
				},
				{
					text: "Pausado",
					value: 2
				},
				{
					text: "Completado",
					value: 3
				},
				{
					text: "Producción Parcial",
					value: 4
				},
				{
					text: "Terminado Prematuramente",
					value: 5
				}
			],
			clientes: [],
			loadingClientes: false,
			filtros: {
				desde: null,
				hasta: null,
        idUsuario: null,
				idCliente: null,
				estadoProduccion: null
			},
			loading: false,
			loadingUsuarios: false,
      usuarios: [],
      ots: [],
      headers:[
        {
          text: "OT",
          align: "center",
          sortable: true,
          value: "ot",
          width: "25%"
        },
        {
          text: "% M.P. Virgen Real",
          align: "center",
          sortable: true,
          value: "virgen",
          width: "25%"
        },
        {
          text: "% M.P. Virgen Teorico",
          align: "center",
          sortable: true,
          value: "objetivo",
          width: "25%"
        },
        {
          text: "% Variación",
          align: "center",
          sortable: true,
          value: "variacion",
          width: "25%"
        },

      ],
      promedio: 0,
      desvStd: 0,
      series: [{
        name: '% Variación',
        data: []
      }],
      chartOptions: {
        chart: {
          type: 'bar',
          height: 350
        },
        plotOptions: {
          bar: {
            colors: {
              ranges: [
                {
                  from: -100,
                  to: -20,
                  color: '#F37362'
                },
                {
                  from: -20,
                  to: -5,
                  color: '#FEBC3B'
                },
                {
                  from: -5,
                  to: 5,
                  color: '#228B22'
                },
                {
                  from: 5,
                  to: 20,
                  color: '#FEBC3B'
                },
                {
                  from: 20,
                  to: 100,
                  color: '#F37362'
                },
              ]
            },
            columnWidth: '80%',
          }
        },
        dataLabels: {
          enabled: false,
        },
        yaxis: {
          title: {
            text: '% Variación',
          },
          labels: {
            formatter: function (y) {
              return y.toFixed(2) + "%";
            }
          }
        },
        xaxis: {
          categories: [],
          labels: {
            rotate: -90
          }
        }
      },
		};
	},
	mounted() {
		this.initialize();
	},
	methods: {
		initialize() {
			this.getClientes();
      this.getUsuarios();
			this.getData();
		},
		getClientes(){
			this.loadingClientes = true;

			axios
				.get('/Clientes')
				.then( (response) => {
					this.clientes = response.data;
				})
				.catch( (error) => {
					this.clientes = [];
					console.log(error);
				})
				.finally( () => {
					this.loadingClientes = false;
				});
		},
    getUsuarios(){
			this.loadingUsuarios = true;

			axios
				.get('/Reportes/UsuariosAlmacen')
				.then( (response) => {
					this.usuarios = response.data;
				})
				.catch( (error) => {
					this.usuarios = [];
					console.log(error);
				})
				.finally( () => {
					this.loadingUsuarios = false;
				});
		},
		getData() {
			if(this.filtros.desde == null || this.filtros.hasta == null || this.filtros.idUsuario == null) return;

			this.loading = true;
			
			axios
				.get("/Reportes/MezclasVariacion",{
					params: this.filtros
				})
				.then((response) => {
					this.series[0].data = response.data.variaciones;
          this.chartOptions.xaxis.categories = response.data.categorias;
          this.ots = response.data.ots;
          this.promedio = response.data.promedio;
          this.desvStd = response.data.desvStd;
				})
				.catch((error) => {
					console.log(error);
          this.series[0].data = [];
          this.chartOptions.xaxis.categories = [];
          this.ots = [];
          this.promedio = 0;
          this.desvStd = 0;
				})
				.finally(() => {
					this.loading = false;
				});
		},
	},
};
</script>