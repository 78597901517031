<template>
  <v-container>
    <template>
      <v-row justify="center">
        <v-dialog v-model="eliminar" persistent max-width="500px" :key="eliminar">
          <v-card>
            <v-card-title class="headline">¿Está seguro que desea eliminar esta unidad?</v-card-title>
            <v-card-text>Esta acción no se puede revertir y será permanente.</v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="blue darken-1" text @click="eliminar = false; delItem = '';">Cancelar</v-btn>
              <v-btn color="red darken-1" text @click="eliminar = false; deleteItemDB()">Aceptar</v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-row>
    </template>
    <v-data-table
      v-if="permisoVista('almacen','r')"
      :headers="headers"
      :items="unidades"
      :search="search"
      :loading="loading"
      loading-text="Cargando... Espere, Por Favor."
    >
      <template v-slot:top>
        <v-toolbar flat color="white">
          <v-dialog v-model="dialog" persistent max-width="500px" :key="dialog">
            <template v-slot:activator="{ on }">
              <v-btn color="primary" dark class="mb-2" v-on="on" v-if="permisoVista('almacen','c')">Nueva Unidad</v-btn>
            </template>
            <v-card>
              <v-card-title>
                <span class="headline">{{ formTitle }}</span>
              </v-card-title>
              <v-card-text>
                <v-container>
                  <v-row>
                    <v-col justify="center" cols="12" sm="12" md="12">
                      <v-text-field v-model="editedItem.nombre" label="Nombre de la Unidad"></v-text-field>
                    </v-col>
                    <v-col justify="center" cols="12" sm="12" md="12">
                      <v-text-field v-model="editedItem.abreviatura" label="Abreviatura de la Unidad"></v-text-field>
                    </v-col>
                    <v-col justify="center" cols="12" sm="12" md="12">
                      <v-select v-model="editedItem.numDimensiones" label="Número de Dimensiones" :items="dimensiones"></v-select>
                    </v-col>
                    <v-col v-if="editedItem.numDimensiones > 1" justify="center" cols="12" sm="12" md="12">
                      <v-select v-model="editedItem.idUnidadBase" label="Unidad Base" :items="unidadesBase()" item-text="nombre" item-value="idUnidad" ></v-select>
                    </v-col>
                  </v-row>
                  <ul>
                    <li class="red--text" v-for="ex in errores" :key="ex">{{ ex }}</li>
                  </ul>
                </v-container>
              </v-card-text>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="blue darken-1" text @click="dialog=false; errores=[];">Cancelar</v-btn>
                <v-btn color="red darken-1" text @click="save()" :disabled="saving" :loading="saving">
                  Guardar
                  <template v-slot:loader>
                    <v-progress-circular
                      indeterminate
                      :width="2"
                      :size="24"
                      color="red"
                    ></v-progress-circular>
                  </template>
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
          <v-spacer></v-spacer>
          <v-text-field
            v-model="search"
            append-icon="search"
            label="Buscar"
            single-line
            hide-details
          ></v-text-field>
        </v-toolbar>
      </template>
      <template v-slot:item.action="{ item }">
        <v-tooltip bottom color="black" class="white--text">
          <template v-slot:activator="{ on }">
        <v-icon v-on="on"
          v-if="permisoVista('almacen','u')"
          small
          class="mr-2"
          @click="editItem(item)"
        >
          edit
        </v-icon>
          </template>
          <span class="white--text">Editar</span>
        </v-tooltip>

        <v-tooltip bottom color="black" class="white--text">
          <template v-slot:activator="{ on }">
        <v-icon v-on="on"
          v-if="permisoVista('almacen','d')"
          small
          @click="deleteItem(item)"
        >
          delete
        </v-icon>
          </template>
          <span class="white--text">Eliminar</span>
        </v-tooltip>
      </template>
      <template v-slot:no-data>
        <v-btn color="primary" @click="initialize">Refrescar</v-btn>
      </template>
    </v-data-table>
    <v-snackbar
      v-model="alerta"
      top
      color="error"
    >
      Existe una Unidad Activa con el mismo nombre
      <v-btn
        text
        @click="alerta = false"
      >
        Cerrar
      </v-btn>
    </v-snackbar>
  </v-container>
  
</template>

<script>
import axios from "axios";

  export default {
    data: () => ({
      saving: false,
      dialog: false,
      alerta: false,
      eliminar: false,
      search: '',
      dimensiones: [
        {
          text:"1 Dimensión",
          value: 1,
        },
        {
          text:"2 Dimensiones",
          value: 2,
        },
        {
          text:"3 Dimensiones",
          value: 3,
        }
      ],
      headers: [
        {
          text: 'Unidad',
          align: 'left',
          sortable: true,
          value: 'nombre',
        },
        {
          text: 'Abreviatura',
          align: 'left',
          sortable: true,
          value: 'abreviatura',
          width:'10%',
          align:'center'
        },
        { text: 'Acciones', value: 'action', sortable: false, width:'25%', align:'center' },
      ],
      loading: false,
      unidades: [],
      delItem: '',
      action: 0,
      errores: [],
      editedIndex: -1,
      editedItem: {
        nombre: '',
        abreviatura: '',
        numDimensiones: 1,
        idUnidadBase: -1,
      },
      defaultItem: {
        nombre: '',
        abreviatura: '',
        numDimensiones: 1,
        idUnidadBase: -1,
      },
    }),

    computed: {
      formTitle () {
        return this.editedIndex === -1 ? 'Nueva Unidad' : 'Editar Unidad'
      }
    },

    watch: {
      dialog (val) {
        val || this.close()
      }
    },

    created () {
      this.initialize()
    },

    methods: {
      initialize () {
        let me = this;
        me.loading = true;
        axios
        .get("/Unidades")
        .then(response =>  {
          me.unidades = response.data;
          me.loading = false;
        })
        .catch(error => {
          console.log(error);
        });
      },

      editItem (item) {
        this.action = 1;
        this.editedIndex = this.unidades.indexOf(item)
        this.editedItem = Object.assign({}, item)
        this.dialog = true
      },

      deleteItem (item) {
        this.eliminar = true;
        this.delItem = item;
      },
      deleteItemDB(){
        let me = this;
        let item = this.delItem;
        this.delItem = '';
        axios
          .put("/Unidades/Desactivar/" + item.idUnidad)
          .then(response =>  {
            me.initialize();
          })
          .catch(error => {
            console.log(error);
          });
      },
      close () {
        this.action = 0,
        this.dialog = false
        setTimeout(() => {
          this.editedItem = Object.assign({}, this.defaultItem)
          this.editedIndex = -1
        }, 300)
      },
      validate(){
        this.errores =  [];
        if (
          this.editedItem.nombre.length < 5 ||
          this.editedItem.nombre.length > 50
        )
        {
          this.errores.push(
            "El nombre debe tener más de 4 caracteres y menos de 50."
          );
          return false;
        }
        if (
          this.editedItem.abreviatura.length < 1 ||
          this.editedItem.abreviatura.length > 6
        )
        {
          this.errores.push(
            "La abreviatura debe tener al menos 1 caracter y menos de 5."
          );
          return false;
        }
        return true;
      },
      unidadesBase(){
        return this.unidades.filter(function(element){
          return element.numDimensiones == 1;
        });
      },
      save () {
        this.action = 0;
        if(this.validate()){
          let me = this;
          me.saving = true;
          if (this.editedIndex > -1)
          {
            //Código para editar
            axios
              .put("/Unidades/Actualizar/"+me.unidades[me.editedIndex].idUnidad, {
                Nombre: me.editedItem.nombre,
                Abreviatura: me.editedItem.abreviatura,
                NumDimensiones: me.editedItem.numDimensiones,
                IdUnidadBase: (me.editedItem.numDimensiones > 1) ? me.editedItem.idUnidadBase : null, 
              })
              .then(response =>  {
                me.saving = false;
                me.close();
                me.initialize();
              })
              .catch(error => {
                me.saving = false;
                if (error.response) {
                  if(error.response.status == 409){
                    me.alerta = true;
                    me.errores.push("Existe una Unidad Activa con el Mismo Nombre");
                  }
                }
              });
          }
          else
          {
            axios
              .post("/Unidades/Crear", {
                Nombre: me.editedItem.nombre,
                Abreviatura: me.editedItem.abreviatura,
                NumDimensiones: me.editedItem.numDimensiones,
                IdUnidadBase: (me.editedItem.numDimensiones > 1) ? me.editedItem.idUnidadBase : null,
              })
              .then(response =>  {
                me.saving = false;
                me.close();
                me.initialize();
              })
              .catch(error => {
                me.saving = false;
                if (error.response) {
                  if(error.response.status == 409){
                    me.alerta = true;
                    me.errores.push("Existe una Unidad Activa con el Mismo Nombre");
                  }
                }
              });
          }
        }
      },
    },
  }
</script>