<template>
	<v-container fluid>
		<v-dialog v-model="dialogDelete" persistent max-width="500px">
			<v-card>
				<v-card-title style="word-break: break-word"
					>¿Está seguro que desea eliminar este Mantenimiento
					Correctivo?</v-card-title
				>
				<v-card-text>
					<p class="subtitle-1 text--secondary">
						Esta acción no se puede revertir y será permanente.
					</p>
				</v-card-text>
				<v-card-actions>
					<v-spacer></v-spacer>
					<v-btn
						color="success"
						:disabled="deleting"
						text
						@click="closeDialog"
						>Cancelar</v-btn
					>
					<v-btn
						color="error"
						:disabled="deleting"
						:loading="deleting"
						text
						@click="deleteMantenimiento"
						>Aceptar</v-btn
					>
				</v-card-actions>
			</v-card>
		</v-dialog>
		<v-dialog v-model="dialog" persistent max-width="400px">
			<v-card>
				<v-card-title>
					{{
						editedItem.idMoldeCatalogoCorrectivo == null
							? "Crear"
							: "Editar"
					}}
					Mantenimiento Correctivo
				</v-card-title>
				<v-card-text>
					<v-container fluid>
						<v-row>
							<v-col cols="12">
								<v-text-field
									v-model="editedItem.codigo"
									label="Código del Mantenimiento Correctivo"
									clearable
									:disabled="saving"
									:error-messages="errores.errorCodigo"
									@focus="$set(errores, 'errorCodigo', null)"
								></v-text-field>
							</v-col>
							<v-col cols="12">
								<v-text-field
									v-model="editedItem.nombre"
									label="Nombre del Mantenimiento Correctivo"
									clearable
									:disabled="saving"
									:error-messages="errores.errorNombre"
									@focus="$set(errores, 'errorNombre', null)"
								></v-text-field>
							</v-col>
						</v-row>
					</v-container>
				</v-card-text>
				<v-card-actions>
					<v-spacer></v-spacer>
					<v-btn
						text
						color="success"
						:disabled="saving"
						@click="closeDialog"
						>Cancelar</v-btn
					>
					<v-btn
						text
						color="error"
						:disabled="saving"
						:loading="saving"
						@click="save"
						>Guardar</v-btn
					>
				</v-card-actions>
			</v-card>
		</v-dialog>
		<v-row>
			<v-col cols="4">
				<v-btn color="primary" :disabled="loading" @click="showDialog()"
					>Agregar Mantenimiento Correctivo</v-btn
				>
			</v-col>
		</v-row>
		<v-row>
			<v-col cols="12">
				<v-data-table
					:items="correctivos"
					:headers="headersCorrectivos"
					:loading="loading"
				>
					<template v-slot:[`item.actions`]="{ item }">
						<v-tooltip bottom color="black" class="white--text">
							<template v-slot:activator="{ on }">
								<v-icon
									v-on="on"
									small
									class="mr-2"
									:disabled="loading"
								>
									mdi-play
								</v-icon>
							</template>
							<span class="white--text"
								>Programar Mantenimiento Atemporal</span
							>
						</v-tooltip>
						<v-tooltip bottom color="black" class="white--text">
							<template v-slot:activator="{ on }">
								<v-icon
									v-on="on"
									small
									class="mr-2"
									:disabled="loading"
									@click="showDialog(item)"
								>
									mdi-pencil
								</v-icon>
							</template>
							<span class="white--text">Editar</span>
						</v-tooltip>
						<v-tooltip bottom color="black" class="white--text">
							<template v-slot:activator="{ on }">
								<v-icon
									v-on="on"
									small
									:disabled="loading"
									@click="showDialog(item, true)"
								>
									mdi-delete
								</v-icon>
							</template>
							<span class="white--text">Eliminar</span>
						</v-tooltip>
					</template>
				</v-data-table>
			</v-col>
		</v-row>
	</v-container>
</template>

<script>
export default {
	data() {
		return {
			errores: {
				errorCodigo: null,
				errorNombre: null,
			},
			deleting: false,
			saving: false,
			correctivos: [],
			headersCorrectivos: [
				{
					text: "Código",
					value: "codigo",
				},
				{
					text: "Nombre",
					value: "nombre",
				},
				{
					text: "Acciones",
					value: "actions",
					width: "10%",
				},
			],
			loading: false,
			editedItem: {
				idMoldeCatalogoCorrectivo: null,
				codigo: null,
				nombre: null,
			},
			defaultEditedItem: {
				idMoldeCatalogoCorrectivo: null,
				codigo: null,
				nombre: null,
			},
			dialog: false,
			dialogDelete: false,
		};
	},
	created() {
		this.correctivos = [
			{
				idMoldeCatalogoCorrectivo: 1,
				codigo: "COR-1",
				nombre: "Mantenimiento Correctivo 1",
			},
			{
				idMoldeCatalogoCorrectivo: 2,
				codigo: "COR-2",
				nombre: "Mantenimiento Correctivo 2",
			},
			{
				idMoldeCatalogoCorrectivo: 3,
				codigo: "COR-3",
				nombre: "Mantenimiento Correctivo 3",
			},
		];
	},
	mounted() {
		this.initialize();
	},
	methods: {
		validate() {
			this.resetErrores();

			if (!this.editedItem.codigo) this.errores.errorCodigo = "Requerido";

			if (!this.editedItem.nombre) this.errores.errorNombre = "Requerido";

			return Object.values(this.errores).every((value) => value == null);
		},
		save() {
			if (!this.validate()) return;

			this.saving = true;

			setTimeout(() => {
				if (this.editedItem.idMoldeCatalogoCorrectivo == null) {
					const maxId = Math.max(
						...this.correctivos.map(
							(correctivo) => correctivo.idMoldeCatalogoCorrectivo
						)
					);
					const payload = {
						...this.editedItem,
						idMoldeCatalogoCorrectivo: maxId <= 0 ? 1 : maxId + 1,
					};
					this.correctivos.push(structuredClone(payload));
				} else {
					const indexEdited = this.correctivos.findIndex(
						(correctivo) =>
							correctivo.idMoldeCatalogoCorrectivo ==
							this.editedItem.idMoldeCatalogoCorrectivo
					);
					this.correctivos.splice(indexEdited, 1, this.editedItem);
				}
				this.saving = false;
				this.closeDialog();
				this.initialize();
			}, 1000);
		},
		deleteMantenimiento() {
			this.deleting = true;

			setTimeout(() => {
				const index = this.correctivos.findIndex(
					(correctivo) =>
						correctivo.idMoldeCatalogoCorrectivo ==
						this.editedItem.idMoldeCatalogoCorrectivo
				);
				this.correctivos.splice(index, 1);
				this.deleting = false;
				this.closeDialog();
				this.initialize();
			}, 1000);
		},
		resetErrores() {
			Object.keys(this.errores).forEach((key) => {
				this.errores[key] = null;
			});
		},
		closeDialog() {
			this.dialog = false;
			this.dialogDelete = false;

			setTimeout(() => {
				this.editedItem = structuredClone(this.defaultEditedItem);
				this.resetErrores();
			}, 200);
		},
		showDialog(item = null, showDelete = false) {
			this.editedItem = structuredClone(item || this.defaultEditedItem);

			if (showDelete) this.dialogDelete = true;
			else this.dialog = true;
		},
		initialize() {
			this.getCatalogoCorrectivoMoldes();
		},
		getCatalogoCorrectivoMoldes() {
			this.loading = true;

			setTimeout(() => {
				this.loading = false;
			}, 1000);
		},
	},
};
</script>
<style scoped>
.centered-input >>> input {
	text-align: center !important;
}
</style>