<template>
	<v-col cols="12" v-if="permisoVista('recetasautomaticas', 'r')">
		<v-dialog v-model="dialogDelete" persistent max-width="40%">
			<v-card>
				<v-card-text>
					<p class="text-h5 pt-4 font-weight-regular text--primary">
						¿Está seguro que desea eliminar esta Receta Automática?
					</p>
					<p class="subtitle-1">
						Esta acción no se puede revertir y será permanente.
					</p>
				</v-card-text>
				<v-card-actions>
					<v-spacer></v-spacer>
					<v-btn
						text
						color="success"
						:disabled="deleting"
						@click="cerrarDialog()"
						>Cancelar</v-btn
					>
					<v-btn
						text
						color="error"
						:disabled="deleting"
						:loading="deleting"
						@click="eliminarRecetaAuto()"
						>Eliminar</v-btn
					>
				</v-card-actions>
			</v-card>
		</v-dialog>
		<v-dialog v-model="dialogCrear" persistent max-width="40%">
			<v-card>
				<v-card-title
					>{{
						itemRecetaAuto.idRecetaAutomatica <= 0
							? "Nueva"
							: "Editar"
					}}
					Receta Automática</v-card-title
				>
				<v-card-text>
					<v-col cols="12">
						<v-autocomplete
							:readonly="itemRecetaAuto.idRecetaAutomatica > 0"
							v-model="itemRecetaAuto.idArticulo"
							:items="articulos"
							:item-text="nombreArticulo"
							item-value="idArticulo"
							label="Materia Prima"
							:clearable="itemRecetaAuto.idRecetaAutomatica <= 0"
							:error-messages="errorArticulo"
							@focus="errorArticulo = ''"
							@change="
								itemRecetaAuto.idReceta=null;
								getArticulosRecetas()
							"
						></v-autocomplete>
					</v-col>
					<v-col cols="12">
						<v-autocomplete
							v-model="itemRecetaAuto.idReceta"
							:items="recetas"
							item-text="articuloReceta"
							item-value="idReceta"
							label="Artículo a Producir"
							clearable
							:error-messages="errorReceta"
							@focus="errorReceta = ''"
						></v-autocomplete>
					</v-col>
				</v-card-text>
				<v-card-actions>
					<v-spacer></v-spacer>
					<v-btn
						text
						color="success"
						:disabled="saving"
						@click="cerrarDialog()"
						>Cancelar</v-btn
					>
					<v-btn
						text
						color="error"
						:disabled="saving"
						:loading="saving"
						@click="guardarRecetaAuto()"
						>Guardar</v-btn
					>
				</v-card-actions>
			</v-card>
		</v-dialog>
		<v-row>
			<v-col cols="5" class="mt-0 pt-0">
				<v-autocomplete
					v-model="filtros.idArticulo"
					:items="articulos"
					:item-text="nombreArticulo"
					item-value="idArticulo"
					label="Artículo"
					clearable
					hide-details
					@change="getRecetasAutomaticas()"
				></v-autocomplete>
			</v-col>
			<v-col cols="6" class="mt-0 pt-0 ml-auto">
				<v-text-field
					class="pt-2"
					v-model="search"
					append-icon="search"
					label="Buscar"
					single-line
					hide-details
				></v-text-field>
			</v-col>
			<v-col cols="4" class="mt-0 pt-0">
				<v-btn
					v-if="permisoVista('recetasautomaticas', 'c')"
					color="primary"
					@click="showDialog"
					>Nueva Receta Automática</v-btn
				>
			</v-col>
		</v-row>
		<v-data-table
			:items="recetasAuto"
			:headers="headersRecetas"
			:loading="loading"
			:search="search"
		>
			<template v-slot:item.idArticulo="{ item }">
				{{ nombreArticulo(item) }}
			</template>
			<template v-slot:item.idArticuloProducir="{ item }">
				{{ nombreArticuloProd(item) }}
			</template>
			<template v-slot:item.actions="{ item }">
				<v-tooltip
					
					bottom
					color="black"
					class="white--text"
					v-if="permisoVista('recetasautomaticas', 'u')"
				>
					<template v-slot:activator="{ on }">
						<v-icon
							v-on="on"
							small
							class="mr-2"
							@click="editDialog(item)"
						>
							edit
						</v-icon>
					</template>
					<span class="white--text">Editar</span>
				</v-tooltip>
				<v-tooltip
					
					bottom
					color="black"
					class="white--text"
					v-if="permisoVista('recetasautomaticas', 'd')"
				>
					<template v-slot:activator="{ on }">
						<v-icon
							v-on="on"
							small
							class="mr-2"
							@click="deleteDialog(item)"
						>
							delete
						</v-icon>
					</template>
					<span class="white--text">Eliminar</span>
				</v-tooltip>
			</template>
		</v-data-table>
		<v-snackbar v-model="alerta" top color="error">
			{{ errorAlerta }}
			<v-btn text @click="alerta = false"> Cerrar </v-btn>
		</v-snackbar>
	</v-col>
</template>

<script>
import axios from "axios";

export default {
	data() {
		return {
			recetas: [],
			articulosMP: [],
			articulos: [],
			filtros: {
				idArticulo: null,
			},
			errorAlerta: "",
			alerta: false,
			search: "",
			errorReceta: "",
			errorArticulo: "",
			dialogDelete: false,
			dialogCrear: false,
			itemRecetaAuto: {
				codigo: "",
				codigoProducir: "",
				idArticulo: 0,
				idArticuloProducir: 0,
				idReceta: 0,
				idRecetaAutomatica: 0,
				nombre: "",
				nombreProducir: "",
			},
			defaultItemReceta: {
				codigo: "",
				codigoProducir: "",
				idArticulo: 0,
				idArticuloProducir: 0,
				idReceta: 0,
				idRecetaAutomatica: 0,
				nombre: "",
				nombreProducir: "",
			},
			headersRecetas: [
				{
					text: "Materia Prima",
					align: "center",
					sortable: true,
					value: "idArticulo",
					width: "45%",
				},
				{
					text: "Artículo a Producir",
					align: "center",
					sortable: true,
					value: "idArticuloProducir",
					width: "45%",
				},
				{
					text: "Acciones",
					value: "actions",
					sortable: false,
					width: "10%",
				},
			],
			recetasAuto: [],
			loading: false,
			saving: false,
			deleting: false,
		};
	},
	mounted() {
		this.initialize();
	},
	methods: {
		nombreArticulo: (articulo) =>
			articulo.codigo != null
				? `[${articulo.codigo}] - ${articulo.nombre}`
				: `${articulo.nombre}`,
		nombreArticuloProd: (articulo) =>
			articulo.codigoProducir != null
				? `[${articulo.codigoProducir}] - ${articulo.nombreProducir}`
				: `${articulo.nombreProducir}`,
		eliminarRecetaAuto() {
			this.deleting = true;
			axios
				.put(
					`/RecetaAutomaticas/DesactivarRecetaAutomatica/${this.itemRecetaAuto.idRecetaAutomatica}`
				)
				.then(() => {
					this.deleting = false;
					this.cerrarDialog();
					this.getRecetasAutomaticas();
				})
				.catch((error) => {
					this.deleting = false;
					console.log(error);
				});
		},
		validate() {
			this.errorReceta = "";
			this.errorArticulo = "";

			if (
				this.itemRecetaAuto.idReceta <= 0 ||
				this.itemRecetaAuto.idReceta == null
			) {
				this.errorReceta = "Debe seleccionar un Artículo a Producir.";
			}
			if (
				this.itemRecetaAuto.idArticulo <= 0 ||
				this.itemRecetaAuto.idArticulo == null
			) {
				this.errorArticulo = "Debe seleccionar una Materia Prima.";
			}

			return this.errorReceta == "" && this.errorArticulo == "";
		},
		guardarRecetaAuto() {
			if (!this.validate()) return;
			this.saving = true;
			if (this.itemRecetaAuto.idRecetaAutomatica <= 0) {
				axios
					.post("/RecetaAutomaticas/CrearRecetaAutomatica", {
						idReceta: this.itemRecetaAuto.idReceta,
						idArticulo: this.itemRecetaAuto.idArticulo,
					})
					.then(() => {
						this.saving = false;
						this.cerrarDialog();
						this.getRecetasAutomaticas();
					})
					.catch((error) => {
						this.saving = false;
						if (error.response) {
							if (error.response.status == 409) {
								this.alerta = true;
								this.errorAlerta =
									"Existe una Receta Automática ACTIVA con el mismo nombre.";
							}
							if (error.response.status == 404) {
								this.alerta = true;
								this.errorAlerta =
									"El Artículo no corresponde a la Receta seleccionada.";
							}
						}
					});
			} else {
				axios
					.put(
						`/RecetaAutomaticas/ActualizarRecetaAutomatica/${this.itemRecetaAuto.idRecetaAutomatica}?idReceta=${this.itemRecetaAuto.idReceta}`
					)
					.then(() => {
						this.saving = false;
						this.cerrarDialog();
						this.getRecetasAutomaticas();
					})
					.catch((error) => {
						this.saving = false;
						if (error.response) {
							if (error.response.status == 409) {
								this.alerta = true;
								this.errorAlerta =
									"Existe una Receta Automática ACTIVA con el mismo nombre.";
							}
							if (error.response.status == 404) {
								this.alerta = true;
								this.errorAlerta =
									"El Artículo no corresponde a la Receta seleccionada.";
							}
						}
					});
			}
		},
		initialize() {
			this.getArticulos();
			this.getRecetasAutomaticas();
		},
		editDialog(item) {
			this.itemRecetaAuto = Object.assign({}, item);
			this.getArticulosRecetas();
			this.dialogCrear = true;
		},
		deleteDialog(item) {
			this.itemRecetaAuto = Object.assign({}, item);
			this.dialogDelete = true;
		},
		showDialog() {
			this.dialogCrear = true;
		},
		cerrarDialog() {
			this.errorReceta = "";
			this.errorArticulo = "";
			this.itemRecetaAuto = Object.assign({}, this.defaultItemReceta);
			this.dialogDelete = false;
			this.dialogCrear = false;
		},
		getArticulosRecetas() {
			if (this.itemRecetaAuto.idArticulo == null) return;
			axios
				.get(
					`/Articulos/ArticuloDependientes/${this.itemRecetaAuto.idArticulo}`
				)
				.then((response) => {
					this.recetas = response.data.dependientes;
				})
				.catch((error) => {
					console.log(error);
				});
		},
		getArticulos() {
			axios
				.get("/Articulos/ArticulosNombres")
				.then((response) => {
					this.articulos = response.data.filter(
						(articulo) => articulo.idArticuloTipo == 1
					);
				})
				.catch((error) => {
					console.log(error);
				});
		},
		getRecetasAutomaticas() {
			axios
				.get("/RecetaAutomaticas/GetRecetaAutomatica", {
					params: {
						IdArticuloMP: this.filtros.idArticulo,
					},
				})
				.then((response) => {
					this.recetasAuto = response.data;
				})
				.catch((error) => {
					console.log(error);
				});
		},
	},
};
</script>