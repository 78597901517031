<template>
  <v-container>
    <v-row>
      <v-col justify="center" cols="12" sm="12" md="12">
        <v-autocomplete
          v-model="idTipo"
          :items="tipos"
          :loading="loadingTipos"
          item-text="nombre"
          item-value="idDesconocido"
        ></v-autocomplete>
      </v-col>
    </v-row>
    <template>
      <v-row justify="center">
        <v-dialog v-model="eliminar" persistent max-width="500px" :key="eliminar">
          <v-card>
            <v-card-title class="headline">¿Está seguro que desea eliminar esta categoría?</v-card-title>
            <v-card-text>Esta acción no se puede revertir y será permanente.</v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="blue darken-1" text @click="eliminar = false; delItem = '';">Cancelar</v-btn>
              <v-btn color="red darken-1" text @click="eliminar = false; deleteItemDB()">Aceptar</v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-row>
    </template>
    <v-data-table
      v-if="permisoVista('desconocidos2','r')"
      :headers="headers"
      :items="instancias"
      :search="search"
      :loading="loading"
      loading-text="Cargando... Espere, Por Favor."
    >
      <template v-slot:top>
        <v-toolbar flat color="white">
          <v-dialog v-model="dialog" persistent max-width="500px" :key="dialog">
            <template v-slot:activator="{ on }">
              <v-btn color="primary" dark class="mb-2" v-on="on" v-if="permisoVista('desconocidos2','c')">Nuevo Desconocido</v-btn>
            </template>
            <v-card>
              <v-card-title>
                <span class="headline">{{ formTitle }}</span>
              </v-card-title>
              <v-card-text>
                <v-container>
                  <v-row>
                    <v-col justify="center" cols="12" sm="12" md="12">
                      <v-text-field v-model="editedItem.nombre" label="Nombre del Desconocido"></v-text-field>
                    </v-col>
                    <v-col justify="center" cols="12" sm="12" md="12">
                      <v-text-field v-model="editedItem.codigo" label="Código del Desconocido"></v-text-field>
                    </v-col>
                    <v-col justify="center" cols="12" sm="12" md="12">
                      <v-text-field type="number" v-model="editedItem.costo" label="Costo del Desconocido"></v-text-field>
                    </v-col>
                    <v-col v-for="(campo, index) in campos" :key="`campos-${index}`" cols="12" sm="12" md="12">
                      <v-text-field
                        v-if="campo.tipo == 1"
                        v-model="editedItem['campo'+(index+1)]"
                        :label="campo.nombre + ' del Desconocido'"
                      ></v-text-field>

                      <v-text-field
                        v-if="campo.tipo == 2"
                        type="number"
                        v-model="editedItem['campo'+(index+1)]"
                        :label="campo.nombre + ' del Desconocido'"
                      ></v-text-field>
                      
                      <v-text-field
                        v-if="campo.tipo == 3"
                        v-model="editedItem['campo'+(index+1)]"
                        :label="campo.nombre + ' del Desconocido'"
                      ></v-text-field>
                    </v-col>
                  </v-row>
                  <ul>
                    <li class="red--text" v-for="ex in errores" :key="ex">{{ ex }}</li>
                  </ul>
                </v-container>
              </v-card-text>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="blue darken-1" text @click="dialog=false; errores=[];">Cancelar</v-btn>
                <v-btn color="red darken-1" text @click="save()" :disabled="saving" :loading="saving">
                  Guardar
                  <template v-slot:loader>
                    <v-progress-circular
                      indeterminate
                      :width="2"
                      :size="24"
                      color="red"
                    ></v-progress-circular>
                  </template>
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
          <v-spacer></v-spacer>
          <v-text-field
            v-model="search"
            append-icon="search"
            label="Buscar"
            single-line
            hide-details
          ></v-text-field>
        </v-toolbar>
      </template>
      <template v-slot:item.action="{ item }">
        <v-tooltip bottom color="black" class="white--text">
          <template v-slot:activator="{ on }">
        <v-icon v-on="on"
          v-if="permisoVista('desconocidos2','u')"
          small
          class="mr-2"
          @click="editItem(item)"
        >
          edit
        </v-icon>
         </template>
          <span class="white--text">Editar</span>
        </v-tooltip>
        <v-tooltip bottom color="black" class="white--text">
          <template v-slot:activator="{ on }">
        <v-icon v-on="on"
          v-if="permisoVista('desconocidos2','d')"
          small
          @click="deleteItem(item)"
        >
          delete
        </v-icon>
         </template>
          <span class="white--text">Eliminar</span>
        </v-tooltip>
      </template>
      <template v-slot:no-data>
        <v-btn color="primary" @click="initialize">Refrescar</v-btn>
      </template>
    </v-data-table>
    <v-snackbar
      v-model="alerta"
      top
      color="error"
    >
      Existe una Categoría Activa con el mismo Nombre
      <v-btn
        text
        @click="alerta = false"
      >
        Cerrar
      </v-btn>
    </v-snackbar>
  </v-container>
  
</template>

<script>
import axios from "axios";

  export default {
    data: () => ({
      saving:false,
      idTipo:-1,
      loadingTipos: false,
      tipos: [],
      campos:[],
      instancias: [],
      dialog: false,
      alerta: false,
      eliminar: false,
      search: '',
      headers: [
        {
          text: 'Categoría',
          align: 'left',
          sortable: true,
          value: 'nombre',
        },
        { text: 'Acciones', value: 'action', sortable: false, width:'35%', align:'center' },
      ],
      loading: false,
      tipos: [],
      delItem: '',
      action: 0,
      errores: [],
      editedIndex: -1,
      editedItem: {
        nombre: '',
      },
      defaultItem: {
        nombre: '',
      },
    }),

    computed: {
      formTitle () {
        return this.editedIndex === -1 ? 'Nuevo Desconocido' : 'Editar Categoría'
      }
    },

    watch: {
      dialog (val) {
        val || this.close()
      },
      idTipo(){
        this.tipo = 
        this.getTipos(this.idTipo);
      }
    },

    created () {
      this.initialize()
    },

    methods: {
      getDesconocidos(){
        let me = this;
        me.loadingTipos = true;
        axios
        .get("/Desconocidos/Desconocidos")
        .then(response =>  {
          me.tipos = response.data;
          me.loadingTipos = false;
          if(me.getTipos.length > 0){
            me.idTipo = me.tipos[0].idDesconocido;
          }
        })
        .catch(error => {
          console.log(error);
        });
      },

      getTipos(id){
        let me = this;
        
        let t = me.tipos.filter(function(element){
          return element.idDesconocido == id;
        });
        if(t.length > 0) me.campos = t[0].campos;
        else me.campos = [];

        me.loading = true;
        axios
        .get("/Desconocidos/DesconocidoInstanciasTipo/"+id)
        .then(response =>  {
          me.instancias = response.data;
          me.loading = false;
        })
        .catch(error => {
          console.log(error);
        });
      },
      initialize () {
        this.getDesconocidos();
      },

      editItem (item) {
        this.action = 1;
        this.editedIndex = this.instancias.indexOf(item)
        this.editedItem = Object.assign({}, item)
        this.dialog = true
      },

      deleteItem (item) {
        this.eliminar = true;
        this.delItem = item;
      },
      deleteItemDB(){
        let me = this;
        let item = this.delItem;
        this.delItem = {};
        axios
          .put("/Desconocidos/DesactivarDesconocidoInstancia/" + me.idTipo+"/"+item.idDesconocidoInstancia)
          .then(response =>  {
            me.tipos = [];
            me.instancias = [];
            me.getDesconocidos();
          })
          .catch(error => {
            console.log(error);
          });
      },
      close () {
        this.action = 0,
        this.dialog = false
        setTimeout(() => {
          this.editedItem = Object.assign({}, this.defaultItem)
          this.editedIndex = -1
        }, 300)
      },
      validate(){
        this.errores =  [];
        if (
          this.editedItem.nombre.length < 2 ||
          this.editedItem.nombre.length > 50
        )
        {
          this.errores.push(
            "El nombre debe tener al menos 2 caracteres y menos de 50."
          );
          return false;
        }
        return true;
      },
      save () {
        this.action = 0;
        if(this.validate()){
          let me = this;
          me.saving = true;
          if (this.editedIndex > -1)
          {
            //Código para editar
            axios
              .put("/Desconocidos/ActualizarDesconocidoInstancia/"+me.idTipo+"/"+me.instancias[me.editedIndex].idDesconocidoInstancia,me.editedItem)
              .then(response =>  {
                me.saving = false;
                me.close();
                me.getTipos(me.idTipo);
              })
              .catch(error => {
                me.saving = false;
                if (error.response) {
                  if(error.response.status == 409){
                    me.alerta = true;
                    me.errores.push("Existe una Categoría Activa con el Mismo Nombre");
                  }
                }
              });
          }
          else
          {
            axios
              .post("/Desconocidos/CrearDesconocidoInstancia/"+me.idTipo, me.editedItem)
              .then(response =>  {
                me.saving = false;
                me.close();
                me.getTipos(me.idTipo);
              })
              .catch(error => {
                me.saving = false;
                if (error.response) {
                  if(error.response.status == 409){
                    me.alerta = true;
                    me.errores.push("Existe una Categoría Activa con el Mismo Nombre");
                  }
                }
              });
          }
        }
      },
    },
  }
</script>