<template>
	<v-container>
		<v-dialog v-model="dialogDelete" persistent max-width="40%">
			<v-card>
				<v-card-text>
					<p class="text-h5 pt-2 font-weight-medium text--primary">
						¿Está seguro que desea eliminar este Correo electrónico?
					</p>
					<p class="subtitle-1">
						Esta acción no se puede revertir y será permanente.
					</p>
				</v-card-text>
				<v-card-actions>
					<v-spacer></v-spacer>
					<v-btn
						text
						color="success"
						:disabled="deleting"
						@click="cerrarDialog()"
						>Cancelar</v-btn
					>
					<v-btn
						text
						color="error"
						:disabled="deleting"
						:loading="deleting"
						@click="eliminarCorreo()"
						>Eliminar</v-btn
					>
				</v-card-actions>
			</v-card>
		</v-dialog>
		<v-dialog v-model="dialogCrear" persistent max-width="40%">
			<v-card>
				<v-card-title
					>{{
						itemCorreo.idInventarioCorreo <= 0 ? "Nuevo" : "Editar"
					}}
					Correo electrónico</v-card-title
				>
				<v-card-text>
					<v-col cols="12">
						<v-text-field
							v-model="itemCorreo.correo"
							label="Correo electrónico"
							:error-messages="errorCorreo"
							@focus="errorCorreo = ''"
							:rules="[rules.email]"
						></v-text-field>
					</v-col>
					<v-col cols="12">
						<v-select
							v-model="itemCorreo.tipos"
							:items="tiposCorreo"
							item-text="name"
							item-value="value"
							label="Tipo(s)"
							multiple
							chips
							deletable-chips
							:error-messages="errorTipos"
							@focus="errorTipos = ''"
						></v-select>
					</v-col>
				</v-card-text>
				<v-card-actions>
					<v-spacer></v-spacer>
					<v-btn
						text
						color="success"
						:disabled="saving"
						@click="cerrarDialog()"
						>Cancelar</v-btn
					>
					<v-btn
						text
						color="error"
						:disabled="saving"
						:loading="saving"
						@click="guardarCorreo()"
						>Guardar</v-btn
					>
				</v-card-actions>
			</v-card>
		</v-dialog>
		<v-row>
			<v-col cols="4" class="mt-0 pt-0">
				<v-btn color="primary" @click="showDialog"
					>Nuevo Correo Electrónico</v-btn
				>
			</v-col>
			<v-col cols="6" class="mt-0 pt-0 ml-auto">
				<v-text-field
					class="pt-2"
					v-model="search"
					append-icon="search"
					label="Buscar"
					single-line
					hide-details
				></v-text-field>
			</v-col>
		</v-row>
		<v-data-table
			:items="correos"
			:headers="headersCorreos"
			:loading="loading"
			:search="search"
		>
			<template v-slot:item.tipos="{ item }">
                <v-col class="d-flex ma-0 pa-0">
                    <v-chip-group column class="justify-center">
                        <v-chip v-for="tipo,idx in item.tipos" :key="`chip-${idx}`">
                            {{ getTipoName(tipo) }}
                        </v-chip>
                    </v-chip-group>
                </v-col>
			</template>
			<template v-slot:item.actions="{ item }">
				<v-tooltip
					
					bottom
					color="black"
					class="white--text"
				>
					<template v-slot:activator="{ on }">
						<v-icon
							v-on="on"
							small
							class="mr-2"
							@click="editDialog(item)"
						>
							edit
						</v-icon>
					</template>
					<span class="white--text">Editar</span>
				</v-tooltip>
				<v-tooltip
					
					bottom
					color="black"
					class="white--text"
				>
					<template v-slot:activator="{ on }">
						<v-icon
							v-on="on"
							small
							class="mr-2"
							@click="deleteDialog(item)"
						>
							delete
						</v-icon>
					</template>
					<span class="white--text">Eliminar</span>
				</v-tooltip>
			</template>
		</v-data-table>
		<v-snackbar v-model="alerta" top color="error">
			{{ errorAlerta }}
			<v-btn text @click="alerta = false"> Cerrar </v-btn>
		</v-snackbar>
	</v-container>
</template>

<script>
import axios from "axios";

export default {
	data() {
		return {
			tiposCorreo: [
				{
					value: 1,
					name: "Materia Prima",
				},
				{
					value: 2,
					name: "Ensambles",
				},
				{
					value: 3,
					name: "Artículos faltantes",
				},
			],
			errorAlerta: "",
			alerta: false,
			search: "",
			errorCorreo: "",
			errorTipos: "",
			dialogDelete: false,
			dialogCrear: false,
			itemCorreo: {
				idInventarioCorreo: -1,
				correo: "",
				tipos: [],
			},
			defaultItemCorreo: {
				idInventarioCorreo: -1,
				correo: "",
				tipos: [],
			},
			headersCorreos: [
				{
					text: "Correo electrónico",
					align: "center",
					sortable: true,
					value: "correo",
					width: "45%",
				},
				{
					text: "Tipo",
					align: "center",
					sortable: true,
					value: "tipos",
					width: "45%",
				},
				{
					text: "Acciones",
					value: "actions",
					sortable: false,
					width: "10%",
				},
			],
			correos: [],
			loading: false,
			saving: false,
			deleting: false,
			rules: {
				email: (value) => {
					const pattern =
						/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
					return (
						pattern.test(value) || "Correo electrónico inválido."
					);
				},
			},
		};
	},
	mounted() {
		this.initialize();
	},
	methods: {
        getTipoName(value){
            const res = this.tiposCorreo.find(tipo=>tipo.value==value);
            return res!=undefined
                ? res.name
                : 'N/A'
        },
		eliminarCorreo() {
			this.deleting = true;
			axios
				.put(
					`/Inventario/DesactivarCorreoInventario/${this.itemCorreo.idInventarioCorreo}`
				)
				.then(() => {
					this.deleting = false;
					this.cerrarDialog();
					this.getCorreos();
				})
				.catch((error) => {
					this.deleting = false;
					console.log(error);
				});
		},
		validate() {
			this.errorCorreo = "";
			this.errorTipos = "";

			if (
				this.itemCorreo.correo.length < 5 ||
				this.itemCorreo.correo.length > 120
			) {
				this.errorCorreo =
					"El correo electrónico debe tener más de 5 caracteres y menos de 120.";
			}
			if (this.itemCorreo.tipos.length == 0) {
				this.errorTipos = "Debe seleccionar al menos un tipo.";
			}

			return this.errorCorreo == "" && this.errorTipos == "";
		},
		guardarCorreo() {
			if (!this.validate()) return;
			this.saving = true;
			if (this.itemCorreo.idInventarioCorreo <= 0) {
				axios
					.post("/Inventario/RegistarCorreoInventario", {
						correo: this.itemCorreo.correo,
						tipos: this.itemCorreo.tipos,
					})
					.then(() => {
						this.saving = false;
						this.cerrarDialog();
						this.getCorreos();
					})
					.catch((error) => {
						this.saving = false;
						if (error.response) {
							if (error.response.status == 409) {
								this.alerta = true;
								this.errorAlerta =
									"Existe un Correo electrónico ACTIVO con el mismo nombre.";
							}
						}
					});
			} else {
				axios
					.put(
						`/Inventario/ActualizarCorreoInventario/${this.itemCorreo.idInventarioCorreo}`,
						{
							correo: this.itemCorreo.correo,
							tipos: this.itemCorreo.tipos,
						}
					)
					.then(() => {
						this.saving = false;
						this.cerrarDialog();
						this.getCorreos();
					})
					.catch((error) => {
						this.saving = false;
						if (error.response) {
							if (error.response.status == 409) {
								this.alerta = true;
								this.errorAlerta =
									"Existe un Correo electrónico ACTIVO con el mismo nombre.";
							}
						}
					});
			}
		},
		initialize() {
			this.getCorreos();
		},
		editDialog(item) {
			this.itemCorreo = Object.assign({}, item);
			this.dialogCrear = true;
		},
		deleteDialog(item) {
			this.itemCorreo = Object.assign({}, item);
			this.dialogDelete = true;
		},
		showDialog() {
			this.dialogCrear = true;
		},
		cerrarDialog() {
			this.errorCorreo = "";
			this.errorTipos = "";
			this.itemCorreo = Object.assign({}, this.defaultItemCorreo);
			this.dialogDelete = false;
			this.dialogCrear = false;
		},
		getCorreos() {
			axios
				.get("/Inventario/GetInventarioCorreos")
				.then((response) => {
					this.correos = response.data;
				})
				.catch((error) => {
					console.log(error);
				});
		},
	},
};
</script>