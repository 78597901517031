<template>
  <v-container fluid>
    <v-dialog
      v-model="reportDialog"
      max-width="1300px"
      persistent
    >
      <v-card>
        <v-card-title>
          Reportar Mantenimiento - {{reportedItem.codigo}}
        </v-card-title>
        <v-card-text>
          <v-row>
            <v-col cols="6">
              <v-simple-table>
                <template v-slot:default>
                  <tbody>
                    <tr class="text-center">
                      <td class="font-weight-bold">Código</td>
                      <td>{{reportedItem.codigo}}</td>
                    </tr>
                    <tr class="text-center">
                      <td class="font-weight-bold">Máquina</td>
                      <td>{{reportedItem.maquina}}</td>
                    </tr>
                    <tr class="text-center">
                      <td class="font-weight-bold"># Cavidades</td>
                      <td>{{reportedItem.numCavidades}}</td>
                    </tr>
                    <tr class="text-center">
                      <td class="font-weight-bold"># Cavidades Activas</td>
                      <td>{{reportedItem.cavidadesActivas}}</td>
                    </tr>
                  </tbody>
                </template>
              </v-simple-table>
            </v-col>
            <v-col cols="6" class="text-body-2">
              <v-simple-table>
                <template v-slot:default>
                  <tbody>
                    <tr class="text-center">
                      <td class="font-weight-bold"># Disparos Totales</td>
                      <td>{{reportedItem.numDisparos}}</td>
                    </tr>
                    <tr class="text-center">
                      <td class="font-weight-bold"># Disparos Ultimo Mantenimiento</td>
                      <td>{{reportedItem.numDisparosUltimoMant}}</td>
                    </tr>
                    <tr class="text-center">
                      <td class="font-weight-bold">Fecha Ultimo Mantenimiento</td>
                      <td>{{reportedItem.fechaUltimoMant}}</td>
                    </tr>
                  </tbody>
                </template>
              </v-simple-table>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12">
              <VueCtkDateTimePicker
                v-model="reportedItem.fechaMantenimiento"
                :min-date="reportedItem.fechaUltimoMant"
                :max-date="now()"
                format="YYYY-MM-DD HH:mm"
                formatted="YYYY-MM-DD HH:mm"
                noHeader
                overlay
                noButtonNow
                :key="`ctki-${index}`"
                buttonNowTranslation="Ahora"
                label="Fecha de Mantenimiento"
                noClearButton
                :disabled="saving"
                :error="errorFecha != null"
                @input="errorFecha = null"
              />
              <v-slide-y-transition>
                <p v-if="errorFecha" class="mb-0 error--text">{{ errorFecha }}</p>
              </v-slide-y-transition>
            </v-col>
            <v-col cols="12">
              <v-data-table
                :items="reportedItem.cavidades"
                :headers="reportHeaders"
                :items-per-page="-1"
                hide-default-footer
              >
                <template v-slot:[`item.activo`]="{ item }">
                  <v-switch :disabled="saving" dense v-model="item.activo"></v-switch>
                </template>
              </v-data-table>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="green" :disabled="saving" text @click="closeReport">Cancelar</v-btn>
          <v-btn color="red" :disabled="saving" :loading="saving" text @click="saveReport">Guardar</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-row>
      <v-col cols="4"
        ><v-autocomplete
          v-model="parametros.idArticulo"
          :items="articulos"
          item-value="idArticulo"
          :item-text="nombreArticulo"
          :loading="loadingArticulos"
          label="Articulo"
          @change="getMoldes"
          :disabled="loadingMoldes"
          clearable
        ></v-autocomplete
      ></v-col>
      <v-col cols="4"
        ><v-autocomplete
          v-model="parametros.idMaquina"
          label="Máquina"
          :items="maquinas"
          item-value="idMaquina"
          :item-text="nombreMaquina"
          @change="getMoldes"
          :disabled="loadingMoldes"
          clearable
        ></v-autocomplete
      ></v-col>
      <v-col cols="4">
        <v-text-field
          v-model="parametros.codigo"
          label="Código de Molde"
          :disabled="loadingMoldes"
          clearable
        ></v-text-field>
      </v-col>
      <v-col cols="12">
        <v-data-table
          :headers="headers"
          :items="moldes"
          :loading="loadingMoldes"
          :search="parametros.codigo"
        >
          <template v-slot:[`item.imagen`]="{ item }">
            <template v-if="item.imagen != null">
              <Thumbnail
                :lazy="false"
                :lazyUrl="''"
                :url="item.imagen"
              ></Thumbnail>
            </template>
            <template v-else>N/A</template>
          </template>
          <template v-slot:[`item.articulos`]="{ item }">
            <template v-if="item.articulos.length > 0">
              <p class="my-1" v-for="articulo,index in item.articulos" :key="`articulo-${index}`">
                {{ item.articulos.length > 1 ? '•' : '' }} {{ articulo }}
              </p>
            </template>
            <template v-else>N/A</template>
          </template>
          <template v-slot:[`item.actions`]="{ item }">
            <v-tooltip bottom color="black" class="white--text">
							<template v-slot:activator="{ on }">
								<v-icon
									v-on="on"
									small
                  :disabled="loadingMoldes"
									@click="reportItem(item)"
								>
									mdi-newspaper-variant-outline
								</v-icon>
							</template>
							<span class="white--text">Reportar Mantenimiento</span>
						</v-tooltip>
          </template>
          <template v-slot:no-data>
            <v-btn color="primary" @click="initialize">
              Recargar
            </v-btn>
          </template>
        </v-data-table>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import axios from "axios";
import Thumbnail from "../../Produccion/ThumbnailMoldes.vue";
import moment from 'moment';
export default {
  components: {
    Thumbnail,
  },
  data: () => ({
    loadingMoldes: false,
    moldes: [],
    loadingAreas: false,
    areas: [],
    loadingArticulos: false,
    articulos: [],
    loadingMaquinas: false,
    maquinas: [],
    parametros: {
      idArticulo: null,
      idMaquina: null,
      codigo: null,
    },
    headers: [
      {
        text: "Imagen",
        align: "center",
        sortable: false,
        value: "imagen",
      },
      { text: "Código del Molde", value: "codigo", align: 'center' },
      { text: "Máquina", value: "maquina", align: 'center' },
      { text: "Cavidades", value: "articulos", align: "center", width: "40%" },
      { text: "# Disparos Totales", value: "numDisparos", align: 'center' },
      { text: "# Disparos Ultimo Mantenimiento ", value: "numDisparosUltimoMant", align: 'center' },
      { text: "Fecha Ultimo Mantenimiento ", value: "fechaUltimoMant", align: 'center' },
      { text: "Acciones", value: "actions", sortable: false, align: "center" },
    ],
    defaultItem: {
      idMolde: -1,
      codigo: null,
      numCavidades: -1,
      articulo: null,
      maquina: null,
      imagen: null,
      cavidades: [],
      pasos: [],
      idArticulo: -1,
      idMaquina: -1,
      cavidadesActivas: -1,
      numDisparos: -1,
      numDisparosUltimoMant: -1,
      idsPasos: [],
      fechaUltimoMant: null,
      fechaMantenimiento: null
    },
    reportHeaders:[
      {
        text: "Código de la Cavidad",
        align: "center",
        sortable: false,
        value: "clave",
      },
      {
        text: "Artículo",
        align: "center",
        sortable: false,
        value: "nombre",
      },
      {
        text: "# Total de Disparos",
        align: "center",
        sortable: false,
        value: "numDisparos",
      },
      {
        text: "# Disparos desde Ultimo Mantenimiento",
        align: "center",
        sortable: false,
        value: "numDisparosUltimoMant",
      },
      {
        text: "Estado",
        align: "center",
        sortable: false,
        value: "activo",
      }
    ],
    reportDialog: false,
    errorFecha: null,
    saving: false,
    reportedItem: {
      idMolde: -1,
      codigo: null,
      numCavidades: -1,
      articulo: null,
      maquina: null,
      imagen: null,
      cavidades: [],
      pasos: [],
      idArticulo: -1,
      idMaquina: -1,
      cavidadesActivas: -1,
      numDisparos: -1,
      numDisparosUltimoMant: -1,
      idsPasos: [],
      fechaUltimoMant: null,
      fechaMantenimiento: null
    }
  }),
  mounted() {
    this.initialize();
  },
  methods: {
    now(){
      return moment().subtract(1, "minutes").format("YYYY-MM-DD HH:mm");
    },
    nombreArticulo: (value) => value.codigo != null ? `[${value.codigo}] - ${value.nombre}` : `${value.nombre}`,
    nombreMaquina: (maquina) => `${maquina.marca} ${maquina.modelo}`,
    initialize(){
      this.getMoldes();
      this.getMaquinas();
      this.getArticulos();
      this.getAreas();
    },
    getMoldes() {
      this.loadingMoldes = true;
      axios
        .get("/Moldes", {
          params: {
            idArticulo: this.parametros.idArticulo,
            idMaquina: this.parametros.idMaquina,
            codigo: this.parametros.codigo,
          },
        })
        .then((response) => {
          this.moldes = response.data;
          this.loadingMoldes = false;
        })
        .catch((error) => {
          console.log(error);
          this.moldes = [];
          this.loadingMoldes = false;
        });
    },
    getAreas() {
      this.loadingAreas = true;
      axios.get("/Areas").then((response) => {
        this.loadingAreas = false;
        this.areas = response.data.filter((a) => a.idArea > 1);
      });
    },
    getArticulos() {
      this.loadingArticulos = true;
      axios
        .get("/Articulos/ArticulosNombres?componentes=true")
        .then((response) => {
          this.articulos = response.data;
          this.loadingArticulos = false;
        })
        .catch((error) => {
          this.loadingArticulos = false;
          console.log(error);
        });
    },
    getMaquinas() {
      this.loadingMaquinas = true;
      axios
        .get("/Maquinas")
        .then((response) => {
          this.maquinas = response.data;
          this.loadingMaquinas = false;
        })
        .catch((error) => {
          console.log(error);
          this.loadingMaquinas = false;
        });
    },
    reportItem(item){
      this.reportedItem = structuredClone(item);

      this.reportDialog = true;
    },
    closeReport(){
      this.reportDialog = false;

      setTimeout(() => {
        this.reportedItem = structuredClone(this.defaultItem);
        this.reportedItem.now = moment().subtract(1, "minutes").format("YYYY-MM-DD HH:mm");
        this.errorFecha = null;
      }, 200);
    },
    validateReport(){
      this.errorFecha = null;

      if(!this.reportedItem.fechaMantenimiento){
        this.errorFecha = "Requerido";
      }

      return this.errorFecha == null;
    },
    saveReport(){
      if(!this.validateReport()) return;

      this.saving = true;

      var payload = {
        idMolde: this.reportedItem.idMolde,
        fecha: this.reportedItem.fechaMantenimiento,
        cavidades: this.reportedItem.cavidades.map(c => ({
          IdMoldeCavidad: c.idMoldeCavidad,
          Activa: c.activo
        }))
      }

      axios
        .post('/Moldes/Mantenimiento',payload)
        .then(() => {
          this.saving = false;
          this.getMoldes();
          this.closeReport();
        })
        .catch(error => {
          this.saving = false;
          console.log(error);
          this.errorFecha = "Ocurrio un error, intentelo nuevamente";
        });
    },
  },
};
</script>
