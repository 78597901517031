export default {
  primary: '#4caf50',
  secondary: '#4caf50',
  tertiary: '#495057',
  accent: '#82B1FF',
  error: '#f55a4e',
  info: '#00d3ee',
  success: '#5cb860',
  warning: '#ffa21a',
  general: '#2196F3',
  cards: '#385F73',
  cardtitlecolor: '#0D47A1'
}