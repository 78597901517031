<template>
  <v-col class="padding-2px">
    <div class="board-column">
      <draggable
          :list="list"
          v-bind="$attrs"
          class="board-column-content"
          @add="repaint"
          @update="repaint"
          @change="change"
          :set-data="setData"
      >
        <div v-for="item in list" :key="item.id" class="board-item">
          <v-tooltip
            bottom
            dark
            color="black"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-row
                v-bind="attrs"
                v-on="on"
              >
                <v-col cols="5" sm="5" md="5" class="align-self-center">
                  <template v-if="item.idArticuloTipo == 1 && replace">
                    <v-row>
                      <v-col cols="1" sm="1" md="1" class="align-self-center ml-1">
                        <v-checkbox
                          v-model="item.remplazar"
                          v-tooltip.bottom="'¿Intercambiable?'"
                          dense
                          hide-details
                          class="pa-0 ma-0"
                        ></v-checkbox>
                      </v-col>
                      <v-col class="align-self-center">
                        {{ itemText(item) }}
                      </v-col>
                    </v-row>
                  </template>
                  <template v-else-if="$utils.isValid(item.idMaquina)">
                    <v-row>
                      <v-col cols="1" sm="1" md="1" class="align-self-center ml-1">
                        <v-checkbox
                          v-model="item.remplazarMaquina"
                          v-tooltip.bottom="'¿Máquina Intercambiable?'"
                          dense
                          hide-details
                          class="pa-0 ma-0"
                        ></v-checkbox>
                      </v-col>
                      <v-col class="align-self-center">
                        {{ itemText(item) }}
                      </v-col>
                    </v-row>
                  </template>
                  <template v-else>
                    {{ itemText(item) }}
                  </template>
                  
                </v-col>
                <v-col class="align-self-center" v-if="(typeof item.idPuesto !== 'undefined')" cols="5" sm="5" md="5">
                  <v-row>
                    <v-col cols="6" sm="6" md="6" class="text-center align-self-center">
                      <span>Emp</span>
                      <v-text-field
                        v-model="item.cantidad"
                        type="number"
                        min="1"
                        label="Número"
                        single-line
                        dense
                        hide-details
                        :error="validarCantidad(item.cantidad)"
                        :class="validarCantidad(item.cantidad)?' v-input--is-focused':''"
                        @keyup="cambioValor"
                       
                      ></v-text-field>
                    </v-col>
                    <v-col cols="6" sm="6" md="6" class="text-center align-self-center">
                      <span style="font-size: 10px;">HH:MM:SS</span>
                      <time-picker
                        v-model="item.tiempo"
                        dense
                        :error="errorTiempo(item)"
                        label="Tiempo Máximo (HH:MM:SS)"
                        @input="cambioValor"
                      ></time-picker>
                    </v-col>
                  </v-row>
                </v-col>
                <v-col class="align-self-center" v-if="(typeof item.idDesconocido !== 'undefined' && item.tipoCosto)" cols="5" sm="5" md="5">
                  <v-row>
                    <v-col cols="8" sm="8" md="8">
                      <v-text-field
                        v-model="item.cantidad"
                        type="number"
                        min="1"
                        label="Cantidad"
                        single-line
                        dense
                        hide-details
                        :error="validarCantidad(item.cantidad)"
                        :class="validarCantidad(item.cantidad)?' v-input--is-focused':''"
                        @keyup="cambioValor"
                       
                      ></v-text-field>
                    </v-col>
                    <v-col cols="4" sm="4" md="4">
                      X
                    </v-col>
                  </v-row>
                </v-col>
                <v-col class="align-self-center" v-if="(typeof item.idDesconocido !== 'undefined' && !item.tipoCosto)" cols="5" sm="5" md="5">
                  <v-row>
                    <v-col cols="6" sm="6" md="6" class="align-self-center">
                      X
                      <v-text-field
                        v-model="item.cantidad"
                        type="number"
                        min="1"
                        label="Número"
                        single-line
                        dense
                        hide-details
                        :error="validarCantidad(item.cantidad)"
                        :class="validarCantidad(item.cantidad)?' v-input--is-focused':''"
                        @keyup="cambioValor"
                       
                      ></v-text-field>
                    </v-col>
                    <v-col cols="6" sm="6" md="6" class="text-center align-self-center">
                      <span style="font-size: 10px;">HH:MM:SS</span>
                      <time-picker
                        v-model="item.tiempo"
                        dense
                        :error="errorTiempo(item)"
                        label="Tiempo Máximo (HH:MM:SS)"
                        @input="cambioValor"
                      ></time-picker>
                    </v-col>
                  </v-row>
                </v-col>
                <v-col class="align-self-center" v-if="(typeof item.marca === 'undefined' && typeof item.idDesconocido === 'undefined' && typeof item.idPuesto === 'undefined')" :cols="item.idArticuloTipo != null ? 3 : 5">
                  <v-row v-if="!item.conversion || (item.conversion && item.numDimensiones == 1)">
                    <v-col cols="12" sm="12" md="12">
                      <v-text-field
                        v-model="item.cantidad"
                        type="number"
                        min="1"
                        label="Cantidad"
                        single-line
                        dense
                        hide-details
                        :error="validarCantidad(item.cantidad)"
                        :class="validarCantidad(item.cantidad)?' v-input--is-focused':''"
                        :suffix="(nameBussines == 'MYC' && item.receta== true ? item.unidad: (item.conversion?item.unidadAuxiliar:item.unidad))"
                        @keyup="cambioValor"
                      ></v-text-field>
                    </v-col>
                  </v-row>
                  <v-row v-else>
                    <v-col :cols="columnasDimensiones">
                      <v-text-field
                        v-model="item.cantidad"
                        type="number"
                        min="1"
                        label="Cantidad"
                        single-line
                        dense
                        hide-details
                        :error="validarCantidad(item.cantidad)"
                        :class="validarCantidad(item.cantidad)?' v-input--is-focused':''"
                        suffix="Pza"
                        @keyup="cambioValor"
                      ></v-text-field>
                    </v-col>
                    <v-col :cols="columnasDimensiones" :class="{ 'pt-0': islgAndDown }">
                      <v-text-field
                        v-model="item._ejeX"
                        type="number"
                        min="0"
                        dense
                        hide-details
                        :error="validarCantidad(item._ejeX)"
                        :class="validarCantidad(item._ejeX)?' v-input--is-focused':''"
                        :label="'Largo ('+item.unidadBase+')'"
                        @keyup="cambioValor"
                      ></v-text-field>
                    </v-col>
                    <v-col v-if="item.numDimensiones >= 2" :cols="columnasDimensiones" :class="{ 'pt-0': islgAndDown }">
                      <v-text-field
                        v-model="item._ejeY"
                        type="number"
                        min="0"
                        dense
                        hide-details
                        :error="validarCantidad(item._ejeY)"
                        :class="validarCantidad(item._ejeY)?' v-input--is-focused':''"
                        :label="'Ancho ('+item.unidadBase+')'"
                        @keyup="cambioValor"
                      ></v-text-field>
                    </v-col>
                    <v-col v-if="item.numDimensiones == 3" :cols="columnasDimensiones" :class="{ 'pt-0': islgAndDown }">
                      <v-text-field
                        v-model="item._ejeZ"
                        type="number"
                        min="0"
                        dense
                        hide-details
                        :error="validarCantidad(item._ejeZ)"
                        :class="validarCantidad(item._ejeZ)?' v-input--is-focused':''"
                        label="Alto"
                        @keyup="cambioValor"
                      ></v-text-field>
                    </v-col>
                  </v-row>
                </v-col>
                <v-col class="align-self-center" v-if="(typeof item.marca !== 'undefined')" cols="5" sm="5" md="5">
                  <v-row>
                    <v-col cols="6" sm="6" md="6" class="text-center align-self-center">
                      <span style="font-size: 10px">U</span>
                      <v-text-field
                        v-model="item.cantidad"
                        type="number"
                        min="1"
                        label="Cantidad"
                        single-line
                        dense
                        hide-details
                        :error="validarCantidad(item.cantidad)"
                        :class="validarCantidad(item.cantidad)?' v-input--is-focused':''"
                        @keyup="cambioValor"
                       
                      ></v-text-field>
                    </v-col>
                    <v-col cols="6" sm="6" md="6" class="text-center align-self-center">
                      <span style="font-size: 10px;">HH:MM:SS</span>
                      <time-picker
                        v-model="item.tiempo"
                        dense
                        :error="errorTiempo(item)"
                        label="Tiempo Máximo (HH:MM:SS)"
                        @input="cambioValor"
                      ></time-picker>
                    </v-col>
                  </v-row>
                </v-col>
                <v-col v-if="item.idArticuloTipo != null" cols="2" class="d-flex align-center">
                  <v-row>
                    <v-col cols="12">
                      <p class="mb-0 text-center" style="font-size: 12px !important">% Merma</p>
                      <v-text-field
                        v-model="item.merma"
                        type="number"
                        label="Porcentaje"
                        min="1"
                        max="100"
                        single-line
                        class="mt-0"
                        dense
                        hide-spin-buttons
                        hide-details
                        clearable
                      ></v-text-field>
                    </v-col>
                  </v-row>
                </v-col>

                <v-col cols="2" class="pl-0 text-center d-flex justify-end align-center">
                  <v-checkbox
                    v-if="$utils.isValid(item.idArticulo)"
                    v-model="item.critico"
                    color="red darken-2"
                    v-tooltip.bottom="'¿Crítico?'"
                    dense
                    hide-details
                    class="pa-0 ma-0 px-2"
                  ></v-checkbox>
                  <v-icon
                    size="24px"
                    @click="itemDelete(item)"
                  >
                    mdi-close-circle-outline
                  </v-icon>
                </v-col>
              </v-row>
            </template>
            <div class="white--text">
              <div v-if="price">
                Costo: {{costoElemento(item)}}
              </div>
              <div v-else>
                Costo: $0.00
              </div>
              <div v-if="$utils.isValid(item.nota)">
                Nota: {{item.nota}}
              </div>
            </div>
          </v-tooltip>
          </div>
      </draggable>
    </div>
  </v-col>
</template>

<script>
import draggable from 'vuedraggable'
import timepicker from '@/components/TimePicker';

export default {
  name: 'DragBox2',
  components: {
    draggable,
    'time-picker':timepicker,
  },
  data: () => ({
      search:'',
  }),
  props: {
    price: {
      type: Boolean,
      default: true,
    },
    index: {
      type: Number,
      default: 1,
    },
    headerText: {
      type: String,
      default: 'Header'
    },
    options: {
      type: Object,
      default() {
        return {}
      }
    },
    itemText:{
        type:Function,
        default: item => item.name,
    },
    inputColor: {
        type:String,
        default:''
    },
    backgroundColor:{
        type:String,
        default: ''
    },
    list: {
      type: Array,
      default() {
        return []
      }
    },
    ignore: {
      type: Number,
      default: -1,
    },
    maxInt: {
      type: Number,
      default: Infinity,
    },
    replace:{
      type: Boolean,
      default: false,
    },
    nameBussines:{
        type:String,
        default: ''
    },
  },
  computed: {
  },
  methods: {
    validarCantidad(cantidad){
      return (parseFloat(cantidad) <= 0 || isNaN(parseFloat(cantidad)) );
    },
    errorTiempo(item){
      return (item.tiempo > this.maxInt)
    },
    change(value){
      let me = this;
      let flag = false;
      let remove = -1;
      let pos = -1;
      this.list.forEach(function(element){
        pos++;

        if(typeof element.idArticulo !== 'undefined' && element.idArticulo == me.ignore){
          remove = pos;
        }

        if(typeof element._ejeX === 'undefined' && element.conversion){
          element._ejeX = "";
          element._ejeY = "";
          element._ejeZ = "";
          flag = true;
        }

        if(typeof element.cantidad === 'undefined'){
          element.cantidad = 1;
          flag = true;
        }
        if(typeof element.idMaquina !== 'undefined' && typeof element.tiempo === 'undefined'){
          element.tiempo = 1;
          flag = true;
        }
        if(typeof element.idPuesto !== 'undefined' && typeof element.tiempo === 'undefined'){
          element.tiempo = 1;
          flag = true;
        }
        if(typeof element.idDesconocido !== 'undefined' && !element.tipoCosto && typeof element.tiempo === 'undefined'){
          element.tiempo = 1;
          flag = true;
        }
      });

      if(remove != -1) this.$emit('update', this.list.splice(pos, 1));

      else if(flag) this.$emit('update', this.list.splice(0, 0));

      else return;
    },
    repaint(value){
      this.$emit('repaint', value);
    },
    setData(dataTransfer) {
      dataTransfer.setData('Text', '')
    },
    itemDelete(item){
      item.cantidad = "";
      item._ejeX = "";
      item._ejeY = "";
      item._ejeZ = "";
      let index = this.list.indexOf(item);
      this.$emit('repaint', this.list.splice(index, 1));
    },
    cambioValor(value){
      this.$emit('update', this.list.splice(0, 0));
    },
    calcularFraccion(num,x,y,z){
      if(num == 1){
        return this.$utils.numbers.validFloat(x);
      }
      if(num == 2){
        return this.$utils.numbers.validFloat(x) * this.$utils.numbers.validFloat(y);
      }
      if(num == 3){
        return this.$utils.numbers.validFloat(x) * this.$utils.numbers.validFloat(y) * this.$utils.numbers.validFloat(z);
      }
    },
    costoElemento(element){
      var costoMin = 0.0;
      var costoMax = 0.0;
      if(typeof element.idDesconocido === 'undefined' && typeof element.idArticulo !== 'undefined'){
        if(element.costo == null) {
          return;
        }
        if(!element.conversion){
          costoMin += element.costo.min * this.$utils.numbers.validFloat(element.cantidad) * (1 + (this.$utils.numbers.validFloat(element.merma)/100));
          costoMax += element.costo.max * this.$utils.numbers.validFloat(element.cantidad) * (1 + (this.$utils.numbers.validFloat(element.merma)/100));
        }
        else{
          switch(element.numDimensiones){
            case 1:
              costoMin += (this.$utils.numbers.validFloat(element.cantidad) / element.factorConversion) * element.costo.min * (1 + (this.$utils.numbers.validFloat(element.merma)/100));
              costoMax += (this.$utils.numbers.validFloat(element.cantidad) / element.factorConversion) * element.costo.max * (1 + (this.$utils.numbers.validFloat(element.merma)/100));
              break;
            case 2:
              costoMin += ( this.calcularFraccion(element.numDimensiones,element._ejeX,element._ejeY,element._ejeZ) / element.factorConversion) * element.costo.min * this.$utils.numbers.validFloat(element.cantidad) * (1 + (this.$utils.numbers.validFloat(element.merma)/100));
              costoMax += ( this.calcularFraccion(element.numDimensiones,element._ejeX,element._ejeY,element._ejeZ) / element.factorConversion) * element.costo.max * this.$utils.numbers.validFloat(element.cantidad) * (1 + (this.$utils.numbers.validFloat(element.merma)/100));
              
              break;
            case 3:
              costoMin += ( this.calcularFraccion(element.numDimensiones,element._ejeX,element._ejeY,element._ejeZ) / element.factorConversion) * element.costo.min * this.$utils.numbers.validFloat(element.cantidad) * (1 + (this.$utils.numbers.validFloat(element.merma)/100));
              costoMax += ( this.calcularFraccion(element.numDimensiones,element._ejeX,element._ejeY,element._ejeZ) / element.factorConversion) * element.costo.max * this.$utils.numbers.validFloat(element.cantidad) * (1 + (this.$utils.numbers.validFloat(element.merma)/100));
              break;
          }
        }
      }
      else if(typeof element.idDesconocido === 'undefined' && typeof element.costo !== 'undefined'){
        costoMin += element.costo * this.$utils.numbers.validFloat(element.cantidad);
        costoMax += element.costo * this.$utils.numbers.validFloat(element.cantidad);
      }
      else if(typeof element.idDesconocido === 'undefined' && typeof element.costoHora !== 'undefined'){
        costoMin += element.cantidad * (element.costoHora / 3600) * element.tiempo;
        costoMax += element.cantidad * (element.costoHora / 3600) * element.tiempo;
      }
      else if(typeof element.idDesconocido !== 'undefined'){
        if(element.tipoCosto){
          costoMin += ( element.cantidad * element.costo );
          costoMax += ( element.cantidad * element.costo );
        }
        else{
          costoMin += (element.tiempo / 3600.0) * element.costo * element.cantidad;
          costoMax += (element.tiempo / 3600.0) * element.costo * element.cantidad;
        }
      }

      if(costoMin == costoMax) return "$"+costoMin.toFixed(2); 
      else return "$"+costoMin.toFixed(2)+" ~ "+"$"+costoMax.toFixed(2);
    }
  },
  computed: {
    islgAndDown(){
      return this.$vuetify.breakpoint.mdAndDown;
    },
    columnasDimensiones(){
      return this.islgAndDown ? 12 : 6;
    },
    columnClass(){
        return 'board-column-header '+this.backgroundColor;
    }
  }
}
</script>
<style lang="scss" scoped>
.board-column {
      //min-width: 300px;
      min-height: 100px !important;
      overflow: hidden !important;
      background: #f0f0f0 !important;
      border-radius: 3px !important;
    
    .board-column-header {
      font-size: 1.25em !important; 
      height: 50px !important;
      line-height: 50px !important;
      overflow: hidden !important;
      padding: 0 20px !important;
      text-align: center !important;
      color: #fff !important;
      border-radius: 3px 3px 0 0 !important;
    }

  .board-column-content {
    max-height: 350px;
    overflow-y: scroll !important;
    overflow-x: hidden !important;
    border: 10px solid transparent !important;
    min-height: 60px !important;
    display: flex !important;
    justify-content: flex-start !important;
    flex-direction: column !important;
    align-items: center !important;

    .board-item {
      cursor: pointer !important;
      width: 99% !important;
      //height: 64px;
      margin: 5px 0 !important;
      background-color: #fff !important;
      text-align: left !important;
      //line-height: 54px;
      padding: 5px 10px !important;
      box-sizing: border-box !important;
      box-shadow: 0px 1px 3px 0 rgba(0, 0, 0, 0.2) !important;
    }
  } 
}
.padding-2px{
    padding: 2px !important;
}

.padding-0px{
  padding-top: 0px !important;
}

.no-padding{
    padding-top: 0px !important;
    padding-bottom: 0px !important;
    margin-top: auto;
    margin-bottom: auto;
}
</style>

