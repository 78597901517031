<template>
	<div>
		<v-tabs v-model="tab" background-color="transparent" grow>
			<!--
			<v-tab
				href="#tab-1"
				v-tooltip.bottom="modoAyuda && $help.almacen.cargasMasivas.movimientosInventario"
				v-if="permisoVista('cargasmasivassae', 'u')"
				@click="resetSAE()"
			>
				Actualización de<br />
				Movimientos de Inventario
			</v-tab>

			<v-tab
				href="#tab-2"
				v-tooltip.bottom="modoAyuda && $help.almacen.cargasMasivas.actualizacionArticulos"
				v-if="permisoVista('articulos', 'u') && cargasMasivas"
				@click="resetActualizacionArticulos()"
			>
				Actualización Masiva<br />
				de Artículos
			</v-tab>
			-->

			<v-tab
				href="#tab-7"
				v-tooltip.bottom="modoAyuda && $help.almacen.cargasMasivas.actualizacionArticulos"
				v-if="permisoVista('articulos', 'u') && !trazabilidadInventario"
				@click="resetActualizacionArticulos()"
			>
				Carga Masiva<br />
				de Movimientos de Entrada
			</v-tab>

			<v-tab
				href="#tab-8"
				v-tooltip.bottom="modoAyuda && $help.almacen.cargasMasivas.actualizacionArticulos"
				v-if="permisoVista('articulos', 'u') && !trazabilidadInventario"
				@click="resetCargaSalidaSinQR()"
			>
				Carga Masiva<br /> 
				de Movimientos de Salida
			</v-tab>

			<v-tab
				href="#tab-3" 
				v-tooltip.bottom="modoAyuda && $help.almacen.cargasMasivas.cargaArticulos" 
				v-if="permisoVista('articulos', 'u') && cargasMasivas"
				@click="resetCargarArticulos()"
			>
				Carga Masiva<br />
				de Artículos
			</v-tab>

			<v-tab
				href="#tab-4" 
				v-tooltip.bottom="modoAyuda && $help.almacen.cargasMasivas.cargaControlInventario" 
				v-if="permisoVista('articulos', 'u') && cargasMasivas"
				@click="resetCargarControl()"
			>
				Carga Masiva de<br />
				Control de Inventario
			</v-tab>

			<v-tab
				href="#tab-5" 
				v-if="permisoVista('articulos', 'u') && cargasMasivas"
				@click="resetCargaPrioridadArticulos()"
			>
				Carga Masiva de<br />
				Prioridad de Artículos
			</v-tab>

			<v-tab
				href="#tab-6"
				v-tooltip.bottom="modoAyuda && $help.almacen.cargasMasivas.cargaPantografo"
				v-if="pantografo"
				@click="resetCargaPantografo()"
			>
				Carga Masiva de<br />
				Pantógrafo
			</v-tab>

			<v-tabs-items v-model="tab" touchless>
				<v-tab-item :key="1" :value="'tab-1'"  eager>
					<ConfiguracionCargasMasivasSAE ref="cargasae" />
				</v-tab-item>
				<v-tab-item :key="2" :value="'tab-2'"  eager>
					<ActualizacionArticulos ref="upart" />
				</v-tab-item>
				<v-tab-item :key="3" :value="'tab-3'"  eager>
					<CargaArticulos ref="carart" />
				</v-tab-item>
				<v-tab-item :key="4" :value="'tab-4'"  eager>
					<CargaControlInventario ref="carinv" />
				</v-tab-item>
				<v-tab-item :key="5" :value="'tab-5'"  eager>
					<CargaPrioridadArticulos ref="carprior" />
				</v-tab-item>
				<v-tab-item :key="6" :value="'tab-6'" >
					<CargaPantografo ref="carpant" />
				</v-tab-item>
				<v-tab-item :key="7" :value="'tab-7'" >
					<CargaMovimientoEntradaSinQR ref="carentsinqr" />
				</v-tab-item>
				<v-tab-item :key="8" :value="'tab-8'" >
					<SalidaCargaMasivaSinQR ref="carentsaldiasinqr" />
				</v-tab-item>
			</v-tabs-items>
		</v-tabs>
	</div>
</template>

<script>
import { mapState } from "vuex";
import CargasMasivasSAE from "../Configuracion/CargasMasivasSAE";
import ActualizacionArticulos from "./CargasMasivas/ActualizacionArticulos.vue";
import CargaArticulos from './CargasMasivas/CargaArticulos.vue';
import CargaControlInventario from './CargasMasivas/CargaControlInventario.vue';
import CargaPantografo from './CargasMasivas/CargaPantografo.vue';
import CargaMovimientoEntradaSinQR from './CargasMasivas/CargaMovimientoEntradaSinQR.vue'
import CargaPrioridadArticulos from './CargasMasivas/CargaPrioridadArticulos.vue';
import SalidaCargaMasivaSinQR from './CargasMasivas/SalidaCargaMasivaSinQR.vue';

export default {
	components: {
		ConfiguracionCargasMasivasSAE: CargasMasivasSAE,
		ActualizacionArticulos,
		CargaArticulos,
		CargaControlInventario,
		CargaPantografo,
		CargaMovimientoEntradaSinQR,
		CargaPrioridadArticulos,
		SalidaCargaMasivaSinQR
	},
	data: () => ({
		tab: 0,
	}),
	computed: {
		...mapState({ 
			cargasMasivas: "cargasMasivas", 
			pantografo: "pantografo",
			modoAyuda: "modoAyuda",
			trazabilidadInventario: "trazabilidadInventario"
		}),
	},
	methods: {
		initialize() {
			this.resetActualizacionArticulos();
			this.resetCargarArticulos();
			this.resetCargarControl();
			this.resetSAE();
			this.resetCargaPrioridadArticulos();
		},
		resetCargaPrioridadArticulos(){
			if (typeof this.$refs.carprior === "undefined") return;
			this.$refs.carprior.initialize();
		},
		resetSAE(){
			if (typeof this.$refs.cargasae === "undefined") return;
			this.$refs.cargasae.initialize();
		},
		resetActualizacionArticulos() {
			if (typeof this.$refs.upart === "undefined") return;
			this.$refs.upart.initialize();
		},
		resetCargarArticulos(){
			if (typeof this.$refs.carart === "undefined") return;
			this.$refs.carart.initialize();
		},
		resetCargarControl(){
			if (typeof this.$refs.carinv === "undefined") return;
			this.$refs.carinv.initialize();
		},
		resetCargaPantografo(){
			if (typeof this.$refs.carpant === "undefined") return;
			this.$refs.carpant.initialize();
		},
		resetCargaEntradaSinQR(){
			if (typeof this.$refs.carentsinqr === "undefined") return;
			this.$refs.carentsinqr.initialize();
		},
		resetCargaSalidaSinQR(){
			if (typeof this.$refs.carentsaldiasinqr === "undefined") return;
			this.$refs.carentsaldiasinqr.initialize();
		}
	},
};
</script>