<template>
  <div>
    <ganttpost
      ref="ganttpost"
    ></ganttpost>
    <v-dialog v-model="detalles" persistent max-width="80%" :key="'d'+detalles.toString()">
      <v-card>
        <v-card-title v-if="!clavePersonalizada">Detalles de {{watchedItem.ot}}</v-card-title>
        <v-card-title v-else>Detalles de la ODT con Clave {{watchedItem.clave}}</v-card-title>
        <v-card-text>
          <apex-chart-dialog
            ref="chartDetalles"
          ></apex-chart-dialog>
          <v-data-table
            :items="watchedItem.articulos"
            :headers="headersDetalle"
          >
            <template v-slot:item="{ item }">
            <tr>
              <td class="text-center">{{item.nombre}}</td>
              <td class="text-center" v-if="permisoVista('listas','$')">${{$utils.strings.formatearCifra(item.precioUnitario,2)}}</td>
              <td class="text-center">{{ $utils.strings.formatearCifra(item.cantidad,2) }}</td>
              <td class="text-center" v-if="permisoVista('listas','$')">${{$utils.strings.formatearCifra(item.costoEstimado,2)}}</td>
              <td class="text-center" v-if="permisoVista('listas','$')">${{$utils.strings.formatearCifra(item.costoReal,2)}}</td>
              <td class="text-center">{{item.fechaInicioLabel}}</td>
              <td class="text-center">{{item.fechaTerminadoLabel}}</td>
              <td class="text-center">{{estiloTiempo(item.tiempoTotalSegundos)}}</td>
              <td class="text-center">{{estiloTiempo(item.tiempoTotalSegundos / item.cantidad)}}</td>
              <td class="text-center">
                <div v-for="(area,idx) in item.areas" :key="'a'+item.idTrabajoOrdenDetalle+'-'+idx">
                  {{area.area}}: {{estiloTiempo(area.tiempo)}}
                </div>
              </td>
              <td class="text-center">
                <v-tooltip v-if="permisoVista('listas','$')"  bottom color="black" class="white--text">
                  <template v-slot:activator="{ on }">
                    <v-icon v-on="on"
                      small
                      class="mr-2"
                      @click="desgloseCostosOTDItem(item)"
                    >
                      mdi-currency-usd
                    </v-icon>
                  </template>
                  <span class="white--text">Desglose de Costos</span>
                </v-tooltip>
              </td>
            </tr>
            </template>
          </v-data-table>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="closeDetalles()">Cerrar</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-data-table
      v-if="permisoVista('listas','r')"
      :headers="visibleHeaders"
      :items="trabajoOrdenes"
      loading-text="Cargando... Espere, Por Favor."
      :loading="loading"
      class="elevation-0"
      ref="ots"
      :server-items-length="trabajoOrdenes_total"
      :items-per-page="trabajoOrdenes_items_per_page"
      :page="trabajoOrdenes_actual_page"
      @update:page="trabajoOrdenes_update_page_action"
      @update:items-per-page="(ipp) => trabajoOrdenes_items_per_page = ipp"
      @update:sort-by="trabajoOrdenes_sort_by_action"
      :footer-props="{disableItemsPerPage:true, disablePagination: this.loading}"
      :disable-sort="true"
      :disable-pagination="loading"
      hide-default-footer
    >
      <template v-slot:top>
        <v-toolbar flat color="white">
          <v-text-field
            v-model="search"
            append-icon="search"
            label="Buscar"
            @keyup="getTrabajoOrdenesOnEnterAction"
            @click:clear="clearSearchAction"
            @click:append="trabajoOrdenes_update_page_action(1)"
            :clearable="true"
            :disabled="loading"
            single-line
            hide-details
          ></v-text-field>
        </v-toolbar>
        <div class="text-right">
          <v-btn text color="primary" class="ml-auto" @click="show_SeleccionHeaders()">
            <v-icon small>mdi-filter-outline</v-icon>
            Mostrar / Ocultar Columnas
          </v-btn>
        </div>
      </template>
      <template v-slot:item="{ item }">
        <tr>
          <td class="text-center" v-if="visibleHeaders.some(x => x.value == 'clave')">
            {{clavePersonalizada?item.clave:item.ot}}
          </td>
          <td class="text-center" v-if="visibleHeaders.some(x => x.value == 'lineaComercial')">
            {{item.lineaComercial}}
          </td>
          <td class="text-center" v-if="visibleHeaders.some(x => x.value == 'cliente')">
            {{item.cliente}}
          </td>
          <td class="text-center" v-if="visibleHeaders.some(x => x.value == 'porcentajeCompletado')">
            {{item.porcentajeCompletado.toFixed(2)}}%
          </td>
          <td class="text-center" v-if="permisoVista('listas','$') && visibleHeaders.some(x => x.value == 'costoEstimado')">
            $ {{ $utils.strings.formatearCifra(item.costoEstimado,2) }}
          </td>
          <td class="text-center" v-if="permisoVista('listas','$') && visibleHeaders.some(x => x.value == 'costoReal')">
            $ {{ $utils.strings.formatearCifra(item.costoReal,2) }}
          </td>
          <td class="text-center" v-if="visibleHeaders.some(x => x.value == 'fechaInicio')">
            {{item.fechaInicioLabel}}
          </td>
          <td class="text-center" v-if="visibleHeaders.some(x => x.value == 'fechaTerminado')">
            {{item.fechaTerminadoLabel}}
          </td>
          <td class="text-center" v-if="visibleHeaders.some(x => x.value == 'fechaEntrega')">
            {{item.fechaEntrega}}
          </td>
          <td class="text-center" v-if="visibleHeaders.some(x => x.value == 'tiempoTotalSegundos')">
            {{estiloTiempo(item.tiempoTotalSegundos)}}
          </td>
          <td class="text-center">
            <v-tooltip bottom color="black" class="white--text">
              <template v-slot:activator="{ on }">
                <v-icon v-on="on"
                  v-if="item.estadoProduccion == 3 && permisoVista('listas','r')"
                  small
                  class="mr-2"
                  @click="ganttItem(item)"
                >
                  mdi-chart-timeline
                </v-icon>
              </template>
              <span class="white--text">Gantt</span>
            </v-tooltip>
            <v-tooltip bottom color="black" class="white--text">
              <template v-slot:activator="{ on }">
                <v-icon v-on="on"
                  v-if="permisoVista('listas','r')"
                  small
                  class="mr-2"
                  @click="watchItem(item)"
                >
                  mdi-eye
                </v-icon>
              </template>
              <span class="white--text">Ver detalles</span>
            </v-tooltip>
            <v-tooltip bottom color="black" class="white--text">
              <template v-slot:activator="{ on }">
                <v-icon v-on="on"
                  v-if="permisoVista('listas','$')"
                  small
                  @click="desgloseCostosItem(item)"
                >
                  mdi-currency-usd
                </v-icon>
              </template>
              <span class="white--text">Desglose de Costos</span>
            </v-tooltip>            
          </td>
        </tr>
      </template>
      <template v-slot:footer="{ props }">
        <v-col cols="12" class="d-flex justify-end align-center border-footer">
          <p class="mb-0 mr-8" style="font-size: 12px !important">Filas por página:</p>
          <v-text-field
            :value="props.pagination.itemsPerPage"
            dense
            hide-details
            disabled
            class="centered-input footer-input mt-0 mr-10"
          ></v-text-field>
          <p class="mb-0 mr-10" style="font-size: 12px !important">
            {{props.pagination.itemsLength ? getPageText(props.pagination): '-'}}
          </p>
          <v-pagination
            v-model="trabajoOrdenes_actual_page"
            :length="props.pagination.pageCount"
            :total-visible="7"
            :disabled="loading"
          ></v-pagination>
        </v-col>
      </template>
    </v-data-table>
    <apex-chart-dialog
      ref="chartGlobal"
    ></apex-chart-dialog>

    <SeleccionHeadersVue ref="seleccionHeaders" v-model="visibleHeaders" :headers="headers" path="ots_cerrado"/>
  </div>
</template>

<script>
import axios from "axios";
import ganttpost from "./Abiertos/GanttPost";
import { mapState } from 'vuex';
import apexChartDialog from '@/components/ApexChartDialog';
import SeleccionHeadersVue from '@/components/SeleccionHeaders.vue'

export default {
   components: {
      ganttpost: ganttpost,
      'apex-chart-dialog': apexChartDialog,
      SeleccionHeadersVue
    },
    data: () => ({
      search: '',
      headers: [
        {
          text: 'ODT',
          align: 'center',
          sortable: false,
          value: 'ot',
        },
        {
          text: 'Línea Comercial',
          align: 'center',
          sortable: false,
          value: 'lineaComercial'
        },
        {
          text: 'Cliente',
          align: 'center',
          sortable: false,
          value: 'cliente'
        },
        {
          text: '% Completado',
          align: 'center',
          sortable: false,
          value: 'porcentajeCompletado'
        },
        {
          text: 'Costo Estimado',
          align: 'center',
          sortable: true,
          value: 'costoEstimado',
        },
        {
          text: 'Costo Real',
          align: 'center',
          sortable: true,
          value: 'costoReal',
        },
        {
          text: 'Fecha de Inicio',
          align: 'center',
          sortable: false,
          value: 'fechaInicio',
          width:'12%',
        },
        {
          text: 'Fecha de Terminado',
          align: 'center',
          sortable: false,
          value: 'fechaTerminado',
          width:'12%',
        },
        {
          text: 'Fecha Compromiso',
          align: 'center',
          sortable: false,
          value: 'fechaEntrega',
          width:'12%',
        },
        {
          text: 'Tiempo de Trabajo',
          align: 'center',
          sortable: false,
          value: 'tiempoTotalSegundos',
        },
        { text: 'Acciones', value: 'action', sortable: false, align:'center'},
      ],
      headersDetalle: [
        {
          text: 'Artículo',
          align: 'center',
          sortable: true,
          value: 'nombre',
        },
        {
          text: 'Precio Unitario',
          align: 'center',
          sortable: true,
          value: 'precioUnitario',
        },
        {
          text: 'Cantidad',
          align: 'center',
          sortable: true,
          value: 'cantidad',
        },
        {
          text: 'Costo Estimado',
          align: 'center',
          sortable: true,
          value: 'costoEstimado',
        },
        {
          text: 'Costo Real',
          align: 'center',
          sortable: true,
          value: 'costoReal',
        },
        {
          text: 'Fecha de Inicio',
          align: 'center',
          sortable: true,
          value: 'fechaInicio',
          width:'12%',
        },
        {
          text: 'Fecha de Terminado',
          align: 'center',
          sortable: true,
          value: 'fechaTerminado',
          width:'12%',
        },
        {
          text: 'Tiempo de Trabajo',
          align: 'center',
          sortable: false,
          value: 'tiempoTotalSegundos',
        },
        {
          text: 'Tiempo de Trabajo Promedio',
          align: 'center',
          sortable: false,
          value: 'tiempoPromedio',
        },
        {
          text: 'Áreas de Trabajo',
          align: 'center',
          sortable: true,
          value: 'areas'
        },
        { text: 'Acciones', value: 'action', sortable: false, align:'center'},
      ],
      loading: false,
      watchedItem: {
        idTrabajoOrden: -1,
        disponibilidadProducto: 0,
        disponibilidadMP: 0,
        estadoProducion: 0,
        articulos:[],
        costoEstimado: 0.0,
        costoReal: 0.0,
      },
      defaultItem: {
        idTrabajoOrden: -1,
        disponibilidadProducto: 0,
        disponibilidadMP: 0,
        estadoProducion: 0,
        articulos:[],
        costoEstimado: 0.0,
        costoReal: 0.0,
      },
      detalles: false,
      trabajoOrdenes: [],
      trabajoOrdenes_total: null,
      trabajoOrdenes_items_per_page: 10,
      trabajoOrdenes_actual_page: 1,

      visibleHeaders: [],
    }),
    computed: {
      ...mapState({
        clavePersonalizada: 'clavePersonalizada',
        nombreEmpresa: 'nombreEmpresa',
      }),
      trabajoOrdenes_fetch_url() {
        var url = `/TrabajoOrdenes/Cerradas?paginar=true&page=${this.trabajoOrdenes_actual_page}&size=${this.trabajoOrdenes_items_per_page}`;
        if(this.search != null && this.search.length > 0){
          url += ("&codigo="+this.search);
        }
        return url;
      },
    },

    created () {
      if(this.clavePersonalizada){
        this.headers[0].text = "Clave";
        this.headers[0].value = 'clave';
      }
      //this.initialize();
      if(!this.permisoVista('listas','$')){
        this.headers = this.headers.filter(element => {
          return element.value != 'costoEstimado' && element.value != 'costoReal';
        });

        this.headersDetalle = this.headersDetalle.filter(element => {
          return element.value != 'precioUnitario' && element.value != 'costoEstimado' && element.value != 'costoReal';
        });
      }
    },

    methods: {
      getPageText: ({ pageStart, pageStop, itemsLength }) => `${pageStart+1}-${pageStop} de ${itemsLength}`,
      getTrabajoOrdenesOnEnterAction($event) {
        if ($event instanceof KeyboardEvent) {
          //  Enter
          if ($event.keyCode === 13) this.trabajoOrdenes_update_page_action(1);
        }
      },
      clearSearchAction() {
        this.search = "";
        this.trabajoOrdenes_update_page_action(1);
      },
      async trabajoOrdenes_update_page_action(page) {
        this.trabajoOrdenes_actual_page = page;
        this.loading = true;
        try {
          let response = await this.getTrabajoOrdenes();
          this.trabajoOrdenes = response.data.result;
          this.trabajoOrdenes_total = response.data.total;
          this.loading = false;
        } catch (exception) {
          console.log(exception);
        }
      },
      trabajoOrdenes_sort_by_action($sort) {
      },
      getTrabajoOrdenes() {
      //* Realizamos peticion HTTP
        return new Promise((resolve, reject) => {
          axios
            .get(this.trabajoOrdenes_fetch_url)
            .then(response => {
              response.data.result = response.data.result.map(element => {
                if(element.idCliente == null) element.cliente = this.nombreEmpresa;
                return element;
              });
              resolve(response);
            })
            .catch(error => {
              reject(error);
            });
        });
      },
      desgloseCostosItem(item){
        this.$refs.chartGlobal.show("Desglose de Costos - "+item.clave,item.costosGrafica.limits,item.costosGrafica.categories,item.costosGrafica.series);
      },
      desgloseCostosOTDItem(item){
        this.$refs.chartGlobal.show("Desglose de Costos - "+item.nombre+" - "+(this.clavePersonalizada ? this.watchedItem.clave : this.watchedItem.ot),item.costosGrafica.limits,item.costosGrafica.categories,item.costosGrafica.series);
      },
      cambio(event){
        var orden = event.toLowerCase().split('-');
        console.log(event);
        if(orden[0] == 'completo'){
          this.initialize();
        }
      },
      initialize () {
        this.trabajoOrdenes_update_page_action(1);
      },
      watchItem(item){
        this.watchedItem = item;
        this.detalles = true;
      },
      ganttItem(item){
        this.$refs.ganttpost.getgantt(item.idTrabajoOrden, item.clave);
      },
      closeDetalles(){
        this.detalles = false;
        this.watchedItem = Object.assign({},this.defaultItem);
        this.watchedItem.articulos = [];
      },
      estiloTiempo(segundos){
        var horas = 0, minutos = 0;
        if(segundos >= 3600){
          horas = Math.trunc(segundos/3600);
          segundos -= (horas * 3600);
        }
        if(segundos >= 60){
          minutos = Math.trunc(segundos/60);
          segundos -= (minutos * 60);
        }
        return this.twoDigits(horas)+":"+this.twoDigits(minutos)+":"+this.twoDigits(Math.trunc(segundos));
      },
      twoDigits(number){
        return ("0" + number).slice(-2);
      },
      show_SeleccionHeaders() {
        this.$refs.seleccionHeaders.show_dialog();
      }
    },
  }
</script>

<style scoped>
.fill-height{
  height: 100% !important;
}
.justify-content{
  justify-content: center !important;
}

.centered-input >>> input {
	text-align: center !important;
}

.footer-input {
  font-size: 12px !important;
  max-width: 40px !important;
}

.border-footer {
  border-top-width: thin;
  border-top-style: solid;
  border-top-color: rgba(0, 0, 0, 0.12);
}
</style>