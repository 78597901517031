<template>
  <v-col cols="12">
    <template>
      <v-row justify="center">
        <v-dialog v-model="eliminar" persistent max-width="500px" :key="eliminar">
          <v-card>
            <v-card-title class="headline">¿Está seguro que desea eliminar este puesto?</v-card-title>
            <v-card-text>Esta acción no se puede revertir y será permanente.</v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="blue darken-1" text @click="eliminar = false; delItem = '';">Cancelar</v-btn>
              <v-btn color="red darken-1" text @click="eliminar = false; deleteItemDB()">Aceptar</v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-row>
    </template>
    <v-data-table
      v-if="permisoVista('puestos','r')"
      :headers="headersFiltrados"
      :items="puestos"
      :search="search"
      :loading="loading"
      loading-text="Cargando... Espere, Por Favor."
    >
      <template v-slot:top>
        <v-toolbar flat color="white">
          <v-dialog v-model="dialog" persistent max-width="500px" :key="dialog">
            <template v-slot:activator="{ on }">
              <v-btn color="primary" dark class="mb-2" v-on="on" v-if="permisoVista('puestos','c')">Nuevo Puesto</v-btn>
            </template>
            <v-card>
              <v-card-title>
                <span class="headline">{{ formTitle }}</span>
              </v-card-title>
              <v-card-text>
                <v-container>
                  <v-row>
                    <v-col justify="center" cols="12" sm="12" md="12">
                      <v-text-field v-model="editedItem.nombre" label="Nombre del Puesto"></v-text-field>
                    </v-col>
                    <v-col justify="center" cols="12" sm="12" md="12">
                      <v-text-field v-model="editedItem.descripcion" label="Descripción del Puesto"></v-text-field>
                    </v-col>
                    <v-col cols="12">
                      <p class="mb-0 text-left" style="font-size: 16px !important">
                        ¿Es un Puesto de Producción?
                      </p>
                      <div class="d-flex justify-start">
                        <v-btn-toggle
                          v-model="editedItem.produccion"
                          class="mt-3"
                          mandatory
                          @change="editedItem.mantenimiento=false"
                        >
                          <v-btn
                            depressed
                            small
                            class="ma-0 py-4"
                            :value="true"
                            :color="
                              editedItem.produccion
                                ? 'success'
                                : 'grey lighten-1'
                            "
                            >Si</v-btn
                          >
                          <v-btn
                            depressed
                            small
                            class="ma-0 py-4"
                            :value="false"
                            :color="
                              !editedItem.produccion
                                ? 'blue'
                                : 'grey lighten-1'
                            "
                            >No</v-btn
                          >
                        </v-btn-toggle>
                      </div>
                    </v-col>
                    <v-col cols="12" v-if="!editedItem.produccion">
                      <p class="mb-0 text-left" style="font-size: 16px !important">
                        ¿Es un Puesto de Mantenimiento?
                      </p>
                      <div class="d-flex justify-start">
                        <v-btn-toggle
                          v-model="editedItem.mantenimiento"
                          class="mt-3"
                          mandatory
                        >
                          <v-btn
                            depressed
                            small
                            class="ma-0 py-4"
                            :value="true"
                            :color="
                              editedItem.mantenimiento
                                ? 'success'
                                : 'grey lighten-1'
                            "
                            >Si</v-btn
                          >
                          <v-btn
                            depressed
                            small
                            class="ma-0 py-4"
                            :value="false"
                            :color="
                              !editedItem.mantenimiento
                                ? 'blue'
                                : 'grey lighten-1'
                            "
                            >No</v-btn
                          >
                        </v-btn-toggle>
                      </div>
                    </v-col>
                  </v-row>
                  <ul>
                    <li class="red--text" v-for="ex in errores" :key="ex">{{ ex }}</li>
                  </ul>
                </v-container>
              </v-card-text>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="blue darken-1" text @click="dialog=false; errores=[];">Cancelar</v-btn>
                <v-btn color="red darken-1" text @click="save()" :disabled="saving" :loading="saving">
                  Guardar
                  <template v-slot:loader>
                    <v-progress-circular
                      indeterminate
                      :width="2"
                      :size="24"
                      color="red"
                    ></v-progress-circular>
                  </template>
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
          <v-spacer></v-spacer>
          <v-text-field
            v-model="search"
            append-icon="search"
            label="Buscar"
            single-line
            hide-details
          ></v-text-field>
        </v-toolbar>
      </template>
      <template v-slot:item.mantenimiento="{ item }">
        {{item.mantenimiento ? "Si" : "No"}}
      </template>
      <template v-slot:item.costoHora="{ item }">
        $ {{ item.costoHora.toFixed(2) }}
      </template>
      <template v-slot:item.action="{ item }">
        <v-tooltip bottom color="black" class="white--text">
          <template v-slot:activator="{ on }">
        <v-icon v-on="on"
          v-if="permisoVista('puestos','u')"
           small
          class="mr-2"
          @click="editItem(item)"
        >
          edit
        </v-icon>
         </template>
          <span class="white--text">Editar</span>
        </v-tooltip>
        <v-tooltip bottom color="black" class="white--text">
          <template v-slot:activator="{ on }">
        <v-icon v-on="on"
          v-if="permisoVista('puestos','d')"
          small
          @click="deleteItem(item)"
        >
          delete
        </v-icon>
         </template>
          <span class="white--text">Eliminar</span>
        </v-tooltip>
      </template>
      <template v-slot:no-data>
        <v-btn color="primary" @click="initialize">Refrescar</v-btn>
      </template>
    </v-data-table>
    <v-snackbar
      v-model="alerta"
      top
      color="error"
    >
      Existe un Puesto Activo con el mismo nombre
      <v-btn
        text
        @click="alerta = false"
      >
        Cerrar
      </v-btn>
    </v-snackbar>
  </v-col>
  
</template>

<script>
import axios from "axios";

  export default {
    data: () => ({
      saving: false,
      dialog: false,
      alerta: false,
      eliminar: false,
      search: '',
      headers: [
        {
          text: 'Puesto',
          align: 'left',
          sortable: true,
          value: 'nombre',
        },
        {
          text: 'Forma Parte de Operación',
          align: 'left',
          sortable: true,
          value: 'produccionLabel',
        },
        {
          text: 'Forma Parte de Mantenimiento',
          align: 'left',
          sortable: true,
          value: 'mantenimiento',
        },
        {
          text: 'Costo Promedio Por Hora',
          align: 'left',
          sortable: true,
          value: 'costoHora',
        },
        { text: 'Acciones', value: 'action', sortable: false, align:'center' },
      ],
      loading: false,
      puestos: [],
      delItem: '',
      action: 0,
      errores: [],
      editedIndex: -1,
      editedItem: {
        nombre: '',
        descripcion: '',
        produccion: false,
        mantenimiento: false,
      },
      defaultItem: {
        nombre: '',
        descripcion: '',
        produccion: false,
        mantenimiento: false,
      },
    }),

    computed: {
      headersFiltrados(){
        return this.permisoVista('puestos','$')
          ? this.headers
          : this.headers.filter(head=>head.value!='costoHora')
      },
      formTitle () {
        return this.editedIndex === -1 ? 'Nuevo Puesto' : 'Editar Puesto'
      }
    },

    watch: {
      dialog (val) {
        val || this.close()
      }
    },

    created () {
      this.initialize()
    },

    methods: {
      validFloat(value){
        return isNaN(parseFloat(value))?0.0:parseFloat(value);
      },
      initialize () {
        let me = this;
        me.loading = true;
        axios
        .get("/Puestos")
        .then(response =>  {
          me.puestos = response.data;
          me.puestos.forEach(function(element){
            element.produccionLabel = element.produccion?"Si":"No";
          })
          me.loading = false;
        })
        .catch(error => {
          console.log(error);
        });
      },

      editItem (item) {
        this.action = 1;
        this.editedIndex = this.puestos.indexOf(item)
        this.editedItem = Object.assign({}, item)
        this.dialog = true
      },

      deleteItem (item) {
        this.eliminar = true;
        this.delItem = item;
      },
      deleteItemDB(){
        axios
          .put("/Puestos/Desactivar/" + this.delItem.idPuesto)
          .then(() =>  {
            this.delItem = '';
            this.initialize();
          })
          .catch(error => {
            console.log(error);
          });
      },
      close () {
        this.action = 0,
        this.dialog = false
        setTimeout(() => {
          this.editedItem = Object.assign({}, this.defaultItem)
          this.editedIndex = -1
        }, 300)
      },
      validate(){
        this.errores =  [];
        if (
          this.editedItem.nombre.length < 5 ||
          this.editedItem.nombre.length > 50
        )
        {
          this.errores.push(
            "El nombre debe tener más de 4 caracteres y menos de 50."
          );
          return false;
        }
        return true;
      },
      save () {
        this.action = 0;
        if(this.validate()){
          let me = this;
          me.saving = true;
          if (this.editedIndex > -1)
          {
            //Código para editar
            axios
              .put("/Puestos/Actualizar/"+me.puestos[me.editedIndex].idPuesto, {
                Nombre: me.editedItem.nombre,
                Descripcion: me.editedItem.descripcion,
                Produccion: me.editedItem.produccion,
                mantenimiento: this.editedItem.mantenimiento
              })
              .then(response =>  {
                me.saving = false;
                me.close();
                me.initialize();
              })
              .catch(error => {
                me.saving = false;
                if (error.response) {
                  if(error.response.status == 409){
                    me.alerta = true;
                    me.errores.push("Existe un Puesto Activo con el Mismo Nombre");
                  }
                }
              });
          }
          else
          {
            axios
              .post("/Puestos/Crear", {
                Nombre: me.editedItem.nombre,
                Descripcion: me.editedItem.descripcion,
                Produccion: me.editedItem.produccion,
                mantenimiento: this.editedItem.mantenimiento
              })
              .then(response =>  {
                me.saving = false;
                me.close();
                me.initialize();
              })
              .catch(error => {
                me.saving = false;
                if (error.response) {
                  if(error.response.status == 409){
                    me.alerta = true;
                    me.errores.push("Existe un Puesto Activo con el Mismo Nombre");
                  }
                }
              });
          }
        }
      },
    },
  }
</script>
<style scoped>
.etiqueta {
  color: #aaa !important;
  font-weight: 400 !important;
}
</style>