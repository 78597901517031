<template>
	<v-card flat>
		<v-card-text v-if="errorCargaMasiva && !loadingCargaMasiva">
			<v-col cols="12" md="12" sm="12" class="text-center">
				<h3 v-if="erroresCarga.length == 0">Se ha producido un Error. Intente una vez más.</h3>
				<template v-else>
					<h3>Uno o varios errores al intentar la carga:</h3>
					<v-list-item>
						<v-list-item-content>
							<v-list-item-title v-for="(item, index) in erroresCarga" :key="index"> <v-icon color="red">mdi-alert-circle-outline</v-icon> {{item}}</v-list-item-title>
						</v-list-item-content>
					</v-list-item>
				</template>
			</v-col>
			<v-col cols="12" md="12" sm="12" class="text-center">
				<v-btn
					depressed
					color="red"
					text
					@click="
						cargarMasivaPrioridadArticulos = false;
						ficheroCarga = null;
						cargaCargaCompleta = false;
						loadingCargaMasiva = false;
						errorCargaMasiva = false;
						erroresCarga = [];
						selectedUpdateFile = null;
					"
					>Aceptar</v-btn
				>
			</v-col>
		</v-card-text>
		<v-card-text v-if="cargaCargaCompleta">
			<v-col cols="12" md="12" sm="12" class="text-center">
				<h3 v-if="cargaLink === ''">
					Carga Realizada Satisfactoriamente
				</h3>
				<div v-else>
					<h3>Carga Realizada Parcialmente</h3>
					<a
						:href="
							'/api/Articulos/ReporteCargaMasiva/' +
							cargaLink +
							'?access_token=' +
							token
						"
						target="_blank"
						>Archivo de Reporte</a
					>
				</div>
			</v-col>
			<v-col cols="12" md="12" sm="12" class="text-center">
				<v-btn
					depressed
					color="green"
					text
					@click="
						cargarMasivaPrioridadArticulos = false;
						ficheroCarga = null;
						cargaCargaCompleta = false;
						loadingCargaMasiva = false;
						errorCargaMasiva = false;
						selectedUpdateFile = null;
					"
					>Aceptar</v-btn
				>
			</v-col>
		</v-card-text>
		<v-card-text v-if="loadingCargaMasiva && !errorCargaMasiva">
			<v-col cols="12" md="12" sm="12" class="text-center">
				<h3>Cargando</h3>
			</v-col>
			<v-col cols="12" md="12" sm="12" class="text-center">
				<v-progress-circular
					:size="75"
					color="green"
					indeterminate
				></v-progress-circular>
			</v-col>
		</v-card-text>
		<v-card-text
			v-if="!loadingCargaMasiva && !cargaCargaCompleta && !errorCargaMasiva"
			class="text-center"
		>
			<p class="mb-0 text-h6 text--secondary text-left">
				Carga Masiva de Prioridad de Artículos
			</p>
			<v-file-input
				v-model="selectedUpdateFile"
				accept=".csv"
				@change="cambioficheroCarga"
				counter
				show-size
				clearable
				label="Fichero Carga .CSV"
			></v-file-input>
			<a
				:href="
					'/api/Articulos/PlantillaCargaMasivaPrioridadArticulos?access_token=' +
					token
				"
				target="_blank"
				class="caption"
			>
				<v-icon color="blue">mdi-download</v-icon>Descargar Plantilla de
				Carga Masiva de Prioridad de Artículos
			</a>
			<br />
			<br />
			<v-row>
				<v-col cols="12" class="py-0">
					<v-btn
						block
						color="blue"
						outlined
						class="my-2 mb-4"
						@click="cargaMasivaInventarioPost"
						>Cargar Prioridad de Artículos</v-btn
					>
				</v-col>
			</v-row>
		</v-card-text>
	</v-card>
</template>

<script>
import axios from "axios";
import { mapState } from "vuex";
export default {
	data() {
		return {
			erroresCarga: [],
			actualizacionMasiva: false,
			loadingActualizacionMasiva: false,
			ficheroCarga: null,
			cargaActualizacionCompleta: false,
			selectedUpdateFile: null,
			errorActualizacionMasiva: false,
			cargaMasiva: false,
			loadingCargaMasiva: false,
			ficheroCarga: null,
			cargaCargaCompleta: false,
			selectedCreateFile: null,
			errorCargaMasiva: false,
			cargaLink: "",
			updateLink: "",
		};
	},
	computed: {
		...mapState({
			cargasMasivas: "cargasMasivas",
			token: "token",
		}),
	},
	mounted() {
		this.initialize();
	},
	methods: {
		initialize() {
			this.resetValues();
		},
		resetValues() {
			this.cargarMasivaPrioridadArticulos = false;
            this.ficheroCarga = null;
            this.cargaCargaCompleta = false;
            this.loadingCargaMasiva = false;
            this.errorCargaMasiva = false;
			this.erroresCarga = [];
            this.selectedUpdateFile = null;
		},
		cambioficheroCarga(event) {
			if (typeof event === "undefined" || event == null)
				this.ficheroCarga = null;
			else this.ficheroCarga = event;
		},
		cargaMasivaInventarioPost() {
            if (this.ficheroCarga != null) {
                this.loadingCargaMasiva = true;
				let formData = new FormData();
				formData.append("file", this.ficheroCarga);
				axios
					.post("/Articulos/CargaMasivaPrioridadArticulos", formData, {
						headers: {
							"Content-Type": "multipart/form-data",
						},
					})
					.then((response) => {
						this.cargaLink = "";
						this.erroresCarga = response.data;
						if( this.erroresCarga.length > 0 ){
							this.errorCargaMasiva = true;
						}
						else {
							this.cargaCargaCompleta = true;
						}
						this.loadingCargaMasiva = false;
					})
					.catch((error) => {
						console.log(error);
						this.loadingCargaMasiva = false;
						this.errorCargaMasiva = true;
					});
			}
		},
	},
};
</script>