<template>
  <v-col cols="12" v-if="permisoVista('faltantes','r')">
    <template>
      <v-row justify="center">
        <v-dialog v-model="eliminar" persistent max-width="500px" :key="eliminar">
          <v-card>
            <v-card-title class="headline">¿Está seguro que desea cerrar esta alerta?</v-card-title>
            <v-card-text>Esta acción no se puede revertir y será permanente.</v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="blue darken-1" text @click="eliminar = false; delItem = '';">Cancelar</v-btn>
              <v-btn color="red darken-1" text @click="eliminar = false; deleteItemDB()">Aceptar</v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-row>
    </template>
    <v-row>
			<v-col cols="5" class="mt-0 pt-0">
				<v-autocomplete
          v-model="filtros.idTrabajoOrden"
          :items="otdfiltro"
          item-value="idTrabajoOrden"
          item-text="clave"
          label="Orden De Trabajo"
          hide-details
          clearable
          @change="getFaltantes()"
        ></v-autocomplete>
			</v-col>
			<v-col cols="6" class="mt-0 pt-0 ml-auto">
				<v-text-field
					class="pt-2"
					v-model="search"
					append-icon="search"
					label="Buscar"
					single-line
					hide-details
				></v-text-field>
			</v-col>
		</v-row>
    <v-row class="mb-2">
        <download-btn
          color="blue darken-1"
          text
          @click="descargarReporteFaltantes"
          label="Descargar Reporte de<br>Artículos Faltantes"
        ></download-btn>
        <download-btn
          color="blue darken-1"
          text
          @click="enviarReporteFaltantes"
          label="Enviar Reporte de<br>Artículos Faltantes"
        ></download-btn>
    </v-row>
    <v-data-table
      :headers="headers"
      :items="faltantes"
      :search="search"
      :loading="loading"
      loading-text="Cargando... Espere, Por Favor."
    >
      <template v-slot:no-data>
        <v-btn color="primary" @click="initialize">Refrescar</v-btn>
      </template>
    </v-data-table>
    <v-snackbar v-model="alerta.value" top :color="alerta.color">
      {{alerta.msg}}
      <v-btn text @click="alerta.value = false">Cerrar</v-btn>
    </v-snackbar>
  </v-col>
  
</template>

<script>
import axios from "axios";
import moment from 'moment';
import DownloadBtn from '../../DownloadBtn.vue';

  export default {
	components: { DownloadBtn },
    data: () => ({
      alerta: {
        value: false,
        color: "error",
        msg: "",
      },
      defaultAlerta: {
        value: false,
        color: "error",
        msg: "",
      },
      sending: false,
      filtros: {
        idTrabajoOrden: null
      },
      search: '',
      headers: [
        {
          text: 'Árticulo Faltante',
          align: 'left',
          sortable: true,
          value: 'articulo',
        },
        {
          text: 'Cantidad',
          align: 'left',
          sortable: true,
          value: 'cantidad',
        },
        {
          text: 'ODT',
          align: 'left',
          sortable: true,
          value: 'claveOt',
        },
        {
          text: 'Árticulo a Producir',
          align: 'left',
          sortable: true,
          value: 'articuloProducir',
        }
      ],
      loading: false,
      faltantes: [],
      otdfiltro: [],
    }),
    mounted () {
      this.initialize()
    },

    methods: {
      enviarReporteFaltantes(){
        this.sending = true;
        axios
        .get('/Inventario/EnviarXLSXFaltantes',{
          params: {
            IdTrabajoOrden: this.filtros.idTrabajoOrden
          }
        })
        .then( () => {
          this.sending = false;
          this.alerta.color = "success";
          this.alerta.msg = "Se ha enviado el reporte a los correos electrónicos correspondientes."
          this.alerta.value = true;
        })
        .catch( error => {
          this.sending = false;
          this.alerta.color = "error";
          this.alerta.msg = "Ocurrio un error al enviar el reporte."
          this.alerta.value = true;
          console.log( error );
        })
      },
      descargarReporteFaltantes(){
        const url = this.filtros.idTrabajoOrden != null 
          ? `/Inventario/XLSXFaltantes?IdTrabajoOrden=${this.filtros.idTrabajoOrden}`
          : '/Inventario/XLSXFaltantes'
        var datetime = moment().format('YYYYMMDDHHmmss');
        this.$utils.axios.downloadFile(url,'GET','reporte-faltantes-'+datetime+'.xlsx','application/vnd.openxmlformats-officedocument.spreadsheetml.sheet');
      },
      getOTDFiltros(){
        axios
          .get('/Inventario/GetOTDFiltro')
          .then( response => {
            this.otdfiltro = response.data;
          })
          .catch( error => {
            console.log( error );
          })
      },
      getFaltantes(){
        this.loading = true;
        axios
        .get("/Inventario/Faltantes",{
          params: {
            IdTrabajoOrden: this.filtros.idTrabajoOrden
          }
        })
        .then(response =>  {
          this.faltantes = response.data;
          this.loading = false;
        })
        .catch(error => {
          this.loading = false;
          console.log(error);
        });
      },
      initialize () {
        this.getFaltantes();
        this.getOTDFiltros();
      },     
    },
  }
</script>