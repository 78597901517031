<template>
	<v-col cols="12">
		<v-dialog v-model="dialogDelete" persistent max-width="40%">
			<v-card>
				<v-card-text>
					<p class="text-h5 pt-4 font-weight-medium text--primary">
						¿Está seguro que desea eliminar este Tiempo de Entrega?
					</p>
					<p class="subtitle-1">
						Esta acción no se puede revertir y será permanente.
					</p>
				</v-card-text>
				<v-card-actions>
					<v-spacer></v-spacer>
					<v-btn
						text
						color="success"
						:disabled="deleting"
						@click="cerrarDialog()"
						>Cancelar</v-btn
					>
					<v-btn
						text
						color="error"
						:disabled="deleting"
						:loading="deleting"
						@click="deleteItem()"
						>Eliminar</v-btn
					>
				</v-card-actions>
			</v-card>
		</v-dialog>
		<v-dialog v-model="dialog" persistent max-width="30%">
			<v-card>
				<v-card-title>
					{{
						editedItem.idArticuloProveedor == null
							? "Nuevo "
							: "Editar "
					}}
					Tiempo de Entrega
				</v-card-title>
				<v-card-text>
					<v-row>
						<v-col
							cols="12"
							v-if="editedItem.idArticuloProveedor == null"
						>
							<v-autocomplete
								v-model="editedItem.idArticulo"
								:items="articulosDisponibles"
								:item-text="nombreArticulo"
								item-value="idArticulo"
								label="Artículo"
								clearable
								:disabled="saving"
								:error-messages="errorArticulo"
								@focus="errorArticulo = ''"
							></v-autocomplete>
						</v-col>
						<v-col
							cols="12"
							v-if="editedItem.idArticuloProveedor == null"
						>
							<v-autocomplete
								v-model="editedItem.idProveedor"
								:items="proveedores"
								item-text="nombre"
								item-value="idProveedor"
								label="Proveedor"
								clearable
								:disabled="saving"
								:error-messages="errorProveedor"
								@focus="errorProveedor = ''"
							></v-autocomplete>
						</v-col>
						<v-col
							v-if="editedItem.idArticuloProveedor != null"
							cols="12"
							class="d-flex justify-center"
						>
							<v-col cols="8">
								<v-text-field
									v-model="editedItem.tiempoEntrega"
									class="centered-input"
									type="number"
									label="Tiempo de Entrega"
									:min="0"
									:suffix="
										$utils.strings.pluralizar(
											'día',
											editedItem.tiempoEntrega
										)
									"
									:disabled="saving"
									:error-messages="errorTiempoEntrega"
									@focus="errorTiempoEntrega = ''"
								></v-text-field>
							</v-col>
						</v-col>
						<v-col cols="6" v-else>
							<v-text-field
								v-model="editedItem.tiempoEntrega"
								class="centered-input"
								type="number"
								label="Tiempo de Entrega"
								:min="0"
								:suffix="
									$utils.strings.pluralizar(
										'día',
										editedItem.tiempoEntrega
									)
								"
								:disabled="saving"
								:error-messages="errorTiempoEntrega"
								@focus="errorTiempoEntrega = ''"
							></v-text-field>
						</v-col>
					</v-row>
				</v-card-text>
				<v-card-actions>
					<v-spacer></v-spacer>
					<v-btn
						color="success"
						text
						@click="cerrarDialog()"
						:disabled="saving"
						>Cancelar</v-btn
					>
					<v-btn
						color="error"
						text
						@click="save()"
						:disabled="saving"
						:loading="saving"
						>Guardar</v-btn
					>
				</v-card-actions>
			</v-card>
		</v-dialog>
		<v-dialog v-model="cargaMasiva" persistent max-width="30%">
			<v-card>
				<v-card-title> Cargar Formato De Carga Masiva </v-card-title>
				<template v-if="estadoCarga == 0">
					<v-card-text>
						<v-file-input
							v-model="ficheroActualizacion"
							label="Fichero de Tiempos de Entrega por Proveedor (.csv)"
							accept=".csv"
							counter
							show-size
							@change="cambioFicheroActualizacion"
						></v-file-input>
					</v-card-text>
					<v-card-actions>
						<v-spacer></v-spacer>
						<v-btn @click="cerrarCargaMasiva" text color="green"
							>Cancelar</v-btn
						>
						<v-btn @click="realizarCargaMasiva" text color="red"
							>Guardar</v-btn
						>
					</v-card-actions>
				</template>
				<template v-if="estadoCarga == 1">
					<v-card-text class="text-center">
						<v-progress-circular
							indeterminate
							size="100"
							width="5"
							color="primary"
						></v-progress-circular>
						<div class="text-h6">Cargando</div>
					</v-card-text>
				</template>
				<template v-if="estadoCarga == 2">
					<v-card-text class="text-center">
						<v-icon size="100" color="green">
							mdi-check-circle-outline
						</v-icon>
						<div class="text-h6">
							Carga Masiva Realizada Satisfactoriamente.
						</div>
					</v-card-text>
					<v-card-actions>
						<v-spacer></v-spacer>
						<v-btn text color="green" @click="cerrarCargaMasiva"
							>Aceptar</v-btn
						>
						<v-spacer></v-spacer>
					</v-card-actions>
				</template>
				<template v-if="estadoCarga == 3">
					<v-card-text class="text-center">
						<v-icon size="100" color="red">
							mdi-close-circle-outline
						</v-icon>
						<div class="text-h6">
							Ha sucedido un Error. Intente nuevamente.
						</div>
					</v-card-text>
					<v-card-actions>
						<v-spacer></v-spacer>
						<v-btn text color="green" @click="cerrarCargaMasiva"
							>Aceptar</v-btn
						>
						<v-spacer></v-spacer>
					</v-card-actions>
				</template>
			</v-card>
		</v-dialog>
		<v-row>
			<v-col cols="6">
				<v-autocomplete
					v-model="filtros.idArticulo"
					:items="articulosNombres"
					:item-text="nombreArticulo"
					item-value="idArticulo"
					label="Artículo"
					hide-details
					clearable
					:disabled="loading"
					@change="getArticulosProveedor()"
				></v-autocomplete>
			</v-col>
			<v-col cols="6">
				<v-autocomplete
					v-model="filtros.idProveedor"
					:items="proveedores"
					item-text="nombre"
					item-value="idProveedor"
					label="Proveedor"
					hide-details
					clearable
					:disabled="loading"
					@change="getArticulosProveedor()"
				></v-autocomplete>
			</v-col>
		</v-row>
		<v-row>
			<v-col cols="12" class="d-flex">
				<v-btn class="mr-auto" color="primary" @click="showDialog()">
					Nuevo Tiempo de Entrega
				</v-btn>
				<download-btn
					color="blue darken-1"
					text
					@click="descargarFormatoCargaMasiva"
					label="Descargar Formato<br/>De Carga Masiva"
				></download-btn>
				<v-btn color="primary" text @click="abrirCargaMasiva"
					>Cargar Formato<br />
					De Carga Masiva</v-btn
				>
			</v-col>
		</v-row>
		<v-row>
			<v-col cols="12">
				<v-data-table
					:items="articulosProveedor"
					:headers="articulosProveedorHeaders"
					:loading="loading"
				>
					<template v-slot:item.tiempoEntrega="{ item }">
						{{
							item.tiempoEntrega % 1 == 0
								? item.tiempoEntrega
								: item.tiempoEntrega.toFixed(1)
						}}
						{{
							$utils.strings.pluralizar(
								" día",
								item.tiempoEntrega
							)
						}}
					</template>
					<template v-slot:item.actions="{ item }">
						<v-tooltip bottom color="black" class="white--text">
							<template v-slot:activator="{ on }">
								<v-icon
									v-on="on"
									small
									class="mr-2"
									@click="showDialog(item)"
								>
									edit
								</v-icon>
							</template>
							<span class="white--text">Editar</span>
						</v-tooltip>
						<v-tooltip bottom color="black" class="white--text">
							<template v-slot:activator="{ on }">
								<v-icon
									v-on="on"
									small
									@click="deleteDialog(item)"
								>
									delete
								</v-icon>
							</template>
							<span class="white--text">Eliminar</span>
						</v-tooltip>
					</template>
				</v-data-table>
			</v-col>
		</v-row>
	</v-col>
</template>

<script>
import axios from "axios";
import moment from 'moment';
import { mapState } from 'vuex';
export default {
	data() {
		return {
			cargaMasiva: false,
			estadoCarga: 0,
			ficheroActualizacion: null,
			articulosProveedorHeaders: [
				{
					text: "Artículo",
					align: "center",
					value: "articulo",
				},
				{
					text: "Proveedor",
					align: "center",
					value: "proveedor",
				},
				{
					text: "Tiempo de Entrega",
					align: "center",
					value: "tiempoEntrega",
				},
				{
					text: "Acciones",
					align: "center",
					value: "actions",
				},
			],
			filtros: {
				idArticulo: null,
				idProveedor: null,
			},
			articulosProveedor: [],
			articulosNombres: [],
			proveedores: [],
			dialogDelete: false,
			dialog: false,
			editedItem: {
				idArticuloProveedor: null,
				idArticulo: null,
				idProveedor: null,
				tiempoEntrega: 0,
			},
			defaultEditedItem: {
				idArticuloProveedor: null,
				idArticulo: null,
				idProveedor: null,
				tiempoEntrega: 0,
			},
			errorArticulo: "",
			errorProveedor: "",
			errorTiempoEntrega: "",
			loading: false,
			saving: false,
			deleting: false,
		};
	},
	computed:{
		...mapState(["token"]),
		articulosDisponibles(){
			const articulosSeleccionados = this.articulosProveedor.map( articulo => articulo.idArticulo );
			return this.articulosNombres.filter( articulo => !articulosSeleccionados.includes(articulo.idArticulo))
		}
	},
	mounted() {
		this.initialize();
	},
	methods: {
		descargarFormatoCargaMasiva() {
			var datetime = moment().format("YYYYMMDDHHmmss");
			this.$utils.axios.downloadFile(
				"/ArticuloProveedor/CsvArticuloProveedor?access_token=" +
					this.token,
				"GET",
				"tiemposentrega-proveedor-" + datetime + ".csv",
				"text/csv"
			);
		},
		realizarCargaMasiva() {
			if (this.ficheroActualizacion != null) {
				this.estadoCarga = 1;
				let formData = new FormData();
				formData.append("formfile", this.ficheroActualizacion);
				axios
					.put(
						"/ArticuloProveedor/CargaArticuloProveedorCsv",
						formData,
						{
							headers: {
								"Content-Type": "multipart/form-data",
							},
						}
					)
					.then(() => {
						this.estadoCarga = 2;
						this.initialize();
					})
					.catch((error) => {
						this.estadoCarga = 3;
						console.log(error);
					});
			}
		},
		abrirCargaMasiva() {
			this.cargaMasiva = true;
		},
		cerrarCargaMasiva() {
			this.cargaMasiva = false;
			this.estadoCarga = 0;
			this.ficheroActualizacion = null;
		},
		cambioFicheroActualizacion(event) {
			if (typeof event === "undefined" || event == null)
				this.ficheroActualizacion = null;
			else this.ficheroActualizacion = event;
		},
		validate() {
			this.errorArticulo = "";
			this.errorProveedor = "";
			this.errorTiempoEntrega = "";

			if (this.editedItem.idArticulo == null) {
				this.errorArticulo = "Debe seleccionar un artículo";
			}

			if (this.editedItem.idProveedor == null) {
				this.errorProveedor = "Debe seleccionar un proveedor";
			}

			if (
				isNaN(parseFloat(this.editedItem.tiempoEntrega)) ||
				parseFloat(this.editedItem.tiempoEntrega) < 0
			) {
				this.errorTiempoEntrega =
					"El tiempo de entrega no debe ser menor a 0";
			}

			return (
				this.errorArticulo == "" &&
				this.errorProveedor == "" &&
				this.errorTiempoEntrega == ""
			);
		},
		deleteItem() {
			this.deleting = true;

			axios
				.put(
					`/ArticuloProveedor/EliminarArticuloProveedor/${this.editedItem.idArticuloProveedor}`
				)
				.then(() => {
					this.dialogDelete = false;
					this.deleting = false;
					this.cerrarDialog();
					this.initialize();
				})
				.catch((error) => {
					this.deleting = false;
					console.log(error);
				});
		},
		save() {
			if (!this.validate()) return;
			this.saving = true;
			axios({
				url:
					this.editedItem.idArticuloProveedor == null
						? "/ArticuloProveedor/CrearArticuloProveedor"
						: `/ArticuloProveedor/ActualizarArticuloProveedor/${this.editedItem.idArticuloProveedor}`,
				method:
					this.editedItem.idArticuloProveedor == null
						? "post"
						: "put",
				data: this.editedItem,
			})
				.then(() => {
					this.cerrarDialog();
					this.saving = false;
					this.initialize();
				})
				.catch((error) => {
					this.saving = false;
					console.log(error);
				});
		},
		cerrarDialog() {
			this.dialog = false;
			this.dialogDelete = false;
			this.editedItem = Object.assign({}, this.defaultEditedItem);
			this.errorArticulo = "";
			this.errorProveedor = "";
			this.errorTiempoEntrega = "";
		},
		showDialog(item = null) {
			this.editedItem =
				item == null
					? Object.assign({}, this.defaultEditedItem)
					: Object.assign({}, item);
			this.dialog = true;
		},
		deleteDialog(item) {
			this.editedItem = Object.assign({}, item);
			this.dialogDelete = true;
		},
		nombreArticulo: (value) =>
			value.codigo != null
				? `[${value.codigo}] - ${value.nombre}`
				: value.nombre,
		initialize() {
			this.getProveedores();
			this.getArticulosNombres();
			this.getArticulosProveedor();
		},
		getProveedores() {
			axios
				.get("/Proveedores")
				.then((response) => {
					this.proveedores = response.data;
				})
				.catch((error) => {
					console.log(error);
				});
		},
		getArticulosNombres() {
			axios
				.get("/Articulos/ArticulosNombres")
				.then((response) => {
					this.articulosNombres = response.data;
				})
				.catch((error) => {
					console.log(error);
				});
		},
		getArticulosProveedor() {
			this.loading = true;
			axios
				.get("/ArticuloProveedor", {
					params: {
						idArticulo: this.filtros.idArticulo,
						idProveedor: this.filtros.idProveedor,
					},
				})
				.then((response) => {
					this.articulosProveedor = response.data;
					this.loading = false;
				})
				.catch((error) => {
					this.loading = false;
					console.log(error);
				});
		}
	},
};
</script>
<style scoped>
.centered-input >>> input {
	text-align: center !important;
}
</style>