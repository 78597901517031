<template>
	<v-col cols="12" class="mt-4">
		<v-row justify="center">
			<v-dialog
				v-model="eliminar"
				persistent
				max-width="550px"
				:key="eliminar"
			>
				<v-card>
					<v-card-title class="headline"
						>¿Está seguro que desea eliminar este Subtipo de
						Paro?</v-card-title
					>
					<v-card-text
						>Esta acción no se puede revertir y será
						permanente.</v-card-text
					>
					<v-card-actions>
						<v-spacer></v-spacer>
						<v-btn
							color="blue darken-1"
							text
							@click="
								eliminar = false;
								delItem = '';
							"
							>Cancelar</v-btn
						>
						<v-btn
							color="red darken-1"
							text
							@click="
								eliminar = false;
								deleteItemDB();
							"
							>Aceptar</v-btn
						>
					</v-card-actions>
				</v-card>
			</v-dialog>
		</v-row>
		<v-data-table
			v-if="permisoVista('subparotipos', 'r')"
			:headers="headers"
			:items="subParoTipos"
			:search="search"
			:loading="loading"
			loading-text="Cargando... Espere, Por Favor."
		>
			<template v-slot:top>
				<v-toolbar flat color="white">
					<v-dialog
						v-model="dialog"
						persistent
						max-width="500px"
						:key="dialog"
					>
						<template v-slot:activator="{ on }">
							<v-btn
								color="primary"
								dark
								class="mb-2"
								v-on="on"
								v-if="permisoVista('subparotipos', 'c')"
								>Nuevo Submotivo de Incidente</v-btn
							>
						</template>
						<v-card>
							<v-card-title>
								<span class="headline">{{ formTitle }}</span>
							</v-card-title>
							<v-card-text>
								<v-container>
									<v-row>
										<v-col
											justify="center"
											cols="12"
											sm="12"
											md="12"
										>
											<v-autocomplete
                        v-model="editedItem.idOEEFactor"
                        :items="oeeFactores"
                        item-text="nombre"
                        item-value="idOEEFactor"
                        label="Factor OEE"
						@change="onChangeOEEFactor"
                      ></v-autocomplete>
										</v-col>
										<v-col
											justify="center"
											cols="12"
											sm="12"
											md="12"
										>
											<v-autocomplete
												v-model="editedItem.idParoTipo"
												label="Seleccione el Motivo de Incidente"
												:items="paros"
												item-text="nombre"
												item-value="idParoTipo"
												@change="cargarUsuarios"
											></v-autocomplete>
										</v-col>
										<v-col
											justify="center"
											cols="12"
											sm="12"
											md="12"
										>
											<v-text-field
												v-model="editedItem.nombreSubParoTipo"
												label="Nombre del Submotivo de Incidente"
											></v-text-field>
										</v-col>
										<v-col
											justify="center"
											cols="12"
											sm="12"
											md="12"
										>
											<v-text-field
												v-model="editedItem.codigoSubParoTipo"
												label="Código del Submotivo de Incidente (Opcional)"
											></v-text-field>
										</v-col>
										<v-col cols="6" sm="6" md="6">
											<p class="mb-0">
												¿Llamado a Mantenimiento?
											</p>
											<div class="d-flex">
												<v-btn-toggle
												v-model="editedItem.mantenimiento"
												class="my-3"
												>
												<v-btn
													depressed
													small
													class="ma-0"
													:color="
													editedItem.mantenimiento == 0
														? 'success'
														: 'grey lighten-1'
													"
													>Si</v-btn
												>
												<v-btn
													depressed
													small
													class="ma-0"
													:color="
													editedItem.mantenimiento == 1
														? 'error'
														: 'grey lighten-1'
													"
													>No</v-btn
												>
												</v-btn-toggle>
											</div>
										</v-col>
										<v-col cols="6" sm="6" md="6"
                      v-if="editedItem.idOEEFactor == 2"
                    >
                      <p class="mb-0">
                        ¿Es un Paro Programado?
                      </p>
                      <div class="d-flex">
                        <v-btn-toggle
                          v-model="editedItem.paroProgramado"
                          class="my-3"
						  @change="onChangeSwitchParo"
                        >
                          <v-btn
                            depressed
                            small
                            class="ma-0"
                            :color="
                              editedItem.paroProgramado == 0
                                ? 'success'
                                : 'grey lighten-1'
                            "
                            >Si</v-btn
                          >
                          <v-btn
                            depressed
                            small
                            class="ma-0"
                            :color="
                              editedItem.paroProgramado == 1
                                ? 'error'
                                : 'grey lighten-1'
                            "
                            >No</v-btn
                          >
                        </v-btn-toggle>
                      </div>
                    </v-col>
					<v-col v-if="!editedItem.mantenimiento && whatsapp == true" cols="12">
						<v-row>
						  <v-col cols="12">
							<v-autocomplete 
								v-model="selectedUsuario"
								label="Usuarios a Notificar"
								:items="filteredUsuariosVinculados"
								item-value="idUsuario"
								item-text="nombre"
								@change="onSelectUsuario"
								:key="ups"
							   >
						  </v-autocomplete>
						</v-col>
						  <v-col v-if="whatsapp == true" cols="12">
							<v-list>
							  <v-list-item v-for="(usuario, index) in editedItem.usuariosAlertas" :key="index">
								<v-list-item-content>
								  <v-list-item-title>{{ usuario.nombre }}</v-list-item-title>
								  <v-list-item-subtitle v-if="usuario.departamento">
									Departamento: {{ usuario.departamento }}
								  </v-list-item-subtitle>
								  <v-list-item-subtitle v-else>Sin Departamento</v-list-item-subtitle>
								</v-list-item-content>
						  
								<!-- Ícono para eliminar el usuario -->
								<v-list-item-action>
								  <v-icon @click="onRemoveUsuario(usuario)" :disabled="!usuario.delete">
									mdi-trash-can
								  </v-icon>
								</v-list-item-action>
							  </v-list-item>
							</v-list>
						  </v-col>
						</v-row>
					  </v-col>
					<v-col v-if="editedItem.paroProgramado == 0" cols="12" class="mb-3">
						<TimePicker
							v-model="editedItem.tiempoEstimado"
							label="Tiempo Aproximado del Paro (HH:MM:SS)"
							:error="errorTiempo"
							@focus="errorTiempo = false"
						/>
					</v-col>
				</v-row>
									<ul>
										<li
											class="red--text"
											v-for="ex in errores"
											:key="ex"
										>
											{{ ex }}
										</li>
									</ul>
								</v-container>
							</v-card-text>
							<v-card-actions>
								<v-spacer></v-spacer>
								<v-btn
									color="blue darken-1"
									text
									@click="
										dialog = false;
										errores = [];
									"
									:disabled="saving"
									>Cancelar</v-btn
								>
								<v-btn
									color="red darken-1"
									text
									@click="save()"
									:disabled="saving"
									:loading="saving"
								>
									Guardar
									<template v-slot:loader>
										<v-progress-circular
											indeterminate
											:width="2"
											:size="24"
											color="red"
										></v-progress-circular>
									</template>
								</v-btn>
							</v-card-actions>
						</v-card>
					</v-dialog>
					<v-spacer></v-spacer>
					<v-text-field
						v-model="search"
						append-icon="search"
						label="Buscar"
						single-line
						hide-details
					></v-text-field>
				</v-toolbar>
			</template>
			<template v-slot:[`item.nombreSubParoTipo`]="{ item }">
				{{ item | nombreFormated }}
			</template>
			<template v-slot:[`item.mantenimiento`]="{ item }">
				{{ item.mantenimiento ? 'NO' : 'SI' }}
			</template>
			<template v-slot:[`item.paroProgramado`]="{ item }">
				{{ item.paroProgramado ? 'NO' : 'SI' }}
			</template>
			<template v-slot:[`item.action`]="{ item }">
				<v-tooltip
					
					bottom
					color="black"
					class="white--text"
				>
					<template v-slot:activator="{ on }">
						<v-icon
							v-on="on"
							v-if="permisoVista('subparotipos', 'u')"
							small
							class="mr-2"
							@click="editItem(item)"
						>
							edit
						</v-icon>
					</template>
					<span class="white--text">Editar</span>
				</v-tooltip>
				<v-tooltip
					
					bottom
					color="black"
					class="white--text"
				>
					<template v-slot:activator="{ on }">
						<v-icon
							v-on="on"
							v-if="permisoVista('subparotipos', 'd')"
							small
							@click="deleteItem(item)"
						>
							delete
						</v-icon>
					</template>
					<span class="white--text">Eliminar</span>
				</v-tooltip>
			</template>
			<template v-slot:no-data>
				<v-btn color="primary" @click="initialize">Refrescar</v-btn>
			</template>
		</v-data-table>
		<v-snackbar v-model="alerta" top color="error">
			Existe un Submotivo de Incidente Activo con el mismo nombre o clave
			<v-btn text @click="alerta = false"> Cerrar </v-btn>
		</v-snackbar>
	</v-col>
</template>

<script>
import axios from "axios";
import { mapState } from "vuex";

export default {
	data: () => ({
		errorTiempo: null,
		paroTipos: [],
		loadingParo: false,
		saving: false,
		dialog: false,
		alerta: false,
		eliminar: false,
		search: "",
		usuariosVinculadosWhatsapp:[],
        selectedUsuario: null,
      	ups:0,
      	alertaUsuariosOrigin: [],
		headers: [
			{
				text: "Submotivo de Incidente",
				align: "left",
				sortable: true,
				value: "nombreSubParoTipo",
			},
			{
				text: "Motivo de Incidente",
				align: "left",
				sortable: true,
				value: "nombreParoTipo",
			},
			{
				text: '¿Llamado a Mantenimiento?',
				align: 'center',
				sortable: true,
				value: 'mantenimiento',
			},
			{
				text: '¿Es un Paro Programado?',
				align: 'center',
				sortable: true,
				value: 'paroProgramado',
			},
			{
				text: "Acciones",
				value: "action",
				sortable: false,
				align: "center",
			},
		],
		loading: false,
		subParoTipos: [],
		delItem: null,
		action: 0,
		errores: [],
		editedIndex: -1,
		editedItem: {
			idParoTipo: 0,
			idSubParoTipo: 0,
			nombreParoTipo: '',
			nombreSubParoTipo: '',
			codigoSubParoTipo: '',
			mantenimiento: 0,
			idOEEFactor: null,
			paroProgramado: 1,
        	tiempoEstimado: 0,
			usuariosAlertas:[]
		},
		defaultItem: {
			idParoTipo: 0,
			idSubParoTipo: 0,
			nombreParoTipo: '',
			nombreSubParoTipo: '',
			codigoSubParoTipo: '',
			mantenimiento: 0,
			idOEEFactor: null,
			paroProgramado: 1,
        	tiempoEstimado: 0,
			usuariosAlertas:[]
		},
		oeeFactores:[],
		array:[]
	}),

	computed: {
		...mapState(["whatsapp"]),
		formTitle() {
			return this.editedIndex === -1
				? "Nuevo Submotivo de Incidente"
				: "Editar Submotivo de Incidente";
		},
		paros(){
			if (this.editedItem.idOEEFactor == null || this.editedItem.idOEEFactor <= 0) return [];
			var datos = this.paroTipos.filter( paro => paro.idOEEFactor == this.editedItem.idOEEFactor );
			this.array = datos
			return datos;
			//return this.paroTipos.filter( paro => paro.idOEEFactor == this.editedItem.idOEEFactor );
		},
		filteredUsuariosVinculados() {
          this.ups++; // Incrementa el contador para asegurarse de que el autocomplete se actualice
          return this.usuariosVinculadosWhatsapp.filter(usuario => 
          !this.editedItem.usuariosAlertas.some(alertaUsuario => alertaUsuario.idUsuario === usuario.idUsuario)
        );
      }
	},

	watch: {
		dialog(val) {
			val || this.close();
		},
	},

	mounted() {
		this.initialize();
	},

	methods: {
		cargarUsuarios(){
			const filtrado = this.array.filter(item => item.idParoTipo === this.editedItem.idParoTipo);
			var elementosTrue = this.editedItem.usuariosAlertas.filter(usuario => usuario.delete === true);
			this.editedItem.usuariosAlertas = [...elementosTrue, ...filtrado[0].alertaUsuarios]
		},
		onSelectUsuario() {
        	if (this.selectedUsuario) {
        		const usuario = this.usuariosVinculadosWhatsapp.find(user => user.idUsuario === this.selectedUsuario);

        	if (usuario && !this.editedItem.usuariosAlertas.some(existingUser => existingUser.idUsuario === usuario.idUsuario)) {
           		 this.editedItem.usuariosAlertas.push(usuario); // Añadir el usuario a la lista
           		 this.selectedUsuario = null; // Limpiar la selección del autocomplete
           		 this.ups++;
         	 }
        	}
      	},
    	onRemoveUsuario(usuario) {
      		this.editedItem.usuariosAlertas =  this.editedItem.usuariosAlertas.filter(user => user.idUsuario !== usuario.idUsuario);
    	},
		onChangeSwitchParo(){
			this.editedItem.tiempoEstimado = 0;
			this.errores = [];
			this.errorTiempo = false;
		},
		onChangeOEEFactor(){
			this.editedItem.idParoTipo = null;
		},
		initialize() {
			this.usuariosVinculados()
			this.getSubparosTipos();
			this.getOEEParosTipos();
			this.getParoTipos();
		},
		getParoTipos(){
			this.loadingParo = true;
			axios
				.get("/ParoTipos")
				.then(response =>  {
					this.paroTipos = response.data.map( paro => ({
						...paro,
						mantenimiento: +!paro.mantenimiento,
						paroProgramado: +!paro.paroProgramado
					}));
					
					this.paroTipos.forEach(paro => {
    					paro.alertaUsuarios.forEach(usuario => {
        				if (usuario.delete === true) {
            					usuario.delete = false;  // Cambiar el valor de delete a false
        					}
    					});
					});
				})
				.catch(console.log)
				.finally( () => {
					this.loadingParo = false;
				});
		},
		change(item){
			this.editedItem.paroProgramado = item
		},
		getOEEParosTipos(){
			axios
				.get('/SubParoTipos/OEEParoTipos')
				.then(response =>  {
					this.oeeFactores = response.data;
				})
				.catch( error => {
					console.log( error );
				})
		},
		getSubparosTipos(){
			this.loading = true;
			axios
				.get("/SubParoTipos")
				.then((response) => {
					this.subParoTipos = response.data.map( subparo => ({
						...subparo,
						idOEEFactor: subparo.oeeFactor.idOEEFactor,
						mantenimiento: +!subparo.mantenimiento,
						paroProgramado: +!subparo.paroProgramado
					}));
					this.loading = false;
				})
				.catch((error) => {
					console.log(error);
				});
		},
		editItem(item) {
			this.action = 1;
			this.editedIndex = this.subParoTipos.indexOf(item);
			this.editedItem = Object.assign({}, item);
			this.editedItem.usuariosAlertas = [...this.editedItem.usuariosAlertas, ...item.oeeFactor.paroTipos[0].alertaUsuarios]
			this.dialog = true;
		},

		deleteItem(item) {
			this.eliminar = true;
			this.delItem = Object.assign({}, item);
		},
		deleteItemDB() {
			axios
				.put(`/SubParoTipos/DesactivarSubParoTipo/${this.delItem.idSubParoTipo}`)
				.then(() => {
          this.initialize();
          this.delItem = [];
				})
				.catch((error) => {
					console.log(error);
				});
		},
		close() {
			(this.action = 0), (this.dialog = false);
			this.usuariosVinculadosWhatsapp = [];
			this.alertaUsuariosOrigin = [];
			this.array = [];

			setTimeout(() => {
				this.editedItem = Object.assign({}, this.defaultItem);
				this.editedIndex = -1;
			}, 300);
		},
		validate() {
			this.errores = [];

			if (
				this.editedItem.idParoTipo <= 0 ||
				this.editedItem.idParoTipo == null
			)
			{
				this.errores.push(
					"Debe seleccionar el Motivo de Incidente."
				);
			}
			
			if (
				this.editedItem.nombreSubParoTipo.length < 2 ||
				this.editedItem.nombreSubParoTipo.length > 50
			) {
				this.errores.push(
					"El nombre debe tener más de 2 caracteres y menos de 50."
				);
			}

			if (
				this.editedItem.mantenimiento == null
			)
			{
				this.errores.push(
					"Debe indicar si el Incidente llamará a Mantenimiento."
				);
			}

			if ( this.editedItem.idOEEFactor == 2 &&
				this.editedItem.paroProgramado == -1
			)
			{
				this.errores.push(
					"Debe indicar si es un paro."
				);
			}

			if(this.editedItem.paroProgramado == 0){
				if(this.editedItem.tiempoEstimado == 0){
					this.errores.push("Debe indicar el Tiempo Aproximado del Paro");
					this.errorTiempo = true;
				}
			}

			return this.errores.length==0;
		},
		save() {
			this.action = 0;
			if (this.validate()) {
				this.saving = true;
				if (this.editedIndex > -1) {
					//Código para editar
					axios
						.put(
							`/SubParoTipos/ActualizarSubParoTipo/${this.subParoTipos[this.editedIndex].idSubParoTipo}`,
							{
								idParoTipo: this.editedItem.idParoTipo,
								nombre: this.editedItem.nombreSubParoTipo,
								codigo: this.$utils.strings.isNullOrEmpty(this.editedItem.codigoSubParoTipo) ? null : this.editedItem.codigoSubParoTipo,
                mantenimiento: !this.editedItem.mantenimiento,
								paroProgramado: (this.editedItem.idOEEFactor == 2) ? !this.editedItem.paroProgramado : false,
								tiempoEstimado: this.editedItem.tiempoEstimado,
								idsUsuarios: this.editedItem.usuariosAlertas.filter(usuario => usuario.delete === true).map(usuario => usuario.idUsuario)
							}
						)
						.then((response) => {
							this.saving = false;
							this.close();
							this.initialize();
						})
						.catch((error) => {
							this.saving = false;
							if (error.response) {
								if (error.response.status == 409) {
									this.alerta = true;
									this.errores.push(
										"Existe un Submotivo de Incidente Activo con el Mismo Nombre o Clave"
									);
								}
							}
						});
				} else {
					axios
						.post("/SubParoTipos/CrearSubParoTipo", {
							idParoTipo: this.editedItem.idParoTipo,
							nombre: this.editedItem.nombreSubParoTipo,
							codigo: this.$utils.strings.isNullOrEmpty(this.editedItem.codigoSubParoTipo) ? null : this.editedItem.codigoSubParoTipo,
              mantenimiento: !this.editedItem.mantenimiento,
							paroProgramado: (this.editedItem.idOEEFactor == 2) ? !this.editedItem.paroProgramado : false,
							tiempoEstimado: this.editedItem.tiempoEstimado,
							idsUsuarios: this.editedItem.usuariosAlertas.filter(usuario => usuario.delete === true).map(usuario => usuario.idUsuario)
						})
						.then((response) => {
							this.saving = false;
							this.close();
							this.initialize();
						})
						.catch((error) => {
							this.saving = false;
							if (error.response) {
								if (error.response.status == 409) {
									this.alerta = true;
									this.errores.push(
										"Existe un Submotivo de Incidente Activo con el Mismo Nombre o Clave"
									);
								}
							}
						});
				}
			}
		},
		usuariosVinculados() {
        this.loading = true;
        axios
          .get("/Usuarios/UsuariosVinculadosWhatsapp")
          .then(response => {
            this.usuariosVinculadosWhatsapp = response.data 
          })
          .catch(error => {
            this.vinculado = false;
            console.log(error)
          })
          .finally(() => {
            this.loading = false;
          })

      },
	},
	filters: {
      nombreFormated({ codigoSubParoTipo, nombreSubParoTipo }){
        return `${codigoSubParoTipo ? `[${codigoSubParoTipo}] -` : ''} ${nombreSubParoTipo}`.trim();
      }
    }
};
</script>