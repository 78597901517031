<template>
  <v-col cols="12">
    <v-tabs
      v-model="tab"
      background-color="transparent"
      grow
    >
      <v-tab>Por Día</v-tab>
      <v-tab>Por Intervalo</v-tab>

      <v-tabs-items v-model="tab" touchless>
        <v-tab-item eager>
            <TiempoActivoMaquinasDia />
        </v-tab-item>
        <v-tab-item eager>
            <TiempoActivoMaquinasRango />
        </v-tab-item>
      </v-tabs-items>
    </v-tabs>
  </v-col>
</template>

<script>
import TiempoActivoMaquinasDia from './TiempoActivoMaquinas/TiempoActivoMaquinasDia.vue';
import TiempoActivoMaquinasRango from './TiempoActivoMaquinas/TiempoActivoMaquinasRango.vue';

export default {
  components:{
    TiempoActivoMaquinasDia,
    TiempoActivoMaquinasRango,
  },
  data: () => ({
    tab: 0,
  }),
}
</script>