<template>
  <div>
    <v-dialog v-model="dialogActionsMasive" persistent max-width="60%">
      <v-card>
        <v-container class="pa-0 ma-0">
          <v-row class="pa-0 ma-0">
            <v-col cols="10" class="ma-0 pa-0 px-6">
              <v-card-title>
                Acciones Masivas OT'S
              </v-card-title>
            </v-col>
            <v-col cols="2" class="ma-0 pa-0 px-10 text-right">
              <v-btn
                color="primary"
                text
                @click="dialogActionsMasive = false; trabajoOrdenesSeleccionados = [];"
              >
                Cerrar
              </v-btn>
            </v-col>
          </v-row>
        </v-container>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col cols="12" md="12" sm="12">
                <v-data-table
                  v-model="trabajoOrdenesSeleccionados"
                  :headers="headers"
                  :items="items"
                  loading-text="Cargando... Espere, Por Favor."
                  :loading="loading"
                  class="elevation-0"
                  ref="orderots"
                  :items-per-page="50"
                  item-key="idTrabajoOrden"
                  show-select
                  dense
                  multi-sort
                  :footer-props="{
                    itemsPerPageOptions: [10, 50, 100, -1]
                  }"
                >
                  <template #header.data-table-select></template>
                  <template v-slot:item.estadoProduccionNum="{ item }">
                    <div v-if="item.estadoProduccionNum == 0"><v-icon color="gray" small>mdi-pause</v-icon>No Iniciado</div>
                    <div v-if="item.estadoProduccionNum == 1"><v-icon color="gray" small>mdi-hammer</v-icon>&nbsp;En Progreso</div>
                    <div v-if="item.estadoProduccionNum == 2"><v-icon color="gray" small>mdi-alert</v-icon>&nbsp;Pausado</div>
                    <div v-if="item.estadoProduccionNum == 3"><v-icon color="gray" small>mdi-check</v-icon>&nbsp;Completado</div>
                    <div v-if="item.estadoProduccionNum == 4"><v-icon color="gray" small>mdi-circle-half-full</v-icon>&nbsp;Producción Parcial</div>
                    <div v-if="item.estadoProduccionNum == 5"><v-icon color="gray" small>mdi-close</v-icon>&nbsp;Terminado Prematuramente</div>
                  </template>
                </v-data-table>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions class="d-flex justify-center">
          <v-btn
            color="error"
            class="mt-0"
            
            :disabled="savingOrdenar || this.trabajoOrdenesSeleccionados.length <= 0 "
						:loading="savingOrdenar"
            @click="saveOrden(-1)"
          >
            Borrar Masivamente
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn
            color="primary"
            class="mt-0"
            
            :disabled="savingOrdenar || this.trabajoOrdenesSeleccionados.length <= 0 || !puedeFinalizarPrematuramente"
            :loading="savingOrdenar"
            @click="saveOrden(0)"
          >
            Terminar prematuramente
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="dialogDelete" persistent max-width="600">
      <v-card>
        <v-card-title class="text-h5">
          ¿Esta seguro que desea {{ formTitle }} estas órdenes de trabajo?
        </v-card-title>
        <v-card-text>
          <ul>
            <li v-for="(ot, x) in trabajoOrdenesSeleccionados" :key="x">{{ ot.clave }}</li>
          </ul>
          <p style="color:red" class="text-center pt-5" >Nota: Esta acción tomará algunos minutos, durante los cuales aún podrá
          ver las Ordenes de Trabajo. Esta acción será permanente y no se puede
          deshacer.</p></v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="primary darken-1"
            :loading="savingOrdenar"
						:disabled="savingOrdenar"
            text
            @click="dialogDelete = false"
          >
            CANCELAR
          </v-btn>
          <v-btn
            color="error darken-1"
            :loading="savingOrdenar"
						:disabled="savingOrdenar"
            text
            @click="eliminarMasiveorPrematuramente()"
          >
            ACEPTAR
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import axios from "axios";

export default {
  data() {
    return {
      singleSelect: false,
      editedIndex: -1,
      trabajoOrdenesSeleccionados: [],
      loading: false,
      items: [],
      savingOrdenar: false,
      dialogActionsMasive: false,
      dialogDelete: false,
      headers: [
        {
          sortable: false,
        },
        {
          text: "Clave",
          align: "center",
          sortable: true,
          value: "clave",
        },
        {
          text: "Línea Comercial",
          align: "center",
          sortable: true,
          value: "comercialLinea",
        },
        {
          text: "Cliente",
          align: "center",
          sortable: true,
          value: "cliente",
        },
        {
          text: "Estado de Producción",
          align: "center",
          sortable: true,
          value: "estadoProduccionNum",
        },
        {
          text: "Fecha creación",
          align: "center",
          sortable: true,
          value: "fechaCreacion",
        }
      ],
    };
  },
  computed: {
    formTitle() {
      return this.editedIndex === -1 ? "borrar" : "terminar anticipadamente";
    },
    puedeFinalizarPrematuramente(){
      if(this.trabajoOrdenesSeleccionados.length <= 0) return false;
      return !this.trabajoOrdenesSeleccionados.some(x => x.estadoProduccionNum == 0);
    }
  },
  methods: {
    mostrarDialog() {
      this.dialogActionsMasive = true;
      this.getTrabajoOrdenes();
    },
    getTrabajoOrdenes() {
      this.loading = true;
      axios
        .get("/TrabajoOrdenes/TrabajoOrdenHeaders")
        .then((response) => {
          this.items = response.data;
          this.loading = false;
        })
        .catch((error) => {
          this.loading = false;
          console.log(error);
        });
    },
    cancelarCerrar() {
      this.items = [];
      this.dialogActionsMasive = false;
      this.singleSelect = false;
      this.editedIndex = -1;
      this.trabajoOrdenesSeleccionados = [];
      this.dialogDelete = false;
			this.savingOrdenar = false;
    },
    saveOrden(item) {
      this.editedIndex = item;
      this.dialogDelete = true;
    },
    eliminarMasiveorPrematuramente() {
      this.savingOrdenar = true;
			
      var idss = [];
      this.trabajoOrdenesSeleccionados.forEach((element) => {
        idss.push(element.idTrabajoOrden);
      });

      axios
        .put(
          this.editedIndex == -1
            ? "TrabajoOrdenes/DesactivarOTSMasivo"
            : "/TrabajoOrdenes/FinalizarPrematuramenteMasivo",
          idss
        )
        .then(() => {
          this.savingOrdenar = false;
          this.cancelarCerrar();
          this.$emit("reset");
        })
        .catch((error) => {
          console.log(error);
					this.savingOrdenar = false;
        });
      
    },
  },
};
</script>

<style></style>
