<template>
	<div>
		<v-col v-if="!mostrar" class="text-center">
			<p class="text-h5">Cargando</p>
			<v-progress-circular
				:size="60"
				color="primary"
				indeterminate
			></v-progress-circular>
		</v-col>
		<vl-map
			:load-tiles-while-animating="true"
			:load-tiles-while-interacting="true"
			ref="map"
			style="height: 400px"
			@click="agregarPuntoPre"
			@mounted="onMapMounted"
			v-if="mostrar"
		>
			<vl-view
				:projection="projection"
				:zoom.sync="zoom"
				:center.sync="center"
				:rotation.sync="rotation"
				:min-zoom="minZoom"
				:max-zoom="maxZoom"
			></vl-view>

			<vl-layer-image id="xkcd">
				<vl-source-image-static
					:url="imgUrl"
					:size="imgSize"
					:extent="imgExtent"
					:projection="projection"
				></vl-source-image-static>
			</vl-layer-image>

			<v-card
				style="
					position: absolute;
					right: 2.9rem;
					top: 11.9rem;
					background-color: rgba(0, 0, 0, 0);
					z-index: 1;
					border-radius: 0.25rem;
				"
				elevation="0"
			>
				<v-card-text class="pa-0">
					<v-col cols="12" class="pa-0">
						<v-tooltip color="black" top>
							<template v-slot:activator="{ on, attrs }">
								<v-btn
									@click="clickBtnVer()"
									class="my-1 px-0"
									small
									v-bind="attrs"
									v-on="on"
									:style="
										mode === 'ver'
											? 'background-color: #3d69a1'
											: 'background-color: #84a5cf'
									"
									style="min-width: 30px !important; width: 36px;"
								>
									<v-icon color="white" style="font-size: 20px">mdi-eye</v-icon>
								</v-btn>
							</template>
							<span class="white--text">Modo Vista</span>
						</v-tooltip>
					</v-col>
					<v-col cols="12" class="pa-0">
						<v-tooltip v-if="permisoVista('planos','u')" color="black" top>
							<template v-slot:activator="{ on, attrs }">
								<v-btn
									@click="modoEditar()"
									class="my-1"
									small
									v-bind="attrs"
									v-on="on"
									:style="
										mode === 'agregar'
											? 'background-color: #3d69a1'
											: 'background-color: #84a5cf'
									"
									style="min-width: 30px !important; width: 36px;"
								>
									<v-icon 
										color="white"
										style="font-size: 20px"
										>mdi-map-marker-plus</v-icon
									>
								</v-btn>
							</template>
							<span class="white--text">Modo Agregar</span>
						</v-tooltip>
					</v-col>
					<v-col cols="12" class="pa-0">
						<v-tooltip v-if="permisoVista('planos','d')" color="black" top>
							<template v-slot:activator="{ on, attrs }">
								<v-btn
									@click="modoBorrar()"
									class="my-1 px-0"
									small
									v-bind="attrs"
									v-on="on"
									:style="
										mode === 'borrar'
											? 'background-color: #3d69a1'
											: 'background-color: #84a5cf'
									"
									style="min-width: 30px !important; width: 36px;"
								>
									<v-icon 
										color="white"
										style="font-size: 20px"
										>mdi-map-marker-remove</v-icon
									>
								</v-btn>
							</template>
							<span class="white--text">Modo Borrar</span>
						</v-tooltip>
					</v-col>
				</v-card-text>
			</v-card>

			<vl-feature
				v-for="(punto, idx) in puntosPre"
				:key="'punto-' + idx"
				:properties="punto"
			>
				<vl-geom-point :coordinates="punto.posicion"></vl-geom-point>
				<vl-style-box>
					<vl-style-icon
						:src="require('@/assets/img/greenpoint.png')"
						:scale="0.1"
						:anchor="[0.5, 0.5]"
						:size="[240, 240]"
					></vl-style-icon>
				</vl-style-box>
				<!-- <vl-interaction-select
					v-if="mode == 'agregar'"
					ref="addInteraction"
					:features.sync="selectedFeaturesPre"
				>
					<template slot-scope="selection"> -->
						<vl-overlay
							:position="posicionSurface(punto.posicion)"
							:auto-pan="true"
							:auto-pan-animation="{ duration: 300 }"
						>
							<template slot-scope="popup">
								<v-card>
									<v-card-title> Marcar Área </v-card-title>
									<v-card-text>
										<v-autocomplete
											v-model="currentPoint.idArea"
											:items="areas"
											item-text="nombre"
											item-value="idArea"
											label="Área"
										></v-autocomplete>
										<v-autocomplete
											v-model="currentPoint.idMaquinaIns"
											:items="
												instanciasxArea(currentPoint.idArea)
											"
											:item-text="marcaModelo"
											item-value="idMaquinaIns"
											label="[Opcional] Máquina"
											hide-details
											clearable
										></v-autocomplete>
									</v-card-text>
									<v-card-actions>
										<v-spacer></v-spacer>
										<v-btn
											text
											color="green"
											small
											@click="removerPuntoPre()"
											>Cancelar</v-btn
										>
										<v-btn
											text
											color="red"
											small
											:disabled="currentPoint.idArea <= 1"
											@click="agregarPunto()"
											>Guardar</v-btn
										>
									</v-card-actions>
								</v-card>
							</template>
						</vl-overlay>
					<!-- </template>
				</vl-interaction-select> -->
			</vl-feature>

			<vl-feature
				v-for="(punto, idx) in puntos"
				:key="'punto-' + idx"
				:properties="punto"
			>
				<vl-geom-point :coordinates="punto.posicion"></vl-geom-point>
				<vl-style-box>
					<vl-style-icon
						v-if="punto.color === 'red'"
						:src="require('@/assets/img/redpoint.png')"
						:scale="0.1"
						:anchor="[0.5, 0.5]"
						:size="[240, 240]"
					></vl-style-icon>
					<vl-style-icon
						v-if="punto.color === 'yellow'"
						:src="require('@/assets/img/yellowpoint.png')"
						:scale="0.1"
						:anchor="[0.5, 0.5]"
						:size="[240, 240]"
					></vl-style-icon>
					<vl-style-icon
						v-if="punto.color === 'green'"
						:src="require('@/assets/img/greenpoint.png')"
						:scale="0.1"
						:anchor="[0.5, 0.5]"
						:size="[240, 240]"
					></vl-style-icon>
				</vl-style-box>
				<vl-interaction-select
					v-if="mode == 'ver' || mode == 'borrar'"
					ref="viewInteraction"
					:features.sync="selectedFeatures"
				>
					<template slot-scope="selection">
						<vl-overlay
							v-for="feature in selectedFeatures"
							:key="feature.id"
							:id="feature.id"
							:position="pointOnSurface(feature.geometry)"
							:auto-pan="true"
							:auto-pan-animation="{ duration: 300 }"
						>
							<template slot-scope="popup">
								<v-card>
									<v-card-title
										class="d-flex justify-space-between"
									>
										Área
										<v-btn
											v-if="mode == 'borrar'"
											color="red"
											dark
											small
											class="my-0"
											@click="
												removerPunto(feature.properties)
											"
											>Borrar</v-btn
										>
										<p
											v-if="mode == 'ver'"
											class="blue--text my-2"
											style="cursor: pointer;"
											@click="verDetalles(feature.properties.idArea)"
											>Ver detalles</p
										>
									</v-card-title>
									<v-card-text>
										<v-autocomplete
											v-model="feature.properties.idArea"
											:items="areas"
											item-text="nombre"
											item-value="idArea"
											label="Área"
											readonly
										></v-autocomplete>
										<v-autocomplete
											v-if="
												feature.properties.idMaquinaIns !==
													null &&
												feature.properties.idMaquinaIns !==
													undefined
											"
											v-model="feature.properties.idMaquinaIns"
											:items="
												instanciasxArea(
													feature.properties.idArea
												)
											"
											:item-text="marcaModelo"
											item-value="idMaquinaIns"
											label="Máquina"
											hide-details
											readonly
										></v-autocomplete>
									</v-card-text>
								</v-card>
							</template>
						</vl-overlay>
					</template>
				</vl-interaction-select>
			</vl-feature>
		</vl-map>
	</div>
</template>

<script>
import { createProj, addProj } from "vuelayers/lib/ol-ext";
import axios from "axios";
import {ScaleLine, FullScreen, OverviewMap, ZoomSlider} from 'ol/control';
import { findPointOnSurface, createStyle } from "vuelayers/lib/ol-ext";
import { mapState } from 'vuex';

// Map views always need a projection.  Here we just want to map image
// coordinates directly to map coordinates, so we create a projection that uses
// the image extent in pixels.
let size = [1280, 720];
let extent = [0, 0, ...size];
// create custom projection for image
// NOTE: VueLayers.olExt available only in UMD build
// in ES build it should be imported explicitly from 'vuelayers/lib/ol-ext'
let customProj = createProj({
	code: "xkcd-image",
	units: "pixels",
	extent,
});
// add it to the list of known projections
addProj(customProj);

export default {
	props: {
		actualPlano: {
			type: Object,
			default: () => {}
		}
	},
	data() {
		return {
			idPlano: -1,
			mostrar: true,
			zoom: 0.7,
			maxZoom: 5,
			minZoom: 0.7,
			currentPoint: {
				idArea: -1,
				idMaquinaIns: null,
			},
			areas: [],
			center: [size[0] / 2, size[1] / 2],
			rotation: 0,
			projection: customProj.getCode(),
			imgUrl: "",
			imgSize: size,
			imgExtent: extent,
			puntos: [],
			puntosPre: [],
			btnColor: "green",
			mode: "ver",
			selectedFeatures: [],
			instancias: [],
			selectedFeaturesPre: [],
			imgFile: null,
			loading: false,
			interval: null,
		};
	},
	computed:{
		...mapState({
      token: 'token'
    }),
	},
	mounted(){
		this.interval = setInterval(() => {
			this.getPuntos();
		},30000);
	},
	methods: {
		pointOnSurface: findPointOnSurface,
		verDetalles(idArea){
			this.$emit( "verIncidencia",idArea );
		},
		posicionSurface(coordenadas){
			const geometry = { 
				type: "Point", 
				coordinates: coordenadas 
			}
			return this.pointOnSurface(geometry);
		},
		marcaModelo: (item) => item.marca.trim() + " - " + item.modelo.trim(),
		onMapMounted() {
			// now ol.Map instance is ready and we can work with it directly
			this.$refs.map.$map.getControls().extend([
				new ZoomSlider(),
			]);
		},
		removerPunto(punto) {
			axios
				.put( `/Planos/EliminarPunto/${punto.idPlanoPunto}` )
				.then( () => {
					this.selectedFeatures = [];
					this.refreshPuntos();
				})
				.catch( error => {
					console.log( error );
				})
			// this.puntos = this.puntos.filter(
			// 	(point) => point.idPlanoPunto !== punto.idPlanoPunto
			// );
		},
		refreshPuntos(){
			this.$emit( 'refresh',this.idPlano );
		},
		instanciasxArea(idArea) {
			return this.instancias.filter(
				(instancia) => instancia.idArea === idArea
			);
		},
		clickBtnVer() {
			this.removerPuntoPre();
			this.mode = "ver";
		},
		modoEditar() {
			this.mode = "agregar";
		},
		modoBorrar() {
			this.mode = "borrar";
		},
		removerPuntoPre() {
			// selectedFeatures =
			// 	selectedFeatures.filter( (f) => f.id !== feature.id )
			this.selectedFeaturesPre = [];
			this.puntosPre = [];
			this.currentPoint.idArea = -1;
			this.currentPoint.idMaquinaIns = null;
		},
		getInstancias() {
			axios
				.get("/MaquinaInstancias")
				.then((response) => {
					this.instancias = response.data;
				})
				.catch((error) => {
					console.log(error);
				});
		},
		getAreas() {
			axios
				.get("/Areas")
				.then((response) => {
					this.areas = response.data.filter(
						(area) => area.idArea > 1
					);
				})
				.catch((error) => {
					console.log(error);
				});
		},
		agregarPunto() {
			if (this.mode === "agregar") {
				const punto = {
					idPlano: this.idPlano,
					idArea: this.currentPoint.idArea,
					idMaquinaIns: this.currentPoint.idMaquinaIns,
					posicion: this.puntosPre[0].posicion,
				};
				
				axios
					.post( '/Planos/CrearPunto',punto )
					.then( () => {
						this.removerPuntoPre();
						this.refreshPuntos();
					})
					.catch( error => {
						console.log( error );
						this.removerPuntoPre();
					})
			}
		},
		agregarPuntoPre(clkEvent) {
			if (this.mode === "agregar") {
				this.puntosPre = [];
				this.puntosPre.push({
					posicion: clkEvent.coordinate,
					color: this.btnColor,
				});
			}
		},
		getPlanoImagen(plano){
			this.mostrar = false;
			this.imgUrl = "/api/planos/imagen/"+plano.ruta+'?access_token='+this.token;
			this.imgSize = plano.resolucion;
			this.center = [this.imgSize[0] / 2, this.imgSize[1] / 2];
			this.imgExtent = [0, 0, ...this.imgSize];
			let customProj = createProj({
				code: "xkcd-image",
				units: "pixels",
				extent: this.imgExtent,
			});
			addProj(customProj);
			this.projection = customProj.getCode(),
			this.puntos = plano.puntos;
			this.idPlano = plano.idPlano;
			this.zoom = 0.7;
			setTimeout(() => {
				this.mostrar = true;
			}, 1000);
			this.$refs.map.refresh();
		},
		initialize() {
			this.getAreas();
			this.getInstancias();
		},
		actualizarPuntos(){
			if(!this.loading) this.getPuntos();
		},
		getPuntos(){
			this.loading = true;
			if(!(this.idPlano >= 1)) return;
			axios
			.get("/Planos/ObtenerPlanoPuntos/"+this.idPlano)
			.then(response => {
				this.puntos = response.data;
				this.loading = false;
			})
			.catch(error => {
				console.log(error);
				this.loading = false;
			})
		}
	},
	destroyed(){
		clearInterval(this.interval);
	},
	created() {
		this.initialize();
	},
};
</script>