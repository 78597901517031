<template>
	<v-col cols="12">
		<v-row>
			<v-col cols="4" md="4" sm="4" class="align-self-end">
				<datePicker
					v-model="desde"
					format="YYYY-MM-DD"
					label="Desde (Obligatorio)"
					clearable
					maxToday
					@input="
						getPersonas();
						obtenerDatos();
					"
					:disabled="cargando"
				></datePicker>
			</v-col>
			<v-col cols="4" md="4" sm="4" class="align-self-end">
				<datePicker
					v-model="hasta"
					:minDate="desde"
					format="YYYY-MM-DD"
					label="Hasta (Obligatorio)"
					clearable
					maxToday
					@input="
						getPersonas();
						obtenerDatos();
					"
					:disabled="cargando"
				></datePicker>
			</v-col>
			<v-col cols="4">
				<v-autocomplete
					v-model="idsPersonas"
					label="Operador(es) (Opcional)"
					:items="personas"
					item-value="idPersona"
					:item-text="nombrePersona"
					hide-details
					multiple
					clearable
					chips
					deletable-chips
					@change="obtenerDatos"
					:loading="cargando"
				></v-autocomplete>
			</v-col>
			<v-col cols="4">
				<v-autocomplete
					v-model="idArea"
					label="Área (Opcional)"
					:items="areas"
					item-value="idArea"
					item-text="nombre"
					hide-details
					clearable
					:disabled="cargando"
					@change="obtenerDatos"
				></v-autocomplete>
			</v-col>
			<v-col cols="4">
				<v-autocomplete
					v-model="idTurno"
					:items="turnos"
					item-text="nombre"
					item-value="idTurno"
					label="Turno (Opcional)"
					hide-details
					clearable
					@change="obtenerDatos"
					:disabled="cargando"
				></v-autocomplete>
			</v-col>
		</v-row>
		<v-row v-if="desde != null && hasta != null">
			<v-col cols="4">
				<download-btn
					color="primary"
					text
					@click="descargarReporte"
					label="Descargar Reporte de<br>Eficiencia de Operadores"
				/>
			</v-col>
		</v-row>
		<v-row>
			<v-col cols="12">
				<v-progress-linear v-if="cargando" color="primary" height="5" indeterminate ></v-progress-linear>
				<apexchart
					type="bar"
					height="350"
					:options="chartOptions"
					:series="series"
				></apexchart>
			</v-col>
		</v-row>
	</v-col>
</template>

<script>
import axios from "axios";
import DownloadBtn from '../../DownloadBtn.vue';
import { mapState } from 'vuex';
import moment from 'moment';
export default {
	components: { DownloadBtn },
	data() {
		return {
			categories: [],
			series: [],
			desde: null,
			hasta: null,
			idArea: null,
			areas: [],
			idTurno: null,
			turnos: [],
			idsPersonas: [],
			personas: [],
			cargando: false,
			chartOptions: {
				chart: {
					type: "bar",
					height: 350,
					zoom: {
						enabled: true,
					},
				},
				dataLabels: {
					enabled: false,
				},
				colors: [() => this.getColor()],
				markers: {
					size: 1,
				},
				stroke: {
					show: true,
					width: 2,
					colors: ["transparent"],
				},
				xaxis: {
					categories: [],
				},
				fill: {
					opacity: 1,
				},
			},
			chartOptionsDefault: {
				chart: {
					type: "bar",
					height: 350,
					zoom: {
						enabled: true,
					},
				},
				// plotOptions: {
				// 	bar: {
				// 		dataLabels: {
				// 			position: "top", // top, center, bottom
				// 		},
				// 	},
				// },
				dataLabels: {
					enabled: false,
					// formatter: function (val) {
					// 	return val.toFixed(2) + "%";
					// },
					// offsetY: -20,
					// style: {
					// 	fontSize: "12px",
					// 	colors: ["#304758"],
					// },
				},
				colors: [() => this.getColor()],
				markers: {
					size: 1,
				},
				stroke: {
					show: true,
					width: 2,
					colors: ["transparent"],
				},
				xaxis: {
					categories: [],
				},
				yaxis: {
					labels: {
						formatter: function (val, index) {
							return val.toFixed(2) + " %";
						},
					},
				},
				tooltip: {
					y: {
						formatter: function (val) {
							return val.toFixed(2) + " %";
						},
					},
				},
				fill: {
					opacity: 1,
				},
			},
			colors: [
				"#008FFB",
				"#00E396",
				"#FEB019",
				"#FF4560",
				"#775DD0",
				"#4CAF50",
				"#FF9800",
				"#1B998B",
				"#FA4443",
				"#A300D6",
			],
			colorsDummy: [
				"#008FFB",
				"#00E396",
				"#FEB019",
				"#FF4560",
				"#775DD0",
				"#4CAF50",
				"#FF9800",
				"#1B998B",
				"#FA4443",
				"#A300D6",
			],
		};
	},
	mounted() {
		this.initialize();
	},
	computed:{
		...mapState(["token"])
	},
	methods: {
		descargarReporte(){
			var datetime = moment().format('YYYYMMDDHHmmss');
			let url = `/Reportes/ReporteEficienciaOperadorXlsx?access_token=${this.token}`;
			if(this.desde != null ) url += `&desde=${this.desde}`;
			if(this.hasta != null ) url += `&hasta=${this.hasta}`;
			if(this.idArea != null ) url += `&idArea=${this.idArea}`;
			if(this.idTurno != null ) url += `&idTurno=${this.idTurno}`;
			if (this.idsPersonas.length > 0) {
				this.idsPersonas.forEach(persona => {
					url += `&idsOperadores=${persona}`;
				});
			}
      		this.$utils.axios.downloadFile(url,'GET','operadores-eficiencia-'+datetime+'.xlsx','application/vnd.openxmlformats-officedocument.spreadsheetml.sheet');
		},
		getColor() {
			if (this.colorsDummy.length == 0) {
				this.colorsDummy = this.colors.map((color) => color);
			}
			return this.colorsDummy.pop();
		},
		nombrePersona: (persona) =>
			persona.nombre + " " + persona.paterno + " " + persona.materno,
		getPersonas() {
			if (this.desde == null || this.hasta == null) {
				this.resetValues();
				return;
			}
			this.idsPersonas = [];
			this.personas = [];
			axios
				.get("/Personas/OperadoresRendimientoIntervalo", {
					params: {
						desde: this.desde,
						hasta: this.hasta,
					},
				})
				.then((response) => {
					this.personas = response.data;
				})
				.catch((error) => {
					console.log(error);
				});
		},
		obtenerDatos() {
			if (this.desde == null || this.hasta == null || this.cargando) {
				this.resetValues();
				this.idArea = null;
				this.idTurno = null;
				return;
			}

			this.cargando = true;

			let url = "/Reportes/ReporteEficienciaOperador";
			if (this.idsPersonas.length > 0) {
				this.idsPersonas.forEach((persona, idx) => {
					url +=
						idx == 0
							? `?idsOperadores=${persona}`
							: `&idsOperadores=${persona}`;
				});
			}

			axios
				.get(url, {
					params: {
						desde: this.desde,
						hasta: this.hasta,
						idArea: this.idArea,
						idTurno: this.idTurno,
					},
				})
				.then((response) => {
					var chartOptions = Object.assign(
						{},
						this.chartOptionsDefault
					);
					chartOptions.colors = [() => this.getColor()];
					chartOptions.xaxis.categories = response.data.operadores;
					this.chartOptions = chartOptions;
					this.series = [];
					this.series = response.data.series;
					this.cargando = false;
				})
				.catch((error) => {
					this.cargando = false;
					this.series = [];
					this.colorsDummy = this.colors.map((color) => color);
					console.log(error);
				});
		},
		resetValues() {
			this.personas = [];
			this.idsPersonas = [];
			this.categories = [];
			this.series = [];
			this.chartOptions = Object.assign({},this.chartOptionsDefault);
			this.chartOptions.xaxis.categories = [];
			this.colorsDummy = this.colors.map((color) => color);
		},
		initialize() {
			this.getAreas();
			this.getTurnos();
		},
		getAreas() {
			axios
				.get("/Areas")
				.then((response) => {
					this.areas = response.data.filter(
						(area) => area.idArea > 1
					);
				})
				.catch((error) => {
					console.log(error);
				});
		},
		getTurnos() {
			axios
				.get("/Turnos")
				.then((response) => {
					this.turnos = response.data;
				})
				.catch((error) => {
					console.log(error);
				});
		},
	},
};
</script>