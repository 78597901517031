<template>
	<v-container fluid>
        <v-row>
            <v-col cols="3">
                <MenuDatepicker
					v-model="filtros.desde"
					label="[Obligatorio] Desde"
                    :max-date="filtros.hasta"
					clearable
					hide-details
					:disabled="loading"
					@input="getData()"
				/>
            </v-col>
            <v-col cols="3">
                <MenuDatepicker
					v-model="filtros.hasta"
					label="[Obligatorio] Hasta"
                    :min-date="filtros.desde"
					clearable
					hide-details
					:disabled="loading"
					@input="getData()"
				/>
            </v-col>
            <v-col cols="3" class="pt-4">
				<v-autocomplete
					ref="ubicacionesSelector"
					v-model="filtros.idsUbicaciones"
					label="[Opcional] Ubicaciones"
					hide-details
					clearable
					:items="ubicaciones"
					item-value="idUbicacion"
					item-text="nombre"
					multiple
					small-chips
					deletable-chips
					:disabled="loading || loadingUbicaciones"
					:loading="loadingUbicaciones"
					@change="onChangeUbicaciones"
				></v-autocomplete>
			</v-col>
            <v-col cols="3">
				<v-select
					v-model="filtros.idTipoUnidad"
					label="[Opcional] Tipo de Unidad"
					hide-details
					clearable
					:items="$utils.mantenimientos.tiposTransporte"
					:disabled="loading"
					@change="getData()"
				></v-select>
			</v-col>
        </v-row>
        <v-row class="justify-end">
            <v-col cols="2" class="py-0 text-right">
                <v-btn
                    color="primary"
                    :disabled="loading || !hayFechasValidas"
                    @click="getData()"
                >
                    <v-icon left>mdi-refresh</v-icon>Refrescar
                </v-btn>
            </v-col>
        </v-row>
		<v-row class="mt-0">
			<v-col cols="12">
				<v-simple-table
                    class="custom-table"
                    :class="{ 'custom-height': hayData }"
                    fixed-header
                >
					<template v-slot:default>
						<thead>
							<tr>
								<template v-for="(head, idx) in headers">
                                    <template v-if="head.value == 'comprobaciones'">
                                        <template v-for="headerComp,idxHeadComp in headersComprobaciones">
                                            <th
                                                class="text-center header-font-size white--text"
                                                :class="{
                                                    'header-color-critico': esHeaderCritico(headerComp.value),
                                                    'header-color-comprobacion': !esHeaderCritico(headerComp.value)
                                                }"
                                                :key="`headerComp-${idx}-${idxHeadComp}`"
                                            >
                                                <span class="vertical-text">{{ headerComp.text }}</span>
                                            </th>
                                        </template>
                                    </template>
                                    <th
                                        v-else-if="head.value == 'fecha'"
                                        class="text-center header-font-size white--text header-color-base"
                                        style="min-width: 150px"
                                        :key="`head-${idx}`"
                                    >
                                        {{ head.text }}
                                    </th>
                                    <th
                                        v-else
                                        class="text-center header-font-size white--text header-color-base"
                                        :key="`head-${idx}`"
                                    >
                                        {{ head.text }}
                                    </th>
                                </template>
							</tr>
						</thead>
						<thead v-if="loading">
							<tr class="v-data-table__progress">
								<th :colspan="headersLength" class="column">
									<div
										role="progressbar"
										aria-valuemin="0"
										aria-valuemax="100"
										class="v-progress-linear v-progress-linear--absolute v-progress-linear--visible theme--light"
										style="height: 4px"
									>
										<div
											class="v-progress-linear__background primary"
											style="
												opacity: 0.3;
												left: 0%;
												width: 100%;
											"
										></div>
										<div
											class="v-progress-linear__buffer"
										></div>
										<div
											class="v-progress-linear__indeterminate v-progress-linear__indeterminate--active"
										>
											<div
												class="v-progress-linear__indeterminate long primary"
											></div>
											<div
												class="v-progress-linear__indeterminate short primary"
											></div>
										</div>
									</div>
								</th>
							</tr>
						</thead>
						<tbody>
							<template v-if="hayData">
								<tr
									v-for="(item, idxTr) in data"
									:key="`tr-${idxTr}`"
								>
									<template v-for="(head, idxTd) in headers">
                                        <template v-if="head.value == 'comprobaciones'">
                                            <template v-for="comp,idxComp in item[head.value]">
                                                <td
                                                    class="text-center"
                                                    :class="{
                                                        'background-error error--text font-weight-bold': comp.promedio != 100 && esHeaderCritico(comp.nombre)
                                                    }"
                                                    :key="`tdComp-${idxTd}-${idxComp}`"
                                                >
                                                    {{ comp.promedio }}%
                                                </td>
                                            </template>
                                        </template>
                                        <td
                                            v-else-if="head.value == 'kilometraje'"
                                            class="text-center"
                                            :key="`td-${idxTd}`"
                                        >
                                            {{ $utils.strings.formatearCifra(item[head.value]) }}
                                        </td>
                                        <td
                                            v-else-if="head.value == 'status'"
                                            class="text-center px-0"
                                            :key="`td-${idxTd}`"
                                        >
                                            <v-progress-linear
                                                class="custom-progress-linear"
                                                :value="item[head.value]"
                                                :color="getColorEstatus(item[head.value])"
                                                style="margin-top: 2px"
                                                height="96%"
                                            >
                                                <template v-slot:default="{ value }">
                                                    <p class="mb-0 font-weight-bold" style="font-size: 14px !important">{{ value }}%</p>
                                                </template>
                                            </v-progress-linear>
                                        </td>
                                        <td
                                            v-else-if="head.value == 'circulacion'"
                                            class="text-center font-weight-bold"
                                            :class="{
                                                'background-error error--text': !item[head.value],
                                                'background-success success--text': item[head.value]
                                            }"
                                            :key="`td-${idxTd}`"
                                        >
                                            {{ item[head.value] ? 'SI' : 'NO' }}
                                        </td>
										<td v-else class="text-center" :key="`td-${idxTd}`">
											{{ item[head.value] }}
										</td>
									</template>
								</tr>
                                <tr>
                                    <td
                                        :colspan="headersLength - 2"
                                        class="background-celda-promedio text-center font-weight-bold"
                                    >
                                        Promedio
                                    </td>
                                    <td 
                                        :colspan="2"
                                        class="background-celda-promedio text-center font-weight-bold"
                                        style="border-left: thin solid rgba(0,0,0,.12);"
                                    >
                                        {{ promedioCalculado }}%
                                    </td>
                                </tr>
							</template>
                            <tr v-else-if="!hayData && !loading">
                                <td
                                    :colspan="headersLength"
                                    class="text-center td-text-secondary"
                                >
                                    No hay datos disponibles
                                </td>
                            </tr>
                            <tr v-else-if="!hayData && loading">
                                <td
                                    :colspan="headersLength"
                                    class="text-center td-text-secondary"
                                >
                                    Cargando...
                                </td>
                            </tr>
						</tbody>
					</template>
				</v-simple-table>
			</v-col>
		</v-row>
	</v-container>
</template>

<script>
import axios from 'axios';
import MenuDatepicker from '../../MenuDatepicker.vue';
import qs from "qs";

export default {
	components: { MenuDatepicker },
	data() {
		return {
            ubicaciones: [],
            loadingUbicaciones: false,
            filtros: {
                idTipoUnidad: 2,
                idsUbicaciones: [],
                desde: null,
                hasta: null
            },
            headersCriticos: [
                "dirección hidraulica",
                "ejes",
                "frenos"
            ],
            headersComprobaciones: [
                {
                    text: "Documentación general",
                    value: "documentación general",
                },
                {
                    text: "Basicos",
                    value: "basicos",
                },
                {
                    text: "Agua",
                    value: "agua",
                },
                {
                    text: "Combustible",
                    value: "combustible",
                },
                {
                    text: "Dirección hidraulica",
                    value: "dirección hidraulica",
                },
                {
                    text: "Transmision",
                    value: "transmision",
                },
                {
                    text: "Sistema de admisión y escape",
                    value: "sistema de admisión y escape",
                },
                {
                    text: "Aire",
                    value: "aire",
                },
                {
                    text: "Ejes",
                    value: "ejes",
                },
                {
                    text: "Suspensión",
                    value: "suspensión",
                },
                {
                    text: "Frenos",
                    value: "frenos",
                },
                {
                    text: "Electrico",
                    value: "electrico",
                },
                {
                    text: "Varios",
                    value: "varios",
                },
                {
                    text: "Herramienta",
                    value: "herramienta",
                },
            ],
			data: [],
			headers: [
				{
					text: "Ubicación",
					value: "ubicacion",
				},
				{
					text: "Unidad",
					value: "unidad",
				},
				// {
				// 	text: "KM",
				// 	value: "kilometraje",
				// },
				{
					text: "Comprobaciones",
					value: "comprobaciones",
				},
				{
					text: "Estatus",
					value: "status",
				},
				{
					text: "Circulación",
					value: "circulacion",
				},
			],
			loading: false,
		};
	},
    computed: {
        promedioCalculado(){
            if(!this.hayData) return 0;
            var suma = this.data.reduce( (acc,item) => acc + item.status,0);
            return Math.ceil(suma / this.data.length);
        },
        hayFechasValidas(){
            return this.filtros.desde && this.filtros.hasta;
        },
        headersLength(){
            return this.headers.length + this.headersComprobaciones.length - 1;
        },
        hayData(){
            return this.data.length > 0;
        }
    },
    mounted(){
        this.initialize();
    },
    methods: {
        getColorEstatus(status){
            if(status < 70) return "error";
            else if(status >= 70 && status <= 80) return "amber";
            else return "success";
        },
        onChangeUbicaciones(){
			this.$refs.ubicacionesSelector.blur();
			this.getData();
		},
        esHeaderCritico(headerValue){
            return this.headersCriticos.includes(headerValue.toLowerCase());
        },
        initialize(){
            this.getData();
            this.getUbicaciones();
        },
        getUbicaciones(){
			this.loadingUbicaciones = true;

			axios
				.get('/Ubicaciones')
				.then( response => {
					this.ubicaciones = response.data;
				})
				.catch(console.log)
				.finally( () => {
					this.loadingUbicaciones = false;
				});
		},
        getData(){
            if(!this.hayFechasValidas) return;

            this.loading = true;
            axios
                .get('/Mantenimientos/GetReporteRevisionUnidadesViajePromedio',{
                    params: this.filtros,
                    paramsSerializer: (params) =>
                        qs.stringify(params, { indices: false, skipNulls: true })
                })
                .then((response) => {
                    this.data = response.data;
                })
                .catch(console.log)
                .finally(() => {
                    this.loading = false;
                });
        }
    }
};
</script>
<style scoped>
.vertical-text {
	writing-mode: vertical-rl;
	transform: rotate(180deg);
	text-align: center;
	white-space: normal;
}

.background-error {
    background-color: #ffcdd2;
}

.background-success {
    background-color: #c8e6c9;
}

.custom-progress-linear >>> .v-progress-linear__background {
    height: inherit;
}

.header-font-size {
    font-size: 17px !important;
}

.header-color-base {
    background-color: #002060 !important;
}

.header-color-comprobacion {
    background-color: #757575 !important;
}

.header-color-critico {
    background-color: #D32F2F !important;
}

.td-text-secondary {
    font-size: 1rem !important;
    color: rgba(0, 0, 0, 0.38);
}

.custom-height >>> .v-data-table__wrapper {
  max-height: 720px;
}

.background-celda-promedio {
    background-color: rgb(255 235 156 / 60%);
    color: #9C6500;
}
</style>