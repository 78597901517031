<template>
  <v-col cols="12" class="mt-4">
    <template>
      <v-row justify="center">
        <v-dialog v-model="eliminar" persistent max-width="550px" :key="eliminar">
          <v-card>
            <v-card-title class="headline" style="word-break: break-word;">¿Está seguro que desea eliminar este Motivo de Incidente?</v-card-title>
            <v-card-text>Esta acción no se puede revertir y será permanente.</v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="blue darken-1" text @click="eliminar = false; delItem = '';">Cancelar</v-btn>
              <v-btn color="red darken-1" text @click="eliminar = false; deleteItemDB()">Aceptar</v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-row>
    </template>
    <v-data-table
      v-if="permisoVista('parotipos','r')"
      :headers="headers"
      :items="paroTipos"
      :search="search"
      :loading="loading"
      loading-text="Cargando... Espere, Por Favor."
    >
      <template v-slot:top>
        <v-toolbar flat color="white">
          <v-dialog v-model="dialog" persistent max-width="500px" :key="dialog">
            <template v-slot:activator="{ on }">
              <v-btn color="primary" dark class="mb-2" v-on="on" v-if="permisoVista('parotipos','c')">Nuevo Motivo de Incidente</v-btn>
            </template>
            <v-card>
              <v-card-title>
                <span class="headline">{{ formTitle }}</span>
              </v-card-title>
              <v-card-text>
                <v-container>
                  <v-row>
                    <v-col justify="center" cols="12" sm="12" md="12">
                      <v-autocomplete
                        v-model="editedItem.idOEEFactor"
                        :items="factores"
                        item-text="nombre"
                        item-value="idOEEFactor"
                        label="Factor OEE"
                      ></v-autocomplete>
                    </v-col>
                    <v-col justify="center" cols="12" sm="12" md="12">
                      <v-text-field v-model="editedItem.nombre" label="Nombre del Motivo de Incidente"></v-text-field>
                    </v-col>
                    <v-col justify="center" cols="12" sm="12" md="12">
                      <v-text-field v-model="editedItem.codigo" label="Código del Motivo de Incidente (Opcional)"></v-text-field>
                    </v-col>
                    <v-col cols="6" sm="6" md="6">
                      <p class="mb-0">
                        ¿Llamado a Mantenimiento?
                      </p>
                      <div class="d-flex">
                        <v-btn-toggle
                          v-model="editedItem.mantenimiento"
                          class="my-3"
                        >
                          <v-btn
                            depressed
                            small
                            class="ma-0"
                            :color="
                              editedItem.mantenimiento == 0
                                ? 'success'
                                : 'grey lighten-1'
                            "
                            >Si</v-btn
                          >
                          <v-btn
                            depressed
                            small
                            class="ma-0"
                            :color="
                              editedItem.mantenimiento == 1
                                ? 'error'
                                : 'grey lighten-1'
                            "
                            >No</v-btn
                          >
                        </v-btn-toggle>
                      </div>
                    </v-col>
                    <v-col cols="6" sm="6" md="6"
                      v-if="editedItem.idOEEFactor == 2"
                    >
                      <p class="mb-0">
                        ¿Es un Paro Programado?
                      </p>
                      <div class="d-flex">
                        <v-btn-toggle
                          v-model="editedItem.paroProgramado"
                          class="my-3"
                          @change="onChangeSwitchParo"
                        >
                          <v-btn
                            depressed
                            small
                            class="ma-0"
                            :color="
                              editedItem.paroProgramado == 0
                                ? 'success'
                                : 'grey lighten-1'
                            "
                            >Si</v-btn
                          >
                          <v-btn
                            depressed
                            small
                            class="ma-0"
                            :color="
                              editedItem.paroProgramado == 1
                                ? 'error'
                                : 'grey lighten-1'
                            "
                            >No</v-btn
                          >
                        </v-btn-toggle>
                      </div>
                    </v-col>
                    <v-col v-if="!editedItem.mantenimiento && whatsapp == true" cols="12">
                      <v-row>
                        <v-col cols="12">
                          <v-autocomplete 
                              v-model="selectedUsuario"
                              label="Usuarios a Notificar"
                              :items="filteredUsuariosVinculados"
                              item-value="idUsuario"
                              item-text="nombre"
                              @change="onSelectUsuario"
                              :key="ups"
                             >
                        </v-autocomplete>
                      </v-col>
                        <v-col cols="12" v-if="whatsapp == true">
                          <v-list :key="ups">
                            <v-list-item v-for="(usuario, index) in editedItem.alertaUsuarios" :key="index">
                              <v-list-item-content>
                                <v-list-item-title>{{ usuario.nombre }}</v-list-item-title>
                                <v-list-item-subtitle v-if="usuario.departamento">
                                  Departamento: {{ usuario.departamento }}
                                </v-list-item-subtitle>
                                <v-list-item-subtitle v-else>Sin Departamento</v-list-item-subtitle>
                              </v-list-item-content>
                        
                              <!-- Ícono para eliminar el usuario -->
                              <v-list-item-action>
                                <v-icon @click="onRemoveUsuario(usuario)">
                                  mdi-trash-can
                                </v-icon>
                              </v-list-item-action>
                            </v-list-item>
                          </v-list>
                        </v-col>
                      </v-row>
                    </v-col>
                    <v-col v-if="editedItem.paroProgramado == 0" cols="12" class="mb-3">
                      <TimePicker
                        v-model="editedItem.tiempoEstimado"
                        label="Tiempo Aproximado del Paro (HH:MM:SS)"
                        :error="errorTiempo"
                        @focus="errorTiempo = false"
                      />
                    </v-col>
                  </v-row>
                  <ul>
                    <li class="red--text" v-for="ex in errores" :key="ex">{{ ex }}</li>
                  </ul>
                </v-container>
              </v-card-text>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="blue darken-1" text :disabled="saving" @click="close(); dialog=false; errores=[];">Cancelar</v-btn>
                <v-btn color="red darken-1" text @click="save()" :disabled="saving" :loading="saving">
                  Guardar
                  <template v-slot:loader>
                    <v-progress-circular
                      indeterminate
                      :width="2"
                      :size="24"
                      color="red"
                    ></v-progress-circular>
                  </template>
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
          <v-spacer></v-spacer>
          <v-text-field
            v-model="search"
            append-icon="search"
            label="Buscar"
            single-line
            hide-details
          ></v-text-field>
        </v-toolbar>
      </template>
      <template v-slot:[`item.nombre`]="{ item }">
        {{ item | nombreFormated }}
      </template>
      <template v-slot:[`item.oeeFactor`]="{ item }">
        {{ item.oeeFactor || 'N/A' }}
      </template>
      <template v-slot:[`item.mantenimiento`]="{ item }">
        {{ item.mantenimiento ? 'NO' : 'SI' }}
      </template>
      <template v-slot:[`item.paroProgramado`]="{ item }">
        {{ item.paroProgramado ? 'NO' : 'SI' }}
      </template>
      <template v-slot:[`item.action`]="{ item }">
        <v-tooltip bottom color="black" class="white--text">
          <template v-slot:activator="{ on }">
        <v-icon v-on="on"
          v-if="permisoVista('parotipos','u')"
          small
          class="mr-2"
          @click="editItem(item)"
        >
          edit
        </v-icon>
         </template>
          <span class="white--text">Editar</span>
        </v-tooltip>
        <v-tooltip bottom color="black" class="white--text">
          <template v-slot:activator="{ on }">
        <v-icon v-on="on"
          v-if="permisoVista('parotipos','d')"
          small
          @click="deleteItem(item)"
        >
          delete
        </v-icon>
         </template>
          <span class="white--text">Eliminar</span>
        </v-tooltip>
      </template>
      <template v-slot:no-data>
        <v-btn color="primary" @click="initialize">Refrescar</v-btn>
      </template>
    </v-data-table>
    <v-snackbar
      v-model="alerta"
      top
      color="error"
    >
      Existe un Motivo de Incidente Activo con el mismo Nombre o Clave
      <v-btn
        text
        @click="alerta = false"
      >
        Cerrar
      </v-btn>
    </v-snackbar>
  </v-col>
  
</template>

<script>
import axios from "axios";
import TimePicker from '../../../TimePicker.vue';
import { mapState } from "vuex";

  export default {
	components: { TimePicker },
    data: () => ({
      errorTiempo: null,
      saving: false,
      dialog: false,
      alerta: false,
      eliminar: false,
      search: '',
      headers: [
        {
          text: 'Tipo',
          align: 'center',
          sortable: true,
          value: 'nombre',
        },
        {
          text: 'Factor OEE',
          align: 'center',
          sortable: true,
          value: 'oeeFactor',
        },
        {
          text: '¿Llamado a Mantenimiento?',
          align: 'center',
          sortable: true,
          value: 'mantenimiento',
        },
        {
          text: '¿Es un Paro Programado?',
          align: 'center',
          sortable: true,
          value: 'paroProgramado',
        },
        { text: 'Acciones', value: 'action', sortable: false, align:'center' },
      ],
      loading: false,
      paroTipos: [],
      delItem: '',
      action: 0,
      errores: [],
      editedIndex: -1,
      usuariosVinculadosWhatsapp:[],
      selectedUsuario: null,
      ups:0,
      alertaUsuariosOrigin: [],
      editedItem: {
        idParoTipo: -1,
        nombre: '',
        idOEEFactor: null,
        oeeFactor: '',
        codigo:"",
        mantenimiento: 0,
        paroProgramado: 1,
        tiempoEstimado: 0,
        alertaUsuarios: []
      },
      defaultItem: {
        idParoTipo: -1,
        nombre: '',
        idOEEFactor: null,
        oeeFactor: '',
        codigo: '',
        mantenimiento: 0,
        paroProgramado: 1,
        tiempoEstimado: 0,
        alertaUsuarios:[]
      },
    }),

    computed: {
      ...mapState(["whatsapp"]),
      formTitle () {
        return this.editedIndex === -1 ? 'Nuevo Motivo de Incidente' : 'Editar Motivo de Incidente'
      },
        filteredUsuariosVinculados() {
          this.ups++; // Incrementa el contador para asegurarse de que el autocomplete se actualice
          return this.usuariosVinculadosWhatsapp.filter(usuario => 
          !this.editedItem.alertaUsuarios.some(alertaUsuario => alertaUsuario.idUsuario === usuario.idUsuario)
        );
      }
    },
    watch: {
      dialog (val) {
        val || this.close()
      }
    },

    created () {
      this.initialize()
    },

    methods: {
      onSelectUsuario() {
        if (this.selectedUsuario) {
        const usuario = this.usuariosVinculadosWhatsapp.find(user => user.idUsuario === this.selectedUsuario);

        if (usuario && !this.editedItem.alertaUsuarios.some(existingUser => existingUser.idUsuario === usuario.idUsuario)) {
            this.editedItem.alertaUsuarios.push(usuario); // Añadir el usuario a la lista
            this.selectedUsuario = null; // Limpiar la selección del autocomplete
            this.ups++;
          }
        }
      },
    onRemoveUsuario(usuario) {
      // Elimina el usuario de la lista de seleccionados
      this.editedItem.alertaUsuarios = 
      this.editedItem.alertaUsuarios.filter(user => user.idUsuario !== usuario.idUsuario);
    },
      onChangeSwitchParo(){
        this.editedItem.tiempoEstimado = 0;
        this.errores = [];
        this.errorTiempo = false;
      },
      initialize () {
        this.getParoTipos();
        this.getOEEFactores();
        this.usuariosVinculados();
      },

      getOEEFactores(){
        axios
          .get( '/OEEFactores' )
          .then( response => {
            this.factores = response.data;
          })
          .catch( error =>  {
            console.log( error );
          })
      },

      getParoTipos(){
        this.loading = true;
        axios
        .get("/ParoTipos")
        .then(response =>  {
          this.paroTipos = response.data.map( paro => ({
            ...paro,
            mantenimiento: +!paro.mantenimiento,
            paroProgramado: +!paro.paroProgramado
          }));
          this.loading = false;
        })
        .catch(error => {
          console.log(error);
        });
      },

      editItem (item) {
        this.action = 1;
        this.alertaUsuariosOrigin = [...item.alertaUsuarios]
        this.editedIndex = this.paroTipos.indexOf(item)
        this.editedItem = Object.assign({}, item)
        this.dialog = true
      },

      deleteItem (item) {
        this.eliminar = true;
        this.delItem = item;
      },
      deleteItemDB(){
        let me = this;
        let item = this.delItem;
        this.delItem = '';
        axios
          .put("/ParoTipos/Desactivar/" + item.idParoTipo)
          .then(() =>  {
            me.initialize();
          })
          .catch(error => {
            console.log(error);
          });
      },
      close() {
        this.action = 0,
        this.dialog = false
        this.editedItem = Object.assign({}, this.defaultItem)
        this.editedItem.alertaUsuarios = [...this.alertaUsuariosOrigin]
          this.editedIndex = -1
          this.alertaUsuariosOrigin = []
          this.ups++;
        /* setTimeout(() => {
          this.editedItem = Object.assign({}, this.defaultItem)
          this.editedIndex = -1
        }, 300) */
      },
      validate(){
        this.errores =  [];
        this.errorTiempo = false;

        if (
          this.editedItem.nombre.length < 2 ||
          this.editedItem.nombre.length > 50
        )
        {
          this.errores.push(
            "El nombre debe tener más de 2 caracteres y menos de 50."
          );
        }

        if (
          this.editedItem.idOEEFactor === null
        )
        {
          this.errores.push(
            "Debe seleccionar un Factor OEE."
          );
        }

        if (
          this.editedItem.mantenimiento == null
        )
        {
          this.errores.push(
            "Debe indicar si el Incidente llamará a Mantenimiento."
          );
        }

        if(this.editedItem.paroProgramado == 0){
          if(this.editedItem.tiempoEstimado == 0){
            this.errores.push("Debe indicar el Tiempo Aproximado del Paro");
            this.errorTiempo = true;
          }
        }

        return this.errores.length == 0;
      },
      save () {
        this.action = 0;
        if(this.validate()){
          let me = this;
          me.saving = true;
          if (this.editedIndex > -1)
          {
            //Código para editar
            axios
              .put("/ParoTipos/Actualizar/"+this.paroTipos[this.editedIndex].idParoTipo, {
                nombre: this.editedItem.nombre,
                idOEEFactor: this.editedItem.idOEEFactor,
                codigo: this.$utils.strings.isNullOrEmpty(this.editedItem.codigo) ? null : this.editedItem.codigo,
                mantenimiento: !this.editedItem.mantenimiento,
                paroProgramado: (this.editedItem.idOEEFactor == 2) ? !this.editedItem.paroProgramado : false,
                tiempoEstimado: this.editedItem.tiempoEstimado,
                idsUsuarios: this.editedItem.alertaUsuarios.map(usuario => usuario.idUsuario)
              })
              .then(() =>  {
                this.saving = false;
                this.close();
                this.initialize();
              })
              .catch(error => {
                this.saving = false;
                if (error.response) {
                  if(error.response.status == 409){
                    this.alerta = true;
                    this.errores.push("Existe un Motivo de Incidente Activo con el Mismo Nombre o Clave");
                  }
                }
              });
          }
          else
          {
            axios
              .post("/ParoTipos/Crear", {
                nombre: this.editedItem.nombre,
                idOEEFactor: this.editedItem.idOEEFactor,
                codigo: this.$utils.strings.isNullOrEmpty(this.editedItem.codigo) ? null : this.editedItem.codigo,
                mantenimiento: !this.editedItem.mantenimiento,
                paroProgramado: (this.editedItem.idOEEFactor == 2) ? !this.editedItem.paroProgramado : false,
                tiempoEstimado: this.editedItem.tiempoEstimado,
                idsUsuarios: this.editedItem.alertaUsuarios.map(usuario => usuario.idUsuario)
              })
              .then(() =>  {
                this.saving = false;
                this.close();
                this.initialize();
              })
              .catch(error => {
                this.saving = false;
                if (error.response) {
                  if(error.response.status == 409){
                    this.alerta = true;
                    this.errores.push("Existe un Motivo de Incidente Activo con el Mismo Nombre o Clave");
                  }
                }
              });
          }
        }
      },
      usuariosVinculados() {
        this.loading = true;
        axios
          .get("/Usuarios/UsuariosVinculadosWhatsapp")
          .then(response => {
            this.usuariosVinculadosWhatsapp = response.data 
          })
          .catch(error => {
            this.vinculado = false;
            console.log(error)
          })
          .finally(() => {
            this.loading = false;
          })

      },
    },
    filters: {
      nombreFormated({ codigo, nombre }){
        return `${codigo ? `[${codigo}] -` : ''} ${nombre}`.trim();
      }
    }
  }
</script>