<template>
	<v-card flat>
		<v-card-text
			class="text-center"
		>
			<p class="mb-0 text-h6 text--secondary text-left">
				{{titulo}}
			</p>
			<v-file-input
				v-model="selectedUpdateFile"
				:accept="accept"
				@change="cambioficheroCarga"
				counter
				show-size
				clearable
				:label="labelFileInput"
                :disabled="disabled"
			></v-file-input>
			<!-- <a
				:href=" `${endpointPlantilla}?access_token=${token}`"
				target="_blank"
				class="caption"
			>
				<v-icon color="blue">mdi-download</v-icon>
                Descargar Plantilla de {{titulo}}
			</a> -->
		</v-card-text>
	</v-card>
</template>

<script>
import { mapState } from "vuex";

export default {
    props: {
        titulo: {
            type: String,
            required: true
        },
        accept: {
            type: String,
            default: ".csv"
        },
        labelFileInput: {
            type: String,
            default: "Fichero Carga .CSV"
        },
        disabled: {
            type: Boolean,
            default: false
        }
        /* endpointPlantilla: {
            type: String,
            required: true
        } */
    },
	data() {
		return {
			selectedUpdateFile: null,
			cargaMasiva: false,
			loadingCargaMasiva: false,
			ficheroCarga: null,
			cargaMasivaCompleta: false,
			errorCargaMasiva: false,
			cargaLink: "",
		};
	},
	computed: {
		...mapState({
			token: "token",
		}),
	},
	mounted() {
		this.initialize();
	},
	methods: {
		initialize() {
			this.resetValues();
		},
		resetValues() {
            this.ficheroCarga = null;
            this.cargaMasivaCompleta = false;
            this.loadingCargaMasiva = false;
            this.errorCargaMasiva = false;
            this.selectedUpdateFile = null;
		},
		cambioficheroCarga(event) {
			if (typeof event === "undefined" || event == null)
				this.ficheroCarga = null;
			else this.ficheroCarga = event;

            this.$emit('change',this.ficheroCarga);
		},
	},
};
</script>