<template>
	<div>
		<v-tabs
            class="pt-2"
			v-model="tab"
			background-color="transparent"
			grow
			show-arrows
            centered
            icons-and-text
		>
            <v-tabs-slider></v-tabs-slider>

			<v-tab href="#tab-1" v-if="mostrarVista('oee-reporte')">
				Reporte OEE
				<v-icon>mdi-poll</v-icon>
			</v-tab>

			<v-tab href="#tab-2" v-if="mostrarVista('reporterendimiento')">
				Reporte<br />Rendimiento
				<v-icon>mdi-chart-line-variant</v-icon>
			</v-tab>

			<v-tab href="#tab-3" v-if="mostrarVista('reportesubpasos')">
				Reporte de<br/>Actividades de <br/>Operadores
				<v-icon>mdi-newspaper-variant-outline</v-icon>
			</v-tab>

			<v-tab
				href="#tab-4"
				v-if="mostrarVista('reporteoperacion')"
				@click="updateReporteOperaciones()"
			>
				Reportes<br />Por Operación
				<v-icon>mdi-account-hard-hat</v-icon>
			</v-tab>

			<v-tab href="#tab-5" v-if="mostrarVista('reporterendimiento')">
				Comparativa<br />Máquinas
				<v-icon>mdi-chart-bar-stacked</v-icon>
			</v-tab>

			<v-tab href="#tab-6" v-if="mostrarVista('reporterendimiento')">
				Tiempo No<br />Productivo Máquinas
				<v-icon>mdi-chart-bar-stacked</v-icon>
			</v-tab>

			<v-tab href="#tab-7" v-if="mostrarVista('sensores')">
				Graficas<br />de Sensores
				<v-icon>mdi-chart-areaspline</v-icon>
			</v-tab>

			<v-tab href="#tab-8" v-if="mostrarVista('matrix')">
				Matriz de<br />Producción
				<v-icon>mdi-matrix</v-icon>
			</v-tab>

			<v-tab href="#tab-9" v-if="mostrarVista('contador')">
				Piezas<br />Contabilizadas
				<v-icon>mdi-robot-industrial</v-icon>
			</v-tab>

			<v-tab href="#tab-10" v-if="mostrarVista('tiempociclo')">
				Tiempo<br />Ciclo
				<v-icon>mdi-cached</v-icon>
			</v-tab>

			<v-tabs-items v-model="tab" touchless>
				<v-tab-item
					:key="1"
					:value="'tab-1'"
					v-if="mostrarVista('oee-reporte')"
				>
					<ReporteOEE ref="reporteoee"></ReporteOEE>
				</v-tab-item>

				<v-tab-item
					:key="2"
					:value="'tab-2'"
					v-if="mostrarVista('reporterendimiento')"
				>
					<ReporteRendimiento
						ref="reporterendimiento"
					></ReporteRendimiento>
				</v-tab-item>

				<v-tab-item
					:key="3"
					:value="'tab-3'"
					v-if="mostrarVista('reportesubpasos')"
				>
					<ReporteSubOperaciones></ReporteSubOperaciones>
				</v-tab-item>

				<v-tab-item
					:key="4"
					:value="'tab-4'"
					v-if="mostrarVista('reporteoperacion')"
				>
					<ReporteOperaciones
						ref="reporteoperacion"
					></ReporteOperaciones>
				</v-tab-item>
				
				<v-tab-item :key="5" :value="'tab-5'">
					<ReporteComparativaMaquinas />
				</v-tab-item>

				<v-tab-item :key="6" :value="'tab-6'">
					<ReporteTiempoNoProductivoMaquinas />
				</v-tab-item>

				<v-tab-item
					:key="7"
					:value="'tab-7'"
					v-if="mostrarVista('sensores')"
				>
					<ReporteGraficaDatos />
				</v-tab-item>

				<v-tab-item
					:key="8"
					:value="'tab-8'"
					v-if="mostrarVista('matrix')"
				>
					<ReporteProduccion></ReporteProduccion>
				</v-tab-item>

				<v-tab-item :key="9" :value="'tab-9'">
					<ReporteMaquinaSensor></ReporteMaquinaSensor>
				</v-tab-item>

				<v-tab-item
					:key="10"
					:value="'tab-10'"
					v-if="mostrarVista('tiempociclo')"
				>
					<ReporteTiempoCiclo></ReporteTiempoCiclo>
				</v-tab-item>

			</v-tabs-items>
		</v-tabs>
	</div>
</template>

<script>
import ReporteOEE from "./OEEFactor.vue";
import ReporteOperaciones from "./Operaciones.vue";
import ReporteRendimiento from "./ReporteRendimiento";
import ReporteSensores from "./ReporteSensores.vue";
import ReporteProduccion from "./ProduccionTiempoReal.vue";
import ReporteMaquinaSensor from "./MaquinaSensorTiempoReal.vue";
import ReporteTiempoCiclo from "./ReporteTiempoCiclo.vue";
import ReporteGraficaDatos from "./Graficas.vue";
import ReporteComparativaMaquinas from "./ComparativaMaquinas.vue";
import ReporteTiempoNoProductivoMaquinas from "./TiempoNoProductivoMaquinas.vue";
import ReporteSubOperaciones from "./ReporteSubOperaciones.vue"

export default {
	components: {
		ReporteSensores: ReporteSensores,
		ReporteOEE: ReporteOEE,
		ReporteOperaciones: ReporteOperaciones,
		ReporteRendimiento: ReporteRendimiento,
		ReporteProduccion: ReporteProduccion,
		ReporteMaquinaSensor: ReporteMaquinaSensor,
		ReporteTiempoCiclo: ReporteTiempoCiclo,
		ReporteGraficaDatos: ReporteGraficaDatos,
		ReporteComparativaMaquinas: ReporteComparativaMaquinas,
		ReporteTiempoNoProductivoMaquinas: ReporteTiempoNoProductivoMaquinas,
		ReporteSubOperaciones: ReporteSubOperaciones
	},
	data: () => ({
		tab: 0,
	}),
	methods: {
		updateReporteOperaciones() {
			if (typeof this.$refs.reporteoperacion === "undefined") return;
			this.$refs.reporteoperacion.initialize();
		},
	},
};
</script>