<template>
  <v-col cols="12">
    <signalr path="/hub/sensor" @change="cambios"></signalr>
    <v-row>
      <v-col cols="6" md="6" sm="6">
        <v-autocomplete
          label="Máquinas Instancias"
          v-model="maquinaInstanciaSeleccionadas"
          :items="maquinaInstancias"
          item-value="idMaquinaIns"
          :item-text="nombreMaquina"
          multiple
          clearable
          return-object
        ></v-autocomplete>
      </v-col>
      <v-col cols="6" md="6" sm="6">
        <v-autocomplete
          v-model="minutos"
          label="Intervalo de Tiempo"
          :items="intervalos"
        ></v-autocomplete>
      </v-col>
    </v-row>
    <v-row>
      <v-col
        v-for="(maquinaIns,idx) in maquinaInstanciaSeleccionadas"
        :key="'m'-maquinaIns.idMaquinaIns"
        :cols="sizeCol(idx)"
        :md="sizeCol(idx)"
        :sm="sizeCol(idx)"
      >
        <grafica
          :maquinaIns="maquinaIns"
          :ref="'grafica'+maquinaIns.idMaquinaIns"
          :minutos="minutos"
        >
        </grafica>
      </v-col>
    </v-row>
  </v-col>
</template>


<script>
import axios from "axios"
import signalr from "@/components/SignalR";
import grafica from "./Sensor/Grafica.vue"

export default {
  components: {
    signalr,
    'grafica':grafica 
  },
  mounted(){
    this.getMaquinaInstancias();
  },
  data:() => ({
    minutos: 1,
    intervalos:[
      { 
        value:1,
        text: "Último Minuto"
      },
      {
        value:5,
        text: "Últimos 5 Minutos"
      },
      {
        value:15,
        text: "Últimos 15 Minutos"
      },
      {
        value:30,
        text: "Últimos 30 Minutos"
      },
      {
        value:60, 
        text: "Ultima Hora"
      },
      {
        value:180, 
        text: "Últimas 3 Horas"
      },
      {
        value:720, 
        text: "Últimas 12 Horas"
      },
      {
        value:1440, 
        text: "Ultimas 24 Horas"
      }
    ],
    maquinaInstancias:[],
    maquinaInstanciaSeleccionadas:[],
  }),
  methods:{
    nombreMaquina: (item) => `${item.marca} ${item.modelo} - [${item.nombre}]`,
    getMaquinaInstancias(){
      this.loading = true;
      axios
      .get("/Sensores/MaquinaInstancias")
      .then(response => {
        this.maquinaInstancias = response.data;
        this.loading = false;
      })
      .catch(error => {
        this.loading = false;
        console.log(error);
      })
    },
    cambios($event){
      var event = $event.toLowerCase().split("-");
      if(event[0] == 'maquinainstancia'){
        var idMaquinaIns = parseInt(event[1]);
        if(this.maquinaInstanciaSeleccionadas.findIndex(mi => mi.idMaquinaIns == idMaquinaIns) >= 0) this.$refs['grafica'+idMaquinaIns][0].actualizar();
      }
    },
    sizeCol(idx){
      if(!this.$utils.isValid(this.maquinaInstanciaSeleccionadas) || this.maquinaInstanciaSeleccionadas.length <= 0) return 0;
      else if(this.maquinaInstanciaSeleccionadas.length == 1) return 12;
      else if(this.maquinaInstanciaSeleccionadas.length == 2) return 6;
      else if(this.maquinaInstanciaSeleccionadas.length == 3){
        if(idx == 0 || idx == 1) return 6;
        else return 12;
      }
      else return 6;
    }
  }
}
</script>