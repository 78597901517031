<template>
	<v-col cols="12" class="px-8" v-if="permisoVista('dashboard', 'r')">
		<v-row class="mt-0">
			<v-col cols="3" class="align-self-end">
				<datePicker
					v-model="filtros.desde"
					format="YYYY-MM-DD"
					label="Desde (Obligatorio)"
					clearable
					maxToday
					@input="obtenerDatos"
					:disabled="cargando"
					:maxDate="filtros.hasta"
				></datePicker>
			</v-col>
			<v-col cols="3" class="align-self-end">
				<datePicker
					v-model="filtros.hasta"
					format="YYYY-MM-DD"
					label="Hasta (Obligatorio)"
					clearable
					maxToday
					@input="obtenerDatos"
					:disabled="cargando"
					:minDate="filtros.desde"
				></datePicker>
			</v-col>
			<v-col cols="3">
				<v-autocomplete
					v-model="filtros.idMaquinaIns"
					:items="maquinasIns"
					item-value="idMaquinaIns"
					:item-text="nombreMaquina"
					label="Maquinas Instancias (Obligatorio)"
					hide-details
					clearable
					:disabled="cargando"
					@input="obtenerDatos"
					@click:clear="resetCampo(5)"
				>
				</v-autocomplete>
			</v-col>
			<v-col cols="3">
				<v-autocomplete
					v-model="filtros.idsTurnos"
					:items="turnos"
					item-value="idTurno"
					item-text="nombre"
					label="Turnos (Opcional)"
					hide-details
					clearable
					multiple
					chips
					deletable-chips
					:disabled="cargando"
					@input="obtenerDatos"
					@click:clear="resetCampo(1)"
				>
				</v-autocomplete>
			</v-col>
		</v-row>
		<v-row>
			<download-btn
				className="my-4"
				color="blue darken-1"
				text
				@click="descargarReporte"
				label="Descargar Reporte <br> de OEE Maquinas"
			/>
		</v-row>
		<br />
		<div v-if="viewData">
			<v-row v-if="realizarPeticion && sameDay" class="px-0 ma-0">
				<v-col cols="12" class="ma-0 text-right text-subtitle-2">
					Proxima Actualización en {{ tiempo }}
				</v-col>
			</v-row>
			<v-row class="justify-center ma-0 pa-0">
				<v-col cols="3" md="3" sm="3">
					<v-card class="fill-height d-flex" color="#2a6099" dark>
						<v-card-text
							class="text-center align-self-center white--text"
						>
							<div class="subtitle-1 font-weight-bold">
								Disponibilidad
							</div>
							<ICountUp
								:delay="1000"
								:endVal="this.datos.disponibilidad * 100"
								class="subtitle-1 font-weight-medium"
								:options="{
									decimalPlaces: 2,
									decimal: '.',
									suffix: '%',
								}"
							/>
						</v-card-text>
					</v-card>
				</v-col>
				<v-col cols="3" md="3" sm="3">
					<v-card class="fill-height d-flex" color="#2a6099" dark>
						<v-card-text
							class="text-center align-self-center white--text"
						>
							<div class="subtitle-1 font-weight-bold">
								{{oeeRendimientoLabel}}
							</div>
							<ICountUp
								:delay="1000"
								:endVal="this.datos.eficiencia * 100"
								class="subtitle-1 font-weight-medium"
								:options="{
									decimalPlaces: 2,
									decimal: '.',
									suffix: '%',
								}"
							/>
						</v-card-text>
					</v-card>
				</v-col>
				<v-col cols="3" md="3" sm="3">
					<v-card class="fill-height d-flex" color="#2a6099" dark>
						<v-card-text
							class="text-center align-self-center white--text"
						>
							<div class="subtitle-1 font-weight-bold">
								Calidad
							</div>
							<ICountUp
								:delay="1000"
								:endVal="this.datos.calidad * 100"
								class="subtitle-1 font-weight-medium"
								:options="{
									decimalPlaces: 2,
									decimal: '.',
									suffix: '%',
								}"
							/>
						</v-card-text>
					</v-card>
				</v-col>
				<v-col cols="3" md="3" sm="3">
					<v-card class="fill-height d-flex" color="#2a6099" dark>
						<v-card-text
							class="text-center align-self-center white--text"
						>
							<div class="subtitle-1 font-weight-bold">OEE</div>
							<ICountUp
								:delay="1000"
								:endVal="this.datos.oee * 100"
								class="subtitle-1 font-weight-medium"
								:options="{
									decimalPlaces: 2,
									decimal: '.',
									suffix: '%',
								}"
							/>
						</v-card-text>
					</v-card>
				</v-col>
			</v-row>
			<v-row v-if="!cargando && renderizar">
				<v-col cols="6" md="6" sm="6" class="text-center">
					<div class="text-subtitle-2">Disponibilidad</div>
					<apexchart
						height="220"
						:options="serie1"
						:series="chartOptions1"
					></apexchart>
				</v-col>
				<v-col cols="6" md="6" sm="6" class="text-center">
					<div class="text-subtitle-2">{{oeeRendimientoLabel}}</div>
					<apexchart
						height="220"
						:options="serie2"
						:series="chartOptions2"
					></apexchart>
				</v-col>
				<v-col cols="6" md="6" sm="6" class="text-center">
					<div class="text-subtitle-2">Calidad</div>
					<apexchart
						height="220"
						:options="serie3"
						:series="chartOptions3"
					></apexchart>
				</v-col>
				<v-col cols="6" md="6" sm="6" class="text-center">
					<div class="text-subtitle-2">OEE</div>
					<apexchart
						height="220"
						:options="serie4"
						:series="chartOptions4"
					></apexchart>
				</v-col>
			</v-row>
		</div>
		<div v-if="cargandoData">
			<v-col cols="12" md="12" sm="12" class="text-center">
				<h4>Cargando</h4>
			</v-col>
			<v-col cols="12" md="12" sm="12" class="text-center">
				<v-progress-circular
					:size="75"
					color="green"
					indeterminate
				></v-progress-circular>
			</v-col>
		</div>
	</v-col>
</template>

<script>
import { mapState } from "vuex";
import axios from "axios";
import ICountUp from "vue-countup-v2";
import moment from "moment";
import DownloadBtn from "../../DownloadBtn.vue";

export default {
	components: {
		ICountUp,
		DownloadBtn,
	},
	props: {
		active: {
			type: Boolean,
			default: false,
		},
	},
	computed: {
		...mapState({
			nombreEmpresa: "nombreEmpresa",
			oeeRendimientoLabel: 'oeeRendimientoLabel',
		}),
		realizarPeticion() {
			return (
				this.$utils.isValid(this.filtros.desde) &&
				this.$utils.isValid(this.filtros.hasta) &&
				this.filtros.idMaquinaIns > 0
			);
		},
		url() {
			var url = `/Reportes/OEEMaquina?Desde=${this.filtros.desde}&Hasta=${this.filtros.hasta}&IdMaquinaIns=${this.filtros.idMaquinaIns}`;
			url += this.$utils.arrays.urlQuery(
				this.filtros.idsTurnos,
				"IdsTurnos",
				false
			);
			return url;
		},
		tiempo() {
			return this.$utils.strings.toTime(this.segundos);
		},
		sameDay() {
			var desde = this.$utils.isValid(this.filtros.desde)
				? moment(this.filtros.desde, "YYYY-MM-DD")
				: moment(1);
			var hasta = this.$utils.isValid(this.filtros.hasta)
				? moment(this.filtros.hasta, "YYYY-MM-DD")
				: moment(1);
			return (
				desde.isSameOrAfter(moment(), "day") ||
				hasta.isSameOrAfter(moment(), "day")
			);
		},
	},
	data() {
		return {
			resumenDiasHeaders: [
				{
					text: "Fecha Inicio",
					align: "center",
					value: "fechaInicioDate",
				},
				{
					text: "Fecha Fin",
					align: "center",
					value: "fechaFinDate",
				},
				{
					text: "Disponibilidad",
					align: "center",
					value: "disponibilidad",
				},
				{
					text: "Rendimiento",
					align: "center",
					value: "eficiencia",
				},
				{
					text: "Calidad",
					align: "center",
					value: "calidad",
				},
				{
					text: "OEE",
					align: "center",
					value: "oee",
				},
			],
			cargandoData: false,
			viewData: false,
			contador: 8,
			interval: null,
			segundos: 60,
			cargando: false,
			expanded: [],
			filtros: {
				desde: null,
				hasta: null,
				idsTurnos: [],
				idMaquinaIns: -1,
			},
			turnos: [],
			areas: [],
			ots: [],
			articulos: [],
			maquinasIns: [],
			operadores: [],
			clientes: [],
			datos: {
				calidad: 0.0,
				disponibilidad: 0.0,
				eficiencia: 0.0,
				fecha: null,
				oee: 0.0,
				operaciones: [],
				piezasNoOk: 0,
				piezasOk: 0,
				piezasTeoricas: 0,
				resumenDias: [],
				scrap: 0.0,
				tiempoOperacionSeg: 0,
				tiempoParos: 0,
				totalPiezas: 0,
			},
			datosDefault: {
				calidad: 0.0,
				disponibilidad: 0.0,
				eficiencia: 0.0,
				fecha: null,
				oee: 0.0,
				operaciones: [],
				piezasNoOk: 0,
				piezasOk: 0,
				piezasTeoricas: 0,
				resumenDias: [],
				scrap: 0.0,
				tiempoOperacionSeg: 0,
				tiempoParos: 0,
				totalPiezas: 0,
			},
			serie1: [],
			chartOptions1: {
				chart: {
					id: "disponibilidad",
					group: "oee",
					type: "line",
					height: 160,
					toolbar: {
						show: false,
					},
				},
				colors: ["#118dff"],
				xaxis: {
					type: "datetime",
					labels: {
						datetimeUTC: false,
					},
					tooltip: {
						enabled: false,
					},
				},
				yaxis: {
					labels: {
						formatter: function (val) {
							return val.toFixed(2) + "%";
						},
					},
					min: 0,
					tickAmount: 5,
				},
			},
			serie2: [],
			chartOptions2: {
				chart: {
					id: "rendimiento",
					group: "oee",
					type: "line",
					height: 160,
					toolbar: {
						show: false,
					},
				},
				colors: ["#ff8410"],
				xaxis: {
					type: "datetime",
					labels: {
						datetimeUTC: false,
					},
					tooltip: {
						enabled: false,
					},
				},
				yaxis: {
					labels: {
						formatter: function (val) {
							return val.toFixed(2) + "%";
						},
					},
					min: 0,
					tickAmount: 5,
				},
			},
			serie3: [],
			chartOptions3: {
				chart: {
					id: "calidad",
					group: "oee",
					type: "line",
					height: 160,
					toolbar: {
						show: false,
					},
				},
				colors: ["#10ff60"],
				xaxis: {
					type: "datetime",
					labels: {
						datetimeUTC: false,
					},
					tooltip: {
						enabled: false,
					},
				},
				yaxis: {
					labels: {
						formatter: function (val) {
							return val.toFixed(2) + "%";
						},
					},
					min: 0,
					tickAmount: 5,
				},
			},
			serie4: [],
			chartOptions4: {
				chart: {
					id: "OEE",
					group: "oee",
					type: "line",
					height: 160,
					toolbar: {
						show: false,
					},
				},
				colors: ["#ff372b"],
				xaxis: {
					type: "datetime",
					labels: {
						datetimeUTC: false,
					},
					tooltip: {
						enabled: false,
					},
				},
				yaxis: {
					labels: {
						formatter: function (val) {
							return val.toFixed(2) + "%";
						},
					},
					min: 0,
					tickAmount: 5,
				},
			},
			renderizar: false,
			headers: [
				{
					text: "OT",
					align: "center",
					sortable: false,
					value: "ot",
					// width: "50%",
				},
				{
					text: "Área",
					align: "center",
					sortable: false,
					value: "area",
					// width: "50%",
				},
				{
					text: "Artículo",
					align: "center",
					sortable: false,
					value: "articuloFase",
					// width: "50%",
				},
				{
					text: "Operador(es)",
					align: "center",
					sortable: false,
					value: "personas",
					// width: "50%",
				},
				{
					text: "Máquina(s)",
					align: "center",
					sortable: false,
					value: "maquinas",
					// width: "50%",
				},
				{
					text: "Disponibilidad",
					align: "center",
					sortable: false,
					value: "disponibilidad",
					// width: "50%",
				},
				{
					text: "Rendimiento",
					align: "center",
					sortable: false,
					value: "eficiencia",
					// width: "50%",
				},
				{
					text: "Calidad",
					align: "center",
					sortable: false,
					value: "calidad",
					// width: "50%",
				},
				{
					text: "OEE",
					align: "center",
					sortable: false,
					value: "oee",
					// width: "50%",
				},
				{ text: "", value: "data-table-expand" },
			],
		};
	},
	methods: {
		nombreArticulo: (articulo) =>
			`[${articulo.codigo}] - ${articulo.nombre}`,
		nombreMaquina: (maquina) =>
			` [${maquina.nombre}] - ${maquina.marca} ${maquina.modelo}`,
		nombrePersona: (persona) =>
			persona.nombre + " " + persona.paterno + " " + persona.materno,
		generarSerie(nombre, campo) {
			var serie = {
				name: nombre,
				data: [],
			};

			if (
				this.datos.datos != null &&
				this.datos.datos.length > 0
			) {
				serie.data = this.datos.datos.map((d) => {
					return {
						x: d.fecha,
						y: d[campo] * 100,
					};
				});
			}

			return [serie];
		},
		chartOptionsGenerador(id, color = "#008FFB") {
			const start = moment(this.filtros.desde, "YYYY-MM-DD");
			const end = moment(this.filtros.hasta, "YYYY-MM-DD");
			const diferenciaDias = Math.abs(
				moment.duration(start.diff(end)).asDays()
			);
			const soloHoras = diferenciaDias <= 1;
			return {
				chart: {
					id: id,
					group: "oee",
					type: "line",
					height: 220,
					toolbar: {
						show: false,
					},
					zoom: {
						enabled: false,
					},
				},
				colors: [color],
				xaxis: {
					type: "datetime",
					labels: {
						// formatter: (value) => moment(value).format("YYYY-MM-DD HH:mm"),
						format: soloHoras ? "HH:mm" : "yyyy-MM-dd HH:mm",
						datetimeUTC: false,
					},
					tooltip: {
						enabled: false,
					},
				},
				yaxis: {
					labels: {
						formatter: function (val) {
							return val.toFixed(2) + "%";
						},
					},
					min: 0,
					tickAmount: 5,
				},
				tooltip: {
					x: {
						formatter: (value) => {
							return moment(value).format("YYYY-MM-DD HH:mm");
						},
					},
				},
			};
		},
		initialize() {
			this.getTurnos();
			this.getMaquinasIns();
		},
		resetCampo(posicion) {
			switch (posicion) {
				case 1:
					this.filtros.idsTurnos = [];
					break;
				case 5:
					this.filtros.idMaquinaIns = -1;
					break;
				default:
					break;
			}
		},
		obtenerDatos() {
			if (!this.realizarPeticion || this.cargando) return;
			/* cargandoData:false,
    viewData:false, */
			this.cargando = true;
			this.segundos = 60;
			this.cargandoData = true;
			axios
				.get(this.url)
				.then((response) => {
					this.datos = response.data;

					this.chartOptions1 = this.generarSerie(
						"Disponibilidad",
						"disponibilidad"
					);
					this.serie1 = this.chartOptionsGenerador(
						"Disponibilidad",
						"#118dff"
					);

					this.chartOptions2 = this.generarSerie(
						this.oeeRendimientoLabel,
						"eficiencia"
					);
					this.serie2 = this.chartOptionsGenerador(
						this.oeeRendimientoLabel,
						"#ff8410"
					);

					this.chartOptions3 = this.generarSerie(
						"Calidad",
						"calidad"
					);
					this.serie3 = this.chartOptionsGenerador(
						"Calidad",
						"#10ff60"
					);

					this.chartOptions4 = this.generarSerie("OEE", "oee");
					this.serie4 = this.chartOptionsGenerador("OEE", "#ff372b");

					this.renderizar = true;
					this.cargando = false;
					this.cargandoData = false;
					this.viewData = true;
				})
				.catch((error) => {
					this.cargando = false;
					this.viewData = true;
					this.cargandoData = false;
					console.log(error);
				});
		},
		getTurnos() {
			axios
				.get("/Turnos")
				.then((response) => {
					this.turnos = response.data;
				})
				.catch((error) => {
					console.log(error);
				});
		},
		getMaquinasIns() {
			axios
				.get("/MaquinaInstancias")
				.then((response) => {
					this.maquinasIns = response.data;
				})
				.catch((error) => {
					console.log(error);
				});
		},
		url2(path) {
			var url = `${path}?Desde=${this.filtros.desde}&Hasta=${this.filtros.hasta}&IdMaquinaIns=${this.filtros.idMaquinaIns}`;
			url += this.$utils.arrays.urlQuery(
				this.filtros.idsTurnos,
				"IdsTurnos",
				false
			);
			return url;
		},
		descargarReporte() {
			var datetime = moment().format("YYYYMMDDHHmmss");
			this.$utils.axios.downloadFile(
				this.url2("/OEEV2/OEEReport"),
				"GET",
				"reporte-oee-" + datetime + ".xlsx",
				"application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
			);
		},
	},
	filters: {
		formatToPercentage(value) {
			return (value * 100).toFixed(2) + "%";
		},
		formatStringFecha(value) {
			return value.slice(0, -3).split("T").join(" ");
		},
	},
	mounted() {
		this.headers[6].text = this.oeeRendimientoLabel;
    this.resumenDiasHeaders[3].text = this.oeeRendimientoLabel;

		this.interval = setInterval(() => {
			if (!this.active) return;

			if (this.segundos <= 0 && this.sameDay) this.obtenerDatos();
			else this.segundos -= 1;
		}, 1000);
		this.initialize();
	},
	destroyed() {
		clearInterval(this.interval);
	},
};
</script>