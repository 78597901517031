import { VCol } from 'vuetify/lib/components/VGrid';
import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VTooltip } from 'vuetify/lib/components/VTooltip';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VRow,[_c(VCol,{staticClass:"pb-0",attrs:{"cols":"12"}},[_c('DownloadBtn',{attrs:{"text":"","color":"primary","className":"my-0","label":"Descargar Reporte<br/>de Factibilidad"},on:{"click":function($event){return _vm.descargarReporte()}}})],1),_c(VCol,[_c(VDataTable,{staticClass:"elevation-1",attrs:{"dense":"","headers":_vm.headers,"items":_vm.desserts,"items-per-page":-1,"item-key":"name","hide-default-footer":""},scopedSlots:_vm._u([{key:"item",fn:function(ref){
var item = ref.item;
return [_c('tr',[_c('td',{staticClass:"text-center"},[_vm._v(_vm._s(item.ot))]),_c('td',{staticClass:"text-center"},[_vm._v(_vm._s(item.cliente))]),_c('td',{class:'text-center ' + _vm.Colors(item.faltantesGenerales.length)},[(item.faltantesGenerales.length == 0)?_c('div',[_vm._v("Disponible")]):_c('div',[_c(VTooltip,{staticClass:"white--text",attrs:{"nudge-left":50,"bottom":"","color":"black"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('span',_vm._g({staticStyle:{"cursor":"pointer"}},on),[_vm._v("Insuficiente")])]}}],null,true)},[_c('span',{staticClass:"white--text text-left"},[_c('ul',_vm._l((item.faltantesGenerales),function(item,index){return _c('li',{key:'ftgral-' + index},[_vm._v(" "+_vm._s(item.cantidad)+" "+_vm._s(item.unidad)+" "+_vm._s(item.articulo)+" ")])}),0)])])],1)]),_c('td',{class:'text-center ' + _vm.Colors(item.faltantesGlobales.length)},[(item.faltantesGlobales.length == 0)?_c('div',[_vm._v("Disponible")]):_c('div',[_c(VTooltip,{staticClass:"white--text",attrs:{"nudge-left":50,"bottom":"","color":"black"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('span',_vm._g({staticStyle:{"cursor":"pointer"}},on),[_vm._v("Insuficiente")])]}}],null,true)},[_c('span',{staticClass:"white--text text-left"},[_c('ul',_vm._l((item.faltantesGlobales),function(item,index){return _c('li',{key:'ftglb-' + index},[_vm._v(" "+_vm._s(item.cantidad)+" "+_vm._s(item.unidad)+" "+_vm._s(item.articulo)+" ")])}),0)])])],1)])])]}}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }