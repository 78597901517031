<template>
  <!-- <v-container> -->
    <v-row justify-sm="center">
      <v-card 
        class="mt-4"
        :width="anchoMaximo"
        fill
      >
        <v-tabs show-arrows v-model="tab" :background-color="colorTabs" centered dark icons-and-text>
          <v-tabs-slider></v-tabs-slider>

          <v-tab v-tooltip.bottom="modoAyuda && $help.almacen.articulosInventario" href="#tab-1" v-if="mostrarVista('articulos')" @click="updateArticulos()">
            Artículos de<br/>Inventario
            <v-icon>mdi-clipboard-text-outline</v-icon>
          </v-tab>
          
          <v-tab v-tooltip.bottom="modoAyuda && $help.almacen.articulosReorden" href="#tab-2" v-if="mostrarVista('articulos')" @click="updateReorden()">
            Artículos<br/>Reorden
            <v-icon>mdi-clipboard-alert</v-icon>
          </v-tab>

          <v-tab v-tooltip.bottom="modoAyuda && $help.almacen.articulosFaltantes" href="#tab-3"  v-if="mostrarVista('faltantes')" @click="updateFaltantes()">
            Artículos<br/>Faltantes
            <v-icon>mdi-alert</v-icon>
          </v-tab>
          
          <v-tab v-tooltip.bottom="modoAyuda && $help.almacen.leerqr" href="#tab-4" v-if="inventario && mostrarVista('leerqr') && trazabilidadInventario" @click="updateLeerQR()">
            Leer QR
            <v-icon>mdi-qrcode-scan</v-icon>
          </v-tab>

          <v-tab v-tooltip.bottom="modoAyuda && $help.almacen.estadoproduccion" href="#tab-5" v-if="inventario && mostrarVista('estadoproduccion') && trazabilidadInventario" @click="updateEstadoProduccion()">
            Estado de <br> Producción
            <v-icon>mdi-radar</v-icon>
          </v-tab>

          <v-tab 
            v-tooltip.bottom="modoAyuda && $help.almacen.cargasMasivas.title" 
            v-if="
              mostrarVista('cargasmasivassae') ||
              mostrarVista('articulos')
            "
            href="#tab-6" 
          >
            Cargas<br/>Masivas
            <v-icon>mdi-cloud-upload-outline</v-icon>
          </v-tab>

          <v-tab v-tooltip.bottom="modoAyuda && $help.almacen.movimientos" href="#tab-7"  v-if="mostrarVista('movimientos')" @click="updateMovimientos()">
            Movimientos
            <v-icon>mdi-arrow-decision-outline</v-icon>
          </v-tab>

          <v-tab v-tooltip.bottom="modoAyuda && $help.almacen.ordenesventas" href="#tab-8" v-if="mostrarVista('ordenesventas')" @click="updateOrdenesVenta()">
            Ordenes de Venta<br>Incompletas
            <v-icon>mdi-file-alert</v-icon>
          </v-tab>

          <!-- <v-tab v-tooltip.bottom="modoAyuda && $help.almacen.configuracionAlmacen.title" href="#tab-9" v-if="mostrarVista('almacen')" @click="updateAlmacen()">
            Configuración<br/>Almacén
            <v-icon>mdi-warehouse</v-icon>
          </v-tab> -->

          <v-tab v-if="mostrarVista('salidasauto')" v-tooltip.bottom="modoAyuda && $help.almacen.salidasauto" href="#tab-10" @click="updateSalidasAutomaticas()">
            Salidas<br/>Automáticas
            <v-icon>mdi-clipboard-arrow-left-outline</v-icon>
          </v-tab>

          <v-tab v-tooltip.bottom="modoAyuda && $help.almacen.excedentes" href="#tab-11" v-if="mostrarVista('excedentes')" @click="updateExcedentes()">
            Excedentes
            <v-icon>mdi-puzzle-plus-outline</v-icon>
          </v-tab>

        </v-tabs>

        <v-tabs-items v-model="tab">

          <v-tab-item :key="1" :value="'tab-1'">
            <v-card flat>
              <v-card-text>
                  <InventarioArticulo ref="articulos" />
              </v-card-text>
            </v-card>
          </v-tab-item>

          <v-tab-item :key="2" :value="'tab-2'" v-if="mostrarVista('articulos')">
            <v-card flat>
              <v-card-text>
                <Reorden ref="reord" />
              </v-card-text>
            </v-card>
          </v-tab-item>

          <v-tab-item :key="3" :value="'tab-3'"  v-if="mostrarVista('faltantes')">
            <v-card flat>
              <v-card-text>
                <InventarioFaltante ref="faltantes"></InventarioFaltante>
              </v-card-text>
            </v-card>
          </v-tab-item>

          <v-tab-item :key="4" :value="'tab-4'" v-if="inventario && mostrarVista('leerqr')">
            <v-card flat>
              <v-card-text>
                <InventarioLeerQR ref="lector"></InventarioLeerQR>
              </v-card-text>
            </v-card>
          </v-tab-item>

          <v-tab-item :key="5" :value="'tab-5'" v-if="inventario && mostrarVista('estadoproduccion')">
            <v-card flat>
              <v-card-text>
                <EstadoProduccion ref="estadoProd" />
              </v-card-text>
            </v-card>
          </v-tab-item>

          <v-tab-item :key="6" :value="'tab-6'">
            <v-card flat color="gray">
              <v-card-text>
                <ListaCargas />
              </v-card-text>
            </v-card>
          </v-tab-item>

          <v-tab-item :key="7" :value="'tab-7'"  v-if="mostrarVista('movimientos')">
            <v-card flat>
              <v-card-text>
                <InventarioMovimiento ref="movimientos"></InventarioMovimiento>
              </v-card-text>
            </v-card>
          </v-tab-item>

          <v-tab-item :key="8" :value="'tab-8'"  v-if="mostrarVista('ordenesventas')">
            <v-card flat>
              <v-card-text>
                <OrdenesVentaIncompletas ref="ordenesventasincompletas" />
              </v-card-text>
            </v-card>
          </v-tab-item>

          <!-- <v-tab-item :key="9" :value="'tab-9'" v-if="mostrarVista('almacen')">
            <v-card flat color="gray">
              <v-card-text>
                <configuracionAlmacen ref="almacen"></configuracionAlmacen>
              </v-card-text>
            </v-card>
          </v-tab-item> -->

          <v-tab-item :key="10" :value="'tab-10'" v-if="mostrarVista('salidasauto')">
            <v-card flat color="gray">
              <v-card-text>
                <SalidasAuto ref="salidas" />
              </v-card-text>
            </v-card>
          </v-tab-item>

          <v-tab-item :key="11" :value="'tab-11'" v-if="mostrarVista('excedentes')">
            <v-card flat>
              <v-card-text>
                <OTSExcedentes ref="excedentes"></OTSExcedentes>
              </v-card-text>
            </v-card>
          </v-tab-item>

        </v-tabs-items>
      </v-card>
    </v-row>
  <!-- </v-container> -->
</template>

<script>
import LeerQR from "./Inventario/LeerQR";
/* import Almacen from "./Configuracion/Almacen.vue"; */
import Faltante from "./Inventario/Faltante";
import Movimiento from "./Inventario/Movimiento";
import { mapState } from 'vuex';
import Reorden from './Inventario/Reorden.vue';
import ListaCargas from './Inventario/ListaCargas.vue';
import Articulo from "./Inventario/Articulo";
import SalidasAuto from './Inventario/SalidasAuto.vue';
import OrdenesVentaIncompletas from './Inventario/OrdenesVentaIncompletas.vue';
import Excedentes from "./OTS/Excedentes.vue";
import EstadoProduccion from './Inventario/EstadoProduccion.vue';

export default {
  data() {
    return {
      tab: null,
      text:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat."
    };
  },
  computed: {
    ...mapState(["inventario","anchoMaximo","colorTabs","modoAyuda","trazabilidadInventario"])
  },
  watch:{
    tab(){
      if(typeof this.$refs.lector === 'undefined') return;
      this.$refs.lector.estadoLector(this.tab == 'tab-3');
    }
  },
  methods:{
    updateEstadoProduccion(){
      if(typeof this.$refs.estadoProd === 'undefined') return;
      this.$refs.estadoProd.initialize();
    },
    updateExcedentes(){
      if(typeof this.$refs.excedentes === 'undefined') return;
      this.$refs.excedentes.initialize();
    },
    updateOrdenesVenta(){
      if(typeof this.$refs.ordenesventasincompletas === 'undefined') return;
      this.$refs.ordenesventasincompletas.initialize();
    },
    updateMovimientos(){
      if(typeof this.$refs.movimientos === 'undefined') return;
      this.$refs.movimientos.initialize();
    },
    updateFaltantes(){
      if(typeof this.$refs.faltantes === 'undefined') return;
      this.$refs.faltantes.initialize();
    },
    updateReorden(){
      if(typeof this.$refs.reord === 'undefined') return;
      this.$refs.reord.initialize();
    },
    /* updateAlmacen(){
      if(typeof this.$refs.almacen === 'undefined') return;
      this.$refs.almacen.refresh();
    }, */
    updateArticulos(){
      if(typeof this.$refs.articulos === 'undefined') return;
      this.$refs.articulos.initialize();
    },
    updateSalidasAutomaticas(){
      if(typeof this.$refs.salidas === 'undefined') return;
      this.$refs.salidas.initialize();
    },
    updateLeerQR(){
      if(typeof this.$refs.lector === 'undefined') return;
      this.$refs.lector.initialize();
    },
  },
  components: {
    InventarioArticulo: Articulo,
    /* configuracionAlmacen: Almacen, */
    InventarioLeerQR: LeerQR,
    InventarioFaltante: Faltante,
    InventarioMovimiento: Movimiento,
    Reorden,
    ListaCargas,
    SalidasAuto,
    OrdenesVentaIncompletas,
    OTSExcedentes: Excedentes,
    EstadoProduccion
  }
};
</script>