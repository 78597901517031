<template>
	<v-col cols="12">
		<v-snackbar v-model="alerta" top color="error">
			Existe un Horario con las mismas características.
			<v-btn text @click="alerta = false"> Cerrar </v-btn>
		</v-snackbar>
		<v-dialog v-model="dialog" max-width="900px" persistent>
			<v-card>
				<v-card-title v-if="editedItem.idMantenimientoInspeccion == null"
					>Agregar Inspección</v-card-title
				>
				<v-card-title v-else>{{ tipoEdicion == 1 ? 'Editar' : 'Clonar' }} Inspección</v-card-title>
				<v-card-text>
					<v-row>
						<v-col cols="6" md="6" sm="6">
							<v-text-field
								v-model="editedItem.codigo"
								label="Código"
								:error-messages="erroresItem.errorCodigo"
								@focus="erroresItem.errorCodigo = null"
							></v-text-field>
						</v-col>
                        <v-col cols="6" md="6" sm="6">
							<v-text-field
								v-model="editedItem.nombre"
								label="Nombre"
								:error-messages="erroresItem.errorNombre"
								@focus="erroresItem.errorNombre = null"
							></v-text-field>
						</v-col>
						<v-col cols="6" md="6" sm="6">
							<v-textarea
								v-model="editedItem.descripcion"
								label="Descripción"
								auto-grow
								class="pt-5"
								rows="1"
							></v-textarea>
						</v-col>
						<v-col cols="6" md="6" sm="6" class="mb-0 pb-0">
							<time-picker
								class="py-3"
								v-model="editedItem.duracion"
								label="Duración"
								:error="!!erroresItem.errorDuracion"
								@focus="erroresItem.errorDuracion = null"
							></time-picker>
						</v-col>
						<v-col cols="6">
							<v-autocomplete
								v-model="editedItem.idMantenimientoCategoria"
								label="Categoria de Inspección"
								:items="categorias"
								item-text="nombre"
								item-value="idMantenimientoCategoria"
								clearable
								:disabled="loadingCategorias"
								:loading="loadingCategorias"
								:error-messages="erroresItem.errorCategoria"
								@focus="erroresItem.errorCategoria = null"
							></v-autocomplete>
						</v-col>
						<v-col cols="6" md="6" sm="6" class="pb-0">
							<v-autocomplete
								v-model="editedItem.idMaquina"
								label="Modelo"
								:items="maquinas"
								:item-text="marcaModelo"
								item-value="idMaquina"
								:menu-props="{
									maxWidth: 'min-content'
								}"
								:error-messages="erroresItem.errorModelo"
								@focus="erroresItem.errorModelo = null"
								@change="onChangeModelo"
							>
								<template v-slot:item="data">
									<v-list-item-content>
										<v-list-item-title>{{
											data.item.marca +
											" - " +
											data.item.modelo
										}}</v-list-item-title>
										<v-list-item-subtitle>{{
											data.item.categoria
										}}</v-list-item-subtitle>
										<v-list-item-subtitle v-if="data.item.descripcionGeneral!=null">{{
											$utils.strings.truncate(
												data.item.descripcionGeneral,
												50
											)
										}}</v-list-item-subtitle>
									</v-list-item-content>
								</template>
							</v-autocomplete>
						</v-col>
						<v-col cols="6" md="6" sm="6" class="mt-0 pt-0">
							<MaquinaInsAutocomplete
								v-model="editedItem.idsMaquinaIns"
								label="[Opcional] Máquina(s)"
								:items="computedIns"
								:persistent-hint="editedItem.idMaquina == null"
								:hint="editedItem.idMaquina == null ? 'Seleccione un Modelo' : ''"
								:disabled="editedItem.idMaquina == null"
								chips
								multiple
								deletable-chips
								item-text="nombre"
								item-value="idMaquinaIns"
							/>
						</v-col>
						<v-col cols="6" md="6" sm="6">
							<MenuDatepicker
								v-model="editedItem.fechaInicioProgramada"
								label="Inicio Programado"
								clearable
								:errorMessage="erroresItem.errorFechaInicioProgramada"
								@focus="erroresItem.errorFechaInicioProgramada = null"
								@input="$forceUpdate()"
							/>
						</v-col>
						<v-col cols="6" v-if="showSubdepto">
							<v-autocomplete
								v-model="editedItem.idSubdepartamento"
								label="Subdepartamento"
								:items="subdepartamentos"
								:item-text="nombreSubdepto"
								item-value="idSubdepartamento"
								clearable
								:error-messages="erroresItem.errorSubdepto"
								@focus="erroresItem.errorSubdepto = null"
							></v-autocomplete>
						</v-col>
					</v-row>
					<v-row>
						<v-col cols="3">
							<p class="text-center mb-0">Tipo de asignación</p>
							<v-radio-group
								v-model="editedItem.tipoAsignacion"
								column
								class="mt-0"
								hide-details
								@change="onChangeTipoAsignacion"
							>
								<v-radio
									label="Personas"
									class="mb-0 text-center"
									:value="1"
								></v-radio>
								<v-radio
									label="Proveedor"
									:value="2"
								></v-radio>
							</v-radio-group>
						</v-col>
						<template v-if="editedItem.tipoAsignacion == 1">
							<v-col cols="3">
								<p class="text-center">Personas requeridas</p>
								<div class="d-flex align-center justify-center">
									<number-input
										v-model="editedItem.numPersonas"
										controls
										inline
										size="small"
										:min="1"
										center
									></number-input>
								</div>
							</v-col>
							<v-col cols="6">
								<v-autocomplete
									v-model="editedItem.asignados"
									label="[Opcional] Asignar por defecto a"
									:items="personasMantenimiento"
									:item-text="nombrePersona"
									item-value="idPersona"
									multiple
									chips
									deletable-chips
									clearable
									:error-messages="erroresItem.errorAsignados"
									@focus="erroresItem.errorAsignados = null"
								></v-autocomplete>
							</v-col>
						</template>
						<template v-else-if="editedItem.tipoAsignacion == 2">
							<v-col cols="6">
								<v-autocomplete
									v-model="editedItem.idMantenimientoProveedor"
									label="[Opcional] Proveedor por defecto"
									:items="proveedores"
									:item-text="$utils.mantenimientos.getNombreProveedor"
									item-value="idMantenimientoProveedor"
									clearable
									hide-details
								></v-autocomplete>
							</v-col>
						</template>
					</v-row>
					<v-row>
						<v-col cols="6">
							<p class="text-center">¿Cada cuántos días se repite?</p>
							<div class="d-flex align-center justify-center">
								<number-input
									v-model="editedItem.repeticion"
									controls
									inline
									size="small"
									:min="1"
									center
								></number-input>
							</div>
						</v-col>
						<v-col cols="6">
							<p class="text-center">¿Distribuir Inspección?</p>
							<div class="d-flex justify-center">
								<v-btn-toggle
									v-model="editedItem.distribuirInspecciones"
								>
									<v-btn
										depressed
										small
										class="ma-0"
										:color="
											editedItem.distribuirInspecciones ==
											0
												? 'success'
												: 'grey lighten-1'
										"
										>Si</v-btn
									>
									<v-btn
										depressed
										small
										class="ma-0"
										:color="
											editedItem.distribuirInspecciones ==
											1
												? 'error'
												: 'grey lighten-1'
										"
										>No</v-btn
									>
								</v-btn-toggle>
							</div>
						</v-col>
					</v-row>
					<ListadoPorHacer
						ref="todo"
						class="mt-4"
						:item="editedItem"
					/>
					<v-slide-y-transition>
						<v-row v-if="errores.length > 0">
							<ul class="mt-6">
								<li
									class="red--text"
									v-for="(error, idx) in errores"
									:key="'error' + idx"
								>
									{{ error }}
								</li>
							</ul>
						</v-row>
					</v-slide-y-transition>
				</v-card-text>
				<v-card-actions>
					<v-spacer></v-spacer>
					<v-btn
						color="green"
						text
						:disabled="guardando"
						@click="closeMantenimiento"
						>Cancelar</v-btn
					>
					<v-btn
						color="red"
						text
						:loading="guardando"
						:disabled="guardando"
						@click="guardarMantenimiento"
						>
							{{ textButtonSave }}
						</v-btn
					>
				</v-card-actions>
			</v-card>
		</v-dialog>
		<dialog-horario
			@create="newHorario($event)"
			@update="updateHorario($event)"
			ref="horario"
		>
		</dialog-horario>
		<v-dialog v-model="dialogEliminar" max-width="500px">
			<v-card>
				<v-card-title class="headline" style="word-break: break-word;">
					¿Está seguro que desea eliminar esta inspección?
				</v-card-title>
				<v-card-text class="text-body-1"
					>Esta acción no se puede revertir y será
					permanente.</v-card-text
				>
				<v-card-actions>
					<v-spacer></v-spacer>
					<v-btn
						color="green"
						text
						:disabled="deleting"
						@click="closeEliminarMantenimiento"
						>Cancelar</v-btn
					>
					<v-btn
						color="red darken-1"
						text
						@click="runRemoverMantenimiento"
						:disabled="deleting"
						:loading="deleting"
					>
						Eliminar
					</v-btn>
				</v-card-actions>
			</v-card>
		</v-dialog>
		<v-row class="justify-space-between">
			<v-col cols="4">
				<v-btn
					v-if="permisoVista('mantenimiento-inspecciones', 'c')"
					color="primary"
					:disabled="loading"
					@click="showDialogInspeccion();"
					>Agregar Inspección</v-btn
				>
			</v-col>
			<v-col cols="4">
				<v-text-field
					v-model="search"
					label="Buscar"
					prepend-inner-icon="mdi-magnify"
					clearable
					:disabled="loading"
				></v-text-field>
			</v-col>
		</v-row>
		<v-data-table
			:items="horarios"
			:headers="headersHorario"
			:loading="loading"
			:search="search"
			loading-text="Cargando... Espere, Por Favor."
		>
			<template v-slot:[`item.actions`]="{ item }">
				<v-tooltip
					v-if="permisoVista('mantenimiento-inspecciones', 'c')"
					bottom
					color="black"
					class="white--text"
				>
					<template v-slot:activator="{ on }">
						<v-icon
							v-on="on"
							small
							class="mr-1"
							:disabled="loading"
							@click="editMantenimiento(item,2)"
						>
							mdi-content-duplicate
						</v-icon>
					</template>
					<span class="white--text">Clonar</span>
				</v-tooltip>
				<v-tooltip
					v-if="permisoVista('mantenimiento-inspecciones', 'u')"
					
					bottom
					color="black"
					class="white--text"
				>
					<template v-slot:activator="{ on }">
						<v-icon
							v-on="on"
							small
							:disabled="loading"
							class="mr-1"
							@click="editMantenimiento(item)"
						>
							edit
						</v-icon>
					</template>
					<span class="white--text">Editar</span>
				</v-tooltip>
				<v-tooltip
					v-if="permisoVista('mantenimiento-inspecciones', 'd')"
					
					bottom
					color="black"
					class="white--text"
				>
					<template v-slot:activator="{ on }">
						<v-icon
							v-on="on"
							small
							:disabled="loading"
							@click="removeMantenimiento(item)"
						>
							delete
						</v-icon>
					</template>
					<span class="white--text">Eliminar</span>
				</v-tooltip>
			</template>
		</v-data-table>
	</v-col>
</template>

<script>
import timepicker from "@/components/TimePicker";
import insumos from "../Insumos.vue";
import horario from "../Horario.vue";
import axios from "axios";
import VSwatches from "vue-swatches";
import moment from "moment";
import ListadoPorHacer from "./ListadoPorHacer.vue";
import Recomendaciones from "./Recomendaciones.vue";
import MenuDatepicker from '../../../MenuDatepicker.vue';
import { mapState } from 'vuex';
import MaquinaInsAutocomplete from '../MaquinaInsAutocomplete.vue';

export default {
	components: {
		"time-picker": timepicker,
		"dialog-insumos": insumos,
		"dialog-horario": horario,
		VSwatches,
		ListadoPorHacer,
		Recomendaciones,
		MenuDatepicker,
		MaquinaInsAutocomplete,
	},
	data(){
		return {
			search: null,
			loadingProveedores: false,
			proveedores: [],
			loadingCategorias: false,
			categorias: [],
			erroresItem: {
				errorCodigo: null,
				errorNombre: null,
				errorModelo: null,
				errorSubdepto: null,
				errorAsignados: null,
				errorCategoria: null,
			},
			tipoEdicion: 1,
			subdepartamentos: [],
			ctkin: 0,
			personasMantenimiento: [],
			guardando: false,
			dialog: false,
			dialogInsumos: false,
			editedItem: null,
			defaultEditedItem: {
				idMantenimientoInspeccion: null,
				idMantenimientoCategoria: null,
				idSubdepartamento: null,
				codigo: "",
				nombre: "",
				descripcion: "",
				duracion: 0,
				idMaquina: null,
				costo: "$0.00",
				insumos: [],
				horarios: [],
				color: "#1976D2",
				idsMaquinaIns: [],
				fechaInicioProgramada: null,
				distribuirInspecciones: 0,
				repeticion: 0,
				comprobaciones: [],
				numPersonas: 1,
				asignados: [],
				tipoAsignacion: 1,
				idMantenimientoProveedor: null,
			},
			maquinas: [],
			headers: [
				{
					text: "Enunciado",
					align: "left",
					sortable: false,
					value: "enunciado",
					width: "80%,",
				},
				{
					text: "Acciones",
					align: "center",
					sortable: false,
					value: "actions",
					width: "20%,",
				},
			],
			alerta: false,
			errores: [],
			horarios: [],
			loading: false,
			headersHorario: [
				{
					text: "Código",
					align: "center",
					sortable: false,
					value: "codigo",
				},
				{
					text: "Nombre",
					align: "center",
					sortable: false,
					value: "nombre",
				},
				{
					text: "Categoría Mantenimiento",
					align: "center",
					sortable: false,
					value: "nombreCategoria",
				},
				{
					text: "Categoría Máquina",
					align: "center",
					sortable: false,
					value: "categoriaMaquina"
				},
				{
					text: "Modelo",
					align: "center",
					sortable: false,
					value: "maquina",
				},
				{
					text: "Acciones",
					align: "center",
					sortable: false,
					value: "actions",
					width: "10%,",
				},
			],
			dialogEliminar: false,
			eliminarItem: null,
			deleting: false,
			dialogAtemporal: false,
			instancias: [],
			atemporalItem: null,
			atemporalInfo: {
				inicio: null,
				instancias: [],
			},
			saving: false,
		}
	},
	beforeMount(){
		this.editedItem = structuredClone(this.defaultEditedItem);
		this.eliminarItem = structuredClone(this.defaultEditedItem);
		this.atemporalItem = structuredClone(this.defaultEditedItem);
	},
	mounted() {
		this.initialize();
	},
	computed: {
		...mapState(['nombreEmpresa']),
		esIndasa(){
			return this.nombreEmpresa.toUpperCase() == "INDASA";
		},
		showSubdepto(){
			if(this.esIndasa) return false;
			return true;
		},
		today(){
			return (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substring(0, 10);
		},
		textButtonSave(){
			return this.editedItem.idMantenimientoInspeccion == null
				? 'Agregar'
				: this.tipoEdicion == 1
					? 'Actualizar'
					: 'Clonar'
		},
		computedIns() {
			const ins = this.instancias.filter(
				(instancia) => instancia.idMaquina === this.editedItem.idMaquina
			);
			return ins;
		},
		plural() {
			return (
				this.$utils.isValid(this.atemporalInfo.instancias) &&
				this.atemporalInfo.instancias.length > 1
			);
		},
		labelAtemporal() {
			if (
				this.$utils.isValid(this.atemporalInfo.inicio) &&
				this.$utils.isValid(this.atemporalInfo.instancias) &&
				this.atemporalInfo.instancias.length > 0
			) {
				var localLocale = moment(
					this.atemporalInfo.inicio,
					"YYYY-MM-DD"
				);
				localLocale.locale("es");
				return (
					"Mantenimiento programado para " +
					localLocale.format(
						"[el día] dddd DD [de] MMMM  [del] YYYY"
					)
				);
			} else return null;
		},
	},
	methods: {
		onChangeModelo(){
			this.resetMaquinaIns();
		},
		onChangeTipoAsignacion(){
			this.editedItem.numPersonas = 1;
			this.editedItem.asignados = [];
			this.editedItem.idMantenimientoProveedor = null;
		},
		nombreMaquinaInstancia(maquinaIns){
			if(this.nombreEmpresa.toUpperCase() == 'INDASA'){
				return `No. Serie: ${maquinaIns.nombre}`;
			}
			return maquinaIns.nombre;
		},
		resetErrores(){
			this.errores = [];
			Object.keys(this.erroresItem).forEach( (key) => this.erroresItem[key] = null );
		},
		nombrePersona: ({ nombre, paterno, materno }) =>
			`${nombre ? `${nombre} ` : ""}${paterno ? `${paterno} ` : ""}${
				materno ? `${materno} ` : ""
			}`.trim(),
		nombreSubdepto: ({ codigo, nombre }) => codigo ? `[${codigo}] - ${nombre}`.trim() : nombre.trim(),
		showDialogInspeccion(){
			this.dialog = true;
			this.ctkin++;
		},
		getPersonasMantenimiento() {
			axios
				.get("/Mantenimientos/PersonasMantenimiento")
				.then((response) => {
					this.personasMantenimiento = response.data;
				})
				.catch((error) => {
					this.personasMantenimiento = [];
					console.log(error);
				});
		},
		showRecomendaciones(item) {
			this.$refs.recomen.showDialogRecomendaciones(item);
		},
		guardarMantenimiento() {
			// this.$refs.todo.togglePanel(null);
			this.save();
		},
		resetMaquinaIns() {
			this.editedItem.idsMaquinaIns = [];
		},
        maquinaMarcaModelo(item){
            const maquina = this.maquinas.find( maquina => maquina.idMaquina == item.idMaquina );
            if(maquina !== undefined) return `${ maquina.marca } - ${ maquina.modelo }`;
            else return '';
        },
		marcaModelo: (item) => item.marca + " - " + item.modelo,
		initialize() {
			this.getMaquinas();
			this.getMantenimientos();
			this.getInstancias();
            this.getPersonasMantenimiento();
			this.getSubdepartamentos();
			this.getCategoriasMantenimiento();
			this.getProveedoresMantenimiento();
		},
		getProveedoresMantenimiento(){
			this.loadingProveedores = true;

			axios
				.get('/MantenimientoProveedores')
				.then( (response) => {
					this.proveedores = response.data;
				})
				.catch(console.log)
				.finally( () => {
					this.loadingProveedores = false;
				});
		},
		getCategoriasMantenimiento() {
			this.loadingCategorias = true;

			axios
				.get("/MantenimientoCategorias")
				.then((response) => {
					this.categorias = response.data;
				})
				.catch((error) => {
					console.log(error);
					this.categorias = [];
				})
				.finally(() => {
					this.loadingCategorias = false;
				});
		},
		getSubdepartamentos(){
			axios
				.get('/Subdepartamento')
				.then( response => {
					this.subdepartamentos = response.data;
				})
				.catch(console.log)
		},
		openInsumos() {
			this.dialog = false;
			this.dialogInsumos = true;
		},
		closeInsumos() {
			this.dialogInsumos = false;
			this.dialog = true;
		},
		updateCosto(event) {
			if (this.dialogInsumos) this.editedItem.costo = event;
		},
		closeMantenimiento() {
			this.dialog = false;
			setTimeout(() => {
				this.editedItem = structuredClone(this.defaultEditedItem);
				this.resetErrores();
				this.tipoEdicion = 1;
				this.$refs.todo.togglePanel(0);
				this.$refs.todo.reset();
			}, 200);
		},
		getMaquinas() {
			axios
				.get("/Maquinas")
				.then((response) => {
					this.maquinas = response.data.filter((el) => el.esMaquina);
				})
				.catch((error) => {
					console.log(error);
				});
		},
		getInstancias() {
			axios
				.get("/MaquinaInstancias")
				.then((response) => {
					this.instancias = response.data;
				})
				.catch((error) => {
					console.log(error);
				});
		},
		getMantenimientos() {
			this.loading = true;
			axios
				.get("/Mantenimientos/GetMantenimientoInspeccion")
				.then((response) => {
					this.loading = false;
					this.horarios = response.data.map(inspeccion => ({
                        ...inspeccion,
                        distribuirInspecciones: inspeccion.distribuirInspecciones ? 0 : 1
                    }));
				})
				.catch((error) => {
					this.loading = false;
					console.log(error);
				});
		},
		editMantenimiento(item,tipoEdicion = 1) {
			this.editedItem = structuredClone(item);
			this.editedItem.comprobaciones = this.$utils.mantenimientos.mapComprobaciones(structuredClone(item.comprobaciones));
			this.editedItem.fechaInicioProgramada = item.fecha;
			this.tipoEdicion = tipoEdicion;
			if(this.tipoEdicion == 2){
				this.editedItem.codigo = null;
				this.editedItem.nombre = null;
			}
			this.dialog = true;
			this.ctkin++;
		},
		removeMantenimiento(item) {
			this.dialogEliminar = true;
			this.eliminarItem = structuredClone(item);
		},
		runRemoverMantenimiento() {
			this.deleting = true;
			axios
				.put(
					`/Mantenimientos/EliminarMantenimientoInspeccion/${this.eliminarItem.idMantenimientoInspeccion}`
				)
				.then((response) => {
					this.deleting = false;
					this.closeEliminarMantenimiento();
					this.getMantenimientos();
				})
				.catch((error) => {
					this.deleting = false;
					console.log(error);
				});
		},
		closeEliminarMantenimiento() {
			this.dialogEliminar = false;
			this.eliminarItem = structuredClone(this.defaultEditedItem);
		},
		launchMantenimiento(item) {
			this.dialogAtemporal = true;
			this.atemporalItem = item;
		},
		closeLaunchMantenimiento() {
			this.dialogAtemporal = false;
			this.atemporalItem = structuredClone(this.defaultEditedItem);
			this.atemporalInfo.inicio = null;
			this.atemporalInfo.instancias = [];
		},
		instanciasFiltradas() {
			return this.instancias.filter((instancia) => {
				return instancia.idMaquina == this.atemporalItem.idMaquina;
			});
		},
		validateAtemporal() {
			this.errores = [];
			if (!this.$utils.isValid(this.atemporalInfo.inicio)) {
				this.errores.push(
					"Falta Indicar una Fecha de Inicio del Mantenimiento"
				);
			}

			if (
				!this.$utils.isValid(this.atemporalInfo.instancias) ||
				this.atemporalInfo.instancias <= 0
			) {
				this.errores.push(
					"Falta Indicar la(s) instancia(s) a las que se realizara el mantenimiento"
				);
			}

			return this.errores.length == 0;
		},
		mantenimientoAtemporal() {
			if (this.validateAtemporal()) {
				var obj = {
					IdMantenimientoPreventivo:
						this.atemporalItem.idMantenimientoInspeccion,
					IdsMaquinasInstancias: this.atemporalInfo.instancias,
					InicioMantenimiento: this.atemporalInfo.inicio,
				};

				this.saving = true;

				axios
					.post("/Mantenimientos/PreventivoAtemporal", obj)
					.then(() => {
						this.saving = false;
						this.closeLaunchMantenimiento();
					})
					.catch((error) => {
						this.saving = false;
					});
			}
		},
		updateHorario($event) {
			var posExist = this.editedItem.horarios.findIndex((h) => {
				return (
					this.$utils.arrays.equals(
						h.diasSemana,
						$event.diasSemana
					) &&
					this.$utils.arrays.equals(h.meses, $event.meses) &&
					this.$utils.arrays.equals(h.diasMes, $event.diasMes) &&
					h.horaMinuto == $event.horaMinuto
				);
			});
			var pos = this.editedItem.horarios.findIndex(
				(h) =>
					h.idMantenimientoPreventivoHorario ==
					$event.idMantenimientoPreventivoHorario
			);
			if (posExist >= 0 && pos != posExist) {
				this.alerta = true;
				return;
			}
			if (pos >= 0) {
				this.editedItem.horarios[pos] = null;
				this.editedItem.horarios[pos] = $event;
			} else {
				this.editedItem.horarios.push($event);
			}
		},
		editHorario(item) {
			this.$refs.horario.lanzar(item);
		},
		deleteHorario(item) {
			var pos = this.editedItem.horarios.indexOf(item);
			if (pos >= 0) this.editedItem.horarios.splice(pos, 1);
		},
		validate() {
			this.resetErrores();

			if (
				!this.editedItem.codigo ||
				this.editedItem.codigo?.length < 5 ||
				this.editedItem.codigo?.length > 100
			) {
				this.erroresItem.errorCodigo = "Debe tener entre 5 y 100 caracteres";
			}

			if (
				!this.editedItem.nombre ||
				this.editedItem.nombre?.length < 5 ||
				this.editedItem.nombre?.length > 100
			) {
				this.erroresItem.errorNombre = "Debe tener entre 5 y 100 caracteres";
			}

			if(this.esIndasa){
				if(this.editedItem.idMantenimientoCategoria == null){
					this.erroresItem.errorCategoria = "Requerido";
				}
			}

			if (this.editedItem.idMaquina == null) {
				this.erroresItem.errorModelo = "Requerido";
			}

			if (this.editedItem.duracion <= 0) {
				this.erroresItem.errorDuracion = "Requerido";
			}

			if (this.editedItem.fechaInicioProgramada == null) {
				this.erroresItem.errorFechaInicioProgramada = "Requerido";
			}

			if(!this.esIndasa){
				if(this.editedItem.idSubdepartamento == null){
					this.erroresItem.errorSubdepto = "Requerido";
				}
			}

			if(this.editedItem.numPersonas > 0){
				if(this.editedItem.asignados.length > 0 && this.editedItem.asignados.length != this.editedItem.numPersonas){
					this.erroresItem.errorAsignados = "Debe ser igual al número de personas requeridas";
				}
			}

			const comprobacionesValidas = this.$refs.todo.validate();

			const hayErrores = Object.values(this.erroresItem).some( (value) => value != null ) || !comprobacionesValidas;
			if(hayErrores){
				this.errores.push("Hay uno o mas errores, porfavor reviselos y vuelva a intentarlo");
				setTimeout(() => {
					this.errores = [];
				}, 3000);
			}

			return !hayErrores;
		},
		save() {
			if (!this.validate()) return;
			this.guardando = true;

			if(this.esIndasa){
				this.$utils.mantenimientos.asignarSubdeptoDefault(this.subdepartamentos,this.editedItem);
			}
			
			const comprobaciones = this.$refs.todo.getListado();
			const data = {
				Codigo: this.editedItem.codigo,
				Nombre: this.editedItem.nombre,
				Descripcion: this.editedItem.descripcion,
				Duracion: this.editedItem.duracion,
				idSubdepartamento: this.editedItem.idSubdepartamento,
				IdMaquina: this.editedItem.idMaquina,
				idsMaquinaIns: this.editedItem.idsMaquinaIns,
				repeticion: this.editedItem.repeticion,
				comprobaciones,
				distribuirInspecciones:
					!this.editedItem.distribuirInspecciones,
				fechaInicioProgramada:
					this.editedItem.fechaInicioProgramada,
				numPersonas: this.editedItem.numPersonas,
				tipoAsignacion: this.editedItem.tipoAsignacion,
				asignados: this.editedItem.asignados,
				idMantenimientoCategoria: this.editedItem.idMantenimientoCategoria,
				idMantenimientoProveedor: this.editedItem.idMantenimientoProveedor
			};

			axios({
				url: this.editedItem.idMantenimientoInspeccion == null || this.tipoEdicion == 2
					? "/Mantenimientos/CrearMantenimientoInspeccion"
					: `/Mantenimientos/ActualizarMantenimientoInspeccion/${this.editedItem.idMantenimientoInspeccion}`,
				method: this.editedItem.idMantenimientoInspeccion == null || this.tipoEdicion == 2
					? 'POST'
					: 'PUT',
				data
			})
				.then(() => {
					this.closeMantenimiento();
					this.getMantenimientos();
					this.guardando = false;
				})
				.catch((error) => {
					console.log(error);
					this.guardando = false;
					if (error.response?.status == 409) {
						this.$set(this.erroresItem, "errorCodigo", "Ya existe");
					}
				});
		},
	},
};
</script>

<style>
.vue-swatches__trigger__wrapper {
	border: 1px solid rgb(0, 0, 0) !important;
	border-radius: 12px !important;
}
</style>