<template>
  <v-dialog v-model="show" persistent max-width="500px" key="especial">
    <v-card>
      <v-card-title>
        Producto Especial
      </v-card-title>
      <v-card-text>
        <v-textarea
          v-model="mensaje"
          auto-grow
          outlined
          label="Mensaje"
        ></v-textarea>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="blue darken-1" text @click="cancelar()">Cancelar</v-btn>
        <v-btn color="red darken-1" text @click="aceptar()">Aceptar</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  props:{
    show:{
      type: Boolean,
      default: true,
    },
    mensaje:{
      type: String,
      default: '',
    }
  },
  data: () => ({
  }),
  methods:{
    cancelar(){
      this.mensaje = "";
      this.$emit('change',"");
    },
    aceptar(){
      this.$emit('change',this.mensaje);
    }
  }
}
</script>