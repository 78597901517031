<template>
    <v-dialog v-model="dialog" persistent max-width="70%">
        <v-card>
            <v-card-title>
                Costos de Reposición de Material
                <v-btn color="primary" class="ml-auto" @click="postCalcularCostosReposicion()">Recalcular Costos de
                    Reposición</v-btn>
            </v-card-title>
            <br>
            <v-card-text>
                <v-container>
                    <v-row>
                        <v-col cols="12">
                            <v-autocomplete v-model="filtros.idOtd" label="Orden de Trabajo Detalle"
                                :items="editedItem.otDetalles" item-text="nombre" item-value="idTrabajoOrdenDetalle"
                                hide-details clearable @change="getMateriasPrimasCostoCompra()"></v-autocomplete>
                        </v-col>
                        <v-col cols="12">
                            <v-data-table :items="materiasPrimasCostoCompra" :headers="headers" :loading="loading"
                                :items-per-page="-1" hide-default-footer>
                                <template v-slot:[`item.articulo`]="{ item }">
                                    {{ getNombreArticulo(item) }}
                                </template>
                                <template v-slot:[`item.cantidadReponer`]="{ item }">
                                    {{ $utils.strings.formatearCifra(item.cantidadReponer, 2) }}
                                </template>
                                <template v-slot:[`item.costoUnitarioActual`]="{ item }">
                                    {{ $utils.strings.formatearCifra(item.costoUnitarioActual, 2) }}
                                </template>
                                <template v-slot:[`item.costo`]="{ item }">
                                    ${{ item.costo.toFixed(2) }}
                                </template>
                            </v-data-table>
                        </v-col>
                        <v-card-title class="ml-auto">Total ${{ $utils.strings.formatearCifra(totalCostos, 2)
                        }}</v-card-title>
                    </v-row>
                </v-container>
            </v-card-text>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn text color="success" @click="cerrarDialog()">Cerrar</v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
import axios from 'axios';
export default {
    data() {
        return {
            dialog: false,
            loading: false,

            filtros: {
                idOtd: null,
            },
            defaultFiltros: {
                idOtd: null,
            },

            editedItem: {
                idTrabajoOrden: null,
                otDetalles: [],
                codigoAlmacen: "",
                nombreAlmacen: ""
            },
            defaultEditedItem: {
                idTrabajoOrden: null,
                otDetalles: [],
                codigoAlmacen: "",
                nombreAlmacen: ""
            },

            headers: [
                {
                    text: "Artículo",
                    align: "center",
                    value: "nombre",
                }, {
                    text: "Cantidad a Reponer",
                    align: "center",
                    value: "cantidadReponer",
                }, {
                    text: "Costo Unitario Actual",
                    align: "center",
                    value: "costoUnitarioActual",
                }, {
                    text: "Fecha del Costo",
                    align: "center",
                    value: "fechaCosto",
                }, {
                    text: "Costo",
                    align: "center",
                    value: "costo",
                }
            ],
            materiasPrimasCostoCompra: [],

            totalCostos: 0,
        }
    },
    methods: {
        getNombreArticulo: ({ codigo, nombre }) => `[${codigo}] - ${nombre}`,
        resetValues() {
            this.editedItem = { ...this.defaultEditedItem };
            this.filtros = { ...this.defaultFiltros };
        },
        showDialog() {
            this.dialog = true;
        },
        cerrarDialog() {
            this.resetValues();
            this.dialog = false;
        },
        setEditedItem(item) {
            this.editedItem.idTrabajoOrden = item.idTrabajoOrden;
            this.editedItem.nombreAlmacen = item.nombreAlmacen;
            this.editedItem.codigoAlmacen = item.codigoAlmacen;
            this.editedItem.otDetalles = item.articulos.map(artic => ({
                idTrabajoOrdenDetalle: artic.idTrabajoOrdenDetalle,
                nombre: artic.nombre
            }));
        },
        initialize(item) {
            this.setEditedItem(item);
            this.getMateriasPrimasCostoCompra()
        },

        getMateriasPrimasCostoCompra() {
            this.loading = true;
            axios
                .get(`/TrabajoOrdenes/MateriasPrimasCostoCompra/${this.editedItem.idTrabajoOrden}`, {
                    params: this.filtros
                })
                .then(response => {
                    this.materiasPrimasCostoCompra = response.data;
                })
                .catch(error => {
                    console.log(error);
                })
                .finally(() => {
                    this.loading = false;
                    this.calcularTotal()
                })
        },
        postCalcularCostosReposicion() {
            this.loading = true;
            axios
                .post(`/TrabajoOrdenes/CalcularCostosReposicion/${this.editedItem.idTrabajoOrden}`)
                .then(response => {
                  this.getMateriasPrimasCostoCompra()
                })
                .catch(error => {
                    console.log(error);
                })
                .finally(() => {
                    this.loading = false;
                })
        },

        calcularTotal() {
            this.totalCostos = this.materiasPrimasCostoCompra.reduce((total, item) => {
                if (item.hasOwnProperty('costo')) {
                    return total + item.costo;
                }
                return total;
            }, 0);
        }
    }
}
</script>