<template>
  <v-text-field
    v-model="value"
    @change="changeEvent($event)"
    @input="inputEvent($event)"
    :dense="dense"
    :hide-details="hideDetails"
    :error="error"
    :class="(error)?'v-input--is-focused':''"
    @keypress="preventInput"
    :label="label"
    :placeholder="placeholder"
  ></v-text-field>
</template>

<script>
export default {
  props:{
    value:{
      type:String,
      default:'',
    },
    dense:{
      type:Boolean,
      default:false
    },
    hideDetails:{
      type:Boolean,
      default:false
    },
    minDatetime:{
      type:String,
      default:"",
    },
    label:{
      type:String,
      default:null,
    },
    placeholder: {
      type: String,
      default: ""
    }
  },
  data: () => ({
    error:false,
  }),
  methods:{
    anyError(){
      return this.error;
    },
    preventInput($event){
    	var keyCode = ($event.keyCode ? $event.keyCode : $event.which);
      if (keyCode != 32 && (keyCode < 47 || keyCode > 58)) {
        $event.preventDefault();
      }
      if(this.value.length > 15){
        $event.preventDefault();
      }
    },
    changeEvent(event){
      this.error = !this.checkString(event);
      if(!this.error && this.checkString(this.minDatetime)){
        if(this.getDate(event) < this.getDate(this.minDatetime)){
          this.error = true;
        }
      }
      this.$emit('change',event);
    },
    inputEvent(event){
      this.error = !this.checkString(event);
      if(!this.error && this.checkString(this.minDatetime)){
        if(this.getDate(event) < this.getDate(this.minDatetime)){
          this.error = true;
        }
      }
      this.$emit('input',event);
    },
    getDate(datetime){
      var matches = datetime.match(/^(\d{2})\/(\d{2})\/(\d{4}) (\d{2}):(\d{2})$/);
      var year = parseInt(matches[3], 10);
      var month = parseInt(matches[2], 10) - 1; // months are 0-11
      var day = parseInt(matches[1], 10);
      var hour = parseInt(matches[4], 10);
      var minute = parseInt(matches[5], 10);
      return new Date(year, month, day, hour, minute);
    },
    checkString(event){
      var matches = event.match(/^(\d{2})\/(\d{2})\/(\d{4}) (\d{2}):(\d{2})$/);
      if (matches === null) {
          // invalid
          return false;
      } else{
          // now lets check the date sanity
          var year = parseInt(matches[3], 10);
          var month = parseInt(matches[2], 10) - 1; // months are 0-11
          var day = parseInt(matches[1], 10);
          var hour = parseInt(matches[4], 10);
          var minute = parseInt(matches[5], 10);
          var date = new Date(year, month, day, hour, minute);
          if (date.getFullYear() !== year
            || date.getMonth() != month
            || date.getDate() !== day
            || date.getHours() !== hour
            || date.getMinutes() !== minute
          ) {
            // invalid
            return false;
          } else {
            // valid
            return true;
          }
      }
    },
    getValue(){
      return this.value;
    },
    setValue(value,verify = true){
      this.value = value;
      if(verify)this.inputEvent(value);
    }
  }
}
</script>