<template>
  <div>
    <v-col cols="12" class="pt-0">
      <v-row>
        <v-col cols="12" md="6" sm="12">
          <datePicker
              v-model="filtros.desde"
              format="YYYY-MM-DD"
              :maxDate="filtros.hasta"
              label="Fecha Inicio (Obligatorio)"
              clearable
              maxToday
              :disabled="cargando"
              @input="getData()"
          ></datePicker>
        </v-col>

        <v-col cols="12" md="6" sm="12">
          <datePicker
              v-model="filtros.hasta"
              format="YYYY-MM-DD"
              :minDate="filtros.desde"
              label="Fecha Final (Obligatorio)"
              clearable
              maxToday
              :disabled="cargando"
              @input="getData()"
          ></datePicker>
        </v-col>

        <v-col cols="12" md="12" sm="12" v-if="cargando">
          <v-progress-linear
              color="primary"
              height="5"
              indeterminate
          ></v-progress-linear>

          <div class="text-center mt-10">
            <p>Seleccione fechas para mostrar las gráficas.</p>
          </div>
        </v-col>

        <v-row v-else>
          <v-col cols="12" md="6" sm="12">
            <v-data-table
                v-if="dataReportes.length > 0"
                :headers="headersReportes"
                :items="dataReportes"
                class="elevation-3 mb-10 mt-3 border"
            >
              <template v-slot:top>
                <v-toolbar flat>
                  <v-toolbar-title>Salidas de Materia Prima por Categoría</v-toolbar-title>
                  <v-divider
                      class="mx-4"
                      inset
                      vertical
                  ></v-divider>
                </v-toolbar>
              </template>
              <template v-slot:item="{ item }">
                <tr>
                  <td
                  v-if="item.view"
                      :style="{
                      backgroundColor:
                        item.color === 'white' ? item.color : item.color + '25',
                    }"
                  >
                    {{ item.categoria }}
                  </td>
                  <td
                  v-if="item.view"
                      :style="{
                      backgroundColor: item.color,
                      color: item.color !== 'white' ? 'whitesmoke' : 'black',
                      fontWeight: item.categoria.includes('Total')
                        ? 'bold'
                        : 'normal',
                    }"
                  >
                    {{ item.conteo.toLocaleString('es-MX') }}
                    <!-- {{ item }} -->
                  </td>
                </tr>
              </template>
            </v-data-table>
          </v-col>

          <v-col cols="12" md="6" sm="12">
            <v-data-table
                v-if="dataTrazabilidad.length > 0"
                :headers="headersTrazabilidad"
                :items="dataTrazabilidad"
                class="elevation-3 mb-10 mt-3 border"
            >
              <template v-slot:top>
                <v-toolbar flat>
                  <v-toolbar-title>PP sin HDPE y sin PEMSA</v-toolbar-title>
                  <v-divider
                      class="mx-4"
                      inset
                      vertical
                  ></v-divider>
                </v-toolbar>
              </template>
              <template v-slot:item="{ item }">
                <tr>
                  <td
                      :style="{
                      backgroundColor:
                        item.color === 'white' ? item.color : item.color + '25',
                      fontWeight:
                        item.categoria.includes('Total') ||
                        item.categoria === ' '
                          ? 'bold'
                          : 'normal',
                    }"
                  >
                    {{ item.categoria }}
                  </td>
                  <td
                      :style="{
                      backgroundColor: item.color,
                      color: item.color !== 'white' ? 'whitesmoke' : 'black',
                      fontWeight:
                        item.categoria.includes('Total') ||
                        item.categoria === ' '
                          ? 'bold'
                          : 'normal',
                    }"
                  >
                    {{ item.cantidad.toLocaleString('es-MX') }}
                  </td>
                </tr>
              </template>
            </v-data-table>
            <v-divider></v-divider>
          </v-col>

          <v-col cols="12" md="6" sm="12">
            <v-data-table
                v-if="dataPPSinHDPE.length > 0"
                :headers="headersPPSinHDPE"
                :items="dataPPSinHDPE"
                class="elevation-3 mb-10 mt-3 border"
            >
              <template v-slot:top>
                <v-toolbar flat>
                  <v-toolbar-title>PP sin HDPE</v-toolbar-title>
                  <v-divider
                      class="mx-4"
                      inset
                      vertical
                  ></v-divider>
                </v-toolbar>
              </template>
              <template v-slot:item="{ item }">
                <tr>
                  <td
                      :style="{
                      backgroundColor:
                        item.color === 'white' ? item.color : item.color + '25',
                    }"
                  >
                    {{ item.categoria }}
                  </td>
                  <td
                      :style="{
                      backgroundColor: item.color,
                      color:
                        item.color !== 'white' && item.color !== '#FFFF00'
                          ? 'whitesmoke'
                          : 'black',
                    }"
                  >
                    {{ item.cantidad.toLocaleString('es-MX') }}
                  </td>
                </tr>
              </template>
            </v-data-table>
            <v-divider></v-divider>
          </v-col>

          <v-col cols="12" md="6" sm="12">
            <v-data-table
                v-if="dataComparativos.length > 0"
                :headers="headersComparativos"
                :items="dataComparativos"
                class="elevation-3 mb-10 mt-3 border"
            >
              <template v-slot:top>
                <v-toolbar flat>
                  <v-toolbar-title>Comparativos</v-toolbar-title>
                  <v-divider
                      class="mx-4"
                      inset
                      vertical
                  ></v-divider>
                </v-toolbar>
              </template>
              <template v-slot:item="{ item }">
                <tr>
                  <td
                      :style="{
                      backgroundColor: '#FFFF00',
                    }"
                  >
                    {{ item.categoria }}
                  </td>
                  <td
                      :style="{
                      backgroundColor: item.color,
                      color:
                        item.color !== 'white' && item.color !== '#FFFF00'
                          ? 'whitesmoke'
                          : 'black',
                      fontWeight: item.categoria.includes('Total')
                        ? 'bold'
                        : 'normal',
                    }"
                  >
                    {{ item.cantidad.toLocaleString('es-MX') }}
                  </td>
                </tr>
              </template>
            </v-data-table>
            <v-divider></v-divider>
          </v-col>

          <v-col cols="12" md="12" sm="12">
            <apexchart
                v-if="series.length > 0"
                type="bar"
                height="500"
                :options="chartOptions"
                :series="series"
            ></apexchart>
          </v-col>
        </v-row>
      </v-row>
    </v-col>
  </div>
</template>

<script>
import axios from "axios";
import ICountUp from "vue-countup-v2";
import Template from "@/components/DashViews/Dashboards/Template.vue";
/* 
    import { mapState } from "vuex"; */
/* import DownloadBtn from "../../../DownloadBtn.vue"; */

export default {
  components: {
    Template,
    ICountUp,
  },
  data() {
    return {
      loadingEstadisticas: true,
      cargando: false,
      filtros: {
        desde: null,
        hasta: null,
      },
      ots: [],
      categories: [],
      series: [],
      dataReportes: [],
      headersReportes: [
        {text: "Categoría", value: "categoria"},
        {text: "Conteo", value: "conteo"},
      ],
      dataTrazabilidad: [],
      headersTrazabilidad: [
        {text: "Categoría", value: "categoria"},
        {text: "Cantidad", value: "cantidad"},
      ],
      dataPPSinHDPE: [],
      headersPPSinHDPE: [
        {text: "Categoría", value: "categoria"},
        {text: "Cantidad", value: "cantidad"},
      ],
      dataComparativos: [],
      headersComparativos: [
        {text: "Categoría", value: "categoria"},
        {text: "Cantidad", value: "cantidad"},
      ],
      loadingMaquinas: false,
      loadingots: false,
      apex: {
        kgUtilizados: 0,
        cantidadLaminas: 0,
        pzaFabricadas: 0,
      },
    };
  },
  mounted() {
    this.initialize();
  },
  computed: {
    chartOptions() {
      return {
        states: {
          active: {
            allowMultipleDataPointsSelection: false,
          },
        },
        chart: {
          type: "bar",
          height: 500,
          barWidth: "10",
          stacked: true,
          toolbar: {
            show: true,
          },
          zoom: {
            enabled: true,
          },
        },
        plotOptions: {
          bar: {
            horizontal: false,
            columnWidth: "100%",
            dataLabels: {
              total: {
                enabled: true,
                style: {
                  fontSize: "13px",
                  fontWeight: 900,
                },
              },
            },
          },
        },
        dataLabels: {
          enabled: false,
          formatter: function (val) {
            return val.toFixed(2) + " Kg";
          },
        },
        stroke: {
          show: true,
          width: 2,
          colors: ["transparent"],
        },
        xaxis: {
          categories: this.categories,
          labels: {
            maxHeight: 200,
          },
        },
        yaxis: {
          labels: {
            formatter: function (val) {
              return val.toFixed(2) + " Kg";
            },
          },
        },
        fill: {
          opacity: 1,
        },
        tooltip: {
          y: {
            formatter: function (val) {
              return val.toFixed(2) + " Kg";
            },
          },
        },
      };
    },
  },
  methods: {
    initialize() {
      // this.getData();
    },
    getDataReportes() {
      if (this.filtros.desde == null) return;
      if (this.filtros.hasta == null) return;

      this.cargando = true;
      this.loadingEstadisticas = false;

      axios
          .get(`/Reportes/ReporteCategoriaArticulos/`, {
            params: {
              FechaInicio: this.filtros.desde,
              FechaFin: this.filtros.hasta,
            },
          })
          .then((response) => {
            
            const datosObject = response.data.response.find(
                (item) => item.name === "DATOS"
            );
            this.dataReportes = datosObject.data;

            //Se agrega la propiedad view en true para que todas aparezcan
            this.dataReportes.forEach(item => {
              item.view = true;
            });

            

            let datosTrazabilidad =
                response.data.responseTrazabilidadInventario.datos;

            datosTrazabilidad[8].cantidad =
                datosObject.data[4].conteo - datosTrazabilidad[7].cantidad;

            // Hacer un slice de datosTrazabilidad para mover los elentos  9, 10, 11 a otro array
            let datosTrazabilidad2 = datosTrazabilidad.slice(9, 12);
            // Eliminar los elementos 9, 10, 11 del array original
            datosTrazabilidad.splice(9, 3);

            this.dataTrazabilidad = datosTrazabilidad;
            this.dataPPSinHDPE = datosTrazabilidad2;

            // Crear datos de tabla COMPARATIVOS
            let datosComparativos = [];
            datosComparativos.push({
              categoria: "MT PP-PE-MAQUILA",
              cantidad: datosObject.data[1].conteo + datosObject.data[2].conteo,
              color: "#92D050",
              view: true // se agrego para poder ocultar en la tabla
            });
            datosComparativos.push({
              categoria: "MP PP-POLIETINELO",
              cantidad: datosTrazabilidad2[0].cantidad,
              color: "#00B050",
              view: true
            });
            datosComparativos.push({
              categoria: "RECORTE PP-POLIETILENO",
              cantidad: datosObject.data[6].conteo,
              color: "#00B0F0",
              view: true
            });
            datosComparativos.push({
              categoria: "PELET PP",
              cantidad: datosTrazabilidad2[1].cantidad,
              color: "#0070C0",
              view: true
            });
            datosComparativos.push({
              categoria: "Total Jalado",
              cantidad: datosObject.data[4].conteo,
              color: "#FFFF00",
              view: true
            });
            datosComparativos.push({
              categoria: "Total Entregado",
              cantidad: datosTrazabilidad2[2].cantidad,
              color: "#FFFF00",
              view: true
            });

            this.dataComparativos = datosComparativos;

            // Codigo temporal
            this.dataComparativos[0].cantidad = this.dataReportes[6].conteo;
            this.dataComparativos[2].cantidad = this.dataReportes[7].conteo;
            
            const elementoAReubicar = this.dataReportes.splice(9, 1)[0];

            this.dataReportes.splice(3, 0, elementoAReubicar);

            this.dataReportes[9].view = false; // se oculta especificamente
            
            this.datosComparativos
            
            //Fin del codigo temporal

            this.dataComparativos[4].cantidad = this.dataComparativos[0].cantidad +  this.dataComparativos[2].cantidad;
            
            var temp = this.dataReportes[10];

            this.dataReportes.splice(10, 1);
            this.dataReportes.splice(7, 0, temp);
          })
          .catch((error) => {
            console.log(error);
          })
          .finally(() => {
            this.cargando = false;
            this.loadingEstadisticas = true;
          });
    },
    getData() {
      if (this.filtros.desde == null) return;
      if (this.filtros.hasta == null) return;

      this.cargando = true;
      this.loadingEstadisticas = false;
      axios
          .get(`/Reportes/ReporteProduccionProcesoFechas/`, {
            params: {
              desde: this.filtros.desde,
              hasta: this.filtros.hasta,
            },
          })
          .then((response) => {
            this.series = response.data.series;
            this.categories = response.data.categorias;
            /* this.apex.kgUtilizados = response.data.kgUtilizados;
              this.apex.cantidadLaminas = response.data.cantidadLaminas;
              this.apex.pzaFabricadas = response.data.pzaFabricadas; */

            this.getDataReportes();
          })
          .catch((error) => {
            console.log(error);
          })
          .finally(() => {
            this.cargando = false;
            this.loadingEstadisticas = true;
          });
    },
  },
};
</script>
