<template>
  <v-col cols="12" v-if="permisoVista('produccionusuarios','r')">
    <v-data-table
      :headers="headers"
      :items="usuarios"
      :search="search"
      :loading="loading"
      :key="loadingChange"
      loading-text="Cargando... Espere, Por Favor."
    >
      <template v-slot:item.condicion="{ item }">
        <v-switch
          v-if="permisoVista('produccionusuarios','d')"
          class="justify-center" 
          @change="changeState(item)"
          :input-value="item.condicion"
          :label="item.condicion?'Activado':'Desactivado'">
        </v-switch>
      </template>
      <template v-slot:top>
        <v-toolbar flat color="white">
          <v-dialog v-model="dialog" persistent max-width="500px" :key="dialog">
            
            
            <v-dialog v-model="pin" persistent max-width="500px">
              <v-card>
                <v-card-title>
                  Asignar Tarjeta
                </v-card-title>
                <v-card-text>
                  <bar-code-reader v-if="pin" @change="getCode"></bar-code-reader>
                  <div class="text-center">
                    <v-progress-circular
                      :rotate="-90"
                      :size="200"
                      :width="25"
                      :value="(lectures / 3)*100"
                      color="primary"
                    >
                      {{ ((lectures / 3)*100).toFixed(2) }}%
                    </v-progress-circular>
                    <br>
                    <br>
                    <div class="text-h5" v-if="lectures >= 3">Tarjeta Leída Correctamente</div>
                  </div>
                </v-card-text>
                <v-card-actions>
                  <v-spacer></v-spacer>
                    <v-btn color="blue darken-1" text @click="pin=false; resetCardReader(); pinViewed=Object.assign({},defaultItem);">Cancelar</v-btn>
                    <v-btn color="red darken-1" text @click="confirmarTarjeta" :disabled="lectures < 3">
                      Confirmar
                      <template v-slot:loader>
                        <v-progress-circular
                          indeterminate
                          :width="2"
                          :size="24"
                          color="red"
                        ></v-progress-circular>
                      </template>
                    </v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>


            <template v-slot:activator="{ on }">
              <v-btn v-if="permisoVista('produccionusuarios','c')" color="primary" dark class="mb-2" v-on="on">Nuevo Usuario</v-btn>
            </template>
            <v-card>
              <v-card-title>
                <span class="headline">{{ formTitle }}</span>
              </v-card-title>
              <v-card-text>
                <v-container>
                  <v-row>
                    <v-col class="d-flex" cols="11" sm="11">
                      <v-select
                        v-model="editedItem.idArea"
                        :items="areas"
                        label="Área"
                        item-text="nombre"
                        :disabled="loadingAreas"
                        item-value="idArea"
                      ></v-select>
                    </v-col>
                    <v-icon
                      class="mr-2"
                      :disabled="loadingAreas"
                      @click="getAreas()"
                    >
                      mdi-reload
                    </v-icon>
                    <v-col justify="center" cols="12" sm="12" md="12">
                      <v-text-field v-model="editedItem.nombre" label="Nombre"></v-text-field>
                    </v-col>
                    <v-col justify="center" cols="6" sm="6" md="6">
                      <v-text-field v-model="editedItem.username" label="Username"></v-text-field>
                    </v-col>
                    <v-col justify="center" cols="6" sm="6" md="6">
                      <v-btn
                        v-if="loginOpsModo == 2"
                        color="primary"
                        block
                        text
                        @click="pinDialog"
                      >
                        <v-icon>mdi-credit-card</v-icon> Asignar Tarjeta
                      </v-btn>
                      <v-text-field
                        v-if="loginOpsModo != 2"
                        v-model="editedItem.password"
                        :append-icon="password ? 'mdi-eye' : 'mdi-eye-off'"
                        :type="password ? 'text' : 'password'"
                        name="password"
                        label="Contraseña del Usuario"
                        :hint="'Al menos '+paswordStationLength+' caracteres'"
                        counter
                        @click:append="password = !password"
                      ></v-text-field>
                    </v-col>
                  </v-row>
                  <ul>
                    <li class="red--text" v-for="ex in errores" :key="ex">{{ ex }}</li>
                  </ul>
                </v-container>
              </v-card-text>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="blue darken-1" text @click="dialog=false; errores=[];">Cancelar</v-btn>
                <v-btn color="red darken-1" text @click="save()" :disabled="saving" :loading="saving">
                  Guardar
                  <template v-slot:loader>
                    <v-progress-circular
                      indeterminate
                      :width="2"
                      :size="24"
                      color="red"
                    ></v-progress-circular>
                  </template>
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
          <v-spacer></v-spacer>
          <v-text-field
            v-model="search"
            append-icon="search"
            label="Buscar"
            single-line
            hide-details
          ></v-text-field>
        </v-toolbar>
      </template>
      <template v-slot:item.action="{ item }">
        <v-row>
          <v-col>
            <v-tooltip bottom color="black" class="white--text">
          <template v-slot:activator="{ on }">
        <v-icon v-on="on"
              v-if="permisoVista('produccionusuarios','u')"
              small
              class="mr-2"
              @click="editItem(item)"
            >
              edit
            </v-icon>
             </template>
          <span class="white--text">Editar</span>
        </v-tooltip>
          </v-col>
        </v-row>
      </template>
      <template v-slot:no-data>
        <v-btn color="primary" @click="initialize">Refrescar</v-btn>
      </template>
    </v-data-table>
    <v-snackbar
      v-model="alerta"
      top
      color="error"
    >
      Existe un Usuario con el mismo Username
      <v-btn
        text
        @click="alerta = false"
      >
        Cerrar
      </v-btn>
    </v-snackbar>
  </v-col>
  
</template>

<script>
import axios from "axios";
import { mapState } from "vuex";
import BarCodeReader from '@/components/BarCodeReader';

  export default {
    components: { 
      BarCodeReader
    },
    data: () => ({
      pin: false,
      cardCode: "",
      lectures: 0,
      saving: false,
      password:true,
      loadingChange:false,
      areas:[],
      loadingAreas: false,
      dialog: false,
      alerta: false,
      search: '',
      headers: [
        {
          text: 'Nombre',
          align: 'left',
          sortable: true,
          value: 'nombre',
        },
        {
          text: 'Username',
          align: 'left',
          sortable: true,
          value: 'username',
        },
        {
          text: 'Área',
          align: 'left',
          sortable: true,
          value: 'area',
        },
        {
          text: 'Activo',
          align: 'center',
          sortable: true,
          value: 'condicion',
          width:'25%'
        },
        { text: 'Acciones', value: 'action', sortable: false, width:'15%', align:'center' },
      ],
      loading: false,
      usuarios: [],
      action: 0,
      errores: [],
      editedIndex: -1,
      editedItem: {
        idArea: -1,
        nombre: '',
        username: '',
        password: '',
      },
      defaultItem: {
        idArea: -1,
        nombre: '',
        username: '',
        password: '',
      },
    }),

    computed: {
       ...mapState(["paswordStationLength","loginOpsModo","loginOpsLength"]),
      formTitle () {
        return this.editedIndex === -1 ? 'Nuevo Usuario' : 'Editar Usuario'
      }
    },

    watch: {
      dialog (val) {
        val || this.close()
      }
    },

    created () {
      this.initialize()
    },

    methods: {
      initialize () {
        let me = this;
        me.getAreas();
        me.loading = true;
        axios
        .get("/ProduccionUsuarios")
        .then(response =>  {
          me.usuarios = response.data;
          me.loading = false;
        })
        .catch(error => {
          console.log(error);
        });
      },
       getAreas(){
        let me = this;
        me.loadingAreas = true;
        axios
        .get("/Areas")
        .then(response =>  {
          me.areas = response.data.filter(element => {
            return element.idArea >=2;
          });
          me.loadingAreas = false;
        })
        .catch(error => {
          console.log(error);
        });
      },
      editItem (item) {
        this.action = 1;
        this.editedIndex = this.usuarios.indexOf(item)
        this.editedItem = Object.assign({}, item)
        this.dialog = true
      },

      changeState (item) {
        let index = this.usuarios.indexOf(item);
        let me = this;
        this.usuarios[index].loading = true;
        if(!this.usuarios[index].condicion){
          //Activar
          axios
          .put("/ProduccionUsuarios/Activar/" + this.usuarios[index].idProduccionUsuario)
          .then(response =>  {
            
          })
          .catch(error => {
            console.log(error);
          });
        }
        else{
          axios
          .put("/ProduccionUsuarios/Desactivar/" + this.usuarios[index].idProduccionUsuario)
          .then(response =>  {
            
          })
          .catch(error => {
            console.log(error);
          });
        }
        this.usuarios[index].condicion = !this.usuarios[index].condicion;
      },
      close () {
        this.action = 0,
        this.dialog = false
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      },
      validate(){
        this.errores =  [];
        if (
          this.editedItem.idArea <= 0
        )
        {
          this.errores.push(
            "* Debe de Seleccionar un Rol"
          );
        }
        if (
          this.editedItem.nombre.length < 5 ||
          this.editedItem.nombre.length > 50
        )
        {
          this.errores.push(
            "* El Nombre debe tener más de 4 caracteres y menos de 50."
          );
        }
        if(this.editedItem.username.length < 5 ||
          this.editedItem.username.length > 50){
          this.errores.push(
            "* El Username debe tener más de 4 caracteres y menos de 50."
          );
        }
        if(this.editedIndex <= -1)
          if(this.editedItem.password.length < this.paswordStationLength|| 
            this.editedItem.password.length > 50){
            this.errores.push(
              "* La Contraseña debe tener al menos"+this.paswordStationLength+"caracteres y menos de 50."
            );
          }
        return (this.errores.length == 0);
      },
      save () {
        this.action = 0;
        if(this.validate()){
          let me = this;
          me.saving = true;
          if (this.editedIndex > -1)
          {
            //Código para editar
            axios
              .put("/ProduccionUsuarios/Actualizar/"+me.usuarios[me.editedIndex].idProduccionUsuario, {
                IdArea: me.editedItem.idArea,
                Nombre: me.editedItem.nombre,
                Username: me.editedItem.username,
                Password: (me.editedItem.password.length > 0) ? me.editedItem.password : null ,
              })
              .then(response =>  {
                me.saving = false;
                me.close();
                me.initialize();
              })
              .catch(error => {
                me.saving = false;
                if (error.response) {
                  if(error.response.status == 409){
                    me.alerta = true;
                    me.errores.push("Existe un Usuario con el mismo Username");
                  }
                }
              });
          }
          else
          {
            axios
              .post("/ProduccionUsuarios/Crear", {
                IdArea: me.editedItem.idArea,
                Nombre: me.editedItem.nombre,
                Username: me.editedItem.username,
                Password: me.editedItem.password,
              })
              .then(response =>  {
                me.saving = false;
                me.close();
                me.initialize();
              })
              .catch(error => {
                me.saving = false;
                if (error.response) {
                  if(error.response.status == 409){
                    me.alerta = true;
                    me.errores.push("Existe un Usuario con el mismo Username");
                  }
                }
              });
          }
        }
      },
      pinDialog(){
        this.pin = true;
      },
      getCode(code){
        if(this.loginOpsLength != code.length) return;

        if(this.lectures == 0){
          this.cardCode = code;
          this.lectures += 1;
        }
        else if(this.lectures == 1 || this.lectures == 2){
          if(this.cardCode == code){
            this.lectures += 1;
          }
        }
      },
      resetCardReader(){
        this.lectures = 0;
        this.cardCode = "";
      },
      confirmarTarjeta(){
        this.editedItem.password = this.cardCode;
        this.pin = false;
        this.resetCardReader();
      }
    },
  }
</script>

<style>
/*
.v-input--selection-contareas.v-input--switch label{
    color: red;
}

.v-input--switch.v-input--is-label-active label{
    color: green !important;
}
*/
</style>