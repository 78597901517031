<template>
	<v-col cols="12" v-if="permisoVista('retrabajo','r')">
		<v-dialog
			v-model="eliminar"
			persistent
			max-width="500px"
			:key="eliminar"
		>
			<v-card>
				<v-card-title class="headline"
					>¿Está seguro que desea eliminar este <br/>Proceso de Retrabajo?</v-card-title
				>
				<v-card-text
					>Esta acción no se puede revertir y será
					permanente.</v-card-text
				>
				<v-card-actions>
					<v-spacer></v-spacer>
					<v-btn
						color="blue darken-1"
						text
						@click="
							eliminar = false;
							deletedItem = '';
						"
						>Cancelar</v-btn
					>
					<v-btn
						color="red darken-1"
						text
						@click="
							eliminar = false;
							deleteItemDB();
						"
						>Aceptar</v-btn
					>
				</v-card-actions>
			</v-card>
		</v-dialog>
		<v-data-table
			:headers="headers"
			:items="procesos"
			:loading="loading"
			loading-text="Cargando... Espere, Por Favor."
			:server-items-length="procesos_total"
			:items-per-page="procesos_items_per_page"
			:page="procesos_actual_page"
			@update:page="procesos_update_page_action"
			@update:items-per-page="(ipp) => (procesos_items_per_page = ipp)"
			@update:sort-by="procesos_sort_by_action"
			:footer-props="{
				disableItemsPerPage: true,
				disablePagination: this.loading,
			}"
			:disable-sort="true"
			:disable-pagination="loading"
		>
			<template v-slot:top>
				<v-toolbar flat color="white">
					<v-btn
						v-if="permisoVista('retrabajo','c')"
						color="primary"
						dark
						class="mb-2"
						@click="$refs.retrabajo.mostrarCrearRetrabajo()"
						>Nuevo Proceso</v-btn
					>
					<v-spacer></v-spacer>
					<v-text-field
						v-model="search"
						append-icon="search"
						label="Buscar por código o artículo"
						single-line
						hide-details
						@keyup="getProcesosOnEnterAction"
						@click:clear="clearSearchAction"
						@click:append="getProcesosAction"
						:clearable="true"
						:disabled="loading"
					></v-text-field>
				</v-toolbar>
			</template>
			<template v-slot:item.action="{ item }">
				<v-tooltip
					v-if="permisoVista('retrabajo','u')"
					
					bottom
					color="black"
					class="white--text"
				>
					<template v-slot:activator="{ on }">
						<v-icon
							v-on="on"
							small
							class="mr-2"
							@click="editItem(item)"
						>
							edit
						</v-icon>
					</template>
					<span class="white--text">Editar</span>
				</v-tooltip>
				<v-tooltip
					v-if="permisoVista('retrabajo','d')"
					
					bottom
					color="black"
					class="white--text"
				>
					<template v-slot:activator="{ on }">
						<v-icon
							v-on="on"
							small
							class="mr-2"
							@click="deleteItem(item)"
						>
							delete
						</v-icon>
					</template>
					<span class="white--text">Borrar</span>
				</v-tooltip>
			</template>
			<template v-slot:no-data>
				<v-btn color="primary" @click="initialize">Refrescar</v-btn>
			</template>
		</v-data-table>
		<Retrabajo ref="retrabajo" @refresh="initialize" />
	</v-col>
</template>

<script>
import axios from "axios";
import { debounce } from "debounce";
import Retrabajo from "./Procesos/Retrabajo.vue";

export default {
	computed: {
		procesos_fetch_url() {
			let url = `/Retrabajo/ListarProcesosRetrabajo?Paginar=true&Page=${this.procesos_actual_page}&Size=${this.procesos_items_per_page}`;
			if (this.search)
				url += this.search.length ? `&NombreCodigo=${this.search}` : "";
			return url;
		},
	},
	components: { Retrabajo },
	data() {
		return {
			dialog: false,
			search: "",
			headers: [
				{
					text: "Código",
					align: "left",
					sortable: true,
					value: "codigo",
				},
				{
					text: "Nombre",
					align: "left",
					sortable: true,
					value: "nombre",
				},
				{
					text: "Artículo",
					align: "left",
					sortable: true,
					value: "articulo",
				},
				{
					text: "Acciones",
					value: "action",
					sortable: false,
					width: "25%",
					align: "center",
				},
			],
			loading: false,
			procesos: [],
			//Paginacion procesos y filtros (server side)
			procesos_total: null,
			procesos_items_per_page: 10,
			procesos_actual_page: 1,
			//
			size: 0,
			procesoRetrabajo: {},
			deletedItem: "",
			eliminar: false,
		};
	},
	created() {
		this.initialize();
	},
	methods: {
		editItem(item) {
			axios
				.get(
					`/Retrabajo/ListarProcesoInformacion/${item.idRetrabajoProceso}`
				)
				.then((response) => {
					this.$refs.retrabajo.mostrarRetrabajo( response.data );
				})
				.catch((error) => {
					console.log(error);
				});
		},
		getProcesos() {
			//* Realizamos peticion HTTP
			return new Promise((resolve, reject) => {
				axios
					.get(this.procesos_fetch_url)
					.then((response) => resolve(response))
					.catch((error) => reject(error));
			});
		},
		async initialize() {
			this.loading = true;
			try {
				let response = await this.getProcesos();
				this.procesos = response.data.result;
				this.procesos_total = response.data.total;
				this.loading = false;
			} catch (exception) {
				console.error(exception);
			}
		},
		async procesos_update_page_action(page) {
			this.procesos_actual_page = page;
			this.loading = true;
			try {
				let response = await this.getProcesos();
				this.procesos = response.data.result;
				this.procesos_total = response.data.total;
				this.loading = false;
			} catch (exception) {
				console.log(exception);
			}
		},
		clearSearchAction() {
			this.search = "";
			this.getProcesosAction();
		},
		async getProcesosAction() {
			this.loading = true;
			this.procesos_actual_page = 1;
			try {
				let response = await this.getProcesos();
				this.procesos = response.data.result;
				this.procesos_total = response.data.total;
				this.loading = false;
			} catch (exception) {
				console.error(exception);
			}
		},
		getProcesosDebounce: debounce(async function () {
			this.loading = true;
			this.procesos_actual_page = 1;
			try {
				let response = await this.getProcesos();
				this.procesos = response.data.result;
				this.procesos_total = response.data.total;
				this.loading = false;
			} catch (exception) {
				console.error(exception);
			}
		}, 750),
		getProcesosDebounceAction($event) {
			if ($event instanceof KeyboardEvent) {
				//  Enter y Tab
				if ($event.keyCode === 13 || $event.keyCode === 9) return;
				//  Flechas teclado
				if ($event.keyCode >= 37 && $event.keyCode <= 40) return;
			}
			this.getProcesosDebounce();
		},
		getProcesosOnEnterAction($event) {
			if ($event instanceof KeyboardEvent) {
				//  Enter
				if ($event.keyCode === 13) this.getProcesosAction();
			}
		},
		deleteItem(item) {
			this.deletedItem = item;
			this.eliminar = true;
		},
		deleteItemDB() {
			let item = this.deletedItem;
			axios
				.put("/Retrabajo/Desactivar/" + item.idRetrabajoProceso)
				.then(() => {
					this.deletedItem = "";
					this.initialize();
				})
				.catch((error) => {
					console.log(error);
				});
		},
	},
};
</script>

<style>
</style>