<template>
	<v-autocomplete
		:chips="chips"
		:multiple="multiple"
		:deletable-chips="deletableChips"
		v-model="idsMaquinaIns"
		:label="label"
		:items="items"
		:persistent-hint="persistentHint"
		:hint="hint"
        :hide-details="hideDetails"
		:disabled="disabled"
		:item-text="itemText"
		:item-value="itemValue"
		:menu-props="{
			maxWidth: 'min-content',
		}"
        :placeholder="placeholder"
        :loading="loading"
        :clearable="clearable"
        :error-messages="errorMessage"
        @focus="$emit('focus')"
        @change="$emit('change')"
	>
		<template v-slot:item="{ item, attrs, on }">
			<v-list-item-action v-if="multiple">
				<v-simple-checkbox
					:value="attrs.inputValue"
					v-on="on"
				></v-simple-checkbox>
			</v-list-item-action>
			<v-list-item-content>
				<v-list-item-title>{{$utils.mantenimientos.nombreMaquinaInstancia(item,nombreEmpresa)}}</v-list-item-title>
				<v-list-item-subtitle v-if="item.numSerie != null">
					No. Serie: {{ item.numSerie }}
				</v-list-item-subtitle>
				<v-list-item-subtitle v-if="item.tipoBascula != null">
					Tipo Bascula:
					{{ $utils.mantenimientos.getTipoBascula(item.tipoBascula) }}
				</v-list-item-subtitle>
				<v-list-item-subtitle v-if="item.tipoTransporte != null">
					Tipo Unidad:
					{{
						$utils.mantenimientos.getTipoTransportes(
							item.tipoTransporte
						)
					}}
				</v-list-item-subtitle>
			</v-list-item-content>
		</template>
	</v-autocomplete>
</template>

<script>
import { mapState } from 'vuex';
export default {
    props: {
        placeholder: {
            type: String,
            default: null
        },
        loading: {
            type: Boolean,
            default: false
        },
        clearable: {
            type: Boolean,
            default: false
        },
        hideDetails: {
            type: Boolean,
            default: false
        },
        errorMessage: {
            type: String,
            default: null
        },
        itemText: {
            type: String,
            default: "nombre"
        },
        itemValue: {
            type: String,
            default: "idMaquinaIns"
        },
        chips: {
            type: Boolean,
            default: false
        },
        multiple: {
            type: Boolean,
            default: false
        },
        deletableChips: {
            type: Boolean,
            default: false
        },
        persistentHint: {
            type: Boolean,
            defaul: false
        },
        hint: {
            type: String,
            default: null
        },
        disabled: {
            type: Boolean,
            default: false
        },
        items: {
            type: Array,
            default: () => []
        },
        label: {
            type: String,
            default: null
        },
        value: {
            type: Array,
            required: true
        }
    },
    computed: {
        ...mapState(['nombreEmpresa']),
        idsMaquinaIns: {
            get(){
                return this.value;
            },
            set(newValue){
                this.$emit('input',newValue);
            }
        }
    }
};
</script>