<template>
	<v-col cols="12">
		<signalr path="/hub/paros" @change="cambios"></signalr>
		<v-row class="text-center">
			<v-col cols="4" md="4" sm="4" class="mt-0 pt-0">
				<v-select
					v-model="filtros.bloqueoProduccion"
					:items="tipoIncidencias"
					item-value="id"
					label="Tipo de Incidencia"
					hide-details
					clearable
					@change="paros_update_page_action(1)"
				></v-select>
			</v-col>
			<v-col cols="4" md="4" sm="4" class="mt-0 pt-0">
				<v-select
					v-model="filtros.idOEEFactor"
					:items="oeeFactores"
					item-value="idOEEFactor"
					item-text="nombre"
					label="Factor OEE"
					hide-details
					clearable
					@change="paros_update_page_action(1)"
				></v-select>
			</v-col>
			<v-col cols="4" md="4" sm="4" class="mt-0 pt-0">
				<v-autocomplete
					v-model="filtros.idParoTipo"
					:items="tipoParosFiltrados()"
					item-value="idParoTipo"
					item-text="nombre"
					label="Motivo del Incidencia"
					hide-details
					clearable
					@change="changeParoTipo"
				></v-autocomplete>
			</v-col>
			<v-col cols="4" md="4" sm="4" class="mt-0 pt-0">
				<v-autocomplete
					v-model="filtros.idSubParoTipo"
					:items="subTipoParoFiltrados()"
					item-value="idSubParoTipo"
					item-text="nombreSubParoTipo"
					label="Submotivo del Incidencia"
					hide-details
					clearable
					@change="paros_update_page_action(1)"
				></v-autocomplete>
			</v-col>
			<v-col cols="4" md="4" sm="4" class="mt-0 pt-0">
				<v-autocomplete
					v-model="filtros.idArea"
					:items="areas"
					item-value="idArea"
					item-text="nombre"
					label="Área"
					hide-details
					clearable
					@change="paros_update_page_action(1)"
				></v-autocomplete>
			</v-col>
			<v-col cols="4" md="4" sm="4" class="align-self-end">
				<VueCtkDateTimePicker
					id="inicio"
					v-model="filtros.inicio"
					label="Desde"
					format="YYYY-MM-DD HH:mm"
					formatted="YYYY-MM-DD HH:mm"
					noHeader
					overlay
					buttonNowTranslation="Ahora"
					@input="paros_update_page_action(1)"
				/>
			</v-col>
			<v-col cols="4" md="4" sm="4" class="align-self-end">
				<VueCtkDateTimePicker
					id="fin"
					v-model="filtros.fin"
					:min-date="filtros.inicio"
					label="Hasta"
					format="YYYY-MM-DD HH:mm"
					formatted="YYYY-MM-DD HH:mm"
					noHeader
					overlay
					buttonNowTranslation="Ahora"
					@input="paros_update_page_action(1)"
				/>
			</v-col>
			<v-col cols="4" md="4" sm="4" class="mt-0 pt-0">
				<v-autocomplete
					v-model="filtros.clave"
					:items="claves"
					item-value="idTrabajoOrden"
					item-text="clave"
					label="OT"
					hide-details
					clearable
					@change="paros_update_page_action(1)"
				></v-autocomplete>
			</v-col>
			<v-col cols="4" md="4" sm="4" class="mt-0 pt-0">
				<v-autocomplete
					v-model="filtros.idArticuloProducir"
					:items="articulosProducir"
					item-value="idArticulo"
					item-text="nombre"
					label="Artículo Producir"
					hide-details
					clearable
					@change="paros_update_page_action(1)"
				></v-autocomplete>
			</v-col>
			<v-col cols="4" md="4" sm="4" class="mt-0 pt-0">
				<v-autocomplete
					v-model="filtros.idArticuloFase"
					:items="articulosFase"
					item-value="idArticulo"
					item-text="nombre"
					label="Artículo Fase"
					hide-details
					clearable
					@change="paros_update_page_action(1)"
				></v-autocomplete>
			</v-col>
			<v-col cols="4" md="4" sm="4" class="mt-0 pt-0">
				<v-autocomplete
					v-model="filtros.idReporto"
					:items="operadores"
					item-value="idPersona"
					:item-text="nombrePersona"
					label="Reportado Por"
					hide-details
					clearable
					@change="paros_update_page_action(1)"
				></v-autocomplete>
			</v-col>
			<v-col cols="4" md="4" sm="4" class="mt-0 pt-0 mb-4">
				<v-autocomplete
					v-model="filtros.idTipoParo"
					:items="tiposParos"
					item-value="id"
					item-text="value"
					label="Tipo de Paros"
					hide-details
					clearable
					@change="paros_update_page_action(1)"
				></v-autocomplete>
			</v-col>
		</v-row>
		<v-col cols="12">
			<download-btn
				v-if="permisoVista('incidencias','r')"
				text
				color="primary"
				className="mt-0"
				@click="descargarReporte()"
				label="Descargar Reporte de<br/>Incidencias Desglosadas"
			></download-btn>
		</v-col>
		<v-data-table
			:headers="headersFiltrados"
			:items="paros"
			:loading="loading"
			loading-text="Cargando... Espere, Por Favor."
			:server-items-length="paros_total"
			:items-per-page="paros_items_per_page"
			:page="paros_actual_page"
			@update:page="paros_update_page_action"
			@update:items-per-page="(ipp) => (paros_items_per_page = ipp)"
			:footer-props="{
				disableItemsPerPage: true,
				disablePagination: this.loading,
			}"
			:disable-sort="true"
			:disable-pagination="loading"
		>
		<template v-slot:item.tiposParo="{ item }">
			<span>{{item.tiposParo ? 'Sensor': 'Manual' }}</span>
		</template>
			<template v-slot:item.tipoIncidencia="{ item }">
				<div v-if="item.tipoIncidencia == 'Paro'" class="red--text font-weight-bold">
					{{item.tipoIncidencia}}
				</div>
				<div v-else>
					{{item.tipoIncidencia}}
				</div>
			</template>
			<template v-slot:item.motivoParo="{ item }">
				<div>
					<div class="font-weight-bold">
						{{item.motivoParo}}
					</div>
					<template v-if="item.subMovitoParo != null">
						{{item.subMovitoParo}}
					</template>
				</div>
			</template>
			<template v-slot:item.reportadoPorOperadores="{ item }">
				<ul
					class="text-left pl-0"
					v-if="item.reportadoPorOperadores.length > 0"
				>
					<li
						v-for="(operador, index) in item.reportadoPorOperadores"
						:key="`op-${index}`"
					>
						{{ operador }}
					</li>
				</ul>
				<div v-else>N/D</div>
			</template>
			<template v-slot:item.proceso="{ item }">
				<div>
					Artículo: {{item.articuloProducir}}
					<br>
					Fase: {{item.articuloFase}}
					<br>
					Área: {{item.nombreArea}}
				</div>
			</template>
			<template v-slot:item.maquina="{ item }">
				<div>
					Marca: {{ item.marca === null ? "N/D" : item.marca }}
					<br>
					Modelo: {{ item.marca === null ? "N/D" : item.marca }}
					<br>
					Código: {{ item.codigo === null ? "N/D" : item.codigo }}
				</div>
			</template>
			<template v-slot:item.fin="{item}">
				{{diferencia(item.inicio)}}
			</template>
		</v-data-table>
		<scrap ref="scrap" @reload="initialize"></scrap>
		<retrabajo ref="retrabajo" :areas="areas" @reload="initialize"></retrabajo>
	</v-col>
</template>

<script>
import axios from "axios";
import Historial from '@/components/DashViews/Mantenimiento/Historial.vue';
import Operaciones from '../../Reportes/Operaciones.vue';
import Scrap from './Scrap.vue'
import Retrabajo from './Retrabajo.vue';
import signalr from "@/components/SignalR";
import moment from "moment";
import DownloadBtn from '../../../DownloadBtn.vue';

export default {
	components: {
		Historial,
		Scrap,
		Retrabajo,
		signalr,
		Operaciones,
		DownloadBtn,
	},
	data() {
		return {
			fechaActual: '',
			tiposParos:[{
				id:1, value:'Sensor'
			},
			{id:2, value:'Manual'}],
			downloadingReport: false,
			chkey1: 0,
			chkey2: 1,
			erroresReported: [],
			savingReporteExt: false,
			panel: 0,
			instancias: [],
			chkey: 0,
			dialogReporteExt: false,
			involucradosPersonas: [],
			scrapTop: 0,
			articulosFase: [],
			articulosProducir: [],
			claves: [],
			operadores: [],
			areas: [],
			filtros: {
				idTipoParo: null,
				idOEEFactor: -1,
				idParoTipo: null,
				idSubParoTipo: null,
				clave: null,
				idArticuloProducir: null,
				idArticuloFase: null,
				idArea: null,
				idReporto: null,
				idResponsable: null,
				inicio: null,
				fin: null,
				bloqueoProduccion: null,
			},
			personas: [],
			validaciones: [],
			tipoSubparos: [],
			tipoParos: [],
			habilitarBtnMant: false,
			loadingMant: false,
			dialogMant: false,
			habilitarBtnResp: false,
			habilitarBtnRec: false,
			cantidadReportada: 0,
			loadingRectificar: false,
			dialogRectificar: false,
			loadingResponsables: false,
			dialogResponsables: false,
			itemMant: {
				idParo: 0,
				idsMantenimientoTareas: []
			},
			defaultItemMant: {
				idParo: 0,
				idsMantenimientoTareas: []
			},
			itemResponsable: {
				idParo: 0,
				idPersona: [],
			},
			defaultItemResponsable: {
				idParo: 0,
				idPersona: [],
			},
			itemRectificar: {
				idParo: 0,
				idParoTipo: 0,
				idSubParoTipo: 0,
				idMaquinaIns: null,
				cantidadReportada: 0,
			},
			defaultItemRectificar: {
				idParo: 0,
				idParoTipo: 0,
				idSubParoTipo: 0,
				idMaquinaIns: null,
				cantidadReportada: 0,
			},
			maquinasIntervinieron:[],
			loading: false,
			paros: [],
			oeeFactores: [],
			paros_total: null,
			paros_items_per_page: 10,
			paros_actual_page: 1,
			headers: [
				{
					text: "Modalidad",
					align: "center",
					sortable: false,
					value: "tiposParo",
				},
				{
					text: "Tipo",
					align: "center",
					sortable: false,
					value: "tipoIncidencia",
				},
				{
					text: "Motivo",
					align: "center",
					sortable: false,
					value: "motivoParo",
				},
				{
					text: "Clave",
					align: "center",
					sortable: false,
					value: "clave",
				},
				{
					text: "Proceso",
					align: "center",
					sortable: false,
					value: "proceso",
					width: '20%'
				},
				{
					text: "Reportado por",
					align: "center",
					sortable: false,
					value: "reportadoPorOperadores",
				},
				{
					text: "Máquina",
					align: "center",
					sortable: false,
					value: "maquina",
					width: '10%'
				},
				{
					text: "Inicio",
					align: "center",
					sortable: false,
					value: "inicio",
					width: '9%'
				},
				{
					text: "Duración",
					align: "center",
					sortable: false,
					value: "fin",
					width: '9%'
				},
			],
			tipoIncidencias:[
				{
					id: true,
					text: "Paro"
				},
				{
					id: false,
					text: "Reporte"
				},
			],
			rules: {
				numValido: (num) => {
					if (!isNaN(parseFloat(num)) && parseFloat(num) >= 0)
						return true;
					return "Cantidad Invalida.";
				},
			},
		}
	},
	computed: {
		headersFiltrados(){
			return this.permisoVista('incidencias','$')
				? this.headers
				: this.headers.filter( head => head.value != 'costos' )
		},
		paros_fetch_url() {
			let url = `/Paros/ParosEnCurso?Paginar=true&Page=${this.paros_actual_page}&Size=${this.paros_items_per_page}`;

			if (this.$utils.isValid(this.filtros.idOEEFactor) && this.filtros.idOEEFactor >= 0) {
				url += "&IdOEEFactor=" + this.filtros.idOEEFactor;
			}

			if (this.$utils.isValid(this.filtros.idParoTipo)) {
				url += "&IdParoTipo=" + this.filtros.idParoTipo;
			}

			if (this.$utils.isValid(this.filtros.idSubParoTipo)) {
				url += "&IdSubParoTipo=" + this.filtros.idSubParoTipo;
			}

			if (this.$utils.isValid(this.filtros.clave)) {
				url += "&IdTrabajoOrden=" + this.filtros.clave;
			}

			if (this.$utils.isValid(this.filtros.idArticuloProducir)) {
				url += "&IdArticuloProducir=" + this.filtros.idArticuloProducir;
			}

			if (this.$utils.isValid(this.filtros.idArticuloFase)) {
				url += "&IdArticuloFase=" + this.filtros.idArticuloFase;
			}

			if (this.$utils.isValid(this.filtros.idArea)) {
				url += "&IdArea=" + this.filtros.idArea;
			}

			if (this.$utils.isValid(this.filtros.idReporto)) {
				url += "&IdReporto=" + this.filtros.idReporto;
			}

			if (this.$utils.isValid(this.filtros.idResponsable)) {
				url += "&IdResponsable=" + this.filtros.idResponsable;
			}

			if (this.$utils.isValid(this.filtros.inicio)) {
				url += "&Inicio=" + this.filtros.inicio;
			}

			if (this.$utils.isValid(this.filtros.fin)) {
				url += "&Fin=" + this.filtros.fin;
			}
			
			if (this.$utils.isValid(this.filtros.bloqueoProduccion)) {
				url += "&BloqueoProduccion=" + this.filtros.bloqueoProduccion;
			}

			if (this.filtros.idTipoParo != null) {
				url += "&tipoParo=" + this.filtros.idTipoParo;
			}

			return url;
		},
		filtroSubparos() {
			return this.tipoSubparos.filter(
				(subparo) =>
					subparo.idParoTipo === this.itemRectificar.idParoTipo
			);
		},
		validacionRec() {
			if (this.validaciones[0] && this.validaciones[1]) return true;
			else return false;
		},
		habilitarBtnRectif(){
			if (
				this.loadingRectificar || 
				isNaN(parseFloat(this.cantidadReportada)) ||
				parseFloat(this.cantidadReportada) < 0 || 
				this.itemRectificar.idParoTipo == 0
			)
				return true;
			else
				return false;
		}
	},
	created() {
		this.initialize();
		this.getOEEFactores();
		this.getTipoParos();
		this.getTipoSubParos();
		this.getPersonas();
		this.getOperadores();
		this.getClaves();
		this.getArticulosProducir();
		this.getArticulosFase();
		this.getInstancias();
	},
	mounted(){
		this.getAreas();
			setInterval(() => {
  		this.fechaActual = moment().format('DD/MM/YYYY HH:mm:ss');
		}, 1000);
	},
	watch: {
		"itemResponsable.idPersona": function (after) {
			after.length === 0
				? (this.habilitarBtnResp = false)
				: (this.habilitarBtnResp = true);
		},
	},
	methods: {
		marcaModelo: (item) => item.marca.trim() + " - " + item.modelo.trim(),
		removeEmptyObj(obj){
			Object.keys(obj).forEach(k =>
				(obj[k] && typeof obj[k] === 'object') && this.removeEmpty(obj[k]) ||
				(!obj[k] && obj[k] !== undefined) && delete obj[k] ||
				(obj[k] == -1) && delete obj[k]
			);
			return obj;
		},
		descargarReporte() {
			this.downloadingReport = true;
			var datetime = moment().format("YYYYMMDDHHmmss");
			const filtrados = this.removeEmptyObj(this.filtros);
			var url = new URLSearchParams(filtrados).toString();
			this.$utils.axios.downloadFile(
				"/Paros/XlsParosEnCurso?" + url,
				"GET",
				"reporte-paros-en-curso-" + datetime + ".xlsx",
				"application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
			);
			this.downloadingReport = false;
		},
		rowOperacionesSelected(selectedItems){
			this.reporteItem.operacion = Object.assign({},selectedItems[0]);
			this.chkey2++;
			this.chkey1++;
			this.reporteItem.idMaquinaIns = null;
			this.reporteItem.operacion.inicio = moment(this.reporteItem.operacion.inicio).format('YYYY-MM-DD HH:mm');
			this.reporteItem.operacion.fin = moment(this.reporteItem.operacion.fin).format('YYYY-MM-DD HH:mm');
			this.reporteItem.inicio = this.reporteItem.operacion.inicio;
			this.reporteItem.fin = this.reporteItem.operacion.fin;
		},
		getInstancias(){
			axios
				.get("/MaquinaInstancias")
				.then( response => {
					this.instancias = response.data;
				})
				.catch( error => {
					console.log(error);
				})
		},
		filtroDetalleIncidencia(idArea){
			this.filtros.idArea = idArea;
			this.filtros.inicio = moment().subtract(5, 'minutes').format('YYYY-MM-DD HH:mm');
			this.paros_update_page_action(1);
		},
		nombreArea(idArea){
			const area = this.areas.find( area => area.idArea === idArea );
			return area !== undefined ? area.nombre : 'Sin Designar';
		},
		getArticulosFase() {
			axios
				.get("/Articulos/ArticulosNombres?componentes=true")
				.then((response) => {
					this.articulosFase = response.data;
				})
				.catch((error) => {
					console.log(error);
				});
		},
		getArticulosProducir() {
			axios
				.get("/Articulos/OTS")
				.then((response) => {
					this.articulosProducir = response.data;
				})
				.catch((error) => {
					console.log(error);
				});
		},
		getClaves() {
			axios
				.get("/TrabajoOrdenes/TrabajoOrdenHeaders")
				.then((response) => {
					this.claves = response.data;
				})
				.catch((error) => {
					console.log(error);
				});
		},
		getOperadores() {
			axios
				.get("/Personas/Operadores")
				.then((response) => {
					this.operadores = response.data;
				})
				.catch((error) => {
					console.log(error);
				});
		},
		changeParoTipo() {
			this.filtros.idSubParoTipo = null;
			this.paros_update_page_action(1);
		},
		leerParametros(){
			if (this.$route.query.idArea != undefined){
				this.filtroDetalleIncidencia(this.$route.query.idArea);
				this.$router.push('/dashboard/calidad');
			}
		},
		getAreas() {
			axios
				.get("/Areas")
				.then((response) => {
					this.areas = response.data;
					this.leerParametros();
				})
				.catch((error) => {
					console.log(error);
				});
		},
		nombrePersona: (persona) => persona.nombre + " " + persona.paterno + " " + persona.materno,
		getPersonas() {
			axios
				.get("/Personas")
				.then((response) => {
					this.personas = response.data;
				})
				.catch((error) => {
					console.log(error);
				});
		},
		getOEEFactores() {
			axios
				.get("/OEEFactores")
				.then((response) => {
					this.oeeFactores = response.data;
					this.oeeFactores.push({
						idOEEFactor: 0,
						nombre: "SIN ESPECIFICAR",
						descripcion: "Sin Especificar",
					});
				})
				.catch((error) => {
					console.log(error);
				});
		},
		getTipoParos() {
			axios
				.get("/ParoTipos")
				.then((response) => {
					this.tipoParos = response.data;
				})
				.catch((error) => {
					console.log(error);
				});
		},
		getTipoSubParos() {
			axios
				.get("/SubParoTipos")
				.then((response) => {
					this.tipoSubparos = response.data;
				})
				.catch((error) => {
					console.log(error);
				});
		},
		async initialize() {
			this.loading = true;
			try {
				let response = await this.getParos();
				this.paros = this.mixOperadores(response.data.result);
				this.paros_total = response.data.total;
				this.loading = false;
			} catch (exception) {
				console.error(exception);
			}
		},
		async paros_update_page_action(page) {
			this.paros_actual_page = page;
			this.loading = true;
			try {
				let response = await this.getParos();
				this.paros = this.mixOperadores(response.data.result);
				this.paros_total = response.data.total;
				this.loading = false;
			} catch (exception) {
				console.log(exception);
			}
		},
		getParos() {
			//* Realizamos peticion HTTP
			return new Promise((resolve, reject) => {
				axios
					.get(this.paros_fetch_url)
					.then((response) => resolve(response))
					.catch((error) => reject(error));
			});
		},
		mixOperadores(prs) {
			return prs.map((item) => {
				for (const elemento in item) {
					if (elemento === "reportadoPor") {
						if (
							item[elemento] === null ||
							item[elemento].length === 0 ||
							JSON.stringify(item[elemento]) === "[]"
						)
							item["reportadoPorOperadores"] =
								item["operadoresReales"];
						else
							item["reportadoPorOperadores"] =
								item["reportadoPor"];
					}
				}
				return item;
			});
		},
		reportarRetrabajo(item){
			this.$refs.retrabajo.mostrarRetrabajo(item);
		},
		cambios(event){
			this.paros_update_page_action(this.paros_actual_page);
		},
		tipoParosFiltrados(){
			if(this.$utils.isValid(this.filtros.idOEEFactor) && this.filtros.idOEEFactor >= 0){
				var idOEEFactor = (this.filtros.idOEEFactor == 0) ? null : this.filtros.idOEEFactor;
				return this.tipoParos.filter(tp => tp.idOEEFactor == idOEEFactor);
			}
			else {
				return this.tipoParos;
			}
		},
		subTipoParoFiltrados(){
			if(this.$utils.isValid(this.filtros.idParoTipo)) return this.tipoSubparos.filter(tp => tp.idParoTipo ==  this.filtros.idParoTipo);
			else return this.tipoSubparos;
		},
		reporteTipoSubparos(){
			return this.$utils.isValid(this.reporteItem.idParoTipo)
			? this.tipoSubparos.filter( sub => sub.idParoTipo == this.reporteItem.idParoTipo )
			: []
		},
		diferencia(item){
			var fecha2 = moment(this.fechaActual, 'DD/MM/YYYY HH:mm:ss');
			var fecha1 = moment(item, 'DD/MM/YYYY HH:mm:ss');
		
			var diferenciaSegundos = fecha2.diff(fecha1, 'seconds');

			var diferenciaMinutos = fecha2.diff(fecha1, 'minutes');

			var diferenciaHoras = fecha2.diff(fecha1, 'hours');

			var diferenciaDias = fecha2.diff(fecha1, 'days');

			const diferenciaMeses = fecha2.diff(fecha1, 'months');

			const diferenciaAnios = fecha2.diff(fecha1, 'years');

			if(diferenciaSegundos < 60)
					return diferenciaSegundos + (diferenciaSegundos < 2 ? ' Segundo' : ' Segundos')				
			else if(diferenciaMinutos < 60)
				return diferenciaMinutos +  (diferenciaMinutos < 2 ? " Minuto" :" Minutos")
			else if(diferenciaHoras < 25)
				return diferenciaHoras + (diferenciaHoras < 2 ? " Hora" :" Horas")
			else if(diferenciaDias <= 31)
				return diferenciaDias + (diferenciaDias < 2 ? " Día":" Días")
			else if (diferenciaMeses <= 12)
			return diferenciaMeses + (diferenciaMeses < 2 ? " Mes":" Meses")
			else
			return diferenciaAnios + (diferenciaAnios < 2? " Año": " Años")
		}
	},
};
</script>