<template>
	<div>
		<v-tabs v-model="tab" background-color="transparent" grow>
			<v-tab
				v-if="permisoVista('mantenimiento-historial','r')"
				@click="mantenimientosClick"
			>
				Mantenimientos Realizados
			</v-tab>
			<v-tab
				v-if="permisoVista('mantenimiento-inspecciones','r')"
				@click="inspeccionesClick"
			>
				Inspecciones Realizadas
			</v-tab>

			<v-tabs-items v-model="tab" touchless>
				<v-tab-item
					v-if="permisoVista('mantenimiento-historial','r')"
					eager
				>
					<Historial ref="hist" />
				</v-tab-item>
				<v-tab-item
					v-if="permisoVista('mantenimiento-inspecciones','r')"
					eager
				>
					<InspeccionesTerminadas ref="ins" />
				</v-tab-item>
			</v-tabs-items>
		</v-tabs>
	</div>
</template>

<script>
import Historial from "./Historial.vue";
import InspeccionesTerminadas from './InspeccionesTerminadas.vue';


export default {
	components: { Historial, InspeccionesTerminadas },
	data: () => ({
		tab: 0,
	}),
	methods: {
		initialize() {
            this.mantenimientosClick();
			this.inspeccionesClick();
		},
		mantenimientosClick() {
			if(typeof this.$refs.hist === 'undefined') return;
			this.$refs.hist.initialize();
		},
		inspeccionesClick(){
			if(typeof this.$refs.ins === 'undefined') return;
			this.$refs.ins.initialize();
		}
	},
};
</script>