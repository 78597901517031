<template>
  <v-col cols="12" v-if="permisoVista('descansooperadores','r')">
    <v-dialog v-model="dialog" max-width="500px" persistent>
      <v-card>
        <v-card-title>
          <span class="text-h5">{{ formTitle }}</span>
        </v-card-title>

        <v-card-text>
          <v-container>
            <v-row>
              <v-col cols="12" v-if="editedIndex != 2">
                <v-autocomplete
                  v-model="editedItem.IdPersona"
                  :items="operadores"
                  item-value="idPersona"
                  :item-text="nombrePersona"
                  label="Operador"
                  clearable
                  :error-messages="errorOperador"
                  @focus="errorOperador = ''"
                >
                </v-autocomplete>
              </v-col>
              <v-col cols="12">
                <v-select
                  v-model="editedItem.Motivo"
                  :items="motivos"
                  :item-text="text"
                  item-value="value"
                  label="Motivo"
                  clearable
                  :error-messages="errorMotivo"
                  @focus="errorMotivo = ''"
                ></v-select>
              </v-col>
              <v-col cols="12">
                <datePicker
                  v-model="editedItem.Desde"
                  format="YYYY-MM-DD"
                  label="Inicio"
                  clearable
                  :minDate="DateNow()"
                  :error-messages="errorFechaInicio"
                  @focus="errorFechaInicio = ''"
                ></datePicker>
              </v-col>
              <v-col cols="12">
                <datePicker
                  v-model="editedItem.Hasta"
                  format="YYYY-MM-DD"
                  label="Fin"
                  clearable
                  :minDate="editedItem.Desde"
                  :error-messages="errorFechaFin"
                  @focus="errorFechaFin = ''"
                ></datePicker>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="success" text @click="close">
            Cancelar
          </v-btn>
          <v-btn color="error" text @click="save">
            Guardar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-row>
      <v-col cols="12">
        <v-btn v-if="permisoVista('descansooperadores','c')" color="primary" class="mr-3" @click="dialog = true">
            Nuevo descanso
        </v-btn>
        <v-btn v-if="permisoVista('descansooperadores','c')" color="primary" @click="overallRest">
          Descanso general
        </v-btn>
      </v-col>
    </v-row>

    <v-dialog v-model="dialogDelete" max-width="500px" persistent>
      <v-card>
        <v-card-title class="text-h5 justify-center"
          >Seguro de eliminar este registro?</v-card-title
        >
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="closeDelete">Cancel</v-btn>
          <v-btn color="red darken-1" text @click="deleteItemConfirm">OK</v-btn>
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-row>
      <v-col cols="12">
        <v-data-table
          :headers="headers"
          :items="desserts"
          :loading="carga"
          loading-text="Cargando... Espere por favor"
          sort-by="calories"
        >
          <template v-slot:[`item.actions`]="{ item }">
            <v-icon v-if="permisoVista('descansooperadores','u')" small class="mr-2" @click="editItem(item)">
              mdi-pencil
            </v-icon>
            <v-icon v-if="permisoVista('descansooperadores','d')" small @click="deleteItem(item)">
              mdi-delete
            </v-icon>
          </template>
          <template v-slot:no-data>
            <v-btn color="primary" @click="initialize">
              Refrescar
            </v-btn>
          </template>
        </v-data-table>
      </v-col>
    </v-row>
  </v-col>
</template>

<script>
import axios from "axios";
export default {
  data: () => ({
    carga: false,
    dialog: false,
    dialogDelete: false,
    headers: [
      {
        text: "Persona",
        align: "start",
        sortable: false,
        value: "persona",
      },
      { text: "Motivo", value: "motivo" },
      { text: "Desde", value: "desde" },
      { text: "Hasta", value: "hasta" },
      { text: "Acciones", value: "actions", sortable: false },
    ],
    desserts: [],
    editedIndex: -1,
    editedItem: {
      Id: null,
      IdPersona: null,
      Motivo: null,
      Desde: null,
      Hasta: null,
    },
    defaultItem: {
      Id: null,
      IdPersona: null,
      Motivo: null,
      Desde: null,
      Hasta: null,
    },
    operadores: [],
    motivos: [
      { text: "Permiso", value: 1 },
      { text: "Vacaciones", value: 2 },
    ],
    errorOperador: "",
    errorMotivo: "",
    errorFechaInicio: "",
    errorFechaFin: "",
    dialogAll: false,
  }),

  computed: {
    formTitle() {
      return this.editedIndex === -1
        ? "Nuevo descanso"
        : this.editedIndex === 2
        ? "Descanso General"
        : "Editar descanso";
    },
  },

  watch: {
    dialog(val) {
      val || this.close();
    },
    dialogDelete(val) {
      val || this.closeDelete();
    },
  },

  created() {
    this.initialize();
  },

  methods: {
    initialize() {
      this.getRestPerson();
      this.getOperadores();
      this.DateNow();
    },
    nombrePersona: ({ nombre, paterno, materno }) =>
      `${nombre ? `${nombre} ` : ""}${paterno ? `${paterno} ` : ""}${
        materno ? `${materno} ` : ""
      }`.trim(),
    editItem(item) {
      this.editedItem = {
        Id: item.id,
        IdPersona: item.idPersona,
        Motivo: item.idMotivo,
        Desde: item.desde,
        Hasta: item.hasta,
      };
      this.editedIndex = 1;
      this.dialog = true;
    },
    overallRest() {
      this.editedIndex = 2;
      this.dialog = true;
    },
    deleteItem(item) {
      this.editedItem = {
        Id: item.id,
        IdPersona: null,
        Motivo: null,
        Desde: null,
        Hasta: null,
      };
      this.dialogDelete = true;
    },
    deleteItemConfirm() {
      //this.desserts.splice(this.editedIndex, 1);
      axios
        .put(`/PersonaDescanso/Eliminar/${this.editedItem.Id}`)
        .then(() => {
          this.dialogDelete = false;
          this.deleting = false;

          this.initialize();
        })
        .catch((error) => {
          this.deleting = false;
          console.log(error);
        });
      this.closeDelete();
    },
    close() {
      this.dialog = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },
    closeDelete() {
      this.dialogDelete = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },
    save() {
      if (!this.validate()) return;

      if (this.editedIndex > -1 && this.editedIndex < 2) {
        axios
          .put("/PersonaDescanso/Actualizar/" + this.editedItem.Id, {
            IdPersona: this.editedItem.IdPersona,
            Motivo: this.editedItem.Motivo,
            Desde: this.editedItem.Desde,
            Hasta: this.editedItem.Hasta,
          })
          .then(() => {
            this.getRestPerson();
          })
          .catch((error) => {
            this.saving = false;
            console.log(error);
          });
      } else if (this.editedIndex == 2) {
        axios
          .post("/PersonaDescanso/CrarDescansoGeneral", {
            IdPersona: null,
            Motivo: this.editedItem.Motivo,
            Desde: this.editedItem.Desde,
            Hasta: this.editedItem.Hasta,
          })
          .then(() => {
            this.getRestPerson();
          })
          .catch((error) => {
            this.saving = false;
            console.log(error);
          });
      } else {
        axios
          .post("/PersonaDescanso/Crear", {
            IdPersona: this.editedItem.IdPersona,
            Motivo: this.editedItem.Motivo,
            Desde: this.editedItem.Desde,
            Hasta: this.editedItem.Hasta,
          })
          .then(() => {
            this.getRestPerson();
          })
          .catch((error) => {
            this.saving = false;
            console.log(error);
          });
      }
      this.close();
    },
    getRestPerson() {
      this.carga = true;
      axios
        .get("/PersonaDescanso")
        .then((response) => {
          this.desserts = response.data;
          this.carga = false;
        })
        .catch((error) => {
          console.log(error);
          this.carga = false;
        });
    },
    getOperadores() {
      axios
        .get("/Personas/Operadores")
        .then((response) => {
          this.operadores = response.data;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    validate() {
      this.errorOperador = "";
      this.errorMotivo = "";
      this.errorFechaInicio = "";
      this.errorFechaFin = "";

      if (this.editedItem.IdPersona == null && this.editedIndex != 2) {
        this.errorOperador = "Debe selecciona al menos un operador";
      } else if (this.editedItem.IdPersona < 1 && this.editedIndex != 2) {
        this.errorOperador = "Debe selecciona al menos un operador";
      }

      if (this.editedItem.Motivo == null) {
        this.errorMotivo = "Debe seleccionar un motivo";
      } else if (this.editedItem.Motivo < 1) {
        this.errorMotivo = "Debe seleccionar un motivo";
      }

      if (this.editedItem.Desde == null) {
        this.errorFechaInicio = "Seleccione una fecha";
      } else if (this.editedItem.Desde.trim().length < 8) {
        this.errorFechaInicio = "Seleccione una fecha";
      }

      if (this.editedItem.Hasta == null) {
        this.errorFechaFin = "Seleccione una fecha";
      } else if (this.editedItem.Hasta.trim().length < 8) {
        this.errorFechaFin = "Seleccione una fecha";
      }

      return (
        this.errorOperador == "" &&
        this.errorMotivo == "" &&
        this.errorFechaInicio == "" &&
        this.errorFechaFin == ""
      );
    },
    DateNow() {
      let date = new Date();
      let output =
        date.getFullYear() +
        "-" +
        String(date.getMonth() + 1).padStart(2, "0") +
        "-" +
        String(date.getDate()).padStart(2, "0");
      return output;
    },
  },
};
</script>
