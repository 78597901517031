<template>
  <v-dialog v-model="value" v-if="mostrarVista('control')" fullscreen>
    <v-card>
      <v-toolbar dark color="primary">
        <v-btn icon dark @click="close">
          <v-icon>mdi-close</v-icon>
        </v-btn>
        <v-toolbar-title class="white--text">{{area.area}}</v-toolbar-title>
        <v-spacer></v-spacer>
      </v-toolbar>
      <v-tabs v-model="tab" fixed-tabs>
        <v-tab @click="obtenerOperadores">Operadores Disponibles</v-tab>
        <v-tab @click="actualizarOperaciones">Operaciones Pendientes</v-tab>
        <v-tab @click="actualizarProgreso">Operaciones En Curso</v-tab>
      </v-tabs>
      <v-tabs-items v-model="tab" :touchless="true">
        <v-tab-item :eager="true">
          <sesiones ref="sesiones" :area="area"></sesiones>
        </v-tab-item>
        <v-tab-item :eager="true">
          <v-card flat>
            <operaciones ref="operaciones" :area="area"></operaciones>
          </v-card>
        </v-tab-item>
        <v-tab-item :eager="true">
          <v-card flat>
            <progreso ref="progreso" :area="area"></progreso>
          </v-card>
        </v-tab-item>
      </v-tabs-items>
    </v-card>
  </v-dialog>
</template>

<script>
import axios from "axios";
import sesiones from "./Sesiones";
import operaciones from "./Operaciones";
import progreso from "./Progreso";

export default {
  components:{
    sesiones,
    operaciones,
    progreso,
  },
  props:{
    area:{
      type:Object,
      default:{
        area:'',
        idArea:-1,
        operacionesCurso: 0,
      },
    },
    value:{
      type: Boolean,
      default: false,
    } 
  },
  data: () => ({
    tab:0,
  }),
  watch:{
    value(){
      if(this.value){
        if(this.tab == 0){
          this.obtenerOperadores();
        }
      }
      else{
        this.$refs.sesiones.limpiar();
      }
    }
  },
  methods:{
    actualizarOperaciones(){
      if(typeof this.$refs.operaciones !== 'undefined'){
          this.$refs.operaciones.actualizar();
        }
    },
    actualizarProgreso(){
      if(typeof this.$refs.progreso !== 'undefined'){
          this.$refs.progreso.actualizar();
      }
      else{
        setTimeout(() => {
          this.actualizarProgreso();
        }, 10);
      }
    },
    actualizarParos(){
      if(typeof this.$refs.progreso !== 'undefined'){
          this.$refs.progreso.actualizarParos();
      }
      else{
        setTimeout(() => {
          this.actualizarParos();
        }, 10);
      }
    },
    obtenerOperadores(){
      setTimeout(() => {
        if(typeof this.$refs.sesiones === 'undefined'){
          this.obtenerOperadores();
        }
        else this.$refs.sesiones.sesionesAbiertas();
      }, 10);
    },
    close(){
      this.value = false;
      this.area = {
        area:'',
        idArea:-1,
        operacionesCurso: 0,
      };
      this.tab = 0;
      this.$emit('input',false);
    },
    actualizar(nArea){
      this.area = nArea;
    },
    eventos(event){
      var orden = event.toLowerCase().split('-');
      if(orden[0] == 'operador' && (orden[1] == 'login' || orden[1] == 'logout') && this.area.idArea == parseInt(orden[2]) ){
        this.obtenerOperadores();
      }
      if(orden[0] == 'paro' && this.area.idArea == parseInt(orden[1])){
        this.actualizarParos();
      }
      if((orden[0] == 'iniciar' || orden[0] == 'avance') && this.area.idArea == parseInt(orden[2]) ){
        this.obtenerOperadores();
        this.actualizarOperaciones();
        this.actualizarProgreso();
      }
    }
  },
}
</script>