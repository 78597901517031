<template>
	<v-card flat>
		<v-card-text v-if="errorCargaMasiva">
			<v-col cols="12" md="12" sm="12" class="text-center">
				<h3>Se ha producido un Error. Intente una vez más.</h3>
			</v-col>
			<v-col cols="12" md="12" sm="12" class="text-center">
				<v-btn
					depressed
					color="red"
					text
					@click="
						cargaMasiva = false;
						ficheroCarga = null;
						cargaCargaCompleta = false;
						loadingCargaMasiva = false;
						errorCargaMasiva = false;
						selectedUpdateFile = null;
					"
					>Aceptar</v-btn
				>
			</v-col>
		</v-card-text>
		<v-card-text v-if="cargaCargaCompleta">
			<v-col cols="12" md="12" sm="12" class="text-center">
				<h3 v-if="cargaLink === ''">
					Carga Realizada Satisfactoriamente
				</h3>
				<div v-else>
					<h3>Carga Realizada Parcialmente</h3>
					<a
						:href="
							'/api/Articulos/ReporteCargaMasiva/' +
							cargaLink +
							'?access_token=' +
							token
						"
                        style="font-size: 18px !important"
						target="_blank"
						>Archivo de Reporte</a
					>
				</div>
			</v-col>
			<v-col cols="12" md="12" sm="12" class="text-center">
				<v-btn
					depressed
					x-large
					color="green"
					text
					@click="
						cargaMasiva = false;
						ficheroCarga = null;
						cargaCargaCompleta = false;
						loadingCargaMasiva = false;
						errorCargaMasiva = false;
						selectedUpdateFile = null;
					"
					>Aceptar</v-btn
				>
			</v-col>
		</v-card-text>
		<v-card-text v-if="loadingCargaMasiva && !errorCargaMasiva">
			<v-col cols="12" md="12" sm="12" class="text-center">
				<h3>Cargando</h3>
			</v-col>
			<v-col cols="12" md="12" sm="12" class="text-center">
				<v-progress-circular
					:size="75"
					color="green"
					indeterminate
				></v-progress-circular>
			</v-col>
		</v-card-text>
		<v-card-text
			v-if="!loadingCargaMasiva && !cargaCargaCompleta && !errorCargaMasiva"
			class="text-center"
		>
			<p class="mb-0 text-h6 text--secondary text-left">
				Carga Masiva de Artículos
			</p>
			<v-file-input
				v-model="selectedUpdateFile"
				accept=".xls, .xlsx"
				@change="cambioficheroCarga"
				counter
				show-size
				clearable
				label="Fichero Carga .XLS o .XLSX"
			></v-file-input>
			<a
				:href="
					'/api/Articulos/PlantillaCargaMasiva?access_token=' + token
				"
				target="_blank"
				class="caption text-center"
			>
				<v-icon color="blue">mdi-download</v-icon>Descargar Plantilla de
				Carga Masiva de Artículos
			</a>
			<br />
			<br />
			<v-row>
				<v-col cols="12" class="py-0">
					<v-btn
						block
						color="blue"
						outlined
						class="my-2 mb-4"
						@click="cargaMasivaPost"
						>Cargar Artículos</v-btn
					>
				</v-col>
			</v-row>
		</v-card-text>
	</v-card>
</template>

<script>
import axios from "axios";
import { mapState } from "vuex";
export default {
	data() {
		return {
			actualizacionMasiva: false,
			loadingActualizacionMasiva: false,
			ficheroCarga: null,
			cargaActualizacionCompleta: false,
			selectedUpdateFile: null,
			errorActualizacionMasiva: false,
			cargaMasiva: false,
			loadingCargaMasiva: false,
			ficheroCarga: null,
			cargaCargaCompleta: false,
			selectedCreateFile: null,
			errorCargaMasiva: false,
			cargaLink: "",
			updateLink: "",
		};
	},
	computed: {
		...mapState({
			cargasMasivas: "cargasMasivas",
			token: "token",
		}),
	},
	mounted() {
		this.initialize();
	},
	methods: {
		cargaMasivaPost() {
            if (this.ficheroCarga != null) {
                this.loadingCargaMasiva = true;
				let formData = new FormData();
				formData.append("file", this.ficheroCarga);
				axios
					.post("/Articulos/CargaMasiva", formData, {
						headers: {
							"Content-Type": "multipart/form-data",
						},
					})
					.then((response) => {
						this.cargaLink = response.data;
						this.cargaCargaCompleta = true;
						this.loadingCargaMasiva = false;
					})
					.catch((error) => {
						console.log(error);
						this.loadingCargaMasiva = false;
						this.errorCargaMasiva = true;
					});
			}
		},
		initialize() {
			this.resetValues();
		},
		resetValues() {
			this.cargaMasiva = false;
            this.ficheroCarga = null;
            this.cargaCargaCompleta = false;
            this.loadingCargaMasiva = false;
            this.errorCargaMasiva = false;
            this.selectedUpdateFile = null;
		},
		cambioficheroCarga(event) {
			if (typeof event === "undefined" || event == null)
				this.ficheroCarga = null;
			else this.ficheroCarga = event;
		},
	},
};
</script>