<template>
  <v-col cols="12" v-if="permisoVista('permisos', 'r')">
    <template>
      <v-row justify="center">
        <v-dialog
          v-model="eliminar"
          persistent
          max-width="500px"
          :key="eliminar"
        >
          <v-card>
            <v-card-title class="headline"
              >¿Está seguro que desea eliminar esta Rol?</v-card-title
            >
            <v-card-text
              >Esta acción no se puede revertir y será permanente.</v-card-text
            >
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                color="blue darken-1"
                text
                @click="
                  eliminar = false;
                  delItem = '';
                "
                >Cancelar</v-btn
              >
              <v-btn
                color="red darken-1"
                text
                @click="
                  eliminar = false;
                  deleteItemDB();
                "
                >Aceptar</v-btn
              >
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-row>
    </template>
    <v-data-table
      :headers="headers"
      :items="roles"
      :search="search"
      :loading="loading"
      loading-text="Cargando... Espere, Por Favor."
    >
      <template v-slot:top>
        <v-toolbar flat color="white">
          <v-dialog v-model="dialog" persistent fullscreen :key="dialog">
            <template v-slot:activator="{ on }">
              <v-btn
                v-if="permisoVista('permisos', 'c')"
                color="primary"
                dark
                class="mb-2"
                v-on="on"
                :disabled="loading"
                >Nuevo Rol</v-btn
              >
            </template>
            <v-card>
              <v-card-title>
                <span class="headline">{{ formTitle }}</span>
              </v-card-title>
              <v-card-text>
                <v-container>
                  <v-row>
                    <v-col justify="center" cols="12" sm="12" md="12">
                      <v-text-field
                        v-model="editedItem.nombre"
                        label="Nombre del Rol"
                        :error-messages="errores[0]"
                        @focus="errores[0]=''"
                      ></v-text-field>
                    </v-col>
                    <v-col justify="center" cols="12" sm="12" md="12">
                      <v-text-field
                        v-model="editedItem.descripcion"
                        label="Descripción del Rol"
                        :error-messages="errores[1]"
                        @focus="errores[1]=''"
                      ></v-text-field>
                    </v-col>
                    <v-col justify="center" cols="12" sm="12" md="12">
                      <v-autocomplete
                        v-model="editedItem.idsReportes"
                        label="Reportes"
                        item-value="idReporte"
                        item-text="nombre"
                        multiple
                        chips
                        deletable-chips
                        :items="reportes"
                      >

                      </v-autocomplete>
                    </v-col>
                    <v-col justify="center" cols="12" sm="12" md="12">
                      <v-card>
                        <v-tabs vertical>
                          <v-tab
                            v-for="(seccion, index) in secciones"
                            :key="index + 1"
                          >
                            <v-icon>{{ seccion.icono }}</v-icon>
                            {{ seccion.nombre }}
                          </v-tab>
                          <v-tab-item
                            v-for="(seccion, index) in secciones"
                            :key="index + 1"
                          >
                            <v-card flat>
                              <v-card-text>
                                <v-data-table
                                  v-model="selected"
                                  :items="seccion.vistas"
                                  :headers="headerPermisos"
                                  hide-default-footer
                                  show-select
                                  item-key="idVista"
                                  :items-per-page="-1"
                                  @item-selected="seleccionaFila"
                                  @toggle-select-all="seleccionaColumna"
                                  checkbox-color="primary"
                                >
                                  <template v-for="(head,idx) in headerPermisos" v-slot:[`item.${head.value}`]="{ item }">
                                    <p 
                                      v-if="head.value=='nombre'"
                                      :key="`nom-${idx}`" 
                                      class="mb-0"
                                      >{{item.nombre}}</p
                                    >
                                    <v-checkbox
                                      v-else
                                      v-model="
                                        permisos[item.idVista][
                                          idx
                                        ]
                                      "
                                      :key="`simp-${item.idVista}-${idx}`"
                                      color="blue darken-2"
                                    ></v-checkbox>
                                  </template>
                                  <!-- <template v-slot:body="{ items }">
                                  <tbody>
                                      <tr v-for="item in items" :key="item.id">
                                          <td>
                                              <v-checkbox 
                                                v-model="filas[item.idVista]"
                                                style="margin:0px;padding:0px"
                                                hide-details 
                                              />
                                          </td>
                                          <td></td>
                                          <td>{{ item.nombre }}</td>
                                          <td
                                            v-for="(accion, index) in acciones"
                                            :key="index + 1"
                                          >
                                            <v-checkbox
                                              v-model="
                                                permisos[item.idVista][
                                                  accion.idAccion
                                                ]
                                              "
                                              color="blue darken-2"
                                            ></v-checkbox>
                                          </td>
                                      </tr>
                                  </tbody>
                                  </template> -->
                                </v-data-table>
                              </v-card-text>
                            </v-card>
                          </v-tab-item>
                        </v-tabs>
                      </v-card>
                    </v-col>
                  </v-row>
                </v-container>
              </v-card-text>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="blue darken-1" text @click="close()"
                  >Cancelar</v-btn
                >
                <v-btn
                  color="red darken-1"
                  text
                  @click="save()"
                  :disabled="saving"
                  :loading="saving"
                >
                  Guardar
                  <template v-slot:loader>
                    <v-progress-circular
                      indeterminate
                      :width="2"
                      :size="24"
                      color="red"
                    ></v-progress-circular>
                  </template>
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
          <v-spacer></v-spacer>
          <v-text-field
            v-model="search"
            append-icon="search"
            label="Buscar"
            single-line
            :disabled="loading"
            hide-details
          ></v-text-field>
        </v-toolbar>
      </template>
      <template v-slot:[`item.action`]="{ item }">
        <v-tooltip bottom color="black" class="white--text">
          <template v-slot:activator="{ on }">
            <v-icon
              v-on="on"
              v-if="permisoVista('permisos', 'u')"
              small
              class="mr-2"
              :disabled="loading"
              @click="editItem(item)"
            >
              edit
            </v-icon>
          </template>
          <span class="white--text">Editar</span>
        </v-tooltip>

        <v-tooltip bottom color="black" class="white--text">
          <template v-slot:activator="{ on }">
            <v-icon
              v-on="on"
              v-if="permisoVista('permisos', 'd')"
              small
              :disabled="loading"
              @click="deleteItem(item)"
            >
              delete
            </v-icon>
          </template>
          <span class="white--text">Eliminar</span>
        </v-tooltip>
      </template>
      <template v-slot:no-data>
        <v-btn color="primary" @click="initialize">Refrescar</v-btn>
      </template>
    </v-data-table>
    <v-snackbar v-model="alerta" top color="error">
      Existe un Rol Activo con el mismo nombre
      <v-btn text @click="alerta = false"> Cerrar </v-btn>
    </v-snackbar>
  </v-col>
</template>

<script>
import axios from "axios";
import { mapState } from "vuex";

export default {
  data(){
    return {
      loadingRoles: false,
      loadingReportes: false,
      reportes:[],
      selected: [],
      contadorSec: 0,
      contador: 0,
      filas: [],
      seleccionados: [],
      saving: false,
      dialog: false,
      alerta: false,
      eliminar: false,
      search: "",
      headers: [
        {
          text: "Rol",
          align: "left",
          sortable: true,
          value: "nombre",
        },
        {
          text: "Acciones",
          value: "action",
          sortable: false,
          width: "35%",
          align: "center",
        },
      ],
      roles: [],
      loadingInfo: false,
      acciones: [],
      secciones: [],
      delItem: "",
      errores: [],
      permisos: [],
      editedIndex: -1,
      editedItem: {
        nombre: "",
        descripcion: "",
        idsReportes:[],
      },
      defaultItem: {
        nombre: "",
        descripcion: "",
        idsReportes:[]
      },
    }
  },
  computed: {
    ...mapState({
      desconocidos: "desconocidos",
      desconocidosIds: "desconocidosIds",
    }),
    loading(){
      return this.loadingRoles || this.loadingReportes || this.loadingInfo;
    },
    formTitle() {
      return this.editedIndex === -1 ? "Nuevo Rol" : "Editar Rol";
    },
    headerPermisos() {
      let headers = [];
      headers.push({
        text: "Vista",
        align: "center",
        sortable: false,
        value: "nombre",
      });
      this.acciones.forEach(function (element) {
        headers.push({
          text: element.nombre,
          align: "center",
          sortable: false,
          value: element.nombre,
        });
      });
      return headers;
    },
  },

  mounted() {
    this.initialize();
  },
  methods: {
    seleccionaColumna(event){
      event.items.forEach( item => {
        this.seleccionaFila({
          item: {...item},
          value: event.value
        });
      })
    },
    seleccionaFila(event){
      this.acciones.forEach( accion => {
        this.permisos[event.item.idVista][accion.idAccion] = event.value;
      });
    },
    initialize() {
      this.getInformacion();
      this.getRoles();
      this.getReportes();
    },
    getRoles(){
      this.loadingRoles = true;
      axios
        .get("/Permisos/Roles")
        .then((response) => {
          this.roles = response.data;
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => {
          this.loadingRoles = false;
        });
    },
    getInformacion() {
      let me = this;
      me.loadingInfo = true;
      axios
        .get("/Permisos/Informacion")
        .then((response) => {
          if (me.desconocidos) me.secciones = response.data.secciones;
          else {
            me.secciones = response.data.secciones;
            me.secciones.sort((x, y) => {
              return x.posicion - y.posicion;
            }); //? Organizamos secciones por posicion
            me.secciones.forEach(function (seccion) {
              seccion.vistas = seccion.vistas.filter(function (vista) {
                return me.desconocidosIds.indexOf(vista.idVista) == -1;
              });
            });
          }
          me.acciones = response.data.acciones;
          me.permisos = {};
          this.resetPermisos();
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => {
          me.loadingInfo = false;
        });
    },
    editItem(item) {
      let me = this;
      this.editedIndex = this.roles.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.resetPermisos();
      this.secciones.forEach(function (seccion) {
        seccion.vistas.forEach(function (vista) {
          me.permisos[vista.idVista] = {};
        });
      });
      item.permisos.forEach((element) => {
        if (!this.desconocidos) {
          if (this.desconocidosIds.indexOf(element.idVista) !== -1) return;
        }

        if(me.permisos[element.idVista]!=null){
          me.permisos[element.idVista][element.idAccion] = true;
        }
      });
      this.preSelect(item.permisos);
      this.dialog = true;
    },

    preSelect(itemPermisos){
      itemPermisos.forEach( permiso => {
        if(this.permisos[permiso.idVista]==undefined) return;
        if(
          this.permisos[permiso.idVista][1] &&
          this.permisos[permiso.idVista][2] &&
          this.permisos[permiso.idVista][3] &&
          this.permisos[permiso.idVista][4] &&
          this.permisos[permiso.idVista][5]
        ){
          this.secciones.forEach((seccion) => {
            seccion.vistas.forEach( (vista) => {
              if(vista.idVista==permiso.idVista){
                this.selected.push(vista);
              }
            });
          });
        }
      });
    },

    deleteItem(item) {
      this.eliminar = true;
      this.delItem = item;
    },
    deleteItemDB() {
      let me = this;
      let item = this.delItem;
      this.delItem = "";
      axios
        .put("/Permisos/Desactivar/" + item.idRol)
        .then((response) => {
          me.initialize();
        })
        .catch((error) => {
          console.log(error);
        });
    },
    close() {
      this.dialog = false;
      this.editedItem = Object.assign({}, this.defaultItem);
      this.selected = [];
      this.errores = [];
      this.resetPermisos();
      this.editedIndex = -1;
    },
    validate() {
      this.errores = [];
      if (
        this.editedItem.nombre.length < 5 ||
        this.editedItem.nombre.length > 50
      ) {
        this.errores[0] =
          "El nombre debe tener más de 4 caracteres y menos de 50.";
      }
      if (this.editedItem.descripcion.length < 5) {
        this.errores[1] = 
          "La descripción debe tener de tener al menos 5 caracteres";
      }

      return this.errores.length==0;
    },
    save() {
      if (this.validate()) {
        let me = this;
        me.saving = true;
        let permisosL = [];
        me.secciones.forEach(function (seccion) {
          seccion.vistas.forEach(function (vista) {
            me.acciones.forEach(function (accion) {
              if (me.permisos[vista.idVista][accion.idAccion] == true) {
                permisosL.push({
                  idVista: vista.idVista,
                  idAccion: accion.idAccion,
                });
              }
            });
          });
        });
        if (this.editedIndex > -1) {
          //Código para editar
          axios
            .put("/Permisos/Actualizar/" + me.roles[me.editedIndex].idRol, {
              Nombre: me.editedItem.nombre,
              Descripcion: me.editedItem.descripcion,
              IdsReportes:me.editedItem.idsReportes,
              Permisos: permisosL,
            })
            .then((response) => {
              me.saving = false;
              me.close();
              me.initialize();
            })
            .catch((error) => {
              me.saving = false;
              if (error.response) {
                if (error.response.status == 409) {
                  me.alerta = true;
                  me.errores.push("Existe un Rol Activo con el Mismo Nombre");
                }
              }
            });
        } else {
          axios
            .post("/Permisos/Crear", {
              Nombre: me.editedItem.nombre,
              Descripcion: me.editedItem.descripcion,
              IdsReportes:me.editedItem.idsReportes,
              Permisos: permisosL,
            })
            .then((response) => {
              me.saving = false;
              me.close();
              me.initialize();
            })
            .catch((error) => {
              me.saving = false;
              if (error.response) {
                if (error.response.status == 409) {
                  me.alerta = true;
                  me.errores.push("Existe un Rol Activo con el Mismo Nombre");
                }
              }
            });
        }
      }
    },
    resetPermisos() {
      this.secciones.forEach((seccion) => {
        seccion.vistas.forEach((vista) => {
          this.permisos[vista.idVista] = {};
          this.acciones.forEach((accion) => {
            this.permisos[vista.idVista][accion.idAccion] = false;
          });
        });
      });
    },
    getReportes(){
      this.loadingReportes = true;
      axios
      .get('/CatReportes/ReportesDisponibles')
      .then(response => {
        this.reportes=response.data;
      })
      .catch(error => {
        console.log(error)
      })
      .finally(() => {
        this.loadingReportes = false;
      });
    }
    // resetFilas(){
    //   this.secciones.forEach((seccion) => {
    //     this.contadorSec = 0;
    //     seccion.vistas.forEach((vista) => {
    //       this.contador = 0;
    //       this.acciones.forEach((accion) => {
    //         if ( this.permisos[vista.idVista][accion.idAccion] ) this.contador += 1; 
    //       });
    //       this.filas[vista.idVista] = this.contador === 5 ? true : false; 
    //       if ( this.filas[vista.idVista] ) this.contadorSec += 1;
    //     });
    //     this.seleccionados[seccion.idSeccion] = this.contadorSec === seccion.vistas.length ? [{}] : [];
    //   });
    // },
  },
};
</script>
