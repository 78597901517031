<template>
	<v-data-table :items="items" :items-per-page="-1" hide-default-footer class="custom-styles">
		<template v-slot:body="{ items }">
			<tbody>
				<tr v-for="header,idx in computedHeaders" :key="`tr-${idx}`">
					<td style="width: 50%">
						{{ header.text }}
					</td>
					<td v-for="item,idx2 in items" :key="`td-${idx}-${idx2}`" style="width: 50%">
						<template v-if="header.value == 'tipo'">
							{{ $utils.mantenimientos.getTipoMantenimiento(item) }}
						</template>
						<template v-else-if="header.value == 'nombre'">
							{{ $utils.mantenimientos.getNombreMantenimiento(item) }}
						</template>
                        <template v-else-if="!esServicio && header.value == 'idMaquina'">
                            {{ $utils.mantenimientos.getNombreMaquina(maquinas,item.idMaquina) }}
                        </template>
                        <template v-else-if="!esServicio && autodetectCategoriaMaquina && header.value == 'categoriaMaquina'">
                            {{ $utils.mantenimientos.getMaquinaCategoria(maquinas,item.idMaquina) }}
                        </template>
                        <template v-else>
						    {{ item[header.value] }}
                        </template>
					</td>
				</tr>
			</tbody>
		</template>
	</v-data-table>
</template>

<script>
import { mapState } from "vuex";
export default {
	props: {
		maquinas: {
			type: Array,
			default: () => [],
		},
		items: {
			type: Array,
			required: true,
		},
        esServicio: {
            type: Boolean,
            default: false
        },
		autodetectCategoriaMaquina: {
			type: Boolean,
			default: true
		},
        headers: {
            type: Array,
            default: () => [
				{
					text: "Nombre",
					align: "center",
					sortable: false,
					value: "nombre",
				},
				{
					text: "Categoría Mantenimiento",
					align: "center",
					sortable: false,
					value: "nombreCategoria",
				},
				{
					text: "Modelo",
					align: "center",
					sortable: false,
					value: "idMaquina",
				},
			]
        }
	},
	computed: {
		...mapState(["nombreEmpresa"]),
		computedHeaders() {
			const headers = this.headers.slice();
			if (this.nombreEmpresa.toUpperCase() == "INDASA" && !this.esServicio && this.autodetectCategoriaMaquina) {
				let indexIdMaquina = headers.findIndex( header => header.value == 'idMaquina' );
				headers.splice(indexIdMaquina, 0, {
					text: "Categoría del Modelo",
					align: "center",
					value: "categoriaMaquina",
				});
			}

			if(!this.esServicio && this.items[0].hasOwnProperty('maquinaIns')){
				let indexIdMaquina = headers.findIndex( header => header.value == 'idMaquina' );
				headers.splice(indexIdMaquina + 1, 0, {
					text: "Máquina",
					align: "center",
					value: "maquinaIns",
				});
			}

			return headers;
		},
	},
};
</script>
<style scoped>
.custom-styles >>> td {
	word-break: break-word;
}
</style>