<template>
  <v-col cols="12">
    <v-dialog
      v-model="dialog"
      max-width="900px"
    >
      <v-card>
        <v-card-title>
          Detalles del Excedente
        </v-card-title>
        <v-card-text>
          <v-data-table
            v-if="permisoVista('excedentes','r')"
            :headers="headersDetalles"
            :items="detalles"
          >
            <template v-slot:item.cantidad="{ item }">
              {{item.cantidad.toFixed(2)}} {{item.unidad}}
            </template>
            <template v-slot:item.inicio="{ item }">
              {{item.inicioLabel}}
            </template>
            <template v-slot:item.fin="{ item }">
              {{item.finLabel}}
            </template>
          </v-data-table>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="green" text @click="cerrarDetalles">Cerrar</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-data-table
      v-if="permisoVista('excedentes','r')"
      :headers="headers"
      :items="excedentes"
      :search="search"
      :loading="loading"
      loading-text="Cargando... Espere, Por Favor."
    >
      <template v-slot:top>
        <v-toolbar flat color="white">
          <v-text-field
            v-model="search"
            append-icon="search"
            label="Buscar"
            single-line
            hide-details
          ></v-text-field>
        </v-toolbar>
      </template>
      <template v-slot:item.cantidad="{ item }">
        {{item.cantidad.toFixed(2)}} {{item.unidad}}
      </template>
      <template v-slot:item.actions="{ item }">
        <v-tooltip bottom color="black" class="white--text">
          <template v-slot:activator="{ on }">
            <v-icon v-on="on"
              small
              @click="mostrarDetalles(item)"
            >
              mdi-eye
            </v-icon>
          </template>
          <span class="white--text">Ver detalles</span>
        </v-tooltip>
      </template>
    </v-data-table>
  </v-col>
  
</template>

<script>
import axios from "axios";

  export default {
    data: () => ({
      dialog:false,
      search: '',
      headers: [
        {
          text: 'Trabajo Orden',
          align: 'center',
          sortable: true,
          value: 'trabajoOrden',
        },
        {
          text: 'Artículo a Producir',
          align: 'left',
          sortable: true,
          value: 'articuloProducir',
        },
        {
          text: 'Cantidad',
          align: 'rigth',
          sortable: true,
          value: 'cantidad',
        },
        {
          text: 'Artículo',
          align: 'left',
          sortable: true,
          value: 'articulo',
        },
        {
          text: 'Área',
          align: 'left',
          sortable: true,
          value: 'area',
        },
        {
          text: 'Acciones',
          align: 'center',
          sortable: false,
          value: 'actions',
          width: '15%'
        },
      ],
      headersDetalles: [
        {
          text: 'Cantidad',
          align: 'rigth',
          sortable: true,
          value: 'cantidad',
        },
        {
          text: 'Artículo',
          align: 'left',
          sortable: true,
          value: 'articulo',
        },
        {
          text: 'Área',
          align: 'left',
          sortable: true,
          value: 'area',
        },
        {
          text: 'Inicio',
          align: 'left',
          sortable: true,
          value: 'inicio',
        },
        {
          text: 'Fin',
          align: 'left',
          sortable: true,
          value: 'fin',
        },
      ],
      loading: false,
      excedentes: [],
      detalles:[],
    }),

    created () {
      this.initialize()
    },

    methods: {
      initialize () {
        let me = this;
        me.loading = true;
        axios
          .get("/TrabajoOrdenes/Excedentes")
          .then(response =>  {
            me.excedentes = response.data;
            me.loading = false;
          })
          .catch(error => {
            console.log(error);
        });
      },
      mostrarDetalles(item){
        this.detalles = item.detalles;
        this.dialog = true;
      },
      cerrarDetalles(){
        this.detalles = [];
        this.dialog = false;
      }
    },
  }
</script>