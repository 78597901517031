<template>
    <v-col cols="12" class="px-8" v-if="permisoVista('dashboard','r')">
        <v-data-table
            :items="operadores"
            :headers="operadoresHeaders"
            :loading="loading"
						:items-per-page="-1"
						hide-default-footer
        >
            <template v-slot:item.articuloProducir="{ item }">
                {{ item.articuloProducir!=null?item.articuloProducir:'Sin operaciones en curso' }}
            </template>
            <template v-slot:item.articuloFase="{ item }">
                {{ item.articuloFase!=null?item.articuloFase:'N/A' }}
            </template>
        </v-data-table>
    </v-col>
</template>

<script>
import axios from "axios";
export default {
	data() {
		return {
            interval: null,
            segundos: 60,
			loading: false,
			operadores: [],
			operadoresHeaders: [
				{
					text: "Operador",
					align: "center",
					sortable: false,
					value: "nombre",
				},
				{
					text: "Área",
					align: "center",
					sortable: false,
					value: "area",
				},
				{
					text: "Artículo a Producir",
					align: "center",
					sortable: false,
					value: "articuloProducir",
				},
				{
					text: "Fase",
					align: "center",
					sortable: false,
					value: "articuloFase",
				},
			],
		};
	},
	mounted() {
        this.getOperadoresOperacion();
        this.interval = setInterval(() => {
            if(this.segundos == 0){
                this.initialize();
            }
            else this.segundos -= 1;
        }, 1000);
	},
	methods: {
		initialize() {
			this.getOperadoresOperacion();
		},
		getOperadoresOperacion() {
			this.loading = true;
            this.segundos = 60;
			axios
				.get("/Reportes/ReporteOperadoresOperacion")
				.then((response) => {
					this.loading = false;
					this.operadores = response.data;
				})
				.catch((error) => {
					this.loading = false;
					console.log(error);
				});
		},
	},
	destroyed() {
		clearInterval(this.interval);
	},
};
</script>