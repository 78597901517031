<template>
  <v-col cols="12">
    <bar-code-reader
      v-if="dialogRFID"
      @change="leerRFID"
    ></bar-code-reader>
    <template>
      <v-row justify="center">
        <v-dialog v-model="view" persistent max-width="500px">
          <v-card>
            <v-card-title class="headline">Ficha de {{viewedItem.nombre+' '+viewedItem.paterno+' '+viewedItem.materno}}</v-card-title>
            <v-card-text>
                <v-container>
                  <v-row>
                    <v-col justify="center" cols="6" sm="6" md="6">
                      <v-text-field readonly v-model="viewedItem.nombre" label="Nombre"></v-text-field>
                    </v-col>
                    <v-col justify="center" cols="6" sm="6" md="6">
                      <v-text-field readonly v-model="viewedItem.paterno" label="Apellido Paterno"></v-text-field>
                    </v-col>
                    <v-col justify="center" cols="6" sm="6" md="6">
                      <v-text-field readonly v-model="viewedItem.materno" label="Apellido Materno"></v-text-field>
                    </v-col>
                    <v-col justify="center" cols="12" sm="12" md="12">
                      <v-autocomplete
                        v-model="viewedItem.habilidades"
                        :items="habilidades"
                        label="Habilidades"
                        item-text="nombre"
                        chips
                        multiple
                        readonly
                        item-value="idHabilidad"
                      ></v-autocomplete>
                    </v-col>
                    <v-expansion-panels cols="12" sm="12" md="12" multiple accordion>
                      <v-expansion-panel>
                        <v-expansion-panel-header>
                          Máquinas
                        </v-expansion-panel-header>
                        <v-expansion-panel-content>
                           <v-simple-table>
                              <thead>
                                <tr>
                                  <th class="text-center">Máquina</th>
                                  <th class="text-center"># Habilidades</th>
                                  <th class="text-center">Porcentaje</th>
                                </tr>
                              </thead>
                              <tbody>
                                <tr v-for="maquina in viewedItem.maquinas" :key="maquina.idMaquina">
                                  <td class="text-center">{{ getNombreMaquina(maquina.idMaquina) }}</td>
                                  <td class="text-center">{{ maquina.nHabilidades }}</td>
                                  <td class="text-center">{{ (maquinas.hRequeridas > 0) ? ((maquina.nHabilidades/maquina.hRequeridas)*100).toFixed(2) : "100.00" }}%</td>
                                </tr>
                              </tbody>
                          </v-simple-table>
                        </v-expansion-panel-content>
                      </v-expansion-panel>
                    </v-expansion-panels>
                  </v-row>
                </v-container>
              </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="red darken-1" text @click="view = false ">Aceptar</v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
        <v-dialog v-model="pin" persistent max-width="500px">
          <v-card>
            <v-card-title>
              Asignar PIN a {{pinViewed.nombre}} {{pinViewed.materno}} {{pinViewed.paterno}}
            </v-card-title>
            <v-card-text>
              <br>
              <v-col cols="12" md="12" sm="12" class="pa-0 ma-0">
                <v-text-field
                  v-if="pinType == 'numbers'"
                  v-model="pinViewed.pin"
                  :maxlength="pinLength"
                  type="number"
                  label="PIN"
                  outlined
                ></v-text-field>
                <v-text-field
                  v-if="pinType == 'chars'"
                  v-model="pinViewed.pin"
                  :maxlength="pinLength"
                  label="PIN"
                  outlined
                ></v-text-field>
              </v-col>
              <v-col cols="12" md="12" sm="12" class="pa-0 ma-0 text-center justify-center">
                <v-btn
                  color="blue"
                  @click="sugerirPin()"
                  large
                >
                  <v-icon color="white" x-large>mdi-reload</v-icon> Sugerir PIN
                </v-btn>
              </v-col>
              <ul>
                <li v-for="(error,idx) in errores" :key="'error'+idx" class="red--text">{{error}}</li>
              </ul>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
                <v-btn color="blue darken-1" text @click="pin=false; pinViewed=Object.assign({},defaultItem);errores=[];">Cancelar</v-btn>
                <v-btn color="red darken-1" text @click="save" :disabled="saving" :loading="saving">
                  Guardar
                  <template v-slot:loader>
                    <v-progress-circular
                      indeterminate
                      :width="2"
                      :size="24"
                      color="red"
                    ></v-progress-circular>
                  </template>
                </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
        <v-dialog v-model="dialogRFID" persistent max-width="500px">
          <v-card>
            <v-card-title>
              Asignar RFID a {{pinViewed.nombre}} {{pinViewed.materno}} {{pinViewed.paterno}}
            </v-card-title>
            <v-card-text>
              <br>
              <v-col cols="12" md="12" sm="12" class="pa-0 ma-0 text-center" >
                <p class="text-h5">Acerque la tarjeta RFID</p>
                <v-progress-circular
                  :rotate="-90"
                  :size="150"
                  :width="15"
                  :value="percentRFID"
                  color="primary"
                >
                  {{ percentRFID }}%
                </v-progress-circular>
              </v-col>
              <br>
              <ul>
                <li v-for="(error,idx) in errores" :key="'error'+idx" class="red--text">{{error}}</li>
              </ul>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
                <v-btn 
                  color="blue darken-1" 
                  text 
                  @click="
                    dialogRFID=false;
                    rfidsLeidos = [];
                    percentRFID = 0;
                    errores = [];
                    pinViewed=Object.assign({},defaultItem);
                  "
                  >Cancelar</v-btn
                >
                <v-btn color="red darken-1" text :disabled="savingRFID || percentRFID < 100 || saving" @click="saveRFID"  :loading="saving">
                  Guardar
                  <template v-slot:loader>
                    <v-progress-circular
                      indeterminate
                      :width="2"
                      :size="24"
                      color="red"
                    ></v-progress-circular>
                  </template>
                </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
        <v-dialog v-model="closeAll" persistent max-width="500px">
          <v-card>
            <v-card-title>
            </v-card-title>
            <v-card-text>
              <div class="text-center">
                <v-icon size="256" color="red">mdi-alert</v-icon>
              </div>
              <div class="red--text title">
                Esta función ejecutara las siguientes acciones:
                <br>
                <ul>
                  <li class="subtitle-2">Desintegrar cualquier Equipo que se encuentre Activo.</li>
                  <li class="subtitle-2">Cerrar las Sesiones de todos los Operadores en las Estaciones de Trabajo.</li>
                  <li class="subtitle-2">Cerrar todas las Operaciones que actualmente se encuentren Ejecutando, reportando un avance de 0.0 (Piezas, Litros, Metros o Cualquier otra Unidad de Medición).</li>
                  <li class="subtitle-2">Recargara la Página de Operaciones de todas las Estaciones de Trabajo que se encuentren encendidas y conectadas.</li>
                </ul>
              </div>
            </v-card-text>
            <v-card-actions>
              <v-btn color="green" text @click="closeAll = false">Cancelar</v-btn>
              <v-spacer></v-spacer>
              <v-btn color="red" text @click="cerrarTodoRun">Ejecutar</v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
        <v-dialog v-model="closeSession" persistent max-width="500px">
          <v-card>
            <v-card-title>
            </v-card-title>
            <v-card-text>
              <div class="text-center">
                <v-icon size="256" color="red">mdi-alert</v-icon>
              </div>
              <div class="red--text title">
                Esta función ejecutara las siguientes acciones:
                <br>
                <ul>
                  <li class="subtitle-2">Expulsara a {{logoutItem.nombre}} {{logoutItem.paterno}} {{logoutItem.materno}} de cualquier equipo en donde se encuentre activo.</li>
                  <li class="subtitle-2">Cerrará la Sesión de {{logoutItem.nombre}} {{logoutItem.paterno}} {{logoutItem.materno}} en todas las Estaciones de Trabajo.</li>
                  <li class="subtitle-2">Cerrar todas las Operaciones en donde {{logoutItem.nombre}} {{logoutItem.paterno}} {{logoutItem.materno}} sea el único Operador, reportando un avance de 0.0 (Piezas, Litros, Metros o Cualquier otra Unidad de Medición).</li>
                </ul>
              </div>
            </v-card-text>
            <v-card-actions>
              <v-btn color="green" text @click="closeSession = false">Cancelar</v-btn>
              <v-spacer></v-spacer>
              <v-btn color="red" text @click="cerrarSesionRun">Ejecutar</v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-row>
    </template>
    <v-dialog v-model="dialogQR" max-width="35%" @click:outside="cerrarDialogQR()">
      <v-card>
        <v-card-title class="d-flex justify-center text-center">
          Código QR generado para <br>
          {{
            `${this.pinViewed.nombre} ${this.pinViewed.paterno} ${this.pinViewed.materno}`
          }}
        </v-card-title>
        <v-card-text class="text-center">
          <lottie :options="checkOptions" :height="250" :width="250"/>
          <a class="headline" target="_blank" :href="`/api/Operadores/QROperador/${this.pinViewed.idPersona}`+'?access_token='+token"><v-icon color="primary" large>mdi-download</v-icon> Descargar Código QR</a>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-btn v-if="permisoVista('operadores','d')" color="red" dark block @click="closeAll = true"><v-icon color="yellow">
      mdi-alert</v-icon> Cerrar Todas las Operaciones y Sesiones
    </v-btn>
    <v-data-table
      v-if="permisoVista('operadores','r')"
      :headers="headers"
      :items="personas"
      :search="search"
      :loading="loading"
      loading-text="Cargando... Espere, Por Favor."
    >
      <template v-slot:top>
        <v-toolbar flat color="white">
          <v-text-field
            v-model="search"
            append-icon="search"
            label="Buscar"
            single-line
            hide-details
          ></v-text-field>
        </v-toolbar>
      </template>
      <template v-slot:item.action="{ item }">
        <v-tooltip v-if="permisoVista('operadores','r') && qrOperador"  bottom color="black" class="white--text">
          <template v-slot:activator="{ on }">
            <v-icon v-on="on"
              small
              class="mr-2"
              @click="showDialogQR(item)"
            >
              mdi-qrcode
            </v-icon>
          </template>
          <span class="white--text">Generar código QR</span>
        </v-tooltip>
        <v-tooltip bottom color="black" class="white--text">
          <template v-slot:activator="{ on }">
        <v-icon v-on="on"
          v-if="permisoVista('operadores','u') && rfid"
          small
          class="mr-2"
          @click="rfidView(item)"
        >
          mdi-credit-card
        </v-icon>
         </template>
          <span class="white--text">Asignar RFID</span>
        </v-tooltip>
        <v-tooltip bottom color="black" class="white--text">
          <template v-slot:activator="{ on }">
        <v-icon v-on="on"
          v-if="permisoVista('operadores','u') && !rfid && !qrOperador"
          small
          class="mr-2"
          @click="pinView(item)"
        >
          mdi-key
        </v-icon>
         </template>
          <span class="white--text">Asignar PIN</span>
        </v-tooltip>
        <v-tooltip bottom color="black" class="white--text">
          <template v-slot:activator="{ on }">
        <v-icon v-on="on"
          v-if="permisoVista('operadores','d')"
          small
          class="mr-2"
          @click="cerrarSesion(item)"
        >
          mdi-ethernet-cable-off
        </v-icon>
         </template>
          <span class="white--text">Cerrar Sesion y Operaciones</span>
        </v-tooltip>
        <v-tooltip bottom color="black" class="white--text">
          <template v-slot:activator="{ on }">
        <v-icon v-on="on"
          v-if="permisoVista('operadores','r')"
          small
          class="mr-2"
          @click="viewItem(item)"
        >
          mdi-eye
        </v-icon>
         </template>
          <span class="white--text">Ver detalles</span>
        </v-tooltip>
      </template>
      <template v-slot:item.pin="{ item }" >
        <div class="text-center">
          {{ getTextoColumna(item) }}
        </div>
      </template>
      <template v-slot:no-data>
        <v-btn color="primary" @click="initialize">Refrescar</v-btn>
      </template>
    </v-data-table>
  </v-col>
  
</template>

<script>
import axios from "axios";
import { mapState } from "vuex";
import BarCodeReader from '../../BarCodeReader.vue';
import moment from 'moment';
import Lottie from 'vue-lottie';
import * as checkData from '@/assets/qr-scanning.json';

  export default {
    components: {
      "bar-code-reader": BarCodeReader,
      'lottie': Lottie
    },
    data: () => ({
      checkOptions: {
        animationData: checkData.default,
        loop:true,
        speeed: 0.5
      },
      rfidsLeidos: [],
      percentRFID: 0,
      view:false,
      saving:false,
      savingRFID:false,
      viewedItem:[],
      search: '',
      loading: false,
      habilidades: [],
      loadingHabilidades: false,
      personas: [],
      pin:false,
      defaultItem: {
        idPersona: 0,
        pin:'',
        nombre:'',
        paterno:'',
        materno:'',
      },
      pinViewed:{
        idPersona: 0,
        pin:'',
        nombre:'',
        paterno:'',
        materno:'',
      },
      errores:[],
      closeAll:false,
      closeSession:false,
      logoutItem:{
        idPersona: 0,
        pin:'',
        nombre:'',
        paterno:'',
        materno:'',
      },
    }),

    computed: {
      ...mapState(["pinType","pinLength","rfid","qrOperador","token"]),
      formTitle () {
        return this.editedIndex === -1 ? 'Nuevo Operador' : 'Editar Operador'
      },
      headers(){
        return [
          {
            text: 'Nombre',
            align: 'left',
            sortable: true,
            value: 'nombre',
          },
          {
            text: 'Paterno',
            align: 'left',
            sortable: true,
            value: 'paterno',
          },
          {
            text: 'Materno',
            align: 'left',
            sortable: true,
            value: 'materno',
          },
          {
            text: 'Turno',
            align: 'left',
            sortable: true,
            value: 'turno',
          },
          {
            text: this.getTextoHeader(),
            align: 'center',
            sortable: true,
            value: 'pin',
          },
          { text: 'Acciones', value: 'action', sortable: false, width:'20%', align:'center' },
        ]
      }
    },

    watch: {
      dialog (val) {
        val || this.close()
      }
    },

    mounted () {
      this.initialize()
    },

    methods: {
      getTextoHeader(){
        return (this.rfid || this.qrOperador) 
          ? this.rfid 
            ? '¿Tiene Tarjeta Asignada?'
            : '¿Tiene Código QR Asignado?'
          : 'PIN';
      },
      getTextoColumna(item){
        if(this.rfid || this.qrOperador){
          return item.pin == null ? 'No' : 'Si';
        }
        else{
          return item.pin;
        }
      },
      leerRFID(rfid){
        if ( this.$utils.strings.isOnlyNumbers(rfid) ){
          if ( this.rfidsLeidos.length < 3 ){
            if( this.rfidsLeidos.length === 0 ) {
              this.rfidsLeidos.push(rfid);
              this.percentRFID += 33;
            }
            else if ( this.rfidsLeidos.length === 1 ){
              if ( this.rfidsLeidos[0] == rfid ){
                this.rfidsLeidos.push(rfid);
                this.percentRFID += 33;
              }
            }
            else if ( this.rfidsLeidos.length === 2 ){
              if ( this.rfidsLeidos[1] == rfid ){
                this.rfidsLeidos.push(rfid);
                this.percentRFID += 34;
              }
            }
          }
        }
      },
      initialize () {
        let me = this;
        me.getHabilidades();
        me.getMaquinas();
        me.loading = true;
        axios
        .get("/Personas/Operadores")
        .then(response =>  {
          me.personas = response.data;
          me.loading = false;
        })
        .catch(error => {
          console.log(error);
        });
      },
      cerrarTodoRun(){
        axios
          .get("/Operadores/LogoutForzadoMasivo")
          .then(() => {})
          .catch(error => {
            console.log(error);
          });
        this.closeAll = false;
      },
      cerrarSesion(item){
        this.logoutItem = Object.assign({},item);
        this.closeSession = true;
      },
      cerrarSesionRun(){
        axios
          .get("/Operadores/LogoutForzadoOperador/"+this.logoutItem.idPersona)
          .then(() => {})
          .catch(error => {
            console.log(error);
          });
        this.closeSession = false;
      },
      getNombreMaquina(idMaquina){
        var m = this.maquinas.find(x => x.idMaquina == idMaquina);
        if(m != null) return m.marca+" "+m.modelo;
        else return "";
      },
      getHabilidades (){
        let me = this;
        me.loadingHabilidades = true;
        axios
        .get("/Habilidades")
        .then(response =>  {
          me.habilidades = response.data;
          me.loadingHabilidades = false;
        })
        .catch(error => {
          console.log(error);
        });
      },
      getMaquinas (){
        let me = this;
        me.loadingMaquinas = true;
        axios
        .get("/Maquinas/Basico")
        .then(response =>  {
          me.maquinas = response.data;
          me.loadingMaquinas = false;
        })
        .catch(error => {
          console.log(error);
        });
      },
      cerrarDialogQR(){
        this.dialogQR = false;
        this.pinViewed = Object.assign({},this.defaultItem);
        this.initialize();
      },
      descargarQR(){
        var datetime = moment().format('YYYYMMDDHHmmss');
        this.$utils.axios.downloadFile(`/Operadores/QROperador/${this.pinViewed.idPersona}`,'GET',`qr-${this.viewedItem.nombre}-${this.viewedItem.paterno}-`+datetime+'.pdf','application/pdf');
      },
      showDialogQR(item){
        this.pinViewed = Object.assign({},item);
        this.dialogQR = true;
      },
      viewItem (item) {
        this.viewedIndex = this.personas.indexOf(item)
        this.viewedItem = Object.assign({}, item)
        this.view = true
      },
      pinView(item){
        this.pinViewed = Object.assign({},item);
        this.pin = true;
      },
      rfidView(item){
        this.pinViewed = Object.assign({},item);
        this.dialogRFID = true;
      },
      sugerirPin(){
        axios
        .get("/Personas/SugererPIN")
        .then(response => {
          this.pinViewed.pin = response.data;
        })
        .catch(error => {
          console.log(error);
        })
      },
      saveRFID(){
        this.pinViewed.pin = this.rfidsLeidos[0];
        this.save();
      },
      save(){
        this.errores = [];
        if(this.pinViewed.pin.length != this.pinLength){
          this.errores.push(`El Pin debe de ser de ${this.pinLength} Dígitos`);
          return;
        }
        if(!this.rfid){
          var reg = /^\d+$/;
          if(!reg.test(this.pinViewed.pin)){
            this.errores.push("El Pin debe de estar formado solo por Números");
          }
        }
        this.saving = true;
        axios
        .put("/Personas/ActualizarPIN",{
          IdOperador: this.pinViewed.idPersona,
          Pin: this.pinViewed.pin
        })
        .then(response => {
          this.saving = false;
          this.pin = false;
          this.dialogRFID = false;
          this.rfidsLeidos = [];
          this.percentRFID = 0;
          this.errores = [];
          this.pinViewed=Object.assign({}, this.defaultItem);
          axios
          .get("/Personas/Operadores")
          .then(response =>  {
            this.personas = response.data;
            this.loading = false;
          })
          .catch(error => {
            console.log(error);
          });
        })
        .catch(error => {
          this.saving = false;
          if(error.response.status == 409){
            this.errores = [];
            this.errores.push("Este PIN es usado por otro Operador");
          }
          console.log(error);
        })
      },
      close () {
        this.action = 0,
        this.dialog = false
        setTimeout(() => {
          this.editedItem = Object.assign({}, this.defaultItem)
          this.editedIndex = -1
        }, 300)
      }
    },
  }
</script>