<template>
	<v-col cols="12" v-if="permisoVista('usuarios', 'r')">
		<bar-code-reader
			v-if="dialogRFID"
			@change="leerRFID"
		></bar-code-reader>
		<v-dialog v-model="dialogDelete" persistent max-width="40%">
			<v-card>
				<v-card-text>
					<p class="text-h5 pt-4 font-weight-regular text--primary">
						¿Está seguro que desea eliminar este Usuario?
					</p>
					<p class="subtitle-1">
						Esta acción no se puede revertir y será permanente.
					</p>
				</v-card-text>
				<v-card-actions>
					<v-spacer></v-spacer>
					<v-btn
						text
						color="success"
						:disabled="deleting"
						@click="cerrarDialogDelete()"
						>Cancelar</v-btn
					>
					<v-btn
						text
						color="error"
						:disabled="deleting"
						:loading="deleting"
						@click="eliminarUsuario()"
						>Eliminar</v-btn
					>
				</v-card-actions>
			</v-card>
		</v-dialog>

	<v-dialog v-model="dialogResetPassword" persistent max-width="40%">
			<v-card>
				<v-card-text>
					<p class="text-h5 pt-4 font-weight-regular text--primary">
						¿Está seguro que deseas reestablecer la contraseña?
					</p>
					<p class="subtitle-1">
						Usuario: {{editedItem.nombre}} <br>
						Email: {{editedItem.email}}
						<br>
						Esta acción no se podrá revertir.
					</p>
				</v-card-text>
				<v-card-actions>
					<v-spacer></v-spacer>
					<v-btn
						text
						color="success"
						:disabled="deleting"
						@click="dialogResetPassword = false"
						>Cancelar</v-btn
					>
					<v-btn
						text
						color="error"
						:disabled="deleting"
						:loading="deleting"
						@click="resetPassword()"
						>Sí, restablecer</v-btn
					>
				</v-card-actions>
			</v-card>
		</v-dialog>

		<v-dialog v-model="dialogUnloackAccount" persistent max-width="40%">
			<v-card>
				<v-card-text>
					<p class="text-h5 pt-4 font-weight-regular text--primary">
						¿Está seguro que deseas desbloquear la cuenta?
					</p>
					<p class="subtitle-1">
						Usuario: {{editedItem.nombre}} <br>
						Email: {{editedItem.email}}
						<br>
						Esta acción no se podrá revertir.
					</p>
				</v-card-text>
				<v-card-actions>
					<v-spacer></v-spacer>
					<v-btn
						text
						color="success"
						:disabled="deleting"
						@click="dialogUnloackAccount = false"
						>Cancelar</v-btn
					>
					<v-btn
						text
						color="error"
						:disabled="deleting"
						:loading="deleting"
						@click="UnlockAccount()"
						>Sí, desbloquear</v-btn
					>
				</v-card-actions>
			</v-card>
		</v-dialog>

		<v-dialog v-model="pin" persistent max-width="500px">
          <v-card>
            <v-card-title>
              <p class="mb-0 text-h6">Asignar PIN a {{pinViewed.nombre}}</p>
            </v-card-title>
            <v-card-text>
              <br>
              <v-col cols="12" md="12" sm="12" class="pa-0 ma-0">
                <v-text-field
                  v-if="pinType == 'numbers'"
                  v-model="pinViewed.pin"
                  :maxlength="pinLength"
                  type="number"
                  label="PIN"
                  outlined
                ></v-text-field>
                <v-text-field
                  v-if="pinType == 'chars'"
                  v-model="pinViewed.pin"
                  :maxlength="pinLength"
                  label="PIN"
                  outlined
                ></v-text-field>
              </v-col>
              <v-col cols="12" md="12" sm="12" class="pa-0 ma-0 text-center justify-center">
                <v-btn
                  color="blue"
                  @click="sugerirPin()"
                  large
                >
                  <v-icon color="white" x-large>mdi-reload</v-icon> Sugerir PIN
                </v-btn>
              </v-col>
              <ul>
                <li v-for="(error,idx) in errores" :key="'error'+idx" class="red--text">{{error}}</li>
              </ul>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
                <v-btn 
					color="green" 
					text 
					@click="
						pin=false; 
						pinViewed=Object.assign({},defaultPinViewed);
						errores = [];
					"
					>Cancelar</v-btn
				>
                <v-btn color="red" text @click="savePINRFID()" :disabled="saving" :loading="saving">
                  Guardar
                  <template v-slot:loader>
                    <v-progress-circular
                      indeterminate
                      :width="2"
                      :size="24"
                      color="red"
                    ></v-progress-circular>
                  </template>
                </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
        <v-dialog v-model="dialogRFID" persistent max-width="500px">
          <v-card>
            <v-card-title>
              <p class="mb-0 text-h6">Asignar RFID a {{pinViewed.nombre}}</p>
            </v-card-title>
            <v-card-text>
              <br>
              <v-col cols="12" md="12" sm="12" class="pa-0 ma-0 text-center" >
                <p class="text-h5">Acerque la tarjeta RFID</p>
                <v-progress-circular
                  :rotate="-90"
                  :size="150"
                  :width="15"
                  :value="percentRFID"
                  color="primary"
                >
                  {{ percentRFID }}%
                </v-progress-circular>
              </v-col>
              <br>
              <ul>
                <li v-for="(error,idx) in errores" :key="'error'+idx" class="red--text">{{error}}</li>
              </ul>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
                <v-btn 
                  color="green" 
                  text 
                  @click="
                    dialogRFID=false;
                    rfidsLeidos = [];
                    percentRFID = 0;
                    errores = [];
                    pinViewed=Object.assign({},defaultPinViewed);
                  "
                  >Cancelar</v-btn
                >
                <v-btn color="red" text :disabled="savingRFID || percentRFID < 100 || saving" @click="saveRFID"  :loading="saving">
                  Guardar
                  <template v-slot:loader>
                    <v-progress-circular
                      indeterminate
                      :width="2"
                      :size="24"
                      color="red"
                    ></v-progress-circular>
                  </template>
                </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
		<v-data-table
			:headers="headers"
			:items="usuarios"
			:search="search"
			:loading="loading"
			:key="loadingChange"
			loading-text="Cargando... Espere, Por Favor."
		>
			<template v-slot:[`item.validado`]="{ item }">
				<v-icon
					medium
					color="green"
					v-if="item.validado"
				>
					mdi-check-circle
				</v-icon>
			</template>
			<template v-slot:[`item.nombre`]="{ item }">
				<p class="py-1 my-1">{{ getNombre(item) }}</p>
			</template>
			<template v-slot:[`item.condicion`]="{ item }">
				<v-switch
					v-if="permisoVista('usuarios', 'd')"
					class="justify-center"
					@change="changeState(item)"
					:input-value="item.condicion"
					:label="item.condicion ? 'Activado' : 'Desactivado'"
				>
				</v-switch>
			</template>
			<template v-slot:item.bloqueo="{ item }">
				<v-icon
					medium
					color="red"
					v-if="item.bloqueado"
				>
					mdi-close-circle
				</v-icon>
				<v-icon
					medium
					color="green"
					v-else
				>
					mdi-check-circle
				</v-icon>
				<!-- ***** -->
				
			</template>
			<template v-slot:top>
				<v-toolbar flat color="white">
					<v-dialog
						v-model="dialog"
						persistent
						max-width="500px"
						:key="dialog"
					>
						<template v-slot:activator="{ on }">
							<v-btn
								v-if="permisoVista('usuarios', 'c')"
								color="primary"
								dark
								class="mb-2"
								v-on="on"
								>Nuevo Usuario</v-btn
							>
						</template>
						<v-card>
							<v-card-title>
								<span class="headline">{{ formTitle }}</span>
							</v-card-title>
							<v-card-text>
								<v-container>
									<v-row>
										<v-col class="d-flex" cols="11" sm="11">
											<v-select
												v-model="editedItem.idRol"
												:items="roles"
												label="Rol"
												item-text="nombre"
												:disabled="loadingRoles"
												item-value="idRol"
											></v-select>
										</v-col>
										<v-icon
											class="mr-2"
											:disabled="loadingRoles"
											@click="getRoles()"
										>
											mdi-reload
										</v-icon>
										<v-col class="d-flex" cols="11" sm="11">
											<v-select
												v-model="editedItem.idsTurnos"
												:items="turnos"
												label="Turno(s)"
												item-text="nombre"
												:disabled="loadingTurnos"
												item-value="idTurno"
												multiple
												chips
											></v-select>
										</v-col>
										<v-icon
											class="mr-2"
											:disabled="loadingRoles"
											@click="getTurnos()"
										>
											mdi-reload
										</v-icon>

										<v-col class="d-flex" cols="11" sm="11">
											<v-select
												v-model="
													editedItem.idsComercialLineas
												"
												:items="lineas"
												label="Líneas Comerciales"
												item-text="nombre"
												:disabled="loadingLineas"
												item-value="idComercialLinea"
												multiple
												chips
											></v-select>
										</v-col>
										<v-icon
											class="mr-2"
											:disabled="loadingLineas"
											@click="getComercialLineas()"
										>
											mdi-reload
										</v-icon>

										<v-col cols="12">
											<v-autocomplete
												v-model="editedItem.idPersona"
												:items="
													editedItem.idUsuario == null
														? personasMantenimiento
														: personasMantenimientoFiltred
												"
												item-value="idPersona"
												:item-text="nombrePersona"
												label="[Opcional] Asociar Persona"
												clearable
												@change="$set(editedItem,'nombre','')"
											></v-autocomplete>
										</v-col>

										<v-col
											justify="center"
											cols="12"
											sm="12"
											md="12"
										>
											<v-text-field
												v-model="editedItem.nombre"
												label="Nombre"
												:disabled="editedItem.idPersona!=null"
											></v-text-field>
										</v-col>
										<v-col
											justify="center"
											cols="6"
											sm="6"
											md="6"
										>
											<v-text-field
												:readonly="editedIndex >= 0"
												v-model="editedItem.email"
												label="Email"
											></v-text-field>
										</v-col>
										<v-col
											justify="center"
											cols="6"
											sm="6"
											md="6"
										>
											<v-text-field
												v-if="editedIndex < 0"
												v-model="editedItem.password"
												:append-icon="
													password
														? 'mdi-eye'
														: 'mdi-eye-off'
												"
												:type="
													password
														? 'text'
														: 'password'
												"
												name="password"
												label="Contraseña del Usuario"
												hint="Al menos 8 caracteres"
												counter
												@click:append="
													password = !password
												"
											></v-text-field>
										</v-col>
										<v-col
											justify="center"
											cols="12"
											sm="12"
											md="12"
										>
											<v-checkbox
												v-model="editedItem.dobleFactor"
												label="Autentificación de Doble Factor"
											></v-checkbox>
										</v-col>
									</v-row>
									<ul>
										<li
											class="red--text"
											v-for="ex in errores"
											:key="ex"
										>
											{{ ex }}
										</li>
									</ul>
								</v-container>
							</v-card-text>
							<v-card-actions>
								<v-spacer></v-spacer>
								<v-btn
									color="blue darken-1"
									text
									@click="cerrarDialog"
									>Cancelar</v-btn
								>
								<v-btn
									color="red darken-1"
									text
									@click="save()"
									:disabled="saving"
									:loading="saving"
								>
									Guardar
									<template v-slot:loader>
										<v-progress-circular
											indeterminate
											:width="2"
											:size="24"
											color="red"
										></v-progress-circular>
									</template>
								</v-btn>
							</v-card-actions>
						</v-card>
					</v-dialog>
					<v-spacer></v-spacer>
					<v-text-field
						v-model="search"
						append-icon="search"
						label="Buscar"
						single-line
						hide-details
					></v-text-field>
				</v-toolbar>
			</template>
			<template v-slot:[`item.action`]="{ item }">
				<v-tooltip
					
					bottom
					color="black"
					class="white--text"
				>
					<template v-slot:activator="{ on }">
						<v-icon
							v-on="on"
							v-if="permisoVista('usuarios', 'u')"
							small
							class="mr-1"
							@click="editItem(item)"
						>
							edit
						</v-icon>
					</template>
					<span class="white--text">Editar</span>
				</v-tooltip>
				<v-tooltip v-if="permisoVista('usuarios', 'u')"  bottom color="black" class="white--text">
					<template v-slot:activator="{ on }">
						<v-icon v-on="on"
							v-if="rfid"
							small
							class="mr-1"
							@click="rfidView(item)"
						>
							mdi-credit-card
						</v-icon>
					</template>
					<span class="white--text">Asignar RFID</span>
				</v-tooltip>
				<v-tooltip v-if="permisoVista('usuarios', 'u')"  bottom color="black" class="white--text">
					<template v-slot:activator="{ on }">
						<v-icon v-on="on"
							v-if="!rfid"
							small
							class="mr-1"
							@click="pinView(item)"
						>
							mdi-key
						</v-icon>
					</template>
					<span class="white--text">Asignar PIN</span>
				</v-tooltip>
				<v-tooltip v-if="permisoVista('usuarios', 'd')"  bottom color="black" class="white--text">
					<template v-slot:activator="{ on }">
						<v-icon v-on="on"
							small
							class="mr-1"
							@click="showDialogDelete(item)"
						>
							mdi-delete
						</v-icon>
					</template>
					<span class="white--text">Eliminar</span>
				</v-tooltip>
				<v-tooltip v-if="permisoVista('usuarios', 'u')"  bottom color="black" class="white--text">
					<template v-slot:activator="{ on }">
						<v-icon v-on="on"
							small
							class="mr-1"
							@click="showDialogResetPassword(item)"
						>
							mdi-lock-reset
						</v-icon>
					</template>
					<span class="white--text">Restablecer contraseña</span>
				</v-tooltip>
				<v-tooltip v-if="permisoVista('usuarios', 'u')"  bottom color="black" class="white--text">
					<template v-slot:activator="{ on }">
						<!-- <v-icon v-if="!item.bloqueado" small class="mr-1">
							mdi-lock-open-variant
						</v-icon> -->
						<v-icon v-on="on" v-if="item.bloqueado"
							small
							class="mr-1"
							@click="showDialogUnlockAccount(item)"
						>
						mdi-lock
						</v-icon>
					</template>
					<span class="white--text">Desbloquear cuenta</span>
				</v-tooltip>
			</template>
			<template v-slot:no-data>
				<v-btn color="primary" @click="initialize">Refrescar</v-btn>
			</template>
		</v-data-table>
		<v-snackbar v-model="alerta" top color="error">
			Existe un Usuario con el mismo Email
			<v-btn text @click="alerta = false"> Cerrar </v-btn>
		</v-snackbar>
	</v-col>
</template>

<script>
import axios from "axios";
import { mapState } from 'vuex';

export default {
	data: () => ({
		personas: [],
		personasMantenimiento: [],
		personasMantenimientoFiltred: [],
		dialogResetPassword : false,
		dialogUnloackAccount: false,
		deleting: false,
		dialogDelete: false,
		savingRFID: false,
		rfidsLeidos: [],
      	percentRFID: 0,
      	pin:false,
		dialogRFID: false,
		saving: false,
		password: true,
		loadingChange: false,
		roles: [],
		loadingRoles: false,
		dialog: false,
		alerta: false,
		search: "",
		headers: [
			{
				align: "center",
				sortable: false,
				value: "validado",
			},
			{
				text: "Nombre",
				align: "left",
				sortable: true,
				value: "nombre",
			},
			{
				text: "Email",
				align: "left",
				sortable: true,
				value: "email",
			},
			{
				text: "Rol",
				align: "left",
				sortable: true,
				value: "rol",
			},
			{
				text: "Activo",
				align: "center",
				sortable: true,
				value: "condicion",
			},
			{
				text: "Bloqueo",
				align: "center",
				sortable: true,
				value: "bloqueo",
			},
			{
				text: "Acciones",
				value: "action",
				sortable: false,
				align: "center",
			},
		],
		defaultPinViewed: {
			idPersona: 0,
			pin:'',
		},
		pinViewed:{
			idPersona: 0,
			pin:'',
		},
		loading: false,
		usuarios: [],
		action: 0,
		errores: [],
		editedIndex: -1,
		editedItem: {
			idUsuario: null,
			idPersona: null,
			idRol: -1,
			nombre: "",
			email: "",
			password: "",
			dobleFactor: false,
			idsTurnos: [],
			idsComercialLineas: [],
		},
		defaultItem: {
			idUsuario: null,
			idPersona: null,
			idRol: -1,
			nombre: "",
			email: "",
			password: "",
			dobleFactor: false,
			idsTurnos: [],
			idsComercialLineas: [],
		},
		turnos: [],
		loadingTurnos: false,
		lineas: [],
		loadingLineas: false,
	}),

	computed: {
      	...mapState(["pinType","pinLength","rfid"]),
		formTitle() {
			return this.editedIndex === -1 ? "Nuevo Usuario" : "Editar Usuario";
		},
	},

	watch: {
		dialog(val) {
			val || this.close();
		},
	},

	created() {
		this.initialize();
	},

	methods: {
		getNombre(item){
			if(!item.nombre){
				const result = this.personas.find( persona => persona.idPersona == item.idPersona );
				return result ? this.nombrePersona(result) : 'N/D';
			}
			return item.nombre
		},
		nombrePersona: ({ nombre, paterno, materno }) =>
			`${nombre ? `${nombre} ` : ""}${paterno ? `${paterno} ` : ""}${
				materno ? `${materno} ` : ""
			}`.trim(),
		cerrarDialog(){
			this.dialog = false;
			this.$nextTick(() => {
				this.errores = [];
				this.personasMantenimientoFiltred = [];
			});
		},
		showDialogResetPassword(item){
			this.editedItem = { ...item };
			this.dialogResetPassword = true;
		},
		showDialogUnlockAccount(item){
			this.editedItem = { ...item };
			this.dialogUnloackAccount = true;
		},
		showDialogDelete(item){
			this.editedItem = { ...item };
			this.dialogDelete = true;
		},
		cerrarDialogDelete(){
			this.dialogDelete = false;
			this.editedItem = { ...this.defaultItem };
		},
		eliminarUsuario(){
			this.deleting = true;
			axios
				.put(`/Usuarios/Eliminar/${this.editedItem.idUsuario}`)
				.then( () => {
					this.deleting = false;
					this.cerrarDialogDelete();
					this.initialize();
				})
				.catch( error => {
					this.deleting = false;
					console.log(error);
				})
		},
		validatePINRFID(){
			this.errores = [];
			if(this.pinViewed.pin.length != this.pinLength){
				this.errores.push(`El Pin debe de ser de ${this.pinLength} Dígitos`);
			}
			if(!this.rfid){
				var reg = /^\d+$/;
				if(!reg.test(this.pinViewed.pin)){
					this.errores.push("El Pin debe de estar formado solo por Números");
				}
			}

			return this.errores.length == 0;
		},
		savePINRFID(){
			if(!this.validatePINRFID()) return;
			this.saving = true;

			axios
				.put(`/Usuarios/Actualizar/${this.pinViewed.idUsuario}`,{
					idRol: this.pinViewed.idRol,
					nombre: this.pinViewed.nombre,
					dobleFactor: this.pinViewed.dobleFactor,
					idsTurnos: this.pinViewed.idsTurnos,
					idsComercialLineas: this.pinViewed.idsComercialLineas,
					pin: this.pinViewed.pin
				})
				.then( () => {
					this.saving = false;
					this.pin = false;
					this.dialogRFID = false;
					this.rfidsLeidos = [];
					this.percentRFID = 0;
					this.errores = [];
					this.pinViewed=Object.assign({}, this.defaultPinViewed);
				})
				.catch( error => {
					this.saving = false;
					if(error.response.status == 409){
						this.errores.push("Este PIN esta siendo usado por otro Usuario.");
					}
					console.log(error);
				})
		},
		rfidView(item){
			this.pinViewed = ({ 
				...item,
				...this.pinViewed
			});
			this.dialogRFID = true;
		},
		pinView(item){
			this.pinViewed = ({ 
				...item,
				...this.pinViewed
			});
			this.pin = true;
		},
		leerRFID(rfid){
			if ( this.$utils.strings.isOnlyNumbers(rfid) ){
				if ( this.rfidsLeidos.length < 3 ){
					if( this.rfidsLeidos.length === 0 ) {
						this.rfidsLeidos.push(rfid);
						this.percentRFID += 33;
					}
					else if ( this.rfidsLeidos.length === 1 ){
						if ( this.rfidsLeidos[0] == rfid ){
							this.rfidsLeidos.push(rfid);
							this.percentRFID += 33;
						}
					}
					else if ( this.rfidsLeidos.length === 2 ){
						if ( this.rfidsLeidos[1] == rfid ){
							this.rfidsLeidos.push(rfid);
							this.percentRFID += 34;
						}
					}
				}
			}
		},
		sugerirPin(){
			axios
			.get("/Personas/SugererPIN")
			.then(response => {
				this.pinViewed.pin = response.data;
			})
			.catch(error => {
				console.log(error);
			})
		},
		saveRFID(){
			this.pinViewed.pin = this.rfidsLeidos[0];
			this.savePINRFID();
		},
		initialize() {
			let me = this;
			me.getRoles();
			me.getTurnos();
			me.getComercialLineas();
			this.getOperadores();
			this.getPersonasMantenimiento();
			me.loading = true;
			axios
				.get("/Usuarios")
				.then((response) => {
          me.usuarios = response.data;
					me.loading = false;
				})
				.catch((error) => {
					console.log(error);
				});
		},
		getOperadores() {
			axios
				.get("/Personas")
				.then((response) => {
					this.personas = response.data;
				})
				.catch((error) => {
					console.log(error);
				});
		},
		getPersonasMantenimiento(){
			axios
				.get("/Usuarios/PersonasMantenimientoUsuario")
				.then( response => {
					this.personasMantenimiento = response.data;
				})
				.catch(console.log)
		},
		getTurnos() {
			this.loadingTurnos = true;
			axios
				.get("/Turnos")
				.then((response) => {
					this.turnos = response.data;
					this.loadingTurnos = false;
				})
				.catch((error) => {
					this.loadingTurnos = false;
					console.log(error);
				});
		},
		getComercialLineas() {
			this.loadingLineas = true;
			axios
				.get("/ComercialLineas")
				.then((response) => {
					this.lineas = response.data;
					this.loadingLineas = false;
				})
				.catch((error) => {
					this.loadingLineas = false;
					console.log(error);
				});
		},
		getRoles() {
			let me = this;
			me.loadingRoles = true;
			axios
				.get("/Permisos/Roles")
				.then((response) => {
					me.roles = response.data;
					me.loadingRoles = false;
				})
				.catch((error) => {
					console.log(error);
				});
		},
		editItem(item) {
      		this.action = 1;
			this.editedIndex = this.usuarios.indexOf(item);
			this.editedItem = Object.assign({}, item);
			const result = this.personasMantenimiento.find(persona => persona.idPersona == this.editedItem.idPersona);
			this.personasMantenimientoFiltred = !result 
				? [ 
					this.personas.find(persona => persona.idPersona == this.editedItem.idPersona),
					...this.personasMantenimiento
				]
				: this.personasMantenimiento.slice();
			this.dialog = true;
		},

		changeState(item) {
			let index = this.usuarios.indexOf(item);
			let me = this;
			this.usuarios[index].loading = true;
			if (!this.usuarios[index].condicion) {
				//Activar
				axios
					.put("/Usuarios/Activar/" + this.usuarios[index].idUsuario)
					.then((response) => {})
					.catch((error) => {
						console.log(error);
					});
			} else {
				axios
					.put(
						"/Usuarios/Desactivar/" + this.usuarios[index].idUsuario
					)
					.then((response) => {})
					.catch((error) => {
						console.log(error);
					});
			}
			this.usuarios[index].condicion = !this.usuarios[index].condicion;
		},
		close() {
			(this.action = 0), (this.dialog = false);
			setTimeout(() => {
				this.editedItem = Object.assign({}, this.defaultItem);
				this.editedItem.idsTurnos = [];
				this.editedItem.idsComercialLineas = [];
				this.editedIndex = -1;
			}, 300);
		},
		validate() {
			this.errores = [];
			if (this.editedItem.idRol <= 0) {
				this.errores.push("* Debe de Seleccionar un Rol");
			}

			if(this.editedItem.idPersona == null){
				if (
					this.editedItem.nombre.length < 5 ||
					this.editedItem.nombre.length > 50
				) {
					this.errores.push(
						"* El Nombre debe tener más de 4 caracteres y menos de 50."
					);
				}
			}
			
			if (
				this.editedItem.email.length < 5 ||
				this.editedItem.email.length > 50
			) {
				this.errores.push(
					"* El Email debe tener más de 4 caracteres y menos de 50."
				);
			}

			if (
				this.editedIndex < 0 &&
				(this.editedItem.password.length < 8 ||
					this.editedItem.password.length > 50)
			) {
				this.errores.push(
					"* La Contraseña debe tener más de 7 caracteres y menos de 50."
				);
			}
			return this.errores.length == 0;
		},
		resetPassword(){
			
			if(this.editedItem.idUsuario <= 0)
			return;
			else if (this.editedItem.idUsuario == null)
			return;

			axios
			.put("/Usuarios/RestablecerPassword/"+this.editedItem.idUsuario)
			.then(() => {
				this.dialogResetPassword = false;
				this.initialize();
				})
				.catch(() => {
					this.dialogResetPassword = false;					
				});
		},
		UnlockAccount(){
			
			if(this.editedItem.idUsuario <= 0)
			return;
			else if (this.editedItem.idUsuario == null)
			return;

			axios
			.put("/Usuarios/Desbloquear/"+this.editedItem.idUsuario)
			.then(() => {
				this.dialogUnloackAccount = false;
				
				this.initialize();
				})
				.catch(() => {
					me.dialogUnloackAccount = false;
					
				});
		},
		save() {
			this.action = 0;
			if (this.validate()) {
				let me = this;
				me.saving = true;
				if (this.editedIndex > -1) {
					//Código para editar
					axios
						.put(
							"/Usuarios/Actualizar/" +
								me.usuarios[me.editedIndex].idUsuario,
							{
								idRol: me.editedItem.idRol,
								nombre: me.editedItem.nombre || null,
								dobleFactor: me.editedItem.dobleFactor,
								idsTurnos: me.editedItem.idsTurnos,
                				idsComercialLineas: me.editedItem.idsComercialLineas,
								idPersona: this.editedItem.idPersona
							}
						)
						.then((response) => {
							me.saving = false;
							me.close();
							me.initialize();
						})
						.catch((error) => {
							me.saving = false;
							if (error.response) {
								if (error.response.status == 409) {
									me.alerta = true;
									me.errores.push(
										"Existe un Usuario con el mismo Email"
									);
								}
							}
						});
				} else {
					axios
						.post("/Usuarios/Crear", {
							idRol: me.editedItem.idRol,
							nombre: me.editedItem.nombre || null,
							email: me.editedItem.email,
							password: me.editedItem.password,
							dobleFactor: me.editedItem.dobleFactor,
							idsTurnos: me.editedItem.idsTurnos,
              				idsComercialLineas: me.editedItem.idsComercialLineas,
							idPersona: this.editedItem.idPersona
						})
						.then((response) => {
							me.saving = false;
							me.close();
							me.initialize();
						})
						.catch((error) => {
							me.saving = false;
							if (error.response) {
								if (error.response.status == 409) {
									me.alerta = true;
									me.errores.push(
										"Existe un Usuario con el mismo Email"
									);
								}
							}
						});
				}
			}
		},
	},
};
</script>

<style>
/*
.v-input--selection-controls.v-input--switch label{
    color: red;
}

.v-input--switch.v-input--is-label-active label{
    color: green !important;
}
*/
</style>