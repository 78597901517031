<template>
	<div
		class="d-flex flex-column align-center justify-center"
		style="height: 68vh"
	>
		<template v-if="loadingListaMaquinas">
			<v-progress-circular
				:size="70"
				:width="6"
				color="primary"
				indeterminate
			></v-progress-circular>
			<p class="text-h4 text--secondary">Cargando</p>
		</template>
		<template v-else-if="noHayMaquinas">
			<v-icon size="100" color="grey">mdi-magnify-close</v-icon>
			<p
				class="mb-0 text-center font-weight-bold"
				style="font-size: 2rem !important; color: #9e9e9e"
			>
				{{
					search
						? `No hay resultados para "${search}"`
						: "No hay datos disponibles"
				}}
			</p>
		</template>
	</div>
</template>

<script>
export default {
    props: {
        loadingListaMaquinas: {
            type: Boolean,
            default: false
        },
        noHayMaquinas: {
            type: Boolean,
            default: false
        },
        search: {
            type: String,
            default: null
        }
    }
};
</script>