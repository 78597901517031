<template>
	<v-row>
		<v-dialog v-model="dialogEliminar" persistent max-width="40%">
			<v-card>
				<v-card-title
					>¿Está seguro que desea eliminar este Subpaso?</v-card-title
				>
				<v-card-text
					>Esta acción no se puede revertir y será
					permanente.</v-card-text
				>
				<v-card-actions>
					<v-spacer></v-spacer>
					<v-btn color="blue darken-1" text @click="cerrarDialog()"
						>Cancelar</v-btn
					>
					<v-btn color="red darken-1" text @click="borrarSubpaso()"
						>Aceptar</v-btn
					>
				</v-card-actions>
			</v-card>
		</v-dialog>
		<v-dialog v-model="dialogSub" persistent max-width="40%">
			<v-card>
				<v-card-title
					>{{
						editedItem.idSubpaso > 0 ? "Editar" : "Agregar"
					}}
					Subpaso</v-card-title
				>
				<v-card-text>
					<v-row>
						<v-col cols="12" v-if="!editedItem.nuevoSubpaso">
							<v-autocomplete
								v-model="editedItem.idCatSubpaso"
								:items="catalogoSubpasosFiltrado"
								:item-text="nombreSubpaso"
								item-value="idCatSubpaso"
								label="Seleccione Subpaso del catálogo"
								:disabled="editedItem.nuevoSubpaso"
								:error-messages="errorCatSubpaso"
								@focus="errorCatSubpaso=''"
								clearable
							></v-autocomplete>
						</v-col>
						<v-col cols="12" class="d-flex justify-end pt-0" v-if="!editedItem.nuevoSubpaso">
							<a style="font-size: 14px" @click="nuevoSubpaso(true)">Crear nuevo subpaso</a>
						</v-col>
						<v-col cols="12" v-if="editedItem.nuevoSubpaso">
							<v-text-field
								v-model="editedItem.codigo"
								label="Código"
								:error-messages="errorCodigo"
								@focus="errorCodigo = ''"
							></v-text-field>
						</v-col>
						<v-col cols="12" v-if="editedItem.nuevoSubpaso">
							<v-text-field
								v-model="editedItem.descripcion"
								label="Descripción"
								:error-messages="errorDescripcion"
								@focus="errorDescripcion = ''"
							></v-text-field>
						</v-col>
						<v-col cols="12" class="d-flex justify-end pt-0" v-if="editedItem.nuevoSubpaso">
							<a style="font-size: 14px" @click="nuevoSubpaso(false)">Seleccionar subpaso del catálogo</a>
						</v-col>
						<v-col cols="12">
							<time-picker
								v-model="editedItem.tiempo"
								label="Tiempo del Subpaso (HH:MM:SS)"
								:error="errorTiempo"
							></time-picker>
						</v-col>
						<v-col cols="12" class="mt-4">
							<v-col cols="6" class="pa-0">
								<v-text-field
									v-model.number="editedItem.costoDestajo"
									type="number"
									:min="0"
									label="Costo Destajo"
									prefix="$"
									:error-messages="errorCostoDestajo"
									@focus="errorCostoDestajo = ''"
								></v-text-field>
							</v-col>
						</v-col>
						<v-col cols="12">
							<div class="ml-auto pb-0 d-flex align-center">
								<p
									class="mb-0 pt-2 mr-3 text--secondary"
									style="font-size: 16px !important"
								>
									¿En un Subpaso obligatorio?
								</p>
								<v-switch
									v-model="editedItem.obligatorio"
									color="success"
									hide-details
									class="mt-0"
								>
									<template v-slot:label>
										<p
											class="mb-0 text--secondary"
											style="font-size: 16px !important"
										>
											{{ editedItem.obligatorio ? "Si" : "No" }}
										</p>
									</template>
								</v-switch>
							</div>
						</v-col>
						<ul v-if="errorTiempo" class="pt-4">
							<li class="red--text">
								Debe indicar el tiempo del subpaso
							</li>
						</ul>
					</v-row>
				</v-card-text>
				<v-card-actions>
					<v-spacer />
					<v-btn color="success" text @click="cerrarDialog()"
						>Cancelar</v-btn
					>
					<v-btn color="error" text @click="guardarSubpaso()"
						>Guardar</v-btn
					>
				</v-card-actions>
			</v-card>
		</v-dialog>
		<v-col cols="12" class="pb-0 d-flex">
			<v-btn color="primary" @click="dialogSub = true"
				>Agregar Subpaso</v-btn
			>
			<div class="ml-auto pb-0 d-flex">
				<p
					class="mb-0 pt-6 mr-3 text--secondary"
					style="font-size: 16px !important"
				>
					¿Subpasos seriados?
				</p>
				<v-switch
					v-model="paso.subpasosSeriados"
					color="success"
					hide-details
					:disabled="paso.subpasos.length == 0"
				>
					<template v-slot:label>
						<p
							class="mb-0 text--secondary"
							style="font-size: 16px !important"
						>
							{{ paso.subpasosSeriados ? "Si" : "No" }}
						</p>
					</template>
				</v-switch>
			</div>
		</v-col>
		<v-col cols="12">
			<v-data-table
				:items="paso.subpasos"
				:headers="headersSubpasos"
				ref="ordersubs"
				:key="tablekey"
				disable-pagination
				hide-default-footer
				@hook:mounted="crearDragable"
				@hook:updated="crearDragable"
			>
				<template v-slot:item="{ item }">
					<tr class="sortableRow" :key="`row-${item.idSubpaso}`">
						<td class="px-1" style="width: 0.1%">
							<v-btn style="cursor: move" icon class="sortHandle"
								><v-icon color="black">mdi-menu</v-icon></v-btn
							>
						</td>
						<td class="text-center">
							{{ getCodeDesc(item,'codigo') }}
						</td>
						<td class="text-center">
							{{ getCodeDesc(item,'descripcion') }}
						</td>
						<td 
							class="text-center" 
							:class="
								(!item.valido && item.valido!=undefined) &&
								'red--text font-weight-bold'
							"
						>
							{{ getTiempoFormat(item.tiempo) }}
						</td>
						<td class="text-center">
							${{ item.costoDestajo.toFixed(2) }}
						</td>
						<td class="text-center">
							{{ getObligatorio(item.obligatorio) }}
						</td>
						<td class="text-center">
							<v-tooltip
								
								bottom
								color="black"
								class="white--text"
							>
								<template v-slot:activator="{ on }">
									<v-icon
										v-on="on"
										small
										class="mr-2"
										@click="editDialog(item)"
									>
										mdi-pencil
									</v-icon>
								</template>
								<span class="white--text">Editar</span>
							</v-tooltip>
							<v-tooltip
								
								bottom
								color="black"
								class="white--text"
							>
								<template v-slot:activator="{ on }">
									<v-icon
										v-on="on"
										small
										class="mr-2"
										@click="deleteDialog(item)"
									>
										mdi-delete
									</v-icon>
								</template>
								<span class="white--text">Eliminar</span>
							</v-tooltip>
						</td>
					</tr>
				</template>
			</v-data-table>
		</v-col>
		<v-col cols="12" v-if="!paso.subsvalidos">
			<ul>
				<li class="red--text" style="font-size: 14px !important">La suma de los tiempos de los Subpasos obligatorios no debe ser mayor al Tiempo Ideal</li>
			</ul>
		</v-col>
	</v-row>
</template>

<script>
import Sortable from "sortablejs";
import timepicker from '@/components/TimePicker';
import moment from 'moment';
import axios from 'axios';

export default {
	components: {
		'time-picker':timepicker
	},
	props: ["paso"],
	data() {
		return {
			errorCatSubpaso: "",
			catalogoSubpasos: [],
			headersSubpasos: [
				{
					sortable: false,
					width: "10%",
				},
				{
					text: "Código",
					align: "center",
					sortable: false,
					value: "codigo",
					width: "16%",
				},
				{
					text: "Subpaso",
					align: "center",
					sortable: false,
					value: "descripcion",
					width: "16%",
				},
				{
					text: "Tiempo",
					align: "center",
					sortable: false,
					value: "tiempo",
					width: "16%",
				},
				{
					text: "Costo Destajo",
					align: "center",
					sortable: false,
					value: "costoDestajo",
					width: "16%",
				},
				{
					text: "Obligatorio",
					align: "center",
					sortable: false,
					value: "obligatorio",
					width: "16%",
				},
				{
					text: "Acciones",
					align: "center",
					sortable: false,
					value: "actions",
					width: "10%",
				},
			],
			tablekey: 0,
			errorTiempo: 0,
			errorCodigo: "",
			errorDescripcion: "",
			errorCostoDestajo: "",
			dialogEliminar: false,
			dialogSub: false,
			editedItem: {
				idSubpaso: 0,
				idCatSubpaso: null,
				codigo: "",
				descripcion: "",
				posicion: 0,
				tiempo: 0,
				obligatorio: false,
				valido: true,
				costoDestajo: 0,
				nuevoSubpaso: false
			},
			defaultEditedItem: {
				idSubpaso: 0,
				idCatSubpaso: null,
				codigo: "",
				descripcion: "",
				posicion: 0,
				tiempo: 0,
				obligatorio: false,
				valido: true,
				costoDestajo: 0,
				nuevoSubpaso: false
			},
		};
	},
	mounted() {
		this.initialize();
	},
	computed:{
		catalogoSubpasosFiltrado(){
			const subpasos = this.paso.subpasos
										.filter(sub => sub.idCatSubpaso != this.editedItem.idCatSubpaso)
										.map( sub => sub.idCatSubpaso );
			return this.catalogoSubpasos.filter( catsub => !subpasos.includes(catsub.idCatSubpaso) );
		}
	},
	methods: {
		nombreSubpaso: (value) => `[${value.codigo}] - ${value.descripcion}`,
		getCodeDesc(item,value){
			if(item.idCatSubpaso!=null){
				//Es un subpaso del catalogo
				const result = this.catalogoSubpasos.find( catsub => catsub.idCatSubpaso == item.idCatSubpaso )
				return result != null ? result[value]: "N/A";
			}
			else{
				//Es un nuevo subpaso
				if(item[value] != '' && item[value] != null) return item[value];
				else return "N/A";
			}
		},
		getCatalogoSubpasos(){
			axios
				.get('/CatSubpaso')
				.then( response => {
					this.catalogoSubpasos = response.data;
				})
				.catch( error => {
					console.log(error);
				})
		},
		nuevoSubpaso(value){
			this.$set(this.editedItem,'nuevoSubpaso',value);
			// if(value){
				this.$set(this.editedItem,'idCatSubpaso',null);
			// }else{
				this.$set(this.editedItem,'codigo',null);
				this.$set(this.editedItem,'descripcion',null);
				this.errorCatSubpaso = "";
				this.errorCodigo = "";
				this.errorDescripcion = "";
			// }
		},
		getObligatorio(obligatorio){
			if(obligatorio==undefined) obligatorio = false;
			return obligatorio ? 'Si' : 'No';
		},
		calcularTiempos(){
			let suma = 0;
			this.paso.subpasos.forEach(subpaso => {
				if(subpaso.obligatorio == true) {
					suma += subpaso.tiempo;
					if(suma>this.paso.tiempoMin) subpaso.valido = false;
					else subpaso.valido = true;
				}else{
					subpaso.valido = true;
				}
			});

			if(suma > this.paso.tiempoMin){
				this.paso.subsvalidos = false;
			}else{
				this.paso.subsvalidos = true;
			}

			this.tablekey++;
		},
		noTrim: (num) => ("0"+num).slice(-2),
		getTiempoFormat(tiempo){
			if(tiempo == undefined) tiempo = 0;
			const hours = Math.floor(moment.duration(tiempo,'seconds').asHours());
			const minutes = moment.duration(tiempo,'seconds').minutes();
			const seconds = moment.duration(tiempo,'seconds').seconds();
			return this.noTrim(hours) + ':' + this.noTrim(minutes) + ':' + this.noTrim(seconds);
		},
		initialize() {
			this.getCatalogoSubpasos();
			this.paso.subsvalidos = this.paso.subsvalidos == undefined ? true : this.paso.subsvalidos;
			this.editedItem = Object.assign({}, this.defaultEditedItem);
			this.errorCodigo = "";
			this.errorDescripcion = "";
			this.errorCostoDestajo = "";
			this.errorCatSubpaso = "";
		},
		borrarSubpaso() {
			this.paso.subpasos = this.paso.subpasos.filter(
				(sub) => sub.idSubpaso != this.editedItem.idSubpaso
			);
			if(this.paso.subpasos.length==0){
				this.paso.subpasosSeriados = false;
			}
			this.recalcularPosiciones();
			this.cerrarDialog();
			this.calcularTiempos();
		},
		guardarSubpaso() {
			if (!this.validar()) return;

			if (this.editedItem.idSubpaso == 0) {
				//Crear
				const obj = Object.assign({}, this.editedItem);
				if (this.paso.subpasos != null) {
					//Obtener el ultimo idSubpaso
					const minId = Math.min.apply(
						Math,
						this.paso.subpasos.map((element) => element.idSubpaso)
					);
					obj.idSubpaso =
						(minId == Infinity || minId - 1 >= 0) ? -1 : minId - 1;

					//Obtener la ultima posicion
					const maxPos = Math.max.apply(
						Math,
						this.paso.subpasos.map((element) => element.posicion)
					);
					obj.posicion = maxPos == -Infinity ? 1 : maxPos + 1;
				}
				this.paso.subpasos.push(obj);
			} else {
				//Actualizar
				let indice = -1;
				this.paso.subpasos.forEach((sub, idx) => {
					if (sub.idSubpaso == this.editedItem.idSubpaso)
						indice = idx;
				});
				if (indice != -1) {
					this.$set(this.paso.subpasos,indice,Object.assign({},this.editedItem)); // REACTIVO
					// this.paso.subpasos[indice] = Object.assign(
					// 	{},
					// 	this.editedItem         // NO REACTIVO
					// );
					this.tablekey++;
					this.recalcularPosiciones();
				}
			}

			this.cerrarDialog();
			this.calcularTiempos();
		},
		validar() {
			this.errorCatSubpaso = "";
			this.errorCodigo = "";
			this.errorDescripcion = "";
			this.errorCostoDestajo = "";
			this.errorTiempo = false;

			if(this.editedItem.nuevoSubpaso){
				if (this.editedItem.codigo == "" || this.editedItem.codigo == null) {
					this.errorCodigo = "Debe introducir un código.";
				}
	
				if (this.editedItem.descripcion == "" || this.editedItem.descripcion == null) {
					this.errorDescripcion = "Debe introducir una descripción.";
				}
			}
			else{
				if(this.editedItem.idCatSubpaso == null){
					this.errorCatSubpaso = "Debe seleccionar un subpaso";
				}
			}

			if (this.editedItem.tiempo == 0) {
				this.errorTiempo = true;
			}

			if(isNaN(parseFloat(this.editedItem.costoDestajo)) || parseFloat(this.editedItem.costoDestajo) < 0){
				this.errorCostoDestajo = "Debe indicar una cantidad valida";
			}

			return (this.editedItem.nuevoSubpaso ? this.errorCodigo == "" && this.errorDescripcion == "" : this.errorCatSubpaso == "") &&
				this.errorCostoDestajo == "" &&
				!this.errorTiempo;
		},
		editDialog(item) {
			this.editedItem = Object.assign({}, item);
			this.dialogSub = true;
		},
		deleteDialog(item) {
			this.editedItem = Object.assign({}, item);
			this.dialogEliminar = true;
		},
		crearDragable() {
			new Sortable(
				this.$refs.ordersubs.$el.getElementsByTagName("tbody")[0],
				{
					draggable: ".sortableRow",
					handle: ".sortHandle",
					onEnd: this.dragReorder,
				}
			);
		},
		recalcularPosiciones() {
			this.paso.subpasos.forEach((sub, idx) => {
				sub.posicion = idx + 1;
			});
		},
		dragReorder({ oldIndex, newIndex }) {
			const movedItem = this.paso.subpasos.splice(oldIndex, 1)[0];
			this.paso.subpasos.splice(newIndex, 0, movedItem);
			//Recalcular posiciones
			this.recalcularPosiciones();
			this.calcularTiempos();
		},
		cerrarDialog() {
			this.dialogEliminar = false;
			this.dialogSub = false;
			this.editedItem = Object.assign({}, this.editedItem,this.defaultEditedItem);
			this.errorCodigo = "";
			this.errorDescripcion = "";
			this.errorCostoDestajo = "";
			this.errorCatSubpaso = "";
			this.errorTiempo = 0;
		},
	},
};
</script>

<style scoped>
.centered-input >>> input {
	text-align: center	
}
</style>