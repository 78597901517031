<template>
  <v-container>
    <v-dialog v-model="dialogWatch" max-width="900px">
      <v-card>
        <v-card-title> REGLAS DE {{ watchedItem.nombre }} </v-card-title>
        <v-card-text>
          <v-dialog v-model="eliminar" max-width="500px">
            <v-card>
              <v-card-title class="headline"
                >¿Está seguro que desea eliminar esta regla?</v-card-title
              >
              <v-card-text
                >Esta acción no se puede revertir y será
                permanente.</v-card-text
              >
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                  color="blue darken-1"
                  text
                  @click="
                    eliminar = false;
                    deletedItem = Object.assign({}, defaultItem);
                  "
                  >Cancelar</v-btn
                >
                <v-btn color="red darken-1" text @click="deleteItemDB()"
                  >Aceptar</v-btn
                >
              </v-card-actions>
            </v-card>
          </v-dialog>
          <v-data-table
            :items="reglas"
            :headers="headersReglas"
            :loading="loadingReglas"
            loading-text="Cargando... Espere, Por Favor."
          >
            <template v-slot:top>
              <v-toolbar flat color="white">
                <v-dialog
                  v-model="dialog"
                  persistent
                  max-width="500px"
                  :key="dialog"
                >
                  <template v-slot:activator="{ on }">
                    <v-btn color="primary" dark class="mb-2" v-on="on"
                      >Nueva Regla</v-btn
                    >
                  </template>
                  <v-card>
                    <v-card-title>
                      <span class="headline">{{ formTitle }}</span>
                    </v-card-title>
                    <v-card-text>
                      <v-row>
                        <v-col cols="12" md="12" sm="12">
                          <v-select
                            v-model="editedItem.idArea"
                            label="Área"
                            :items="areas"
                            item-text="nombre"
                            item-value="idArea"
                          >
                          </v-select>
                        </v-col>
                        <v-col cols="12" md="12" sm="12">
                          <v-textarea
                            v-model="editedItem.enunciado"
                            label="Enunciado"
                            rows="1"
                            auto-grow
                            dense
                          ></v-textarea>
                        </v-col>
                        <v-col cols="12" md="12" sm="12" class="ma-0 pa-0">
                          <switchx
                            v-model="editedItem.respuesta"
                            label="Respuesta Esperada"
                          ></switchx>
                        </v-col>
                        <v-col cols="12" md="12" sm="12" class="ma-0 pa-0">
                          <switchx
                            v-model="editedItem.scrapAutomatico"
                            label="Scrapt Automatico"
                            @input="cambioEstado"
                          ></switchx>
                        </v-col>
                        <v-col cols="12" md="12" sm="12" class="ma-0 pa-0">
                          <switchx
                            v-model="editedItem.retrabajoAutomatico"
                            v-if="!editedItem.scrapAutomatico"
                            label="Retrabajo Automatico"
                          ></switchx>
                        </v-col>
                      </v-row>
                      <ul>
                        <li class="red--text" v-for="ex in errores" :key="ex">
                          {{ ex }}
                        </li>
                      </ul>
                    </v-card-text>
                    <v-card-actions>
                      <v-spacer></v-spacer>
                      <v-btn color="blue darken-1" text @click="close()"
                        >Cancelar</v-btn
                      >
                      <v-btn
                        color="red darken-1"
                        text
                        @click="save()"
                        :disabled="saving"
                        :loading="saving"
                      >
                        Guardar
                        <template v-slot:loader>
                          <v-progress-circular
                            indeterminate
                            :width="2"
                            :size="24"
                            color="red"
                          ></v-progress-circular>
                        </template>
                      </v-btn>
                    </v-card-actions>
                  </v-card>
                </v-dialog>
                <v-spacer></v-spacer>
                <v-text-field
                  v-model="search"
                  append-icon="search"
                  label="Buscar"
                  single-line
                  hide-details
                ></v-text-field>
              </v-toolbar>
            </template>
            <template v-slot:item.respuesta="{ item }">
              <div v-if="item.respuesta" class="green--text">Sí</div>
              <div v-else class="red--text">No</div>
            </template>
            <template v-slot:item.scrapAutomatico="{ item }">
              <v-icon small>{{
                item.scrapAutomatico ? "mdi-check" : "mdi-close"
              }}</v-icon>
            </template>
            <template v-slot:item.retrabajoAutomatico="{ item }">
              <v-icon small>{{
                item.retrabajoAutomatico ? "mdi-check" : "mdi-close"
              }}</v-icon>
            </template>
            <template v-slot:item.respuesta="{ item }">
              <div v-if="item.respuesta" class="green--text">Sí</div>
              <div v-else class="red--text">No</div>
            </template>
            <template v-slot:item.action="{ item }">
              <v-tooltip bottom color="black" class="white--text">
                <template v-slot:activator="{ on }">
                  <v-icon v-on="on" small class="mr-2" @click="editItem(item)">
                    edit
                  </v-icon>
                </template>
                <span class="white--text">Editar</span>
              </v-tooltip>
              <v-tooltip bottom color="black" class="white--text">
                <template v-slot:activator="{ on }">
                  <v-icon v-on="on" small @click="deleteItem(item)">
                    delete
                  </v-icon>
                </template>

                <span class="white--text">Eliminar</span>
              </v-tooltip>
            </template>
            <template v-slot:no-data>
              <v-btn color="primary" @click="initialize">Refrescar</v-btn>
            </template>
          </v-data-table>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text color="blue" @click="closeWatch">Cerrar</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-data-table
      :headers="headers"
      :items="articulos"
      :search="search"
      :loading="loading"
      loading-text="Cargando... Espere, Por Favor."
    >
      <template v-slot:top>
        <v-toolbar flat color="white">
          <v-text-field
            v-model="search"
            append-icon="search"
            label="Buscar"
            single-line
            hide-details
          ></v-text-field>
        </v-toolbar>
      </template>
      <template v-slot:item.action="{ item }">
        <v-tooltip bottom color="black" class="white--text">
          <template v-slot:activator="{ on }">
            <v-icon v-on="on" small class="mr-2" @click="watchItem(item)">
              mdi-eye
            </v-icon>
          </template>
          <span class="white--text">Ver</span>
        </v-tooltip>
      </template>
      <template v-slot:no-data>
        <v-btn color="primary" @click="initialize">Refrescar</v-btn>
      </template>
    </v-data-table>
  </v-container>
</template>

<script>
import axios from "axios";
import switchx from "@/components/Switchx";

export default {
  components: {
    switchx: switchx,
  },
  props: {
    idArticuloTipo: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      search: "",
      loading: false,
      articulos: [],
      headers: [
        {
          text: "Artículo",
          align: "left",
          sort: true,
          value: "nombre",
        },
        {
          text: "Acciones",
          value: "action",
          sortable: false,
          width: "25%",
          align: "center",
        },
      ],
      headersReglas: [
        {
          text: "Área",
          align: "center",
          sortable: true,
          value: "area",
          width: "12%",
        },
        {
          text: "Enunciado",
          align: "left",
          sortable: false,
          value: "enunciado",
          width: "50%",
        },
        {
          text: "Respuesta Esperada",
          align: "center",
          sortable: true,
          value: "respuesta",
        },
        {
          text: "Scrapt Automatico",
          align: "center",
          sortable: true,
          value: "scrapAutomatico",
        },
        {
          text: "Retrabajo Automatico",
          align: "center",
          sortable: true,
          value: "retrabajoAutomatico",
        },
        {
          text: "Acciones",
          value: "action",
          sortable: false,
          width: "10%",
          align: "center",
        },
      ],
      watchedItem: {
        idArticulo: -1,
        nombre: "",
      },
      defaultWatchItem: {
        idArticulo: -1,
        nombre: "",
      },
      watchedIndex: -1,
      dialogWatch: false,
      defaultItem: {
        idRegla: -1,
        idArea: -1,
        enunciado: "",
        respuesta: true,
        scrapAutomatico: false,
        retrabajoAutomatico: false,
      },
      deletedItem: {
        idRegla: -1,
        idArea: -1,
        enunciado: "",
        respuesta: true,
        scrapAutomatico: false,
        retrabajoAutomatico: false,
      },
      editedItem: {
        idRegla: -1,
        idArea: -1,
        enunciado: "",
        respuesta: true,
        scrapAutomatico: false,
        retrabajoAutomatico: false,
      },
      editedIndex: -1,
      reglas: [],
      loadingReglas: false,
      dialog: false,
      errores: [],
      eliminar: false,
    };
  },
  mounted() {
    this.initialize();
  },
  computed: {
    formTitle() {
      return this.editedIndex === -1 ? "Nueva Regla" : "Editar Regla";
    },
  },
  methods: {
    initialize() {
      this.loading = true;
      axios
        .get("/Articulos/Tipo/" + this.idArticuloTipo + "?precio=false")
        .then((response) => {
          this.articulos = response.data;
          this.loading = false;
        })
        .catch((error) => {
          this.loading = false;
          console.log(error);
        });
    },
    watchItem(item) {
      this.watchedItem = Object.assign({}, item);
      this.watchedIndex = this.articulos.indexOf(item);
      this.dialogWatch = true;
      this.loadingReglas = true;
      this.recargarReglas();
    },
    recargarReglas() {
      axios
        .get("/Calidad/ReglasArticulo/" + this.watchedItem.idArticulo)
        .then((response) => {
          this.reglas = response.data.reglas;
          this.areas = response.data.areas;
          this.loadingReglas = false;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    cambioEstado() {
      if (this.editedItem.scrapAutomatico) {
        this.editedItem.retrabajoAutomatico = false;
      }
    },
    validate() {
      this.errores = [];
      if (this.editedItem.idArea <= 1) {
        this.errores.push("Se debe de seleccionar un Área");
      }

      if (this.editedItem.enunciado.length < 10) {
        this.errores.push("El Enunciado debe de tener al ménos 10 caracteres");
      }

      return this.errores.length == 0;
    },
    save() {
      if (this.validate()) {
        this.saving = true;

        var obj = {
          IdArticulo: this.watchedItem.idArticulo,
          IdReceta: null,
          IdPaso: null,
          IdArea: this.editedItem.idArea,
          Enunciado: this.editedItem.enunciado,
          Respuesta: this.editedItem.respuesta,
          ScrapAutomatico: this.editedItem.scrapAutomatico,
          RetrabajoAutomatico: this.editedItem.retrabajoAutomatico,
        };

        if (this.editedIndex == -1) {
          axios
            .post("/Calidad/CrearReglaArticulo", obj)
            .then((response) => {
              this.saving = false;
              this.close();
              this.recargarReglas();
            })
            .catch((error) => {
              this.saving = false;
              console.log(error);
            });
        } else {
          axios
            .put(
              "/Calidad/ActualizarReglaArticulo/" + this.editedItem.idRegla,
              obj
            )
            .then((respose) => {
              this.saving = false;
              this.close();
              this.recargarReglas();
            })
            .catch((error) => {
              this.saving = false;
              console.log(error);
            });
        }
      }
    },
    close() {
      this.editedIndex = -1;
      this.editedItem = Object.assign({}, this.defaultItem);
      this.dialog = false;
    },
    closeWatch() {
      this.watchedItem = Object.assign({}, this.defaultWatchItem);
      this.watchedIndex = -1;
      this.dialogWatch = false;
      this.loadingReglas = false;
      this.reglas = [];
    },
    editItem(item) {
      this.editedIndex = this.reglas.indexOf(item);
      this.editedItem = Object.assign({}, this.reglas[this.editedIndex]);
      this.dialog = true;
    },
    deleteItem(item) {
      this.deletedItem = item;
      this.eliminar = true;
    },
    deleteItemDB(item) {
      axios
        .put("/Calidad/DesactivarRegla/" + this.deletedItem.idRegla)
        .then((response) => {
          this.deletedItem = Object.assign({}, this.defaultItem);
          this.eliminar = false;
          this.recargarReglas();
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },
};
</script>