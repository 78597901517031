<template>
	<v-col cols="12" v-if="permisoVista('precioventapublico', 'r')">
		<v-dialog
			v-model="cargaMasiva"
			persistent
			max-width="500px"
			key="cargaMasiva"
		>
			<v-card>
				<v-card-title
					>Carga Masiva de Precios de Venta al Público</v-card-title
				>
				<v-card-text v-if="errorCargaMasiva">
					<v-col cols="12" md="12" sm="12" class="text-center">
						<h5>Se ha producido un error. Intente una vez más.</h5>
					</v-col>
					<v-col cols="12" md="12" sm="12" class="text-center">
						<v-btn
							depressed
							color="red"
							text
							@click="
								cargaMasiva = false;
								ficheroCarga = null;
								cargaCargaCompleta = false;
								loadingCargaMasiva = false;
								errorCargaMasiva = false;
								selectedUpdateFile = null;
							"
							>Cerrar</v-btn
						>
					</v-col>
				</v-card-text>
				<v-card-text v-if="cargaCargaCompleta">
					<v-col cols="12" md="12" sm="12" class="text-center">
						<h4>Carga Realizada Correctamente</h4>
					</v-col>
					<v-col cols="12" md="12" sm="12" class="text-center">
						<v-btn
							depressed
							color="success"
							text
							@click="
								cargaMasiva = false;
								ficheroCarga = null;
								cargaCargaCompleta = false;
								loadingCargaMasiva = false;
								errorCargaMasiva = false;
								selectedUpdateFile = null;
							"
							>Cerrar</v-btn
						>
					</v-col>
				</v-card-text>
				<v-card-text v-if="loadingCargaMasiva && !cargaCargaCompleta">
					<v-col cols="12" md="12" sm="12" class="text-center">
						<h4>Cargando</h4>
					</v-col>
					<v-col cols="12" md="12" sm="12" class="text-center">
						<v-progress-circular
							:size="75"
							color="green"
							indeterminate
						></v-progress-circular>
					</v-col>
				</v-card-text>
				<v-card-text
					v-if="
						!loadingCargaMasiva &&
						!cargaCargaCompleta &&
						!errorCargaMasiva
					"
					class="text-center"
				>
					<v-file-input
						v-model="ficheroCarga"
						accept=".csv"
						@change="cambioficheroCompras"
						show-size
						label="Fichero Carga .CSV"
					></v-file-input>

					<br />
					<br />
					<v-row>
						<v-spacer></v-spacer>
						<v-btn
							color="blue darken-1"
							text
							@click="
								cargaMasiva = false;
								ficheroCarga = null;
								cargaCargaCompleta = false;
								loadingCargaMasiva = false;
								errorCargaMasiva = false;
								selectedUpdateFile = null;
							"
							>Cancelar</v-btn
						>
						<v-btn
							color="red darken-1"
							text
							@click="cargaMasivaPost"
							>Aceptar</v-btn
						>
					</v-row>
				</v-card-text>
			</v-card>
		</v-dialog>
		<v-dialog v-model="dialogVenta" persistent max-width="30%">
			<v-card>
				<v-card-title>Definir Precio de Venta al Público</v-card-title>
				<v-card-text>
					<v-row class="d-flex justify-center">
						<v-col cols="8" md="8" sm="8">
							<v-text-field
								v-model="costo"
								label="Precio de Venta"
								type="number"
								:min="0"
								prefix="$"
								:disabled="loadingVenta"
								:rules="[rules.numValido]"
							></v-text-field>
						</v-col>
					</v-row>
				</v-card-text>
				<v-card-actions>
					<v-spacer></v-spacer>
					<v-btn
						text
						color="red"
						:disabled="loadingVenta"
						@click="cerrarDialogVenta"
						>Cancelar</v-btn
					>
					<v-btn
						text
						color="green"
						:disabled="
							loadingVenta ||
							isNaN(parseFloat(costo)) ||
							parseFloat(costo) <= 0
						"
						:loading="loadingVenta"
						@click="guardarDialogVenta"
						>Guardar</v-btn
					>
				</v-card-actions>
			</v-card>
		</v-dialog>
		<v-row>
			<v-col cols="12" class="d-flex align-center pt-0">
				<v-col cols="6" class="mr-auto">
					<v-text-field
						v-model="search"
						append-icon="search"
						label="Buscar por código o artículo"
						single-line
						hide-details
                        @keyup="enterSearch"
                        @click:clear="clearSearch"
						clearable
						:disabled="loading"
					></v-text-field>
				</v-col>
				<download-btn
					v-if="
						permisoVista('precioventapublico', 'r') &&
						permisoVista('precioventapublico', '$')
					"
					text
					color="primary"
					className="mr-3"
					v-on="on"
					@click="descargarFicheroCompras"
					label="Descargar Reporte<br>Precios de Venta"
				></download-btn>
				<v-btn
					v-if="permisoVista('precioventapublico', 'u')"
					text
					color="primary"
					class="mr-3"
					v-on="on"
					:disabled="loadingSubir || loadingDescargar"
					:loading="loadingSubir"
					@click="cargaMasiva = true"
				>
					Carga Masiva <br />Precios de Venta
				</v-btn>
			</v-col>
		</v-row>
		<v-row>
			<v-col cols="12">
				<v-data-table
					:items="precios"
					:headers="headersPreciosFiltrados"
					:loading="loading"
                    loading-text="Cargando... Espere, Por Favor."
					:search="search"
                    :server-items-length="precios_total"
                    :items-per-page="precios_items_per_page"
                    :page="precios_actual_page"
                    @update:page="getPreciosVentasAction"
                    @update:items-per-page="(ipp) => (precios_items_per_page = ipp)"
                    :footer-props="{
                        disableItemsPerPage: true,
                        disablePagination: this.loading,
                    }"
                    :disable-sort="true"
                    :disable-pagination="loading"
				>
					<template v-slot:item.precio="{ item }">
						$ {{ item.precio.toFixed(2) }}
					</template>
					<template v-slot:item.actions="{ item }">
						<v-tooltip
							v-if="
								permisoVista('precioventapublico', 'u') &&
								permisoVista('precioventapublico', '$')
							"
							bottom
							color="black"
							class="white--text"
						>
							<template v-slot:activator="{ on }">
								<v-icon
									v-on="on"
									small
									@click="costoVenta(item)"
									>edit</v-icon
								>
							</template>
							<span class="white--text">Editar</span>
						</v-tooltip>
					</template>
				</v-data-table>
			</v-col>
		</v-row>
	</v-col>
</template>

<script>
import axios from "axios";
import moment from "moment";
export default {
	data() {
		return {
			search: null,
			precios: [],
            precios_actual_page: 1,
            precios_items_per_page: 10,
            precios_total: null,
			loadingSubir: false,
			loadingDescargar: false,
			cargaMasiva: false,
			ficheroCarga: null,
			cargaCargaCompleta: false,
			loadingCargaMasiva: false,
			errorCargaMasiva: false,
			selectedUpdateFile: null,
			headersCostos: [
				{
					text: "Código",
					align: "center",
					value: "codigo",
				},
				{
					text: "Artículo",
					align: "center",
					value: "articulo",
				},
				{
					text: "Precio de Venta",
					align: "center",
					value: "precio",
				},
				{
					text: "Acciones",
					align: "center",
					value: "actions",
				},
			],
			defaultItemVenta: {
				idArticulo: -1,
				fecha: null,
				costo: 0,
			},
			itemVenta: {
				idArticulo: -1,
				fecha: null,
				costo: 0,
			},
			loadingVenta: false,
			costo: 0,
			dialogVenta: false,
			loading: false,
			rules: {
				numValido: (num) => {
					if (!isNaN(parseFloat(num)) && num > 0) return true;
					return "Invalido.";
				},
			},
		};
	},
	mounted() {
		this.initialize();
	},
	computed: {
		headersPreciosFiltrados() {
			return this.permisoVista("precioventapublico", "$")
				? this.headersCostos
				: this.headersCostos.filter((head) => head.value != "precio");
		},
	},
	methods: {
        enterSearch({code}){
            if(code == "Enter") this.getPreciosVentasAction(1);
        },
        clearSearch(){
            this.search = null;
            this.getPreciosVentasAction(1);
        },
		guardarDialogVenta() {
			this.loadingVenta = true;
			this.itemVenta.costo = parseFloat(this.costo);
			axios
				.post("/Articulos/ArticuloCostoVenta", this.itemVenta)
				.then(() => {
					this.loadingVenta = false;
					this.dialogVenta = false;
					this.costo = 0;
					this.getPreciosVentasAction(this.precios_actual_page);
				})
				.catch((error) => {
					this.loadingVenta = false;
					console.log(error);
				});
		},
		cerrarDialogVenta() {
			this.dialogVenta = false;
			this.costo = 0;
			this.itemVenta = Object.assign({}, this.defaultItemVenta);
		},
		costoVenta(item) {
			this.itemVenta = Object.assign({}, this.defaultItemVenta);
			this.itemVenta.idArticulo = item.idArticulo;
			this.costo = item.precio;
			this.dialogVenta = true;
		},
		descargarFicheroCompras() {
			var datetime = moment().format("YYYYMMDDHHmmss");
			this.$utils.axios.downloadFile(
				"/Articulos/CsvPrecioVenta",
				"GET",
				"reporte-precio-venta-publico-" + datetime + ".csv",
				"text/csv"
			);
		},
		cargaMasivaPost() {
			this.loadingCargaMasiva = true;
			if (this.ficheroCarga !== null && this.ficheroCarga !== undefined) {
				let formData = new FormData();
				formData.append("reportePrecios", this.ficheroCarga);
				axios
					.post("/Articulos/CsvprecioVentaRegistro", formData, {
						headers: {
							"Content-Type": "multipart/form-data",
						},
					})
					.then(() => {
						this.cargaCargaCompleta = true;
						this.loadingCargaMasiva = false;
						this.getPreciosVentasAction(this.precios_actual_page);
					})
					.catch((error) => {
						console.log(error);
						this.errorCargaMasiva = true;
						this.loadingCargaMasiva = false;
					});
			}
		},
		cambioFicheroCompras(event) {
			if (typeof event === undefined || event === null)
				this.ficheroCarga = null;
			else this.ficheroCarga = event;
		},
		initialize() {
			this.getPreciosVentasAction(1);
		},
		getPreciosVentas() {
            return new Promise( (resolve,reject) => {
                axios
                    .get("/Ventas/ListarPrecioVentaArticulo",{
                        params:{
                            paginar: true,
                            size: this.precios_items_per_page,
                            page: this.precios_actual_page,
                            nombreCodigo: this.search
                        }
                    })
                    .then((response) => resolve(response))
                    .catch((error) => reject(error))
            });
		},
        getPreciosVentasAction(page){
            this.loading = true;
            this.precios_actual_page = page;
            this.getPreciosVentas()
                .then( response => {
                    this.precios = response.data.result;
                    this.precios_total = response.data.total;
                })
                .catch( error => {
                    console.log(error);
                })
                .finally( () => {
                    this.loading = false;
                })
        }
	},
};
</script>