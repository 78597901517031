<template>
  <v-row>
    <v-col cols="12">
      <v-card 
        elevation="0"
        fill
        class="mt-4"
        v-if="notificacion"
      >
      <v-alert dense type="error" icon="mdi-web-sync" >
          <v-row align="center">
            <v-col class="grow">
              <span v-if="segundosresponse  == -1" >{{fecha}}</span>
              <span v-else>
                No se han recibido datos del reloj checador desde {{fecha}}. Por favor, revise el equipo encargado de la sincronización.
              </span>
            </v-col>
          </v-row>
        </v-alert>
      </v-card>
    </v-col>
    <v-col cols="4">
      <datePicker
        v-model="inicio"
        format="YYYY-MM-DD"
        label="Desde (Obligatorio)"
        clearable
        maxToday
        @input="historial_update_page_action(1)"
      ></datePicker>
    </v-col>
    <v-col cols="4">
      <datePicker
        v-model="fin"
        format="YYYY-MM-DD"
        label="Hasta (Obligatorio)"
        clearable
        maxToday
        @input=" historial_update_page_action(1)"
      ></datePicker>
    </v-col>
    <v-col cols="4">
      <v-autocomplete
        v-model="idEmployee"
        :items="itemsEmployee"
        item-value="idPersona"
        :item-text="nombrePersona"
        @input="historial_update_page_action(1)"
        clearable
        label="Empleado (Obligatorio para Descarga de Reporte)"
      ></v-autocomplete>
    </v-col>
    <v-col cols="12">
      <DownloadBtn
        text
        color="primary"
        className="my-0"
        @click="descargarReporte"
        label="Descargar Reporte por usuario"
        :disabled="this.inicio == null || this.fin == null|| this.idEmployee == null ? true : false"
      />
    </v-col>
    <v-col cols="12"
      ><v-data-table
        dense
        :headers="headers"
        :items="desserts"
        item-key="name"
        :loading="load"
        loading-text="Cargando... Espere por favor"
        :server-items-length="historial_total"
        :items-per-page="historial_items_per_page"
        :page="historial_actual_page"
        @update:page="historial_update_page_action"
        @update:items-per-page="(ipp) => (historial_items_per_page = ipp)"
        @update:sort-by="historial_sort_by_action"
        :footer-props="{
          disableItemsPerPage: true,
          disablePagination: this.load,
        }"
        :disable-sort="true"
      >
      </v-data-table></v-col
    >
  </v-row>
</template>

<script>
import axios from "axios";
import { mapState } from 'vuex';
import moment from "moment";
import DownloadBtn from "../../DownloadBtn.vue";
export default {
  components: {
    DownloadBtn,
  },
  data: () => ({
    historial_total: 0,
    historial_items_per_page: 10,
    historial_actual_page: 1,
    desserts: [],
    headers: [
      { text: "Empleado", value: "persona" },
      { text: "Método de Autentificación", value: "metodo" },
      { text: "Evento", value: "evento" },
      { text: "Fecha", value: "fecha" },
    ],
    load: false,
    inicio: null,
    fin: null,
    itemsEmployee: [],
    idEmployee: null,
    btnDounwload: false,
    fecha: null,
    mensaje: null,
    notificacion: true,
    segundos: 300,
    segundosresponse : null
  }),
  mounted() {
    this.initialize();
  },
  created(){
    this.ultimaSincronizacion()
  },
  computed: {
    historial_fetch_url() {
      return (
        `/Reportes/GetReportesRelojChecador?paginar=true&page=${this.historial_actual_page}&size=${this.historial_items_per_page}` +
        this.historial_filtros_url
      );
    },
    historial_filtros_url() {
      var filtro = "";
      if (this.idEmployee != null) filtro += `&IdPersona=${this.idEmployee}`;
      if (this.inicio != null) filtro += `&Inicio=${this.inicio}`;
      if (this.fin != null) filtro += `&Fin=${this.fin}`;

      return filtro;
    },
    ...mapState(["token"])
  },
  methods: {
    nombrePersona: (persona) => //Agregado para funcionamiento del filtro empleados
			persona.nombre + " " + persona.paterno + " " + persona.materno,
    initialize() {
      //this.getData();
      this.getOperadores();
      //this.historial_update_page_action(1);
    },
    dateSet(date) {
      return moment(date).format("YYYY-MM-DD HH:mm:ss");
    },
    getOperadores() {
      axios
        .get("/Personas/OperadoresBasico")
        .then((response) => {
          this.itemsEmployee = response.data;
          //console.log(this.itemsEmployee);
        })
        .catch((error) => {
          Error.log(error);
        });
    },
    contenidofiltros() {
      return this.inicio != null && this.fin != null;
    },
    async historial_update_page_action(page) {
    this.historial_actual_page = page;
    if (this.contenidofiltros()) {
      this.load = true;
      this.btnDounwload = false;
      try {
        let response = await this.getHistorial();
        this.desserts = response.data.result;
        this.historial_total = response.data.total;
        this.load = false;
        this.btnDounwload = true;
      } catch (exception) {
        console.error(exception);
      }
    } else {
      console.log("Filtros incompletos");
    }
  },
    getHistorial() {
      return new Promise((resolve, reject) => {
        axios
          .get(this.historial_fetch_url)
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },
    historial_sort_by_action($sort) {},

    descargarReporte() {
      var datetime = moment().format("YYYYMMDDHHmmss");
      
      let url = `/Reportes/XlsReporteUsuarioChecador?access_token=${this.token}`;
      if(this.inicio != null ) url += `&fechaInicio=${this.inicio}`;
      if(this.fin != null ) url += `&fechaFin=${this.fin}`;
      if(this.idEmployee != null ) url += `&IdPersona=${this.idEmployee}`;

      this.$utils.axios.downloadFile(
        url,
        "GET",
        "reporte-relojCheador-" + datetime + ".xlsx",
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
      );
    },
    ultimaSincronizacion() {
      axios
        .get("/RelojChecador/UltimaSincronizacion")
        .then(response => {
        this.segundos = 300;
        this.fecha = response.data.fecha;
        this.segundosresponse = response.data.minutos;
        
        if(response.data.minutos > 15 || response.data.minutos == -1)
          this.notificacion = true;
        else
          this.notificacion = false;
      })
      .catch(error => {
        console.log(error);
      });
    },
  },
};
</script>
