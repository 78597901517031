<template>
	<v-card flat>
		<v-card-text v-if="errorCargaPantografo">
			<v-col cols="12" md="12" sm="12" class="text-center">
				<h3>Se ha producido un Error. Intente una vez más.</h3>
			</v-col>
			<v-col cols="12" md="12" sm="12" class="text-center">
				<v-btn
					depressed
					color="red"
					text
					@click="
						uploadPantografo = false;
						ficheroCarga = null;
						cargaCompletada = false;
						loadingCargaPantografo = false;
						errorCargaPantografo = false;
						selectedUpdateFile = null;
					"
					>Aceptar</v-btn
				>
			</v-col>
		</v-card-text>
		<v-card-text v-if="cargaCompletada">
			<v-col cols="12" md="12" sm="12" class="text-center">
				<h3 v-if="cargaLink === ''">
					Carga Realizada Satisfactoriamente
				</h3>
				<div v-else>
					<h3>Carga Realizada Parcialmente</h3>
					<a
						:href="
							'/api/Articulos/ReporteCargaMasiva/' +
							cargaLink +
							'?access_token=' +
							token
						"
						target="_blank"
						>Archivo de Reporte</a
					>
				</div>
			</v-col>
			<v-col cols="12" md="12" sm="12" class="text-center">
				<v-btn
					depressed
					color="green"
					text
					@click="
						uploadPantografo = false;
						ficheroCarga = null;
						cargaCompletada = false;
						loadingCargaPantografo = false;
						errorCargaPantografo = false;
						selectedUpdateFile = null;
					"
					>Aceptar</v-btn
				>
			</v-col>
		</v-card-text>
		<v-card-text v-if="loadingCargaPantografo && !errorCargaPantografo">
			<v-col cols="12" md="12" sm="12" class="text-center">
				<h3>Cargando</h3>
			</v-col>
			<v-col cols="12" md="12" sm="12" class="text-center">
				<v-progress-circular
					:size="75"
					color="green"
					indeterminate
				></v-progress-circular>
			</v-col>
		</v-card-text>
		<v-card-text
			v-if="!loadingCargaPantografo && !cargaCompletada && !errorCargaPantografo && !checkSalidasAuto"
			class="text-center"
		>
			<p class="text-h6 text--secondary text-left">
				Carga Masiva de Pantógrafo
			</p>
			<v-row>
				<v-col cols="6">
					<v-autocomplete
						v-model="idArticulo"
						:items="articulos"
						item-value="idArticulo"
						:item-text="nombreArticulo"
						label="Artículo"
						clearable
						hide-details
					></v-autocomplete>
				</v-col>
				<v-col cols="6">
					<v-file-input
						v-model="selectedUpdateFile"
						:disabled="idArticulo==null"
						accept=".cnc"
						@change="cambioficheroCarga"
						counter
						show-size
						clearable
						label="Fichero Carga .CNC"
					></v-file-input>
				</v-col>
			</v-row>
			<!-- <a
				:href="
					'/api/Inventario/PlantillaControlInventario?access_token=' +
					token
				"
				target="_blank"
				class="caption"
			>
				<v-icon color="blue">mdi-download</v-icon>Descargar Plantilla de
				Carga Masiva de Pantógrafo
			</a> -->
			<br />
			<br />
			<v-row>
				<v-col cols="12" class="py-0">
					<v-btn
						block
						color="blue"
						outlined
						:disabled="idArticulo==null||selectedUpdateFile==null"
						class="my-2 mb-4"
						@click="cargaMasivaInventarioPost"
						>Cargar Archivo</v-btn
					>
				</v-col>
			</v-row>
		</v-card-text>
		<v-dialog v-model="checkSalidasAuto" persistent max-width="40%">
			<v-card>
				<v-card-title>Salidas Automáticas de Artículos</v-card-title>	
				<v-card-text>
					<v-col cols="12" class="text-center">
						<v-data-table
							:items="salidas"
							:headers="headersSalidas"
							:items-per-page="-1"
							hide-default-footer
						>
							<template v-slot:header.salida="{ header }">
								<div class="d-flex justify-center align-center">
									<v-checkbox
										v-model="chkAll"
										color="primary"
										class="mt-0"
										hide-details
										@change="selectAll($event)"
									></v-checkbox>
									<p class="mb-0 pt-1" style="font-size: 17px !important">{{ header.text }}</p>
								</div>
							</template>
							<template v-slot:item.salida="{ item }">
								<div class="d-flex justify-center">
									<v-switch
										v-model="item.salida"
										color="success"
									>
										<template v-slot:label>
											<p class="mb-0 text--primary">{{item.salida?'Activada':'Desactivada'}}</p>
										</template>
									</v-switch>
								</div>
							</template>
						</v-data-table>
					</v-col>
				</v-card-text>
				<v-card-actions>
					<v-spacer></v-spacer>
					<v-btn
						text 
						color="success" 
						@click="cerrarDialogSalidas()" 
						:disabled="savingSalidas" 
						>Cancelar</v-btn
					>
					<v-btn
						text 
						color="error" 
						@click="guardarSalidas()" 
						:disabled="savingSalidas" 
						:loading="savingSalidas"
						>Aceptar</v-btn
					>
				</v-card-actions>
			</v-card>
		</v-dialog>
	</v-card>
</template>

<script>
import axios from "axios";
import { mapState } from "vuex";
export default {
	data() {
		return {
			chkAll: false,
			actualizacionMasiva: false,
			loadingActualizacionMasiva: false,
			ficheroCarga: null,
			cargaActualizacionCompleta: false,
			selectedUpdateFile: null,
			errorActualizacionMasiva: false,
			cargaMasiva: false,
			loadingCargaPantografo: false,
			ficheroCarga: null,
			cargaCompletada: false,
			selectedCreateFile: null,
			errorCargaPantografo: false,
			cargaLink: "",
			updateLink: "",
			articulos: [],
			idArticulo: null,
			checkSalidasAuto: false,
			salidas: [],
			headersSalidas: [
				{
					text: "Artículo",
					align: "center",
					sortable: false,
					value: "articulo",
				},
				{
					text: "Salida Automática",
					align: "center",
					sortable: false,
					value: "salida",
				}
			],
			savingSalidas: false,
		};
	},
	computed: {
		...mapState({
			token: "token",
            pantografo: "pantografo"
		}),
	},
	mounted() {
		this.initialize();
	},
	methods: {
		nombreArticulo: value => value.codigo!=null?`[${value.codigo}] - ${value.nombre}`:`${value.nombre}`,
		cerrarDialogSalidas(){
			this.savingSalidas = false;
			this.checkSalidasAuto = false;
			this.chkAll = false;
		},
		selectAll(event){
			this.salidas.forEach( salida => {
				salida.salida = !!event;
			});
		},
		initialize() {
			this.resetValues();
			this.getArticulos();
		},
		guardarSalidas(){
			this.savingSalidas = true;
			this.salidas.forEach(salida => {
				axios
					.put('/Inventario/ActualizarSalidaAutomatica',{
						idArticulo: salida.idArticulo,
						salidaAutomatica: !!salida.salida
					})
					.then( () => {} )
					.catch( error => {
						console.log(error);
					})
			});
			this.cerrarDialogSalidas();
		},
		getArticulos(){
			axios
				.get('/Articulos/ArticulosNombres')
				.then( response => {
					this.articulos = response.data.filter( articulo => articulo.idArticuloTipo == 1);
				})
				.catch( error => {
					console.log(error);
				})
		},
		resetValues() {
			this.uploadPantografo = false;
            this.ficheroCarga = null;
            this.cargaCompletada = false;
            this.loadingCargaPantografo = false;
            this.errorCargaPantografo = false;
            this.selectedUpdateFile = null;
			this.idArticulo = null;
			this.checkSalidasAuto = false;
			this.savingSalidas = false;
		},
		cambioficheroCarga(event) {
			if (typeof event === "undefined" || event == null)
				this.ficheroCarga = null;
			else this.ficheroCarga = event;
		},
		cargaMasivaInventarioPost() {
            if (this.ficheroCarga != null) {
                this.loadingCargaPantografo = true;
				let formData = new FormData();
				formData.append("formfile", this.ficheroCarga);
				axios
					.put(`/Inventario/CargarPantografo/${this.idArticulo}`, formData, {
						headers: {
							"Content-Type": "multipart/form-data",
						},
					})
					.then((response) => {
						if(response.data.length>0){
							this.salidas = response.data.map( salida => ({
								...salida,
								salida: false
							}));
							this.cargaCompletada = true;
							this.checkSalidasAuto = true;
							this.loadingCargaPantografo = false;
							this.idArticulo = null;
						}else{
							// this.cargaLink = response.data;
							this.cargaCompletada = true;
							this.loadingCargaPantografo = false;
							this.idArticulo = null;
						}
					})
					.catch((error) => {
						console.log(error);
						this.loadingCargaPantografo = false;
						this.errorCargaPantografo = true;
						this.idArticulo = null;
					});
			}
		},
	},
};
</script>