<template>
  <v-row>
    <v-col cols="4">
      <datePicker
        v-model="inicio"
        format="YYYY-MM-DD"
        label="Desde (Obligatorio)"
        clearable
        maxToday
        @input="GetData()"
      ></datePicker>
    </v-col>
    <v-col cols="4">
      <datePicker
        v-model="fin"
        format="YYYY-MM-DD"
        label="Hasta (Obligatorio)"
        clearable
        maxToday
        @input="GetData()"
      ></datePicker>
    </v-col>
    <v-col cols="4">
      <v-select
        label="Jefe (Obligatorio)"
        v-model="idBoss"
        :items="itemsBoss"
        item-text="nombre"
        item-value="idPersona"
        @change="GetData()"
      ></v-select>
    </v-col>
    <v-col cols="12" class="my-n5">
      <DownloadBtn
        v-if="visble"
        color="blue darken-1"
        text
        class="my-n5"
        @click="downloadReport"
        label="Descargar Reporte de subordinados"
        :retryTime="20"
      />
      <v-btn v-else class="my-n5" disabled color="blue darken-1" text>
        Descargar Reporte de subordinados
      </v-btn>
    </v-col>
    <v-col cols="12">
      <div v-if="dialog2" style="height:350px">
        <v-row class="fill-height" align-content="center" justify="center">
          <v-col class="text-subtitle-1 text-center" cols="12">
            Cargando...
          </v-col>
          <v-col cols="6">
            <v-progress-linear
              indeterminate
              rounded
              height="6"
            ></v-progress-linear>
          </v-col>
        </v-row>
      </div>
      <div v-else id="chart">
        <apexchart
          type="bar"
          height="350"
          :options="chartOptions"
          :series="series"
        ></apexchart>
      </div>
    </v-col>
  </v-row>
</template>

<script>
import axios from "axios";
import DownloadBtn from "../../DownloadBtn.vue";
import moment from "moment";
export default {
  data: () => ({
    series: [],
    idBoss: -1,
    itemsBoss: [],
    inicio: null,
    fin: null,
    categorias: [],
    visble: false,
    dialog2: false,
  }),
  components: {
    DownloadBtn,
  },
  mounted() {
    this.GetJefes();
  },
  computed: {
    chartOptions() {
      return {
        chart: {
          type: "bar",
          height: 350,
        },
        plotOptions: {
          bar: {
            horizontal: false,
            columnWidth: "55%",
            endingShape: "rounded",
          },
        },
        dataLabels: {
          enabled: false,
        },
        stroke: {
          show: true,
          width: 2,
          colors: ["transparent"],
        },
        xaxis: {
          categories: this.categorias,
        },
        yaxis: {
          title: {
            text: "Subordinados",
          },
        },
        fill: {
          opacity: 1,
        },
        tooltip: {
          y: {
            formatter: function(val) {
              return val;
            },
          },
        },
      };
    },
  },
  methods: {
    GetData() {
      this.dialog2 = true;
      if (this.blockButtom()) {
        const url = `Reportes/GRaficaJefeSubordinados?fechaInicio=${this.inicio}&fechaFin=${this.fin}&idJefe=${this.idBoss}`;
        axios
          .get(url)
          .then((response) => {
            this.series = response.data.series;
            this.categorias = response.data.categories;
            this.dialog2 = false;
            this.visble = true;
          })
          .catch((error) => {
            console.log(error);
            this.visble = false;
            this.dialog2 = false;
          });
      }
    },
    blockButtom() {
      if (this.idBoss != -1 && this.inicio != null && this.fin != null)
        return true;
      else {
        this.visble = false;
        this.dialog2 = false;
        return false;
      }
    },
    GetJefes() {
      axios
        .get(`Reportes/ListarJefes`)
        .then((response) => {
          this.itemsBoss = response.data;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    url(path) {
      var url = `${path}?fechaInicio=${this.inicio}&fechaFin=${this.fin}&idJefe=${this.idBoss}`;
      return url;
    },
    downloadReport() {
      /* Reportes/XlsxJefeSubordinados */
      var datetime = moment().format("YYYYMMDDHHmmss");
      this.$utils.axios.downloadFile(
        this.url("Reportes/XlsxJefeSubordinados"),
        "GET",
        "reporte-subordinados-" + datetime + ".xlsx",
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
      );
    },
  },
};
</script>
