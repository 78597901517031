<template>
	<v-container fluid>
		<v-row>
			<v-col cols="3">
				<datePicker
					v-model="filtros.desde"
					format="YYYY-MM-DD"
					:maxDate="filtros.hasta"
					label="Desde (Obligatorio)"
					clearable
					:disabled="loading"
					@input="getData()"
				></datePicker>
			</v-col>
			<v-col cols="3">
				<datePicker
					v-model="filtros.hasta"
					format="YYYY-MM-DD"
					:minDate="filtros.desde"
					label="Hasta (Obligatorio)"
					clearable
					:disabled="loading"
					@input="getData()"
				></datePicker>
			</v-col>
			<v-col cols="3">
				<v-autocomplete
					v-model="filtros.idCliente"
					label="Cliente"
					:items="clientes"
					item-text="nombre"
					item-value="idCliente"
					hide-details
					clearable
					:disabled="loading || loadingClientes"
					:loading="loadingClientes"
					@change="getData()"
				></v-autocomplete>
			</v-col>
			<v-col cols="3">
				<v-autocomplete
					v-model="filtros.estadoProduccion"
					label="Estado de Producción"
					:items="estadosProduccion"
					hide-details
					clearable
					:disabled="loading"
					@change="getData()"
				></v-autocomplete>
			</v-col>
		</v-row>
		<v-row>
			<v-col cols="12">
				<v-simple-table class="custom-class-table">
					<template v-slot:default>
						<thead>
							<tr>
								<th class="text-center">OT</th>
								<th class="text-center">Artículo Tipo</th>
								<th class="text-center">Materia Prima</th>
								<th class="text-center">Cantidad Necesaria</th>
								<th class="text-center">Cantidad Usada</th>
								<th class="text-center">Cantidad Faltante</th>
								<th class="text-center">
									Desviación de Materia Prima
								</th>
								<th class="text-center">Mezcla Teórica</th>
								<th class="text-center">Mezcla Real</th>
								<th class="text-center">Variación</th>
							</tr>
						</thead>
						<thead v-if="loading">
							<tr>
								<th
									class="px-0"
									colspan="10"
									style="height: 0px"
								>
									<v-progress-linear
										indeterminate
										color="primary"
										height="4px"
									></v-progress-linear>
								</th>
							</tr>
						</thead>
						<tbody v-if="!loading && items.length > 0">
							<template v-for="(ot, idx0) in items">
								<template
									v-for="(articulo, idx2) in ot.virgenes
										.articulos"
								>
									<tr
										:class="{
											'rows-pares-color': idx0 % 2 != 0,
											'rows-impares-color': idx0 % 2 == 0,
										}"
										:key="`articulos-virgenes-${idx2}`"
									>
										<td
											v-if="idx2 == 0"
											:rowspan="ot.numRenglones"
										>
											{{ ot.ot }}
										</td>
										<td
											v-if="idx2 == 0"
											:rowspan="
												ot.virgenes.articulos.length
											"
										>
											{{ ot.virgenes.nombre }}
										</td>
										<td>
											{{ articulo.nombre }}
										</td>
										<td>
											{{
												articulo.cantidadNecesaria.toFixed(
													2
												)
											}}
											{{ articulo.unidad }}
										</td>
										<td>
											{{
												articulo.cantidadUsada.toFixed(
													2
												)
											}}
											{{ articulo.unidad }}
										</td>
										<td>
											{{
												articulo.cantidadFaltante.toFixed(
													2
												)
											}}
											{{ articulo.unidad }}
										</td>
										<td
											v-if="idx2 == 0"
											:rowspan="
												ot.virgenes.articulos.length
											"
										>
											{{
												ot.virgenDesviacionMP.toFixed(2)
											}}
											{{ articulo.unidad }}
										</td>
										<td
											v-if="idx2 == 0"
											:rowspan="
												ot.virgenes.articulos.length
											"
										>
											{{
												ot.virgenMezclaTeorica.toFixed(
													2
												)
											}}
											%
										</td>
										<td
											v-if="idx2 == 0"
											:rowspan="
												ot.virgenes.articulos.length
											"
										>
											{{
												ot.virgenMezclaReal.toFixed(2)
											}}
											%
										</td>
										<td
											v-if="idx2 == 0"
											:rowspan="ot.numRenglones"
										>
											{{ ot.variacion.toFixed(2) }} %
										</td>
									</tr>
								</template>
								<template
									v-for="(reciclado, idx2) in ot.reciclados"
								>
									<template
										v-for="(
											articulo, idx3
										) in reciclado.articulos"
									>
										<tr
											:class="{
												'rows-pares-color':
													idx0 % 2 != 0,
												'rows-impares-color':
													idx0 % 2 == 0,
											}"
											:key="`row-articulo-${idx3}`"
										>
											<td
												v-if="idx3 == 0"
												:rowspan="
													reciclado.articulos.length
												"
											>
												{{ reciclado.nombre }}
											</td>
											<td>
												{{ articulo.nombre }}
											</td>
											<td>
												{{
													articulo.cantidadNecesaria.toFixed(
														2
													)
												}}
												{{ articulo.unidad }}
											</td>
											<td>
												{{
													articulo.cantidadUsada.toFixed(
														2
													)
												}}
												{{ articulo.unidad }}
											</td>
											<td>
												{{
													articulo.cantidadFaltante.toFixed(
														2
													)
												}}
												{{ articulo.unidad }}
											</td>
											<td
												v-if="idx2 == 0 && idx3 == 0"
												:rowspan="
													ot.numRenglones -
													ot.virgenes.articulos.length
												"
											>
												{{
													ot.recicladosDesviacionMP.toFixed(
														2
													)
												}}
												{{ articulo.unidad }}
											</td>
											<td
												v-if="idx2 == 0 && idx3 == 0"
												:rowspan="
													ot.numRenglones -
													ot.virgenes.articulos.length
												"
											>
												{{
													ot.recicladosMezclaTeorica.toFixed(
														2
													)
												}}
												%
											</td>
											<td
												v-if="idx2 == 0 && idx3 == 0"
												:rowspan="
													ot.numRenglones -
													ot.virgenes.articulos.length
												"
											>
												{{
													ot.recicladosMezclaReal.toFixed(
														2
													)
												}}
												%
											</td>
										</tr>
									</template>
								</template>
							</template>
						</tbody>
						<tbody v-if="items.length == 0">
							<tr class="rows-impares-color text--disabled">
								<td colspan="10">
									<v-btn
										color="primary"
										:disabled="loading"
										@click="initialize()"
										>Refrescar</v-btn
									>
								</td>
							</tr>
						</tbody>
					</template>
				</v-simple-table>
			</v-col>
		</v-row>
	</v-container>
</template>

<script>
import axios from "axios";
export default {
	data() {
		return {
			estadosProduccion: [
				{
					text: "En Progreso",
					value: 1
				},
				{
					text: "Pausado",
					value: 2
				},
				{
					text: "Completado",
					value: 3
				},
				{
					text: "Producción Parcial",
					value: 4
				},
				{
					text: "Terminado Prematuramente",
					value: 5
				}
			],
			clientes: [],
			loadingClientes: false,
			filtros: {
				desde: null,
				hasta: null,
				idCliente: null,
				estadoProduccion: null
			},
			loading: false,
			items: [],
		};
	},
	mounted() {
		this.initialize();
	},
	methods: {
		initialize() {
			this.getClientes();
			this.getData();
		},
		getClientes(){
			this.loadingClientes = true;

			axios
				.get('/Clientes')
				.then( (response) => {
					this.clientes = response.data;
				})
				.catch( (error) => {
					this.clientes = [];
					console.log(error);
				})
				.finally( () => {
					this.loadingClientes = false;
				});
		},
		getData() {
			if(this.filtros.desde == null || this.filtros.hasta == null) return;

			this.loading = true;
			
			axios
				.get("/Reportes/Mezclas",{
					params: this.filtros
				})
				.then((response) => {
					this.items = response.data;
				})
				.catch((error) => {
					console.log(error);
					this.items = [];
				})
				.finally(() => {
					this.loading = false;
				});
		},
	},
};
</script>
<style scoped>
.rows-impares-color:hover {
	background: rgba(0, 0, 0, 0.05) !important;
}

.rows-impares-color {
	background-color: rgba(0, 0, 0, 0.05);
}

.rows-pares-color:hover {
	background: transparent !important;
}

.rows-pares-color {
	background-color: transparent;
}

.custom-class-table >>> td {
	text-align: center !important;
	font-size: 0.875rem !important;
	border-bottom: thin solid rgba(0, 0, 0, 0.12);
}

.custom-class-table >>> th {
	text-align: center !important;
	font-size: 1.0625rem !important;
}
</style>