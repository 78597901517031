import { VAutocomplete } from 'vuetify/lib/components/VAutocomplete';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VProgressLinear } from 'vuetify/lib/components/VProgressLinear';
import { VRow } from 'vuetify/lib/components/VGrid';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VCol,{attrs:{"cols":"12"}},[_c(VRow,[_c(VCol,{staticClass:"align-self-end",attrs:{"cols":"4","md":"4","sm":"4"}},[_c('datePicker',{attrs:{"format":"YYYY-MM-DD","label":"Desde (Obligatorio)","clearable":"","maxToday":"","disabled":_vm.cargando},on:{"input":function($event){_vm.getPersonas();
					_vm.obtenerDatos();}},model:{value:(_vm.desde),callback:function ($$v) {_vm.desde=$$v},expression:"desde"}})],1),_c(VCol,{staticClass:"align-self-end",attrs:{"cols":"4","md":"4","sm":"4"}},[_c('datePicker',{attrs:{"minDate":_vm.desde,"format":"YYYY-MM-DD","label":"Hasta (Obligatorio)","clearable":"","maxToday":"","disabled":_vm.cargando},on:{"input":function($event){_vm.getPersonas();
					_vm.obtenerDatos();}},model:{value:(_vm.hasta),callback:function ($$v) {_vm.hasta=$$v},expression:"hasta"}})],1),_c(VCol,{attrs:{"cols":"4"}},[_c(VAutocomplete,{attrs:{"label":"Operador(es) (Opcional)","items":_vm.personas,"item-value":"idPersona","item-text":_vm.nombrePersona,"hide-details":"","multiple":"","clearable":"","chips":"","deletable-chips":"","loading":_vm.cargando},on:{"change":_vm.obtenerDatos},model:{value:(_vm.idsPersonas),callback:function ($$v) {_vm.idsPersonas=$$v},expression:"idsPersonas"}})],1),_c(VCol,{attrs:{"cols":"4"}},[_c(VAutocomplete,{attrs:{"label":"Área (Opcional)","items":_vm.areas,"item-value":"idArea","item-text":"nombre","hide-details":"","clearable":"","disabled":_vm.cargando},on:{"change":_vm.obtenerDatos},model:{value:(_vm.idArea),callback:function ($$v) {_vm.idArea=$$v},expression:"idArea"}})],1),_c(VCol,{attrs:{"cols":"4"}},[_c(VAutocomplete,{attrs:{"items":_vm.turnos,"item-text":"nombre","item-value":"idTurno","label":"Turno (Opcional)","hide-details":"","clearable":"","disabled":_vm.cargando},on:{"change":_vm.obtenerDatos},model:{value:(_vm.idTurno),callback:function ($$v) {_vm.idTurno=$$v},expression:"idTurno"}})],1)],1),(_vm.desde != null && _vm.hasta != null)?_c(VRow,[_c(VCol,{attrs:{"cols":"4"}},[_c('download-btn',{attrs:{"color":"primary","text":"","label":"Descargar Reporte de<br>Eficiencia de Operadores"},on:{"click":_vm.descargarReporte}})],1)],1):_vm._e(),_c(VRow,[_c(VCol,{attrs:{"cols":"12"}},[(_vm.cargando)?_c(VProgressLinear,{attrs:{"color":"primary","height":"5","indeterminate":""}}):_vm._e(),_c('apexchart',{attrs:{"type":"bar","height":"350","options":_vm.chartOptions,"series":_vm.series}})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }