<template>
  <div>
    <table
      :style="'width:100%;border-collapse: collapse; border-style: hidden; box-shadow: 0 0 0 1px '+borderColor+';'"
    >
      <tbody>
        <tr>
          <v-tooltip
            
            top
            color="black"
            class="white--text"
          >
            <template v-slot:activator="{ on, attrs }">
              <td
                v-bind="attrs"
                v-on="on"
                class="text-center"
                :style="'border: '+borderSize+'px solid '+borderColor+'; padding-top: 10px; padding-bottom: 10px; background-color: #4CAF50; width:'+value.porcentajeNormal+'%; height: '+height+'px;'"
              ></td>
            </template>
            <span class="white--text">
              <strong>Ordinario: </strong>$ {{$utils.strings.formatearCifra(value.normal)}}
            </span>
          </v-tooltip>

          <v-tooltip
            v-if="value.retrabajo > 0.0"
            
            top
            color="black"
            class="white--text"
          >
            <template v-slot:activator="{ on, attrs }">
              <td
                v-bind="attrs"
                v-on="on"
                class="text-center"
                :style="'border: '+borderSize+'px solid '+borderColor+'; padding-top: 10px; padding-bottom: 10px; background-color: #2196F3; width:'+value.porcentajeRetrabajo+'%; height: '+height+'px;'"
              ></td>
            </template>
            <span class="white--text">
              <strong>Retrabajo: </strong>$ {{$utils.strings.formatearCifra(value.retrabajo)}}
            </span>
          </v-tooltip>

          <v-tooltip
            v-if="value.retrabajo > 0.0"
            
            top
            color="black"
            class="white--text"
          >
            <template v-slot:activator="{ on, attrs }">
              <td
                v-bind="attrs"
                v-on="on"
                class="text-center"
                :style="'border: '+borderSize+'px solid '+borderColor+'; padding-top: 10px; padding-bottom: 10px; background-color: #F44336; width:'+value.porcentajeScrap+'%; height: '+height+'px;'"
              ></td>
            </template>
            <span class="white--text">
              <strong>Scrap: </strong>$ {{$utils.strings.formatearCifra(value.scrap)}}
            </span>
          </v-tooltip>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
export default {
  props:{
    height:{
      type: Number,
      default: 100,
    },
    borderColor:{
      type: String,
      default:'#666666'
    },
    borderSize:{
      type: Number,
      default:1
    },
    value:{
      type:Array,
      default:[]
    }
  },
  computed:{
  },
  methods:{
  }
}
</script>