<template>
	<div>
        <template v-if="item.tipoAsignacion == 1 || item.tipoAsignacion == 0">
            <ul class="py-1">
                <li
                    v-for="(mecanico, idx) in item.showAllMecanicos
                        ? item.mecanicos
                        : item.mecanicos.slice(0, numWrap)"
                    :key="'mec-' + idx"
                    style="font-size: 14px !important"
                    :class="{ 'pb-1': item.mecanicos.length > 1 }"
                >
                    {{
                        typeof mecanico == "string"
                            ? mecanico
                            : `${mecanico.nombre} - $${mecanico.costo.toFixed(2)}`
                    }}
                </li>
                <template v-if="item.mecanicos.length > numWrap">
                    <a
                        class="mb-0 primary--text"
                        style="font-size: 14px !important"
                        @click="$set(item, 'showAllMecanicos', !!!item.showAllMecanicos)"
                    >
                        {{
                            item.showAllMecanicos
                                ? "ver menos"
                                : `ver (${item.mecanicos.length - numWrap}) más`
                        }}
                    </a>
                </template>
            </ul>
        </template>
        <template v-else-if="item.tipoAsignacion == 2">
            <p class="mb-0">
                {{
                    $utils.mantenimientos.findNombreProveedor(
                        item.idMantenimientoProveedor,
                        proveedores
                    )
                }}
            </p>
        </template>
    </div>
</template>

<script>
export default {
    props: {
        numWrap: {
            type: Number,
            default: 2
        },
        item: {
            type: Object,
            required: true
        },
        proveedores: {
            type: Array,
            default: () => []
        }
    }
};
</script>