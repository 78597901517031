<template>
  <v-col cols="12">
    <br />
    <br />
    <template>
      <v-row>
        <v-dialog v-model="dialogVenta" persistent max-width="30%">
          <v-card>
            <v-card-title>Definir Precio de Venta</v-card-title>
            <v-card-text>
              <v-row class="d-flex justify-center">
                <v-col cols="8" md="8" sm="8">
                  <v-text-field
                    v-model="costo"
                    label="Precio de Venta"
                    type="number"
                    min="0"
                    prefix="$"
                    :disabled="loadingVenta"
                    :rules="[rules.numValido]"
                  ></v-text-field>
                </v-col>
              </v-row>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                text
                color="red"
                :disabled="loadingVenta"
                @click="cerrarDialogVenta"
                >Cancelar</v-btn
              >
              <v-btn
                text
                color="green"
                :disabled="loadingVenta || !habilitarBtnVenta"
                :loading="loadingVenta"
                @click="guardarDialogVenta"
                >Guardar</v-btn
              >
            </v-card-actions>
          </v-card>
        </v-dialog>
        <v-dialog v-model="showCalculadoraRollos" persistent max-width="500px">
          <v-card>
            <v-card-title
              >Calculadora de Factor de Conversión de Rollos</v-card-title
            >
            <v-card-text>
              <v-row>
                <v-col cols="6" md="6" sm="6">
                  <v-text-field
                    v-model="calculadoraRollos.gramaje"
                    label="Gramaje (g/m2)"
                    type="number"
                    min="0"
                  ></v-text-field>
                </v-col>
                <v-col cols="6" md="6" sm="6">
                  <v-text-field
                    v-model="calculadoraRollos.anchura"
                    label="Anchura (cm)"
                    type="number"
                    min="0"
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12" md="12" sm="12">
                  <v-text-field
                    v-model="factorConversionRollos"
                    label="Factor de Conversión (cm)"
                    type="number"
                    min="0"
                    readonly
                  ></v-text-field>
                </v-col>
              </v-row>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn text color="green" @click="cerrarCalculadora"
                >Cerrar</v-btn
              >
            </v-card-actions>
          </v-card>
        </v-dialog>
        <v-dialog
          v-model="avanzada"
          persistent
          max-width="500px"
          :key="avanzada"
          content-class="dialog-busqueda"
          @keydown="dialog_busqueda_keyevent_action"
        >
          <v-card class="card-busqueda">
            <v-card-title>
              <span class="headline">Búsqueda Avanzada</span>
            </v-card-title>
            <v-card-text>
              <v-row>
                <v-col justify="center" cols="11" sm="11" md="11">
                  <v-autocomplete
                    v-model="busqueda.categorias"
                    :items="categorias"
                    label="Categoría"
                    item-text="nombre"
                    item-value="idArticuloCategoria"
                    placeholder="Todos"
                    multiple
                    chips
                    small-chips
                    dense
                    clearable
                    deletable-chips
                    hide-details
                    :disabled="loading"
                  ></v-autocomplete>
                </v-col>
                <v-icon
                  class="mr-2"
                  :disabled="loadingCategoria"
                  @click="getCategorias()"
                  >mdi-reload</v-icon
                >
              </v-row>
              <v-row>
                <v-col justify="center" cols="11" sm="11" md="11">
                  <v-autocomplete
                    v-model="busqueda.tipos"
                    :items="tipos"
                    label="Tipo"
                    item-text="nombre"
                    item-value="idArticuloTipo"
                    placeholder="Todos"
                    multiple
                    chipsey
                    small-chips
                    dense
                    clearable
                    deletable-chips
                    hide-details
                    :disabled="loading"
                  ></v-autocomplete>
                </v-col>
                <v-icon class="mr-2" :disabled="loadingTipo" @click="getTipos()"
                  >mdi-reload</v-icon
                >
              </v-row>
              <v-row>
                <v-col justify="center" cols="11" sm="11" md="11">
                  <v-autocomplete
                    v-model="busqueda.clases"
                    :items="clases"
                    label="Clase"
                    item-text="clase"
                    item-value="idArticuloClase"
                    placeholder="Todos"
                    multiple
                    small-chips
                    dense
                    clearable
                    deletable-chips
                    hide-details
                    :disabled="loading"
                  ></v-autocomplete>
                </v-col>
                <v-icon class="mr-2" :disabled="loadingClases" @click="getClases()"
                  >mdi-reload</v-icon
                >
              </v-row>
              <v-row>
                <v-col justify="center" cols="11" sm="11" md="11">
                  <v-autocomplete
                    v-model="busqueda.unidades"
                    :items="unidades"
                    label="Unidad"
                    item-text="nombre"
                    item-value="idUnidad"
                    placeholder="Todos"
                    multiple
                    chips
                    small-chips
                    dense
                    clearable
                    deletable-chips
                    hide-details
                    :disabled="loading"
                  ></v-autocomplete>
                </v-col>
                <v-icon
                  class="mr-2"
                  :disabled="loadingUnidad"
                  @click="getUnidades()"
                  >mdi-reload</v-icon
                >
              </v-row>
              <v-row>
                <v-col justify="center" cols="11" sm="11" md="11">
                  <v-autocomplete
                    v-model="busqueda.almacenes"
                    :items="almacenes"
                    label="Almacén"
                    item-text="nombre"
                    item-value="idAlmacen"
                    placeholder="Todos"
                    multiple
                    chips
                    small-chips
                    dense
                    clearable
                    deletable-chips
                    hide-details
                    :disabled="loading"
                  ></v-autocomplete>
                </v-col>
                <v-icon
                  class="mr-2"
                  :disabled="loadingAlmacen"
                  @click="getAlmacenes()"
                  >mdi-reload</v-icon
                >
              </v-row>
              <v-row> <!--Para el filtro de subalmacenes-->
                <v-col justify="center" cols="11" sm="11" md="11">
                  <v-autocomplete
                    v-model="busqueda.subalmacenes"
                    :items="subalmacenes"
                    label="Subalmacén"
                    item-text="subalmacen"
                    item-value="idSubalmacen"
                    placeholder="Todos"
                    multiple
                    chips
                    small-chips
                    dense
                    clearable
                    deletable-chips
                    hide-details
                    :disabled="loading"
                  ></v-autocomplete>
                </v-col>
                <v-icon
                  class="mr-2"
                  :disabled="loadingSubalmacen"
                  @click="getSubalmacenes()"
                  >mdi-reload</v-icon
                >
              </v-row> <!--Fin de lo agregado para subalmacenes-->
              <v-row>
                <v-col justify="center" cols="11" sm="11" md="11">
                  <v-autocomplete
                    v-model="busqueda.estados"
                    :items="estados"
                    label="Estado"
                    item-text="name"
                    item-value="id"
                    placeholder="Todos"
                    multiple
                    chips
                    small-chips
                    dense
                    clearable
                    deletable-chips
                    hide-details
                    :disabled="loading"
                  ></v-autocomplete>
                </v-col>
                <v-icon class="mr-2" @click="getUnidades()">mdi-reload</v-icon>
              </v-row>
            </v-card-text>
            <v-card-actions
              class="card-actions-busqueda d-flex justify-space-between"
              style="width: 93%"
            >
              <section>
                <v-btn
                  color="primary"
                  text
                  @click="getArticulosAction()"
                  :disabled="loading"
                  >Buscar</v-btn
                >
                <v-btn
                  color="green darken-1"
                  text
                  @click="resetBusqueda()"
                  :disabled="loading"
                  >Restablecer</v-btn
                >
              </section>
              <v-btn color="blue darken-1" text @click="avanzada = false"
                >Salir</v-btn
              >
            </v-card-actions>
          </v-card>
        </v-dialog>
        <v-dialog
          v-model="cargaMasiva"
          persistent
          max-width="500px"
          key="cargaMasiva"
        >
          <v-card>
            <v-card-title>Carga Masiva de Precio de Compras</v-card-title>
            <v-card-text v-if="errorCargaMasiva">
              <v-col cols="12" md="12" sm="12" class="text-center">
                <h5>Se ha producido un error. Intente una vez más.</h5>
              </v-col>
              <v-col cols="12" md="12" sm="12" class="text-center">
                <v-btn
                  depressed
                  color="red"
                  text
                  @click="
                    cargaMasiva = false;
                    ficheroCarga = null;
                    cargaCargaCompleta = false;
                    loadingCargaMasiva = false;
                    errorCargaMasiva = false;
                    selectedUpdateFile = null;
                  "
                  >Cerrar</v-btn
                >
              </v-col>
            </v-card-text>
            <v-card-text v-if="cargaCargaCompleta">
              <v-col cols="12" md="12" sm="12" class="text-center">
                <h4>Carga Realizada Correctamente</h4>
              </v-col>
              <v-col cols="12" md="12" sm="12" class="text-center">
                <v-btn
                  depressed
                  color="red"
                  text
                  @click="
                    cargaMasiva = false;
                    ficheroCarga = null;
                    cargaCargaCompleta = false;
                    loadingCargaMasiva = false;
                    errorCargaMasiva = false;
                    selectedUpdateFile = null;
                  "
                  >Cerrar</v-btn
                >
              </v-col>
            </v-card-text>
            <v-card-text v-if="loadingCargaMasiva && !cargaCargaCompleta">
              <v-col cols="12" md="12" sm="12" class="text-center">
                <h4>Cargando</h4>
              </v-col>
              <v-col cols="12" md="12" sm="12" class="text-center">
                <v-progress-circular
                  :size="75"
                  color="green"
                  indeterminate
                ></v-progress-circular>
              </v-col>
            </v-card-text>
            <v-card-text
              v-if="
                !loadingCargaMasiva && !cargaCargaCompleta && !errorCargaMasiva
              "
              class="text-center"
            >
              <v-file-input
                v-model="ficheroCarga"
                accept=".csv"
                @change="cambioficheroCompras"
                show-size
                label="Fichero Carga .CSV"
              ></v-file-input>

              <br />
              <br />
              <v-row>
                <v-spacer></v-spacer>
                <v-btn
                  color="blue darken-1"
                  text
                  @click="
                    cargaMasiva = false;
                    ficheroCarga = null;
                    cargaCargaCompleta = false;
                    loadingCargaMasiva = false;
                    errorCargaMasiva = false;
                    selectedUpdateFile = null;
                  "
                  >Cancelar</v-btn
                >
                <v-btn color="red darken-1" text @click="cargaMasivaPost"
                  >Aceptar</v-btn
                >
              </v-row>
            </v-card-text>
          </v-card>
        </v-dialog>
      </v-row>
    </template>
    <v-alert
      v-if="alertas > 0 && permisoVista('articulos2', 'r')"
      text
      prominent
      type="error"
      icon="mdi-alert"
      >Se han detectado artículos que necesitan tener al menos una receta, para
      poder calcular su costo de manufactura. En caso de emplear alguno de estos
      artículos en la sección de recetas, su costo será de $0.00.</v-alert
    >
    <v-btn
      v-if="calculadoraRollos_show"
      text
      @click="showCalculadoraRollos = true"
      color="primary"
    >
      <v-icon>mdi-calculator</v-icon>Calculadora de Rollos
      <v-icon>mdi-paper-roll-outline</v-icon>
    </v-btn>
    <v-data-table
      v-if="permisoVista('articulos2', 'r')"
      :headers="headers"
      :items="articulos"
      :search="search"
      :loading="loading"
      loading-text="Cargando... Espere, Por Favor."
      :server-items-length="articulos_total"
      :items-per-page="articulos_items_per_page"
      :page="articulos_actual_page"
      @update:page="articulos_update_page_action"
      @update:items-per-page="(ipp) => (articulos_items_per_page = ipp)"
      @update:sort-by="articulos_sort_by_action"
      :footer-props="{
        disableItemsPerPage: true,
        disablePagination: this.loading,
      }"
      :disable-sort="true"
      :disable-pagination="loading"
    >

      <template v-slot:item="{ item }">
        <tr>
          <td>
            <div v-if="item.imagen != null" >
          <TooltipThumbnail
          :lazy="false"
          :lazyUrl="''"
          :url="item.imagen"
        ></TooltipThumbnail>
        </div>  
          </td>
          <td>
              <div class="d-flex align-center justify-center">
                <v-icon class="mr-2" v-if="item.faltaReceta" color="red"
                  >mdi-alert</v-icon
                >
                <p class="mb-0">{{ item.codigo }}</p>
              </div>
          </td>
          <td class="text-center">{{ item.nombre }}</td>
          <td class="text-center">{{ item.articuloClase ? item.articuloClase : "N/D" }}</td>
          <td class="text-center">{{ item.articuloCategoria }}</td>
          <td class="text-center">{{ item.articuloTipo }}</td>
          <td class="text-center">{{ item.unidad }}</td>
          <td class="text-center">{{ item.estado }}</td>
          <td>
            <div class="d-flex justify-center align-center">
              <v-tooltip
                :nudge-left="50"
                bottom
                color="black"
                class="white--text"
              >
                <template v-slot:activator="{ on }">
                  <v-icon
                    v-on="on"
                    v-if="permisoVista('articulos2', 'u')"
                    small
                    class="mr-2"
                    :disabled="loading"
                    @click="editItem(item)"
                    >edit</v-icon
                  >
                </template>
                <span class="white--text">Editar</span>
              </v-tooltip>
              <v-tooltip
                v-if="
                  permisoVista('articulos2', 'u') &&
                  permisoVista('articulos2', '$')
                "
                :nudge-left="50"
                bottom
                color="black"
                class="white--text"
              >
                <template v-slot:activator="{ on }">
                  <v-icon
                    v-on="on"
                    v-show="item.vender"
                    small
                    class="mr-2"
                    @click="costoVenta(item)"
                    >mdi-currency-usd</v-icon
                  >
                </template>
                <span class="white--text">Precio Venta</span>
              </v-tooltip>
              <v-tooltip
                :nudge-left="50"
                bottom
                color="black"
                class="white--text"
                v-if="
                  permisoVista('articulos2', 'u') && computedDependientes(item)
                "
              >
                <template v-slot:activator="{ on }">
                  <v-icon
                    v-on="on"
                    small
                    class="mr-2"
                    :disabled="loading"
                    @click="verDependientes(item)"
                    >mdi-file-tree-outline</v-icon
                  >
                </template>
                <span class="white--text">Artículos Dependientes</span>
              </v-tooltip>
              <v-switch
                v-if="item.idArticuloTipo == 1"
                v-model="item.critico"
                v-tooltip.bottom="item.critico ? 'Desactivar' : 'Activar'"
                color="success"
                :disabled="loading"
                @change="changeSwitchArticulo({ idArticulo: item.idArticulo, estado: $event })"
              ></v-switch>
            </div>
          </td>
        </tr>
      </template>
      <template v-slot:top>
        <v-toolbar flat color="white">
          <v-dialog v-model="dialog" persistent max-width="500px" :key="dialog">
            <v-card>
              <v-card-title>
                <span class="headline">{{ formTitle }}</span>
              </v-card-title>
              <v-card-text>
                <v-form ref="form" >
                  <v-container>
                  <v-row>
                    <v-col justify="center" cols="11" sm="11" md="11">
                        <v-text-field
                            v-model="editedItem.nombre"
                            label="Nombre Articulo"
                          ></v-text-field>
                    </v-col>
                    <v-col justify="center" cols="11" sm="11" md="11">
                        <v-text-field
                            v-model="editedItem.codigo"
                            label="Código Articulo"
                          ></v-text-field>
                    </v-col>
                    <v-col justify="center" cols="11" sm="11" md="11">
                      <p style="font-size: 16px !important">Salida Automática</p>
                          <v-checkbox
                            v-model="editedItem.salidaAutomatica"
                            color="success"
                            class="mt-0"
                            hide-details
                          >
                            <template v-slot:label>
                              <p class="mb-0 text--primary">{{editedItem.salidaAutomatica?'Activada':'Desactivada'}}</p>
                            </template>
                          </v-checkbox>
                    </v-col>
                    <v-col justify="center" cols="11" sm="11" md="11">
                          <v-autocomplete
                            v-model="editedItem.idUnidad"
                            :items="unidadesonly"
                            label="Unidad"
                            item-text="nombre"
                            item-value="idUnidad"
                          ></v-autocomplete>
                        </v-col>
                    <v-col justify="center" cols="11" sm="11" md="11">
                      <v-autocomplete
                        v-model="editedItem.idArticuloCategoria"
                        :items="categorias"
                        label="Categoría"
                        item-text="nombre"
                        item-value="idArticuloCategoria"
                      ></v-autocomplete>
                    </v-col>
                    <v-icon
                      class="mr-2"
                      :disabled="loadingCategoria"
                      @click="getCategorias()"
                      >mdi-reload</v-icon
                    >
                    <v-col justify="center" cols="11" sm="11" md="11">
                      <v-autocomplete
                        v-model="editedItem.idArticuloTipo"
                        :items="tipos"
                        label="Tipo"
                        item-text="nombre"
                        item-value="idArticuloTipo"
                        @change="cambioTipo(), hideFactorConversion()"
                      ></v-autocomplete>
                      <!-- @change="hideFactorConversion()" -->
                    </v-col>
                    <v-icon
                      class="mr-2"
                      :disabled="loadingCategoria"
                      @click="getTipos()"
                      >mdi-reload</v-icon
                    >
                    <v-col justify="center" cols="11" sm="11" md="11">
                      <v-autocomplete
                        v-model="editedItem.idArticuloClase"
                        :items="clases"
                        label="Clase"
                        item-text="clase"
                        item-value="idArticuloClase"
                        :loading="loadingClases"
                        clearable
                      ></v-autocomplete>
                    </v-col>
                    <v-icon
                      class="mr-2"
                      :disabled="loadingClases"
                      @click="getClases()"
                      >mdi-reload</v-icon
                    >
                    <v-col justify="center" cols="11" sm="11" md="11">
                      <v-autocomplete
                        v-model="editedItem.idsArticuloFamilias"
                        :items="familias"
                        label="Familia de Productos"
                        item-text="nombre"
                        item-value="idArticuloFamilia"
                        :multiple="multiplesFamilias"
                      ></v-autocomplete>
                    </v-col>
                    <v-icon
                      class="mr-2"
                      :disabled="loadingCategoria"
                      @click="getFamilias()"
                      >mdi-reload</v-icon
                    >
                    <v-col cols="12">
                      <VSelectTags
                        v-model="editedItem.tags"
                        ref="selecTags"
                        :key="chkey"
                        :items="tags"
                        itemText="tag"
                        itemValue="idTag"
                        placeholder="Tags"
                      />
                    </v-col>
                    <v-col
                      v-if="
                      editedIndex == -1||(
                        editedItem.idArticuloTipo == 1 ||
                        editedItem.idArticuloTipo == 3 ||
                        editedItem.vender || editedItem.produccion == true || editedItem.receta == true || editedItem.articuloProducir == true)
                      "
                      justify="center"
                      cols="12"
                      sm="12"
                      md="12"
                    >
                      <v-checkbox
                        v-model="editedItem.conversion"
                        label="¿Es posible reportar en unidades alternas?"
                      ></v-checkbox>
                    </v-col>
                    <v-col
                      justify="center"
                      v-if="
                      
                        editedItem.conversion &&
                        (editedIndex == -1||(editedItem.idArticuloTipo == 1 ||
                          editedItem.idArticuloTipo == 3 ||
                          editedItem.vender || editedItem.produccion == true || editedItem.receta == true || editedItem.articuloProducir == true))
                      "
                      cols="11"
                      sm="11"
                      md="11"
                    >
                      <v-autocomplete
                        v-model="editedItem.idUnidadAuxiliar"
                        :items="unidades"
                        item-text="nombre"
                        item-value="idUnidad"
                        label="Unidad de Conversión"
                        @change="cambioUnidad"
                      ></v-autocomplete>
                    </v-col>
                    <v-icon
                      v-if="
                        editedItem.conversion &&
                        (editedItem.idArticuloTipo == 1 ||
                          editedItem.idArticuloTipo == 3 ||
                          editedItem.vender || editedItem.produccion == true || editedItem.receta == true || editedItem.articuloProducir == true)
                      "
                      class="mr-2"
                      @click="getUnidades()"
                    >
                      mdi-reload
                    </v-icon>
                    <v-col
                      v-if="
                        editedItem.conversion &&
                        (editedItem.idArticuloTipo == 1 ||
                          editedItem.idArticuloTipo == 3 ||
                          editedItem.vender || editedItem.produccion == true || editedItem.receta == true || editedItem.articuloProducir == true)
                      "
                      justify="center"
                      cols="12"
                      sm="12"
                      md="12"
                      class="no-padding"
                    >
                      <v-row
                        v-if="unidadConversion.numDimensiones > 1"
                        class="no-padding"
                      >
                        <v-col
                          justify="center"
                          :cols="colSize"
                          :sm="colSize"
                          :md="colSize"
                          class="no-padding"
                        >
                          <v-text-field
                            v-model="editedItem.ejeX"
                            type="number"
                            min="0"
                            label="Largo"
                            @input="factorConversion()"
                          ></v-text-field>
                        </v-col>
                        <v-col
                          v-if="unidadConversion.numDimensiones >= 2"
                          justify="center"
                          :cols="colSize"
                          :sm="colSize"
                          :md="colSize"
                          class="no-padding"
                        >
                          <v-text-field
                            v-model="editedItem.ejeY"
                            type="number"
                            min="0"
                            label="Ancho"
                            @input="factorConversion()"
                          ></v-text-field>
                        </v-col>
                        <v-col
                          v-if="unidadConversion.numDimensiones == 3"
                          justify="center"
                          :cols="colSize"
                          :sm="colSize"
                          :md="colSize"
                          class="no-padding"
                        >
                          <v-text-field
                            v-model="editedItem.ejeZ"
                            type="number"
                            min="0"
                            label="Alto"
                            @input="factorConversion()"
                          ></v-text-field>
                        </v-col>
                      </v-row>
                      <v-row class="no-padding">
                        <v-col
                          justify="center"
                          cols="12"
                          sm="12"
                          md="12"
                          class="no-padding"
                        >
                          <v-text-field
                            v-model="editedItem.factorConversion"
                            type="number"
                            min="0"
                            :readonly="unidadConversion.numDimensiones > 1"
                            label="Equivalencia"
                          ></v-text-field>
                        </v-col>
                      </v-row>
                      <v-row class="no-padding" v-if="false">
                        <v-col
                          justify="center"
                          cols="12"
                          sm="12"
                          md="12"
                          class="no-padding"
                        >
                          <switchx
                            v-model="editedItem.costoFC"
                            label="Aplicar al Costo Factor de Conversión"
                          ></switchx>
                        </v-col>
                      </v-row>
                    </v-col>

                    <v-col justify="center" cols="12" sm="12" md="12">
                      <v-file-input
                        v-model="editedItem.imagen"
                        accept="image/png, image/jpeg, image/jpg"
                        label="Imagen (Opcional)"
                      ></v-file-input>
                    </v-col>
                  </v-row>
                  <ul>
                    <li class="red--text" v-for="ex in errores" :key="ex">
                      {{ ex }}
                    </li>
                  </ul>
                  </v-container>
                </v-form>
              </v-card-text>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                  color="blue darken-1"
                  text
                  @click="
                    dialog = false;
                    errores = [];
                  "
                  >Cancelar</v-btn
                >
                <v-btn
                  color="red darken-1"
                  text
                  @click="save()"
                  :disabled="saving"
                  :loading="saving"
                >
                  Guardar
                  <template v-slot:loader>
                    <v-progress-circular
                      indeterminate
                      :width="2"
                      :size="24"
                      color="red"
                    ></v-progress-circular>
                  </template>
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
          <v-col>
            <v-row>
              <v-text-field
                v-model="search"
                append-icon="search"
                label="Buscar por código o artículo"
                single-line
                hide-details
                @keyup="getArticulosOnEnterAction"
                @click:clear="clearSearchAction"
                @click:append="getArticulosAction()"
                :clearable="true"
                :disabled="loading"
              ></v-text-field>
              
            </v-row>
            <v-row>
              <v-btn
                depressed
                color="primary"
                @click="dialog = true"
                >
                Nuevo articulo
              </v-btn>
                <v-spacer></v-spacer>
                <v-btn small text color="primary" @click="avanzada = true">
                <v-icon>mdi-target</v-icon>&nbsp; Búsqueda Avanzada
              </v-btn>
            </v-row>
            <!-- <v-row justify="end">
              <v-btn small text color="primary" @click="avanzada = true">
                <v-icon>mdi-target</v-icon>&nbsp; Búsqueda Avanzada
              </v-btn>
            </v-row> -->
          </v-col>
        </v-toolbar>
        <v-dialog
          :loading="loadingDep"
          v-model="dialogDep"
          persistent
          max-width="500px"
          :key="dialogDep"
        >
          <v-card>
            <v-card-title>Artículos Dependientes - [{{dependienteItem.codigo}}] - {{dependienteItem.nombre}}</v-card-title>
            <v-card-text class="py-0 px-2">
              <v-container>
                <v-row>
                  <v-col cols="12">
                    <div v-if="
                    loadingDep &&
                        JSON.stringify(dependientes) == '[]'" class="d-flex justify-center">
                          <v-progress-circular
                            :size="70"
                            :width="7"
                            color="green"
                            indeterminate
                          ></v-progress-circular>
                          <br/>
                          <br/>
                          <br/>
                          <br/>
                          <h1>Cargando</h1>
                        </div>
                    <v-simple-table
                      v-if="
                        dependientes.length > 0 &&
                        JSON.stringify(dependientes) !== '[]'
                      "
                    >
                      <template v-slot:default>
                        <thead>
                          <tr>
                            <th class="text-left" style="font-size: 16px">
                              Artículo
                            </th>
                            <th class="left" style="font-size: 16px">Área</th>
                            <th class="left" style="font-size: 16px">Estado</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr
                            v-for="item in dependientes"
                            :key="`dep-${item.idReceta}`"
                          >
                            <td>
                              {{
                                item.codigo != null
                                  ? `${item.codigo} - ${item.articuloReceta}`
                                  : `${item.articuloReceta}`
                              }}
                            </td>
                            <td>
                              {{ item.area }}
                            </td>
                            <td>
                              <v-switch
                                v-model="item.critico"
                                color="success"
                                v-tooltip.bottom="item.critico ? 'Desactivar' : 'Activar'"
                                :disabled="!enableSwitchArticDep"
                                @change="changeSwitchArticuloDependiente(item,$event)"
                              ></v-switch>
                            </td>
                          </tr>
                        </tbody>
                      </template>
                    </v-simple-table>
                    <div
                      v-if="
                        !loadingDep &&
                        dependientes.length === 0 &&
                        JSON.stringify(dependientes) === '[]'
                      "
                      class="text-center text-h6"
                    >
                      Este artículo no se emplea en ninguna Receta.
                    </div>
                  </v-col>
                </v-row>
              </v-container>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="green darken-1" text @click="cerrarDialogDep()">
                Cerrar
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </template>
      <!--
      <template v-slot:item.faltaReceta="{ item }">
        <v-icon v-if="item.faltaReceta" class="mr-2" color="red"
          >mdi-alert</v-icon
        >
      </template>
      -->
      <template v-slot:[`item.action`]="{ item }">
        <v-tooltip
          
          bottom
          color="black"
          class="white--text"
        >
          <template v-slot:activator="{ on }">
            <v-icon
              v-on="on"
              v-if="permisoVista('articulos2', 'u')"
              small
              class="mr-2"
              @click="editItem(item)"
              >edit</v-icon
            >
          </template>
          <span class="white--text">Editar</span>
        </v-tooltip>
        <v-tooltip
          v-if="
            permisoVista('articulos2', 'u') &&
            permisoVista('articulos2', '$')
          "
          
          bottom
          color="black"
          class="white--text"
        >
          <template v-slot:activator="{ on }">
            <v-icon
              v-on="on"
              v-show="item.vender"
              small
              class="mr-2"
              @click="costoVenta(item)"
              >mdi-currency-usd</v-icon
            >
          </template>
          <span class="white--text">Precio Venta</span>
        </v-tooltip>
        <v-tooltip
          
          bottom
          color="black"
          class="white--text"
          v-if="
            permisoVista('articulos2', 'u') &&
            computedDependientes(item)
          "
        >
          <template v-slot:activator="{ on }">
            <v-icon
              v-on="on"
              small
              class="mr-2"
              @click="verDependientes(item)"
              >mdi-file-tree-outline</v-icon
            >
          </template>
          <span class="white--text">Artículos Dependientes</span>
        </v-tooltip>
      </template>
      <template v-slot:no-data>
        <v-btn color="primary" @click="initialize">Refrescar</v-btn>
      </template>
    </v-data-table>

    <div class="table-footer-prepend d-flex pl-2 align-center">
      <download-btn
        v-if="
          permisoVista('articulos2', 'r') && permisoVista('articulos2', '$')
        "
        text
        color="primary"
        className="mr-3"
        v-on="on"
        @click="descargarFicheroCompras"
        label="Descargar Reporte<br>Precio de Compras"
      ></download-btn>
      <v-btn
        v-if="permisoVista('articulos2', 'u')"
        text
        color="primary"
        class="mr-3"
        v-on="on"
        :disabled="loadingSubir || loadingDescargar"
        :loading="loadingSubir"
        @click="cargaMasiva = true"
      >
        Carga Masiva <br />Precio de compras
      </v-btn>
    </div>
  </v-col>
</template>

<script>
import axios from "axios";
import switchx from "@/components/Switchx";
import moment from "moment";
import { mapState } from "vuex";
import { debounce } from "debounce";
import VSelectTags from "@/components/VSelectTags.vue";
import DownloadBtn from "../../DownloadBtn.vue";
import TooltipThumbnail from "@/components/TooltipThumbnail.vue";

export default {
  components: {
    switchx: switchx,
    VSelectTags,
    DownloadBtn,
    TooltipThumbnail
  },
  data: () => ({
    unidadesonly:[],
    dialogArticulo: false,
    enableSwitchArticDep: false,
    clases: [],
    loadingClases: false,
    chkey: 0,
    tags: [],
    cargaMasiva: false,
    ficheroCarga: null,
    cargaCargaCompleta: false,
    loadingCargaMasiva: false,
    errorCargaMasiva: false,
    selectedUpdateFile: null,
    fichero: null,
    loadingDescargar: false,
    loadingSubir: false,
    costo: 0,
    errores: [],
    habilitarBtnVenta: false,
    loadingVenta: false,
    dialogVenta: false,
    defaultItemVenta: {
      idArticulo: -1,
      fecha: null,
      costo: 0,
    },
    itemVenta: {
      idArticulo: -1,
      fecha: null,
      costo: 0,
    },
    dependientes: [],
    loadingDep: false,
    dialogDep: false,
    showCalculadoraRollos: false,
    calculadoraRollos: {
      gramaje: 0.0,
      anchura: 0.0,
    },
    saving: false,
    conversion: false,
    unidadConversion: {
      idUnidad: -1,
      nombre: "",
      abreviatura: "",
      numDimensiones: 0,
    },
    defaultUnidadConversion: {
      idUnidad: -1,
      nombre: "",
      abreviatura: "",
      numDimensiones: 1,
    },
    estados: [
      {
        name: "VIGENTE",
        id: 1,
      },
      {
        name: "DESCONTINUADO",
        id: 0,
      },
    ],
    busqueda: {
      nombre: "",
      categorias: [],
      clases: [],
      tipos: [],
      unidades: [],
      codigo: "",
      almacenes: [],
      subalmacenes: [], //Nuevo en el filtro
      estados: [1],
    },
    idArticuloTipo: [],
    avanzada: false,
    loadingTipo: false,
    tipos: [],
    loadingCategoria: false,
    categorias: [],
    ultimoTipo: -1,
    loadingFamilia: false,
    multiplesFamilias: true,
    familias: [],
    loadingUnidad: false,
    unidades: [],
    loadingSubalmacen: false, //Agregado para el filtro
    dialog: false,
    alerta: false,
    eliminar: false,
    search: "",
    headers: [
      /*
      {
        text: "",
        align: "center",
        sortable: true,
        value: "faltaReceta",
        width: "50px",
      },
      */
     {
        text: "Imagen",
        align: "center",
        sortable: false,
        value: "imagen",
      },
      {
        text: "Código",
        align: "center",
        sortable: true,
        value: "codigo",
      },
      {
        text: "Artículo",
        align: "center",
        sortable: true,
        value: "nombre",
      },
      {
        text: "Clase",
        align: "center",
        sortable: true,
        value: "articuloClase",
      },
      {
        text: "Categoría",
        align: "center",
        sortable: true,
        value: "articuloCategoria",
      },
      {
        text: "Tipo",
        align: "center",
        sortable: true,
        value: "articuloTipo",
      },
      {
        text: "Unidad",
        align: "center",
        sortable: true,
        value: "unidad",
      },
      {
        text: "Estado",
        align: "center",
        sortable: true,
        value: "estado",
      },
      {
        text: "Acciones",
        value: "action",
        sortable: false,
        width: "15%",
        align: "center",
      },
    ],
    loading: false,
    articulos: [],
    //? Paginacion articulos y filtros (server side)
    articulos_total: null,
    articulos_items_per_page: 10,
    articulos_actual_page: 1,
    delItem: "",
    action: 0,
    errores: [],
    editedIndex: -1,
    editedItem: {
      nombre: null,
      codigo: null,
      salidaAutomatica: false,
      idUnidad: -1,
      idArticuloTipo: -1,
      idArticuloCategoria: -1,
      idArticuloClase: null,
      idsArticuloFamilias: [],
      ejeX: 0,
      ejeY: 0,
      ejeZ: 0,
      factorConversion: 0,
      conversion: false,
      idUnidadAuxiliar: -1,
      tags: [],
      imagen:null,
      articuloProducir: null
    },
    defaultItem: {
      nombre: null,
      codigo: null,
      salidaAutomatica: false,
      idUnidad: -1,
      idArticuloTipo: -1,
      idArticuloCategoria: -1,
      idArticuloClase: null,
      idsArticuloFamilias: [],
      ejeX: 0,
      ejeY: 0,
      ejeZ: 0,
      factorConversion: 0,
      conversion: false,
      idUnidadAuxiliar: -1,
      tags: [],
      imagen:null,
      articuloProducir: null
    },
    dependienteItem: {
      nombre: null,
      codigo: null,
    },
    alertas: 0,
    materiaPrima: false,
    simpleloading: true,
    HideConversionfactor: true,
    rules: {
      numValido: (num) => {
        if (!isNaN(parseFloat(num)) && num > 0) return true;
        return "Invalido.";
      },
    },
  }),
  computed: {
    ...mapState({
      calculadoraRollos_show: "calculadoraRollos",
    }),
    factorConversionRollos() {
      try {
        return (
          10000000.0 /
          (parseFloat(this.calculadoraRollos.gramaje) *
            parseFloat(this.calculadoraRollos.anchura))
        ).toFixed(4);
      } catch (error) {
        return "NaN";
      }
    },
    formTitle() {
      return this.editedIndex === -1 ? "Nuevo Artículo" : "Editar Artículo";
    },
    articulos_fetch_url() {
      let url = `/Articulos?paginar=true&page=${this.articulos_actual_page}&size=${this.articulos_items_per_page}`;

      if (this.search)
        url += this.search.length ? `&nombreCodigo=${this.search}` : "";

      if (this.busqueda.categorias)
        this.busqueda.categorias.forEach(
          (x) => (url += `&categorias=${x || ""}`)
        );

      if (this.busqueda.tipos)
        this.busqueda.tipos.forEach((x) => (url += `&tipos=${x || ""}`));

      if (this.busqueda.clases.length > 0)
        this.busqueda.clases.forEach((x) => (url += `&clases=${x || ""}`));

      if (this.busqueda.unidades)
        this.busqueda.unidades.forEach((x) => (url += `&unidades=${x || 0}`));

      if (this.busqueda.almacenes)
        this.busqueda.almacenes.forEach((x) => (url += `&almacenes=${x || 0}`));

      if (this.busqueda.subalmacenes)
        this.busqueda.subalmacenes.forEach((x) => (url += `&subalmacenes=${x || 0}`));  //Para el filtro  

      if (this.busqueda.estados)
        this.busqueda.estados.forEach((x) => (url += `&estados=${x || 0}`));
      return url;
    },
    formTitle() {
      return this.editedIndex === -1 ? "Nuevo Artículo" : "Editar Artículo";
    },
    colSize() {
      return 12 / this.unidadConversion.numDimensiones;
    },
  },

  watch: {
    dialog(val) {
      val || this.close();
    },
    costo() {
      if (!isNaN(parseFloat(this.costo)) && parseFloat(this.costo) > 0)
        this.habilitarBtnVenta = true;
      else this.habilitarBtnVenta = false;
    },
  },

  mounted() {
    this.initialize();
  },

  methods: {
    changeSwitchArticuloDependiente({ idArticuloPadre, idReceta, idArea },estado){
      axios
        .put('/Recetas/EditarCriticoReceta',{
          idArticulo: idArticuloPadre,
          idReceta,
          idArea,
          critico: estado
        })
        .then( () => {
          var articulo = this.articulos.find(x => x.idArticulo == idArticulo);
          this.verDependientes(articulo, false);
        })
        .catch( error => {
          console.log(error);
        });
    },
    async changeSwitchArticulo({ idArticulo, estado }, ){
      //console.log(estado);
      var articulo = this.articulos.find(x => x.idArticulo == idArticulo);
      await this.requestEditarCritico(idArticulo, estado);
      if(estado){
        try{          
          var response = await axios.get(`/Articulos/ArticuloDependientes/${idArticulo}`);
          var dependientesDesactivados = response.data.dependientes.filter(d => !d.critico).length;
          if (dependientesDesactivados > 0){
            
            this.verDependientes(articulo);
          };
        }
        catch(error){
          console.log(error);
        }
      }
    },
    async requestEditarCritico(idArticulo, estado){
      try
      {
        await axios
        .put('/Articulos/EditarCritico',{
          idArticulo,
          estado
        });
        this.getArticulosAction(this.articulos_actual_page);
      }
      catch(error){
        console.log(error);
      }
    },
    getClases(){
      this.loadingClases = true;
      axios
        .get('/Articulos/ListarArticuloClase')
        .then( response => {
          this.clases = response.data;
        })
        .catch( error => {
          console.log(error);
        })
        .finally( () => {
          this.loadingClases = false;
        })
    },
    cambioFicheroCompras(event) {
      if (typeof event === undefined || event === null)
        this.ficheroCarga = null;
      else this.ficheroCarga = event;
    },
    descargarFicheroCompras() {
      var datetime = moment().format("YYYYMMDDHHmmss");
      this.$utils.axios.downloadFile(
        "/Articulos/CsvPrecioVenta",
        "GET",
        "reporte-precio-compras-" + datetime + ".csv",
        "text/csv"
      );
      this.loadingDescargar = true;
      axios
        .get("/Articulos/CsvPrecioVenta")
        .then(() => {
          this.loadingDescargar = false;
        })
        .catch((error) => {
          this.loadingDescargar = false;
          console.log(error);
        });
    },
    cargaMasivaPost() {
      this.loadingCargaMasiva = true;
      if (this.ficheroCarga !== null && this.ficheroCarga !== undefined) {
        let formData = new FormData();
        formData.append("reportePrecios", this.ficheroCarga);
        axios
          .post("/Articulos/CsvprecioVentaRegistro", formData, {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          })
          .then(() => {
            this.cargaCargaCompleta = true;
            this.loadingCargaMasiva = false;
          })
          .catch((error) => {
            console.log(error);
            this.errorCargaMasiva = true;
            this.loadingCargaMasiva = false;
          });
      }
    },
    cerrarDialogDep() {
      this.dialogDep = false;
      this.dependientes = [];
      this.dependienteItem = Object.assign({}, this.defaultItem);
    },
    computedDependientes(item) {
      return (
        (item.produccion == true || item.receta == true) &&
        item.idArticuloTipo != 3 &&
        item.idArticuloTipo != null
      );
    },
    verDependientes(item, enableLoading = true) {
      this.dependienteItem = item;
      this.dialogDep = true;
      if(enableLoading){
        this.dependientes = [];
        this.loadingDep = true;
      }
      this.enableSwitchArticDep = false;
      axios
        .get(`/Articulos/ArticuloDependientes/${item.idArticulo}`)
        .then((response) => {
          this.dependientes = response.data.dependientes.map( dependiente => ({
            ...dependiente,
            critico: response.data.critico ? dependiente.critico : response.data.critico,
            idArticuloPadre: item.idArticulo
          }));
          this.enableSwitchArticDep = response.data.critico;
          if(enableLoading) this.loadingDep = false;
        })
        .catch((error) => console.log(error));
    },
    cambioUnidad(event) {
      if (event >= 1) {
        var unidadAux = this.unidades.find((u) => u.idUnidad == event);
        if (unidadAux == null) return;
        this.unidadConversion = Object.assign({}, unidadAux);
      } else {
        this.conversion = false;
        this.unidadConversion = Object.assign({}, this.defaultUnidadConversion);
      }
    },
    cerrarCalculadora() {
      this.showCalculadoraRollos = false;
      this.calculadoraRollos.gramaje = 0.0;
      this.calculadoraRollos.anchura = 0.0;
    },
    fixFloat(value, decimals = 2) {
      return parseFloat(value.toFixed(decimals));
    },
    validFloat(value) {
      return isNaN(parseFloat(value)) ? 0 : parseFloat(value);
    },
    factorConversion() {
      var x = this.validFloat(this.editedItem.ejeX);
      var y = this.validFloat(this.editedItem.ejeY);
      var z = this.validFloat(this.editedItem.ejeZ);

      let valor = 0;
      if (this.unidadConversion.numDimensiones === 2) {
        valor = x * y;
      }

      if (this.unidadConversion.numDimensiones == 3) {
        valor = x * y * z;
      }

      this.editedItem.factorConversion = this.fixFloat(valor, 4);
    },
    cambioTipo() {      
      var event = this.editedItem.idArticuloTipo;
      if (event != 3) {
        if (this.ultimoTipo == 3) {
          this.multiplesFamilias = true;
          let x = this.editedItem.idsArticuloFamilias;
          this.editedItem.idsArticuloFamilias = [];
          if (x != null && typeof x !== "undefined")
            this.editedItem.idsArticuloFamilias.push(x);
        }
      } else {
        this.editedItem.idsArticuloFamilias = []
        this.multiplesFamilias = false;
        let x = this.editedItem.idsArticuloFamilias;
        if (
          typeof this.editedItem.idsArticuloFamilias !== "undefined" &&
          x.length > 0
        ) {
          this.editedItem.idsArticuloFamilias = x[0];
        } else {
          this.editedItem.idsArticuloFamilias = -1;
        }
      }

      var tipo = this.tipos.find((c) => c.idArticuloTipo == event);
      if (tipo == null) {
        this.materiaPrima = false;
      } else {
        this.materiaPrima = tipo.produccion;
      }
      this.ultimoTipo = event;
    },
    resetBusqueda() {
      this.busqueda.nombre = "";
      this.busqueda.categorias = [];
      this.busqueda.clases = [];
      this.busqueda.tipos = [];
      this.busqueda.codigo = "";
      this.busqueda.unidades = [];
      this.busqueda.estados = [];
      this.busqueda.subalmacenes = []; //Para el filtro
      this.getArticulosAction();
    },
    getTags() {
      axios
        .get("/Articulos/ListTag")
        .then((response) => {
          this.tags = response.data.map((etiqueta) => ({
            idTag: etiqueta.idTag,
            tag: etiqueta.nombre,
          }));
        })
        .catch((error) => {
          console.log(error);
        });
    },
    async initialize() {
      let me = this;
      this.getCategorias();
      this.getTipos();
      this.getClases();
      this.getFamilias();
      this.getUnidades();
      this.getSubalmacenes(); //Nuevo en el filtro
      this.getTags();
      this.loading = true;
      try {
        let response = await this.getArticulos();
        this.articulos = response.data.result;
        this.articulos_total = response.data.total;
        this.loading = false;
      } catch (exception) {
        console.error(exception);
      }
    },
    articulos_sort_by_action($sort) {
      //console.log("sort by(checar evento sort-desc): ", $sort);
    },
    dialog_busqueda_keyevent_action($event) {
      if ($event.keyCode === 27) this.avanzada = false;
      if ($event.keyCode === 13) this.getArticulosAction();
    },
    clearSearchAction() {
      this.search = "";
      this.getArticulosAction();
    },
    getArticulos() {
      //* Realizamos peticion HTTP
      return new Promise((resolve, reject) => {
        axios
          .get(this.articulos_fetch_url)
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },
    async getArticulosAction(actualPage = 1) {
      this.loading = true;
      this.articulos_actual_page = actualPage;
      try {
        let response = await this.getArticulos();
        this.articulos = response.data.result;
        this.articulos_total = response.data.total;
        this.loading = false;
      } catch (exception) {
        console.error(exception);
      }
    },
    getArticulosDebounce: debounce(async function () {
      this.loading = true;
      this.articulos_actual_page = 1;
      try {
        let response = await this.getArticulos();
        this.articulos = response.data.result;
        console.log(this.articulos)
        this.articulos_total = response.data.total;
        this.loading = false;
      } catch (exception) {
        console.error(exception);
      }
    }, 750),
    getArticulosDebounceAction($event) {
      if ($event instanceof KeyboardEvent) {
        //  Enter y Tab
        if ($event.keyCode === 13 || $event.keyCode === 9) return;
        //  Flechas teclado
        if ($event.keyCode >= 37 && $event.keyCode <= 40) return;
      }
      this.getArticulosDebounce();
    },
    getArticulosOnEnterAction($event) {
      if ($event instanceof KeyboardEvent) {
        //  Enter
        if ($event.keyCode === 13) this.getArticulosAction();
      }
    },
    getCategorias() {
      let me = this;
      me.loadingCategoria = true;
      axios
        .get("/ArticuloCategorias")
        .then((response) => {
          me.categorias = response.data;
          me.categorias.push({
            idArticuloCategoria: null,
            nombre: "SIN ASIGNAR",
            descripcion: "SIN ASIGNAR",
            condicion: true,
          });
          me.loadingCategoria = false;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    getFamilias() {
      let me = this;
      me.loadingFamilia = true;
      axios
        .get("/ArticuloFamilias")
        .then((response) => {
          me.familias = response.data;
          me.loadingFamilia = false;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    getTipos() {
      let me = this;
      me.loadingTipo = true;
      axios
        .get("/ArticuloTipos")
        .then((response) => {
          me.tipos = response.data;
          me.tipos.push({
            idArticuloTipo: null,
            nombre: "SIN ASIGNAR",
            vacio: false,
            descripcion: "SIN ASIGNAR",
            receta: false,
            condicion: true,
          });
          me.loadingTipo = false;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    getUnidades() {
      let me = this;
      me.loadingUnidad = true;
      axios
        .get("/Unidades")
        .then((response) => {
          me.unidades = response.data;
          me.unidadesonly = response.data;
          me.loadingUnidad = false;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    getSubalmacenes() {  //Se agregó para el filtro
      let me = this;
      me.loadingSubalmacen = true;
      axios
        .get("/Subalmacenes")
        .then((response) => {
          me.subalmacenes = response.data;
          me.loadingSubalmacen = false;
        })
        .catch((error) => {
          console.error(error);
        });
    },
    editItem(item) {
      this.action = 1;
      this.editedIndex = this.articulos.indexOf(item);
      this.editedItem = Object.assign({}, item);
      //this.cambioTipo(this.editedItem.idArticuloTipo);
      this.dialog = true;
      this.chkey += 1;
    },
    guardarDialogVenta() {
      this.loadingVenta = true;
      this.itemVenta.costo = parseFloat(this.costo);
      axios
        .post("/Articulos/ArticuloCostoVenta", this.itemVenta)
        .then(() => {
          this.loadingVenta = false;
          this.dialogVenta = false;
          this.costo = 0;
        })
        .catch((error) => {
          this.loadingVenta = false;
          console.log(error);
        });
    },
    cerrarDialogVenta() {
      this.dialogVenta = false;
      this.costo = 0;
      this.itemVenta = Object.assign({}, this.defaultItemVenta);
    },
    costoVenta(item) {
      this.itemVenta = Object.assign({}, this.defaultItemVenta);
      this.itemVenta.idArticulo = item.idArticulo;
      this.dialogVenta = true;
    },
    deleteItem(item) {
      this.eliminar = true;
      this.delItem = item;
    },
    deleteItemDB() {
      let me = this;
      let item = this.delItem;
      this.delItem = "";
      axios
        .put("/Articulos/Desactivar/" + item.idArticulo)
        .then((response) => {
          me.initialize();
        })
        .catch((error) => {
          console.log(error);
        });
    },
    close() {
      (this.action = 0), (this.dialog = false);
      setTimeout(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      }, 300);
    },
    makeValidation(string, min, max, msgError) {
      if (string.length < min || string.length > max) {
        return msgError;
      } else return null;
    },
    validate() {
      this.errores = [];
      try {
      if (
        this.editedItem.idArticuloCategoria <= 0 ||
        this.editedItem.idArticuloCategoria == null
      ) {
        this.errores.push("Debe de seleccionar una Categoría de Artículo");
      }

      if (this.editedItem.nombre  == null)
        this.errores.push("Agregue un nombre de artículo");
       else if(this.editedItem.nombre.length <= 0)
      this.errores.push("Agregue un nombre de artículo");

      if (this.editedItem.codigo  == null)
        this.errores.push("Agregue un código de artículo");
      else if(this.editedItem.codigo.length > 50)
      this.errores.push("El código solo admite 50 caracteres como máximo");
       else if(this.editedItem.codigo.length <= 0)
      this.errores.push("Agregue un código de artículo");

      if(this.editedItem.idUnidad <= 0 || this.editedItem.idUnidad == null)
      {
          this.errores.push("Seleccionar una unidad");
          }

      if (
        this.editedItem.idArticuloTipo <= 0 ||
        this.editedItem.idArticuloTipo == null
      ) {
        this.errores.push("Debe de seleccionar un Tipo de Artículo");
      }
      
      if (this.editedItem.idArticuloTipo == 3 && (this.editedItem.idsArticuloFamilias == -1 || this.editedItem.idsArticuloFamilias == null)) {
        if(this.errores.indexOf("Debe de seleccionar una Familia") == -1) this.errores.push("Debe de seleccionar una Familia");
      } else if (this.editedItem.idArticuloTipo == 3 && this.editedItem.idsArticuloFamilias == null ) {
        if(this.errores.indexOf("Debe de seleccionar una Familia") == -1) this.errores.push("Debe de seleccionar una Familia");
      } else if (this.editedItem.idArticuloTipo == 3 && this.editedItem.idsArticuloFamilias.length < 1 ) {
        if(this.errores.indexOf("Debe de seleccionar una Familia") == -1) this.errores.push("Debe de seleccionar una Familia");
      }

      if(this.editedItem.idUnidadAuxiliar > 0 || this.editedItem.idUnidadAuxiliar != null){
        if(this.editedItem.idUnidad == this.editedItem.idUnidadAuxiliar) this.errores.push("La unidad no puede ser igual a la unidad auxiliar");
      }
      
      } catch (error) {
            console.error(error);
      }
      return this.errores.length == 0;
    },
    save() {
      this.action = 0;
      
      if(!this.validate()) return
      
        let me = this;
        me.saving = true;
        let t = [];
        if (me.editedItem.idArticuloTipo == 3) {
      if (Array.isArray(me.editedItem.idsArticuloFamilias)) {
          t.push(...me.editedItem.idsArticuloFamilias);
        } else {
          t.push(me.editedItem.idsArticuloFamilias);
        }
      }

        let ids = [];
        let tags = [];
        if(this.editedItem.tags.length > 0)
          this.editedItem.tags.forEach((etiqueta) => {
            if (etiqueta.idTag != undefined) ids.push(etiqueta.idTag);
            else tags.push(etiqueta.tag);
          });
        
        let idsFamiliasArticulos = [];
        /* if(this.editedItem.idsArticuloFamilias > 0)
          idsFamiliasArticulos.push(...this.editedItem.idsArticuloFamilias)
        else
          this.editedItem.idsArticuloFamilias.forEach(element => {
            if(element != null) idsFamiliasArticulos.push(element)
          }); */

          if (Array.isArray(this.editedItem.idsArticuloFamilias)) {
            idsFamiliasArticulos.push(...this.editedItem.idsArticuloFamilias.filter(element => element !== null));
          } else {
            idsFamiliasArticulos.push(this.editedItem.idsArticuloFamilias);
          }
        
        let EjeX = this.editedItem.conversion == true && (this.editedIndex == -1 || this.editedItem.idArticuloTipo == 1 || this.editedItem.idArticuloTipo == 3 || this.editedItem.vender || this.editedItem.produccion == true || this.editedItem.receta == true || this.editedItem.articuloProducir == true) ? this.unidadConversion.numDimensiones == 1 ? this.editedItem.factorConversion : this.validFloat(this.editedItem.ejeX) : null;
        let EjeY = this.editedItem.conversion == true && (this.editedIndex == -1 || this.editedItem.idArticuloTipo == 1 || this.editedItem.idArticuloTipo == 3 || this.editedItem.vender || this.editedItem.produccion == true || this.editedItem.receta == true || this.editedItem.articuloProducir == true) ? this.unidadConversion.numDimensiones >= 2 ? this.validFloat(this.editedItem.ejeY) : null : null;
        let EjeZ = this.editedItem.conversion == true && (this.editedIndex == -1 || this.editedItem.idArticuloTipo == 1 || this.editedItem.idArticuloTipo == 3 || this.editedItem.vender || this.editedItem.produccion == true || this.editedItem.receta == true || this.editedItem.articuloProducir == true) ? this.unidadConversion.numDimensiones == 3 ? this.validFloat(this.editedItem.ejeZ) : null : null;
        let IdsArticuloFamilias = this.editedItem.idArticuloTipo == 3 ? t : idsFamiliasArticulos;
        let IdUnidadAuxiliar = this.editedItem.conversion ? this.editedItem.idUnidadAuxiliar : null;
        let FactorConversion = this.editedItem.conversion ? this.editedItem.factorConversion : null;
        let conversion = this.editedItem.conversion;
              
        const formData = new FormData();
        try {
            formData.append("IdUnidad", me.editedItem.idUnidad);
            formData.append("Nombre", me.editedItem.nombre);
            formData.append("Codigo", me.editedItem.codigo);
            formData.append("IdArticuloCategoria", me.editedItem.idArticuloCategoria);
            formData.append("IdArticuloTipo", me.editedItem.idArticuloTipo);
            IdsArticuloFamilias.forEach(element => {
              if(element > 0) formData.append("IdsArticuloFamilias", element);
            });
            formData.append("Conversion", conversion);
            formData.append("salidaAutomatica", me.editedItem.salidaAutomatica == null ? false : me.editedItem.salidaAutomatica);        
            if(IdUnidadAuxiliar != null) formData.append("IdUnidadAuxiliar", IdUnidadAuxiliar);
            if(this.editedItem.idArticuloClase > 0) formData.append("idArticuloClase", this.editedItem.idArticuloClase);
            if(FactorConversion != null) formData.append("FactorConversion", FactorConversion);
            if(EjeX != null) formData.append("EjeX", EjeX);
            if(EjeY != null) formData.append("EjeY", EjeY);
            if(EjeZ != null) formData.append("EjeZ", EjeZ);

            if(ids.length > 0) ids.forEach(element => {
                  if(element > 0) formData.append("IdTags", element);
                });

                if(tags.length > 0) tags.forEach(element => {
                  if(element != '') formData.append("Tags", element);
                });

            formData.append("imagen", me.editedItem.imagen);
          } catch (error) {
            console.error(error);
          }

        if (this.editedIndex == -1) {
          axios
          .post("/Articulos/CrearNevoArticulo/", formData, {
				              	headers: {
				              		"Content-Type": "multipart/form-data",
				              	},
				              })
          .then((response) => {
            me.saving = false;
            me.close();
            me.initialize();
          })
          .catch((error) => {
            console.log(error)
            me.saving = false;
            if (error.response) {
              if (error.response.status == 409) {
                me.alerta = true;
                me.errores.push(
                  "Existe un Artículo Activo con el Mismo Nombre o Código"
                );
              }
            }
          });
        }else{
          axios
              .put("/Articulos/ActualizarProduccion/" +
              me.articulos[me.editedIndex].idArticulo,
                formData,{
                  headers: {
				           		"Content-Type": "multipart/form-data",
				           	},
                }
              ).then((response) => {
            me.saving = false;
            me.close();
            me.initialize();
          })
          .catch((error) => {
            me.saving = false;
            if (error.response) {
              if (error.response.status == 409) {
                me.alerta = true;
                me.errores.push(
                  "Existe un Artículo Activo con el Mismo Nombre o Código"
                );
              }
            }
          });
        }
        
      
    },
    async articulos_update_page_action(page) {
      this.articulos_actual_page = page;
      this.loading = true;
      try {
        let response = await this.getArticulos();
        this.articulos = response.data.result;
        this.articulos_total = response.data.total;
        this.loading = false;
      } catch (exception) {
        console.log(exception);
      }
    },
    hideFactorConversion(){
      var obj = this.tipos.filter(a=> a.idArticuloTipo == this.editedItem.idArticuloTipo)[0].receta
      this.HideConversionfactor = obj;
    }
  },
};
</script>

<style>
.dialog-busqueda {
  border-radius: 0 !important;
  margin: 0 !important;
  height: 100% !important;
  max-height: 100% !important;
  position: absolute !important;
  overflow-y: auto !important;
  top: 0 !important;
  right: 0 !important;
}
.card-busqueda {
  max-height: 100% !important;
  height: 100% !important;
}

.card-actions-busqueda {
  position: absolute;
  bottom: 0;
  right: 0;
}

.no-padding {
  padding-top: 0px !important;
  padding-bottom: 0px !important;
  margin-top: auto;
  margin-bottom: auto;
}

.v-progress-circular {
  margin: 1rem;
}
</style>