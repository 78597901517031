<template>
	<v-col cols="12" v-if="permisoVista('planos','r')">
		<alert-dialog 
			@close="cerrarAlerta" 
			icon="mdi-file-pdf"
			ref="alerta"
		></alert-dialog>
		<signalr path="/hub/planos" @change="cambios"></signalr>
		<v-dialog v-model="dialogBorrar" max-width="40%" persistent>
			<v-card>
				<v-card-title
					>¿Está seguro que desea eliminar este Plano?</v-card-title
				>
				<v-card-text
					>Esta acción no se puede revertir y será
					permanente.</v-card-text
				>
				<v-card-actions>
					<v-spacer></v-spacer>
					<v-btn
						color="green darken-1"
						text
						:disabled="loadingBorrado"
						@click="cerrarBorrado()"
						>Cancelar</v-btn
					>
					<v-btn
						color="red darken-1"
						text
						:disabled="loadingBorrado"
						:loading="loadingBorrado"
						@click="borrarPlano()"
						>Aceptar</v-btn
					>
				</v-card-actions>
			</v-card>
		</v-dialog>
		<v-dialog v-model="newPlano" max-width="50%" persistent>
			<v-card>
				<v-card-title> Nuevo Plano </v-card-title>
				<v-card-text v-if="planoItem.porcentajeCarga === 0">
					<v-col cols="12">
						<v-text-field
							v-model="planoItem.nombre"
							label="Nombre del plano"
							:rules="[rules.nombre]"
						></v-text-field>
					</v-col>
					<v-col cols="12">
						<v-text-field
							v-model="planoItem.descripcion"
							label="Descripcion del plano"
						></v-text-field>
					</v-col>
					<v-col cols="12">
						<v-file-input
							v-model="planoItem.imagen"
							label="Subir imagen"
							accept=".jpg,.jpeg,.png,.gif,.pdf"
							counter
							show-size
							@change="cambioFicheroPlano"
						></v-file-input>
					</v-col>
				</v-card-text>
				<v-card-text v-if="planoItem.porcentajeCarga > 0">
					<v-col
						v-if="Math.ceil(planoItem.porcentajeCarga) < 100"
						cols="12"
						class="text-subtitle-1 text-center"
					>
						Guardando el Plano
					</v-col>
					<v-col
						v-if="Math.ceil(planoItem.porcentajeCarga) == 100"
						cols="12"
						class="text-subtitle-1 text-center"
					>
						Se ha guardado el Plano
					</v-col>
					<v-col cols="12">
						<v-progress-linear
							v-model="planoItem.porcentajeCarga"
							height="20"
							class="white--text"
						>
							{{ Math.ceil(planoItem.porcentajeCarga) }}%
						</v-progress-linear>
					</v-col>
				</v-card-text>
				<v-card-actions>
					<v-spacer></v-spacer>
					<v-btn
						v-if="planoItem.porcentajeCarga > 0"
						color="red"
						text
						:disabled="savingPlano"
						@click="cancelNewPlano"
						>Cerrar</v-btn
					>
					<v-btn
						v-if="planoItem.porcentajeCarga === 0"
						color="green"
						text
						:disabled="savingPlano"
						@click="cancelNewPlano"
						>Cancelar</v-btn
					>
					<v-btn
						v-if="planoItem.porcentajeCarga === 0"
						color="red"
						text
						:disabled="savingPlano || planoItem.imagen === null"
						:loading="savingPlano"
						@click="guardarNewPlano"
						>Guardar</v-btn
					>
				</v-card-actions>
			</v-card>
		</v-dialog>
			<v-col cols="12">
				<v-btn v-if="permisoVista('planos','c')" color="primary" @click="nuevoPlano()"
					>Agregar Nuevo Plano</v-btn
				>
			</v-col>
			<div class="d-flex justify-center">
					<v-select
						class="pl-3"
						v-model="currentPlano"
						:items="planos"
						:item-text="nombrePlano"
						item-value="idPlano"
						label="Plano"
						@change="planoActual"
					></v-select>
					<v-btn
						v-if="permisoVista('planos','d')"
						color="red"
						text
						class="ml-2"
						v-show="currentPlano !== null && planos.length > 0"
						@click="showBorrarPlano()"
						>Borrar Plano</v-btn
					>
			</div>
			<v-col cols="12">
				<Plano 
					v-show="planos.length > 0" 
					@refresh="actualizarPuntos" 
					ref="plane"
					@verIncidencia="detalleIncidencia"
				/>
			</v-col>
	</v-col>
</template>

<script>
import Plano from "./Planos/Plano.vue";
import axios from "axios";
import signalr from "@/components/SignalR";
import AlertDialog from '../../AlertDialog.vue';

export default {
	components: {
		Plano,
		signalr,
		AlertDialog,
	},
	data() {
		return {
			loadingBorrado: false,
			dialogBorrar: false,
			currentPlano: null,
			savingPlano: false,
			plano: null,
			planoItem: {
				nombre: "",
				descripcion: "",
				imagen: null,
				porcentajeCarga: 0,
			},
			defaultPlanoItem: {
				nombre: "",
				descripcion: "",
				imagen: null,
				porcentajeCarga: 0,
			},
			planos: [],
			newPlano: false,
			rules: {
				nombre: (value) => {
					return (
						value.length >= 5 ||
						"El nombre del plano debe tener al menos 5 caracteres"
					);
				},
			},
		};
	},
	created() {
		this.initialize();
	},
	methods: {
		alertaPDF(){
			this.$refs.alerta.show("Convirtiendo PDF a Imagen","Por favor espere unos minutos...");
		},
		detalleIncidencia(idArea){
			this.$emit( "detalleIncidencia",idArea );
		},
		nombrePlano: plano => 
			(plano.descripcion === null || plano.descripcion === "")
			? `${plano.nombre}`
			: `${plano.nombre} - ${plano.descripcion}`,
		cambioFicheroPlano(event){
			if (typeof event === "undefined" || event === null)
				this.planoItem.imagen = null;
			else this.planoItem.imagen = event;
		},
		actualizarPuntos(idPlano){
			axios
				.get("/Planos")
				.then((response) => {
					this.planos = response.data;
					this.currentPlano = idPlano;
					this.planoActual();
				})
				.catch((error) => {
					console.log(error);
				});
		},
		showBorrarPlano() {
			this.dialogBorrar = true;
		},
		cerrarBorrado() {
			this.dialogBorrar = false;
		},
		borrarPlano() {
			this.loadingBorrado = true;
			axios
				.put(`/Planos/EliminarPlano/${this.currentPlano}`)
				.then(() => {
					this.loadingBorrado = false;
					this.initialize();
					this.cerrarBorrado();
				})
				.catch((error) => {
					this.loadingBorrado = false;
					console.log(error);
				});
		},
		planoActual() {
			const plano = this.planos.find(
				(plano) => plano.idPlano === this.currentPlano
			);
			this.$refs.plane.getPlanoImagen(plano);
		},
		initialize() {
			this.getPlanos();
		},
		getPlanos() {
			axios
				.get("/Planos")
				.then((response) => {
					this.planos = response.data;
					if (this.planos.length > 0){
						this.currentPlano = this.planos[this.planos.length-1].idPlano;
						this.planoActual();
					}
				})
				.catch((error) => {
					console.log(error);
				});
		},
		nuevoPlano() {
			this.newPlano = true;
		},
		cancelNewPlano() {
			this.planoItem = Object.assign({}, this.defaultPlanoItem);
			this.newPlano = false;
		},
		guardarNewPlano() {
			this.savingPlano = true;
			let formData = new FormData();
			formData.append("Nombre", this.planoItem.nombre);
			formData.append("Descripcion", this.planoItem.descripcion);
			formData.append("Imagen", this.planoItem.imagen);

			axios
				.post("/Planos/CrearPlano", formData, {
					headers: {
						"Content-Type": "multipart/form-data",
					},
					onUploadProgress: function (progressEvent) {
						this.planoItem.porcentajeCarga = parseInt(
							Math.round(
								(progressEvent.loaded / progressEvent.total) *
									100
							)
						);
					}.bind(this),
				})
				.then((resp) => {
					if( resp.status == 202 ){
						this.cancelNewPlano();
						this.alertaPDF();
					}
					this.savingPlano = false;
					this.initialize();
				})
				.catch((error) => {
					this.savingPlano = false;
					console.log(error);
				});
		},
		cambios(){
			this.$refs.plane.actualizarPuntos();
		}
	},
};
</script>