<template>
  <v-col cols="12">
    <v-row>
      <v-col cols="3">
        <v-autocomplete v-model="filtros.idsTrabajoOrdenes" label="Orden(es) de Trabajo" :loading="loadingODTS"
          :disabled="loadingODTS || loading" :items="ordenesTrabajo" item-text="nombre" item-value="idTrabajoOrden" chips
          multiple deletable-chips clearable hide-details @change="getDatos()"></v-autocomplete>
      </v-col>
      <v-col cols="3">
        <v-autocomplete v-model="filtros.idsArticulos" label="Artículo(s)" :loading="loadingArticulos"
          :disabled="loadingArticulos || loading" :items="articulos" :item-text="nombreArticulo" item-value="idArticulo"
          chips multiple deletable-chips clearable hide-details @change="getDatos()"></v-autocomplete>
      </v-col>
      <v-col cols="3">
        <v-autocomplete v-model="filtros.idsAreas" label="Área(s)" :loading="loadingAreas"
          :disabled="loadingAreas || loading" :items="areas" item-text="nombre" item-value="idArea" chips multiple
          deletable-chips clearable hide-details @change="getDatos()"></v-autocomplete>
      </v-col>
      <v-col cols="3">
        <v-select v-model="filtros.estado" label="Estado" :items="estadosLiberacion" clearable :disabled="loading"
          hide-details @change="getDatos()" style="padding-top: 22px"></v-select>
      </v-col>
    </v-row>
    <v-row v-if="datos.length > 0">
      <v-col cols="4" class="py-0">
        <download-btn color="blue darken-2" text @click="descargarReporte" label="Descargar Reporte<br>de Resultados"
          :disabled="loading"></download-btn>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        <v-data-table :loading="loading" :items="datos" :headers="headers">
          <template v-slot:[`item.`]>

          </template>
          <template v-slot:[`item.fases`]="{ item }">
            <!--{{JSON.stringify(item.fases)}}-->
            <div v-for="(fase, idx) in item.fases" :key="'fase' + idx">
              <div class="text-subtitle-2">{{ fase.posicion }}ª Revisión <v-icon small
                  :color="fase.completa ? 'green' : 'red'">mdi-{{ fase.completa ? "check-circle" : 'clock' }}</v-icon>
                {{ fase.completa ? "Completa" : 'En Progreso' }} </div>
              <v-row v-for="(intento, idxI) in fase.intentos" :key="'inte' + idxI">
                <v-col cols="4">
                  <v-row>
                    <v-col cols="10">
                      <b>Resultado:</b> <v-icon small :color="intento.estado ? 'green' : 'red'">mdi-{{ intento.estado ?
                        "check-circle" : 'close-thick' }}</v-icon>
                      {{ intento.estado ? "Aprobado" : "Rechazado" }}
                      <br>
                      <b>Cantidad:</b> {{ intento.cantidad.toFixed(2) }} {{ item.unidad }}
                    </v-col>
                    <v-col cols="2">
                      <v-tooltip bottom color="black">
                        <template v-slot:activator="{ on, attrs }">
                          <v-icon color="primary" v-bind="attrs" v-on="on" @click="openDialogDetalles(intento)">
                            mdi-eye
                          </v-icon>
                        </template>
                        <span style="color: white;">Ver resultados</span>
                      </v-tooltip>
                    </v-col>
                  </v-row>
                </v-col>
                <v-col cols="4">
                  <b>Inicio:</b> {{ intento.inicio }}
                  <br>
                  <b>Fin:</b> {{ intento.fin }}
                </v-col>
                <v-col cols="4">
                  <b>Realizado Por:</b>
                  <br>
                  <ul>
                    <li v-for="(persona, idxP) in intento.participantes" :key="'per' + idxP">{{ persona }}</li>
                  </ul>
                </v-col>
              </v-row>
              <br>
            </div>
          </template>
        </v-data-table>
      </v-col>
    </v-row>

    <v-dialog v-model="dialogDetallesLiberacion" max-width="85%" persistent>
      <v-card>
        <v-card-title>
          Detalles de Liberación de la Producción
        </v-card-title>
        <v-card-text>
          <v-container v-for="(seccion, idxS) in preguntas" :key="'s'+idxS">
            <center>
              <v-card-subtitle style="font-weight: bold;">
                {{ seccion.nombreSeccion }}
              </v-card-subtitle>
            </center>
            <v-data-table 
              :items="seccion.preguntas" 
              :headers="headersPreguntas"
              :items-per-page="-1"
              hide-default-footer="false" disable-sort>
              <template v-slot:[`item.resultado`]="{ item }">
                <v-icon :color="colorIconoResultado(item, intento.estado)">{{ iconoResultado(item, intento.estado) }}</v-icon>
              </template>

              <template v-slot:[`item.texto`]="{ item }">
                {{ item.texto }}
              </template>

              <template v-slot:[`item.tipoComprobacion`]="{ item }">
                <br>
                <span v-if="item.tipoComprobacion === 1"> Dicotómica (Si o No)</span>
                <span v-if="item.tipoComprobacion === 2"> Valor Numérico</span>
                <br><br>
                <v-col class="pa-0" v-if="item.tipoComprobacion == 2">
                  <div class="d-flex">
                    <v-col cols="3" class="px-1 d-flex justify-center">
                      Minimo: {{ item.minimo }} <span v-if="item.idUnidad === 1">PZA</span>
                    </v-col>
                    <v-col cols="6" class="px-1 pt-6 pb-0 d-flex justify-center">
                      <v-range-slider :value="[item.minimoAceptado, item.maximoAceptado]" :min="item.minimo"
                        :max="item.maximo" hide-details class="pt-2 align-center" :step="step" thumb-label="always"
                        thumb-size="40" :key="sliderKey" readonly />
                    </v-col>
                    <v-col cols="3" class="px-1 d-flex justify-center">
                      Maximo: {{ item.maximo }} <span v-if="item.idUnidad === 1">PZA</span>
                    </v-col>
                  </div>
                </v-col>
                <v-col v-if="item.tipoComprobacion == 2" class="d-flex justify-space-between pa-0">
                  <v-col cols="3" class="px-1 pt-0">
                    Min Aceptado: {{ item.minimoAceptado }}
                  </v-col>
                  <v-col cols="3" class="px-1 pt-0">
                    Max Aceptado: {{ item.maximoAceptado }}
                  </v-col>
                </v-col>
              </template>

              <template v-slot:[`item.respuesta`]="{ item }">
                {{ respuestaPregunta(item) }}
              </template>
            </v-data-table>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-btn color=" primary" class="ml-auto" @click="closeDialogDetalles" text>Cerrar</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-col>
</template>

<script>
import axios from "axios";
import qs from "qs";
import DownloadBtn from '../../../DownloadBtn.vue';
import moment from 'moment';
import { mapState } from 'vuex';

export default {
  components: { DownloadBtn },
  data: () => ({
    estadosLiberacion: [
      {
        text: "Completadas",
        value: true
      },
      {
        text: "En Progreso",
        value: false
      }
    ],
    filtros: {
      idsTrabajoOrdenes: [],
      idsArticulos: [],
      idsAreas: [],
      estado: null
    },
    loadingAreas: false,
    areas: [],
    loadingArticulos: false,
    articulos: [],
    ordenesTrabajo: [],
    loadingODTS: false,
    loading: false,
    loadingPreguntas: false,
    datos: [],
    headers: [
      {
        text: "Orden de Trabajo",
        align: "center",
        sortable: true,
        value: "trabajoOrden",
        width: "20%"
      },
      {
        text: "Artículo",
        align: "center",
        sortable: true,
        value: "articulo",
        width: "20%"
      },
      {
        text: "Fases",
        align: "center",
        sortable: false,
        value: "fases",
        width: "60%"
      },
    ],
    headersPreguntas: [
      {
        text: "Resultado",
        align: "center",
        value: "resultado",
        width: "15%"
      }, {
        text: "Comprobación",
        align: "center",
        value: "texto",
        width: "20%"
      }, {
        text: "Tipo de Comprobación",
        align: "center",
        value: "tipoComprobacion",
        width: "50%"
      }, {
        text: "Respuesta",
        align: "center",
        value: "respuesta",
        width: "15%"
      }
    ],
    step: 0.001,
    dialogDetallesLiberacion: false,
    preguntas: [],
    intento: {
      cantidad: -1,
      duracionSegundos: -1,
      estado: false,
      fin: "2023-01-01 00:00:00",
      inicio: "2023-01-01 00:00:00",
      participantes: []
    },
    defaultIntento: {
      cantidad: -1,
      duracionSegundos: -1,
      estado: false,
      fin: "2023-01-01 00:00:00",
      inicio: "2023-01-01 00:00:00",
      participantes: []
    }
  }),
  computed: {
    ...mapState(['token']),
  },
  mounted() {
    this.initialize();
  },
  methods: {
    nombreArticulo: ({ codigo, nombre }) => codigo != null ? `[${codigo}] - ${nombre}` : `${nombre}`,
    descargarReporte() {
      var datetime = moment().format("YYYYMMDDHHmmss");
      axios({
        url: `/Liberacion/ListarProcesosXlsx`,
        params: {
          ...this.filtros,
          token: this.token,
        },
        paramsSerializer: (params) =>
          qs.stringify(params, { indices: false, skipNulls: true }),
        method: "GET",
        responseType: "blob",
      })
        .then((response) => {
          let blob = new Blob([response.data], {
            type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
          });
          let a = document.createElement("a");
          let blobURL = URL.createObjectURL(blob);
          a.download = `reporte-resultados-liberacion-produccion-${datetime}.xlsx`;
          a.href = blobURL;
          document.body.appendChild(a);
          a.click();
          document.body.removeChild(a);
        })
        .catch((error) => {
          console.log(error);
        });
    },
    initialize() {
      this.getDatos();
      this.getOrdenesTrabajo();
      this.getArticulos();
      this.getAreas();
    },
    getAreas() {
      this.loadingAreas = true;

      axios
        .get('/Areas')
        .then(response => {
          this.areas = response.data.filter(area => area.idArea > 1);
        })
        .catch(console.log)
        .finally(() => {
          this.loadingAreas = false;
        })
    },
    getArticulos() {
      this.loadingArticulos = true;

      axios
        .get('/Articulos/ArticulosNombres', {
          params: {
            componentes: true,
            activo: true
          }
        })
        .then(response => {
          this.articulos = response.data;
        })
        .catch(console.log)
        .finally(() => {
          this.loadingArticulos = false;
        })
    },
    getOrdenesTrabajo() {
      this.loadingODTS = true;

      axios
        .get('/TrabajoOrdenes/ListarOT', {
          params: {
            otsCerradas: true
          }
        })
        .then(response => {
          this.ordenesTrabajo = response.data;
        })
        .catch(console.log)
        .finally(() => {
          this.loadingODTS = false;
        })
    },
    getDatos() {
      this.loading = true;
      axios
        .get("/Liberacion/ListarLiberacionesProcesos", {
          params: this.filtros,
          paramsSerializer: (params) =>
            qs.stringify(params, { indices: false, skipNulls: true }),
        })
        .then(response => {
          this.datos = response.data;
        })
        .catch(console.log)
        .finally(() => {
          this.loading = false;
        })
    },

    openDialogDetalles(intento) {
        this.loadingPreguntas = true;
        this.dialogDetallesLiberacion = true;
        this.intento = intento;
        axios
        .get("/Liberacion/ConsultarDetalles/" + intento.idListaComprobacionAplicada)
        .then((response) => {
          this.preguntas = response.data;
          
          console.log(response.data)
        })
        .catch((error) => {
          console.log(error)
        })
        .finally(() => {
          this.loadingPreguntas = false;
        })
    },
    closeDialogDetalles(){
      this.dialogDetallesLiberacion = false;
      this.preguntas = [];
      this.loadingPreguntas = false;
      this.intento = Object.assign({}, this.defaultIntento);
    },
    iconoResultado(item, estado) {
      if(estado) return 'mdi-check'
      else if (item.resultadoNumero) {
        return item.resultadoNumero >= item.minimoAceptado && item.resultadoNumero <= item.maximoAceptado ? 'mdi-check' : 'mdi-close'
      } else {
        return item.resultadoBool ? 'mdi-check' : 'mdi-close'
      }
    },
    colorIconoResultado(item, estado) {
      if(estado) return 'success'

      else if (item.resultadoNumero) {
        return item.resultadoNumero >= item.minimoAceptado && item.resultadoNumero <= item.maximoAceptado ? 'success' : 'error'
      } else {
        return item.resultadoBool ? 'success' : 'error'
      }
    },
    respuestaPregunta(item, estado) {
      if(estado) return "Sí"
      else if (item.resultadoNumero) {
        return item.resultadoNumero
      } else {
        return item.resultadoBool ? "Si" : "No"
      }
    }
  }
}
</script>