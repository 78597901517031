<template>
  <v-dialog v-model="show" fullscreen hide-overlay transition="dialog-bottom-transition">
      <v-card>
        <v-toolbar dark color="primary">
          <v-btn icon dark @click="close()">
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <div v-if="clave == null" class="headline">
            Gantt de la ODT-{{idTrabajoOrden}}
          </div>
          <div v-else class="headline">
            Gantt de la ODT con Clave {{clave}}
          </div>
          <v-spacer></v-spacer>
        </v-toolbar>
        <v-card-text>
          <v-row class="ma-0 pa-0">
            <v-col>
              <v-slider
                v-model="zoom"
                min="0"
                max="5"
                label="Zoom"
                dense
                hide-details
                @change="timeInterval"
              ></v-slider>
            </v-col>
          </v-row>
          <FullCalendar
            ref="calendar"
            schedulerLicenseKey="GPL-My-Project-Is-Open-Source"
            :events="events"
            :resources="resources"
            :hiddenDays="hiddenDays"
            :minTime="minTime"
            :maxTime="maxTime"
            :slotWidth="fullcalendar.slotWidth"
            :slotDuration="fullcalendar.slotDuration"
            :locale="fullcalendar.locale"
            :editable="fullcalendar.editable"
            :plugins="fullcalendar.calendarPlugins"
            :now="fullcalendar.now"
            :aspectRatio="fullcalendar.aspectRatio"
            :scrollTime="fullcalendar.scrollTime"
            :header="fullcalendar.header"
            :defaultView="fullcalendar.defaultView"
            :views="fullcalendar.views"
            :resourceLabelText="fullcalendar.resourceLabelText"
            :resourcesInitiallyExpanded="fullcalendar.resourcesInitiallyExpanded"
            :eventRender="eventRender"
          />
        </v-card-text>
      </v-card>
    </v-dialog> 
</template>

<script>
import FullCalendar from '@fullcalendar/vue'
import resourceTimelinePlugin from '@fullcalendar/resource-timeline'
import interactionPlugin from '@fullcalendar/interaction'
import dayGridPlugin from '@fullcalendar/daygrid'
import esLocale from '@fullcalendar/core/locales/es';
import timeGridPlugin from '@fullcalendar/timegrid'
import listPlugin from '@fullcalendar/list'
import axios from 'axios'
import tippy from 'tippy.js';


export default {
  components: {
    FullCalendar
  },
  data: () => ({
    fullcalendar:{
      slotWidth: 100,
      slotDuration: "04:00",
      nowIndicator: true,
      locale: esLocale,
      resourcesInitiallyExpanded: false,
      calendarPlugins: [ interactionPlugin,dayGridPlugin,resourceTimelinePlugin ],
      now: new Date().toISOString(),
      editable: false, // enable draggable events
      aspectRatio: 1.8,
      scrollTime: new Date().toISOString(), // undo default 6am scrollTime
      header: {
        left: 'today prev,next',
        center: 'title',
        right: '',
        /*right: 'resourceTimelineLaboralDays,resourceTimelineDetails,resourceTimelineWeek'*/
      },
      defaultView: 'resourceTimelineWeek',
      views: {
        resourceTimelineWeek: {
          type: 'resourceTimeline',
          duration: { days: 14 },
          buttonText: 'Semana'
        },
      },
      resourceLabelText: 'Áreas',
    },
    resources: [],
    events: [],
    hiddenDays: [],
    minTime: "00:00:00",
    maxTime: "24:00:00",
    zoom:3,
    intervals:[
      "00:30",
      "01:00",
      "02:00",
      "04:00",
      "08:00",
      "12:00",
    ],
    clave: null,
    idTrabajoOrden:-1,
    show:false,
    time: new Date(),
  }),
  methods:{
    eventRender: function(info) {
      if(info.event.id.split("-").length == 5){
        tippy(info.el, {
          content: this.get_info(info.event),
          allowHTML: true,
        });
      }
    },
    get_info(event){
      var html = '';
      if(event.extendedProps.info != null){
        html += '<strong>Componente:</strong> &nbsp;'+event.extendedProps.info.componente;
        html += '<br>';
        html += '<strong>Cantidad:</strong> &nbsp;'+event.extendedProps.info.cantidad.toFixed(2);
        html += '<br>';
        html += '<strong>Área:</strong> &nbsp;'+event.extendedProps.info.area;
        html += '<br>';
        if(event.extendedProps.info.status == 0){
          html += '<strong>Estado:</strong> &nbsp Operación Planeada';
          html += '<br>';
          html += '<strong>Inicio Planeado:</strong> &nbsp;'+event.extendedProps.info.inicio;
          html += '<br>';
          html += '<strong>Fin Planeado:</strong> &nbsp;'+event.extendedProps.info.fin;
          html += '<br>';
        }
        
        else if(event.extendedProps.info.status == 1){
          html += '<strong>Estado:</strong> &nbsp Operación Estimada';
          html += '<br>';
          html += '<strong>Inicio Estimado:</strong> &nbsp;'+event.extendedProps.info.inicio;
          html += '<br>';
          html += '<strong>Fin Estimado:</strong> &nbsp;'+event.extendedProps.info.fin;
          html += '<br>';
        }

        else{
          html += '<strong>Estado:</strong> &nbsp Operación Realizada';
          html += '<br>';
          html += '<strong>Inicio:</strong> &nbsp;'+event.extendedProps.info.inicio;
          html += '<br>';
          html += '<strong>Finalizo:</strong> &nbsp;'+event.extendedProps.info.fin;
          html += '<br>';
        }
      }
      return html;
    },
    getCalendario(){
      if(this.idTrabajoOrden == -1){
        return;
      }
      axios
        .get("/TrabajoOrdenes/CalendarioPost/"+this.idTrabajoOrden)
        .then(response =>{
          this.time = new Date(response.data.time);
          this.resources = response.data.resources;
          this.events = response.data.events;
          this.hiddenDays = response.data.hiddenDays;
          this.minTime = response.data.minTime;
          this.maxTime = response.data.maxTime;
          this.$refs.calendar.getApi().scrollToTime(this.time);
          this.$refs.calendar.getApi().gotoDate(this.time);
          this.$refs.calendar.$emit('refetch-events');
        })
        .catch(error => {
          console.log(error);
        })
    },
    timeInterval(){
      this.fullcalendar.slotDuration = this.intervals[this.zoom];
      var now = new Date();
      //var rangeStart = this.$refs.calendar.state.dateProfile.renderRange.start;
      // attention here
      //this.$refs.calendar.scrollToTime(now - rangeStart);
    },
    getgantt(idTrabajoOrden, clave){
      this.idTrabajoOrden = idTrabajoOrden;
      this.clave = clave;
      this.show = true;
      this.getCalendario();
    },
    close(){
      this.show = false;
      this.idTrabajoOrden = -1;
    }
  }
}

</script>

<style>

  body {
    margin: 0;
    padding: 0;
    font-family: Arial, Helvetica Neue, Helvetica, sans-serif;
    font-size: 14px;
  }

  #calendar {
    max-width: 900px;
    margin: 50px auto;
  }

</style>

<style lang='scss'>

@import '~@fullcalendar/core/main.css';
@import '~@fullcalendar/timeline/main.css';
@import '~@fullcalendar/resource-timeline/main.css';

</style>