<template>
	<v-col cols="12">
    <v-row v-if="loading">
      <v-col cols="12" class="text-center">
        <v-progress-circular
          size="100"
          color="primary"
          indeterminate
        ></v-progress-circular>
        <br>
        <br>
        <div class="text-h4">Cargando</div>
      </v-col>
    </v-row>
    <v-row v-else-if="!vinculado">
      <v-col cols="12" class="text-center">
        <v-icon
          :size="256"
          color="green"
        >
          mdi-check-circle-outline
        </v-icon>
        <br>
        <span class="text-h4">Su cuenta se encuentra vinculada a Telegram</span>
        <br>
        <span>A partir de este momento podra recibir alertas y mensajes de ManagrX en su dispositivo.</span>
        <br>
        <v-btn @click="desvincular" color="red">Desvincular Cuenta</v-btn>
      </v-col>
    </v-row>
    <v-row v-else-if="vinculado">
      <v-col cols="12" class="text-center">
        <v-icon
          :size="256"
          color="red"
        >
          mdi-close-circle-outline
        </v-icon>
        <br>
        <span class="text-h4">Su cuenta se no encuentra vinculada a Telegram</span>
        <br>
        <br>
        <v-row>
          <v-col cols="5">
            Dar Clic o 
            <v-btn
              color="blue"
              block
              :href="linkTelegram"
              target="_blank"
            >
              <v-icon>mdi-telegram</v-icon> Vincular mi Telegram
            </v-btn>
          </v-col>
          <v-col cols="2">
          </v-col>
          <v-col cols="5">
            Copiar el Enlace
            <v-text-field
              v-model="linkTelegram"
              readonly
              outlined
              append-icon="mdi-content-copy"
              @click:append="copyLink"
            ></v-text-field>
          </v-col>
        </v-row>
        <v-expansion-panels>
          <v-expansion-panel>
            <v-expansion-panel-header>
              Pasos para Vincular mi cuenta de ManagrX a Telegram
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <ol>
                <li class="text-left">Dar Clic en el bóton llamado "Vincular Mi Telegram" o copiar el enlace y abrirlo desde el dispositivo a vincular.</li>
                <br>
                <li class="text-left">Al abrir Telegram, dar clic en "Iniciar"</li>
                <br>
                <v-img src="/img/telegram/paso_2.jpg" max-width="600px"/>
                <br>
                <li class="text-left">A continuación, se debe de enviar un mensaje con la siguiente estructura <b>/login tu_email@empresa.com tu_contraseña_managrx</b></li>
                <br>
                <v-img src="/img/telegram/paso_3.jpg" max-width="600px"/>
                <br>
                <li class="text-left">Finalmente, recibira un mensajee confirmación, indicando que su dispositivo esta correctamente vinculado.</li>
                <br>
                <v-img src="/img/telegram/paso_3.jpg" max-width="600px"/>
              </ol>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
      </v-col>
    </v-row>
	</v-col>
</template>

<script>
import axios from "axios";
import { mapState } from "vuex";

export default {
	components: {
	},
	data() {
		return {
      loading: false,
      vinculado: true
		};
	},
  computed:{
    ...mapState(["linkTelegram"])
  },
  mounted() {
		this.verificarVinculacion();
	},
	methods: {
    verificarVinculacion(){
      
      this.loading = true;
      axios
      .get("/Usuarios/VerificarUsuarioTelegram")
      .then(response => {
        console.log(response.data)
        this.vinculado = response.data;
      })
      .catch(error => {
        this.vinculado = false;
        console.log(error)
      })
      .finally(() => {
        this.loading = false;
      })
     
    },
    desvincular(){
      
      this.loading = true;
      axios
      .put("/Usuarios/DesvincularTelegram")
      .then(response => {
        this.verificarVinculacion();
      })
      .catch(error => {
        console.log(error)
      })
      .finally(() => {
        this.loading = false;
      })
     
    },
    reset(){
      this.verificarVinculacion();
    },
    copyLink(){
      navigator.clipboard.writeText(this.linkTelegram);
    }
	},
};
</script>

<style>
</style>