import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VContainer,{attrs:{"fluid":""}},[_c(VRow,{staticClass:"justify-space-between align-end"},[_c(VCol,{attrs:{"cols":"4"}},[_c('DownloadBtn',{attrs:{"color":"blue darken-1","text":"","className":"my-0","disabled":_vm.loading,"label":"Descargar Reporte de <br>M.P. por Entregar"},on:{"click":function($event){return _vm.descargarReporte()}}})],1),_c(VCol,{attrs:{"cols":"4"}},[_c(VTextField,{attrs:{"label":"Buscar","hide-details":"","clearable":"","prepend-inner-icon":"mdi-magnify"},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1)],1),_c(VRow,[_c(VCol,{attrs:{"cols":"12"}},[_c(VDataTable,{attrs:{"items":_vm.data,"headers":_vm.dataHeaders,"loading":_vm.loading,"search":_vm.search},scopedSlots:_vm._u([{key:"item.articuloProducir",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.getCodigoNombre( item.articuloProducirCodigo, item.articuloProducirNombre ))+" ")]}},{key:"item.almacen",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.getCodigoNombre(item.codigoAlmacen, item.almacen))+" ")]}},{key:"item.articulo",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.getCodigoNombre(item.codigoArticulo, item.articulo))+" ")]}},{key:"item.cantidadEntregar",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.cantidadEntregar.toFixed(2))+" "+_vm._s(item.unidad)+" ")]}},{key:"item.cantidadSalida",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.cantidadSalida.toFixed(2))+" "+_vm._s(item.unidad)+" ")]}},{key:"item.cantidadRestante",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.cantidadRestante.toFixed(2))+" "+_vm._s(item.unidad)+" ")]}}],null,true)})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }