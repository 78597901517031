<template>
<v-col class="padding-2px">
    <v-container>
    <v-text-field
        v-model="search"
        :label="'Buscar en '+headerText"
        append-icon="search"
        single-line
        hide-details
    ></v-text-field>
    </v-container>
    <div class="board-column">
        <v-col :class="columnClass">
            {{headerText}}
        </v-col>
        <draggable
            :list="listFiltered"
            v-bind="$attrs"
            class="board-column-content"
            :set-data="setData"
        >
            <div  v-for="item in listFiltered" :key="item.id" class="board-item">
                <div v-if="!remove" class="container">
                  <div v-if="!surplus">
                    <v-row class="padding-2px">
                      <v-col cols="10" sm="10" md="10" class="padding-2px text-center-vertical align-self-center">
                        {{ itemText(item) }}
                      </v-col>
                      <v-col cols="2" sm="2" md="2" class="padding-2px">
                        <v-icon
                            size="24px"
                            @click="itemAdd(item)"
                          >
                            mdi-plus-circle-outline
                          </v-icon>
                      </v-col> 
                    </v-row>
                  </div>
                  <div v-else>
                    <v-row class="padding-2px">
                      <v-col cols="6" sm="6" md="6" class="padding-2px text-center-vertical align-self-center">
                        {{ itemText(item) }}
                      </v-col>
                      <v-col cols="4" sm="4" md="4" :class="'padding-2px ' + textColor(item.cantidad)">
                        {{item.cantidad.toFixed(3)}}
                      </v-col>
                      <v-col cols="2" sm="2" md="2" class="padding-2px align-self-center">
                        {{  (typeof item.unidad !== 'undefined') ? item.unidad : (typeof item.idMaquina !== 'undefined') ? 'U' : (typeof item.idDesconocido !== 'undefined' ? 'X' : 'Emp')}}
                      </v-col>
                    </v-row>
                  </div>
                </div>
                <div v-if="remove" class="container">
                  <v-row class="padding-2px">
                    <v-col cols="10" sm="10" md="10" class="padding-2px text-center-vertical align-self-center">
                      {{ itemText(item) }}
                    </v-col>
                    <v-col cols="2" sm="2" md="2" class="padding-2px align-self-center">
                      <v-icon
                          size="24px"
                          @click="itemDelete(item)"
                        >
                          mdi-close-circle-outline
                        </v-icon>
                    </v-col> 
                  </v-row>
                </div>           
            </div>
        </draggable>
    </div>
</v-col>
</template>

<script>
import draggable from 'vuedraggable'

export default {
  name: 'DragTable',
  components: {
    draggable
  },
  data: () => ({
      search:'',
  }),
  props: {
    headerText: {
      type: String,
      default: 'Header'
    },
    remove:{
      type: Boolean,
      default:false
    },
    surplus:{
      type: Boolean,
      default:false
    },
    options: {
      type: Object,
      default() {
        return {}
      }
    },
    itemText:{
        type:Function,
        default: item => item.name,
    },
    inputColor: {
        type:String,
        default:''
    },
    backgroundColor:{
        type:String,
        default: ''
    },
    list: {
      type: Array,
      default() {
        return []
      }
    }
  },
  methods: {
    setData(dataTransfer) {
      dataTransfer.setData('Text', '')
    },
    itemAdd(item){
      this.$emit('insert',item);
    },
    itemDelete(item){
      let index = this.list.indexOf(item);
      this.$emit('update:list', this.list.splice(index, 1));
    },
    textColor(number){
      if(number > 0){
        return 'black--text';
      }
      if(number == 0){
        return 'green--text';
      }
      if(number < 0){
        return 'red--text';
      }
    }
  },
  computed: {
    columnClass(){
        return 'board-column-header '+this.backgroundColor;
    },
    listFiltered(){
        let me = this;
        return this.list.filter(function(el) {
            return (me.$props.itemText(el).toUpperCase().indexOf(me.search.toUpperCase()) != -1);
        });
    }
  }
}
</script>
<style lang="scss" scoped>
    .board-column {
      //min-width: 300px;
      min-height: 100px;
      height: auto;
      overflow: hidden;
      background: #f0f0f0;
      border-radius: 3px;
    
    .board-column-header {
      font-size: 1.25em; 
      height: 50px;
      line-height: 50px;
      overflow: hidden;
      padding: 0 20px;
      text-align: center;
      color: #fff;
      border-radius: 3px 3px 0 0;
    }

  .board-column-content {
    overflow-y: scroll;
    overflow-x: hidden;
    border: 10px solid transparent;
    min-height: 60px;
    min-height: 100px;
    max-height: 100px;
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
    align-items: center;

    .board-item {
      cursor: pointer;
      width: 99%;
      //height: 64px;
      margin: 5px 0;
      background-color: #fff;
      text-align: left;
      //line-height: 54px;
      padding: 5px 10px;
      box-sizing: border-box;
      box-shadow: 0px 1px 3px 0 rgba(0, 0, 0, 0.2);
    }
  } 
}

.padding-0px{
  padding-top: 0px !important;
}

.padding-2px{
    padding: 2px !important;
}

.text-center-vertical{
    margin-top: auto;
    margin-bottom: auto;
}
</style>



