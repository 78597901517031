<template>
  <div>
    <v-tooltip v-model="show" top>
      <template v-slot:activator="{ on, attrs }">
        <v-btn icon v-bind="attrs" v-on="on" @click="showImageOriginal(url)">
          <v-icon color="grey lighten-1">
            mdi-image-area
          </v-icon>
        </v-btn>
      </template>
      <v-img max-height="150" max-width="380" :src="currentImage"></v-img>
    </v-tooltip>

    <v-dialog v-model="dialogView" persistent max-height="500"  max-width="500">
      <v-card>
        <v-card-title class="text-h5"> </v-card-title>
        <v-card-text>
          <v-img
            :src="currentImageOriginal"
            :lazy-src="currentImage"
            contain
            max-height="490"
            max-width="490"
            aspect-ratio="1"
          >
            <template v-slot:placeholder>
              <v-row class="fill-height ma-0" align="center" justify="center">
                <v-progress-circular
                  indeterminate
                  color="grey lighten-5"
                ></v-progress-circular>
              </v-row>
            </template>
          </v-img>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary darken-1" text @click="cerrar">
            CERRAR
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import axios from "axios";
import { mapState } from "vuex";
export default {
  props: {
    lazy: { type: Boolean, default: false },
    lazyUrl: String,
    url: { type: String, required: true },
  },
  data() {
    return {
      show: false,
      dialogView: false,
      currentImage: null,
      currentImageOriginal: null,
    };
  },
  computed: {
    ...mapState(["token"]),
  },
  beforeUpdate() {
    this.showImage(this.url);
  },
  methods: {
    showImage(item) {
      const url = `/Moldes/ImagenMolde?imagen=m_${item}&access_token=${this.token}`;
      axios({
        url: url,
        method: "GET",
        responseType: "blob",
        data: null,
      })
        .then(async (response) => {
          let blob = new Blob([response.data], {
            type: "application/octet-stream",
          });
          this.currentImage = await this.$utils.images.blobToBase64(blob);
        })
        .catch((error) => {
          console.log(error);
        });
    },
    showImageOriginal(item) {
      this.dialogView = true;
      const url = `/Moldes/ImagenMolde?imagen=${item}&access_token=${this.token}`;
      axios({
        url: url,
        method: "GET",
        responseType: "blob",
        data: null,
      })
        .then(async (response) => {
          let blob = new Blob([response.data], {
            type: "application/octet-stream",
          });
          this.currentImageOriginal = await this.$utils.images.blobToBase64(
            blob
          );
        })
        .catch((error) => {
          console.log(error);
        });
    },
    cerrar() {
      this.show = false;
      this.dialogView = false;
      this.currentImageOriginal = null;
    },
  },
};
</script>
