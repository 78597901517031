<template>
  <v-container fluid>
    <v-dialog v-model="dialogDetalles" persistent max-width="75%">
      <v-card>
        <v-card-title>
          Detalles del Horario {{editedItem.desdeHora}} - {{editedItem.hastaHora}}
        </v-card-title>
        <v-card-text>
          <v-data-table
            :headers="headers"
            :items="editedItem.operaciones"
            :items-per-page="-1"
            hide-default-footer
          >
            <template v-slot:[`item.tiempoTrabajado`]="{ item }">
              {{$utils.strings.toTime(item.tiempoTrabajado)}}
            </template>
            <template v-slot:[`item.tiempoParo`]="{ item }">
              {{$utils.strings.toTime(item.tiempoParo)}}
            </template>
            <template v-slot:[`item.tiempoCiclo`]="{ item }">
              {{$utils.strings.toTime(item.tiempoCiclo)}}
            </template>
          </v-data-table>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text color="success" @click="closeDialogDetalles">Cerrar</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-row>
      <v-col cols="4">
        <v-autocomplete
          v-model="filtros.idMaquinaIns"
          :items="maquinasInstancias"
          :item-text="nombreMaquinaInstancia"
          item-value="idMaquinaIns"
          label="Máquina Instancia"
          hide-details
          clearable
          :loading="loadingMaquinasInstancias"
          :disabled="loading"
          @change="obtenerDatos"
        ></v-autocomplete>
      </v-col>
      <v-col cols="4">
        <datePicker
          v-model="filtros.fecha"
          format="YYYY-MM-DD"
          label="Fecha"
          clearable
          :class="loading && 'disabled-input'"
          :disabled="loading"
          maxToday
          @input="obtenerDatos"
        ></datePicker>
      </v-col>
      <v-col cols="4">
        <v-autocomplete
          v-model="filtros.turno"
          label="Turno"
          :items="turnos"
          item-text="nombre"
          item-value="idTurno"
          clearable
          :loading="loadingTurnos"
          :disabled="loading"
          @change="obtenerDatos"
        ></v-autocomplete>
      </v-col>
      <v-col cols="4" md="4" sm="4">
        <DownloadBtn
          text
          color="primary"
          className="my-0"
          @click="report"
          label="Descargar Reporte<br/>ACUMULADO DE MÁQUINAS<br/>RECETAS"
        />
        <!--  -->
      </v-col>
    </v-row>
    <v-row v-if="realizarPeticion" class="px-0 ma-0">
      <v-col cols="12" class="ma-0 text-right text-subtitle-2">
        Proxima Actualización en {{ tiempo }}
      </v-col>
    </v-row>
    <v-row v-if="loading">
      <v-col cols="12" class="text-center">
        <v-progress-circular
          :size="70"
          :width="7"
          color="green"
          indeterminate
        ></v-progress-circular>
        <h2>Cargando...</h2>
      </v-col>
    </v-row>
    <v-row v-else>
      <v-col cols="12" v-if="realizarPeticion">
        <v-simple-table class="custom-stripped-color">
          <template v-slot:default>
            <thead class="custom-background-color white--text">
              <tr>
                <th class="force-border-bottom" rowspan="2">
                  Hora
                </th>
                <th
                  class="
										background-color-estandar
										border-bottom-white
									"
                  colspan="2"
                >
                  Estandar
                </th>
                <th
                  class="
										background-color-real
										border-bottom-white
									"
                  colspan="2"
                >
                  Real
                </th>
                <th
                  class="
										background-color-diferencia
										border-bottom-white
									"
                  colspan="2"
                >
                  Diferencia
                </th>
                <th class="force-border-bottom" rowspan="2">
                  Piezas NO OK
                </th>
                <th class="force-border-bottom" rowspan="2">
                  Tiempo Muerto <br />
                  (Minutos)
                </th>
                <th class="force-border-bottom" rowspan="2" style="width: 5%; background-color: #616161">
                  Acciones
                </th>
              </tr>
              <tr>
                <th
                  class="
										background-color-estandar
										max-width-th
									"
                  style="border-left: 1px solid #dddddd"
                >
                  Hora
                </th>
                <th
                  class="
										background-color-estandar
										max-width-th
									"
                >
                  Acumulado
                </th>
                <th class="background-color-real max-width-th">
                  Hora
                </th>
                <th class="background-color-real max-width-th">
                  Acumulado
                </th>
                <th
                  class="
										background-color-diferencia
										max-width-th
									"
                >
                  Hora
                </th>
                <th
                  class="
										background-color-diferencia
										max-width-th
									"
                >
                  Acumulado
                </th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(dato, idx) in datos.horas" :key="`dato-${idx}`">
                <td>{{ dato.desdeHora }} - {{ dato.hastaHora }}</td>
                <td
                  :class="{
                    'background-color-estandar_row': index % 2 != 0,
                  }"
                >
                  <ICountUp
                    :endVal="dato.pzaTeoricas"
                    :delay="500"
                    class="counter-font-default-size"
                  />
                </td>
                <td
                  :class="{
                    'background-color-estandar_row': index % 2 != 0,
                  }"
                >
                  <ICountUp
                    :endVal="dato.pzaTeoricasAcumulado"
                    :delay="500"
                    class="counter-font-default-size"
                  />
                </td>
                <td
                  :class="{
                    'background-color-real_row': index % 2 != 0,
                  }"
                >
                  <ICountUp
                    :endVal="dato.pzaRealizadas"
                    :delay="500"
                    class="counter-font-default-size"
                  />
                </td>
                <td
                  :class="{
                    'background-color-real_row': index % 2 != 0,
                  }"
                >
                  <ICountUp
                    :endVal="dato.pzaRealizadasAcumulado"
                    :delay="500"
                    class="counter-font-default-size"
                  />
                </td>
                <td
                  :class="{
                    'background-color-diferencia_row': index % 2 != 0,
                  }"
                >
                  <ICountUp
                    :endVal="dato.diferencia"
                    :delay="500"
                    class="counter-font-default-size"
                  />
                </td>
                <td
                  :class="{
                    'background-color-diferencia_row': index % 2 != 0,
                  }"
                >
                  <ICountUp
                    :endVal="dato.diferenciaAcumulado"
                    :delay="500"
                    class="counter-font-default-size"
                  />
                </td>
                <td>
                  <ICountUp
                    :endVal="dato.pzaNoOk"
                    :delay="500"
                    class="counter-font-default-size"
                  />
                </td>
                <td>
                  <ICountUp
                    :endVal="dato.tiempoParo"
                    :delay="500"
                    class="counter-font-default-size"
                  />
                </td>
                <td>
                  <v-tooltip bottom color="black" class="white--text">
                    <template v-slot:activator="{ on }">
                      <v-icon
                        v-if="permisoVista('bloqueomaquinainasistencia','u')"
                        v-on="on"
                        small
                        @click="showDialogDetalles(dato)"
                      >
                        mdi-eye
                      </v-icon>
                    </template>
                    <span class="white--text">Ver detalles</span>
                  </v-tooltip>
                </td>
              </tr>
              <tr>
                <td class="custom-background-color white--text">
                  Total
                </td>
                <td class="background-color-estandar_row" colspan="2">
                  <ICountUp
                    :endVal="datos.pzaTeoricas"
                    :delay="500"
                    class="counter-font-default-size"
                  />
                </td>
                <td class="background-color-real_row" colspan="2">
                  <ICountUp
                    :endVal="datos.pzaRealizadas"
                    :delay="500"
                    class="counter-font-default-size"
                  />
                </td>
                <td class="background-color-diferencia_row" colspan="2">
                  <ICountUp
                    :endVal="datos.diferencia"
                    :delay="500"
                    class="counter-font-default-size"
                  />
                </td>
                <td>
                  <ICountUp
                    :endVal="datos.pzaNoOk"
                    :delay="500"
                    class="counter-font-default-size"
                  />
                </td>
                <td>
                  <ICountUp
                    :endVal="datos.tiempoParo"
                    :delay="500"
                    class="counter-font-default-size"
                  />
                </td>
                <td></td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>
      </v-col>
      <v-col cols="12" v-else>
        <v-alert color="blue-grey" text class="mb-0">
          <div class="text-center" style="font-size: 16px">
            Debe de Seleccionar Maquina, Fecha y Turno
          </div>
        </v-alert>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import axios from "axios";
import ICountUp from "vue-countup-v2";
import DownloadBtn from '../../DownloadBtn.vue'

export default {
  components: {
    ICountUp,
    DownloadBtn
  },
  data: () => ({
    dialogDetalles: false,
    editedItem: {
      desdeHora: null,
      hastaHora: null,
    },
    defaultDetallesItem: {
      desdeHora: null,
      hastaHora: null,
    },
    filtros: {
      idMaquinaIns: null,
      fecha: null,
      turno: null,
    },
    turnos: [],
    loadingTurnos: false,
    maquinasInstancias: [],
    loadingMaquinasInstancias: false,
    interval: null,
    loading: false,
    segundos: 120,
    datos: {
      pzaTeoricas: 0,
      pzaRealizadas: 0,
      pzaNoOk: 0,
      tiempoParo: 0,
      horas: []
    },
    datosDefault: {
      pzaTeoricas: 0,
      pzaRealizadas: 0,
      pzaNoOk: 0,
      tiempoParo: 0,
      horas: []
    },
    headers: [
      {
        text: 'Articulo',
        align: 'center',
        sortable: true,
        value: 'articulo',
      },
      {
        text: 'Tiempo Trabajado',
        align: 'center',
        sortable: true,
        value: 'tiempoTrabajado',
      },
      {
        text: 'Tiempo Paro',
        align: 'center',
        sortable: true,
        value: 'tiempoParo',
      },
      {
        text: 'Tiempo Ciclo',
        align: 'center',
        sortable: true,
        value: 'tiempoCiclo',
      },
      {
        text: 'Pza Realizadas',
        align: 'center',
        sortable: true,
        value: 'pzaRealizadas',
      },
      {
        text: 'Pza Teoricas',
        align: 'center',
        sortable: true,
        value: 'pzaTeoricas',
      }
    ]
  }),
  mounted() {
    this.getMaquinasInstancias();
    this.getTurnos();
  },
  computed: {
    tiempo() {
      return this.$utils.strings.toTime(this.segundos);
    },
    realizarPeticion() {
      return !(
        this.filtros.idMaquinaIns == null ||
        this.filtros.idMaquinaIns <= 0 ||
        this.filtros.fecha == null ||
        this.filtros.turno == null ||
        this.filtros.turno <= 0
      );
    }
  },
  mounted() {
    this.initialize();
    this.interval = setInterval(() => {
      if (this.loading || !this.realizarPeticion) return;

      if (this.segundos == 0) {
        this.obtenerDatos();
      } else this.segundos -= 1;
    }, 1000);
  },
  destroyed() {
    clearInterval(this.interval);
  },
  methods: {
    closeDialogDetalles(){
      this.dialogDetalles = false;

      setTimeout(() => {
        this.editedItem = Object.assign({},this.defaultDetallesItem);
      }, 200);
    },
    showDialogDetalles(dato){
      this.editedItem = Object.assign({},dato);

      this.dialogDetalles = true;
    },
    initialize(){
      this.getMaquinasInstancias();
      this.getTurnos();
    },
    getTurnos() {
      this.loadingTurnos = true;
      axios
        .get("/Turnos")
        .then((response) => {
          this.turnos = response.data;
        })
        .catch(console.log)
        .finally(() => {
          this.loadingTurnos = false;
        });
    },
    getMaquinasInstancias() {
      this.loadingMaquinasInstancias = true;
      axios
        .get("/MaquinaInstancias")
        .then((response) => {
          this.maquinasInstancias = response.data;
        })
        .catch(console.log)
        .finally(() => {
          this.loadingMaquinasInstancias = false;
        });
    },
    nombreMaquinaInstancia: ({ nombre, marca, modelo }) => {
      let nombreMaquinaInstancia = "";
      nombreMaquinaInstancia += nombre ? `[${nombre}] - ` : "";
      nombreMaquinaInstancia += marca ? `${marca} ` : "";
      nombreMaquinaInstancia += modelo ? `${modelo}` : "";
      return nombreMaquinaInstancia.trim();
    },
    obtenerDatos(){
      if (!this.realizarPeticion) return;

      this.paros = Object.assign({}, this.datosDefault);

      this.loading = true;

      axios
        .get(
          `/Reportes/ReporteRendimeintoMaquinaManual?idMaquinaIns=${this.filtros.idMaquinaIns}&fecha=${this.filtros.fecha}&idTurno=${this.filtros.turno}`
        )
        .then((response) => {

          this.datos = response.data;
          this.loading = false;
          this.segundos = 120;
        })
        .catch((error) => {
          console.log(error);
          this.loading = false;
          this.datos = Object.assign({}, this.datosDefault);
          this.segundos = 120;
        });
    },
    report() {
      var url = `/Reportes/XlsxReporteRendimeintoMaquinaManual?idMaquinaIns=${this.filtros.idMaquinaIns}&fecha=${this.filtros.fecha}&idTurno=${this.filtros.turno}`
      
      var fechaActual = new Date();
      var horaActual = fechaActual.getHours();
      var minutosActuales = fechaActual.getMinutes();
      var segundosActuales = fechaActual.getSeconds();

      var resultado = horaActual * minutosActuales * segundosActuales;
      
      this.$utils.axios.downloadFile(
        url,
        "GET",
        "reporte-acumulado-maquinas-recetas-"+resultado+".xlsx",
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
      );
    }
  },
};
</script>

<style scoped>
.disabled-input {
  cursor: default !important;
}

.disabled-input >>> .vd-activator {
  pointer-events: none;
}

.disabled-input >>> label {
  pointer-events: none;
}

.force-border-bottom {
  border-bottom: thin solid rgba(0, 0, 0, 0.12);
}

.border-bottom-white {
  border-bottom: 1px solid #dddddd;
}

.max-width-th {
  max-width: 80px;
}

.custom-background-color {
  background-color: rgb(42, 96, 153);
}

.custom-stripped-color tbody tr:nth-child(odd) {
  background-color: rgba(42, 96, 153, 0.14);
}

.background-color-estandar {
  background-color: #278db0;
}

.background-color-estandar_row {
  background-color: rgba(38, 141, 176, 0.3);
}

.background-color-real {
  background-color: #993981;
}

.background-color-real_row {
  background-color: rgba(153, 57, 129, 0.3);
}

.background-color-diferencia {
  background-color: #cc6023;
}

.background-color-diferencia_row {
  background-color: rgba(204, 97, 35, 0.4);
}

.counter-font-default-size {
  font-size: 0.875rem;
}

th {
  text-align: center !important;
  font-size: 1.0625rem !important;
  color: white !important;
}

tbody tr {
  text-align: center !important;
}

table th + th {
  border-left: 1px solid #dddddd;
}
table td + td {
  border-left: 1px solid #dddddd;
}

.no-seleccionable {
  -webkit-user-select: none; /* Para navegadores basados en Webkit */
  -moz-user-select: none; /* Para navegadores basados en Mozilla */
  -ms-user-select: none; /* Para navegadores basados en Microsoft */
  user-select: none; /* Estándar CSS */
}
</style>
