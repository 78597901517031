import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VProgressCircular } from 'vuetify/lib/components/VProgressCircular';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VDialog,{attrs:{"max-width":"1000px","persistent":""},model:{value:(_vm.loading),callback:function ($$v) {_vm.loading=$$v},expression:"loading"}},[_c(VCard,[_c(VCardTitle),_c(VCardText,{staticClass:"text-center"},[_c('br'),_c('br'),_c('br'),_c('div',{staticClass:"display-2"},[_vm._v("Cargando")]),_c('br'),_c('br'),_c(VProgressCircular,{attrs:{"size":250,"width":15,"color":"primary","indeterminate":""}}),_c('br'),_c('br'),_c('br')],1),_c(VCardActions)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }