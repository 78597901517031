<template>
    <v-col cols="12" v-if="permisoVista('articulos','r')">
        <v-row class="pb-4">
            <v-col cols="6">
                <v-autocomplete
                    v-model="filtros.idsArticulos"
                    :items="articulos"
                    :item-text="nombreArticulo"
                    item-value="idArticulo"
                    label="Artículo"
                    clearable
					hide-details
					multiple
					chips
					deletable-chips
					@change="articulosReorden_update_page_action(1)"
                ></v-autocomplete>
            </v-col>
            <v-col cols="6">
                <v-autocomplete
                    v-model="filtros.idsAlmacenes"
                    :items="almacenes"
                    item-text="nombre"
                    item-value="idAlmacen"
                    label="Almacén"
                    clearable
					hide-details
					multiple
					chips
					deletable-chips
					@change="articulosReorden_update_page_action(1)"
                ></v-autocomplete>
            </v-col>
        </v-row>
        <v-data-table
            :headers="headersReorden"
            :items="articulosReorden"
            :loading="loading"
            loading-text="Cargando... Espere, Por Favor."
            :server-items-length="articulosReorden_total"
            :items-per-page="articulosReorden_items_per_page"
            :page="articulosReorden_actual_page"
            @update:page="articulosReorden_update_page_action"
            @update:items-per-page="(ipp) => (articulosReorden_items_per_page = ipp)"
            :footer-props="{
                disableItemsPerPage: true,
                disablePagination: this.loading,
            }"
            :disable-sort="true"
        >
            <template v-slot:item.total="{item}">
                {{item.total!=null?item.total.toFixed(4):'N/A'}}
            </template>
            <template v-slot:item.puntoReorden="{item}">
                {{item.puntoReorden!=null?item.puntoReorden.toFixed(4):'N/A'}}
            </template>
        </v-data-table>
    </v-col>
</template>

<script>
import axios from 'axios';
export default {
	data() {
		return {
            filtros: {
                idsArticulos: [],
                idsAlmacenes: [],
            },
			headersReorden: [
				{
					text: "Artículo",
					align: "center",
					sort: false,
					value: "articulo",
				},
                {
					text: "Almacén",
					align: "center",
					sort: false,
					value: "almacen",
				},
                {
					text: "Inventario Total",
					align: "center",
					sort: false,
					value: "total",
				},
                {
					text: "Punto Reorden",
					align: "center",
					sort: false,
					value: "puntoReorden",
				},
			],
            articulosReorden: [],
            articulos: [],
            almacenes: [],
            loading: false,
            //? Paginacion articulos y filtros (server side)
            articulosReorden_total: null,
            articulosReorden_items_per_page: 10,
            articulosReorden_actual_page: 1,
		};
	},
    mounted(){
        this.initialize();
    },
    methods: {
        nombreAlmacen: almacen => `[${almacen.codigo}] ${almacen.nombre}`,
        nombreArticulo: articulo => `[${articulo.codigo}] ${articulo.nombre}`,
        initialize() {
            this.getArticulos();
            this.getAlmacenes();
			this.articulosReorden_update_page_action(1);
		},
        getArticulos(){
            axios
                .get("/Articulos/ArticulosNombres?componentes=false")
                .then( response => {
                    this.articulos = response.data;
                })
                .catch( error => {
                    console.log(error);
                })

        },
        getAlmacenes(){
            axios
                .get("/Almacenes")
                .then( response => {
                    this.almacenes = response.data;
                })
                .catch( error => {
                    console.log(error);
                })
        },
        async articulosReorden_update_page_action(page) {
			this.articulosReorden_actual_page = page;
			this.loading = true;
			try {
				let response = await this.getArticulosReorden();
				this.articulosReorden = response.data.result;
				this.articulosReorden_total = response.data.total;
				this.loading = false;
			} catch (exception) {
				console.log(exception);
			}
		},
        getArticulosReorden() {
			//* Realizamos peticion HTTP
			return new Promise((resolve, reject) => {
                let idsArticulos = "";
                let idsAlmacenes = "";
                this.filtros.idsArticulos.forEach( articulo => {
                    idsArticulos += `&IdsArticulos=${articulo}`;
                });
                this.filtros.idsAlmacenes.forEach( almacen => {
                    idsAlmacenes += `&IdsAlmacenes=${almacen}`;
                });
				axios
					.get(`/Inventario/ArticulosReorden?Paginar=true${idsArticulos}${idsAlmacenes}`,{
                        params: {
                            Page: this.articulosReorden_actual_page,
                            Size: this.articulosReorden_items_per_page,
                        }
                    })
					.then((response) => resolve(response))
					.catch((error) => reject(error));
			});
		},
    }
};
</script>

<style>
</style>