<template>
  <v-dialog
    v-model="dialogRemanufactura"
    persistent
    max-width="70%"
  >
    <v-card>
       <v-card-title v-if="!clavePersonalizada">Detalles de {{watchedItem.ot}}</v-card-title>
      <v-card-title v-else>Detalles de la ODT con Clave {{watchedItem.clave}}</v-card-title>
      <v-card-text>
        <v-progress-linear
          v-if="loadingRemanufactura"
          indeterminate
          color="primary"
        ></v-progress-linear>
        <v-row v-if="remanufacturaOperaciones.length > 0" justify="center">
          <v-expansion-panels>
            <v-expansion-panel
              v-for="(otd,i) in remanufacturaOperaciones"
              :key="'otd'+i"
            >
              <v-expansion-panel-header>
                <v-row class="ma-0 pa-0">
                  <v-col cols="6" class="text-subtitle-1 text-center ma-0 pa-0">
                      [{{otd.codigo}}] - {{otd.articulo}}
                  </v-col>
                  <v-col cols="3"/>
                  <v-col cols="3" class="text-subtitle-1 text-center ma-0 pa-0">
                    {{otd.porcentajeCompletado.toFixed(2)}}%
                  </v-col>
                </v-row>
              </v-expansion-panel-header>
              <v-expansion-panel-content>
                <v-row>
                  <v-col cols="4">
                    <v-card class="fill-height d-flex">
                      <v-card-text class="text-center align-self-center">
                        <div class="subtitle-1 font-weight-bold">
                          # Scrap
                        </div>
                        <ICountUp
                          :delay="1000"
                          :endVal="otd.numScrap"
                          class="subtitle-1 font-weight-medium red--text"
                        />
                      </v-card-text>
                    </v-card>
                  </v-col>
                  <v-col cols="4"></v-col>
                  <v-col cols="4">
                    <v-card class="fill-height d-flex">
                      <v-card-text class="text-center align-self-center">
                        <div class="subtitle-1 font-weight-bold">
                          # Retrabajo
                        </div>
                        <ICountUp
                          :delay="1000"
                          :endVal="otd.numRetrabajo"
                          class="subtitle-1 font-weight-medium blue--text"
                        />
                      </v-card-text>
                    </v-card>
                  </v-col>
                </v-row>
                <v-data-table
                  :items="otd.remanufacturas"
                  hide-default-footer
                  :items-per-page="-1"
                  :headers="headers"
                  show-expand
                  item-key="idRemanufacturaTarea"
                >
                  <template v-slot:item.tipo="{ item }">
                    <div :class="color(item)">
                      {{item.remanufacturaTipo}}
                    </div>
                  </template>
                  <template v-slot:item.procedimiento="{ item }">
                      {{procedimientoNombre(item)}}
                  </template>
                  <template v-slot:item.articulo="{ item }">
                      {{item.cantidad.toFixed(2)}} {{item.unidad}} de {{item.articulo}}
                  </template>
                  <template v-slot:item.porcentaje="{ item }">
                      {{item.porcentajeCompletado.toFixed(2)}}%
                  </template>

                  <template v-slot:expanded-item="{ headers, item }">
                    <td :colspan="headers.length">
                      <br>
                      <v-simple-table dense>
                        <template v-slot:default>
                          <thead>
                            <tr>
                              <th class="text-center">
                                Artículo
                              </th>
                              <th class="text-center">
                                Área
                              </th>
                              <th class="text-center">
                                % Avance
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr
                              v-for="op in item.operaciones"
                              :key="op.idOperacion"
                            >
                              <td class="text-center">
                                {{ op.articulo }}
                              </td>
                              <td class="text-center">
                                {{ op.area }}
                              </td>
                              <td class="text-center">
                                {{ op.cantidadRealizada}} de {{op.cantidadTotal}}
                                <br>
                                ({{op.porcentajeCompletado.toFixed(2)}}%)
                              </td>
                            </tr>
                          </tbody>
                        </template>
                      </v-simple-table>
                      <br>
                    </td>
                  </template>
                </v-data-table>
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
        </v-row>
        <div v-if="remanufacturaOperaciones.length == 0 && !loadingRemanufactura" class="text-h5 text-center">
          No hay Procesos de Remanufactura en esta Orden de Trabajo
        </div>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="blue darken-1" text @click="closeRemanufactura()">Cerrar</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import axios from "axios";
import ICountUp from 'vue-countup-v2';

export default {
  components:{
    ICountUp,
  },
  props:{
    watchedItem:{
      type: Object,
      default: () => ({
        ot:"",
        clave:"",
        idTrabajoOrden: -1,
        disponibilidadProducto: 0,
        disponibilidadMP: 0,
        estadoProducion: 0,
        articulos:[],
      })
    }
  },
  data:() => ({
    expanded: [],
    dialogRemanufactura:false,
    remanufacturaOperaciones:[],
    loadingRemanufactura: false,
    headers: [
      {
        text: 'Tipo de Remanufactura',
        align: 'center',
        sortable: true,
        value: 'tipo',
      },
      {
        text: 'Procedimiento',
        align: 'center',
        sortable: true,
        value: 'procedimiento',
      },
      {
        text: 'Artículo Involucrado',
        align: 'center',
        sortable: true,
        value: 'articulo',
      },
      {
        text: '% Avance',
        align: 'center',
        sortable: true,
        value: 'porcentaje',
      },
      { text: '', value: 'data-table-expand' },
    ]
  }),
  methods:{
    color(item){
      if(item.idRemanufacturaTipo == 1) return 'red--text text-subtitle-2';
      else return 'blue--text text-subtitle-2';
    },
    procedimientoNombre(item){
      if(item.idRemanufacturaTipo == 1) return "N/A";
      else return (item.codigoProceso == null) ? item.nombreProceso : `[${item.codigoProceso}] - ${item.nombreProceso}`;
    },
    show(idTrabajoOrden){
      this.remanufacturaOperaciones = [];
      this.dialogRemanufactura = true;
      this.loadingRemanufactura = true;
      axios
      .get("/TrabajoOrdenes/RemanufacturaOperaciones/"+idTrabajoOrden)
      .then(response => {
        this.remanufacturaOperaciones = response.data;
        this.loadingRemanufactura = false;
      })
      .catch(error => {
        console.log(error);
        this.loadingRemanufactura = false;
      })
    },
    closeRemanufactura(){
      this.remanufacturaOperaciones = [];
      this.dialogRemanufactura = false;
      this.loadingRemanufactura = false;
      this.$emit('close',null);
    }
  }
}
</script>