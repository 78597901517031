<template>
  <v-col cols="12" v-if="permisoVista('cate-maquinas','r')">
    <template>
      <v-dialog v-model="eliminar" persistent max-width="500px" :key="eliminar">
        <v-card>
          <v-card-title class="headline">¿Está seguro que desea eliminar<br/>este Grupo de Máquinas?</v-card-title>
          <v-card-text>Esta acción no se puede revertir y será permanente.</v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="blue darken-1" text @click="eliminar = false; delItem = '';">Cancelar</v-btn>
            <v-btn color="red darken-1" text @click="eliminar = false; deleteItemDB()">Aceptar</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </template>
    <v-data-table
      :headers="headers"
      :items="clasificaciones"
      :search="search"
      :loading="loading"
      loading-text="Cargando... Espere, Por Favor."
    >
      <template v-slot:top>
        <v-toolbar flat color="white">
          <v-dialog v-model="dialog" persistent max-width="500px" :key="dialog">
            <template v-slot:activator="{ on }">
              <v-btn v-if="permisoVista('cate-maquinas','c')" color="primary" dark class="mb-2" v-on="on">Nuevo Grupo de Máquinas</v-btn>
            </template>
            <v-card>
              <v-card-title>
                <span class="headline">{{ formTitle }}</span>
              </v-card-title>
              <v-card-text>
                <v-container>
                  <v-row>
                    <v-col justify="center" cols="12" sm="12" md="12">
                      <v-text-field v-model="editedItem.nombre" label="Nombre del Grupo"></v-text-field>
                    </v-col>
                    <v-col justify="center" cols="12" sm="12" md="12">
                      <v-text-field v-model="editedItem.descripcion" label="Descripción del Grupo"></v-text-field>
                    </v-col>
                  </v-row>
                  <ul>
                    <li class="red--text" v-for="ex in errores" :key="ex">{{ ex }}</li>
                  </ul>
                </v-container>
              </v-card-text>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="blue darken-1" text @click="dialog=false; errores=[];">Cancelar</v-btn>
                <v-btn color="red darken-1" text @click="save()" :disabled="saving" :loading="saving">
                  Guardar
                  <template v-slot:loader>
                    <v-progress-circular
                      indeterminate
                      :width="2"
                      :size="24"
                      color="red"
                    ></v-progress-circular>
                  </template>
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
          <v-spacer></v-spacer>
          <v-text-field
            v-model="search"
            append-icon="search"
            label="Buscar"
            single-line
            hide-details
          ></v-text-field>
        </v-toolbar>
      </template>
      <template v-slot:item.action="{ item }">
        <v-tooltip bottom color="black" class="white--text">
          <template v-slot:activator="{ on }">
        <v-icon v-on="on"
          v-if="permisoVista('cate-maquinas','u')"
          small
          class="mr-2"
          @click="editItem(item)"
        >
          edit
        </v-icon>
         </template>
          <span class="white--text">Editar</span>
        </v-tooltip>
        <v-tooltip bottom color="black" class="white--text">
          <template v-slot:activator="{ on }">
        <v-icon v-on="on"
          v-if="permisoVista('cate-maquinas','d')"
          small
          @click="deleteItem(item)"
        >
          delete
        </v-icon>
         </template>
          <span class="white--text">Eliminar</span>
        </v-tooltip>
      </template>
      <template v-slot:no-data>
        <v-btn color="primary" @click="initialize">Refrescar</v-btn>
      </template>
    </v-data-table>
    <v-snackbar
      v-model="alerta"
      top
      color="error"
    >
      Existe una Clasificación Activa con el mismo nombre
      <v-btn
        text
        @click="alerta = false"
      >
        Cerrar
      </v-btn>
    </v-snackbar>
  </v-col>
  
</template>

<script>
import axios from "axios";

  export default {
    data: () => ({
      saving: false,
      dialog: false,
      alerta: false,
      eliminar: false,
      search: '',
      headers: [
        {
          text: 'Grupo',
          align: 'left',
          sortable: true,
          value: 'nombre',
        },
        { text: 'Acciones', value: 'action', sortable: false, width:'35%', align:'center' },
      ],
      loading: false,
      clasificaciones: [],
      delItem: '',
      action: 0,
      errores: [],
      editedIndex: -1,
      editedItem: {
        nombre: '',
        descripcion: '',
      },
      defaultItem: {
        nombre: '',
        descripcion: '',
      },
    }),

    computed: {
      formTitle () {
        return this.editedIndex === -1 ? 'Nuevo Grupo de Máquinas' : 'Editar Grupo de Máquinas'
      }
    },

    watch: {
      dialog (val) {
        val || this.close()
      }
    },

    created () {
      this.initialize()
    },

    methods: {
      initialize () {
        this.loading = true;
        axios
        .get("/MaquinaTipos")
        .then(response =>  {
          this.clasificaciones = response.data;
          this.loading = false;
        })
        .catch(error => {
          console.log(error);
        });
      },

      editItem (item) {
        this.action = 1;
        this.editedIndex = this.clasificaciones.indexOf(item)
        this.editedItem = Object.assign({}, item)
        this.dialog = true
      },

      deleteItem (item) {
        this.eliminar = true;
        this.delItem = item;
      },
      deleteItemDB(){
        let item = this.delItem;
        this.delItem = '';
        axios
          .put("/MaquinaTipos/DesactivarMaquinaTipo/" + item.idMaquinaTipo)
          .then(response =>  {
            this.initialize();
          })
          .catch(error => {
            console.log(error);
          });
      },
      close () {
        this.action = 0,
        this.dialog = false
        setTimeout(() => {
          this.editedItem = Object.assign({}, this.defaultItem);
          this.editedIndex = -1;
        }, 300)
      },
      validate(){
        this.errores =  [];
        if (
          this.editedItem.nombre.length < 2 ||
          this.editedItem.nombre.length > 50
        )
        {
          this.errores.push(
            "El nombre debe tener más de 2 caracteres y menos de 50."
          );
          return false;
        }
        return true;
      },
      save () {
        this.action = 0;
        if(this.validate()){
          this.saving = true;
          if (this.editedIndex > -1)
          {
            //Código para editar
            axios
              .put("/MaquinaTipos/ActualizarMaquinaTipo/"+this.clasificaciones[this.editedIndex].idMaquinaTipo, {
                nombre: this.editedItem.nombre,
                descripcion: this.editedItem.descripcion,
              })
              .then(() =>  {
                this.saving = false;
                this.close();
                this.initialize();
              })
              .catch(error => {
                this.saving = false;
                if (error.response) {
                  if(error.response.status == 409){
                    this.alerta = true;
                    this.errores.push("Existe una Clasificación Activa con el Mismo Nombre");
                  }
                }
              });
          }
          else
          {
            axios
              .post("/MaquinaTipos/CrearMaquinaTipo", {
                nombre: this.editedItem.nombre,
                descripcion: this.editedItem.descripcion,
              })
              .then( () =>  {
                this.saving = false;
                this.close();
                this.initialize();
              })
              .catch(error => {
                this.saving = false;
                if (error.response) {
                  if(error.response.status == 409){
                    this.alerta = true;
                    this.errores.push("Existe una Clasificación Activa con el Mismo Nombre");
                  }
                }
              });
          }
        }
      },
    },
  }
</script>