<template>
	<v-col cols="12" v-if="permisoVista('publicoventas','r')">
		<v-dialog v-model="dialogDelete" persistent max-width="40%">
			<v-card>
				<v-card-text>
					<p class="text-h5 pt-4 font-weight-medium text--primary">
						¿Está seguro que desea eliminar esta Solicitud de Venta?
					</p>
					<p class="subtitle-1">
						Esta acción no se puede revertir y será permanente.
					</p>
				</v-card-text>
				<v-card-actions>
					<v-spacer></v-spacer>
					<v-btn
						text
						color="success"
						:disabled="deleting"
						@click="cerrarDialog()"
						>Cancelar</v-btn
					>
					<v-btn
						text
						color="error"
						:disabled="deleting"
						:loading="deleting"
						@click="deleteSolicitud()"
						>Eliminar</v-btn
					>
				</v-card-actions>
			</v-card>
		</v-dialog>
		<v-dialog v-model="dialog" persistent max-width="70%">
			<v-card>
				<v-card-title>
					{{ editedItem.idPublicoVenta > 0 ? "Editar " : "Crear " }}
					Solicitud de Venta
				</v-card-title>
				<v-card-text>
					<v-row>
						<v-col cols="4">
							<v-text-field
								v-model="editedItem.clave"
								label="Clave"
								clearable
								:error-messages="errorClave"
								@focus="errorClave = ''"
								:disabled="saving"
							></v-text-field>
						</v-col>
						<v-col cols="4" v-if="editedItem.idPublicoVenta == 0">
							<v-autocomplete
								v-model="editedItem.idCliente"
								:items="clientes"
								item-text="nombre"
								item-value="idCliente"
								label="Cliente"
								clearable
								:error-messages="errorCliente"
								@focus="errorCliente = ''"
								:disabled="saving"
							></v-autocomplete>
						</v-col>
						<v-col cols="4" class="pt-5">
							<VueCtkDateTimePicker
								v-model="editedItem.fechaCompromiso"
								id="fechaCompromiso"
								label="Fecha de Compromiso"
								format="YYYY-MM-DD HH:mm"
								formatted="YYYY-MM-DD HH:mm"
								:min-date="fechaMinCompromiso"
								noHeader
								overlay
								:error="errorFechaCompromiso != ''"
								buttonNowTranslation="Ahora"
							/>
						</v-col>
						<v-col cols="12">
							<v-data-table
								:items="editedItem.articulos"
								:headers="articulosHeaders"
								:items-per-page="-1"
								hide-default-footer
							>
								<template v-slot:item.idArticulo="{ item }">
									<v-autocomplete
										v-model="item.idArticulo"
										:items="articulosFiltrados(item)"
										:item-text="nombreArticulo"
										item-value="idArticulo"
										placeholder="Seleccionar artículo"
										clearable
										:error-messages="
											item.errorMensajeArticulo
										"
										@focus="item.errorMensajeArticulo = ''"
										@change="changeArticulo(item)"
									></v-autocomplete>
								</template>
								<template v-slot:item.cantidad="{ item }">
									<v-text-field
										v-model="item.cantidad"
										type="number"
										class="centered-input"
										:min="0"
										:error-messages="
											item.errorMensajeCantidad
										"
										:suffix="$utils.strings.pluralizar(getUnidadArticulo(item.idArticulo),item.cantidad)"
										@focus="item.errorMensajeCantidad = ''"
										@input="validarArticulo(item)"
									></v-text-field>
								</template>
								<template v-slot:item.costo="{ item }">
									<v-text-field
										v-model="item.costo"
										type="number"
										class="centered-input"
										:label="item.label"
										:min="0"
										prefix="$"
										:error-messages="
											item.errorMensajeCosto
										"
										@focus="item.errorMensajeCosto = ''"
										@input="validarArticulo(item)"
									></v-text-field>
								</template>
								<template v-slot:item.actions="{ index }">
									<v-tooltip
										bottom
										color="black"
										class="white--text text-center"
									>
										<template v-slot:activator="{ on }">
											<v-icon
												v-on="on"
												small
												@click="
													deleteItemArticulo(index)
												"
											>
												delete
											</v-icon>
										</template>
										<span class="white--text"
											>Eliminar</span
										>
									</v-tooltip>
								</template>
							</v-data-table>
							<v-col cols="4" class="pa-0">
								<v-btn
									color="primary"
									class="pl-0"
									text
									@click="agregarArticulo()"
									>Agregar artículo
									<v-icon right>mdi-plus</v-icon></v-btn
								>
							</v-col>
						</v-col>
						<v-col cols="12" v-if="errores.length > 0">
							<ul>
								<li
									v-for="(error, idx) in errores"
									:key="idx"
									class="red--text"
								>
									{{ error }}
								</li>
							</ul>
						</v-col>
					</v-row>
				</v-card-text>
				<v-card-actions>
					<v-spacer></v-spacer>
					<v-btn
						text
						color="success"
						:disabled="saving"
						@click="cerrarDialog()"
						>Cerrar</v-btn
					>
					<v-btn
						text
						color="error"
						:disabled="saving"
						:loading="saving"
						@click="save()"
						>Guardar</v-btn
					>
				</v-card-actions>
			</v-card>
		</v-dialog>
		<v-dialog v-model="dialogDetalles" persistent max-width="70%">
			<v-card>
				<v-card-title>
					Detalles de la Orden de Venta - {{ editedItem.clave }}
				</v-card-title>
				<v-card-text>
					<v-data-table
						:items="editedItem.articulos"
						:headers="headersDetallesOrdenes"
					>
						<template v-slot:item.cantidad="{ item }">
							{{ `${item.cantidadEntregada==0?item.cantidadEntregada:item.cantidadEntregada.toFixed(2)} ${$utils.strings.pluralizar(item.unidad,item.cantidadEntregada)}` }}
							{{ ` de ${item.cantidad==0?item.cantidad:item.cantidad.toFixed(2)} ${$utils.strings.pluralizar(item.unidad,item.cantidad)}` }}
						</template>
						<template v-slot:item.lotes="{ item }">
							<p v-if="item.lotes.length == 0" class="mb-0">N/A</p>
							<div v-else class="text-center my-1">
								<ul class="d-inline-block">
									<li 
										v-for="(lote,idx) in item.lotes" 
										:key="idx"
										style="font-size: 14px !important"
										class="py-1"
									>
										{{ `${lote} ${item.unidad}` }}
									</li>
								</ul>
							</div>
						</template>
						<template v-slot:item.qrs="{ item }">
							<p v-if="item.qrs.length == 0" class="mb-0">N/A</p>
							<div v-else class="text-center my-1">
								<ul class="d-inline-block">
									<li 
										v-for="(qr,idx) in item.qrs" 
										:key="idx"
										style="font-size: 14px !important"
										class="py-1"
									>
										{{ `${qr} ${item.unidad}` }}
									</li>
								</ul>
							</div>
						</template>
					</v-data-table>
				</v-card-text>
				<v-card-actions>
					<v-spacer></v-spacer>
					<v-btn 
						color="success" 
						text 
						@click="cerrarDialogDetalles()"
					>
						Cerrar
					</v-btn>
				</v-card-actions>
			</v-card>
		</v-dialog>
		<v-row v-if="permisoVista('publicoventas','c')">
			<v-col cols="4" class="py-0">
				<v-btn color="primary" @click="showDialogCrear()">
					Crear Solicitud de Venta
				</v-btn>
			</v-col>
		</v-row>
		<v-row>
			<v-col cols="4">
				<v-autocomplete
					v-model="filtros.clave"
					:items="claves"
					item-text="clave"
					item-value="idPublicoVenta"
					label="Clave"
					hide-details
					clearable
					:disabled="cargando"
					@change="getVentasPublico()"
				></v-autocomplete>
			</v-col>
			<v-col cols="4">
				<v-autocomplete
					v-model="filtros.idCliente"
					:items="clientes"
					item-text="nombre"
					item-value="idCliente"
					label="Cliente"
					hide-details
					clearable
					:disabled="cargando"
					@change="getVentasPublico()"
				></v-autocomplete>
			</v-col>
			<v-col cols="4">
				<v-autocomplete
					v-model="filtros.idArticulo"
					:items="articulos"
					:item-text="nombreArticulo"
					item-value="idArticulo"
					label="Artículo"
					hide-details
					clearable
					:disabled="cargando"
					@change="getVentasPublico()"
				></v-autocomplete>
			</v-col>
			<v-col cols="4">
				<v-select
					v-model="filtros.entregada"
					:items="estados"
					item-text="nombre"
					item-value="value"
					label="Estado"
					hide-details
					clearable
					:disabled="cargando"
					@change="getVentasPublico()"
				></v-select>
			</v-col>
			<v-col cols="4" class="align-self-end">
				<VueCtkDateTimePicker
					v-model="filtros.desde"
					id="inicio"
					label="Desde"
					format="YYYY-MM-DD HH:mm"
					formatted="YYYY-MM-DD HH:mm"
					noHeader
					overlay
					buttonNowTranslation="Ahora"
					@input="getVentasPublico()"
				/>
			</v-col>
			<v-col cols="4" class="align-self-end">
				<VueCtkDateTimePicker
					id="fin"
					v-model="filtros.hasta"
					:min-date="desde"
					label="Hasta"
					format="YYYY-MM-DD HH:mm"
					formatted="YYYY-MM-DD HH:mm"
					noHeader
					overlay
					buttonNowTranslation="Ahora"
					@input="getVentasPublico()"
				/>
			</v-col>
			<v-col cols="4">
				<v-autocomplete
					v-model="filtros.idUsuario"
					:items="usuarios"
					item-text="nombre"
					item-value="idUsuario"
					label="Usuario"
					hide-details
					clearable
					@change="getVentasPublico()"
				></v-autocomplete>
			</v-col>
		</v-row>
		<v-row>
			<v-col cols="12">
				<v-data-table
					:items="ventas"
					:headers="ventasHeaders"
					:loading="cargando"
				>
					<template v-slot:item.clave="{ item }">
						{{item.clave==null||item.clave==''?'N/A':item.clave}}
					</template>
					<template v-slot:item.articulos="{ item }">
						<v-simple-table
							class="transparent my-1"
							:key="item.ventasKey"
						>
							<template v-slot:default>
								<thead>
									<th class="text-center"></th>
									<th class="text-center"></th>
									<th v-if="permisoVista('publicoventas','$')" class="text-center"></th>
								</thead>
								<tbody>
									<tr
										v-for="articulo in item.articulos.slice(
											0,
											item.showAll == true
												? item.articulos.length
												: 2
										)"
										:key="articulo.idArticulo"
										class="transparent"
									>
										<td>{{ articulo.articulo }}</td>
										<td>
											{{ `${articulo.cantidad.toFixed(2)} ${$utils.strings.pluralizar(` ${articulo.unidad}`,articulo.cantidad)}` }}
										</td>
										<td v-if="permisoVista('publicoventas','$')">
											${{ articulo.costo.toFixed(2) }}
										</td>
									</tr>
								</tbody>
							</template>
						</v-simple-table>
						<hr
							v-if="item.articulos.length > 2"
							color="#E0E0E0"
							class="font-weight-light"
						/>
						<p
							v-if="item.articulos.length > 2"
							class="my-2 text-center"
							@click="
								() => {
									item.showAll = !!!item.showAll;
									item.ventasKey++;
								}
							"
							style="cursor: pointer; color: #1976d2"
						>
							{{
								!!item.showAll == false
									? `Mostar ${item.articulos.length - 2} más`
									: `Mostrar menos`
							}}
						</p>
					</template>
					<template v-slot:item.fechaEntrega="{ item }">
						{{
							item.fechaEntrega == null
								? "N/A"
								: item.fechaEntrega
						}}
					</template>
					<template v-slot:item.actions="{ item }">
						<v-tooltip
							v-if="
								!item.enProceso &&
								item.fechaEntrega == null &&
								permisoVista('publicoventas','u')
							"
							bottom
							color="black"
							class="white--text text-center"
						>
							<template v-slot:activator="{ on }">
								<v-icon
									v-on="on"
									small
									class="mr-2"
									@click="showDialogEditar(item)"
								>
									edit
								</v-icon>
							</template>
							<span class="white--text">Editar</span>
						</v-tooltip>
						<v-tooltip
							v-if="
								!item.enProceso &&
								item.fechaEntrega == null &&
								permisoVista('publicoventas','d')
							"
							bottom
							color="black"
							class="white--text text-center"
						>
							<template v-slot:activator="{ on }">
								<v-icon
									v-on="on"
									small
									@click="showDialogDelete(item)"
								>
									delete
								</v-icon>
							</template>
							<span class="white--text">Eliminar</span>
						</v-tooltip>
						<v-tooltip
							v-if="item.enProceso"
							bottom
							color="black"
							class="white--text text-center"
						>
							<template v-slot:activator="{ on }">
								<v-icon
									v-on="on"
									small
									@click="showDialogDetalles(item)"
								>
									mdi-eye
								</v-icon>
							</template>
							<span class="white--text">Ver Detalles</span>
						</v-tooltip>
					</template>
				</v-data-table>
			</v-col>
			<v-col cols=""> </v-col>
		</v-row>
	</v-col>
</template>

<script>
import axios from "axios";
import { mapState } from "vuex";
import moment from "moment";
export default {
	data() {
		return {
			headersDetallesOrdenes:[
				{
					text: "Código",
					align: "center",
					value: "codigo",
				},
				{
					text: "Artículo",
					align: "center",
					value: "nombre",
				},
				{
					text: "Cantidad",
					align: "center",
					value: "cantidad",
				},
				{
					text: "Lotes",
					align: "center",
					value: "lotes",
				},
				{
					text: "Códigos QR",
					align: "center",
					value: "qrs",
				},
			],
			dialogDetalles: false,
			tableKey: 0,
			errorClave: "",
			errorCliente: "",
			errorFechaCompromiso: "",
			dialogDelete: false,
			articulosHeaders: [
				{
					text: "Artículo",
					align: "center",
					width: "60%",
					value: "idArticulo",
				},
				{
					text: "Cantidad",
					align: "center",
					width: "15%",
					value: "cantidad",
				},
				{
					text: "Costo Unitario",
					align: "center",
					width: "15%",
					value: "costo",
				},
				{
					text: "Acciones",
					align: "center",
					width: "10%",
					value: "actions",
				},
			],
			errores: [],
			filtros: {
				clave: null,
				idCliente: null,
				idArticulo: null,
				entregada: null,
				desde: null,
				hasta: null,
				idUsuario: null
			},
			movimientos: [
				{
					nombre: "Surtidos",
					value: true,
				},
				{
					nombre: "Sin Surtir",
					value: false,
				},
			],
			estados: [
				{
					nombre: "Entregado",
					value: 1,
				},
				{
					nombre: "Pendiente",
					value: 2,
				},
				{
					nombre: "En Progreso",
					value: 3,
				},
			],
			dialog: false,
			cargando: false,
			saving: false,
			desde: null,
			hasta: null,
			clientes: [],
			ventas: [],
			usuarios: [],
			articulos: [],
			ventasHeaders: [
				{
					text: "Clave",
					align: "center",
					value: "clave",
				},
				{
					text: "Cliente",
					align: "center",
					value: "cliente",
				},
				{
					text: "Artículos",
					align: "center",
					value: "articulos",
				},
				{
					text: "Fecha de Solicitud",
					align: "center",
					value: "fechaSolicitud",
				},
				{
					text: "Fecha de Compromiso",
					align: "center",
					value: "fechaCompromiso",
				},
				{
					text: "Fecha de Entrega",
					align: "center",
					value: "fechaEntrega",
				},
				{
					text: "Realizada Por",
					align: "center",
					value: "usuario",
				},
				{
					text: "Estado",
					align: "center",
					value: "estado",
				},
				{
					text: "Acciones",
					align: "center",
					value: "actions",
				},
			],
			editedItem: {
				clave: "",
				idPublicoVenta: 0,
				idCliente: 0,
				fechaCompromiso: null,
				articulos: [],
			},
			defaultEditedItem: {
				clave: "",
				idPublicoVenta: 0,
				idCliente: 0,
				fechaCompromiso: null,
				articulos: [],
			},
			deleting: false,
		};
	},
	computed:{
		fechaMinCompromiso(){
			return moment().add(1,'minutes').format("YYYY-MM-DD HH:mm")
		},
		claves(){
			return this.ventas.filter(venta => venta.clave != "")
		}
	},
	mounted() {
		this.initialize();
	},
	methods: {
		async changeArticulo(item){
			await this.getLabelCosto(item);
			this.validarArticulo(item);
		},
		getLabelCosto(item){
			return new Promise( async (resolve) => {
				if(item.idArticulo == null) {
					this.$set(item,"label","");
					return resolve();
				}
				try {
					const response = await this.realizarPeticionPrecio(item.idArticulo);
					this.$set(item,"label",`Min: $${response.data.max.toFixed(2)}`);
				} catch (error) {
					console.log(error);
					this.$set(item,"label","");
				}
				finally {
					resolve();
				}
			});
		},
		realizarPeticionPrecio(idArticulo){
			return new Promise( (resolve,reject) => {
				axios
					.get('/Articulos/ObtenerPrecioPublicoVenta',{
						params:{
							idArticulo
						}
					})
					.then( response => {
						resolve(response);
					})
					.catch( error => {
						reject(error);
					})
			});
		},
		getUnidadArticulo(idArticulo){
			if(idArticulo == null) return null;
			const articulo = this.articulos.find(articulo => articulo.idArticulo == idArticulo);
			return articulo != null ? articulo.abreviatura : null;
		},
		articulosFiltrados(item) {
			const idsArticulos = this.editedItem.articulos
				.filter((articulo) => articulo.idArticulo != item.idArticulo)
				.map((articulo) => articulo.idArticulo);
			return this.articulos.filter(
				(articulo) => !idsArticulos.includes(articulo.idArticulo)
			);
		},
		deleteItemArticulo(index) {
			this.editedItem.articulos.splice(index, 1);
		},
		deleteSolicitud() {
			this.deleting = true;
			axios
				.put(
					`/Ventas/EliminarSolicitudVentaPublico/${this.editedItem.idPublicoVenta}`
				)
				.then(() => {
					this.deleting = false;
					this.cerrarDialog();
					this.getVentasPublico();
				})
				.catch((error) => {
					this.deleting = false;
					console.log(error);
				});
		},
		validarArticulo(item) {
			if (item.idArticulo == null) {
				this.$set(
					item,
					"errorMensajeArticulo",
					"Debe seleccionar un artículo"
				); //reactivo
				// item.errorMensajeArticulo = "Debe seleccionar un artículo"; //no reactivo
			}
			else{
				this.$set(item,"errorMensajeArticulo","");
			}

			if (
				isNaN(parseFloat(item.cantidad)) ||
				parseFloat(item.cantidad) <= 0
			) {
				this.$set(
					item,
					"errorMensajeCantidad",
					"Debe ingresar una cantidad valida"
				); //reactivo
				// item.errorMensajeCantidad = "Debe ingresar una cantidad valida"; //no reactivo
			} else {
				this.$set(item, "errorMensajeCantidad", "");
			}

			if (
				isNaN(parseFloat(item.costo)) ||
				parseFloat(item.costo) <= 0
			) {
				this.$set(
					item,
					"errorMensajeCosto",
					"Debe ingresar un costo unitario valido"
				);
			}
			else if(parseFloat(item.costo) < parseFloat(item.label?.split("$")[1])){
				this.$set(
					item,
					"errorMensajeCosto",
					"El costo unitario esta por debajo del costo promedio del articulo"
				);
			} 
			else {
				this.$set(item, "errorMensajeCosto", "");
			}
		},
		nombreArticulo(item) {
			if (item.nombre == null && item.codigo == null) return "N/A";
			if (item.codigo == null) return item.nombre;
			if (item.nombre == null) return `[${item.codigo}] - N/A`;
			return `[${item.codigo}] - ${item.nombre}`;
		},
		agregarArticulo() {
			this.editedItem.articulos.push({
				idArticulo: null,
				cantidad: 0,
				costo: 0,
				articKey: 0,
			});
		},
		validate() {
			this.errorClave = "";
			this.errorCliente = "";
			this.errorFechaCompromiso = "";
			this.errores = [];
			let numErroresArticulos = 0;

			if (
				this.editedItem.clave == null ||
				this.editedItem.clave == ""
			) {
				this.errorClave = "Debe definir una clave";
			}

			if (
				this.editedItem.idCliente == null ||
				this.editedItem.idCliente == 0
			) {
				this.errorCliente = "Debe seleccionar un cliente";
			}

			if (this.editedItem.fechaCompromiso == null) {
				this.errorFechaCompromiso =
					"Debe seleccionar una Fecha de Compromiso";
				this.errores.push(this.errorFechaCompromiso);
			}

			if (this.editedItem.articulos.length == 0) {
				this.errores.push("Debe agregar al menos un Artículo");
			} else {
				this.editedItem.articulos.forEach((articulo) => {
					this.$set(articulo, "errorMensajeArticulo", "");
					this.$set(articulo, "errorMensajeCantidad", "");
					this.$set(articulo, "errorMensajeCosto", "");
					this.validarArticulo(articulo);
					if (
						articulo.errorMensajeArticulo != "" ||
						articulo.errorMensajeCantidad != "" ||
						articulo.errorMensajeCosto != ""
					) {
						numErroresArticulos++;
					}
				});
			}

			return (
				this.errorClave == "" &&
				this.errorCliente == "" &&
				this.errores.length == 0 &&
				numErroresArticulos == 0
			);
		},
		save() {
			if (!this.validate()) return;
			this.saving = true;

			const obj = {
				idPublicoVenta: this.editedItem.idPublicoVenta,
				idCliente: this.editedItem.idCliente,
				fechaCompromiso: this.editedItem.fechaCompromiso,
				clave: this.editedItem.clave,
				articulos: this.editedItem.articulos.map( articulo => ({
					idArticulo: articulo.idArticulo,
					cantidad: parseFloat(articulo.cantidad),
					costo: parseFloat(articulo.costo)
				})),
			};
			if (this.editedItem.idPublicoVenta == 0) {
				axios
					.post("/Ventas/CrearSolicitudVentaPublico", obj)
					.then(() => {
						this.saving = false;
						this.cerrarDialog();
						this.getVentasPublico();
					})
					.catch((error) => {
						this.saving = false;
						if(error.response){
							if(error.response.status == 409){
								this.errorClave = "Ya existe una solicitud de venta con la misma clave";
							}
							if(error.response.status == 400){
								this.errorFechaCompromiso = "Debe seleccionar una Fecha de Compromiso mayor a la fecha y hora actual";
								this.errores.push(this.errorFechaCompromiso);
							}
						}
						console.log(error);
					});
			} else {
				axios
					.put(
						`/Ventas/ActualizarSolicitudVentaPublico/${this.editedItem.idPublicoVenta}`,
						obj
					)
					.then(() => {
						this.saving = false;
						this.cerrarDialog();
						this.getVentasPublico();
					})
					.catch((error) => {
						this.saving = false;
						if(error.response){
							if(error.response.status == 409){
								this.errorClave = "Ya existe una solicitud de venta con la misma clave";
							}
							if(error.response.status == 400){
								this.errorFechaCompromiso = "Debe seleccionar una Fecha de Compromiso mayor a la fecha y hora actual";
								this.errores.push(this.errorFechaCompromiso);
							}
						}
						console.log(error);
					});
			}
		},
		cerrarDialog() {
			this.dialog = false;
			this.dialogDelete = false;
			this.editedItem = JSON.parse(
				JSON.stringify(this.defaultEditedItem)
			);
			this.errorClave = "";
			this.errorCliente = "";
			this.errorFechaCompromiso = "";
			this.errores = [];
		},
		cerrarDialogDetalles(){
			this.dialogDetalles = false;
			this.editedItem = JSON.parse(
				JSON.stringify(this.defaultEditedItem)
			);
		},
		showDialogDelete(item) {
			this.editedItem = JSON.parse(JSON.stringify(item));
			this.dialogDelete = true;
		},
		showDialogDetalles(item){
			this.editedItem = JSON.parse(JSON.stringify(item));
			this.dialogDetalles = true;
		},
		showDialogEditar(item) {
			this.editedItem = JSON.parse(JSON.stringify(item));
			this.editedItem.articulos.forEach(articulo => {
				this.getLabelCosto(articulo);
			});
			this.dialog = true;
		},
		showDialogCrear() {
			this.editedItem = JSON.parse(
				JSON.stringify(this.defaultEditedItem)
			);
			this.dialog = true;
		},
		initialize() {
			this.getClientes();
			this.getUsuarios();
			this.getArticulos();
			this.getVentasPublico();
		},
		getArticulos() {
			axios
				.get("/Articulos/ArticulosNombres")
				.then((response) => {
					this.articulos = response.data;
				})
				.catch((error) => {
					console.log(error);
				});
		},
		getUsuarios() {
			axios
				.get("/Usuarios")
				.then((response) => {
					this.usuarios = response.data;
				})
				.catch((error) => {
					console.log(error);
				});
		},
		getClientes() {
			axios
				.get("/Clientes")
				.then((response) => {
					this.clientes = response.data;
				})
				.catch((error) => {
					console.log(error);
				});
		},
		getVentasPublico() {
			this.cargando = true;
			axios
				.get(`/Ventas/ListarSolicitudVentaPublico`, {
					params: {
						desde: this.filtros.desde,
						hasta: this.filtros.hasta,
						idCliente: this.filtros.idCliente,
						idUsuario: this.filtros.idUsuario,
						entregada: this.filtros.entregada,
						idArticulo: this.filtros.idArticulo,
						idPublicoVenta: this.filtros.clave
					},
				})
				.then((response) => {
					this.ventas = response.data.map((item) => ({
						...item,
						articulos: item.articulos.map(articulo => ({
							...articulo,
							costo: articulo.costoUnitario
						})),
						ventasKey: 0,
					}));
					this.cargando = false;
				})
				.catch((error) => {
					this.cargando = false;
					console.log(error);
				});
		},
	},
};
</script>
<style scoped>
.centered-input >>> input {
	text-align: center;
}
</style>