<template>
	<div>
		<v-tabs v-model="tab" background-color="transparent" grow>
			<v-tab
				v-tooltip.bottom="modoAyuda && $help.rh.brigadasrh.brigadas"
				v-if="mostrarVista('brigadas', 'r')"
				@click="brigadaClick"
			>
				Brigadas
			</v-tab>
			<v-tab
				v-tooltip.bottom="modoAyuda && $help.rh.brigadasrh.brigadap"
				v-if="mostrarVista('brigadap', 'r')"
				@click="brigadaPuestosClick"
			>
				Puestos de brigadas
			</v-tab>
      		<v-tab
			 	v-tooltip.bottom="modoAyuda && $help.rh.brigadasrh.brigadape"
				v-if="mostrarVista('brigadape', 'r')"
				@click="brigadadpersonasClick"
			>
				Asignacion persona brigada
			</v-tab>

			<v-tab
				v-tooltip.bottom="modoAyuda && $help.rh.brigadasrh.briarbol"
				v-if="mostrarVista('briarbol', 'r')"
				@click="brigadadiagramaClick"
			>
				Brigada diagrama
			</v-tab>

			<v-tabs-items v-model="tab" touchless>
				<v-tab-item eager v-if="mostrarVista('brigadas', 'r')">
                    <Brigada ref="brigada" />
				</v-tab-item>
				<v-tab-item eager v-if="mostrarVista('brigadap', 'r')">
                    <BrigadaPuesto ref="brigadaPuestos" />
				</v-tab-item>
        		<v-tab-item eager v-if="mostrarVista('brigadape', 'r')">
                    <BrigadadPersonas ref="brigadadpersonas" />
				</v-tab-item>
				<v-tab-item eager v-if="mostrarVista('briarbol', 'r')">
                    <BrigadaDiagrama ref="brigadadiagrama" />
				</v-tab-item>
			</v-tabs-items>
		</v-tabs>
	</div>
</template>

<script>
import BrigadaPuesto from "./BrigadaPuesto.vue";
import Brigada from './Brigada.vue';
import BrigadadPersonas from './BrigadadPersonas.vue';
import BrigadaDiagrama from './BrigadaDiagrama.vue';

export default {
	components: {
		BrigadaPuesto,
		Brigada,
    	BrigadadPersonas,
		BrigadaDiagrama
	},
	data(){
        return {
            tab: 0,
        }
	},
	methods: {
		initialize() {
			this.brigadaClick();
            this.brigadaPuestosClick();
            this.brigadadpersonasClick();
			this.brigadadiagramaClick();
		},
		brigadaClick() {
			if (typeof this.$refs.brigada === "undefined") return;
			this.$refs.brigada.initialize();
		},
		brigadaPuestosClick() {
			if (typeof this.$refs.brigadaPuestos === "undefined") return;
			this.$refs.brigadaPuestos.initialize();
		},
    brigadadpersonasClick() {
			if (typeof this.$refs.brigadadpersonas === "undefined") return;
			this.$refs.brigadadpersonas.initialize();
		},
		brigadadiagramaClick() {
			if (typeof this.$refs.brigadadiagrama === "undefined") return;
			this.$refs.brigadadiagrama.initialize();
		},
	},
};
</script>