<template>
  <v-container fluid>
    <v-row style="display: flex; justify-content: end; align-items: end;">
      <v-col cols="2">
        <v-btn
            color="green darken-1"
            text
            className="my-0"
            @click="getDatos"
            :disabled="loading || !filtros.fechaInicio || !filtros.fechaFin"
            style="width: 100%; border: 1px solid #00c853;"
        >
          <v-icon left>mdi-refresh</v-icon>
          Refrescar
        </v-btn>
      </v-col>
    </v-row>
    <v-row class="justify-space-between align-end">
      <v-col cols="6">
        <MenuDatepicker
            v-model="filtros.fechaInicio"
            label="[Obligatorio] Fecha de Inicio"
            :max-date="filtros.fechaFin"
            clearable
            hide-details
            @input="getDatos()"
            :disabled="loading"
        />
      </v-col>
      <v-col cols="6">
        <MenuDatepicker
            v-model="filtros.fechaFin"
            label="[Obligatorio] Fecha de Fin"
            clearable
            hide-details
            :min-date="filtros.fechaInicio"
            @input="getDatos()"
            :disabled="loading"
        />
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        <v-divider></v-divider>
        <div v-if="!filtros.fechaInicio || !filtros.fechaFin" class="text-center">
          <v-alert
              dense
              outlined
              type="warning"
              elevation="0"
              border="left"
              colored-border
              style="margin-top: 50px;"
          >
            <strong>Selecciona un rango de fechas para visualizar los datos</strong>
          </v-alert>
        </div>
        <div v-if="loading" class="text-center">
          <v-progress-circular indeterminate color="primary"></v-progress-circular>
        </div>
        <div v-else>
          <div v-if="data.length === 0 && !loading && (filtros.fechaInicio && filtros.fechaFin)" class="text-center">
            <v-alert
                dense
                outlined
                type="warning"
                elevation="0"
                border="left"
                colored-border
                style="margin-top: 50px;"
            >
              <strong>No hay datos para mostrar</strong>
            </v-alert>
          </div>
        </div>
      </v-col>
      <v-col cols="12">
        <canvas id="myChartCostos5"></canvas>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import DownloadBtn from "../../DownloadBtn.vue";
import {mapState} from "vuex";
import axios from "axios";
import Chart from "chart.js/auto";
import MenuDatepicker from "@/components/MenuDatepicker.vue";

export default {
  components: {MenuDatepicker, DownloadBtn},
  data() {
    return {
      search: null,
      data: [],
      loading: false,
      myChart: null,
      filtros: {
        fechaInicio: undefined,
        fechaFin: undefined,
      },
    };
  },
  mounted() {
    this.initialize();
  },
  computed: {
    ...mapState(["token"]),
  },
  methods: {
    initialize() {
      this.getDatos();
    },
    getCodigoNombre: (code, name) => {
      let codeName = "";
      codeName += code ? `[${code}] - ` : "";
      codeName += name;
      return codeName.trim();
    },
    processAndGraphData(responseData) {
      // Get all keys and sum the values for each key
      let sums = {};

      responseData.forEach((item) => {
        sums[item['maquinaUnidad']] = (sums[item['maquinaUnidad']] || 0) + item['costoTotal'];
      });

      // Eliminar los tipos que no sean de tipo string
      Object.keys(sums).forEach((key) => {
        if (typeof key !== "string") {
          delete sums[key];
        }
      });

      this.createBarChart(sums);
    },

    createBarChart(data) {
      if (this.myChart) {
        this.myChart.destroy();
      }

      const alphaValue = 0.5;

      let ctx = document.getElementById("myChartCostos5").getContext("2d");
      this.myChart = new Chart(ctx, {
        type: "bar",
        data: {
          labels: Object.keys(data),
          datasets: [
            {
              label: "Costo total",
              data: Object.values(data),
              backgroundColor: [`rgba(255, 99, 132, ${alphaValue})`, `rgba(54, 162, 235, ${alphaValue})`, `rgba(255, 206, 86, ${alphaValue})`, `rgba(75, 192, 192, ${alphaValue})`, `rgba(153, 102, 255, ${alphaValue})`, `rgba(255, 159, 64, ${alphaValue})`],
              borderColor: ["rgba(255, 99, 132, 1)", "rgba(54, 162, 235, 1)", "rgba(255, 206, 86, 1)", "rgba(75, 192, 192, 1)", "rgba(153, 102, 255, 1)", "rgba(255, 159, 64, 1)"],
              borderWidth: 2,
            },
          ],
        },
        options: {
          scales: {
            y: {
              beginAtZero: true,
            },
          },
          plugins: {
            tooltip: {
              callbacks: {
                label: function (context) {
                  let label = context.dataset.label || "";
                  if (label) {
                    label += ": ";
                  }
                  if (context.parsed.y !== null) {
                    label += new Intl.NumberFormat("es-MX", {
                      style: "currency",
                      currency: "MXN",
                    }).format(context.parsed.y);
                  }
                  return label;
                },
              },
            },
            datalabels: {
              anchor: "end",
              align: "end",
              formatter: (value) => {
                return new Intl.NumberFormat("es-MX", {
                  style: "currency",
                  currency: "MXN",
                }).format(value);
              },
            }
          },
        },
      });
    },
    getDatos() {
      if (!this.filtros.fechaInicio || !this.filtros.fechaFin) {
        return;
      }

      this.loading = true;

      axios
          .get(
              `/Mantenimientos/ObtenerCostosMantenimientosPreventivosYCorrectivos?inicio=${this.filtros.fechaInicio}&fin=${this.filtros.fechaFin}`
          )
          .then((response) => {
            this.data = response.data;
            this.processAndGraphData(response.data);
          })
          .catch(console.log)
          .finally(() => {
            this.loading = false;
          });
    },
  },
};
</script>
