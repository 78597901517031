<template>
  <v-footer
    id="core-footer"
    height="60"
    absolute
    color="transparent"
  >
    <div class="footer-items">
      <span
        v-for="link in links"
        :key="link.name"
      >
        <a
          :href="link.Link"
          class="tertiary--text footer-links">{{ link.name }}</a>
      </span>
    </div>
    <v-spacer/>
    <span class="font-weight-light copyright" style="padding-right: 1rem">
      &copy;
      {{ (new Date()).getFullYear() }}
      <a
        class="text-decoration-none green--text"
        href="http://www.managrx.com/"
        target="_blank">ManagrX</a>
    </span>
  </v-footer>
</template>

<script>
export default {
  data: () => ({
    links: [
      // { name: 'Home', Link: '/dashboard/reportes' },
    ]
  })
}
</script>

<style>
#core-footer {
  z-index: 0;
}
</style>
