import { VAutocomplete } from 'vuetify/lib/components/VAutocomplete';
import { VTextarea } from 'vuetify/lib/components/VTextarea';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (!_vm.newRecord && _vm.items.length > 0)?_c('div',[_c(VAutocomplete,{attrs:{"label":_vm.label,"items":_vm.items,"loading":_vm.loading,"disabled":_vm.loading || _vm.disabled,"error-messages":_vm.itemError,"append-outer-icon":"mdi-reload"},on:{"focus":function($event){_vm.itemError = null},"click:append-outer":function($event){return _vm.$emit('click:refresh')}},model:{value:(_vm.itemValue),callback:function ($$v) {_vm.itemValue=$$v},expression:"itemValue"}}),_c('div',{staticClass:"text-right"},[_c('a',{class:{
				'grey--text disabled-anchor': _vm.loading || _vm.disabled,
			},on:{"click":function($event){return _vm.toggle(true)}}},[_vm._v("Crear nuevo")])])],1):_c('div',[_c(VTextarea,{attrs:{"label":_vm.label,"outlined":_vm.outlined,"loading":_vm.loading,"disabled":_vm.loading || _vm.disabled,"auto-grow":_vm.autoGrow,"rows":_vm.rows,"error-messages":_vm.itemError},on:{"focus":function($event){_vm.itemError = null}},model:{value:(_vm.itemValue),callback:function ($$v) {_vm.itemValue=$$v},expression:"itemValue"}}),(_vm.items.length > 0)?_c('div',{staticClass:"text-right"},[_c('a',{class:{
				'grey--text disabled-anchor': _vm.loading || _vm.disabled,
			},on:{"click":function($event){return _vm.toggle(false)}}},[_vm._v("Seleccionar existente")])]):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }