<template>
  <v-col cols="12" v-if="permisoVista('evolucioncostos','r')">
    <v-row>
      <v-col cols="4" md="4" sm="4">
        <v-autocomplete
          v-model="idArticulo"
          label="Artículo"
          :items="articulos"
          item-value="idArticulo"
          item-text="nombre"
          hide-details
          clearable
          @change="getEvolucionCostos"
          :loading="loadingOperador"
        ></v-autocomplete>
      </v-col>
      <v-col cols="4" md="4" sm="4" class="align-self-end">
        <datePicker
          v-model="inicio"
          format="YYYY-MM-DD"
          :maxDate="fin"
          label="Desde"
          clearable
          maxToday
          @input="getEvolucionCostos"
        ></datePicker>
      </v-col>
      <v-col cols="4" md="4" sm="4" class="align-self-end">
        <datePicker
          v-model="fin"
          format="YYYY-MM-DD"
          :minDate="inicio"
          label="Hasta"
          clearable
          maxToday
          @input="getEvolucionCostos"
        ></datePicker>
      </v-col>
    </v-row>
    <apexchart type="line" height="350" :options="chartOptions" :series="series"></apexchart>
  </v-col>
</template>

<script>
import axios from "axios";
import moment from "moment";
import datePicker from "@/components/DatePicker";
import { VueDatePicker } from '@mathieustan/vue-datepicker';

export default {
  data: () => ({
    series: [],
    chartOptions: {
      chart: {
        type: 'line',
        height: 350,
         locales: [{
          name: "es",
          options: {
            months: ["Enero","Febrero","Marzo","Abril","Mayo","Junio","Julio","Agosto","Septiembre","Octubre","Noviembre","Diciembre"],
            shortMonths: ["Ene","Feb","Mar","Abr","May","Jun","Jul","Ago","Sep","Oct","Nov","Dic"],
            days: ["Domingo","Lunes","Martes","Miércoles","Jueves","Viernes","Sábado"],
            shortDays: ["Dom","Lun","Mar","Mie","Jue","Vie","Sab"],
            toolbar: {
              exportToSVG: "Descargar SVG",
              exportToPNG: "Descargar PNG",
              exportToCSV: "Descargar CSV",
              menu: "Menu",
              selection: "Seleccionar",
              selectionZoom: "Seleccionar Zoom",
              zoomIn: "Aumentar",
              zoomOut: "Disminuir",
              pan: "Navegación",
              reset: "Reiniciar Zoom"
            }
          }
        }],
        defaultLocale: "es"
      },
      stroke: {
        curve: 'stepline',
      },
      dataLabels: {
        enabled: false
      },
      title: {
        text: '',
        align: 'left'
      },
      markers: {
        hover: {
          sizeOffset: 4
        }
      },
      xaxis: {
        type: 'datetime',
        labels: {
          datetimeUTC: false
        }
      },
      yaxis: {
        labels: {
          formatter: function (val) {
            return "$"+val.toFixed(2);
          }
        }
      },
    },
    idArticulo:null,
    inicio: null,
    fin:null
  }),
  mounted(){
    this.initialize();
  },
  methods:{
    initialize(){
      this.getArticulos();
    },
    getArticulos(){
      this.loadingArticulos = true;

      axios
      .get("/Articulos/ArticulosProduccion")
      .then(response => {
        this.loadingArticulos = false;
        this.articulos = response.data;
      })
      .catch(error => {
        console.log(error);
        this.loadingArticulos = false;
      })
    },
    getEvolucionCostos(){
      if(!this.$utils.isValid(this.idArticulo)){
        this.series = [];
        return;
      }
      var url = "/Compras/EvolucionCosto/"+this.idArticulo;
      var parameter = false;

      if(this.$utils.isValid(this.inicio)){
        url += "?Inicio="+this.inicio;
        parameter = true;
      }
      if(this.$utils.isValid(this.fin)){
        url += (parameter?'&':'?')+"Fin="+this.fin;
      }

      this.loading = true;
      axios
      .get(url)
      .then(response => {
        this.loading = false;
        this.series = [];
        this.series.push(response.data);
      })
      .catch(error => {
        this.loading = false;
        console.log(error);
      })
    }
  }
    
}
</script>