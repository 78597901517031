<template>
  <v-text-field
    v-model="string"
    v-if="paint"
    :autofocus="autofocus"
    :dense="dense"
    :hide-details="hideDetails"
    :error="error || errorStatus"
    :class="(error || errorStatus)?'v-input--is-focused':''"
    :label="label"
    :suffix="suffix"
    :disabled="disabled"
    @keypress="preventInput"
    @change="changeEvent($event)"
    @input="inputEvent($event)"
    @focus="$emit('focus')"
  >
  </v-text-field>
</template>

<script>
export default {
  props:{
    value:{
      type:Number,
      default: undefined,
    },
    dense:{
      type:Boolean,
      default:false
    },
    hideDetails:{
      type:Boolean,
      default:false
    },
    decimal:{
      type:Boolean,
      default:false
    },
    negative:{
      type:Boolean,
      default:false
    },
    label:{
      type:String,
      default:null,
    },
    max:{
      type:Number,
      default:Infinity,
    },
    errorStatus:{
      type: Boolean,
      default: false
    },
    min:{
      type: Number,
      default: Number.NEGATIVE_INFINITY
    },
    suffix: {
      type: String,
      default: ""
    },
    disabled:{
      type: Boolean,
      default: false
    }
  },
  mounted(){
    if(typeof this.value !== 'undefined'){
      this.string = this.value+'';
      this.inputEvent();
    }
  },
  watch:{
    value(){
      if(typeof this.value !== 'undefined'){
        if(!isNaN(this.value)) this.string = this.value+'';
      }
    }
  },
  data: () => ({
    error:false,
    string:'',
    paint:true,
    autofocus:false,
  }),
  methods:{
    anyError(){
      return this.error;
    },
    setValue(number){
      this.string = number+"";
      this.error = isNaN(number);
    },
    preventInput($event){
      var keyCode = ($event.keyCode ? $event.keyCode : $event.which);
      
      if(keyCode == 45){
        if(!this.negative) $event.preventDefault();
        else if(this.string.indexOf('-') >= 0) $event.preventDefault();
      }

      else if(keyCode == 46){
        if(!this.decimal) $event.preventDefault();
        else {
          if((this.string.split(".").length -1) >= 1) $event.preventDefault();
        }
      }

      else{
        if(keyCode < 48 || keyCode > 57){
          $event.preventDefault();
        }
      }
    },
    parseNumber(string){
      var number = parseFloat(string);
      this.error = isNaN(number);
      return number;
    },
    removeDash(){
      this.string = this.string.replace('-','');
      this.repaint();
    },
    changeEvent(event){
      if(this.negative){
        this.removeZeroBegin();
        if(this.string.indexOf('-') > 0){
          this.removeDash();
        }
      }
      else{
        this.removeZeroBegin(0);
      }

      var number = this.parseNumber(this.string);

      if(number > this.max) this.error = true;
      if(number <= this.min) this.error = true;

      this.$emit('change',number);
    },
    inputEvent(){
      if(this.negative){
        this.removeZeroBegin();
        if(this.string.indexOf('-') > 0){
          this.removeDash();
        }
      }
      else{
        this.removeZeroBegin(0);
      }
      if(this.string == '-0'){
        this.string = '0';
        this.repaint();
      }

      var number = this.parseNumber(this.string);

      if(number > this.max) this.error = true;
      if(number <= this.min) this.error = true;

      this.$emit('input',number);
    },
    removeZeroBegin(repaint = true){
      /*
      var pos = 0;
      if(this.string[0] == '-') pos+=1;

      if(this.string.length >= pos+2){
        if(this.string[pos] == '0'){
          this.string = this.removeAt(this.string,pos);
          this.removeZeroBegin(pos,false);

          if(repaint) this.repaint();
        }
      }
      */
      if(repaint) this.repaint();
    },
    removeAt(string,pos) {
      var chars = string.split('');
      chars.splice(pos,1);
      return chars.join('');
    },
    repaint(){
      this.autofocus = true;
      this.paint = false;
      this.$nextTick(() => {
          this.paint = true;
      });

      setTimeout(() => {
        this.autofocus = false;
      }, 1000);
    }
  }
}
</script>