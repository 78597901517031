<template>
  <v-menu 
    offset-y
    :close-on-content-click="false"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-autocomplete
        v-model="value"
        v-bind="attrs"
        v-on="on"
        :label="label"
        :items="items"
        item-value="value"
        item-text="text"
        hide-details
        dense
        multiple
        chips
        clearable
        deletable-chips
        @change="sortItems"
        @update="sortItems"
        @mousedown="preventEvent"
        @mouseup="preventEvent"
        @focus="preventEvent"
        @blur="preventEvent"
        @click:append="preventEvent"
        :readonly="readonly"
      >
      </v-autocomplete>
    </template>
    <v-card>
      <v-card-text>
        <v-btn-toggle
          v-model="value"
          multiple
          class="d-table"
          @change="sortItems"
        >
          <v-btn class="black--text" v-for="(item,idx) in items" :key="'btn'+idx">{{item.text}}</v-btn>
        </v-btn-toggle>
      </v-card-text>
    </v-card>
  </v-menu>
</template>

<script>
export default {
  props:{
    label:{
      type:String,
      default:null,
    },
    value:{
      type:Array,
      default:[],
    },
    items:{
      type:Array,
      default:[],
    },
  },
  data: () => ({
    readonly:false,
  }),
  methods:{
    preventEvent($event){
      if($event.target.nodeName !== 'BUTTON' && $event.target.nodeName !== 'INPUT'){
        this.hideDropdown($event);  
      }
    },
    hideDropdown($event){
      this.readonly = true
      $event.preventDefault()
      setTimeout(() => {
        this.readonly = false;
      },10)
    },
    sortItems(){
      this.value = this.value.sort();
      this.$emit('change',this.value);
      this.$emit('input',this.value);
    },
  }
}
</script>