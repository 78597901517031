import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VProgressCircular } from 'vuetify/lib/components/VProgressCircular';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';
import { VToolbar } from 'vuetify/lib/components/VToolbar';
import { VToolbarTitle } from 'vuetify/lib/components/VToolbar';
import { VTooltip } from 'vuetify/lib/components/VTooltip';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VCol,{attrs:{"cols":"12"}},[_c(VDialog,{attrs:{"max-width":"85%","persistent":""},model:{value:(_vm.dialogDesglosar),callback:function ($$v) {_vm.dialogDesglosar=$$v},expression:"dialogDesglosar"}},[_c(VCard,{staticClass:"pb-6 pt-5"},[_c(VCardTitle,{staticClass:"pb-2 pt-2"},[_vm._v(" Desglosar Movimiento ")]),_c(VCardText,[_c(VRow,[_c(VCol,{attrs:{"cols":"12","md":"12","sm":"12"}},[_c('div',{staticClass:"d-flex"},[_c(VBtn,{attrs:{"color":"primary","loading":_vm.loadingHistorial,"disabled":_vm.loadingHistorial || _vm.savingDesglosar},on:{"click":_vm.showDialogHistorial}},[_vm._v("Seleccionar Mantenimientos")]),(_vm.movimientoDesglose.payload!== undefined)?_c(VBtn,{staticClass:"ml-auto",attrs:{"color":"primary","disabled":_vm.loadingHistorial || _vm.savingDesglosar},on:{"click":_vm.distribuirTotal}},[_vm._v("Distribuir "+_vm._s(parseFloat(_vm.movimientoDesglose.cantidadFaltante).toFixed(2))+" "+_vm._s(_vm.movimientoDesglose.unidad)+" ")]):_vm._e()],1),_c(VDataTable,{attrs:{"items":_vm.movimientoDesglose.payload,"headers":_vm.headersDesglose,"footer-props":{
									disableItemsPerPage: true,
									disablePagination: this.loading,
								},"disable-sort":true,"hide-default-footer":"","items-per-page":-1},scopedSlots:_vm._u([{key:"item.cantidad",fn:function(ref){
								var index = ref.index;
return [_c(VTextField,{staticClass:"centered-input",attrs:{"rules":[
											_vm.cantidadRule(_vm.cantidades[index]) ]},on:{"blur":function($event){return _vm.convert3D(_vm.cantidades[index], index)}},model:{value:(_vm.cantidades[index]),callback:function ($$v) {_vm.$set(_vm.cantidades, index, $$v)},expression:"cantidades[index]"}})]}},{key:"item.porcentaje",fn:function(ref){
										var index = ref.index;
return [_vm._v(" "+_vm._s(_vm.porcentajes[index])+" % ")]}}])})],1)],1),_c('ul',{staticClass:"d-flex"},[_vm._l((_vm.errores),function(error,idx){return _c('li',{key:'error' + idx,staticClass:"red--text"},[_vm._v(" "+_vm._s(error)+" ")])}),(_vm.movimientoDesglose.payload!== undefined)?_c('div',{key:'error' + _vm.idx,staticClass:"ml-auto",class:[_vm.errores.length > 0 ? ( _vm.totality !== 0 ? 'orange--text' : 'black--text' ):'green--text']},[_vm._v(" SUMATORIA: "+_vm._s((_vm.totality).toFixed(2))+" "+_vm._s(_vm.movimientoDesglose.unidad)+" ")]):_vm._e()],2)],1),_c(VCardActions,[_c(VSpacer),_c(VBtn,{attrs:{"color":"red","disabled":_vm.savingDesglosar},on:{"click":_vm.closeDesglosar}},[_vm._v("Cancelar")]),_c(VBtn,{attrs:{"color":"green","disabled":_vm.savingDesglosar ||
							!_vm.cantidadesValidos,"loading":_vm.savingDesglosar},on:{"click":_vm.guardarDesglosar},scopedSlots:_vm._u([{key:"loader",fn:function(){return [_c(VProgressCircular,{attrs:{"indeterminate":"","width":2,"size":24,"color":"red"}})]},proxy:true}])},[_vm._v(" Guardar ")])],1)],1)],1),_c(VDialog,{attrs:{"max-width":"90%","persistent":""},model:{value:(_vm.dialogHistorial),callback:function ($$v) {_vm.dialogHistorial=$$v},expression:"dialogHistorial"}},[_c(VCard,[_c(VToolbar,{attrs:{"color":"primary","dark":""}},[_c(VBtn,{attrs:{"icon":"","dark":""},on:{"click":_vm.cerrarDialogHistorial}},[_c(VIcon,[_vm._v("mdi-close")])],1),_c(VToolbarTitle,{staticStyle:{"color":"white"}},[_vm._v("Seleccionar Mantenimientos")]),_c(VSpacer),_c(VBtn,{attrs:{"dark":"","text":""},on:{"click":_vm.clickGuardarSelected}},[_vm._v(" Seleccionar ")])],1),_c(VCardText,[_c('Historial',{ref:"historyMan",attrs:{"seleccionable":true}})],1)],1)],1),_c(VDataTable,{attrs:{"items":_vm.sinDesglosar,"headers":_vm.headersSinDesglosar,"loading":_vm.loading,"loading-text":"Cargando.. Por favor espere"},scopedSlots:_vm._u([{key:"item.articulo",fn:function(ref){
							var item = ref.item;
return [_vm._v(" ["+_vm._s(item.articuloCodigo)+"] - "+_vm._s(item.articulo)+" ")]}},{key:"item.cantidad",fn:function(ref){
							var item = ref.item;
return [_vm._v(" "+_vm._s(item.cantidad.toFixed(2))+" "+_vm._s(item.unidad)+" ")]}},{key:"item.costoUnitario",fn:function(ref){
							var item = ref.item;
return [_vm._v(" $ "+_vm._s(item.costoUnitario.toFixed(2))+" ")]}},{key:"item.costoTotal",fn:function(ref){
							var item = ref.item;
return [_vm._v(" $ "+_vm._s(item.costoTotal.toFixed(2))+" ")]}},{key:"item.actions",fn:function(ref){
							var item = ref.item;
return [(_vm.permisoVista('movimientos-sindesglosar', 'c') && _vm.permisoVista('movimientos-sindesglosar', '$'))?_c(VTooltip,{staticClass:"white--text",attrs:{"bottom":"","color":"black"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
							var on = ref.on;
return [_c(VIcon,_vm._g({attrs:{"small":""},on:{"click":function($event){return _vm.desglosarMovimiento(item)}}},on),[_vm._v(" mdi-file-tree ")])]}}],null,true)},[_c('span',{staticClass:"white--text"},[_vm._v("Desglosar")])]):_vm._e()]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }