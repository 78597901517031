import { VAutocomplete } from 'vuetify/lib/components/VAutocomplete';
import { VChip } from 'vuetify/lib/components/VChip';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VProgressLinear } from 'vuetify/lib/components/VProgressLinear';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSimpleTable } from 'vuetify/lib/components/VDataTable';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.permisoVista('disponibilidad','r'))?_c(VCol,{attrs:{"cols":"12"}},[_c(VRow,[_c(VCol,{attrs:{"cols":"3"}},[_c(VAutocomplete,{attrs:{"items":_vm.turnos,"item-text":"nombre","item-value":"idTurno","label":"Turno","return-object":"","clearable":""},on:{"click:clear":_vm.resetTurno,"change":_vm.getDatos},model:{value:(_vm.turno),callback:function ($$v) {_vm.turno=$$v},expression:"turno"}})],1),_c(VCol,{attrs:{"cols":"3"}},[_c(VAutocomplete,{attrs:{"items":_vm.personasTurno,"item-text":_vm.nombrePersona,"item-value":"idPersona","multiple":"","label":_vm.personaExcluir,"clearable":""},on:{"click:clear":_vm.resetExcluir,"change":_vm.getDatos},model:{value:(_vm.idsPersonasExcluir),callback:function ($$v) {_vm.idsPersonasExcluir=$$v},expression:"idsPersonasExcluir"}})],1),_c(VCol,{attrs:{"cols":"3"}},[_c(VAutocomplete,{attrs:{"items":_vm.personasOtrosTurnos,"item-text":_vm.nombrePersona,"item-value":"idPersona","multiple":"","clearable":"","label":"Incluir Personas"},on:{"click:clear":_vm.resetIncluir,"change":_vm.getDatos},model:{value:(_vm.idsPersonasIncluir),callback:function ($$v) {_vm.idsPersonasIncluir=$$v},expression:"idsPersonasIncluir"}})],1),_c(VCol,{attrs:{"cols":"3"}},[_c(VAutocomplete,{attrs:{"items":_vm.maquinasIns,"item-text":_vm.nombreMaquina,"item-value":"idMaquinaIns","multiple":"","label":"Excluir Máquinas","clearable":""},on:{"click:clear":_vm.resetExcluirMaquinas,"change":_vm.getDatos},model:{value:(_vm.idsMaquinasExcluir),callback:function ($$v) {_vm.idsMaquinasExcluir=$$v},expression:"idsMaquinasExcluir"}})],1)],1),_c(VDataTable,{attrs:{"items":_vm.datos,"headers":_vm.headers,"loading":_vm.loading,"items-per-page":-1,"hide-default-footer":"","disable-sort":""},scopedSlots:_vm._u([{key:"item",fn:function(ref){
var item = ref.item;
return [_c('tr',[_c('td',{staticClass:"text-center"},[_vm._v(" "+_vm._s(item.nombre)+" ")]),_c('td',{staticClass:"text-center"},_vm._l((item.habilidades),function(habilidad,idx){return _c(VChip,{key:'h-'+idx,attrs:{"label":""}},[_vm._v(" "+_vm._s(habilidad)+" ")])}),1),_c('td',{staticClass:"pa-0 ma-0"},[_c(VSimpleTable,{staticClass:"pa-0 ma-0",scopedSlots:_vm._u([{key:"default",fn:function(){return [_c('tbody',_vm._l((item.personas),function(persona,idx){return _c('tr',{key:'p-'+idx},[_c('td',{staticClass:"text-center",staticStyle:{"width":"25% !important"}},[_vm._v(_vm._s(persona.nombre))]),_c('td',{staticClass:"text-center",staticStyle:{"width":"25% !important"}},[_c(VProgressLinear,{attrs:{"value":persona.porcentajeHabilidades,"height":"25"}},[_c('strong',[_vm._v(_vm._s(Math.ceil(persona.porcentajeHabilidades))+"%")])])],1),_c('td',{staticClass:"text-center",staticStyle:{"width":"50% !important"}},_vm._l((persona.habilidades),function(habilidad,idx){return _c(VChip,{key:'h-'+idx,staticClass:"ma-1",attrs:{"label":"","x-small":""}},[_vm._v(" "+_vm._s(habilidad)+" ")])}),1)])}),0)]},proxy:true}],null,true)})],1)])]}}],null,false,1106756178)})],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }