import { VCol } from 'vuetify/lib/components/VGrid';
import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VTooltip } from 'vuetify/lib/components/VTooltip';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.permisoVista('accesodispositivos','r'))?_c(VCol,{attrs:{"cols":"12"}},[_c(VRow,[_c(VCol,{attrs:{"cols":"6","md":"6","sm":"6"}}),_c(VCol,{attrs:{"cols":"6","md":"6","sm":"6"}})],1),_c(VDataTable,{attrs:{"items":_vm.accesos,"loading":_vm.loading,"headers":_vm.headers},scopedSlots:_vm._u([{key:"item.autorizado",fn:function(ref){
var item = ref.item;
return [_c('div',[_vm._v(" "+_vm._s((item.autorizado == null) ? "No" : "Sí, por "+item.usuarioAutorizo)+" ")]),(item.autorizado != null)?_c('div',[_vm._v(" Desde "+_vm._s(item.autorizado)+" ")]):_vm._e()]}},{key:"item.ip",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s((item.ip == null) ? 'N/D' : item.ip)+" ")]}},{key:"item.pais",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s((item.pais == null) ? 'N/D' : item.pais)+" ")]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [(item.autorizado == null && item.condicion == true && _vm.permisoVista('accesodispositivos', 'u'))?_c(VTooltip,{staticClass:"white--text",attrs:{"bottom":"","color":"black"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c(VIcon,_vm._g({attrs:{"small":""},on:{"click":function($event){return _vm.validarAcceso(item)}}},on),[_vm._v(" mdi-check ")])]}}],null,true)},[_c('span',{staticClass:"white--text"},[_vm._v("Autorizar Acceso")])]):_vm._e(),_vm._v("     "),(item.autorizado == null && item.condicion == true && _vm.permisoVista('accesodispositivos', 'u'))?_c(VTooltip,{staticClass:"white--text",attrs:{"bottom":"","color":"black"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c(VIcon,_vm._g({attrs:{"small":""},on:{"click":function($event){return _vm.cancelarAcceso(item)}}},on),[_vm._v(" mdi-cancel ")])]}}],null,true)},[_c('span',{staticClass:"white--text"},[_vm._v("Negar Acceso")])]):_vm._e()]}}],null,false,2097733414)}),_c('yes-no-dialog',{ref:"yesNoDialog",on:{"change":_vm.respuesta}})],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }