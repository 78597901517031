<template>
  <div v-if="permisoVista('brigadas', 'r')">
    <v-btn v-if="permisoVista('brigadas', 'c')"
    color="primary" dark class="mb-2" @click="dialog = true"
    >
      Nueva brigada
    </v-btn>
    <v-dialog v-model="dialog" max-width="500px">
      <v-card>
        <v-card-title>
          <span class="text-h5">{{ formTitle }}</span>
        </v-card-title>

        <v-card-text>
          <v-container>
            <v-row>
              <v-col cols="12" sm="12" md="12">
                <v-text-field
                  v-model="editedItem.nombre"
                  label="Nombre"
                  :error="editedItem.errorNombreEstatus"
                  :error-messages="editedItem.errorNombre"
                  :key="up"
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="12" md="12">
                <v-text-field
                  v-model="editedItem.abreviatura"
                  label="Abreviatura"
                  :error="editedItem.errorAbreviaturaEstatus"
                  :error-messages="editedItem.errorAbreviatura"
                  :key="up"
                ></v-text-field>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="close">
            CANCELAR
          </v-btn>
          <v-btn color="red darken-1" text @click="save">
            ACEPTAR
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="dialogDelete" max-width="500px">
      <v-card>
        <v-card-title class="headline"
          >¿Está seguro que desea eliminar este elemento?</v-card-title
        >
        <v-card-text
          >Esta acción no se puede revertir y será permanente.</v-card-text
        >
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="closeDelete">CANCELAR</v-btn>
          <v-btn color="red darken-1" text @click="deleteItemConfirm"
            >ACEPTAR</v-btn
          >
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-data-table 
      :headers="headers"
      :items="brigadas"
      sort-by="calories"
      :loading="loadData"
    >
      <template v-slot:[`item.actions`]="{ item }">
        <v-icon v-if="permisoVista('brigadas','u')"
        small class="mr-2" @click="editItem(item)"
        >
          mdi-pencil
        </v-icon>
        <v-icon v-if="permisoVista('brigadas','d')"
        small @click="deleteItem(item)"
        >
          mdi-delete
        </v-icon>
      </template>
      <template v-slot:no-data>
        <v-btn color="primary" @click="initialize">
          Reset
        </v-btn>
      </template>
    </v-data-table>
  </div>
</template>

<script>
import axios from "axios";

export default {
  data: () => ({
    loadData: false,
    up: 0,
    dialog: false,
    dialogDelete: false,
    headers: [
      { text: "Nombre", value: "nombre" },
      { text: "Abreviatura", value: "abreviatura" },
      { text: "Acciones", value: "actions", width: "10%", sortable: false },
    ],
    brigadas: [],
    editedIndex: -1,
    editedItem: {
      idBrigada: null,
      nombre: null,
      abreviatura: null,
      errorNombreEstatus: false,
      errorNombre: null,
      errorAbreviaturaEstatus: false,
      errorAbreviatura: null,
    },
    defaultItem: {
      IdBrigada: null,
      nombre: null,
      abreviatura: null,
      errorNombreEstatus: false,
      errorNombre: null,
      errorAbreviaturaEstatus: false,
      errorAbreviatura: null,
    },
  }),

  computed: {
    formTitle() {
      return this.editedIndex === -1 ? "Nueva Brigada" : "Editar Brigada";
    },
  },

  watch: {
    dialog(val) {
      val || this.close();
    },
    dialogDelete(val) {
      val || this.closeDelete();
    },
  },

  created() {
    this.initialize();
  },

  methods: {
    initialize() {
      this.getBrigadas();
    },

    editItem(item) {
      this.editedIndex = this.brigadas.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialog = true;
    },

    deleteItem(item) {
      this.editedIndex = this.brigadas.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialogDelete = true;
    },

    deleteItemConfirm() {
      //this.brigadas.splice(this.editedIndex, 1);
      axios
          .put(
            "/Brigada/Desactivar/" + this.editedItem.idBrigada
          )
          .then((response) => {
            this.dialog = false;
            this.close();
            this.initialize();
          })
          .catch((error) => {
            this.dialog = false;
          });
      this.closeDelete();
    },

    close() {
      this.dialog = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },

    closeDelete() {
      this.dialogDelete = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },
    validacion() {
      if (this.editedItem.nombre == null || this.editedItem.nombre == "") {
        this.editedItem.errorNombreEstatus = true;
        this.editedItem.errorNombre = "Ingrese un nombre para la brigada";
      } else {
        this.editedItem.errorNombreEstatus = null;
        this.editedItem.errorNombre = null;
      }

      if (
        this.editedItem.abreviatura == null ||
        this.editedItem.abreviatura == ""
      ) {
        this.editedItem.errorAbreviaturaEstatus = true;
        this.editedItem.errorAbreviatura = "Ingrese una abreviatura";
      } else {
        this.editedItem.errorAbreviaturaEstatus = null;
        this.editedItem.errorAbreviatura = null;
      }

      this.up++;
      if (
        this.editedItem.errorAbreviaturaEstatus == null &&
        this.editedItem.errorNombreEstatus == null
      )
        return false;
      else return true;
    },
    save() {
      if (this.validacion()) return;
      var obj = {
        Nombre: this.editedItem.nombre,
        Abreviatura: this.editedItem.abreviatura,
      };
      this.loadData = true;
      if (this.editedIndex > -1) {
        axios
          .put("/Brigada/Actualizar/" + this.editedItem.idBrigada, obj)
          .then((response) => {
            this.close();
            this.initialize();
          })
          .catch((error) => {
            console.log(error);
          });
      } else {
        axios
          .post("/Brigada/Crear", obj)
          .then(() => {
            this.close();
            this.initialize();
          })
          .catch((error) => {
            console.log(error);
          });
      }
      this.close();
    },
    getBrigadas() {
      this.loadData = true;
      axios
        .get("/Brigada")
        .then((response) => {
          this.brigadas = response.data;
          this.loadData = false;
        })
        .catch((error) => {
          console.log(error);
          this.loadData = false;
        });
    },
  },
};
</script>
