<template>
  <v-dialog
    v-model="loading"
    max-width="1000px"
    persistent
  >
    <v-card>
      <v-card-title></v-card-title>
      <v-card-text class="text-center">
        <br>
        <br>
        <br>
        <div class="display-2">Cargando</div>
        <br>
        <br>
        <v-progress-circular
          :size="250"
          :width="15"
          color="primary"
          indeterminate
        ></v-progress-circular>
        <br>
        <br>
        <br>
      </v-card-text>  
      <v-card-actions></v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  data: () => ({
    loading: false,
  }),
  methods:{
    show(state){
      this.loading = state;
    }
  }
}
</script>