<template>
  <div v-if="permisoVista('brigadape', 'r')">
    <v-btn v-if="permisoVista('brigadape', 'c')"
    color="primary" dark class="mb-2" @click="dialog = true"
    >
      Nueva asignación
    </v-btn>
    <v-dialog v-model="dialog" max-width="500px">
      <v-card>
        <v-card-title>
          <span class="text-h5">{{ formTitle }}</span>
        </v-card-title>

        <v-card-text>
          <v-container>
            <v-row>
              <v-col cols="12" sm="6" md="12">
                <v-autocomplete
                  v-model="editedItem.idPersona"
                  :items="operadores"
                  item-value="idPersona"
                  :item-text="nombrePersona"
                  label="Operador"
                  clearable
                  :error="editedItem.erroridPersonaEstatus"
                  :error-messages="editedItem.erroridPersona"
                  :key="up"
                >
                </v-autocomplete>
              </v-col>
              <v-col cols="12" sm="6" md="12">
                <v-autocomplete
                  v-model="editedItem.idBrigada"
                  :items="brigadas"
                  item-value="idBrigada"
                  :item-text="nombreBrigada"
                  label="Brigada"
                  clearable
                  :error="editedItem.erroridBrigadaEstatus"
                  :error-messages="editedItem.erroridBrigada"
                >
                </v-autocomplete>
              </v-col>
              <v-col cols="12" sm="6" md="12">
                <v-autocomplete
                  v-model="editedItem.idBrigadaPuesto"
                  :items="brigadasPuesto"
                  item-value="idBrigadaPuesto"
                  :item-text="nombreBrigadaPuesto"
                  label="Puesto de Brigada"
                  clearable
                  :error="editedItem.erroridBrigadaPuestoEstatus"
                  :error-messages="editedItem.erroridBrigadaPuesto"
                >
                </v-autocomplete>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="close">
            CANCELAR
          </v-btn>
          <v-btn color="red darken-1" text @click="save">
            ACEPTAR
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="dialogDelete" max-width="500px">
      <v-card>
       <v-card-title class="headline"
          >¿Está seguro que desea eliminar este elemento?</v-card-title
        >
        <v-card-text
          >Esta acción no se puede revertir y será permanente.</v-card-text
        >
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="closeDelete"
            >CANCELAR</v-btn
          >
          <v-btn color="red darken-1" text @click="deleteItemConfirm"
            >ACEPTAR</v-btn
          >
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-data-table
      :headers="headers"
      :items="desserts"
      sort-by="calories"
      :loading="loadData"
    >
      <template v-slot:item.nombre="{ item }">
        {{ item.nombre + " " + item.paterno + " " + item.materno }}
      </template>
      <template v-slot:item.brigada="{ item }">
        {{ item.nombreBrigada + " - [" + item.abreviaturaBrigada + "]" }}
      </template>
      <template v-slot:item.puesto="{ item }">
        {{
          item.nombreBrigadaPuesto +
            " - [" +
            item.abreviaturaBrigadaPuesto +
            "]"
        }}
      </template>
      <template v-slot:item.actions="{ item }">
        <v-icon v-if="permisoVista('brigadape', 'u')"
        small class="mr-2" @click="editItem(item)"
        >
          mdi-pencil
        </v-icon>
        <v-icon v-if="permisoVista('brigadape', 'd')"
        small @click="deleteItem(item)"
        >
          mdi-delete
        </v-icon>
      </template>
      <template v-slot:no-data>
        <v-btn color="primary" @click="initialize">
          Reset
        </v-btn>
      </template>
    </v-data-table>
  </div>
</template>

<script>
import axios from "axios";
import { NullLogger } from "@aspnet/signalr";
export default {
  data: () => ({
    up: 0,
    loadData: false,
    dialog: false,
    dialogDelete: false,
    headers: [
      { text: "Nombre", value: "nombre" },
      { text: "Brigada", value: "brigada" },
      { text: "Puesto", value: "puesto" },
      { text: "Posicion", value: "posicion" },
      { text: "Acciones", value: "actions", sortable: false },
    ],
    desserts: [],
    operadores: [],
    brigadasPuesto: [],
    brigadas: [],
    editedIndex: -1,
    editedItem: {
      idBrigadaPersona: null,
      idBrigada: null,
      idPersona: null,
      idBrigadaPuesto: null,
      erroridBrigadaEstatus: false,
      erroridBrigada: null,
      erroridPersonaEstatus: false,
      erroridPersona: null,
      erroridBrigadaPuestoEstatus: false,
      erroridBrigadaPuesto: null,
      idBrigadaActual: null,
      idPersonaActual: null,
      idBrigadaPuestoActual: null,
    },
    defaultItem: {
      idBrigadaPersona: null,
      idBrigada: null,
      idPersona: null,
      idBrigadaPuesto: null,
      erroridBrigadaEstatus: false,
      erroridBrigada: null,
      erroridPersonaEstatus: false,
      erroridPersona: null,
      erroridBrigadaPuestoEstatus: false,
      erroridBrigadaPuesto: null,
      idBrigadaActual: null,
      idPersonaActual: null,
      idBrigadaPuestoActual: null,
    },
  }),

  computed: {
    formTitle() {
      return this.editedIndex === -1
        ? "Asignar persona a brigada"
        : "Editar Persona brigada";
    },
  },

  watch: {
    dialog(val) {
      val || this.close();
    },
    dialogDelete(val) {
      val || this.closeDelete();
    },
  },

  created() {
    this.initialize();
  },

  methods: {
    initialize() {
      try {
        this.getBrigadaPersonas();
      } catch (error) {
        console.log(error);
      }
      this.getOperadores();
      this.getBrigadaPuesto();
      this.getBrigadas();
    },
    nombrePersona: ({ nombre, paterno, materno }) =>
      `${nombre ? `${nombre} ` : ""}${paterno ? `${paterno} ` : ""}${
        materno ? `${materno} ` : ""
      }`.trim(),
    nombreBrigadaPuesto: ({ nombre, abreviatura, posicion }) =>
      `${nombre} - ${abreviatura} ${posicion}`.trim(),
    nombreBrigada: ({ nombre, abreviatura }) =>
      `${nombre} - ${abreviatura}`.trim(),
    editItem(item) {
      this.editedIndex = this.desserts.indexOf(item);
      
      this.editedItem.idBrigadaPersona = item.idBrigadaPersona;
      this.editedItem.idBrigada = item.idBrigada;
      this.editedItem.idPersona = item.idPersona;
      this.editedItem.idBrigadaPuesto = item.idBrigadaPuesto;
      this.editedItem.erroridBrigadaEstatus = item.erroridBrigadaEstatus;
      this.editedItem.erroridBrigada = item.erroridBrigada;
      this.editedItem.erroridPersonaEstatus = item.erroridPersonaEstatus;
      this.editedItem.erroridPersona = item.erroridPersona;
      this.editedItem.erroridBrigadaPuestoEstatus =
        item.erroridBrigadaPuestoEstatus;
      this.editedItem.erroridBrigadaPuesto = item.erroridBrigadaPuesto;
      this.editedItem.idBrigadaActual = JSON.parse(
        JSON.stringify(item.idBrigada)
      );
      this.editedItem.idPersonaActual = JSON.parse(
        JSON.stringify(item.idPersona)
      );
      this.editedItem.idBrigadaPuestoActual = JSON.parse(
        JSON.stringify(item.idBrigadaPuesto)
      );

      this.dialog = true;
    },

    deleteItem(item) {
      this.editedIndex = this.desserts.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialogDelete = true;
    },

    deleteItemConfirm() {
      //this.desserts.splice(this.editedIndex, 1);
      axios
        .put("/BrigadaPersonas/Desactivar/" + this.editedItem.idBrigadaPersona)
        .then((response) => {
          this.dialog = false;
          this.close();
          this.initialize();
        })
        .catch((error) => {
          console.log(error);
        });
      this.closeDelete();
    },

    close() {
      this.dialog = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },

    closeDelete() {
      this.dialogDelete = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },
    validacion() {
      if (this.editedItem.idBrigada == null) {
        this.editedItem.erroridBrigadaEstatus = true;
        this.editedItem.erroridBrigada = "Selecciona una brigada";
      } else {
        this.editedItem.erroridBrigadaEstatus = null;
        this.editedItem.erroridBrigada = null;
      }

      if (this.editedItem.idPersona == null) {
        this.editedItem.erroridPersonaEstatus = true;
        this.editedItem.erroridPersona = "Seleccione una persona";
      } else {
        this.editedItem.erroridPersonaEstatus = null;
        this.editedItem.erroridPersona = null;
      }

      if (this.editedItem.idBrigadaPuesto == null) {
        this.editedItem.erroridBrigadaPuestoEstatus = true;
        this.editedItem.erroridBrigadaPuesto = "Seleccione un puesto";
      } else {
        this.editedItem.erroridBrigadaPuestoEstatus = null;
        this.editedItem.erroridBrigadaPuesto = null;
      }

      this.up++;
      if (
        this.editedItem.erroridBrigadaPuestoEstatus == null &&
        this.editedItem.erroridPersonaEstatus == null &&
        this.editedItem.erroridBrigadaEstatus == null
      )
        return false;
      else return true;
    },
    save() {
      try {
        if (this.validacion()) return;

        var obj =
          this.editedIndex > -1
            ? {
                idBrigada: this.editedItem.idBrigada,
                idPersona: this.editedItem.idPersona,
                idBrigadaPuesto: this.editedItem.idBrigadaPuesto,
                idBrigadaActual: this.editedItem.idBrigadaActual,
                idPersonaActual: this.editedItem.idPersonaActual,
                idBrigadaPuestoActual: this.editedItem.idBrigadaPuestoActual,
              }
            : {
                idBrigada: this.editedItem.idBrigada,
                idPersona: this.editedItem.idPersona,
                idBrigadaPuesto: this.editedItem.idBrigadaPuesto,
              };

        console.log(this.editedItem);

        if (this.editedIndex > -1) {
          axios
            .put(
              "/BrigadaPersonas/Actualizar/" + this.editedItem.idBrigadaPersona,
              obj
            )
            .then((response) => {
              this.dialog = false;
              this.close();
              this.initialize();
            })
            .catch((error) => {
              console.log(error);
              if (error.response) {
                if (error.response.status == 409) {
                  this.editedItem.erroridBrigadaEstatus = true;
                  this.editedItem.erroridBrigada =
                    "Selecciona una brigada diferente";
                  this.editedItem.erroridPersonaEstatus = true;
                  this.editedItem.erroridPersona =
                    "Seleccione una persona diferente";
                  this.editedItem.erroridBrigadaPuestoEstatus = true;
                  this.editedItem.erroridBrigadaPuesto =
                    "Seleccione un puesto diferente";
                }
              }
            });
        } else {
          axios
            .post("/BrigadaPersonas/Crear", obj)
            .then(() => {
              this.dialog = false;
              this.close();
              this.initialize();
            })
            .catch((error) => {
              if (error.response) {
                if (error.response.status == 409) {
                  this.editedItem.erroridBrigadaEstatus = true;
                  this.editedItem.erroridBrigada =
                    "Selecciona una brigada diferente";
                  this.editedItem.erroridPersonaEstatus = true;
                  this.editedItem.erroridPersona =
                    "Seleccione una persona diferente";
                  this.editedItem.erroridBrigadaPuestoEstatus = true;
                  this.editedItem.erroridBrigadaPuesto =
                    "Seleccione un puesto diferente";
                }
              }
            });
        }
      } catch (error) {
        console.log(error);
      }
    },
    getBrigadas() {
      axios
        .get("/Brigada")
        .then((response) => {
          this.brigadas = response.data;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    getOperadores() {
      axios
        .get("/Personas/Operadores")
        .then((response) => {
          this.operadores = response.data;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    getBrigadaPuesto() {
      axios
        .get("/BrigadaPuestos")
        .then((response) => {
          this.brigadasPuesto = response.data;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    getBrigadaPersonas() {
      this.loadData = true;
      axios
        .get("/BrigadaPersonas")
        .then((response) => {
          this.desserts = response.data;
          this.loadData = false;
        })
        .catch((error) => {
          console.log(error);
          this.loadData = false;
        });
    },
  },
};
</script>
