<template>
  <v-container fluid>
    <v-dialog v-model="dialogSKU" persistent max-width="400px">
      <v-card>
        <v-card-title>Editar SKU</v-card-title>
        <v-card-text>
          <v-container fluid>
            <v-row>
              <v-col cols="12">
                <v-text-field
                  v-model="skuItem.sku"
                  label="SKU"
                  clearable
                  :error-messages="errorSKU"
                  @focus="errorSKU=null"
                ></v-text-field>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            text
            color="success"
            :disabled="skuItem.savingSku"
            @click="closeDialogSKU()"
            >Cancelar</v-btn
          >
          <v-btn
            text
            color="error"
            :disabled="skuItem.savingSku"
            :loading="skuItem.savingSku"
            @click="saveSKU()"
            >Guardar</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-row>
      <v-col cols="4">
        <v-autocomplete
          v-model="filtros.idMaquinaIns"
          :items="maquinasInstancias"
          :item-text="nombreMaquinaInstancia"
          item-value="idMaquinaIns"
          label="Máquina Instancia"
          hide-details
          clearable
          :loading="loadingMaquinasInstancias"
          :disabled="loading"
          @change="obtenerDatos"
        ></v-autocomplete>
      </v-col>
      <v-col cols="4">
        <datePicker
          v-model="filtros.fecha"
          format="YYYY-MM-DD"
          label="Fecha"
          clearable
          :class="loading && 'disabled-input'"
          :disabled="loading"
          maxToday
          @input="obtenerDatos"
        ></datePicker>
      </v-col>
      <v-col cols="4">
        <v-autocomplete
          v-model="filtros.turno"
          label="Turno"
          :items="turnos"
          item-text="nombre"
          item-value="idTurno"
          clearable
          :loading="loadingTurnos"
          :disabled="loading"
          @change="obtenerDatos"
        ></v-autocomplete>
      </v-col>
    </v-row>
    <v-row v-if="realizarPeticion" class="px-0 ma-0">
      <v-col cols="12" class="ma-0 text-right text-subtitle-2">
        Proxima Actualización en {{ tiempo }}
      </v-col>
    </v-row>
    <v-row v-if="loading">
      <v-col cols="12" class="text-center">
        <v-progress-circular
          :size="70"
          :width="7"
          color="green"
          indeterminate
        ></v-progress-circular>
        <h2>Cargando...</h2>
      </v-col>
    </v-row>
    <v-row v-else>
      <v-col cols="12" v-if="realizarPeticion">
        <v-simple-table class="custom-stripped-color">
          <template v-slot:default>
            <thead class="custom-background-color white--text">
              <tr>
                <th class="force-border-bottom" rowspan="2">
                  Hora
                </th>
                <th class="force-border-bottom" rowspan="2">
                  SKU
                </th>
                <th
                  class="
										background-color-estandar
										border-bottom-white
									"
                  colspan="2"
                >
                  Estandar
                </th>
                <th
                  class="
										background-color-real
										border-bottom-white
									"
                  colspan="2"
                >
                  Real
                </th>
                <th
                  class="
										background-color-diferencia
										border-bottom-white
									"
                  colspan="2"
                >
                  Diferencia
                </th>
                <th class="force-border-bottom" rowspan="2">
                  Piezas NO OK
                </th>
                <th class="force-border-bottom" rowspan="2">
                  Tiempo Muerto <br />
                  (Minutos)
                </th>
              </tr>
              <tr>
                <th
                  class="
										background-color-estandar
										max-width-th
									"
                  style="border-left: 1px solid #dddddd"
                >
                  Hora
                </th>
                <th
                  class="
										background-color-estandar
										max-width-th
									"
                >
                  Acumulado
                </th>
                <th class="background-color-real max-width-th">
                  Hora
                </th>
                <th class="background-color-real max-width-th">
                  Acumulado
                </th>
                <th
                  class="
										background-color-diferencia
										max-width-th
									"
                >
                  Hora
                </th>
                <th
                  class="
										background-color-diferencia
										max-width-th
									"
                >
                  Acumulado
                </th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(dato, idx) in datos.renglones" :key="`dato-${idx}`">
                <td>{{ dato.horaInicio }} - {{ dato.horaFin }}</td>
                <td>
                  <div class="d-flex align-center justify-center">
                    <p class="mb-0 mr-2">{{ dato.sku || "N/D" }}</p>
                    <v-icon small color="grey darken-1" @click="showDialogSKU(dato)">mdi-pencil</v-icon>
                  </div>
                </td>
                <td
                  :class="{
                    'background-color-estandar_row': index % 2 != 0,
                  }"
                >
                  <ICountUp
                    v-if="!dato.editar"
                    :endVal="dato.estandarHora"
                    :delay="500"
                    class="counter-font-default-size"
                  />
                  <v-btn text icon @click="editNumber(dato, 1)">
                    <v-icon small color="grey darken-1">mdi-pencil</v-icon>
                  </v-btn>
                </td>
                <td
                  :class="{
                    'background-color-estandar_row': index % 2 != 0,
                  }"
                >
                  <ICountUp
                    :endVal="dato.estandarAcumulado"
                    :delay="500"
                    class="counter-font-default-size"
                  />
                </td>
                <td
                  :class="{
                    'background-color-real_row': index % 2 != 0,
                  }"
                >
                  <ICountUp
                    :endVal="dato.realHora"
                    :delay="500"
                    class="counter-font-default-size"
                  />
                </td>
                <td
                  :class="{
                    'background-color-real_row': index % 2 != 0,
                  }"
                >
                  <ICountUp
                    :endVal="dato.realAcumulado"
                    :delay="500"
                    class="counter-font-default-size"
                  />
                </td>
                <td
                  :class="{
                    'background-color-diferencia_row': index % 2 != 0,
                  }"
                >
                  <ICountUp
                    :endVal="dato.diferenciaHora"
                    :delay="500"
                    class="counter-font-default-size"
                  />
                </td>
                <td
                  :class="{
                    'background-color-diferencia_row': index % 2 != 0,
                  }"
                >
                  <ICountUp
                    :endVal="dato.diferenciaAcumulado"
                    :delay="500"
                    class="counter-font-default-size"
                  />
                </td>
                <td>
                  <ICountUp
                    :endVal="dato.noOk"
                    :delay="500"
                    class="counter-font-default-size"
                  />
                  <v-btn v-if="editarPzsNoOk" text icon @click="editNumber(dato, 2)">
                    <v-icon small color="grey darken-1">mdi-pencil</v-icon>
                  </v-btn>
                </td>
                <td>
                  <ICountUp
                    :endVal="dato.tiempoMuerto"
                    :delay="500"
                    class="counter-font-default-size"
                  />
                  <v-btn v-if="editarTiempoMuerto" text icon @click="editNumber(dato, 3)">
                    <v-icon small color="grey darken-1">mdi-pencil</v-icon>
                  </v-btn>

                </td>
              </tr>
              <tr>
                <td class="custom-background-color white--text" colspan="2">
                  Total
                </td>
                <td class="background-color-estandar_row" colspan="2">
                  <ICountUp
                    :endVal="datos.totalEstandar"
                    :delay="500"
                    class="counter-font-default-size"
                  />
                </td>
                <td class="background-color-real_row" colspan="2">
                  <ICountUp
                    :endVal="datos.totalReal"
                    :delay="500"
                    class="counter-font-default-size"
                  />
                </td>
                <td class="background-color-diferencia_row" colspan="2">
                  <ICountUp
                    :endVal="datos.totalDiferencia"
                    :delay="500"
                    class="counter-font-default-size"
                  />
                </td>
                <td>
                  <ICountUp
                    :endVal="datos.totalNoOk"
                    :delay="500"
                    class="counter-font-default-size"
                  />
                </td>
                <td>
                  <ICountUp
                    :endVal="datos.totalTiempoMuerto"
                    :delay="500"
                    class="counter-font-default-size"
                  />
                </td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>
      </v-col>
      <v-col cols="12" v-else>
        <v-alert color="blue-grey" text class="mb-0">
          <div class="text-center" style="font-size: 16px">
            Debe de Seleccionar Maquina, Fecha y Turno
          </div>
        </v-alert>
      </v-col>
    </v-row>
    <v-dialog v-model="dialog" persistent max-width="400px">
      <v-card>
        <v-card-title style="word-break: break-word">{{ formTitle }}</v-card-title>
        <v-card-text>
          <v-row>
            <v-col cols="12">
              <v-text-field
                v-model="number"
                :label="
                  type == 1
                    ? 'Piezas por Hora'
                    : type == 2
                    ? 'Piezas NO Ok'
                    : type == 3
                    ? 'Tiempo Muerto'
                    : ''
                "
                type="number"
                step="0.1"
                min="0"
                hide-spin-buttons
                :error="errorNumberstatus"
                :error-messages="errorNumber"
                :key="up"
                @input="validar()"
              ></v-text-field>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="success" text @click="Cancelar()">
            Cancelar
          </v-btn>
          <v-btn color="error" text @click="sendDataHora()">
            Aceptar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import ICountUp from "vue-countup-v2";
import axios from "axios";
import { mapState } from "vuex"
export default {
  components: {
    ICountUp,
  },
  data() {
    return {
      errorSKU: null,
      dialogSKU: false,
      skuItem: {
        sku: null,
        fechaInicio: null,
        fechaFin: null,
      },
      defaultSkuItem: {
        sku: null,
        fechaInicio: null,
        fechaFin: null,
      },
      dialog: false,
      type: null,
      number: null,
      objeto: null,
      errorNumberstatus: false,
      errorNumber: null,
      up: 1,
      filtros: {
        idMaquinaIns: null,
        fecha: null,
        turno: null,
      },
      loadingTurnos: false,
      turnos: [],
      loadingMaquinasInstancias: false,
      maquinasInstancias: [],
      loading: false,
      datos: {
        totalEstandar: 0.0,
        totalReal: 0.0,
        totalDiferencia: 0.0,
        totalNoOk: 0.0,
        totalTiempoMuerto: 0.0,
        renglones: [],
      },
      datosDefault: {
        totalEstandar: 0.0,
        totalReal: 0.0,
        totalDiferencia: 0.0,
        totalNoOk: 0.0,
        totalTiempoMuerto: 0.0,
        renglones: [],
      },
      segundos: 20,
      interval: null,
    };
  },
  mounted() {
    this.initialize();
    this.interval = setInterval(() => {
      if (this.loading || !this.realizarPeticion) return;

      if (this.segundos == 0) {
        this.obtenerDatos();
      } else this.segundos -= 1;
    }, 1000);
  },
  destroyed() {
    clearInterval(this.interval);
  },
  computed: {
    ...mapState(['editarPzsNoOk','editarTiempoMuerto']),
    realizarPeticion() {
      return !(
        this.filtros.idMaquinaIns == null ||
        this.filtros.idMaquinaIns <= 0 ||
        this.filtros.fecha == null ||
        this.filtros.turno == null ||
        this.filtros.turno <= 0
      );
    },
    tiempo() {
      return this.$utils.strings.toTime(this.segundos);
    },
    formTitle() {
      return this.type === 1
        ? "Editar Piezas por Hora"
        : this.type == 2
        ? "Editar Piezas No Ok"
        : this.type == 3
        ? "Editar Tiempo Muerto"
        : "";
    },
  },
  methods: {
    validateSKU(){
      this.errorSKU = null;

      if(!this.skuItem.sku){
        this.errorSKU = "Requerido";
      }

      return this.errorSKU == null;
    },
    saveSKU(){
      if(!this.validateSKU()) return;

      this.skuItem.savingSku = true;

      axios
        .post('/MaquinaInsOEEManual/AgregarSKU',{
          idMaquinaIns: this.filtros.idMaquinaIns,
          inicio: this.skuItem.fechaInicio,
          fin: this.skuItem.fechaFin,
          sku: this.skuItem.sku
        })
        .then( () => {
          this.closeDialogSKU();
          this.obtenerDatos();
        })
        .catch( (error) => {
          console.log(error);
          this.errorSKU = "Ocurrio un error";
        })
        .finally( () => {
          this.skuItem.savingSku = false;
        });
    },
    closeDialogSKU(){
      this.dialogSKU = false;

      setTimeout(() => {
        this.errorSKU = null;
        this.skuItem = { ...this.defaultSkuItem };
      }, 200);
    },
    showDialogSKU(dato){
      this.skuItem = { ...dato };
      this.dialogSKU = true;
    },
    initialize() {
      this.getMaquinasInstancias();
      this.getTurnos();
    },
    sotpInterval() {
      clearInterval(this.interval);
    },
    getTurnos() {
      this.loadingTurnos = true;
      axios
        .get("/Turnos")
        .then((response) => {
          this.turnos = response.data;
        })
        .catch(console.log)
        .finally(() => {
          this.loadingTurnos = false;
        });
    },
    getMaquinasInstancias() {
      this.loadingMaquinasInstancias = true;
      axios
        .get("/MaquinaInstancias")
        .then((response) => {
          this.maquinasInstancias = response.data;
        })
        .catch(console.log)
        .finally(() => {
          this.loadingMaquinasInstancias = false;
        });
    },
    nombreMaquinaInstancia: ({ nombre, marca, modelo }) => {
      let nombreMaquinaInstancia = "";
      nombreMaquinaInstancia += nombre ? `[${nombre}] - ` : "";
      nombreMaquinaInstancia += marca ? `${marca} ` : "";
      nombreMaquinaInstancia += modelo ? `${modelo}` : "";
      return nombreMaquinaInstancia.trim();
    },
    obtenerDatos() {
      if (!this.realizarPeticion) return;

      this.loading = true;
      this.datos = Object.assign({}, this.datosDefault);
      axios
        .get(
          `/Reportes/ReporteAcumuladoMaquina?idMaquinaIns=${this.filtros.idMaquinaIns}&fecha=${this.filtros.fecha}&idTurno=${this.filtros.turno}`
        )
        .then((response) => {
          this.datos.totalEstandar = response.data.totalEstandar;
          this.datos.totalReal = response.data.totalReal;
          this.datos.totalDiferencia = response.data.totalDiferencia;
          this.datos.totalNoOk = response.data.totalNoOk;
          this.datos.totalTiempoMuerto = response.data.totalTiempoMuerto;

          this.datos.renglones = response.data.renglones.map((element) => ({
            ...element,
            editar: false,
            editarNok: false,
            editartiempoMuerto: false,
          }));
          this.loading = false;
          this.segundos = 20;
        })
        .catch((error) => {
          console.log(error);
          this.loading = false;
          this.datos = Object.assign({}, this.datosDefault);
          this.segundos = 20;
          //this.sotpInterval();
        });
    },
    editNumber(item, type) {
      this.objeto = item;
      this.type = type;
      this.dialog = true;
    },
    validar() {
      if (this.number == null || this.number == "") {
        this.errorNumberstatus = true;
        this.errorNumber = "Ingresa una cantidad valida";
        this.up++;
      } else {
        this.errorNumberstatus = false;
        this.errorNumber = null;
      }
    },
    Cancelar() {
      this.dialog = false;
      this.number = null;
      this.objeto = null;
      this.errorNumberstatus = false;
      this.errorNumber = null;
    },
    sendDataHora() {
      if (this.number == null) {
        this.validar();
        return;
      }

      if (this.type == 1) {
        axios
          .post("/MaquinaInsOEEManual/AgregarPzaTeorico", {
            idMaquinaIns: this.filtros.idMaquinaIns,
            inicio: this.objeto.fechaInicio,
            fin: this.objeto.fechaFin,
            valor: parseFloat(this.number),
          })
          .then((response) => {
            this.obtenerDatos();
          })
          .catch((error) => {
            console.error(error);
          });
      }

      if (this.type == 2) {
        axios
          .post("/MaquinaInsOEEManual/AgregarPzaNoOk", {
            idMaquinaIns: this.filtros.idMaquinaIns,
            inicio: this.objeto.fechaInicio,
            fin: this.objeto.fechaFin,
            valor: parseFloat(this.number),
          })
          .then((response) => {
            this.obtenerDatos();
          })
          .catch((error) => {
            console.error(error);
          });
      }

      if (this.type == 3) {
        axios
          .post("/MaquinaInsOEEManual/AgregarTiempoParo", {
            idMaquinaIns: this.filtros.idMaquinaIns,
            inicio: this.objeto.fechaInicio,
            fin: this.objeto.fechaFin,
            valor: parseFloat(this.number),
          })
          .then((response) => {
            this.obtenerDatos();
          })
          .catch((error) => {
            console.error(error);
          });
      }
      this.number = null;
      this.objeto = null;
      this.dialog = false;
    },
  },
};
</script>

<style scoped>
.disabled-input {
  cursor: default !important;
}

.disabled-input >>> .vd-activator {
  pointer-events: none;
}

.disabled-input >>> label {
  pointer-events: none;
}

.force-border-bottom {
  border-bottom: thin solid rgba(0, 0, 0, 0.12);
}

.border-bottom-white {
  border-bottom: 1px solid #dddddd;
}

.max-width-th {
  max-width: 80px;
}

.custom-background-color {
  background-color: rgb(42, 96, 153);
}

.custom-stripped-color tbody tr:nth-child(odd) {
  background-color: rgba(42, 96, 153, 0.14);
}

.background-color-estandar {
  background-color: #278db0;
}

.background-color-estandar_row {
  background-color: rgba(38, 141, 176, 0.3);
}

.background-color-real {
  background-color: #993981;
}

.background-color-real_row {
  background-color: rgba(153, 57, 129, 0.3);
}

.background-color-diferencia {
  background-color: #cc6023;
}

.background-color-diferencia_row {
  background-color: rgba(204, 97, 35, 0.4);
}

.counter-font-default-size {
  font-size: 0.875rem;
}

th {
  text-align: center !important;
  font-size: 1.0625rem !important;
  color: white !important;
}

tbody tr {
  text-align: center !important;
}

table th + th {
  border-left: 1px solid #dddddd;
}
table td + td {
  border-left: 1px solid #dddddd;
}

.no-seleccionable {
  -webkit-user-select: none; /* Para navegadores basados en Webkit */
  -moz-user-select: none; /* Para navegadores basados en Mozilla */
  -ms-user-select: none; /* Para navegadores basados en Microsoft */
  user-select: none; /* Estándar CSS */
}
</style>
