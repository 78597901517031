<template>
  <div>
    <v-row>
      <v-col cols="6" class="pt-0">
        <datePicker
          v-model="filtros.desde"
          format="YYYY-MM-DD"
          :maxDate="fin"
          label="Desde (Obligatorio)"
          clearable
          
          @input="getAreas(IdOrdenTrabajo)"
          :disabled="loading"
        ></datePicker>
      </v-col>
      <v-col cols="6" class="pt-0">
        <datePicker
          v-model="filtros.hasta"
          :minDate="filtros.desde"
          format="YYYY-MM-DD"
          label="Hasta (Obligatorio)"
          clearable
          
          @input="getAreas(IdOrdenTrabajo)"
          :disabled="loading"
        ></datePicker>
      </v-col>
      <v-col cols="12">
        <div>
          <v-data-table
            v-if="loading"
            item-key="name"
            class="elevation-1"
            :headers="headers"
            :loading="loading"
            loading-text="Cargando... Espere por favor"
          ></v-data-table>
          <v-data-table
            v-else-if="desserts.length < 1"
            item-key="name"
            class="elevation-1"
            :headers="headers"
          ></v-data-table>
          <v-data-table
            v-else
            v-for="(item, key) in desserts"
            :key="key"
            :headers="headers"
            :items="item.data"
            :items-per-page="5"
            class="elevation-1"
          >
            <template v-slot:top>
              <v-toolbar flat>
                <v-toolbar-title>{{ item.semana }}</v-toolbar-title>
              </v-toolbar>
            </template>

            <template v-slot:item.numPedido="{ item }">
              <template v-if="nombreEmpresa == 'MYC'">
                <v-text-field
                  style="text-align: center"
                  v-if="item.editNumPedido"
                  v-model="item.numPedido"
                  append-outer-icon="mdi-close"
                  @click:append-outer="cancelNoPedido(item, key)"
                  append-outer-icon-append="Cancelar"
                  append-icon="mdi-content-save"
                  @click:append="guardarNoPedido(item, key)"
                  append-icon-append="Guardar"
                  :error="item.errorEstatusNumPedido"
                  :error-messages="item.errorEstatusTextoNumPedido"
                  :loading="animationNumPedido"
                  :disabled="animationNumPedido"
                ></v-text-field>
                <p v-else class="text-center">
                  {{ item.numPedido == null ? "N/A" : item.numPedido }}
                  <v-btn
                    color="primary"
                    icon
                    x-small
                    @click="editarNoPedido(item, key)"
                  >
                    <v-icon>mdi-pencil</v-icon>
                  </v-btn>
                </p>
              </template>
              <template v-else>
                {{ item.numPedido == null ? "N/A" : item.numPedido }}
              </template>
            </template>
            <template v-slot:item.mycPedido="{ item }">
              <template v-if="nombreEmpresa == 'MYC'">
                <v-text-field
                  style="text-align: center"
                  v-if="item.editMycPedido"
                  v-model="item.mycPedido"
                  append-outer-icon="mdi-close"
                  @click:append-outer="cancelMycPedido(item, key)"
                  append-outer-icon-append="Cancelar"
                  append-icon="mdi-content-save"
                  @click:append="guardarMycPedido(item, key)"
                  append-icon-append="Guardar"
                  :error="item.errorEstatusMycPedido"
                  :error-messages="item.errorEstatusTextoMycPedido"
                  :loading="animationMycPedido"
                  :disabled="animationMycPedido"
                ></v-text-field>
                <p v-else class="text-center">
                  {{ item.mycPedido == null ? "N/A" : item.mycPedido }}
                  <v-btn
                    color="primary"
                    icon
                    x-small
                    @click="editarMycPedido(item, key)"
                  >
                    <v-icon>mdi-pencil</v-icon>
                  </v-btn>
                </p>
              </template>
              <template v-else>
                {{ item.mycPedido == null ? "N/A" : item.mycPedido }}
              </template>
            </template>
            <template v-slot:item.acumRemision="{ item }">
              <template v-if="nombreEmpresa == 'MYC'">
                <v-text-field
                  style="text-align: center"
                  v-if="item.EditAcumRemision"
                  v-model="item.acumRemision"
                  append-outer-icon="mdi-close"
                  @click:append-outer="cancelAcumRemision(item, key)"
                  append-outer-icon-append="Cancelar"
                  append-icon="mdi-content-save"
                  @click:append="guardarAcumRemision(item, key)"
                  append-icon-append="Guardar"
                  :error="item.errorEstatusAcumRemision"
                  :error-messages="item.errorEstatusTextoAcumRemision"
                  :loading="animationAcumRemision"
                  :disabled="animationAcumRemision"
                ></v-text-field>
                <p v-else class="text-center">
                  {{ item.acumRemision == null ? "N/A" : item.acumRemision }}
                  <v-btn
                    color="primary"
                    icon
                    x-small
                    @click="editarAcumRemision(item, key)"
                  >
                    <v-icon>mdi-pencil</v-icon>
                  </v-btn>
                </p>
              </template>
              <template v-else>
                {{ item.acumRemision == null ? "N/A" : item.acumRemision }}
              </template>
            </template>
            <template v-slot:item.observaciones="{ item }">
              <template v-if="nombreEmpresa == 'MYC'">
                <v-text-field
                  style="text-align: center"
                  v-if="item.editObservacion"
                  v-model="item.observaciones"
                  append-outer-icon="mdi-close"
                  @click:append-outer="cancelObservacion(item, key)"
                  append-outer-icon-append="Cancelar"
                  append-icon="mdi-content-save"
                  @click:append="guardarObservacion(item, key)"
                  append-icon-append="Guardar"
                  :error="item.errorEstatusObervacion"
                  :error-messages="item.errorEstatusTextoObervacion"
                  :loading="animationObservacion"
                  :disabled="animationObservacion"
                ></v-text-field>
                <p v-else class="text-center">
                  {{ item.observaciones == null ? "N/A" : item.observaciones }}
                  <v-btn
                    color="primary"
                    icon
                    x-small
                    @click="editarObservacion(item, key)"
                  >
                    <v-icon>mdi-pencil</v-icon>
                  </v-btn>
                </p>
              </template>
              <template v-else>
                {{ item.observaciones == null ? "N/A" : item.observaciones }}
              </template>
            </template>
          </v-data-table>
        </div>
      </v-col>
      <v-col cols="12">
        <DownloadBtn
          text
          color="primary"
          className="my-0"
          @click="report"
          label="Descargar Reporte MP<br/>Semanal"
          :disabled="
            filtros.desde == null || filtros.hasta == null ? true : false
          "
          :retryTime="20"
        />
      </v-col>
    </v-row>
  </div>
</template>

<script>
import axios from "axios";
import { mapState } from "vuex";
export default {
  props: {
    IdOrdenTrabajo: {
      type: Number,
      default: null,
    },
  },
  data() {
    return {
      maxDate: null,
      filtros: {
        desde: null,
        hasta: null,
      },
      loading: false,
      headers: [
        {
          text: "Fecha Entrega", //
          align: "start",
          sortable: false,
          value: "fechaCompromiso",
        },
        { text: "Cliente", value: "cliente", sortable: false }, //
        { text: "No. Pedido", value: "numPedido", sortable: false }, //
        { text: "Myc Pedido", value: "mycPedido", sortable: false }, //
        { text: "Modelo", value: "articulo", sortable: false }, //
        { text: "Pza pedido", value: "piezas", sortable: false }, //
        { text: "Pza x entregar", value: "piezasEntregar", sortable: false }, //
        { text: "Ultima remisión", value: "ultRemision", sortable: false }, //
        { text: "Acum remisión", value: "acumRemision", sortable: false }, //
        { text: "Entrada PT", value: "pt", sortable: false }, //
        { text: "N Lote", value: "num", sortable: false }, //
        { text: "Obervaciones", value: "observaciones", sortable: false }, //
      ],
      desserts: [],
    };
  },
  created() {
    /* this.getAreas(this.IdOrdenTrabajo) */ this.EliminarHeader();
  },
  computed: {
    ...mapState({
      nombreEmpresa: "nombreEmpresa",
    }),
  },
  methods: {
    isNullOrWhiteSpace(str) {
      return !str || str.trim() === "";
    },
    EliminarHeader() {
      if (this.nombreEmpresa != "MYC") {
        this.headers = this.headers.filter(
          (header) =>
            header.value !== "numPedido" && header.value !== "mycPedido"
        );
      }
    },
    cancelNoPedido(obj, pos) {
      var indice = this.busquedaIndice(obj, pos);
      this.desserts[pos].data[indice].numPedido =
        this.desserts[pos].data[indice].numPedidoAnterior;
      this.desserts[pos].data[indice].editNumPedido = false;
      this.desserts[pos].data[indice].errorEstatusNumPedido = false;
      this.desserts[pos].data[indice].errorEstatusTextoNumPedido = null;
    },
    editarNoPedido(obj, pos) {
      var indice = this.busquedaIndice(obj, pos);
      
      this.desserts[pos].data[indice].numPedidoAnterior =
        this.desserts[pos].data[indice].numPedido;
      this.desserts[pos].data[indice].editNumPedido = true;
    },
    guardarNoPedido(obj, pos) {
      var indice = this.busquedaIndice(obj, pos);

      if (this.isNullOrWhiteSpace(this.desserts[pos].data[indice].numPedido)) {
        this.desserts[pos].data[indice].errorEstatusNumPedido = true;
        this.desserts[pos].data[indice].errorEstatusTextoNumPedido =
          "Verificar";
        return;
      } else {
        this.desserts[pos].data[indice].errorEstatusNumPedido = false;
        this.desserts[pos].data[indice].errorEstatusTextoNumPedido = null;
      }

      this.desserts[pos].data[indice].animationNumPedido = true;

      var data = {
        idTrabajoOrden: this.IdOrdenTrabajo,
        NumPedido: this.desserts[pos].data[indice].numPedido,
      };
      axios
        .put("/TrabajoOrdenes/EditarNumPedido/", data)
        .then((respose) => {
          this.desserts[pos].data[indice].numPedidoAnterior = null;
          this.desserts[pos].data[indice].editNumPedido = false;
          this.desserts[pos].data[indice].errorEstatusNumPedido = false;
          this.desserts[pos].data[indice].errorEstatusTextoNumPedido = null;
          this.desserts[pos].data[indice].animationNumPedido = false;
        })
        .catch((error) => {
          this.desserts[pos].data[indice].numPedido =
            this.desserts[pos].data[indice].numPedidoAnterior;
          this.desserts[pos].data[indice].numPedidoAnterior = null;
          this.desserts[pos].data[indice].editNumPedido = false;
          this.desserts[pos].data[indice].animationNumPedido = false;
          console.log(error);
        });
    },
    cancelMycPedido(obj, pos) {
      var indice = this.busquedaIndice(obj, pos);
      this.desserts[pos].data[indice].mycPedido =
        this.desserts[pos].data[indice].mycPedidoAnterior;
      this.desserts[pos].data[indice].editMycPedido = false;
      this.desserts[pos].data[indice].errorEstatusTextoMycPedido = false;
      this.desserts[pos].data[indice].errorEstatusMycPedido = null;
    },
    editarMycPedido(obj, pos) {

      var indice = this.busquedaIndice(obj, pos);
      this.desserts[pos].data[indice].mycPedidoAnterior =
        this.desserts[pos].data[indice].mycPedido;
      this.desserts[pos].data[indice].editMycPedido = true;
    },
    guardarMycPedido(obj, pos) {
      var indice = this.busquedaIndice(obj, pos);

      if (this.isNullOrWhiteSpace(this.desserts[pos].data[indice].mycPedido)) {
        this.desserts[pos].data[indice].errorEstatusTextoMycPedido =
          "Verificar";
        this.desserts[pos].data[indice].errorEstatusMycPedido = true;
        return;
      } else {
        this.desserts[pos].data[indice].errorEstatusTextoMycPedido = null;
        this.desserts[pos].data[indice].errorEstatusMycPedido = false;
      }

      var data = {
        idTrabajoOrden: this.IdOrdenTrabajo,
        MycPedido: this.desserts[pos].data[indice].mycPedido,
      };
      this.desserts[pos].data[indice].animationMycPedido = true;
      axios
        .put("/TrabajoOrdenes/EditarMycPedido/", data)
        .then((respose) => {
          this.desserts[pos].data[indice].editMycPedido = false;
          this.desserts[pos].data[indice].mycPedidoAnterior = null;
          this.desserts[pos].data[indice].errorEstatusTextoMycPedido = false;
          this.desserts[pos].data[indice].errorEstatusMycPedido = null;
          this.desserts[pos].data[indice].animationMycPedido = false;
        })
        .catch((error) => {
          this.desserts[pos].data[indice].errorEstatusTextoMycPedido = false;
          this.desserts[pos].data[indice].errorEstatusMycPedido = null;
          this.desserts[pos].data[indice].editMycPedido = false;
          this.desserts[pos].data[indice].mycPedido =
            this.desserts[pos].data[indice].mycPedidoAnterior;
          this.desserts[pos].data[indice].mycPedidoAnterior = null;
          this.desserts[pos].data[indice].animationMycPedido = false;
          console.log(error);
        });
    },
    cancelObservacion(obj, pos) {
      var indice = this.busquedaIndice(obj, pos);
      this.desserts[pos].data[indice].observaciones =
        this.desserts[pos].data[indice].observacionAnterior;
      this.desserts[pos].data[indice].editObservacion = false;
      this.desserts[pos].data[indice].errorEstatusObervacion = false;
      this.desserts[pos].data[indice].errorEstatusTextoObervacion = null;
    },
    editarObservacion(obj, pos) {
      var indice = this.busquedaIndice(obj, pos);
      this.desserts[pos].data[indice].observacionAnterior =
        this.desserts[pos].data[indice].observaciones;
      this.desserts[pos].data[indice].editObservacion = true;
    },
    guardarObservacion(obj, pos) {
      var indice = this.busquedaIndice(obj, pos);

      if (
        this.isNullOrWhiteSpace(this.desserts[pos].data[indice].observaciones)
      ) {
        this.desserts[pos].data[indice].errorEstatusTextoObervacion =
          "Verificar";
        this.desserts[pos].data[indice].errorEstatusObervacion = true;
        return;
      } else {
        this.desserts[pos].data[indice].errorEstatusTextoObervacion = null;
        this.desserts[pos].data[indice].errorEstatusObervacion = false;
      }

      var data = {
        IdTrabajoOrden: this.IdOrdenTrabajo,
        Obsevaciones: this.desserts[pos].data[indice].observaciones,
      };
      this.desserts[pos].data[indice].animationObservacion = true;

      axios
        .put("/TrabajoOrdenes/EditarObservaciones/", data)
        .then((respose) => {
          this.desserts[pos].data[indice].editObservacion = false;
          this.desserts[pos].data[indice].observacionAnterior = null;
          this.desserts[pos].data[indice].errorEstatusObervacion = false;
          this.desserts[pos].data[indice].errorEstatusTextoObervacion = null;
          this.desserts[pos].data[indice].animationObservacion = false;
        })
        .catch((error) => {
          this.desserts[pos].data[indice].errorEstatusObervacion = false;
          this.desserts[pos].data[indice].errorEstatusTextoObervacion = null;
          this.desserts[pos].data[indice].editObservacion = false;
          this.desserts[pos].data[indice].mycPedido =
            this.desserts[pos].data[indice].observacionAnterior;
          this.desserts[pos].data[indice].observacionAnterior = null;
          this.desserts[pos].data[indice].animationObservacion = false;
          console.log(error);
        });
    },
    //*******************
    cancelAcumRemision(obj, pos) {
      var indice = this.busquedaIndice(obj, pos);
      this.desserts[pos].data[indice].acumRemision =
        this.desserts[pos].data[indice].acumRemisionAnterior;
      this.desserts[pos].data[indice].EditAcumRemision = false;
      this.desserts[pos].data[indice].errorEstatusAcumRemision = false;
      this.desserts[pos].data[indice].errorEstatusTextoAcumRemision = null;
    },
    editarAcumRemision(obj, pos) {
      var indice = this.busquedaIndice(obj, pos);
      
      this.desserts[pos].data[indice].acumRemisionAnterior = this.desserts[pos].data[indice].acumRemision;
      this.desserts[pos].data[indice].EditAcumRemision = true;
    },
    guardarAcumRemision(obj, pos) {
      var indice = this.busquedaIndice(obj, pos);

      if (
        this.isNullOrWhiteSpace(this.desserts[pos].data[indice].acumRemision)
      ) {
        this.desserts[pos].data[indice].errorEstatusTextoAcumRemision =
          "Verificar";
        this.desserts[pos].data[indice].errorEstatusAcumRemision = true;
        return;
      } else {
        this.desserts[pos].data[indice].errorEstatusTextoAcumRemision = null;
        this.desserts[pos].data[indice].errorEstatusAcumRemision = false;
      }

      var data = {
        idTrabajoOrden: this.IdOrdenTrabajo,
        AcumRemision: this.desserts[pos].data[indice].acumRemision,
      };
      this.desserts[pos].data[indice].animationAcumRemision = true;
      axios
        .put("/TrabajoOrdenes/EditarAcumRemision/", data)
        .then((respose) => {
          this.desserts[pos].data[indice].EditAcumRemision = false;
          this.desserts[pos].data[indice].acumRemisionAnterior = null;
          this.desserts[pos].data[indice].errorEstatusAcumRemision = false;
          this.desserts[pos].data[indice].errorEstatusTextoAcumRemision = null;
          this.desserts[pos].data[indice].animationAcumRemision = false;
        })
        .catch((error) => {
          this.desserts[pos].data[indice].EditAcumRemision = false;
          this.desserts[pos].data[indice].acumRemisionAnterior = null;
          this.desserts[pos].data[indice].errorEstatusAcumRemision = false;
          this.desserts[pos].data[indice].errorEstatusTextoAcumRemision = null;
          this.desserts[pos].data[indice].animationAcumRemision = false;
          this.desserts[pos].data[indice].acumRemision =
            this.desserts[pos].data[indice].acumRemisionAnterior;
          console.log(error);
        });
    },
    //*******************
    busquedaIndice(obj, pos) {
      var busqueda = {
        num: obj.num,
        fechaCompromiso: obj.fechaCompromiso,
        cliente: obj.cliente,
        articulo: obj.articulo,
        laminas: obj.laminas,
        piezas: obj.piezas,
        piezasEntregar: obj.piezasEntregar,
        ultRemision: obj.ultRemision,
        ptManualidades: obj.ptManualidades,
        ptCuartoBlanco: obj.ptCuartoBlanco,
        ot: obj.ot,
        pt: obj.pt
      };

      return this.desserts[pos].data.findIndex(function (item) {
        return Object.keys(item).every(function (key) {
          if (
            ![
              "observaciones",
              "numPedidoAnterior",
              "mycPedidoAnterior",
              "numPedido",
              "mycPedido",
              "editNumPedido",
              "editMycPedido",
              "errorEstatusTextoNumPedido",
              "errorEstatusNumPedido",
              "errorEstatusTextoMycPedido",
              "errorEstatusMycPedido",
              "observacionAnterior",
              "editObservacion",
              "errorEstatusTextoObervacion",
              "errorEstatusObervacion",
              "animationNumPedido",
              "animationMycPedido",
              "animationObservacion",
              "acumRemisionAnterior",
              "EditAcumRemision",
              "errorEstatusTextoAcumRemision",
              "errorEstatusAcumRemision",
              "animationAcumRemision",
              "acumRemision",
            ].includes(key)
          ) {
            return item[key] === busqueda[key];
          }
          return true;
        });
      });
    },
    getAreas(IdOrdenTrabajo) {
      if (this.filtros.desde == null || this.filtros.hasta == null) return;
      this.loading = true;
      axios
        .get("/Reportes/ReporteSemanalesPedidos", {
          params: {
            idTrabajoOrden: IdOrdenTrabajo,
            fechaInicio: this.filtros.desde,
            fechaFin: this.filtros.hasta,
          },
        })
        .then((response) => {
          var datos = response.data.map((item) => {
            const newDataItems = item.data.map((subItem) => {
              return {
                ...subItem,
                editNumPedido: false,
                numPedidoAnterior: null,
                editMycPedido: false,
                mycPedidoAnterior: null,
                errorEstatusNumPedido: false,
                errorEstatusTextoNumPedido: null,
                errorEstatusTextoMycPedido: false,
                errorEstatusMycPedido: null,
                observacionAnterior: null,
                editObservacion: false,
                errorEstatusTextoObervacion: null,
                errorEstatusObervacion: false,
                animationNumPedido: false,
                animationMycPedido: false,
                animationObservacion: false,
                acumRemisionAnterior: null,
                EditAcumRemision: false,
                errorEstatusTextoAcumRemision: null,
                errorEstatusAcumRemision: false,
                animationAcumRemision: false,
              };
            });
            return { ...item, data: newDataItems };
          });

          this.desserts = datos;

          this.loading = false;
        })
        .catch((error) => {
          console.log(error);
          this.loading = false;
        });
    },
    report() {
      if (this.filtros.desde == null || this.filtros.hasta == null) return;

      var url = "/Reportes/ReporteSemanalesPedidosXlsx?";
      url +=
        "IdTrabajoOrden=" +
        this.IdOrdenTrabajo +
        "&fechaInicio=" +
        this.filtros.desde +
        "&fechaFin=" +
        this.filtros.hasta;

      this.$utils.axios.downloadFile(
        url,
        "GET",
        `reporte-mp-semanal.xlsx`,
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
      );
    },
  },
};
</script>