<template>
  <v-row>
    <v-col cols="4">
      <datePicker
        v-model="inicio"
        format="YYYY-MM-DD"
        label="Desde (Obligatorio)"
        clearable
        maxToday
        @input="GetData()"
      ></datePicker>
    </v-col>
    <v-col cols="4">
      <datePicker
        v-model="fin"
        format="YYYY-MM-DD"
        label="Hasta (Obligatorio)"
        clearable
        maxToday
        @input="GetData()"
      ></datePicker>
    </v-col>
    <v-col cols="4">
      <v-autocomplete
        v-model="idArea"
        label="Área (Opcional)"
        :items="areas"
        item-value="idArea"
        item-text="nombre"
        hide-details
        clearable
        @input="GetData()"
        :disable="loadArea"
        :loading="loadArea"
      ></v-autocomplete>
    </v-col>
    <v-col cols="4">
      <v-autocomplete
        v-model="idArticuloTerminado"
        :items="articulosProducir"
        :item-text="nombreArticulo"
        item-value="idArticulo"
        label="Artículo Terminado (Opcional)"
        hide-details
        clearable
        @input="GetData()"
        :disabled="loadArticuloTerminado"
        :loading="loadArticuloTerminado"
      ></v-autocomplete>
    </v-col>
    <v-col cols="4">
      <v-autocomplete
        v-model="idArticuloFase"
        :items="articulosFase"
        :item-text="nombreArticulo"
        item-value="idArticulo"
        label="Artículo Fase (Opcional)"
        hide-details
        clearable
        @input="GetData()"
        :disabled="loadArticuloFase"
        :loading="loadArticuloFase"
      ></v-autocomplete>
    </v-col>
    <v-col cols="4">
      <v-autocomplete
        v-model="idSubpaso"
        :items="subpasos"
        :item-text="nombreSubpaso"
        item-value="idCatSubpaso"
        label="Subpaso (Opcional)"
        hide-details
        clearable
        @input="GetData()"
        :disabled="loadSubpaso"
        :loading="loadSubpaso"
      ></v-autocomplete>
    </v-col>
    <v-col cols="12" class="my-n3">
      <DownloadBtn
        v-if="visble"
        color="blue darken-1"
        text
        
        @click="downloadReport"
        label="Descargar Reporte de subordinados"
        :retryTime="20"
      />
      <v-btn v-else disabled color="blue darken-1" text>
        Descargar Reporte de subordinados
      </v-btn>
    </v-col>
    <v-col cols="12">
      <div v-if="dialog2" style="height:350px">
        <v-row class="fill-height" align-content="center" justify="center">
          <v-col class="text-subtitle-1 text-center" cols="12">
            Cargando...
          </v-col>
          <v-col cols="6">
            <v-progress-linear
              indeterminate
              rounded
              height="6"
            ></v-progress-linear>
          </v-col>
        </v-row>
      </div>
      <div v-else id="chart">
        <apexchart
          type="bar"
          height="350"
          :options="chartOptions"
          :series="series"
        ></apexchart>
      </div>
    </v-col>
  </v-row>
</template>

<script>
import axios from "axios";
import DownloadBtn from "../../DownloadBtn.vue";
import moment from "moment";
export default {
  data: () => ({
    series: [],
    inicio: null,
    fin: null,
    categorias: [],
    visble: false,
    dialog2: false,
    idArticuloTerminado: null,
    articulosProducir: [],
    idArticuloFase: null,
    idArea: null,
    areas: [],
    idTurno: null,
    turnos: [],
    idSubpaso: null,
    subpasos: [],
    loadArea: true,
    loadArticuloTerminado: true,
    loadArticuloFase: true,
    loadSubpaso: true,
  }),
  components: {
    DownloadBtn,
  },
  created() {
    this.callAllMethods();
  },
  computed: {
    chartOptions() {
      return {
        chart: {
          type: "bar",
          height: 350,
        },
        plotOptions: {
          bar: {
            horizontal: false,
            columnWidth: "55%",
            endingShape: "rounded",
          },
        },
        dataLabels: {
          enabled: false,
        },
        stroke: {
          show: true,
          width: 2,
          colors: ["transparent"],
        },
        xaxis: {
          categories: this.categorias,
        },
        yaxis: {
          title: {
            text: "Jefes",
          },
          labels: {
            formatter: function(val, index) {
              return val+ " %";
            }
          }
        },
        fill: {
          opacity: 1,
        },
        tooltip: {
          y: {
            formatter: function(val) {
              return val+ " %";
            },
          },
        },
      };
    },
  },
  methods: {
    nombreSubpaso: (value) =>
      `${value.codigo ? `[${value.codigo}] - ` : ""}${value.descripcion}`,
    nombreArticulo: ({ codigo, nombre }) =>
      `${codigo ? `[${codigo}] - ` : ""}${nombre ?? ""}`,
    GetData() {
      this.dialog2 = true;
      if (this.blockButtom()) {
        let url = `Reportes/ReporteRendimientoJefeSubordinado?`;
        if (this.inicio != null) url += `&Desde=${this.inicio}`;
        if (this.fin != null) url += `&Hasta=${this.fin}`;
        if (this.idArea != null) url += `&IdArea=${this.idArea}`;
        if (this.idArticuloTerminado != null) url += `&IdArticuloTerminado=${this.idArticuloTerminado}`;
        if (this.idArticuloFase != null) url += `&IdArticuloFase=${this.idArticuloFase}`;
        if (this.idSubpaso != null) url += `&IdSubpaso=${this.idSubpaso}`;

        axios
          .get(url)
          .then((response) => {
            console.log(response);
            this.series = response.data.series;
            this.categorias = response.data.xaxis.categories;
            this.dialog2 = false;
            this.visble = true;
          })
          .catch((error) => {
            console.log(error);
            this.visble = false;
            this.dialog2 = false;
          });
      }
    },
    blockButtom() {
      if (this.inicio != null && this.fin != null) return true;
      else {
        this.visble = false;
        this.dialog2 = false;
        return false;
      }
    },
    url(path) {
      var url = `${path}?`;
			if (this.inicio != null) url += `&Desde=${this.inicio}`;
        if (this.fin != null) url += `&Hasta=${this.fin}`;
        if (this.idArea != null) url += `&IdArea=${this.idArea}`;
        if (this.idArticuloTerminado != null) url += `&IdArticuloTerminado=${this.idArticuloTerminado}`;
        if (this.idArticuloFase != null) url += `&IdArticuloFase=${this.idArticuloFase}`;
        if (this.idSubpaso != null) url += `&IdSubpaso=${this.idSubpaso}`;
      return url;
    },
    callAllMethods() {
      this.getAreas();
      this.getTurnos();
      this.getArticulosFase();
      this.getArticulosProducir();
      this.getSubpasos();
    },
    downloadReport() {
      var datetime = moment().format("YYYYMMDDHHmmss");
      this.$utils.axios.downloadFile(
        this.url("Reportes/XlsReporteRendimientoJefes"),
        "GET",
        "reporte-Jefes-" + datetime + ".xlsx",
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
      );
    },
    getTurnos() {
      axios
        .get("/Turnos")
        .then((response) => {
          this.turnos = response.data;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    getAreas() {
      axios
        .get("/Areas")
        .then((response) => {
          this.areas = response.data.filter((area) => area.idArea > 1);
          this.loadArea = false;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    getSubpasos() {
      axios
        .get("/CatSubpaso")
        .then((response) => {
          this.subpasos = response.data;
          this.loadSubpaso = false;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    getArticulosFase() {
      axios
        .get("/Articulos/ArticulosNombres?componentes=true")
        .then((response) => {
          this.articulosFase = response.data;
          this.loadArticuloFase = false;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    getArticulosProducir() {
      axios
        .get("/Articulos/OTS")
        .then((response) => {
          this.articulosProducir = response.data;
          this.loadArticuloTerminado = false;
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },
};
</script>
