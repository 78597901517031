import { VAutocomplete } from 'vuetify/lib/components/VAutocomplete';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VRow } from 'vuetify/lib/components/VGrid';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.permisoVista('articulos','r'))?_c(VCol,{attrs:{"cols":"12"}},[_c(VRow,{staticClass:"pb-4"},[_c(VCol,{attrs:{"cols":"6"}},[_c(VAutocomplete,{attrs:{"items":_vm.articulos,"item-text":_vm.nombreArticulo,"item-value":"idArticulo","label":"Artículo","clearable":"","hide-details":"","multiple":"","chips":"","deletable-chips":""},on:{"change":function($event){return _vm.articulosReorden_update_page_action(1)}},model:{value:(_vm.filtros.idsArticulos),callback:function ($$v) {_vm.$set(_vm.filtros, "idsArticulos", $$v)},expression:"filtros.idsArticulos"}})],1),_c(VCol,{attrs:{"cols":"6"}},[_c(VAutocomplete,{attrs:{"items":_vm.almacenes,"item-text":"nombre","item-value":"idAlmacen","label":"Almacén","clearable":"","hide-details":"","multiple":"","chips":"","deletable-chips":""},on:{"change":function($event){return _vm.articulosReorden_update_page_action(1)}},model:{value:(_vm.filtros.idsAlmacenes),callback:function ($$v) {_vm.$set(_vm.filtros, "idsAlmacenes", $$v)},expression:"filtros.idsAlmacenes"}})],1)],1),_c(VDataTable,{attrs:{"headers":_vm.headersReorden,"items":_vm.articulosReorden,"loading":_vm.loading,"loading-text":"Cargando... Espere, Por Favor.","server-items-length":_vm.articulosReorden_total,"items-per-page":_vm.articulosReorden_items_per_page,"page":_vm.articulosReorden_actual_page,"footer-props":{
                disableItemsPerPage: true,
                disablePagination: this.loading,
            },"disable-sort":true},on:{"update:page":_vm.articulosReorden_update_page_action,"update:items-per-page":function (ipp) { return (_vm.articulosReorden_items_per_page = ipp); }},scopedSlots:_vm._u([{key:"item.total",fn:function(ref){
            var item = ref.item;
return [_vm._v(" "+_vm._s(item.total!=null?item.total.toFixed(4):'N/A')+" ")]}},{key:"item.puntoReorden",fn:function(ref){
            var item = ref.item;
return [_vm._v(" "+_vm._s(item.puntoReorden!=null?item.puntoReorden.toFixed(4):'N/A')+" ")]}}],null,false,3670832766)})],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }