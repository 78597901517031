import { VCol } from 'vuetify/lib/components/VGrid';
import { VDataTable } from 'vuetify/lib/components/VDataTable';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.permisoVista('dashboard','r'))?_c(VCol,{staticClass:"px-8",attrs:{"cols":"12"}},[_c(VDataTable,{attrs:{"items":_vm.operadores,"headers":_vm.operadoresHeaders,"loading":_vm.loading,"items-per-page":-1,"hide-default-footer":""},scopedSlots:_vm._u([{key:"item.articuloProducir",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.articuloProducir!=null?item.articuloProducir:'Sin operaciones en curso')+" ")]}},{key:"item.articuloFase",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.articuloFase!=null?item.articuloFase:'N/A')+" ")]}}],null,false,3973769487)})],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }