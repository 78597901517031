<template>
  <div>
    <v-dialog :value="value" persistent width="90%">
      <v-card>
        <v-card-title>Movimiento entre Almacenes sin QR</v-card-title>
        <v-card-text class="text-center">
          <v-row>
            <v-col cols="4">
              <v-autocomplete
                v-model="item.almacenOrigen"
                label="Almacen Origen"
                :items="almacen"
                :item-text="nombreAlmacen"
                item-value="idAlmacen"
                return-object
                clearable
                @change="getInfoArticulo(), filtrarArticulos()"
                :disabled="loadArticulos"
              ></v-autocomplete>
            </v-col>
            <v-col cols="4">
              <v-autocomplete
                v-model="item.subalmacenOrigen"
                label="SubAlmacen Origen(Opcional)"
                :items="subalmacen"
                item-value="id"
                item-text="value"
                return-object
                clearable
                @change="getInfoArticulo(), filtrarArticulos()"
                :disabled="loadArticulos"
              ></v-autocomplete>
            </v-col>
            <v-col cols="4">
              <v-autocomplete
                v-model="item.articulo"
                :items="articulosFiltrados"
                label="Articulo"
                item-value="IdArticulo"
                :item-text="nombreArticulo"
                return-object
                clearable
                @change="getInfoArticulo()"
                :disabled="loadArticulos"
                :loading="loadArticulos"
              ></v-autocomplete>
            </v-col>
            <v-col cols="4">
              <v-autocomplete
                v-model="item.almacenDestino"
                label="Almacen Destino"
                :items="almacen"
                :item-text="nombreAlmacen"
                item-value="idAlmacen"
                return-object
                clearable
              ></v-autocomplete>
            </v-col>
            <v-col cols="4">
              <v-autocomplete
                v-model="item.subalmacenDestino"
                label="SubAlmacen Destino(Opcional)"
                :items="subalmacenesFiltrados(item)"
                :item-text="nombreSubalmacen"
                item-value="idSubalmacen"
                return-object
                clearable
              ></v-autocomplete>
            </v-col>
            <v-col cols="4">
              <v-text-field
                v-model="item.cantidad"
                :label="itemMaximos.length < 1 ?`Cantidad`:
                  `Máximo: ${itemMaximos.maximo} ${$utils.strings.pluralizar(
                    itemMaximos.abreviatura,
                    itemMaximos.maximo,
                    false
                  )}`
                "
                type="number"
                hide-spin-buttons
                :suffix="
                  $utils.strings.pluralizar(
                    itemMaximos.abreviatura,
                    itemMaximos.cantidad,
                    false
                  )
                "
                :error="
                  parseFloat(item.cantidad) > parseFloat(itemMaximos.maximo)
                "
                :disabled="saving"
                :error-messages="item.errorCantidad"
                @focus="$set(item, 'errorCantidad', '')"
                clearable
              ></v-text-field>
            </v-col>
            <v-col cols="12">
              <v-btn
                style="float: right;"
                color="primary"
                @click="addItemToList()"
                >Agregar</v-btn
              >
            </v-col>
            <v-col cols="12">
              <v-data-table
                dense
                :headers="headers"
                :items="desserts"
                item-key="name"
                hide-default-footer
              >
                <template v-slot:item.subalmacenOrigen="{ item }">
                  <p v-if="item.subalmacenOrigen == null">N/A</p>
                  <p v-else>{{ item.subalmacenOrigen }}</p>
                </template>
                <template v-slot:item.subalmacenDestino="{ item }">
                  <p v-if="item.subalmacenDestino == null">N/A</p>
                  <p v-else>{{ item.subalmacenDestino }}</p>
                </template>
                <template v-slot:item.actions="{ item }">
                  <v-tooltip bottom color="black" class="white--text">
                    <template v-slot:activator="{ on }">
                      <v-icon v-on="on" small @click="deleteItem(item)">
                        delete
                      </v-icon>
                    </template>
                    <span class="white--text">Eliminar</span>
                  </v-tooltip>
                </template>
              </v-data-table>
              <ul v-if="error.length">
                <li v-for="(item, x) in error" :key="x">{{ item }}</li>
              </ul>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="success" text @click="cerrarDialog()" :disabled="saving"
            >Cerrar</v-btn
          >
          <v-btn
            color="error"
            text
            @click="save()"
            :disabled="saving"
            :loading="saving"
            >Guardar</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import axios from "axios";
export default {
  props: {
    value: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      error: [],
      itemMaximos: [],
      idAlmacen: null,
      dialogSavedSinQR: false,
      almacen: [],
      subalmacen: [],
      articulos: [],
      headers: [
        {
          text: "Almacen Origen",
          align: "center",
          sortable: false,
          value: "almacenOrigen",
        },
        {
          text: "Subalmacén Origen",
          align: "center",
          sortable: false,
          value: "subalmacenOrigen",
        },
        {
          text: "Almacen destino",
          align: "center",
          sortable: false,
          value: "almacenDestino",
        },
        {
          text: "Subalmacén destino",
          align: "center",
          sortable: false,
          value: "subalmacenDestino",
        },
        {
          text: "Articulo",
          align: "center",
          sortable: false,
          value: "articulo",
        },
        {
          text: "Cantidad",
          align: "center",
          sortable: false,
          value: "cantidad",
        },
        {
          text: "Acciones",
          align: "center",
          sortable: false,
          value: "actions",
        },
      ],
      desserts: [],
      item: [],
      articulosFiltrados:[],
      loadArticulos: false,
      saving: false
    };
  },
  mounted() {
    this.initialize();
  },
  methods: {
    initialize() {
      this.getSubalmacenes();
      this.getAlmacenes();
      this.getArticulos();
    },
    nombreAlmacen: ({ codigo, nombre }) => `[${codigo}] - ${nombre}`.trim(),
    nombreArticulo: ({ codigo, nombre }) => `[${codigo}] - ${nombre}`.trim(),
    nombreSubalmacen: ({ clave, subalmacen }) =>
      `[${clave}] - ${subalmacen}`.trim(),
    cerrarDialog() {
      this.item = [];
      this.itemMaximos = [];
      this.desserts = [];
      this.articulosFiltrados = [];
      this.$emit("input", false);
    },
    validate() {
      
      try {
        let hayErrores = false;

      this.$set(this.item, "errorAlmacen", "");
      this.$set(this.item, "errorCantidad", "");

      if (isNaN(parseFloat(this.item.cantidad)) || parseFloat(this.item.cantidad) <= 0) {
        this.$set(this.item.cantidad, "errorCantidad", "Cantidad invalida");
        hayErrores = true;
      }
      
      if (parseFloat(this.item.cantidad) > this.itemMaximos.maximo) {
        this.$set(this.item.cantidad,"errorCantidad",`Máximo: ${this.itemMaximos.maximo}${this.$utils.strings.pluralizar(this.itemMaximos.abreviatura,this.itemMaximos.maximo,false)}`);
        hayErrores = true;
      }
      
      return !hayErrores;
      } catch (error) {
        console.log(error)
      }

      
    },
    save() {
      this.$emit("input", false);
    },
    validationItemList() {
      this.error = [];

      if(this.item.articulo == undefined)
        this.error.push("Seleccione un artículo");

      if(this.item.almacenOrigen == undefined)
        this.error.push("Seleccione un almacén origen");

      if(this.item.almacenDestino == undefined)
        this.error.push("Seleccione un almacén destino");

      if( this.item.almacenOrigen != undefined && this.item.almacenDestino != undefined ) {
        if (this.item.almacenOrigen.idAlmacen == this.item.almacenDestino.idAlmacen)
          this.error.push("El almacén origen no puede ser el mismo que el almacén destino");
      }

      if(this.item.subalmacenOrigen != undefined && this.item.subalmacenDestino == undefined)
        this.error.push("Selecciona un subalmacén destino");

      if (this.item.subalmacenOrigen == undefined && this.item.subalmacenDestino != undefined)
        this.error.push("Selecciona un subalmacén origen");

      if (this.item.subalmacenOrigen != undefined && this.item.subalmacenDestino != undefined) {
        if (this.item.subalmacenOrigen.idSubalmacen == this.item.subalmacenDestino.idSubalmacen)
          this.error.push( "El subalmacén origen no puede ser el mismo que el subalmacén destino");
      }

      if (parseFloat(this.item.cantidad) == NaN)
        this.error.push("Ingrese una cantidad valida");

      if (parseFloat(this.item.cantidad) < 1)
        this.error.push("Ingrese una cantidad mayor a 0");

      if (isNaN(this.item.cantidad)) {
        this.error.push("Ingrese una cantidad valida");
      }

      if(this.itemMaximos.length < 1)
        this.error.push("Aún no seleccionas un artículo");
      else{
        if( parseFloat(this.item.cantidad) > parseFloat(this.itemMaximos.maximo))
          this.error.push("La cantidad ingresada es superior a la cantidad máxima de "+this.itemMaximos.maximo); 
      }

      

      var item = this.desserts.filter(
        (a) =>
          a.idAlmacenOrigen == this.item.almacenOrigen.idAlmacen &&
          a.idSubalmacenOrigen ==
            (this.item.subalmacenOrigen == undefined
              ? null
              : this.item.subalmacenOrigen.idSubalmacen) &&
          a.idAlmacenDestino == this.item.almacenDestino.idAlmacen &&
          a.idSubalmacenDestino ==
            (this.item.subalmacenDestino == undefined
              ? null
              : this.item.subalmacenDestino.idSubalmacen) &&
          a.idArticulo == this.item.articulo.idArticulo
      );

      if (item.length > 0) {
        this.error.push("Ya existe un registro con esos datos");
      }

      return this.error.length > 0 ? true : false;
    },
    addItemToList() {
      if (this.validationItemList()) return;
      
      var objeto = {
        idAlmacenOrigen: this.item.almacenOrigen.idAlmacen,
        almacenOrigen: `[${this.item.almacenOrigen.codigo}] - ${this.item.almacenOrigen.nombre}`.trim(),
        idSubalmacenOrigen:
          this.item.subalmacenOrigen == undefined
            ? null
            : this.item.subalmacenOrigen.idSubalmacen,
        subalmacenOrigen:
          this.item.subalmacenOrigen == undefined
            ? null
            : `[${this.item.subalmacenOrigen.clave}] - ${this.item.subalmacenOrigen.subalmacen}`.trim(),
        idAlmacenDestino: this.item.almacenDestino.idAlmacen,
        almacenDestino: `[${this.item.almacenDestino.codigo}] - ${this.item.almacenDestino.nombre}`.trim(),
        idSubalmacenDestino:
          this.item.subalmacenDestino == undefined
            ? null
            : this.item.subalmacenDestino.idSubalmacen,
        subalmacenDestino:
          this.item.subalmacenDestino == undefined
            ? null
            : `[${this.item.subalmacenDestino.clave}] - ${this.item.subalmacenDestino.subalmacen}`.trim(),
        idArticulo: this.item.articulo.idArticulo,
        articulo: "["+this.item.articulo.codigo+"] - "+this.item.articulo.nombre,
        cantidad: parseFloat(this.item.cantidad),
      };

      this.error = [];
      this.desserts.push(objeto);
      this.item = [];
      this.itemMaximos = [];
    },
    deleteItem(item) {
      var index = this.desserts.indexOf(item);
      this.desserts.splice(index, 1);
    },
    save() {
      if (this.desserts.length < 1) return;
      
      const movimientos = this.desserts.map((item) => ({
        idAlmacenOrigen: item.idAlmacenOrigen,
        idSubalmacenOrigen: item.idSubalmacenOrigen,
        idAlmacenDestino: item.idAlmacenDestino,
        idSubalmacenDestino: item.idSubalmacenDestino,
        idArticulo: item.idArticulo,
        cantidad: parseFloat(item.cantidad),
      }));
      this.saving = true;
      axios
        .post("/Inventario/CrearMovimientoEntreAlmacenesSinQR", movimientos)
        .then((response) => {
          this.desserts = [];
          this.value = false;
          this.saving = false;
          this.$emit("input", false);
          this.$emit("initialize");
        })
        .catch((error) => {
          this.saving = false;
          console.log(error);
        });
    },
    getAlmacenes() {
      //this.loadingAlmacenes = true;

      axios
        .get("/Almacenes")
        .then((response) => {
          this.almacen = response.data;
        })
        .catch(console.log)
        .finally(() => {
          //this.loadingAlmacenes = false;
        });
    },
    getSubalmacenes() {
      axios
        .get("/Subalmacenes")
        .then((response) => {
          this.subalmacenes = response.data;
        })
        .catch(console.log)
        .finally(() => {
          //this.loadingSubalmacenes = false;
        });
    },
    articulos_fetch_url() {
      var url = `/Articulos?paginar=false&page=${1}&size=${20}&estados=1`;

      if (this.idAlmacen != null) url += `&almacenes=${this.idAlmacen || 0}`;

      return url;
    },
    getArticulos() {
      this.loadArticulos = true;
      axios
        .get("Articulos/ArticulosSinQr")
        .then((response) => {
          this.articulos = response.data;
          this.loadArticulos = false;
        })
        .catch(console.log)
        .finally(() => {
          this.loadArticulos = false;
        });
    },
    filtrarArticulos(){
      if((this.item.almacenOrigen == undefined || this.item.almacenOrigen == null) && (this.item.subalmacenOrigen == undefined || this.item.subalmacenOrigen == null)){
          this.articulosFiltrados = [];
          return;
          }

      if(this.item.almacenOrigen != undefined || this.item.almacenOrigen != null){
          this.articulosFiltrados =  this.articulos.filter(a=> a.idAlmacen == this.item.almacenOrigen.idAlmacen);
          return;
         }
         
      
      if ((this.item.almacenOrigen != undefined || this.item.almacenOrigen != null) && (this.item.subalmacenOrigen != undefined || this.item.subalmacenOrigen != null))
        {
          this.articulosFiltrados = this.articulos.filter(a=> a.idAlmacen == this.item.almacenOrigen.idAlmacen && a.IdSubalmacen == this.item.subalmacenOrigen.IdSubalmacen)
          return;
        }
      
    },
    subalmacenesFiltrados({ idAlmacenDestino }) {
      if (idAlmacenDestino == null) return [];

      return this.subalmacenes.filter(
        (subalm) => subalm.idAlmacen == idAlmacenDestino
      );
    },
    getInfoArticulo() {
      
      if(this.item.articulo == undefined || this.item.articulo == null){ 
        this.item.cantidad = []; 
        this.itemMaximos = [];
        }
      
      if(this.item.almacenOrigen == null){
        this.item.articulo = null;
        this.itemMaximos = [];
        this.item.subalmacenOrigen = null;
        this.item.cantidad = [];
        }

      if(this.item.subalmacenOrigen == null && this.item.almacenOrigen == null  ){
        this.item.articulo = null;
        this.itemMaximos = [];
        this.item.cantidad = [];
        }

      if (this.item.articulo == undefined) return;
      if (this.item.almacenOrigen == undefined) return;
      if (this.item.articulo == null) return;
      if (this.item.almacenOrigen == null) return;

      var url = `/Inventario/InformacionBasicaSinQR?IdArticulo=${this.item.articulo.idArticulo}&IdAlmacen=${this.item.almacenOrigen.idAlmacen}`;
      if (this.item.subalmacenOrigen != undefined || this.item.subalmacenOrigen != null)
        url += `&IdSubalmacen=${this.item.subalmacenOrigen.idSubalmacen}`;
      
      axios
        .get(url)
        .then((response) => {
          this.itemMaximos = response.data;
        })
        .catch(console.log)
        .finally(() => {
          
        });
    },
  },
};
</script>
