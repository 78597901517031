<template>
  <v-menu
    ref="menu"
    v-model="menu2"
    :close-on-content-click="false"
    :nudge-right="40"
    :return-value.sync="value"
    transition="scale-transition"
    offset-y
    max-width="290px"
    min-width="290px"
  >
    <template v-slot:activator="{ on }">
      <v-text-field
        v-model="value"
        :label="label"
        prepend-icon="access_time"
        readonly
        v-on="on"
      ></v-text-field>
    </template>
    <v-time-picker
      v-if="menu2"
      :min="min"
      :max="max"
      v-model="value"
      full-width
      ampm-in-title
      @input="updateDate"
    >
      <v-spacer></v-spacer>
      <v-btn text color="blue" @click="menu2 = false">Cancelar</v-btn>
      <v-btn text color="red" @click="$refs.menu.save(value)">Aceptar</v-btn>
    </v-time-picker>
  </v-menu>
</template>

<script>
  export default {
    data: () => ({
      menu2: false,
    }),
    methods:{
      updateDate() {
        this.$emit('input',this.value);
      }
    },
    props:{
      value:{
        type: Object,
        default: null
      },
      label:{
        type: String,
        default: "",
      },
      min:{
        type: String,
        default: "",
      },
      max:{
        type: String,
        default: "",
      }
    }
  }
</script>