<template>
	<v-container>
		<v-dialog v-model="dialogComprobaciones" persistent max-width="85%">
			<v-card>
				<v-card-title>Comprobaciones</v-card-title>
				<v-card-text>
					<p class="text-subtitle-1 text--secondary">
						Máquina: {{ getNombreMaquina() }}
					</p>
					<v-data-table
						:items="itemComprobaciones.comprobaciones"
						:headers="headersComprobaciones"
						loading-text="Cargando... Espere, Por Favor."
						:loading="loadingComprobaciones"
						:footer-props="{
							disableItemsPerPage: true,
							disablePagination: this.loadingComprobaciones,
						}"
					>
						<template v-slot:item.satisfactoria="{ item }">
							<p
								class="mb-0 font-weight-bold"
								:class="
									item.satisfactoria
										? 'success--text'
										: 'error--text'
								"
								style="font-size: 14px !important"
							>
								{{ item.satisfactoria ? "SI" : "NO" }}
							</p>
						</template>
						<template v-slot:item.comentario="{ item }">
							<p class="mb-0">
								{{
									item.comentario == null
										? "N/A"
										: item.comentario
								}}
							</p>
						</template>
						<template v-slot:item.documentos="{ item }">
							<p
								v-if="
									item.documentos == null ||
									item.documentos.length == 0
								"
								class="mb-0"
							>
								N/A
							</p>
							<div class="text-center">
								<template
									v-for="(documento, idx) in item.documentos"
								>
									<div
										class="d-inline-block mx-2 py-2"
										:key="'links-' + idx"
										style="font-size: 14px"
									>
										<a
											:href="`/api/Documentos/Descargar/${documento.nombre}?access_token=${token}`"
											class="text-decoration-none"
											target="_blank"
											>{{ documento.nombre }}</a
										>
									</div>
								</template>
							</div>
						</template>
						<template v-slot:item.resuelta="{ item }">
							<p
								class="mb-0 font-weight-bold"
								:class="
									item.resuelta
										? 'success--text'
										: 'error--text'
								"
								style="font-size: 14px !important"
							>
								{{ item.resuelta ? "SI" : "NO" }}
							</p>
						</template>
						<template v-slot:item.reparacion="{ item }">
							<p v-if="item.reparacion == null" class="mb-0">
								N/A
							</p>
							<template v-else>
								<v-col cols="12" class="d-flex pa-0">
									<v-col cols="6" class="py-0 px-1">
										<v-text-field
											:value="
												item.reparacion.tipo == null
													? 'N/A'
													: item.reparacion.tipo
											"
											readonly
											hide-details
										>
											<template v-slot:label>
												<p class="primary--text">
													Tipo
												</p>
											</template>
										</v-text-field>
									</v-col>
									<v-col cols="6" class="pa-0">
										<v-text-field
											:value="
												getStatusReparacion(
													item.reparacion
												)
											"
											readonly
											hide-details
										>
											<template v-slot:label>
												<p class="primary--text">
													Estado
												</p>
											</template>
										</v-text-field>
									</v-col>
								</v-col>
								<v-col cols="12" class="py-0 px-2">
										<v-text-field
											:value="`[${item.reparacion.codigo}] ${item.reparacion.nombre}`"
											readonly
											hide-details
										>
											<template v-slot:label>
												<p class="primary--text">
													Código
												</p>
											</template>
										</v-text-field>
									</v-col>
								<v-col cols="12" class="d-flex pa-0">
									<v-col cols="6" class="py-0 px-1">
										<v-text-field
											:value="
												item.reparacion
													.fechaInicioProgramada ==
												null
													? 'N/A'
													: item.reparacion
															.fechaInicioProgramada
											"
											readonly
											hide-details
										>
											<template v-slot:label>
												<p class="primary--text">
													Inicio Programado
												</p>
											</template>
										</v-text-field>
									</v-col>
									<v-col cols="6" class="py-0 px-1">
										<v-text-field
											:value="
												item.reparacion
													.fechaFinProgramada == null
													? 'N/A'
													: item.reparacion
															.fechaFinProgramada
											"
											readonly
											hide-details
										>
											<template v-slot:label>
												<p class="primary--text">
													Fin Programado
												</p>
											</template>
										</v-text-field>
									</v-col>
								</v-col>
								<v-col cols="12" class="d-flex pa-0">
									<v-col cols="6" class="py-0 px-1">
										<v-text-field
											:value="
												item.reparacion
													.fechaInicioRealizo == null
													? 'N/A'
													: item.reparacion
															.fechaInicioRealizo
											"
											readonly
											hide-details
										>
											<template v-slot:label>
												<p class="primary--text">
													Inicio Realizo
												</p>
											</template>
										</v-text-field>
									</v-col>
									<v-col cols="6" class="py-0 px-1">
										<v-text-field
											:value="
												item.reparacion
													.fechaFinRealizo == null
													? 'N/A'
													: item.reparacion
															.fechaFinRealizo
											"
											readonly
											hide-details
										>
											<template v-slot:label>
												<p class="primary--text">
													Fin Realizo
												</p>
											</template>
										</v-text-field>
									</v-col>
								</v-col>
							</template>
						</template>
					</v-data-table>
				</v-card-text>
				<v-card-actions>
					<v-spacer></v-spacer>
					<v-btn
						color="green"
						text
						small
						@click="cerrarDialogComprobaciones"
						>Cerrar</v-btn
					>
				</v-card-actions>
			</v-card>
		</v-dialog>
		<v-dialog v-model="dialogInspeccion" persistent max-width="70%">
			<v-card>
				<v-card-title>Inspecciones</v-card-title>
				<v-card-text>
					<v-data-table
						:items="itemInspeccion"
						:headers="headersInspeccion"
						loading-text="Cargando... Espere, Por Favor."
						:loading="loadingInspeccion"
						:footer-props="{
							disableItemsPerPage: true,
							disablePagination: this.loadingInspeccion,
						}"
					>
						<template v-slot:item.completo="{ item }">
							<p
								class="mb-0 font-weight-bold"
								:class="
									item.completo
										? 'success--text'
										: 'error--text'
								"
								style="font-size: 14px !important"
							>
								{{ item.completo ? "SI" : "NO" }}
							</p>
						</template>
						<template v-slot:item.fechaInicio="{ item }">
							{{
								item.fechaInicio == null
									? "N/A"
									: item.fechaInicio
							}}
						</template>
						<template v-slot:item.fechaFin="{ item }">
							{{ item.fechaFin == null ? "N/A" : item.fechaFin }}
						</template>
						<template v-slot:item.maquina="{ item }">
							{{ `${item.marca} - ${item.modelo}` }}
						</template>
						<template v-slot:item.actions="{ item }">
							<v-tooltip
								
								bottom
								color="black"
								class="white--text"
							>
								<template v-slot:activator="{ on }">
									<v-icon
										:class="
											item.comprobaciones.length == 0
											? 'text--disabled'
											: ''
										"
										v-on="on"
										small
										@click="
											item.comprobaciones.length != 0
											&& verComprobaciones(item)
										"
										:style="
											item.comprobaciones.length == 0
											&& 'cursor: default'
										"
									>
										mdi-order-bool-ascending-variant
									</v-icon>
								</template>
								<span class="white--text"
									>Ver Comprobaciones</span
								>
							</v-tooltip>
						</template>
					</v-data-table>
				</v-card-text>
				<v-card-actions>
					<v-spacer></v-spacer>
					<v-btn
						color="green"
						text
						small
						@click="cerrarDialogInspeccion"
						>Cerrar</v-btn
					>
				</v-card-actions>
			</v-card>
		</v-dialog>
		<v-row class="pt-2">
			<v-col cols="4">
				<VueCtkDateTimePicker
					v-model="filtros.inicio"
					label="Desde"
					format="YYYY-MM-DD HH:mm"
					formatted="YYYY-MM-DD HH:mm"
					noHeader
					overlay
					buttonNowTranslation="Ahora"
					@input="mantenimientos_update_page_action()"
				/>
			</v-col>
			<v-col cols="4">
				<VueCtkDateTimePicker
					v-model="filtros.fin"
					:min-date="filtros.inicio"
					label="Hasta"
					format="YYYY-MM-DD HH:mm"
					formatted="YYYY-MM-DD HH:mm"
					noHeader
					overlay
					buttonNowTranslation="Ahora"
					@input="mantenimientos_update_page_action()"
				/>
			</v-col>
			<v-col cols="4">
				<v-select
					v-model="filtros.completo"
					:items="itemsCompleto"
					item-text="nombre"
					item-value="value"
					label="Completos"
					class="pt-1"
					hide-details
					clearable
					@change="mantenimientos_update_page_action()"
				></v-select>
			</v-col>
			<v-col cols="4">
				<v-autocomplete
					v-model="filtros.idsInspeccion"
					:items="inspecciones"
					:item-text="nombreInspeccion"
					item-value="idMantenimientoInspeccion"
					label="Inspección"
					class="mt-0 pt-0 mb-3"
					clearable
					hide-details
					multiple
					chips
					deletable-chips
					@change="mantenimientos_update_page_action()"
				></v-autocomplete>
			</v-col>
		</v-row>
		<v-data-table
			:items="ciclos"
			:headers="headersCiclos"
			loading-text="Cargando... Espere, Por Favor."
			:loading="loading"
			:footer-props="{
				disableItemsPerPage: true,
				disablePagination: this.loading,
			}"
		>
			<template v-slot:item.porcentaje="{ item }">
				<v-progress-linear
					v-model="item.porcentaje"
					:color="item.porcentaje == 100 ? 'green' : 'blue'"
					height="25"
					rounded
				>
					<strong
						:class="
							item.porcentaje == 100
								? 'white--text'
								: 'black--text'
						"
						>{{ Math.ceil(item.porcentaje) }}%</strong
					>
				</v-progress-linear>
			</template>
			<template v-slot:item.actions="{ item }">
				<v-tooltip
					
					bottom
					color="black"
					class="white--text"
				>
					<template v-slot:activator="{ on }">
						<v-icon v-on="on" small @click="verInspecciones(item)">
							mdi-eye
						</v-icon>
					</template>
					<span class="white--text">Ver Inspecciones</span>
				</v-tooltip>
			</template>
		</v-data-table>
	</v-container>
</template>

<script>
import axios from "axios";
import { mapState } from "vuex";
export default {
	data() {
		return {
			inspecciones: [],
			loading: false,
			filtros: {
				completo: null,
				idsInspeccion: [],
				inicio: null,
				fin: null,
			},
			itemsCompleto: [
				{
					value: true,
					nombre: "SI"
				},
				{
					value: false,
					nombre: "NO"
				}
			],
			headersComprobaciones: [
				{
					text: "Comprobación",
					align: "center",
					sort: false,
					value: "comprobacion",
				},
				{
					text: "Fecha",
					align: "center",
					sort: false,
					value: "fecha",
				},
				{
					text: "Satisfactoria",
					align: "center",
					sort: false,
					value: "satisfactoria",
				},
				{
					text: "Comentario",
					align: "center",
					sort: false,
					value: "comentario",
				},
				{
					text: "Documentos",
					align: "center",
					sort: false,
					value: "documentos",
				},
				{
					text: "Resuelta",
					align: "center",
					sort: false,
					value: "resuelta",
				},
				{
					text: "Reparación",
					align: "center",
					sort: false,
					value: "reparacion",
				},
			],
			loadingComprobaciones: false,
			headersInspeccion: [
				{
					text: "Completo",
					align: "center",
					sort: false,
					value: "completo",
				},
				{
					text: "Responsable",
					align: "center",
					sort: false,
					value: "responsable",
				},
				{
					text: "Máquina",
					align: "center",
					sort: false,
					value: "maquina",
				},
				{
					text: "Instancia",
					align: "center",
					sort: false,
					value: "maquinaInstancia",
				},
				{
					text: "Inicio",
					align: "center",
					sort: false,
					value: "fechaInicio",
				},
				{
					text: "Fin",
					align: "center",
					sort: false,
					value: "fechaInicio",
				},
				{
					text: "Acciones",
					align: "center",
					sort: false,
					value: "actions",
				},
			],
			itemComprobaciones: {
				maquinaInstancia: "",
				marca: "",
				modelo: "",
			},
			defaultItemComprobaciones: {
				maquinaInstancia: "",
				marca: "",
				modelo: "",
			},
			loadingInspeccion: false,
			dialogComprobaciones: false,
			dialogInspeccion: false,
			itemInspeccion: [],
			loading: false,
			ciclos: [],
			headersCiclos: [
				{
					text: "%",
					align: "center",
					sort: false,
					value: "porcentaje",
				},
				{
					text: "# Ciclo",
					align: "center",
					sort: false,
					value: "numero",
				},
				{
					text: "Nombre",
					align: "center",
					sort: false,
					value: "nombre",
				},
				{
					text: "Código",
					align: "center",
					sort: false,
					value: "codigo",
				},
				{
					text: "Inicio",
					align: "center",
					sort: false,
					value: "fechaInicio",
				},
				{
					text: "Acciones",
					align: "center",
					sort: false,
					value: "actions",
				},
			],
		};
	},
	computed: {
		...mapState(["token"]),
	},
	mounted() {
		this.initialize();
	},
	methods: {
		nombreInspeccion: ins => `[${ins.codigo}] ${ins.nombre}`,
		getStatusReparacion(reparacion) {
			if (
				reparacion.fechaInicioRealizo == null &&
				reparacion.fechaFinRealizo == null
			) {
				return "Programada";
			} else if (
				reparacion.fechaInicioRealizo != null &&
				reparacion.fechaFinRealizo == null
			) {
				return "En Progreso";
			} else if (
				reparacion.fechaInicioRealizo != null &&
				reparacion.fechaFinRealizo != null
			) {
				return "Realizada";
			}
		},
		getNombreMaquina() {
			return `[${
				this.itemComprobaciones.maquinaInstancia
			}] - ${this.itemComprobaciones.marca.trim()} ${this.itemComprobaciones.modelo.trim()}`;
		},
		verComprobaciones(item) {
			this.itemComprobaciones = Object.assign({}, item);
			this.dialogComprobaciones = true;
		},
		initialize() {
			this.mantenimientos_update_page_action();
			this.getInspecciones();
		},
		getInspecciones(){
			axios
				.get("/Mantenimientos/GetMantenimientoInspeccion")
				.then( response => {
					this.inspecciones = response.data;
				})
				.catch( error => {
					console.log( error );
				})
		},
		async mantenimientos_update_page_action() {
			this.loading = true;
			try {
				let response = await this.getCiclos();
				this.ciclos = response;
				this.loading = false;
			} catch (exception) {
				console.log(exception);
			}
		},
		getCiclos() {
			return new Promise((resolve,reject) => {
				let idsInspecciones = "";
				if(this.filtros.idsInspeccion.length > 0){
					this.filtros.idsInspeccion.forEach((ins)=>{
						idsInspecciones += `&IdsInspeccion=${ins}`;
					});
				}
				axios
					.get(`/Mantenimientos/EstadoInspeccionPorcentajes?${idsInspecciones}`,{
						params: {
							Completo: this.filtros.completo,
							Inicio: this.filtros.inicio,
							Fin: this.filtros.fin
						}
					})
					.then((response) => {
						let cycles = [];
						response.data.forEach((ciclo) => {
							cycles.push(
								...ciclo.ciclos.map((cycle) => ({
									idMantenimentoInspeccion:
										ciclo.idMantenimentoInspeccion,
									nombre: ciclo.nombre,
									codigo: ciclo.codigo,
									...cycle,
								}))
							);
						});
						resolve(cycles);
					})
					.catch((error) => {
						reject(error);
					});
			});
		},
		cerrarDialogComprobaciones() {
			this.itemComprobaciones = Object.assign(
				{},
				this.defaultItemComprobaciones
			);
			this.dialogComprobaciones = false;
		},
		cerrarDialogInspeccion() {
			this.itemInspeccion = [];
			this.dialogInspeccion = false;
		},
		verInspecciones(item) {
			this.itemInspeccion = item.inspecciones;
			this.dialogInspeccion = true;
		},
	},
};
</script>