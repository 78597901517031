<template>
  <v-container>
    <v-row>
      <v-col cols="4">
        <VueCtkDateTimePicker
					id="fechaInicio"
					v-model="inicio"
					label="Desde (Obligatorio)"
					:max-date="fin"
					format="YYYY-MM-DD HH:mm"
					formatted="YYYY-MM-DD HH:mm"
					noHeader
					overlay
					buttonNowTranslation="Ahora"
          @input="obtenerDatos()"
				/>
      </v-col>
      <v-col cols="4">
        <VueCtkDateTimePicker
					id="fechaFin"
					v-model="fin"
					label="Hasta"
					:min-date="desde"
					format="YYYY-MM-DD HH:mm"
					formatted="YYYY-MM-DD HH:mm"
					noHeader
					overlay
					buttonNowTranslation="Ahora"
          @input="obtenerDatos()"
				/>
      </v-col>
      <v-col cols="4">
        <v-autocomplete
          v-model="idArea"
          label="Area"
          :items="areas"
          item-value="idArea"
          item-text="nombre"
          hide-details
          clearable
          @change="obtenerDatos()"
        >
        </v-autocomplete>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="3">
        <v-autocomplete
          v-model="idTrabajoOrden"
          label="Orden de Trabajo"
          :items="ordenesTrabajo"
          item-value="idTrabajoOrden"
          item-text="nombre"
          hide-details
          clearable
          @change="obtenerDatos()"
        >
        </v-autocomplete>
      </v-col>
      <v-col cols="3">
        <v-autocomplete
          v-model="idEstado"
          label="Estado"
          :items="estados"
          clearable
          hide-details
          @change="obtenerDatos()"
        >
        </v-autocomplete>
      </v-col>
      <v-col cols="3">
        <v-autocomplete
          v-model="idMaquinaIns"
          label="Máquina Instancia"
          :items="maquinasIns"
          item-value="idMaquinaIns"
					:item-text="nombreMaquina"
          clearable
          hide-details
          @change="obtenerDatos()"
        >
        </v-autocomplete>
      </v-col>
      <v-col cols="3">
        <v-autocomplete
          v-model="idArticulo"
          label="Artículo"
          :items="articulos"
          item-value="idArticulo"
          :item-text="nombreArticulo"
          clearable
          hide-details
          @change="obtenerDatos()"
        >
        </v-autocomplete>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        <v-data-table
          :headers="headers"
          :items="operaciones"
          :loading="loading"
        >
          <template v-slot:item="props">
            <tr>
              <td class="text-center">
                {{ props.item.clave }}
              </td>
              <td class="text-center">
                {{ props.item.articuloProducir }}
              </td>
              <td class="text-center">
                {{ props.item.articulo }}
              </td>
              <td class="text-center">
                {{ props.item.operadoresTeoricos }}
              </td>
              <td class="text-center">
                {{ props.item.operadoresReales }}
              </td>
              <td :class="props.item.status.toLowerCase() != 'ok' ? 'text-center red--text red lighten-4': 'text-center green--text green lighten-4'">
                {{ props.item.status }}
              </td>
            </tr>
          </template>
        </v-data-table>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import axios from "axios"

export default {
  data: () => ({
    inicio: null,
    fin: null,
    cargando: false,
    areas: [],
    loadingAreas: false,
    idArea: -1,
    maquinasIns:[],
    loadingMaquinasIns: false,
    idMaquinaIns: -1,
    ordenesTrabajo: [],
    loadingODTS: false,
    idTrabajoOrden: -1,
    articulos: [],
    loadingArticulos: false,
    idArticulo: -1,
    estados: [
      {
        value: 0,
        text: "En Progreso"
      },
      {
        value: 1,
        text: "Finalizadas"
      }
    ],
    idEstado: -1,
    operaciones: [],
    headers:[
      {
        text: "OT",
        align: "center",
        sortable: true,
        value: "clave"
      },
      {
        text: "Artículo a Producir",
        align: "center",
        sortable: true,
        value: "articuloProducir"
      },
      {
        text: "Artículo Fase",
        align: "center",
        sortable: true,
        value: "articulo"
      },
      {
        text: "# Operadores Teoricos",
        align: "center",
        sortable: true,
        value: "operadoresTeoricos"
      },
      {
        text: "# Operadores Reales",
        align: "center",
        sortable: true,
        value: "operadoresReales"
      },
      {
        text: "Status",
        align: "center",
        sortable: true,
        value: "status"
      },
    ]
  }),
  mounted(){
    this.getAreas();
    this.getMaquinaIns();
    this.getOrdenesTrabajo();
    this.getArticulos();
  },
  methods:{
    nombreMaquina: (maquina) => `[${maquina.nombre}] - ${maquina.marca} ${maquina.modelo}`,
    nombreArticulo: ({ codigo, nombre }) => codigo != null ? `[${codigo}] - ${nombre}` : `${nombre}`,
    getAreas(){
      this.loadingAreas = true;
      axios
      .get("/Areas")
      .then(response => {
        this.areas = response.data.filter(a => a.idArea > 1);
      })
      .catch(error => {
        this.areas = [];
        console.log(error);
      })
      .finally(() => {
        this.loadingAreas = false;
      })
    },
    getMaquinaIns(){
      this.loadingAreas = true;
      axios
      .get("/MaquinaInstancias")
      .then(response => {
        this.maquinasIns = response.data;
      })
      .catch(error => {
        this.maquinasIns = [];
        console.log(error);
      })
      .finally(() => {
        this.loadingMaquinasIns = false;
      })
    },
    getOrdenesTrabajo() {
      this.loadingODTS = true;

      axios
        .get('/TrabajoOrdenes/ListarOT', {
          params: {
            otsCerradas: true
          }
        })
        .then(response => {
          this.ordenesTrabajo = response.data;
        })
        .catch(error => {
          console.log(error);
          this.loadingODTS = [];
        })
        .finally(() => {
          this.loadingODTS = false;
        })
    },
    getArticulos(){
      this.loadingArticulos = false;
      axios
      .get('/Articulos/ArticulosNombres', {
        params: {
          componentes: true,
          activo: true
        }
      })
      .then(response => {
        this.articulos = response.data;
      })
      .catch(console.log)
      .finally(() => {
        this.loadingArticulos = false;
      })
    },
    obtenerDatos(){
      if(this.inicio == null || this.inicio.length == 0) return;

      this.loading = true;
      axios
      .get(`/Reportes/ReporteDistribucionOperadores`,{
				params: {
					desde: this.inicio,
          hasta: (this.hasta === undefined) ? null : this.hasta,
          idArea: (this.idArea == -1) ? null : this.idArea,
          idTrabajoOrden: (this.idTrabajoOrden == -1) ? null : this.idTrabajoOrden,
          estado: (this.idEstado == -1) ? null : this.idEstado,
          idArticulo: (this.idArticulo == -1) ? null : this.idArticulo,
          idMaquinaIns: (this.idMaquinaIns == -1) ? null : this.idMaquinaIns,
				}
			})
      .then(response => {
        this.operaciones = response.data;
      })
      .catch(error => {
        console.log(error)
      })
      .finally(() => {
        this.loading = false;
      })
      this.loading = false;
    }
  }
}
</script>