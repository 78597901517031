<template>
    <v-dialog v-model="dialog" max-width="700">
        <v-card>
            <v-card-title class="text-center">Mostrar / Ocultar Columnas</v-card-title>
            <v-card-text>
                <v-simple-table height="300px">
                    <template v-slot:default>
                        <thead>
                            <tr>
                                <th class="text-center" style="font-weight: bold; font-size: 15px;">
                                    Columnas
                                </th>
                                <th class="text-center" style="font-weight: bold; font-size: 15px;">
                                    Estado
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="header in headers" :key="header.text" class="text-center">
                                <template v-if="header.text !== 'Acciones'">
                                    <td>{{ header.text }}</td>
                                    <td>
                                        <v-icon v-if="!hiddenHeaders.includes(header)"
                                            @click="visible_off(header)">mdi-eye-outline</v-icon>
                                        <v-icon v-if="hiddenHeaders.includes(header)"
                                            @click="visible_on(header)">mdi-eye-off-outline</v-icon>
                                    </td>
                                </template>
                            </tr>
                        </tbody>
                    </template>
                </v-simple-table>
            </v-card-text>
            <v-card-actions>
                <v-btn text color="primary" class="ml-auto" @click="showAll(); ">Mostrar Todos</v-btn>
                <v-btn text color="success" @click=" hide_dialog(); ">Cancelar</v-btn>
                <v-btn text color="error" @click=" update_headers(); ">Actualizar</v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
import { mapState } from 'vuex';
import axios from 'axios';

export default {
    data() {
        return {
            dialog: false,
            hiddenHeaders: [],
        }
    },
    props: {
        headers: {
            type: Array,
            required: true,
        },
        value: {
            type: Array,
            required: true,
        },
        path: {
            type: String,
            required: true,
        },
    },
    methods: {
        pushPreferenciasUsuario() {
            axios
            .put("/Usuarios/ActualizarPreferencias", {
                ruta: this.path,
                ColumnasOcultar: this.hiddenHeaders.map(obj => obj.value)
            })
            .then(() => {
            })
            .catch(error => {
                console.log(error);
            })
        },
        show_dialog() {
            this.dialog = true;
            this.hiddenHeaders = this.headers.filter((x) => !this.value.includes(x));
        },
        hide_dialog() {
            this.dialog = false;
        },
        visible_off(header) {
            this.hiddenHeaders.push(header);
        },
        visible_on(header) {
            const index = this.hiddenHeaders.indexOf(header);
            if (index !== -1) { this.hiddenHeaders.splice(index, 1); }
        },
        showAll() {
            this.hiddenHeaders = []
        },
        update_headers(mounted = false) {
            const hiddenHeadersPref = JSON.stringify(this.hiddenHeaders.map(obj => obj.value))
            this.$store
                .dispatch("changePreferences", { id: this.path, data: hiddenHeadersPref })
                .then((response) => {
                })
                .catch((err) => {
                    console.log(err)
                });

            if(!mounted) this.pushPreferenciasUsuario()
            this.$emit("input", this.headers.filter((x) => !this.hiddenHeaders.includes(x)));
            this.dialog = false;
        },
    },
    computed: {
        ...mapState(["preferenciasUsuario", "idUser"])
    },
    mounted() {
        try {
            this.hiddenHeaders = this.preferenciasUsuario[this.path]
            if (typeof this.hiddenHeaders === 'undefined' || this.hiddenHeaders == null) this.hiddenHeaders = []
        } catch (error) {
            this.hiddenHeders = []
        }
        
        this.$emit("input", this.headers.filter((x) => !this.hiddenHeaders.includes(x.value)));
    },
}
</script>