<template>
	<v-row justify="center">
		<v-dialog v-model="dialog" persistent max-width="450">
			<v-card :loading="loading">
				<template slot="progress">
					<v-progress-linear
						color="green"
						height="10"
						indeterminate
					></v-progress-linear>
				</template>
				<v-card-title v-if="estado < 2" class="headline">
					Restablecer contraseña
				</v-card-title>
				<v-card-title v-if="estado === 2" class="headline">
					Contraseña restablecida
				</v-card-title>
				<v-card-text>
					<v-container>
						<v-row v-if="estado === 0">
							<v-col cols="12">
								<p class="text--primary body-1 mb-0">
									Ingrese su correo electrónico para
									restablecer su contraseña.
								</p>
							</v-col>
							<v-col cols="12">
								<v-text-field
									autofocus="true"
									v-model="email"
									prepend-icon="mail"
									label="Email"
									:disabled="loading"
									:rules="[rules.email]"
								></v-text-field>
							</v-col>
						</v-row>
						<transition name="slide-x-reverse-transition">
							<v-row v-if="estado === 1">
								<v-col cols="12">
									<p class="text--primary body-1 mb-0">
										Ingrese el código de seguridad enviado a
										su email para obtener una nueva
										contraseña.
									</p>
								</v-col>
								<v-col cols="12">
									<v-text-field
										autofocus="true"
										v-model="secreto"
										prepend-icon="mdi-lock"
										label="Código de seguridad"
										:disabled="loading"
										:rules="[rules.codigo]"
									></v-text-field>
								</v-col>
							</v-row>
							<v-row v-if="estado === 2">
								<v-col cols="12">
									<p class="text--primary body-1 mb-0">
										Se ha enviado la nueva contraseña a su
										email.
									</p>
								</v-col>
							</v-row>
						</transition>
					</v-container>
				</v-card-text>
				<v-card-actions>
					<v-spacer></v-spacer>
					<v-btn
						v-if="estado !== 2"
						color="red darken-1"
						:disabled="loading"
						@click="cancelPass"
					>
						Cancelar
					</v-btn>
					<v-btn
						color="green darken-1"
						:disabled="loading || !valido"
						@click="resetPass"
					>
						Aceptar
					</v-btn>
				</v-card-actions>
			</v-card>
		</v-dialog>
	</v-row>
</template>

<script>
import axios from "axios";

export default {
	data() {
		return {
			estado: 0,
			dialog: false,
			loading: false,
			valido: false,
			email: "",
			secreto: "",
			rules: {
				email: (value) => {
					const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
					if (pattern.test(value)) {
						this.valido = true;
						return true;
					} else {
						this.valido = false;
						return "Email invalido.";
					}
				},
				codigo: (value) => {
					if (!!value) {
						this.valido = true;
						return true;
					} else {
						this.valido = false;
						return "Campo requerido.";
					}
				},
			},
		};
	},
	methods: {
		mostrarDialog() {
			this.dialog = true;
		},
		cancelPass() {
			this.dialog = false;
			this.estado = 0;
			this.loading = false;
			this.email = "";
			this.secreto = "";
			this.valido = false;
		},
		resetPass() {
			this.loading = true;
			if (this.estado === 0) {
				axios
					.post("/Usuarios/SolicitarReset", {
						email: this.email,
					})
					.then(() => {
						this.estado = 1;
						this.loading = false;
					})
					.catch((error) => console.log(error));
			} else if (this.estado === 1) {
				axios
					.post("/Usuarios/AutorizarReset", {
						email: this.email,
						secreto: this.secreto,
					})
					.then(() => {
						this.estado = 2;
						this.loading = false;
					})
					.catch((error) => console.log(error));
			} else if (this.estado === 2) {
				this.cancelPass();
			}
		},
	},
};
</script>