<template>
  <v-col cols="12" class="px-8" v-if="permisoVista('tiempociclo','r')">
    <v-row>
      <v-col cols="6" md="6" sm="6">
        <v-autocomplete
          label="Máquinas Instancias (Obligatorio)"
          v-model="maquinaInstancia"
          :items="maquinaInstancias"
          item-value="idMaquinaIns"
          :item-text="nombreMaquina"
          @click:clear="restablecer"
          @change="getDatos"
        ></v-autocomplete>
      </v-col>
      <v-col cols="6" md="6" sm="6">
        <v-autocomplete
          v-model="minutos"
          label="Intervalo de Tiempo (Obligatorio)"
          :items="intervalos"
          @change="getDatos"
        ></v-autocomplete>
      </v-col>
    </v-row>
    <v-row v-if="realizarPeticion" class="px-0 ma-0">
      <v-col cols="12" class="ma-0 text-right text-subtitle-2">
        Proxima Actualización en {{tiempo}}
      </v-col>
    </v-row> 
    <v-progress-linear v-if="cargando" color="primary" height="5" indeterminate ></v-progress-linear>
    <apexchart type="scatter" height="350" :options="chartOptions" :series="series"></apexchart>
  </v-col>
</template>

<script>
import axios from "axios"
import moment from "moment"

export default {
  data:() => ({
    cargando:false,
    series: [],
    chartOptions: {
      chart: {
        height: 350,
        type: 'scatter',
      },
      xaxis: {
        type: 'datetime',
        labels: {
          datetimeUTC: false
        }
      },
      yaxis: {
        labels: {
          formatter: function (value) {
            var tiempo = value;
            var horas = Math.trunc(tiempo/3600);
            tiempo -= horas * 3600;
            if(horas <10) horas = "0"+horas;
            var minutos =  Math.trunc(tiempo / 60);
            tiempo -= minutos * 60;
            if(minutos <10) minutos = "0"+minutos;
            if(tiempo <10) tiempo = "0"+tiempo;
            return horas+":"+minutos+":"+tiempo;
          }
        }
      },
      tooltip: {
        x: {
          formatter: function (val) {
            return moment(val).format("YYYY-MM-DD HH:mm:ss");
          }
        }
      }
    },
    minutos: 15,
    intervalos:[
      { 
        value:1,
        text: "Último Minuto"
      },
      {
        value:5,
        text: "Últimos 5 Minutos"
      },
      {
        value:15,
        text: "Últimos 15 Minutos"
      },
      {
        value:30,
        text: "Últimos 30 Minutos"
      },
      {
        value:60, 
        text: "Ultima Hora"
      },
      {
        value:180, 
        text: "Últimas 3 Horas"
      },
      {
        value:720, 
        text: "Últimas 12 Horas"
      },
      {
        value:1440, 
        text: "Ultimas 24 Horas"
      }
    ],
    maquinaInstancias:[],
    maquinaInstancia: -1,
    loading: false,
    segundos: -1,
  }),
  mounted(){
    setInterval(() => {
      if(this.segundos == 0){
        this.getDatos();
      }
      else{
        this.segundos -= 1;
      }
    }, 1000);
    this.getMaquinaInstancias();
  },
  computed:{
    realizarPeticion(){
      return (this.$utils.isValid(this.maquinaInstancia) && this.maquinaInstancia > 0 && this.minutos > 0);
    },
    url(){
      return `/Lecturas/ReporteGraficaCiclos?IdMaquinaIns=${this.maquinaInstancia}&Desde=${this.minutos}`;
    },
    tiempo() {
      return this.$utils.strings.toTime(this.segundos);
    }
  },
  methods:{
    nombreMaquina: maquina => `${maquina.marca} ${maquina.modelo} - [${maquina.nombre}]`,
    restablecer(){
      this.maquinaInstancia = -1;
    },
    getMaquinaInstancias(){
      this.loading = true;
      this.cargando = true;
      axios
      .get("/Sensores/MaquinaInstancias")
      .then(response => {
        this.maquinaInstancias = response.data;
        this.loading = false;
        this.cargando = false;
      })
      .catch(error => {
        this.loading = false;
        this.cargando = false;
        console.log(error);
      })
    },
    getDatos(){
      if(this.loading || !this.realizarPeticion) return;
      
      this.loading = true;
      this.segundos = 30;
      axios
      .get(this.url)
      .then(response => {
        this.series = response.data;
        this.loading = false;
      })
      .catch(error => {
        this.loading = false;
        console.log(error);
      })
    }
  }
}
</script>