<template>
	<v-select-advanced
		v-model="value"
		multiple
		searchable
		taggable
		:options="items"
		:label="itemText"
		:placeholder="placeholder"
		:disabled="disabled"
		appendToBody
		@input="changeValue"
	></v-select-advanced>
</template>

<script>
export default {
	props: {
		items: {
			type: Array,
			default: [],
		},
		itemValue: {
			type: String,
			default: "",
		},
		itemText: {
			type: String,
			default: "",
		},
		placeholder: {
			type: String,
			default: "",
		},
		value: {
			type: Array,
			default: [],
		},
		disabled: {
			type: Boolean,
			default: false
		}
	},
	data() {
		return {
			editedItem: [],
		};
	},
	computed: {
		ids() {
			return this.value
				.filter((tag) => typeof tag[this.itemValue] != "undefined")
				.map((tag) => tag);
		},
		tags() {
			return this.value
				.filter((tag) => typeof tag[this.itemValue] == "undefined")
				.map((tag) => tag[this.itemText]);
		},
	},
	methods: {
		changeValue() {
			let items = [];
			this.ids.forEach( id => {
				items.push(id);
			});
			this.tags.forEach( etiqueta => {
				items.push({tag: etiqueta})				
			});
			this.$emit('input',items);
		},
	},
};
</script>