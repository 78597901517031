<template>
  <v-row>
    <v-dialog
      v-model="showEditor"
      max-width="500px"
    >
      <v-card>
        <v-card-title>
          {{edit?'Editar':'Nuevo'}} Horario
        </v-card-title>
        <v-card-text>
          <v-row>
            <v-col cols="12" md="12" sm="12">
              <v-select
                v-model="editedItem.diaSemana"
                label="Día de la Semana"
                :items="diasSemana"
                item-text="name"
                item-value="value"
              ></v-select>
            </v-col>
            <v-col cols="6" md="6" sm="6">
              <input-time
                v-model="editedItem.inicio"
                label="Inicio"
                :max="fin"
              ></input-time>
            </v-col>
            <v-col cols="6" md="6" sm="6">
              <input-time
                v-model="editedItem.fin"
                :min="inicio"
                label="Fin"
              ></input-time>
            </v-col>
            <v-col cols="12" md="12" sm="12">
              <li v-if="mensajeError != ''" class="red--text">{{mensajeError}}</li>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text color="blue" @click="close()">Cancelar</v-btn>
          <v-btn v-if="permisoVista('horarios','d')" color="green darken-1" text @click="del()" :disabled="deleting" :loading="deleting">
            Eliminar
            <template v-slot:loader>
              <v-progress-circular
                indeterminate
                :width="2"
                :size="24"
                color="green"
              ></v-progress-circular>
            </template>
          </v-btn>
          <v-btn v-if="permisoVista('horarios','u')" color="red darken-1" text @click="save()" :disabled="saving" :loading="saving">
            Guardar
            <template v-slot:loader>
              <v-progress-circular
                indeterminate
                :width="2"
                :size="24"
                color="red"
              ></v-progress-circular>
            </template>
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-col col="12" md="12" sm="12" v-if="permisoVista('horarios','r')">
      <v-btn
        v-if="permisoVista('horarios','c')"
        color="primary"
        @click="showEditor = true; edit=false;"
      >Nuevo Horario</v-btn>
      <v-container>
        <v-calendar
          ref="calendar"
          :short-weekdays="false"
          :short-intervals="false"
          :events="events"
          :interval-format="intervalFormat"
          :now="today"
          :value="value"
          @click:event="showEvent"
          color="primary"
          type="week"
          interval-height="25px"
          :interval-minutes="60"
          :first-interval="start"
          :interval-count="intervals"
        ></v-calendar>
      </v-container>
    </v-col>
  </v-row>
</template>

<script>
import axios from 'axios';
import inputTime from "@/components/InputTime";

  export default {
    components:{
      'input-time':inputTime,
    },
    data: () => ({
      value: '2017-01-02',
      today: '2016-12-31',
      events: [],
      start: 8,
      intervals: 18,
      showEditor: false,
      edit: false,
      inicio:null,
      fin:null,
      diasSemana:[
        {
          name:"Domingo",
          value: 0,
        },
        {
          name:"Lunes",
          value: 1,
        },
        {
          name:"Martes",
          value: 2,
        },
        {
          name:"Miércoles",
          value: 3,
        },
        {
          name:"Jueves",
          value: 4,
        },
        {
          name:"Viernes",
          value: 5,
        },
        {
          name:"Sábado",
          value: 6,
        }
      ],
      editedItem:{
        diaSemana:0,
        inicio:"",
        fin:""
      },
      defaultItem:{
        diaSemana:0,
        inicio:"",
        fin:""
      },
      saving:false,
      deleting:false,
      mensajeError:"",
    }),
    created(){
      this.initialize();
    },
    methods:{
      initialize () {
        axios
          .get("/Horarios")
          .then(response => {
            this.events = response.data.events;
            this.start = response.data.start;
            this.intervals = response.data.intervals;
          })
          .catch(error => {
            console.log(error);
          });
      },
      intervalFormat(day){
        var time = day.time.split(":");
        if(parseInt(time[0])<= 12) return day.time +" AM";
        else return this.twoDigits(parseInt(time[0])%12)+":"+time[1] +" PM";
      },
      twoDigits(integer){
        return (integer<=9?'0'+integer:integer);
      },
      showEvent({ nativeEvent, event }){
        if(!this.permisoVista('horarios','u') && !this.permisoVista('horarios','d')) return;
        this.showEditor = true;
        this.edit = true;
        this.editedItem = event;
      },
      close(){
        this.editedItem = Object.assign({},this.defaultItem);
        this.showEditor = false;
        this.mensajeError = "";
      },
      save(){
        var obj = {
          DiaSemana: this.editedItem.diaSemana,
          Inicio: this.editedItem.inicio,
          Fin: this.editedItem.fin
        };

        this.saving = true;

        if(!this.edit){
          axios
          .post("/Horarios/Crear",obj)
          .then(response => {
            this.saving = false;
            this.mensajeError = "";
            this.close();
            this.initialize();
          })
          .catch(error => {
            this.saving = false;
            this.mensajesError(error);
          });
        }
        else{
          axios
          .put("/Horarios/Actualizar/"+this.editedItem.idHorario,obj)
          .then(response => {
            this.saving = false;
            this.mensajeError = "";
            this.close();
            this.initialize();
          })
          .catch(error => {
            this.saving = false;
            this.mensajesError(error);
          });
        }
      },
      del(){
        this.deleting = true;
        axios
        .put("/Horarios/Desactivar/"+this.editedItem.idHorario)
        .then(response => {
          this.deleting = false;
          this.mensajeError = "";
          this.close();
          this.initialize();
        })
        .catch(error => {
          this.deleting = false;
          this.mensajesError(error);
        });
      },
      mensajesError(error){
        if (error.response) {
          switch(error.response.status){
            case 400:
              this.mensajeError = "La Hora de Fin es menor a la Hora de Inicio";
              break;
            case 409:
              this.mensajeError = "Este Horario se superpone a otro(s) Horario(s) Existente(s)";
              break;
            default:
              this.mensajeError = "Se ha presentado un Error";
              break;
          }
        }
      }
    },
    mounted () {
      if(typeof this.$refs.calendar !== 'undefined') this.$refs.calendar.scrollToTime('08:00')
    }
  }
</script>

<style>
button.v-btn.v-btn--depressed.v-btn--fab.v-btn--round.theme--light.v-size--default.transparent {
    display: none;
}
</style>