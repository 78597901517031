<template>
	<v-col cols="12">
		<v-dialog v-model="dialog" persistent max-width="500px">
			<v-card>
				<v-card-title style="word-break: break-word"
					>Confirmación de Actualización de Costos</v-card-title
				>
				<v-card-text>
					<v-container fluid>
						<v-row>
							<v-col cols="12" class="px-0">
								<p class="mb-0 text-subtitle-1 text--primary">
									¿Está seguro que desea realizar esta acción?
									Esta acción es permanente y no se puede
									revertir.
								</p>
							</v-col>
						</v-row>
					</v-container>
				</v-card-text>
				<v-card-actions>
					<v-spacer></v-spacer>
					<v-btn
						color="success"
						text
						:disabled="saving"
						@click="onClickCancelar"
						>Cancelar</v-btn
					>
					<v-btn
						color="error"
						text
						:disabled="saving"
						:loading="saving"
						@click="onClickConfirmar"
						>Confirmar</v-btn
					>
				</v-card-actions>
			</v-card>
		</v-dialog>
		<v-row class="align-center">
			<v-col cols="4" class="pt-1">
				<v-autocomplete
					v-model="filtros.idArticulo"
					:label="labelFiltroArticulo"
					:items="articulos"
					:item-text="nombreArticulo"
					item-value="idArticulo"
					hide-details
					clearable
					:disabled="loadingArticulos || loadingArticulosMP"
					:loading="loadingArticulos"
					@change="getArticulosMP()"
				></v-autocomplete>
			</v-col>
			<v-col cols="3">
				<VueCtkDateTimePicker
					id="timepicker-desde"
					v-model="filtros.desde"
					:label="labelFiltroDesde"
					format="YYYY-MM-DD HH:mm"
					formatted="YYYY-MM-DD HH:mm"
					noHeader
					overlay
					buttonNowTranslation="Ahora"
					@input="getArticulosMP()"
				/>
			</v-col>
			<v-col cols="3">
				<VueCtkDateTimePicker
					id="timepicker-hasta"
					v-model="filtros.hasta"
					:min-date="filtros.desde"
					:label="labelFiltrosHasta"
					format="YYYY-MM-DD HH:mm"
					formatted="YYYY-MM-DD HH:mm"
					noHeader
					overlay
					buttonNowTranslation="Ahora"
					@input="getArticulosMP()"
				/>
			</v-col>
			<v-col cols="2" class="d-flex align-center justify-end">
				<v-btn
					color="primary"
					:disabled="!filtrosValidos || loadingArticulosMP"
					@click="getArticulosMP()"
					style="width: 150px"
					>Buscar</v-btn
				>
			</v-col>
		</v-row>
		<v-row>
			<v-col cols="12">
				<v-data-table
					v-model="articulosSelected"
					:items="articulosMP"
					:headers="articulosHeaders"
					:loading="loadingArticulosMP"
					show-select
					item-key="idMovimiento"
					@toggle-select-all="selectAllArticulos"
				>
					<template v-slot:[`item.cantidad`]="{ item }">
						{{ getCantidadText(item) }}
					</template>
					<template v-slot:[`item.costoUnitario`]="{ item }">
						{{ getCostoText(item) }}
					</template>
					<template v-slot:[`item.proveedores`]="{ item }">
						<p
							v-for="(proveedor, index) in item.proveedores"
							:key="`proveedor-${index}`"
							:class="item.proveedores.length > 1 ? 'my-2' : 'mb-0'"
						>
							{{
								item.proveedores.length > 1
									? `• ${proveedor}`
									: proveedor
							}}
						</p>
					</template>
				</v-data-table>
			</v-col>
		</v-row>
		<v-row>
			<v-col cols="12" class="d-flex align-center justify-space-between">
				<v-col cols="3" class="pa-0">
					<v-text-field
						v-model="nuevoCosto"
						label="Nuevo Costo Unitario"
						prefix="$"
						type="number"
						hide-spin-buttons
						clearable
						:error-messages="errorCosto"
						@focus="errorCosto = null"
						:disabled="!hayArticulosSelected || loadingArticulosMP"
					></v-text-field>
				</v-col>
				<v-col cols="2" class="pa-0 d-flex align-center justify-end">
					<v-btn
						color="primary"
						:disabled="!hayArticulosSelected || loadingArticulosMP"
						@click="onClickActualizarCosto"
						style="max-width: 180px"
						>Actualizar Costo</v-btn
					>
				</v-col>
			</v-col>
		</v-row>
	</v-col>
</template>

<script>
import axios from "axios";
export default {
	data() {
		return {
			saving: false,
			dialog: false,
			errorCosto: null,
			nuevoCosto: null,
			filtros: {
				idArticulo: null,
				desde: null,
				hasta: null,
			},
			articulos: [],
			articulosSelected: [],
			articulosMP: [],
			articulosHeaders: [
				{
					text: "Cantidad",
					align: "center",
					value: "cantidad",
				},
				{
					text: "Fecha Creación",
					align: "center",
					value: "fechaCreacion",
				},
				{
					text: "Costo Unitario",
					align: "center",
					value: "costoUnitario",
				},
				{
					text: "Proveedores",
					align: "center",
					value: "proveedores",
				},
			],
			loadingArticulosMP: false,
			loadingArticulos: false,
		};
	},
	computed: {
		hayArticulosSelected() {
			return this.articulosSelected.length > 0;
		},
		labelFiltrosHasta() {
			return this.filtros.hasta != null ? "Hasta" : "[Obligatorio] Hasta";
		},
		labelFiltroDesde() {
			return this.filtros.desde != null ? "Desde" : "[Obligatorio] Desde";
		},
		labelFiltroArticulo() {
			return this.filtros.idArticulo != null
				? "Artículo"
				: "[Obligatorio] Artículo";
		},
		filtrosValidos() {
			return Object.values(this.filtros).every((value) => value != null);
		},
	},
	watch: {
		hayArticulosSelected(){
			if(!this.hayArticulosSelected) this.nuevoCosto = null;
		}
	},
	mounted() {
		this.initialize();
	},
	methods: {
		getCostoText: ({ costoUnitario }) => `$${costoUnitario.toFixed(2)}`,
		getCantidadText: ({ cantidad, unidad }) => `${cantidad.toFixed(2)} ${unidad}`,
		nombreArticulo: ({ codigo, nombre }) => `${codigo ? `[${codigo}] -` : ""} ${nombre}`.trim(),
		selectAllArticulos({ value }){
			this.articulosSelected = value ? this.articulosMP.slice() : [];
		},
		onClickCancelar() {
			this.dialog = false;
		},
		resetFiltros() {
			Object.keys(this.filtros).forEach((key) => {
				this.filtros[key] = null;
			});
		},
		resetValues() {
			this.articulosMP = [];
			this.articulosSelected = [];
			this.nuevoCosto = null;
			this.errorCosto = null;
		},
		onClickConfirmar() {
			this.saving = true;

			const payload = {
				idsMovimientos: this.articulosSelected.map(
					(articulo) => articulo.idMovimiento
				),
				idArticulo: this.filtros.idArticulo,
				precioUnitario: parseFloat(this.nuevoCosto),
			};

			axios
				.post("/Compras/ActualizarCostoMP", payload)
				.then(() => {
					this.saving = false;
					this.resetValues();
					this.getArticulosMP();
					this.onClickCancelar();
				})
				.catch((error) => {
					this.saving = false;
					console.log(error);
				});
		},
		validate() {
			this.errorCosto = null;

			if (isNaN(parseFloat(this.nuevoCosto)))
				this.errorCosto = "Requerido";
			else if (parseFloat(this.nuevoCosto) <= 0)
				this.errorCosto = "Inválido";

			return this.errorCosto == null;
		},
		onClickActualizarCosto() {
			if (!this.validate()) return;

			this.dialog = true;
		},
		initialize() {
			this.getArticulos();
			this.resetFiltros();
			this.resetValues();
		},
		getArticulos() {
			this.loadingArticulos = true;

			axios
				.get("/Articulos/ArticulosNombres")
				.then((response) => {
					this.articulos = response.data;
				})
				.catch((error) => {
					this.articulos = [];
					console.log(error);
				})
				.finally(() => {
					this.loadingArticulos = false;
				});
		},
		getArticulosMP() {
			this.articulosMP = [];
			this.articulosSelected = [];
			if (!this.filtrosValidos) {
				this.resetValues();
				return;
			}

			this.loadingArticulosMP = true;

			axios
				.get("/Compras/IngresosConCosto", {
					params: this.filtros,
				})
				.then((response) => {
					this.articulosMP = response.data;
				})
				.catch((error) => {
					this.articulosMP = [];
					console.log(error);
				})
				.finally(() => {
					this.loadingArticulosMP = false;
				});
		},
	},
};
</script>