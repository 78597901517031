<template>
	<div class="custom-column-width text-center flex d-flex flex-column">
		<div
			v-if="item.idPersona"
			class="white--text rounded-t-lg pa-2 flex d-flex justify-center align-center"
			:class="colorHeader"
		>
			<div>
				<p class="mb-0 text-h6" style="font-size: 1.125rem !important">
					{{ item.nombre }}
				</p>
				<p class="mb-0 text-subtitle-1" style="font-size: 1rem !important">
					Tiempo tareas asignadas: {{ tiempoTotalFormated }}
				</p>
			</div>
		</div>
		<div v-else class="white--text rounded-t-lg pa-2 flex d-flex justify-center align-center" :class="color">
			<div>
				<p class="mb-0 text-h6 font-weight-bold" style="font-size: 1.125rem !important">Tareas sin asignar</p>
				<p class="mb-0 text-subtitle-1" style="font-size: 1rem !important">
					{{ numTareasComputed }} Tareas sin asignar
				</p>
			</div>
		</div>
		<div class="grey lighten-2 rounded-b-lg py-2 px-1">
			<div class="d-flex flex-column custom-content-container px-2">
				<draggable
					:list="item.tareasAsignadas"
					:animation="200"
					ghost-class="ghost-card"
					class="draggable-area"
					group="tareas"
					:disabled="saving || disabled"
					@change="$emit('change',$event, item)"
				>
					<CardTablero
						v-for="subitem in item.tareasAsignadas"
						:key="`item-${subitem.tipo}-${
							subitem.idMantenimientoTarea ||
							subitem.idMantenimientoCalendarioInspeccion
						}`"
						:item="subitem"
						class="mb-2 cursor-draggable"
					/>
				</draggable>
			</div>
		</div>
	</div>
</template>
<script>
import draggable from "vuedraggable";
import { defineAsyncComponent } from "vue";
export default {
	props: {
		item: {
			type: Object,
			default: () => ({}),
		},
		color: {
			type: String,
			default: "teal darken-2",
		},
		saving: {
			type: Boolean,
			default: false
		},
		disabled: {
			type: Boolean,
			default: false
		}
	},
	components: {
		CardTablero: defineAsyncComponent(() => import("./CardTablero.vue")),
		draggable,
	},
	computed: {
		numTareasComputed() {
			return this.item?.tareasAsignadas.length || 0;
		},
		tiempoTotalFormated() {
			return this.$utils.time.secondsToTime(this.tiempoTotalTareas);
		},
		tiempoTotalTareas() {
			const sumatoriaTareas = this.item?.tareasAsignadas.reduce(
				(acumulador, object) => acumulador + object.duracionSegundos,
				0
			);

			return this.item.duracionSubdepartamentos + sumatoriaTareas;
		},
		colorHeader() {
			// 23400 = 06:30:00
			// 27900 = 07:45:00
			if (this.tiempoTotalTareas > 27900) return "red accent-4";
			if (
				this.tiempoTotalTareas >= 23400 &&
				this.tiempoTotalTareas <= 27900
			)
				return "deep-orange darken-2";
			return this.color;
		},
	}
};
</script>
<style scoped>
.custom-column-height {
	height: calc(100vh - 18.75rem);
}

.custom-column-width {
	min-width: 18.125rem;
	max-width: 18.125rem;
}

.custom-content-container {
	height: calc(100vh - 25rem);
	overflow-y: auto;
	overflow-x: hidden;
}

.draggable-area {
	min-height: 18.75rem;
}

.cursor-draggable {
	cursor: -webkit-grab;
    cursor:    -moz-grab;
    cursor:         grab;
}
</style>