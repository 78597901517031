<template>
	<v-container fluid>
		<v-dialog v-model="dialogDelete" persistent max-width="500px">
			<v-card>
				<v-card-title style="word-break: break-word"
					>¿Está seguro que desea eliminar este Mantenimiento
					Preventivo?</v-card-title
				>
				<v-card-text>
					<p class="subtitle-1 text--secondary">
						Esta acción no se puede revertir y será permanente.
					</p>
				</v-card-text>
				<v-card-actions>
					<v-spacer></v-spacer>
					<v-btn
						color="success"
						:disabled="deleting"
						text
						@click="closeDialog"
						>Cancelar</v-btn
					>
					<v-btn
						color="error"
						:disabled="deleting"
						:loading="deleting"
						text
						@click="deleteMantenimiento"
						>Aceptar</v-btn
					>
				</v-card-actions>
			</v-card>
		</v-dialog>
		<v-dialog v-model="dialog" persistent max-width="55%">
			<v-card>
				<v-card-title>
					{{
						editedItem.idMoldeCatalogoPreventivo == null
							? "Crear"
							: "Editar"
					}}
					Mantenimiento Preventivo
				</v-card-title>
				<v-card-text>
					<v-container fluid>
						<v-row>
							<v-col cols="6">
								<v-text-field
									v-model="editedItem.codigo"
									label="Código del Mantenimiento Preventivo"
									clearable
									:disabled="saving"
									:error-messages="errores.errorCodigo"
									@focus="$set(errores, 'errorCodigo', null)"
								></v-text-field>
							</v-col>
							<v-col cols="6">
								<v-text-field
									v-model="editedItem.nombre"
									label="Nombre del Mantenimiento Preventivo"
									clearable
									:disabled="saving"
									:error-messages="errores.errorNombre"
									@focus="$set(errores, 'errorNombre', null)"
								></v-text-field>
							</v-col>
						</v-row>
						<v-row>
							<v-col cols="12">
								<v-data-table
									:items="editedItem.moldesAgendados"
									:headers="headersMoldesAgendados"
									:items-per-page="-1"
									hide-default-footer
								>
									<template
										v-slot:[`item.idMolde`]="{ item }"
									>
										<v-autocomplete
											v-model="item.idMolde"
											:items="
												moldesFiltered(item.idMolde)
											"
											item-text="codigo"
											item-value="idMolde"
											clearable
											:disabled="saving || loadingMoldes"
											:loading="loadingMoldes"
											:error-messages="item.errorMolde"
											@focus="
												$set(item, 'errorMolde', null)
											"
										></v-autocomplete>
									</template>
									<template
										v-slot:[`item.disparos`]="{ item }"
									>
										<v-text-field
											v-model="item.disparos"
											type="number"
											hide-spin-buttons
											suffix="Disparos"
											class="centered-input"
											:disabled="saving"
											:error-messages="item.errorDisparos"
											@focus="
												$set(
													item,
													'errorDisparos',
													null
												)
											"
										></v-text-field>
									</template>
									<template
										v-slot:[`item.agendar`]="{ item }"
									>
										<v-select
											v-model="item.agendar"
											:items="tiposAgenda"
											:disabled="saving"
											:error-messages="item.errorAgenda"
											@focus="
												$set(item, 'errorAgenda', null)
											"
										></v-select>
									</template>
									<template
										v-slot:[`item.actions`]="{ index }"
									>
										<v-tooltip
											bottom
											color="black"
											class="white--text"
										>
											<template v-slot:activator="{ on }">
												<v-icon
													v-on="on"
													small
													:disabled="saving"
													@click="deleteItem(index)"
												>
													mdi-delete
												</v-icon>
											</template>
											<span class="white--text"
												>Eliminar</span
											>
										</v-tooltip>
									</template>
								</v-data-table>
							</v-col>
						</v-row>
						<v-row>
							<v-col
								cols="12"
								class="py-0 d-flex align-center justify-space-between"
							>
								<v-btn
									color="primary"
									class="my-0"
									text
									:disabled="disableButtonAgregarMolde"
									@click="addItem"
								>
									<v-icon left>mdi-plus</v-icon> Agregar Molde
								</v-btn>
								<v-slide-y-transition>
									<p
										v-if="errores.errorMoldeAgendado"
										class="mb-0 red--text"
									>
										{{ errores.errorMoldeAgendado }}
									</p>
								</v-slide-y-transition>
							</v-col>
						</v-row>
					</v-container>
				</v-card-text>
				<v-card-actions>
					<v-spacer></v-spacer>
					<v-btn
						text
						color="success"
						:disabled="saving"
						@click="closeDialog"
						>Cancelar</v-btn
					>
					<v-btn
						text
						color="error"
						:disabled="saving"
						:loading="saving"
						@click="save"
						>Guardar</v-btn
					>
				</v-card-actions>
			</v-card>
		</v-dialog>
		<v-row>
			<v-col cols="4">
				<v-btn color="primary" :disabled="loading" @click="showDialog()"
					>Agregar Mantenimiento Preventivo</v-btn
				>
			</v-col>
		</v-row>
		<v-row>
			<v-col cols="12">
				<v-data-table
					:items="preventivos"
					:headers="headersPreventivos"
					:loading="loading"
				>
					<template v-slot:[`item.numMoldes`]="{ item }">
						{{
							`${item.moldesAgendados.length} ${
								item.moldesAgendados.length > 1
									? "Moldes"
									: "Molde"
							}`
						}}
					</template>
					<template v-slot:[`item.actions`]="{ item }">
						<v-tooltip bottom color="black" class="white--text">
							<template v-slot:activator="{ on }">
								<v-icon
									v-on="on"
									small
									class="mr-2"
									:disabled="loading"
								>
									mdi-play
								</v-icon>
							</template>
							<span class="white--text"
								>Programar Mantenimiento Atemporal</span
							>
						</v-tooltip>
						<v-tooltip bottom color="black" class="white--text">
							<template v-slot:activator="{ on }">
								<v-icon
									v-on="on"
									small
									class="mr-2"
									:disabled="loading"
									@click="showDialog(item)"
								>
									mdi-pencil
								</v-icon>
							</template>
							<span class="white--text">Editar</span>
						</v-tooltip>
						<v-tooltip bottom color="black" class="white--text">
							<template v-slot:activator="{ on }">
								<v-icon
									v-on="on"
									small
									:disabled="loading"
									@click="showDialog(item, true)"
								>
									mdi-delete
								</v-icon>
							</template>
							<span class="white--text">Eliminar</span>
						</v-tooltip>
					</template>
				</v-data-table>
			</v-col>
		</v-row>
	</v-container>
</template>

<script>
import axios from "axios";
export default {
	data() {
		return {
			errores: {
				errorCodigo: null,
				errorNombre: null,
				errorMoldeAgendado: null,
			},
			loadingMoldes: false,
			moldes: [],
			deleting: false,
			saving: false,
			tiposAgenda: [
				{
					text: "Inmediatamente",
					value: 1,
				},
				{
					text: "Próxima Hora",
					value: 2,
				},
				{
					text: "Próximo Turno",
					value: 3,
				},
				{
					text: "Dia de Mañana",
					value: 4,
				},
			],
			defaultMoldeAgendado: {
				idMolde: null,
				disparos: null,
				agendar: null,
				errorMolde: null,
				errorDisparos: null,
				errorAgenda: null,
			},
			headersMoldesAgendados: [
				{
					text: "Molde",
					align: "center",
					value: "idMolde",
					width: "30%",
				},
				{
					text: "Agendar cada",
					align: "center",
					value: "disparos",
					width: "30%",
				},
				{
					text: "Agendar",
					align: "center",
					value: "agendar",
					width: "30%",
				},
				{
					text: "",
					align: "center",
					value: "actions",
					width: "5%",
				},
			],
			preventivos: [],
			headersPreventivos: [
				{
					text: "Código",
					value: "codigo",
				},
				{
					text: "Nombre",
					value: "nombre",
				},
				{
					text: "Moldes",
					value: "numMoldes",
				},
				{
					text: "Acciones",
					value: "actions",
					width: "5%",
				},
			],
			loading: false,
			editedItem: {
				idMoldeCatalogoPreventivo: null,
				codigo: null,
				nombre: null,
				numMoldes: null,
				moldesAgendados: [],
			},
			defaultEditedItem: {
				idMoldeCatalogoPreventivo: null,
				codigo: null,
				nombre: null,
				numMoldes: null,
				moldesAgendados: [],
			},
			dialog: false,
			dialogDelete: false,
		};
	},
	computed: {
		disableButtonAgregarMolde() {
			return (
				this.moldesSeleccionados.length == this.moldes.length ||
				this.saving
			);
		},
		moldesSeleccionados() {
			return this.editedItem.moldesAgendados.reduce(
				(acc, moldeAgendado) => {
					if (moldeAgendado.idMolde != null)
						acc.push(moldeAgendado.idMolde);
					return acc;
				},
				[]
			);
		},
	},
	created() {
		this.preventivos = [
			{
				idMoldeCatalogoPreventivo: 1,
				codigo: "PREV-1",
				nombre: "Mantenimiento Preventivo 1",
				numMoldes: null,
				moldesAgendados: [
					{
						idMolde: 1001,
						disparos: "21",
						agendar: 2,
					},
				],
			},
			{
				idMoldeCatalogoPreventivo: 2,
				codigo: "PREV-2",
				nombre: "Mantenimiento Preventivo 2",
				numMoldes: null,
				moldesAgendados: [
					{
						idMolde: 1001,
						disparos: "31",
						agendar: 3,
					},
					{
						idMolde: 1002,
						disparos: "21",
						agendar: 4,
					},
				],
			},
			{
				idMoldeCatalogoPreventivo: 3,
				codigo: "PREV-3",
				nombre: "Mantenimiento Preventivo 3",
				numMoldes: null,
				moldesAgendados: [
					{
						idMolde: 1001,
						disparos: "12",
						agendar: 2,
					},
					{
						idMolde: 1002,
						disparos: "13",
						agendar: 3,
					},
					{
						idMolde: 1003,
						disparos: "32",
						agendar: 4,
					},
				],
			},
		];
	},
	mounted() {
		this.initialize();
	},
	methods: {
		validate() {
			this.resetErrores();
			let hayErrores = false;

			if (!this.editedItem.codigo) this.errores.errorCodigo = "Requerido";

			if (!this.editedItem.nombre) this.errores.errorNombre = "Requerido";

			if (this.editedItem.moldesAgendados.length == 0)
				this.errores.errorMoldeAgendado = "Agregue al menos un molde";
			else {
				this.editedItem.moldesAgendados.forEach((moldeAgendado) => {
					this.$set(moldeAgendado, "errorMolde", null);
					this.$set(moldeAgendado, "errorDisparos", null);
					this.$set(moldeAgendado, "errorAgenda", null);

					if (moldeAgendado.idMolde == null) {
						this.$set(moldeAgendado, "errorMolde", "Requerido");
						hayErrores = true;
					}

					if (isNaN(parseInt(moldeAgendado.disparos))) {
						this.$set(moldeAgendado, "errorDisparos", "Requerido");
						hayErrores = true;
					} else if (parseInt(moldeAgendado.disparos) <= 0) {
						this.$set(moldeAgendado, "errorDisparos", "Inválido");
						hayErrores = true;
					}

					if (moldeAgendado.agendar == null) {
						this.$set(moldeAgendado, "errorAgenda", "Requerido");
						hayErrores = true;
					}
				});
			}

			return (
				Object.values(this.errores).every((value) => value == null) &&
				!hayErrores
			);
		},
		save() {
			if (!this.validate()) return;

			this.saving = true;

			setTimeout(() => {
				if (this.editedItem.idMoldeCatalogoPreventivo == null) {
					const maxId = Math.max(
						...this.preventivos.map(
							(preventivo) => preventivo.idMoldeCatalogoPreventivo
						)
					);
					const payload = {
						...this.editedItem,
						idMoldeCatalogoPreventivo: maxId <= 0 ? 1 : maxId + 1,
					};
					this.preventivos.push(structuredClone(payload));
				} else {
					const indexEdited = this.preventivos.findIndex(
						(preventivo) =>
							preventivo.idMoldeCatalogoPreventivo ==
							this.editedItem.idMoldeCatalogoPreventivo
					);
					this.preventivos.splice(indexEdited, 1, this.editedItem);
				}
				this.saving = false;
				this.closeDialog();
				this.initialize();
			}, 1000);
		},
		deleteMantenimiento() {
			this.deleting = true;

			setTimeout(() => {
				const index = this.preventivos.findIndex(
					(preventivo) =>
						preventivo.idMoldeCatalogoPreventivo ==
						this.editedItem.idMoldeCatalogoPreventivo
				);
				this.preventivos.splice(index, 1);
				this.deleting = false;
				this.closeDialog();
				this.initialize();
			}, 1000);
		},
		moldesFiltered(idMolde) {
			const moldesSelected = this.moldesSeleccionados.filter(
				(moldeId) => moldeId != idMolde
			);
			return this.moldes.filter(
				(molde) => !moldesSelected.includes(molde.idMolde)
			);
		},
		resetErrores() {
			Object.keys(this.errores).forEach((key) => {
				this.errores[key] = null;
			});
		},
		closeDialog() {
			this.dialog = false;
			this.dialogDelete = false;

			setTimeout(() => {
				this.editedItem = structuredClone(this.defaultEditedItem);
				this.resetErrores();
			}, 200);
		},
		addItem() {
			this.errores.errorMoldeAgendado = null;
			this.editedItem.moldesAgendados.push(
				Object.assign({}, this.defaultMoldeAgendado)
			);
		},
		deleteItem(index) {
			this.editedItem.moldesAgendados.splice(index, 1);
		},
		showDialog(item = null, showDelete = false) {
			this.editedItem = structuredClone(item || this.defaultEditedItem);

			if (showDelete) this.dialogDelete = true;
			else this.dialog = true;
		},
		initialize() {
			this.getMoldes();
			this.getCatalogoPreventivoMoldes();
		},
		getMoldes() {
			this.loadingMoldes = true;
			axios
				.get("/Moldes")
				.then((response) => {
					response.data.push(
						...[
							{
								idMolde: 1001,
								codigo: "MOLD-1",
							},
							{
								idMolde: 1002,
								codigo: "MOLD-2",
							},
							{
								idMolde: 1003,
								codigo: "MOLD-3",
							},
						]
					);
					this.moldes = response.data;
				})
				.catch((error) => {
					this.moldes = [];
					console.log(error);
				})
				.finally(() => {
					this.loadingMoldes = false;
				});
		},
		getCatalogoPreventivoMoldes() {
			this.loading = true;

			setTimeout(() => {
				this.loading = false;
			}, 1000);
		},
	},
};
</script>
<style scoped>
.centered-input >>> input {
	text-align: center !important;
}
</style>