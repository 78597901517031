import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VTab } from 'vuetify/lib/components/VTabs';
import { VTabItem } from 'vuetify/lib/components/VTabs';
import { VTabs } from 'vuetify/lib/components/VTabs';
import { VTabsItems } from 'vuetify/lib/components/VTabs';
import { VTabsSlider } from 'vuetify/lib/components/VTabs';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VRow,{attrs:{"justify-sm":"center"}},[_c(VCard,{staticClass:"mt-4",attrs:{"width":_vm.anchoMaximo,"fill":""}},[_c(VTabs,{attrs:{"show-arrows":"","background-color":"blue lighten-2 accent-4","centered":"","dark":"","icons-and-text":""},model:{value:(_vm.tab),callback:function ($$v) {_vm.tab=$$v},expression:"tab"}},[_c(VTabsSlider),(_vm.mostrarVista('dashboard') && !_vm.mqtt)?_c(VTab,{attrs:{"href":"#tab-1"}},[_vm._v(" Dashboard "),_c(VIcon,[_vm._v("mdi-chart-box")])],1):_vm._e(),(!_vm.mqtt)?_c(VTab,{attrs:{"href":"#tab-2"}},[_vm._v(" Reportes"),_c('br'),_vm._v("Dinámicos "),_c(VIcon,[_vm._v("mdi-file-chart")])],1):_vm._e(),(!_vm.mqtt)?_c(VTab,{attrs:{"href":"#tab-3"}},[_vm._v(" Analítica"),_c('br'),_vm._v("de Datos "),_c(VIcon,[_vm._v("mdi-chart-multiple")])],1):_vm._e(),(_vm.mqtt)?_c(VTab,{attrs:{"href":"#tab-4"}},[_vm._v(" Reporte"),_c('br'),_vm._v("MQTT "),_c(VIcon,[_vm._v("mdi-chart-multiple")])],1):_vm._e()],1),_c(VTabsItems,{attrs:{"touchless":""},model:{value:(_vm.tab),callback:function ($$v) {_vm.tab=$$v},expression:"tab"}},[(!_vm.mqtt)?_c(VTabItem,{key:1,attrs:{"value":'tab-1'}},[_c(VCard,{attrs:{"flat":""}},[_c(VCardText,{staticClass:"pt-0"},[_c('Dashboard',{attrs:{"tipo":1}})],1)],1)],1):_vm._e(),(!_vm.mqtt)?_c(VTabItem,{key:2,attrs:{"value":'tab-2'}},[_c(VCard,{attrs:{"flat":""}},[_c(VCardText,{staticClass:"pa-0"},[_c('ReportesDelDia')],1)],1)],1):_vm._e(),(!_vm.mqtt)?_c(VTabItem,{key:3,attrs:{"value":'tab-3'}},[_c('Dashboard',{attrs:{"tipo":2}})],1):_vm._e(),(_vm.mqtt)?_c(VTabItem,{key:4,attrs:{"value":'tab-4'}},[_c('ReporteMonterrey')],1):_vm._e()],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }