<template>
  <v-col cols="12" v-if="permisoVista('accesodispositivos','r')">
    <v-row>
      <v-col cols="6" md="6" sm="6">

      </v-col>
      <v-col cols="6" md="6" sm="6">
        
      </v-col>
    </v-row>
    <v-data-table
      :items="accesos"
      :loading="loading"
      :headers="headers"
    >
      <template v-slot:item.autorizado="{ item }">
        <div>
          {{  (item.autorizado == null) ? "No" : "Sí, por "+item.usuarioAutorizo }}
        </div>
        <div v-if="item.autorizado != null">
          Desde {{item.autorizado}}
        </div>
      </template>
      <template v-slot:item.ip="{ item }">
        {{ (item.ip == null) ? 'N/D' : item.ip }}
      </template>
      <template v-slot:item.pais="{ item }">
        {{ (item.pais == null) ? 'N/D' : item.pais }}
      </template>
      <template v-slot:item.actions="{ item }">
        <v-tooltip bottom color="black" class="white--text" v-if="item.autorizado == null && item.condicion == true && permisoVista('accesodispositivos', 'u')">
          <template v-slot:activator="{ on }">
            <v-icon v-on="on"
              small
              @click="validarAcceso(item)"
            >
              mdi-check
            </v-icon>
          </template>
          <span class="white--text">Autorizar Acceso</span>
        </v-tooltip>
        &nbsp; &nbsp;
        <v-tooltip bottom color="black" class="white--text" v-if="item.autorizado == null && item.condicion == true && permisoVista('accesodispositivos', 'u')">
          <template v-slot:activator="{ on }">
            <v-icon v-on="on"
              small
              @click="cancelarAcceso(item)"
            >
              mdi-cancel
            </v-icon>
          </template>
          <span class="white--text">Negar Acceso</span>
        </v-tooltip>
      </template>
    </v-data-table>
    <yes-no-dialog
      @change="respuesta"
			ref="yesNoDialog"
    >
    </yes-no-dialog>
  </v-col>
</template>

<script>
import axios from "axios";
import yesNoDialog from "@/components/YesNoDialog";

export default {
  components:{
    yesNoDialog
  },
  data: () => ({
    loading: false,
    accesos: [],
    headers:[
      {
        text: 'Usuario Producción',
        align: 'center',
        sortable: true,
        value: 'usuarioArea',
      },
      {
        text: 'Área',
        align: 'center',
        sortable: true,
        value: 'area',
      },
      {
        text: 'IP',
        align: 'center',
        sortable: true,
        value: 'ip',
      },
      {
        text: 'País',
        align: 'center',
        sortable: true,
        value: 'pais',
      },
      {
        text: 'Intento de Acceso',
        align: 'center',
        sortable: true,
        value: 'creado',
      },
      {
        text: 'Autorizado',
        align: 'center',
        sortable: true,
        value: 'autorizado',
      },
      {
        text: 'Acciones',
        align: 'center',
        sortable: true,
        value: 'actions',
      },
    ],
    modoDialog: 0,
    selectedItem: null,
  }),
  mounted(){
    this.initialize();
  },
  methods: {
    initialize () {
      this.getAccesos();
    },
    getAccesos(){
      this.loading = true;
      axios
      .get("/AccesoDispositivos")
      .then(response =>  {
        this.accesos = response.data;
        this.loading = false;
      })
      .catch(error => {
        console.log(error);
      });
    },
    validarAcceso(item){
      this.$refs.yesNoDialog.show("¿Desea Autorizar el Acceso a este Dispositivo y a esta Área?");
      this.modoDialog = 1;
      this.selectedItem = Object.assign({},item);
    },
    cancelarAcceso(item){
      this.$refs.yesNoDialog.show("¿Desea Denegar el Acceso a este Dispositivo y a esta Área?");
      this.modoDialog = 2;
      this.selectedItem = Object.assign({},item);
    },
    respuesta(resp){

      if(resp == false){
        this.modoDialog = 0;
      }
      else{
        if(this.modoDialog == 1){
          axios
          .put("/AccesoDispositivos/AutorizarInicioSesion/"+this.selectedItem.idAccesoDispositivo)
          .then(() => {
            this.initialize();
          })
          .catch(error => {
            console.log(error);
          })
        }

        else if(this.modoDialog == 2){
          axios
          .put("/AccesoDispositivos/DenegarInicioSesion/"+this.selectedItem.idAccesoDispositivo)
          .then(() => {
            this.initialize();
          })
          .catch(error => {
            console.log(error);
          })
        }
      }
    }
  }
}
</script>