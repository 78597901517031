import { VChip } from 'vuetify/lib/components/VChip';
import { VChipGroup } from 'vuetify/lib/components/VChipGroup';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VDataTable,{attrs:{"items":_vm.comprobaciones,"headers":_vm.headerComprobaciones,"loading":_vm.loading,"loading-text":"Cargando... Espere, Por Favor.","hide-default-footer":"","items-per-page":-1,"footer-props":{
			disableItemsPerPage: true,
			disablePagination: true,
		},"disable-sort":true},scopedSlots:_vm._u([{key:"item.respuesta",fn:function(ref){
		var item = ref.item;
return [_vm._v(" "+_vm._s(item.respuesta || "N/A")+" ")]}},{key:"item.satisfactoria",fn:function(ref){
		var item = ref.item;
return [_c('p',{staticClass:"mb-0 font-weight-bold",class:{
					'red--text': !item.satisfactoria,
					'green--text': item.satisfactoria,
				}},[_vm._v(" "+_vm._s(item.satisfactoria ? "SI" : "NO")+" ")])]}},{key:"item.resuelta",fn:function(ref){
				var item = ref.item;
return [_c('p',{staticClass:"mb-0 font-weight-bold",class:{
					'red--text': !item.resuelta,
					'green--text': item.resuelta,
				}},[_vm._v(" "+_vm._s(item.resuelta ? "SI" : "NO")+" ")])]}},{key:"item.comentario",fn:function(ref){
				var item = ref.item;
return [_vm._v(" "+_vm._s(item.comentario == null ? "N/A" : item.comentario)+" ")]}},{key:"item.notas",fn:function(ref){
				var item = ref.item;
return [_vm._v(" "+_vm._s(item.notas == null ? "N/A" : item.notas)+" ")]}},{key:"item.documentos",fn:function(ref){
				var item = ref.item;
return [(item.documentos == null || item.documentos.length == 0)?[_vm._v(" N/A ")]:_vm._e(),_c(VChipGroup,{staticClass:"centered-chips",attrs:{"column":""}},_vm._l((item.documentos),function(documento,idx){return _c(VChip,{key:("docs-" + idx),attrs:{"outlined":"","color":"blue","href":("/api/Documentos/Descargar/" + ((typeof documento == 'string') ? documento : documento.nombre) + "?access_token=" + _vm.token)}},[_c(VIcon,{attrs:{"left":""}},[_vm._v("mdi-file-outline")]),_vm._v(" "+_vm._s((typeof documento == "string") ? _vm.$utils.strings.truncateElipsis(documento) : _vm.$utils.strings.truncateElipsis(documento.nombre))+" "),_c(VIcon,{staticClass:"pr-1",attrs:{"right":"","size":"18"}},[_vm._v("mdi-download")])],1)}),1)]}},{key:"item.reparacion",fn:function(ref){
				var item = ref.item;
return [(item.reparacion == null)?_c('p',{staticClass:"mb-0"},[_vm._v("N/A")]):[_c(VContainer,{staticClass:"pa-0",attrs:{"fluid":""}},[_c(VCol,{staticClass:"pa-0 py-1",attrs:{"cols":"12"}},[_c(VTextField,{attrs:{"value":item.reparacion.tipo == null
									? 'N/A'
									: item.reparacion.tipo,"readonly":"","hide-details":""},scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('p',[_vm._v("Tipo")])]},proxy:true}],null,true)})],1),_c(VCol,{staticClass:"pa-0 py-1",attrs:{"cols":"12"}},[_c(VTextField,{attrs:{"value":_vm.getStatusReparacion(item.reparacion),"readonly":"","hide-details":""},scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('p',[_vm._v("Estado")])]},proxy:true}],null,true)})],1),_c(VCol,{staticClass:"pa-0 py-1",attrs:{"cols":"12"}},[_c(VTextField,{attrs:{"value":("[" + (item.reparacion.codigo) + "] " + (item.reparacion.nombre)),"readonly":"","hide-details":""},scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('p',[_vm._v("Nombre")])]},proxy:true}],null,true)})],1),_c(VCol,{staticClass:"pa-0 py-1",attrs:{"cols":"12"}},[_c(VTextField,{attrs:{"value":item.reparacion.fechaInicioProgramada == null
									? 'N/A'
									: item.reparacion.fechaInicioProgramada,"readonly":"","hide-details":""},scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('p',[_vm._v("Fecha Programada")])]},proxy:true}],null,true)})],1),_c(VCol,{staticClass:"pa-0 py-1",attrs:{"cols":"12"}},[_c(VTextField,{attrs:{"value":item.reparacion.fechaInicioRealizo == null
									? 'N/A'
									: item.reparacion.fechaInicioRealizo,"readonly":"","hide-details":""},scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('p',[_vm._v("Inicio Realizo")])]},proxy:true}],null,true)})],1),_c(VCol,{staticClass:"pa-0 py-1",attrs:{"cols":"12"}},[_c(VTextField,{attrs:{"value":item.reparacion.fechaFinRealizo == null
									? 'N/A'
									: item.reparacion.fechaFinRealizo,"readonly":"","hide-details":""},scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('p',[_vm._v("Fin Realizo")])]},proxy:true}],null,true)})],1)],1)]]}}],null,true)})}
var staticRenderFns = []

export { render, staticRenderFns }