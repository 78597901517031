import { VBtn } from 'vuetify/lib/components/VBtn';
import { VBtnToggle } from 'vuetify/lib/components/VBtnToggle';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VFileInput } from 'vuetify/lib/components/VFileInput';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSimpleTable } from 'vuetify/lib/components/VDataTable';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VTextarea } from 'vuetify/lib/components/VTextarea';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VContainer,[_c(VDialog,{attrs:{"persistent":"","max-width":"60%"},model:{value:(_vm.dialogTerminar),callback:function ($$v) {_vm.dialogTerminar=$$v},expression:"dialogTerminar"}},[_c(VCard,[_c(VCardTitle,[_vm._v("Terminar Mantenimiento")]),_c(VCardText,[_c(VSimpleTable,{scopedSlots:_vm._u([{key:"default",fn:function(){return [_c('thead',[_c('tr',[_c('th',{staticClass:"text-center",staticStyle:{"font-size":"16px"},attrs:{"width":"40%"}},[_vm._v(" Comprobación ")]),_c('th',{staticClass:"text-center",staticStyle:{"font-size":"16px"},attrs:{"width":"50%"}},[_vm._v(" Estado ")]),_c('th',{staticClass:"text-center",staticStyle:{"font-size":"16px"},attrs:{"width":"10%"}},[_vm._v(" Recursos de referencia ")])])]),_c('tbody',_vm._l((_vm.tareaMantenimiento),function(item,idx){return _c('tr',{key:idx},[_c('td',{staticClass:"text-center"},[_c('p',{staticClass:"mb-0",staticStyle:{"font-size":"14px !important"}},[_vm._v(" "+_vm._s(item.comprobacion)+" ")]),(item.satisfactoria == 1)?_c(VTextarea,{staticClass:"mt-13",attrs:{"outlined":"","label":"Comentarios","auto-grow":"","rows":"1","hide-details":""},model:{value:(item.comentario),callback:function ($$v) {_vm.$set(item, "comentario", $$v)},expression:"item.comentario"}}):_vm._e()],1),_c('td',[_c('p',{staticClass:"pt-3 mb-0 text-center"},[_vm._v(" ¿Satisfactoria? ")]),_c('div',{staticClass:"d-flex justify-center"},[_c(VBtnToggle,{staticClass:"my-3",on:{"change":function($event){item.idsDocumentos = [];
													item.comentario = null;}},model:{value:(item.satisfactoria),callback:function ($$v) {_vm.$set(item, "satisfactoria", $$v)},expression:"item.satisfactoria"}},[_c(VBtn,{staticClass:"ma-0",attrs:{"depressed":"","small":"","color":item.satisfactoria == 0
															? 'success'
															: 'grey lighten-1'}},[_vm._v("Si")]),_c(VBtn,{staticClass:"ma-0",attrs:{"depressed":"","small":"","color":item.satisfactoria == 1
															? 'error'
															: 'grey lighten-1'}},[_vm._v("No")])],1)],1),(item.satisfactoria == 1)?_c('div',{staticClass:"mt-6 text-center",attrs:{"bottom":""}},[_vm._l((item.idsDocumentos),function(documento,idx){return [_c('div',{key:'links-' + idx,staticClass:"d-inline-block mx-2 py-2",staticStyle:{"font-size":"14px"}},[_c('a',{staticClass:"text-decoration-none"},[_vm._v(_vm._s(documento.nombre))]),_c(VIcon,{attrs:{"small":""},on:{"click":function($event){return _vm.removerRecurso(
																item,
																documento
															)}}},[_vm._v("mdi-close")])],1)]}),_c('div',{staticClass:"d-block d-flex justify-center"},[_c(VBtn,{attrs:{"color":"light-blue lighten-1","outlined":"","dark":"","small":""},on:{"click":function($event){return _vm.showDialogRecursos(
															item.idMantenimientoComprobacion
														)}}},[_vm._v("Agregar Evidencia")])],1)],2):_vm._e()]),_c('td',[_c('ul',[_vm._l((item.documentos),function(doc,idx){return _c('li',{key:("doc-" + idx),staticClass:"pb-1"},[_c('a',{staticClass:"text-decoration-none",attrs:{"href":("/api/Documentos/Descargar/" + doc + "?access_token=" + _vm.token),"target":"_blank"}},[_vm._v(_vm._s(doc))])])}),_vm._l((item.links),function(link,idx){return _c('li',{key:("link-" + idx),staticClass:"pb-1"},[_c('a',{staticClass:"text-decoration-none",attrs:{"href":link.includes('http')
														? link
														: ("https://" + link),"target":"_blank"}},[_vm._v(_vm._s(link))])])})],2)])])}),0)]},proxy:true}])}),_c(VRow,[_c(VCol,{staticClass:"mt-4",attrs:{"cols":"12","md":"12","sm":"12"}},[_c(VTextarea,{attrs:{"outlined":"","label":"Recomendaciones","auto-grow":"","rows":"1","hide-details":""},model:{value:(_vm.recomendacion),callback:function ($$v) {_vm.recomendacion=$$v},expression:"recomendacion"}})],1)],1),_c('ul',{staticClass:"my-3"},_vm._l((_vm.errores),function(ex){return _c('li',{key:ex,staticClass:"red--text"},[_vm._v(" "+_vm._s(ex)+" ")])}),0)],1),_c(VCardActions,[_c(VSpacer),_c(VBtn,{attrs:{"color":"green","text":"","disabled":_vm.loadingFinish,"small":""},on:{"click":_vm.cerrarDialogTerminar}},[_vm._v("Cancelar")]),_c(VBtn,{attrs:{"color":"red","text":"","small":"","disabled":_vm.loadingFinish,"loading":_vm.loadingFinish},on:{"click":_vm.terminarTarea}},[_vm._v("Guardar")])],1)],1)],1),_c(VDialog,{attrs:{"persistent":"","max-width":"40%"},model:{value:(_vm.dialogRecursos),callback:function ($$v) {_vm.dialogRecursos=$$v},expression:"dialogRecursos"}},[_c(VCard,[_c(VCardTitle,[_vm._v("Agregar Evidencias")]),_c(VCardText,[_c(VFileInput,{attrs:{"label":"Seleccione sus archivos","accept":".pdf,.doc,.docx,.xls,.xlsx,.ppt,.pptx,.jpg,.jpeg,.png,.gif,.svg","counter":"","multiple":"","show-size":""},on:{"change":_vm.cambioFicheroRecurso},model:{value:(_vm.itemRecurso.idsDocumentos),callback:function ($$v) {_vm.$set(_vm.itemRecurso, "idsDocumentos", $$v)},expression:"itemRecurso.idsDocumentos"}}),_c('div',{staticClass:"d-flex justify-center"},[_c(VBtn,{staticClass:"mx-2",attrs:{"color":"red","disabled":_vm.subiendoArchivos},on:{"click":_vm.cerrarDialogRecursos}},[_vm._v("Cancelar")]),_c(VBtn,{staticClass:"mx-2",attrs:{"color":"green","loading":_vm.subiendoArchivos,"disabled":_vm.validBtn || _vm.subiendoArchivos},on:{"click":_vm.agregarRecurso}},[_vm._v("Agregar")])],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }