<template>
  <div>
    <v-dialog
      v-model="asignacionMaquina"
      fullscreen
      hide-overlay
      transition="dialog-bottom-transition"
    >
    <v-card>
      <v-toolbar
          dark
          color="primary"
        >
          <v-btn
            icon
            dark
            @click="asignacionMaquina = false"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <v-toolbar-title class="text-h5 white--text">
          Asignacion de Maquinas
        
          </v-toolbar-title>
          <v-spacer></v-spacer>
          <v-toolbar-items>
          </v-toolbar-items>
        </v-toolbar>
        <v-card-text>
        <v-simple-table>
    <template v-slot:default>
      <thead>
        <tr>
          <th class="text-left">OT</th>
          <th class="text-left" style="width: 20px;">Articulo</th>
          <th class="text-left" style="width: 20px;">Articulo Fase</th>
          <th class="text-left">Area</th>
          <th class="text-left">Maquina</th>
          <th class="text-left">MaquinaInstancia</th>
        </tr>
      </thead>
      <tbody>
        <template v-for="(item, index) in datosAsignacion">
        <tr :key="index">
          <td :rowspan="item.count1">{{ item.ot }}</td>
          <td :rowspan="item.count1">{{ item.articulo }}</td>
          <td :rowspan="item.count1">{{ item.articuloFase }}</td>
          <td :rowspan="item.count1">{{ item.area }}</td>
          <td>{{ item.maquinas[0].marca }} {{ item.maquinas[0].modelo }}</td>
          <td>
            <v-autocomplete            
              v-model="item.maquinas[0].idMaquinasIns"
              :items="filtrarMaquinasIns(item.maquinas[0].idMaquina)"
              item-value="idMaquinaIns"
              :item-text="nombreMaquina"
              
              :disabled="loadMaquinasInstancia"
              :loading="loadMaquinasInstancia"
              dense
              
              hide-details
              @change="asignarMaquina(item, item.maquinas[0].idMaquina, item.maquinas[0].idMaquinasIns)"
              
            ></v-autocomplete>
            <!-- <span v-if="item.maquinas[0].fecha == null" v-else>{{ item.maquinas[0].fecha }}</span> -->
          </td>
        </tr>
        <tr v-for="(machine, machineIndex) in item.maquinas" :key="machineIndex" v-if="machineIndex > 0">
          <td>{{ machine.marca }} {{ machine.modelo }}</td>
          <td>
            <v-autocomplete
              v-model="machine.idMaquinasIns"
              :items="filtrarMaquinasIns(machine.idMaquina)"
              item-value="idMaquinaIns"
              :item-text="nombreMaquina"
              
              :disabled="loadMaquinasInstancia"
              :loading="loadMaquinasInstancia"
              
              dense
              hide-details
              @change="asignarMaquina(item, machine.idMaquina, machine.idMaquinasIns)"
              
            ></v-autocomplete>
            <!--v-if="machine.fecha == null" <span v-else>{{ machine.fecha }}</span> -->
          </td>
        </tr>
        </template>
      </tbody>
    </template>
  </v-simple-table>
        </v-card-text>
        <!-- <v-card-actions>
          <v-spacer></v-spacer>
          
          <v-btn
          color="green darken-1"
            text
            @click="asignacionMaquina = false"
          >
            ACEPTAR
          </v-btn>
        </v-card-actions> -->
      </v-card>
    </v-dialog>
    <v-dialog v-model="dialogPorcentajeAvance" persistent max-width="40%">
      <v-card>
        <v-card-title> Porcentaje Mínimo de Avance </v-card-title>
        <v-card-text>
          <PorcentajeAvance
            :porcentajeItem="porcentajeItem"
            :key="`perc-${perkey}`"
          />
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn
              color="success"
              text
              @click="cerrarDialog()"
              >Cerrar</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="dialogOperacionDetalle" max-width="80%" persistent>
      <v-card>
        <v-card-title>Operaciones Realizadas</v-card-title>
        <v-card-text>
          <Operaciones 
            ref="oper"
            :modeDetalle="true"
            :idTrabajoOrden="detallesItem.idTrabajoOrden"
            :idArea="detallesItem.idArea"
            :key="`op-${opkey}`"
          />
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            text
            color="success"
            @click="cerrarDialogOperacionDetalle()"
            >Cerrar</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="detalles" persistent max-width="90%" :key="'d'+detalles.toString()">
      <v-card>
        <v-card-title v-if="!clavePersonalizada">Detalles de {{watchedItem.ot}}</v-card-title>
        <v-card-title v-else>Detalles de la ODT con Clave {{watchedItem.clave}}</v-card-title>
        <v-card-text>
          <v-data-table
            :items="watchedItem.articulos"
            :headers="headersDetalle"
          >
            <template v-slot:item="{ item }">
            <tr>
              <td class="text-center">{{item.nombre}}</td>
              <td :class="'text-center justify-center '+(item.faltantesMP.length == 0?'green  white--text':'yellow black--text')">
                <div v-if="item.faltantesMP.length == 0">
                  Disponible
                </div>
                <div v-else>
                  <v-tooltip bottom color="black" class="white--text">
                    <template v-slot:activator="{ on }">
                      <span v-on="on">Insuficiente</span>
                    </template>
                    <span class="white--text text-left">
                      <ul>
                        <li v-for="(msg, idx) in item.faltantesMP" :key="'faltante'+item.idArticulo+'-'+idx">{{msg}}</li>
                      </ul>
                    </span>
                  </v-tooltip>
                </div>
              </td>
              <td :class="'text-center ' +colorAvance(item.porcentaje)">{{(item.porcentaje || 0).toFixed(2)}} %</td>
              <td class="text-center" v-if="permisoVista('listas','$')">${{ $utils.strings.formatearCifra(item.precioUnitario,2) }}</td>
              <td class="text-center">
                <div v-if="!!item.editarCantidad" class="d-flex align-center justify-space-between">
                  <v-text-field
                    :value="item.cantidad"
                    placeholder="Cantidad"
                    type="number"
                    hide-spin-buttons
                    class="mr-3 centered-input"
                    :class="!!item.errorNuevaCantidad && 'v-input--is-focused'"
                    @input="item.nuevaCantidad = $event"
                    :disabled="!!item.savingNuevaCantidadOTD"
                    :loading="!!item.savingNuevaCantidadOTD"
                    :error="!!item.errorNuevaCantidad"
                    @focus="resetErrorNuevaCantidad(item)"
                  ></v-text-field>
                  <div>
                    <v-tooltip color="black" class="white--text" bottom>
                      <template v-slot:activator="{ on }">
                        <v-icon v-on="on"
                          style="font-size: 1.125rem !important"
                          @click="guardarNuevaCantidadOTD(item)"
                          :disabled="!!item.savingNuevaCantidadOTD"
                          color="success"
                        >
                          mdi-content-save
                        </v-icon>
                      </template>
                      <span class="white--text">Guardar</span>
                    </v-tooltip>
                    <v-tooltip color="black" class="white--text" bottom>
                      <template v-slot:activator="{ on }">
                        <v-icon v-on="on"
                          @click="modoEditarCantidadOTD(item,false)"
                          style="font-size: 1.125rem !important"
                          :disabled="!!item.savingNuevaCantidadOTD"
                          color="error"
                        >
                          mdi-cancel
                        </v-icon>
                      </template>
                      <span class="white--text">Cancelar</span>
                    </v-tooltip>
                  </div>
                </div>
                <div v-if="!!!item.editarCantidad" class="d-flex align-center justify-center">
                  <p class="mb-0 mr-2">{{$utils.strings.formatearCifra(item.cantidad)}}</p>
                  <v-tooltip color="black" class="white--text" bottom>
                    <template v-slot:activator="{ on }">
                      <v-icon v-on="on"
                        v-if="item.estadoProduccion == 0 && permisoVista('listas','u')"
                        @click="modoEditarCantidadOTD(item,true)"
                        style="font-size: 1.125rem !important"
                        color="primary"
                      >
                        mdi-pencil
                      </v-icon>
                    </template>
                    <span class="white--text">Editar</span>
                  </v-tooltip>
                </div>
              </td>
              <td class="text-center" v-if="permisoVista('listas','$')">${{$utils.strings.formatearCifra(item.costoEstimadoTotal,2)}}</td>
              <td class="text-center" v-if="permisoVista('listas','$')">${{$utils.strings.formatearCifra(item.costoEstimado,2)}}</td>
              <td class="text-center" v-if="permisoVista('listas','$')">${{$utils.strings.formatearCifra(item.costoReal,2)}}</td>
              <td class="text-center">{{item.fechaFinalizacion}}</td>
              <td class="text-center">
                <div v-for="(area,idx) in item.areas" :key="'a'+item.idTrabajoOrdenDetalle+'-'+idx" :class="colorAvance(area.porcentaje)">
                  <v-tooltip bottom color="black" class="white--text">
                    <template v-slot:activator="{ on }">
                      <span 
                        v-on="on" 
                        @mouseover="area.hover = true;" 
                        @mouseleave="area.hover = false;"
                        :class="area.hover && 'font-weight-black'"
                        style="cursor: pointer;"
                        @click="showDialogOperacionDetalle(item.idTrabajoOrden,area.idArea)"
                        >{{area.porcentaje.toFixed(2)}}% {{area.area}}</span
                      >
                    </template>
                    <span class="white--text text-left">
                      <ul>
                        <li v-for="(msg, idx) in area.piezas" :key="'avance-'+idx">{{msg}}</li>
                      </ul>
                    </span>
                  </v-tooltip>
                </div>
              </td>
              <td :class="'text-center '+colors2[item.estadoProduccion]+' '+textcolors2[item.estadoProduccion]">
                <div v-if="item.estadoProduccion == 0">
                  <v-icon small>mdi-pause</v-icon>No Iniciado
                  <br>
                  <a v-if="permisoVista('listas','c')" href="#" class="blue--text" @click="launchItemOTD(item)">
                    <v-icon class="blue--text">mdi-rocket</v-icon>
                    Iniciar Producción
                  </a>
                </div>
                <div v-if="item.estadoProduccion == 1"><v-icon color="white" small>mdi-hammer</v-icon>&nbsp;En Progreso</div>
                <div v-if="item.estadoProduccion == 2"><v-icon class="white--text" small>mdi-alert</v-icon>&nbsp;Pausado</div>
                <div v-if="item.estadoProduccion == 3"><v-icon class="white--text" small>mdi-check</v-icon>&nbsp;Completado</div>
              </td>
              <td class="text-center">
                <v-tooltip color="black" class="white--text" bottom>
                  <template v-slot:activator="{ on }">
                    <v-icon v-on="on"
                      v-if="(item.estadoProduccion == 1 || item.estadoProduccion == 2) && permisoVista('listas','u')"
                      small
                      @click="pauseItemOTD(item)"
                    >
                      {{item.estadoProduccion == 1? 'mdi-pause':'mdi-play'}}
                    </v-icon>
                  </template>
                  <span class="white--text">{{item.estadoProduccion == 1? 'Pausar':'Reanudar'}}</span>
                </v-tooltip>
                <v-tooltip color="black" class="white--text" bottom v-if="item.estadoProduccion > 0">
                  <template v-slot:activator="{ on }">
                    <v-icon v-on="on"
                      v-if="permisoVista('listas','u')"
                      small
                      @click="finishEarlyItemOTD(item)"
                    >
                      mdi-close-thick
                    </v-icon>
                  </template>
                  <span class="white--text">Finalizar Prematuramente</span>
                </v-tooltip>
                <v-tooltip color="black" class="white--text" bottom>
                  <template v-slot:activator="{ on }">
                    <v-icon v-on="on"
                      v-if="permisoVista('listas','$')"
                      small
                      @click="desgloseCostosOTD(item)"
                    >
                      mdi-currency-usd
                    </v-icon>
                  </template>
                  <span class="white--text">Desglose de Costos</span>
                </v-tooltip>
                <v-tooltip color="black" class="white--text" bottom>
                  <template v-slot:activator="{ on }">
                    <v-icon v-on="on"
                      v-if="permisoVista('listas','u')"
                      small
                      @click="showDialogPorcentajeAvance(item)"
                    >
                      mdi-percent
                    </v-icon>
                  </template>
                  <span class="white--text">Porcentajes Mínimos de Avance</span>
                </v-tooltip>
                <v-tooltip color="black" class="white--text" bottom v-if="item.porcentaje >= 100">
                  <template v-slot:activator="{ on }">
                    <v-icon v-on="on"
                      v-if="permisoVista('listas','u')"
                      small
                      @click="showDialogFinalizarManualmente(item,2)"
                    >
                      mdi-close-box
                    </v-icon>
                  </template>
                  <span class="white--text">Finalizar Manualmente</span>
                </v-tooltip>
              </td>
            </tr>
            </template>
          </v-data-table>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="closeDetalles()">Cerrar</v-btn>
        </v-card-actions>
        <apex-chart-dialog
          ref="chartDetalles"
        ></apex-chart-dialog>
      </v-card>
    </v-dialog>
    <v-dialog v-model="launch" persistent max-width="800px" :key="'l'+launch.toString()">
      <v-card>
        <v-card-title></v-card-title>
        <v-card-text v-if="verificando" class="text-center">
          <h3>Verificando Materia Prima</h3>
          <v-progress-circular
            :size="75"
            color="green"
            indeterminate
          ></v-progress-circular>
        </v-card-text>
        <v-card-text v-else class="text-center">
          <h3 v-if="!clavePersonalizada">¿Desea Iniciar la Producción de la {{launchedItem.ot}}?</h3>
          <h3 v-else>¿Desea Iniciar la Producción de la ODT con Clave {{launchedItem.clave}}?</h3>
          <!--
          /*
          0: Sin Iniciar
          1: Lanzando
          2: Error
          3: Lanzado
          */
          -->
          <v-row v-if="launchState == 0">
            <v-col v-if="faltaMP" cols="12" md="12" sm="12" class="red--text">
              <h4>Cantidad Insuficiente de Materia Prima para garantizar el cumplimiento de la ODT.</h4>
            </v-col>
            <v-col cols="6" md="6" sm="6">
              <v-btn depressed color="green" @click="launchProduccion()">Si</v-btn>
            </v-col>
            <v-col cols="6" md="6" sm="6">
              <v-btn depressed color="red" @click="closeLaunch()">No</v-btn>
            </v-col>
          </v-row>
          <v-row v-if="launchState == 1 || verificando" class="justify-center">
            <v-col cols="12" md="12" sm="12">
              <h4>Cargando</h4>
            </v-col>
            <v-col cols="12" md="12" sm="12">
              <v-progress-circular
                :size="75"
                color="green"
                indeterminate
              ></v-progress-circular>
            </v-col>
          </v-row>
          <v-row v-if="launchState == 2" class="justify-center">
            <v-col cols="12" md="12" sm="12">
              <h5>Se ha producido un Error. Intente una vez más.</h5>
            </v-col>
            <v-col cols="12" md="12" sm="12">
              <v-btn depressed color="red" text @click="closeLaunch()">Cerrar</v-btn>
            </v-col>
          </v-row>
          <v-row v-if="launchState == 21" class="justify-center">
            <v-col cols="12" md="12" sm="12">
              <h5>Se ha producido un Error.</h5>
              <h6>Los siguientes artículos no tienen al menos una receta de producción</h6>
              <ul>
                <li class="red--text" v-for="(articulo,idx) in recetasFaltantes" :key="'articulo'+idx">{{articulo.codigo}} - {{articulo.nombre}}</li>
              </ul>
            </v-col>
            <v-col cols="12" md="12" sm="12">
              <v-btn depressed color="red" text @click="closeLaunch()">Cerrar</v-btn>
            </v-col>
          </v-row>
          <v-row v-if="launchState == 3" class="justify-center">
            <v-col class="text-center">
              <lottie :options="checkOptions" :height="200" :width="200"/>
            </v-col>
            <v-col cols="12" md="12" sm="12">
              <h5 v-if="!clavePersonalizada">La {{launchedItem.ot}} se ha agregado a Producción Correctamente.</h5>
              <h5 v-els>La ODT con Clave {{launchedItem.clave}} se ha agregado a Producción Correctamente.</h5>
            </v-col>
            <v-col cols="12" md="12" sm="12">
              <v-btn depressed color="green" text @click="closeLaunch()">Aceptar</v-btn>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-dialog v-model="pause" persistent max-width="800px" :key="'p'+pause.toString()">
      <v-card>
        <v-card-title></v-card-title>
        <v-card-text class="text-center">
          <h3 v-if="!clavePersonalizada">¿Desea {{pausedItem.estadoProduccion == 1 ?'Pausar':'Reanudar'}} la Producción de la ODT-{{pausedItem.idTrabajoOrden}}?</h3>
          <h3 v-else>¿Desea {{pausedItem.estadoProduccion == 1 ?'Pausar':'Reanudar'}} la Producción de la ODT con Clave {{pausedItem.clave}}?</h3>
          <!--
          /*
          0: Sin Iniciar
          1: Lanzando
          2: Error
          3: Lanzado
          */
          -->
          <v-row v-if="pauseState == 0" class="justify-center">
            <v-col cols="6" md="6" sm="6">
              <v-btn depressed color="green" @click="launchPause()">Si</v-btn>
            </v-col>
            <v-col cols="6" md="6" sm="6">
              <v-btn depressed color="red" @click="closePause()">No</v-btn>
            </v-col>
          </v-row>
          <v-row v-if="pauseState == 1" class="justify-center">
            <v-col cols="12" md="12" sm="12">
              <h4>Cargando</h4>
            </v-col>
            <v-col cols="12" md="12" sm="12">
              <v-progress-circular
                :size="75"
                color="green"
                indeterminate
              ></v-progress-circular>
            </v-col>
          </v-row>
          <v-row v-if="pauseState == 2" class="justify-center">
            <v-col cols="12" md="12" sm="12">
              <h5>Se ha producido un Error. Intente una vez más.</h5>
            </v-col>
            <v-col cols="12" md="12" sm="12">
              <v-btn depressed color="red" text @click="closePause()">Cerrar</v-btn>
            </v-col>
          </v-row>
          <v-row v-if="pauseState == 3" class="justify-center">
            <v-col class="text-center">
              <lottie :options="checkOptions" :height="200" :width="200"/>
            </v-col>
            <v-col cols="12" md="12" sm="12">
              <h5 v-if="!clavePersonalizada">La ODT-{{pausedItem.idTrabajoOrden}} se ha {{pausedItem.estadoProduccion == 1 ?'Pausado':'Reanudado'}} Correctamente.</h5>
              <h5 v-else>La ODT con Clave {{pausedItem.clave}} se ha {{pausedItem.estadoProduccion == 1 ?'Pausado':'Reanudado'}} Correctamente.</h5>
            </v-col>
            <v-col cols="12" md="12" sm="12">
              <v-btn depressed color="green" text @click="closePause()">Aceptar</v-btn>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-dialog v-model="launchOTD" persistent max-width="800px" :key="'l'+launch.toString()">
      <v-card>
        <v-card-title></v-card-title>
        <v-card-text v-if="verificando" class="text-center">
          <h3>Verificando Materia Prima</h3>
          <v-progress-circular
            :size="75"
            color="green"
            indeterminate
          ></v-progress-circular>
        </v-card-text>
        <v-card-text v-else class="text-center">
          <h3 v-if="!clavePersonalizada">¿Desea Iniciar la Producción del Artículo {{launchedItemOTD.nombre}} de la {{watchedItem.ot}}?</h3>
          <h3 v-else>¿Desea Iniciar la Producción del Artículo {{launchedItemOTD.nombre}} de la ODT con Clave {{watchedItem.clave}}?</h3>
          <!--
          /*
          0: Sin Iniciar
          1: Lanzando
          2: Error
          3: Lanzado
          */
          -->
          <v-row v-if="launchState == 0">
            <v-col v-if="faltaMP" cols="12" md="12" sm="12" class="red--text">
              <h4>Cantidad Insuficiente de Materia Prima para garantizar el cumplimiento de la ODT.</h4>
            </v-col>
            <v-col cols="6" md="6" sm="6">
              <v-btn depressed color="green" @click="launchProduccionOTD()">Si</v-btn>
            </v-col>
            <v-col cols="6" md="6" sm="6">
              <v-btn depressed color="red" @click="closeLaunchOTD()">No</v-btn>
            </v-col>
          </v-row>
          <v-row v-if="launchState == 1 || verificando" class="justify-center">
            <v-col cols="12" md="12" sm="12">
              <h4>Cargando</h4>
            </v-col>
            <v-col cols="12" md="12" sm="12">
              <v-progress-circular
                :size="75"
                color="green"
                indeterminate
              ></v-progress-circular>
            </v-col>
          </v-row>
          <v-row v-if="launchState == 2" class="justify-center">
            <v-col cols="12" md="12" sm="12">
              <h5>Se ha producido un Error. Intente una vez más.</h5>
            </v-col>
            <v-col cols="12" md="12" sm="12">
              <v-btn depressed color="red" text @click="closeLaunchOTD()">Cerrar</v-btn>
            </v-col>
          </v-row>
          <v-row v-if="launchState == 21" class="justify-center">
            <v-col cols="12" md="12" sm="12">
              <h5>Se ha producido un Error.</h5>
              <h6>Los siguientes artículos no tienen al menos una receta de producción</h6>
              <ul>
                <li class="red--text" v-for="(articulo,idx) in recetasFaltantes" :key="'articulo'+idx">{{articulo.codigo}} - {{articulo.nombre}}</li>
              </ul>
            </v-col>
            <v-col cols="12" md="12" sm="12">
              <v-btn depressed color="red" text @click="closeLaunch()">Cerrar</v-btn>
            </v-col>
          </v-row>
          <v-row v-if="launchState == 3" class="justify-center">
            <v-col class="text-center">
              <lottie :options="checkOptions" :height="200" :width="200"/>
            </v-col>
            <v-col cols="12" md="12" sm="12">
              <h5 v-if="!clavePersonalizada">La {{launchedItem.ot}} se ha agregado a Producción Correctamente.</h5>
              <h5 v-els>La ODT con Clave {{launchedItem.clave}} se ha agregado a Producción Correctamente.</h5>
            </v-col>
            <v-col cols="12" md="12" sm="12">
              <v-btn depressed color="green" text @click="closeLaunchOTD()">Aceptar</v-btn>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-dialog v-model="pauseOTD" persistent max-width="800px" :key="'p'+pause.toString()">
      <v-card>
        <v-card-title></v-card-title>
        <v-card-text class="text-center">
          <h3 v-if="!clavePersonalizada">¿Desea {{pausedItemOTD.estadoProduccion == 1 ?'Pausar':'Reanudar'}} la Producción del Artículo {{pausedItemOTD.nombre}} de la ODT-{{watchedItem.idTrabajoOrden}}?</h3>
          <h3 v-else>¿Desea {{pausedItemOTD.estadoProduccion == 1 ?'Pausar':'Reanudar'}} la Producción del Artículo {{pausedItemOTD.nombre}} de la ODT con Clave {{watchedItem.clave}}?</h3>
          <!--
          /*
          0: Sin Iniciar
          1: Lanzando
          2: Error
          3: Lanzado
          */
          -->
          <v-row v-if="pauseState == 0" class="justify-center">
            <v-col cols="6" md="6" sm="6">
              <v-btn depressed color="green" @click="launchPauseOTD()">Si</v-btn>
            </v-col>
            <v-col cols="6" md="6" sm="6">
              <v-btn depressed color="red" @click="closePauseOTD()">No</v-btn>
            </v-col>
          </v-row>
          <v-row v-if="pauseState == 1" class="justify-center">
            <v-col cols="12" md="12" sm="12">
              <h4>Cargando</h4>
            </v-col>
            <v-col cols="12" md="12" sm="12">
              <v-progress-circular
                :size="75"
                color="green"
                indeterminate
              ></v-progress-circular>
            </v-col>
          </v-row>
          <v-row v-if="pauseState == 2" class="justify-center">
            <v-col cols="12" md="12" sm="12">
              <h5>Se ha producido un Error. Intente una vez más.</h5>
            </v-col>
            <v-col cols="12" md="12" sm="12">
              <v-btn depressed color="red" text @click="closePauseOTD()">Cerrar</v-btn>
            </v-col>
          </v-row>
          <v-row v-if="pauseState == 3" class="justify-center">
            <v-col class="text-center">
              <lottie :options="checkOptions" :height="200" :width="200"/>
            </v-col>
            <v-col cols="12" md="12" sm="12">
              <h5 v-if="!clavePersonalizada">La próduccion del Artículo {{pausedItemOTD.nombre}} de La ODT-{{watchedItem.idTrabajoOrden}} se ha {{pausedItemOTD.estadoProduccion == 2 ?'Pausado':'Reanudado'}} Correctamente.</h5>
              <h5 v-else>La Producción del Artículo {{pausedItemOTD.nombre}} de la ODT con Clave {{watchedItem.clave}} se ha {{pausedItemOTD.estadoProduccion == 2 ?'Pausado':'Reanudado'}} Correctamente.</h5>
            </v-col>
            <v-col cols="12" md="12" sm="12">
              <v-btn depressed color="green" text @click="closePauseOTD()">Aceptar</v-btn>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-dialog v-model="dialogFinishEarly" persistent max-width="800px" :key="'fe'+dialogFinishEarly.toString()">
      <v-card>
        <v-card-title></v-card-title>
        <v-card-text class="text-center">
          <h3 v-if="!clavePersonalizada">¿Desea Finalizar Prematuramente la Producción de la ODT-{{finishedEarlyItem.idTrabajoOrden}}?</h3>
          <h3 v-else>¿Desea Finalizar Prematuramente la Producción de la ODT con Clave {{finishedEarlyItem.clave}}?</h3>
          <!--
          /*
          0: Sin Iniciar
          1: Lanzando
          2: Error
          3: Lanzado
          */
          -->
          <v-row v-if="finishedEarlyState == 0" class="justify-center">
            <v-col cols="6" md="6" sm="6">
              <v-btn depressed color="green" @click="launchFinishedEarly()">Si</v-btn>
            </v-col>
            <v-col cols="6" md="6" sm="6">
              <v-btn depressed color="red" @click="closeFinishedEarly()">No</v-btn>
            </v-col>
          </v-row>
          <v-row v-if="finishedEarlyState == 1" class="justify-center">
            <v-col cols="12" md="12" sm="12">
              <h4>Cargando</h4>
            </v-col>
            <v-col cols="12" md="12" sm="12">
              <v-progress-circular
                :size="75"
                color="green"
                indeterminate
              ></v-progress-circular>
            </v-col>
          </v-row>
          <v-row v-if="finishedEarlyState == 2" class="justify-center">
            <v-col cols="12" md="12" sm="12">
              <h5>Se ha producido un Error. Intente una vez más.</h5>
            </v-col>
            <v-col cols="12" md="12" sm="12">
              <v-btn depressed color="red" text @click="closeFinishedEarly()">Cerrar</v-btn>
            </v-col>
          </v-row>
          <v-row v-if="finishedEarlyState == 3" class="justify-center">
            <v-col class="text-center">
              <lottie :options="checkOptions" :height="200" :width="200"/>
            </v-col>
            <v-col cols="12" md="12" sm="12">
              <h5 v-if="!clavePersonalizada">La próduccion de La ODT-{{finishedEarlyItem.idTrabajoOrden}} se ha Finalizado Prematuramente Correctamente.</h5>
              <h5 v-else>La Producción de la ODT con Clave {{finishedEarlyItem.clave}} se ha Finalizado Prematuramente Correctamente.</h5>
            </v-col>
            <v-col cols="12" md="12" sm="12">
              <v-btn depressed color="green" text @click="closeFinishedEarly()">Aceptar</v-btn>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-dialog v-model="dialogFinishEarlyOTD" persistent max-width="800px" :key="'p'+dialogFinishEarlyOTD.toString()">
      <v-card>
        <v-card-title></v-card-title>
        <v-card-text class="text-center">
          <h3 v-if="!clavePersonalizada">¿Desea Finalizar Prematuramente la Producción del Artículo {{finishedEarlyItem.nombre}} de la ODT-{{OTItem.idTrabajoOrden}}?</h3>
          <h3 v-else>¿Desea Finalizar Prematuramente la Producción del Artículo {{finishedEarlyItem.nombre}} de la ODT con Clave {{OTItem.clave}}?</h3>
          <!--
          /*
          0: Sin Iniciar
          1: Lanzando
          2: Error
          3: Lanzado
          */
          -->
          <v-row v-if="finishedEarlyState == 0" class="justify-center">
            <v-col cols="6" md="6" sm="6">
              <v-btn depressed color="green" @click="launchFinishedEarlyOTD()">Si</v-btn>
            </v-col>
            <v-col cols="6" md="6" sm="6">
              <v-btn depressed color="red" @click="closeFinishedEarlyOTD()">No</v-btn>
            </v-col>
          </v-row>
          <v-row v-if="finishedEarlyState == 1" class="justify-center">
            <v-col cols="12" md="12" sm="12">
              <h4>Cargando</h4>
            </v-col>
            <v-col cols="12" md="12" sm="12">
              <v-progress-circular
                :size="75"
                color="green"
                indeterminate
              ></v-progress-circular>
            </v-col>
          </v-row>
          <v-row v-if="finishedEarlyState == 2" class="justify-center">
            <v-col cols="12" md="12" sm="12">
              <h5>Se ha producido un Error. Intente una vez más.</h5>
            </v-col>
            <v-col cols="12" md="12" sm="12">
              <v-btn depressed color="red" text @click="closeFinishedEarlyOTD()">Cerrar</v-btn>
            </v-col>
          </v-row>
          <v-row v-if="finishedEarlyState == 3" class="justify-center">
            <v-col class="text-center">
              <lottie :options="checkOptions" :height="200" :width="200"/>
            </v-col>
            <v-col cols="12" md="12" sm="12">
              <h5 v-if="!clavePersonalizada">La próduccion del Artículo {{finishedEarlyItem.nombre}} de La ODT-{{OTItem.idTrabajoOrden}} ha Finalizado Prematuramente Correctamente.</h5>
              <h5 v-else>La Producción del Artículo {{finishedEarlyItem.nombre}} de la ODT con Clave {{OTItem.clave}} ha Finalizado Prematuramente Correctamente.</h5>
            </v-col>
            <v-col cols="12" md="12" sm="12">
              <v-btn depressed color="green" text @click="closeFinishedEarlyOTD(true)">Aceptar</v-btn>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-dialog v-model="dialogCierreManual" persistent max-width="500px">
      <v-card>
        <v-card-title v-if="cierreManualItem.tipoCierre == 1" style="word-break: break-word;">
          ¿Desea finalizar manualmente la {{ clavePersonalizada ? `ODT con Clave ${cierreManualItem.clave}` : `ODT-${cierreManualItem.idTrabajoOrden}` }}?
        </v-card-title>
        <v-card-title v-else-if="cierreManualItem.tipoCierre == 2" style="word-break: break-word;">
          ¿Desea finalizar manualmente la producción del artículo {{ cierreManualItem.nombre }} de la {{ clavePersonalizada ? `ODT con Clave ${watchedItem.clave}` : `ODT-${watchedItem.ot}` }}?
        </v-card-title>
        <v-card-text>
          <div v-if="cerrandoManualmente" class="text-center">
            <v-progress-circular
              :size="70"
              color="primary"
              indeterminate
            ></v-progress-circular>
            <p class="mb-0 text-subtitle-1" style="font-size: 20px !important">Finalizando</p>
          </div>
          <p v-if="cierreManualItem.error && !cerrandoManualmente" class="mb-0 red--text text-subtitle-1">Ha ocurrido un error. Por favor, intente nuevamente.</p>
          <p v-if="!cierreManualItem.error && !cerrandoManualmente" class="mb-0 text--secondary text-subtitle-1">Esta acción puede demorar algunos minutos.</p>
        </v-card-text>
        <v-card-actions class="d-flex align-center justify-center">
          <v-btn color="success" class="mr-4" :disabled="cerrandoManualmente" @click="closeDialogFinalizarManualmente()">No</v-btn>
          <v-btn color="error" :disabled="cerrandoManualmente" @click="finalizarManualmente()">Si</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="dialogCantidadObjetivo" persistent max-width="70%">
      <v-card>
        <v-card-title>Cantidad Objetivo por Operación</v-card-title>
        <v-card-text>
          <v-container fluid>
            <v-row>
              <v-col cols="12">
                <v-autocomplete
                  v-model="cantidadObjetivoItem.idTrabajoOrdenDetalle"
                  label="Orden de Trabajo Detalle"
                  :items="cantidadObjetivoItem.otDetalles"
                  item-text="nombre"
                  item-value="idTrabajoOrdenDetalle"
                  hide-details
                  clearable
                  :disabled="cantidadObjetivoItem.loading"
                  @change="getOperacionesCantidadObjetivo()"
                ></v-autocomplete>
              </v-col>
              <v-col cols="12">
                <v-data-table
                  :items="cantidadObjetivoItem.data"
                  :headers="cantidadObjetivoHeaders"
                  :loading="cantidadObjetivoItem.loading"
                >
                  <template v-slot:[`item.maquinas`]="{ item }">
                    <p
                      v-for="maquina,idx in item.maquinas"
                      :key="`maquina-${idx}`"
                      :class="{'mb-1': item.maquinas.length > 1, 'mb-0': item.maquinas.length == 1}"
                    >
                      {{ item.maquinas.length > 1 ? '• ' : '' }}{{ maquina }}
                    </p>
                  </template>
                  <template v-slot:[`item.cantidadObjetivo`]="{ item }">
                    <div class="d-flex align-center justify-center" v-if="!!item.modoEditar">
                      <v-text-field
                        v-model="item.cantidadObjetivo"
                        type="number"
                        class="mr-2 centered-input"
                        clearable
                        hide-spin-buttons
                        :disabled="!!item.saving"
                        :loading="!!item.saving"
                        :suffix="item.unidad"
                        :error-messages="item.errorCantidadObjetivo"
                        @focus="$set(item,'errorCantidadObjetivo',null)"
                      ></v-text-field>
                      <v-icon :disabled="!!item.saving" class="mr-2" small color="primary" @click="onClickCancelarCantidadObjetivo(item)">mdi-cancel</v-icon>
                      <v-icon :disabled="!!item.saving" small color="success" @click="onClickSaveCantidadObjetivo(item)">mdi-content-save</v-icon>
                    </div>
                    <div class="d-flex align-center justify-center" v-else>
                      <p class="mb-0 mr-2">{{ item.cantidadObjetivo == null ? "0.00" : item.cantidadObjetivo.toFixed(2) }} {{ item.unidad }}</p>
                      <v-icon small color="primary" @click="onClickEditarCantidadObjetivo(item)">mdi-pencil</v-icon>
                    </div>
                  </template>
                </v-data-table>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            text
            color="success"
            :disabled="cantidadObjetivoItem.loading"
            @click="closeDialogCantidadObjetivo()"
            >Cerrar</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
    <apex-chart-dialog
      ref="chartGlobal"
    ></apex-chart-dialog>
    <!-- <detalles
      :show="dialog"
      @close="closeDialog()"
    ></detalles> -->
    <v-dialog
      v-model="activeDate"
      persistent
      max-width="290"
    >
      <v-card>
        <v-card-title class="text-h5">
          Seleccione una nueva fecha de entrega
        </v-card-title>
        <v-card-text>
          <VueDatePicker
					v-model="parametersdate.fecha"
					no-header
					format="MM-DD-YYYY"
					@onChange="cambioFechaCompromiso"
				>
					<template #activator>
						<v-text-field
							v-model="parametersdate.fechaCompromisoLabel"
							readonly
							ref="activator"
							hide-details
						></v-text-field>
					</template>
				</VueDatePicker>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
          color="blue darken-1"
            text
            @click="activeDate = false"
          >
            Cancelar
          </v-btn>
          <v-btn
          color="red darken-1"
            text
            @click="SenDataDate()"
          >
            aceptar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="eliminar" persistent max-width="500px" :key="eliminar">
      <v-card>
        <v-card-title class="headline">¿Está seguro que desea eliminar esta ODT?</v-card-title>
        <v-card-text>Esta acción no se puede revertir y será permanente.</v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="eliminar = false; deletedItem = null;">Cancelar</v-btn>
          <v-btn color="red darken-1" text @click="eliminar = false; deleteItemDB()">Aceptar</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    
    <v-row class="pa-0 ma-0">
      <v-col :cols="nColsCard" :md="nColsCard" :sm="nColsCard">
        <v-switch v-model="switch1" :label="`Calcular Finalización con Optimización`" @change="cambiarEstado()" ></v-switch>
      </v-col>
    </v-row>

    <v-row
      class="pa-0 ma-0"
      v-if="permisoVista('listas','r')"
    >
      <v-col :cols="nColsCard" :md="nColsCard" :sm="nColsCard">
        <v-card class="fill-height d-flex">
          <v-card-text class="text-center align-self-center">
            <div class="subtitle-1 font-weight-bold">
              # ODT Producción
            </div>
            <ICountUp
              v-if="!loadingEstadisticas"
              :delay="1000"
              :endVal="estadisticas.numOTS"
              class="subtitle-1 font-weight-medium"
            />
            <div v-else class="text-center">
              <v-progress-circular
                :size="25"
                color="primary"
                indeterminate
              ></v-progress-circular>
            </div>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col :cols="nColsCard" :md="nColsCard" :sm="nColsCard" v-if="permisoVista('listas','$')">
        <v-card class="fill-height d-flex">
          <v-card-text class="text-center align-self-center">
            <div class="subtitle-1 font-weight-bold">
              $ Monto Estimado de la Producción
            </div>
            <ICountUp
              v-if="!loadingEstadisticas"
              :delay="1000"
              :endVal="estadisticas.montoTotalEstimadoProduccion"
              class="subtitle-1 font-weight-medium"
              :options="{
                decimalPlaces: 2,
                separator: ',',
                decimal: '.',
                prefix:'$'
              }"
            />
            <div v-else class="text-center">
              <v-progress-circular
                :size="25"
                color="primary"
                indeterminate
              ></v-progress-circular>
            </div>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col :cols="nColsCard" :md="nColsCard" :sm="nColsCard" v-if="permisoVista('listas','$')">
        <v-card class="fill-height d-flex">
          <v-card-text class="text-center align-self-center">
            <div class="subtitle-1 font-weight-bold">
              $ Monto Real de la Producción
            </div>
            <ICountUp
              v-if="!loadingMontoReal"
              :delay="1000"
              :endVal="montoTotalRealProduccion"
              class="subtitle-1 font-weight-medium"
              :options="{
                decimalPlaces: 2,
                separator: ',',
                decimal: '.',
                prefix:'$'
              }"
            />
            <div v-else class="text-center">
              <v-progress-circular
                :size="25"
                color="primary"
                indeterminate
              ></v-progress-circular>
            </div>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col :cols="nColsCard" :md="nColsCard" :sm="nColsCard">
        <v-card class="fill-height d-flex">
          <v-card-text class="text-center align-self-center">
            <div class="subtitle-1 font-weight-bold">
              % Total de Avance
            </div>
            <ICountUp
              v-if="!loadingEstadisticas"
              :delay="1000"
              :endVal="estadisticas.porcentajeTotalProduccion"
              class="subtitle-1 font-weight-medium"
              :options="{
                decimalPlaces: 2,
                decimal: '.',
                suffix: '%'
              }"
            />
            <div v-else class="text-center">
              <v-progress-circular
                :size="25"
                color="primary"
                indeterminate
              ></v-progress-circular>
            </div>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <MateriasPrimasConsumidas ref="rawMaterial" />
    <OrdenarAbiertas ref="ordenar" @reset="initialize" />
    <AccionesMasivas ref="MasiveAcctions" @reset="initialize" />
    <ModificarSubpasos 
      ref="modsubs"
      :idTrabajoOrden="detallesItem.idTrabajoOrden" 
      @cerrar="cerrarDialogModificarSubpasos()" 
    />
    <v-data-table
      v-if="permisoVista('listas','r')"
      :headers="visibleHeaders"
      :items="trabajoOrdenes"
      :loading="loading"
      loading-text="Cargando... Espere, Por Favor."
      class="elevation-0"
      ref="ots"
      :server-items-length="abiertas_total"
			:items-per-page="abiertas_items_per_page"
			:page="abiertas_actual_page"
			@update:page="abiertas_update_page_action"
			@update:items-per-page="(ipp) => (abiertas_items_per_page = ipp)"
			:footer-props="{
				disableItemsPerPage: true,
				disablePagination: this.loading,
			}"
			:disable-sort="true"
			:disable-pagination="loading"
      hide-default-footer
    >
      <template v-slot:top>
        <v-toolbar flat color="white">
          <!-- <v-btn color="primary" dark class="mb-2" @click="dialog = true;" v-if="permisoVista('listas','c')">Nueva Orden de Trabajo</v-btn> -->
          <v-btn
            :disabled="loading"
            color="primary"
            class="mb-2"
            v-if="permisoVista('listas', 'u')"
            @click="mostrarDialogOrdenar()"
          >
            <v-icon left>mdi-sort</v-icon>Ordenar
          </v-btn>
          <v-btn
            :disabled="loading"
            color="primary"
            class="mb-2"
            v-if="permisoVista('listas', 'u')"
            @click="mostrarDialogAccionesMasivas()"
          >
            <v-icon left>mdi-checkbox-multiple-marked-outline</v-icon>Acciones Masivas
          </v-btn>
          <v-btn :disabled="loading" color="primary" @click="asignacionMaquina = true">Asignacion de maquinas</v-btn>
          <v-btn :disabled="loading" color="primary" @click="reportegeneralpedidosdialog = true">Reporte General de Pedidos</v-btn>
          <!--Comienzo del select para el filtro de estados-->
          <v-select  class="mt-7 ml-2" style="width: 200px"  
            :items="filtroEstado"
            v-model="idEstado"
            item-text="text"
            item-value="value"
            label="Estado"
            @change="abiertas_update_page_action(1)"
            clearable
            dense
          ></v-select> <!--Fin del filtro de estado-->
          <v-spacer></v-spacer>
          <v-text-field
            v-model="search"
            append-icon="search"
            label="Buscar"
            @keyup="getTrabajoOrdenesOnEnterAction"
            @click:clear="clearSearchAction"
            @click:append="abiertas_update_page_action(1)"
            :clearable="true"
            :disabled="loading"
            single-line
            hide-details
          ></v-text-field>
        </v-toolbar>
        <div class="text-right">
          <v-btn text color="primary" class="ml-auto" @click="show_SeleccionHeaders()">
            <v-icon small>mdi-filter-outline</v-icon>
            Mostrar / Ocultar Columnas
          </v-btn>
        </div>
      </template>
      <template v-slot:item="{ item }">
        <tr :key="itemKey(item)">
          <td class="text-center" v-if="visibleHeaders.some(x => x.value == 'ot')">
            {{clavePersonalizada?item.clave:item.ot}}
          </td>
          <td class="text-center" v-if="visibleHeaders.some(x => x.value == 'lineaComercial')">
            {{item.lineaComercial}}
          </td>
          <td class="text-center" v-if="visibleHeaders.some(x => x.value == 'cliente')">
            {{item.cliente}}
          </td>
          <td class="text-center" v-if="permisoVista('listas','$') && visibleHeaders.some(x => x.value == 'costoEstimadoTotal')">
            $ {{ $utils.strings.formatearCifra(item.costoEstimadoTotal,2) }}
          </td>
          <td class="text-center" v-if="permisoVista('listas','$') && visibleHeaders.some(x => x.value == 'costoEstimado')">
            $ {{ $utils.strings.formatearCifra(item.costoEstimado,2) }}
          </td>
          <td class="text-center" v-if="permisoVista('listas','$') && visibleHeaders.some(x => x.value == 'costoReal')">
            $ {{ $utils.strings.formatearCifra(item.costoReal,2) }}
          </td>
          <td class="text-center" v-if="visibleHeaders.some(x => x.value == 'costoReposicion')">
            <div v-if="item.costoReposicion">$ {{ $utils.strings.formatearCifra(item.costoReposicion,2) }}</div>
            <div v-else>$ 0.00</div>
          </td>
          <td :class="'text-center ' +colorAvance(item.porcentajeCompletado)" v-if="visibleHeaders.some(x => x.value == 'porcentajeCompletado')">
            {{(item.porcentajeCompletado || 0).toFixed(2)}}%
          </td>
          <td class="text-center" v-if="visibleHeaders.some(x => x.value == 'fechaInicioDeProduccion')">
            <div v-if="item.fechaInicioDeProduccion != null">
              {{item.fechaInicioDeProduccion}}
            </div>
            <div v-else>
              No Disponible
            </div>
          </td>
          <td class="text-center" v-if="visibleHeaders.some(x => x.value == 'fecha')">
            <div v-if="item.fechaFinalizacion != null">
              {{item.fechaFinalizacion}}
            </div>
            <div v-else>
              No Disponible
            </div>
          </td>
          <td class="text-center" v-if="visibleHeaders.some(x => x.value == 'fechaEntrega')">
            <!-- {{item.fechaEntrega}}  -->
            <span >{{ item.fechaEntrega }}</span>
            <!-- <v-btn icon @click="showDialogDate(item.idTrabajoOrden, item.fechaEntrega)" > <v-icon>mdi-pencil</v-icon> </v-btn> -->
            <v-icon
										v-on="on"
										small
										class="ml-2 mb-1"
										@click="showDialogDate(item.idTrabajoOrden, item.fechaEntrega)"
									>
                  mdi-pencil
									</v-icon>
          </td>
          <td :class="'text-center '+colors1[item.disponibilidadMP]+' '+textcolors1[item.disponibilidadMP]" v-if="visibleHeaders.some(x => x.value == 'disponibilidadMP')">
            <div v-if="item.disponibilidadMP == 0">
              <v-tooltip left color="black" class="white--text">
                <template v-slot:activator="{ on }">
                  <span v-on="on">Insuficiente</span>
                </template>
                <span class="white--text text-left">
                  <ul>
                    <li v-for="(msg, idx) in item.faltantesMP" :key="'faltante'+item.idArticulo+'-'+idx">{{msg}}</li>
                  </ul>
                </span>
              </v-tooltip>
            </div>
            <div v-else-if="item.disponibilidadMP == 1" class="white--text"> Disponible</div>
            <div v-else-if="item.disponibilidadMP == 2" class="white--text"> No Disponible</div>
            <div v-else>Cargando</div>
          </td>
          <td :class="'text-center '+colors2[item.estadoProduccion]+' '+textcolors2[item.estadoProduccion]" v-if="visibleHeaders.some(x => x.value == 'estadoProduccion')">
            <div v-if="item.lanzando == true">
              <v-icon class="red--text">mdi-rocket-launch</v-icon>&nbsp;Lanzando a Producción
            </div>
            <div v-else-if="item.estadoProduccion == 0">
              <div v-if="item.articulos.length > 0">
                <v-icon small >mdi-pause</v-icon>No Iniciado
                <br>
                <a v-if="permisoVista('listas','c')" href="#" class="blue--text" @click="launchItem(item)">
                  <v-icon  class="blue--text">mdi-rocket</v-icon>
                  Iniciar Producción
                </a> 
              </div>
              <div v-else>
                <v-icon disabled small >mdi-pause</v-icon>No Iniciado
                <br>
                <a v-if="permisoVista('listas','c')" class="blue--text">
                  <v-icon disabled class="blue--text">mdi-rocket</v-icon>
                  Iniciar Producción
                </a> 
              </div>
            </div>
            <div v-else-if="item.estadoProduccion == 1"><v-icon color="white" small>mdi-hammer</v-icon>&nbsp;En Progreso</div>
            <div v-else-if="item.estadoProduccion == 2"><v-icon class="white--text" small>mdi-alert</v-icon>&nbsp;Pausado</div>
            <div v-else-if="item.estadoProduccion == 3"><v-icon class="white--text" small>mdi-check</v-icon>&nbsp;Completado</div>
            <div v-else-if="item.estadoProduccion == 4"><v-icon class="white--text" small>mdi-circle-half-full</v-icon>&nbsp;Producción Parcial</div>
            
          </td>
          <td class="text-center">
            <v-tooltip bottom color="black" class="white--text">
              <template v-slot:activator="{ on }">
                <v-icon v-on="on"
                  v-if="(item.estadoProduccion == 1 || item.estadoProduccion == 2) && permisoVista('listas','u')"
                  small
                  @click="pauseItem(item)"
                  class="mr-2"
                >
                  {{item.estadoProduccion == 1? 'mdi-pause':'mdi-play'}}
                </v-icon>
              </template>
              <span class="white--text">{{item.estadoProduccion == 1? 'Pausar':'Reanudar'}}</span>
            </v-tooltip>
            <v-tooltip bottom color="black" class="white--text" v-if="item.estadoProduccion == 1">
              <template v-slot:activator="{ on }">
                <v-icon v-on="on"
                   v-if="permisoVista('listas','u')"
                  small
                  @click="finishEarlyItem(item)"
                  class="mr-2"
                >
                  mdi-close-thick
                </v-icon>
              </template>
              <span class="white--text">Finalizar Prematuramente</span>
            </v-tooltip>
            <v-tooltip bottom color="black" class="white--text">
              <template v-slot:activator="{ on }">
                <v-icon v-on="on"
                  v-if="permisoVista('listas','r')"
                  small
                  @click="watchItem(item)"
                  class="mr-2"
                >
                  mdi-eye
                </v-icon>
              </template>
              <span class="white--text">Ver detalles</span>
            </v-tooltip>
            <v-tooltip bottom color="black" class="white--text">
              <template v-slot:activator="{ on }">
                <v-icon v-on="on"
                  v-if="permisoVista('listas','d')"
                  small
                  @click="deleteItem(item)"
                  class="mr-2"
                >
                  delete
                </v-icon>
              </template>
              <span class="white--text">Eliminar</span>
            </v-tooltip>
            <v-tooltip color="black" class="white--text" bottom>
              <template v-slot:activator="{ on }">
                <v-icon v-on="on"
                  v-if="permisoVista('listas','$')"
                  small
                  @click="desgloseCostosOT(item)"
                  class="mr-2"
                >
                  mdi-currency-usd
                </v-icon>
              </template>
              <span class="white--text">Desglose de Costos</span>
            </v-tooltip>
            <v-tooltip color="black" class="white--text" bottom>
              <template v-slot:activator="{ on }">
                <v-icon v-on="on"
                  v-if="permisoVista('listas','u')"
                  small
                  @click="showModificarSubpasos(item)"
                  class="mr-2"
                >
                  mdi-shuffle-variant
                </v-icon>
              </template>
              <span class="white--text">Modificar Subpasos</span>
            </v-tooltip>
            <v-tooltip color="black" class="white--text" bottom v-if="item.estadoProduccion > 0">
              <template v-slot:activator="{ on }">
                <v-icon v-on="on"
                  v-if="permisoVista('listas','r')"
                  small
                  @click="showDialogMateriasPrimas(item)"
                  class="mr-2"
                >
                  mdi-warehouse
                </v-icon>
              </template>
              <span class="white--text">Materias Primas<br>Consumidas</span>
            </v-tooltip>
            <v-tooltip color="black" class="white--text" bottom v-if="cantidadObjetivoQR">
              <template v-slot:activator="{ on }">
                <v-icon v-on="on"
                  v-if="permisoVista('listas','u')"
                  small
                  class="mr-2"
                  @click="showDialogCantidadObjetivo(item)"
                >
                  mdi-bullseye-arrow
                </v-icon>
              </template>
              <span class="white--text">Cantidad Objetivo</span>
            </v-tooltip>
            <v-tooltip color="black" class="white--text" bottom v-if="item.porcentajeCompletado >= 100">
              <template v-slot:activator="{ on }">
                <v-icon v-on="on"
                  v-if="permisoVista('listas','u')"
                  small
                  @click="showDialogFinalizarManualmente(item,1)"
                >
                  mdi-close-box
                </v-icon>
              </template>
              <span class="white--text">Finalizar Manualmente</span>
            </v-tooltip>
            <v-tooltip color="black" class="white--text" bottom>
              <template v-slot:activator="{ on }">
                <v-icon v-on="on" small @click="showCostosDeReposicion(item,1)">
                  mdi-store
                </v-icon>
              </template>
              <span class="white--text">Costos de Reposición</span>
            </v-tooltip>
            <v-tooltip color="black" class="white--text" bottom>
              <template v-slot:activator="{ on }">
                <v-icon v-on="on" small @click="showDialogPedidos(item)">
                  mdi-package
                </v-icon>
              </template>
              <span class="white--text">Pedidos</span>
            </v-tooltip>
            <v-tooltip color="black" class="white--text" bottom> <!--Agregado para la impresión de hoja viajera-->
              <template v-slot:activator="{ on }">
                <v-icon v-on="on" small @click="showDialogImpresion(item)">
                  mdi-arrow-down
                </v-icon>
              </template>
              <span class="white--text">Impresión por Áreas</span>
            </v-tooltip>
          </td>
        </tr>
      </template>
      <template v-slot:no-data>
        <v-btn color="primary" @click="initialize">Refrescar</v-btn>
      </template>
      <template v-slot:footer="{ props }">
        <v-col cols="12" class="d-flex justify-end align-center border-footer">
          <p class="mb-0 mr-8" style="font-size: 12px !important">Filas por página:</p>
          <v-text-field
            :value="props.pagination.itemsPerPage"
            dense
            hide-details
            disabled
            class="centered-input footer-input mt-0 mr-10"
          ></v-text-field>
          <p class="mb-0 mr-10" style="font-size: 12px !important">
            {{props.pagination.itemsLength ? getPageText(props.pagination): '-'}}
          </p>
          <v-pagination
            v-model="abiertas_actual_page"
            :length="props.pagination.pageCount"
            :total-visible="7"
            :disabled="loading"
          ></v-pagination>
        </v-col>
      </template>
    </v-data-table>
    <v-snackbar
      v-model="alerta"
      top
      color="error"
    >
      Existe una Área Activa con el mismo nombre
      <v-btn
        text
        @click="alerta = false"
      >
        Cerrar
      </v-btn>
    </v-snackbar>

    <CostosReposicion ref="CostosReposicion"/>

    <SeleccionHeadersVue ref="seleccionHeaders" v-model="visibleHeaders" :headers="headers" path="ots_abierto"/>
    <v-dialog
      v-model="dialogPedidos"
      persistent
      max-width="980"
      @input="handleDialogChange"
    >
      <v-card>
        <v-card-title class="text-h5">
          Pedidos semana
        </v-card-title>
        <v-card-text v-if="dialogPedidos">
          <v-row>
            <!-- <v-col cols="6" class="pt-0">
              <datePicker
                v-model="filtros.desde"
                format="YYYY-MM-DD"
                :maxDate="fin"
                label="Desde (Obligatorio)"
                clearable
                maxToday
                @input="getAreas(IdOrdenTrabajo)"
                :disabled="loading"
              ></datePicker>
            </v-col>
            <v-col cols="6" class="pt-0">
              <datePicker
                v-model="filtros.hasta"
                :minDate="filtros.desde"
                format="YYYY-MM-DD"
                label="Hasta (Obligatorio)"
                clearable
                maxToday
                @input="getAreas(IdOrdenTrabajo)"
                :disabled="loading"
              ></datePicker>
            </v-col> -->
            <v-col cols="12">
              <Pedidos :IdOrdenTrabajo="idOrdenTrabajo" :key="dialogPedidos" ></Pedidos>
            </v-col>
          </v-row>
          
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <!-- <DownloadBtn
          text
          color="primary"
          className="my-0"
          @click="report"
          label="Descargar Reporte MP<br/>Semanal"
		  :disabled="filtros.desde == null || filtros.hasta == null ? true : false"
        /> -->
          <v-btn
            color="red darken-1"
            text
            @click="dialogPedidos = false"
          >
            Cerrar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="reportegeneralpedidosdialog"
      persistent
      max-width="500"
    >
      <v-card>
        <v-card-title class="text-h5">
          Descargar reporte general de pedidos
        </v-card-title>
        <v-card-text>
          <v-row>
            <v-col cols="6">
              <datePicker
                v-model="filtros.desde"
                format="YYYY-MM-DD"
                :maxDate="fin"
                label="Desde (Obligatorio)"
                clearable
                
                :disabled="loading"
              ></datePicker>
            </v-col>
            <v-col cols="6">
              <datePicker
                v-model="filtros.hasta"
                :minDate="filtros.desde"
                format="YYYY-MM-DD"
                label="Hasta (Obligatorio)"
                clearable
                
                :disabled="loading"
              ></datePicker>
            </v-col>
            <v-col cols="12">
                <DownloadBtn
                  text
                  color="primary"
                  className="my-0"
                  @click="report"
                  label="Descargar Reporte MP<br/>Semanal"
                  :disabled="filtros.desde == null || filtros.hasta == null ? true : false"
                  :retryTime="20"
            />
            </v-col>
            </v-row>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="red darken-1"
            text
            @click="cerrarDialogPedidos()"
            :disable="loading"
          >
            Cerrar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="dialogImpresion"
      persistent
      max-width="500"
    >
      <v-card>
        <v-card-title class="text-h5">
          Archivo de impresión OT por área
        </v-card-title>
        <span class="ml-4">Seleccione el área o deje en blanco para imprimir todas</span>
            <v-container>
              <v-row>
                <v-col cols="12" md="6" sm="6" class="ml-2">
                  <v-autocomplete
                    v-model="impresionHoja.idArea"
                    :items="areas"
                    item-text="area"
                    item-value="idArea"
                    label="Área"
                    multiple
                    clearable 
                    hide-details
                  ></v-autocomplete>
                </v-col>
                <v-col cols="4" md="4" sm="2">
                  <Download-btn
                    :retryTime="20"
                    text
                    label="Generar Archivo"
                    color="blue darken-1"
                    @click="descargarArchivo"
                  ></Download-btn>
                </v-col>
              </v-row>
            </v-container>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              color="red darken-1"
              text
              @click="cerrarDialogImpresion()"
            >
              Cerrar
            </v-btn>
          </v-card-actions>  
      </v-card>  
    </v-dialog> <!--Para la descarga de impresión por áreas-->
  </div>
</template>

<script>
import axios from "axios";
import moment from "moment";
import detalles from "./Abiertos/Detalles";
import { mapState } from 'vuex';
import Lottie from 'vue-lottie';
import * as checkData from '@/assets/check.json';
import {Howl, Howler} from 'howler';
import Sortable from 'sortablejs';
import ICountUp from 'vue-countup-v2';
import apexChartDialog from '@/components/ApexChartDialog';
import OrdenarAbiertas from './OrdenarAbiertas.vue';
import AccionesMasivas from './AccionesMasivas.vue';
import Operaciones from '../../Reportes/Operaciones.vue';
import PorcentajeAvance from './PorcentajeAvance.vue';
import ModificarSubpasos from './ModificarSubpasos.vue';
import MateriasPrimasConsumidas from './MateriasPrimasConsumidas.vue';
import { VueDatePicker } from "@mathieustan/vue-datepicker";

import CostosReposicion from './CostosReposicion.vue'
import SeleccionHeadersVue from '@/components/SeleccionHeaders.vue'
import DownloadBtn from "../../../DownloadBtn.vue"
import Pedidos from './Pedidos.vue'
import { index } from "d3";

export default {
   components: {
      detalles: detalles,
      'lottie': Lottie,
      ICountUp,
      'apex-chart-dialog': apexChartDialog,
      OrdenarAbiertas,
      Operaciones,
      PorcentajeAvance,
      ModificarSubpasos,
      MateriasPrimasConsumidas,
      AccionesMasivas,
      DownloadBtn,
      CostosReposicion,
      SeleccionHeadersVue,
      VueDatePicker,
      Pedidos
    },
    data: () => ({
      reportegeneralpedidosdialog: false,
      descargareporteen:false,
      idEstado: null, //Para el filtro
      //Items usados en el filtro de estados
      noNave: true,
      filtroEstado: [
        {text: 'No Iniciado', value: 0},
        {text: 'Procesando', value: 1},
        {text: 'Pausado', value: 2},
        {text: 'Iniciado Parcialmente', value: 4},
      ],  

      filtros: {
        desde: null,
        hasta: null,
      },
      maxDate: null,
      idOrdenTrabajo:null,
      dialogPedidos: false,
      switch1: true,
      activeDate: false,
      parametersdate:{
        idTrabajoOrden: null,
        fecha: null,
        fechaCompromisoLabel:null
      },
      loadAsignacionMaquinas: false ,
      asignacionMaquina: false,
      headersAsignacion:[
        { text: 'OT', value: 'ot' },
        { text: 'Articulo', value: 'articulo' },
        { text: 'Articulo Fase', value: 'articuloFase' },
        { text: 'Area', value: 'area' },
        { text: 'Maquina', value: 'maquina' },
        { text: 'Maquina Instancia', value: 'idMaquinaIns' }
      ],
      datosAsignacion:[],
      insMaquinas:[],
      maquinasIntancias:[],
      loadMaquinasInstancia: false,
      cantidadObjetivoHeaders: [
        {
          text: "Artículo",
          align: "center",
          value: "articulo"
        },
        {
          text: "Área",
          align: "center",
          value: "area"
        },
        {
          text: "Máquina",
          align: "center",
          value: "maquinas"
        },
        {
          text: "Cantidad a producir",
          align: "center",
          value: "cantidad"
        },
        {
          text: "Cantidad Objetivo",
          align: "center",
          value: "cantidadObjetivo"
        }
      ],
      cantidadObjetivoItem: {
        idTrabajoOrden: null,
        idTrabajoOrdenDetalle: null,
        data: [],
        otDetalles: [],
        loading: false,
      },
      defaultCantidadObjetivoItem: {
        idTrabajoOrden: null,
        idTrabajoOrdenDetalle: null,
        data: [],
        otDetalles: [],
        loading: false,
      },
      dialogCantidadObjetivo: false,
      cerrandoManualmente: false,
      dialogCierreManual: false,
      cierreManualItem: {
        idTrabajoOrden: null,
        idTrabajoOrdenDetalle: null,
        clave: null,
        tipoCierre: null,
        nombre: null,
        error: false
      },
      defaultCierreManualItem: {
        idTrabajoOrden: null,
        idTrabajoOrdenDetalle: null,
        clave: null,
        tipoCierre: null,
        nombre: null,
        error: false
      },
      perkey: 0,
      porcentajeItem: -1,
      dialogPorcentajeAvance: false,
      detallesItem: {
        idTrabajoOrden: 0,
        idArea: 0,
      },
      defaultDetallesItem: {
        idTrabajoOrden: 0,
        idArea: 0,
      },
      opkey: 0,
      dialogOperacionDetalle: false,
      loadingMontoReal: false,
      montoTotalRealProduccion: 0.0,
      abiertas: [],
      //Paginacion abiertas y filtros (server side)
      abiertas_total: null,
      //abiertas_items_per_page: 10,
      abiertas_actual_page: 1,
      //
      itemKeys: new WeakMap(),
      currentItemKey: 0,
      launch: false,
      launchState: 0,
      launchedItem: {
        idTrabajoOrden: -1,
        disponibilidadProducto: 0,
        disponibilidadMP: 0,
        estadoProducion: 0,
        articulos:[],
      },
      colors1:['yellow','green','red'],
      textcolors1:[,'black--text','white--text'],
      colors2:['','orange','red','green','blue'],
      textcolors2:['','white--text','white--text','white--text','white--text'],
      expanded: [],
      checkOptions: {animationData: checkData.default, loop:false, speeed: 0.5},
      saving: false,
      dialog: false,
      alerta: false,
      eliminar: false,
      search: '',
      estado: 0, //Usado para el filtro
      headers: [
        {
          text: 'ODT',
          align: 'center',
          sortable: false,
          value: 'ot',
        },
        {
          text: 'Línea Comercial',
          align: 'center',
          sortable: false,
          value: 'lineaComercial'
        },
        {
          text: 'Cliente',
          align: 'center',
          sortable: false,
          value: 'cliente'
        },
        {
          text: 'Costo Estimado Total',
          align: 'center',
          sortable: true,
          value: 'costoEstimadoTotal'
        },
        {
          text: 'Costo Estimado Actual',
          align: 'center',
          sortable: true,
          value: 'costoEstimado'
        },
        {
          text: 'Costo Real',
          align: 'center',
          sortable: false,
          value: 'costoReal'
        },
        {
          text: 'Costo Reposición',
          align: 'center',
          sortable: false,
          value: 'costoReposicion'
        },
        {
          text: "% Completado",
          align: 'center',
          sortable: false,
          value: 'porcentajeCompletado'
        },
        {
          text: "Fecha de Inicio de Producción",
          align: 'center',
          sortable: false,
          value: 'fechaInicioDeProduccion'
        },
        {
          text: 'Fecha de Finalización',
          align: 'center',
          sortable: false,
          value: 'fecha',
        },
        {
          text: 'Fecha Compromiso',
          align: 'center',
          sortable: false,
          value: 'fechaEntrega'
        },
        {
          text: 'M.P.',
          align: 'center',
          sortable: false,
          value: 'disponibilidadMP'
        },
        {
          text: 'Producción',
          align: 'center',
          sortable: false,
          value: 'estadoProduccion'
        },
        { text: 'Acciones', value: 'action', sortable: false, align:'center'},
      ],
      headersDetalle: [
        {
          text: 'Artículo',
          align: 'center',
          sortable: true,
          value: 'nombre',
          width:'10%'
        },
        {
          text: 'M.P.',
          align: 'center',
          sortable: false,
          width:'10%'
        },
        {
          text: '% Completado',
          align:'center',
          sortable: true,
          value: 'porcentaje',
          width:'8%'
        },
        {
          text: 'Precio Unitario',
          align: 'center',
          sortable: true,
          value: 'precioUnitario',
          width:'8%'
        },
        {
          text: 'Cantidad',
          align: 'center',
          sortable: true,
          value: 'cantidad',
          width:'8%'
        },
        {
          text: 'Costo Estimado Total',
          align: 'center',
          sortable: true,
          value: 'costoEstimadoTotal',
          width:'10%'
        },
        {
          text: 'Costo Estimado',
          align: 'center',
          sortable: true,
          value: 'costoEstimado',
          width:'10%'
        },
        {
          text: 'Costo Real',
          align: 'center',
          sortable: true,
          value: 'costoReal',
          width:'10%'
        },
        {
          text: 'Fecha de Finalización',
          align: 'center',
          sortable: true,
          value: 'fechaFinalizacion',
          width:'8%'
        },
        {
          text: 'Áreas de Trabajo',
          align: 'center',
          sortable: true,
          value: 'areas'
        },
        {
          text: 'Producción',
          align: 'center',
          sortable: false,
          value: 'estadoProduccion'
        },
        {
          text: 'Acciones',
          align: 'center',
          sortable: false,
          value: 'actions'
        },
      ],
      headersDesglose:[
        {
          text: 'Concepto',
          align: 'center',
          sortable: true,
          value: 'concepto',
          width:'40%'
        },
        {
          text: 'Tiempo',
          align: 'center',
          sortable: true,
          value: 'tiempo',
          width:'20%'
        },
        {
          text: 'Cantidad',
          align: 'center',
          sortable: true,
          value: 'cantidad',
          width:'20%'
        },
        {
          text: 'Costo',
          align: 'rigth',
          sortable: true,
          value: 'costoTotal',
          width:'20%'
        },
      ],
      loading: false,
      deletedItem: {},
      defaultItem: {
        idTrabajoOrden: -1,
        disponibilidadProducto: 0,
        disponibilidadMP: 0,
        estadoProducion: 0,
        articulos:[],
        costosGrafica:{
          series:[]
        }
      },
      estadisticas:{
        numOTS: 0,
        montoTotalEstimadoProduccion: 0.0,
        porcentajeTotalProduccion: 0.0,
      },
      detalles: false,
      trabajoOrdenes: [],
      sound: null,
      verificando:true,
      faltaMP: false,
      pauseState: 0,
      pause:false,
      pausedItem: {
        idTrabajoOrden: -1,
        disponibilidadProducto: 0,
        disponibilidadMP: 0,
        estadoProducion: 0,
        articulos:[],
      },
      pauseOTD:false,
      launchOTD:false,
      launchedItemOTD:{
        areas: [],
        cantidad: 0,
        costo: 0,
        estadoProduccion: 0,
        faltantesMP: [],
        fechaFinalizacion: null,
        idArticulo: 0,
        idTrabajoOrdenDetalle: 0,
        montoTotal: 0,
        nombre: "",
        porcentaje: 0.0,
        precioUnitario: 0.0,
      },
      pausedItemOTD:{
        areas: [],
        cantidad: 0,
        costo: 0,
        estadoProduccion: 0,
        faltantesMP: [],
        fechaFinalizacion: null,
        idArticulo: 0,
        idTrabajoOrdenDetalle: 0,
        montoTotal: 0,
        nombre: "",
        porcentaje: 0.0,
        precioUnitario: 0.0,
      },
      defaultItemOTD:{
        areas: [],
        cantidad: 0,
        costo: 0,
        estadoProduccion: 0,
        faltantesMP: [],
        fechaFinalizacion: null,
        idArticulo: 0,
        idTrabajoOrdenDetalle: 0,
        montoTotal: 0,
        nombre: "",
        porcentaje: 0.0,
        precioUnitario: 0.0,
        desgloseCostosEstimados: null,
        desgloseCostosReales: null,
        costosGrafica:{
          series:[]
        }
      },
      posWachedItem: -1,
      posWatchedDetailItem: -1,
      desgloseOTDDialog: false,
      desgloseOTDialog:false,
      defaultChartOptions: {
        chart: {
          type: 'bar',
          height: 430
        },
        plotOptions: {
          bar: {
            horizontal: true,
            dataLabels: {
              position: 'top',
            },
          }
        },
        colors: ['#008FFB', '#FF4560'],
        dataLabels: {
          enabled: false
        },
        stroke: {
          width: 1,
          colors: ["#fff"]
        },
        
        grid: {
          xaxis: {
            lines: {
              show: false
            }
          }
        },
        yaxis: {
          min: 0,
          max: 0,
          title: {
            // text: 'Age',
          },
        },
        tooltip: {
          shared: false,
          x: {
            formatter: function (val) {
              return val
            }
          },
          y: {
            formatter: function (val) {
              return "$ " + Math.abs(val).toLocaleString('en-US', {minimumFractionDigits: 2, maximumFractionDigits: 2});
            }
          }
        },
        xaxis: {
          categories: [],
          title: {
            text: 'Costo'
          },
          labels: {
            formatter: function (val) {
              return "$ " + Math.abs(Math.round(val)).toLocaleString('en-US', {minimumFractionDigits: 2, maximumFractionDigits: 2});
            }
          }
        },
      },
      recetasFaltantes:[],
      finishedEarlyItem: {
        idTrabajoOrden: -1,
        clave: "",
      },
      defaultFinishedEarlyItem: {
        idTrabajoOrden: -1,
        clave: "",
      },
      dialogFinishEarly: false,
      finishedEarlyState: 0,
      dialogFinishEarlyOTD:false,
      OTItem: {
        idTrabajoOrden: -1,
        disponibilidadProducto: 0,
        disponibilidadMP: 0,
        estadoProducion: 0,
        articulos:[],
        costosGrafica:{
          series:[]
        }
      },
      areas: [],
      dialogImpresion: false,  //Para la impresión de hoja viajera
      impresionHoja: {
        idTrabajoOrden: 0,
        idArea: 0,
        areas: [],
        idsAreas: []
      },
      loadingEstadisticas: false,

      costosDeReposicion:false,

      visibleHeaders: [],
    }),
    computed: {
      abiertas_fetch_url() {
        let url = `/TrabajoOrdenes?paginar=true&Page=${this.abiertas_actual_page}&Size=${this.abiertas_items_per_page}`;
        if(this.search.length > 0) url += ("&codigo="+this.search);
        if (this.idEstado != null) url += "&estado="+this.idEstado      //Se agregó esta línea para el filtro
        return url;
      },
      watchedItem(){
        if(this.posWachedItem >= 0 && this.$utils.isValid(this.trabajoOrdenes[this.posWachedItem])){
          var response = JSON.parse(JSON.stringify(this.trabajoOrdenes[this.posWachedItem]));
          return response;
        }
        else {
          var response = Object.assign({}, this.defaultItem);
          response.articulos = [];
          response.chartOptions = Object.assign({},this.defaultChartOptions);
          return response;
        }
      },
      watchedDetailItem(){
        if(this.posWatchedDetailItem >= 0 && this.$utils.isValid(this.watchedItem.articulos[this.posWatchedDetailItem])){
          var response = Object.assign({},this.watchedItem.articulos[this.posWatchedDetailItem]);
          return response;
        }
        else {
          var response = Object.assign({}, this.defaultItemOTD);
          response.chartOptions = Object.assign({},this.defaultChartOptions);
          return response;
        }
      },
      nColsCard(){
        return this.permisoVista('listas','$')?3:6;
      },
      formTitle () {
        return this.editedIndex === -1 ? 'Nueva Orden de Trabajo' : 'Editar Orden de Trabajo'
      },
      numOTS(){
        return this.trabajoOrdenes.filter(ot => ot.estadoProduccion == 1).length;
      },
      montoTotalProduccion(){
        var montoTotal = 0;
        this.trabajoOrdenes
          .filter(ot => ot.estadoProduccion == 1 || ot.estadoProduccion == 3)
          .forEach(ot => {
            montoTotal += ot.costoEstimadoTotal;
          });
        return montoTotal;
      },
      avanceTotal(){
        var porcentajeTotal = 0;
        this.trabajoOrdenes
          .filter(ot => ot.estadoProduccion == 1)
          .forEach(ot => {
            porcentajeTotal += ot.porcentajeCompletado;
          });
        
        if(isNaN(porcentajeTotal / this.numOTS)) return 0;
        else return porcentajeTotal / this.numOTS;
      },
      ...mapState({
        nombreEmpresa: 'nombreEmpresa',
        clavePersonalizada: 'clavePersonalizada',
        abiertas_items_per_page: 'numOTS',
        cantidadObjetivoQR: 'cantidadObjetivoQR',
        preferenciasUsuario: 'preferenciasUsuario',
      }),
    },

    // mounted(){
    //   if(this.permisoVista('listas','u')){
    //     new Sortable(
    //       this.$refs.ots.$el.getElementsByTagName('tbody')[0],
    //       {
    //         draggable: '.sortableRow',
    //         handle: '.sortHandle',
    //         onEnd: this.dragReorder
    //       }
    //     )
    //   }
    // },

    created () {
      // if(this.clavePersonalizada){
      //   this.headers[1].text = "Clave";
      //   this.headers[1].value = 'clave';
      // }
      this.getUltimoModoCalculoEstimado();
      this.getOperacionesAsignadas();
      this.maquinasIntacias();
      if(!this.permisoVista('listas','$')){
        this.headers = this.headers.filter(element => {
          return element.value != 'costoEstimado' && element.value != 'costoReal' && element.value != 'costoEstimadoTotal';
        });

        this.headersDetalle = this.headersDetalle.filter(element => {
          return element.value != 'costoReal' && element.value != 'costoEstimado' && element.value != 'costoEstimadoTotal' && element.value != 'precioUnitario' && element.value != 'montoTotal';
        });
      }
      this.getTrabajoOrdenesEstadisticas();
      this.getMontoTotalRealProduccion();
    },

    methods: {
      cerrarDialogPedidos(){
        this.filtros.desde = null;
        this.filtros.hasta = null;
        this.reportegeneralpedidosdialog = false
      },
      report() {

        if (this.filtros.desde == null || this.filtros.hasta == null) return;

        var url = "/Reportes/ReporteSemanalesPedidosXlsx?"; 
        url += "&fechaInicio=" + this.filtros.desde+"&fechaFin="+this.filtros.hasta;

        this.$utils.axios.downloadFile(
        url,
        "GET",
        `reporte-mp-semanal.xlsx`,
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
        );
  
      },


      SenDataDate(){
        var obj = {
          idTrabajoOrden: this.parametersdate.idTrabajoOrden,
          fecha: this.parametersdate.fechaCompromisoLabel
        }

        axios
            .put("/TrabajoOrdenes/EditarFechaCompromiso/", obj)
            .then((respose) => {
              this.activeDate = !this.activeDate
              this.trabajoOrdenes = this.trabajoOrdenes.map(ordenes => {
                if (ordenes.idTrabajoOrden === this.parametersdate.idTrabajoOrden) {
                  return {
                    ...ordenes,
                    fechaEntrega: this.parametersdate.fechaCompromisoLabel
                  };
                  console.log("sdasdsadasdsa")
                }
                return ordenes;
              });
              
            })
            .catch((error) => {
              
              console.log(error);
            });
      },
      cambioFechaCompromiso() {
        this.parametersdate.fechaCompromisoLabel = this.parametersdate.fecha.split("-")
          .reverse()
          .join("-");;
    },
      showDialogDate(id, fechap){
        this.parametersdate.idTrabajoOrden = id;
        var [dia, mes, anio] = fechap.split("-").map(Number);
        var fechas = new Date(anio, mes - 1, dia);
        this.parametersdate.fecha = fechas;
        this.parametersdate.fechaCompromisoLabel = fechap;
        this.activeDate = !this.activeDate;
      },
      validateCantidadObjetivo(item){
        this.$set(item,'errorCantidadObjetivo',null);

        if(item.cantidadObjetivo != null){
          if(parseFloat(item.cantidadObjetivo)  <= 0){
            this.$set(item,'errorCantidadObjetivo','Debe ser mayor a cero');
            return;
          }
        }

        return item.errorCantidadObjetivo == null;
      },
      onClickSaveCantidadObjetivo(item){
        if(!this.validateCantidadObjetivo(item)) return;
        if(item.cantidadObjetivo == null || item.cantidadObjetivo == ""){
          this.onClickCancelarCantidadObjetivo(item);
          return;
        }

        this.$set(item,'saving',true);
        axios
          .put(`/TrabajoOrdenes/CambiarCantidadObjetivo`,{
            idOperacion: item.idOperacion,
            canttidadObjetivo: parseFloat(item.cantidadObjetivo)
          })
          .then( () => {
            this.$set(item,'cantidadObjetivo',parseFloat(item.cantidadObjetivo));
            this.onClickCancelarCantidadObjetivo(item,false);
          })
          .catch( (error) => {
            console.log(error);
            this.$set(item,'errorCantidadObjetivo','Ocurrio un error');
          })
          .finally( () => {
            this.$set(item,'saving',false);
          });

      },
      onClickCancelarCantidadObjetivo(item,resetValue = true){
        this.$set(item,'modoEditar',false);
        if(resetValue) this.$set(item,'cantidadObjetivo',item.cantidadObjetivoPrevious);
        this.$set(item,'cantidadObjetivoPrevious',0);
        this.$set(item,'errorCantidadObjetivo',null);
      },
      onClickEditarCantidadObjetivo(item){
        if(item.cantidadObjetivo == null) this.$set(item,'cantidadObjetivo',0);
        this.$set(item,'cantidadObjetivoPrevious',parseFloat(item.cantidadObjetivo));
        this.$set(item,'modoEditar',true);
      },
      closeDialogCantidadObjetivo(){
        this.dialogCantidadObjetivo = false;

        setTimeout(() => {
          this.cantidadObjetivoItem = JSON.parse(JSON.stringify(this.defaultCantidadObjetivoItem));
        }, 200);
      },
      showDialogCantidadObjetivo(item){
        this.cantidadObjetivoItem = JSON.parse(JSON.stringify(item));
        
        this.dialogCantidadObjetivo = true;
        this.getOperacionesCantidadObjetivo();
      },
      getOperacionesCantidadObjetivo(){
        this.$set(this.cantidadObjetivoItem,'loading',true);

        axios
          .get(`/TrabajoOrdenes/OperacionesCantidadObjetivo/${this.cantidadObjetivoItem.idTrabajoOrden}`,{
            params: {
              idTrabajoOrdenDetalle: this.cantidadObjetivoItem.idTrabajoOrdenDetalle
            }
          })
          .then( (response) => {
            this.$set(this.cantidadObjetivoItem,'data',response.data);
            this.$set(this.cantidadObjetivoItem,'otDetalles',response.data.map( (item) => ({
              idTrabajoOrdenDetalle: item.idTrabajoOrdenDetalle,
              nombre: `${item.articuloProducir} - ${item.cantidad}`
            })))
          })
          .catch( (error) => {
            console.log(error);
            this.$set(this.cantidadObjetivoItem,'data',[]);
            this.$set(this.cantidadObjetivoItem,'otDetalles',[]);
          })
          .finally( () => {
            this.$set(this.cantidadObjetivoItem,'loading',false);
          })
      },
      finalizarManualmente(){
        this.cerrandoManualmente = true;
        axios({
          url: this.cierreManualItem.tipoCierre == 1
            ? `/TrabajoOrdenes/FinalizarManualOT/${this.cierreManualItem.idTrabajoOrden}`
            : `/TrabajoOrdenes/FinalizarManualOTD/${this.cierreManualItem.idTrabajoOrdenDetalle}`,
          method: 'PUT'
        })
          .then( () => {
            this.cerrandoManualmente = false;
            this.closeDialogFinalizarManualmente();
            this.initialize();
          })
          .catch( error => {
            this.cerrandoManualmente = false;
            this.cierreManualItem.error = true;
            console.log(error);
          })
      },
      closeDialogFinalizarManualmente(){
        this.dialogCierreManual = false;

        setTimeout(() => {
          this.cierreManualItem = Object.assign({},this.defaultCierreManualItem);
        }, 200);
      },
      showDialogFinalizarManualmente(item,tipoCierre){
        this.cierreManualItem = Object.assign({},item);
        this.cierreManualItem.tipoCierre = tipoCierre;

        this.dialogCierreManual = true;
      },
      resetErrorNuevaCantidad(item){
        this.$set(item,'errorNuevaCantidad',false);
      },
      validarNuevaCantidadOTD(item){
        this.$set(item,'errorNuevaCantidad',false);

        if(
          isNaN(parseFloat(item.nuevaCantidad)) ||
          parseFloat(item.nuevaCantidad) <= 0
        ){
          this.$set(item,'errorNuevaCantidad',true);
        }

        return !item.errorNuevaCantidad;
      },
      guardarNuevaCantidadOTD(item){
        if(item.nuevaCantidad === undefined){
          this.modoEditarCantidadOTD(item,false);
          return;
        }
        if(!this.validarNuevaCantidadOTD(item)) return;

        this.$set(item,'savingNuevaCantidadOTD',true);

        axios
          .put('/TrabajoOrdenes/ModificarCantidadOTD',{
            idTrabajoOrdenDetalle: item.idTrabajoOrdenDetalle,
            cantidad: parseFloat(item.nuevaCantidad)
          })
          .then( () => {
            this.$set(item,'cantidad',parseFloat(item.nuevaCantidad));
            this.$set(item,'savingNuevaCantidadOTD',false);
            this.modoEditarCantidadOTD(item,false);
            this.initialize();
          })
          .catch( error => {
            this.$set(item,'savingNuevaCantidadOTD',false);
            console.log(error);
          });
      },
      modoEditarCantidadOTD(item,value){
        this.$set(item,'editarCantidad',value);
        this.$set(item,'errorNuevaCantidad',false);
      },
      showDialogMateriasPrimas(item){
        this.$refs.rawMaterial.showDialog();
        this.$nextTick( () => {
          this.$refs.rawMaterial.initialize(item);
        });
      },
      getPageText: ({ pageStart, pageStop, itemsLength }) => `${pageStart+1}-${pageStop} de ${itemsLength}`,
      showModificarSubpasos(item){
        this.detallesItem.idTrabajoOrden = item.idTrabajoOrden;
        if(this.$refs.modsubs != undefined){
          this.$nextTick( () => {
            this.$refs.modsubs.showDialog();
          });
        }
      },
      cerrarDialogModificarSubpasos(){
        this.detallesItem.idTrabajoOrden = 0;
      },
      cerrarDialog(){
        this.dialogPorcentajeAvance = false;
        this.porcentajeItem = -1;
      },
      showDialogPorcentajeAvance(item){
        this.porcentajeItem = Object.assign({},item);
        this.dialogPorcentajeAvance = true;
        this.perkey++;
      },
      showDialogOperacionDetalle(idTrabajoOrden, idArea){
        this.detallesItem = Object.assign({},{
          idTrabajoOrden,
          idArea
        });
        this.dialogOperacionDetalle = true;
        this.opkey++;
      },
      cerrarDialogOperacionDetalle(){
        this.dialogOperacionDetalle = false;
        this.detallesItem = Object.assign({},this.defaultDetallesItem);
      },
      getMontoTotalRealProduccion(){
        this.loadingMontoReal = true;
        axios
        .get("/TrabajoOrdenes/MontoTotalProduccion")
        .then(response => {
          this.loadingMontoReal = false;
          this.montoTotalRealProduccion = response.data;
        })
        .catch(error => {
          this.loadingMontoReal = false;
          console.log(error);
        })
      },
      getTrabajoOrdenesEstadisticas(){
        this.loadingEstadisticas = true;
        axios
        .get("/TrabajoOrdenes/Estadisticas")
        .then(response => {
          this.loadingEstadisticas = false;
          this.estadisticas = response.data;
        })
        .catch(error => {
          this.loadingEstadisticas = false;
          console.log(error);
        })
      },
      mostrarDialogOrdenar(){
        this.$refs.ordenar.mostrarDialog();
      },
      mostrarDialogAccionesMasivas(){
        this.$refs.MasiveAcctions.mostrarDialog();
      },
      colorAvance(porcentaje){
        if(porcentaje <= 0.0)
          return 'red--text';
        else if(porcentaje < 100.0)
          return 'orange--text';
        else
          return 'green--text';
      },
      dragReorder ({oldIndex, newIndex}) {
        const movedItem = this.trabajoOrdenes.splice(oldIndex, 1)[0]
        this.trabajoOrdenes.splice(newIndex, 0, movedItem)
      
        var ids = this.trabajoOrdenes.map(element => {
          return element.idTrabajoOrden;
        });

        axios
        .put("/TrabajoOrdenes/CambiarPrioridad",ids)
        .then(() => {
          this.getTiempoFinalizacion();
        })
        .catch(error => {
          console.log(error);
        })
      },
      itemKey (item) {
        if (!this.itemKeys.has(item)) this.itemKeys.set(item, ++this.currentItemKey)
        return this.itemKeys.get(item)
      },
      desgloseCostosOT(item){
        this.$refs.chartGlobal.show("Desglose de Costos - "+item.clave,item.costosGrafica.limits,item.costosGrafica.categories,item.costosGrafica.series);;
      },
      desgloseCostosOTD(item){
        this.posWatchedDetailItem = this.watchedItem.articulos.indexOf(item);
        this.$refs.chartDetalles.show("Desglose de Costos - "+item.nombre+" - "+(this.clavePersonalizada ? this.watchedItem.clave : this.watchedItem.ot),item.costosGrafica.limits,item.costosGrafica.categories,item.costosGrafica.series);
      },
      cerrarOTDesglose(){
        this.desgloseOTDialog = false;
        this.posWachedItem = -1;
      },
      cerrarOTDDesglose(){
        this.desgloseOTDDialog = false;
        this.posWatchedDetailItem = -1;
      },
      cambio(event){
        var orden = event.toLowerCase().split('-');
        if(orden[0] == 'avance'){
          axios
            .get("/TrabajoOrdenes/PorcentajeCompletado/"+orden[1])
            .then(response => {
              var ot = this.trabajoOrdenes.find(element => element.idTrabajoOrden == parseInt(orden[1]));
              if(typeof ot != 'undefined'){
                var index = this.trabajoOrdenes.indexOf(ot);
                this.trabajoOrdenes[index].porcentajeCompletado = parseFloat(response.data.porcentaje);

                response.data.detalles.forEach(detalle =>{
                  var pos = this.trabajoOrdenes[index].articulos.findIndex(articulo => articulo.idTrabajoOrdenDetalle == detalle.idTrabajoOrdenDetalle);
                  this.trabajoOrdenes[index].articulos[pos].porcentaje = detalle.porcentaje;
                  this.trabajoOrdenes[index].articulos[pos].areas = detalle.areas;
                });
              
                if(!this.clavePersonalizada){
                  this.$notify({
                    group: 'produccion2',
                    title: 'Avance en Progreso de Orden de Trabajo',
                    text: 'Ha avanzado el progreso de la ODT-'+orden[1]+', ahora es del '+parseFloat(response.data.porcentaje).toFixed(2)+'%',
                    type: 'info',
                  });
                }
                else{
                  this.$notify({
                    group: 'produccion2',
                    title: 'Avance en Progreso de Orden de Trabajo',
                    text: 'Ha avanzado el progreso de la ODT con Clave '+this.trabajoOrdenes[index].clave+', ahora es del '+parseFloat(response.data.porcentaje).toFixed(2)+'%',
                    type: 'info',
                  });
                }
              }
            })
            .catch(error => {
              console.log(error);
            });
          this.getTiempoFinalizacion();
          
        }
        if(orden[0] == 'completo'){
          var ot = this.trabajoOrdenes.find(element => element.idTrabajoOrden == parseInt(orden[1]));

          if(ot != null){
            if(!this.clavePersonalizada){
              this.$notify({
                group: 'produccion',
                title: 'Orden de Trabajo Terminada',
                text: 'Se ha completado satisfactoriamente la ODT-'+orden[1],
                type: 'success',
              });
            }
            else{
              this.$notify({
                group: 'produccion',
                title: 'Orden de Trabajo Terminada',
                text: 'Se ha completado satisfactoriamente la ODT con Clave '+ot.clave,
                type: 'success',
              });
            }
            this.sound.play();
          }
          
          this.initialize();
        }
        if(orden[0] == 'otd' && orden[1] == 'terminada'){
          var idxOT = this.trabajoOrdenes.findIndex(element => element.idTrabajoOrden == parseInt(orden[2]));

          if(idxOT >= 0){
            var idxOTD = this.trabajoOrdenes[idxOT].articulos.findIndex(element => element.idTrabajoOrdenDetalle == parseInt(orden[3]));
            if(idxOTD >= 0) this.trabajoOrdenes[idxOT].articulos[idxOTD].estadoProducion = 3;
          }
        }
      },
      getAbiertas() {
        //* Realizamos peticion HTTP
        return new Promise((resolve, reject) => {
          axios
            .get(this.abiertas_fetch_url)
            .then((response) => resolve(response))
            .catch((error) => reject(error));
        });
      },
      async initialize() {
        this.loading = true;
        try {
          let response = await this.getAbiertas();
          this.trabajoOrdenes = this.subprocesar(response.data.result);
          this.abiertas_total = response.data.total;
          this.loading = false;
        } catch (exception) {
          console.error(exception);
        }
      },
      async abiertas_update_page_action(page) {
        this.abiertas_actual_page = page;
        this.loading = true;
        try {
          let response = await this.getAbiertas();
          this.trabajoOrdenes = this.subprocesar(response.data.result);
          this.abiertas_total = response.data.total;
          this.loading = false;
        } catch (exception) {
          console.log(exception);
        }
      },
      subprocesar ( openOTS ) {
        this.sound = new Howl({
          src: ['/sounds/notification.webm','/sounds/notification.mp3']
        });
        const newOpenOTS = openOTS.map(element => {
          if(element.idCliente == null) element.cliente = this.nombreEmpresa;
          element.fechaFinalizacion = null;
          element.faltantesMP = [];
          element.disponibilidadMP = null;
          element.articulos.map(e => {
            e.fechaFinalizacion = null;
            e.faltantesMP = [];
            return e;
          })
          return element;
        });
        this.getFaltantesMP();
        this.getTiempoFinalizacion();
        return newOpenOTS;
      },
      getTiempoFinalizacion(){
        axios
          .get("/TrabajoOrdenes/TiempoFinalizacion")
          .then(res => {
            res.data.forEach(element => {
              var posOT = this.trabajoOrdenes.findIndex(e => e.idTrabajoOrden == element.idTrabajoOrden);
              if(posOT >= 0){
                this.trabajoOrdenes[posOT].fechaFinalizacion = element.fecha;
                element.detalles.forEach(detalle => {
                  var posDetalle = this.trabajoOrdenes[posOT].articulos.findIndex(e => e.idTrabajoOrdenDetalle == detalle.idTrabajoOrdenDetalle);
                  if(posDetalle >= 0){
                    this.trabajoOrdenes[posOT].articulos[posDetalle].fechaFinalizacion = detalle.fecha;
                  }
                });
              }
            });
          })
          .catch(er => {
            console.log(er);
          })
      },
      getFaltantesMP(){
        axios
        .get("/TrabajoOrdenes/FaltantesMP")
        .then(response => {
          this.trabajoOrdenes.forEach((ot,posOT) => {
            var idxOTF = response.data.findIndex(otf => otf.idTrabajoOrden == ot.idTrabajoOrden);
            if(idxOTF < 0 ){
              if(this.trabajoOrdenes[posOT].estadoProduccion == 1 || this.trabajoOrdenes[posOT].estadoProduccion == 4){
                this.trabajoOrdenes[posOT].disponibilidadMP = 1;
                this.trabajoOrdenes[posOT].faltantesMP = [];
              }
              else{
                this.trabajoOrdenes[posOT].disponibilidadMP = 2;
                this.trabajoOrdenes[posOT].faltantesMP = [];
              }
            }
            else{
              this.trabajoOrdenes[posOT].disponibilidadMP = 0;
              this.trabajoOrdenes[posOT].faltantesMP = response.data[idxOTF].faltantes;

              response.data[idxOTF].detalles.forEach(detalle => {
                var posOTD = this.trabajoOrdenes[posOT].articulos.findIndex(otd => otd.idTrabajoOrdenDetalle == detalle.idTrabajoOrdenDetalle);
                if(posOTD >= 0){
                  this.trabajoOrdenes[posOT].articulos[posOTD].faltantesMP = detalle.faltantes;
                }
              });
            }
          })
        })
        .catch(error => {
          console.log(error);
        })
      },
      pauseItem(item){
        this.pausedItem = item;
        this.pause = true;
        this.pauseState = 0;
      },
      launchPause(){
        this.pauseState = 1;
        if(this.pausedItem.estadoProduccion == 1){
          axios
          .put("/TrabajoOrdenes/Pausar/"+this.pausedItem.idTrabajoOrden)
          .then(response => {
            this.initialize();
            this.pauseState = 3;
          })
          .catch(error => {
            this.pauseState = 2;
            console.log(error);
          });
        }
        else{
          axios
          .put("/TrabajoOrdenes/Reanudar/"+this.pausedItem.idTrabajoOrden)
          .then(response => {
            this.initialize();
            this.pauseState = 3;
          })
          .catch(error => {
            this.pauseState = 2;
            console.log(error);
          });
        }
      },
      closePause(){
        this.pausedItem = Object.assign({},this.defaultItem);
        this.pauseState = 0;
        this.pause = false;
      },
      watchItem(item){
        this.posWachedItem = this.trabajoOrdenes.indexOf(item);
        this.detalles = true;
      },
      launchItem(item){
        this.verificando = false;
        this.launch = true;
        this.launchedItem = item;
        this.faltaMP = false;
      },
      deleteItem(item){
        this.deletedItem = item;
        this.eliminar = true;
      },
      /*
        0: Sin Iniciar
        1: Lanzando
        2: Error
        3: Lanzado
      */
      launchProduccion(){
        this.launchState = 1;
        axios
          .post("/TrabajoOrdenes/Producir/"+this.launchedItem.idTrabajoOrden)
          .then(response => {
            this.launchState = 3;
            this.initialize();
          })
          .catch(error => {
            if (error.response.status == 409) {
              this.launchState = 21;
              this.recetasFaltantes = error.response.data;
            }
            else{
              this.launchState = 2;
            }
            console.log(error);
          });
      },
      closeLaunch(){
        this.launchedItem = Object.assign({},this.defaultItem);
        this.launchedItem.articulos = [];
        this.launch = false;
        this.launchState = 0;
      },
      closeDetalles(){
        this.detalles = false;
        this.posWachedItem = -1;
      },
      closeDialog(value) {
        this.dialog = value;
        if(!this.dialog){
          this.initialize();
        }
      },
      deleteItemDB(){
        axios
        .put("/TrabajoOrdenes/Desactivar/"+this.deletedItem.idTrabajoOrden)
        .then(response => {
          this.eliminar = false;
          this.deletedItem = null;
          this.initialize();
        })
        .catch(error => {
          console.log(error);
          this.eliminar = false;
          this.deletedItem = null;
        });
      },
      launchItemOTD(item){
        this.verificando = false;
        this.launchOTD = true;
        this.faltaMP = false;
        this.launchedItemOTD = item;
      },
      launchProduccionOTD(){
        this.launchState = 1;
        axios
        .post("/TrabajoOrdenes/Producir/"+this.launchedItemOTD.idTrabajoOrden+"?IdTrabajoOrdenDetalle="+this.launchedItemOTD.idTrabajoOrdenDetalle)
        .then(response => {
          this.launchState = 3;
          this.initialize();
          this.launchedItemOTD.estadoProduccion = 1;
        })
        .catch(error => {
          if (error.response.status == 409) {
              this.launchState = 21;
              this.recetasFaltantes = error.response.data;
            }
            else{
              this.launchState = 2;
            }
          console.log(error);
        });
      },
      closeLaunchOTD(){
        this.launchedItemOTD = Object.assign({},this.defaultItemOTD);
        this.launchOTD = false;
        this.launchState = 0;
      },
      pauseItemOTD(item){
        this.pausedItemOTD = item;
        this.pauseOTD = true;
        this.pauseState = 0;
      },
      launchPauseOTD(){
        this.pauseState = 1;
        if(this.pausedItemOTD.estadoProduccion == 1){
          axios
          .put("/TrabajoOrdenes/Pausar/"+this.pausedItemOTD.idTrabajoOrden+"?idTrabajoOrdenDetalle="+this.pausedItemOTD.idTrabajoOrdenDetalle)
          .then(response => {
            this.initialize();
            this.pausedItemOTD.estadoProduccion = 2;
            this.pauseState = 3;
          })
          .catch(error => {
            this.pauseState = 2;
            console.log(error);
          });
        }
        else{
          axios
          .put("/TrabajoOrdenes/Reanudar/"+this.pausedItemOTD.idTrabajoOrden+"?idTrabajoOrdenDetalle="+this.pausedItemOTD.idTrabajoOrdenDetalle)
          .then(response => {
            this.initialize();
            this.pausedItemOTD.estadoProduccion = 1;
            this.pauseState = 3;
          })
          .catch(error => {
            this.pauseState = 2;
            console.log(error);
          });
        }
      },
      closePauseOTD(){
        this.pausedItemOTD = Object.assign({},this.defaultItemOTD);
        this.pauseState = 0;
        this.pauseOTD = false;
      },
      launchFinishedEarly(){
        this.finishedEarlyState = 1;
        axios
        .put("/TrabajoOrdenes/TerminarAnticipadamenteOT/"+this.finishedEarlyItem.idTrabajoOrden)
        .then(() => {
          this.finishedEarlyState = 3;
          this.initialize();
        })
        .catch(error => {
          this.finishedEarlyState = 2;
        })
      },
      finishEarlyItem(item){
        this.finishedEarlyItem = item;
        this.dialogFinishEarly = true;
        this.finishedEarlyState = 0;
      },
      closeFinishedEarly(){
        this.finishedEarlyItem = Object.assign({},this.defaultFinishedEarlyItem);
        this.dialogFinishEarly = false;
        this.finishedEarlyState = 0;
      },
      launchFinishedEarlyOTD(){
        this.finishedEarlyState = 1;
        axios
        .put("/TrabajoOrdenes/TerminarAnticipadamenteOTD/"+this.watchedDetailItem.idTrabajoOrdenDetalle)
        .then(() => {
          this.finishedEarlyState = 3;
          if(this.watchedItem.articulos.length == 1) this.detalles = false;
        })
        .catch(error => {
          console.log(error)
          this.finishedEarlyState = 2;
        })
      },
      finishEarlyItemOTD(item){
        this.posWatchedDetailItem = this.watchedItem.articulos.indexOf(item);
        this.OTItem = Object.assign({},this.watchedItem);
        this.finishedEarlyItem = item;
        this.dialogFinishEarlyOTD = true;
        this.finishedEarlyState = 0;
      },
      closeFinishedEarlyOTD(){
        this.dialogFinishEarlyOTD = false;
        this.finishedEarlyItem = Object.assign({},this.defaultFinishedEarlyItem);
        this.dialogFinishEarlyOTD = false;
        this.finishedEarlyState = 0;
        this.OTItem = Object.assign({},this.defaultItem);
      },
      getTrabajoOrdenesOnEnterAction($event) {
        if ($event instanceof KeyboardEvent) {
          //  Enter
          if ($event.keyCode === 13) this.abiertas_update_page_action(1);
        }
      },
      clearSearchAction() {
        this.search = "";
        this.abiertas_update_page_action(1);
      },

      showCostosDeReposicion(item){
        this.$refs.CostosReposicion.showDialog();
        this.$refs.CostosReposicion.initialize(item);
      },
      showDialogPedidos(item){        
        this.idOrdenTrabajo = item.idTrabajoOrden
        this.dialogPedidos = true;
      },
      handleDialogChange(value) {
      if (value) {
        // El diálogo se ha abierto, ejecuta el código necesario
        console.log('Dialog abierto');
      } else {
        // El diálogo se ha cerrado, ejecuta el código necesario
        console.log('Dialog cerrado');
      }
    },
      show_SeleccionHeaders() {
        this.$refs.seleccionHeaders.show_dialog();
      },
      getOperacionesAsignadas() {
      this.loadAsignacionMaquinas = true;
      axios
        .get("/TrabajoOrdenes/OperacionesPorAsignarMaquina")
        .then(response => {
          this.loadAsignacionMaquinas = false;
          this.datosAsignacion = response.data;
        })
        .catch(error => {
          this.loadAsignacionMaquinas = false;
          console.log(error);
        })
    },
    resetAreasImpresion() {
      this.impresionHoja.idArea = [];
      this.areas = [];
      //console.log(this.areas);
    },
    showDialogImpresion(item) { 
      this.impresionHoja.idTrabajoOrden = item.idTrabajoOrden
      this.dialogImpresion = true;
      this.getAreas();
      //this.iinitialize();
      },
      getAreas() {
          axios
            .get(`/TrabajoOrdenes/AreasOrdenTrabajo/${this.impresionHoja.idTrabajoOrden}`)
            .then((response) => {
              this.areas = response.data;
              //console.log(this.impresionHoja.idTrabajoOrden);
              //console.log(this.areas)
            })
            .catch((error) => {
              this.resetAreasImpresion();
              console.log(error);
            });    
      },
      cerrarDialogImpresion(){
        this.resetAreasImpresion();
        this.dialogImpresion = false;
        //console.log("Entré a la función")
      },
    descargarArchivo(){   //Función encargada de descargar el archivo ya sea con areas seleccionadas o no   
      var url = `/TrabajoOrdenes/ImpresionOrdenTrabajo/${this.impresionHoja.idTrabajoOrden}`;
      var areas = Array.isArray(this.impresionHoja.idArea) ? this.impresionHoja.idArea : [];
      if (areas.length === 0) {
        areas = this.areas.map(area => area.idArea);
      }
      areas.forEach((idArea, index) => {
        url += `${index === 0 ? '?' : '&'}idsAreas=${idArea}`;
      });
      var datetime = moment().format('YYYYMMDDHHmmss');
      this.$utils.axios.downloadFile(url, 'GET', 'orden-trabajo-' + datetime + '.pdf', 'application/pdf');
    },
    maquinasIntacias(){
      this.loadMaquinasInstancia = true;
      axios
        .get("TrabajoOrdenes/MaquinasMaquinasIntancias")
        .then((response) => {
          this.maquinasIntancias = response.data;
          this.loadMaquinasInstancia = false;
        })
        .catch((error) => {
          this.loadMaquinasInstancia = false;
          console.log(error);
        });
    },
    filtrarMaquinasIns(items) {
      return this.maquinasIntancias.filter(item => item.idMaquina === items)[0].maquinasInstancia;
    },
    nombreMaquina: (maquina) => `[${maquina.nombre}] - ${maquina.marca} ${maquina.modelo}`,
    asignarMaquina(item, idMaquina, idMaquinaIns){
      var objeto = new Object();
      objeto.idOperacion = item.idOperacion;
      objeto.idMaquinaIns = idMaquinaIns;
      objeto.idMaquina = idMaquina;

      axios
        .post("/TrabajoOrdenes/CrearActualizarAsignacionMaquina", objeto)
        .then((response) => {
          this.getOperacionesAsignadas();
        })
        .catch((error) => {
          this.saving = false;
          console.log(error);
        });

    },
    getUltimoModoCalculoEstimado(){
        this.loadingMontoReal = true;
        axios
        .get("/TrabajoOrdenes/UltimoModoCalculoEstimado")
        .then(response => {
          
          this.switch1 = response.data;
        })
        .catch(error => {
          this.loadingMontoReal = false;
          console.log(error);
        })
      },
      cambiarEstado(){
        var estado = this.switch1 ? 1 : 0;
        
        axios
          .post("/TrabajoOrdenes/GuardarModoCalculoEstimadoOT/"+estado)
          .then(response => {
            console.info(response.data)
          })
          .catch(error => {
              console.error(error.response.data);
          });
      },
    },
  } 

</script>

<style scoped>
.fill-height{
  height: 100%;
}
.justify-content{
  justify-content: center !important;
}

.centered-input >>> input {
	text-align: center !important;
}

.footer-input {
  font-size: 12px !important;
  max-width: 40px !important;
}

.border-footer {
  border-top-width: thin;
  border-top-style: solid;
  border-top-color: rgba(0, 0, 0, 0.12);
}
</style>