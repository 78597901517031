<template>
  <v-container class="pa-0 ma-0">
    <label class="v-label--active v-label--is-disabled theme--light ">{{label}}</label>
      <v-row class="ma-0 pa-0 text-center justify-center">
        <v-col class="pa-0 ma-0">
          <v-btn
            color="green"
            :text="!value"
            :outlined="!value"
            @click="change(true)"
          >Si</v-btn>
        </v-col>
        <v-col class="pa-0 ma-0">
          <v-btn
            color="red"
            :text="value"
            :outlined="value"
            @click="change(false)"
          >No</v-btn>
        </v-col>
    </v-row>
  </v-container> 
</template>

<script>
import axios from 'axios'

export default {
  props:{
    value:{
      type:Boolean,
      default: false,
    },
    label:{
      type:String,
      default:"",
    }
  },
  methods:{
    change(event){
      this.$emit('input',event);
    }
  }
}
</script>