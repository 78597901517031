<template>
	<v-menu
		v-model="menu"
		:close-on-content-click="false"
		:nudge-right="40"
		:nudge-top="40"
		transition="scale-transition"
		offset-y
		min-width="auto"
	>
		<template v-slot:activator="{ attrs }">
			<v-text-field
				v-model="date"
				:label="label"
				readonly
				:clearable="clearable"
				:disabled="disabled"
				:error-messages="errorMessage"
				:outlined="outlined"
				v-bind="attrs"
				:hide-details="hideDetails"
				ref="custom-textfield-activator"
				@focus="$emit('focus')"
			></v-text-field>
		</template>
		<v-date-picker
			v-model="date"
			class="custom-date-picker"
			:no-title="noTitle"
			:max="maxDate"
			:min="minDate"
			first-day-of-week="1"
			@input="menu = false"
		></v-date-picker>
	</v-menu>
</template>

<script>
export default {
	props: {
		value: {
			type: [Array, String],
			required: true,
		},
		label: {
			type: String,
			required: true,
		},
		disabled: {
			type: Boolean,
			default: false,
		},
		clearable: {
			type: Boolean,
			default: false,
		},
		noTitle: {
			type: Boolean,
			default: false
		},
		maxDate: {
			type: String,
			default: undefined
		},
		minDate: {
			type: String,
			default: undefined
		},
		errorMessage: {
			type: String,
			default: null
		},
		hideDetails: {
			type: Boolean,
			default: false
		},
		outlined: {
			type: Boolean,
			default: false
		},
	},
	data() {
		return {
			menu: false,
		};
	},
	computed: {
		date: {
			get() {
				return this.value;
			},
			set(newValue) {
				this.$emit("input", newValue);
			},
		},
	},
	mounted(){
		let element = this.$refs['custom-textfield-activator'];
		element.$el.querySelector('.v-text-field__slot').addEventListener('click', () => {
			this.menu = true;
		});
	}
};
</script>
<style scoped>
.custom-date-picker >>> .v-btn--rounded {
	border-radius: 28px !important;
}

.custom-date-picker >>> .v-picker__title__btn:not(.v-picker__title__btn--active) {
	opacity: 0.6;
	cursor: pointer;
}

.custom-date-picker >>> .v-date-picker-title__year {
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	display: -webkit-inline-box;
	display: -ms-inline-flexbox;
	display: inline-flex;
	font-size: 14px;
	font-family: Roboto, sans-serif;
	font-weight: 500;
	margin-bottom: 8px;
}

.custom-date-picker >>> .v-date-picker-title__date > div {
	font-size: 34px;
	font-family: Roboto, sans-serif;
	font-weight: 500;
	line-height: 34px;
}

.custom-date-picker >>> .v-btn.v-btn--disabled {
	color: rgba(0, 0, 0, .26) !important;
}

.custom-date-picker >>> .v-btn {
	color: rgba(0, 0, 0, 0.87) !important;
	font-family: Roboto, sans-serif;
	font-weight: 500 !important;
	padding: 0;
}

.custom-date-picker >>> .v-date-picker-table .v-btn.v-btn--active {
	color: #fff !important;
}

.custom-date-picker >>> .accent {
	background-color: #005caf !important;
	border-color: #005caf !important;
}

.custom-date-picker >>> .accent--text {
	color: #005caf !important;
	caret-color: #005caf !important;
}

.custom-date-picker >>> .v-btn__content {
	font-family: Roboto, sans-serif;
	font-weight: 500 !important;
	flex-grow: 1;
}

.custom-date-picker >>> tbody tr:nth-of-type(odd) {
	background-color: transparent;
}

.custom-date-picker >>> .v-btn--active:before {
	background-color: rgb(85, 85, 85);
}

.custom-date-picker >>> .v-btn:focus:before {
	background-color: rgb(85, 85, 85);
}

.custom-date-picker >>> .v-btn:hover:before {
	background-color: rgb(85, 85, 85);
}

.custom-date-picker >>> .v-date-picker-header__value button {
	font-size: 1rem;
}

.custom-date-picker >>> .v-date-picker-years > li:not(.active) {
	font-family: Roboto, sans-serif !important;
	font-size: 1rem;
	line-height: 24px;
	font-weight: 400 !important;
}

.custom-date-picker >>> .v-date-picker-years > li.active {
	font-family: Roboto, sans-serif !important;
	line-height: 39px;
}
</style>