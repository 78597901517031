<template>
	<v-dialog v-model="dialogRecomendaciones" max-width="40%" persistent>
		<v-card>
			<v-card-title class="pa-4 pb-4 pt-5">
				Recomendaciones
			</v-card-title>
			<v-card-text>
				<p class="text-subtitle-1 ">{{ label }}</p>
				<v-row>
					<v-col cols="12" md="12" sm="12">
						<ul>
                            <li v-for="(recomendacion, idx) in recomendaciones" :key="idx">
                                <p class="text--primary">{{recomendacion}}</p>
                            </li>
                        </ul>
					</v-col>
				</v-row>
			</v-card-text>
			<v-card-actions>
				<v-spacer></v-spacer>
				<v-btn
					text
					color="green"
					@click="closeRecomendaciones"
					>Cerrar</v-btn
				>
			</v-card-actions>
		</v-card>
	</v-dialog>
</template>

<script>
export default {
    data(){
        return {
            dialogRecomendaciones: false,
            recomendaciones: [],
			label: ""
        }
    },
    methods: {
        closeRecomendaciones(){
            this.dialogRecomendaciones = false;
            this.recomendaciones = [];
        },
        showDialogRecomendaciones(item){
            this.recomendaciones = item.recomendaciones;
			this.label = `[${item.codigo}] ${item.nombre }`
            this.dialogRecomendaciones = true;
		},
    }
};
</script>

<style>
</style>