import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VTextarea } from 'vuetify/lib/components/VTextarea';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VDialog,{key:"especial",attrs:{"persistent":"","max-width":"500px"},model:{value:(_vm.show),callback:function ($$v) {_vm.show=$$v},expression:"show"}},[_c(VCard,[_c(VCardTitle,[_vm._v(" Producto Especial ")]),_c(VCardText,[_c(VTextarea,{attrs:{"auto-grow":"","outlined":"","label":"Mensaje"},model:{value:(_vm.mensaje),callback:function ($$v) {_vm.mensaje=$$v},expression:"mensaje"}})],1),_c(VCardActions,[_c(VSpacer),_c(VBtn,{attrs:{"color":"blue darken-1","text":""},on:{"click":function($event){return _vm.cancelar()}}},[_vm._v("Cancelar")]),_c(VBtn,{attrs:{"color":"red darken-1","text":""},on:{"click":function($event){return _vm.aceptar()}}},[_vm._v("Aceptar")])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }