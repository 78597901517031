<template>
  <v-col cols="12">
    <template>
      <v-row justify="center">
        <v-dialog
          v-model="eliminar"
          persistent
          max-width="500px"
          :key="eliminar"
        >
          <v-card>
            <v-card-title class="headline"
              >¿Está seguro que desea eliminar esta área?</v-card-title
            >
            <v-card-text
              >Esta acción no se puede revertir y será permanente.</v-card-text
            >
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                color="blue darken-1"
                text
                @click="
                  eliminar = false;
                  delItem = '';
                "
                >Cancelar</v-btn
              >
              <v-btn
                color="red darken-1"
                text
                @click="
                  eliminar = false;
                  deleteItemDB();
                "
                >Aceptar</v-btn
              >
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-row>
    </template>
    <v-dialog v-model="dialogErrorArea409" persistent max-width="50%">
      <v-card>
        <v-card-text class="pb-0">
          <div class="text-center">
            <lottie
              :options="checkOptions"
              :height="200"
              :width="200"
              :key="lottieKey"
              class="py-0"
            />
          </div>
          <p class="text-h5 text--primary text-center">No se puede eliminar esta área, porque esta en uso en al menos una receta.</p>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn 
            text 
            color="success" 
            @click="cerrarDialogErrorArea()"
            >Cerrar</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-data-table
      v-if="permisoVista('areas', 'r')"
      :headers="headers"
      :items="areas"
      :search="search"
      :loading="loading"
      loading-text="Cargando... Espere, Por Favor."
    >
      <template v-slot:top>
        <v-toolbar flat color="white" class="mb-0">
          <v-dialog v-model="dialog" persistent max-width="500px" :key="dialog">
            <template v-slot:activator="{ on }">
              <v-btn
                color="primary"
                dark
                class="mb-2"
                v-on="on"
                v-if="permisoVista('areas', 'c')"
                >Nueva Área</v-btn
              >
            </template>
            <v-card>
              <v-card-title>
                <span class="headline">{{ formTitle }}</span>
              </v-card-title>
              <v-card-text>
                <v-container>
                  <v-row>
                    <v-col justify="center" cols="12" sm="12" md="12">
                      <v-text-field
                        v-model="editedItem.nombre"
                        label="Nombre del Área"
                      ></v-text-field>
                    </v-col>
                    <v-col class="py-0" justify="center" cols="12" sm="12" md="12">
                      <p>¿Es un área exclusiva de retrabajo?</p>
                      <v-radio-group
                        v-model="editedItem.retrabajo"
                        mandatory
                        row
                      >
                        <v-radio
                          label="Si"
                          :value="true"
                        ></v-radio>
                        <v-radio
                          label="No"
                          :value="false"
                          @change="errores = [];editedItem.areasAceptadas = null"
                        ></v-radio>
                      </v-radio-group>
                    </v-col>
                    <v-col 
                      v-if="editedItem.retrabajo" 
                      justify="center" 
                      cols="12" 
                      sm="12" 
                      md="12"
                    >
                      <v-autocomplete
                        v-model="editedItem.areasAceptadas"
                        chips
                        clearable
                        deletable-chips
                        multiple
                        :items="areasAceptables"
                        item-text="nombre"
                        item-value="idArea"
                        label="Áreas de las que puede recibir retrabajo"
                      ></v-autocomplete>
                    </v-col>
                  </v-row>
                  <ul>
                    <li class="red--text" v-for="ex in errores" :key="ex">
                      {{ ex }}
                    </li>
                  </ul>
                </v-container>
              </v-card-text>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                  color="blue darken-1"
                  text
                  @click="
                    dialog = false;
                    errores = [];
                  "
                  >Cancelar</v-btn
                >
                <v-btn
                  color="red darken-1"
                  text
                  @click="save()"
                  :disabled="saving"
                  :loading="saving"
                >
                  Guardar
                  <template v-slot:loader>
                    <v-progress-circular
                      indeterminate
                      :width="2"
                      :size="24"
                      color="red"
                    ></v-progress-circular>
                  </template>
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
          <v-spacer></v-spacer>
          <v-text-field
            v-model="search"
            append-icon="search"
            label="Buscar"
            single-line
            hide-details
          ></v-text-field>
        </v-toolbar>
        <div class="d-flex justify-end">
          <v-btn
            color="primary"
            dark
            class="mt-0"
            v-if="permisoVista('areas', 'c')"
            @click="modoOrdenar = true;"
          >
            <v-icon left>mdi-sort</v-icon>Ordenar
          </v-btn>
        </div>
      </template>
      <template v-slot:item.retrabajo="{ item }">
        {{ item.retrabajo ? 'Si' : 'No' }}
      </template>
      <template v-slot:item.areasAceptadas="{ item }">
        <template v-if="item.areasAceptadas.length === 0 || item.areasAceptadas === null ">
          <p class="px-4 mb-0">N/A</p>
        </template>
        <template v-else>
          <ul v-for="(area, index) in item.areasAceptadas" :key="index">
            <li>{{ area.nombre }}</li>
          </ul>
        </template>
      </template>

      <template v-slot:item.action="{ item }">
        <v-tooltip bottom color="black" class="white--text">
          <template v-slot:activator="{ on }">
            <v-icon v-on="on"
              v-show="item.idArea > 1 && permisoVista('areas', 'u')"
              small
              class="mr-2"
              @click="editItem(item)"
            >
              edit
            </v-icon>
          </template>
          <span class="white--text">Editar</span>
        </v-tooltip>

        <v-tooltip bottom color="black" class="white--text">
          <template v-slot:activator="{ on }">
            <v-icon v-on="on"
              v-show="item.idArea > 1 && permisoVista('areas', 'd')"
              small
              @click="deleteItem(item)"
            >
              delete
            </v-icon>
          </template>
          <span class="white--text">Eliminar</span>
        </v-tooltip>
      </template>
      <template v-slot:no-data>
        <v-btn color="primary" @click="initialize">Refrescar</v-btn>
      </template>
    </v-data-table>

    <v-dialog v-model="modoOrdenar" persistent max-width="60%">
      <v-card>
        <v-card-title>Ordenar</v-card-title>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col cols="12" md="12" sm="12">
                <draggable
                  v-if="permisoVista('areas', 'u')"
                  class="list-group"
                  tag="ul"
                  v-model="areasOrden"
                  v-bind="dragOptions"
                  @start="drag = true"
                  @end="drag = false"
                >
                  <transition-group type="transition" :name="!drag ? 'flip-list' : null">
                    <v-card v-for="element in areasOrden" :key="element.idArea" color="grey lighten-4">
                      <v-card-text v-show="element.idArea !== 1 ">
                        <v-icon> mdi-menu </v-icon>
                        {{ element.nombre }}
                      </v-card-text>
                    </v-card>
                  </transition-group>
                </draggable>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions class="d-flex justify-center">
          <v-btn
            color="primary"
            class="mt-0"
            v-if="permisoVista('areas', 'u')"
            :disabled="savingOrdenar"
            @click="cancelarOrdenar"
          >
            Cancelar
          </v-btn>
          <v-btn
            color="error"
            class="mt-0"
            v-if="permisoVista('areas', 'u')"
            :disabled="savingOrdenar"            
            :loading="savingOrdenar"
            @click="saveOrder"
          >
            Guardar Orden
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-snackbar v-model="alerta" top color="error">
      Existe un Área Activa con el mismo nombre
      <v-btn text @click="alerta = false"> Cerrar </v-btn>
    </v-snackbar>
  </v-col>
</template>

<script>
import axios from "axios";
import draggable from "vuedraggable";
import Lottie from "vue-lottie";
import * as checkData from "@/assets/error.json";

export default {
  components: {
		lottie: Lottie,
    draggable,
  },
  data: () => ({
    checkOptions: {
      animationData: checkData.default,
      loop: false,
      speeed: 1
    },
    lottieKey: 0,
    dialogErrorArea409: false,
    showTabla: false,
    savingOrdenar: false,
    modoOrdenar: false,
    areasOrden: [],
    drag: false,
    saving: false,
    dialog: false,
    alerta: false,
    eliminar: false,
    search: "",
    headers: [
      {
        text: "Área",
        align: "left",
        sortable: true,
        value: "nombre",
        width: "15%"
      },
      {
        text: "¿Es un área exclusiva de retrabajo?",
        align: "center",
        sortable: true,
        value: "retrabajo",
        width: "20%"
      },
      {
        text: "Áreas de las que puede recibir retrabajo",
        align: "center",
        sortable: true,
        value: "areasAceptadas",
        width: "20%"
      },
      {
        text: "Acciones",
        value: "action",
        sortable: false,
        width: "10%",
        align: "center",
      },
    ],
    loading: false,
    areas: [],
    delItem: "",
    action: 0,
    errores: [],
    editedIndex: -1,
    editedItem: {
      nombre: "",
      retrabajo: false,
      areasAceptadas: null
    },
    defaultItem: {
      nombre: "",
      retrabajo: false,
      areasAceptadas: null
    },
  }),

  computed: {
    formTitle() {
      return this.editedIndex === -1 ? "Nueva Área" : "Editar Área";
    },
    dragOptions() {
      return {
        animation: 200,
        group: "description",
        disabled: false,
        ghostClass: "ghost",
      };
    },
    areasAceptables(){
      return this.areas.filter( area => !area.retrabajo && area.idArea !== this.editedItem.idArea );
    },
  },

  watch: {
    dialog(val) {
      val || this.close();
    },
  },

  created() {
    this.initialize();
  },

  methods: {
    cerrarDialogErrorArea(){
      this.dialogErrorArea409 = false;
      this.eliminar = false;
      this.delItem = '';
    },
    cancelarOrdenar(){
      this.modoOrdenar = false;
      this.areasOrden = this.areas;
    },
    saveOrder(){
      this.savingOrdenar = true;
      const objAreas = this.areasOrden.map( (element,index) =>  ({
        idArea: element.idArea,
        posicion: index+1
      }))
      .filter(x => x.idArea !== 1);
      axios
        .put("/Areas/ModificarPosiciones", objAreas)
        .then(()=> {
          this.initialize();
          this.savingOrdenar = false;
          this.modoOrdenar = false;
        })
        .catch( error =>  {
          this.savingOrdenar = false;
          console.log(error);
        })
    },
    initialize() {
      let me = this;
      me.loading = true;
      axios
        .get("/Areas?MostrarRetrabajo=true")
        .then((response) => {
          me.areas = response.data.filter(a => a.idArea > 1).map( area => ({
            ...area,
            retrabajo: area.retrabajo === null ? false : area.retrabajo
          }))
          me.areasOrden = response.data;
          me.loading = false;
        })
        .catch((error) => {
          console.log(error);
        });
    },

    editItem(item) {
      this.action = 1;
      this.editedIndex = this.areas.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialog = true;
    },

    deleteItem(item) {
      this.eliminar = true;
      this.delItem = item;
    },
    deleteItemDB() {
      let me = this;
      let item = this.delItem;
      this.delItem = "";
      axios
        .put("/Areas/Desactivar/" + item.idArea)
        .then((response) => {
          me.initialize();
        })
        .catch((error) => {
          if(error.response){
            if(error.response.status == 409){
              this.dialogErrorArea409 = true;
              this.lottieKey++;
            }
          }
          console.log(error);
        });
    },
    close() {
      (this.action = 0), (this.dialog = false);
      setTimeout(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      }, 300);
    },
    validate() {
      this.errores = [];
      if (
        this.editedItem.nombre.length < 2 ||
        this.editedItem.nombre.length > 50
      ) {
        this.errores.push(
          "El nombre debe tener más de 2 caracteres y menos de 50."
        );
      }

      if( this.editedItem.retrabajo ){
        if( 
          this.editedItem.areasAceptadas === null || 
          this.editedItem.areasAceptadas.length === 0
        ){
          this.errores.push(
            "Debe de seleccionar al menos un área de las que puede recibir retrabajo."
          );
        }
      }
      
      return this.errores.length > 0 ? false : true;
    },
    save() {
      this.action = 0;
      if (this.validate()) {
        let me = this;
        me.saving = true;
        if (this.editedIndex > -1) {
          //Código para editar
          axios
            .put("/Areas/Actualizar/" + me.areas[me.editedIndex].idArea, {
              Nombre: me.editedItem.nombre,
              retrabajo: this.editedItem.retrabajo,
              idsAreasAceptadas: this.editedItem.areasAceptadas
            })
            .then((response) => {
              me.saving = false;
              me.close();
              me.initialize();
            })
            .catch((error) => {
              me.saving = false;
              if (error.response) {
                if (error.response.status == 409) {
                  me.alerta = true;
                  me.errores.push("Existe un Área Activa con el Mismo Nombre");
                }
              }
            });
        } else {
          axios
            .post("/Areas/Crear", {
              Nombre: me.editedItem.nombre,
              retrabajo: this.editedItem.retrabajo,
              idsAreasAceptadas: this.editedItem.areasAceptadas
            })
            .then((response) => {
              me.saving = false;
              me.close();
              me.initialize();
            })
            .catch((error) => {
              me.saving = false;
              if (error.response) {
                if (error.response.status == 409) {
                  me.alerta = true;
                  me.errores.push("Existe un Área Activa con el Mismo Nombre");
                }
              }
            });
        }
      }
    },
  },
};
</script>