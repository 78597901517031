<template>
  <v-row>
    <v-col cols="12" class="pb-0">
      <DownloadBtn
        text
        color="primary"
        className="my-0"
        @click="descargarReporte()"
        label="Descargar Reporte<br/>de Factibilidad"
      />
    </v-col>
    <v-col>
      <v-data-table
        dense
        :headers="headers"
        :items="desserts"
        :items-per-page="-1"
        item-key="name"
        class="elevation-1"
        hide-default-footer
      >
        <template v-slot:item="{ item }">
          <tr>
            <td class="text-center">{{ item.ot }}</td>
            <td class="text-center">{{ item.cliente }}</td>
            <td
              :class="'text-center ' + Colors(item.faltantesGenerales.length)"
            >
              <div v-if="item.faltantesGenerales.length == 0">Disponible</div>
              <div v-else>
                <v-tooltip
                  :nudge-left="50"
                  bottom
                  color="black"
                  class="white--text"
                >
                  <template v-slot:activator="{ on }">
                    <span v-on="on" style="cursor: pointer">Insuficiente</span>
                  </template>
                  <span class="white--text text-left">
                    <ul>
                      <li
                        v-for="(item, index) in item.faltantesGenerales"
                        :key="'ftgral-' + index"
                      >
                        {{ item.cantidad }} {{ item.unidad }}
                        {{ item.articulo }}
                      </li>
                    </ul>
                  </span>
                </v-tooltip>
              </div>
            </td>
            <td :class="'text-center ' + Colors(item.faltantesGlobales.length)">
              <div v-if="item.faltantesGlobales.length == 0">Disponible</div>
              <div v-else>
                <v-tooltip
                  :nudge-left="50"
                  bottom
                  color="black"
                  class="white--text"
                >
                  <template v-slot:activator="{ on }">
                    <span v-on="on" style="cursor: pointer">Insuficiente</span>
                  </template>
                  <span class="white--text text-left">
                    <ul>
                      <li
                        v-for="(item, index) in item.faltantesGlobales"
                        :key="'ftglb-' + index"
                      >
                        {{ item.cantidad }} {{ item.unidad }}
                        {{ item.articulo }}
                      </li>
                    </ul>
                  </span>
                </v-tooltip>
              </div>
            </td>
          </tr>
        </template>
      </v-data-table>
    </v-col>
  </v-row>
</template>

<script>
import axios from "axios";
import DownloadBtn from "../../DownloadBtn.vue";
export default {
  components: {DownloadBtn},
  data: () => ({
    desserts: [],
    headers: [
      {
        text: "OT",
        align: "center",
        sortable: false,
        value: "ot",
        width: "15",
      },
      {
        text: "Cliente",
        align: "center",
        sortable: false,
        value: "cliente",
        width: "15",
      },
      {
        text: "Estado Jerárquico",
        align: "center",
        sortable: false,
        value: "faltantesGenerales",
        width: "10",
      },
      {
        text: "Estado Especifico",
        align: "center",
        sortable: false,
        value: "faltantesGlobales",
        width: "10",
      },
    ],
  }),
  mounted() {
    this.Getdata();
  },
  methods: {
    Getdata() {
      axios
        .get("/Reportes/ReporteFactibilidadOrdenTrabajo")
        .then((response) => {
          this.desserts = response.data;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    Colors(longitud) {
      if (longitud == 0) return "green";
      else return "yellow";
    },
    descargarReporte(){
      var url = "/Reportes/ReporteFactibilidadOrdenTrabajoXlsx";
      this.$utils.axios.downloadFile(url,'GET','ReporteFactibilidadOrdenTrabajo.xlsx','application/vnd.openxmlformats-officedocument.spreadsheetml.sheet');
    }
  },
};
</script>