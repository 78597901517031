<template>
	<v-dialog v-model="modoOrdenar" persistent max-width="60%">
		<v-card>
			<v-card-title class="px-8 d-flex align-center justify-space-between">
				<p class="mb-0 custom-title">Ordenar OT'S</p>
				<div>
					<v-btn
						color="primary"
						class="my-0 mr-2"
						v-if="permisoVista('areas', 'u')"
						:disabled="savingOrdenar"
						@click="cancelarOrdenar"
					>
						Cancelar
					</v-btn>
					<v-btn
						color="error"
						class="my-0"
						v-if="permisoVista('areas', 'u')"
						:disabled="savingOrdenar"
						:loading="savingOrdenar"
						@click="saveOrden"
					>
						Guardar Orden
					</v-btn>
				</div>
			</v-card-title>
			<v-card-text>
				<v-container>
					<v-row>
						<v-col cols="12" md="12" sm="12">
							<v-data-table
								:headers="headers"
								:items="items"
								loading-text="Cargando... Espere, Por Favor."
								:loading="loading"
								class="elevation-0"
								ref="orderots"
								hide-default-footer
								disable-pagination
							>
								<template v-slot:item="{ item }">
									<tr
										class="sortableRow"
										:key="`row-${item.idTrabajoOrden}`"
									>
										<td class="px-1" style="width: 0.1%">
											<v-btn
												style="cursor: move"
												icon
												class="sortHandle"
												><v-icon color="black"
													>mdi-menu</v-icon
												></v-btn
											>
										</td>
										<td class="text-center">
											{{ item.clave }}
										</td>
										<td class="text-center">
											{{ item.comercialLinea }}
										</td>
										<td class="text-center">
											{{ item.cliente }}
										</td>
									</tr>
								</template>
							</v-data-table>
						</v-col>
					</v-row>
				</v-container>
			</v-card-text>
		</v-card>
	</v-dialog>
</template>

<script>
import axios from "axios";
import Sortable from "sortablejs";

export default {
	data() {
		return {
			loading: false,
			items: [],
			savingOrdenar: false,
			modoOrdenar: false,
			drag: false,
			headers: [
				{
					sortable: false,
				},
				{
					text: "Clave",
					align: "center",
					sortable: false,
					value: "clave",
				},
				{
					text: "Línea Comercial",
					align: "center",
					sortable: false,
					value: "comercialLinea",
				},
				{
					text: "Cliente",
					align: "center",
					sortable: false,
					value: "cliente",
				},
			],
		};
	},
	updated() {
		if (this.permisoVista("listas", "u")) {
			new Sortable(
				this.$refs.orderots.$el.getElementsByTagName("tbody")[0],
				{
					draggable: ".sortableRow",
					handle: ".sortHandle",
					onEnd: this.dragReorder,
				}
			);
		}
	},
	methods: {
		dragReorder({ oldIndex, newIndex }) {
			const movedItem = this.items.splice(oldIndex, 1)[0];
			this.items.splice(newIndex, 0, movedItem);
		},
		mostrarDialog() {
			this.modoOrdenar = true;
			this.getTrabajoOrdenes();
		},
		getTrabajoOrdenes() {
			this.loading = true;
			axios
				.get("/TrabajoOrdenes/TrabajoOrdenHeaders")
				.then((response) => {
					this.items = response.data;
					this.loading = false;
				})
				.catch((error) => {
					this.loading = false;
					console.log(error);
				});
		},
		cancelarOrdenar() {
			this.modoOrdenar = false;

			setTimeout(() => {
				this.items = [];
			}, 200);
		},
		saveOrden() {
			this.savingOrdenar = true;
			const ordenados = this.items.map((item, index) => ({
				idTrabajoOrden: item.idTrabajoOrden,
				posicion: this.items.length - index,
			}));

			axios
				.put("/TrabajoOrdenes/CambiarPrioridades", ordenados)
				.then(() => {
					this.savingOrdenar = false;
					this.cancelarOrdenar();
					this.$emit("reset");
				})
				.catch((error) => {
					console.log(error);
				});
		},
	},
};
</script>

<style scoped>
.custom-title {
	font-size: 1.25rem !important;
    font-weight: 500;
    letter-spacing: .0125em;
}
</style>