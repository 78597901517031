import { VAutocomplete } from 'vuetify/lib/components/VAutocomplete';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VProgressLinear } from 'vuetify/lib/components/VProgressLinear';
import { VRow } from 'vuetify/lib/components/VGrid';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.permisoVista('dashboard','r'))?_c(VCol,{staticClass:"px-8",attrs:{"cols":"12"}},[_c(VRow,[_c(VCol,{staticClass:"align-self-end",attrs:{"cols":"4","md":"4","sm":"4"}},[_c('datePicker',{attrs:{"format":"YYYY-MM-DD","maxDate":_vm.fin,"label":"Desde (Obligatorio)","clearable":"","maxToday":"","disabled":_vm.cargando},on:{"input":_vm.obtenerDatos},model:{value:(_vm.desde),callback:function ($$v) {_vm.desde=$$v},expression:"desde"}})],1),_c(VCol,{staticClass:"align-self-end",attrs:{"cols":"4","md":"4","sm":"4"}},[_c('datePicker',{attrs:{"minDate":_vm.desde,"format":"YYYY-MM-DD","label":"Hasta (Obligatorio)","clearable":"","maxToday":"","disabled":_vm.cargando},on:{"input":_vm.obtenerDatos},model:{value:(_vm.hasta),callback:function ($$v) {_vm.hasta=$$v},expression:"hasta"}})],1),_c(VCol,{staticClass:"align-self-end",attrs:{"cols":"4","md":"4","sm":"4"}},[_c(VAutocomplete,{attrs:{"items":_vm.turnos,"item-text":"nombre","item-value":"idTurno","label":"Turno (Opcional)","hide-details":"","clearable":"","disabled":_vm.cargando},on:{"change":_vm.obtenerDatos},model:{value:(_vm.idTurno),callback:function ($$v) {_vm.idTurno=$$v},expression:"idTurno"}})],1)],1),_c(VRow,{class:!_vm.active&&'mb-4'},[_c(VCol,{staticClass:"pa-0 ma-0",attrs:{"cols":"12"}},[(_vm.realizarPeticion && !_vm.cargando)?_c(VBtn,{attrs:{"color":"primary","x-small":""},on:{"click":function($event){_vm.maquinasArticulosSeleccion.push(Object.assign({},_vm.maquinaArticuloDefault))}}},[_vm._v("Agregar Máquina")]):_vm._e()],1),_c(VCol,{staticClass:"pa-0 ma-0",attrs:{"cols":"12"}},[(_vm.realizarPeticion && !_vm.cargando)?_c(VDataTable,{attrs:{"items":_vm.maquinasArticulosSeleccion,"headers":_vm.headers,"hide-default-footer":""},scopedSlots:_vm._u([{key:"item.idMaquinaIns",fn:function(ref){
var item = ref.item;
return [_c(VAutocomplete,{attrs:{"items":_vm.maquinasFiltradas,"item-value":"idMaquinaIns","placeholder":"(Obligatorio)","item-text":_vm.nombreMaquina,"hide-details":"","dense":""},on:{"change":function($event){item.idArticulo = -1; _vm.obtenerDatosAvanzados();}},model:{value:(item.idMaquinaIns),callback:function ($$v) {_vm.$set(item, "idMaquinaIns", $$v)},expression:"item.idMaquinaIns"}})]}},{key:"item.idArticulo",fn:function(ref){
var item = ref.item;
return [_c(VAutocomplete,{attrs:{"items":_vm.articulosFiltrados(item.idMaquinaIns),"item-value":"idArticulo","placeholder":"(Obligatorio)","item-text":"articulo","hide-details":"","dense":""},on:{"change":function($event){return _vm.obtenerDatosAvanzados()}},model:{value:(item.idArticulo),callback:function ($$v) {_vm.$set(item, "idArticulo", $$v)},expression:"item.idArticulo"}})]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [(_vm.maquinasArticulosSeleccion.indexOf(item) != 0)?_c(VIcon,{attrs:{"small":""},on:{"click":function($event){return _vm.eliminarElemento(item)}}},[_vm._v("delete")]):_vm._e()]}}],null,false,539509222)}):_vm._e()],1)],1),(_vm.realizarPeticion && _vm.realizarPeticionAvanzada && _vm.active)?_c(VRow,{staticClass:"px-0 ma-0"},[_c(VCol,{staticClass:"ma-0 text-right text-subtitle-2",attrs:{"cols":"12"}},[_vm._v(" Proxima Actualización en "+_vm._s(_vm.tiempo)+" ")])],1):_vm._e(),(_vm.cargandoAvanzado)?_c(VProgressLinear,{attrs:{"color":"primary","height":"5","indeterminate":""}}):_vm._e(),_c('apexchart',{attrs:{"type":"bar","height":"350","options":_vm.chartOptions,"series":_vm.series}})],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }