<template>
	<v-container fluid v-if="permisoVista('mantenimientosporprogramar','r')">
		<v-dialog v-model="dialogNotificaciones" max-width="600px" persistent>
			<v-card>
				<v-card-title style="word-break: normal">Configuración de notificaciones</v-card-title>
				<v-card-text>
					<v-container fluid>
						<v-row>
							<v-col cols="12">
								<p class="mb-6 text-subtitle-1">Seleccione a continuación las personas a las que se les notificará por correo cuando se creen nuevos mantenimientos por programar</p>
								<v-autocomplete
									v-model="editedItem.idsPersonas"
									label="Personas a notificar"
									:items="personas"
									:item-text="nombrePersona"
									item-value="idPersona"
									multiple
									small-chips
									deletable-chips
									clearable
									:disabled="saving"
									:error-messages="erroresItem.errorIdsPersonas"
									@focus="erroresItem.errorIdsPersonas = null"
								></v-autocomplete>
							</v-col>
						</v-row>
					</v-container>
				</v-card-text>
				<v-card-actions>
					<v-spacer />
					<v-btn
						text
						color="success"
						:disabled="saving"
						@click="closeDialogNotificaciones"
						>Cancelar</v-btn
					>
					<v-btn
						text
						color="error"
						:disabled="saving"
						:loading="saving"
						@click="guardarUsuariosNotificar"
						>Guardar</v-btn
					>
				</v-card-actions>
			</v-card>
		</v-dialog>
		<v-dialog v-model="dialogProgramar" max-width="500px" persistent>
			<v-card>
				<v-card-title>
					<v-card-title class="headline"
						>Programar Mantenimiento</v-card-title
					>
					<v-card-text>
						<v-row>
							<v-col cols="12">
								<HeaderInfoMantenimiento
									:headers="headersProgramarMantenimiento"
									:autodetectCategoriaMaquina="false"
									:items="[{ ...editedItem }]"
								/>
							</v-col>
						</v-row>
						<v-row>
							<v-col cols="12" md="12" sm="12">
								<MenuDatepicker
									v-model="editedItem.inicio"
									label="Inicio del Mantenimiento"
									clearable
									:disabled="programando"
									:errorMessage="erroresItem.errorFechaInicio"
									@focus="erroresItem.errorFechaInicio = null"
								/>
							</v-col>
						</v-row>
					</v-card-text>
				</v-card-title>
				<v-card-actions>
					<v-spacer></v-spacer>
					<v-btn
						color="green"
						text
						:disabled="programando"
						@click="closeDialogProgramar"
						>Cancelar</v-btn
					>
					<v-btn
						color="red darken-1"
						text
						@click="programarMantenimiento"
						:disabled="programando"
						:loading="programando"
					>
						Guardar
					</v-btn>
				</v-card-actions>
			</v-card>
		</v-dialog>
		<v-row class="justify-space-between">
			<v-col v-if="permisoVista('mantenimientosporprogramar','u')" cols="4">
				<v-btn
					color="primary"
					:disabled="loading || loadingPersonasNotificar"
					:loading="loadingPersonasNotificar"
					@click="showDialogNotificaciones"
				>
					<v-icon left>mdi-email-outline</v-icon>
					Configuración de <br> notificaciones
				</v-btn>
			</v-col>
			<v-col cols="4">
				<v-text-field
					v-model="search"
					label="Buscar"
					prepend-inner-icon="mdi-magnify"
					clearable
					hide-details
					:disabled="loading"
				></v-text-field>
			</v-col>
		</v-row>
		<v-row>
			<v-col cols="12">
				<v-data-table
					:items="mantenimientos"
					:headers="headersMantenimiento"
					:loading="loading"
					:search="search"
				>
					<template v-slot:[`item.programadoCada`]="{ item }">
						{{
							$utils.strings.formatearCifra(item.programadoCada)
						}}
						Km
					</template>
					<template v-slot:[`item.kilometraje`]="{ item }">
						{{ $utils.strings.formatearCifra(item.kilometraje) }} Km
					</template>
					<template v-slot:[`item.actions`]="{ item }">
						<v-tooltip
							v-if="permisoVista('mantenimientosporprogramar','c')"
							bottom
							color="black"
							class="white--text"
						>
							<template v-slot:activator="{ on }">
								<v-icon
									v-on="on"
									small
									:disabled="loading"
									@click="showDialogProgramar(item)"
								>
									mdi-play
								</v-icon>
							</template>
							<span class="white--text"
								>Programar Mantenimiento</span
							>
						</v-tooltip>
					</template>
					<template v-slot:no-data>
						<v-btn color="primary" @click="getData()">
							<v-icon left>mdi-refresh</v-icon>
							Refrescar
						</v-btn>
					</template>
				</v-data-table>
			</v-col>
		</v-row>
	</v-container>
</template>

<script>
import axios from "axios";
import HeaderInfoMantenimiento from './Catalogos/HeaderInfoMantenimiento.vue';
import MenuDatepicker from '../../MenuDatepicker.vue';
import MaquinaInsAutocomplete from './MaquinaInsAutocomplete.vue';
export default {
	components: { HeaderInfoMantenimiento, MenuDatepicker, MaquinaInsAutocomplete },
	data() {
		return {
			loadingPersonasNotificar: false,
			personasNotificar: [],
			personas: [],
			saving: false,
			dialogNotificaciones: false,
			erroresItem: {
				errorFechaInicio: null,
				errorIdsPersonas: null,
			},
			programando: false,
			dialogProgramar: false,
			editedItem: null,
			defaultEditedItem: {
				idMantenimientoPreventivo: null,
				idMaquinaInstancia: null,
				inicio: null,
				idsPersonas: []
			},
			search: null,
			mantenimientos: [],
			headersMantenimiento: [
				{
					text: "Nombre",
					align: "center",
					sortable: false,
					value: "nombreMantenimiento",
				},
				{
					text: "Categoría Mantenimiento",
					align: "center",
					sortable: false,
					value: "nombreCategoria",
				},
				{
					text: "Categoría Máquina",
					align: "center",
					sortable: false,
					value: "categoriaMaquina",
				},
				{
					text: "Máquina",
					align: "center",
					sortable: false,
					value: "maquina",
				},
				{
					text: "Kilometraje",
					align: "center",
					value: "kilometraje",
				},
				{
					text: "Programado cada",
					align: "center",
					value: "programadoCada",
				},
				{
					text: "Fecha",
					align: "center",
					value: "fecha",
				},
				{
					text: "Acciones",
					align: "center",
					sortable: false,
					value: "actions",
					width: "10%,",
				},
			],
			loading: false,
		};
	},
	computed: {
		headersProgramarMantenimiento(){
			const omitHeaders = ['actions','kilometraje','programadoCada'];
			return this.headersMantenimiento.filter(header => !omitHeaders.includes(header.value));
		}
	},
	beforeMount(){
		this.editedItem = structuredClone(this.defaultEditedItem);
	},
	mounted() {
		this.initialize();
	},
	methods: {
		nombrePersona: ({ nombre, paterno, materno }) =>
			`${nombre ? `${nombre} ` : ""}${paterno ? `${paterno} ` : ""}${
				materno ? `${materno} ` : ""
			}`.trim(),
		closeDialogNotificaciones(){
			this.dialogNotificaciones = false;

			setTimeout(() => {
				this.editedItem = structuredClone(this.defaultEditedItem);
				this.resetErrores();
			}, 200);
		},
		validateUsuariosNotificar(){
			this.resetErrores();

			if(this.editedItem.idsPersonas.length == 0)
				this.erroresItem.errorIdsPersonas = 'Requerido al menos uno';

			const hayErrores = Object.values(this.erroresItem).some(value => value != null);

			return !hayErrores;
		},
		guardarUsuariosNotificar(){
			if(!this.validateUsuariosNotificar()) return;
			
			this.saving = true;

			const payload = {
				idsPersonas: this.editedItem.idsPersonas
			}

			axios
				.post('/Mantenimientos/ActualizarNotificacionesMttoPorProgramar', payload)
				.then(() => {
					this.saving = false;
					this.closeDialogNotificaciones();
					this.getPersonasNotificar();
				})
				.catch(error => {
					console.log(error);
					this.saving = false;
				});
		},
		showDialogNotificaciones(){
			this.editedItem.idsPersonas = this.personasNotificar.slice();
			this.dialogNotificaciones = true;
		},
		resetErrores(){
			Object.keys(this.erroresItem).forEach(key => this.erroresItem[key] = null);
		},
		validate(){
			this.resetErrores();

			if(this.editedItem.inicio == null){
				this.erroresItem.errorFechaInicio = "Requerido";
			}

			const hayErrores = Object.values(this.erroresItem).some(value => value != null);

			return !hayErrores;
		},
		programarMantenimiento(){
			if(!this.validate()) return;

			this.programando = true;

			const payload = {
				idMantenimientoPreventivo: this.editedItem.idMantenimientoPreventivo,
				idMaquinaInstancia: this.editedItem.idMaquinaInstancia,
				inicioMantenimiento: this.editedItem.inicio
			}

			axios
				.post("/Mantenimientos/ProgramarMantenimientoKm",payload)
				.then(() => {
					this.programando = false;
					this.closeDialogProgramar();
					this.getData();
				})
				.catch(error => {
					console.log(error);
					this.programando = false;
				})
		},
		closeDialogProgramar(){
			this.dialogProgramar = false;
			setTimeout(() => {
				this.editedItem = structuredClone(this.defaultEditedItem);
				this.resetErrores();
			}, 200);
		},
		showDialogProgramar(item) {
			this.editedItem = structuredClone(item);
			this.dialogProgramar = true;
		},
		initialize() {
			this.getData();
			this.getPersonas();
			this.getPersonasNotificar();
		},
		getPersonasNotificar(){
			this.loadingPersonasNotificar = true;

			axios
				.get('/Mantenimientos/GetNotificacionesMttoPorProgramar')
				.then(response => {
					this.personasNotificar = response.data.map(persona => persona.idPersona);
				})
				.catch(error => {
					console.log(error);
				})
				.finally(() => {
					this.loadingPersonasNotificar = false;
				})
		},
		getPersonas(){
			axios
				.get('/Personas')
				.then( (response) => {
					this.personas = response.data;
				})
				.catch( (error) => {
					console.log(error);
				});
		},
		getData() {
			this.loading = true;

			axios
				.get("/Mantenimientos/GetMantenimientosPorProgramarKM")
				.then((response) => {
					this.mantenimientos = response.data;
				})
				.catch((error) => {
					console.log(error);
					this.mantenimientos = [];
				})
				.finally(() => {
					this.loading = false;
				});
		},
	},
};
</script>