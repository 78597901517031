import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VImg } from 'vuetify/lib/components/VImg';
import { VProgressLinear } from 'vuetify/lib/components/VProgressLinear';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';
import { VTreeview } from 'vuetify/lib/components/VTreeview';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VRow,[_c(VCol,{attrs:{"cols":"3"}},[(!_vm.viewCamera)?_c(VCard,{attrs:{"elevation":"0"}},[_c(VRow,[_c(VCol,{staticClass:"pb-0",attrs:{"cols":"12"}},[_c(VImg,{staticClass:"white--text align-end",attrs:{"height":"200px","src":"https://i.imgur.com/mdRIzgI.png"}},[_c(VCardTitle,{staticClass:"text-center"},[_vm._v(_vm._s(_vm.errorCamera))])],1)],1)],1),_c(VRow,[_c(VCol,{staticClass:"py-0",attrs:{"cols":"12"}},[_c(VTextField,{attrs:{"label":"Código de Identificación","hide-details":""},model:{value:(_vm.codigoIdentificacion),callback:function ($$v) {_vm.codigoIdentificacion=$$v},expression:"codigoIdentificacion"}})],1)],1),_c(VRow,[_c(VCol,{staticClass:"pt-0",attrs:{"cols":"12"}},[_c(VBtn,{attrs:{"color":"green","block":""},on:{"click":_vm.getInformacionCodigo}},[_vm._v(" Leer Código Manualmente ")])],1)],1)],1):_c(VCard,{staticClass:"mx-auto",attrs:{"max-width":"350","elevation":"0"}},[_c(VRow,{staticClass:"ma-0 pa-0"},[_c(VCol,{staticClass:"ma-0 pa-0",attrs:{"cols":"12"}},[_c('qrcode-stream',{on:{"init":_vm.onInit,"decode":_vm.onDecode}})],1),_c(VCol,{staticClass:"ma-0 pa-0",attrs:{"cols":"12"}},[_c(VTextField,{attrs:{"label":"Código de Identificación","hide-details":""},model:{value:(_vm.codigoIdentificacion),callback:function ($$v) {_vm.codigoIdentificacion=$$v},expression:"codigoIdentificacion"}})],1),_c(VCol,{staticClass:"ma-0 pa-0",attrs:{"cols":"12"}},[_c(VBtn,{attrs:{"color":"green","block":""},on:{"click":_vm.getInformacionCodigo}},[_vm._v(" Leer Código Manualmente ")])],1)],1)],1)],1),_c(VCol,{attrs:{"cols":"9"}},[(!_vm.cargando)?_c(VTreeview,{attrs:{"items":_vm.arbol,"open-all":""},scopedSlots:_vm._u([{key:"prepend",fn:function(ref){
var item = ref.item;
return [_c(VIcon,[_vm._v(" "+_vm._s(_vm.icons[item.tipo])+" ")])]}}],null,false,3561344343)}):_c(VProgressLinear,{attrs:{"indeterminate":""}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }