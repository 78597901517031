<template>
  <v-col cols="12" v-if="permisoVista('simulacion', 'r')">
    <v-dialog v-model="dialog" persistent max-width="60%">
      <v-card>
        <v-card-title>
          {{
            editedItem.area
              ? `Editar Configuración del Área de ${editedItem.area}`
              : "Crear Nueva Configuración"
          }}
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col cols="12" v-if="!editedItem.area">
                <v-autocomplete
                  v-model="editedItem.idArea"
                  label="Área"
                  :items="areas"
                  item-text="nombre"
                  item-value="idArea"
                  clearable
                  :error-messages="errorArea"
                  @focus="errorArea = ''"
                  @change="areaChanged"
                ></v-autocomplete>
              </v-col>
              <v-col cols="12">
                <v-data-table
                  :items="editedItem.lineas"
                  :headers="lineasHeaders"
                  hide-default-footer
                  :items-per-page="-1"
                >
                  <template v-slot:[`item.nombre`]="{ item }">
                    <v-text-field
                      v-model="item.nombre"
                      dense
                      clearable
                      :error-messages="item.errorNombre"
                      @focus="item.errorNombre = ''"
                    ></v-text-field>
                  </template>
                  <template v-slot:[`item.numOperadores`]="{ item }">
                    <number-input
                      v-model="item.numOperadores"
                      controls
                      inline
                      size="small"
                      :min="0"
                      center
                      @change="item.errorNumOperador = ''"
                    ></number-input>
                    <v-scroll-y-transition>
                      <p
                        v-if="item.errorNumOperador != ''"
                        class="mb-0 red--text"
                        style="font-size: 12px !important"
                      >
                        {{ item.errorNumOperador }}
                      </p>
                    </v-scroll-y-transition>
                  </template>
                  <template v-slot:[`item.idsMaquinasIns`]="{ item, index }">
                    <v-autocomplete
                      v-model="item.idsMaquinasIns"
                      :items="maquinasInstanciasLineas(index)"
                      :item-text="nombreMaquina"
                      item-value="idMaquinaIns"
                      multiple
                      chips
                      deletable-chips
                      clearable
                      :disabled="loadingMaquinasInstancias"
                      :loading="loadingMaquinasInstancias"
                      :error-messages="item.errorMaquinas"
                      @focus="item.errorMaquinas = ''"
                    ></v-autocomplete>
                  </template>
                  <template v-slot:[`item.actions`]="{ item }">
                    <v-tooltip
                      v-if="permisoVista('simulacion', 'u')"
                      bottom
                      color="black"
                      class="white--text"
                    >
                      <template v-slot:activator="{ on }">
                        <v-icon
                          v-on="on"
                          small
                          class="mr-2"
                          @click="eliminarLinea(item)"
                        >
                          delete
                        </v-icon>
                      </template>
                      <span class="white--text">Eliminar</span>
                    </v-tooltip>
                  </template>
                </v-data-table>
                <v-btn text color="primary" @click="agregarLinea">
                  <v-icon left>mdi-plus</v-icon>
                  Agregar línea
                </v-btn>
                <v-scroll-y-transition>
                  <p
                    v-if="errorNoLineas != ''"
                    class="mb-0 mt-1 red--text"
                    style="font-size: 12px !important"
                  >
                    {{ errorNoLineas }}
                  </p>
                </v-scroll-y-transition>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text color="success" :disabled="saving" @click="cerrarDialog()"
            >Cancelar</v-btn
          >
          <v-btn
            text
            color="error"
            :disabled="saving"
            :loading="saving"
            @click="save()"
            >Guardar</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-row v-if="permisoVista('simulacion', 'c')">
      <v-col cols="4" class="py-0">
				<v-btn color="primary" :disabled="loading" @click="showDialog()"
					>Crear Nueva Configuración</v-btn
				>
			</v-col>
    </v-row>
    
    <v-row>
      <v-col cols="12">
        <v-data-table
          :items="simulacion"
          :headers="simulacionHeaders"
          :loading="loading"
        >
          <template v-slot:[`header.lineas`]>
            <div class="d-flex">
              <div
                class="d-flex justify-center align-center"
                style="width: 10%;font-size: 1.0625rem !important;"
              >
                # de Operadores
              </div>
              <div
                class="d-flex justify-center align-center"
                style="width: 30%;font-size: 1.0625rem !important;"
              >
                Línea
              </div>
              <div
                class="d-flex justify-center align-center"
                style="width: 60%;font-size: 1.0625rem !important;"
              >
                Máquinas Disponibles
              </div>
            </div>
          </template>
          <template v-slot:[`item.lineas`]="{ item,index }">
            <v-simple-table
              class="transparent my-1"
            >
              <template v-slot:default>
                <tbody>
                  <tr
                    v-for="linea in item.lineas"
                    :key="`tr-linea-${linea.idConfiguracionArea}`"
                    class="transparent"
                  >
                    <td class="text-center" width="10%">{{ linea.numOperadores }}</td>
                    <td class="text-center" width="30%">{{ linea.nombre }}</td>
                    <td class="text-center py-2" width="60%">
                      <p
                        v-if="linea.idsMaquinasIns.length == 0"
                        class="mb-0 py-1"
                        :key="`linea-nomaqs-${index}`"
                      >
                        N/A
                      </p>
                      <template v-else>
                          <p
                            v-for="maquina in linea.idsMaquinasIns"
                            class="mb-0 py-1"
                            :key="`maquina-${maquina}`"
                          >
                            • {{ getNombreInsMaquinaById(maquina) }}
                          </p>
                      </template>
                    </td>
                  </tr>
                </tbody>
              </template>
            </v-simple-table>
          </template>
          <template v-slot:[`item.actions`]="{ item }">
            <v-tooltip
              v-if="permisoVista('simulacion', 'u')"
              bottom
              color="black"
              class="white--text"
            >
              <template v-slot:activator="{ on }">
                <v-icon v-on="on" small class="mr-2" @click="showDialog(item)">
                  edit
                </v-icon>
              </template>
              <span class="white--text">Editar</span>
            </v-tooltip>
          </template>
        </v-data-table>
      </v-col>
    </v-row>
  </v-col>                                    
</template>

<script>
import axios from "axios";
export default {
  data() {
    return {
      loadingMaquinasInstancias: false,
      areas: [],
      errorNoLineas: "",
      errorArea: "",
      saving: false,
      maquinasInstancias: [],
      insMaquinas: [],
      loading: false,
      lineasHeaders: [
        {
          text: "Nombre línea",
          align: "center",
          sortable: false,
          value: "nombre",
        },
        {
          text: "# Operadores",
          align: "center",
          width: "10%",
          sortable: false,
          value: "numOperadores",
        },
        {
          text: "Máquinas",
          align: "center",
          sortable: false,
          value: "idsMaquinasIns",
        },
        {
          text: "Acciones",
          align: "center",
          width: "10%",
          sortable: false,
          value: "actions",
        },
      ],
      simulacionHeaders: [
        {
          text: "Área",
          align: "center",
          width: "15%",
          value: "area",
        },
        {
          text: "Lineas",
          align: "center",
          sortable: false,
          value: "lineas"
        },
        {
          text: "Acciones",
          align: "center",
          width: "10%",
          sortable: false,
          value: "actions",
        },
      ],
      simulacion: [],
      dialog: false,
      defaultItemLinea: {
        idConfiguracionArea: null,
        nombre: null,
        idsMaquinasIns: [],
        numOperadores: 0,
        errorNombre: "",
        errorNumOperador: "",
      },
      editedItem: {
        idArea: null,
        area: null,
        lineas: [],
      },
      defaultEditedItem: {
        idArea: null,
        area: null,
        lineas: [],
      },
    };
  },
  mounted() {
    this.initialize();
  },
  methods: {
    getNombreInsMaquinaById(idMaquinaIns){
      const result = this.insMaquinas.find( insMaquina => insMaquina.idMaquinaIns == idMaquinaIns );
      return result ? this.nombreMaquina(result) : 'N/A';
    },
    maquinasInstanciasLineas(index, bypass = false) {
      const maquinasSeleccionadas = [];
      this.editedItem.lineas.forEach((linea, idx) => {
        if (!bypass) {
          if (index != idx) maquinasSeleccionadas.push(...linea.idsMaquinasIns);
        } else {
          maquinasSeleccionadas.push(...linea.idsMaquinasIns);
        }
      });

      const resultado = this.maquinasInstancias.filter(
        (maquina) =>
          maquina.idArea == this.editedItem.idArea &&
          !maquinasSeleccionadas.includes(maquina.idMaquinaIns)
      );

      return resultado;
    },
    areaChanged() {
      this.editedItem.lineas.forEach((linea) => {
        this.$set(linea, "idsMaquinasIns", []);
      });
    },
    eliminarLinea(item) {
      const index = this.editedItem.lineas.indexOf(item);
      this.editedItem.lineas.splice(index, 1);
    },
    agregarLinea() {
      this.editedItem.lineas.push(
        JSON.parse(JSON.stringify(this.defaultItemLinea))
      );
      this.errorNoLineas = "";
    },
    cerrarDialog() {
      this.dialog = false;
      this.editedItem = JSON.parse(JSON.stringify(this.defaultEditedItem));
      this.resetErrores();
    },
    resetErrores() {
      this.errorArea = "";
      this.errorNoLineas = "";

      this.editedItem.lineas.forEach((linea) => {
        this.$set(linea, "errorNombre", "");
        this.$set(linea, "errorNumOperador", "");
      });
    },
    validate() {
      this.resetErrores();
      let erroresLineas = false;

      if (this.editedItem.idArea == null) {
        this.errorArea = "Debe seleccionar un área";
      }

      if (this.editedItem.lineas.length == 0) {
        this.errorNoLineas = "Debe agregar al menos 1 línea";
      } else {
        this.editedItem.lineas.forEach((linea) => {
          if (linea.nombre == null) {
            this.$set(linea, "errorNombre", "Requerido");
            erroresLineas = true;
          } else if (linea.nombre.length < 4) {
            this.$set(linea, "errorNombre", "Mínimo 4 caracteres");
            erroresLineas = true;
          }

          if (linea.numOperadores == 0) {
            this.$set(linea, "errorNumOperador", "Mínimo 1 operador");
            erroresLineas = true;
          }
        });
      }

      return (
        this.errorArea == "" &&
        this.errorNoLineas == "" &&
        erroresLineas == false
      );
    },
    save() {
      if (!this.validate()) return;
      const obj = {
        idArea: this.editedItem.idArea,
        lineas: this.editedItem.lineas,
      };

      this.saving = true;

      axios
        .post("/ConfiguracionArea/CrearNuevaConfiguracion", obj)
        .then(() => {
          this.saving = false;
          this.cerrarDialog();
          this.initialize();
          this.$emit('refreshValidation');
        })
        .catch((error) => {
          this.saving = false;
          console.log(error);
        });
    },
    nombreMaquina: ({ nombre, marca, modelo }) => {
      let machine = "";
      machine += nombre ? `[${nombre}] - ` : "";
      machine += marca ? `${marca} ` : "";
      machine += modelo ? `${modelo}` : "";
      return machine.trim();
    },
    showDialog(item = null) {
      this.getMaquinasInstanciasDisponibles(item);
      this.editedItem = JSON.parse(
        JSON.stringify(item || this.defaultEditedItem)
      );
      this.dialog = true;
    },
    initialize() {
      this.getMaquinasIns();
      this.getAreas();
      this.getSimulacion();
    },
    getMaquinasIns() {
      axios
        .get("/MaquinaInstancias")
        .then((response) => {
          this.insMaquinas = response.data;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    getAreas() {
      axios
        .get("/ConfiguracionArea/AreasSinConfiguracion")
        .then((response) => {
          this.areas = response.data.filter((area) => area.idArea != 1);
        })
        .catch((error) => {
          console.log(error);
        });
    },
    getMaquinasInstanciasDisponibles(item) {
      this.loadingMaquinasInstancias = true;
      axios
        .get("/MaquinaInstancias/MaquinasIntancia", {
          params: {
            idConfiguracionArea: item?.idArea,
          },
        })
        .then((response) => {
          if (item) {
            const maquinasFiltradas = response.data.filter(
              (maquina) => maquina.idArea == item.idArea
            );
            maquinasFiltradas.push(
              ...this.insMaquinas.filter((maquina) =>
                item.idsMaquinasDisponibles.includes(maquina.idMaquinaIns)
              )
            );

            const maquinasUnicas = [
              ...new Map(
                maquinasFiltradas.map((maquina) => [
                  maquina["idMaquinaIns"],
                  maquina,
                ])
              ).values(),
            ];

            this.maquinasInstancias = maquinasUnicas;
          } else {
            this.maquinasInstancias = response.data;
          }
        })
        .catch(console.log)
        .finally(() => {
          this.loadingMaquinasInstancias = false;
        });
    },
    getSimulacion() {
      this.loading = true;
      axios
        .get("/ConfiguracionArea")
        .then((response) => {
          this.simulacion = response.data;
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
};
</script>
<style scoped>
.custom-th {
  font-size: .875rem !important;
  border-bottom: thin solid rgba(0, 0, 0, 0.12);
}
</style>