import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VTab } from 'vuetify/lib/components/VTabs';
import { VTabItem } from 'vuetify/lib/components/VTabs';
import { VTabs } from 'vuetify/lib/components/VTabs';
import { VTabsItems } from 'vuetify/lib/components/VTabs';
import { VToolbar } from 'vuetify/lib/components/VToolbar';
import { VToolbarTitle } from 'vuetify/lib/components/VToolbar';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.mostrarVista('control'))?_c(VDialog,{attrs:{"fullscreen":""},model:{value:(_vm.value),callback:function ($$v) {_vm.value=$$v},expression:"value"}},[_c(VCard,[_c(VToolbar,{attrs:{"dark":"","color":"primary"}},[_c(VBtn,{attrs:{"icon":"","dark":""},on:{"click":_vm.close}},[_c(VIcon,[_vm._v("mdi-close")])],1),_c(VToolbarTitle,{staticClass:"white--text"},[_vm._v(_vm._s(_vm.area.area))]),_c(VSpacer)],1),_c(VTabs,{attrs:{"fixed-tabs":""},model:{value:(_vm.tab),callback:function ($$v) {_vm.tab=$$v},expression:"tab"}},[_c(VTab,{on:{"click":_vm.obtenerOperadores}},[_vm._v("Operadores Disponibles")]),_c(VTab,{on:{"click":_vm.actualizarOperaciones}},[_vm._v("Operaciones Pendientes")]),_c(VTab,{on:{"click":_vm.actualizarProgreso}},[_vm._v("Operaciones En Curso")])],1),_c(VTabsItems,{attrs:{"touchless":true},model:{value:(_vm.tab),callback:function ($$v) {_vm.tab=$$v},expression:"tab"}},[_c(VTabItem,{attrs:{"eager":true}},[_c('sesiones',{ref:"sesiones",attrs:{"area":_vm.area}})],1),_c(VTabItem,{attrs:{"eager":true}},[_c(VCard,{attrs:{"flat":""}},[_c('operaciones',{ref:"operaciones",attrs:{"area":_vm.area}})],1)],1),_c(VTabItem,{attrs:{"eager":true}},[_c(VCard,{attrs:{"flat":""}},[_c('progreso',{ref:"progreso",attrs:{"area":_vm.area}})],1)],1)],1)],1)],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }