<template>
	<v-list class="py-0" dark>
		<v-list-group
			v-for="maquina in lista"
			:key="maquina.idMaquina"
			no-action
			class="teal white--text"
			active-class="white--text"
			:value="true"
			:disabled="true"
			append-icon="mdi-chevron-up"
		>
			<template v-slot:activator>
				<v-list-item-content>
					<v-list-item-title>
						{{ maquina.marca }} {{ maquina.modelo }}
					</v-list-item-title>
				</v-list-item-content>
			</template>
			<v-list-item-group :value="currentSelection" light>
				<v-list-item
					v-for="instancia in maquina.maquinaInstancias"
					:key="instancia.idMaquinaIns"
					link
					:value="instancia.idMaquinaIns"
					class="teal lighten-5"
					active-class="teal--text text--darken-3"
					style="padding-left: 4.375rem"
					@click="onClickItem(maquina, instancia)"
					:disabled="loadingDataInstancias"
				>
					<template v-slot:default="{ active }">
						<v-list-item-content>
							<v-list-item-title>
								{{
									getNombreMaquinaInstancia(
										maquina,
										instancia
									)
								}}
							</v-list-item-title>
						</v-list-item-content>

						<v-list-item-action>
							<v-radio-group
								:value="active"
								:disabled="loadingDataInstancias"
							>
								<v-radio
									color="teal darken-2"
									:value="true"
								></v-radio>
							</v-radio-group>
						</v-list-item-action>
					</template>
				</v-list-item>
			</v-list-item-group>
		</v-list-group>
	</v-list>
</template>

<script>
export default {
	props: {
		lista: {
			type: Array,
			default: () => [],
		},
		getNombreMaquinaInstancia: {
			type: Function,
			default: () => {},
		},
		currentSelection: {
			type: Number,
			default: 0,
		},
		loadingDataInstancias: {
			type: Boolean,
			default: false,
		},
	},
	methods: {
		onClickItem(maquina, instancia) {
			this.$emit(
				"select",
				maquina,
				this.currentSelection == instancia.idMaquinaIns
					? null
					: instancia
			);
		},
	},
};
</script>
<style scoped>
.v-list-item__subtitle,
.v-list-item__title {
	white-space: break-spaces;
}
</style>