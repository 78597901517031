<template>
  <div>
    <v-row>
      <v-col class="md-6">
        <datePicker
          v-model="inicio"
          format="YYYY-MM-DD"
          :maxDate="fin"
          label="Desde (Obligatorio)"
          clearable
          maxToday
          @input="GetData()"
        ></datePicker>
      </v-col>
      <v-col class="md-6">
        <datePicker
          v-model="fin"
          format="YYYY-MM-DD"
          :minDate="inicio"
          label="Hasta (Obligatorio)"
          clearable
          maxToday
          @input="GetData()"
        ></datePicker>
      </v-col>
    </v-row>
    <v-progress-linear
      v-if="cargando"
			color="primary"
			height="5"
			indeterminate
		></v-progress-linear>
    <apexchart
      type="bar"
      height="350"
      :options="chartOptions"
      :series="series"
    ></apexchart>
  </div>
</template>

<script>
import axios from "axios";
export default {
  data() {
    return {
      cargando:false,
      series: [],
      chartOptions: {
        chart: {
          type: "bar",
          height: 350,
        },
        plotOptions: {
          bar: {
            horizontal: false,
            columnWidth: "55%",
            endingShape: "rounded",
          },
        },
        dataLabels: {
          enabled: true,
        },
        stroke: {
          show: true,
          width: 2,
          colors: ["transparent"],
        },
        xaxis: {
          categories: [],
        },
        yaxis: {
          title: {
            text: "Asistencias",
          },
        },
        fill: {
          opacity: 1,
        },
        tooltip: {
          y: {
            formatter: function (val) {
              return val + " Asistencias";
            },
          },
        },
      },
      defaultChartOptions: {
        chart: {
          type: "bar",
          height: 350,
        },
        plotOptions: {
          bar: {
            horizontal: false,
            columnWidth: "55%",
            endingShape: "rounded",
          },
        },
        dataLabels: {
          enabled: true,
        },
        stroke: {
          show: true,
          width: 2,
          colors: ["transparent"],
        },
        xaxis: {
          categories: [],
        },
        yaxis: {
          title: {
            text: "Asistencias",
          },
        },
        fill: {
          opacity: 1,
        },
        tooltip: {
          y: {
            formatter: function (val) {
              return val + " Asistencias";
            },
          },
        },
      },
      inicio: null,
      fin: null,
      categorias:[]
    };
  },
  methods: {
    GetData() {
      if (this.inicio == null && this.fin == null) console.log("sin datos");
      else if (this.inicio == null) console.log("sin datos");
      else if (this.fin == null) console.log("sin datos");
      else if (this.inicio > this.fin)
        console.log("La fecha de fin no puede ser mayor a la inicial");
      else {
        this.cargando = true;
        this.series = [];
        this.categorias = [];
        const url = `Reportes/ReportePersonasAsistencias?fecha=${this.inicio}&fecha2=${this.fin}`;
        axios
          .get(url)
          .then((response) => {
            this.series = response.data.series;

            this.chartOptions = Object.assign({},this.defaultChartOptions);
            this.chartOptions.xaxis.categories = Object.assign([],response.data.categories);
            this.cargando = false;
          })
          .catch((error) => {
            console.log(error);
            this.cargando = false;
          });
      }
    },
  },
  mounted() {
    //this.GetData();
  },
};
</script>