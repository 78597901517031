<template>
  <div>

    <v-dialog v-model="detalles" persistent max-width="1200px" :key="'d' + detalles.toString()">
      <v-card>
        <v-card-title v-if="!clavePersonalizada">Detalles de {{ watchedItem.ot }}</v-card-title>
        <v-card-title v-else>Detalles de la ODT con Clave {{ watchedItem.clave }}</v-card-title>
        <v-card-text>
          <apex-chart-dialog ref="chartDetalles"></apex-chart-dialog>
          <v-data-table :items="watchedItem.articulos" :headers="headersDetalle">
            <template v-slot:item="{ item }">
              <tr>
                <td class="text-center">{{ item.nombre }}</td>
                <td :class="'text-center ' + colorAvance(item.porcentaje)">{{ item.porcentaje.toFixed(2) }} %</td>
                <!--
              <td class="text-center" v-if="permisoVista('listas','$')">${{item.precioUnitario.toFixed(2)}}</td>
              <td class="text-center">{{item.cantidad}}</td>
              <td class="text-center" v-if="permisoVista('listas','$')">${{item.montoTotal.toFixed(2)}}</td>
              -->
                <td class="text-center">{{ item.fechaFinalizacion }}</td>
                <td class="text-center">
                  <barra-avance v-model="item.areas" borderColor="#ffffff" :height="30"></barra-avance>
                </td>
                <td :class="'text-center ' + colors2[item.estadoProduccion] + ' ' + textcolors2[item.estadoProduccion]">
                  <div v-if="item.lanzando == true">
                    <v-icon class="red--text">mdi-rocket-launch</v-icon>&nbsp;Lanzando a Producción
                  </div>
                  <div v-else-if="item.estadoProduccion == 0">
                    <v-icon small>mdi-pause</v-icon>No Iniciado
                  </div>
                  <div v-else-if="item.estadoProduccion == 1"><v-icon color="white" small>mdi-hammer</v-icon>&nbsp;En
                    Progreso</div>
                  <div v-else-if="item.estadoProduccion == 2"><v-icon class="white--text"
                      small>mdi-alert</v-icon>&nbsp;Pausado</div>
                  <div v-else-if="item.estadoProduccion == 3"><v-icon class="white--text"
                      small>mdi-check</v-icon>&nbsp;Completado</div>
                </td>
                <td class="text-center">
                  <v-tooltip bottom color="black" class="white--text">
                    <template v-slot:activator="{ on }">
                      <v-icon v-on="on" v-if="permisoVista('listas', '$')" small class="mr-2"
                        @click="desgloseCostosOTDItem(item)">
                        mdi-currency-usd
                      </v-icon>
                    </template>
                    <span class="white--text">Desglose de Costos</span>
                  </v-tooltip>
                </td>
              </tr>
            </template>
          </v-data-table>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="closeDetalles()">Cerrar</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <remanufactura-dialog ref="remanufactura" :watchedItem="watchedItem"
      @close="closeRemanufactura"></remanufactura-dialog>
    <v-row class="pa-0 ma-0" v-if="permisoVista('listas', 'r') && idTrabajoOrdenMadre == null">
      <v-col :cols="nColsCard" :md="nColsCard" :sm="nColsCard">
        <v-card class="fill-height d-flex">
          <v-card-text class="text-center align-self-center">
            <div class="subtitle-1 font-weight-bold">
              # ODT Producción
            </div>
            <ICountUp v-if="!loadingEstadisticas" :delay="1000" :endVal="estadisticas.numOTS"
              class="subtitle-1 font-weight-medium" />
            <div v-else class="text-center">
              <v-progress-circular :size="25" color="primary" indeterminate></v-progress-circular>
            </div>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col :cols="nColsCard" :md="nColsCard" :sm="nColsCard" v-if="permisoVista('listas', '$')">
        <v-card class="fill-height d-flex">
          <v-card-text class="text-center align-self-center">
            <div class="subtitle-1 font-weight-bold">
              $ Monto Estimado de la Producción
            </div>
            <ICountUp v-if="!loadingEstadisticas" :delay="1000" :endVal="estadisticas.montoTotalEstimadoProduccion"
              class="subtitle-1 font-weight-medium" :options="{
                  decimalPlaces: 2,
                  separator: ',',
                  decimal: '.',
                  prefix: '$'
                }" />
            <div v-else class="text-center">
              <v-progress-circular :size="25" color="primary" indeterminate></v-progress-circular>
            </div>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col :cols="nColsCard" :md="nColsCard" :sm="nColsCard" v-if="permisoVista('listas', '$')">
        <v-card class="fill-height d-flex">
          <v-card-text class="text-center align-self-center">
            <div class="subtitle-1 font-weight-bold">
              $ Monto Real de la Producción
            </div>
            <ICountUp v-if="!loadingMontoReal" :delay="1000" :endVal="montoTotalRealProduccion"
              class="subtitle-1 font-weight-medium" :options="{
                  decimalPlaces: 2,
                  separator: ',',
                  decimal: '.',
                  prefix: '$'
                }" />
            <div v-else class="text-center">
              <v-progress-circular :size="25" color="primary" indeterminate></v-progress-circular>
            </div>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col :cols="nColsCard" :md="nColsCard" :sm="nColsCard">
        <v-card class="fill-height d-flex">
          <v-card-text class="text-center align-self-center">
            <div class="subtitle-1 font-weight-bold">
              % Total de Avance
            </div>
            <ICountUp v-if="!loadingEstadisticas" :delay="1000" :endVal="estadisticas.porcentajeTotalProduccion"
              class="subtitle-1 font-weight-medium" :options="{
                  decimalPlaces: 2,
                  decimal: '.',
                  suffix: '%'
                }" />
            <div v-else class="text-center">
              <v-progress-circular :size="25" color="primary" indeterminate></v-progress-circular>
            </div>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <v-row class="ma-0 pa-0" v-if="idTrabajoOrdenMadre == null">
      <v-col cols="12" class="py-0" v-if="permisoVista('listas', '$')">
        <v-btn color="primary" @click="/*generarReporte*/show_dialogReporteCostos()" :disabled="loadingReporte">
          Generar Reporte de <br />Costos Estimados y Reales
        </v-btn>
        <v-btn class="mx-2" color="primary" @click="generarReporteSemanal" :disabled="loadingReporteSemanal">
          Generar <br />Reporte Semanal
        </v-btn>
        <!-- <download-btn
          color="primary"
          @click="generarReporteOTDS"
          :retryTime="90"
          label="Generar Reporte<br/>de Ordenes de Trabajo"
        ></download-btn> -->
        <v-btn :disabled="loadReportOT" color="primary" @click="generarReporteOTDS">Generar Reporte<br />de Ordenes de
          Trabajo</v-btn>
      </v-col>
    </v-row>
    <v-data-table v-if="permisoVista('listas', 'r')" :headers="visibleHeaders" :items="trabajoOrdenes"
      loading-text="Cargando... Espere, Por Favor." :loading="loading" class="elevation-0" ref="ots"
      :server-items-length="trabajoOrdenes_total" :items-per-page="trabajoOrdenes_items_per_page"
      :page="trabajoOrdenes_actual_page" @update:page="trabajoOrdenes_update_page_action"
      @update:items-per-page="(ipp) => trabajoOrdenes_items_per_page = ipp"
      @update:sort-by="trabajoOrdenes_sort_by_action"
      :footer-props="{ disableItemsPerPage: true, disablePagination: this.loading }" :disable-sort="true"
      :disable-pagination="loading" hide-default-footer>
      <template v-slot:top>
        <v-toolbar flat color="white">
          <v-text-field v-model="search" append-icon="search" label="Buscar" @keyup="getTrabajoOrdenesOnEnterAction"
            @click:clear="clearSearchAction" @click:append="trabajoOrdenes_update_page_action(1)" :clearable="true"
            :disabled="loading" single-line hide-details></v-text-field>
        </v-toolbar>
        <div class="text-right">
          <v-btn text color="primary" class="ml-auto" @click="show_SeleccionHeaders(headers)">
            <v-icon small>mdi-filter-outline</v-icon>
            Mostrar / Ocultar Columnas
          </v-btn>
        </div>
      </template>
      <template v-slot:item="{ item }">
        <tr>
          <td class="text-center" v-if="visibleHeaders.some(x => x.value == 'ot')">
            {{ clavePersonalizada ? item.clave : item.ot }}
          </td>
          <td class="text-center" v-if="visibleHeaders.some(x => x.value == 'lineaComercial')">
            {{ item.lineaComercial }}
          </td>
          <td class="text-center" v-if="visibleHeaders.some(x => x.value == 'cliente')">
            {{ item.cliente }}
          </td>
          <td class="text-center"
            v-if="permisoVista('listas', '$') && visibleHeaders.some(x => x.value == 'costoEstimadoTotal')">
            $ {{ $utils.strings.formatearCifra(item.costoEstimadoTotal, 2) }}
            <porcentaje-costos v-model="item.detallesCostoEstimado" borderColor="#ffffff" :height="20">
            </porcentaje-costos>
          </td>
          <td class="text-center" v-if="permisoVista('listas', '$') && visibleHeaders.some(x => x.value == 'costoEstimado')">
            $ {{ $utils.strings.formatearCifra(item.costoEstimado, 2) }}
          </td>
          <td class="text-center" v-if="permisoVista('listas', '$') && visibleHeaders.some(x => x.value == 'costoReal')">
            $ {{ $utils.strings.formatearCifra(item.costoReal, 2) }}
          </td>
          <td :class="'text-center ' + colorAvance(item.porcentajeCompletado)"
            v-if="visibleHeaders.some(x => x.value == 'porcentajeCompletado')">
            {{ item.porcentajeCompletado.toFixed(2) }}%
          </td>
          <td class="text-center" v-if="visibleHeaders.some(x => x.value == 'porcentajeAvance')">
            <p class="mb-0" v-if="item.areas.length == 0">N/D</p>
            <barra-avance v-else v-model="item.areas" borderColor="#ffffff" :height="30"></barra-avance>
          </td>
          <td class="text-center" v-if="visibleHeaders.some(x => x.value == 'fechaInicioDeProduccion')">
            <div v-if="item.fechaInicioDeProduccion != null">
              {{item.fechaInicioDeProduccion}}
            </div>
            <div v-else>
              No Disponible
            </div>
          </td>
          <td class="text-center" v-if="visibleHeaders.some(x => x.value == 'fecha')">
            <div v-if="item.fechaFinalizacion != null">
              {{ item.fechaFinalizacion }}
            </div>
            <div v-else>
              No Disponible
            </div>
          </td>
          <td :class="'text-center ' + colorDiasRestantes(item.diasRestantes)"
            v-if="visibleHeaders.some(x => x.value == 'fechaEntrega')">
            {{ item.fechaEntrega }}
          </td>
          <td :class="'text-center ' + colorDiasRestantes(item.diasRestantes)"
            v-if="visibleHeaders.some(x => x.value == 'diasRestantes')">
            {{ item.diasRestantes }}
          </td>
          <td :class="'text-center ' + colors2[item.estadoProduccion] + ' ' + textcolors2[item.estadoProduccion]"
            v-if="visibleHeaders.some(x => x.value == 'estadoProduccion')">
            <div v-if="item.estadoProduccion == 0">
              <v-icon small>mdi-pause</v-icon>No Iniciado
            </div>
            <div v-if="item.estadoProduccion == 1"><v-icon color="white" small>mdi-hammer</v-icon>&nbsp;En Progreso</div>
            <div v-if="item.estadoProduccion == 2"><v-icon class="white--text" small>mdi-alert</v-icon>&nbsp;Pausado</div>
            <div v-if="item.estadoProduccion == 3"><v-icon class="white--text" small>mdi-check</v-icon>&nbsp;Completado
            </div>
            <div v-if="item.estadoProduccion == 4"><v-icon class="white--text"
                small>mdi-circle-half-full</v-icon>&nbsp;Producción Parcial</div>

          </td>
          <td class="text-center">
            <v-tooltip bottom color="black" class="white--text">
              <template v-slot:activator="{ on }">
                <v-icon v-on="on" v-if="permisoVista('listas', 'r')" small class="mr-2" @click="watchItem(item)">
                  mdi-eye
                </v-icon>
              </template>
              <span class="white--text">Ver detalles</span>
            </v-tooltip>
            <v-tooltip bottom color="black" class="white--text">
              <template v-slot:activator="{ on }">
                <v-icon v-on="on" v-if="permisoVista('listas', '$')" small class="mr-2" @click="desgloseCostosItem(item)">
                  mdi-currency-usd
                </v-icon>
              </template>
              <span class="white--text">Desglose de Costos</span>
            </v-tooltip>
            <v-tooltip bottom color="black" class="white--text">
              <template v-slot:activator="{ on }">
                <v-icon v-on="on" v-if="permisoVista('listas', '$')" small class="mr-2"
                  @click="desgloseCostosRemanufacturaItem(item)">
                  mdi-cog-refresh
                </v-icon>
              </template>
              <span class="white--text">Desglose de Remanufactura</span>
            </v-tooltip>
          </td>
        </tr>
      </template>
      <template v-slot:footer="{ props }">
        <v-col cols="12" class="d-flex justify-end align-center border-footer">
          <p class="mb-0 mr-8" style="font-size: 12px !important">Filas por página:</p>
          <v-text-field :value="props.pagination.itemsPerPage" dense hide-details disabled
            class="centered-input footer-input mt-0 mr-10"></v-text-field>
          <p class="mb-0 mr-10" style="font-size: 12px !important">
            {{ props.pagination.itemsLength ? getPageText(props.pagination) : '-' }}
          </p>
          <v-pagination v-model="trabajoOrdenes_actual_page" :length="props.pagination.pageCount" :total-visible="7"
            :disabled="loading"></v-pagination>
        </v-col>
      </template>
    </v-data-table>
    <apex-chart-dialog ref="chartGlobal"></apex-chart-dialog>
    <alert-dialog ref="aviso" icon="mdi-check" color="green"></alert-dialog>

    <v-dialog v-model="dialogReporteCostos" persistent max-width="700">
      <v-card>
        <v-card-title>Reporte de Costos Reales y Estimados</v-card-title>

        <center>
          <v-card-subtitle>Indique la fecha y hasta la cual desea que se genere este reporte</v-card-subtitle>
        </center>
        <br>

        <v-card-text>
          <v-container>
            <v-row>
              <v-col cols="6">
                <VueCtkDateTimePicker v-model="fechaInicio" :max-date="maxFecha" @input="errorFechaInicio = ''" onlyDate
                  format="YYYY-MM-DD" formatted="YYYY-MM-DD" label="Fecha de Inicio" noButtonNow noHeader overlay
                  :error="errorFechaInicio != ''" />
                <p v-if="errorFechaInicio" class="red--text pt-1 mb-0" style="font-size: 12px !important">
                  {{
                    errorFechaInicio
                  }}</p>
              </v-col>
              <v-col cols="6">
                <VueCtkDateTimePicker v-model="fechaFin" :min-date="fechaInicio" :max-date="obtenerFechaActual()"
                  @input="errorFechaFin = ''" onlyDate format="YYYY-MM-DD" formatted="YYYY-MM-DD" label="Fecha Final"
                  noButtonNow noHeader overlay :error="errorFechaFin != ''" />
                <p v-if="errorFechaFin" class="red--text pt-1 mb-0" style="font-size: 12px !important">{{
                  errorFechaFin
                }}</p>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>

        <v-card-actions>
          <v-btn @click="hide_dialogReporteCostos()" text color="success" class="ml-auto" :loading="loadingReporte"
            :disabled="loadingReporte">Cancelar</v-btn>
          <v-btn @click="generarReporteFechas()" text color="error" :loading="loadingReporte"
            :disabled="loadingReporte">Aceptar</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <SeleccionHeadersVue ref="seleccionHeaders" v-model="visibleHeaders" :headers="headers" path="ots_resumen"/>

  </div>
</template>

<script>
import axios from "axios";
import { mapState } from 'vuex';
import { Howl, Howler } from 'howler';
import ICountUp from 'vue-countup-v2';
import barraAvance from '@/components/BarraAvance';
import apexChartDialog from '@/components/ApexChartDialog';
import alertDialog from '@/components/AlertDialog';
import porcentajeCostos from '@/components/PorcentajeCostos';
import Remanufactura from './Remanufactura.vue';

import SeleccionHeadersVue from '@/components/SeleccionHeaders.vue'

export default {
  props: {
    idTrabajoOrdenMadre: {
      type: Number,
      default: null
    }
  },
  components: {
    ICountUp,
    barraAvance,
    apexChartDialog,
    'apex-chart-dialog': apexChartDialog,
    'alertDialog': alertDialog,
    'porcentaje-costos': porcentajeCostos,
    'remanufactura-dialog': Remanufactura,
    SeleccionHeadersVue
  },
  data: () => ({
    loadReportOT: false,
    loadingReporteSemanal: false,
    colors1: ['yellow', 'green'],
    textcolors1: [, 'black--text', 'white--text'],
    colors2: ['', 'orange', 'red', 'green', 'blue'],
    textcolors2: ['', 'white--text', 'white--text', 'white--text', 'white--text'],
    trabajoOrdenes_total: null,
    //trabajoOrdenes_items_per_page: 10,
    trabajoOrdenes_actual_page: 1,
    search: '',
    headers: [
      {
        text: 'ODT',
        align: 'center',
        sortable: true,
        value: 'ot',
      },
      {
        text: 'Línea Comercial',
        align: 'center',
        sortable: true,
        value: 'lineaComercial',
        width: '12%',
      },
      {
        text: 'Cliente',
        align: 'center',
        sortable: true,
        value: 'cliente',
        width: '12%',
      },
      {
        text: 'Costo Estimado Total',
        align: 'center',
        sortable: true,
        value: 'costoEstimadoTotal'
      },
      {
        text: 'Costo Estimado Actual',
        align: 'center',
        sortable: true,
        value: 'costoEstimado'
      },
      {
        text: 'Costo Real',
        align: 'center',
        sortable: true,
        value: 'costoReal'
      },
      {
        text: "% Completado",
        align: 'center',
        sortable: true,
        value: 'porcentajeCompletado'
      },
      {
        text: "Porcentaje de Avance",
        align: 'center',
        sortable: false,
        width: '20%',
        value: 'porcentajeAvance'
      },
      {
        text: "Fecha de Inicio de Producción",
        align: 'center',
        sortable: false,
        width: '20%',
        value: 'fechaInicioDeProduccion'
      },
      {
        text: 'Fecha de Finalización',
        align: 'center',
        sortable: false,
        value: 'fecha',
      },
      {
        text: 'Fecha Compromiso',
        align: 'center',
        sortable: false,
        value: 'fechaEntrega'
      },
      {
        text: 'Días Restantes',
        align: 'center',
        sortable: true,
        value: 'diasRestantes'
      },
      {
        text: 'Producción',
        align: 'center',
        sortable: false,
        value: 'estadoProduccion'
      },
      { text: 'Acciones', value: 'action', sortable: false, align: 'center' },
    ],
    headersDetalle: [
      {
        text: 'Artículo',
        align: 'center',
        sortable: true,
        value: 'nombre',
      },
      {
        text: '% Completado',
        align: 'center',
        sortable: true,
        value: 'porcentaje',
      },
      /*
      {
        text: 'Precio Unitario',
        align: 'center',
        sortable: true,
        value: 'precioUnitario',
      },
      {
        text: 'Cantidad',
        align: 'center',
        sortable: true,
        value: 'cantidad',
      },
      {
        text: 'Monto Total',
        align: 'center',
        sortable: true,
        value: 'montoTotal',
      },
      */
      {
        text: 'Fecha de Finalización',
        align: 'center',
        sortable: true,
        value: 'fechaFinalizacion',
      },
      {
        text: 'Áreas de Trabajo',
        align: 'center',
        sortable: true,
        value: 'areas',
        width: '35%'
      },
      {
        text: 'Producción',
        align: 'center',
        sortable: false,
        value: 'estadoProduccion'
      },
      { text: 'Acciones', value: 'action', sortable: false, align: 'center' }
    ],
    loading: false,
    detalles: false,
    trabajoOrdenes: [],
    sound: null,
    posWachedItem: -1,
    defaultItem: {
      idTrabajoOrden: -1,
      disponibilidadProducto: 0,
      disponibilidadMP: 0,
      estadoProducion: 0,
      articulos: [],
    },
    estadisticas: {
      numOTS: 0,
      montoTotalEstimadoProduccion: 0.0,
      porcentajeTotalProduccion: 0.0,
    },
    montoTotalRealProduccion: 0.0,
    loadingEstadisticas: true,
    loadingMontoReal: true,
    loadingReporte: false,

    dialogReporteCostos: false,
    fechaInicio: null,
    fechaFin: null,
    fechaMax: null,
    errorFechaInicio: "",
    errorFechaFin: "",
    visibleHeaders: [],
  }),

  computed: {
    watchedItem() {
      if (this.posWachedItem >= 0 && this.$utils.isValid(this.trabajoOrdenes[this.posWachedItem])) return this.trabajoOrdenes[this.posWachedItem];
      else return this.defaultItem;
    },
    nColsCard() {
      return this.permisoVista('listas', '$') ? 3 : 6;
    },
    trabajoOrdenes_fetch_url() {
      let url = this.idTrabajoOrdenMadre == null
        ? '/TrabajoOrdenes/TrabajoOrdenesResumen'
        : `/TrabajoOrdenMadres/DetallesTrabajoOrdenMadre/${this.idTrabajoOrdenMadre}`;
      url += `?paginar=true&page=${this.trabajoOrdenes_actual_page}&size=${this.trabajoOrdenes_items_per_page}`;
      if (this.search != null && this.search.length > 0) {
        url += ("&codigo=" + this.search);
      }
      return url;
    },
    ...mapState({
      nombreEmpresa: 'nombreEmpresa',
      clavePersonalizada: 'clavePersonalizada',
      trabajoOrdenes_items_per_page: 'numOTS',
      token: 'token',
      preferenciasUsuario: 'preferenciasUsuario',
    }),
    maxFecha() {
      return this.fechaFin ? this.fechaFin : this.fechaMax
    }
  },
  mounted() {
    this.initialize();
    if (!this.permisoVista('listas', '$')) {
      this.headers = this.headers.filter(element => {
        return element.value != 'costoEstimado' && element.value != 'costoReal' && element.value != 'costoEstimadoTotal';
      });

      this.headersDetalle = this.headersDetalle.filter(element => {
        return element.value != 'precioUnitario' && element.value != 'montoTotal';
      });
    }
    if (!this.fechaFin) {
      this.fechaMax = this.obtenerFechaActual();
    }
  },
  methods: {
    getPageText: ({ pageStart, pageStop, itemsLength }) => `${pageStart + 1}-${pageStop} de ${itemsLength}`,
    generarReporteOTDS() {
      this.loadReportOT = true;
      axios
        .post(
          "/TrabajoOrdenes/GeneretXlsxReporteTrabajoOrdenes")
        .then(() => {
          this.$refs.aviso.show("Reporte en Progreso", "Se le notificara cuando el reporte este listo para descargar.");
          this.loadReportOT = false;
        })
        .catch((error) => {

          console.log(error);
          this.loadReportOT = false;
        });
    },
    desgloseCostosItem(item) {
      this.$refs.chartGlobal.show("Desglose de Costos - " + item.clave, item.costosGrafica.limits, item.costosGrafica.categories, item.costosGrafica.series);
    },
    desgloseCostosOTDItem(item) {
      this.$refs.chartGlobal.show("Desglose de Costos - " + item.nombre + " - " + (this.clavePersonalizada ? this.watchedItem.clave : this.watchedItem.ot), item.costosGrafica.limits, item.costosGrafica.categories, item.costosGrafica.series);
    },
    getTrabajoOrdenesEstadisticas() {
      this.loadingEstadisticas = true;
      axios
        .get("/TrabajoOrdenes/Estadisticas")
        .then(response => {
          this.loadingEstadisticas = false;
          this.estadisticas = response.data;
        })
        .catch(error => {
          this.loadingEstadisticas = false;
          console.log(error);
        })
    },
    getMontoTotalRealProduccion() {
      this.loadingMontoReal = true;
      axios
        .get("/TrabajoOrdenes/MontoTotalProduccion")
        .then(response => {
          this.loadingMontoReal = false;
          this.montoTotalRealProduccion = response.data;
        })
        .catch(error => {
          this.loadingMontoReal = false;
          console.log(error);
        })
    },
    getTrabajoOrdenesOnEnterAction($event) {
      if ($event instanceof KeyboardEvent) {
        //  Enter
        if ($event.keyCode === 13) this.trabajoOrdenes_update_page_action(1);
      }
    },
    clearSearchAction() {
      this.search = "";
      this.trabajoOrdenes_update_page_action(1);
    },
    async trabajoOrdenes_update_page_action(page) {
      this.trabajoOrdenes_actual_page = page;
      this.loading = true;
      try {
        let response = await this.getTrabajoOrdenes();
        this.trabajoOrdenes = response.data.result;
        this.trabajoOrdenes_total = response.data.total;
        this.loading = false;
        this.getTiempoFinalizacion();

      } catch (exception) {
        console.log(exception);
      }
    },
    trabajoOrdenes_sort_by_action($sort) {
    },
    colorDiasRestantes(dias) {
      if (dias > 5) return 'green--text';
      else if (dias <= 5 && dias > 0) return 'orange--text';
      return 'red--text'
    },
    colorAvance(porcentaje) {
      if (porcentaje <= 0.0)
        return 'red--text';
      else if (porcentaje < 100.0)
        return 'orange--text';
      else
        return 'green--text';
    },
    cambio(event) {
      var orden = event.toLowerCase().split('-');
      if (orden[0] == 'avance' || orden[0] == 'completo' || (orden[0] == 'otd' && orden[1] == 'terminada')) {
        this.initialize();
      }
    },
    getTrabajoOrdenes() {
      //* Realizamos peticion HTTP
      return new Promise((resolve, reject) => {
        axios
          .get(this.trabajoOrdenes_fetch_url)
          .then(response => {
            response.data.result = response.data.result.map(element => {
              if (element.idCliente == null) element.cliente = this.nombreEmpresa;
              element.fechaFinalizacion = null;
              element.faltantesMP = [];
              element.articulos.map(e => {
                e.fechaFinalizacion = null;
                e.faltantesMP = [];
                return e;
              })
              return element;
            });
            resolve(response);
          })
          .catch(error => {
            reject(error);
          });
      });
    },
    initialize() {
      if (this.idTrabajoOrdenMadre == null) {
        this.sound = new Howl({
          src: ['/sounds/notification.webm', '/sounds/notification.mp3']
        });
        this.getTrabajoOrdenesEstadisticas();
        this.getMontoTotalRealProduccion();
      }
      this.trabajoOrdenes_update_page_action(1);
    },
    getTiempoFinalizacion() {
      axios
        .get("/TrabajoOrdenes/TiempoFinalizacion")
        .then(res => {
          res.data.forEach(element => {
            var posOT = this.trabajoOrdenes.findIndex(e => e.idTrabajoOrden == element.idTrabajoOrden);
            if (posOT >= 0) {
              this.trabajoOrdenes[posOT].fechaFinalizacion = element.fecha;
              element.detalles.forEach(detalle => {
                var posDetalle = this.trabajoOrdenes[posOT].articulos.findIndex(e => e.idTrabajoOrdenDetalle == detalle.idTrabajoOrdenDetalle);
                if (posDetalle >= 0) {
                  this.trabajoOrdenes[posOT].articulos[posDetalle].fechaFinalizacion = detalle.fecha;
                }
              });
            }
          });
        })
        .catch(er => {
          console.log(er);
        })
    },
    watchItem(item) {
      this.posWachedItem = this.trabajoOrdenes.indexOf(item);
      this.detalles = true;
    },
    /*
      0: Sin Iniciar
      1: Lanzando
      2: Error
      3: Lanzado
    */
    closeDetalles() {
      this.detalles = false;
      this.posWachedItem = -1;
    },
    generarReporte() {
      this.$refs.aviso.show("Reporte en Progreso", "Se le notificara cuando el reporte este listo para descargar.");
      this.loadingReporte = true;
      axios
        .put("/TrabajoOrdenes/GenerarGraficaPdf")
        .then(() => {
          this.loadingReporte = false;
        })
        .catch(error => {
          console.log(error);
          this.loadingReporte = false;
        })
    },
    generarReporteSemanal() {
      this.$refs.aviso.show("Reporte en Progreso", "Se le notificara cuando el reporte este listo para descargar.");
      this.loadingReporteSemanal = true;
      axios
        .get("/TrabajoOrdenes/CSVReporteTrabajoOrdenes")
        .then(() => {
          this.loadingReporteSemanal = false;
        })
        .catch(error => {
          console.log(error);
          this.loadingReporteSemanal = false;
        })
    },
    desgloseCostosRemanufacturaItem(item) {
      this.posWachedItem = this.trabajoOrdenes.indexOf(item);
      this.$refs.remanufactura.show(item.idTrabajoOrden);
    },
    closeRemanufactura() {
      this.posWachedItem = -1;
    },

    hide_dialogReporteCostos() {
      this.fechaInicio = this.fechaFin = this.errorFechaInicio = this.errorFechaFin = ""
      this.dialogReporteCostos = false
    },
    show_dialogReporteCostos() {
      this.dialogReporteCostos = true
    },
    obtenerFechaActual() {
      const fechaActual = new Date();
      const año = fechaActual.getFullYear();
      let mes = fechaActual.getMonth() + 1;
      let dia = fechaActual.getDate();

      if (mes < 10) {
        mes = `0${mes}`;
      }
      if (dia < 10) {
        dia = `0${dia}`;
      }

      const fechaFormateada = `${año}-${mes}-${dia}`;
      return fechaFormateada;
    },
    generarReporteFechas() {
      if (this.fechaInicio && this.fechaFin) {
        this.loadingReporte = true;
        axios
          .put("/TrabajoOrdenes/GenerarGraficaPdf?desde=" + this.fechaInicio + "&hasta=" + this.fechaFin)
          .then(() => {
            this.loadingReporte = false
            this.hide_dialogReporteCostos()
            //this.$refs.aviso.show("Reporte en Progreso", "Se le notificara cuando el reporte este listo para descargar.");
          })
          .catch(error => {
            console.log(error)
            this.loadingReporte = false
          })
      }
      else {
        if (!this.fechaInicio) this.errorFechaInicio = "Debe seleccionar una Fecha"
        if (!this.fechaFin) this.errorFechaFin = "Debe seleccionar una Fecha"
      }
    },
    show_SeleccionHeaders() {
      this.$refs.seleccionHeaders.show_dialog();
    }
  }
}
</script>

<style scoped>
.fill-height {
  height: 100%;
}

.justify-content {
  justify-content: center !important;
}

.cardBanner {
  height: 116px !important;
  /* padding-top: inherit !important; */
}

.centered-input>>>input {
  text-align: center !important;
}

.footer-input {
  font-size: 12px !important;
  max-width: 40px !important;
}

.border-footer {
  border-top-width: thin;
  border-top-style: solid;
  border-top-color: rgba(0, 0, 0, 0.12);
}
</style>
