<template>
	<div>
		<v-tabs v-model="tab" background-color="transparent" grow centered>
			<v-tab
				v-if="mostrarVista('proveedores')"
				@click="updateProveedores()"
			>
				Proveedores
			</v-tab>

			<v-tab
				v-if="mostrarVista('proveedortipos')"
				@click="updateProveedorTipos()"
			>
				Tipos de Proveedores
			</v-tab>

			<v-tab
				@click="updateTiempoEntrega()"
			>
				Tiempo de Entrega por Proveedor
			</v-tab>

			<v-tabs-items v-model="tab" touchless>
				<v-tab-item v-if="mostrarVista('proveedores')">
					<v-card flat>
						<v-card-text class="px-0 pb-0">
							<comprasProveedores
								ref="proveedores"
							></comprasProveedores>
						</v-card-text>
					</v-card>
				</v-tab-item>

				<v-tab-item v-if="mostrarVista('proveedortipos')">
					<v-card flat>
						<v-card-text class="px-0 pb-0">
							<comprasProveedorTipos
								ref="proveedortipos"
							></comprasProveedorTipos>
						</v-card-text>
					</v-card>
				</v-tab-item>

				<v-tab-item>
					<v-card flat>
						<v-card-text class="px-0 pb-0">
							<ProveedorTiemposEntrega ref="proveedortiempos" />
						</v-card-text>
					</v-card>
				</v-tab-item>
			</v-tabs-items>
		</v-tabs>
	</div>
</template>

<script>
import Proveedores from "./Proveedores.vue";
import ProveedorTiemposEntrega from './ProveedorTiemposEntrega.vue';
import ProveedorTipos from "./ProveedorTipos.vue";

export default {
	components: {
        ComprasProveedores: Proveedores,
        ComprasProveedorTipos: ProveedorTipos,
		ProveedorTiemposEntrega,
	},
	data: () => ({
		tab: 0,
	}),
	methods: {
		initialize() {
            this.updateProveedores();
            this.updateProveedorTipos();
			this.updateTiempoEntrega();
		},
		updateTiempoEntrega(){
			if(typeof this.$refs.proveedortiempos === 'undefined') return;
            this.$refs.proveedortiempos.initialize();
		},
        updateProveedores(){
            if(typeof this.$refs.proveedores === 'undefined') return;
            this.$refs.proveedores.initialize();
        },
        updateProveedorTipos(){
            if(typeof this.$refs.proveedortipos === 'undefined') return;
            this.$refs.proveedortipos.initialize();
        },
	},
};
</script>