// https://vuex.vuejs.org/en/state.html

const getIdsReportes = () => {
    try {
        return JSON.parse(localStorage.getItem('idsReportes'));
    } catch (error) {
        return [];
    }
};

const getPreferenciasUsuario = () => {
    try {
        var preferencias = JSON.parse(localStorage.getItem('preferenciasUsuario')) || {};
        return preferencias;
    } catch (error) {
        return {};
    }
};


export default {
    authStatus: '',
    token: localStorage.getItem('token') || '',
    user: localStorage.getItem('user') || '',
    routes: JSON.parse(localStorage.getItem('routes')),
    idUser: parseInt(localStorage.getItem('idUser')),
    idRol: parseInt(localStorage.getItem('idRol')),
    idsTurnos: [],
    preferenciasUsuario: getPreferenciasUsuario(),
    //0.- todos los reportes, 1.- reportes de sensores, 2.- reportes sin sensores
    tipoFiltroReportes: 2,
    //idsReportes: localStorage.getItem('idsReportes')!=null?JSON.parse(localStorage.getItem('idsReportes')):[],
    idsReportes: getIdsReportes(),
    //0.- todos los reportes, 1.- reportes de sensores, 2.- reportes sin sensores
    tipoFiltroReportes: 2,
    validado: (localStorage.getItem('validado') == null) ? true : localStorage.getItem('validado') == 'true',
    cambiarPassword: (localStorage.getItem('cambiarPassword') == null) ? false : localStorage.getItem('cambiarPassword') == 'true',
    desconocidos: true,
    desconocidosIds: [],
    netError: false,
    internetStatus: 0,
    nombreEmpresa: "Managrx",
    clavePersonalizada: false,
    calculadoraRollos: false,
    maquila: false,
    cargasMasivas: false,
    inventario: false,
    finalizarLabel: "Finalizar",
    pinLength: 4,
    loginOpsModo: 1,
    loginOpsLength: 8,
    pinType: "numbers",
    rfid: false,
    rutasAprendizaje: false,
    reporteAuxiliar: false,
    pantografo: true,
    anchoMaximo: "95%",
    qrOperador: false,
    mqtt: false,
    webPushKey: "BNc5fgO5KiglWjC3bc1hrnw2sPfuE4N1WXXxrxgBFZ2xwNTlIzW-pxKZIKIMIWrCtafbWh_BSmvDae36UWokPIA",
    colorTabs: "blue darken-3", //default: blue lighten-2 accent-4
    modoAyuda: JSON.parse(localStorage.getItem('modoAyuda') || false),
    numOTS: 10,
    trazabilidadInventario: true,
    movimientosFactura: false,
    blackDecker: false,
    listadoChips: false,
    iniciarAutoNuevaODT: false,
    demostracion: false,
    moldes: true,
    odtAgrupadas: false,
    operacionesCantidadCero: false,
    editarPzsNoOk: false,
    editarTiempoMuerto: false,
    salidasQR: false,
    cantidadObjetivoQR: false,
    oeeRendimientoLabel: "Rendimiento",
    linkTelegram: null,
    idSubAlmacen: -1,
    pausarActividadMtto: true,
    whatsapp: false
}
