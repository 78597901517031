<template>
	<div>
		<v-tabs v-model="tab" background-color="transparent" grow>
			<v-tab @click="almacenesClick"> Almacenes </v-tab>

			<v-tab @click="subAlmacenesClick"> Subalmacenes </v-tab>

			<v-tabs-items v-model="tab" touchless>
				<v-tab-item eager>
					<Almacen ref="almacenes" />
				</v-tab-item>
				<v-tab-item eager>
					<Subalmacenes ref="subalmacenes" />
				</v-tab-item>
			</v-tabs-items>
		</v-tabs>
	</div>
</template>

<script>
import Almacen from "./Almacen/Almacen.vue";
import Subalmacenes from "./Almacen/Subalmacenes.vue";

export default {
	components: {
		Almacen,
		Subalmacenes,
	},
	data: () => ({
		tab: 0,
	}),
	methods: {
		initialize() {
			this.almacenesClick();
      this.subAlmacenesClick();
		},
		almacenesClick() {
			if (typeof this.$refs.almacenes === "undefined") return;
			this.$refs.almacenes.initialize();
		},
    subAlmacenesClick() {
			if (typeof this.$refs.subalmacenes === "undefined") return;
			this.$refs.subalmacenes.initialize();
		},
	},
};
</script>