<template>
  <v-card
    :color="colorCard(item.status)"
    dark
    class="flex"
  >
    <v-card-text class="d-flex align-center" style="height: 100%">
      <v-container fluid class="ma-0 pa-0">
        <v-row align="center" justify="center">
          <v-col cols="12">
            <div class="columna">
              <div class="text-center text-h6 white--text">{{item.codigoMaquina}}</div>
            </div>
          </v-col>
        </v-row>
        <v-row class="ma-0 pa-0">       
          <v-col cols="12" class="ma-0 pa-0">
            <div class="d-flex justify-center align-center pa-0">
              <div style="position: relative; !important">
                <apexchart
                type="radialBar"
                height="275"
                :options="chartOptions"
                :series="[item.oee]"
                ></apexchart>
              </div>
              <div class="apex-custom-wrap text-center">
                <ICountUp
                  :endVal="item.oee"
                  class="text-h4 font-weight-bold"
                  :options="{ suffix: '%' }"
                />
                <p
                  class="mb-1 text-subtitle-1"
                  style="font-size: 0.875rem !important"
                >
                  OEE
                </p>
              </div>
            </div>
          </v-col>
        </v-row>
        <v-row class="ma-0 pa-0">
          <v-col cols="4" class="pa-0 ma-0 white--text text-center">
            <span class="text-h6">{{item.disponibilidad.toFixed(2)}}%</span>
            <br>
            Disponibilidad
          </v-col>
          <v-col cols="4" class="pa-0 ma-0 white--text text-center">
            <span class="text-h6">{{item.rendimiento.toFixed(2)}}%</span>
            <br>
            Rendimiento
          </v-col>
          <v-col cols="4" class="pa-0 ma-0 white--text  text-center">
            <span class="text-h6">{{item.calidad.toFixed(2)}}%</span>
            <br>
            Calidad
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12">
            <div class="columna">
              <template v-if="item.status == 0">
                <div class="text-center text-h6 white--text">Sin Actividad</div>
              </template>
              <template v-if="item.status >= 1">
                <div class="text-center text-h6 white--text">{{item.ot}}</div>
              </template>
              <template v-if="item.status >= 2 && item.paroTipo != null">
                <div class="text-center text-subtitle-1 white--text">Paro: {{item.paroTipo}}</div>
              </template>
              <template v-if="item.status >= 2 && item.paroSubTipo != null">
                <div class="text-center text-subtitle-2 white--text">SubParo: {{item.paroSubTipo}}</div>
              </template>
            </div>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12">
            <div class="columna">
              <template v-if="item.status == 0 || item.status == 1">
                <div class="text-center text-h6 white--text">Total: {{$utils.strings.toTime(segundos)}}</div>
              </template>
              <template v-if="item.status == 1">
                <div class="text-center text-subtitle-1 white--text">Paro: {{$utils.strings.toTime(item.tiempoParoSegundos)}}</div>
              </template>
              <template v-if="item.status >= 2">
                <div class="text-center text-h6 white--text">Paro: {{$utils.strings.toTime(segundos)}}</div>
              </template>
            </div>
          </v-col>
        </v-row>
      </v-container>
    </v-card-text> 
  </v-card>
</template>

<script>
import moment from "moment"
import ICountUp from "vue-countup-v2";

export default {
  components: {
		ICountUp,
	},
  props:{
    item: {
      type: Object,
      default: () => ({}),
    }
	},
  data: () => ({
    segundos: 0,
    interval: null,
    chartOptions: {
				chart: {
          offsetY: 0,
					type: "radialBar",
				},
				colors: ['#FFFFFF'],
				plotOptions: {
					radialBar: {
            track: {
              background: "#757575",
            },
						hollow: {
							size: "65%",
						},
						dataLabels: {
							show: false,
						},
					},
				},
			},
  }),
  computed: {
  },
  created(){
    try {
      var datetime = moment(this.item.fechaInicio);
      this.segundos = Math.trunc(
        (moment().valueOf() - datetime.valueOf()) / 1000
      );
      if (isNaN(this.segundos)) this.segundos = 0;
      this.iniciar();

    } catch (error) {
      this.iniciar();
    }
  },
  destroyed(){
    this.detener();
  },
  methods:{
    colorCard(status){
      switch (status) {
        case 0:
          return "#2b78e4";
        case 1:
          return "#009e0f";
        case 2:
          return "#009e0f";
        case 3:
          return "#ffc000";
        case 4:
          return "#cf2a27";
        case 5:
          return "#660000";
        default:
          return "#952175";
      }
    },
    iniciar(){
      if(this.interval != null) return;

      this.interval = setInterval(() => {
        this.segundos += 1;
        if(this.item.status == 2 && this.segundos >= (this.item.tiempoCambioMoldeSeg)){
          this.$emit('status',3);
        }
        if(this.item.status == 3 && this.segundos >= (this.item.tiempoCambioMoldeSeg+(15*60))){
          this.$emit('status',4);
        }
      },1000);
    },
    detener(){
      if(this.interval == null || typeof this.interval == 'undefined') return;
      clearInterval(this.interval);
      this.interval = null;
    }
  }
}
</script>

<style>
.columna {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.apex-custom-wrap {
	position: absolute;
	top: 9.5rem;
}
</style>