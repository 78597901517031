<template>
	<v-dialog v-model="mostrar" persistent max-width="75%">
		<v-card>
			<v-card-title>
				{{
					procesoItem.idRetrabajoProceso > 0 ? "Actualizar" : "Crear"
				}}
				Retrabajo Proceso
			</v-card-title>
			<v-card-text>
				<v-row class="ma-0 pa-0">
					<v-col cols="4" class="ma-0 pa-0 pr-3">
						<v-select
							v-model="procesoItem.idArticulo"
							:items="articulosTipos"
							item-value="idArticulo"
							:item-text="nombreArticulo"
							label="Tipo de Artículo"
						></v-select>
					</v-col>
					<v-col cols="4" class="ma-0 pa-0">
						<v-text-field
							v-model="procesoItem.codigo"
							label="Código del Proceso de Remanufactura"
						></v-text-field>
					</v-col>
					<v-col cols="4" class="ma-0 py-0">
						<v-text-field
							v-model="procesoItem.nombre"
							label="Nombre del Proceso de Remanufactura"
						></v-text-field>
					</v-col>
					<v-col
						v-for="(area, idx) in areasSeleccionables"
						:key="'a-' + idx"
						cols="3"
						class="my-0 py-0"
					>
						<v-row class="ma-0 pa-0">
							<v-col cols="12" class="ma-0 pa-0">
								<v-checkbox
									v-model="areas[idx].activo"
									:label="area.nombre"
								></v-checkbox>
							</v-col>
						</v-row>
						<v-row class="ma-0 pa-0">
							<v-col cols="12" class="ma-0 pa-0">
								<span style="font-size: 10px"
									>Tiempo Máximo (HH:MM:SS)</span
								>
								<time-picker
									v-model="areas[idx].tiempo"
									:disabled="!areas[idx].activo"
									dense
									:error="tiempoMinimo(areas[idx].tiempo)"
								></time-picker>
							</v-col>
						</v-row>
					</v-col>
					<v-col cols="12">
						<br />
						<div class="text-h5 text-center">Elementos</div>
						<v-data-table
							v-if="loadingAdicionalInfo < 3"							
							:loading="loadingAdicionalInfo < 3"
						>
							<template v-slot:loading>
								Cargando. Espere un momento por favor.
							</template>
						</v-data-table>
						<v-data-table
							v-else
							:loading="loadingAdicionalInfo < 3"
							:headers="headers"
							:items="elementos"
							:items-per-page="-1"
							hide-default-footer
							disable-sort
						>
							<template v-slot:loading>
								Cargando. Espere un momento por favor.
							</template>
							<template v-slot:no-data>
								No se ha ingresado ningún elemento
							</template>
							<template v-slot:item.area="{ item }">
								<v-select
									v-model="item.idArea"
									:items="areasValidas"
									item-value="idArea"
									item-text="area"
									dense
									hide-details
								>
								</v-select>
							</template>
							<template v-slot:item.tipo="{ item }">
								<v-select
									v-model="item.idTipo"
									:items="tipos"
									dense
									hide-details
								>
								</v-select>
							</template>
							<template v-slot:item.info="{ item }">
								<template v-if="item.idTipo == 1">
									<v-row class="ma-0 pa-0">
										<v-col cols="4" class="ma-0 pa-2">
											<v-row class="ma-0 pa-0">
												<v-col
													cols="10"
													class="ma-0 pa-0"
												>
													<v-text-field-number
														v-model="item.cantidad"
														dense
														hide-details
														decimal
														:label="'Cantidad'"
														:min="0"
													>
													</v-text-field-number>
												</v-col>
												<v-col
													cols="2"
													class="ma-0 pa-0"
													style="align-self: center"
												>
													{{
														item.articulo
															.numDimensiones !=
															null &&
														item.articulo
															.numDimensiones >= 2
															? "Pza"
															: item.articulo
																	.unidad
													}}
												</v-col>
											</v-row>
										</v-col>
										<v-col cols="8" class="ma-0 pa-2">
											<v-row class="ma-0 pa-0">
												<v-col class="ma-0 py-0 px-1">
													<v-autocomplete
														v-model="
															item.idArticulo
														"
														label="Artículo"
														:items="articulos"
														:item-text="
															nombreArticulo
														"
														item-value="idArticulo"
														dense
														hide-details
														@change="
															cambioArticulo(
																item,
																item.idArticulo
															)
														"
													></v-autocomplete>
												</v-col>
												<v-col
													v-if="
														colsNumDimensiones(
															item.articulo
																.numDimensiones
														)[1] > 0
													"
													:cols="
														colsNumDimensiones(
															item.articulo
																.numDimensiones
														)[1]
													"
													class="ma-0 py-0 px-1"
												>
													<v-text-field-number
														v-model="item.ejeX"
														:label="
															'Largo (' +
															item.articulo
																.unidadBase +
															')'
														"
														dense
														hide-details
														decimal
														:min="0"
													></v-text-field-number>
												</v-col>
												<v-col
													v-if="
														colsNumDimensiones(
															item.articulo
																.numDimensiones
														)[2] > 0
													"
													:cols="
														colsNumDimensiones(
															item.articulo
																.numDimensiones
														)[2]
													"
													class="ma-0 py-0 px-1"
												>
													<v-text-field-number
														v-model="item.ejeY"
														:label="
															'Ancho (' +
															item.articulo
																.unidadBase +
															')'
														"
														dense
														hide-details
														decimal
														:min="0"
													></v-text-field-number>
												</v-col>
												<v-col
													v-if="
														colsNumDimensiones(
															item.articulo
																.numDimensiones
														)[3] > 0
													"
													:cols="
														colsNumDimensiones(
															item.articulo
																.numDimensiones
														)[3]
													"
													class="ma-0 py-0 px-1"
												>
													<v-text-field-number
														v-model="item.ejeZ"
														:label="
															'Alto (' +
															item.articulo
																.unidadBase +
															')'
														"
														dense
														hide-details
														decimal
														:min="0"
													></v-text-field-number>
												</v-col>
											</v-row>
										</v-col>
									</v-row>
								</template>
								<template v-if="item.idTipo == 2">
									<v-row class="ma-0 pa-0">
										<v-col cols="4" class="ma-0 pa-2">
											<v-text-field-number
												v-model="item.cantidad"
												dense
												hide-details
												decimal
												label="Cantidad"
												:min="0"
											>
											</v-text-field-number>
										</v-col>
										<v-col cols="4" class="ma-0 pa-2">
											<v-autocomplete
												v-model="item.idPuesto"
												label="Puesto"
												:items="puestos"
												item-text="nombre"
												item-value="idPuesto"
												dense
												hide-details
											></v-autocomplete>
										</v-col>
										<v-col
											cols="4"
											class="ma-0 py-0 px-2 text-left"
										>
											<span style="font-size: 10px"
												>Tiempo(HH:MM:SS)</span
											>
											<time-picker
												v-model="item.tiempo"
												dense
											></time-picker>
										</v-col>
									</v-row>
								</template>
								<template v-if="item.idTipo == 3">
									<v-row class="ma-0 pa-0">
										<v-col cols="4" class="ma-0 pa-2">
											<v-text-field-number
												v-model="item.cantidad"
												dense
												hide-details
												decimal
												label="Cantidad"
												:min="0"
											>
											</v-text-field-number>
										</v-col>
										<v-col cols="4" class="ma-0 pa-2">
											<v-autocomplete
												v-model="item.idMaquina"
												:items="maquinas"
												:item-text="nombreMaquina"
												item-value="idMaquina"
												label="Máquina"
												dense
												hide-details
											></v-autocomplete>
										</v-col>
										<v-col
											cols="4"
											class="ma-0 py-0 px-2 text-left"
										>
											<span style="font-size: 10px"
												>Tiempo(HH:MM:SS)</span
											>
											<time-picker
												v-model="item.tiempo"
												dense
											></time-picker>
										</v-col>
									</v-row>
								</template>
								<template v-if="item.idTipo == 4">
									<v-row class="ma-0 pa-0">
										<v-col cols="4" class="ma-0 pa-2">
											<v-text-field-number
												v-model="item.cantidad"
												dense
												hide-details
												decimal
												label="Cantidad"
												:min="0"
											>
											</v-text-field-number>
										</v-col>
										<v-col cols="4" class="ma-0 pa-2">
											<v-select
												v-model="item.idHerramienta"
												:items="herramientas"
												:item-text="nombreMaquina"
												item-value="idMaquina"
												label="Herramienta"
												dense
												hide-details
											></v-select>
										</v-col>
										<v-col
											cols="4"
											class="ma-0 py-0 px-2 text-left"
										>
											<span style="font-size: 10px"
												>Tiempo(HH:MM:SS)</span
											>
											<time-picker
												v-model="item.tiempo"
												dense
											></time-picker>
										</v-col>
									</v-row>
								</template>
							</template>
						</v-data-table>
						<v-btn
							:disabled="loadingAdicionalInfo < 3"
							color="primary"
							text
							@click="agregarElemento"
						>
							Agregar Elemento
						</v-btn>
					</v-col>
				</v-row>
				<v-row class="ma-0 pa-0">
					<v-col cols="12" class="ma-0 pa-0">
						<ul>
							<li v-for="(error, idx) in errores" :key="idx">
								{{ error }}
							</li>
						</ul>
					</v-col>
				</v-row>
			</v-card-text>
			<v-card-actions>
				<v-spacer></v-spacer>
				<v-btn color="green" :disabled="saving" text @click="cerrar"> Cancelar </v-btn>
				<v-btn
					color="red"
					:loading="saving"
					:disabled="saving"
					text
					@click="save"
				>
					Guardar
				</v-btn>
			</v-card-actions>
		</v-card>
	</v-dialog>
</template>

<script>
import axios from "axios";
import timepicker from "@/components/TimePicker";
import vtextfieldnumber from "@/components/VTextFieldNumber.vue";

export default {
	components: {
		"time-picker": timepicker,
		"v-text-field-number": vtextfieldnumber,
	},
	watch: {
		nuevoProceso() {
			if (
				this.nuevoProceso == true &&
				this.loadedAdicionalInfo == false
			) {
				//this.loadAdicionalInfo();
				//this.loadedAdicionalInfo = true;
			}
		},
		loadingAdicionalInfo() {
			if (this.loadingAdicionalInfo < 3) return;
			this.postProcesado();
		},
	},
	data: () => ({
		saving: false,
		mostrar: false,
		procesoItem: {
			idRetrabajoProceso: -1,
			nombre: "",
			codigo: "",
			idArticulo: -1,
		},
		defaultProcesoItem: {
			idRetrabajoProceso: -1,
			nombre: "",
			codigo: "",
			idArticulo: -1,
		},
		editedItem: 1,
		cantidadMaxima: 0,
		seleccionados: [],
		areasSeleccionables: [],
		cantidad: 0,
		procesos: [],
		proceso: null,
		loading: 0,
		nuevoProceso: false,
		areas: [],
		tipos: [
			{
				text: "Materia Prima",
				value: 1,
			},
			{
				text: "Trabajador",
				value: 2,
			},
			{
				text: "Máquina",
				value: 3,
			},
			{
				text: "Herramienta",
				value: 4,
			},
		],
		headers: [
			{
				text: "Área",
				align: "center",
				sortable: false,
				value: "area",
				width: "15%",
			},
			{
				text: "Tipo",
				align: "center",
				sortable: false,
				value: "tipo",
				width: "15%",
			},
			{
				text: "Información Adicional",
				align: "center",
				sortable: false,
				value: "info",
				width: "70%",
			},
		],
		elementos: [],
		defaultElemento: {
			idArea: -1,
			idTipo: -1,
			idArticulo: -1,
			idPuesto: -1,
			idMaquina: -1,
			idHerramienta: -1,
			ejeX: null,
			ejeY: null,
			ejeZ: null,
			articulo: {
				numDimensiones: null,
				unidad: "",
				unidadAuxiliar: "",
				unidadBase: "",
			},
		},
		defaultArticulo: {
			numDimensiones: null,
			unidad: "",
			unidadAuxiliar: "",
			unidadBase: "",
		},
		loadingAdicionalInfo: 0,
		loadedAdicionalInfo: false,
		maquinas: [],
		herramientas: [],
		puestos: [],
		articulos: [],
		articulosTipos: [],
		estandarizar: false,
		nombre: "",
		codigo: "",
		errores: [],
	}),
	computed: {
		areasValidas() {
			return this.areas.filter((a) => a.activo == true);
		},
	},
	methods: {
		postProcesado() {
			this.elementos.forEach((e) => {
				if (e.idArticulo !== null && e.idArticulo > 0) {
					var articulo = this.articulos.find(
						(a) => a.idArticulo === e.idArticulo
					);
					if (this.$utils.isValid(articulo)) e.articulo = articulo;
				}
				if (e.idMaquina !== null && e.idMaquina > 0) {
					var isMaquina = this.maquinas.some(
						(m) => m.idMaquina === e.idMaquina
					);
					if (!isMaquina) {
						e.idTipo = 4;
						e.idHerramienta = e.idMaquina;
						e.idMaquina = null;
					}
				}
			});
		},
		nombreProceso: (proceso) =>
			"[" + proceso.codigo + "] - " + proceso.nombre,
		nombreMaquina: (maquina) => maquina.marca + " - " + maquina.modelo,
		nombreArticulo: (articulo) =>
			"[" + articulo.codigo + "] -" + articulo.nombre,
		mostrarRetrabajo(item) {
			this.editedItem = 2; //Actualizar
			this.getAreas();
			this.procesoItem = item;
			this.elementos = item.ingredientes.map((ingrediente) => ({
				...ingrediente,
				idTipo:
					(ingrediente.idArticulo !== null && 1) ||
					(ingrediente.idPuesto !== null &&
						ingrediente.idPuesto !== 3 &&
						2) ||
					(ingrediente.idMaquina !== null && 3) ||
					4,
				articulo:
					ingrediente.idArticulo !== null &&
					ingrediente.idArticulo > 0
						? Object.assign({}, this.defaultArticulo)
						: null,
				idHerramienta: null,
			}));
			if (this.loadingAdicionalInfo >= 3) this.postProcesado();
			this.mostrar = true;
		},
		mostrarCrearRetrabajo() {
			this.editedItem = 1; //Crear
			this.getAreas();
			this.procesoItem = Object.assign({}, this.defaultProcesoItem);
			this.elementos = [];
			this.mostrar = true;
		},
		getAreas() {
			axios
				.get("/Areas?MostrarRetrabajo=true")
				.then((response) => {
					const areasResponse = response.data.filter(
						(area) => area.idArea > 1
					);
					this.areasSeleccionables = areasResponse.filter(
						(area) => area.retrabajo === null || !area.retrabajo
					);
					if (this.procesoItem.idRetrabajoProceso > 0) {
						this.areas = areasResponse.map((a) => {
							const areaItem = this.procesoItem.areas.find(
								(areaItem) => areaItem.idArea === a.idArea
							);
							return {
								...a,
								area: a.nombre,
								activo: areaItem !== undefined ? true : false,
								tiempo:
									areaItem !== undefined
										? areaItem.tiempo
										: 0,
							};
						});
					} else {
						this.areas = areasResponse.map((a) => ({
							...a,
							area: a.nombre,
							activo: false,
							tiempo: 0,
						}));
					}
				})
				.catch((error) => {
					console.log(error);
				});
		},
		cerrar() {
			this.mostrar = false;
			this.procesoItem = Object.assign({}, this.defaultProcesoItem);
			this.cantidadMaxima = 0;
			this.areasSeleccionables = [];
			this.seleccionados = [];
			this.cantidad = 0;
			this.procesos = [];
			this.proceso = null;
			this.nuevoProceso = false;
			this.areas = [];
			this.elementos = [];
			this.estandarizar = false;
			this.nombre = "";
			this.codigo = "";
		},
		resetProceso() {
			this.proceso = null;
		},
		agregarElemento() {
			this.elementos.push(Object.assign({}, this.defaultElemento));
		},
		loadAdicionalInfo() {
			this.getMaquinas();
			this.getPuestos();
			this.getTiposArticulos();
			this.getArticulos();
		},
		getMaquinas() {
			axios
				.get("/Maquinas")
				.then((response) => {
					this.maquinas = response.data.filter(function (el) {
						return el.esMaquina == true;
					});
					this.herramientas = response.data.filter(function (el) {
						return el.esMaquina == false;
					});
					this.loadingAdicionalInfo += 1;
				})
				.catch((error) => {
					console.log(error);
				});
		},
		getPuestos() {
			axios
				.get("/Puestos")
				.then((response) => {
					this.puestos = response.data.filter(
						(p) => p.produccion == true
					);
					this.loadingAdicionalInfo += 1;
				})
				.catch((error) => {
					console.log(error);
				});
		},
		getTiposArticulos() {
			axios
				.get("/Articulos/Tipo/3")
				.then((response) => {
					this.articulosTipos = response.data;
				})
				.catch((error) => {
					console.log(error);
				});
		},
		getArticulos() {
			axios
				.get("/Articulos/ArticulosProduccion?precio=true")
				.then((response) => {
					this.articulos = response.data;
					this.loadingAdicionalInfo += 1;
				})
				.catch((error) => {
					console.log(error);
				});
		},
		cambioArticulo(item, idArticulo) {
			var art = this.articulos.find((a) => a.idArticulo == idArticulo);
			if (!this.$utils.isValid(art))
				item.articulo = Object.assign({}, this.defaultArticulo);
			else item.articulo = art;
		},
		colsNumDimensiones(numDimensiones) {
			if (numDimensiones == null || numDimensiones == 1)
				return [12, 0, 0, 0];
			else if (numDimensiones == 2) return [8, 2, 2, 0];
			else return [6, 2, 2, 2];
		},
		tiempoMinimo(value) {
			if (value > 0) return false;
			else return true;
		},
		validate() {
			this.errores = [];

			// if (this.nuevoProceso || this.procesos.length <= 0) {
			var numAreas = this.areas.filter((a) => a.activo == true).length;
			if (numAreas <= 0) this.errores.push("No hay áreas activas.");

			var numAreasTiempo = this.areas.filter(
				(a) => a.activo == true && a.tiempo > 0
			).length;
			if (numAreas != numAreasTiempo)
				this.errores.push(
					"Hay áreas activas que tienen un tiempo de 00:00:00 (HH:MM:SS)."
				);

			if (this.elementos.length <= 0)
				this.errores.push("No se ha indicado elementos.");

			this.elementos.forEach((e, idx) => {
				if (e.idArea <= 0)
					this.errores.push(
						"Falta indicar un área en el elemento " +
							(idx + 1) +
							"."
					);
				if (e.idTipo == 1) {
					if (e.cantidad <= 0)
						this.errores.push(
							"La cantidad debe de ser mayor que cero en el elemento " +
								(idx + 1) +
								"."
						);
					if (e.idArticulo <= 0)
						this.errores.push(
							"Falta indicar el Artículo en el elemento " +
								(idx + 1) +
								"."
						);

					if (
						e.articulo.numDimensiones != null &&
						e.articulo.numDimensiones > 1
					) {
						if (e.ejeX <= 0 && e.articulo.numDimensiones >= 2)
							this.errores.push(
								"La cantidad en el Largo debe de ser mayor que cero en el elemento " +
									(idx + 1) +
									"."
							);
						if (e.ejeY <= 0 && e.articulo.numDimensiones >= 2)
							this.errores.push(
								"La cantidad en el Ancho debe de ser mayor que cero en el elemento " +
									(idx + 1) +
									"."
							);
						if (e.ejeZ <= 0 && e.articulo.numDimensiones == 3)
							this.errores.push(
								"La cantidad en el Alto debe de ser mayor que cero en el elemento " +
									(idx + 1) +
									"."
							);
					}
				} else if (e.idTipo >= 2 && e.idTipo <= 4) {
					if (e.cantidad <= 0)
						this.errores.push(
							"La cantidad debe de ser mayor que cero en el elemento " +
								(idx + 1) +
								"."
						);
					if (e.idTipo == 2 && e.idPuesto <= 0)
						this.errores.push(
							"Falta indicar el Puesto en el elemento " +
								(idx + 1) +
								"."
						);
					if (e.idTipo == 3 && e.idMaquina <= 0)
						this.errores.push(
							"Falta indicar la Máquina en el elemento " +
								(idx + 1) +
								"."
						);
					if (e.idTipo == 4 && e.idHerramienta <= 0)
						this.errores.push(
							"Falta indicar la Herramienta en el elemento " +
								(idx + 1) +
								"."
						);

					if (e.tiempo <= 0)
						this.errores.push(
							"El tiempo tiene que ser mayor que 00:00:00 (HH:MM:SS) en el elemento " +
								(idx + 1) +
								"."
						);
					else {
						var area = this.areas.find((a) => a.idArea == e.idArea);
						if (e.tiempo > area.tiempo)
							this.errores.push(
								"El tiempo del elemento " +
									(idx + 1) +
									" es mayor que el del Área de " +
									area.area +
									"."
							);
					}
				} else
					this.errores.push(
						"Falta indicar el tipo de recurso en el elemento " +
							(idx + 1) +
							"."
					);
			});

			// if (this.estandarizar) {
			if (this.procesoItem.codigo.length < 4)
				this.errores.push(
					"El Código del Proceso debe de tener al menos 4 caracteres"
				);
			if (this.procesoItem.nombre.length < 6)
				this.errores.push(
					"El Nombre del Proceso debe de tener al menos 6 caracteres"
				);

			if (this.procesoItem.codigo.length >= 50)
				this.errores.push(
					"El Código del Proceso debe de tener menos de 50 caracteres"
				);
			if (this.procesoItem.nombre.length >= 100)
				this.errores.push(
					"El Nombre del Proceso debe de tener menos de 100 caracteres"
				);
			// }
			// }

			return this.errores.length == 0;
		},
		save() {
			if (!this.validate()) return;

			this.saving = true;

			var areas = this.areas
				.filter((a) => a.activo == true)
				.map((a) => {
					return {
						IdArea: a.idArea,
						Tiempo: a.tiempo,
					};
				});

			var ingredientes = this.elementos.map((e) => {
				return {
					IdArea: e.idArea,
					IdArticulo: e.idTipo == 1 ? e.idArticulo : null,
					IdMaquina:
						e.idTipo == 3 || e.idTipo == 4
							? e.idTipo == 3
								? e.idMaquina
								: e.idHerramienta
							: null,
					IdPuesto: e.idTipo == 2 ? e.idPuesto : null,
					IdDesconocidoIns: null,
					Cantidad: e.cantidad,
					Tiempo: e.idTipo >= 2 && e.idTipo <= 4 ? e.tiempo : null,
					Remplazar: false,
					Proporcional:
						e.idTipo == 1 && e.articulo.numDimensiones >= 2,
					Fraccion:
						e.idTipo == 1 && e.articulo.numDimensiones >= 2
							? e.articulo.numDimensiones
								? e.ejeX * e.ejeY
								: e.ejeX * e.ejeY * e.ejeZ
							: null,
					EjeX:
						e.idTipo == 1 && e.articulo.numDimensiones >= 2
							? e.ejeX
							: null,
					EjeY:
						e.idTipo == 1 && e.articulo.numDimensiones >= 2
							? e.ejeY
							: null,
					EjeZ:
						e.idTipo == 1 && e.articulo.numDimensiones == 3
							? e.ejeZ
							: null,
				};
			});

			var obj = {
				// IdParo: this.procesoItem.idRetrabajoProceso,
				// IdRetrabajoProceso: !(
				// 	this.nuevoProceso || this.procesos.length <= 0
				// )
				// 	? this.proceso
				// 	: null,
				// Cantidad: this.cantidad,
				// Guardar:
				// 	(this.nuevoProceso || this.procesos.length <= 0) &&
				// 	this.estandarizar,
				Areas: areas,
				idArticulo: this.procesoItem.idArticulo,
				Nombre: this.procesoItem.nombre,
				Codigo: this.procesoItem.codigo,
				Ingredientes: ingredientes,
			};

			if (this.editedItem === 1) {
				//Crear
				axios
					.post("/Retrabajo/CrearRetrabajo", obj)
					.then(() => {
						this.saving = false;
						this.refreshProcesos();
						this.cerrar();
					})
					.catch((error) => {
						this.saving = false;
						console.log(error);
					});
			} else {
				//Actualizar
				axios
					.put(
						`/Retrabajo/Actualizar/${this.procesoItem.idRetrabajoProceso}`,
						obj
					)
					.then(() => {
						this.saving = false;
						this.refreshProcesos();
						this.cerrar();
					})
					.catch((error) => {
						this.saving = false;
						console.log(error);
					});
			}
		},
		refreshProcesos() {
			this.$emit("refresh");
		},
	},
	created() {
		this.loadAdicionalInfo();
		this.loadedAdicionalInfo = true;
	},
};
</script>