<template>
	<v-dialog v-model="dialog" max-width="500px" persistent>
		<v-card>
			<v-card-title
				>{{
					detailsMode ? "Detalles del" : "Agregar"
				}}
				Molde</v-card-title
			>
			<v-card-text>
				<v-container fluid>
					<v-row v-if="!detailsMode">
						<v-col cols="12">
							<v-autocomplete
								v-model="molde"
								label="Seleccionar molde"
								:items="moldesComputed"
								:item-text="nombreMolde"
								item-value="idMolde"
								clearable
								:error-messages="errorMolde"
								return-object
								:disabled="loadingMoldes"
								:loading="loadingMoldes"
								@click:clear="onClearSelectModel"
								@focus="errorMolde = ''"
								@change="onChangeMolde"
							></v-autocomplete>
						</v-col>
					</v-row>
					<v-row v-if="molde" justify="center">
						<v-col
							cols="12"
							class="pt-0 d-flex justify-center align-center"
						>
							<div v-if="loadingImage" class="text-center">
								<v-progress-circular
									:size="50"
									color="primary"
									indeterminate
								></v-progress-circular>
								<p class="mb-0 text-subtitle-1">
									Cargando imagen
								</p>
							</div>
							<div
								v-else-if="!localImage"
								class="text-center"
								style="min-width: 180px"
							>
								<v-icon size="100" color="grey"
									>mdi-file-image</v-icon
								>
							</div>
							<template v-else-if="localImage">
								<v-scale-transition hide-on-leave>
									<v-img
										:src="localImage"
										aspect-ratio="1.7778"
										style="cursor: default"
										:key="`imagen-${molde.idMolde}`"
										contain
									></v-img>
								</v-scale-transition>
							</template>
						</v-col>
					</v-row>
					<v-row v-if="molde">
						<v-col cols="12">
							<v-simple-table>
								<template v-slot:default>
									<tbody>
										<tr
											v-for="(
												header, index
											) in moldesHeaders"
											:key="`tr-${index}`"
										>
											<td
												class="text-center font-weight-bold"
											>
												{{ header.text }}
											</td>
											<td class="text-center">
												{{ molde[header.value] }}
											</td>
										</tr>
									</tbody>
								</template>
							</v-simple-table>
						</v-col>
					</v-row>
				</v-container>
			</v-card-text>
			<v-card-actions>
				<v-spacer></v-spacer>
				<template v-if="!detailsMode">
					<v-btn text color="primary" @click="onClickCerrarDialog()"
						>Cancelar</v-btn
					>
					<v-btn
						:disabled="loadingMoldes"
						text
						color="success"
						@click="agregarMolde()"
						>Agregar</v-btn
					>
				</template>
				<v-btn
					v-else
					text
					color="primary"
					@click="onClickCerrarDialog()"
					>Cerrar</v-btn
				>
			</v-card-actions>
		</v-card>
	</v-dialog>
</template>

<script>
import axios from "axios";
import qs from "qs";

export default {
	props: {
		getImagen: {
			type: Function,
			required: true
		},
		moldesSelected: {
			type: Array,
			default: () => [],
		},
		idArticulo: {
			type: Number,
			required: true,
		},
		idsMaquinas: {
			type: Array,
			required: true,
		},
	},
	data() {
		return {
			loadingMoldes: false,
			dialog: false,
			molde: {
				idMolde: null,
				codigo: null,
				numCavidades: null,
				articulo: null,
				maquina: null,
				imagen: null,
				cavidades: [],
				pasos: [],
				idArticulo: null,
				idMaquina: null,
			},
			moldes: [],
			localImage: null,
			moldesHeaders: [
				{
					text: "Código del Molde",
					align: "center",
					value: "codigo",
				},
				{
					text: "Número de cavidades",
					align: "center",
					value: "numCavidades",
				},
				{
					text: "Número de cavidades activas",
					align: "center",
					value: "cavidadesActivas",
				},
				{
					text: "Número de disparos",
					align: "center",
					value: "numDisparos",
				},
				{
					text: "Máquinas",
					align: "center",
					value: "maquina",
				},
			],
			errorMolde: null,
			detailsMode: false,
			loadingImage: false,
		};
	},
	computed: {
		moldesComputed() {
			return this.moldes.filter(
				(molde) =>
					!this.moldesSelected.find(
						(moldeSelect) => moldeSelect.idMolde == molde.idMolde
					)
			);
		},
	},
	destroyed(){
		this.resetValues();
	},
	methods: {
		loadImage(){
			this.loadingImage = true;

			this.getImagen(this.molde)
				.then( imageBase64 => {
					this.localImage = imageBase64;
				})
				.catch( () => {
					this.localImage = null;
				})
				.finally( () => {
					this.loadingImage = false;
				});
		},
		onChangeMolde() {
			this.loadImage();
		},
		nombreMolde({ codigo }) {
			return `Código: ${codigo}`;
		},
		getMoldes() {
			this.loadingMoldes = true;

			axios
				.get("/Recetas/ListarMoldes", {
					params: {
						idArticulo: this.idArticulo,
						idsMaquinas: this.idsMaquinas,
					},
					paramsSerializer: (params) =>
						qs.stringify(params, {
							indices: false,
							skipNulls: true,
						}),
				})
				.then((response) => {
					this.moldes = response.data;
				})
				.catch((error) => {
					this.moldes = [];
					console.log(error);
				})
				.finally(() => {
					this.loadingMoldes = false;
				});
		},
		onClearSelectModel() {
			this.resetValues();
		},
		resetValues() {
			this.molde = null;
			this.localImage = null;
			this.loadingImage = false;
			this.uploadedFile = null;
			this.errorMolde = null;
			this.detailsMode = false;
		},
		showDialog(molde) {
			this.detailsMode = molde != null;
			
			this.molde = JSON.parse(JSON.stringify(molde));
			
			if (this.detailsMode) this.loadImage();
			else this.getMoldes();

			this.dialog = true;
		},
		onClickCerrarDialog() {
			this.dialog = false;

			setTimeout(() => {
				this.resetValues();
			}, 200);
		},
		validate() {
			this.errorMolde = null;

			if (this.molde == null) {
				this.errorMolde = "Requerido";
			}

			return this.errorMolde == null;
		},
		agregarMolde() {
			if (!this.validate()) return;

			this.$emit("select", this.molde);
			this.onClickCerrarDialog();
		},
		trimmText(value) {
			return value.length > 22 ? value.slice(0, 19).concat("...") : value;
		},
	},
};
</script>
<style scoped>
table td + td {
	border-left: 1px solid #dddddd;
}
</style>