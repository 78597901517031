<template>
</template>

<script>

export default {
  data: () => ({
    time:1000,
    wait:1000,
    string:"",
    countdown:false,
  }),
  created(){
    window.addEventListener('keydown', (e) => {
      this.readStrings(e);
    });
  },
  methods:{
    readStrings(e){
      if(e.key != 'Shift' && e.key != 'Enter'){
        this.string += e.key;
        this.time = this.wait;
        if(!this.countdown){
          this.countdownString();
          this.countdown = true;
        }
      }
      else if(e.key == 'Enter'){
        this.countdown = false;
        this.time = this.wait;
        this.$emit('change',this.string);
        this.string = "";
      }
    },
    countdownString(){
      setTimeout(() => {
        if(this.time <= 0 && this.string != ''){
          this.$emit('change',this.string);
          this.string = "";
          this.time = this.wait;
          this.countdown = false;
        }
        else{
          if(this.countdown){
            this.time -= 100;
            this.countdownString();
          }        
        }
      }, 100);
    },
  }
}
</script>