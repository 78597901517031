<template>
  <div>
    <v-row>
      <v-col cols="2" md="2" sm="12">
        <datePicker
          v-model="filtros.desde"
          format="YYYY-MM-DD"
          :maxDate="filtros.hasta"
          label="Desde (Obligatorio)"
          clearable
          maxToday
          @input="getData()"
          :disabled="cargando"
        ></datePicker>
      </v-col>
      <v-col cols="2" md="2" sm="12">
        <datePicker
          v-model="filtros.hasta"
          format="YYYY-MM-DD"
          :minDate="filtros.desde"
          label="Hasta (Obligatorio)"
          clearable
          maxToday
          @input="getData()"
          :disabled="cargando"
        ></datePicker>
      </v-col>
      <v-col cols="2" md="2" sm="12">
        <v-select
        v-model="filtros.tipoMantenimiento"
          :items="tipoMantenimiento"
          item-value="id"
          item-text="value"
          label="Tipo mantenimiento (Opcional)"
          clearable
          @change="
            getData();"
            :disabled="cargando"
        ></v-select>
      </v-col>
      <v-col cols="3" md="3" sm="12">
        <v-autocomplete
          v-model="filtros.idMaquina"
          :items="maquinas"
          item-value="idMaquina"
          :item-text="marcaModelo"
          label="Máquina (Opcional)"
          hide-details
          clearable
          :disabled="cargando || loadingMaquinas"
          :loading="loadingMaquinas"
          @change="
            getData();
            changeMaquina();
          "
          
        >
        </v-autocomplete>
      </v-col>
      <v-col cols="3" md="3" sm="12">
        <v-autocomplete
          v-model="filtros.idMaquinaIns"
          :items="instanciasFiltradas"
          item-value="idMaquinaIns"
          item-text="nombre"
          label="Máquina Instancia (Opcional)"
          :placeholder="
            filtros.idMaquina == null ? 'Seleccione primero una Máquina' : ''
          "
          clearable
          hide-details
          :disabled="cargando || loadingInstancias"
          :loading="loadingInstancias"
          @change="getData()"
        >
        </v-autocomplete>
      </v-col>
      <v-col cols="12" md="12" sm="12" v-if="cargando">
        <v-progress-linear
          color="primary"
          height="5"
          indeterminate
        ></v-progress-linear>
      </v-col>
      <v-col v-else cols="12" md="12" sm="12">
        <apexchart
          type="bar"
          height="350"
          :options="chartOptions"
          :series="series"
        ></apexchart>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import axios from "axios";
/* 
import { mapState } from "vuex"; */
/* import DownloadBtn from "../../../DownloadBtn.vue"; */

export default {
  data() {
    return {
      cargando: false,
      tipoMantenimiento: [
        {
          id: 1,
          value: "Preventivo",
        },
        {
          id: 2,
          value: "Correcctivo",
        },
        {
          id: 3,
          value: "Inspeccion",
        },
      ],
      filtros: {
        idMaquina: null,
        idMaquinaIns: null,
        tipoMantenimiento: null,
        desde: null,
        hasta: null,
      },
      maquinas: [],
      instancias: [],
      categories: [],
      series: [],
      loadingMaquinas: false,
      loadingInstancias: false,
    };
  },
  mounted() {
    this.initialize();
  },
  computed: {
    instanciasFiltradas() {
      if (this.filtros.idMaquina == null) return [];
      return this.instancias.filter(
        (instancia) => instancia.idMaquina == this.filtros.idMaquina
      );
    },
    chartOptions() {
      return {
        states: {
          active: {
            allowMultipleDataPointsSelection: false,
          },
        },
        chart: {
          type: "bar",

          height: 350,
        },
        plotOptions: {
          bar: {
            horizontal: false,
            columnWidth: "55%",
            endingShape: "rounded",
          },
        },
        dataLabels: {
          enabled: false,
        },
        stroke: {
          show: true,
          width: 2,
          colors: ["transparent"],
        },
        xaxis: {
          categories: this.categories,
        },
        yaxis: {
          labels: {
            formatter: function (val, index) {
              return val.toFixed(2) + " %";
            },
          },
        },
        fill: {
          opacity: 1,
        },
        tooltip: {
          y: {
            formatter: function (val) {
              return val.toFixed(2) + " %";
            },
          },
        },
      };
    },
  },
  methods: {
    initialize() {
      this.getMaquinasIns();
      this.getMaquinaInstancias();
    },
    marcaModelo: ({ marca, modelo }) => `${marca} - ${modelo}`,
    getMaquinasIns() {
      this.loadingMaquinas = true;
      axios
        .get("/Maquinas")
        .then((response) => {
          this.maquinas = response.data;
          this.loadingMaquinas = false;
          console.log(this.maquinas);
        })
        .catch((error) => {
          console.log(error);
          this.loadingMaquinas = false;
        });
    },
    getMaquinaInstancias() {
      this.loadingInstancias = true;
      axios
        .get("/MaquinaInstancias")
        .then((response) => {
          this.instancias = response.data;
        })
        .catch((error) => {
          this.instancias = [];
          console.log(error);
        })
        .finally(() => {
          this.loadingInstancias = false;
        });
    },
    changeMaquina() {
      this.filtros.idMaquinaIns = null;
    },
    getData() {
      if (this.filtros.desde == null) return;
      if (this.filtros.hasta == null) return;
      this.cargando = true;
      axios
        .get("/Reportes/MaquinasInsPorcentajeMantenimientos", {
          params: {
            desde: this.filtros.desde,
            hasta: this.filtros.hasta,
            tipoMantenimiento: this.filtros.tipoMantenimiento,
            idMaquina: this.filtros.idMaquina,
            idMaquinaIns: this.filtros.idMaquinaIns,
          },
        })
        .then((response) => {
          this.series = response.data.series;
          this.categories = response.data.categories;
          this.cargando = false;
        })
        .catch((error) => {
          console.log(error);
          this.cargando = false;
        });
    },
  },
};
</script>