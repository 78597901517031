<template>
	<v-container fluid>
		<v-row v-if="estadoCargaMasiva == 1 || estadoCargaMasiva == 2">
			<v-col cols="6">
				<CargaMasivaTemplate
					titulo="Carga Masiva de Programación"
					@change="ficheros.ficheroProgramacion = $event"
					ref="programacion"
					:disabled="estadoCargaMasiva == 2"
				/>
			</v-col>
			<v-col cols="6">
				<CargaMasivaTemplate
					titulo="Carga Masiva de Inventario"
					@change="ficheros.ficheroInventario = $event"
					ref="inventario"
					:disabled="estadoCargaMasiva == 2"
				/>
			</v-col>
			<v-col cols="6">
				<CargaMasivaTemplate
					titulo="Carga Masiva de BOM"
					@change="ficheros.ficheroBOM = $event"
					ref="bom"
					:disabled="estadoCargaMasiva == 2"
				/>
			</v-col>
			<v-col cols="6">
				<CargaMasivaTemplate
					titulo="Carga Masiva de Familia de Artículos"
					@change="ficheros.ficheroFamilias = $event"
					ref="familias"
					:disabled="estadoCargaMasiva == 2"
				/>
			</v-col>
			<v-col cols="12">
				<v-btn
					block
					color="blue"
					outlined
					class="my-2 mb-4"
					:disabled="deshabilitarCarga || estadoCargaMasiva == 2"
					:loading="estadoCargaMasiva == 2"
					@click="realizarCargaMasiva"
				>
					<v-icon left>mdi-cloud-upload</v-icon> Iniciar la Carga
					Masiva
				</v-btn>
			</v-col>
		</v-row>
		<v-row v-if="estadoCargaMasiva == 3">
			<v-col cols="12" class="d-flex flex-column align-center">
				<lottie
					id="inProgressAnimation"
					:options="inProgressAnimationOptions"
					:height="200"
					:width="200"
					:key="`error-${inProgressAnimationKey}`"
					class="py-0"
				/>
				<p class="text-h5 font-weight-medium">
					Carga Masiva en Progreso
				</p>
				<p class="text-h6 font-weight-regular">
					Se le notificará cuando la carga haya concluido.
				</p>
				<!--
				<v-btn large text color="green" @click="resetValues()"
					>Aceptar</v-btn
				>
				-->
			</v-col>
		</v-row>
		<v-row v-if="estadoCargaMasiva == 4">
			<v-col cols="12" class="d-flex flex-column align-center">
				<v-icon size="100" color="red"> mdi-close-circle </v-icon>
				<div class="text-h5 my-4">
					Ha sucedido un error. Intente nuevamente.
				</div>
				<v-btn large text color="green" @click="resetValues()"
					>Aceptar</v-btn
				>
			</v-col>
		</v-row>
	</v-container>
</template>

<script>
import CargaMasivaTemplate from "../Inventario/CargasMasivas/CargaMasivaTemplate.vue";
import Lottie from "vue-lottie";
import * as inProgressAnimation from "@/assets/inProgress.json";
import axios from 'axios';

export default {
	components: {
		CargaMasivaTemplate,
		lottie: Lottie,
	},
	data() {
		return {
			inProgressAnimationOptions: {
				animationData: inProgressAnimation.default,
				loop: true,
				speeed: 1,
			},
			inProgressAnimationKey: 0,
			estadoCargaMasiva: 1,
			ficheros: {
				ficheroProgramacion: null,
				ficheroInventario: null,
				ficheroBOM: null,
				ficheroFamilias: null,
			},
		};
	},
	mounted() {
		this.initialize();
	},
	computed: {
		deshabilitarCarga() {
			return Object.values(this.ficheros).some((value) => value == null);
		},
	},
	methods: {
		initialize() {
			this.resetValues();
			this.getCargaEnProgreso();

			this.interval = setInterval(() => {
        if(this.estadoCargaMasiva == 3) this.getCargaEnProgreso();
      },10000);
		},
		resetValues() {
			Object.keys(this.ficheros).forEach(
				(key) => (this.ficheros[key] = null)
			);
			this.$refs.programacion?.resetValues();
			this.$refs.inventario?.resetValues();
			this.$refs.bom?.resetValues();
			this.$refs.familias?.resetValues();
			this.estadoCargaMasiva = 1;
		},
		getCargaEnProgreso(){
      axios
      .get("/BlackDeckerCargaMasiva/CargaMasivaEnProgreso")
      .then(response => {
				this.estadoCargaMasiva = (response.data)? 3 : 1;
      })
      .catch(error => {
				this.estadoCargaMasiva = 1;
        console.log(error);
      })
    },
		realizarCargaMasiva() {
			this.estadoCargaMasiva = 2;
			let formData = new FormData();

			formData.append(
				"reporteMovimientos",
				this.ficheros.ficheroInventario
			);
			formData.append("produccion", this.ficheros.ficheroFamilias);
			formData.append("bomFile", this.ficheros.ficheroBOM);
			formData.append(
				"programacionFile",
				this.ficheros.ficheroProgramacion
			);

			axios
				.post("/BlackDeckerCargaMasiva/CargarArchivos", formData, {
					headers: {
						"Content-Type": "multipart/form-data",
					},
				})
				.then(() => {
					this.cargaMasivaCompleta = true;
					this.inProgressAnimationKey++;
					this.estadoCargaMasiva = 3;
				})
				.catch((error) => {
					console.log(error);
					this.loadingCargaMasiva = false;
					this.estadoCargaMasiva = 4;
				});
		},
	},
};
</script>

<style>
</style>