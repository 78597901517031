import { VAppBar } from 'vuetify/lib/components/VAppBar';
import { VBadge } from 'vuetify/lib/components/VBadge';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VMenu } from 'vuetify/lib/components/VMenu';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VToolbarItems } from 'vuetify/lib/components/VToolbar';
import { VToolbarTitle } from 'vuetify/lib/components/VToolbar';
import { VTooltip } from 'vuetify/lib/components/VTooltip';
import Intersect from 'vuetify/lib/directives/intersect';
import Ripple from 'vuetify/lib/directives/ripple';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VAppBar,{attrs:{"flat":"","max-height":"70px","id":"core-toolbar","dark":"","color":"rgb(33,33,33)"}},[_c('signalr',{attrs:{"path":"/hub/notificacion"},on:{"change":_vm.notificacion}}),_c('div',{staticClass:"v-toolbar-title"},[_c(VToolbarTitle,{staticClass:"font-weight-light white--text"},[(_vm.responsive)?_c(VBtn,{staticClass:"default v-btn--simple",attrs:{"icon":""},on:{"click":function($event){$event.stopPropagation();return _vm.onClickBtn.apply(null, arguments)}}},[_c(VIcon,[_vm._v("mdi-view-list")])],1):_vm._e(),_vm._v(" "+_vm._s(_vm.title)+" ")],1)],1),_c(VSpacer),_c(VToolbarItems,[_c('internet-check'),_c(VTooltip,{staticClass:"white--text",attrs:{"bottom":"","color":"black"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c(VBtn,_vm._g({staticClass:"ma-0",attrs:{"value":_vm.modoAyuda,"color":_vm.modoAyuda&&'yellow',"text":"","icon":""},on:{"click":function($event){return _vm.toggleModoAyuda()}}},on),[_c(VIcon,{attrs:{"size":22}},[_vm._v("mdi-help")])],1)]}}])},[_c('span',{staticClass:"white--text"},[_vm._v("Ayuda: "+_vm._s(_vm.modoAyuda?'Activada':'Desactivada'))])]),_c(VTooltip,{staticClass:"white--text",attrs:{"bottom":"","color":"black"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c(VIcon,_vm._g({staticClass:"toolbar-items pl-0",staticStyle:{"cursor":"pointer"},attrs:{"color":""},on:{"click":function($event){return _vm.showBusquedaDocumentacion()}}},on),[_vm._v("mdi-book-open-blank-variant")])]}}])},[_c('span',{staticClass:"white--text"},[_vm._v("Documentación")])]),_c(VTooltip,{staticClass:"white--text",attrs:{"bottom":"","color":"black"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c(VBtn,_vm._g({staticClass:"ma-0",attrs:{"text":"","icon":"","target":"_blank","href":"/dashboard/descargas"}},on),[_c(VIcon,{staticClass:"toolbar-items pl-0"},[_vm._v("mdi-download")])],1)]}}])},[_c('span',{staticClass:"white--text"},[_vm._v("Descargas")])]),_c(VMenu,{attrs:{"left":"","close-on-content-click":false,"content-class":"top"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var menu = ref.on;
var attrs = ref.attrs;
return [_c(VTooltip,{staticClass:"white--text",attrs:{"bottom":"","color":"black"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var tooltip = ref.on;
return [_c(VIcon,_vm._g(_vm._b({attrs:{"color":"","size":22}},'v-icon',attrs,false),Object.assign({}, menu, tooltip)),[_vm._v(" mdi-bug ")])]}}],null,true)},[_c('span',{staticClass:"white--text"},[_vm._v("Reportar Error")])])]}}])},[_c(VContainer,{staticClass:"notifications"},[_c(VRow,{attrs:{"dense":""}},[_c(VCol,{attrs:{"cols":"12"}},[_c('help')],1)],1)],1)],1),_c('router-link',{directives:[{def: Ripple,name:"ripple",rawName:"v-ripple"}],staticClass:"toolbar-items",attrs:{"to":"/dashboard/reportes"}},[_c(VTooltip,{staticClass:"white--text",attrs:{"bottom":"","color":"black"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c(VIcon,_vm._g({attrs:{"size":22,"color":""}},on),[_vm._v("mdi-home")])]}}])},[_c('span',{staticClass:"white--text"},[_vm._v("Inicio")])])],1),_c(VMenu,{attrs:{"left":"","close-on-content-click":false,"content-class":"top"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var menu = ref.on;
var attrs = ref.attrs;
return [_c(VBadge,{staticClass:"align-self-center",attrs:{"content":_vm.numMessages,"value":_vm.numMessages}},[_c(VTooltip,{staticClass:"white--text",attrs:{"bottom":"","color":"black"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var tooltip = ref.on;
return [_c(VIcon,_vm._g(_vm._b({attrs:{"color":"","size":20}},'v-icon',attrs,false),Object.assign({}, menu, tooltip)),[_vm._v(" mdi-bell ")])]}}],null,true)},[_c('span',{staticClass:"white--text"},[_vm._v("Notificaciones")])])],1)]}}])},[_c(VContainer,{staticClass:"notifications"},[_c(VRow,{attrs:{"dense":""}},[_vm._l((_vm.notificaciones),function(notificacion,idx){return _c(VCol,{key:'notificacion-' + idx,attrs:{"cols":"12"}},[_c(VCard,{attrs:{"color":"#385F73","dark":""}},[_c(VRow,{staticClass:"pa-0 ma-0"},[_c(VCol,{staticClass:"pa-0 ma-0",attrs:{"cols":"10"}},[_c(VCardText,{staticClass:"ma-0"},[_c('div',{staticClass:"text-subtitle-1"},[_vm._v(" "+_vm._s(notificacion.titulo)+" ")]),_c('div',[_vm._v(_vm._s(notificacion.mensaje))]),(notificacion.url != null)?[(notificacion.urlTipo == 0)?_c('div'):_vm._e(),(notificacion.urlTipo == 1)?_c('div',[_c('div',{staticClass:"text-body-2",staticStyle:{"cursor":"pointer"},on:{"click":function($event){return _vm.descargarArchivo(notificacion.url)}}},[(notificacion.urlIcono != null)?_c(VIcon,{attrs:{"small":""}},[_vm._v(_vm._s(notificacion.urlIcono))]):_vm._e(),_vm._v(_vm._s(notificacion.urlMensaje))],1)]):_vm._e()]:_vm._e()],2)],1),_c(VCol,{staticClass:"align-self-center",attrs:{"cols":"2"}},[_c(VIcon,{on:{"click":function($event){return _vm.marcarLeido(notificacion.idNotificacion)}}},[_vm._v("mdi-check")])],1)],1)],1)],1)}),(_vm.notificaciones.length == 0)?_c(VCol,{attrs:{"cols":"12"}},[_c(VCard,{attrs:{"outlined":""}},[_c(VCardTitle,{staticClass:"headline text-center justify-center"},[_vm._v("No hay Notificaciones")])],1)],1):_vm._e(),_c('div',{directives:[{def: Intersect,name:"intersect",rawName:"v-intersect",value:({
              handler: _vm.onIntersect,
              options: {
                threshold: [0, 0.5, 1.0],
              },
            }),expression:"{\n              handler: onIntersect,\n              options: {\n                threshold: [0, 0.5, 1.0],\n              },\n            }"}]})],2)],1)],1),_c(VTooltip,{staticClass:"white--text",attrs:{"bottom":"","color":"black"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
            var on = ref.on;
return [_c(VIcon,_vm._g({staticClass:"toolbar-items",attrs:{"color":""},on:{"click":_vm.logout}},on),[_vm._v("mdi-power")])]}}])},[_c('span',{staticClass:"white--text"},[_vm._v("Salir")])])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }