import { VAutocomplete } from 'vuetify/lib/components/VAutocomplete';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VImg } from 'vuetify/lib/components/VImg';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemAction } from 'vuetify/lib/components/VList';
import { VListItemAvatar } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VListItemSubtitle } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VMenu } from 'vuetify/lib/components/VMenu';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VSystemBar } from 'vuetify/lib/components/VSystemBar';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.permisoVista('organigrama','r'))?_c(VCol,{staticClass:"pa-0",attrs:{"cols":"12"}},[_c(VDialog,{attrs:{"persistent":"","max-width":"35%"},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c(VCard,[_c(VCardTitle,[_vm._v("Agregar")]),_c(VCardText,[_c(VRow,[_c(VCol,{attrs:{"cols":"12"}},[_c(VAutocomplete,{attrs:{"items":_vm.personas,"item-text":_vm.nombrePersona,"item-value":"idPersona","label":"Jefe","clearable":!_vm.editedItem.subsOnly,"readonly":_vm.editedItem.subsOnly,"disabled":_vm.saving,"error-messages":_vm.errorJefe},on:{"focus":function($event){_vm.errorJefe = ''},"change":function($event){_vm.editedItem.idsSubordinados = []}},model:{value:(_vm.editedItem.idJefe),callback:function ($$v) {_vm.$set(_vm.editedItem, "idJefe", $$v)},expression:"editedItem.idJefe"}})],1),_c(VCol,{attrs:{"cols":"12"}},[_c(VAutocomplete,{attrs:{"items":_vm.personasSinJefeFiltradas,"item-text":"name","item-value":"idPersona","label":"Subordinados","clearable":"","chips":"","deletable-chips":"","multiple":"","disabled":_vm.editedItem.idJefe == null || _vm.saving,"error-messages":_vm.errorSubordinados},on:{"focus":function($event){_vm.errorSubordinados = ''}},model:{value:(_vm.editedItem.idsSubordinados),callback:function ($$v) {_vm.$set(_vm.editedItem, "idsSubordinados", $$v)},expression:"editedItem.idsSubordinados"}})],1)],1)],1),_c(VCardActions,[_c(VSpacer),_c(VBtn,{attrs:{"text":"","color":"success","disabled":_vm.saving},on:{"click":_vm.cerrarDialog}},[_vm._v("Cancelar")]),_c(VBtn,{attrs:{"text":"","color":"error","disabled":_vm.saving,"loading":_vm.saving},on:{"click":_vm.save}},[_vm._v("Guardar")])],1)],1)],1),_c('OrganizationChart',{staticClass:"customNode",attrs:{"id":"chart","datasource":_vm.datos,"pan":"","zoom":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var nodeData = ref.nodeData;
return [_c(VList,{staticClass:"rounded-lg pt-0"},[_c(VSystemBar,{staticClass:"rounded-t-lg",attrs:{"height":10,"color":nodeData.color != null && nodeData.color}}),_c(VListItem,{attrs:{"two-line":""}},[_c(VListItemAvatar,[_c(VImg,{attrs:{"src":("https://ui-avatars.com/api/?name=" + (nodeData.name) + "&size=64")}})],1),_c(VListItemContent,[_c(VListItemTitle,{staticClass:"text-h6 text-left"},[_vm._v(" "+_vm._s(nodeData.name)+" ")]),_c(VListItemSubtitle,{staticClass:"text-left"},[_vm._v(" "+_vm._s(_vm.nombreEmpresa)+" ")])],1),(
							nodeData.idPersona > 0 && 
							(_vm.permisoVista('organigrama','u') || _vm.permisoVista('organigrama','d'))
						)?_c(VListItemAction,[_c(VMenu,{attrs:{"transition":"slide-x-transition","attach":"","offset-x":"","close-on-click":"","content-class":"elevation-0"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
						var on = ref.on;
						var attrs = ref.attrs;
return [_c(VBtn,_vm._g(_vm._b({attrs:{"icon":""}},'v-btn',attrs,false),on),[_c(VIcon,{attrs:{"color":"grey"}},[_vm._v("mdi-dots-vertical")])],1)]}}],null,true)},[_c('div',{staticClass:"d-flex"},[(_vm.permisoVista('organigrama','u'))?_c(VBtn,{staticClass:"my-0",attrs:{"fab":"","dark":"","color":"orange","elevation":"0"},on:{"click":function($event){return _vm.agregarNodo(nodeData)}}},[_c(VIcon,{attrs:{"size":"30"}},[_vm._v("mdi-pencil")])],1):_vm._e(),(_vm.permisoVista('organigrama','d'))?_c(VBtn,{staticClass:"my-0",attrs:{"fab":"","dark":"","color":"red","elevation":"0"},on:{"click":function($event){return _vm.borrarNodo(nodeData)}}},[_c(VIcon,{attrs:{"size":"30"}},[_vm._v("mdi-delete")])],1):_vm._e()],1)])],1):_vm._e()],1)],1)]}}],null,false,1576829045)}),(_vm.permisoVista('organigrama','c'))?_c(VBtn,{staticStyle:{"right":"0.6rem","bottom":"0.8rem","z-index":"20"},attrs:{"fab":"","absolute":"","dark":"","color":"green"},on:{"click":function($event){return _vm.agregarNodo()}}},[_c(VIcon,{attrs:{"size":"30"}},[_vm._v("mdi-plus")])],1):_vm._e()],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }