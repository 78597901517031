import { VAutocomplete } from 'vuetify/lib/components/VAutocomplete';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VProgressLinear } from 'vuetify/lib/components/VProgressLinear';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSimpleTable } from 'vuetify/lib/components/VDataTable';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VContainer,{attrs:{"fluid":""}},[_c(VRow,{staticClass:"justify-space-between"},[_c(VCol,{staticClass:"d-flex pa-0",attrs:{"cols":"10"}},[_c(VCol,{attrs:{"cols":"4"}},[_c('MenuDatepicker',{attrs:{"label":"[Obligatorio] Fecha","clearable":"","hide-details":"","disabled":_vm.loading},on:{"input":function($event){return _vm.getData()}},model:{value:(_vm.filtros.fecha),callback:function ($$v) {_vm.$set(_vm.filtros, "fecha", $$v)},expression:"filtros.fecha"}})],1),_c(VCol,{staticClass:"pt-4",attrs:{"cols":"5"}},[_c(VAutocomplete,{ref:"ubicacionesSelector",attrs:{"label":"[Opcional] Ubicaciones","hide-details":"","clearable":"","items":_vm.ubicaciones,"item-value":"idUbicacion","item-text":"nombre","multiple":"","small-chips":"","deletable-chips":"","disabled":_vm.loading || _vm.loadingUbicaciones,"loading":_vm.loadingUbicaciones},on:{"change":_vm.onChangeUbicaciones},model:{value:(_vm.filtros.idsUbicaciones),callback:function ($$v) {_vm.$set(_vm.filtros, "idsUbicaciones", $$v)},expression:"filtros.idsUbicaciones"}})],1)],1),_c(VCol,{staticClass:"text-right",attrs:{"cols":"2"}},[_c(VBtn,{attrs:{"color":"primary","disabled":_vm.loading || !_vm.hayFechaValida},on:{"click":function($event){return _vm.getData()}}},[_c(VIcon,{attrs:{"left":""}},[_vm._v("mdi-refresh")]),_vm._v("Refrescar ")],1)],1)],1),_c(VRow,[_c(VCol,{attrs:{"cols":"12"}},[_c(VSimpleTable,{staticClass:"custom-table",class:{ 'custom-height': _vm.hayData },attrs:{"fixed-header":""},scopedSlots:_vm._u([{key:"default",fn:function(){return [_c('thead',[_c('tr',[_vm._l((_vm.headers),function(head,idx){return [(head.value == 'comprobaciones')?[_vm._l((_vm.headersComprobaciones),function(headerComp,idxHeadComp){return [_c('th',{key:("headerComp-" + idx + "-" + idxHeadComp),staticClass:"text-center header-font-size white--text",class:{
                                                    'header-color-critico': _vm.esHeaderCritico(headerComp.value),
                                                    'header-color-comprobacion': !_vm.esHeaderCritico(headerComp.value)
                                                }},[_c('span',{staticClass:"vertical-text"},[_vm._v(_vm._s(headerComp.text))])])]})]:(head.value == 'fecha')?_c('th',{key:("head-" + idx),staticClass:"text-center header-font-size white--text header-color-base",staticStyle:{"min-width":"150px"}},[_vm._v(" "+_vm._s(head.text)+" ")]):_c('th',{key:("head-" + idx),staticClass:"text-center header-font-size white--text header-color-base"},[_vm._v(" "+_vm._s(head.text)+" ")])]})],2)]),(_vm.loading)?_c('thead',[_c('tr',{staticClass:"v-data-table__progress"},[_c('th',{staticClass:"column fixed-header-loading",attrs:{"colspan":_vm.headersLength}},[_c('div',{staticClass:"v-progress-linear v-progress-linear--absolute v-progress-linear--visible theme--light",staticStyle:{"height":"4px"},attrs:{"role":"progressbar","aria-valuemin":"0","aria-valuemax":"100"}},[_c('div',{staticClass:"v-progress-linear__background primary",staticStyle:{"opacity":"0.3","left":"0%","width":"100%"}}),_c('div',{staticClass:"v-progress-linear__buffer"}),_c('div',{staticClass:"v-progress-linear__indeterminate v-progress-linear__indeterminate--active"},[_c('div',{staticClass:"v-progress-linear__indeterminate long primary"}),_c('div',{staticClass:"v-progress-linear__indeterminate short primary"})])])])])]):_vm._e(),_c('tbody',[(_vm.hayData)?[_vm._l((_vm.data),function(item,idxTr){return _c('tr',{key:("tr-" + idxTr)},[_vm._l((_vm.headers),function(head,idxTd){return [(head.value == 'comprobaciones')?[_vm._l((item[head.value]),function(comp,idxComp){return [_c('td',{key:("tdComp-" + idxTd + "-" + idxComp),staticClass:"text-center",class:{
                                                        'background-error error--text font-weight-bold': !comp.satisfactoria
                                                    }},[_vm._v(" "+_vm._s(comp.satisfactoria ? 'SI' : 'NO')+" ")])]})]:(head.value == 'status')?_c('td',{key:("td-" + idxTd),staticClass:"text-center px-0"},[_c(VProgressLinear,{staticClass:"custom-progress-linear",staticStyle:{"margin-top":"2px"},attrs:{"value":item[head.value],"color":_vm.getColorEstatus(item[head.value]),"height":"96%"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                                                    var value = ref.value;
return [_c('p',{staticClass:"mb-0 font-weight-bold",staticStyle:{"font-size":"14px !important"}},[_vm._v(_vm._s(Math.ceil(value))+"%")])]}}],null,true)})],1):_c('td',{key:("td-" + idxTd),staticClass:"text-center"},[_vm._v(" "+_vm._s(isNaN(parseFloat(item[head.value])) ? item[head.value] : _vm.$utils.strings.formatearCifra(item[head.value]))+" ")])]})],2)}),_c('tr',[_c('td',{staticClass:"background-celda-promedio text-center font-weight-bold",attrs:{"colspan":_vm.headersLength - 1}},[_vm._v(" Promedio ")]),_c('td',{staticClass:"background-celda-promedio text-center font-weight-bold",staticStyle:{"border-left":"thin solid rgba(0,0,0,.12)"}},[_vm._v(" "+_vm._s(_vm.promedioCalculado)+"% ")])])]:(!_vm.hayData && !_vm.loading)?_c('tr',[_c('td',{staticClass:"text-center td-text-secondary",attrs:{"colspan":_vm.headersLength}},[_vm._v(" No hay datos disponibles ")])]):(!_vm.hayData && _vm.loading)?_c('tr',[_c('td',{staticClass:"text-center td-text-secondary",attrs:{"colspan":_vm.headersLength}},[_vm._v(" Cargando... ")])]):_vm._e()],2)]},proxy:true}])})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }