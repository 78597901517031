<template>
  <v-dialog v-model="dialog" max-width="1000px">
    <v-card>
      <v-card-text class="text-center">
        <br>
        <br>
        <div class="text-h5">{{message}}</div>
        <br>
        <div v-if="subMessage != null" class="text-h6">{{subMessage}}</div>
        <br>
        <v-row>
          <v-col cols="6" md="6" sm="6">
            <v-btn x-large color="green" @click="answer(true)">Si</v-btn>
          </v-col>
          <v-col cols="6" md="6" sm="6">
            <v-btn x-large color="red" @click="answer(false)">No</v-btn>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>

export default {
  data: () => ({
    dialog:false,
    message:"",
    subMessage:null,
  }),
  methods:{    
    show(message,subMessage = null){
      this.message = message;
      this.subMessage = subMessage;
      this.dialog = true;
    },
    reset(){
      this.dialog = false;
      this.$emit('close', null );
    },
    answer(state){
      this.dialog = false;
      this.reset();
      this.$emit('change',state);
    }

  }
}
</script>