<template>
  <v-dialog v-model="dialog" max-width="1000px">
    <v-card>
      <v-card-text class="text-center">
        <br>
        <br>
        <v-icon :color="color" :size="250">{{icon}}</v-icon>
        <br>
        <br>
        <br>
        <div class="display-2">{{message}}</div>
        <br>
        <a class="headline" target="__blank" :href="url"><v-icon color="primary" large>mdi-download</v-icon>{{downloadBtn}}</a>
        <br>
        <v-btn block color="primary" x-large @click="reset">Aceptar</v-btn>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>

export default {
  props: {
    icon: {
      type: String,
      default: "mdi-alert",
    },
    color:{
      type: String,
      default: "red",
    }
  },
  data: () => ({
    dialog:false,
    message:"",
    url:"",
    downloadBtn:"",
  }),
  methods:{    
    show(url,downloadBtn,message){
      this.message = message;
      this.dialog = true;
      this.url = url;
      this.downloadBtn = downloadBtn;
    },
    reset(){
      this.dialog = false;
    },
  }
}
</script>