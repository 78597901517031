import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VProgressCircular } from 'vuetify/lib/components/VProgressCircular';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSnackbar } from 'vuetify/lib/components/VSnackbar';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';
import { VTooltip } from 'vuetify/lib/components/VTooltip';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VContainer,[[_c(VRow,{attrs:{"justify":"center"}},[_c(VDialog,{key:_vm.eliminar,attrs:{"persistent":"","max-width":"500px"},model:{value:(_vm.eliminar),callback:function ($$v) {_vm.eliminar=$$v},expression:"eliminar"}},[_c(VCard,[_c(VCardTitle,{staticClass:"headline"},[_vm._v("¿Está seguro que desea eliminar esta familia?")]),_c(VCardText,[_vm._v("Esta acción no se puede revertir y será permanente.")]),_c(VCardActions,[_c(VSpacer),_c(VBtn,{attrs:{"color":"blue darken-1","text":""},on:{"click":function($event){_vm.eliminar = false;
                _vm.delItem = '';}}},[_vm._v("Cancelar")]),_c(VBtn,{attrs:{"color":"red darken-1","text":""},on:{"click":function($event){_vm.eliminar = false;
                _vm.deleteItemDB();}}},[_vm._v("Aceptar")])],1)],1)],1)],1)],_c(VDialog,{key:_vm.dialog,attrs:{"persistent":"","max-width":"500px"},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c(VCard,[_c(VCardTitle,[_c('span',{staticClass:"headline"},[_vm._v(_vm._s(_vm.formTitle))])]),_c(VCardText,[_c(VContainer,[_c(VRow,[_c(VCol,{attrs:{"justify":"center","cols":"12","sm":"12","md":"12"}},[_c(VTextField,{attrs:{"label":"Código de la Familia de Productos"},model:{value:(_vm.editedItem.codigo),callback:function ($$v) {_vm.$set(_vm.editedItem, "codigo", $$v)},expression:"editedItem.codigo"}})],1),_c(VCol,{attrs:{"justify":"center","cols":"12","sm":"12","md":"12"}},[_c(VTextField,{attrs:{"label":"Nombre de la Familia de Productos"},model:{value:(_vm.editedItem.nombre),callback:function ($$v) {_vm.$set(_vm.editedItem, "nombre", $$v)},expression:"editedItem.nombre"}})],1),_c(VCol,{attrs:{"justify":"center","cols":"12","sm":"12","md":"12"}},[_c(VTextField,{attrs:{"label":"Descripción de la Familia de Productos"},model:{value:(_vm.editedItem.descripcion),callback:function ($$v) {_vm.$set(_vm.editedItem, "descripcion", $$v)},expression:"editedItem.descripcion"}})],1)],1),_c('ul',_vm._l((_vm.errores),function(ex){return _c('li',{key:ex,staticClass:"red--text"},[_vm._v(" "+_vm._s(ex)+" ")])}),0)],1)],1),_c(VCardActions,[_c(VSpacer),_c(VBtn,{attrs:{"color":"blue darken-1","text":""},on:{"click":function($event){_vm.dialog = false;
            _vm.errores = [];}}},[_vm._v("Cancelar")]),_c(VBtn,{attrs:{"color":"red darken-1","text":"","disabled":_vm.saving,"loading":_vm.saving},on:{"click":function($event){return _vm.save()}},scopedSlots:_vm._u([{key:"loader",fn:function(){return [_c(VProgressCircular,{attrs:{"indeterminate":"","width":2,"size":24,"color":"red"}})]},proxy:true}])},[_vm._v(" Guardar ")])],1)],1)],1),_c(VRow,[_c(VCol,{staticClass:"pa-0 d-flex align-center justify-space-between",attrs:{"cols":"12"}},[(!_vm.modoOrdenar)?_c(VCol,[(_vm.permisoVista('almacen', 'c'))?_c(VBtn,{staticClass:"mr-2",attrs:{"color":"primary","dark":""},on:{"click":function($event){_vm.dialog = true}}},[_vm._v("Nueva Familia de Productos")]):_vm._e()],1):_c(VCol,[_c(VBtn,{staticClass:"mr-2",attrs:{"color":"red darken-2","disabled":_vm.savingOrden},on:{"click":function($event){return _vm.cancelModoOrdenar()}}},[_vm._v("Cancelar")]),_c(VBtn,{attrs:{"color":"success","disabled":_vm.savingOrden},on:{"click":function($event){return _vm.saveOrden()}}},[_vm._v("Guardar")])],1),_c(VCol,{staticClass:"d-flex justify-center align-center"},[(_vm.permisoVista('almacen', 'u'))?_c(VBtn,{staticClass:"mr-4",attrs:{"color":"teal darken-2","disabled":_vm.modoOrdenar,"loading":_vm.savingOrden},on:{"click":_vm.toggleModoOrdenar}},[_vm._v("Ordenar")]):_vm._e(),_c(VTextField,{staticClass:"mb-4",attrs:{"append-icon":"search","label":"Buscar","disabled":_vm.modoOrdenar,"single-line":"","hide-details":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1)],1)],1),(_vm.permisoVista('almacen', 'r'))?_c(VDataTable,{attrs:{"headers":_vm.computedHeaders,"items":_vm.familias,"search":_vm.search,"loading":_vm.loading,"items-per-page":_vm.computedItemsPerPage,"footer-props":{
      disableItemsPerPage: _vm.modoOrdenar 
    },"loading-text":"Cargando... Espere, Por Favor.","item-key":"idArticuloFamilia"},scopedSlots:_vm._u([{key:"body",fn:function(props){return [_c('draggable',{attrs:{"list":props.items,"tag":"tbody","disabled":!_vm.modoOrdenar},on:{"change":function($event){return _vm.listChanged(props.items)}}},_vm._l((props.items),function(item,index){return _c('DataTableRowHandler',{key:index,attrs:{"item":item,"headers":_vm.computedHeaders,"itemClass":_vm.getClass},scopedSlots:_vm._u([{key:"item.codigo",fn:function(ref){
    var item = ref.item;
return [(item.codigo == null)?_c('span',[_vm._v("N/A")]):_c('span',[_vm._v(_vm._s(item.codigo))])]}},{key:"item.handleIcon",fn:function(){return [_c(VIcon,[_vm._v("mdi-menu")])]},proxy:true},{key:"item.action",fn:function(ref){
    var item = ref.item;
return [_c('div',{staticClass:"d-flex justify-center align-center"},[_c(VTooltip,{staticClass:"white--text",attrs:{"bottom":"","color":"black"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
    var on = ref.on;
return [(_vm.permisoVista('almacen', 'u'))?_c(VIcon,_vm._g({staticClass:"mr-2",attrs:{"small":"","disabled":_vm.modoOrdenar},on:{"click":function($event){return _vm.editItem(item)}}},on),[_vm._v(" edit ")]):_vm._e()]}}],null,true)},[_c('span',{staticClass:"white--text"},[_vm._v("Editar")])]),_c(VTooltip,{staticClass:"white--text",attrs:{"bottom":"","color":"black"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
    var on = ref.on;
return [(_vm.permisoVista('almacen', 'd'))?_c(VIcon,_vm._g({attrs:{"small":"","disabled":_vm.modoOrdenar},on:{"click":function($event){return _vm.deleteItem(item)}}},on),[_vm._v(" delete ")]):_vm._e()]}}],null,true)},[_c('span',{staticClass:"white--text"},[_vm._v("Eliminar")])])],1)]}}],null,true)})}),1)]}},{key:"no-data",fn:function(){return [_c(VBtn,{attrs:{"color":"primary"},on:{"click":_vm.initialize}},[_vm._v("Refrescar")])]},proxy:true}],null,false,3566269268)}):_vm._e(),_c(VSnackbar,{attrs:{"top":"","color":"error"},model:{value:(_vm.alerta),callback:function ($$v) {_vm.alerta=$$v},expression:"alerta"}},[_vm._v(" Existe una Familia Activa con el mismo nombre "),_c(VBtn,{attrs:{"text":""},on:{"click":function($event){_vm.alerta = false}}},[_vm._v(" Cerrar ")])],1)],2)}
var staticRenderFns = []

export { render, staticRenderFns }