import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSlider } from 'vuetify/lib/components/VSlider';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VToolbar } from 'vuetify/lib/components/VToolbar';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VDialog,{attrs:{"fullscreen":"","hide-overlay":"","transition":"dialog-bottom-transition"},model:{value:(_vm.show),callback:function ($$v) {_vm.show=$$v},expression:"show"}},[_c(VCard,[_c(VToolbar,{attrs:{"dark":"","color":"primary"}},[_c(VBtn,{attrs:{"icon":"","dark":""},on:{"click":function($event){return _vm.close()}}},[_c(VIcon,[_vm._v("mdi-close")])],1),(_vm.clave == null)?_c('div',{staticClass:"headline"},[_vm._v(" Gantt de la ODT-"+_vm._s(_vm.idTrabajoOrden)+" ")]):_c('div',{staticClass:"headline"},[_vm._v(" Gantt de la ODT con Clave "+_vm._s(_vm.clave)+" ")]),_c(VSpacer)],1),_c(VCardText,[_c(VRow,{staticClass:"ma-0 pa-0"},[_c(VCol,[_c(VSlider,{attrs:{"min":"0","max":"5","label":"Zoom","dense":"","hide-details":""},on:{"change":_vm.timeInterval},model:{value:(_vm.zoom),callback:function ($$v) {_vm.zoom=$$v},expression:"zoom"}})],1)],1),_c('FullCalendar',{ref:"calendar",attrs:{"schedulerLicenseKey":"GPL-My-Project-Is-Open-Source","events":_vm.events,"resources":_vm.resources,"hiddenDays":_vm.hiddenDays,"minTime":_vm.minTime,"maxTime":_vm.maxTime,"slotWidth":_vm.fullcalendar.slotWidth,"slotDuration":_vm.fullcalendar.slotDuration,"locale":_vm.fullcalendar.locale,"editable":_vm.fullcalendar.editable,"plugins":_vm.fullcalendar.calendarPlugins,"now":_vm.fullcalendar.now,"aspectRatio":_vm.fullcalendar.aspectRatio,"scrollTime":_vm.fullcalendar.scrollTime,"header":_vm.fullcalendar.header,"defaultView":_vm.fullcalendar.defaultView,"views":_vm.fullcalendar.views,"resourceLabelText":_vm.fullcalendar.resourceLabelText,"resourcesInitiallyExpanded":_vm.fullcalendar.resourcesInitiallyExpanded,"eventRender":_vm.eventRender}})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }