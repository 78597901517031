import { VAutocomplete } from 'vuetify/lib/components/VAutocomplete';
import { VListItemAction } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VListItemSubtitle } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VSimpleCheckbox } from 'vuetify/lib/components/VCheckbox';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VAutocomplete,{attrs:{"chips":_vm.chips,"multiple":_vm.multiple,"deletable-chips":_vm.deletableChips,"label":_vm.label,"items":_vm.items,"persistent-hint":_vm.persistentHint,"hint":_vm.hint,"hide-details":_vm.hideDetails,"disabled":_vm.disabled,"item-text":_vm.itemText,"item-value":_vm.itemValue,"menu-props":{
			maxWidth: 'min-content',
		},"placeholder":_vm.placeholder,"loading":_vm.loading,"clearable":_vm.clearable,"error-messages":_vm.errorMessage},on:{"focus":function($event){return _vm.$emit('focus')},"change":function($event){return _vm.$emit('change')}},scopedSlots:_vm._u([{key:"item",fn:function(ref){
		var item = ref.item;
		var attrs = ref.attrs;
		var on = ref.on;
return [(_vm.multiple)?_c(VListItemAction,[_c(VSimpleCheckbox,_vm._g({attrs:{"value":attrs.inputValue}},on))],1):_vm._e(),_c(VListItemContent,[_c(VListItemTitle,[_vm._v(_vm._s(_vm.$utils.mantenimientos.nombreMaquinaInstancia(item,_vm.nombreEmpresa)))]),(item.numSerie != null)?_c(VListItemSubtitle,[_vm._v(" No. Serie: "+_vm._s(item.numSerie)+" ")]):_vm._e(),(item.tipoBascula != null)?_c(VListItemSubtitle,[_vm._v(" Tipo Bascula: "+_vm._s(_vm.$utils.mantenimientos.getTipoBascula(item.tipoBascula))+" ")]):_vm._e(),(item.tipoTransporte != null)?_c(VListItemSubtitle,[_vm._v(" Tipo Unidad: "+_vm._s(_vm.$utils.mantenimientos.getTipoTransportes( item.tipoTransporte ))+" ")]):_vm._e()],1)]}}]),model:{value:(_vm.idsMaquinaIns),callback:function ($$v) {_vm.idsMaquinaIns=$$v},expression:"idsMaquinaIns"}})}
var staticRenderFns = []

export { render, staticRenderFns }