import { VAutocomplete } from 'vuetify/lib/components/VAutocomplete';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VChip } from 'vuetify/lib/components/VChip';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VTextarea } from 'vuetify/lib/components/VTextarea';
import { VTooltip } from 'vuetify/lib/components/VTooltip';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VDialog,{attrs:{"persistent":"","max-width":"75%"},model:{value:(_vm.dialogModificarSubpasos),callback:function ($$v) {_vm.dialogModificarSubpasos=$$v},expression:"dialogModificarSubpasos"}},[_c(VCard,[_c(VCardTitle,[_vm._v("Modificar Subpasos")]),_c(VCardText,[_c(VRow,[_c(VCol,{attrs:{"cols":"6"}},[_c(VAutocomplete,{attrs:{"items":_vm.articulos,"item-text":"articulo","item-value":"idTrabajoOrdeDetalle","label":"Artículo","chips":"","multiple":"","deletable-chips":"","clearable":"","hide-details":""},on:{"change":function($event){return _vm.getSubpasos()}},model:{value:(_vm.filtros.idsOTD),callback:function ($$v) {_vm.$set(_vm.filtros, "idsOTD", $$v)},expression:"filtros.idsOTD"}})],1),_c(VCol,{staticClass:"pt-5",attrs:{"cols":"6"}},[_c(VAutocomplete,{attrs:{"items":_vm.areas,"item-text":"nombre","item-value":"idArea","label":"Areas","clearable":"","hide-details":""},on:{"change":function($event){return _vm.getSubpasos()}},model:{value:(_vm.filtros.idArea),callback:function ($$v) {_vm.$set(_vm.filtros, "idArea", $$v)},expression:"filtros.idArea"}})],1)],1),_c(VRow,[_c(VCol,{attrs:{"cols":"12"}},[_c(VDataTable,{key:("table-" + _vm.tablekey),attrs:{"items":_vm.subpasos,"headers":_vm.subpasosHeaders,"loading":_vm.loading || _vm.switching},scopedSlots:_vm._u([{key:"item.activo",fn:function(ref){
var item = ref.item;
return [_c(VChip,{staticClass:"font-weight-bold",attrs:{"color":!!item.activo ? 'green' : 'red',"outlined":""}},[_vm._v(" "+_vm._s(item.activo ? "Activo" : "Desactivado")+" ")])]}},{key:"item.subpaso",fn:function(ref){
var item = ref.item;
return [_c('p',{staticClass:"mb-0 py-2"},[_vm._v(_vm._s(_vm.getNombreSubpaso(item)))])]}},{key:"item.nota",fn:function(ref){
var item = ref.item;
return [(!!item.modoEdit == false)?_c('div',{staticClass:"d-flex justify-center py-2"},[_c('p',{staticClass:"mb-0 mr-3"},[_vm._v(_vm._s(item.nota))]),_c(VIcon,{attrs:{"small":"","disabled":_vm.switching || _vm.loading},on:{"click":function($event){item.modoEdit = true;
										item.nuevaNota = item.nota;
										_vm.tablekey++;}}},[_vm._v("mdi-pencil")])],1):(!!item.modoEdit == true)?_c('div',{staticClass:"py-2"},[_c(VTextarea,{attrs:{"outlined":"","auto-grow":"","rows":"1","hide-details":""},model:{value:(item.nuevaNota),callback:function ($$v) {_vm.$set(item, "nuevaNota", $$v)},expression:"item.nuevaNota"}}),_c('div',{staticClass:"d-flex justify-center"},[_c(VBtn,{attrs:{"small":"","color":"success","text":""},on:{"click":function($event){item.modoEdit = false;
											item.nuevaNota = '';
											_vm.tablekey++;}}},[_vm._v("Cancelar")]),_c(VBtn,{attrs:{"small":"","color":"error","text":""},on:{"click":function($event){return _vm.guardarNota(item)}}},[_vm._v("Guardar")])],1)],1):_vm._e()]}},{key:"item.actions",fn:function(ref){
											var item = ref.item;
return [(!!item.activo)?_c(VTooltip,{staticClass:"white--text",attrs:{"nudge-left":50,"bottom":"","color":"black"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
											var on = ref.on;
return [_c(VIcon,_vm._g({attrs:{"small":"","disabled":_vm.switching || _vm.loading},on:{"click":function($event){return _vm.switchSubpaso(item, false)}}},on),[_vm._v(" mdi-close-thick ")])]}}],null,true)},[_c('span',{staticClass:"white--text"},[_vm._v("Desactivar")])]):_c(VTooltip,{staticClass:"white--text",attrs:{"nudge-left":50,"bottom":"","color":"black"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
											var on = ref.on;
return [_c(VIcon,_vm._g({attrs:{"small":"","disabled":_vm.switching || _vm.loading},on:{"click":function($event){return _vm.switchSubpaso(item, true)}}},on),[_vm._v(" mdi-check-bold ")])]}}],null,true)},[_c('span',{staticClass:"white--text"},[_vm._v("Activar")])])]}}])})],1)],1)],1),_c(VCardActions,[_c(VSpacer),_c(VBtn,{attrs:{"color":"success","text":""},on:{"click":function($event){return _vm.cerrarDialogModificarSubpasos()}}},[_vm._v("Cerrar")])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }