<template>
  <div>
    <v-container class="grey lighten-5">
      <div class="text-center">
        <h1>Descargas</h1>
        <p>
          Amplía las capacidades de ManagrX con nuestra sección de descargas.
          <br />
          Encuentra instaladores que te permitirán integrar otras aplicaciones y
          añadir nuevas funcionalidades.
          <br />¡Descarga hoy mismo y maximiza tu potencial con ManagrX!
        </p>
      </div>
      <div class="d-flex flex-sm-row flex-column">
        <v-row>
          <v-col cols="12" md="12">
            <v-list>
              <v-list-item
                v-for="(data, index) in itemsDescargas"
                :key="index"
                :class="`d-flex px-0 ${index > 0 ? 'mt-4' : ''}`"
              >
                <v-img
                  contain
                  max-height="30"
                  max-width="30"
                  :src="showImage(data.imagen)"
                  class="me-3"
                ></v-img>
                <!-- :src="data.url" -->
                <div class="d-flex align-center flex-grow-1 flex-wrap">
                  <div class="me-auto pe-2">
                    <v-list-item-title
                      >{{ data.titulo }}
                      <span class="font-weight-bold">{{
                        data.version
                      }}</span></v-list-item-title
                    >
                    <v-list-item-subtitle>{{
                      data.descripcion
                    }}</v-list-item-subtitle>
                  </div>

                  <span class="font-weight-semibold success--text text-right">
                    <v-btn
                      class="ma-2"
                      :loading="data.loading"
                      :disabled="data.loading"
                      color="info"
                      @click="getImageURL(data.url, data)"
                    >
                      Descargar
                      <template v-slot:loader>
                        <span>
                          <v-icon light>mdi-cached</v-icon>
                        </span>
                      </template>
                    </v-btn>
                  </span>
                </div>
              </v-list-item>
            </v-list>
          </v-col>
        </v-row>
      </div>
    </v-container>
  </div>
</template>

<script>
import axios from "axios";
export default {
  data() {
    return {
      itemsDescargas: [],
      deposit: [],
      loading4: false,
      loader: null,
      editedIndex: null,
    };
  },
  watch: {

  },
  created() {
    this.initialize();
  },
  methods: {
    initialize() {
      this.getDescargas();
    },
    getDescargas() {
      axios
        .get("/Descarga")
        .then((response) => {
          this.itemsDescargas = response.data;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    showImage(url) {
      const urlDescarga = process.env.BASE_URL + url;
      return urlDescarga;
    },
    getImageURL(url, item) {
      this.editedIndex = this.itemsDescargas.indexOf(item);
      this.itemsDescargas[this.editedIndex].loading = true;
      var ventana = window.open();
      ventana.addEventListener("beforeunload", function () {
        ventana.close();
      });
      ventana.location.href = url;
      this.itemsDescargas[this.editedIndex].loading = false;
    },
  },
};
</script>