<template>
  <v-dialog
    v-model="show"
    max-width="800px"
    persistent
  >
    <v-card>
      <v-card-title>
        Crear Horario
      </v-card-title>
      <v-card-text>
        <v-row class="text-center justify-center">
          <div class="headline">{{leyenda(editedItem)}}</div>
        </v-row>
        <v-row>
          <v-col cols="6" md="6" sm="6">
            <v-select-array
              v-model="editedItem.diasSemana"
              label="Días de la Semana"
              :items="diasSemana"
            ></v-select-array>
          </v-col>
          <v-col cols="6" md="6" sm="6">
            <v-select-array
              v-model="editedItem.diasMes"
              label="Días del Mes"
              :items="rangoNumeros(31)"
            ></v-select-array>
          </v-col>
          <v-col cols="6" md="6" sm="6">
            <v-select-array
              v-model="editedItem.meses"
              label="Meses"
              :items="meses"
            ></v-select-array>
          </v-col>
          <v-col cols="6" md="6" sm="6">
            <input-time
              v-model="editedItem.horaMinuto"
              label="Hora"
            ></input-time>
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="green" @click="close()">Cancelar</v-btn>
        <v-btn color="red" @click="save()">Guardar</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import VSelectArray from '@/components/VSelectArray'

export default {
  components:{
    'v-select-array':VSelectArray,
  },
  data: () => ({
    diasSemana:[
      {
        value:0,
        text:"Domingo",
      },
      {
        value:1,
        text:"Lunes",
      },
      {
        value:2,
        text:"Martes",
      },
      {
        value:3,
        text:"Miércoles",
      },
      {
        value:4,
        text:"Jueves",
      },
      {
        value:5,
        text:"Viernes",
      },
      {
        value:6,
        text:"Sábado",
      }
    ],
    meses:[
      {
        value:0,
        text:'Enero',
      },
      {
        value:1,
        text:'Febrero',
      },
      {
        value:2,
        text:'Marzo',
      },
      {
        value:3,
        text:'Abril',
      },
      {
        value:4,
        text:'Mayo',
      },
      {
        value:5,
        text:'Junio',
      },
      {
        value:6,
        text:'Julio',
      },
      {
        value:7,
        text:'Agosto',
      },
      {
        value:8,
        text:'Septiembre',
      },
      {
        value:9,
        text:'Octubre',
      },
      {
        value:10,
        text:'Noviembre',
      },
      {
        value:11,
        text:'Diciembre',
      },
    ],
    show:false,
    editedItem:{
      idMantenimientoHorario:-1,
      diasSemana:[],
      meses:[],
      diasMes:[],
      horaMinuto:"00:00",
    },
    defaultEditedItem:{
      idMantenimientoHorario:-1,
      diasSemana:[],
      meses:[],
      diasMes:[],
      horaMinuto:"00:00",
    }
  }),
  methods:{
    rangoNumeros(limite){
      return Array.from(Array(limite).keys()).map(e => {
        return {
          value: e,
          text: e+1,
        }
      })
    },
    lanzar(item = null){
      if(item == null){
        this.editedItem = Object.assign({},this.defaultEditedItem);
        this.editedItem.idMantenimientoHorario = -Math.round(Math.random()*10000);
      }
      else this.editedItem = item;

      this.show = true;
    },
    save(){
      this.$emit('update',this.editedItem);
      this.close();
    },
    close(){
      this.show = false;
      this.editedItem = Object.assign({},this.defaultEditedItem);
      this.editedItem.diasSemana = [];
      this.editedItem.mes = [];
      this.editedItem.diasMes = [];
    },
    leyenda(item){
      var mensaje = "";
      if(item.diasMes.length == 0){
        if(item.diasSemana.length == 0 || item.diasSemana.length == 7){
          mensaje = "Todos los días"
        }
        else{
          mensaje = "Los días ";
          for (let i = 0; i < item.diasSemana.length; i++) {
            if(i >= 1){
              if(i == item.diasSemana.length - 1) mensaje += " y ";
              else mensaje += ", "
            }
            var diaSemana = this.diasSemana.find(ds => ds.value == item.diasSemana[i]);
            if(this.$utils.isValid(diaSemana)) mensaje += diaSemana.text;
          }
        }
      }
      else{
        mensaje = "Los días ";
        for (let i = 0; i < item.diasMes.length; i++) {
          if(i >= 1){
            if(i == item.diasMes.length - 1) mensaje += " y ";
            else mensaje += ", "
          }
          mensaje += (item.diasMes[i]+1)
        }

        if(item.diasSemana.length > 0 && item.diasSemana.length < 7){
          mensaje += " que sean ";
          for (let i = 0; i < item.diasSemana.length; i++) {
            if(i >= 1){
              if(i == item.diasSemana.length - 1) mensaje += " y ";
              else mensaje += ", "
            }
            var diaSemana = this.diasSemana.find(ds => ds.value == item.diasSemana[i]);
            if(this.$utils.isValid(diaSemana)) mensaje += diaSemana.text;
          }
        }
      }

      if(item.meses.length > 0 && item.meses.length < 12){
        if(item.meses.length == 1) mensaje += " del mes de "
        else mensaje += " de lo meses de "
        for (let i = 0; i < item.meses.length; i++) {
            if(i >= 1){
              if(i == item.meses.length - 1) mensaje += " y ";
              else mensaje += ", "
            }
            var mes = this.meses.find(ds => ds.value == item.meses[i]);
            if(this.$utils.isValid(mes)) mensaje += mes.text;
        }
      }

      mensaje += " a las "+item.horaMinuto + " horas";
      return mensaje;
    }
  }
}
</script>