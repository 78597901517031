<template>
	<v-container fluid>
		<v-row class="text-center">
			<v-col cols="4" md="4" sm="4" class="mt-0 pt-0">
				<v-select
					v-model="filtros.bloqueoProduccion"
					:items="tipoIncidencias"
					item-value="id"
					label="Tipo de Incidencia"
					hide-details
					clearable
					@change="getSeries()"
				></v-select>
			</v-col>
			<v-col cols="4" md="4" sm="4" class="mt-0 pt-0">
				<v-select
					v-model="filtros.idOEEFactor"
					:items="oeeFactores"
					item-value="idOEEFactor"
					item-text="nombre"
					label="Factor OEE"
					hide-details
					clearable
					@change="getSeries()"
				></v-select>
			</v-col>
			<v-col cols="4" md="4" sm="4" class="mt-0 pt-0">
				<v-autocomplete
					v-model="filtros.idParoTipo"
					:items="tipoParosFiltrados"
					item-value="idParoTipo"
					item-text="nombre"
					label="Motivo del Incidencia"
					hide-details
					clearable
					@change="changeParoTipo"
				></v-autocomplete>
			</v-col>
			<v-col cols="4" md="4" sm="4" class="mt-0 pt-0">
				<v-autocomplete
					v-model="filtros.idSubParoTipo"
					:items="subTipoParoFiltrados"
					item-value="idSubParoTipo"
					item-text="nombreSubParoTipo"
					label="Submotivo del Incidencia"
					hide-details
					clearable
					@change="getSeries()"
				></v-autocomplete>
			</v-col>
			<v-col cols="4" md="4" sm="4" class="mt-0 pt-0">
				<v-autocomplete
					v-model="filtros.idArea"
					:items="areas"
					item-value="idArea"
					item-text="nombre"
					label="Área"
					hide-details
					clearable
					@change="getSeries()"
				></v-autocomplete>
			</v-col>
			<v-col cols="4" md="4" sm="4" class="align-self-end">
				<VueCtkDateTimePicker
					id="inicio"
					v-model="filtros.inicio"
					label="Desde"
					format="YYYY-MM-DD HH:mm"
					formatted="YYYY-MM-DD HH:mm"
					noHeader
					overlay
					buttonNowTranslation="Ahora"
					@input="getSeries()"
				/>
			</v-col>
			<v-col cols="4" md="4" sm="4" class="align-self-end">
				<VueCtkDateTimePicker
					id="fin"
					v-model="filtros.fin"
					:min-date="filtros.inicio"
					label="Hasta"
					format="YYYY-MM-DD HH:mm"
					formatted="YYYY-MM-DD HH:mm"
					noHeader
					overlay
					buttonNowTranslation="Ahora"
					@input="getSeries()"
				/>
			</v-col>
			<v-col cols="4" md="4" sm="4" class="mt-0 pt-0">
				<v-autocomplete
					v-model="filtros.clave"
					:items="claves"
					item-value="idTrabajoOrden"
					item-text="clave"
					label="OT"
					hide-details
					clearable
					@change="getSeries()"
				></v-autocomplete>
			</v-col>
			<v-col cols="4" md="4" sm="4" class="mt-0 pt-0">
				<v-autocomplete
					v-model="filtros.idArticuloProducir"
					:items="articulosProducir"
					item-value="idArticulo"
					item-text="nombre"
					label="Artículo Producir"
					hide-details
					clearable
					@change="getSeries()"
				></v-autocomplete>
			</v-col>
			<v-col cols="3" md="3" sm="3" class="mt-0 pt-0">
				<v-autocomplete
					v-model="filtros.idArticuloFase"
					:items="articulosFase"
					item-value="idArticulo"
					item-text="nombre"
					label="Artículo Fase"
					hide-details
					clearable
					@change="getSeries()"
				></v-autocomplete>
			</v-col>
			<v-col cols="3" md="3" sm="3" class="mt-0 pt-0">
				<v-autocomplete
					v-model="filtros.idReporto"
					:items="operadores"
					item-value="idPersona"
					:item-text="nombrePersona"
					label="Reportado Por"
					hide-details
					clearable
					@change="getSeries()"
				></v-autocomplete>
			</v-col>
			<v-col cols="3" md="3" sm="3" class="mt-0 pt-0 mb-4">
				<v-autocomplete
					v-model="filtros.idResponsable"
					:items="personas"
					item-value="idPersona"
					:item-text="nombrePersona"
					label="Persona Responsable"
					hide-details
					clearable
					@change="getSeries()"
				></v-autocomplete>
			</v-col>
			<v-col cols="3" md="3" sm="3" class="mt-0 pt-0 mb-4">
				<v-autocomplete
					v-model="filtros.idTipoParo"
					:items="tiposParos"
					item-value="id"
					item-text="value"
					label="Tipo de Paros"
					hide-details
					clearable
					@change="getSeries()"
				></v-autocomplete>
			</v-col>
		</v-row>
		<v-row>
			<v-col cols="12">
				<v-progress-linear
					v-if="loading"
					indeterminate
					color="primary"
				></v-progress-linear>
				<apexchart
					v-if="!loading"
					type="bar"
					height="350"
					:options="chartOptions"
					:series="series"
				></apexchart>
			</v-col>
		</v-row>
	</v-container>
</template>

<script>
import axios from "axios";
export default {
	data() {
		return {
			tiposParos: [
				{
					id: 1,
					value: "Sensor",
				},
				{
					id: 2,
					value: "Manual",
				},
			],
			personas: [],
			operadores: [],
			articulosFase: [],
			articulosProducir: [],
			claves: [],
			areas: [],
			tipoSubparos: [],
			tipoParos: [],
			oeeFactores: [],
			tipoIncidencias: [
				{
					id: true,
					text: "Paro",
				},
				{
					id: false,
					text: "Reporte",
				},
			],
			filtros: {
				idTipoParo: null,
				idOEEFactor: -1,
				idParoTipo: null,
				idSubParoTipo: null,
				clave: null,
				idArticuloProducir: null,
				idArticuloFase: null,
				idArea: null,
				idReporto: null,
				idResponsable: null,
				inicio: null,
				fin: null,
				bloqueoProduccion: null,
			},
			series: [],
			chartOptions: null,
			loading: false,
		};
	},
	computed: {
		subTipoParoFiltrados() {
			if (this.$utils.isValid(this.filtros.idParoTipo))
				return this.tipoSubparos.filter(
					(tp) => tp.idParoTipo == this.filtros.idParoTipo
				);
			else return this.tipoSubparos;
		},
		tipoParosFiltrados() {
			if (
				this.$utils.isValid(this.filtros.idOEEFactor) &&
				this.filtros.idOEEFactor >= 0
			) {
				const idOEEFactor =
					this.filtros.idOEEFactor == 0
						? null
						: this.filtros.idOEEFactor;
				return this.tipoParos.filter(
					(tp) => tp.idOEEFactor == idOEEFactor
				);
			} else {
				return this.tipoParos;
			}
		},
	},
	beforeMount() {
		this.setDefaultsChart();
	},
	mounted() {
		this.initialize();
	},
	methods: {
		nombrePersona: (persona) =>
			persona.nombre + " " + persona.paterno + " " + persona.materno,
		initialize() {
			this.getSeries();
			this.getOEEFactores();
			this.getTipoParos();
			this.getTipoSubParos();
			this.getPersonas();
			this.getOperadores();
			this.getClaves();
			this.getArticulosProducir();
			this.getArticulosFase();
			this.getAreas();
		},
		getPersonas() {
			axios
				.get("/Personas")
				.then((response) => {
					this.personas = response.data;
				})
				.catch((error) => {
					console.log(error);
				});
		},
		getOperadores() {
			axios
				.get("/Personas/Operadores")
				.then((response) => {
					this.operadores = response.data;
				})
				.catch((error) => {
					console.log(error);
				});
		},
		getArticulosFase() {
			axios
				.get("/Articulos/ArticulosNombres?componentes=true")
				.then((response) => {
					this.articulosFase = response.data;
				})
				.catch((error) => {
					console.log(error);
				});
		},
		getArticulosProducir() {
			axios
				.get("/Articulos/OTS")
				.then((response) => {
					this.articulosProducir = response.data;
				})
				.catch((error) => {
					console.log(error);
				});
		},
		getClaves() {
			axios
				.get("/TrabajoOrdenes/TrabajoOrdenHeaders")
				.then((response) => {
					this.claves = response.data;
				})
				.catch((error) => {
					console.log(error);
				});
		},
		getAreas() {
			axios
				.get("/Areas")
				.then((response) => {
					this.areas = response.data;
				})
				.catch((error) => {
					console.log(error);
				});
		},
		getTipoSubParos() {
			axios
				.get("/SubParoTipos")
				.then((response) => {
					this.tipoSubparos = response.data;
				})
				.catch((error) => {
					console.log(error);
				});
		},
		changeParoTipo() {
			this.filtros.idSubParoTipo = null;
			this.getSeries();
		},
		getTipoParos() {
			axios
				.get("/ParoTipos")
				.then((response) => {
					this.tipoParos = response.data;
				})
				.catch((error) => {
					console.log(error);
				});
		},
		getOEEFactores() {
			axios
				.get("/OEEFactores")
				.then((response) => {
					this.oeeFactores = response.data;
					this.oeeFactores.push({
						idOEEFactor: 0,
						nombre: "SIN ESPECIFICAR",
						descripcion: "Sin Especificar",
					});
				})
				.catch((error) => {
					console.log(error);
				});
		},
		getSeries() {
			this.loading = true;

			axios
				.get("/Paros/GraficaParos", {
					params: {
						paginar: false,
						idParoTipo: this.filtros.idParoTipo,
						idSubParoTipo: this.filtros.idSubParoTipo,
						idTrabajoOrden: this.filtros.clave,
						idArticuloProducir: this.filtros.idArticuloProducir,
						idArticuloFase: this.filtros.idArticuloFase,
						idArea: this.filtros.idArea,
						idReporto: this.filtros.idReporto,
						idResponsable: this.filtros.idResponsable,
						idOEEFactor: this.filtros.idOEEFactor,
						bloqueoProduccion: this.filtros.bloqueoProduccion,
						inicio: this.filtros.inicio,
						fin: this.filtros.fin,
						tipoParo: this.filtros.idTipoParo,
					},
				})
				.then((response) => {
					this.setDefaultsChart(response.data.categories);
					this.series = response.data.series;
				})
				.catch((error) => {
					this.setDefaultsChart();
					this.series = [];
				})
				.finally(() => {
					this.loading = false;
				});
		},
		setDefaultsChart(categories = []) {
			this.chartOptions = {
				chart: {
					type: "bar",
					height: 350,
					stacked: true,
					toolbar: {
						show: true,
					},
					zoom: {
						enabled: true,
					},
				},
				responsive: [
					{
						breakpoint: 480,
						options: {
							legend: {
								position: "bottom",
								offsetX: -10,
								offsetY: 0,
							},
						},
					},
				],
				plotOptions: {
					bar: {
						horizontal: false,
						dataLabels: {
							total: {
								enabled: true,
								style: {
									fontSize: "13px",
									fontWeight: 900,
								},
							},
						},
					},
				},
				xaxis: {
					type: "category",
					categories,
				},
				legend: {
					position: "right",
					offsetY: 40,
				},
				fill: {
					opacity: 1,
				},
			};
		},
	},
};
</script>