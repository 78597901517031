<template>
  <v-dialog
    v-model="show"
    fullscreen
    hide-overlay
    transition="dialog-bottom-transition"
  >
    <v-overlay :value="cargando" opacity="0.96">
      <v-row justify="center" align="center">
        <div class="text-center ma-12">
          <v-progress-circular class="text-center" indeterminate size="64"></v-progress-circular>
          <br>
          <br>
          <p class="text-center display-1 font-italic">Cargando</p>
        </div>
      </v-row>
    </v-overlay>
    <v-card>
      <v-toolbar dark color="primary">
        <v-toolbar-title class="white--text">Insumos para {{name}}</v-toolbar-title>
        <v-spacer></v-spacer>
      </v-toolbar>
      <v-card-text>
        <v-row>
          <v-col v-for="(tipo,index) in tipos" :key="'a'+tipo.idArticuloTipo" :cols="columnTabla" :sm="columnTabla" :md="columnTabla">
            <drag-table
              :list="articulosFiltrados(tipo.idArticuloTipo)"
              :clone="clone"
              :key="('a'+tipo.idArticuloTipo)"
              :group="{name:'a'+tipo.idArticuloTipo,pull:'clone',put:false}"
              :header-text="toUpperCaseAfterSpace(tipo.nombre)" 
              :background-color="getColor(index)+' darken-2'"
              :item-text="codigoNombre"
              @insert="insertItem"
            ></drag-table>
          </v-col>
          <v-col v-if="herramientas.length > 0" key="a101" :cols="columnTabla" :sm="columnTabla" :md="columnTabla">
            <drag-table
              :list="herramientasFiltradas()"
              :clone="clone"
              key="a101"
              :group="{name:'a101',pull:'clone',put:false}"
              header-text="Herramientas" 
              :background-color="getColor(tipos.length+1)+' darken-2'"
              :item-text="marcaModelo"
              @insert="insertItem"
            ></drag-table>
          </v-col>
          <v-col v-if="puestos.length > 0" key="a102" :cols="columnTabla" :sm="columnTabla" :md="columnTabla">
            <drag-table
              :list="puestosFiltrados()"
              :clone="clone"
              key="a102"
              :group="{name:'a102',pull:'clone',put:false}"
              header-text="Operadores" 
              :background-color="getColor(tipos.length+2)+' darken-2'"
              :item-text="nombrePuesto"
              @insert="insertItem"
            ></drag-table>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" sm="12" md="12">
            <div class="text-center">
              <h4 v-if="permisoVista('cat-mantenimientopreventivo','$')">Costo de los Insumos: {{costoInsumosLabel()}}</h4>
            </div>
            <v-card
              class="md-12"
              color="grey lighten-2"
            >
              <drag-box-2
                :list="value"
                :item-text="etiqueta"
                :maxInt="duracion"
                :group="{name:'paso'+paso,pull:false,put:true}"
                :price="permisoVista('cat-mantenimientopreventivo','$')"
                replace
                @repaint="changeList"
                @update="changeList"
              ></drag-box-2>
            </v-card>
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn @click="close" text color="red">Cerrar</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>  
</template>

<script>
import axios from "axios";
import DragTable from "@/components/DragTable.vue";
import DragBox2 from "@/components/DragBox2.vue";

export default {
  components:{
    'drag-table':DragTable,
    'drag-box2':DragBox2,
  },
  props:{
    show:{
      type:Boolean,
      default:false,
    },
    value:{
      type:Array,
      default:[],
    },
    name:{
      type:String,
      default:"",
    },
    duracion:{
      type:Number,
      default:0,
    }
  },
  data: () => ({
    colors: ['blue','cyan','green','indigo','brown','lime','orange','pink','purple','red','teal','yellow'],
    loaded:0,
    target:3,
    defaultTarget:3,
    tipos:[],
    herramientas:[],
    puestos:[],
    cargando:true,
  }),
  watch:{
    show(){
      if(this.show == true){
        this.loaded = 0;
        this.initialize();
      }
    },
    loaded(){
      if(this.loaded == this.target){
        var articulos = [];
        this.tipos.forEach(tipo =>{
          articulos = articulos.concat(tipo.articulos);
        })
        for (let i = 0; i < this.value.length; i++) {
          if(this.value[i].idArticulo != null && !this.$utils.isValid(this.value[i].codigo)){
            var art = articulos.find(a => a.idArticulo == this.value[i].idArticulo);
            if(this.$utils.isValid(art)){
              var articulo = Object.assign(art,{});
            
              if(articulo.conversion){
                if(articulo.numDimensiones == 1){
                  articulo.cantidad = this.value[i].fraccion * articulo.factorConversion;
                }
                if(articulo.numDimensiones == 2){
                  articulo.cantidad = this.value[i].cantidad;
                  articulo._ejeX = this.value[i].ejeX;
                  articulo._ejeY = this.value[i].ejeY;
                }
                if(articulo.numDimensiones == 3){
                  articulo.cantidad = this.value[i].cantidad;
                  articulo._ejeX = this.value[i].ejeX;
                  articulo._ejeY = this.value[i].ejeY;
                  articulo._ejeZ = this.value[i].ejeZ;
                }
              }
              else articulo.cantidad = this.value[i].cantidad;
              this.value[i] = articulo;
            }
          }
          else if(this.value[i].idMaquina != null){
            var herra = this.herramientas.find(h => h.idMaquina == this.value[i].idMaquina);
            if(this.$utils.isValid(herramienta) && !this.$utils.isValid(this.value[i].marca)){
              var herramienta = Object.assign({},herra);
              herramienta.cantidad = this.value[i].cantidad;
              herramienta.tiempo = this.value[i].tiempo;
              this.value[i] = herramienta;
            }
          }
          else if(this.value[i].idPuesto != null){
            var pues = this.puestos.find(h => h.idPuesto == this.value[i].idPuesto);
            if(this.$utils.isValid(pues) && !this.$utils.isValid(this.value[i].nombre)){
              var puesto = Object.assign({},pues);
              puesto.cantidad = this.value[i].cantidad;
              puesto.tiempo = this.value[i].tiempo;
              this.value[i] = puesto;
            }
          }
        }
        this.cargando = false;
      }
    },
  },
  computed:{
    numTablas(){
        let i = 0;
        i += this.tipos.length;
        i += (this.herramientas.length)?1:0;
        i += (this.puestos.length)?1:0;
        return i;
      },
      columnTabla(){
        switch(this.numTablas){
          case 1:
            return 12;
          case 2:
            return 6;
          case 3:
            return 4;
          case 4:
            return 3;
          default:
            return 3;
        }
      },
  },
  methods:{
    marcaModelo: element => element.marca+' - '+element.modelo,
    codigoNombre: element => element.codigo+' - '+element.nombre,
    nombrePuesto: element => element.nombre,
    initialize(){
      this.cargando = true;
      this.getTipos();
      this.getMaquinas();
      this.getPuestos();
    },
    close(){
      this.show = false;
      this.$emit('close',false);
    },
    getTipos(){
      this.target = this.defaultTarget;
      axios
      .get("/ArticuloTipos")
      .then(response =>  {
        this.tipos = response.data.filter(t => {
          return t.vacio == false && (t.idArticuloTipo == 1 || (t.produccion == false && t.receta == false));
        })
        .map(tipo => {
          tipo.articulos = [];
          return tipo;
        });
        this.target += this.tipos.length;
        this.tipos.forEach( (tipo) => {
          axios
          .get("/Articulos/Tipo/"+tipo.idArticuloTipo+"?precio=true")
          .then(response => {
            tipo.articulos = response.data;
            this.loaded += 1;
          })
          .catch(error => {
            console.log(error);
          })
        });
        this.loaded += 1;
      })
      .catch(error => {
        console.log(error);
      });
    },
    getMaquinas(){
      axios
      .get("/Maquinas")
      .then(response =>  {
        this.herramientas = response.data.filter(function(el) {
          return (el.esMaquina == false);
        });
        this.loaded += 1;  
      })
      .catch(error => {
        console.log(error);
      });
    },
    getPuestos(){
      axios
      .get("/Puestos")
      .then(response =>  {
        this.puestos = response.data;
        this.loaded += 1;
       })
      .catch(error => {
        console.log(error);
      });
    },
    toUpperCaseAfterSpace(string){
      let s = string.toLowerCase();
      s = this.setCharAt(s,0,s[0].toUpperCase());
      for(var i = 0; i<s.length; i++){
        if( s[i] == ' ') s = this.setCharAt(s,i+1,s[i+1].toUpperCase());
      }
      return s;
    },
    setCharAt(str,index,chr) {
      if(index > str.length-1) return str;
      return str.substr(0,index) + chr + str.substr(index+1);
    },
    getColor(pos){
      return this.colors[pos % this.colors.length];
    },
    clone: function(el){
      return Object.assign({}, el);
    },
    insertItem(event){
      var item = Object.assign({}, event);
      item.cantidad = 1;
      item.tiempo = 1;
      this.value.push(item);
    },
    etiqueta: element => {
      if(typeof element.marca !== "undefined" && typeof element.idPuesto === 'undefined'){
        return element.marca+' - '+element.modelo;
      }
      else if (typeof element.idPuesto !== 'undefined'){
        return element.nombre;
      }
      else if(typeof element.codigo !== 'undefined'){
        return element.codigo+' - '+element.nombre
      }
      else{
        return "Hola";
      }
    },
    articulosFiltrados(idArticuloTipo){
      let coleccion = this.tipos.find(function(element){
        return element.idArticuloTipo == idArticuloTipo;
      });
      
      if(coleccion == null) return [];
      let ids = this.value.filter(function(element){
        return typeof element.idArticulo !== 'undefined';
      })
      .map(function(element){
        return element.idArticulo;
      });        
      return coleccion.articulos.filter(function(element){
        return ids.indexOf(element.idArticulo) == -1;
      });
    },
    herramientasFiltradas(){
      let ids = this.value.filter(function(element){
        return typeof element.idMaquina !== 'undefined' && !element.esMaquina;
      })
      .map(function(element){
        return element.idMaquina;
      });        
      return this.herramientas.filter(function(element){
        return ids.indexOf(element.idMaquina) == -1;
      });
    },
    puestosFiltrados(){
      let ids = this.value.filter(function(element){
        return typeof element.idPuesto !== 'undefined';
      })
      .map(function(element){
        return element.idPuesto;
      });        
      return this.puestos.filter(function(element){
        return ids.indexOf(element.idPuesto) == -1;
      });
    },
    calcularFraccion(num,x,y,z){
      if(num == 1){
        return this.$utils.numbers.validFloat(x);
      }
      if(num == 2){
        return this.$utils.numbers.validFloat(x) * this.$utils.numbers.validFloat(y);
      }
      if(num == 3){
        return this.$utils.numbers.validFloat(x) * this.$utils.numbers.validFloat(y) * this.$utils.numbers.validFloat(z);
      }
    },
    costoInsumos(numPaso){
        if(typeof this.value === 'undefined') return "0.00";

        let costoMin = 0.0;
        let costoMax = 0.0;
        this.value.forEach((element) => {
          if(typeof element.idDesconocido === 'undefined' && typeof element.idArticulo !== 'undefined'){
            if(element.costo == null) {
              return;
            }
            if(!element.conversion){
              costoMin += element.costo.min * this.$utils.numbers.validFloat(element.cantidad);
              costoMax += element.costo.max * this.$utils.numbers.validFloat(element.cantidad);
            }
            else{
              switch(element.numDimensiones){
                case 1:
                  costoMin += (this.$utils.numbers.validFloat(element.cantidad) / element.factorConversion) * element.costo.min;
                  costoMax += (this.$utils.numbers.validFloat(element.cantidad) / element.factorConversion) * element.costo.max;
                  break;
                case 2:
                  costoMin += ( this.calcularFraccion(element.numDimensiones,element._ejeX,element._ejeY,element._ejeZ) / element.factorConversion) * element.costo.min * this.$utils.numbers.validFloat(element.cantidad);
                  costoMax += ( this.calcularFraccion(element.numDimensiones,element._ejeX,element._ejeY,element._ejeZ) / element.factorConversion) * element.costo.max * this.$utils.numbers.validFloat(element.cantidad);
                  
                  break;
                case 3:
                  costoMin += ( this.calcularFraccion(element.numDimensiones,element._ejeX,element._ejeY,element._ejeZ) / element.factorConversion) * element.costo.min * this.$utils.numbers.validFloat(element.cantidad);
                  costoMax += ( this.calcularFraccion(element.numDimensiones,element._ejeX,element._ejeY,element._ejeZ) / element.factorConversion) * element.costo.max * this.$utils.numbers.validFloat(element.cantidad);
                  break;
              }
            }
          }
          else if(typeof element.idDesconocido === 'undefined' && typeof element.costo !== 'undefined'){
            costoMin += element.costo * this.$utils.numbers.validFloat(element.cantidad);
            costoMax += element.costo * this.$utils.numbers.validFloat(element.cantidad);
          }

          else if(typeof element.idDesconocido === 'undefined' && typeof element.costoHora !== 'undefined'){
            costoMin += element.cantidad * (element.costoHora / 3600) * element.tiempo;
            costoMax += element.cantidad * (element.costoHora / 3600) * element.tiempo;
          }

          else if(typeof element.idDesconocido !== 'undefined'){
            if(element.tipoCosto){
              costoMin += ( element.cantidad * element.costo );
              costoMax += ( element.cantidad * element.costo );
            }
            else{
              costoMin += (element.tiempo / 3600.0) * element.costo * element.cantidad;
              costoMax += (element.tiempo / 3600.0) * element.costo * element.cantidad;
            }
          }
        });

        return [costoMin,costoMax];
      },
      costoInsumosLabel(numPaso){
        let costo = this.costoInsumos(numPaso);
        if(costo[0] == costo[1]){
          this.$emit('update:price',"$"+costo[0].toFixed(2));
          return "$"+costo[0].toFixed(2); 
        }
        else{
          this.$emit('update:price',"$"+costo[0].toFixed(2)+" ~ "+"$"+costo[1].toFixed(2));
          return "$"+costo[0].toFixed(2)+" ~ "+"$"+costo[1].toFixed(2);
        }
      },
  }
}
</script>