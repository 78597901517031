<template>
	<v-btn
		:color="color"
		:text="text"
		:class="className"
		@click="clickFunc"
		style="font-size: 12px !important"
		:style="style"
		:ripple="false"
		:disabled="loading || disabled"
	>
		<v-icon
			v-if="icon && left"
			:size="iconSize"
			:left="left"
			v-html="icon"
		/>
		<div
			v-html="txtButton"
		/>
		<v-icon
			v-if="icon && right"
			:size="iconSize"
			:right="right"
			v-html="icon"
		/>
	</v-btn>
</template>

<script>
export default {
	props: {
		left: {
			type: Boolean,
			default: false
		},
		right: {
			type: Boolean,
			default: false
		},
		color: {
			type: String,
			default: "primary",
		},
		icon: {
			type: String,
			default: ""
		},
		iconSize: {
			type: String || Number,
			default: 24
		},
		text: {
			type: Boolean,
			default: false,
		},
		className: {
			type: String,
			default: "",
		},
		click: {
			type: Function,
			default: () => {},
		},
		label: {
			type: String,
			default: "",
		},
		style: {
			type: String,
			default: "",
		},
		retryTime:{
			type: Number,
			default: 10
		},
		disabled: {
			type: Boolean,
			default: false
		},
		waitingText: {
			type: String,
			default: "Descargando"
		}
	},
	data() {
		return {
			txtButton: this.label,
			segundos: this.retryTime,
			interval: null,
			loading: false,
		};
	},
	mounted() {
		this.initialize();
	},
	destroyed() {
		this.stopInterval();
	},
	methods: {
		initialize() {},
		clickAsync() {
			return new Promise((resolve) => {
				this.$emit("click");
				return resolve();
			});
		},
		clickFunc() {
			this.loading = true;
			const txtWait = this.waitingText ? `${this.waitingText}...<br>` : '';
			this.txtButton = `${txtWait}Espere ${this.segundos} seg.`;
			this.interval = setInterval(() => {

				if (this.segundos == this.retryTime) {
					this.clickAsync();
				}

				this.segundos -= 1;

				if (this.segundos == 0) {
					this.stopInterval();
					return;
				}
				
				this.txtButton = `${txtWait}Espere ${this.segundos} seg.`;
			}, 1000);
		},
		stopInterval() {
			clearInterval(this.interval);
			this.segundos = this.retryTime;
			this.loading = false;
			this.$nextTick(() => {
				this.txtButton = this.label;
			});
		},
	},
};
</script>