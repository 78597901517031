<template>
  <v-row>
    <v-col cols="3">
      <!--  <div
        v-if="!viewCamera"
        style="
          max-width: 350px;
          max-height: 197px;
          margin-left: auto;
          margin-right: auto;
        "
      >
        <h3></h3>
      </div> -->
      <v-card v-if="!viewCamera"
        elevation="0"
      >
        <v-row>
          <v-col cols="12" class="pb-0">
            <v-img
              class="white--text align-end"
              height="200px"
              src="https://i.imgur.com/mdRIzgI.png"
            >
              <v-card-title class="text-center">{{ errorCamera }}</v-card-title>
            </v-img>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" class="py-0">
            <v-text-field
              v-model="codigoIdentificacion"
              label="Código de Identificación"
              hide-details
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" class="pt-0">
            <v-btn
              color="green"
              block
              @click="getInformacionCodigo"
            >
              Leer Código Manualmente
            </v-btn>
          </v-col>
        </v-row>
      </v-card>
      <v-card v-else class="mx-auto" max-width="350" elevation="0">
        <v-row class="ma-0 pa-0">
          <v-col cols="12" class="ma-0 pa-0">
            <qrcode-stream @init="onInit" @decode="onDecode"></qrcode-stream>
          </v-col>
          <v-col cols="12" class="ma-0 pa-0">
            <v-text-field
              v-model="codigoIdentificacion"
              label="Código de Identificación"
              hide-details
            >
            </v-text-field>
          </v-col>
          <v-col cols="12" class="ma-0 pa-0">
            <v-btn
              color="green"
              block
              @click="getInformacionCodigo"
            >
              Leer Código Manualmente
            </v-btn>
          </v-col>
        </v-row>
      </v-card>
    </v-col>
    <v-col cols="9">
      <v-treeview
        v-if="!cargando"
        :items="arbol"
        open-all
      >
        <template v-slot:prepend="{ item }">
          <v-icon>
            {{ icons[item.tipo] }}
          </v-icon>
        </template>
      </v-treeview>
      <v-progress-linear
        v-else
        indeterminate
      ></v-progress-linear>
    </v-col>
  </v-row>
</template>

<script>
import { QrcodeStream } from "vue-qrcode-reader";
import axios from "axios";

export default {
  components: {
    QrcodeStream,
  },
  data: () => ({
    viewCamera: true,
    errorCamera: null,
    codigoIdentificacion: null,
    icons:{
      qr: 'mdi-qrcode',
      area: 'mdi-texture-box',
      operadores: 'mdi-account-group',
      operador: 'mdi-account-hard-hat',
      maquinas: 'mdi-hammer-wrench',
      maquina: 'mdi-robot-industrial-outline',
      materiasprimas: 'mdi-shape-plus',
      materiaprima: 'mdi-plus-circle-outline',
      componentes: 'mdi-sitemap',
      componente: 'mdi-cog-outline',
      paso: 'mdi-multicast',
      subpaso: 'mdi-ray-start-arrow',
      tiempo: 'mdi-clock-outline'
    },
    arbol: [],
    cargando: false,
  }),
  mounted() {},
  methods: {
    initialize(){},
    onDecode(code) {
      this.codigoIdentificacion = code;
      this.getInformacionCodigo();
    },
    getInformacionCodigo(){
      this.cargando = true;
      axios
      .get(`/Trazabilidad/GenerarArbol?codigo=${this.codigoIdentificacion}`)
      .then(response => {
        this.arbol = response.data;
        this.cargando = false;
      })
      .catch(error => {
        console.log(error);
        this.arbol = [];
        this.cargando = false;
      })
    },
    async onInit(promise) {
      try {
        const { capabilities } = await promise;
      } catch (error) {
        if (error.name === "InsecureContextError") {
          this.viewCamera = false;
          this.errorCamera = "Conexion de camara no segura";
          return;
        }

        if (error.name === "NotAllowedError") {
          this.viewCamera = false;
          this.errorCamera = "Se denego el permiso de acceso a la cámara";
          return;
        }
        if (error.name === "NotFoundError") {
          this.viewCamera = false;
          this.errorCamera = "No hay ningun dispositivo instalado";
          return;
        }

        if (error.name === "NotSupportedError") {
          this.viewCamera = false;
          this.errorCamera = "No funciona a traves de HTTP o localhost";
          return;
        }

        if (error.name === "NotReadableError") {
          this.viewCamera = false;
          this.errorCamera = "La cámara se encuentra en uso";
          return;
        }
        if (error.name === "OverconstrainedError") {
          this.viewCamera = false;
          this.errorCamera =
            "did you requested the front camera although there is none?";
          return;
        }
        if (error.name === "StreamApiNotSupportedError") {
          this.viewCamera = false;
          this.errorCamera =
            "El navegador no cuenta con las funciones suficientes";
          return;
        }
      }
    },
  },
};
</script>
