<template>
	<div>
		<!--Creación de las 3 pestañas-->
		<v-tabs v-model="tab" background-color="transparent" grow>
			<v-tab v-if="mostrarVista('optimizacion')" @click="optimizacionProduccionClick">Producción</v-tab>
			<v-tab v-if="mostrarVista('simulacion')" @click="realizarSimulacionClick">
				Realizar Simulación
			</v-tab>
			<v-tab v-if="mostrarVista('simulacion')" @click="configSimulationClick"> Configuración </v-tab>
			
			<!--Pestaña de producción-->
			<v-tabs-items v-model="tab" touchless>
				<v-tab-item eager>
					<OptimizacionProduccion
						ref="optiSim"
					 />
				</v-tab-item>
				<!--Pestaña de realizar simulación-->
				<v-tab-item eager>
				<div class="mt-3">
						<!--Mensaje de error-->
						<v-dialog v-model="erroresSimulacionDialog" persistent width="45%">
							<v-card>
								<v-card-title>
									<span class="text-h5"
										>Errores de Configuración de Simulación</span
									>
								</v-card-title>
								<v-card-text>
									<v-container fluid>
										<v-row>
											<v-col cols="12" class="pa-0">
												<v-data-table
													:items="validacionItems"
													:headers="headersErroresValidacion"
													:items-per-page="-1"
													hide-default-footer
												>
													<template
														v-slot:[`item.indice`]="{ index }"
													>
														{{ index + 1 }}
													</template>
													<template v-slot:[`item.error`]="{ item }">
														{{ item }}
													</template>
												</v-data-table>
											</v-col>
										</v-row>
									</v-container>
								</v-card-text>
								<v-card-actions>
									<v-spacer></v-spacer>
									<v-btn
										color="red darken-1"
										text
										@click="erroresSimulacionDialog = false"
									>
										Cerrar
									</v-btn>
								</v-card-actions>
							</v-card>
						</v-dialog>
						<v-slide-y-transition>
							<v-col cols="12" v-if="loadingValidation || !validationSuccess">
								<v-row>
									<v-col cols="12" class="py-0">
										<v-alert dense :color="alertColor" outlined>
											<v-row align="center">
												<v-col class="grow">
													<p class="mb-0" v-if="loadingValidation">
														<v-icon
															v-if="loadingValidation"
															large
															color="primary"
															>mdi-cube-scan</v-icon
														>
														<v-icon
															v-else-if="
																!loadingValidation &&
																!validationSuccess
															"
															large
															color="error"
															>mdi-alert</v-icon
														>
														Se está realizando la
														<span
															class="font-weight-bold"
															style="font-size: 14px !important"
															>validación</span
														>
														de la configuración de la
														<span
															class="font-weight-bold"
															style="font-size: 14px !important"
															>simulación</span
														>.
													</p>
													<p
														class="mb-0"
														v-else-if="
															!loadingValidation &&
															!validationSuccess
														"
													>
														<v-icon large color="red"
															>mdi-alert</v-icon
														>
														Se han detectado
														<span
															class="font-weight-bold"
															style="font-size: 14px !important"
															>errores</span
														>
														de configuración que impiden
														<span
															class="font-weight-bold"
															style="font-size: 14px !important"
															>realizar la simulación</span
														>.
													</p>
												</v-col>
												<v-col class="shrink">
													<v-btn
														class="ma-2"
														:loading="loadingValidation"
														:disabled="loadingValidation"
														color="error"
														@click="erroresSimulacionDialog = true"
													>
														Ver detalles
													</v-btn>
												</v-col>
											</v-row>
										</v-alert>
									</v-col>
								</v-row>
							</v-col>
						</v-slide-y-transition>
						<!--Componente "lanzar simulación"-->
						<LanzarSimulacion
							ref="launchSim"
							:loadingValidation="loadingValidation"
							:validationSuccess="validationSuccess"
						/>
				</div>
				</v-tab-item>
				<!--Pestaña configuración -->
				<v-tab-item eager>
					<ConfigSimulacion
						ref="configSim"
						@refreshValidation="validarSimulacion"
					/>
				</v-tab-item>
			</v-tabs-items>
		</v-tabs>
		
	</div>
</template>

<script>
import axios from "axios";
import ConfigSimulacion from "./Simulacion/ConfigSimulacion.vue";
import LanzarSimulacion from "./Simulacion/LanzarSimulacion.vue";
import OptimizacionProduccion from '../Produccion/OptimizacionProduccion.vue';

export default {
	components: {
		ConfigSimulacion,
		LanzarSimulacion,
		OptimizacionProduccion
	},
	data() {
		return {
			erroresSimulacionDialog: false,
			validacionItems: [],
			validationSuccess: false,
			loadingValidation: false,
			tab: 0,
			headersErroresValidacion: [
				{
					text: "#",
					align: "center",
					value: "indice",
				},
				{
					text: "Error",
					align: "center",
					value: "error",
				},
			],
		};
	},
	computed: {
		alertColor() {
			if (this.loadingValidation) {
				return "primary";
			} else if (!this.loadingValidation && !this.validationSuccess) {
				return "error";
			}
		},
	},
	mounted() {
		this.initialize();
	},
	methods: {
		initialize() {
			this.configSimulationClick();
			this.realizarSimulacionClick();
			this.optimizacionProduccionClick();
			this.validarSimulacion();
		},
		validarSimulacion() {
			this.loadingValidation = true;
			axios
				.get("/TrabajoOrdenes/ValidarSimulacion")
				.then((response) => {
					this.validacionItems = response.data;
					this.validationSuccess = response.data.length == 0;
				})
				.catch((error) => {
					console.log(error);
				})
				.finally(() => {
					this.loadingValidation = false;
				});
		},
		realizarSimulacionClick() {
			if (typeof this.$refs.launchSim === "undefined") return;
			this.$refs.launchSim.initialize();
		},
		configSimulationClick() {
			if (typeof this.$refs.configSim === "undefined") return;
			this.$refs.configSim.initialize();
		},
		optimizacionProduccionClick(){
			if (typeof this.$refs.optiSim === "undefined") return;
		    this.$refs.optiSim.initialize();
		}
	},
};
</script>