<template>
  <v-col cols="12" class="px-8" v-if="permisoVista('dashboard','r')">
    <v-row >
      <v-col cols="4" md="4" sm="4" class="align-self-end">
        <datePicker
          v-model="desde"
          format="YYYY-MM-DD"
          :maxDate="fin"
          label="Desde (Obligatorio)"
          clearable
          maxToday
          @input="getMaquinasIns();obtenerDatos()"
          :disabled="cargando"
        ></datePicker>
      </v-col>
      <v-col cols="4" md="4" sm="4" class="align-self-end">
        <datePicker
          v-model="hasta"
          :minDate="desde"
          format="YYYY-MM-DD"
          label="Hasta (Obligatorio)"
          clearable
          maxToday
          @input="getMaquinasIns();obtenerDatos()"
          :disabled="cargando"
        ></datePicker>
      </v-col>
      <v-col cols="4" md="4" sm="4" class="align-self-end">
        <v-autocomplete
					v-model="idTurno"
					:items="turnos"
					item-text="nombre"
					item-value="idTurno"
					label="Turno (Opcional)"
					hide-details
					clearable
					@change="obtenerDatos"
					:disabled="cargando"
				></v-autocomplete>
      </v-col>
      <!-- <v-col cols="3" class="align-self-end">
        <VueCtkDateTimePicker
          v-model="desdeHora"
          label="Hora Inicio"
          format="hh:mm a"
          formatted="hh:mm a"
          outputFormat="HH:mm"
          inputSize="sm"
          :minuteInterval="10"
          onlyTime
          noLabel
          @input="obtenerDatos"
          :disabled="cargando"
        />
      </v-col>
      <v-col cols="3" class="align-self-end">
        <VueCtkDateTimePicker
          v-model="hastaHora"
          label="Hora Fin"
          format="hh:mm a"
          formatted="hh:mm a"
          outputFormat="HH:mm"
          inputSize="sm"
          :minuteInterval="10"
          onlyTime
          noLabel
          @input="obtenerDatos"
          :disabled="cargando"
        />
      </v-col> -->
    </v-row>
    <v-row class="mb-2">
      <v-col cols="12">
        <v-autocomplete
          v-model="idsMaquinasIns"
          label="Instancias de Máquinas (Opcional)"
          :items="maquinasIns"
          item-value="idMaquinaIns"
          :item-text="nombreMaquina"
          multiple
          clearable
          dense
          hide-details
          @change="obtenerDatos"
          :disabled="cargando"
        ></v-autocomplete>
      </v-col>
    </v-row>
    <v-row v-if="realizarPeticion" class="px-0 ma-0">
      <v-col cols="12" class="ma-0 text-right text-subtitle-2">
        Proxima Actualización en {{tiempo}}
      </v-col>
    </v-row>
    <v-progress-linear
      v-if="cargando"
			color="primary"
			height="5"
			indeterminate
		></v-progress-linear>
    <apexchart type="bar" height="350" :options="chartOptions" :series="series"></apexchart>
  </v-col>
</template>

<script>
import axios from "axios"

export default {
  props:{
    active:{
      type: Boolean,
      default: false,
    }
  },
  computed:{
    realizarPeticion(){
      if(this.desdeHora != null && this.hastaHora != null && this.desde != null && this.hasta != null) return true;
      else if(this.desdeHora == null && this.hastaHora == null && this.desde != null && this.hasta != null) return true;
      else return false;
      
    },
    url(){
      var url = `/Dashboard/Grafica4?Desde=${this.desde}&Hasta=${this.hasta}`;
      if(this.desdeHora != null && this.hastaHora != null) url += `&DesdeHora=${this.desdeHora}&HastaHora=${this.hastaHora}`;
      if(this.idsMaquinasIns.length > 0){
        this.idsMaquinasIns.forEach(idMaquinaIns => {
          url += `&idsMaquinasIns=${idMaquinaIns}`;
        })
      }
      return url;
    },
    chartOptions(){
      return {
        states: {
          active: {
              allowMultipleDataPointsSelection: false,
          }
        },
        chart: {
          type: 'bar',


          height: 350,
        },
        plotOptions: {
          bar: {
            horizontal: false,
            columnWidth: '55%',
            endingShape: 'rounded'
          },
        },
        dataLabels: {
          enabled: false
        },
        stroke: {
          show: true,
          width: 2,
          colors: ['transparent']
        },
        xaxis: {
          categories: this.categories.map(c => c.maquinaIns),
        },
        yaxis: {
          /*
          title: {
            text: '$ (thousands)'
          }
          */
        },
        fill: {
          opacity: 1
        },
        tooltip: {
          /*
          y: {
            formatter: function (val) {
              return "$ " + val + " thousands"
            }
          }
          */
        }
      }
    },
    tiempo(){
      return this.$utils.strings.toTime(this.segundos);
    }
  },
  data: () => ({
    interval: null,
    segundos: 60,
    cargando: false,
    desde: null,
    hasta:null,
    desdeHora: null,
    hastaHora:null,
    categories: [],
    series: [],
    maquinasIns:[],
    idsMaquinasIns: [],
    idTurno: null,
    turnos: []
  }),
  methods:{
    nombreMaquina: maquina => ` [${maquina.nombre}] - ${maquina.marca} ${maquina.modelo}`,
    getTurnos() {
			axios
			.get("/Turnos")
			.then((response) => {
				this.turnos = response.data;
			})
			.catch((error) => {
				console.log(error);
			});
		},
    obtenerDatos(){
      if(!this.realizarPeticion || this.cargando) return;

      this.cargando = true;
      this.segundos = 60;

      axios
      .get(this.url,{
        params: {
          IdTurno: this.idTurno
        }
      })
      .then(response => {
        this.categories = response.data.categories;
        this.series = response.data.series;
        this.cargando = false;
      })
      .catch(error => {
        this.cargando = false;
        console.log(error);
      })
    },
    getMaquinasIns(){
      if(this.desde==null || this.hasta==null){
        this.maquinasIns = [];
        return;
      }

      axios
      .get("/MaquinaInstancias/MaquinasConOperaciones",{
        params:{
          fechaInicio: this.desde,
          fechaFin: this.hasta
        }
      })
      .then(response => {
        this.maquinasIns = response.data;
      })
      .catch(error => {
        console.log(error);
      })
    },
  },
  mounted(){
    this.interval = setInterval(() => {
      if(!this.active) return;

      if(this.segundos == 0) {
        this.obtenerDatos();
      }
      else this.segundos -= 1;
    },1000);
    this.getTurnos();
  },
  destroyed(){
    clearInterval(this.interval);
  }
}
</script>