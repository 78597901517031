<template>
  <v-dialog v-model="showDialog" persistent max-width="80%">
    <v-card>
      <v-card-title>{{title}}</v-card-title>
      <v-card-text>
        <v-container>
          <apexchart ref="chart" :type="type" :height="height" :options="chartOptions" :series="series"></apexchart>
        </v-container>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="blue" text @click="close">Cerrar</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
  export default {
    data: () => ({
      showDialog:false,
      title: "",
      height: 440,
      chartOptions: {
        chart: {
          type: 'bar',
          height: 430
        },
        plotOptions: {
          bar: {
            horizontal: true,
            dataLabels: {
              position: 'top',
            },
          }
        },
        colors: ['#008FFB', '#FF4560'],
        dataLabels: {
          enabled: false
        },
        stroke: {
          width: 1,
          colors: ["#fff"]
        },
        
        grid: {
          xaxis: {
            lines: {
              show: false
            }
          }
        },
        yaxis: {
          min: 0,
          max: 0,
          title: {
            // text: 'Age',
          },
        },
        tooltip: {
          shared: false,
          x: {
            formatter: function (val) {
              return val
            }
          },
          y: {
            formatter: function (val) {
              return "$ " + (Math.abs(val)).toLocaleString('en-US', {minimumFractionDigits: 2, maximumFractionDigits: 2});
            }
          }
        },
        xaxis: {
          categories: [],
          title: {
            text: 'Costo'
          },
          labels: {
            formatter: function (val) {
              return "$ " + (Math.abs(Math.round(val))).toLocaleString('en-US', {minimumFractionDigits: 2, maximumFractionDigits: 2});
            }
          }
        },
      },
      defaultChartOptions: {
        chart: {
          type: 'bar',
          height: 430
        },
        plotOptions: {
          bar: {
            horizontal: true,
            dataLabels: {
              position: 'top',
            },
          }
        },
        colors: ['#008FFB', '#FF4560'],
        dataLabels: {
          enabled: false
        },
        stroke: {
          width: 1,
          colors: ["#fff"]
        },
        
        grid: {
          xaxis: {
            lines: {
              show: false
            }
          }
        },
        yaxis: {
          min: 0,
          max: 0,
          title: {
            // text: 'Age',
          },
        },
        tooltip: {
          shared: false,
          x: {
            formatter: function (val) {
              return val
            }
          },
          y: {
            formatter: function (val) {
              return "$ " + (Math.abs(val)).toLocaleString('en-US', {minimumFractionDigits: 2, maximumFractionDigits: 2});
            }
          }
        },
        xaxis: {
          categories: [],
          title: {
            text: 'Costo'
          },
          labels: {
            formatter: function (val) {
              return "$ " + (Math.abs(Math.round(val))).toLocaleString('en-US', {minimumFractionDigits: 2, maximumFractionDigits: 2});
            }
          }
        },
      },
      series:[],
      type:'bar'
    }),
    methods:{
      show(title,limit,categories,series,type = null, height = null,){
        this.showDialog = true;
        this.title = title;
        this.chartOptions = Object.assign({},this.defaultChartOptions);
        this.chartOptions.yaxis.min = 0;
        this.chartOptions.yaxis.max = limit;
        this.chartOptions.xaxis.categories = Object.assign([],categories);
        this.series = series;
        if(type != null)this.type = type;
        if(height != null)this.height = height;
        setTimeout(() => {
          this.$refs.chart.refresh();
        },250);
      },
      close(){
        this.showDialog = false;
        this.title = "";
        this.chartOptions = Object.assign({},this.defaultChartOptions);
        this.series = [];
        this.type = 'bar';
        this.height = 440;
      }
    }
  }
</script>