<template>
  <v-container>
    <v-col
      cols="12"
      md="12"
      sm="12"
      v-if="operaciones.length === 0 && sinDatos"
    >
      <v-card flat>
        <div class="text-center mt-16 pt-16">
          <v-progress-circular
            :size="70"
            :width="7"
            color="green"
            indeterminate
          ></v-progress-circular>
          <h2>Cargando...</h2>
        </div>
      </v-card>
    </v-col>
    <div v-else v-for="(op, idx) in operaciones" :key="'operacion-' + idx">
      <operacion
        v-for="(detalle, idx2) in op.operacionesEnProgreso"
        :key="'op-' + idx + '-' + idx2"
        :operacion="op"
        :sesiones="sesiones"
        :area="area"
        :paros="paros"
        :paroTipos="paroTipos"
        :detalle="detalle"
        @change="actualizar"
        @update="paroActualizar"
      ></operacion>
    </div>
    <v-col cols="12" md="12" sm="12" v-if="operaciones.length == 0 && !sinDatos">
      <v-card flat>
        <div class="h2 text-center">
          No hay Operaciones en Progreso en Esta Área
        </div>
      </v-card>
    </v-col>
  </v-container>
</template>

<script>
import axios from "axios";
import operacion from "./Operacion";

export default {
  components: {
    operacion,
  },
  props: {
    area: {
      type: Object,
      default: {
        area: "",
        idArea: -1,
        operacionesCurso: 0,
      },
    },
  },
  data: () => ({
    operaciones: [],
    sesiones: [],
    paros: [],
    paroTipos: [],
    sinDatos: true,
  }),
  created() {
    this.getParoTipos();
  },
  methods: {
    actualizar() {
      this.getOperaciones();
      this.getOperadores();
      this.getParos();
    },
    detallesFiltrados(operaciones) {
      return operaciones.filter((e) => {
        return e.iniciada;
      });
    },
    getOperaciones() {
      this.operaciones = [];
      axios
        .get("/ComandoYControl/OperacionesProgreso/" + this.area.idArea)
        .then((response) => {
          this.operaciones = response.data;
          if(this.operaciones.length == 0) this.sinDatos = false;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    paroActualizar() {
      this.getParos();
    },
    getOperadores() {
      axios
        .get("/ComandoYControl/SesionesAbiertas/" + this.area.idArea)
        .then((response) => {
          this.sesiones = response.data;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    actualizarParos() {
      this.getParos();
    },
    getParos() {
      axios
        .get("/ComandoYControl/Paros/" + this.area.idArea)
        .then((response) => {
          this.paros = response.data;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    getParoTipos() {
      axios
        .get("/ComandoYControl/ParoTipos")
        .then((response) => {
          this.paroTipos = response.data;
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },
};
</script>