import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VForm } from 'vuetify/lib/components/VForm';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VCol,{attrs:{"cols":"12"}},[_c(VDialog,{attrs:{"persistent":"","max-width":"40%"},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c(VCard,[_c(VCardText,{staticClass:"pb-0"},[_c('p',{staticClass:"text-center headline pt-4 pb-0 mb-0"},[_vm._v(" Se ha enviado un email a su correo nuevo para confirmar el cambio ")]),_c('lottie',{staticClass:"py-0",attrs:{"options":_vm.checkOptions,"height":200,"width":200}})],1),_c(VCardActions,{staticClass:"pt-0"},[_c(VBtn,{attrs:{"color":"primary","block":"","large":""},on:{"click":_vm.logout}},[_vm._v("Aceptar")])],1)],1)],1),_c(VForm,{ref:"form",attrs:{"lazy-validation":""},model:{value:(_vm.valid),callback:function ($$v) {_vm.valid=$$v},expression:"valid"}},[_c(VRow,{staticClass:"d-flex justify-center"},[_c(VCol,{attrs:{"cols":"8"}},[_c('div',{staticClass:"headline"},[_vm._v("Cambiar E-mail")])]),_c(VCol,{attrs:{"cols":"8"}},[_c(VTextField,{attrs:{"prepend-icon":"mdi-email","label":"Nuevo E-mail*","type":"text","required":"","rules":_vm.emailRules},model:{value:(_vm.emailItem.nuevoEmail),callback:function ($$v) {_vm.$set(_vm.emailItem, "nuevoEmail", $$v)},expression:"emailItem.nuevoEmail"}}),_c('p',{staticClass:"mt-2 mb-0"},[_vm._v("*campo requerido")])],1),_c(VCol,{staticClass:"d-flex justify-end",attrs:{"cols":"8"}},[_c(VBtn,{attrs:{"disabled":_vm.loadingEmail,"loading":_vm.loadingEmail,"color":"primary mx-2"},on:{"click":_vm.save}},[_vm._v(" Guardar ")])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }