import { VBtn } from 'vuetify/lib/components/VBtn';
import { VMenu } from 'vuetify/lib/components/VMenu';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';
import { VTimePicker } from 'vuetify/lib/components/VTimePicker';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VMenu,{ref:"menu",attrs:{"close-on-content-click":false,"nudge-right":40,"return-value":_vm.value,"transition":"scale-transition","offset-y":"","max-width":"290px","min-width":"290px"},on:{"update:returnValue":function($event){_vm.value=$event},"update:return-value":function($event){_vm.value=$event}},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c(VTextField,_vm._g({attrs:{"label":_vm.label,"prepend-icon":"access_time","readonly":""},model:{value:(_vm.value),callback:function ($$v) {_vm.value=$$v},expression:"value"}},on))]}}]),model:{value:(_vm.menu2),callback:function ($$v) {_vm.menu2=$$v},expression:"menu2"}},[(_vm.menu2)?_c(VTimePicker,{attrs:{"min":_vm.min,"max":_vm.max,"full-width":"","ampm-in-title":""},on:{"input":_vm.updateDate},model:{value:(_vm.value),callback:function ($$v) {_vm.value=$$v},expression:"value"}},[_c(VSpacer),_c(VBtn,{attrs:{"text":"","color":"blue"},on:{"click":function($event){_vm.menu2 = false}}},[_vm._v("Cancelar")]),_c(VBtn,{attrs:{"text":"","color":"red"},on:{"click":function($event){return _vm.$refs.menu.save(_vm.value)}}},[_vm._v("Aceptar")])],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }