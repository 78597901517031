<template>
  <v-col cols="12">
    <v-row>
      <v-col xs="12" sm="12" md="12" lg="12" xl="12">
        <v-btn
          color="primary"
          dark
          class="mb-2"
          @click="dialogCreateEdit = true"
        >
          Nueva liberación
        </v-btn>
      </v-col>
      <v-col xs="12" sm="12" md="6" lg="6" xl="6">
        <v-autocomplete
          v-model="idsArticulos"
          :items="articulos"
          item-value="idArticulo"
          :item-text="nombreArticulo"
          label="Articulos"
          :loading="loadingArticulos"
          multiple
          clearable
          chips
          @change="getData()"
        ></v-autocomplete>
      </v-col>
      <v-col xs="12" sm="12" md="6" lg="6" xl="6">
        <v-autocomplete
          v-model="idsAreas"
          label="Áreas"
          :items="areas"
          item-value="idArea"
          item-text="nombre"
          :loading="loadingAreas"
          multiple
          clearable
          chips
          @change="getData()"
        ></v-autocomplete>
      </v-col>
      <v-col xs="12" sm="12" md="12" lg="12" xl="12">
        <v-data-table
          :headers="headers"
          :items="listasLiberacion"
          :items-per-page="5"
          class="elevation-1"
          :loading="loadingTable"
          loading-text="Cargando... Espere por favor"
        >
          <template v-slot:item.articulo="{ item }">
            {{ `[${item.codigo}] - ${item.articulo}` }}
          </template>
          <template v-slot:item.areas="{ item }">
            <ul>
              <li v-for="(area, x) in item.areas" :key="x">
                {{ area.nombre }}
              </li>
            </ul>
          </template>

          <template v-slot:item.liberacionFases="{ item }">
            <span v-if="item.liberacionFases.length == 0">N/A</span>
            <ul v-else>
              <li v-for="(condicion, x) in item.liberacionFases" :key="x">
                {{`${condicion.posicion}ᵃ V. [${condicion.codigo}] - ${condicion.nombre}: ${ condicion.cantidad } ${ item.unidad }`}}
              </li>
            </ul>
          </template>
          <template v-slot:item.actions="{ item }">
            <v-tooltip bottom color="black" class="white--text">
              <template v-slot:activator="{ on }">
                <v-icon v-on="on" small class="mr-2" @click="editItem(item)">
                  edit
                </v-icon>
              </template>
              <span class="white--text">Editar</span>
            </v-tooltip>

            <v-tooltip bottom color="black" class="white--text">
              <template v-slot:activator="{ on }">
                <v-icon v-on="on" small @click="deleteItem(item)">
                  delete
                </v-icon>
              </template>
              <span class="white--text">Eliminar</span>
            </v-tooltip>
          </template>
        </v-data-table>
      </v-col>
    </v-row>

    <v-dialog v-model="dialogCreateEdit" persistent max-width="75%">
      <v-card>
        <v-card-title class="text-h5">
          <span class="text-h5">{{ formTitle }}</span>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col xs="12" sm="12" md="4" lg="4" xl="4">
                <v-autocomplete
                  v-model="editedItem.idArticulo"
                  :items="articulos"
                  item-value="idArticulo"
                  :item-text="nombreArticulo"
                  label="Articulo"
                  dense
                  :loading="loadingArticulos"
                  clearable
                  @change="getAreasLiberacion(); getListasComprobaciones();"
                ></v-autocomplete>
              </v-col>
              <v-col xs="12" sm="12" md="4" lg="4" xl="4">
                <v-checkbox
                  v-model="editedItem.subArticulos"
                  label="¿Incluir Sub-Articulos?"
                  dense
                  :disabled="!$utils.isValid(editedItem.idArticulo) || editedItem.idArticulo <= 0"
                   @change="getAreasLiberacion(); getListasComprobaciones();"
                ></v-checkbox>
              </v-col>
              <v-col xs="12" sm="12" md="4" lg="4" xl="4">
                <v-autocomplete
                  v-model="editedItem.idsAreas"
                  label="Áreas"
                  :items="areasLiberacion"
                  item-value="idArea"
                  item-text="nombre"
                  :loading="loadingAreasLiberacion"
                  multiple
                  clearable
                  dense
                  chips
                  small-chips
                  deletable-chips
                  :disabled="(!$utils.isValid(editedItem.idArticulo) || editedItem.idArticulo <= 0) || loadingAreasLiberacion"
                ></v-autocomplete>
              </v-col>
              <v-col xs="12" sm="12" md="12" lg="12" xl="12">
                <v-data-table
                  :headers="headersCreateEdit"
                  :items="editedItem.liberacionFases"
                  dense
                  hide-default-footer
                >
                  <template v-slot:item.comprobacion="{ item }">
                    <v-select
                      v-model="item.idComprobacionLista"
                      item-value="idComprobacionLista"
                      :item-text="nombreLista"
                      :items="listaComprobacion"
                      :disabled="loadingListaComprobacion"
                      :loading="loadingListaComprobacion"
                      dense
                    ></v-select>
                  </template>
                  <template v-slot:item.posicion="{ item }">
                    {{item.posicion}}ᵃ Validación
                  </template>
                  <template v-slot:item.cantidad="{ item }">
                    <v-text-field v-model="item.cantidad" dense></v-text-field>
                  </template>
                  <template v-slot:item.actions="{ item }">
                    <v-tooltip bottom color="black" class="white--text">
                      <template v-slot:activator="{ on }">
                        <v-icon
                          v-on="on"
                          small
                          @click="RemoverLiberacionFase(item)"
                        >
                          delete
                        </v-icon>
                      </template>
                      <span class="white--text">Eliminar</span>
                    </v-tooltip>
                  </template>
                </v-data-table>
                <div class="text-center">
                  <v-btn
                    small
                    color="green"
                    class="my-2"
                    outlined
                    @click="agregarLiberacionFase"
                    >Agregar Fase de Liberación</v-btn
                  >
                </div>
              </v-col>
              <ul>
                <li class="red--text" v-for="ex in errores" :key="ex">
                  {{ ex }}
                </li>
              </ul>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="cancelar()">
            CANCELAR
          </v-btn>
          <v-btn
            color="red darken-1"
            :loading="loadSave"
            :disabled="loadSave"
            text
            @click="save()"
          >
            GUARDAR
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="dialogDelete" max-width="600px">
      <v-card>
        <v-card-title class="headline"
          >¿Está seguro que desea eliminar este elemento?</v-card-title
        >
        <v-card-text
          >Esta acción no se puede revertir y será permanente.</v-card-text
        >
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="cancelar()">CANCELAR</v-btn>
          <v-btn color="red darken-1" text @click="deleteItemConfirm"
            >ACEPTAR</v-btn
          >
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-col>
</template>

<script>
import axios from "axios";

export default {
  data() {
    return {
      dialogCreateEdit: false,
      loadingAreas: false,
      loadingTable: false,
      loadingArticulos: false,
      loadSave: false,
      articulos: [],
      areas: [],
      newIndex: 0,
      posicion: null,
      idsAreas: [],
      idsArticulos: [],
      headers: [
        {
          text: "Articulo",
          align: "center",
          value: "articulo",
        },
        { text: "Áreas", value: "areas" },
        { text: "Condiciones", align: "center", value: "liberacionFases" },
        { text: "Acciones", align: "center", value: "actions" },
      ],
      listasLiberacion: [],
      headersCreateEdit: [
        { text: "# Validacion", align: "center", value: "posicion" },
        {
          text: "Lista de Comprobación",
          align: "center",
          value: "comprobacion",
        },
        { text: "#Unidades Validacion", align: "center", value: "cantidad" },
        { text: "Acciones", align: "center", value: "actions" },
      ],
      listaComprobacion: [],
      loadingListaComprobacion: false,
      editedIndex: -1,
      editedItem: {
        idLiberacion: 0,
        idArticulo: 0,
        idsAreas: [],
        subArticulos: false,
        liberacionFases: [],
      },
      defaultItem: {
        idLiberacion: 0,
        idArticulo: 0,
        idsAreas: [],
        subArticulos: false,
        liberacionFases: [],
      },
      errores: [],
      areasLiberacion: [],
      loadingAreasLiberacion: false
    };
  },
  computed: {
    formTitle() {
      return this.editedIndex === -1 ? "Nuevo Liberación" : "Editar Liberación";
    },
  },

  watch: {
    dialog(val) {
      val || this.close();
    },
    dialogDelete(val) {
      val || this.closeDelete();
    },
  },

  created() {
    this.initialize();
  },
  methods: {
    initialize() {
      this.getData();
      this.getArticulos();
      this.getAreas();
    },
    nombreArticulo: (value) =>
      value.codigo != null
        ? `[${value.codigo}] - ${value.nombre}`
        : `${value.nombre}`,
    nombreLista: (lista) => `[${lista.codigo}] - ${lista.nombre}`,
    getData() {
      let idsTags = "";
      let idsTags2 = "";

      this.idsArticulos.forEach((etiqueta) => {
        idsTags += `&idsArticulos=${etiqueta}`;
      });

      this.idsAreas.forEach((etiqueta) => {
        idsTags2 += `&IdsAreas=${etiqueta}`;
      });

      this.loadingTable = true;
      axios
        .get(`/Liberacion/ListarLiberaciones?${idsTags}${idsTags2}`)
        .then((response) => {
          this.listasLiberacion = response.data;
          this.loadingTable = false;
        })
        .catch((error) => {
          console.log(error);
          this.loadingTable = false;
        });
    },
    agregarLiberacionFase() {
      this.newIndex -= 1;
      var posicion =
        this.editedItem.liberacionFases.map((a) => a.posicion).length == 0
          ? 0
          : Math.max(...this.editedItem.liberacionFases.map((a) => a.posicion));
      this.posicion = posicion + 1;
      const newObj = JSON.parse(
        JSON.stringify({
          posicion: this.posicion,
          idLiberacionFase: this.newIndex,
          idComprobacionLista: null,
          cantidad: null,
        })
      );
      this.editedItem.liberacionFases.push(newObj);
    },
    RemoverLiberacionFase(itemDelete) {
      this.editedItem.liberacionFases = this.editedItem.liberacionFases.filter(
        (item) => JSON.stringify(item) != JSON.stringify(itemDelete)
      );
      this.reordenarPosiciones();
    },
    reordenarPosiciones() {
      var posiciones = this.editedItem.liberacionFases;
      let index = 1;
      posiciones.forEach((element) => {
        element.posicion = index++;
      });
    },
    cancelar() {
      this.dialogCreateEdit = false;
      this.$nextTick(() => {
        this.editedItem.liberacionFases = [];
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
        this.newIndex = 0;
        this.posicion = null;
        this.errores = [];
        this.loadSave = false;
        
      });
    },
    editItem(item) {
      this.editedIndex = this.listasLiberacion.indexOf(item);
      const asing = Object.assign({}, item);
      this.editedItem.idLiberacion = asing.idLiberacion;
      this.editedItem.idArticulo = asing.idArticulo;
      this.editedItem.idsAreas = asing.areas.map((x) => x.idArea);
      this.editedItem.subArticulos = asing.subArticulos;
      this.editedItem.liberacionFases = JSON.parse(
        JSON.stringify(asing.liberacionFases)
      );
      this.getAreasLiberacion();
      this.getListasComprobaciones()

      this.dialogCreateEdit = true;
    },
    deleteItem(item) {
      this.editedIndex = this.listasLiberacion.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialogDelete = true;
    },
    deleteItemConfirm() {
      if (this.editedItem.idLiberacion < 1) return;

      axios
        .put("/Liberacion/EliminarLiberacion/" + this.editedItem.idLiberacion)
        .then(() => {
          this.getData();
        })
        .catch((error) => {
          Error.log(error);
        });
      this.closeDelete();
    },
    close() {
      this.dialog = false;
      this.$nextTick(() => {
        this.editedItem.liberacionFases = [];
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
        this.loadingAreasLiberacion = false;
        this.areasLiberacion = [];
      });
    },
    closeDelete() {
      this.dialogDelete = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },
    getArticulos() {
      this.loadingArticulos = true;
      axios
        .get("/Articulos/ArticulosNombres?Componentes=true")
        .then((response) => {
          this.articulos = response.data;
          this.loadingArticulos = false;
        })
        .catch((error) => {
          this.loadingArticulos = false;
          console.log(error);
        });
    },
    getAreas() {
      this.loadingAreas = true;
      axios.get("/Areas").then((response) => {
        this.loadingAreas = false;
        this.areas = response.data.filter((a) => a.idArea > 1);
      });
    },
    validate() {
      this.errores = [];

      if (this.editedItem.idsAreas.length < 1)
        this.errores.push("Se debe de seleccionar al menos un Área");

      if (this.editedItem.idArticulo < 1)
        this.errores.push("Seleccione un artículo");
      if (this.editedItem.subArticulos == null)
        this.errores.push("Marque la casilla si es un Sub-articulo");
      if (this.editedItem.liberacionFases.length < 1)
        this.errores.push("Agrega al menos una fase de liberación");

      if (this.editedItem.liberacionFases.length > 0) {
        var liberacionFase = this.editedItem.liberacionFases;

        liberacionFase.forEach((element) => {
          if (element.cantidad == null)
            if (
              this.errores.indexOf(
                "Uno o más campos de unidad se encuentran vacíos"
              ) < 0
            )
              this.errores.push(
                "Uno o más campos de unidad se encuentran vacíos"
              );
        });
      }

      return this.errores.length == 0;
    },
    save() {
      if (!this.validate()) return;
      this.loadSave = true;
      axios
        .post("/Liberacion/CrearActualizarLiberacion", this.editedItem)
        .then(() => {
          this.cancelar();
          this.getData();
          this.loadSave = false;
        })
        .catch((error) => {
          this.loadSave = false;
          console.log(error);
        });
    },
    getAreasLiberacion(){
      this.loadingAreasLiberacion = true;
      
      axios
      .get(`/Liberacion/ObtenerAreas?IdArticulo=${this.editedItem.idArticulo}&IncluirSubArticulos=${this.editedItem.subArticulos}`)
      .then((response) => {
        this.loadingAreasLiberacion = false;
        this.areasLiberacion = response.data;
      })
      .catch(error => {
        this.loadingAreasLiberacion = false;
        this.areasLiberacion = [];
        console.log(error)
      })
    },
    getListasComprobaciones(){
      this.loadingListaComprobacion = true;

      axios
      .get(`/Liberacion/ObtenerComprobaciones?IdArticulo=${this.editedItem.idArticulo}&IncluirSubArticulos=${this.editedItem.subArticulos}`)
      .then((response) => {
        this.loadingListaComprobacion = false;
        this.listaComprobacion = response.data;
      })
      .catch(error => {
        this.loadingListaComprobacion = false;
        this.listaComprobacion = [];
        console.log(error)
      })
    }
  },
};
</script>
