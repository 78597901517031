<template>
  <v-col cols="12">
    <loading-dialog
      ref="loading"
    >
    </loading-dialog>
    <alert-dialog
      icon="mdi-check-circle-outline"
      color="green"
      ref="correct"
    >
    </alert-dialog>
    <download-dialog
      icon="mdi-close-octagon"
      color="red"
      ref="download"
    >
    </download-dialog>
    
    <v-col cols="12" md="12">
      <div class="text-h6">
        Actualización de Movimientos de Inventario
      </div>
      <v-file-input
        v-model="ficheroMovimientos"
        :disabled="!permisoVista('cargasmasivassae','u')"
        label="Fichero de Movimientos (.csv)"
        accept=".csv"
        counter
        show-size
        @change="cambioFicheroMovimientos"
      ></v-file-input>
      <v-btn :disabled="!permisoVista('cargasmasivassae','u')" block color="blue" outlined @click="actualizarMovimientos">Actualizar Movimientos de Inventario</v-btn>
    </v-col>
  </v-col>
</template>

<script>
import axios from "axios";
import loadingDialog from "@/components/LoadingDialog";
import alertDialog from "@/components/AlertDialog";
import downloadDialog from "@/components/DownloadDialog";
import { mapState } from 'vuex';

export default {
  components:{
    'loading-dialog':loadingDialog,
    'alert-dialog':alertDialog,
    'download-dialog':downloadDialog
  },
  data: () => ({
    ficheroMovimientos: null,
  }),
  computed:{
		...mapState({
      token: 'token'
    }),
	},
  mounted(){
    this.initialize();
  },
  methods: {
    initialize(){
      this.ficheroMovimientos = null;
    },
    cambioFicheroMovimientos(event) {
      if (typeof event === "undefined" || event == null)
        this.ficheroMovimientos = null;
      else this.ficheroMovimientos = event;
    },
    actualizarMovimientos(){
      if (this.ficheroMovimientos != null) {
        this.$refs.loading.show(true);
        let formData = new FormData();
        formData.append("file", this.ficheroMovimientos);
        axios
          .post("/SAE/ActualizacionMovimientos", formData, {
            headers: {
              "Content-Type": "multipart/form-data"
            }
          })
          .then(response => {
            this.$refs.loading.show(false);
            this.ficheroMovimientos = null;
            if(response.data != null){
              this.$refs.download.show("/api/SAE/DescargarReporte/"+response.data+'?access_token='+token,"Descargar Reporte de Errores","Se ha presentado uno o varios errores al momento de realizar la actualización de movimientos."); 
            }
            else{
              this.$refs.correct.show("Actualización Realizada Correctamente");
            }
          })
          .catch(error => {
            this.$refs.loading.show(false);
            console.log(error);
          });
      }
    }
  }
}
</script>
