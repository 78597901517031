<template>
	<v-col cols="12" class="pa-0 d-flex align-center">
		<v-col cols="3">
			<p class="text-center mb-0">Tipo de asignación</p>
			<v-radio-group
				v-model="assignType"
				column
				class="mt-0"
				hide-details
                :disabled="disabled"
				@change="onChangeTipoAsignacion"
			>
				<v-radio
					label="Personas"
					class="mb-1 text-center"
					:value="1"
				></v-radio>
				<v-radio label="Proveedor" :value="2"></v-radio>
			</v-radio-group>
		</v-col>
		<v-col v-if="assignType == 1" cols="9">
			<v-autocomplete
				v-model="mechanics"
                id="mechanic-autocomplete"
				:items="items.personas"
				item-value="idPersona"
				:item-text="nombrePersona"
				label="Realizado por"
				multiple
				chips
				deletable-chips
				:disabled="disabled"
                :error-messages="errorMechanic"
                @focus="errorMechanic = null"
			></v-autocomplete>
		</v-col>
		<v-col v-else-if="assignType == 2" cols="9">
			<v-autocomplete
				v-model="supplierId"
                id="supplier-autocomplete"
				label="Realizado por"
				:items="items.proveedores"
				:item-text="$utils.mantenimientos.getNombreProveedor"
				item-value="idMantenimientoProveedor"
				clearable
				:disabled="disabled"
                :error-messages="errorSupplier"
                @focus="errorSupplier = null"
			></v-autocomplete>
		</v-col>
	</v-col>
</template>

<script>
export default {
	props: {
		items: {
			type: Object,
			default: () => ({
                personas: [],
                proveedores: []
            }),
		},
        tipoAsignacion: {
            type: Number,
            default: 1
        },
        mecanicos: {
            type: Array,
            default: () => []
        },
        idMantenimientoProveedor: {
            type: Number,
            default: null
        },
        disabled: {
            type: Boolean,
            default: false
        },
        errorMecanicos: {
            type: String,
            default: null
        },
        errorProveedor: {
            type: String,
            default: null
        },
	},
    computed: {
        assignType: {
            get(){
                return this.tipoAsignacion;
            },
            set(newValue){
                this.$emit('update:tipoAsignacion',newValue);
            }
        },
        mechanics: {
            get(){
                return this.mecanicos;
            },
            set(newValue){
                this.$emit('update:mecanicos',newValue);
            }
        },
        supplierId: {
            get(){
                return this.idMantenimientoProveedor;
            },
            set(newValue){
                this.$emit('update:idMantenimientoProveedor',newValue);
            }
        },
        errorMechanic: {
            get(){
                return this.errorMecanicos;
            },
            set(newValue){
                this.$emit('update:errorMecanicos',newValue);
            }
        },
        errorSupplier: {
            get(){
                return this.errorProveedor;
            },
            set(newValue){
                this.$emit('update:errorProveedor',newValue);
            }
        }
    },
    methods: {
        nombrePersona: ({ nombre, paterno, materno }) =>
			`${nombre ? `${nombre} ` : ""}${paterno ? `${paterno} ` : ""}${
				materno ? `${materno} ` : ""
			}`.trim(),
        onChangeTipoAsignacion(){
			this.mechanics = [];
            this.supplierId = null;
            this.errorMechanic = null;
            this.errorSupplier = null;
		},
    }
};
</script>