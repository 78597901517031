import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSelect } from 'vuetify/lib/components/VSelect';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';
import { VToolbar } from 'vuetify/lib/components/VToolbar';
import { VTooltip } from 'vuetify/lib/components/VTooltip';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c(VDialog,{attrs:{"persistent":"","max-width":"70%"},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c(VCard,[_c(VCardTitle,[_vm._v(" Asignar Vendedor - "+_vm._s(_vm.assignedItem.clave)+" ")]),_c(VCardText,[_c(VRow,[_c(VCol,{attrs:{"cols":"12"}},[_c(VSelect,{attrs:{"items":_vm.vendedores,"item-value":"idVendedor","item-text":_vm.nombrePersona},model:{value:(_vm.idVendedor),callback:function ($$v) {_vm.idVendedor=$$v},expression:"idVendedor"}})],1)],1)],1),_c(VCardActions,[_c(VSpacer),_c(VBtn,{attrs:{"color":"green","text":""},on:{"click":_vm.closeAssign}},[_vm._v("Cancelar")]),_c(VBtn,{attrs:{"color":"red","text":""},on:{"click":_vm.save}},[_vm._v("Aceptar")])],1)],1)],1),_c(VRow,[_c(VCol,{attrs:{"cols":"12","md":"12"}},[(_vm.permisoVista('otssinvendedor','r'))?_c(VDataTable,{attrs:{"headers":_vm.headers,"items":_vm.ots,"search":_vm.search,"loading":_vm.loading,"loading-text":"Cargando... Espere, Por Favor."},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c(VToolbar,{attrs:{"flat":"","color":"white"}},[_c(VTextField,{attrs:{"append-icon":"search","label":"Buscar","single-line":"","hide-details":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1)]},proxy:true},{key:"item.action",fn:function(ref){
var item = ref.item;
return [_c(VTooltip,{staticClass:"white--text",attrs:{"bottom":"","color":"black"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [(_vm.permisoVista('otssinvendedor','c'))?_c(VIcon,_vm._g({staticClass:"mr-2",attrs:{"small":""},on:{"click":function($event){return _vm.assignSeller(item)}}},on),[_vm._v(" mdi-check-bold ")]):_vm._e()]}}],null,true)},[_c('span',{staticClass:"white--text"},[_vm._v("Asignar Vendedor")])])]}}],null,false,142357552)}):_vm._e()],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }