<template>
  <v-app-bar flat max-height="70px" id="core-toolbar" dark color="rgb(33,33,33)">
    <signalr path="/hub/notificacion" @change="notificacion"></signalr>
    <div class="v-toolbar-title">
      <v-toolbar-title class="font-weight-light white--text">
        <v-btn
          v-if="responsive"
          class="default v-btn--simple"
          icon
          @click.stop="onClickBtn"
        >
          <v-icon>mdi-view-list</v-icon>
        </v-btn>
        {{ title }}
      </v-toolbar-title>
    </div>

    <v-spacer />
    <v-toolbar-items>
      <internet-check></internet-check>
      <!-- Ayuda -->
      <v-tooltip bottom color="black" class="white--text">
        <template v-slot:activator="{ on }">
          <v-btn
            :value="modoAyuda"
            class="ma-0"
            :color="modoAyuda&&'yellow'"
            text
            icon
            v-on="on"
            @click="toggleModoAyuda()"
          >
            <v-icon :size="22">mdi-help</v-icon>
          </v-btn>
        </template>
        <span class="white--text">Ayuda: {{modoAyuda?'Activada':'Desactivada'}}</span>
      </v-tooltip>

      <v-tooltip bottom color="black" class="white--text">
        <template v-slot:activator="{ on }">
          <v-icon
            class="toolbar-items pl-0"
            v-on="on"
            color
            style="cursor: pointer"
            @click="showBusquedaDocumentacion()"
            >mdi-book-open-blank-variant</v-icon
          >
        </template>
        <span class="white--text">Documentación</span>
      </v-tooltip>

      <v-tooltip bottom color="black" class="white--text">
        <template v-slot:activator="{ on }">
          <v-btn
            class="ma-0"
            text
            icon
            v-on="on"
            target="_blank"
            href="/dashboard/descargas"
          >
            <v-icon class="toolbar-items pl-0">mdi-download</v-icon>
          </v-btn>
        </template>
        <span class="white--text">Descargas</span>
      </v-tooltip>
      
      <v-menu left :close-on-content-click="false" content-class="top">
        <template v-slot:activator="{ on: menu, attrs }">
          <v-tooltip bottom color="black" class="white--text">
              <template v-slot:activator="{ on: tooltip }">
                <v-icon
                  color
                  v-bind="attrs"
                  v-on="{ ...menu, ...tooltip }"
                  :size="22"
                >
                  mdi-bug
                </v-icon>
              </template>
              <span class="white--text">Reportar Error</span>
            </v-tooltip>
        </template>

        <v-container class="notifications">
          <v-row dense>
            <v-col cols="12">
              <help></help>
            </v-col>
          </v-row>
        </v-container>
      </v-menu>

      <router-link v-ripple class="toolbar-items" to="/dashboard/reportes">
        <v-tooltip bottom color="black" class="white--text">
          <template v-slot:activator="{ on }">
            <v-icon :size="22" color v-on="on">mdi-home</v-icon>
          </template>
          <span class="white--text">Inicio</span>
        </v-tooltip>
      </router-link>
      
      <!--Notificaciones-->
      <v-menu left :close-on-content-click="false" content-class="top">
        <template v-slot:activator="{ on: menu, attrs }">
          <v-badge
            class="align-self-center"
            :content="numMessages"
            :value="numMessages"
          >
            <v-tooltip bottom color="black" class="white--text">
              <template v-slot:activator="{ on: tooltip }">
                <v-icon
                  color
                  v-bind="attrs"
                  v-on="{ ...menu, ...tooltip }"
                  :size="20"
                >
                  mdi-bell
                </v-icon>
              </template>
              <span class="white--text">Notificaciones</span>
            </v-tooltip>
          </v-badge>
        </template>

        <v-container class="notifications">
          <v-row dense>
            <v-col
              v-for="(notificacion, idx) in notificaciones"
              :key="'notificacion-' + idx"
              cols="12"
            >
              <v-card color="#385F73" dark>
                <v-row class="pa-0 ma-0">
                  <v-col cols="10" class="pa-0 ma-0">
                    <v-card-text class="ma-0">
                      <div class="text-subtitle-1">
                        {{ notificacion.titulo }}
                      </div>
                      <div>{{ notificacion.mensaje }}</div>
                      <template v-if="notificacion.url != null">
                        <div v-if="notificacion.urlTipo == 0">
                        </div>
                        <div v-if="notificacion.urlTipo == 1">
                          <div @click="descargarArchivo(notificacion.url)" class="text-body-2" style="cursor:pointer"><v-icon small v-if="notificacion.urlIcono != null">{{notificacion.urlIcono}}</v-icon>{{notificacion.urlMensaje}}</div>
                        </div>
                      </template>
                    </v-card-text>
                  </v-col>
                  <v-col cols="2" class="align-self-center">
                    <v-icon @click="marcarLeido(notificacion.idNotificacion)"
                      >mdi-check</v-icon
                    >
                  </v-col>
                </v-row>
              </v-card>
            </v-col>
            <v-col v-if="notificaciones.length == 0" cols="12">
              <v-card outlined>
                <v-card-title class="headline text-center justify-center"
                  >No hay Notificaciones</v-card-title
                >
              </v-card>
            </v-col>
            <div
              v-intersect="{
                handler: onIntersect,
                options: {
                  threshold: [0, 0.5, 1.0],
                },
              }"
            ></div>
          </v-row>
        </v-container>
      </v-menu>
      <v-tooltip bottom color="black" class="white--text">
        <template v-slot:activator="{ on }">
          <v-icon class="toolbar-items" v-on="on" color @click="logout"
            >mdi-power</v-icon
          >
        </template>
        <span class="white--text">Salir</span>
      </v-tooltip>
    </v-toolbar-items>
  </v-app-bar>
</template>

<script>
import { mapMutations, mapGetters, mapState } from "vuex";
import signalr from "@/components/SignalR";
import axios from "axios";
import internetCheck from "@/components/InternetCheck";
import help from "@/components/Help";

export default {
  components: {
    signalr,
    internetCheck,
    help
  },
  data: () => ({
    continuar: true,
    numMessages: 0,
    pagina: 1,
    notificaciones: [],
    responsive: false,
    responsiveInput: false,
  }),
  computed: {
    ...mapGetters(["authorized"]),
    ...mapState(["modoAyuda"]),
    title(){
      return this.$route.meta.name;
    }
  },

  mounted() {
    this.onResponsiveInverted();
    window.addEventListener("resize", this.onResponsiveInverted);
    this.getNumNotificaciones();
    this.getNotificaciones();
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.onResponsiveInverted);
  },

  methods: {
    ...mapMutations("app", ["setDrawer", "toggleDrawer"]),
    showBusquedaDocumentacion(){
      this.$emit('showBusquedaDocumentacion',true);
    },
    toggleModoAyuda(){
      this.$store
				.dispatch("toggleModoAyuda")
				.catch((err) => {
					console.log(err);
				});
    },
    onClickBtn() {
      this.setDrawer(!this.$store.state.app.drawer);
    },
    onClick() {
      //
    },
    onResponsiveInverted() {
      if (window.innerWidth < 600) {
        this.responsive = true;
        this.responsiveInput = false;
      } else {
        this.responsive = false;
        this.responsiveInput = true;
      }
    },
    logout: function () {
      this.$store.dispatch("logout").then(() => {
        this.$router.push("/");
      });
    },
    marcarLeido(idNotificacion) {
      this.notificaciones = this.notificaciones.filter((e) => {
        return e.idNotificacion != idNotificacion;
      });
      if (this.numMessages >= 1) this.numMessages -= 1;

      axios
        .post("/Notificaciones/MarcarLeido?IdNotificacion=" + idNotificacion)
        .then()
        .catch((error) => {
          console.log(error);
        });
    },
    onIntersect(entries, observer) {
      if (entries[0].isIntersecting) {
        this.getNotificaciones();
      }
    },
    getNotificaciones() {
      if (!this.continuar) return;
      axios
        .get("/Notificaciones?pagina=" + this.pagina)
        .then((response) => {
          this.notificaciones = this.notificaciones.concat(response.data);
          if (response.data.length == 10) {
            this.pagina += 1;
          } else {
            this.continuar = false;
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    getNumNotificaciones() {
      axios
        .get("/Notificaciones/NumNotificaciones")
        .then((response) => {
          this.numMessages = response.data;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    notificacion(event) {
      if (
        event.idSeccion != null &&
        event.idVista == null &&
        event.idAccion == null &&
        event.idRol == null &&
        event.idUsuario == null
      ) {
        if (
          this.$store.state.routes.some((r) => r.idSeccion == event.idSeccion)
        ) {
          this.getNumNotificaciones();
          this.continuar = true;
          this.notificaciones = [];
          this.pagina = 1;
          this.getNotificaciones();
        }
      } else if (
        event.idSeccion == null &&
        event.idVista != null &&
        event.idAccion == null &&
        event.idRol == null &&
        event.idUsuario == null
      ) {
        if (
          this.$store.state.routes.some((element) =>
            element.vistas.some((v) => v.idVista == event.idVista)
          )
        ) {
          this.getNumNotificaciones();
          this.continuar = true;
          this.notificaciones = [];
          this.pagina = 1;
          this.getNotificaciones();
        }
      } else if (
        event.idSeccion == null &&
        event.idVista != null &&
        event.idAccion != null &&
        event.idRol == null &&
        event.idUsuario == null
      ) {
        if (
          this.$store.state.routes.some((element) =>
            element.vistas.some(
              (v) =>
                v.idVista == event.idVista &&
                v.idsPermisos.some((x) => x == event.idAccion)
            )
          )
        ) {
          this.getNumNotificaciones();
          this.continuar = true;
          this.notificaciones = [];
          this.pagina = 1;
          this.getNotificaciones();
        }
      } else if (
        event.idSeccion == null &&
        event.idVista == null &&
        event.idAccion == null &&
        event.idRol != null &&
        event.idUsuario == null
      ) {
        if (this.$store.state.idRol == event.idRol) {
          this.getNumNotificaciones();
          this.continuar = true;
          this.notificaciones = [];
          this.pagina = 1;
          this.getNotificaciones();
        }
      } else if (
        event.idSeccion == null &&
        event.idVista == null &&
        event.idAccion == null &&
        event.idRol == null &&
        event.idUsuario != null
      ) {
        if (this.$store.state.idUser == event.idUsuario) {
          this.getNumNotificaciones();
          this.continuar = true;
          this.notificaciones = [];
          this.pagina = 1;
          this.getNotificaciones();
        }
      } else if (
        event.idSeccion == null &&
        event.idVista == null &&
        event.idAccion == null &&
        event.idRol == null &&
        event.idUsuario == null
      ) {
        this.getNumNotificaciones();
        this.continuar = true;
        this.notificaciones = [];
        this.pagina = 1;
        this.getNotificaciones();
      }
    },
    descargarArchivo(url){
      var partes =  url.split("/");
      var fichero = partes[partes.length -1];
      this.$utils.axios.downloadFile(url,'GET',fichero,'application/octet-stream');
    },
  },
};
</script>

<style>
#core-toolbar a {
  text-decoration: none;
}
</style>

<style scoped>
.top {
  top: 70px !important;
  max-height: 600px; /* or any height you want */
  overflow-y: auto;
}
.notifications {
  width: 400px !important;
  background: white !important;
}

.v-btn:before {
  opacity: 0 !important;
}

.v-ripple__container {
  opacity: 0 !important;
}
</style>