<template>
	<v-col cols="12" v-if="permisoVista('puntosimpresion','r')">
		<v-dialog v-model="dialogToggleAutenticar" persistent max-width="600px">
			<v-card>
				<v-card-text class="pt-2">
					<p class="text-h5 text--primary font-weight-medium">
						¿Está seguro que desea
						{{
							editedItem.autenticada
								? "autenticar"
								: "desautenticar"
						}}
						este Punto de Impresión?
					</p>
					<v-simple-table>
						<template v-slot:default>
							<thead>
								<tr>
									<th class="text-center th-text">
										Punto de Impresión
									</th>
									<th class="text-center th-text">
										Identificador
									</th>
									<th class="text-center th-text">
										Impresora Predeterminada
									</th>
								</tr>
							</thead>
							<tbody>
								<tr>
									<td class="text-center">
										{{ editedItem.puntoImpresion }}
									</td>
									<td class="text-center">
										{{ editedItem.identificador }}
									</td>
									<td class="text-center">
										{{ editedItem.impresora }}
									</td>
								</tr>
							</tbody>
						</template>
					</v-simple-table>
				</v-card-text>
				<v-card-actions>
					<v-spacer></v-spacer>
					<v-btn
						text
						color="success"
						:disabled="toggling"
						@click="cerrarDialog()"
						>Cancelar</v-btn
					>
					<v-btn
						text
						color="error"
						:disabled="toggling"
						:loading="toggling"
						@click="toggleAutenticar()"
						>Aceptar</v-btn
					>
				</v-card-actions>
			</v-card>
		</v-dialog>
		<v-dialog v-model="dialog" persistent max-width="500px">
			<v-card>
				<v-card-title>{{ editedItem.idPuntoImpresion == null ? 'Nuevo' : 'Editar' }} Punto de Impresión</v-card-title>
				<v-card-text>
					<v-container>
						<v-row>
							<v-col cols="12">
								<v-text-field
									v-model="editedItem.puntoImpresion"
									label="Punto de Impresión"
									clearable
									:disabled="saving"
									:error-messages="errores.errorPuntoImpresion"
									@focus="$set(errores,'errorPuntoImpresion',null)"
								></v-text-field>
							</v-col>
							<v-col cols="12">
								<v-text-field
									v-model="editedItem.identificador"
									label="Identificador"
									clearable
									:disabled="editedItem.idPuntoImpresion!=null || saving"
									:error-messages="errores.errorIdentificador"
									@focus="$set(errores,'errorIdentificador',null)"
								></v-text-field>
							</v-col>
							<v-col cols="12" v-if="editedItem.idPuntoImpresion != null">
								<v-autocomplete
									v-model="editedItem.idImpresora"
									label="Impresora Predeterminada"
									:items="editedItem.impresoras"
									item-text="nombre"
									item-value="idImpresora"
									clearable
									:disabled="loadingImpresoras || saving"
									:loading="loadingImpresoras"
									:error-messages="errores.errorImpresora"
									@focus="$set(errores,'errorImpresora',null)"
								></v-autocomplete>
							</v-col>
							<v-col cols="12" v-if="editedItem.idPuntoImpresion != null">
								<v-autocomplete
									v-model="editedItem.idsAreas"
									label="Área(s)"
									:items="areas"
									item-text="nombre"
									item-value="idArea"
									multiple
									chips
									clearable
									deletable-chips
									:disabled="loadingAreas || saving"
									:loading="loadingAreas"
									:error-messages="errores.errorArea"
									@focus="$set(errores,'errorArea',null)"
								></v-autocomplete>
							</v-col>
						</v-row>
					</v-container>
				</v-card-text>
				<v-card-actions>
					<v-spacer></v-spacer>
					<v-btn
						text
						color="success"
						:disabled="saving"
						@click="cerrarDialog()"
						>Cancelar</v-btn
					>
					<v-btn
						text
						color="error"
						:disabled="saving"
						:loading="saving"
						@click="save()"
						>Guardar</v-btn
					>
				</v-card-actions>
			</v-card>
		</v-dialog>
		<v-row>
			<v-col cols="3" class="d-flex align-center">
				<v-select
					v-model="filtros.autenticado"
					label="¿Está Autenticado?"
					:items="authenticated"
					item-text="text"
					item-value="value"
					hide-details
					:disabled="loading"
					clearable
					class="mb-2"
					@change="getPuntosImpresion"
				></v-select>
			</v-col>
			<v-col cols="5"></v-col>
			<v-col cols="4" class="ml-auto">
				<v-text-field
					v-model="search"
					label="Buscar"
					append-icon="mdi-magnify"
					clearable
					hide-details
					:disabled="loading"
				></v-text-field>
			</v-col>
		</v-row>
		<v-row>
			<v-col cols="12">
				<v-data-table
					:items="puntosImpresion"
					:headers="puntosImpresionHeaders"
					:search="search"
					:loading="loading"
				>
					<template v-slot:[`item.estado`]="{ item }">
						<v-icon
							:color="!item.autenticada ? 'grey' : (item.estado ? 'green' : 'red')"
						>
							circle
						</v-icon>
					</template>
					<template v-slot:[`item.areas`]="{ item }">
						<template v-if="item.areas.length == 0">Sin Especificar</template>
						<template v-else>
							<p
								v-for="(area,idx) in item.areas"
								:key="`area-${idx}`"
								class="mb-1"
							>
								{{ item.areas.length > 1 ? '•' : '' }} {{ area }}
							</p>
						</template>
					</template>
					<template v-slot:[`item.autenticada`]="{ item }">
						<v-chip
							:color="item.autenticada?'success':'error'"
							label
							text-color="white"
							style="width: 70px"
						>
							<v-icon left> {{item.autenticada?'mdi-check':'mdi-close'}} </v-icon>
							<p class="mb-0">{{item.autenticada?'SI':'NO'}}</p>
						</v-chip>
					</template>
					<template v-slot:[`item.actions`]="{ item }">
						<v-tooltip v-if="permisoVista('puntosimpresion','u')" bottom color="black" class="white--text">
							<template v-slot:activator="{ on }">
								<v-icon
									v-on="on"
									size="18"
									:disabled="loading"
									@click="showDialogToggle(item)"
									>{{
										item.autenticada
											? "mdi-cancel"
											: "mdi-check-circle"
									}}</v-icon
								>
							</template>
							<span class="white--text">{{
								item.autenticada
									? "Desautenticar"
									: "Autenticar"
							}}</span>
						</v-tooltip>
						<v-tooltip v-if="permisoVista('puntosimpresion','u')" bottom color="black" class="white--text">
							<template v-slot:activator="{ on }">
								<v-icon
									v-on="on"
									size="18"
									:disabled="loading"
									@click="showDialog(item)"
									>edit</v-icon
								>
							</template>
							<span class="white--text">Editar</span>
						</v-tooltip>
					</template>
				</v-data-table>
			</v-col>
		</v-row>
	</v-col>
</template>

<script>
import axios from "axios";
export default {
	data() {
		return {
			loadingAreas: false,
			areas: [],
			loadingImpresoras: false,
			toggling: false,
			errores: {
				errorPuntoImpresion: null,
				errorIdentificador: null,
				errorImpresora: null,
				errorArea: null
			},
			saving: false,
			impresoras: [],
			dialogToggleAutenticar: false,
			editedItem: {
				idPuntoImpresion: null,
				puntoImpresion: "",
				identificador: "",
				idImpresora: null,
				impresora: null,
				autenticada: false,
				idsAreas: []
			},
			defaultEditedItem: {
				idPuntoImpresion: null,
				puntoImpresion: "",
				identificador: "",
				idImpresora: null,
				impresora: null,
				autenticada: false,
				idsAreas: []
			},
			dialog: false,
			puntosImpresionHeaders: [
				{
					text: "Estado",
					align: "center",
					value: "estado",
				},
				{
					text: "Punto de Impresión",
					align: "center",
					value: "puntoImpresion",
				},
				{
					text: "Identificador",
					align: "center",
					value: "identificador",
				},
				{
					text: "Impresora Predeterminada",
					align: "center",
					value: "impresora",
				},
				{
					text: "Área(s)",
					align: "center",
					value: "areas",
				},
				{
					text: "¿Está Autenticada?",
					align: "center",
					value: "autenticada",
				},
				{
					text: "Acciones",
					align: "center",
					value: "actions",
				},
			],
			loading: false,
			puntosImpresion: [],
			search: null,
			filtros: {
				autenticado: null,
			},
			authenticated: [
				{
					text: "Sí",
					value: true,
				},
				{
					text: "No",
					value: false,
				},
			],
		};
	},
	mounted() {
		this.initialize();
	},
	methods: {
		toggleAutenticar() {
			this.toggling = true;
			axios
				.put(`/Impresora/Autentificar/${this.editedItem.idPuntoImpresion}?autenticar=${this.editedItem.autenticada}`)
				.then(() => {
					this.toggling = false;
					this.cerrarDialog();
					this.initialize();
				})
				.catch((error) => {
					this.toggling = false;
					console.log(error);
				});
		},
		resetErrores(){
			Object.keys(this.errores).forEach( (key) => this.errores[key] == null);
		},
		validate() {
			this.resetErrores();

			if (
				this.editedItem.puntoImpresion == null ||
				this.editedItem.puntoImpresion.length <= 5
			) {
				this.$set(this.errores,'errorPuntoImpresion',"El nombre del Punto de Impresion debe tener mínimo 5 caracteres");
			}

			if (
				this.editedItem.identificador == null ||
				this.editedItem.identificador.length <= 5
			) {
				this.$set(this.errores,'errorIdentificador',"El Identificador debe tener mínimo 5 caracteres");
			}

			return Object.values(this.errores).every( (value) => value == null );
		},
		save() {
			if (!this.validate()) return;

			this.saving = true;

			axios
			.put('/Impresora/ActualizarPuntoImpresion', {
				idPuntoImpresion: this.editedItem.idPuntoImpresion,
				nombre: this.editedItem.puntoImpresion,
				idImpresora: this.editedItem.idImpresora,
				idsAreas: this.editedItem.idsAreas
			})
			.then(() => {
					this.saving = false;
					this.cerrarDialog();
					this.initialize();
			})
			.catch(error => {
				this.saving = false;
				console.log(error);
			})
		},
		cerrarDialog() {
			this.dialog = false;
			this.dialogToggleAutenticar = false;

			setTimeout(() => {
				this.editedItem = structuredClone(this.defaultEditedItem);
				this.resetErrores();
			}, 200);
		},
		showDialogToggle(item) {
			this.editedItem = structuredClone({
				...item,
				autenticada: !item.autenticada,
			});
			this.dialogToggleAutenticar = true;
		},
		showDialog(item = null) {
			this.editedItem = structuredClone(item || this.defaultEditedItem);
			this.dialog = true;
		},
		getAreas() {
      this.loadingAreas = true;
      axios.get("/Areas").then((response) => {
        this.loadingAreas = false;
        this.areas = response.data.filter((a) => a.idArea > 1);
      });
    },
		initialize() {
			this.getPuntosImpresion();
			this.getAreas();
		},
		getPuntosImpresion() {
			this.loading = true;
			axios
				.get("/Impresora/PuntosImpresion", {
					params: {
						autenticado: this.filtros.autenticado,
					},
				})
				.then((response) => {
					this.puntosImpresion = response.data;
				})
				.catch((error) => {
					this.puntosImpresion = [];
					console.log(error);
				})
				.finally(() => {
					this.loading = false;
				});
		},
	},
};
</script>
<style scoped>
.th-text {
	font-size: 16px !important;
}
</style>