<template>
  <v-container justify="center" class="no-padding-side">
    <label v-if="!dense" :class="'v-label v-label--active theme--light label-style '+(error ? 'label-error' : 'label-color')">{{label}}</label>
    <v-row style="padding: 8px 0 12px 0; min-width: 80px;">
      <v-text-field 
        v-model="hours"
        min="0"
        max="168"
        label="HH"
        type="number"
        :class="'no-padding' + ((error && !disabled)?' v-input--is-focused':'')"
        @input="updateTime"
        :error="error && !disabled"
        single-line
        hide-details
        :disabled="disabled"
        @focus="$emit('focus')"
      ></v-text-field>
      <v-text-field
        v-model="minutes"
        min="0"
        max="59"
        label="MM"
        type="number"
        :class="'no-padding' + ((error && !disabled)?' v-input--is-focused':'')"
        @input="updateTime"
        :error="error && !disabled"
        single-line
        hide-details
        :disabled="disabled"
        @focus="$emit('focus')"
      ></v-text-field> 
      <v-text-field
        v-model="seconds"
        min="0"
        max="59"
        label="SS"
        type="number"
        :class="'no-padding' + ((error && !disabled)?' v-input--is-focused':'')"
        @input="updateTime"
        :error="error && !disabled"
        single-line
        hide-details
        :disabled="disabled"
        @focus="$emit('focus')"
      ></v-text-field>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name:'TimePicker',
  data: () => ({
      seconds:0,
      minutes:0,
      hours:0,
  }),
  props: {
    value:{
      type: Number,
      default: 0,
    },
    label: {
      type: String,
      default: '',
    },
    error: {
      type: Boolean,
      default: false,
    },
    dense: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  watch: {
    value(){
      this.showValue();
    }
  },
  mounted(){
    this.showValue();
  },
  methods:{
    showValue(){
      let v = parseInt(this.value);
      let h = 0, m = 0;
      if(v >= 3600){
        h = Math.trunc(v/3600);
        v -= h * 3600;
      }
      if(v >= 60){
        m = Math.trunc(v/60);
        v -= m * 60;
      }

      this.hours = h;
      this.minutes = m;
      this.seconds = v;

    },
    validInt(value){
      return isNaN(parseInt(value))?0:parseInt(value);
    },
    updateTime() {
      this.$emit('input', this.validInt(this.seconds) + (this.validInt(this.minutes) * 60) + (this.validInt(this.hours) * 3600) );
    }
  }
}
</script>

<style scoped>
.v-text-field {
    max-width:800px !important;
    width: 33%;
}

.no-padding {
  padding-top: 2px !important;
  margin-top: 0px !important;
}

.no-padding-side {
  padding-top: 0px !important;
  padding-bottom: 0px !important;
}

.label-style {
  font-size: 0.85em !important;
  left: -14px;
  position: relative;
}

.label-color {
  color: #aaa !important;
}

.label-error {
  color: red !important;
}
</style>
