<template>
	<div>
		<v-tabs v-model="tab" background-color="transparent" grow>
			<v-tab @click="onClickEstadoMoldes">
				Estado de los Moldes
			</v-tab>
            <v-tab v-if="empresaValida" @click="onClickCatalogoPreventivo">
				Catálogo de Mantenimiento Preventivo
			</v-tab>
            <v-tab v-if="empresaValida" @click="onClickCatalogoCorrectivo">
				Catálogo de Mantenimiento Correctivo
			</v-tab>

			<v-tabs-items v-model="tab" touchless>
				<v-tab-item eager>
					<Moldes ref="estadoMoldes" />
				</v-tab-item>
                <v-tab-item v-if="empresaValida" eager>
					<CatalogoPreventivo ref="preventivo" />
				</v-tab-item>
                <v-tab-item v-if="empresaValida" eager>
					<CatalogoCorrectivo ref="correctivo" />
				</v-tab-item>
			</v-tabs-items>
		</v-tabs>
	</div>
</template>

<script>
import CatalogoPreventivo from './Moldes/CatalogoPreventivo.vue';
import CatalogoCorrectivo from './Moldes/CatalogoCorrectivo.vue';
import Moldes from './Moldes/Moldes.vue';
import { mapState } from 'vuex';

export default {
	components: { Moldes, CatalogoPreventivo, CatalogoCorrectivo },
	data(){
        return {
            tab: 0,
        }
	},
	computed: {
		empresaValida(){
			return this.nombreEmpresa.toLowerCase().trim() == "Richter".toLowerCase().trim();
		},
		...mapState(['nombreEmpresa'])
	},
	mounted(){
		this.initialize();
	},
	methods: {
		initialize() {
			this.onClickEstadoMoldes();
		},
        onClickCatalogoCorrectivo(){
            if (typeof this.$refs.correctivo === "undefined") return;
			this.$refs.correctivo.initialize();
        },
        onClickCatalogoPreventivo(){
            if (typeof this.$refs.preventivo === "undefined") return;
			this.$refs.preventivo.initialize();
        },
        onClickEstadoMoldes(){
            if (typeof this.$refs.estadoMoldes === "undefined") return;
			this.$refs.estadoMoldes.initialize();
        },
	},
};
</script>