import { VAutocomplete } from 'vuetify/lib/components/VAutocomplete';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VScrollYTransition } from 'vuetify/lib/components/transitions';
import { VSimpleTable } from 'vuetify/lib/components/VDataTable';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';
import { VTooltip } from 'vuetify/lib/components/VTooltip';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.permisoVista('simulacion', 'r'))?_c(VCol,{attrs:{"cols":"12"}},[_c(VDialog,{attrs:{"persistent":"","max-width":"60%"},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c(VCard,[_c(VCardTitle,[_vm._v(" "+_vm._s(_vm.editedItem.area ? ("Editar Configuración del Área de " + (_vm.editedItem.area)) : "Crear Nueva Configuración")+" ")]),_c(VCardText,[_c(VContainer,[_c(VRow,[(!_vm.editedItem.area)?_c(VCol,{attrs:{"cols":"12"}},[_c(VAutocomplete,{attrs:{"label":"Área","items":_vm.areas,"item-text":"nombre","item-value":"idArea","clearable":"","error-messages":_vm.errorArea},on:{"focus":function($event){_vm.errorArea = ''},"change":_vm.areaChanged},model:{value:(_vm.editedItem.idArea),callback:function ($$v) {_vm.$set(_vm.editedItem, "idArea", $$v)},expression:"editedItem.idArea"}})],1):_vm._e(),_c(VCol,{attrs:{"cols":"12"}},[_c(VDataTable,{attrs:{"items":_vm.editedItem.lineas,"headers":_vm.lineasHeaders,"hide-default-footer":"","items-per-page":-1},scopedSlots:_vm._u([{key:"item.nombre",fn:function(ref){
var item = ref.item;
return [_c(VTextField,{attrs:{"dense":"","clearable":"","error-messages":item.errorNombre},on:{"focus":function($event){item.errorNombre = ''}},model:{value:(item.nombre),callback:function ($$v) {_vm.$set(item, "nombre", $$v)},expression:"item.nombre"}})]}},{key:"item.numOperadores",fn:function(ref){
var item = ref.item;
return [_c('number-input',{attrs:{"controls":"","inline":"","size":"small","min":0,"center":""},on:{"change":function($event){item.errorNumOperador = ''}},model:{value:(item.numOperadores),callback:function ($$v) {_vm.$set(item, "numOperadores", $$v)},expression:"item.numOperadores"}}),_c(VScrollYTransition,[(item.errorNumOperador != '')?_c('p',{staticClass:"mb-0 red--text",staticStyle:{"font-size":"12px !important"}},[_vm._v(" "+_vm._s(item.errorNumOperador)+" ")]):_vm._e()])]}},{key:"item.idsMaquinasIns",fn:function(ref){
var item = ref.item;
var index = ref.index;
return [_c(VAutocomplete,{attrs:{"items":_vm.maquinasInstanciasLineas(index),"item-text":_vm.nombreMaquina,"item-value":"idMaquinaIns","multiple":"","chips":"","deletable-chips":"","clearable":"","disabled":_vm.loadingMaquinasInstancias,"loading":_vm.loadingMaquinasInstancias,"error-messages":item.errorMaquinas},on:{"focus":function($event){item.errorMaquinas = ''}},model:{value:(item.idsMaquinasIns),callback:function ($$v) {_vm.$set(item, "idsMaquinasIns", $$v)},expression:"item.idsMaquinasIns"}})]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [(_vm.permisoVista('simulacion', 'u'))?_c(VTooltip,{staticClass:"white--text",attrs:{"bottom":"","color":"black"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c(VIcon,_vm._g({staticClass:"mr-2",attrs:{"small":""},on:{"click":function($event){return _vm.eliminarLinea(item)}}},on),[_vm._v(" delete ")])]}}],null,true)},[_c('span',{staticClass:"white--text"},[_vm._v("Eliminar")])]):_vm._e()]}}],null,true)}),_c(VBtn,{attrs:{"text":"","color":"primary"},on:{"click":_vm.agregarLinea}},[_c(VIcon,{attrs:{"left":""}},[_vm._v("mdi-plus")]),_vm._v(" Agregar línea ")],1),_c(VScrollYTransition,[(_vm.errorNoLineas != '')?_c('p',{staticClass:"mb-0 mt-1 red--text",staticStyle:{"font-size":"12px !important"}},[_vm._v(" "+_vm._s(_vm.errorNoLineas)+" ")]):_vm._e()])],1)],1)],1)],1),_c(VCardActions,[_c(VSpacer),_c(VBtn,{attrs:{"text":"","color":"success","disabled":_vm.saving},on:{"click":function($event){return _vm.cerrarDialog()}}},[_vm._v("Cancelar")]),_c(VBtn,{attrs:{"text":"","color":"error","disabled":_vm.saving,"loading":_vm.saving},on:{"click":function($event){return _vm.save()}}},[_vm._v("Guardar")])],1)],1)],1),(_vm.permisoVista('simulacion', 'c'))?_c(VRow,[_c(VCol,{staticClass:"py-0",attrs:{"cols":"4"}},[_c(VBtn,{attrs:{"color":"primary","disabled":_vm.loading},on:{"click":function($event){return _vm.showDialog()}}},[_vm._v("Crear Nueva Configuración")])],1)],1):_vm._e(),_c(VRow,[_c(VCol,{attrs:{"cols":"12"}},[_c(VDataTable,{attrs:{"items":_vm.simulacion,"headers":_vm.simulacionHeaders,"loading":_vm.loading},scopedSlots:_vm._u([{key:"header.lineas",fn:function(){return [_c('div',{staticClass:"d-flex"},[_c('div',{staticClass:"d-flex justify-center align-center",staticStyle:{"width":"10%","font-size":"1.0625rem !important"}},[_vm._v(" # de Operadores ")]),_c('div',{staticClass:"d-flex justify-center align-center",staticStyle:{"width":"30%","font-size":"1.0625rem !important"}},[_vm._v(" Línea ")]),_c('div',{staticClass:"d-flex justify-center align-center",staticStyle:{"width":"60%","font-size":"1.0625rem !important"}},[_vm._v(" Máquinas Disponibles ")])])]},proxy:true},{key:"item.lineas",fn:function(ref){
var item = ref.item;
var index = ref.index;
return [_c(VSimpleTable,{staticClass:"transparent my-1",scopedSlots:_vm._u([{key:"default",fn:function(){return [_c('tbody',_vm._l((item.lineas),function(linea){return _c('tr',{key:("tr-linea-" + (linea.idConfiguracionArea)),staticClass:"transparent"},[_c('td',{staticClass:"text-center",attrs:{"width":"10%"}},[_vm._v(_vm._s(linea.numOperadores))]),_c('td',{staticClass:"text-center",attrs:{"width":"30%"}},[_vm._v(_vm._s(linea.nombre))]),_c('td',{staticClass:"text-center py-2",attrs:{"width":"60%"}},[(linea.idsMaquinasIns.length == 0)?_c('p',{key:("linea-nomaqs-" + index),staticClass:"mb-0 py-1"},[_vm._v(" N/A ")]):_vm._l((linea.idsMaquinasIns),function(maquina){return _c('p',{key:("maquina-" + maquina),staticClass:"mb-0 py-1"},[_vm._v(" • "+_vm._s(_vm.getNombreInsMaquinaById(maquina))+" ")])})],2)])}),0)]},proxy:true}],null,true)})]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [(_vm.permisoVista('simulacion', 'u'))?_c(VTooltip,{staticClass:"white--text",attrs:{"bottom":"","color":"black"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c(VIcon,_vm._g({staticClass:"mr-2",attrs:{"small":""},on:{"click":function($event){return _vm.showDialog(item)}}},on),[_vm._v(" edit ")])]}}],null,true)},[_c('span',{staticClass:"white--text"},[_vm._v("Editar")])]):_vm._e()]}}],null,true)})],1)],1)],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }