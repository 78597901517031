import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VProgressCircular } from 'vuetify/lib/components/VProgressCircular';
import { VRow } from 'vuetify/lib/components/VGrid';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VCard,{attrs:{"elevation":"0"}},[_c(VCardText,[_c(VContainer,{attrs:{"fluid":""}},[(_vm.loading)?[_c(VRow,[_c(VCol,{staticClass:"d-flex flex-column align-center justify-center",staticStyle:{"height":"80vh"},attrs:{"cols":"12"}},[_c(VProgressCircular,{attrs:{"size":90,"width":7,"color":"primary","indeterminate":""}}),_c('p',{staticClass:"text-h4 text--secondary"},[_vm._v("Cargando")])],1)],1)]:_vm._e(),(!_vm.loading)?[_c(VRow,_vm._l((_vm.datos),function(dato,index){return _c(VCol,{key:index,staticClass:"d-flex flex-column py-2 px-1"},[_c('OEEMaquinaCardDummy',{key:("maquina-card-" + index),attrs:{"id":("card-" + index),"data":dato}})],1)}),1)]:_vm._e()],2)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }