import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VImg } from 'vuetify/lib/components/VImg';
import { VProgressCircular } from 'vuetify/lib/components/VProgressCircular';
import { VScaleTransition } from 'vuetify/lib/components/transitions';
import { VSimpleTable } from 'vuetify/lib/components/VDataTable';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VCard,{staticClass:"my-4 mr-4 custom-card-class",attrs:{"color":_vm.color},on:{"click":function($event){return _vm.onClickCard()}}},[_c(VContainer,{staticClass:"pa-0 fill-height",class:{
			'justify-center': _vm.tipo == 'icon',
		},attrs:{"fluid":""}},[(_vm.tipo == 'image')?_c(VBtn,{staticClass:"button-delete-small",attrs:{"fab":"","color":"error","top":"","right":"","absolute":"","elevation":"2"},on:{"click":function($event){$event.stopPropagation();$event.preventDefault();return _vm.onClickDelete()}}},[_c(VIcon,{attrs:{"color":"white","size":"16"}},[_vm._v("mdi-close")])],1):_vm._e(),(_vm.tipo == 'image')?[_c(VCol,{staticClass:"pa-0 text-center",attrs:{"cols":"12"}},[(_vm.loadingImage)?_c('div',{staticClass:"text-center"},[_c(VProgressCircular,{attrs:{"size":50,"color":"primary","indeterminate":""}}),_c('p',{staticClass:"mb-0 text-subtitle-2"},[_vm._v("Cargando imagen")])],1):[_c(VScaleTransition,{attrs:{"hide-on-leave":""}},[(_vm.localImage)?_c(VImg,{attrs:{"src":_vm.localImage,"height":"140"}}):_c(VIcon,{staticStyle:{"height":"140px"},attrs:{"size":"70","color":"grey"}},[_vm._v("mdi-file-image")])],1)]],2),_c(VCol,{staticClass:"pa-0",attrs:{"cols":"12"}},[_c(VSimpleTable,{attrs:{"dense":""},scopedSlots:_vm._u([{key:"default",fn:function(){return [_c('tbody',[_c('tr',[_c('td',{staticClass:"text-center font-weight-bold",staticStyle:{"width":"40%"}},[_vm._v(" Código del Molde ")]),_c('td',{staticClass:"text-center"},[_vm._v(" "+_vm._s(_vm._f("trimmText")(_vm.molde.codigo))+" ")])])])]},proxy:true}],null,false,3679802301)})],1)]:_c('div',{staticClass:"text-center"},[_c(VIcon,{attrs:{"color":"grey darken-3"}},[_vm._v("mdi-plus")]),_c('p',{staticClass:"mb-0"},[_vm._v("Agregar molde")])],1)],2)],1)}
var staticRenderFns = []

export { render, staticRenderFns }