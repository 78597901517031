<template>
  <v-col cols="12" v-if="permisoVista('ingresos','r')">
    <v-dialog
      v-model="dialog"
      persistent
      max-width="800px"
    >
      <v-card>
        <v-card-title>Definir Costo</v-card-title>
        <v-card-text>
          <br>
          <div class="text-h6 text-center">
            {{editItem.cantidad.toFixed(2)}} {{editItem.unidad}} de {{editItem.articulo}}
          </div>
          <v-row>
            <v-col cols="6" md="6" sm="6">
              <v-text-field-number
                v-model="editItem.costoUnidad"
                ref="costoUnidad"
                :label="'Costo por ' +editItem.unidad"
                hide-details
                dense
                decimal
                @input="calcularMontoTotal"
                @change="calcularMontoTotal"
              ></v-text-field-number>
            </v-col>
            <v-col cols="6" md="6" sm="6">
              <v-text-field-number
                v-model="editItem.montoTotal"
                ref="montoTotal"
                :label="'Monto Total'"
                hide-details
                dense
                decimal
                @input="calcularCostoUnidad"
                @change="calcularCostoUnidad"
              ></v-text-field-number>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" md="12" sm="12">
              <datePicker
                v-model="editItem.fecha"
                ref="fecha"
                label="Fecha de Compra"
                format="YYYY-MM-DD"
                maxToday
              ></datePicker>
            </v-col>
            <!-- <v-row>
              <v-col cols="4" md="4" sm="4" class="text-center">
                <v-btn x-small color="primary" text @click="setFecha(0)">Fecha de Creación</v-btn>
              </v-col>
              <v-col cols="4" md="4" sm="4" class="text-center">
                <v-btn x-small color="primary" text @click="setFecha(1)">Fecha de Recepción</v-btn>
              </v-col>
              <v-col cols="4" md="4" sm="4" class="text-center">
                <v-btn x-small color="primary" text @click="setFecha(2)">Fecha de Ingreso</v-btn>
              </v-col>
            </v-row> -->
          </v-row>
          <v-row>
            <v-col cols="12" md="12" sm="12">
              <ul>
                <li class="red--text" v-for="(error,idx) in errores" :key="'error'+idx">{{error}}</li>
              </ul>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="green" text @click="reset">Cancelar</v-btn>
          <v-btn
            color="red darken-1"
            text
            @click="save()"
            :disabled="saving"
            :loading="saving"
          >
            Guardar
            <template v-slot:loader>
              <v-progress-circular
                indeterminate
                :width="2"
                :size="24"
                color="red"
              ></v-progress-circular>
            </template>
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-row>
			<v-col cols="4">
				<v-autocomplete
					v-model="filtros.idArticulo"
					:items="ingresos"
					:item-text="nombreArticulo"
					item-value="idArticulo"
					label="Buscar"
					hide-details
					clearable
					:disabled="loading"
					@change="getIngresos()"
				></v-autocomplete>
			</v-col>
		</v-row>
    <v-row>
      <v-col cols="12">
        <v-data-table
          :headers="headers"
          :items="ingresos"
          :loading="loading"
        >
          <template v-slot:no-data>
          </template>
          <template v-slot:[`item.responsable`]="{ item }">
            {{item.responsable == null ? 'Sin Determinar':item.responsable}}
          </template>
          <template v-slot:[`item.cantidad`]="{ item }">
            {{item.cantidad.toFixed(2)}} {{item.unidad}}
          </template>
          <template v-slot:[`item.actions`]="{ item }">
            <v-tooltip bottom color="black" class="white--text">
              <template v-slot:activator="{ on }">
            <v-icon v-on="on"
              v-if="permisoVista('ingresos','c')"
              small
              @click="asignarCosto(item)"
            >
              mdi-check
            </v-icon>
              </template>
              <span class="white--text">Asignar Costo</span>
            </v-tooltip>
          </template>
        </v-data-table>
      </v-col>
    </v-row>
  </v-col>
</template>

<script>
import axios from "axios";
import VTextFieldNumber from '@/components/VTextFieldNumber.vue'
import datePicker from "@/components/DatePicker";
import { VueDatePicker } from '@mathieustan/vue-datepicker';

export default {
  components:{
    'v-text-field-number': VTextFieldNumber
  },
  data: () => ({
    filtros: {
      idArticulo: null
    },
    loading:false,
    headers:[
      { 
        text: "Código",
        align: "center",
        sortable: true,
        value: "codigo",
      },
      { 
        text: "Artículo",
        align: "center",
        sortable: true,
        value: "articulo",
      },
      { 
        text: "Cantidad",
        align: "center",
        sortable: true,
        value: "cantidad",
      },
      { 
        text: "Responsable",
        align: "center",
        sortable: true,
        value: "responsable",
      },
      { 
        text: "Fecha de Creación",
        align: "center",
        sortable: true,
        value: "fechaCreacion",
      },
      { 
        text: "Fecha de Recepción",
        align: "center",
        sortable: true,
        value: "fechaRecepcion",
      },
      { 
        text: "Fecha de Ingreso",
        align: "center",
        sortable: true,
        value: "fechaIngreso",
      },
      { 
        text: "Acciones",
        align: "center",
        sortable: false,
        value: "actions",
      },
    ],
    ingresos:[],
    defaultItem:{
      idMovimiento: -1,
      articulo: "",
      cantidad: 0.0,
      unidad: "",
      responsable: "",
      fechaCreacion: "",
      fechaRecepcion: "",
      fechaIngreso: "",
    },
    editItem:{
      idMovimiento: -1,
      articulo: "",
      cantidad: 0.0,
      unidad: "",
      responsable: "",
      fechaCreacion: "",
      fechaRecepcion: "",
      fechaIngreso: "",
    },
    dialog:false,
    saving:false,
    errores:[],
   }),
   created(){
     this.initialize();
   },
   methods:{
    nombreArticulo: ({ codigo, articulo }) => `[${codigo}] - ${articulo}`,
    initialize(){
      this.getIngresos();
    },
    getIngresos(){
      this.loading = true;
      axios
      .get("/Compras/IngresosSinCosto", {
        params: this.filtros
      })
      .then(response => {
        this.loading = false;
        this.ingresos = response.data;
      })
      .catch(error => {
        console.log(error);
        this.loading = false;
      })
    },
    asignarCosto(item){
      this.dialog = true;
      this.editItem = item;
      this.editItem.montoTotal = 0.0;
      this.editItem.costoUnidad = 0.0;
      this.editItem.fecha = null;
      this.setFecha(0)
    },
    reset(){
      this.editItem = Object.assign({},this.defaultItem);
      this.dialog = false;
    },
    calcularMontoTotal(){
      if(!isNaN(this.editItem.costoUnidad)){
        this.editItem.montoTotal = this.editItem.costoUnidad * this.editItem.cantidad;
        this.$refs.montoTotal.setValue(this.editItem.montoTotal);
      }
      else{
        this.editItem.montoTotal = 0.0;
      }
    },
    calcularCostoUnidad(){
      if(!isNaN(this.editItem.montoTotal)){
        this.editItem.costoUnidad = this.editItem.montoTotal / this.editItem.cantidad;
        this.$refs.costoUnidad.setValue(this.editItem.costoUnidad);
      }
      else{
        this.editItem.costoUnidad = 0.0;
      }
    },
    setFecha(valor){
      if(valor == 0){
        this.editItem.fecha = this.editItem.fechaCreacion.substring(0,10);
      }
      else if(valor == 1){
        this.editItem.fecha = this.editItem.fechaRecepcion.substring(0,10);
      }
      else{
        this.editItem.fecha = this.editItem.fechaIngreso.substring(0,10);
      }
      this.$refs.fecha.setValue(this.editItem.fecha);
    },
    validate(){
      this.errores = []
      if(!this.$utils.isValid(this.editItem.costoUnidad) || this.$utils.numbers.validFloat(this.editItem.costoUnidad) <= 0){
        this.errores.push("El Costo por " + this.editItem.unidad+" debe de ser mayor que cero.");
      }

      if(!this.$utils.isValid(this.editItem.costoUnidad) || this.$utils.numbers.validFloat(this.editItem.costoUnidad) <= 0){
        this.errores.push("El Monto Total debe de ser mayor que cero.");
      }

      if(!this.$utils.isValid(this.editItem.fecha) || this.editItem.fecha.length != 10){
        this.errores.push("Debe seleccionar una Fecha de Compra.");
      }

      return (this.errores.length == 0);
    },
    save(){
      if(!this.validate()) return;
      this.saving = true;
      axios
      .put("/Compras/ReporteCostoIngresos",{
        IdMovimiento: this.editItem.idMovimiento,
        CostoUnidad: this.editItem.costoUnidad,
        MontoTotal: this.editItem.montoTotal,
        Fecha: this.editItem.fecha
      })
      .then(() => {
        this.saving = false;
        this.reset();
        this.initialize();
      })
      .catch(error => {
        this.saving = false;
        console.log(error);
      });
    }
   }
}
</script>