<template>
	<v-col
		cols="12"
		class="pa-0 d-flex flex-column align-center justify-center"
		:class="computedHeight"
	>
		<template v-if="loadingDataInstancias">
			<v-progress-circular
				:size="70"
				:width="6"
				color="primary"
				indeterminate
			></v-progress-circular>
			<p class="text-h4 text--secondary">Cargando</p>
		</template>
		<template
			v-else-if="
				!hayMaquinaInstanciaSeleccionada && hayMaquinas
			"
		>
			<v-icon size="100" color="grey">mdi-robot-industrial</v-icon>
			<p
				class="mb-0 font-weight-bold"
				style="font-size: 2rem !important; color: #9e9e9e"
			>
				Seleccione una Instancia
			</p>
		</template>
		<template
			v-else-if="noHayData"
		>
			<v-icon size="100" color="grey">mdi-database-remove</v-icon>
			<p
				class="mb-0 font-weight-bold"
				style="font-size: 2rem !important; color: #9e9e9e"
			>
				No hay datos disponibles
			</p>
		</template>
	</v-col>
</template>

<script>
export default {
    props: {
        hayMaquinaInstanciaSeleccionada: {
            type: Boolean,
            default: false
        },
        loadingDataInstancias: {
            type: Boolean,
            default: false
        },
        hayMaquinas: {
            type: Boolean,
            default: false
        },
        noHayData: {
            type: Boolean,
            default: false
        }
    },
    computed: {
        computedHeight() {
			return this.hayMaquinaInstanciaSeleccionada
				? "height-72vh"
				: "height-78vh";
		},
    }
};
</script>

<style scoped>
.height-72vh {
	height: 72vh;
}

.height-78vh {
	height: 78vh;
}
</style>