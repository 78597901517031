<template>
   <v-tooltip
      
      bottom
      color="black"
      class="white--text"
    >
      <template v-slot:activator="{ on, attrs }">
        <v-icon
          v-bind="attrs"
          v-on="on"
         :color="color"
        >mdi-earth</v-icon>
      </template>
      <span class="white--text">
        <strong>AVG: </strong>{{reports.length > 0 ? average(reports).toFixed(2) : 0}} ms
        <br>
        <strong>Min: </strong>{{reports.length > 0 ? Math.min.apply(Math, reports) : 0}} ms
        <br>
        <strong>Max: </strong>{{reports.length > 0 ? Math.max.apply(Math, reports) : 0}} ms
      </span>
    </v-tooltip>
</template>

<script>
import axios from "axios";
import { mapMutations } from "vuex";

export default {
  data: () => ({
    interval: null,
    reports:[],
  }),
  mounted(){
    this.ping("/Ayuda/Ping")
      .then(response => { 
        this.reports.push(response);
        this.reports = this.reports.slice(Math.max(this.reports.length - 10, 0));
      })
      .catch(() => {
        this.reports.push(-1)
        this.reports = this.reports.slice(Math.max(this.reports.length - 10, 0));
      });

    this.interval = setInterval(() => {
      this.ping("/Ayuda/Ping")
      .then(response => {
        this.reports.push(response);
        this.reports = this.reports.slice(Math.max(this.reports.length - 10, 0));
      })
      .catch(() => {
        this.reports.push(-1);
        this.reports = this.reports.slice(Math.max(this.reports.length - 10, 0));
      });
    },10000);
  },
  beforeDestroy(){
    if(this.interval != null) clearInterval(this.interval);
  },
  computed:{
    status(){
      if(this.reports.length == 0) return 0;
      else if(this.reports[this.reports.length-1] == -1) return 3;
      else if(this.reports[this.reports.length-1] > 250 || this.reports.indexOf(-1) >= 0 || this.average(this.reports) > 250) return 2;
      else return 1;
    },
    color(){
      switch(this.status){
        case 0:
          return 'white';
        case 1:
          return 'green';
        case 2:
          return 'yellow';
        case 3:
          return 'red';
        default:
          return 'white';
      }
    }
  },
  watch:{
    status(){
      this.update_internet_status(this.status);
    }
  },
  methods:{
    ...mapMutations(["update_internet_status"]),
    ping(url){
      return new Promise((resolve,reject) => {
        var start = (new Date()).getTime();
        setTimeout(() => { reject(Error('Timeout')); }, 5000);
        axios
        .get(url)
        .then(() => {
          var time = ((new Date()).getTime() - start);
          resolve(time);
        })
        .catch(error => {
          reject(error);
        })
      });
    },
    average(array){
      var x = array.filter(a => a > 0);
      if(x.length > 0) return x.reduce((a, b) => a + b) / x.length;
      else return -1;
    }
  }
}
</script>