<template>
	<v-col cols="12" v-if="permisoVista('organigrama','r')" class="pa-0">
		<v-dialog v-model="dialog" persistent max-width="35%">
			<v-card>
				<v-card-title>Agregar</v-card-title>
				<v-card-text>
					<v-row>
						<v-col cols="12">
							<v-autocomplete
								v-model="editedItem.idJefe"
								:items="personas"
								:item-text="nombrePersona"
								item-value="idPersona"
								label="Jefe"
								:clearable="!editedItem.subsOnly"
								:readonly="editedItem.subsOnly"
								:disabled="saving"
								:error-messages="errorJefe"
								@focus="errorJefe = ''"
								@change="editedItem.idsSubordinados = []"
							></v-autocomplete>
						</v-col>
						<v-col cols="12">
							<v-autocomplete
								v-model="editedItem.idsSubordinados"
								:items="personasSinJefeFiltradas"
								item-text="name"
								item-value="idPersona"
								label="Subordinados"
								clearable
								chips
								deletable-chips
								multiple
								:disabled="editedItem.idJefe == null || saving"
								:error-messages="errorSubordinados"
								@focus="errorSubordinados = ''"
							></v-autocomplete>
						</v-col>
					</v-row>
				</v-card-text>
				<v-card-actions>
					<v-spacer></v-spacer>
					<v-btn
						@click="cerrarDialog"
						text
						color="success"
						:disabled="saving"
						>Cancelar</v-btn
					>
					<v-btn
						@click="save"
						text
						color="error"
						:disabled="saving"
						:loading="saving"
						>Guardar</v-btn
					>
				</v-card-actions>
			</v-card>
		</v-dialog>
		<OrganizationChart
			id="chart"
			:datasource="datos"
			pan
			zoom
			class="customNode"
		>
			<template slot-scope="{ nodeData }">
				<v-list class="rounded-lg pt-0">
					<v-system-bar
						:height="10"
						:color="nodeData.color != null && nodeData.color"
						class="rounded-t-lg"
					></v-system-bar>
					<v-list-item two-line>
						<v-list-item-avatar>
							<v-img
								:src="`https://ui-avatars.com/api/?name=${nodeData.name}&size=64`"
							></v-img>
						</v-list-item-avatar>
						<v-list-item-content>
							<v-list-item-title class="text-h6 text-left">
								{{ nodeData.name }}
							</v-list-item-title>
							<v-list-item-subtitle class="text-left">
								{{ nombreEmpresa }}
							</v-list-item-subtitle>
						</v-list-item-content>
						<v-list-item-action
							v-if="
								nodeData.idPersona > 0 && 
								(permisoVista('organigrama','u') || permisoVista('organigrama','d'))
							"
						>
							<v-menu
								transition="slide-x-transition"
								attach
								offset-x
								close-on-click
								content-class="elevation-0"
							>
								<template v-slot:activator="{ on, attrs }">
									<v-btn icon v-bind="attrs" v-on="on">
										<v-icon color="grey"
											>mdi-dots-vertical</v-icon
										>
									</v-btn>
								</template>

								<div class="d-flex">
									<v-btn
										v-if="permisoVista('organigrama','u')"
										@click="agregarNodo(nodeData)"
										fab
										dark
										color="orange"
										class="my-0"
										elevation="0"
									>
										<v-icon size="30">mdi-pencil</v-icon>
									</v-btn>
									<v-btn
										v-if="permisoVista('organigrama','d')"
										@click="borrarNodo(nodeData)"
										fab
										dark
										color="red"
										class="my-0"
										elevation="0"
									>
										<v-icon size="30">mdi-delete</v-icon>
									</v-btn>
								</div>
							</v-menu>
						</v-list-item-action>
					</v-list-item>
				</v-list>
			</template>
		</OrganizationChart>
		<v-btn
			v-if="permisoVista('organigrama','c')" 
			@click="agregarNodo()" 
			fab
			absolute
			dark
			color="green"
			style="right: 0.6rem; bottom: 0.8rem; z-index: 20"
		>
			<v-icon size="30">mdi-plus</v-icon>
		</v-btn>
	</v-col>
</template>

<script>
import axios from "axios";
import OrganizationChart from "vue-organization-chart";
import "vue-organization-chart/dist/orgchart.css";
import { mapState } from "vuex";

export default {
	components: {
		OrganizationChart,
	},
	data() {
		return {
			optionsBtn: false,
			personas: [],
			errorSubordinados: "",
			errorJefe: "",
			dialog: false,
			datos: {},
			colores: [
				"red",
				"orange",
				"blue",
				"green",
				"purple",
				"pink accent-2",
				"teal",
				"cyan",
				"lime",
				"yellow",
			],
			fab: false,
			nodo: {
				idPersona: null,
				name: "",
				children: null,
				color: "",
			},
			defaultNodo: {
				idPersona: null,
				name: "",
				children: null,
				color: "",
			},
			editedItem: {
				idJefe: null,
				subsOnly: false,
				idsSubordinados: [],
			},
			defaultEditedItem: {
				idJefe: null,
				subsOnly: false,
				idsSubordinados: [],
			},
			saving: false,
			personasSinJefe: [],
		};
	},
	mounted() {
		this.initialize();
	},
	computed: {
		...mapState(["nombreEmpresa"]),
		personasSinJefeFiltradas() {
			if (this.editedItem.idJefe == null) return [];
			const jefe = this.buscarJefePersona(
				this.editedItem.idJefe,
				this.datos.children
			);

			const filtrado = this.personasSinJefe.filter(
				(persona) =>
					persona.idPersona != this.editedItem.idJefe &&
					persona.idPersona != jefe?.idPersona
			);

			if (this.editedItem.subsOnly && this.editedItem.children) {
				filtrado.unshift(
					...this.editedItem.children.map((persona) => ({
						idPersona: persona.idPersona,
						name: persona.name,
					}))
				);
			}

			return filtrado;
		},
	},
	methods: {
		// SOLO PARA MUESTRA DE USO DE dom-to-image-more
		// descargarImagen(){
		// 	domtoimage
		// 		.toBlob(document.getElementById("chart"),{ style: {
		// 			overflow: "visible",
		// 			'background-color': "rgb(247, 247, 247)",
		// 			height: "auto",
		// 			width: "100%",
		// 			transform: ""
		// 		}})
		// 		.then((blob) => {
		// 			// window.saveAs(blob, "organigrama.png");
		// 			let a = document.createElement("a") 
		// 			let blobURL = URL.createObjectURL(blob)
		// 			a.download = "organigrama.png"
		// 			a.href = blobURL
		// 			document.body.appendChild(a)
		// 			a.click()
		// 			document.body.removeChild(a)
		// 		});
		// },
		buscarJefePersona(idPersona, children, previous = null) {
			let result = null;
			children.some(
				(persona) =>
					(result =
						persona.idPersona == idPersona
							? previous
							: this.buscarJefePersona(
									idPersona,
									persona.children || [],
									persona
							  ))
			);
			return result;
		},
		nombrePersona: (value) =>
			`${value.nombre} ${value.paterno} ${value.materno}`,
		cerrarDialog() {
			this.dialog = false;
			this.editedItem = { ...this.defaultEditedItem };
			this.errorJefe = "";
			this.errorSubordinados = "";
		},
		validate() {
			this.errorJefe = "";
			this.errorSubordinados = "";

			if (this.editedItem.idJefe == null) {
				this.errorJefe = "Debe seleccionar una persona";
			}

			if (this.editedItem.idsSubordinados.length == 0) {
				this.errorSubordinados =
					"Debe seleccionar uno o más subordinados";
			}

			return this.errorJefe == "" && this.errorSubordinados == "";
		},
		save() {
			if (!this.validate()) return;

			this.saving = true;

			axios({
					url: this.editedItem.subsOnly
						? "/Personas/ActualizarAsignarJefeSubordinados"
						: "/Personas/AsignarJefeSubordinados",
					method: this.editedItem.subsOnly ? "PUT" : "POST",
					data: {
						idJefe: this.editedItem.idJefe,
						idsSubordinados: this.editedItem.idsSubordinados,
					},
				})
				.then(() => {
					this.saving = false;
					this.cerrarDialog();
					this.initialize();
				})
				.catch((error) => {
					this.saving = false;
					if(error.response){
						if(error.response.status == 409){
							this.errorSubordinados = `Error al relacionar ${this.editedItem.idsSubordinados.length==1?'el subordinado':'los subordinados'} con el jefe seleccionado`;
						}
					}
					console.log(error);
				});
		},
		borrarNodo(nodo) {
			axios
				.put(`/Personas/EliminarSubordinados/${nodo.idPersona}`)
				.then(() => {
					this.initialize();
				})
				.catch((error) => {
					console.log(error);
				});
		},
		agregarNodo(item = null) {
			this.editedItem =
				item == null
					? { ...this.defaultEditedItem }
					: {
							...item,
							subsOnly: true,
							idJefe: item.idPersona,
							idsSubordinados: item.children?.map(
								(persona) => persona.idPersona
							),
					  };
			this.dialog = true;
		},
		randomNumber() {
			return Math.floor(Math.random() * (1 - 100 + 1) + 100);
		},
		initialize() {
			this.getPersonas();
			this.getDatos();
			this.getPersonasSinJefe();
		},
		getPersonasSinJefe() {
			axios
				.get("/Personas/PersonasSinJefe")
				.then((response) => {
					this.personasSinJefe = response.data;
				})
				.catch((error) => {
					console.log(error);
				});
		},
		definirColores(arreglo, indice = 0) {
			let color = this.colores[indice];
			arreglo.forEach((item) => {
				item.color = color;
				this.definirColores(item.children || [], indice + 1);
			});
			return arreglo[0];
		},
		getPersonas() {
			axios
				.get("/Personas")
				.then((response) => {
					this.personas = response.data;
				})
				.catch((error) => {
					console.log(error);
				});
		},
		getDatos() {
			axios
				.get("/Personas/ListarJefeSubordinadoArbol")
				.then((response) => {
					response.data.ds.children =
						response.data.ds.children.filter(
							(ch) => ch.children.length > 0
						);
					this.datos = this.definirColores([response.data.ds]);
				})
				.catch((error) => {
					console.log(error);
				});
		},
	},
};
</script>
<style scoped>
.customNode {
	background-color: rgb(247, 247, 247);
	height: 45rem;
}
.customNode >>> .orgchart .node {
	width: min-content;
}

.orgchart-container {
	border: none;
	width: calc(100% - 0px);
}

.customNode >>> .orgchart .lines .topLine {
	border-top: 1px solid rgba(0, 0, 0, 0.5);
}
.customNode >>> .orgchart .lines .rightLine {
	border-right: 1px solid rgba(0, 0, 0, 0.5);
}
.customNode >>> .orgchart .lines .downLine {
	background-color: rgba(0, 0, 0, 0.5);
}
.customNode >>> .orgchart .lines .leftLine {
	border-left: 1px solid rgba(0, 0, 0, 0.5);
}

.customNode >>> tbody tr:nth-of-type(odd) {
	background-color: transparent;
}
</style>