<template>
	<apexchart
		ref="chart"
		:type="type"
		:height="height"
		:options="chartOptions"
		:series="series"
	></apexchart>
</template>

<script>
export default {
	data: () => ({
		showDialog: false,
		title: "",
		height: 440,
		series: [],
		type: "scatter",
		chartOptions: {
			chart: {
				height: 440,
				type: "scatter",
				zoom: {
					enabled: true,
					type: "xy",
				},
			},
			xaxis: {
				min: 0,
				tickAmount: 10,
				labels: {
					formatter: function (val) {
						return parseFloat(val).toFixed(1);
					},
				},
			},
			yaxis: {
				min: 0,
				tickAmount: 7,
			},
		},
		defaultChartOptions: {
			series: [],
			chart: {
				height: 440,
				type: "scatter",
				zoom: {
					enabled: true,
					type: "xy",
				},
			},
			xaxis: {
				min: 0,
				type: "numeric",
    			tickAmount: 'dataPoints',
				labels: {
					formatter: function (val) {
						try {
							return new Date(val * 1000).toISOString().substr(11, 8)
						} catch (error) {
							return ''
						}
					},
				},
			},
			yaxis: {
				min: 0,
			},
			tooltip: {
				y: {
					title: {
						formatter: function(seriesName) { return seriesName.replace( 'Promedio','Frecuencia:' ) }
					}
				},
				x: {
					formatter: function(seriesName) { 
						try {
							return 'Tiempo: '+new Date(seriesName * 1000).toISOString().substr(11, 8) 
						} catch (error) {
							return ''
						}
					}
				}
			}
		},
	}),
	methods: {
		show(series, type = null, height = null) {
			this.chartOptions = Object.assign({}, this.defaultChartOptions);
			this.series = series;
			if (type != null) this.type = type;
			if (height != null) this.height = height;
			setTimeout(() => {
				this.$refs.chart.refresh();
			}, 250);
		},
		close() {
			this.chartOptions = Object.assign({}, this.defaultChartOptions);
			this.series = [];
			this.type = "scatter";
			this.height = 440;
		},
	},
};
</script>