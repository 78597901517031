<template>
<div>
  <notifications group="produccion2" position="bottom left" :duration="3000"/>
  <notifications group="produccion" position="bottom right" :duration="10000"/>
  <signalr
    path="/hub/produccion"
    @change="cambio"
  ></signalr>
  <v-tabs
    v-model="tab"
    background-color="transparent"
    grow
  >
    <v-tab
      @click="resumenClick"
    >
      Resumen
    </v-tab>
    <v-tab
      @click="abiertoClick"
    >
      En Progreso
    </v-tab>
    <v-tab
      @click="cerradoClick"
    >
      Finalizadas
    </v-tab>

    <v-tab
      @click="otsMadresClick"
    >
      Ordenes de Trabajo Madre
    </v-tab>

    <v-tabs-items v-model="tab" touchless>
      <v-tab-item eager>
        <resumen ref="resumen"></resumen>
      </v-tab-item>
      <v-tab-item eager>
        <abierto ref="abierto"></abierto>
      </v-tab-item>
      <v-tab-item eager>
        <cerrado ref="cerrado"></cerrado>
      </v-tab-item>
      <v-tab-item eager>
        <ODTMadres ref="odtmothers" />
      </v-tab-item>
    </v-tabs-items>
  </v-tabs>
</div>
</template>

<script>
import resumen from "./Lista/Resumen";
import abierto from "./Lista/Abierto";
import cerrado from "./Lista/Cerrado";
import signalr from '@/components/SignalR';
import ODTMadres from './Lista/ODTMadres.vue';

export default {
  components:{
    abierto,
    cerrado,
    signalr,
    ODTMadres,
    resumen
  },
  data: () => ({
    tab:0,
  }),
  methods:{
    cambio(event){
      if(this.tab == 0)this.$refs.resumen.cambio(event);
      if(this.tab == 1)this.$refs.abierto.cambio(event);
      if(this.tab == 2)this.$refs.cerrado.cambio(event);
    },
    initialize(){
      this.otsMadresClick(); 
      this.resumenClick();
      this.abiertoClick();
      this.cerradoClick();
    },
    otsMadresClick(){
      if(typeof this.$refs.odtmothers === 'undefined') return;
      this.$refs.odtmothers.initialize();
    },
    resumenClick(){
      if(typeof this.$refs.resumen === 'undefined') return;
      this.$refs.resumen.initialize();
    },
    abiertoClick(){
      if(typeof this.$refs.abierto === 'undefined') return;
      this.$refs.abierto.initialize();
    },
    cerradoClick(){
      if(typeof this.$refs.cerrado === 'undefined') return;
      this.$refs.cerrado.initialize();
    }
  }
}
</script>