import loadingDialog from '@/components/LoadingDialog';
<template>
  <v-col cols="12">
    <v-card>
      <v-toolbar color="primary" dark flat>
        <v-tabs v-model="currentItem" fixed-tabs slider-color="white">
          <v-tab
            v-for="(item, idx) in head"
            :key="'tab-' + idx"
            :href="'#tab-' + idx"
            class="p-10"
            @click="changeSection(item.code)"
          >
            <v-icon> {{ item.icon }} </v-icon> {{ item.title }}
          </v-tab>
        </v-tabs>
      </v-toolbar>

      <v-tabs-items v-model="currentItem">
        <v-tab-item
          v-for="(item, idx) in head"
          :key="item"
          :value="'tab-' + idx"
        >
          <v-card v-if="item.code == 'favoritos'" :elevation="0">
            <v-card-text>
              <div v-if="favs.length == 0">
                <p class="text-h6 text-center">No tiene reportes favoritos</p>
              </div>

              <v-tabs v-else centered class="mt-n5" :key="tabsKey">
                <v-tab
                  v-for="fav in listFavs()"
                  :key="'tab-' + fav.idReporte"
                  :href="'#tab-' + fav.idReporte"
                  @click="changeView(fav.idReporte)"
                >
                  <span color="primary">{{ fav.nombre }}</span>
                  <v-btn text color="yellow" @click="removeFavs(fav.idReporte)"
                    ><v-icon>mdi-star</v-icon></v-btn
                  >
                </v-tab>
              </v-tabs>
            </v-card-text>
          </v-card>
          <!-- Favs -->
          <v-card v-else flat>
            <v-card-text class="mt-n7">
              <v-card :elevation="0">
                <v-tabs v-if="show" fixed-tabs>
                  <v-tab
                    v-for="report in getReports(item.code)"
                    :key="'tab-' + report.idReporte"
                    :href="'#tab-' + report.idReporte"
                    @click="changeView(report.idReporte)"
                  >
                    {{ report.nombre }}
                  </v-tab>
                </v-tabs>
              </v-card>
            </v-card-text>
          </v-card>
        </v-tab-item>
      </v-tabs-items>
      <!-- Items Globales -->
      <v-tabs-items v-model="currentView">
        <v-tab-item
          v-for="report in reports"
          :key="`tab-${report.idReporte}`"
          :value="'tab-' + report.idReporte"
        >
          <v-col cols="12">
            <div class="d-flex justify-center align-center">
              <div class="text-h4">{{ report.nombre }}</div>
              <v-btn
                v-if="!isInFavs(report.idReporte)"
                text
                color="grey"
                @click="addFavs(report.idReporte)"
                ><v-icon>mdi-star</v-icon></v-btn
              >
              <v-btn
                v-else
                text
                color="yellow"
                @click="removeFavs(report.idReporte)"
                ><v-icon>mdi-star</v-icon></v-btn
              >
            </div>

            <Grafica1
              :active="`tab-${report.idReporte}` == currentView"
              v-if="report.idReporte == 1"
            />
            <Grafica2
              :active="`tab-${report.idReporte}` == currentView"
              v-else-if="report.idReporte == 2"
            />
            <Grafica3
              :active="`tab-${report.idReporte}` == currentView"
              v-else-if="report.idReporte == 3"
            />
            <Grafica4
              :active="`tab-${report.idReporte}` == currentView"
              v-else-if="report.idReporte == 4"
            />
            <Grafica5
              :active="`tab-${report.idReporte}` == currentView"
              v-else-if="report.idReporte == 5"
            />
            <Grafica6
              :active="`tab-${report.idReporte}` == currentView"
              v-else-if="report.idReporte == 6"
            />
            <Grafica7
              :active="`tab-${report.idReporte}` == currentView"
              v-else-if="report.idReporte == 7"
            />
            <Matriz1
              :active="`tab-${report.idReporte}` == currentView"
              v-else-if="report.idReporte == 8"
            />
            <Matriz2
              :active="`tab-${report.idReporte}` == currentView"
              v-else-if="report.idReporte == 9"
            />
            <Matriz3
              :active="`tab-${report.idReporte}` == currentView"
              v-else-if="report.idReporte == 10"
            />
            <ReporteOEE
              :active="`tab-${report.idReporte}` == currentView"
              v-else-if="report.idReporte == 11"
            />
            <ReporteRendimiento
              :active="`tab-${report.idReporte}` == currentView"
              v-else-if="report.idReporte == 12"
            />
            <ReporteSubOperaciones
              :active="`tab-${report.idReporte}` == currentView"
              v-else-if="report.idReporte == 13"
            />
            <ReporteOperaciones
              :active="`tab-${report.idReporte}` == currentView"
              v-else-if="report.idReporte == 14"
            />
            <ReporteComparativaMaquinas
              :active="`tab-${report.idReporte}` == currentView"
              v-else-if="report.idReporte == 15"
            />
            <ReporteTiempoNoProductivoMaquinas
              :active="`tab-${report.idReporte}` == currentView"
              v-else-if="report.idReporte == 16"
            />
            <ReporteGraficaDatos
              :active="`tab-${report.idReporte}` == currentView"
              v-else-if="report.idReporte == 17"
            />
            <ReporteProduccion
              :active="`tab-${report.idReporte}` == currentView"
              v-else-if="report.idReporte == 18"
            />
            <ReporteMaquinaSensor
              :active="`tab-${report.idReporte}` == currentView"
              v-else-if="report.idReporte == 19"
            />
            <ReporteTiempoCiclo
              :active="`tab-${report.idReporte}` == currentView"
              v-else-if="report.idReporte == 20"
            />
            <ReporteOperadoresOperacion
              :active="`tab-${report.idReporte}` == currentView"
              v-else-if="report.idReporte == 21"
            />
            <ReporteAsistenciasEmpleado
              :active="`tab-${report.idReporte}` == currentView"
              v-else-if="report.idReporte == 22"
            />
            <ReporteFactibilidadOrdenTrabajo
              :active="`tab-${report.idReporte}` == currentView"
              v-else-if="report.idReporte == 29"
            />
            <v-container v-else-if="report.url != null" fluid grid-list-xl>
              <v-layout wrap>
                <iframe
                  id="info"
                  width="100%"
                  height="920"
                  :src="report.url"
                  frameborder="0"
                  allowFullScreen="true"
                ></iframe>
              </v-layout>
            </v-container>
          </v-col>
        </v-tab-item>
      </v-tabs-items>
    </v-card>
  </v-col>
</template>

<script>
import axios from "axios";
import { mapState, mapMutations } from "vuex";
import OEEFactor from "./Reportes/OEEFactor.vue";
import Grafica1 from "./Dashboards/Grafica1.vue";
import Grafica2 from "./Dashboards/Grafica2.vue";
import Grafica3 from "./Dashboards/Grafica3.vue";
import Grafica4 from "./Dashboards/Grafica4.vue";
import Grafica6 from "./Dashboards/Grafica6.vue";
import Grafica7 from "./Dashboards/Grafica7.vue";
import Matriz1 from "./Dashboards/Matriz1.vue";
import Matriz2 from "./Dashboards/Matriz2.vue";
import Matriz3 from "./Dashboards/Matriz3.vue";
import Grafica5 from "./Dashboards/Grafica5.vue";
import ReporteRendimiento from "./Reportes/ReporteRendimiento.vue";
import ReporteSubOperaciones from "./Reportes/ReporteSubOperaciones.vue";
import ReporteOperaciones from "./Reportes/Operaciones.vue";
import ReporteComparativaMaquinas from "./Reportes/ComparativaMaquinas.vue";
import ReporteTiempoNoProductivoMaquinas from "./Reportes/TiempoNoProductivoMaquinas.vue";
import ReporteGraficaDatos from "./Reportes/Graficas.vue";
import ReporteProduccion from "./Reportes/ProduccionTiempoReal.vue";
import ReporteMaquinaSensor from "./Reportes/MaquinaSensorTiempoReal.vue";
import ReporteTiempoCiclo from "./Reportes/ReporteTiempoCiclo.vue";
import ReporteOperadoresOperacion from "./Reportes/ReporteOperadoresOperacion.vue";
import ReporteAsistenciasEmpleado from "./Reportes/ReporteAsistenciasEmpleado.vue";
import ReporteFactibilidadOrdenTrabajo from "./Reportes/ReporteFactibilidadOrdenTrabajo.vue";

export default {
  components: {
    ReporteOEE: OEEFactor,
    Grafica1,
    Grafica2,
    Grafica3,
    Grafica4,
    Grafica5,
    Grafica6,
    Grafica7,
    Matriz1,
    Matriz2,
    Matriz3,
    ReporteRendimiento,
    ReporteSubOperaciones,
    ReporteOperaciones,
    ReporteComparativaMaquinas,
    ReporteTiempoNoProductivoMaquinas,
    ReporteGraficaDatos,
    ReporteProduccion,
    ReporteMaquinaSensor,
    ReporteTiempoCiclo,
    ReporteOperadoresOperacion,
    ReporteAsistenciasEmpleado,
    ReporteFactibilidadOrdenTrabajo,
  },
  data() {
    return {
      tabsKey: 0,
      tab: null,
      currentView: null,
      ultimoClick: null,
      currentItem: "tab-0",
      show: true,
      favorite: false,
      reports: [],
      head: [
        {
          code: "favoritos",
          title: "Favoritos",
          icon: "mdi-star-outline",
        },
        {
          code: "usabilidad",
          title: "Usabilidad",
          icon: "mdi-source-branch-check",
        },
        {
          code: "produccion",
          title: "Reportes Generales de Producción",
          icon: "mdi-factory",
        },
        {
          code: "operadores",
          title: "Reportes de Operadores",
          icon: "mdi-account-hard-hat",
        },
        {
          code: "maquinas",
          title: "Reportes de Máquinas",
          icon: "mdi-car-shift-pattern",
        },
        {
          code: "oee",
          title: "OEE",
          icon: "mdi-speedometer",
        },
        {
          code: "calidad",
          title: "Calidad",
          icon: "mdi-certificate",
        },
        {
          code: "analitica",
          title: "Analitica de Datos",
          icon: "mdi-chart-line",
        },
      ],
    };
  },
  mounted() {
    this.getReportsDB();
  },
  computed: {
    ...mapState({ favs: "idsReportes" }),
  },
  methods: {
    ...mapMutations(["add_report", "delete_report"]),
    listFavs() {
      var response = [];
      this.head.forEach((h) => {
        if (h.code != "favoritos") {
          var reportsSection = this.reports.filter(
            (r) => r.seccion == h.code && this.favs.indexOf(r.idReporte) >= 0
          );
          if (reportsSection.length > 0) {
            reportsSection = reportsSection.sort(
              (a, b) => a.posicion - b.posicion
            );
            response = response.concat(reportsSection);
          }
        }
      });
      return response;
    },
    getReports(seccion) {
      var reports = this.reports.filter((r) => r.seccion == seccion);
      reports = reports.sort((a, b) => a.posicion - b.posicion);
      return reports;
    },
    changeView(idReporte) {
      if (idReporte == "mounted") {
        const listaFavoritos = this.listFavs();
        var report = listaFavoritos.length > 0 ? listaFavoritos[0] : null;
        if (report == null) this.currentView = null;
        else {
          this.currentView = "tab-" + report.idReporte;
          this.tabsKey++;
        }
      } else {
        this.currentView = "tab-" + idReporte;
      }
    },
    changeSection(seccion) {
      this.show = false;

      if (seccion != "favoritos") {
        var minPosition = Math.min.apply(
          Math,
          this.reports
            .filter((r) => r.seccion == seccion)
            .map((r) => r.posicion)
        );
        if (isNaN(minPosition)) this.currentView = null;
        var report = this.reports.find(
          (r) => r.seccion == seccion && r.posicion == minPosition
        );
        if (report == null) this.currentView = null;
        else this.currentView = "tab-" + report.idReporte;
      } else {
        this.changeView("mounted");
      }
      setTimeout(() => {
        this.show = true;
      }, 50);
    },
    isInFavs(idReporte) {
      if (this.favs.find((id) => id == idReporte)) return true;
      else return false;
    },
    addFavs(idReporte) {
      this.add_report(idReporte);
      localStorage.setItem("idsReportes", JSON.stringify(this.favs));
      axios
        .put("/Usuarios/AgregarReporte/" + idReporte)
        .then(() => {})
        .catch((error) => {
          Error.log(error);
        });
    },
    removeFavs(idReporte) {
      this.delete_report(idReporte);
      localStorage.setItem("idsReportes", JSON.stringify(this.favs));
      if (this.currentItem == "tab-0") {
        this.changeView("mounted");
      }
      axios
        .put("/Usuarios/EliminarReporte/" + idReporte)
        .then(() => {})
        .catch((error) => {
          Error.log(error);
        });
    },
    getReportsDB() {
      axios
        .get("/CatReportes/Listar")
        .then((response) => {
          this.reports = response.data;
          console.log(response.data);
          this.changeSection("favoritos");
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },
};
</script>