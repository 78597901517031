<template>
  <v-dialog v-model="dialog" max-width="1000px">
    <v-card>
      <v-card-text class="text-center">
        <br>
        <br>
        <v-icon :color="color" :size="250">{{icon}}</v-icon>
        <br>
        <br>
        <br>
        <div class="display-2">{{message}}</div>
        <br>
        <div v-if="subMessage != null" class="display-1">{{subMessage}}</div>
        <br>
        <v-btn block color="primary" x-large @click="reset">Aceptar</v-btn>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>

export default {
  props: {
    icon: {
      type: String,
      default: "mdi-alert",
    },
    color:{
      type: String,
      default: "red",
    }
  },
  data: () => ({
    dialog:false,
    message:"",
    subMessage:null,
  }),
  methods:{    
    show(message,subMessage = null){
      this.message = message;
      this.subMessage = subMessage;
      this.dialog = true;
    },
    reset(){
      this.dialog = false;
      this.$emit( 'close', null );
    },
  }
}
</script>