<template>
	<v-container>
		<v-dialog v-model="dialogDelete" persistent max-width="40%">
			<v-card>
				<v-card-text>
					<p class="text-h5 pt-2 font-weight-medium text--primary">
						¿Está seguro que desea eliminar esta Unidad de Tiempo?
					</p>
					<p class="subtitle-1">
						Esta acción no se puede revertir y será permanente.
					</p>
				</v-card-text>
				<v-card-actions>
					<v-spacer></v-spacer>
					<v-btn
						text
						color="success"
						:disabled="deleting"
						@click="cerrarDialog()"
						>Cancelar</v-btn
					>
					<v-btn
						text
						color="error"
						:disabled="deleting"
						:loading="deleting"
						@click="eliminarTiempoUnidad()"
						>Eliminar</v-btn
					>
				</v-card-actions>
			</v-card>
		</v-dialog>
		<v-dialog v-model="dialogCrear" persistent max-width="40%">
			<v-card>
				<v-card-title
					>{{
						itemTiempoUnidad.idTiempoUnidad <= 0
							? "Crear"
							: "Editar"
					}}
					Unidad de Tiempo</v-card-title
				>
				<v-card-text>
					<v-col cols="12">
						<v-text-field
							v-model="itemTiempoUnidad.nombre"
							label="Nombre"
							:error-messages="errorNombre"
						></v-text-field>
					</v-col>
					<v-col cols="12">
						<v-text-field
							v-model="itemTiempoUnidad.abreviatura"
							label="Abreviatura"
							:error-messages="errorAbreviatura"
						></v-text-field>
					</v-col>
				</v-card-text>
				<v-card-actions>
					<v-spacer></v-spacer>
					<v-btn
						text
						color="success"
						:disabled="saving"
						@click="cerrarDialog()"
						>Cancelar</v-btn
					>
					<v-btn
						text
						color="error"
						:disabled="saving"
						:loading="saving"
						@click="guardarTiempoUnidad()"
						>Guardar</v-btn
					>
				</v-card-actions>
			</v-card>
		</v-dialog>
		<v-row>
			<v-col cols="4" class="mt-0 pt-0">
				<v-btn v-if="permisoVista('tiempounidades', 'c')" color="primary" @click="showDialog"
					>Nueva Unidad de Tiempo</v-btn
				>
			</v-col>
			<v-col cols="6" class="mt-0 pt-0 ml-auto">
				<v-text-field
					class="pt-2"
					v-model="search"
					append-icon="search"
					label="Buscar"
					single-line
					hide-details
				></v-text-field>
			</v-col>
		</v-row>
		<v-data-table
			v-if="permisoVista('tiempounidades', 'r')"
			:items="tiempos"
			:headers="headersTiempos"
			:loading="loading"
			:search="search"
		>
			<template v-slot:item.actions="{ item }">
				<v-tooltip
					v-if="permisoVista('tiempounidades', 'u')"
					
					bottom
					color="black"
					class="white--text"
				>
					<template v-slot:activator="{ on }">
						<v-icon
							v-on="on"
							small
							class="mr-2"
							@click="editDialog(item)"
						>
							edit
						</v-icon>
					</template>
					<span class="white--text">Editar</span>
				</v-tooltip>
				<v-tooltip
					v-if="permisoVista('tiempounidades', 'd')"
					
					bottom
					color="black"
					class="white--text"
				>
					<template v-slot:activator="{ on }">
						<v-icon
							v-on="on"
							small
							class="mr-2"
							@click="deleteDialog(item)"
						>
							delete
						</v-icon>
					</template>
					<span class="white--text">Eliminar</span>
				</v-tooltip>
			</template>
		</v-data-table>
		<v-snackbar v-model="alerta" top color="error">
			{{ errorAlerta }}
			<v-btn text @click="alerta = false"> Cerrar </v-btn>
		</v-snackbar>
	</v-container>
</template>

<script>
import axios from "axios";

export default {
	data() {
		return {
            errorAlerta: "",
            alerta: false,
			search: "",
			errorNombre: "",
			errorAbreviatura: "",
			dialogDelete: false,
			dialogCrear: false,
			itemTiempoUnidad: {
				idTiempoUnidad: -1,
				nombre: "",
				abreviatura: "",
			},
			defaultItemTiempoUnidad: {
				idTiempoUnidad: -1,
				nombre: "",
				abreviatura: "",
			},
			headersTiempos: [
				{
					text: "Nombre",
					align: "left",
					sortable: true,
					value: "nombre",
					width: "45%",
				},
				{
					text: "Abreviatura",
					align: "left",
					sortable: true,
					value: "abreviatura",
					width: "45%",
				},
				{
					text: "Acciones",
					value: "actions",
					sortable: false,
					width: "10%",
				},
			],
			tiempos: [],
			loading: false,
			saving: false,
			deleting: false,
		};
	},
	mounted() {
		this.initialize();
	},
	methods: {
		eliminarTiempoUnidad() {
			this.deleting = true;
			axios
				.put(
					`/TiempoUnidades/DesactivarTiempoUnidad/${this.itemTiempoUnidad.idTiempoUnidad}`
				)
				.then(() => {
					this.deleting = false;
					this.cerrarDialog();
					this.getTiempos();
				})
				.catch((error) => {
					this.deleting = false;
					console.log(error);
				});
		},
		validate() {
			this.errorNombre = "";
			this.errorAbreviatura = "";

			if (
				this.itemTiempoUnidad.nombre.length < 4 ||
				this.itemTiempoUnidad.nombre.length > 50
			) {
				this.errorNombre =
					"El nombre debe tener más de 3 caracteres y menos de 50.";
			}
			if (
				this.itemTiempoUnidad.abreviatura.length < 1 ||
				this.itemTiempoUnidad.abreviatura.length > 5
			) {
				this.errorAbreviatura =
					"La abreviatura debe tener al menos 1 caracter y menos de 5.";
			}

			return this.errorNombre == "" && this.errorAbreviatura == "";
		},
		guardarTiempoUnidad() {
			if (!this.validate()) return;
			this.saving = true;
			if (this.itemTiempoUnidad.idTiempoUnidad <= 0) {
				axios
					.post("/TiempoUnidades/CrearTiempoUnidad", {
						nombre: this.itemTiempoUnidad.nombre,
						abreviatura: this.itemTiempoUnidad.abreviatura,
					})
					.then(() => {
						this.saving = false;
						this.cerrarDialog();
						this.getTiempos();
					})
					.catch((error) => {
						this.saving = false;
						if (error.response) {
							if (error.response.status == 409) {
								this.alerta = true;
								this.errorAlerta = "Existe una Unidad de Tiempo Activa con el Mismo Nombre";
							}
						}
					});
			} else {
				axios
					.put(
						`/TiempoUnidades/ActualizarTiempoUnidad/${this.itemTiempoUnidad.idTiempoUnidad}`,
						{
							nombre: this.itemTiempoUnidad.nombre,
							abreviatura: this.itemTiempoUnidad.abreviatura,
						}
					)
					.then(() => {
						this.saving = false;
						this.cerrarDialog();
						this.getTiempos();
					})
					.catch((error) => {
						this.saving = false;
						if (error.response) {
							if (error.response.status == 409) {
								this.alerta = true;
								this.errorAlerta = "Existe una Unidad de Tiempo Activa con el Mismo Nombre";
							}
						}
					});
			}
		},
		initialize() {
			this.getTiempos();
		},
		editDialog(item) {
			this.itemTiempoUnidad = Object.assign({}, item);
			this.dialogCrear = true;
		},
		deleteDialog(item) {
			this.itemTiempoUnidad = Object.assign({}, item);
			this.dialogDelete = true;
		},
		showDialog() {
			this.dialogCrear = true;
		},
		cerrarDialog() {
			this.errorNombre = "";
			this.errorAbreviatura = "";
			this.itemTiempoUnidad = Object.assign(
				{},
				this.defaultItemTiempoUnidad
			);
			this.dialogDelete = false;
			this.dialogCrear = false;
		},
		getTiempos() {
			axios
				.get("/TiempoUnidades")
				.then((response) => {
					this.tiempos = response.data;
				})
				.catch((error) => {
					console.log(error);
				});
		},
	},
};
</script>