<template>
  <v-col cols="12" v-if="permisoVista('interfazrelojchecador','r')">
    <v-row>
      <v-col cols="12">
        <v-data-table
          :headers="headers"
          :items="desserts"
          :loading="cargando"
          loading-text="Loading... Please wait"
          sort-by="calories"
        >
          <template v-slot:top>
        
              <v-dialog v-model="dialog" max-width="500px">
                <v-card>
                  <v-card-title>
                    <span class="text-h5">{{ formTitle }}</span>
                  </v-card-title>
                  <v-card-text>
                    <v-container>
                      <v-row>
                        <v-col class="col-12">
                          <div class="text-center">
                            <v-autocomplete
                              v-model="idsSelec"
                              :items="tags"
                              item-value="idUsuarioReloj"
                              item-text="nombre"
                              chips
                              label="Usuario Reloj Checador"
                              multiple
                              deletable-chips
                            ></v-autocomplete>
                          </div>
                        </v-col>
                      </v-row>
                    </v-container>
                  </v-card-text>
                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="blue darken-1" text @click="close"> Cancelar </v-btn>
                    <v-btn color="blue darken-1" text @click="save"> Guardar </v-btn>
                  </v-card-actions>
                </v-card>
              </v-dialog>
        
          </template>
          <template v-slot:[`item.idRelojChecador`]="{ item }">
            <div class="d-flex justify-center">
              <v-chip
                v-if="item.idRelojChecador.length <= 0"
                color="red"
                text-color="white"
                style="
                  min-width: 100px;
                "
                class="d-flex align-center justify-center"
                >Pendiente</v-chip
              >
              <v-chip
                v-else
                color="green"
                text-color="white"
                style="
                  min-width: 100px;
                "
                class="d-flex align-center justify-center"
                >Configurado</v-chip
              >
            </div>
          </template>
          <template v-slot:[`item.actions`]="{ item }">
            <v-icon v-if="permisoVista('interfazrelojchecador','u')" small class="mr-2" @click="editItem(item)"> mdi-pencil </v-icon>
          </template>
          <template v-slot:no-data>
            <v-btn color="primary" @click="initialize"> Reset </v-btn>
          </template>
        </v-data-table>
      </v-col>
    </v-row>
  </v-col>
</template>

<script>
import axios from "axios";
export default {
  data: () => ({
    cargando:false,
    dialog: false,
    dialogDelete: false,
    headers: [
      {
        text: "Empleado",
        align: 'center',
        value: "nombre",
      },
      {
        text: "Estado",
        align: 'center',
        value: "idRelojChecador",
      },
      { text: "Acciones", value: "actions", align: 'center',sortable: false },
    ],
    desserts: [],
    editedIndex: -1,
    editedItem: {
      idPersona: null,
      idRelojChecador: null,
      nombre: null,
    },
    defaultItem: {
      idPersona: null,
      idRelojChecador: null,
      nombre: null,
    },
    tags: [],
    idsSelec: [],
    idPersona: null,
  }),

  computed: {
    formTitle() {
      return this.editedIndex === -1 ? "New Item" : "Editar";
    },
  },

  watch: {
    dialog(val) {
      val || this.close();
    },
    dialogDelete(val) {
      val || this.closeDelete();
    },
  },

  created() {
    this.initialize();
  },

  methods: {
    initialize() {
      this.cargando = true;
      axios
        .get(`Personas/PersonaRC`)
        .then((response) => {
          this.desserts = response.data;
          this.cargando = false;
        })
        .catch((error) => {
          this.cargando = false;
          console.log(error);
        });
    },

    editItem(item) {
      this.idPersona = item.idPersona;
      this.GetIdsRelojChecador(item.idRelojChecador);
      this.editedIndex = this.desserts.indexOf(item);
      this.editedItem = Object.assign(JSON.parse(JSON.stringify(item)));
      this.dialog = true;
    },
    close() {
      this.dialog = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },
    save() {
      if (this.editedIndex > -1) {
        let edit = new Object();
        edit.idPersona = this.editedItem.idPersona;
        edit.nombre = this.editedItem.nombre;
        edit.idRelojChecador = this.idsSelec;

        Object.assign(this.desserts[this.editedIndex], edit);

        console.log(JSON.parse(JSON.stringify(this.editedItem)));

        let obj = new Object();
        obj.IdPersona = this.idPersona;
        obj.Ids = this.idsSelec;

        axios
          .post("/RelojChecador/AgregarIdUsuario", obj)
          .then((response) => {
            console.log(response);
          })
          .catch((error) => {
            this.saving = false;
            console.log(error);
          });
      } else {
        //this.desserts.push(this.editedItem);
        console.log("aqui no entra");
      }
      this.close();
    },
    GetIdsRelojChecador(ids) {
      this.tags = [];
      this.idsSelec = [];

      var url = `RelojChecador/UsuariosNoAsignadosRelojChecador`;

      if(this.$utils.isValid(ids) && ids.length > 0){
        ids.forEach( (idPersona,idx) => {
					url += `${idx==0?'?':'&'}IdsUsuariosRelojChecador=${idPersona}`;
				});
      }

      axios
        .get(url)
        .then((response) => {
          this.tags = response.data;
          
          ids.forEach((element) => {
            this.idsSelec.push(element);
          });
        
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },
};
</script>