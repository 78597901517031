<template>
	<v-col cols="12" v-if="permisoVista('actividadesprogramadas','r')">
		<v-dialog
			v-model="dialog"
			max-width="800px"
		>
			<v-card>
				<v-card-title>
					{{(editedItem.idActividadProgramada == -1)?"Crear":"Editar"}} Actividad Programada
				</v-card-title>
				<v-card-text>
					<v-row>
						<v-col cols="12" md="12" sm="12">
							<v-autocomplete
								v-model="editedItem.idActividadProgramable"
								:items="actividadesProgramables"
								item-text="nombre"
								item-value="idActividadProgramable"
								label="Actividad Programable"
							></v-autocomplete>
						</v-col>
						<v-col cols="12" md="12" sm="12">
							<VueCronEditorBuefy
								v-model="editedItem.cronExpression"
								locale="es"
								:visibleTabs="['daily', 'weekly', 'monthly']"
							/>
						</v-col>
						<v-col cols="12" md="12" sm="12">
							<ul>
								<li class="red--text text-caption" v-for="(error,idx) in errores" :key="'e'+idx">{{error}}</li>
							</ul>
						</v-col>
					</v-row>
				</v-card-text>
				<v-card-actions>
					<v-spacer></v-spacer>
					<v-btn color="blue darken-1" text @click="close()"
            >Cancelar</v-btn
          >
          <v-btn
            color="red darken-1"
            text
            @click="save()"
            :disabled="saving"
            :loading="saving"
          >
            Guardar
            <template v-slot:loader>
              <v-progress-circular
                indeterminate
                :width="2"
                :size="24"
                color="red"
              ></v-progress-circular>
            </template>
          </v-btn>
				</v-card-actions>
			</v-card>
		</v-dialog>
		<v-btn color="primary" v-if="permisoVista('actividadesprogramadas','c')" @click="dialog = true">
			Crear Actividad Programada
		</v-btn>
		<v-data-table
			:items="actividadesProgramadas"
			:loading="loading"
			:headers="headers"
		>
			<template v-slot:item.cronExpression="{ item }">
				{{cronConvert(item.cronExpression)}}
			</template>
			<template v-slot:item.action="{ item }">
				<v-tooltip v-if="permisoVista('actividadesprogramadas','u')"  bottom color="black" class="white--text">
          <template v-slot:activator="{ on }">
            <v-icon v-on="on"
              small
              class="mr-2"
              @click="triggerItem(item)"
            >
              mdi-play
            </v-icon>
          </template>
          <span class="white--text">Ejecutar</span>
        </v-tooltip>
				<v-tooltip v-if="permisoVista('actividadesprogramadas','u')"  bottom color="black" class="white--text">
          <template v-slot:activator="{ on }">
            <v-icon v-on="on"
              small
              class="mr-2"
              @click="editItem(item)"
            >
              edit
            </v-icon>
          </template>
          <span class="white--text">Editar</span>
        </v-tooltip>
				<v-tooltip v-if="permisoVista('actividadesprogramadas','d')"  bottom color="black" class="white--text">
          <template v-slot:activator="{ on }">
            <v-icon v-on="on"
              small
              class="mr-2"
              @click="deleteItem(item)"
            >
              delete
            </v-icon>
          </template>
          <span class="white--text">Eliminar</span>
        </v-tooltip>
			</template>
		</v-data-table>
		<YesNoDialog
			@change="respuesta"
			ref="yesNoDialog"
		></YesNoDialog>
	</v-col>
</template>

<script>
import axios from "axios";
import cronstrue from "cronstrue/i18n";
import VueCronEditorBuefy from 'vue-cron-editor-buefy';
import YesNoDialog from "@/components/YesNoDialog.vue";

export default {
	components:{
		VueCronEditorBuefy,
		YesNoDialog,
	},
	data:() => ({
		actividadesProgramadas:[],
		actividadesProgramables:[],
		dialog: false,
		loading: false,
		saving: false,
		errores:[],
		estado:0,
		headers:[
			{
        text: 'Actividad',
        align: 'center',
        value: 'actividadProgramable',
				width: '40%'
			},
			{
        text: 'Periodicidad',
        align: 'center',
        value: 'cronExpression',
				width: '40%'
      },
			{
        text: 'Acciones',
        align: 'center',
				sortable:false,
        value: 'action',
				width: '20%'
      },
		],
		defaultEditedItem: {
		  idActividadProgramada: -1,
		  idActividadProgramable: -1,
		  cronExpression: "0 0 */1 * *"
		},
		editedItem: {
		  idActividadProgramada: -1,
		  idActividadProgramable: -1,
		  cronExpression: "0 0 */1 * *"
		},
	}),
	created(){
		this.initialize();
	},
	methods:{
		cronConvert(cron){
			return cronstrue.toString(cron,{ locale: "es" })
		},
		initialize(){
			this.getActividadesProgramadas();
			this.getActividadesProgramables();
		},
		getActividadesProgramadas(){
			this.loading = true;
			axios
			.get("/ActividadesProgramadas/ActividadesProgramadas")
			.then(response => {
				this.actividadesProgramadas = response.data;
				this.loading = false;
			})
			.catch(error => {
				console.log(error);
			})
		},
		getActividadesProgramables(){
			axios
			.get("/ActividadesProgramadas/ActividadesProgramables")
			.then(response => {
				this.actividadesProgramables = response.data;
			})
			.catch(error => {
				console.log(error);
			})
		},
		close(){
			this.editedItem = Object.assign({},this.defaultEditedItem);
			this.dialog = false;
		},
		save(){
			this.errores = [];

			try {
				this.cronConvert(this.editedItem.cronExpression);
			}
			catch (error) {
				this.errores.push("Intervalo Incorrecto");
			}

			if(this.editedItem.idActividadPrograda <= 0) this.errores.push("Falta Actividad Programable");

			if(this.errores.length > 0) return;

			if(this.editedItem.idActividadProgramada == -1){
				axios
				.post("/ActividadesProgramadas/Crear",{
					IdActividadProgramable: this.editedItem.idActividadProgramable,
					CronExpression: this.editedItem.cronExpression,
				})
				.then(() => {
					this.getActividadesProgramadas();
					this.close();
				})
				.catch(error => {
					console.log(error);
				})
			}
			else{
				axios
				.put("/ActividadesProgramadas/Actualizar",{
					IdActividadProgramada: this.editedItem.idActividadProgramada,
					IdActividadProgramable: this.editedItem.idActividadProgramable,
					CronExpression: this.editedItem.cronExpression,
				})
				.then(() => {
					this.getActividadesProgramadas();
					this.close();
				})
				.catch(error => {
					console.log(error);
				})
			}
		},
		editItem(item){
			this.editedItem = item;
			this.dialog = true;
		},
		triggerItem(item){
			this.estado = 1;
			this.$refs.yesNoDialog.show("¿Desea ejecutar este Actividad en este momento?");
			this.editedItem = item;
		},
		deleteItem(item){
			this.estado = 2;
			this.$refs.yesNoDialog.show("¿Desea eliminar este Actividad?","Esta acción no se puede revertir y será permanente.");
			this.editedItem = item;
		},
		resetEstado(){
			this.estado = 0;
			this.editedItem = Object.assign({},this.defaultEditedItem);
		},
		respuesta(value){
			if(value == false) this.resetEstado();
			else{
				if(this.estado == 1){
					axios
					.post("/ActividadesProgramadas/Trigger/"+this.editedItem.idActividadProgramada)
					.then(() => {
						this.resetEstado();
					})
					.catch(error => {
						console.log(error);
					})
				}
				else if(this.estado == 2){
					axios
					.put("/ActividadesProgramadas/Desactivar/"+this.editedItem.idActividadProgramada)
					.then(() => {
						this.resetEstado();
						this.initialize();
					})
				}
			}
		}
	}
}
</script>