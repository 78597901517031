<template>
  <v-container fluid>
    <v-row>
      <v-col cols="6">
        <datePicker
          v-model="filtros.fechaInicio"
          format="YYYY-MM-DD"
          label="Desde (Obligatorio)"
          :maxDate="filtros.fechaFin"
          maxToday
          clearable
          @input="getData()"
          :disabled="loading"
        ></datePicker>
      </v-col>
      <v-col cols="6">
        <datePicker
          v-model="filtros.fechaFin"
          :minDate="filtros.fechaInicio"
          format="YYYY-MM-DD"
          label="Hasta (Obligatorio)"
          clearable
          @input="getData()"
          :disabled="loading"
        ></datePicker>
      </v-col>
      <v-col cols="6">
        <DownloadBtn
          text
          color="primary"
          className="my-0"
          @click="report"
          label="Descargar Reporte MP<br/>Semanal"
		      :disabled="filtros.fechaInicio == null || filtros.fechaFin == null ? true : false"
          :retryTime="20"
        />
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        <v-progress-linear
          v-if="loading"
          indeterminate
          color="primary"
        ></v-progress-linear>
        <v-simple-table
          class="custom-table"
          :class="{ 'custom-height': hayData }"
          fixed-header
        >
          <template v-slot:default>
            <thead>
              <tr>
                <th
                  v-for="(header, index) in dataHeaders"
                  :key="`header-${index}`"
                >
                  {{ header.text }}
                </th>
              </tr>
            </thead>
            <tbody v-if="hayData">
              <template v-for="(semana, indexSemana) in data">
                <template v-for="(dia, indexDia) in semana.dias">
                  <tr
                    v-for="(data, indexData) in dia.data"
                    :key="`tr-${indexSemana}-${indexDia}-${indexData}`"
                  >
                    <td
                      v-for="(header, indexTd) in dataHeaders"
                      :key="`td-${indexTd}`"
                    >
                      {{
                        header.isNumeric
                          ? data[header.value].toFixed(2)
                          : data[header.value]
                      }}
                    </td>
                  </tr>
                  <tr :key="`tr-mpdiaria-${indexDia}`">
                    <td :colspan="dataHeaders.length" class="border-mp-diaria">
                      Requisición MP diaria (24 horas):
                      {{ dia.mpDiaria.toFixed(2) }} Kg
                    </td>
                  </tr>
                </template>
                <tr :key="`td-mpsemanal-${indexSemana}`">
                  <td
                    :colspan="dataHeaders.length"
                    class="font-weight-bold text-uppercase border-mp-semanal"
                  >
                    Requisición MP semanal: {{ semana.mpSemanal.toFixed(2) }} Kg
                  </td>
                </tr>
              </template>
            </tbody>
            <tbody v-else>
              <tr class="info-row">
                <td v-if="loading" :colspan="dataHeaders.length + 2">
                  Cargando...
                </td>
                <td v-else :colspan="dataHeaders.length + 2">
                  No hay datos disponibles
                </td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import axios from "axios";
import DownloadBtn from "../../DownloadBtn.vue";
export default {
  components: { DownloadBtn },
  data() {
    return {
      data: [],
      dataHeaders: [
        {
          text: "# Semana",
          align: "center",
          value: "no_semana",
        },
        {
          text: "Orden de fabricación",
          align: "center",
          value: "orden",
        },
        {
          text: "Fecha de inicio de producción",
          align: "center",
          value: "fechaInicio",
        },
        {
          text: "Fecha compromiso",
          align: "center",
          value: "fechaCompromiso",
        },
        {
          text: "Días faltantes para entrega",
          align: "center",
          value: "diasFaltantes",
        },
        {
          text: "Cliente",
          align: "center",
          value: "cliente",
        },
        {
          text: "Artículo",
          align: "center",
          value: "articulo",
        },
        {
          text: "OT-Lote",
          align: "center",
          value: "ot",
        },
        {
          text: "Láminas del pedido",
          align: "center",
          value: "laminas",
          isNumeric: true,
        },
        {
          text: "Piezas del pedido",
          align: "center",
          value: "piezas",
          isNumeric: true,
        },
        {
          text: "MP requerida / KG Extrusión",
          align: "center",
          value: "mpRequerida",
          isNumeric: true,
        },
      ],
      filtros: {
        fechaInicio: null,
        fechaFin: null,
      },
      loading: false,
    };
  },
  computed: {
    hayData() {
      return !this.loading && this.data.length > 0;
    },
  },
  methods: {
    getTotalWeekRows: (semana) =>
      semana.dias.reduce((acc, dia) => acc + dia.data.length, 0),
    getData() {
      if (this.filtros.fechaInicio == null || this.filtros.fechaFin == null) {
        this.data = [];
        return;
      }

      this.loading = true;

      axios
        .get("/Reportes/ReporteSemanalesDiariosTemporal", {
          params: this.filtros,
        })
        .then((response) => {
          this.data = response.data;
        })
        .catch((error) => {
          console.log(error);
          this.data = [];
        })
        .finally(() => {
          this.loading = false;
        });
    },
    report() {

	  if (this.filtros.fechaInicio == null || this.filtros.fechaFin == null) 
        return;
		
		var url = "/Reportes/ReporteSemanalesPedidosXlsx?";
		url += "&fechaInicio=" + this.filtros.fechaInicio+"&fechaFin="+this.filtros.fechaFin;

      this.$utils.axios.downloadFile(
        url,
        "GET",
        `reporte-mp-semanal.xlsx`,
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
      );
    },
  },
};
</script>
<style scoped>
.custom-height >>> .v-data-table__wrapper {
  max-height: 720px;
}

.border-mp-diaria {
  border-top: 2px dashed rgb(0 0 0 / 50%);
  border-bottom: 2px dashed rgb(0 0 0 / 50%) !important;
}

.border-mp-semanal {
  border-bottom: 2px solid rgb(0 0 0 / 50%) !important;
}

.custom-table >>> th {
  text-align: center !important;
  font-size: 17px !important;
}

.custom-table >>> td {
  text-align: center !important;
}

.info-row {
  color: rgba(0, 0, 0, 0.38);
  background-color: rgba(0, 0, 0, 0.05);
}
</style>