import { VAutocomplete } from 'vuetify/lib/components/VAutocomplete';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VChip } from 'vuetify/lib/components/VChip';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VCombobox } from 'vuetify/lib/components/VCombobox';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSnackbar } from 'vuetify/lib/components/VSnackbar';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';
import { VTooltip } from 'vuetify/lib/components/VTooltip';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VContainer,[_c(VDialog,{attrs:{"persistent":"","max-width":"50%"},model:{value:(_vm.dialogAddControl),callback:function ($$v) {_vm.dialogAddControl=$$v},expression:"dialogAddControl"}},[_c(VCard,[_c(VCardTitle,[_vm._v(_vm._s(_vm.tituloDialogAdd))]),_c(VCardText,[_c(VAutocomplete,{attrs:{"items":_vm.almacenes,"item-text":"nombre","item-value":"idAlmacen","label":"Almacén"},model:{value:(_vm.editItem.idAlmacen),callback:function ($$v) {_vm.$set(_vm.editItem, "idAlmacen", $$v)},expression:"editItem.idAlmacen"}}),_c(VTextField,{attrs:{"label":"Máximo","error-messages":_vm.erroresMaximo},model:{value:(_vm.editItem.maximo),callback:function ($$v) {_vm.$set(_vm.editItem, "maximo", $$v)},expression:"editItem.maximo"}}),_c(VTextField,{attrs:{"label":"Punto Reorden","error-messages":_vm.erroresPR},model:{value:(_vm.editItem.puntoReorden),callback:function ($$v) {_vm.$set(_vm.editItem, "puntoReorden", $$v)},expression:"editItem.puntoReorden"}}),_c(VTextField,{attrs:{"label":"Mínimo","error-messages":_vm.erroresMinimo},model:{value:(_vm.editItem.minimo),callback:function ($$v) {_vm.$set(_vm.editItem, "minimo", $$v)},expression:"editItem.minimo"}}),_c(VCombobox,{attrs:{"chips":"","clearable":"","label":"Correo(s) a notificar","multiple":"","append-icon":"","delimiters":[','],"hint":"Introduzca uno o más correos seguidos de una Coma, Enter o Tabulador."},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
var attrs = ref.attrs;
var item = ref.item;
var select = ref.select;
var selected = ref.selected;
return [_c(VChip,_vm._b({attrs:{"input-value":selected,"close":""},on:{"click":select,"click:close":function($event){return _vm.removeChip(item)}}},'v-chip',attrs,false),[_vm._v(" "+_vm._s(item)+" ")])]}}]),model:{value:(_vm.editItem.correo),callback:function ($$v) {_vm.$set(_vm.editItem, "correo", $$v)},expression:"editItem.correo"}})],1),_c(VCardActions,[_c(VSpacer),_c(VBtn,{attrs:{"color":"green","disabled":_vm.loadingAgregar,"text":""},on:{"click":_vm.cancelarDialogAddControl}},[_vm._v("Cancelar")]),_c(VBtn,{attrs:{"color":"red","disabled":_vm.loadingAgregar || _vm.validarBtnAgregar,"loading":_vm.loadingAgregar,"text":""},on:{"click":_vm.agregarControl}},[_vm._v(" "+_vm._s(this.editItem.idArticuloInventarioControl < 0 ? 'Agregar' : 'Actualizar')+" ")])],1)],1)],1),_c(VDialog,{attrs:{"persistent":"","max-width":"60%"},model:{value:(_vm.dialogControl),callback:function ($$v) {_vm.dialogControl=$$v},expression:"dialogControl"}},[_c(VCard,[_c(VCardTitle,[_vm._v(" Máximos, Mínimos y Puntos de Reorden ")]),_c(VCardText,[_c(VRow,[_c(VCol,{attrs:{"cols":"4"}},[_c(VBtn,{attrs:{"color":"primary"},on:{"click":_vm.showDialogAddControl}},[_vm._v("Agregar Min,Max,PR")])],1)],1),_c(VDataTable,{attrs:{"items":_vm.controlInventario,"headers":_vm.headersControl,"loading-text":"Cargando... Porfavor espere","loading":_vm.loading,"footer-props":{
						disableItemsPerPage: true,
						disablePagination: true,
					}},scopedSlots:_vm._u([{key:"item.correo",fn:function(ref){
					var item = ref.item;
return [(item.correo==null)?_c('p',{staticClass:"mb-0"},[_vm._v("N/A")]):_c('ul',{staticClass:"py-1"},_vm._l((item.correo),function(email){return _c('li',{key:((item.idArticuloInventarioControl) + "-" + email),staticClass:"pb-1"},[_vm._v(" "+_vm._s(email)+" ")])}),0)]}},{key:"item.actions",fn:function(ref){
					var item = ref.item;
return [_c(VTooltip,{staticClass:"white--text",attrs:{"bottom":"","color":"black"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
					var on = ref.on;
return [(_vm.permisoVista('articulos', 'd'))?_c(VIcon,_vm._g({staticClass:"mr-2",attrs:{"small":""},on:{"click":function($event){return _vm.editarControl(item)}}},on),[_vm._v("mdi-pencil")]):_vm._e()]}}],null,true)},[_c('span',{staticClass:"white--text"},[_vm._v("Editar")])])]}}])})],1),_c(VCardActions,[_c(VSpacer),_c(VBtn,{attrs:{"color":"green","text":""},on:{"click":_vm.cerrarDialogControl}},[_vm._v("Cerrar")])],1)],1)],1),_c(VSnackbar,{attrs:{"top":"","color":"error"},model:{value:(_vm.alerta),callback:function ($$v) {_vm.alerta=$$v},expression:"alerta"}},[_vm._v(" El Almacén seleccionado ya esta registrado. "),_c(VBtn,{attrs:{"text":""},on:{"click":function($event){_vm.alerta = false}}},[_vm._v(" Cerrar ")])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }