<template>
  <div>
    <v-row>
      <v-col cols="4" xl="4" md="4" lg="4" xs="12" sm="12">
        <v-autocomplete
          v-model="idArea"
          label="Área (Obligatorio)"
          :items="areas"
          item-text="nombre"
          item-value="idArea"
          clearable
          @change="operacionesDisponibles()"
        ></v-autocomplete>
      </v-col>
      <v-col cols="4" xl="4" md="4" lg="4" xs="12" sm="12">
        <v-autocomplete
          v-model="idTrabajoOrden"
          label="OT"
          :items="claves"
          item-text="clave"
          item-value="idTrabajoOrden"
          clearable
          @change="operacionesDisponibles()"
        ></v-autocomplete>
      </v-col>
      <v-col cols="4" xl="4" md="4" lg="4" xs="12" sm="12">
        <v-autocomplete
          v-model="idPersonaParameter"
          label="Operadores"
          :items="operadores"
          :item-text="nombrePersona"
          item-value="idPersona"
          clearable
          @change="operacionesDisponibles()"
        ></v-autocomplete>
      </v-col>
      <v-col cols="12" xs="12" sm="12" xl="12" md="12" lg="12">
        <v-data-table
          :headers="headers"
          :items="idArea == null ? [] : desserts"
          class="elevation-1"
          :loading="loadTable"
        >
          <template v-slot:item.articuloFase="{ item }">
            <span>
              {{ "[" + item.codigoArticulo + "] " + item.articulo }}
            </span>
          </template>
          <template v-slot:item.subPaso="{ item }">
            <span
              v-if="item.codigoSubpaso == null && item.nombreSubpaso == null"
            >
              N/A
            </span>
            <span
              v-else-if="
                item.codigoSubpaso == null && item.nombreSubpaso != null
              "
            >
              {{ item.nombreSubpaso }}
            </span>
            <span
              v-else-if="
                item.codigoSubpaso != null && item.nombreSubpaso == null
              "
            >
              {{ "[" + item.codigoArticuloProducir + "]" }}
            </span>
            <span
              v-else-if="
                item.codigoSubpaso != null && item.nombreSubpaso != null
              "
            >
              {{
                "[" + item.codigoArticuloProducir + "] " + item.nombreSubpaso
              }}
            </span>
          </template>
          <template v-slot:item.asignacion="{ item }">
            <span v-if="item.operadores == null || item.operadores.length < 1">N/A</span>
            <ul v-else>
              <li v-for="(oper, x) in item.operadores" :key="x">
                <span>{{ oper.nombre }}</span>
              </li>
            </ul>
          </template>
          <template v-slot:item.acciones="{ item }">
            <v-tooltip bottom color="black" class="white--text">
              <template v-slot:activator="{ on }">
                <v-icon
                  v-on="on"
                  small
                  class="mr-2"
                  @click="editOperador(item)"
                >
                  mdi-account-multiple-plus
                </v-icon>
              </template>
              <span class="white--text">Agregar persona</span>
            </v-tooltip>
            <v-tooltip bottom color="black" class="white--text">
              <template v-slot:activator="{ on }">
                <v-icon
                  v-on="on"
                  small
                  class="mr-2"
                  @click="EliminarAsignaciones(item)"
                >
                  delete
                </v-icon>
              </template>
              <span class="white--text">Eliminar</span>
            </v-tooltip>
          </template>
        </v-data-table>
      </v-col>
    </v-row>

    <!-- Operadores -->
    <v-dialog v-model="dialogOperadores" persistent max-width="500">
      <v-card>
        <v-card-title class="text-h5">
          <span class="text-h5">Operadores</span>
        </v-card-title>
        <v-card-text>
          <v-autocomplete
            v-model="idPersona"
            :items="operadores"
            item-value="idPersona"
            :item-text="nombrePersona"
            label="Operador"
            clearable
            small-chips
            multiple
            :item-disabled="item => item.idPersona === -1"
          >
          </v-autocomplete>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="cancelar()" :disabled="saveData" :loading="saveData">
            CANCELAR
          </v-btn>
          <v-btn color="red darken-1" text @click="saveOperadoresDisponibles()" :disabled="saveData" :loading="saveData">
            GUARDAR
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="dialogDelete" persistent max-width="500">
      <v-card>
        <v-card-text
          ><p class="text-h5 pt-4 font-weight-medium text--primary">
            ¿Está seguro que desea eliminar todas las asignaciones de esta
            operación?
          </p>
          <p class="subtitle-1 text--primary">
            Esta acción no se puede revertir y será permanente.
          </p></v-card-text
        >
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary darken-1" text @click="cancelar()" :disabled="saveData" :loading="saveData">
            CANCELAR
          </v-btn>
          <v-btn color="red darken-1" text @click="eliminarConfirmacion()" :disabled="saveData" :loading="saveData">
            ACEPTAR
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import axios from "axios";
export default {
  data: () => ({
    dialogOperadores: false,
    dialogDelete: false,
    loadTable: false,
    saveData: false,
    operadores: [],
    headers: [
      {
        text: "OT",
        align: "center",
        sortable: false,
        value: "clave",
      },
      {
        text: "Cliente",
        align: "center",
        sortable: false,
        value: "cliente",
      },
      {
        text: "Articulo Producir",
        align: "center",
        sortable: false,
        value: "articuloProducir",
      },
      {
        text: "Articulo Fase",
        align: "center",
        sortable: false,
        value: "articuloFase",
      },
      {
        text: "SubPaso",
        align: "center",
        sortable: false,
        value: "subPaso",
      },
      {
        text: "Asignacion",
        align: "center",
        sortable: false,
        value: "asignacion",
      },
      {
        text: "Acciones",
        align: "center",
        sortable: false,
        value: "acciones",
      },
    ],
    desserts: [],
    areas: [],
    claves: [],
    idArea: null,
    idTrabajoOrden: null,
    idPersona: [],
    editedIndex: -1,
    idOperacion: null,
    idPersonaParameter: -1,
    
  }),
  mounted() {
    this.getAreas();
    this.getClaves();
    this.getOperadores();
  },
  computed: {
    formTitle() {
      return this.editedIndex === -1 ? "New Item" : "Agregar operador";
    },
  },
  methods: {
    nombrePersona: ({ nombre, paterno, materno }) =>
      `${nombre ? `${nombre} ` : ""}${paterno ? `${paterno} ` : ""}${
        materno ? `${materno} ` : ""
      }`.trim(),
    editOperador(item) {
      this.editedIndex = this.desserts.indexOf(item);
      this.idPersona = this.desserts[this.editedIndex].operadores.map(
        (x) => x.idPersona
      );
      this.dialogOperadores = true;
    },
    personaNombre(idPersona) {
      var persona = this.operadores.find((a) => a.idPersona == idPersona);
      return {
        idPersona: persona.idPersona,
        nombre: persona.nombre + " " + persona.paterno + " " + persona.materno,
      };
    },
    saveOperadoresDisponibles() {
      let obj = new Object();
      obj.idOperacion = this.desserts[this.editedIndex].idOperacion;
      obj.idsPersonas = this.idPersona;
      this.saveData = true;

      axios
        .post("/ComandoYControl/CrearActualizarAsignacionOperacion", obj)
        .then(() => {
          this.dialogOperadores = false;
          this.desserts = [];
          this.operacionesDisponibles();
          this.saveData = false;
        })
        .catch((error) => {
          this.dialogOperadores = false;
          this.saveData = false;
          console.log(error);
        });
    },
    operacionesDisponibles() {
      if (this.idArea == null) return;

      this.loadTable = true;
      axios
        .get("/ComandoYControl/OperacionesDisponibles/" + this.idArea, {
          params: {
            idTrabajoOrden: this.idTrabajoOrden,
            idPersona: this.idPersonaParameter
          },
        })
        .then((response) => {
          this.loadTable = false;
          let obj1 = [];
          var obj = response.data.map(function(element) {
            element.detalles.map(function(ele) {
              ele.operaciones.map(function(sd) {
                obj1.push(sd);
              });
            });
          });
          this.desserts = obj1;
        })
        .catch((error) => {
          console.log(error);
          this.loadTable = false;
        });
    },
    EliminarAsignaciones(item) {
      this.dialogDelete = true;
      this.idOperacion = item.idOperacion;
    },
    eliminarConfirmacion() {
      if (this.idOperacion == null) return;
      this.saveData = true;
      axios
        .put(
          "/ComandoYControl/EliminarAsignacionesOperacion/" + this.idOperacion
        )
        .then(() => {
          this.operacionesDisponibles();
          this.dialogDelete = false;
          this.saveData = false;
        })
        .catch((error) => {
          this.saveData = false;
          console.log(error);
        });
    },
    cancelar(){
      this.dialogOperadores = false;
      this.dialogDelete = false;
      this.editedIndex = -1;
      this.idPersona = [];
    },
    getAreas() {
      axios
        .get("/Areas")
        .then((response) => {
          this.areas = response.data.filter(
            (a) => a.idArea > 1 && a.condicion == true
          );
          //console.log(this.areas);
        })
        .catch((error) => {
          console.log(error);
        });
    },
    getClaves() {
      axios
        .get("/TrabajoOrdenes/TrabajoOrdenHeaders")
        .then((response) => {
          this.claves = response.data;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    getOperadores() {
      axios
        .get("/Personas/Operadores")
        .then((response) => {
          this.operadores = response.data;
          
          this.operadores.unshift({
        "idPersona": -1,
        "idPuesto": -1,
        "idTurno": -1,
        "idDepartamento": null,
        "codigo": "N/A",
        "puesto": "N/A",
        "turno": "N/A",
        "departamentoCodigo": null,
        "departamentoNombre": null,
        "nombre": "SIN ASIGNAR",
        "paterno": "",
        "materno": "",
        "pin": "-1",
        "rfc": null,
        "incorporacion": null,
        "costoHora": -1,
        "habilidades": [],
        "maquinas": [],
        "ultimaFechaIncorporacion": null,
        "condicion": true,
        "areas": null,
        "telefono": ",",
        "email": null,
        "estado": true,
        "mantenimiento": false,
        "idsSubdepartamentos": [],
        "subdepartamentos": [],
        "imagen": null,
        "ciclo": []
    });
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },
};
</script>
