<template>
	<v-col cols="12">
		<v-row>
			<v-col cols="4" md="4" sm="4" class="align-self-end">
				<datePicker
					v-model="inicio"
					format="YYYY-MM-DD"
					:maxDate="fin"
					label="Desde (Obligatorio)"
					clearable
					maxToday
					@input="
						getMaquinas();
						resetMaquinas();
					"
					:disabled="loading"
				></datePicker>
			</v-col>
			<v-col cols="4" md="4" sm="4" class="align-self-end">
				<datePicker
					v-model="fin"
					format="YYYY-MM-DD"
					:minDate="inicio"
					label="Hasta (Obligatorio)"
					clearable
					maxToday
					@input="
						getMaquinas();
						resetMaquinas();
					"
					:disabled="loading"
				></datePicker>
			</v-col>
			<v-col cols="4" md="4" sm="4">
				<v-autocomplete
					v-model="idsMaquinas"
					label="Máquina(s) (Obligatorio)"
					:items="maquinas"
					item-value="idMaquinaIns"
					:item-text="nombreMaquina"
					hide-details
					multiple
					clearable
					chips
					deletable-chips
					ref="maquinas"
					@change="realizarPeticionDebounce"
					:loading="loadingMaquina"
					:disabled="inicio == null || fin == null || loading"
				></v-autocomplete>
			</v-col>
			<v-col cols="4" md="4" sm="4" class="align-self-end">
				<v-autocomplete
					v-model="idTurno"
					:items="turnos"
					item-text="nombre"
					item-value="idTurno"
					label="Turno (Opcional)"
					clearable
					hide-details
					@change="realizarPeticionDebounce"
					:disabled="idsMaquinas.length == 0 || loading"
				></v-autocomplete>
			</v-col>
			<v-col cols="12" class="pb-0">
				<download-btn
					text
					color="primary"
					className="my-0"
					@click="descargarReporte()"
					:disabled="series.length == 0 || idsMaquinas.length == 0"
					:retryTime="20"
					label="Descargar Reporte de<br>Rendimiento por Intervalo"
				/>
			</v-col>
			<v-col cols="12" md="12" sm="12">
				<v-progress-linear
					v-if="loading"
					indeterminate
					color="primary"
				></v-progress-linear>
				<apexchart
					ref="chart"
					type="bar"
					height="500"
					:options="chartOptions"
					:series="series"
				></apexchart>
			</v-col>
		</v-row>
	</v-col>
</template>

<script>
import axios from "axios";
import DownloadBtn from "../../../DownloadBtn.vue";
import { debounce } from "debounce";

export default {
	components: { DownloadBtn },
	data: () => ({
		series: [],
		chartOptions: {
			chart: {
				type: "bar",
				height: 350,
				stacked: true,
				locales: [
					{
						name: "es",
						options: {
							months: [
								"Enero",
								"Febrero",
								"Marzo",
								"Abril",
								"Mayo",
								"Junio",
								"Julio",
								"Agosto",
								"Septiembre",
								"Octubre",
								"Noviembre",
								"Diciembre",
							],
							shortMonths: [
								"Ene",
								"Feb",
								"Mar",
								"Abr",
								"May",
								"Jun",
								"Jul",
								"Ago",
								"Sep",
								"Oct",
								"Nov",
								"Dic",
							],
							days: [
								"Domingo",
								"Lunes",
								"Martes",
								"Miércoles",
								"Jueves",
								"Viernes",
								"Sábado",
							],
							shortDays: [
								"Dom",
								"Lun",
								"Mar",
								"Mie",
								"Jue",
								"Vie",
								"Sab",
							],
							toolbar: {
								exportToSVG: "Descargar SVG",
								exportToPNG: "Descargar PNG",
								exportToCSV: "Descargar CSV",
								menu: "Menu",
								selection: "Seleccionar",
								selectionZoom: "Seleccionar Zoom",
								zoomIn: "Aumentar",
								zoomOut: "Disminuir",
								pan: "Navegación",
								reset: "Reiniciar Zoom",
							},
						},
					},
				],
				defaultLocale: "es",
			},
			plotOptions: {
				bar: {
					horizontal: true,
				},
			},
			stroke: {
				width: 1,
				colors: ["#fff"],
			},
			xaxis: {
				categories: [],
				labels: {
					formatter: function (val) {
						function zeroPrefix(num, digit) {
							if (digit <= 0) return "0";
							if (num > Math.pow(10, digit - 1)) return `${num}`;

							var zero = "";
							for (var i = 0; i < digit; i++) {
								zero += "0";
							}
							return (zero + num).slice(-digit);
						}

						var horas = Math.trunc(val / 60);
						var minutos = val - horas * 60;

						return (
							(horas <= 9 ? zeroPrefix(horas, 2) : horas) +
							":" +
							zeroPrefix(minutos, 2)
						);
					},
				},
			},
			yaxis: {
				title: {
					text: undefined,
				},
			},
			tooltip: {
				y: {
					formatter: function (val) {
						function zeroPrefix(num, digit) {
							if (digit <= 0) return "0";
							if (num > Math.pow(10, digit - 1)) return `${num}`;

							var zero = "";
							for (var i = 0; i < digit; i++) {
								zero += "0";
							}
							return (zero + num).slice(-digit);
						}

						var horas = Math.trunc(val / 60);
						var minutos = val - horas * 60;

						return (
							(horas <= 9 ? zeroPrefix(horas, 2) : horas) +
							":" +
							zeroPrefix(minutos, 2)
						);
					},
				},
			},
			fill: {
				opacity: 1,
			},
			legend: {
				position: "top",
				horizontalAlign: "left",
				offsetX: 40,
			},
			dataLabels: {
				enabled: true,
				formatter: function (val, opts) {
					function zeroPrefix(num, digit) {
						if (digit <= 0) return "0";
						if (num > Math.pow(10, digit - 1)) return `${num}`;
						var zero = "";
						for (var i = 0; i < digit; i++) {
							zero += "0";
						}
						return (zero + num).slice(-digit);
					}

					var horas = Math.trunc(val / 60);
					var minutos = val - horas * 60;

					return (
						(horas <= 9 ? zeroPrefix(horas, 2) : horas) +
						":" +
						zeroPrefix(minutos, 2)
					);
				},
				style: {
					colors: ["#fff", "#fff"],
				},
			},
		},
		defaultChartOptions: {
			chart: {
				type: "bar",
				height: 350,
				stacked: true,
				locales: [
					{
						name: "es",
						options: {
							months: [
								"Enero",
								"Febrero",
								"Marzo",
								"Abril",
								"Mayo",
								"Junio",
								"Julio",
								"Agosto",
								"Septiembre",
								"Octubre",
								"Noviembre",
								"Diciembre",
							],
							shortMonths: [
								"Ene",
								"Feb",
								"Mar",
								"Abr",
								"May",
								"Jun",
								"Jul",
								"Ago",
								"Sep",
								"Oct",
								"Nov",
								"Dic",
							],
							days: [
								"Domingo",
								"Lunes",
								"Martes",
								"Miércoles",
								"Jueves",
								"Viernes",
								"Sábado",
							],
							shortDays: [
								"Dom",
								"Lun",
								"Mar",
								"Mie",
								"Jue",
								"Vie",
								"Sab",
							],
							toolbar: {
								exportToSVG: "Descargar SVG",
								exportToPNG: "Descargar PNG",
								exportToCSV: "Descargar CSV",
								menu: "Menu",
								selection: "Seleccionar",
								selectionZoom: "Seleccionar Zoom",
								zoomIn: "Aumentar",
								zoomOut: "Disminuir",
								pan: "Navegación",
								reset: "Reiniciar Zoom",
							},
						},
					},
				],
				defaultLocale: "es",
			},
			plotOptions: {
				bar: {
					horizontal: true,
				},
			},
			stroke: {
				width: 1,
				colors: ["#fff"],
			},
			xaxis: {
				categories: [],
				labels: {
					formatter: function (val) {
						function zeroPrefix(num, digit) {
							if (digit <= 0) return "0";
							if (num > Math.pow(10, digit - 1)) return `${num}`;

							var zero = "";
							for (var i = 0; i < digit; i++) {
								zero += "0";
							}
							return (zero + num).slice(-digit);
						}

						var horas = Math.trunc(val / 60);
						var minutos = val - horas * 60;

						return (
							(horas <= 9 ? zeroPrefix(horas, 2) : horas) +
							":" +
							zeroPrefix(minutos, 2)
						);
					},
				},
			},
			yaxis: {
				title: {
					text: undefined,
				},
			},
			tooltip: {
				y: {
					formatter: function (val) {
						function zeroPrefix(num, digit) {
							if (digit <= 0) return "0";
							if (num > Math.pow(10, digit - 1)) return `${num}`;

							var zero = "";
							for (var i = 0; i < digit; i++) {
								zero += "0";
							}
							return (zero + num).slice(-digit);
						}

						var horas = Math.trunc(val / 60);
						var minutos = val - horas * 60;

						return (
							(horas <= 9 ? zeroPrefix(horas, 2) : horas) +
							":" +
							zeroPrefix(minutos, 2)
						);
					},
				},
			},
			fill: {
				opacity: 1,
			},
			legend: {
				position: "top",
				horizontalAlign: "left",
				offsetX: 40,
			},
			dataLabels: {
				enabled: true,
				formatter: function (val, opts) {
					function zeroPrefix(num, digit) {
						if (digit <= 0) return "0";
						if (num > Math.pow(10, digit - 1)) return `${num}`;
						var zero = "";
						for (var i = 0; i < digit; i++) {
							zero += "0";
						}
						return (zero + num).slice(-digit);
					}

					var horas = Math.trunc(val / 60);
					var minutos = val - horas * 60;

					return (
						(horas <= 9 ? zeroPrefix(horas, 2) : horas) +
						":" +
						zeroPrefix(minutos, 2)
					);
				},
				style: {
					colors: ["#fff", "#fff"],
				},
			},
		},
		loadingMaquina: false,
		maquinas: [],
		idsMaquinas: [],
		inicio: null,
		fin: null,
		loading: false,
		areas: [],
		idArea: null,
		turnos: [],
		idTurno: null,
	}),
	computed: {
		fetchUrl() {
			var url = "/Reportes/MaquinaInstanciaRendimientoIntervalo?";

			if (this.inicio != null) url += "Inicio=" + this.inicio;
			else return null;
			if (this.fin != null) url += "&Fin=" + this.fin;
			else return null;

			if (
				this.$utils.isValid(this.idsMaquinas) &&
				this.idsMaquinas.length > 0
			) {
				this.idsMaquinas.forEach(
					(x) => (url += `&idsMaquinaIns=${x || 0}`)
				);
			} else return null;

			return url;
		},
	},
	mounted() {
		this.getTurnos();
	},
	methods: {
		realizarPeticionDebounce: debounce(function () {
			this.$refs.maquinas.blur();
			this.getEstadisticas();
		}, 1000),
		nombreMaquina: ({ nombre, marca, modelo }) => {
			let machineName = "";
			machineName += nombre ? `[${nombre}] - ` : "";
			machineName += marca ? `${marca} ` : "";
			machineName += modelo ? modelo : "";
			return machineName.trim();
		},
		resetMaquinas() {
			this.idsMaquinas = [];
			this.idTurno = null;
		},
		descargarReporte() {
			//ENDPOINT para descargar reporte por Dia
			var url = "/Reportes/XlsxMaquinaInstanciaRendimientoIntervalo?";

			if (this.inicio != null) url += "Inicio=" + this.inicio;
			else return;
			if (this.fin != null) url += "&Fin=" + this.fin;
			else return;

			url += this.idTurno ? `&idTurno=${this.idTurno}` : '';

			if (
				this.$utils.isValid(this.idsMaquinas) &&
				this.idsMaquinas.length > 0
			) {
				this.idsMaquinas.forEach(
					(x) => (url += `&idsMaquinaIns=${x || 0}`)
				);
			}

			this.$utils.axios.downloadFile(
				url,
				"GET",
				"reporte-rendimiento-maquinas-rango-" +
					this.inicio +
					"-" +
					this.fin +
					".xlsx",
				"application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
			);
		},
		getTurnos() {
			axios
				.get("/Turnos")
				.then((response) => {
					this.turnos = response.data;
				})
				.catch((error) => {
					console.log(error);
				});
		},
		getMaquinas() {
			if (this.inicio == null || this.fin == null){
				this.series = [];
				this.chartOptions = JSON.parse(
					JSON.stringify(this.defaultChartOptions)
				);	
				return;
			}
			this.idsMaquinas = [];
			this.maquinas = [];
			this.series = [];
			this.chartOptions = JSON.parse(
				JSON.stringify(this.defaultChartOptions)
			);
			this.loadingMaquina = true;
			axios
				.get("/MaquinaInstancias/MaquinaInstanciaRendimiento", {
					params: {
						fechaInicio: this.inicio,
						fechaFin: this.fin,
					},
				})
				.then((response) => {
					this.maquinas = response.data;
					this.loadingMaquina = false;
				})
				.catch((error) => {
					this.loadingMaquina = false;
					console.log(error);
				});
		},
		getEstadisticas() {
			var url = this.fetchUrl;
			if (url == null) {
				this.series = [];
				this.chartOptions = JSON.parse(
					JSON.stringify(this.defaultChartOptions)
				);
				return;
			}
			this.loading = true;
			axios
				.get(url, {
					params: {
						idTurno: this.idTurno,
					},
				})
				.then((response) => {
					this.loading = false;
					this.series = response.data.series;
					var options = JSON.parse(
						JSON.stringify(this.defaultChartOptions)
					);
					options.xaxis.categories = response.data.instancias;
					this.chartOptions = options;
				})
				.catch((error) => {
					this.loading = false;
					console.log(error);
				});
		},
	},
};
</script>