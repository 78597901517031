<template>
  <div>
    <v-dialog v-model="dialogTiempo" persistent max-width="600px">
      <v-card>
        <v-card-title
          ><span class="text-h5">Alerta de Inactividad</span></v-card-title
        >
        <v-card-text class="mt-3">
          <v-autocomplete
            v-model="tiempoSinActividad"
            :items="bloques"
            label="Tiempo estimado"
            item-text="text"
            item-value="value"
            hide-details
            dense
          ></v-autocomplete>
          <p v-if="errorTime" style="color: #D50000">Selecciona un item</p>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="closeDialosTime">
            Cerrar
          </v-btn>
          <v-btn color="red darken-1" text @click="EditTimeClose">
            Guardar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="dialogMaquinaInstancia" persistent max-width="600px">
      <v-card>
        <v-card-title
          ><span class="text-h5">Configurar Alerta</span></v-card-title
        >
        <v-card-text class="mt-3">
          <v-row>
            <v-col cols="12" sm="12" md="12" xl="12">
              <v-autocomplete
                v-model="tiempoSinActividad"
                label="Agrupador Tiempo"
                :items="bloques"
                item-text="text"
                item-value="value"
                hide-details
                dense
                clearable
              ></v-autocomplete>
              <p
                v-if="erroresMaquinasIns.tiempoSinActividad"
                style="color: #D50000"
                :key="erroresMaquinasIns.tiempoSinActividad"
              >
                Selecciona un tiempo
              </p>
            </v-col>
            <v-col cols="12" sm="12" md="12" xl="12">
              <v-select
                v-model="operacion"
                label="Operación"
                :items="itemsOperacion"
                item-text="text"
                item-value="value"
                hide-details
                dense
                clearable
              ></v-select>
              <p v-if="erroresMaquinasIns.operacion" style="color: #D50000">
                Selecciona un tipo de Operación
              </p>
            </v-col>
            <v-col
              :cols="
                comparador == null
                  ? 6
                  : comparador == 7 || comparador == 8
                  ? 4
                  : 6
              "
              sm="12"
              :md="
                comparador == null
                  ? 6
                  : comparador == 7 || comparador == 8
                  ? 4
                  : 6
              "
              xl="12"
            >
              <v-select
                v-model="comparador"
                label="Comparador"
                :items="itemsComparacion"
                item-text="text"
                item-value="value"
                hide-details
                dense
                clearable
              ></v-select>
              <p v-if="erroresMaquinasIns.comparador" style="color: #D50000">
                Selecciona un comparador
              </p>
            </v-col>
            <v-col
              :cols="
                comparador == null
                  ? 6
                  : comparador == 7 || comparador == 8
                  ? 4
                  : 6
              "
              sm="12"
              :md="
                comparador == null
                  ? 6
                  : comparador == 7 || comparador == 8
                  ? 4
                  : 6
              "
              xl="12"
            >
              <v-text-field
                v-model="valor"
                label="Valor"
                hide-details
                dense
              ></v-text-field>
              <p v-if="erroresMaquinasIns.valor" style="color: #D50000">
                Ingrese un valor
              </p>
            </v-col>
            <v-col
              v-if="comparador == 7 || comparador == 8"
              cols="4"
              sm="4"
              md="4"
              xl="4"
            >
              <v-text-field
                v-model="valor2"
                label="Valor 2"
                hide-details
                dense
              ></v-text-field>
              <p v-if="erroresMaquinasIns.valor2" style="color: #D50000">
                Ingrese un valor
              </p>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="CloseMaquinaInstancia">
            Cerrar
          </v-btn>
          <v-btn color="red darken-1" text @click="editMaquinaInstanciaClose">
            Guardar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-data-table
      :headers="headers"
      :items="itemsData"
      :loading="loadingTable"
      loading-text="Cargando... Espere, Por Favor."
    >
      <template v-slot:top>
        <v-toolbar flat>
          <v-dialog v-model="dialog" persistent max-width="800px">
            <template v-slot:activator="{ on, attrs }">
              <v-btn color="primary" dark class="mb-2" v-bind="attrs" v-on="on">
                Nueva alerta
              </v-btn>
            </template>
            <v-card>
              <v-card-title>
                <span class="text-h5">{{ formTitle }}</span>
              </v-card-title>

              <v-card-text>
                <v-container>
                  <v-row>
                    <v-col cols="4" sm="4" md="4">
                      <v-autocomplete
                        v-model="editedItem.idMaquinaIns"
                        :items="maquinasIns"
                        label="Máquina"
                        item-value="idMaquinaIns"
                        :item-text="maquinaLabel"
                        hide-details
                        dense
                        @change="getKeys()"
                      ></v-autocomplete>
                    </v-col>
                    <v-col cols="4" sm="4" md="4">
                      <v-autocomplete
                        v-model="editedItem.clave"
                        :items="keys"
                        label="Seleccione los Indicadores a Mostrar"
                        
            		  
									clearable
									hide-details
                        dense
                        :loading="loadingClaves"
                        :disabled="loadingClaves"
                      >
                        <template v-slot:item="data">
                          <v-checkbox
                            :label="data.item"
                            hide-details
                            dense
                            v-model="data.attrs.inputValue"
                          >
                          </v-checkbox>
                          <!-- <v-spacer></v-spacer>
                          <v-list-item-action @click.stop>
                            <v-btn
                              icon
                              color="grey"
                              @click.stop.prevent="editarClave(data.item)"
                            >
                              <v-icon>{{
                                editing !== item ? "mdi-pencil" : "mdi-check"
                              }}</v-icon>
                            </v-btn>
                          </v-list-item-action> -->
                        </template>
                      </v-autocomplete>
                    </v-col>
                    <v-col cols="4" sm="4" md="4">
                      <v-text-field
                        v-model="editedItem.unidad"
                        label="Unidad"
                        hide-details
                        dense
                      ></v-text-field>
                    </v-col>
                    <v-col cols="6" sm="6" md="6">
                      <v-autocomplete
                        v-model="editedItem.metodosNotificacion"
                        label="Métodos de Notificación"
                        :items="metodosNotificacion"
                        item-value="id"
                        item-text="value"
                        multiple
                        chips
                        small-chips
                        hide-details
                        dense
                      ></v-autocomplete>
                    </v-col>
                    <v-col cols="6" sm="6" md="6">
                      <v-autocomplete
                        v-model="editedItem.idsUsuarios"
                        :items="usuarios"
                        item-text="nombre"
                        item-value="idUsuario"
                        label="Usuario"
                        hide-details
                        clearable
                        multiple
                        chips
                        small-chips
                        dense
                      ></v-autocomplete>
                    </v-col>
                    <v-col cols="12" sm="12" md="12">
                      <v-divider></v-divider>
                      <span class="text-h6">Alerta de Inactividad</span>
                      <v-data-table
                        dense
                        :headers="headersTiempo"
                        :items="editedItem.tiempoInactividad"
                        :items-per-page="5"
                        :sort-by="['tiempoNoActividad']"
                        :sort-desc="false"
                        class="mt-3"
                        hide-default-footer
                      >
                        <template v-slot:[`item.tiempoNoActividad`]="{ item }">
                          <span v-if="item.tiempoNoActividad == null"></span>
                          <span v-else>{{
                            secondsToMinutes(item.tiempoNoActividad)
                          }}</span>
                        </template>
                        <template v-slot:[`item.actions`]="{ item }">
                          <v-row>
                            <v-col cols="6" md="6" sm="6" xl="6">
                              <v-tooltip
                                bottom
                                color="black"
                                class="white--text"
                              >
                                <template v-slot:activator="{ on }">
                                  <v-icon
                                    v-on="on"
                                    small
                                    @click="showDialogTime(item)"
                                  >
                                    mdi-pencil
                                  </v-icon>
                                </template>
                                <span class="white--text">Editar</span>
                              </v-tooltip>
                            </v-col>
                            <v-col cols="6" md="6" sm="6" xl="6">
                              <v-tooltip
                                bottom
                                color="black"
                                class="white--text"
                              >
                                <template v-slot:activator="{ on }">
                                  <v-icon
                                    v-on="on"
                                    small
                                    @click="DeleteTime(item)"
                                  >
                                    mdi-delete
                                  </v-icon>
                                </template>
                                <span class="white--text">Eliminar</span>
                              </v-tooltip>
                            </v-col>
                          </v-row>
                        </template>
                      </v-data-table>
                      <div class="text-center">
                        <v-btn
                          x-small
                          color="primary"
                          class="my-2"
                          outlined
                          @click="agregarTiempo"
                          >Agregar Alerta de Inactividad</v-btn
                        >
                      </div>
                    </v-col>
                    <v-col cols="12" sm="12" md="12">
                      <v-divider></v-divider>
                      <span class="text-h6">Alerta de Valor</span>
                      <v-data-table
                        dense
                        :headers="headersMaquinaInstancia"
                        :items="editedItem.alertaValor"
                        :items-per-page="5"
                        :sort-by="['agrupadorTiempo']"
                        :sort-desc="false"
                        class="mt-3"
                        hide-default-footer
                      >
                        <template v-slot:[`item.agrupadorTiempo`]="{ item }">
                          <span v-if="item.agrupadorTiempo != null">{{
                            secondsToMinutes(item.agrupadorTiempo)
                          }}</span>
                        </template>

                        <template v-slot:[`item.operacion`]="{ item }">
                          <span v-if="item.operacion == 1">Suma</span>
                          <span v-if="item.operacion == 2">Promedio</span>
                          <span v-if="item.operacion == 3">Maximo</span>
                          <span v-if="item.operacion == 4">Minimo</span>
                          <span v-if="item.operacion == 5">Conteo</span>
                        </template>

                        <template v-slot:[`item.validacion`]="{ item }">
                          <div
                            v-if="
                              item.valor === null &&
                                item.comparador === null &&
                                item.valor2 === null
                            "
                          >
                            <span>N/A</span>
                          </div>
                          <div v-else>
                            <span v-if="item.comparador != null">
                              <p v-if="item.comparador == 1">
                                {{ "El valor es < Menor a " + item.valor }}
                              </p>
                              <p v-if="item.comparador == 2">
                                {{
                                  "El valor es <= Menor o Igual a " + item.valor
                                }}
                              </p>
                              <p v-if="item.comparador == 3">
                                {{ "El valor es = Igual a " + item.valor }}
                              </p>
                              <p v-if="item.comparador == 4">
                                {{
                                  "El valor es != Diferente de " + item.valor
                                }}
                              </p>
                              <p v-if="item.comparador == 5">
                                {{ "El valor es > Mayor que " + item.valor }}
                              </p>
                              <p v-if="item.comparador == 6">
                                {{
                                  "El valor es >= Mayor o igual que " +
                                    item.valor
                                }}
                              </p>
                              <p v-if="item.comparador == 7">
                                {{
                                  "El valor se encuentra <> Entre " +
                                    item.valor +
                                    " y " +
                                    item.valor
                                }}
                              </p>
                              <p v-if="item.comparador == 8">
                                {{
                                  "El valor !<> No esta Entre " +
                                    item.valor +
                                    " y " +
                                    item.valor2
                                }}
                              </p>
                            </span>
                          </div>
                        </template>

                        <template v-slot:[`item.actions`]="{ item }">
                          <v-row>
                            <v-col cols="6" md="6" sm="6" xl="6">
                              <v-tooltip
                                bottom
                                color="black"
                                class="white--text"
                              >
                                <template v-slot:activator="{ on }">
                                  <v-icon
                                    v-on="on"
                                    small
                                    @click="showDialogMaquinaInstancia(item)"
                                  >
                                    mdi-pencil
                                  </v-icon>
                                </template>
                                <span class="white--text">Editar</span>
                              </v-tooltip>
                            </v-col>
                            <v-col cols="6" md="6" sm="6" xl="6">
                              <v-tooltip
                                bottom
                                color="black"
                                class="white--text"
                              >
                                <template v-slot:activator="{ on }">
                                  <v-icon
                                    v-on="on"
                                    small
                                    @click="DeleteMaquinaInstancia(item)"
                                  >
                                    mdi-delete
                                  </v-icon>
                                </template>
                                <span class="white--text">Eliminar</span>
                              </v-tooltip>
                            </v-col>
                          </v-row>
                        </template>
                      </v-data-table>
                      <div class="text-center">
                        <v-btn
                          x-small
                          color="primary"
                          class="my-2"
                          outlined
                          @click="agregarMaquinaInstancia"
                          >Agregar Alerta de Valor</v-btn
                        >
                      </div>
                    </v-col>
                  </v-row>
                  <v-row v-if="errores.length > 0">
                    <ul>
                      <li
                        class="red--text"
                        v-for="(error, idx) in errores"
                        :key="'error' + idx"
                      >
                        {{ error }}
                      </li>
                    </ul>
                  </v-row>
                </v-container>
              </v-card-text>

              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="blue darken-1" text @click="CancelarGrnal">
                  CANCELAR
                </v-btn>
                <v-btn color="red darken-1" text @click="save">
                  GUARDAR
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
          <v-dialog v-model="dialogDelete" persistent max-width="500px">
            <v-card>
              <v-card-title class="text-h6"
                >¿Está seguro que desea eliminar este registro?</v-card-title
              >
              <v-card-text
                >Esta acción no se puede revertir y será
                permanente.</v-card-text
              >
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="blue darken-1" text @click="closeDelete"
                  >CANCELAR</v-btn
                >
                <v-btn color="red darken-1" text @click="deleteItemConfirm"
                  >ACEPTAR</v-btn
                >
                <v-spacer></v-spacer>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-toolbar>
      </template>

      <template v-slot:[`item.maquina`]="{ item }">
        <span>{{
          "[" + item.nombre + "]" + " - " + item.marca + " " + item.modelo
        }}</span>
      </template>

      <template v-slot:[`item.idsUsuarios`]="{ item }">
        <ul>
          <li v-for="(idUsuario, x) in item.idsUsuarios" :key="x">
            <span>{{ usuariosNombre(idUsuario) }}</span>
          </li>
        </ul>
      </template>
      <template v-slot:[`item.tiempoInactividad`]="{ item }">
        <span v-if="item.tiempoInactividad.length == 0"
          >Sin alertas configuradas</span
        >
        <span v-else-if="item.tiempoInactividad.length == 1">{{
          item.tiempoInactividad.length + " Alerta Configuradas"
        }}</span>
        <span v-else>{{
          item.tiempoInactividad.length + " Alertas Configuradas"
        }}</span>
      </template>
      <template v-slot:[`item.unidad`]="{ item }">
        <span v-if="item.unidad == null">N/A</span>
        <span v-else>{{ item.unidad }}</span>
      </template>
      <template v-slot:[`item.metodosNotificacion`]="{ item }">
        <ul>
          <li v-for="(ie, x) in item.metodosNotificacion" :key="x">
            <span v-if="ie == 1">Notificación de ManagrX</span>
            <span v-if="ie == 2">WebPush</span>
            <span v-if="ie == 3">Correo Electronico</span>
            <span v-if="ie == 4">Telegram</span>
          </li>
        </ul>
      </template>

      <template v-slot:[`item.alertaValor`]="{ item }">
        <span v-if="item.alertaValor.length == 0"
          >Sin alertas configuradas</span
        >
        <span v-else-if="item.alertaValor.length == 1">{{
          item.alertaValor.length + " Alerta Configuradas"
        }}</span>
        <span v-else>{{
          item.alertaValor.length + " Alertas Configuradas"
        }}</span>
      </template>

      <template v-slot:[`item.actions`]="{ item }">
        <v-tooltip bottom color="black" class="white--text">
          <template v-slot:activator="{ on }">
            <v-icon v-on="on" small class="mr-2" @click="editItem(item)">
              edit
            </v-icon>
          </template>
          <span class="white--text">Editar</span>
        </v-tooltip>
        <v-tooltip bottom color="black" class="white--text">
          <template v-slot:activator="{ on }">
            <v-icon v-on="on" small @click="deleteItem(item)"> delete </v-icon>
          </template>
          <span class="white--text">Eliminar</span>
        </v-tooltip>
      </template>
      <template v-slot:no-data>
        <v-btn color="primary" @click="initialize">
          Refrescar
        </v-btn>
      </template>
    </v-data-table>
  </div>
</template>

<script>
import axios from "axios";
import moment from "moment";
export default {
  data() {
    return {
      keys: [],
      loadingTable: false,
      errorTime: false,
      dialogTiempo: false,
      dialogMaquinaInstancia: false,
      headers: [
        {
          text: "Máquina",
          value: "maquina",
          align: "center",
        },
        { text: "Clave", value: "clave", align: "center" },
        { text: "Unidad", value: "unidad", align: "center" },
        {
          text: "Metodos de Notificación",
          value: "metodosNotificacion",
          align: "center",
        },
        { text: "Usuarios a notificar", value: "idsUsuarios", align: "center" },
        {
          text: "Alerta de Inactividad",
          value: "tiempoInactividad",
          align: "center",
        },
        {
          text: "Alerta de Valor",
          value: "alertaValor",
          align: "center",
        },
        {
          text: "Acciones",
          value: "actions",
          sortable: false,
          align: "center",
        },
      ],
      headersTiempo: [
        {
          text: "Alerta de Inactividad",
          value: "tiempoNoActividad",
          sortable: false,
          align: "center",
        },
        {
          text: "Acciones",
          value: "actions",
          sortable: false,
          align: "center",
          width: "15%",
        },
      ],
      headersMaquinaInstancia: [
        {
          text: "Tiempo agrupado",
          value: "agrupadorTiempo",
          sortable: false,
          align: "center",
        },
        {
          text: "Operación",
          value: "operacion",
          sortable: false,
          align: "center",
        },
        /* {
          text: "Valor 1",
          value: "valor",
          sortable: false,
          align: "center",
        },
        {
          text: "Comparador",
          value: "comparador",
          sortable: false,
          align: "center",
        },
        {
          text: "Valor 2",
          value: "valor2",
          sortable: false,
          align: "center",
        }, */
        { text: "Condición", value: "validacion", align: "center" },
        {
          text: "Acciones",
          value: "actions",
          sortable: false,
          align: "center",
        },
      ],
      itemsData: [],
      maquinasIns: [],
      metodosNotificacion: [
        {
          id: 1,
          value: "Notificación de ManagrX",
        },
        {
          id: 2,
          value: "WebPush",
        },
        {
          id: 3,
          value: "Correo Electronico",
        },
        {
          id: 4,
          value: "Telegram",
        },
      ],
      itemsOperacion: [
        { value: 1, text: "Suma" },
        { value: 2, text: "Promedio" },
        { value: 3, text: "Maximo" },
        { value: 4, text: "Minimo" },
        { value: 5, text: "Conteo" },
      ],
      itemsComparacion: [
        { value: 1, text: "< Menor" },
        { value: 2, text: "<= Menor o Igual" },
        { value: 3, text: "= Igual" },
        { value: 4, text: "!=	Diferente" },
        { value: 5, text: "> Mayor que" },
        { value: 6, text: ">=	Menor igual que" },
        { value: 7, text: "<>	Entre" },
        { value: 8, text: "!<> No Entre" },
      ],
      segundosTotales: null,
      usuarios: [],
      errores: [],
      tiempoSinActividad: null,
      operacion: null,
      comparador: null,
      valor: null,
      valor2: null,
      dialog: false,
      dialogDelete: false,
      editedIndex: -1,
      editedItem: {
        idMaquinaInstanciaAlerta: null,
        idMaquinaIns: null,
        clave: null,
        unidad: null,
        metodosNotificacion: [],
        idsUsuarios: [],
        tiempoInactividad: [],
        alertaValor: [],
      },
      defaultItem: {
        idMaquinaInstanciaAlerta: null,
        idMaquinaIns: null,
        clave: null,
        unidad: null,
        metodosNotificacion: [],
        idsUsuarios: [],
        tiempoInactividad: [],
        alertaValor: [],
      },
      bloques: [
        {
          value: 1,
          text: "1 Minuto",
          disabled: false,
        },
        {
          value: 2,
          text: "2 Minutos",
          disabled: false,
        },
        {
          value: 5,
          text: "5 Minutos",
          disabled: false,
        },
        {
          value: 10,
          text: "10 Minutos",
          disabled: false,
        },
        {
          value: 30,
          text: "30 Minutos",
          disabled: false,
        },
        {
          value: 60,
          text: "1 Hora",
          disabled: false,
        },
      ],
      erroresMaquinasIns: [
        {
          tiempoSinActividad: false,
          operacion: false,
          comparador: false,
          valor: false,
          valor2: false,
        },
      ],
      newIndex: 0,
      newIndexMaquinaIns: 0,
      idMaquinaInstanciaAlertaPing: null,
      idMaquinaInstanciaAlertaValor: null,
      loadingClaves: false,
    };
  },
  mounted() {
    
  },
  computed: {
    formTitle() {
      return this.editedIndex === -1 ? "Nueva alerta" : "Editar Alerta";
    },
  },

  watch: {
    dialog(val) {
      val || this.close();
    },
    dialogDelete(val) {
      val || this.closeDelete();
    },
  },

  mounted() {
    this.initialize();
  },
  methods: {
    initialize(){
      this.GetData();
    this.getMaquinasIns();
    this.getUsuarios();
    },
    maquinaLabel: ({ nombre, marca, modelo }) =>
      `[${nombre}] - ${marca} ${modelo}`.trim(),
    usuariosNombre(id) {
      var users = this.usuarios;
      return users.find((element) => element.idUsuario == id) == undefined
        ? ""
        : users.find((element) => element.idUsuario == id).nombre;
    },
    agregarTiempo() {
      this.newIndex -= 1;
      const newObj = {
        idMaquinaInstanciaAlertaPing: this.newIndex,
        tiempoNoActividad: null,
      };
      this.editedItem.tiempoInactividad.push(newObj);
    },
    agregarMaquinaInstancia() {
      this.newIndexMaquinaIns -= 1;
      const newObj = {
        idMaquinaInstanciaAlertaValor: this.newIndexMaquinaIns,
        agrupadorTiempo: null,
        operacion: null,
        comparador: null,
        valor: null,
        valor2: null,
      };
      this.editedItem.alertaValor.push(newObj);
    },
    secondsToMinutes(item) {
      var operacion = item / 60;
      if (operacion >= 60) return "1 Hora";
      else if (operacion < 60)
        return operacion + (operacion > 1 ? " Minutos" : " Minuto");
    },
    secondsToMinute(item) {
      return item / 60;
    },
    GetData() {
      this.loadingTable = true;
      axios
        .get("/Alerta/ListarMAquinaInstanciaAlertas/")
        .then((response) => {
          this.itemsData = response.data;
          this.loadingTable = false;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    getMaquinasIns() {
      axios
        .get("/MaquinaInstancias")
        .then((response) => {
          this.maquinasIns = response.data;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    getUsuarios() {
      axios
        .get("/Usuarios")
        .then((response) => {
          this.usuarios = response.data;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    validacion() {
      this.errores = [];
      if (
        this.editedItem.idMaquinaIns == null ||
        this.editedItem.idMaquinaIns < 1
      ) {
        this.errores.push("Seleccione al menos una maquina");
      }

      if (this.editedItem.clave == null) {
        this.errores.push("Ingrese una clave");
      }

      if (this.editedItem.metodosNotificacion.length == 0) {
        this.errores.push("Seleccione al menos un metodo de notificación");
      }

      if (this.editedItem.idsUsuarios.length == 0) {
        this.errores.push("Seleccione al menos un usuario");
      }

      if (
        this.editedItem.tiempoInactividad.length <= 0 &&
        this.editedItem.alertaValor.length <= 0
      )
        this.errores.push(
          "Ingrese al menos una Alerta de Inactividad o una Alerta de Valor"
        );

      if (this.editedItem.tiempoInactividad.length > 0) {
        var obj = this.editedItem.tiempoInactividad;
        obj.forEach((element) => {
          if (element.tiempoNoActividad == null) {
            this.errores.push(
              "Ingresa campos no vacios o nulos dentro de Tiempo de Inactividad"
            );
            return;
          }
        });
      }

      if (this.editedItem.alertaValor.length > 0) {
        var obj = this.editedItem.alertaValor;
        obj.forEach((element) => {
          if (
            (element.agrupadorTiempo == null || element.agrupadorTiempo < 1) &&
            (element.operacion == null || element.operacion < 1) &&
            (element.comparador == null || element.comparador < 1) &&
            element.valor == null
          ) {
            this.errores.push(
              "Ingresa campos no vacios o nulos dentro de Alerta de Valor"
            );
            return;
          }
        });
      }

      return this.errores.length > 0 ? true : false;
    },
    CancelarGrnal() {
      this.dialog = false;
      this.editedItem = Object.assign({}, this.defaultItem);
      this.editedIndex = -1;
      this.newIndex = 0;
      this.newIndexMaquinaIns = 0;
      this.idMaquinaInstanciaAlertaPing = null;
      this.idMaquinaInstanciaAlertaValor = null;
      this.dialogTiempo = false;
      this.dialogMaquinaInstancia = false;
      this.tiempoSinActividad = null;
      this.operacion = null;
      this.comparador = null;
      this.valor = null;
      this.valor2 = null;
      this.dialog = false;
      this.dialogDelete = false;
      this.editedIndex = -1;
    },
    editItem(item) {
      this.editedIndex = this.itemsData.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialog = true;
    },
    deleteItem(item) {
      this.editedIndex = this.itemsData.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialogDelete = true;
    },
    deleteItemConfirm() {
      if (
        this.editedItem.idMaquinaInstanciaAlerta == null ||
        this.editedItem.idMaquinaInstanciaAlerta < 1
      )
        return;
      axios
        .put(
          "/Alerta/EliminarAlerta/" + this.editedItem.idMaquinaInstanciaAlerta
        )
        .then(() => {
          this.GetData();
        })
        .catch((error) => {
          Error.log(error);
        });
      this.closeDelete();
    },
    close() {
      this.dialog = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },
    closeDelete() {
      this.dialogDelete = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },
    showDialogTime(item) {
      this.dialogTiempo = true;
      this.idMaquinaInstanciaAlertaPing = item.idMaquinaInstanciaAlertaPing;
      if (item.tiempoNoActividad != null)
        this.tiempoSinActividad = this.bloques.find(
          (bloq) => bloq.value == item.tiempoNoActividad / 60
        ).value;
    },
    showDialogMaquinaInstancia(item) {
      this.dialogMaquinaInstancia = true;
      this.idMaquinaInstanciaAlertaValor = item.idMaquinaInstanciaAlertaValor;
      let index = this.editedItem.alertaValor.findIndex(
        (a) =>
          a.idMaquinaInstanciaAlertaValor == this.idMaquinaInstanciaAlertaValor
      );
      this.tiempoSinActividad =
        this.editedItem.alertaValor[index].agrupadorTiempo / 60;
      this.operacion = this.editedItem.alertaValor[index].operacion;
      this.comparador = this.editedItem.alertaValor[index].comparador;
      this.valor = this.editedItem.alertaValor[index].valor;
      this.valor2 = this.editedItem.alertaValor[index].valor2;
    },
    closeDialosTime() {
      this.dialogTiempo = false;
      this.errorTime = false;
      this.tiempoSinActividad = null;
    },
    EditTimeClose() {
      if (this.tiempoSinActividad == null) {
        this.errorTime = true;
        return;
      }
      this.dialogTiempo = false;
      this.errorTime = false;
      this.editedItem.tiempoInactividad.find(
        (x) =>
          x.idMaquinaInstanciaAlertaPing == this.idMaquinaInstanciaAlertaPing
      ).tiempoNoActividad = this.tiempoSinActividad * 60;
      this.tiempoSinActividad = null;
    },
    CloseMaquinaInstancia() {
      this.dialogMaquinaInstancia = false;
      this.tiempoSinActividad = null;
      this.operacion = null;
      this.comparador = null;
      this.valor = null;
      this.valor2 = null;
      this.erroresMaquinasIns.tiempoSinActividad = false;
      this.erroresMaquinasIns.operacion = false;
      this.erroresMaquinasIns.comparador = false;
      this.erroresMaquinasIns.valor = false;
      this.erroresMaquinasIns.valor2 = false;
    },
    editMaquinaInstanciaClose() {
      if (!this.validarMaquinaInstancia()) return;

      this.dialogMaquinaInstancia = false;
      let index = this.editedItem.alertaValor.findIndex(
        (a) =>
          a.idMaquinaInstanciaAlertaValor == this.idMaquinaInstanciaAlertaValor
      );
      this.editedItem.alertaValor[index].agrupadorTiempo =
        this.tiempoSinActividad * 60;
      this.editedItem.alertaValor[index].operacion = this.operacion;
      this.editedItem.alertaValor[index].comparador = this.comparador;
      this.editedItem.alertaValor[index].valor = parseInt(this.valor);
      this.editedItem.alertaValor[index].valor2 =
        this.comparador == 7 || this.comparador == 8
          ? parseInt(this.valor2)
          : null;
      this.tiempoSinActividad = null;

      this.erroresMaquinasIns.tiempoSinActividad = false;
      this.erroresMaquinasIns.operacion = false;
      this.erroresMaquinasIns.comparador = false;
      this.erroresMaquinasIns.valor = false;
      this.erroresMaquinasIns.valor2 = false;
    },
    validarMaquinaInstancia() {
      if (this.tiempoSinActividad == null || this.tiempoSinActividad < 1) {
        this.erroresMaquinasIns.tiempoSinActividad = true;
        return false;
      }

      if (this.operacion == null || this.operacion < 1) {
        this.erroresMaquinasIns.operacion = true;
        return false;
      }

      if (this.comparador == null < this.comparador < 1) {
        this.erroresMaquinasIns.comparador = true;
        return false;
      }

      if (this.valor == null || !this.$utils.numbers.isFloat(this.valor)) {
        this.erroresMaquinasIns.valor = true;
        return false;
      }

      if (
        (this.valor2 == null || !this.$utils.numbers.isFloat(this.valor) || this.valor2 < this.valor) &&
        (this.comparador == 7 || this.comparador == 8)
      ) {
        this.erroresMaquinasIns.valor2 = true;
        return false;
      }

      return true;
    },
    DeleteTime(item) {
      var pos = this.editedItem.tiempoInactividad.indexOf(item);
      this.editedItem.tiempoInactividad.splice(pos, 1);
    },
    DeleteMaquinaInstancia(item) {
      var pos = this.editedItem.alertaValor.indexOf(item);
      this.editedItem.alertaValor.splice(pos, 1);
    },
    save() {
      if (this.validacion()) return;

      var objeto = new Object();
      objeto.idMaquinaInstanciaAlerta =
        this.editedItem.idMaquinaInstanciaAlerta == null
          ? 0
          : this.editedItem.idMaquinaInstanciaAlerta;
      objeto.clave = this.editedItem.clave;
      objeto.idMaquinaIns = this.editedItem.idMaquinaIns;
      objeto.unidad = this.editedItem.unidad;
      objeto.metodosNotificacion = this.editedItem.metodosNotificacion;
      objeto.idsUsuarios = this.editedItem.idsUsuarios;
      objeto.tiempoInactividad = this.editedItem.tiempoInactividad;
      objeto.maquinaInstanciaAlertaValor = this.editedItem.alertaValor;
      //-1 crear
      axios
        .post("/Alerta/CrearActualizarAlertas", objeto)
        .then((response) => {
          this.GetData();
          this.saving = false;
          this.close();
        })
        .catch((error) => {
          this.saving = false;
          console.log(error);
        });

      this.close();
    },
    getKeys() {
      if (this.editedItem.idMaquinaIns == null) return;
      var fecha = moment()
        .add(-7, "days")
        .format("YYYY-MM-DD");
      this.loadingClaves = true;

      axios
        .get("/SensorDatos/Keys", {
          params: {
            inicio: fecha + " 00:00",
            fin: null,
            idMaquinaIns: this.editedItem.idMaquinaIns,
          },
        })
        .then((response) => {
          this.keys = response.data;
          this.loadingClaves = false;
          if (this.configSelected == "" || this.configSelected == null)
            this.clavesSeleccionadas = this.keys;
        })
        .catch((error) => {
          this.loadingClaves = false;
          console.log(error);
        });
    },
  },
};
</script>

<style>
.v-data-table-header th {
  white-space: nowrap;
}
</style>
