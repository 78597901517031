<template>
	<v-main>
		<password-recovery ref="pwdrecover" />
		<v-container fill-height fluid step="1">
			<v-layout align-center justify-center step="1">
				<v-flex xs12 sm8 md4>
					<v-card class="elevation-12">
						<v-card-title class="justify-center">
							<img src="/img/logotipo-300x115.png" />
						</v-card-title>

						<v-card-text>
							<v-progress-linear
								v-if="loading"
								indeterminate
							>	
							</v-progress-linear>
							<v-form v-if="!doubleFactor">
								<v-text-field
									ref="username"
									v-model="username"
									:disabled="loading"
									:rules="[
										() =>
											!!username ||
											'El campo es requerido',
									]"
									prepend-icon="mdi-account"
									label="Login"
									placeholder="Email"
									required
								/>
								<v-text-field
									ref="password"
									v-model="password"
									:disabled="loading"
									:rules="[
										() =>
											!!password ||
											'El campo es requerido',
									]"
									:append-icon="
										showPassword ? 'mdi-eye-off' : 'mdi-eye'
									"
									:type="showPassword ? 'text' : 'password'"
									prepend-icon="mdi-lock"
									label="Password"
									placeholder="*********"
									counter
									required
									@keydown.enter="login"
									@click:append="showPassword = !showPassword"
								/>
							</v-form>
							<div align="left">
								<a
									class="text-decoration-none primary--text"
									@click="mostrarDialog"
									>¿Olvidaste tu contraseña?</a
								>
							</div>

							<v-form v-if="doubleFactor">
								<v-text-field
									ref="code"
									v-model="code"
									:disabled="loading"
									:rules="[
										() =>
											!!password ||
											'El campo es requerido',
									]"
									:append-icon="
										showPassword ? 'mdi-eye-off' : 'mdi-eye'
									"
									:type="showPassword ? 'text' : 'password'"
									prepend-icon="mdi-lock"
									label="Token de Acceso"
									placeholder="*********"
									counter
									required
									@keydown.enter="loginDF"
									@click:append="showPassword = !showPassword"
								/>
							</v-form>
						</v-card-text>
						<v-divider class="mt-3" />
						<v-card-actions>
							<v-spacer />
							<v-btn
								v-if="doubleFactor"
								align-center
								justify-center
								color="red"
								@click="cancelDF"
								>Cancelar
							</v-btn>
							<v-btn
								v-if="doubleFactor"
								align-center
								:disabled="loading"
								justify-center
								color="green"
								@click="loginDF"
								>Enviar Token
							</v-btn>
							<v-btn
								v-if="!doubleFactor"
								align-center
								:disabled="loading"
								justify-center
								color="green"
								@click="login"
								>Login
							</v-btn>
						</v-card-actions>
						<v-snackbar
							v-model="snackbar"
							color="red"
							:top="true"
						>
							{{ errorMessages }}
							<v-btn dark text @click="snackbar = false">
								Cerrar
							</v-btn>
						</v-snackbar>
					</v-card>
				</v-flex>
			</v-layout>
		</v-container>
	</v-main>
</template>

<script>
import PasswordRecovery from "./PasswordRecovery.vue";

export default {
	components: { PasswordRecovery },
	data: function () {
		return {
			username: "",
			password: "",
			code: "",
			doubleFactor: false,
			errorMessages: "Información de login Incorrecta",
			snackbar: false,
			color: "general",
			showPassword: false,
			loading: false,
		};
	},

	// Sends action to Vuex that will log you in and redirect to the dash otherwise, error
	methods: {
		login: function () {
			this.loading = true;
			let username = this.username;
			let password = this.password;
			let me = this;
			
			var height = window.screen.availHeight;
			var width = window.screen.availWidth;

			this.$store
				.dispatch("login", { username, password,height,width })
				.then((response) => {
					this.loading = false;
					if (response == "auth_success") {
						const default_route = localStorage.getItem(
							"default_route"
						);
						if (default_route == null) {
							store.dispatch("logout");
							router.go();
						}
						if (default_route === '/dashboard') this.$router.push('/dashboard/reportes');
						else this.$router.push(default_route);
					}
					if (response == "double_factor_required") {
						me.doubleFactor = true;
					}
				})
				.catch((err) => {
					this.loading = false;
					console.log(err);
					this.snackbar = true;
				});
		},
		loginDF: function () {
			this.loading = true;
			let username = this.username;
			let code = this.code;
			let me = this;
			this.$store
				.dispatch("loginDF", { username, code })
				.then((response) => {
					this.loading = false;
					if (response == "auth_success") {
						this.$router.push("/dashboard/reportes");
					}
				})
				.catch((err) => {
					console.log(err);
					this.snackbar = true;
					this.loading = false;
				});
		},
		cancelDF: function () {
			this.doubleFactor = false;
			this.code = "";
			this.username = "";
			this.password = "";
		},
		mostrarDialog(){
			this.$refs.pwdrecover.mostrarDialog();
		}
	},
	metaInfo() {
		return {
			title: " ManagrX | Login ",
		};
	},
};
</script>
