<template>
  <v-container>
    <v-dialog v-model="watch" persistent max-width="500px" :key="watch">
      <v-card>
        <v-card-title>
          <span class="headline">Detalles de Desconocido</span>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col justify="center" cols="12" sm="12" md="12">
                <v-text-field v-model="watchedItem.nombre" readonly label="Nombre del Desconocido"></v-text-field>
              </v-col>
              <v-col justify="center" cols="12" sm="12" md="12">
                <v-text-field v-model="watchedItem.descripcion" readonly label="Descripción del Desconocido"></v-text-field>
              </v-col>
              <v-col justify="center" cols="12" sm="12" md="12">
                <v-autocomplete
                  v-model="watchedItem.tipoCosto"
                  label="Tipo de Costo"
                  :items="tipoCosto"
                  readonly
                ></v-autocomplete>
              </v-col>
              <template>
                <v-row>
                  <v-col justify="center" cols="12" sm="12" md="12">
                    <v-list>
                      <draggable tag="v-list-item-group" :list="watchedItem.campos" handle=".handle" :disabled="true">
                        <v-list-item
                          class="list-group-item"
                          v-for="element in watchedItem.campos"
                          :key="element.id"
                        >
                          <v-list-item-icon style="margin-right: 10px;">
                             <v-icon class="handle">mdi-reorder-horizontal</v-icon>
                          </v-list-item-icon>
                          <v-list-item-content style="padding:0px;">
                            <v-row>
                              <v-col justify="center" cols="6" sm="6" md="6">
                                <v-text-field
                                  v-model="element.nombre"
                                  label="Nombre del Campo"
                                  dense
                                  hide-details
                                  readonly
                                ></v-text-field>
                              </v-col>
                              <v-col justify="center" cols="6" sm="6" md="6">
                                <v-autocomplete
                                  v-model="element.tipoCosto"
                                  :items="tipos"
                                  label="Tipo de Campo"
                                  dense
                                  hide-details
                                  readonly
                                ></v-autocomplete>
                              </v-col>
                            </v-row>
                          </v-list-item-content>
                        </v-list-item>
                      </draggable>
                    </v-list>
                  </v-col>
                </v-row>
              </template>
            </v-row>
            <ul>
              <li class="red--text" v-for="ex in errores" :key="ex">{{ ex }}</li>
            </ul>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="watch=false; watchedItem = defaultItem;">Cerrar</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-data-table
      v-if="permisoVista('desconocidos','r')"
      :headers="headers"
      :items="desconocidos"
      :search="search"
      :loading="loading"
      loading-text="Cargando... Espere, Por Favor."
    >
      <template v-slot:top>
        <v-toolbar flat color="white">
          <v-dialog v-model="dialog" persistent max-width="500px" :key="dialog">
            <template v-slot:activator="{ on }">
              <v-btn color="primary" dark class="mb-2" v-on="on" v-if="permisoVista('desconocidos','c')">Nuevo Desconocido</v-btn>
            </template>
            <v-card>
              <v-card-title>
                <span class="headline">{{ formTitle }}</span>
              </v-card-title>
              <v-card-text>
                <v-container>
                  <v-row>
                    <v-col justify="center" cols="12" sm="12" md="12">
                      <v-text-field v-model="editedItem.nombre" label="Nombre del Desconocido"></v-text-field>
                    </v-col>
                    <v-col justify="center" cols="12" sm="12" md="12">
                      <v-text-field v-model="editedItem.descripcion" label="Descripción del Desconocido"></v-text-field>
                    </v-col>
                    <v-col justify="center" cols="12" sm="12" md="12">
                      <v-autocomplete
                        v-model="editedItem.tipoCosto"
                        label="Tipo de Costo"
                        :items="tipoCosto"
                      ></v-autocomplete>
                    </v-col>
                    <template>
                      <v-row>
                        <v-col justify="center" cols="12" sm="12" md="12">
                          <v-btn block small color="primary" dark @click="addColumn">Agregar Campo</v-btn>
                        </v-col>
                        <v-col justify="center" cols="12" sm="12" md="12">
                          <v-list>
                            <draggable tag="v-list-item-group" :list="editedItem.campos" handle=".handle">
                              <v-list-item
                                class="list-group-item"
                                v-for="(element, idx) in editedItem.campos"
                                :key="element.id"
                              >
                                <v-list-item-icon style="margin-right: 10px;">
                                   <v-icon class="handle">mdi-reorder-horizontal</v-icon>
                                </v-list-item-icon>
                                <v-list-item-content style="padding:0px;">
                                  <v-row>
                                    <v-col justify="center" cols="5" sm="5" md="5">
                                      <v-text-field
                                        v-model="element.nombre"
                                        label="Nombre del Campo"
                                        dense
                                        hide-details
                                      ></v-text-field>
                                    </v-col>
                                    <v-col justify="center" cols="5" sm="5" md="5">
                                      <v-autocomplete
                                        v-model="element.tipo"
                                        :items="tipos"
                                        label="Tipo de Campo"
                                        dense
                                        hide-details
                                      ></v-autocomplete>
                                    </v-col>
                                    <v-col justify="center" cols="2" sm="2" md="2" style="margin-top: auto; margin-bottom: auto;">
                                      <v-icon class="close" @click="removeAt(idx)">mdi-close-circle-outline</v-icon>
                                    </v-col>
                                  </v-row>
                                </v-list-item-content>
                              </v-list-item>
                            </draggable>
                          </v-list>
                        </v-col>
                      </v-row>
                    </template>
                  </v-row>
                  <ul>
                    <li class="red--text" v-for="ex in errores" :key="ex">{{ ex }}</li>
                  </ul>
                </v-container>
              </v-card-text>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="blue darken-1" text @click="dialog=false; errores=[]; resetCampos(); editedItem = defaultItem;">Cancelar</v-btn>
                <v-btn color="red darken-1" text @click="save()" :disabled="saving" :loading="saving">
                  Guardar
                  <template v-slot:loader>
                    <v-progress-circular
                      indeterminate
                      :width="2"
                      :size="24"
                      color="red"
                    ></v-progress-circular>
                  </template>
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
          <v-spacer></v-spacer>
          <v-text-field
            v-model="search"
            append-icon="search"
            label="Buscar"
            single-line
            hide-details
          ></v-text-field>
        </v-toolbar>
      </template>
      
      <template v-slot:item.action="{ item }">
        <v-tooltip bottom color="black" class="white--text">
          <template v-slot:activator="{ on }">
        <v-icon v-on="on"
          v-if="permisoVista('desconocidos','r')"
          small
          class="mr-2"
          @click="watchItem(item)"
        >
          mdi-eye
        </v-icon>
          </template>
          <span class="white--text">Ver</span>
        </v-tooltip>
      </template>
      <template v-slot:no-data>
        <v-btn color="primary" @click="initialize">Refrescar</v-btn>
      </template>
    </v-data-table>
    <v-snackbar
      v-model="alerta"
      top
      color="error"
    >
      Existe un Desconocido Activo con el mismo Nombre o Código
      <v-btn
        text
        @click="alerta = false"
      >
        Cerrar
      </v-btn>
    </v-snackbar>
  </v-container>
  
</template>

<script>
import axios from "axios";
import draggable from 'vuedraggable'

  export default {
    components: {
      draggable
    },
    data: () => ({
      saving: false,
      watch: false,
      dialog: false,
      alerta: false,
      search: '',
      headers: [
        {
          text: 'Desconocido',
          align: 'left',
          sortable: true,
          value: 'nombre',
        },
        { text: 'Acciones', value: 'action', sortable: false, width:'35%', align:'center' },
      ],
      loading: false,
      desconocidos: [],
      delItem: '',
      action: 0,
      errores: [],
      editedIndex: -1,
      tipos: [
        {
          text: "Texto",
          value: 1,
        },
        {
          text: "Número",
          value: 2,
        },
        {
          text: "Fecha y Hora",
          value: 3,
        },
      ],
      tipoCosto: [
        {
          text: "Consumo",
          value: true,
        },
        {
          text: "Hora",
          value: false,
        }
      ],
      editedItem: {
        nombre: '',
        descripcion: '',
        tipoCosto: true,
        nCampos: 0,
        campos: [],
      },
      watchedItem: {
        nombre: '',
        descripcion: '',
        tipoCosto: true,
        campos: [],
      },
      defaultItem: {
        nombre: '',
        descripcion: '',
        tipoCosto: true,
        nCampos: 0,
        campos: [],
      },
    }),

    computed: {
      formTitle () {
        return this.editedIndex === -1 ? 'Nuevo Desconocido' : 'Editar Desconocido'
      },
      campos (){
        return this.editedItem.campos;
      }
    },

    watch: {
      dialog (val) {
        val || this.close()
      },
      campos(){
        let i = 0;
        this.editedItem.campos.forEach(function(element){
          i++;
          element.orden = i;
        });
      }
    },

    created () {
      this.initialize()
    },

    methods: {
      addColumn(){
        this.editedItem.nCampos++;
        this.editedItem.campos.push({ nombre: "", id:this.editedItem.nCampos, tipo:-1 });
      },
      removeAt(idx) {
        this.editedItem.campos.splice(idx, 1);
      },
      resetCampos(){
        while(this.editedItem.campos.length > 0){
          this.removeAt(0);
        }
      },
      watchItem(item){

      },
      initialize () {
        let me = this;
        me.loading = true;
        axios
        .get("/Desconocidos/Desconocidos")
        .then(response =>  {
          me.desconocidos = response.data;
          me.loading = false;
        })
        .catch(error => {
          console.log(error);
        });
      },
      watchItem(item){
        this.watchedItem = Object.assign({}, item);
        this.watch = true;
      },
      close () {
        this.action = 0,
        this.dialog = false
        setTimeout(() => {
          this.editedItem = Object.assign({}, this.defaultItem)
          this.editedIndex = -1
        }, 300)
      },
      validate(){
        this.errores =  [];
        if (
          this.editedItem.nombre.length < 5 ||
          this.editedItem.nombre.length > 50
        )
        {
          this.errores.push(
            "El nombre debe tener más de 4 caracteres y menos de 50."
          );
          return false;
        }
        return true;
      },
      save () {
        this.action = 0;
        if(this.validate()){
          let me = this;
          me.saving = true;
          let cmps = me.campos.map(function(element) { 
            delete element.id; 
            return element; 
          });

          axios
            .post("/Desconocidos/CrearDesconocido", {
              Nombre: me.editedItem.nombre,
              Descripcion: me.editedItem.descripcion,
              TipoCosto: me.editedItem.tipoCosto,
              Campos: cmps,
            })
            .then(response =>  {
              me.saving = false;
              me.close();
              me.initialize();
            })
            .catch(error => {
              me.saving = false;
              if (error.response) {
                if(error.response.status == 409){
                  me.alerta = true;
                  me.errores.push("Existe un Desconocido Activo con el Mismo Nombre o Código");
                }
              }
            });
        }
      },
    },
  }
</script>