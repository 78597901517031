<template>
	<v-container>
		<v-dialog v-model="dialogAddControl" persistent max-width="50%">
			<v-card>
				<v-card-title>{{ tituloDialogAdd }}</v-card-title>
				<v-card-text>
					<v-autocomplete
						v-model="editItem.idAlmacen"
						:items="almacenes"
						item-text="nombre"
						item-value="idAlmacen"
						label="Almacén"
					></v-autocomplete>
					<v-text-field
						v-model="editItem.maximo"
						label="Máximo"
						:error-messages="erroresMaximo"
					></v-text-field>
					<v-text-field
						v-model="editItem.puntoReorden"
						label="Punto Reorden"
						:error-messages="erroresPR"
					></v-text-field>
					<v-text-field
						v-model="editItem.minimo"
						label="Mínimo"
						:error-messages="erroresMinimo"
					></v-text-field>
					<v-combobox
						v-model="editItem.correo"
						chips
						clearable
						label="Correo(s) a notificar"
						multiple
						append-icon=""
						:delimiters="[',']"
						hint="Introduzca uno o más correos seguidos de una Coma, Enter o Tabulador."
					>
						<template
							v-slot:selection="{ attrs, item, select, selected }"
						>
							<v-chip
								v-bind="attrs"
								:input-value="selected"
								close
								@click="select"
								@click:close="removeChip(item)"
							>
								{{ item }}
							</v-chip>
						</template>
					</v-combobox>
				</v-card-text>
				<v-card-actions>
					<v-spacer></v-spacer>
					<v-btn
						color="green"
						@click="cancelarDialogAddControl"
						:disabled="loadingAgregar"
						text
						>Cancelar</v-btn
					>
					<v-btn
						color="red"
						@click="agregarControl"
						:disabled="loadingAgregar || validarBtnAgregar"
						:loading="loadingAgregar"
						text
						>
							{{
								this.editItem.idArticuloInventarioControl < 0
								? 'Agregar'
								: 'Actualizar'
							}}
						</v-btn
					>
				</v-card-actions>
			</v-card>
		</v-dialog>
		<v-dialog v-model="dialogControl" persistent max-width="60%">
			<v-card>
				<v-card-title>
					Máximos, Mínimos y Puntos de Reorden
				</v-card-title>
				<v-card-text>
					<v-row>
						<v-col cols="4">
							<v-btn color="primary" @click="showDialogAddControl"
								>Agregar Min,Max,PR</v-btn
							>
						</v-col>
					</v-row>
					<v-data-table
						:items="controlInventario"
						:headers="headersControl"
						loading-text="Cargando... Porfavor espere"
						:loading="loading"
						:footer-props="{
							disableItemsPerPage: true,
							disablePagination: true,
						}"
					>
						<template v-slot:item.correo="{ item }">
							<p 
								class="mb-0" 
								v-if="item.correo==null"
								>N/A</p
							>
							<ul 
								v-else
								class="py-1"
							>
								<li 
									v-for="email in item.correo" 
									:key="`${item.idArticuloInventarioControl}-${email}`"
									class="pb-1"
								>
									{{email}}
								</li>
							</ul>
						</template>
						<template v-slot:item.actions="{ item }">
							<v-tooltip
								
								bottom
								color="black"
								class="white--text"
							>
								<template v-slot:activator="{ on }">
									<v-icon
										v-on="on"
										v-if="permisoVista('articulos', 'd')"
										small
										class="mr-2"
										@click="editarControl(item)"
										>mdi-pencil</v-icon
									>
								</template>
								<span class="white--text">Editar</span>
							</v-tooltip>
						</template>
					</v-data-table>
				</v-card-text>
				<v-card-actions>
					<v-spacer></v-spacer>
					<v-btn color="green" text @click="cerrarDialogControl"
						>Cerrar</v-btn
					>
				</v-card-actions>
			</v-card>
		</v-dialog>
		<v-snackbar v-model="alerta" top color="error">
			El Almacén seleccionado ya esta registrado.
			<v-btn text @click="alerta = false"> Cerrar </v-btn>
		</v-snackbar>
	</v-container>
</template>

<script>
import axios from "axios";
export default {
	data() {
		return {
			alerta: false,
			erroresMaximo: [""],
			erroresPR: [""],
			erroresMinimo: [""],
			idArticulo: 0,
			loadingAgregar: false,
			editItem: {
				idArticuloInventarioControl: -1,
				correo: [],
			},
			defaultEditItem: {
				idArticuloInventarioControl: -1,
				correo: [],
			},
			almacenes: [],
			loading: false,
			dialogControl: false,
			dialogAddControl: false,
			controlInventario: [],
			headersControl: [
				{
					text: "Almacén",
					align: "left",
					sortable: true,
					value: "almacen",
				},
				{
					text: "Mínimo",
					align: "center",
					sortable: true,
					value: "minimo",
				},
				{
					text: "Máximo",
					align: "center",
					sortable: true,
					value: "maximo",
				},
				{
					text: "Punto Reorden",
					align: "center",
					sortable: true,
					value: "puntoReorden",
				},
				{
					text: "Notificar a",
					align: "center",
					sortable: true,
					value: "correo",
				},
				{
					text: "Acciones",
					align: "center",
					sortable: false,
					value: "actions",
					width: "10%",
				},
			],
		};
	},
	computed: {
		tituloDialogAdd() {
			return this.editItem.idArticuloInventarioControl < 0
				? "Agregar Min, Max y PR"
				: "Editar Min, Max y PR";
		},
		validarBtnAgregar() {
			return !(
				this.erroresMaximo.length == 0 &&
				this.erroresPR.length == 0 &&
				this.erroresMinimo.length == 0
			);
		},
	},
	watch: {
		"editItem.maximo": function (after) {
			this.validarValores();
		},
		"editItem.puntoReorden": function (after) {
			this.validarValores();
		},
		"editItem.minimo": function (after) {
			this.validarValores();
		},
	},
	methods: {
		removeChip(item) {
			this.editItem.correo = this.editItem.correo.filter(
				(correo) => correo != item
			);
		},
		validarValores() {
			this.erroresMaximo = [];
			this.erroresPR = [];
			this.erroresMinimo = [];

			if (
				!(
					!isNaN(parseFloat(this.editItem.maximo)) &&
					parseFloat(this.editItem.maximo) >= 0 &&
					parseFloat(this.editItem.maximo) >= this.editItem.minimo &&
					parseFloat(this.editItem.maximo) >=
						this.editItem.puntoReorden
				)
			) {
				this.erroresMaximo.push(
					"El valor Máximo debe ser mayor o igual que el Minimo y el Punto de Reorden"
				);
			}
			if (
				!(
					!isNaN(parseFloat(this.editItem.puntoReorden)) &&
					parseFloat(this.editItem.puntoReorden) >= 0 &&
					parseFloat(this.editItem.puntoReorden) >=
						this.editItem.minimo &&
					parseFloat(this.editItem.puntoReorden) <=
						this.editItem.maximo
				)
			) {
				this.erroresPR.push(
					"El valor Punto de Reorden debe ser mayor o igual que el Minimo y menor que el Maximo"
				);
			}
			if (
				!(
					!isNaN(parseFloat(this.editItem.minimo)) &&
					parseFloat(this.editItem.minimo) >= 0 &&
					parseFloat(this.editItem.minimo) <=
						this.editItem.puntoReorden &&
					parseFloat(this.editItem.minimo) <= this.editItem.maximo
				)
			) {
				this.erroresMinimo.push(
					"El valor Mínimo debe ser menor o igual que el Punto de Reorden y el Maximo"
				);
			}
		},
		agregarControl() {
			this.loadingAgregar = true;
			const obj = {
				idArticulo: this.idArticulo,
				idAlmacen: this.editItem.idAlmacen,
				minimo: parseFloat(this.editItem.minimo),
				maximo: parseFloat(this.editItem.maximo),
				puntoReorden: parseFloat(this.editItem.puntoReorden),
				correo: this.editItem.correo?.map(email => email.trim()).join(", "),
			};
			if (this.editItem.idArticuloInventarioControl < 0) {
				axios
					.post("/Inventario/CrearControlInventario", obj)
					.then(() => {
						this.loadingAgregar = false;
						this.cancelarDialogAddControl();
						this.getListadoControlInventario();
						this.$emit("refresh");
					})
					.catch((error) => {
						this.loadingAgregar = false;
						if (error.response) {
							if (error.response.status == 409) {
								this.alerta = true;
							}
						}
					});
			} else {
				axios
					.put(
						`/Inventario/ActualizarControlInventario/${this.editItem.idArticuloInventarioControl}`,
						obj
					)
					.then(() => {
						this.loadingAgregar = false;
						this.cancelarDialogAddControl();
						this.getListadoControlInventario();
						this.$emit("refresh");
					})
					.catch((error) => {
						this.loadingAgregar = false;
						console.log(error);
					});
			}
		},
		editarControl(item) {
			this.editItem = Object.assign({}, item);
			this.dialogAddControl = true;
		},
		showDialogAddControl() {
			this.editItem = Object.assign({}, this.defaultEditItem);
			this.dialogAddControl = true;
		},
		cerrarDialogControl() {
			this.controlInventario = [];
			this.idArticulo = 0;
			this.dialogControl = false;
		},
		cancelarDialogAddControl() {
			this.editItem = Object.assign({}, this.defaultEditItem);
			this.dialogAddControl = false;
		},
		mostrarDialogControl(idArticulo) {
			this.idArticulo = idArticulo;
			this.getListadoControlInventario();
			this.getAlmacenes();
		},
		getListadoControlInventario() {
			this.loading = true;
			axios
				.get("/Inventario/ListarPuntosReorden", {
					params: { idArticulo: this.idArticulo },
				})
				.then((response) => {
					this.controlInventario = response.data.map(el => ({
						...el,
						correo: el.correo?.split(",").map((email) => email.trim())
					}))
					this.dialogControl = true;
					this.loading = false;
				})
				.catch((error) => {
					this.loading = false;
					console.log(error);
				});
		},
		getAlmacenes() {
			axios
				.get("/Almacenes")
				.then((response) => {
					this.almacenes = response.data;
				})
				.catch((error) => {
					console.log(error);
				});
		},
	},
};
</script>

<style>
</style>