<template>
  <v-col cols="12">
    <template>
      <v-row justify="center">
        <v-dialog v-model="eliminar" persistent max-width="500px" :key="eliminar">
          <v-card>
            <v-card-title class="headline">¿Está seguro que desea eliminar este turno?</v-card-title>
            <v-card-text>Esta acción no se puede revertir y será permanente.</v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="blue darken-1" text @click="eliminar = false; delItem = '';">Cancelar</v-btn>
              <v-btn color="red darken-1" text @click=" eliminar = false; deleteItemDB() ">Aceptar</v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-row>
    </template>
    <v-data-table v-if=" permisoVista('turnos', 'r') " :headers=" headers " :items=" turnos " :search=" search "
      :loading=" loading " loading-text="Cargando... Espere, Por Favor.">
      <template v-slot:top>
        <v-toolbar flat color="white">
          <v-dialog v-model=" dialog " persistent max-width="500px" :key=" dialog ">
            <template v-slot:activator=" { on } ">
              <v-btn color="primary" dark class="mb-2" v-on=" on " v-if=" permisoVista('turnos', 'c') ">Nuevo
                Turno</v-btn>
            </template>
            <v-card>
              <v-card-title>
                <span class="headline">{{ formTitle }}</span>
              </v-card-title>
              <v-card-text>
                <v-container>
                  <v-row>
                    <v-col justify="center" cols="12" sm="12" md="12">
                      <v-text-field v-model=" editedItem.nombre " label="Nombre del Turno"></v-text-field>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col justify="center" cols="12" sm="12" md="12">
                      <v-text-field v-model=" editedItem.codigo " label="Código de Turno" maxlength="8" />
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="6" md="6" sm="6">
                      <input-time v-model=" editedItem.inicio " label="Inicio" :max=" fin "></input-time>
                    </v-col>
                    <v-col cols="6" md="6" sm="6">
                      <input-time v-model=" editedItem.fin " :min=" inicio " label="Fin"></input-time>
                    </v-col>
                  </v-row>
                  <ul>
                    <li class="red--text" v-for="        ex         in         errores        " :key=" ex ">{{ ex }}</li>
                  </ul>
                </v-container>
              </v-card-text>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="blue darken-1" text @click=" dialog = false; errores = []; ">Cancelar</v-btn>
                <v-btn color="red darken-1" text @click=" save() " :disabled=" saving " :loading=" saving ">
                  Guardar
                  <template v-slot:loader>
                    <v-progress-circular indeterminate :width=" 2 " :size=" 24 " color="red"></v-progress-circular>
                  </template>
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
          <v-spacer></v-spacer>
          <v-text-field v-model=" search " append-icon="search" label="Buscar" single-line hide-details></v-text-field>
        </v-toolbar>
      </template>
      <template v-slot:item.action=" { item } ">
        <v-tooltip bottom color="black" class="white--text">
          <template v-slot:activator=" { on } ">
            <v-icon v-on=" on " v-if=" permisoVista('turnos', 'u') " small class="mr-2" @click=" editItem(item) ">
              edit
            </v-icon>
          </template>
          <span class="white--text">Editar</span>
        </v-tooltip>
        <v-tooltip bottom color="black" class="white--text">
          <template v-slot:activator=" { on } ">
            <v-icon v-on=" on " v-if=" permisoVista('turnos', 'd') " small @click=" deleteItem(item) ">
              delete
            </v-icon>
          </template>
          <span class="white--text">Eliminar</span>
        </v-tooltip>
      </template>
      <template v-slot:no-data>
        <v-btn color="primary" @click=" initialize ">Refrescar</v-btn>
      </template>
    </v-data-table>
    <v-snackbar v-model=" alerta " top color="error">
      Existe un Turno Activo con el mismo nombre
      <v-btn text @click=" alerta = false ">
        Cerrar
      </v-btn>
    </v-snackbar>
  </v-col>
</template>

<script>
import axios from "axios";
import inputTime from "@/components/InputTime";

export default {
  components: {
    'input-time': inputTime,
  },
  data: () => ({
    saving: false,
    dialog: false,
    alerta: false,
    eliminar: false,
    search: '',
    headers: [
      {
        text: 'Código',
        align: 'left',
        sortable: true,
        value: 'codigo',
      },
      {
        text: 'Turno',
        align: 'left',
        sortable: true,
        value: 'nombre',
      },
      {
        text: 'Inicio',
        align: 'left',
        sortable: true,
        value: 'inicio',
      },
      {
        text: 'Fin',
        align: 'left',
        sortable: true,
        value: 'fin',
      },
      { text: 'Acciones', value: 'action', sortable: false, width: '35%', align: 'center' },
    ],
    loading: false,
    turnos: [],
    delItem: '',
    action: 0,
    errores: [],
    editedIndex: -1,
    editedItem: {
      nombre: '',
      codigo: '',
      inicio: '',
      fin: '',
    },
    defaultItem: {
      nombre: '',
      codigo: '',
      inicio: '',
      fin: '',
    },
  }),

  computed: {
    formTitle() {
      return this.editedIndex === -1 ? 'Nuevo Turno' : 'Editar Turno'
    }
  },

  watch: {
    dialog(val) {
      val || this.close()
    }
  },

  created() {
    this.initialize()
  },

  methods: {
    initialize() {
      let me = this;
      me.loading = true;
      axios
        .get("/Turnos")
        .then(response => {
          me.turnos = response.data;
          me.loading = false;
        })
        .catch(error => {
          console.log(error);
        });
    },

    editItem(item) {
      this.action = 1;
      this.editedIndex = this.turnos.indexOf(item)
      this.editedItem = Object.assign({}, item)
      this.dialog = true
    },

    deleteItem(item) {
      this.eliminar = true;
      this.delItem = item;
    },
    deleteItemDB() {
      let me = this;
      let item = this.delItem;
      this.delItem = '';
      axios
        .put("/Turnos/Desactivar/" + item.idTurno)
        .then(response => {
          me.initialize();
        })
        .catch(error => {
          console.log(error);
        });
    },
    close() {
      this.action = 0,
        this.dialog = false
      setTimeout(() => {
        this.editedItem = Object.assign({}, this.defaultItem)
        this.editedIndex = -1
      }, 300)
    },
    validate() {
      this.errores = [];
      if (
        this.editedItem.nombre.length < 2 ||
        this.editedItem.nombre.length > 50
      ) {
        this.errores.push(
          "El nombre debe tener más de 2 caracteres y menos de 50."
        );
        return false;
      }
      return true;
    },
    save() {
      this.action = 0;
      if (this.validate()) {
        let me = this;
        me.saving = true;
        if (this.editedIndex > -1) {
          //Código para editar
          axios
            .put("/Turnos/Actualizar/", {
              Codigo: me.editedItem.codigo == "" ? "" : me.editedItem.codigo,
              IdTurno: me.editedItem.idTurno,
              Nombre: me.editedItem.nombre,
              Inicio: me.editedItem.inicio,
              Fin: me.editedItem.fin,
            })
            .then(response => {
              me.saving = false;
              me.close();
              me.initialize();
            })
            .catch(error => {
              me.saving = false;
              if (error.response) {
                if (error.response.status == 409) {
                  me.alerta = true;
                  me.errores.push("Existe un Turno Activo con el Mismo Nombre");
                }
              }
            });
        }
        else {
          axios
            .post("/Turnos/Crear", {
              Codigo: me.editedItem.codigo == "" ? "" : me.editedItem.codigo,
              Nombre: me.editedItem.nombre,
              Inicio: me.editedItem.inicio,
              Fin: me.editedItem.fin,
            })
            .then(response => {
              me.saving = false;
              me.close();
              me.initialize();
            })
            .catch(error => {
              me.saving = false;
              if (error.response) {
                if (error.response.status == 409) {
                  me.alerta = true;
                  me.errores.push("Existe un Turno Activo con el Mismo Nombre");
                }
              }
            });
        }
      }
    },
  },
}
</script>