<template>
  <v-container>
  </v-container>
</template>

<script>
import axios from "axios"

export default {
  props:{
    active:{
      type: Boolean,
      default: false,
    }
  },
  computed:{
    realizarPeticion(){
      return false;
    },
    url(){
      return '';
    }
  },
  data: () => ({
    interval: null,
    segundos: 60,
    cargando: false,
  }),
  methods:{
    obtenerDatos(){
      if(this.cargando) return;

      this.cargando = true;
      this.segundos = 60;

      axios
      .get(this.url)
      .then(response => {
        this.cargando = false;
      })
      .catch(error => {
        this.cargando = false;
        console.log(error);
      })
    }
  },
  mounted(){
    this.interval = setInterval(() => {
      if(!this.active) return;

      if(this.segundos == 0) this.obtenerDatos();
      else this.segundos -= 1;
    },1000);
  },
  destroyed(){
    clearInterval(this.interval);
  }
}
</script>