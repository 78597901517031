<template>
  <div>
    <table
      v-if="value.length > 0"
      :style="'width:100%;border-collapse: collapse; border-style: hidden; box-shadow: 0 0 0 1px '+borderColor+';'"
    >
      <tbody>
        <tr>
          <v-tooltip
            v-for="(item,idx) in value"
            :key="'item-'+idx"
            
            top
            color="black"
            class="white--text"
          >
            <template v-slot:activator="{ on, attrs }">
              <td
                v-bind="attrs"
                v-on="on"
                :class="'text-center '+colorText(item)"
                :style="'border: '+borderSize+'px solid '+borderColor+'; padding-top: 10px; padding-bottom: 10px; background-color: '+color(item)+'; width:'+dimension+'; height: '+height+'px;'"
              ></td>
            </template>
            <span class="white--text">
              <strong>{{itemTitle}}: </strong>{{item[itemText]}}
              <br>
              <strong>Avance: </strong>{{item[itemPorcentajeText].toFixed(2)}}%
            </span>
          </v-tooltip>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
export default {
  props:{
    height:{
      type: Number,
      default: 100,
    },
    itemTitle: {
      type: String,
      default: "Área"
    },
    itemText: {
      type: String,
      default: "area"
    },
    itemPorcentajeText: {
      type: String,
      default: "porcentaje"
    },
    borderColor:{
      type: String,
      default:'#666666'
    },
    borderSize:{
      type: Number,
      default:1
    },
    value:{
      type:Array,
      default:[]
    }
  },
  computed:{
    dimension(){
      if(this.value.length == 0) return "100%";
      return (100.0/this.value.length).toFixed(2) + "%";
    },
  },
  methods:{
    /*
    color(item){
      if(item[this.itemPorcentajeText] == 100) return "green";
      else if(item[this.itemPorcentajeText] < 100 && item[this.itemPorcentajeText] > 0) return "yellow";
      else return "red";
    },
    */
    color(item){
      if(item[this.itemPorcentajeText] >= 100) return "green";
      else if(item[this.itemPorcentajeText] < 100 && item[this.itemPorcentajeText] > 0){
        //Verde -> Amarillo
        return this.$utils.colors.mergeColors(
          {
            r: 0,
            g: 128,
            b: 0
          },{
            r: 255,
            g: 255,
            b: 0
          },
          item[this.itemPorcentajeText]/ 100.0)
      }
      else return "red";
    },
    colorText(item){
      if(item[this.itemPorcentajeText] >= 100) return "white--text";
      else if(item[this.itemPorcentajeText] < 100 && item[this.itemPorcentajeText] > 0) return "black--text";
      else return "white--text";
    }
  }
}
</script>