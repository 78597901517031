<template>
  <v-container>
    <template>
      <v-row justify="center">
        <v-dialog
          v-model="eliminar"
          persistent
          max-width="500px"
          :key="eliminar"
        >
          <v-card>
            <v-card-title class="headline"
              >¿Está seguro que desea eliminar este tipo?</v-card-title
            >
            <v-card-text
              >Esta acción no se puede revertir y será permanente.</v-card-text
            >
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                color="blue darken-1"
                text
                @click="
                  eliminar = false;
                  delItem = '';
                "
                >Cancelar</v-btn
              >
              <v-btn
                color="red darken-1"
                text
                @click="
                  eliminar = false;
                  deleteItemDB();
                "
                >Aceptar</v-btn
              >
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-row>
    </template>

    <v-dialog v-model="dialogOrdenar" persistent max-width="60%">
      <v-card>
        <v-card-title>Ordenar</v-card-title>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col cols="12" md="12" sm="12">
                <draggable
                  v-if="permisoVista('almacen', 'u')"
                  class="list-group"
                  tag="ul"
                  v-model="tiposOrden"
                  v-bind="dragOptions"
                  @start="drag = true"
                  @end="drag = false"
                >
                  <transition-group type="transition" :name="!drag ? 'flip-list' : null">
                    <v-card 
                      v-for="element in tiposOrden" 
                      :key="element.idArticuloTipo" 
                      :disabled="element.idArticuloTipo === 3"
                    >
                      <v-card-text>
                        <v-icon> mdi-menu </v-icon>
                        {{ element.nombre }}
                      </v-card-text>
                    </v-card>
                  </transition-group>
                </draggable>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions class="d-flex justify-center">
          <v-btn
            color="primary"
            class="mt-0"
            v-if="permisoVista('areas', 'u')"
            :disabled="savingOrdenar"
            @click="cancelarOrdenar"
          >
            Cancelar
          </v-btn>
          <v-btn
            color="error"
            class="mt-0"
            v-if="permisoVista('areas', 'u')"
            :disabled="savingOrdenar"            
            :loading="savingOrdenar"
            @click="saveOrder"
          >
            Guardar Orden
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-data-table
      v-if="permisoVista('almacen', 'r')"
      :headers="headers"
      :items="tipos"
      :search="search"
      :loading="loading"
      loading-text="Cargando... Espere, Por Favor."
    >
      <template v-slot:top>
        <v-toolbar flat color="white">
          <v-dialog v-model="dialog" persistent max-width="500px" :key="dialog">
            <template v-slot:activator="{ on }">
              <v-btn
                color="primary"
                dark
                class="mb-2"
                v-on="on"
                v-if="permisoVista('almacen', 'c')"
                >Nuevo Tipo</v-btn
              >
            </template>
            <v-card>
              <v-card-title>
                <span class="headline">{{ formTitle }}</span>
              </v-card-title>
              <v-card-text>
                <v-container>
                  <v-row>
                    <v-col justify="center" cols="12" sm="12" md="12">
                      <v-text-field
                        v-model="editedItem.nombre"
                        label="Nombre del Tipo"
                      ></v-text-field>
                    </v-col>
                    <v-col justify="center" cols="12" sm="12" md="12">
                      <v-text-field
                        v-model="editedItem.descripcion"
                        label="Descripción del Tipo"
                      ></v-text-field>
                    </v-col>
                    <v-col
                      v-if="
                        editedItem.idArticuloTipo >= 4 ||
                        typeof editedItem.idArticuloTipo === 'undefined'
                      "
                      justify="center"
                      cols="12"
                      sm="12"
                      md="12"
                    >
                      <v-autocomplete
                        :items="sn"
                        v-model="editedItem.receta"
                        label="¿Require de una Receta?"
                      ></v-autocomplete>
                    </v-col>
                    <v-col
                      v-if="
                        (editedItem.idArticuloTipo >= 4 ||
                          typeof editedItem.idArticuloTipo === 'undefined') &&
                        editedItem.receta == false
                      "
                      justify="center"
                      cols="12"
                      sm="12"
                      md="12"
                    >
                      <v-autocomplete
                        :items="sn"
                        v-model="editedItem.produccion"
                        label="¿Es parte de la produccion?"
                      ></v-autocomplete>
                    </v-col>
                  </v-row>
                  <ul>
                    <li class="red--text" v-for="ex in errores" :key="ex">
                      {{ ex }}
                    </li>
                  </ul>
                </v-container>
              </v-card-text>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                  color="blue darken-1"
                  text
                  @click="
                    dialog = false;
                    errores = [];
                  "
                  >Cancelar</v-btn
                >
                <v-btn
                  color="red darken-1"
                  text
                  @click="save()"
                  :disabled="saving"
                  :loading="saving"
                >
                  Guardar
                  <template v-slot:loader>
                    <v-progress-circular
                      indeterminate
                      :width="2"
                      :size="24"
                      color="red"
                    ></v-progress-circular>
                  </template>
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
          <v-spacer></v-spacer>
          <v-text-field
            v-model="search"
            append-icon="search"
            label="Buscar"
            single-line
            hide-details
          ></v-text-field>
        </v-toolbar>
        <div class="d-flex justify-end">
          <v-btn
            color="primary"
            class="mt-0"
            v-show="permisoVista('almacen', 'u')"
            @click="dialogOrdenar = true"
          >
            <v-icon left>mdi-sort</v-icon>Ordenar
          </v-btn>
        </div>
      </template>
      <template v-slot:item.action="{ item }">
        <v-tooltip bottom color="black" class="white--text">
          <template v-slot:activator="{ on }">
            <v-icon v-on="on"
              v-if="permisoVista('almacen', 'u')"
              small
              class="mr-2"
              @click="editItem(item)"
            >
              edit
            </v-icon>
          </template>
          <span class="white--text">Editar</span>
        </v-tooltip>

        <v-tooltip bottom color="black" class="white--text">
          <template v-slot:activator="{ on }">
            <v-icon v-on="on"
              v-if="item.idArticuloTipo > 3 && permisoVista('almacen', 'd')"
              small
              @click="deleteItem(item)"
            >
              delete
            </v-icon>
          </template>
          <span class="white--text">Eliminar</span>
        </v-tooltip>
      </template>
      <template v-slot:no-data>
        <v-btn color="primary" @click="initialize">Refrescar</v-btn>
      </template>
    </v-data-table>

    <v-snackbar v-model="alerta" top color="error">
      Existe un Tipo Activo con el mismo nombre
      <v-btn text @click="alerta = false"> Cerrar </v-btn>
    </v-snackbar>
  </v-container>
</template>

<script>
import axios from "axios";
import draggable from "vuedraggable";

export default {
  components: {
    draggable,
  },
  data: () => ({
    savingOrdenar: false,
    dialogOrdenar: false,
    saving: false,
    drag: false,
    dialog: false,
    alerta: false,
    eliminar: false,
    search: "",
    headers: [
      {
        text: "Tipo",
        align: "left",
        sortable: true,
        value: "nombre",
      },
      {
        text: "Acciones",
        value: "action",
        sortable: false,
        width: "35%",
        align: "center",
      },
    ],
    sn: [
      {
        text: "Sí",
        value: true,
      },
      {
        text: "No",
        value: false,
      },
    ],
    loading: false,
    tipos: [],
    delItem: "",
    action: 0,
    errores: [],
    editedIndex: -1,
    editedItem: {
      nombre: "",
      descripcion: "",
      receta: null,
      produccion: null,
    },
    defaultItem: {
      nombre: "",
      descripcion: "",
      receta: null,
      produccion: null,
    },
    tiposOrden: [],
  }),

  computed: {
    formTitle() {
      return this.editedIndex === -1 ? "Nuevo Tipo" : "Editar Tipo";
    },
    dragOptions() {
      return {
        animation: 200,
        group: "description",
        disabled: false,
        ghostClass: "ghost",
      };
    },
  },

  watch: {
    dialog(val) {
      val || this.close();
    },
  },

  created() {
    this.initialize();
  },

  methods: {
    cancelarOrdenar(){
      this.dialogOrdenar = false;
      this.tiposOrden = this.tipos.filter(function (element) {
        return (
          element.receta == true ||
          element.idArticuloTipo == 2 ||
          element.idArticuloTipo == 3
        );
      })
      .sort(function (a, b) {
        return a.posicion - b.posicion;
      });
    },
    initialize() {
      let me = this;
      me.loading = true;
      axios
        .get("/ArticuloTipos")
        .then((response) => {
          me.tipos = response.data.sort(function (a, b) {
              return a.posicion - b.posicion;
            });
          me.tiposOrden = response.data
            .filter(function (element) {
              return (
                element.receta == true ||
                element.idArticuloTipo == 2 ||
                element.idArticuloTipo == 3
              );
            })
            .sort(function (a, b) {
              return a.posicion - b.posicion;
            });
          me.loading = false;
        })
        .catch((error) => {
          console.log(error);
        });
    },

    editItem(item) {
      this.action = 1;
      this.editedIndex = this.tipos.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialog = true;
    },

    deleteItem(item) {
      this.eliminar = true;
      this.delItem = item;
    },
    deleteItemDB() {
      let me = this;
      let item = this.delItem;
      this.delItem = "";
      axios
        .put("/ArticuloTipos/Desactivar/" + item.idArticuloTipo)
        .then((response) => {
          me.initialize();
        })
        .catch((error) => {
          console.log(error);
        });
    },
    close() {
      (this.action = 0), (this.dialog = false);
      setTimeout(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      }, 300);
    },
    validate() {
      this.errores = [];
      if (
        this.editedItem.nombre.length < 2 ||
        this.editedItem.nombre.length > 50
      ) {
        this.errores.push(
          "El nombre debe tener más de 4 caracteres y menos de 50."
        );
        return false;
      }
      return true;
    },
    saveOrder() {
      this.savingOrdenar = true;
      const obj = this.tiposOrden.map((element,index) => ({
        idArticuloTipo: element.idArticuloTipo,
        posicion: index+1,
      }));

      axios
        .put("/ArticuloTipos/Posiciones", obj)
        .then(() => {
          this.initialize();
          this.savingOrdenar = false;
          this.dialogOrdenar = false;
        })
        .catch((error) => {
          this.savingOrdenar = false;
          console.log(error);
        });
    },
    save() {
      this.action = 0;
      if (this.validate()) {
        let me = this;
        me.saving = true;
        if (this.editedIndex > -1) {
          //Código para editar
          axios
            .put(
              "/ArticuloTipos/Actualizar/" +
                me.tipos[me.editedIndex].idArticuloTipo,
              {
                Nombre: me.editedItem.nombre,
                Descripcion: me.editedItem.descripcion,
                Receta: me.editedItem.receta,
                Produccion: me.editedItem.produccion,
              }
            )
            .then((response) => {
              me.saving = false;
              me.close();
              me.initialize();
            })
            .catch((error) => {
              me.saving = false;
              if (error.response) {
                if (error.response.status == 409) {
                  me.alerta = true;
                  me.errores.push("Existe una Tipo Activa con el Mismo Nombre");
                }
              }
            });
        } else {
          axios
            .post("/ArticuloTipos/Crear", {
              Nombre: me.editedItem.nombre,
              Descripcion: me.editedItem.descripcion,
              Receta: me.editedItem.receta,
              Produccion: me.editedItem.produccion,
            })
            .then((response) => {
              me.saving = false;
              me.close();
              me.initialize();
            })
            .catch((error) => {
              me.saving = false;
              if (error.response) {
                if (error.response.status == 409) {
                  me.alerta = true;
                  me.errores.push("Existe una Tipo Activa con el Mismo Nombre");
                }
              }
            });
        }
      }
    },
  },
};
</script>

<style scoped>
.v-application ul {
  padding-left: 0px !important;
}
</style>