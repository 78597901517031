<template>
  <v-col cols="12" v-if="permisoVista('lotes','r')">
    <signalr path="/hub/procesos" @change="cambio"></signalr>
    <v-data-table
      :items="procesos"
      :headers="headers"
    >
      <template v-slot:item.progreso="{ item }">
        <v-progress-linear
          color="teal"
          buffer-value="0"
          :value="item.progreso"
          stream
        ></v-progress-linear>
        {{item.observaciones}}
      </template>
      <template v-slot:item.action="{ item }">
        <v-tooltip v-if="item.nombreArchivo != null"  bottom color="black" class="white--text">
          <template v-slot:activator="{ on }">
            <v-icon v-on="on" small @click="descargarArchivo(item.nombreArchivo)" >mdi-download</v-icon>
          </template>
          <span class="white--text">Descargar Archivo</span>
        </v-tooltip>
        <v-tooltip v-if="permisoVista('lotes','d')"  bottom color="black" class="white--text">
          <template v-slot:activator="{ on }">
            <v-icon v-on="on" small @click="eliminarProceso(item.idProcesoLote)" >delete</v-icon>
          </template>
          <span class="white--text">Eliminar</span>
        </v-tooltip>
      </template>
    </v-data-table>
  </v-col>
</template>

<script>
import axios from "axios";
import signalr from "@/components/SignalR";
import { mapState } from 'vuex';

export default {
  components: {
    signalr,
  },
  data: () => ({
    loading: false,
    procesos: [],
    headers: [
      {
        text: 'Fecha de Creación',
        align: 'center',
        sortable: true,
        value: 'fechaCreacionLabel',
        width:'15%'
      },
      {
        text: 'Proceso',
        align: 'center',
        sortable: true,
        value: 'nombre',
        width:'15%'
      },
      {
        text: 'Progreso',
        align: 'center',
        sortable: true,
        value: 'progreso',
      },
      { text: 'Acciones', value: 'action', sortable: false, width:'20%', align:'center' },
    ],
  }),
  computed:{
    ...mapState({
      idUser: 'idUser',
    }),
  },
  mounted(){
    this.initialize();
  },
  methods:{
    initialize () {
      this.getProcesos();
    },
    cambio(event){
      var orden = event.toLowerCase().split('-');
      if(this.idUser == parseInt(orden[1])){
        if(orden[0] == "recargar"){
          this.getProcesos();
        }

        if(orden[0] == "actualizar"){
          this.actualizarProceso(orden[2]);
        }
      }
    },
    getProcesos(){
      this.loading = true;
      axios
      .get("/ProcesosLotes/Mostrar")
      .then(response => {
        this.loading = false;
        this.procesos = response.data;
      })
      .catch(error => {
        this.loading = false;
        console.log(error);
      })
    },
    actualizarProceso(idProcesoLote){
      this.loading = true;
      axios
      .get("/ProcesosLotes/MostrarDetalles/"+idProcesoLote)
      .then(response => {
        this.loading = false;
        var idx = this.procesos.findIndex(p => p.idProcesoLote == response.data.idProcesoLote);
        if(idx >= 0){
          this.procesos[idx].progreso = response.data.progreso;
          this.procesos[idx].fechaFinalizacion = response.data.fechaFinalizacion;
          this.procesos[idx].fechaFinalizacionLabel = response.data.fechaFinalizacionLabel;
          this.procesos[idx].nombreArchivo = response.data.nombreArchivo;
          this.procesos[idx].observaciones = response.data.observaciones;
        }
      })
      .catch(error => {
        this.loading = false;
        console.log(error);
      })
    },
    eliminarProceso(idProcesoLote){
      this.loading = true;
      axios
      .put("/ProcesosLotes/Eliminar/"+idProcesoLote)
      .then(response => {
        this.getProcesos();  
      })
      .catch(error => {
        this.loading = false;
        console.log(error);
      })
    },
    descargarArchivo(fichero){
      var url = "/TrabajoOrdenes/DescargarReporte/"+fichero;
      this.$utils.axios.downloadFile(url,'GET',fichero,'application/octet-stream');
    },
  }
}
</script>