<template>
  <v-col cols="12" v-if="permisoVista('leerqr','r')" class="text-center">
    <notifications group="inventario" position="bottom right" :duration="10000"/>
    <v-row  v-if="estado">
      <div style="max-width:350px; max-height:197px; margin-left: auto; margin-right: auto;">
        <qrcode-stream @decode="onDecode"></qrcode-stream>
      </div>
    </v-row>
    <v-row>
      <v-col cols="12" md="12" sm="12">
        <v-text-field
          v-model="codigo"
          label="Código de Identificación"
          hide-details
          @keydown.enter="getCode"
          @keyup="setCodeStyle"
          maxlength="12"
          :error="!(validCode(codigo) && codigo.length >= 11 && codigo.length <= 12) && codigo.length >= 1"
        >
        </v-text-field>
      </v-col>
    </v-row>
    <template v-if="!loading && viewedItem.codigo != ''">
      <v-row>
        <v-col cols="3" md="3" sm="3">
          <v-text-field
            v-model="viewedItem.almacen"
            label="Almacén"
            readonly
          ></v-text-field>
        </v-col>
        <v-col cols="3" md="3" sm="3">
          <v-text-field
            v-model="viewedItem.articulo"
            label="Artículo"
            readonly
          ></v-text-field>
        </v-col>
        <v-col cols="3" md="3" sm="3">
          <v-text-field
            v-model="viewedItem.codigo"
            label="Clave"
            readonly
          ></v-text-field>
        </v-col>
        <v-col cols="3" md="3" sm="3">
          <v-text-field
            v-model="viewedItem.lote"
            label="# Lote"
            readonly
          ></v-text-field>
        </v-col>
      </v-row>
      <v-data-table
        v-if="viewedItem.proveedores.length > 0"
        :items="viewedItem.proveedores"
        :headers="headersProveedores"
        :items-per-page="-1"
        hide-default-footer
      >
      </v-data-table>
      <br>
      <br>
      <v-data-table
        v-if="viewedItem.movimientos.length > 0"
        :items="viewedItem.movimientos"
        :headers="headersMovimientos"
        :items-per-page="-1"
        hide-default-footer
      >
        <template v-slot:[`item.icono`]="{ item }">
          <v-icon v-if="item.tipoMovimiento==6" :color="colores[0]" small>{{'mdi-'+iconos[0]}}</v-icon>
          <v-icon v-else-if="item.tipoMovimiento==7" color="orange" small>{{'mdi-swap-horizontal-bold'}}</v-icon>
          <v-icon v-else :color="colores[item.tipoMovimiento -1]" small>{{'mdi-'+iconos[item.tipoMovimiento -1]}}</v-icon>
        </template>
        <template v-slot:item.tipoMovimiento="{ item }">
          {{getTipoMovimiento(item.tipoMovimiento)}}
        </template>
        <template v-slot:item.cantidad="{ item }">
          {{ item.cantidad.toFixed(2) }}
        </template>
        <template v-slot:item.detalles="{ item }">
          <div v-if="item.trabajoOrden != null">
            <b>Orden de Trabajo: </b>{{item.trabajoOrden}}
          </div>
          <div v-if="item.articulo != null">
            <b>Artículo a Producir: </b>{{item.articulo}}
          </div>
          <div v-if="item.almacen != null">
            <b>Almacen: </b>{{item.almacen}}
          </div>
          <div v-if="item.subAlmacen != null">
            <b>SubAlmacen: </b>{{item.subAlmacen}}
          </div>
        </template>
      </v-data-table>
      <div v-if="viewedItem.lotesOT!=null">
        <v-data-table
          v-if="viewedItem.lotesOT.length > 0"
          :items="viewedItem.lotesOT"
          :headers="headersLotes"
          :items-per-page="-1"
          hide-default-footer
        >
          <template v-slot:item.lotes="{ item }">
            <ul>
              <li 
                v-for="(lote,idx) in item.lotes" 
                :key="`lote-${idx}`"
              >
                {{ lote!=null?lote:'N/D' }}
              </li>
            </ul>
          </template>
        </v-data-table>
      </div>
    </template>
    <template
      v-if="loading"
    >
      <v-progress-circular
				indeterminate
				size="200"
				width="10"
				color="primary"
			></v-progress-circular>
			<div class="text-h6">Cargando</div>
    </template>
    <template v-if="viewedItem.qrProduccion == true">
      <br/>
      <br/>
      <br/>
      <div class="text-h4">Informacion de Trazabilidad</div>
      <v-row>
        <v-col cols="3"/>
        <v-col cols="6" class="text-left">
          <v-treeview
            v-if="!loadingArbol"
            :items="arbol"
            open-all
          >
            <template v-slot:prepend="{ item }">
              <v-icon>
                {{ icons[item.tipo] }}
              </v-icon>
            </template>
          </v-treeview>
          <v-progress-linear
            v-else
            indeterminate
          ></v-progress-linear>
        </v-col>
        <v-col cols="3"/>
      </v-row>
    </template>
  </v-col>
</template>

<script>
import axios from "axios";
import { QrcodeStream } from 'vue-qrcode-reader'
import {Howl, Howler} from 'howler';
import { mapState } from 'vuex';

export default {
  components:{
    QrcodeStream,
  },
  
  data: () => ({
    estado:true,
    loading: false,
    iconos:['arrow-left','arrow-right','plus','redo','cached'],
    colores:['red','green','black','orange','brow'],
    headersLotes: [
      {
        text: 'Artículo',
        value: 'articulo',
        align:'center',
        width:'50%',
      },
      {
        text: '# Lote',
        value: 'lotes',
        align:'center',
        width:'50%',
      },
    ],
    tipoMovimientos:[
      {
        nombre:"Salida Parcial",
        idTipoMovimiento:6
      },
      {
        nombre:"Salida Parcial",
        idTipoMovimiento:6
      },
      {
        nombre:"Transferencia",
        idTipoMovimiento:7
      },
      {
        nombre:"Salida",
        idTipoMovimiento:1
      },
      {
        nombre:"Entrada",
        idTipoMovimiento:2
      },
      {
        nombre:"Excedente",
        idTipoMovimiento:3
      },
      {
        nombre:"Reintegro",
        idTipoMovimiento:4
      }
    ],
    codigo:'',
    soundBeep:null,
    soundError:null,
    viewedItem:{
      almacen: "",
      articulo: "",
      codigo: "",
      idAlmacen: -1,
      idArticulo: -1,
      idElemento: -1,
      movimientos: [],
      proveedores: [],
      lote: "",
      lotesOT: null,
      qrProduccion: false
    },
    defaultItem:{
      almacen: "",
      articulo: "",
      codigo: "",
      idAlmacen: -1,
      idArticulo: -1,
      idElemento: -1,
      movimientos: [],
      proveedores: [],
      lote: "",
      lotesOT: null,
      qrProduccion: false
    },
    headersProveedores:[
      {
        text: 'Tipo de Proveedor',
        value: 'proveedorTipo',
        align:'center',
        width:'50%',
      },
      {
        text: 'Proveedor',
        value: 'proveedor',
        align:'center',
        width:'50%',
      },
    ],
    headersMovimientos:[
      {
        text: '',
        value: 'icono',
        width:'1%',
        sortable: false,
      },
      {
        text: 'Fecha',
        value: 'fechaCreacion',
        align:'center',
        width:'20%',
      },
      {
        text: 'Tipo de Movimiento',
        value: 'tipoMovimiento',
        align:'center',
        width:'20%',
      },
      {
        text: 'Cantidad',
        value: 'cantidad',
        align:'center',
        width:'20%',
      },
      {
        text: 'Detalles',
        value: 'detalles',
        align: 'center',
      }
    ],
    arbol: [],
    loadingArbol: true,
    icons:{
      qr: 'mdi-qrcode',
      area: 'mdi-texture-box',
      operadores: 'mdi-account-group',
      operador: 'mdi-account-hard-hat',
      maquinas: 'mdi-hammer-wrench',
      maquina: 'mdi-robot-industrial-outline',
      materiasprimas: 'mdi-shape-plus',
      materiaprima: 'mdi-plus-circle-outline',
      componentes: 'mdi-sitemap',
      componente: 'mdi-cog-outline',
      paso: 'mdi-multicast',
      subpaso: 'mdi-ray-start-arrow',
      tiempo: 'mdi-clock-outline'
    }
  }),

  computed:{
    ...mapState({
      nombreEmpresa: 'nombreEmpresa',
    }),
  },
  created(){
    this.initialize();
  },
  methods:{
    initialize(){
      this.soundBeep = new Howl({
        src: ['/sounds/beep.webm','/sounds/beep.mp3']
      });
      this.soundError = new Howl({
        src: ['/sounds/error.webm','/sounds/error.mp3']
      });
    },
    estadoLector(value){
      this.estado = value;
    },
    onDecode(code){  
      this.loading = true;
      axios
      .get("/Inventario/InformacionBasica?Codigo="+code+"&Movimientos=true")
      .then(response => {
        response.data.codigo = this.setCodeStyleReturn(response.data.codigo);
        this.viewedItem = response.data;
        this.viewedItem.lote = this.viewedItem.lote!=null ? this.viewedItem.lote : 'N/D';
        this.soundBeep.play();
        this.loading = false;

        if(response.data.qrProduccion == true){
          this.getInformacionCodigo(response.data.codigo);
        }
      })
      .catch(error => {
        this.soundError.play();
        this.$notify({
          group: 'inventario',
          title: 'No Existe un Elemento con el Código '+ this.setCodeStyleReturn(code),
          type: 'error',
        });
        this.loading = false;
      });
    },
    
    getInformacionCodigo(codigo){
      this.loadingArbol = true;
      axios
      .get(`/Trazabilidad/GenerarArbol?codigo=${codigo}`)
      .then(response => {
        this.arbol = response.data;
        this.loadingArbol = false;
      })
      .catch(error => {
        console.log(error);
        this.arbol = [];
        this.loadingArbol = false;
      })
    },
    getTipoMovimiento(tipo){
      var x = this.tipoMovimientos.find(z => z.idTipoMovimiento == tipo);
      if(x == null) return "x";
      else return x.nombre;
    },
    setCodeStyleReturn(codigo){
      if(codigo.length >= 1){
        try{
          if(codigo[0] == '@' && codigo.length > 1){
            return "@" + codigo.substring(1).toUpperCase().replace(/-/g, '').match(/.{1,3}/g).join('-');
          }

          else return codigo.toUpperCase().replace(/-/g, '').match(/.{1,3}/g).join('-');
        }
        catch{
          return '';
        }
      }
    },
    setCodeStyle(){
      if(this.codigo.length >= 1){
        try{
          if(this.codigo[0] == '@' && this.codigo.length > 1){
            this.codigo = "@" + this.codigo.substring(1).toUpperCase().replace(/-/g, '').match(/.{1,3}/g).join('-');
          }

          else this.codigo = this.codigo.toUpperCase().replace(/-/g, '').match(/.{1,3}/g).join('-');
        }
        catch{
          this.codigo = '';
        }
      }
    },
    getCode(){
      var codigo = this.codigo.toUpperCase().replace(/-/g, '');
      if(codigo.length == 10 && codigo[0] == "@"){
        this.onDecode(codigo);
        this.codigo = "";
      }
      if(codigo.length == 9 && this.onlyNumberAndLetters(codigo)){
        this.onDecode(codigo);
        this.codigo = "";
      }
    },
    validCode(str){
      if(str[0] == '@'){
        return this.onlyNumberAndLetters(str.substring(1).replace(/-/g, ''));
      }
      else return this.onlyNumberAndLetters(str.replace(/-/g, ''));
    },
    onlyNumberAndLetters(str) {
      return str.match("^[A-Z0-9-]+$");
    }
  }
}
</script>