import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VSpacer } from 'vuetify/lib/components/VGrid';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VDialog,{attrs:{"persistent":"","max-width":"80%"},model:{value:(_vm.showDialog),callback:function ($$v) {_vm.showDialog=$$v},expression:"showDialog"}},[_c(VCard,[_c(VCardTitle,[_vm._v(_vm._s(_vm.title))]),_c(VCardText,[_c(VContainer,[_c('apexchart',{ref:"chart",attrs:{"type":_vm.type,"height":_vm.height,"options":_vm.chartOptions,"series":_vm.series}})],1)],1),_c(VCardActions,[_c(VSpacer),_c(VBtn,{attrs:{"color":"blue","text":""},on:{"click":_vm.close}},[_vm._v("Cerrar")])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }