<template>
    <v-row>
      <v-col cols="12">
          <div class="text-center text-h3">
            Reporte de Rendimiento de Operadores
          </div>
          <v-progress-linear
            v-if="cargando"
            indeterminate
            color="blue darken-2"
          ></v-progress-linear>
          <Bar
            :chart-options="chartOptions"
            :chart-data="chartData"
          />
      </v-col>
    </v-row>
</template>

<script>
import { Bar } from 'vue-chartjs/legacy'
import { Chart as ChartJS, Title, Tooltip, Legend, BarElement, CategoryScale, LinearScale } from 'chart.js'
import ChartDataLabels from 'chartjs-plugin-datalabels';
import axios from "axios"

ChartJS.register(Title, Tooltip, Legend, BarElement, CategoryScale, LinearScale, ChartDataLabels)

export default {
  components: { 
    Bar
  },
  data: () => ({
    chartData: {
      labels: [],
      datasets: [
        {
          label: '% Rendimiento',
          backgroundColor: '#4CAF50',
          data: [],
        }
      ]
    },
    defaultChartData: {
      labels: [],
      datasets: [
        {
          label: '% Rendimiento',
          backgroundColor: '#4CAF50',
          data: [],
        }
      ]
    },
    chartOptions: {
      plugins: {
        datalabels: {
          anchor: 'end',
          align: 'start',
          color: 'white',
          display: function(context) {
            return context.dataset.data[context.dataIndex] >= 10;
          },
          font: {
            weight: 'bold',
            size: '30'
          },
          formatter: (value) => `${Math.round(value)}%`
        }
      },
      indexAxis: 'y',
      responsive: true,
      maintainAspectRatio: false,
      height: '90%',
      scales: {
        y: {
            ticks: {
                font: {
                    size: 30,
                }
            }
        }
    }
    },
    cargando: false,
    interval: null,
  }),
  mounted(){
    this.getData();
    this.interval = setInterval(() => {
      this.getData();
    },300000)
  },
  destroyed(){
    clearInterval(this.interval);
  },
  methods:{
    getData(){
      this.cargando = true;
      axios
      .get('/Reportes/ReporteEficienciaProyeccion')
      .then(response => {
        this.cargando = false;
        var temp = Object.assign({},this.defaultChartData);
        temp.labels = response.data.labels;
        temp.datasets[0].data = response.data.data;
        this.chartData = temp;
      })
      .catch(error => {
        this.cargando = false;
        console.log(error);
      })
    }
  }
}
</script>