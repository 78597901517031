<template>
	<!-- <v-row> -->
	<v-col cols="12" md="12" sm="12" class="pa-0 ma-0">
		<v-dialog v-model="dialogRecursos" persistent max-width="40%">
			<v-card>
				<v-card-title>Agregar Recurso</v-card-title>
				<v-card-text>
					<v-select
						v-model="itemRecurso.tipo"
						:items="tiposRecursos"
						item-text="tipo"
						item-value="id"
						label="Tipo de Recurso"
						:error-messages="itemRecurso.errorTipo"
						@focus="$set(itemRecurso,'errorTipo',null)"
						@change="onChangeTipo()"
					></v-select>
					<v-text-field
						v-if="itemRecurso.tipo == 1 || itemRecurso.tipo == 2"
						v-model="itemRecurso.recursos.titulo"
						label="Titulo"
						clearable
						:error-messages="itemRecurso.errorTitulo"
						@focus="$set(itemRecurso,'errorTitulo',null)"
					></v-text-field>
					<v-file-input
						v-model="itemRecurso.recursos.idDocumento"
						v-if="itemRecurso.tipo == 1"
						label="Seleccione un archivo"
						accept=".pdf,.doc,.docx,.xls,.xlsx,.ppt,.pptx,.jpg,.jpeg,.png,.gif,.svg"
						show-size
						:error-messages="itemRecurso.errorDocumento"
						@focus="$set(itemRecurso,'errorDocumento',null)"
						@change="cambioFicheroRecurso"
					></v-file-input>
					<v-text-field
						v-model="itemRecurso.recursos.links"
						v-if="itemRecurso.tipo == 2"
						label="Introduzca la URL"
						:error-messages="itemRecurso.errorLink"
						@focus="$set(itemRecurso,'errorLink',null)"
					></v-text-field>
				</v-card-text>
				<v-card-actions>
					<v-spacer/>
					<v-btn
						color="error"
						text
						:disabled="subiendoArchivos"
						@click="cerrarDialogRecursos"
						>Cancelar</v-btn
					>
					<v-btn
						color="success"
						text
						:loading="subiendoArchivos"
						:disabled="subiendoArchivos"
						@click="agregarRecurso"
						>Agregar</v-btn
					>
				</v-card-actions>
			</v-card>
		</v-dialog>
		<v-expansion-panels v-model="panel">
			<v-expansion-panel>
				<v-expansion-panel-header>
					Lista de Comprobación
				</v-expansion-panel-header>
				<v-expansion-panel-content>
					<v-data-table
						:headers="headers"
						:items="item.comprobaciones"
						loading-text="Cargando... Espere, Por Favor."
						:loading="loading"
						class="elevation-0"
						ref="listadotodo"
						hide-default-footer
						hide-default-header
						disable-pagination
					>
						<template v-slot:item="{ item, index: compIdx }">
							<tr
								class="sortableRow"
								:key="`row-${item.idMantenimientoComprobacion}`"
							>
								<td class="px-1" style="width: 10px">
									<v-btn
										style="cursor: move"
										icon
										class="sortHandle"
										><v-icon color="black"
											>mdi-menu</v-icon
										></v-btn
									>
								</td>
								<td class="text-center" style="width: 35%">
									<v-text-field
										v-model="item.comprobacion"
										label="Nombre"
										:error-messages="item.errorNombre"
										@focus="$set(item,'errorNombre',null)"
									></v-text-field>
								</td>
								<td style="width: 60%">
									<v-container fluid>
										<v-row>
											<v-col
												:cols="(item.tipoComprobacion == 1 || item.tipoComprobacion == null) ? 12 : 6"
												:class="{ 'pb-0': item.tipoComprobacion == 1 || item.tipoComprobacion == null }"
											>
												<v-select
													v-model="item.tipoComprobacion"
													label="Tipo de comprobación"
													:items="$utils.mantenimientos.tiposComprobaciones"
													:error-messages="item.errorTipoComprobacion"
													@focus="$set(item,'errorTipoComprobacion',null)"
													@change="onChangeTipoComprobacion(item)"
												></v-select>
											</v-col>
											<template v-if="item.tipoComprobacion == 2">
												<v-col cols="6" class="d-flex justify-center">
													<div>
														<p class="mb-2 text-center grey--text text--darken-1">Número de opciones</p>
														<number-input
															v-model="item.numOpciones"
															controls
															inline
															size="small"
															:inputtable="false"
															:min="2"
															center
															@change="onChangeNumOpciones(item)"
														></number-input>
													</div>
												</v-col>
											</template>
											<template v-if="item.tipoComprobacion == 2">
												<v-col v-for="opcion,idx in item.opciones" cols="6" class="py-0" :key="`opcion-${idx}`">
													<v-text-field
														v-model="opcion.nombre"
														:label="`Opción ${idx + 1}`"
														clearable
														:error-messages="opcion.errorOpcion"
														@focus="$set(opcion,'errorOpcion',null)"
													></v-text-field>
												</v-col>
											</template>
											<v-col cols="12">
												<v-divider class="mx-0" style="border-color: #BDBDBD" />
												<v-chip-group column>
													<v-chip
														v-for="(documento, idx) in item.recursos.idsDocumentos"
														:key="'docs-'+idx"
														close
														outlined
														color="blue"
														:href="`/api/Documentos/Descargar/${documento.nombre}?access_token=${token}`"
														@click:close="removerRecurso(item,documento)"
													>
														<v-icon left>mdi-file-outline</v-icon>
														{{ $utils.strings.truncateElipsis(documento.nombre) }}
														<v-icon right size="18" class="pr-1">mdi-download</v-icon>
													</v-chip>
													<v-chip
														v-for="(link, idx) in item.recursos.links"
														:key="'links-'+idx"
														close
														outlined
														color="blue"
														:href="link.url.includes('http') ? link.url : `https://${link.url}`"
														target="_blank"
														@click:close="removerRecurso(item, link)"
													>
														<v-icon left>mdi-link</v-icon>
														{{ $utils.strings.truncateElipsis(link.titulo) }}
														<v-icon right size="18" class="pr-1">mdi-open-in-new</v-icon>
													</v-chip>
												</v-chip-group>
												<div class="text-center">
													<v-btn
														color="light-blue lighten-1"
														outlined
														dark
														x-small
														@click="
															showDialogRecursos(
																item.comprobacion
															)
														"
														>Agregar Recursos</v-btn
													>
												</div>
											</v-col>
										</v-row>
									</v-container>
								</td>
								<td class="text-center pl-2" style="width: 5%">
									<v-tooltip
										bottom
										color="black"
										class="white--text"
									>
										<template v-slot:activator="{ on }">
											<v-icon
												v-if="item.tipoComprobacion != null"
												v-on="on"
												small
												@click="clonarComprobacion(item,compIdx)"
											>
												mdi-content-duplicate
											</v-icon>
										</template>
										<span class="white--text"
											>Clonar</span
										>
									</v-tooltip>
									<v-tooltip
										bottom
										color="black"
										class="white--text"
									>
										<template v-slot:activator="{ on }">
											<v-icon
												v-on="on"
												small
												@click="
													removerComprobacion(item)
												"
											>
												mdi-delete
											</v-icon>
										</template>
										<span class="white--text"
											>Eliminar</span
										>
									</v-tooltip>
								</td>
							</tr>
						</template>
					</v-data-table>
					<div class="text-center">
						<v-btn
							color="green"
							class="my-2"
							outlined
							@click="agregarComprobacion"
							>Agregar Comprobación</v-btn
						>
					</div>
				</v-expansion-panel-content>
			</v-expansion-panel>
		</v-expansion-panels>
	</v-col>
	<!-- </v-row> -->
</template>

<script>
import Sortable from "sortablejs";
import axios from "axios";
import { mapState } from 'vuex';

export default {
	props: {
		item: {
			type: Object,
			default: () => ({}),
		}
	},
	data() {
		return {
			validTitle: false,
			panel: 0,
			panelComprobacion: false,
			subiendoArchivos: false,
			itemRecurso: null,
			defaultItemComprobacion: {
				idMantenimientoComprobacion: -1,
				comprobacion: null,
				tipo: null,
				errorTipo: null,
				errorTitulo: null,
				errorDocumento: null,
				errorLink: null,
				recursos: {
					titulo: null,
					idDocumento: null,
					idsDocumentos: [],
					links: [],
				},
				tipoComprobacion: 1,
				numOpciones: 2,
				opciones: [],
				errorNombre: null,
				errorTipoComprobacion: null
			},
			newIndex: 0,
			tiposRecursos: [
				{
					id: 1,
					tipo: "Interno",
				},
				{
					id: 2,
					tipo: "Externo",
				},
			],
			dialogRecursos: false,
			loading: false,
			drag: false,
			urlValida: false,
			headers: [
				{
					sortable: false,
				},
				{
					text: "Comprobación",
					align: "center",
					sortable: false,
					value: "comprobacion",
				},
				{
					text: "",
					align: "center",
					sortable: false,
					value: "recursos",
				},
				{
					text: "",
					align: "center",
					sortable: false,
					value: "actions"
				},
			],
			rules: {
				validarURL: (value) => {
					const expression =
						/[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)?/gi;
					const regex = new RegExp(expression);
					return value.match(regex)
				},
			},
		};
	},
	computed: {
		...mapState(['token'])
	},
	beforeMount(){
		this.itemRecurso = structuredClone(this.defaultItemComprobacion);
	},
	mounted(){
		this.crearDragable();
	},
	methods: {
		clonarComprobacion(item,compIdx){
			const cloneItem = structuredClone(item);
			this.newIndex -= 1;
			cloneItem.idMantenimientoComprobacion = this.newIndex;
			cloneItem.comprobacion = null;
			this.item.comprobaciones.splice(compIdx + 1, 0,cloneItem);
		},
		onChangeTipoComprobacion(item){
			if(item.tipoComprobacion == 1){
				this.$set(item,'numOpciones',2);
				this.$set(item,'opciones',[]);
			}
			else if(item.tipoComprobacion == 2){
				this.$set(
					item,
					'opciones',
					[
						structuredClone(this.$utils.mantenimientos.defaultOpcionItem),
						structuredClone(this.$utils.mantenimientos.defaultOpcionItem)
					]
				);
			}
		},
		onChangeNumOpciones(item){
			if(item.numOpciones < item.opciones.length){
				item.opciones.splice(-1,1);
			}
			else if(item.numOpciones > item.opciones.length){
				item.opciones.splice(item.opciones.length, 0, structuredClone(this.$utils.mantenimientos.defaultOpcionItem));
			}
		},
		onChangeTipo(){
			this.$set(this.itemRecurso.recursos,'titulo',null);
			this.$set(this.itemRecurso.recursos,'idDocumento',null);
			this.$set(this.itemRecurso.recursos,'links',[]);
			this.resetErroresRecursos();
		},
		togglePanel(value){
			this.newIndex = 0;
			this.panel = value;
		},
		resetErrores(){
			for (const comprobacion of this.item.comprobaciones) {
				for (const key in comprobacion) {
					if(key.toLocaleLowerCase().includes('error')){
						this.$set(comprobacion,key,null);
					}
				}

				for (const opcion of comprobacion.opciones) {
					for (const key in opcion) {
						if(key.toLocaleLowerCase().includes('error')){
							this.$set(opcion,key,null);
						}
					}
				}
			}
		},
		validate(){
			this.resetErrores();
			let haveErrors = false;

			for (const comp of this.item.comprobaciones) {
				if(!comp.comprobacion){
					this.$set(comp,'errorNombre','Requerido');
					haveErrors = true;
				}

				// if(comp.tipoComprobacion == null){
				// 	this.$set(comp,'errorTipoComprobacion','Requerido');
				// 	haveErrors = true;
				// }

				if(comp.tipoComprobacion == 2){
					for (const opcion of comp.opciones) {
						if(!opcion.nombre){
							this.$set(opcion,'errorOpcion','Requerido');
							haveErrors = true;
						}
					}
				}
			}

			return !haveErrors;
		},
		getListado() {
			const listado = this.item.comprobaciones.map((item) => ({
				idMantenimientoComprobacion:
					item.idMantenimientoComprobacion == null
						? -1
						: item.idMantenimientoComprobacion,
				comprobacion: item.comprobacion,
				idsDocumentos: item.recursos.idsDocumentos.map(
					(documento) => documento.idDocumento
				),
				links: item.recursos.links,
				tipoComprobacion: item.tipoComprobacion,
				opciones: item.opciones.map( (opcion) => opcion.nombre )
			}));
			return listado;
		},
		crearDragable() {
			new Sortable(
				this.$refs.listadotodo.$el.getElementsByTagName("tbody")[0],
				{
					draggable: ".sortableRow",
					handle: ".sortHandle",
					onEnd: this.dragReorder,
				}
			);
			this.panel = 0;
		},
		reset() {
			this.itemRecurso = structuredClone(this.defaultItemComprobacion);
		},
		removerComprobacion(itemDelete) {
			this.$set(this.item,'comprobaciones',this.item.comprobaciones.filter(
				(item) => JSON.stringify(item) != JSON.stringify(itemDelete)
			));
		},
		removerRecurso(itemDelete, resourceDelete) {
			this.$set(this.item,'comprobaciones',this.item.comprobaciones.map((item) => {
				if (JSON.stringify(item) == JSON.stringify(itemDelete)) {
					if (resourceDelete.idDocumento !=null) {
						this.$set(item.recursos,'idsDocumentos',item.recursos.idsDocumentos.filter(
							(doc) => doc.idDocumento != resourceDelete.idDocumento
						));
					} else {
						this.$set(item.recursos,'links',item.recursos.links.filter(
							(link) => link.titulo != resourceDelete.titulo
						));
					}
				}
				return item;
			}));
		},
		agregarComprobacion() {
			this.newIndex -= 1;
			const newObj = structuredClone(this.defaultItemComprobacion);
			newObj.idMantenimientoComprobacion = this.newIndex;
			this.item.comprobaciones.push(newObj);
		},
		resetErroresRecursos(){
			this.$set(this.itemRecurso,'errorTipo',null);
			this.$set(this.itemRecurso,'errorTitulo',null);
			this.$set(this.itemRecurso,'errorDocumento',null);
			this.$set(this.itemRecurso,'errorLink',null);
		},
		validateRecurso(){
			this.resetErroresRecursos();
			let hayErrores = false;

			if(this.itemRecurso.tipo == null){
				this.$set(this.itemRecurso,'errorTipo','Requerido');
				hayErrores = true;
			}
			else if (this.itemRecurso.tipo == 1){
				if(this.itemRecurso.recursos.titulo == null){
					this.$set(this.itemRecurso,'errorTitulo','Requerido');
					hayErrores = true;
				}
				else if(this.itemRecurso.recursos.titulo.length < 5){
					this.$set(this.itemRecurso,'errorTitulo','Mínimo 5 caracteres');
					hayErrores = true;
				}
				
				if(this.itemRecurso.recursos.idDocumento == null){
					this.$set(this.itemRecurso,'errorDocumento','Requerido');
					hayErrores = true;
				}
			}
			else if (this.itemRecurso.tipo == 2){
				if(this.itemRecurso.recursos.titulo == null){
					this.$set(this.itemRecurso,'errorTitulo','Requerido');
					hayErrores = true;
				}
				else if(this.itemRecurso.recursos.titulo.length < 5){
					this.$set(this.itemRecurso,'errorTitulo','Mínimo 5 caracteres');
					hayErrores = true;
				}

				if(
					this.itemRecurso.recursos.links == null ||
					this.itemRecurso.recursos.links?.length == 0
				){
					this.$set(this.itemRecurso,'errorLink','Requerido');
					hayErrores = true;
				}
				else if(!this.rules.validarURL(this.itemRecurso.recursos.links)){
					this.$set(this.itemRecurso,'errorLink','URL invalida');
					hayErrores = true;
				}
			}

			return !hayErrores;
		},
		agregarRecurso() {
			if(!this.validateRecurso()) return;

			if (this.itemRecurso.tipo == 2) {
				this.$set(this.item,'comprobaciones',this.item.comprobaciones.map((item) => {
					if (item.comprobacion == this.itemRecurso.comprobacion) {
						item.recursos.links.push({
							titulo: this.itemRecurso.recursos.titulo,
							url: this.itemRecurso.recursos.links
						});
					}
					return item;
				}));
				this.cerrarDialogRecursos();
			}

			if (this.itemRecurso.tipo == 1) {
				let documentos = new FormData();
				documentos.append("ficheros", this.itemRecurso.recursos.idDocumento);

				this.subiendoArchivos = true;

				axios
					.post(
						`/Documentos/Cargar?titulo=${this.itemRecurso.recursos.titulo}`,
						documentos,
						{
							headers: {
								"Content-Type": "multipart/form-data",
							},
						}
					)
					.then((response) => {
						const documentosSubidos = response.data.map((doc) => ({
							idDocumento: doc.idDocumento,
							nombre: doc.nombre,
						}));

						if (documentosSubidos.length > 0) {
							this.$set(this.item,'comprobaciones',this.item.comprobaciones.map((item) => {
								if (
									item.comprobacion ==
									this.itemRecurso.comprobacion
								) {
									item.recursos.idsDocumentos =
										item.recursos.idsDocumentos.concat(
											documentosSubidos
										);
								}
								return item;
							}));
						}
					})
					.catch((error) => {
						console.log(error);
					})
					.finally(() => {
						this.subiendoArchivos = false;
						this.cerrarDialogRecursos();
					});
			}
		},
		showDialogRecursos(itemComprobacion) {
			this.itemRecurso.comprobacion = itemComprobacion;
			this.dialogRecursos = true;
		},
		cerrarDialogRecursos() {
			this.itemRecurso = structuredClone(this.defaultItemComprobacion)
			this.dialogRecursos = false;
		},
		cambioFicheroRecurso(event) {
			if (typeof event === "undefined" || event === null)
				this.itemRecurso.recursos.idDocumento = null;
			else this.itemRecurso.recursos.idDocumento = event;
		},
		dragReorder({ oldIndex, newIndex }) {
			const movedItem = this.item.comprobaciones.splice(oldIndex, 1)[0];
			this.item.comprobaciones.splice(newIndex, 0, movedItem);
		},
	},
};
</script>