<template>
		<v-card elevation="0">
			<v-dialog v-model="dialogDelete" persistent max-width="40%">
				<v-card>
					<v-card-title style="word-break: break-word"
						>¿Está seguro que desea eliminar este
						Indicador?</v-card-title
					>
					<v-card-text>
						<p class="subtitle-1 text--primary">
							Esta acción no se puede revertir y será permanente.
						</p>
					</v-card-text>
					<v-card-actions>
						<v-spacer></v-spacer>
						<v-btn
							text
							color="success"
							:disabled="deleting"
							@click="closeDialogDelete"
							>Cancelar</v-btn
						>
						<v-btn
							text
							color="error"
							:disabled="deleting"
							:loading="deleting"
							@click="deleteIndicador"
							>Eliminar</v-btn
						>
					</v-card-actions>
				</v-card>
			</v-dialog>
			<v-dialog v-model="dialog" persistent max-width="30%">
				<v-card>
					<v-card-title
						>{{
							editedItem.idMaquinaInstanciaGraficaDinamica == null
								? "Crear"
								: "Configuración del"
						}}
						Indicador</v-card-title
					>
					<v-card-text>
						<v-container fluid>
							<v-row>
								<v-col cols="12">
									<v-autocomplete
										v-model="editedItem.clave"
										label="Parámetro"
										:items="instanciaKeysComputed"
										:loading="loadingKeysInstancias"
										:disabled="
											loadingKeysInstancias || saving
										"
										:error-messages="errorClave"
										@focus="errorClave = ''"
									></v-autocomplete>
								</v-col>
								<v-col cols="12">
									<v-text-field
										v-model="editedItem.nombre"
										label="Título"
										clearable
										:disabled="saving"
										:error-messages="errorNombre"
										@focus="errorNombre = ''"
									></v-text-field>
								</v-col>
								<v-col cols="6">
									<v-text-field
										v-model="editedItem.sufijo"
										label="Unidad"
										:disabled="saving"
										clearable
									></v-text-field>
								</v-col>
								<v-col cols="6">
									<v-text-field
										v-model="editedItem.prefijo"
										label="Abreviatura"
										:disabled="saving"
										clearable
									></v-text-field>
								</v-col>
								<v-col cols="6">
									<v-text-field
										v-model="editedItem.limiteInferior"
										label="Limite Inferior"
										type="number"
										hide-spin-buttons
										clearable
										:disabled="saving"
										:error-messages="errorLimiteInferior"
										@focus="errorLimiteInferior = ''"
									></v-text-field>
								</v-col>
								<v-col cols="6">
									<v-text-field
										v-model="editedItem.limiteSuperior"
										label="Limite Superior"
										type="number"
										hide-spin-buttons
										clearable
										:disabled="saving"
										:error-messages="errorLimiteSuperior"
										@focus="errorLimiteSuperior = ''"
									></v-text-field>
								</v-col>
							</v-row>
						</v-container>
					</v-card-text>
					<v-card-actions>
						<v-btn
							v-if="
								editedItem.idMaquinaInstanciaGraficaDinamica !=
								null
							"
							text
							color="error"
							:disabled="deleting || saving"
							:loading="deleting"
							@click="showDialogDelete"
							>Eliminar</v-btn
						>
						<v-spacer></v-spacer>
						<v-btn
							text
							color="primary"
							@click="closeDialog"
							:disabled="saving"
							>Cancelar</v-btn
						>
						<v-btn
							text
							color="success"
							@click="save"
							:disabled="saving"
							:loading="saving"
							>Guardar</v-btn
						>
					</v-card-actions>
				</v-card>
			</v-dialog>
			
			<v-card-text class="pa-0">
				<v-container fluid>
					<v-row>
						<v-col cols="3">
							<v-text-field
								v-model="search"
								label="Buscar"
								hide-details
								class="mb-4"
								outlined
								single-line
								clearable
								color="teal darken-3"
								prepend-inner-icon="mdi-magnify"
							></v-text-field>
							<div class="listado-container">
								<NoDataOverlayListaMaquinas
									v-if="
										loadingListaMaquinas ||
										listaMaquinasComputed.length == 0
									"
									:loadingListaMaquinas="loadingListaMaquinas || loadingKeysInstancias"
									:noHayMaquinas="
										listaMaquinasComputed.length == 0
									"
									:search="search"
								/>
								<ProyeccionListaMaquinas
									v-else
									:lista="listaMaquinasComputed"
									:loadingDataInstancias="
										loadingDataInstancias || loadingKeysInstancias
									"
									:currentSelection="
										maquinaInstanciaSelected.idMaquinaIns
									"
									:getNombreMaquinaInstancia="
										getNombreMaquinaInstancia
									"
									@select="onSelectMaquinaInstancia"
								/>
							</div>
						</v-col>
						<v-col cols="9">
							<v-row
								class="cards-container d-flex justify-center"
							>
								<v-col cols="12" class="padding-title">
									<p
										class="
											mb-0
											text-h4
											font-weight-medium
											text-center
										"
									>
										{{ nombreMaquinaInstanciaSelected }}
									</p>
								</v-col>
								<template v-if="escenarioInvalido">
									<NoDataOverlayIndicadores
										:hayMaquinaInstanciaSeleccionada="
											hayMaquinaInstanciaSeleccionada
										"
										:loadingDataInstancias="
											loadingListaMaquinas || loadingKeysInstancias
										"
										:hayMaquinas="listaMaquinas.length > 0"
										:noHayData="
											dataInstancias.length == 0 ||
											listaMaquinas.length == 0
										"
									/>
								</template>
								<template v-else>
									<div
										v-for="(
											instancia, index
										) in dataInstancias"
										:key="index"
										class="pa-2"
									>
										<ProyeccionMaquinaCard
											:id="`card-${index}`"
											:key="`maquina-card-${index}`"
											:idMaquinaIns="
												maquinaInstanciaSelected.idMaquinaIns
											"
											:instancia="instancia"
											@clickAlert="onClickAlert"
											@clickConfig="showDialog"
										/>
									</div>
								</template>
							</v-row>
						</v-col>
					</v-row>
					<v-fab-transition>
						<v-btn
							v-if="
								hayMaquinaInstanciaSeleccionada &&
								instanciaKeys.length > 0
							"
							color="success"
							fab
							dark
							bottom
							right
							fixed
							class="custom-fixed-button"
							@click="showDialog"
						>
							<v-icon large>mdi-plus</v-icon>
						</v-btn>
					</v-fab-transition>
				</v-container>
			</v-card-text>
		</v-card>
	
</template>

<script>
import axios from "axios";
import ProyeccionListaMaquinas from "./Proyeccion/ProyeccionListaMaquinas.vue";
import ProyeccionMaquinaCard from "./Proyeccion/ProyeccionMaquinaCard.vue";
import NoDataOverlayListaMaquinas from "./Proyeccion/NoDataOverlayListaMaquinas.vue";
import NoDataOverlayIndicadores from "./Proyeccion/NoDataOverlayIndicadores.vue";

export default {
	components: {
		ProyeccionMaquinaCard,
		ProyeccionListaMaquinas,
		NoDataOverlayListaMaquinas,
		NoDataOverlayIndicadores,
	},
	props: {
		value: {
			type: Boolean,
			default: false,
		},
	},
	data() {
		return {
			deleting: false,
			dialogDelete: false,
			saving: false,
			errorClave: "",
			errorNombre: "",
			errorLimiteInferior: "",
			errorLimiteSuperior: "",
			dialog: false,
			editedItem: {
				idMaquinaInstanciaGraficaDinamica: null,
				clave: null,
				nombre: null,
				sufijo: null,
				prefijo: null,
				limiteInferior: null,
				limiteSuperior: null,
			},
			defaultEditedItem: {
				idMaquinaInstanciaGraficaDinamica: null,
				clave: null,
				nombre: null,
				sufijo: null,
				prefijo: null,
				limiteInferior: null,
				limiteSuperior: null,
			},
			loadingKeysInstancias: false,
			instanciaKeys: [],
			dataInstancias: [],
			loadingDataInstancias: false,
			maquinaInstanciaSelected: {
				idMaquinaIns: null,
			},
			loadingListaMaquinas: false,
			listaMaquinas: [],
			search: null,
			defaultRadialChartOptions: {
				chart: {
					type: "radialBar",
				},
				colors: ["#FF5722"],
				plotOptions: {
					radialBar: {
						startAngle: -135,
						endAngle: 225,
						hollow: {
							size: "65%",
						},
						dataLabels: {
							show: false,
						},
					},
				},
				fill: {
					type: "gradient",
					gradient: {
						type: "horizontal",
						gradientToColors: ["#4CAF50"],
						inverseColors: false,
						stops: [0]
					},
				},
				stroke: {
					lineCap: "round",
				},
			},
			defaultLinealChartOptions: {
				chart: {
					type: "line",
					zoom: {
						enabled: false,
					},
					toolbar: {
						show: false,
					},
				},
				colors: ["#FFFFFF"],
				dataLabels: {
					enabled: false,
				},
				stroke: {
					curve: "smooth",
				},
				grid: {
					row: {
						colors: ["transparent"], // takes an array which will be repeated on columns
						opacity: 0.5,
					},
				},
				xaxis: {
					categories: [],
					labels: {
						style: {
							colors: "white",
						},
					},
					tooltip: {
						enabled: false,
					},
				},
				yaxis: {
					title: {
						rotate: -90,
						style: {
							color: "#fff"
						}
					},
					labels: {
						style: {
							colors: "white",
						},
					},
				},
			},
		};
	},
	created(){
		this.initialize()
	},
	computed: {
		hayMaquinaInstanciaSeleccionada() {
			return this.maquinaInstanciaSelected.idMaquinaIns != null;
		},
		instanciaKeysComputed() {
			const instanciaKeysFiltred = this.instanciaKeys.slice();

			if (this.editedItem.idMaquinaInstanciaGraficaDinamica != null) {
				instanciaKeysFiltred.unshift(this.editedItem.clave);
			}

			return instanciaKeysFiltred;
		},
		escenarioInvalido() {
			return (
				!this.hayMaquinaInstanciaSeleccionada ||
				this.loadingDataInstancias ||
				this.dataInstancias.length == 0 ||
				this.listaMaquinas.length == 0
			);
		},
		listaMaquinasComputed() {
			if (!this.search) return this.listaMaquinas;

			const filtrado = [];
			this.listaMaquinas.forEach((maquina) => {
				const instanciasFiltradas = maquina.maquinaInstancias.filter(
					(instancia) =>
						this.getNombreMaquinaInstancia(maquina, instancia)
							.toLowerCase()
							.includes(this.search.toLowerCase())
				);

				if (instanciasFiltradas.length > 0) {
					filtrado.push({
						...maquina,
						maquinaInstancias: instanciasFiltradas,
					});
				}
			});

			return filtrado;
		},
		nombreMaquinaInstanciaSelected() {
			return this.maquinaInstanciaSelected?.nombre || "";
		},
	},
	watch: {
		value() {
			if (this.value) {
				this.initialize();
			} else {
				this.$nextTick(() => {
					this.resetValues();
				});
			}
		},
	},
	methods: {
		getNombreMaquina: ({ marca, modelo }) => `${marca} ${modelo}`,
		getNombreMaquinaInstancia: ({ marca, modelo }, { codigo }) =>
			`[${codigo}] - ${marca} ${modelo}`,
		resetValues() {
			this.search = null;
			this.maquinaInstanciaSelected = {
				idMaquinaIns: null,
			};
			this.instanciaKeys = [];
			this.dataInstancias = [];
			this.listaMaquinas = [];
		},
		deleteIndicador() {
			this.deleting = true;

			axios
				.put(
					`/GraficaDinamica/EliminarMaquinaInsGraficaDinamica/${this.editedItem.idMaquinaInstanciaGraficaDinamica}`
				)
				.then(() => {
					this.deleting = false;
					this.closeDialogDelete();
					this.closeDialog();
					this.getDatosInstancias();
					this.getKeysMaquinasInstancias();
				})
				.catch((error) => {
					this.deleting = false;
					console.log(error);
				});
		},
		onClickAlert(item) {
			const previousStatus = item.notificacion;
			this.$set(item, "notificacion", !item.notificacion);
			axios
				.put(
					`/GraficaDinamica/CambiarNotificaciones/${item.idMaquinaInstanciaGraficaDinamica}?notificacion=${item.notificacion}`
				)
				.catch((error) => {
					this.$set(item, "notificacion", previousStatus);
					console.log(error);
				});
		},
		validate() {
			this.errorClave = "";
			this.errorNombre = "";
			this.errorLimiteInferior = "";
			this.errorLimiteSuperior = "";

			if (this.editedItem.clave == null) {
				this.errorClave = "Requerido";
			}

			if (this.editedItem.nombre == null) {
				this.errorNombre = "Requerido";
			} else if (this.editedItem.nombre.length < 5) {
				this.errorNombre = "Mínimo 5 caracteres";
			}

			if (isNaN(parseFloat(this.editedItem.limiteInferior))) {
				this.errorLimiteInferior = "Cantidad invalida";
			}
			if (isNaN(parseFloat(this.editedItem.limiteSuperior))) {
				this.errorLimiteSuperior = "Cantidad invalida";
			}

			if (
				parseFloat(this.editedItem.limiteInferior) >
				parseFloat(this.editedItem.limiteSuperior)
			) {
				this.errorLimiteInferior = "Cantidad invalida";
			}

			return (
				this.errorClave == "" &&
				this.errorNombre == "" &&
				this.errorLimiteInferior == "" &&
				this.errorLimiteSuperior == ""
			);
		},
		save() {
			if (!this.validate()) return;

			this.saving = true;

			axios({
				url:
					this.editedItem.idMaquinaInstanciaGraficaDinamica == null
						? "/GraficaDinamica/CrearMaquinaInsGraficaDinamica"
						: `/GraficaDinamica/ActualizarMaquinaInsGraficaDinamica/${this.editedItem.idMaquinaInstanciaGraficaDinamica}`,
				method:
					this.editedItem.idMaquinaInstanciaGraficaDinamica == null
						? "POST"
						: "PUT",
				data: {
					...this.editedItem,
					idMaquinaIns: this.maquinaInstanciaSelected.idMaquinaIns,
					limiteInferior: parseFloat(this.editedItem.limiteInferior),
					limiteSuperior: parseFloat(this.editedItem.limiteSuperior),
				},
			})
				.then(() => {
					this.saving = false;
					this.closeDialog();
					this.getDatosInstancias();
					this.getKeysMaquinasInstancias();
				})
				.catch((error) => {
					this.saving = false;
					console.log(error);
				});
		},
		resetErrores() {
			this.errorClave = "";
			this.errorNombre = "";
			this.errorLimiteInferior = "";
			this.errorLimiteSuperior = "";
		},
		closeDialogDelete() {
			this.dialogDelete = false;
		},
		closeDialog() {
			this.dialog = false;
			this.dialogDelete = false;
			this.$nextTick().then(() => {
				this.editedItem = Object.assign({}, this.defaultEditedItem);
				this.resetErrores();
			});
		},
		showDialogDelete() {
			this.dialogDelete = true;
		},
		showDialog(item = null) {
			this.editedItem = Object.assign({}, item || this.defaultEditedItem);
			this.dialog = true;
		},
		onSelectMaquinaInstancia(maquina, instancia) {
			if (!instancia) {
				this.maquinaInstanciaSelected = {
					idMaquinaIns: null,
				};
				return;
			}

			this.maquinaInstanciaSelected = { ...instancia };
			this.maquinaInstanciaSelected.nombre =
				this.getNombreMaquinaInstancia(maquina, instancia);

			this.getDatosInstancias();
			this.instanciaKeys = [];
			this.getKeysMaquinasInstancias();
		},
		initialize() {
			this.getListaMaquinas();
			//this.getKeysMaquinasInstancias()
			//this.getDatosInstancias()
		},
		getKeysMaquinasInstancias() {
			this.loadingKeysInstancias = true;

			axios
				.get("/SensorDatos/KeysMaquinaIns", {
					params: {
						idMaquinaIns:
							this.maquinaInstanciaSelected.idMaquinaIns,
						disponibles: true,
					},
				})
				.then((response) => {
					this.instanciaKeys = response.data;
				})
				.catch((error) => {
					this.instanciaKeys = [];
					console.log(error);
				})
				.finally(() => {
					this.loadingKeysInstancias = false;
				});
		},
		getDatosInstancias() {
			this.loadingDataInstancias = true;

			axios
				.get(
					`/GraficaDinamica/GraficasDinamicasMaquinaIns/${this.maquinaInstanciaSelected.idMaquinaIns}`
				)
				.then((response) => {
					this.dataInstancias = response.data;
					this.procesarArregloRespuesta(this.dataInstancias);
				})
				.catch((error) => {
					this.dataInstancias = [];
					console.log(error);
				})
				.finally(() => {
					this.loadingDataInstancias = false;
				});
		},
		getListaMaquinas() {
			this.loadingListaMaquinas = true;
			axios
				.get("/GraficaDinamica/ListarMaquinasInterfaz", {
					params: {
						codigo: this.search,
					},
				})
				.then((response) => {
					this.listaMaquinas = response.data;
				})
				.catch(console.log)
				.finally(() => {
					this.loadingListaMaquinas = false;
				});
		},
		procesarArregloRespuesta(arreglo) {
			arreglo.forEach((item) => {
				item.chartOptions = JSON.parse(
					JSON.stringify(this.defaultRadialChartOptions)
				);
				item.linealChartOptions = JSON.parse(
					JSON.stringify(this.defaultLinealChartOptions)
				);
			});
		},
	},
};
</script>
<style scoped>
.cards-container {
	max-height: calc(100vh - 5.625rem);
	overflow-y: auto;
}

.v-dialog__content >>> .v-dialog--fullscreen {
	overflow-y: hidden !important;
}

.listado-container {
	max-height: calc(100vh - 10.9375rem);
	overflow-y: auto;
}

.custom-fixed-button.v-btn--fixed.v-btn--bottom {
	bottom: 0.125rem;
}

.custom-fixed-button.v-btn--fixed.v-btn--right {
	right: 0.5rem;
}

.padding-title {
	padding-top: 1.125rem !important;
	padding-bottom: 1.125rem !important;
	padding-left: 0;
	padding-right: 0;
}
</style>