<template>
  <div v-if="permisoVista('gantt','r')">
    <v-row class="ma-0 pa-0">
      <v-col>
        <v-slider
          v-model="zoom"
          min="0"
          max="5"
          label="Zoom"
          dense
          hide-details
          @change="timeInterval"
        ></v-slider>
      </v-col>
    </v-row>
    <FullCalendar
      ref="calendar"
      schedulerLicenseKey="GPL-My-Project-Is-Open-Source"
      :events="events"
      :resources="resources"
      :hiddenDays="hiddenDays"
      :minTime="minTime"
      :maxTime="maxTime"
      :slotWidth="fullcalendar.slotWidth"
      :slotDuration="fullcalendar.slotDuration"
      :nowIndicator="fullcalendar.nowIndicator"
      :locale="fullcalendar.locale"
      :editable="fullcalendar.editable"
      :plugins="fullcalendar.calendarPlugins"
      :now="fullcalendar.now"
      :aspectRatio="fullcalendar.aspectRatio"
      :scrollTime="fullcalendar.scrollTime"
      :header="fullcalendar.header"
      :defaultView="fullcalendar.defaultView"
      :views="fullcalendar.views"
      :resourceLabelText="fullcalendar.resourceLabelText"
      :resourcesInitiallyExpanded="fullcalendar.resourcesInitiallyExpanded"
      :eventRender="eventRender"
    />
  </div> 
</template>

<script>
import FullCalendar from '@fullcalendar/vue'
import resourceTimelinePlugin from '@fullcalendar/resource-timeline'
import interactionPlugin from '@fullcalendar/interaction'
import dayGridPlugin from '@fullcalendar/daygrid'
import esLocale from '@fullcalendar/core/locales/es';
import timeGridPlugin from '@fullcalendar/timegrid'
import listPlugin from '@fullcalendar/list'
import axios from 'axios'
import tippy from 'tippy.js';


export default {
  components: {
    FullCalendar
  },
  data: () => ({
    fullcalendar:{
      slotWidth: 100,
      slotDuration: "00:30",
      nowIndicator: true,
      locale: esLocale,
      resourcesInitiallyExpanded: false,
      calendarPlugins: [ interactionPlugin,dayGridPlugin,resourceTimelinePlugin ],
      now: new Date().toISOString(),
      editable: false, // enable draggable events
      aspectRatio: 1.8,
      scrollTime: new Date().toISOString(), // undo default 6am scrollTime
      header: {
        left: 'today prev next',
        center: 'title',
        right: '',
        /*right: 'resourceTimelineLaboralDays,resourceTimelineDetails,resourceTimelineWeek'*/
      },
      defaultView: 'resourceTimelineWeek',
      views: {
        resourceTimelineWeek: {
          type: 'resourceTimeline',
          duration: { days: 14 },
          buttonText: 'Semana'
        },
      },
      resourceLabelText: 'Desglose de Avances',
    },
    resources: [],
    events: [],
    hiddenDays: [],
    minTime: "00:00:00",
    maxTime: "24:00:00",
    zoom:0,
    intervals:[
      "00:30",
      "01:00",
      "02:00",
      "04:00",
      "08:00",
      "12:00",
    ]
  }),
  created(){
    this.initialize();
  },
  methods:{
    initialize () {
      this.getCalendario();
    },
    eventRender: function(info) {
      if(info.event.id.split("-").length == 5){
        tippy(info.el, {
          content: this.get_info(info.event),
          allowHTML: true,
        });
      }
    },
    get_info(event){
      var html = '';
      if(event.extendedProps.info != null){
        if(!event.extendedProps.info.paro){
          html += '<strong>Componente:</strong> &nbsp;'+event.extendedProps.info.componente;
          html += '<br>';
          html += '<strong>Cantidad:</strong> &nbsp;'+event.extendedProps.info.cantidad.toFixed(2);
          html += '<br>';
          html += '<strong>Área:</strong> &nbsp;'+event.extendedProps.info.area;
          html += '<br>';
          if(event.extendedProps.info.status == 0){
            html += '<strong>Estado:</strong> &nbsp Operación Planeada';
            html += '<br>';
            html += '<strong>Inicio Planeado:</strong> &nbsp;'+event.extendedProps.info.inicio;
            html += '<br>';
            html += '<strong>Fin Planeado:</strong> &nbsp;'+event.extendedProps.info.fin;
            html += '<br>';
          }

          else if(event.extendedProps.info.status == 1){
            html += '<strong>Estado:</strong> &nbsp Operación Estimada';
            html += '<br>';
            html += '<strong>Inicio Estimado:</strong> &nbsp;'+event.extendedProps.info.inicio;
            html += '<br>';
            html += '<strong>Fin Estimado:</strong> &nbsp;'+event.extendedProps.info.fin;
            html += '<br>';
          }

          else{
            html += '<strong>Estado:</strong> &nbsp Operación Realizada';
            html += '<br>';
            html += '<strong>Inicio:</strong> &nbsp;'+event.extendedProps.info.inicio;
            html += '<br>';
            html += '<strong>Finalizo:</strong> &nbsp;'+event.extendedProps.info.fin;
            html += '<br>';
          }
        }
        
        else{
          html += '<strong>'+event.extendedProps.info.nombre+'</strong> &nbsp;';
          html += '<br>';
          html += '<strong>Motivo:</strong> &nbsp;'+event.extendedProps.info.motivo;
          html += '<br>';
          html += '<strong>Inicio:</strong> &nbsp;'+event.extendedProps.info.inicio;
          html += '<br>';
          html += '<strong>Finalizo:</strong> &nbsp;'+event.extendedProps.info.fin;
          html += '<br>';
        }
      }
      return html;
    },
    getCalendario(){
      axios
        .get("/TrabajoOrdenes/Calendario2")
        .then(response =>{
          this.resources = response.data.resources;
          this.events = response.data.events;
          this.hiddenDays = response.data.hiddenDays;
          this.minTime = response.data.minTime;
          this.maxTime = response.data.maxTime;
          if(this.permisoVista('listas','r'))this.$refs.calendar.$emit('refetch-events')
        })
        .catch(error => {
          console.log(error);
        })
    },
    timeInterval(){
      this.fullcalendar.slotDuration = this.intervals[this.zoom];
      var now = new Date();
      //var rangeStart = this.$refs.calendar.state.dateProfile.renderRange.start;
      // attention here
      //this.$refs.calendar.scrollToTime(now - rangeStart);
    }
  }
}

</script>

<style>

  body {
    margin: 0;
    padding: 0;
    font-family: Arial, Helvetica Neue, Helvetica, sans-serif;
    font-size: 14px;
  }

  #calendar {
    max-width: 900px;
    margin: 50px auto;
  }

  .fc-button-primary {
    background-color: #1976d2 !important;
    border-color: #1976d2 !important;
    color: white !important;
  }

  .fc-button .fc-icon {
    vertical-align: bottom;
    color: white;
  }

</style>

<style lang='scss'>

@import '~@fullcalendar/core/main.css';
@import '~@fullcalendar/timeline/main.css';
@import '~@fullcalendar/resource-timeline/main.css';

</style>