<template>
  <div>
    <!-- <vue-navigation-bar :options="navbarOptions" @vnb-item-clicked="cambio"/> -->
    <v-tabs
      class="pt-2"
			v-model="tab"
			background-color="transparent"
			grow
			show-arrows
      centered
      @change="cambio"
		>
      <v-tabs-slider></v-tabs-slider>
      <v-tab v-if="tipo==1 && mostrarVista('dashboard')" href="#g1">
				Producción Planeada<br/>vs Capacidad Productiva
			</v-tab>

      <v-tab v-if="tipo==1 && mostrarVista('dashboard')" href="#g2">
				Producción Objetivo<br/>vs Real
			</v-tab>

      <v-tab v-if="tipo==1 && mostrarVista('dashboard')" href="#g3">
				Acumulado Estimado<br/>vs Realizado
			</v-tab>

      <v-tab v-if="tipo==1 && mostrarVista('dashboard')" href="#g4">
				Reporte Piezas<br/>Ok/No Ok
			</v-tab>

      <v-tab v-if="tipo==1 && mostrarVista('dashboard')" href="#g5">
				Producción Tiempo Real<br/>Específico por Máquina y Artículo
			</v-tab>

      <v-tab v-if="tipo==1 && mostrarVista('dashboard')" href="#g6">
				Producción Acumulada<br/>Específica
			</v-tab>

      <v-tab v-if="tipo==1 && mostrarVista('dashboard')" href="#g7">
				Índice de <br/>Calidad
			</v-tab>

      <v-tab v-if="tipo==1 && mostrarVista('dashboard')" href="#m1">
				Matriz de<br/>Producción
			</v-tab>

      <v-tab v-if="tipo==1 && mostrarVista('dashboard')" href="#m2">
				Matriz OEE
			</v-tab>

      <v-tab v-if="tipo==1 && mostrarVista('dashboard')" href="#m3">
				OEE Avanzado
			</v-tab>

      <v-tab v-if="$utils.isValid(dashboard) && tipo==2" href="#a1">
				Dashboard
			</v-tab>

      <v-tab v-if="$utils.isValid(producto) && tipo==2" href="#a2">
				Reporte por<br/>Artículo
			</v-tab>

      <v-tab v-if="$utils.isValid(maquinas) && tipo==2" href="#a4">
				Reporte de<br/>Máquinas
			</v-tab>

      <v-tab v-if="$utils.isValid(mantenimientos) && tipo==2" href="#a5">
				Reporte de<br/>Mantenimientos
			</v-tab>

      <v-tab v-if="$utils.isValid(remanufactura) && tipo==2" href="#a6">
				Reporte de<br/>Remanufactura
			</v-tab>

      <v-tab v-if="$utils.isValid(ciclos) && tipo==2" href="#a7">
				Reporte de<br/>Tiempos Ciclos
			</v-tab>

    </v-tabs>
    <v-tabs-items
      v-model="tab"
    >
      <v-tab-item :key="'g1'" :value="'g1'">
        <grafica-1 :active="tab=='g1'"></grafica-1>
      </v-tab-item>

      <v-tab-item :key="'g2'" :value="'g2'">
        <grafica-2 :active="tab=='g2'"></grafica-2>
      </v-tab-item>

      <v-tab-item :key="'g3'" :value="'g3'">
        <grafica-3 :active="tab=='g3'"></grafica-3>
      </v-tab-item>

      <v-tab-item :key="'g4'" :value="'g4'">
        <grafica-4 :active="tab=='g4'"></grafica-4>
      </v-tab-item>

      <v-tab-item :key="'g5'" :value="'g5'">
        <grafica-5 :active="tab=='g5'"></grafica-5>
      </v-tab-item>
      
      <v-tab-item :key="'g6'" :value="'g6'">
        <grafica-6 :active="tab=='g6'"></grafica-6>
      </v-tab-item>

      <v-tab-item :key="'g7'" :value="'g7'">
        <grafica-7 :active="tab=='g7'"></grafica-7>
      </v-tab-item>
      
      <v-tab-item :key="'m1'" :value="'m1'">
        <matriz-1 :active="tab=='m1'"></matriz-1>
      </v-tab-item>
      
      <v-tab-item :key="'m2'" :value="'m2'">
        <matriz-2 :active="tab=='m2'" />
      </v-tab-item>

      <v-tab-item :key="'m3'" :value="'m3'">
        <matriz-3 :active="'m3' == tab" />
      </v-tab-item>

      <!--
      <v-tab-item :key="'g8'" :value="'g8'">
        <grafica-8 :active="tab=='g8'"></grafica-8>
      </v-tab-item>
      -->

      <v-tab-item :key="'a1'" :value="'a1'">
        <v-container fluid grid-list-xl>
          <v-layout wrap>
            <iframe id="info" width="100%" height="920" :src="dashboard" frameborder="0" allowFullScreen="true"></iframe>      
          </v-layout>
        </v-container>
      </v-tab-item>

      <v-tab-item :key="'a2'" :value="'a2'">
        <v-container fluid grid-list-xl>
          <v-layout wrap>
            <iframe id="info" width="100%" height="920" :src="productos" frameborder="0" allowFullScreen="true"></iframe>      
          </v-layout>
        </v-container>
      </v-tab-item>

      <!-- <v-tab-item :key="'a3'" :value="'a3'">
        <v-container fluid grid-list-xl>
          <v-layout wrap>
            <iframe id="info" width="100%" height="920" :src="oee" frameborder="0" allowFullScreen="true"></iframe>      
          </v-layout>
        </v-container>
      </v-tab-item> -->

      <v-tab-item :key="'a4'" :value="'a4'">
        <v-container fluid grid-list-xl>
          <v-layout wrap>
            <iframe id="info" width="100%" height="920" :src="maquinas" frameborder="0" allowFullScreen="true"></iframe>      
          </v-layout>
        </v-container>
      </v-tab-item>

      <v-tab-item :key="'a5'" :value="'a5'">
        <v-container fluid grid-list-xl>
          <v-layout wrap>
            <iframe id="info" width="100%" height="920" :src="mantenimientos" frameborder="0" allowFullScreen="true"></iframe>      
          </v-layout>
        </v-container>
      </v-tab-item>

      <v-tab-item :key="'a6'" :value="'a6'">
        <v-container fluid grid-list-xl>
          <v-layout wrap>
            <iframe id="info" width="100%" height="920" :src="remanufactura" frameborder="0" allowFullScreen="true"></iframe>      
          </v-layout>
        </v-container>
      </v-tab-item>

      <v-tab-item :key="'a7'" :value="'a7'">
        <v-container fluid grid-list-xl>
          <v-layout wrap>
            <iframe id="info" width="100%" height="920" :src="ciclos" frameborder="0" allowFullScreen="true"></iframe>      
          </v-layout>
        </v-container>
      </v-tab-item>

      <v-tab-item :key="'a8'" :value="'a8'">
        <v-container fluid grid-list-xl>
          <v-layout wrap>
            <iframe id="info" width="100%" height="920" :src="paros" frameborder="0" allowFullScreen="true"></iframe>      
          </v-layout>
        </v-container>
      </v-tab-item>

    </v-tabs-items>
  </div>
</template>

<script>
import { mapState } from "vuex";
import Grafica1 from "./Dashboards/Grafica1.vue";
import Grafica2 from "./Dashboards/Grafica2.vue";
import Grafica3 from "./Dashboards/Grafica3.vue";
import Grafica4 from "./Dashboards/Grafica4.vue";
import Grafica5 from "./Dashboards/Grafica5.vue";
import Grafica6 from "./Dashboards/Grafica6.vue";
import Grafica7 from "./Dashboards/Grafica7.vue";
import Matriz1 from "./Dashboards/Matriz1.vue"
import Grafica8 from './Dashboards/Grafica8.vue';
import Matriz2 from './Dashboards/Matriz2.vue';
import Matriz3 from './Dashboards/Matriz3.vue';

export default {
  props: {
    tipo: {
      type: Number,
      default: 1
    },
  },
  components:{
    Grafica1,
    Grafica2,
    Grafica3,
    Grafica4,
    Grafica5,
    Grafica6,
    Grafica7,
    Matriz1,
    Grafica8,
    Matriz2,
    Matriz3
  },
  data: () => ({
    tab: null,
  }),
  computed: {
    ...mapState(["dashboard","productos","oee","maquinas","mantenimientos","remanufactura","ciclos","paros"]),
    navbarOptions(){
      var response = {
        elementId: "main-navbar",
        menuOptionsLeft: this.tipo == 1
        ? [
          {
            type: "link",
            text: "Producción",
            arrowColor: "#659CC8",
            subMenuOptions: [
              /*
              {
                isLinkAction: true,
                type: "link",
                text: "Reporte OEE",
              },
              */
              {
                isLinkAction: true,
                type: "link",
                text: "Producción vs Capacidad Productiva",
              },
              {
                isLinkAction: true,
                type: "link",
                text: "Producción Objetivo vs Real",
              },
              {
                isLinkAction: true,
                type: "link",
                text: "Acumulado Estimado vs Realizado"
              },
              {
                isLinkAction: true,
                type: "link",
                text: "Matriz de Producción"
              },
              {
                isLinkAction: true,
                type: "link",
                text: "Matriz OEE"
              },
              {
                isLinkAction: true,
                type: "link",
                text: "OEE Avanzado"
              },
              {
                isLinkAction: true,
                type: "link",
                text: "Producción Tiempo Real"
              },
              {
                isLinkAction: true,
                type: "link",
                text: "Producción Tiempo Real Especifica"
              },
              {
                isLinkAction: true,
                type: "link",
                text: "Producción Acumulada Especifica"
              },
              {
                isLinkAction: true,
                type: "link",
                text: "Índice de Calidad"
              }
            ]
          }
        ]
        : []
      };

      if(this.tipo==2){
        var analitica = {
          type: "link",
          text: "Análitica",
          arrowColor: "#659CC8",
          subMenuOptions: [
          ]
        };
  
        if(this.$utils.isValid(this.dashboard)) analitica.subMenuOptions.push({
          isLinkAction: true,
          type: "link",
          text: "Dashboard"
        });
  
        if(this.$utils.isValid(this.producto)) analitica.subMenuOptions.push({
          isLinkAction: true,
          type: "link",
          text: "Reporte por Artículo"
        });
  
        if(this.$utils.isValid(this.maquinas)) analitica.subMenuOptions.push({
          isLinkAction: true,
          type: "link",
          text: "Reporte de Máquinas"
        });
  
        if(this.$utils.isValid(this.mantenimientos)) analitica.subMenuOptions.push({
          isLinkAction: true,
          type: "link",
          text: "Reporte de Mantenimientos"
        });
  
        if(this.$utils.isValid(this.remanufactura)) analitica.subMenuOptions.push({
          isLinkAction: true,
          type: "link",
          text: "Reporte de Remanufactura"
        });
  
        if(this.$utils.isValid(this.ciclos)) analitica.subMenuOptions.push({
          isLinkAction: true,
          type: "link",
          text: "Reporte de Tiempos Ciclos"
        });
  
        if(analitica.subMenuOptions.length > 0){
          response.menuOptionsLeft.push(analitica);
        }

      }

      return response;
    }
  },
  methods:{
    cambio(evt){
      switch (evt) {
        case 'Producción vs Capacidad Productiva':
          this.tab = 'g1'
          break;
        case 'Producción Objetivo vs Real':
          this.tab = 'g2'
          break;
        case 'Acumulado Estimado vs Realizado':
          this.tab = 'g3'
          break;
        case 'Producción Tiempo Real':
          this.tab = 'g4'
          break;
        case 'Producción Tiempo Real Especifica':
          this.tab = 'g5'
          break;
        case 'Producción Acumulada Especifica':
          this.tab = 'g6'
          break;
        case 'Índice de Calidad':
          this.tab = 'g7'
          break;
        case 'Reporte OEE':
          this.tab = 'g8'
          break;
        case 'Matriz de Producción':
          this.tab = 'm1'
          break;
        case 'Matriz OEE':
          this.tab = 'm2'
          break;
        case 'OEE Avanzado':
          this.tab = 'm3'
          break;
        case 'Dashboard':
          this.tab = 'a1'
          break;
        case 'Reporte por Artículo':
          this.tab = 'a2'
          break;
        case 'Reporte de Máquinas':
          this.tab = 'a4'
          break;
        case 'Reporte de Mantenimientos':
          this.tab = 'a5'
          break;
        case 'Reporte de Remanufactura':
          this.tab = 'a6'
          break;
        case 'Reporte de Tiempos Ciclos':
          this.tab = 'a7'
          break;
        case 'Pareto de Incidencias':
          this.tab = 'a8'
          break;
        default:
          break;
      }
    }
  }
}
</script>