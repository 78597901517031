<template>
  <!-- <v-container> -->
    <v-row justify-sm="center">
      <v-card 
        class="mt-4"
        :width="anchoMaximo"
        fill
      >
        <v-tabs show-arrows v-model="tab" :background-color="colorTabs" centered dark icons-and-text>
          <v-tabs-slider></v-tabs-slider>

          <v-tab v-tooltip.bottom="modoAyuda && $help.compras.evolucionCostos" href="#tab-1" v-if="mostrarVista('evolucioncostos')" @click="updateEvolucionCostos()">
            Evolución de Costos
            <v-icon>mdi-chart-line</v-icon>
          </v-tab>
          
          <v-tab v-tooltip.bottom="modoAyuda && $help.compras.proveedores" href="#tab-2" v-if="mostrarVista('proveedores') || mostrarVista('proveedortipos')" @click="updateProveedores()">
            Proveedores
            <v-icon>mdi-store-outline</v-icon>
          </v-tab>

          <v-tab v-tooltip.bottom="modoAyuda && $help.compras.ingresosMP" href="#tab-3" v-if="mostrarVista('ingresos')" @click="updateIngresos()">
            Ingresos M.P.
            <v-icon>mdi-cart-arrow-down</v-icon>
          </v-tab>

          <v-tab v-tooltip.bottom="modoAyuda && $help.compras.ingresosMP" href="#tab-4" @click="onClickActualizarCostos()">
            Actualizar<br>Costos M.P.
            <v-icon>mdi-update</v-icon>
          </v-tab>

          <v-tab v-tooltip.bottom="modoAyuda && $help.compras.historicoArticulos" href="#tab-5" v-if="mostrarVista('preciohistorico')" @click="updatePrecioArticulos()">
            Histórico Precios<br/>de Articulos
            <v-icon>mdi-chart-histogram</v-icon>
          </v-tab>

        </v-tabs>

        <v-tabs-items v-model="tab">

          <v-tab-item :key="1" :value="'tab-1'" v-if="mostrarVista('evolucioncostos')">
            <v-card flat>
              <v-card-text>
                <comprasEvolucionCostos ref="evolucioncostos"></comprasEvolucionCostos>
              </v-card-text>
            </v-card>
          </v-tab-item>

          <v-tab-item :key="2" :value="'tab-2'">
            <v-card flat>
              <v-card-text>
                <ListaProveedores ref="providers"/>
              </v-card-text>
            </v-card>
          </v-tab-item>

          <v-tab-item :key="3" :value="'tab-3'" v-if="mostrarVista('ingresos')">
            <v-card flat>
              <v-card-text>
                <IngresosMPListado ref="ingresos" />
              </v-card-text>
            </v-card>
          </v-tab-item>

          <v-tab-item :key="4" :value="'tab-4'">
            <v-card flat>
              <v-card-text>
                <ActualizarCostosMP ref="actualizarcostos" />
              </v-card-text>
            </v-card>
          </v-tab-item>

          <v-tab-item :key="5" :value="'tab-5'" v-if="mostrarVista('preciohistorico')">
            <v-card flat>
              <v-card-text>
                <PreciosArticulos ref="updatePrecios" />
              </v-card-text>
            </v-card>
          </v-tab-item>

        </v-tabs-items>
      </v-card>
    </v-row>
  <!-- </v-container> -->
</template>

<script>
import EvolucionCostos from "./Compras/EvolucionCostos.vue";
import PreciosArticulos from './Compras/PreciosArticulos.vue';
import ListaProveedores from './Compras/ListaProveedores.vue';
import { mapState } from 'vuex';
import IngresosMPListado from './Compras/IngresosMPListado.vue';
import ActualizarCostosMP from './Compras/ActualizarCostosMP.vue';

export default {
  data() {
    return {
      tab: null,
      text:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat."
    };
  },
  methods:{
    onClickActualizarCostos(){
      if(typeof this.$refs.actualizarcostos === 'undefined') return;
      this.$refs.actualizarcostos.initialize();
    },
    updateProveedores(){
      if(typeof this.$refs.providers === 'undefined') return;
      this.$refs.providers.initialize();
    },
    updateIngresos(){
      if(typeof this.$refs.ingresos === 'undefined') return;
      this.$refs.ingresos.initialize();
    },
    updateEvolucionCostos(){
      if(typeof this.$refs.evolucioncostos === 'undefined') return;
      this.$refs.evolucioncostos.initialize();
    },
    updatePrecioArticulos(){
      if(typeof this.$refs.updatePrecios === 'undefined') return;
      this.$refs.updatePrecios.initialize();
    }
  },
  computed: {
    ...mapState(["anchoMaximo","colorTabs","modoAyuda"])
  },
  components: {
    ComprasEvolucionCostos: EvolucionCostos,
    PreciosArticulos,
    ListaProveedores,
    IngresosMPListado,
    ActualizarCostosMP
  }
};
</script>