<template>
	<v-container fluid v-if="permisoVista('mant-categorias', 'r')">
		<v-dialog v-model="dialog" persistent max-width="500px">
			<v-card>
				<v-card-title
					>{{
						editedItem.idMantenimientoCategoria == null ? "Agregar" : "Editar"
					}}
					Categoria</v-card-title
				>
				<v-card-text>
					<v-container fluid>
						<v-row>
							<v-col cols="12">
								<v-text-field
									v-model="editedItem.nombre"
									label="Nombre"
									:disabled="saving"
									:error-messages="errorNombre"
                                    clearable
                                    @keyup.enter="save"
									@focus="errorNombre = null"
								></v-text-field>
							</v-col>
						</v-row>
					</v-container>
				</v-card-text>
				<v-card-actions>
					<v-spacer></v-spacer>
					<v-btn
						text
						color="success"
						:disabled="saving"
						@click="closeDialog"
						>Cancelar</v-btn
					>
					<v-btn
						text
						color="error"
						:disabled="saving"
						:loading="saving"
						@click="save"
						>Guardar</v-btn
					>
				</v-card-actions>
			</v-card>
		</v-dialog>
		<v-dialog v-model="dialogDelete" persistent max-width="500px">
			<v-card>
				<v-card-title style="word-break: break-word"
					>¿Está seguro que desea eliminar esta Categoria?</v-card-title
				>
				<v-card-text>
					<p class="subtitle-1 text--secondary">
						Esta acción será permanente y no se puede revertir.
					</p>
				</v-card-text>
				<v-card-actions>
					<v-spacer></v-spacer>
					<v-btn
						color="success"
						:disabled="deleting"
						text
						@click="closeDialog"
						>Cancelar</v-btn
					>
					<v-btn
						color="error"
						:disabled="deleting"
						:loading="deleting"
						text
						@click="deleteItem"
						>Aceptar</v-btn
					>
				</v-card-actions>
			</v-card>
		</v-dialog>

		<v-row
            :class="{
                'justify-space-between': permisoVista('mant-categorias', 'c'),
                'justify-end': !permisoVista('mant-categorias', 'c')
            }"
        >
			<v-col
                cols="4"
            >
				<v-btn
                    v-if="permisoVista('mant-categorias', 'c')"
					color="primary"
					:disabled="loading"
					@click="showDialog()"
				>
					Agregar Categoria
				</v-btn>
			</v-col>

            <v-col cols="4">
                <v-text-field
                    v-model="search"
                    label="Buscar"
                    clearable
                    prepend-inner-icon="mdi-magnify"
                    :disabled="loading"
                ></v-text-field>
            </v-col>
		</v-row>
		<v-row>
			<v-col cols="12">
				<v-data-table
					:items="categorias"
					:headers="categoriasHeaders"
					:loading="loading"
					:search="search"
				>
					<template v-slot:[`item.actions`]="{ item }">
						<v-tooltip bottom color="black" class="white--text">
							<template v-slot:activator="{ on }">
								<v-icon
									v-if="permisoVista('mant-categorias', 'u')"
									v-on="on"
									small
									class="mr-2"
                                    :disabled="loading"
									@click="showDialog(item)"
								>
									edit
								</v-icon>
							</template>
							<span class="white--text">Editar</span>
						</v-tooltip>
						<v-tooltip bottom color="black" class="white--text">
							<template v-slot:activator="{ on }">
								<v-icon
									v-if="permisoVista('mant-categorias', 'd')"
									v-on="on"
									small
                                    :disabled="loading"
									@click="showDialog(item, true)"
								>
									delete
								</v-icon>
							</template>
							<span class="white--text">Eliminar</span>
						</v-tooltip>
					</template>
				</v-data-table>
			</v-col>
		</v-row>
	</v-container>
</template>

<script>
import axios from "axios";
export default {
	data() {
		return {
            deleting: false,
			saving: false,
			errorNombre: null,
			dialogDelete: false,
			dialog: false,
			search: null,
			categorias: [],
			categoriasHeaders: [
				{
					text: "Nombre",
					value: "nombre",
                    align: "center",
                    width: "90%"
				},
				{
					text: "Acciones",
					value: "actions",
                    align: "center",
                    width: "10%"
				},
			],
			loading: false,
			editedItem: null,
			defaultEditedItem: {
				idMantenimientoCategoria: null,
				nombre: null,
			},
		};
	},
    beforeMount(){
        this.editedItem = structuredClone(this.defaultEditedItem);
    },
	mounted() {
		this.initialize();
	},
	methods: {
        deleteItem(){
            this.deleting = true;

            axios
                .put(`/MantenimientoCategorias/Desactivar/${this.editedItem.idMantenimientoCategoria}`)
                .then( () => {
                    this.closeDialog();
                    this.getCategoriasMantenimiento();
                    this.deleting = false;
                })
                .catch( (error) => {
                    this.deleting = false;
                    console.log(error);
                });
        },
		validate() {
			this.errorNombre = null;

			if (!this.editedItem.nombre)
				this.errorNombre = "Requerido";

			return this.errorNombre == null;
		},
		save() {
			if (!this.validate()) return;

			this.saving = true;

			axios({
				url:
					this.editedItem.idMantenimientoCategoria == null
						? "/MantenimientoCategorias/Crear"
						: `/MantenimientoCategorias/Actualizar/${this.editedItem.idMantenimientoCategoria}`,
				method: this.editedItem.idMantenimientoCategoria == null ? "POST" : "PUT",
				data: {
					nombre: this.editedItem.nombre?.trim(),
				},
			})
				.then(() => {
					this.closeDialog();
                    this.getCategoriasMantenimiento();
					this.saving = false;
				})
				.catch((error) => {
					console.log(error);
					this.saving = false;
					if (error.response.status == 409)
						this.errorNombre = "Ya existe";
					else
						this.errorNombre = "Ocurrio un error. Vuelva a intentarlo";
				});
		},
		closeDialog() {
			this.dialog = false;
			this.dialogDelete = false;

			setTimeout(() => {
				this.editedItem = structuredClone(this.defaultEditedItem);
                this.errorNombre = null;
			}, 200);
		},
		showDialog(item = null, showDialogDelete = false) {
			this.editedItem = structuredClone(item || this.defaultEditedItem);

			if (showDialogDelete) this.dialogDelete = true;
			else this.dialog = true;
		},
		initialize() {
            this.search = null;
			this.getCategoriasMantenimiento();
		},
		getCategoriasMantenimiento() {
			this.loading = true;

			axios
				.get("/MantenimientoCategorias")
				.then((response) => {
					this.categorias = response.data;
				})
				.catch((error) => {
					console.log(error);
					this.categorias = [];
				})
				.finally(() => {
					this.loading = false;
				});
		},
	},
};
</script>