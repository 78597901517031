export default {
  install(Vue) {
    Vue.prototype.$help = {
      reportes: {
        favoritos: "Reportes que se han seleccionado como favoritos",
        usabilidad: "Reportes de Usabilidad",
        produccion: "Reportes generales de Producción",
        operadores: "Reportes de Operadores",
        maquinas: "Reportes de Máquinas",
        almacen: "Reportes de Almacén",
        oee: "Reportes de OEE",
        calidad: "Reportes de Calidad",
        analitica: "Reportes históricos para Analítica de datos"
      },
      rh: {
        puestos: "Crear, editar y eliminar Puestos",
        departamentos: "Crear y asignar departamentos al personal",
        horarios: "Crear, editar y eliminar Horarios",
        turnos: "Crear, editar y eliminar Turnos",
        habilidades: "Crear, editar y eliminar Habilidades",
        equipospredefinidos: "Crear, editar y eliminar Equipos Predefinidos de Operadores",
        vendedores: "Crear, editar y eliminar Vendedores",
        operadores: "Listado para detalles, asignar contraseñas, cerrar sesiones y operaciones de Operadores",
        personas: "Crear, editar y eliminar Personas",
        brigadasrh: {
          title: "Listado de brigadas",
          brigadas: "Crear, editar y eliminar Brigadas",
          brigadap: "Crear, editar y eliminar Puestos de Brigadas",
          brigadape: "Crear, editar y eliminar Asiganciones de Personas a Brigadas",
          briarbol: "Ver diagrama de Brigadas"
        },
        organigrama: "Diagrama para visualizar la estructura organizacional de la empresa",
        relojchecador: "Listado de empleados que muestra si han sido vinculados al reloj checador",
        descansoOperadores: "Configurar tiempos de descanso para operadores"
      },
      almacen: {
        articulosInventario: "Listado de artículos de inventario",
        articulosReorden: "Listado de artículos que alcanzaron el punto de reorden",
        articulosFaltantes: "Listado de artículos faltantes",
        leerqr: "Detalles de movimientos mediante lectura de QR",
        estadoproduccion: "Leer código manualmente y agregar Almacén",
        cargasMasivas: {
          title: "Realizar actualizaciones masiva mediante archivos",
          movimientosInventario: "Realizar actualizaciones masivas de movimientos de inventario",
          actualizacionArticulos: "Realizar actualizaciones masivas de artículos",
          cargaArticulos: "Realizar cargas masivas de artículos",
          cargaControlInventario: "Realizar cargas masivas de control de inventario",
          cargaPantografo: "Realizar cargas masivas de pantógrafo",
        },
        movimientos: "Listado de movimientos de inventario",
        ordenesventas: "Listado de ordenes de venta que falta terminarlas de surtir",
        configuracionAlmacen: {
          title: "Configuración de catálogos de almacén",
          unidades: "Configuración del catálogo de Unidades",
          categorias: "Configuración del catálogo de Categorías",
          tipos: "Configuración del catálogo de Tipos de productos",
          clases: "Configuración del catálogo de Clases de Artículos",
          familias: "Configuración del catálogo de Familias de productos",
          calidad: "Configuración del catálogo de Calidad",
          tiempounidades: "Configuración del catálogo de Unidades de Tiempo",
          correos: "Configuración del catálogo de Correos de notificación",
        },
        salidasauto: "Listado de artículos que tienen la opción de salida automática",
        excedentes: "Listado de excedentes de artículos"
      },
      produccion: {
        cargaMasivaBlackDecker: "Realizar la carga masiva de Programación, Inventario, BOM y Familias de Artículos",
        disponibilidadMaquinas: "Listado de máquinas disponibles",
        piezasDisparo: "Configuración del número de piezas que puede disparar una máquina",
        listaODT: "Listado de Ordenes de Trabajo en resumen, en progreso y finalizadas",
        nuevaODT: "Crear una nueva Orden de Trabajo",
        avancesProduccion: "Reportes de avances de producción",
        listaArticulos: "Listado de Ordenes de Trabajo por artículos",
        gantt: "Gráfica de Gantt de las Ordenes de Trabajo",
        actividadesProgramadas: "Listado de actividades programadas en cierto periodo",
        gestionEnsambles: "Activar o desactivar la producción de ensambles",
        reporteArea: "Reporte por Áreas"
      },
      calidad: {
        incidencias: {
          title: "Listado detallado de incidencias de producción",
          incidencias: "Listado detallado de incidencias de produccion desglosadas",
          sinEspecificar: "Listado detallado de incidencias de producción no desglosadas",
        },
        listasComprobacion: {
          title: "Configuración y gráficas de listas de comprobación de calidad",
          resultados: "Reporte y gráficas de las listas de comprobación",
          configuracion: "Crear, editar y eliminar las listas de comprobación",
        },
        bloqueoMaquinaInasistencia: "Maquinas bloqueadas por inasistencias de operadores",
        factoresOEE: "Listado para editar Factores OEE",
        motivosRemanufactura: "Crear, editar y eliminar Motivos de Remanufactura",
        catalogoIncidencias: {
          title: "Listados de Motivos y Submotivos de Incidencias",
          motivos: "Crear, editar y eliminar Motivos de Incidentes",
          submotivos: "Crear, editar y eliminar Submotivos de Incidentes",
        },
        procesosRetrabajo: "Crear, editar y eliminar Procesos de Retrabajo",
      },
      mantenimiento: {
        calendarioMantenimiento: "Calendario de Inspecciones, Mantenimientos Preventivos y Correctivos",
        tablero: "Tablero para asignar actividades de Mantenimiento",
        usoMaquinas: "Historial y graficas del Uso de Maquinas",
        statusMantenimientos: {
          title: "Estados de Mantenimientos e Inspecciones",
          mantPorProgramar: "Listado de Mantenimientos que llegaron al kilometraje programado",
          mantEncurso: "Listado de Mantenimientos que se encuentra en curso",
          mantRealizados: "Listado de Mantenimientos que fueron realizados",
          insCurso: "Listado de Inspecciones que se encuentran en curso",
          insRealizadas: "Listado de Inspecciones que fueron realizadas",
        },
        cargarCostos: "Reporte y desglose de costos de facturas y movimientos",
        bloqueosMaquinas: "Crear o finalizar bloqueos de Máquinas",
        ciclosInspecciones: "Listado de ciclos de Inspecciones que se han completado",
        alertasMantenimiento: "Listado de alertas de mantenimiento",
        catalogosMantenimiento: {
          title: "Catálogos de Inspecciones, Mantenimientos Preventivos y Correctivos",
          proveedores: "Crear, editar y eliminar Proveedores de Mantenimientos",
          categorias: "Crear, editar y eliminar Categorias de Mantenimientos",
          inspecciones: "Crear, editar y eliminar Inspecciones",
          mantPreventivo: "Crear, editar, eliminar y programar Mantenimientos Preventivos",
          mantCorrectivo: "Crear, editar, eliminar, programar y reportar Mantenimientos Correctivos",
          servicios: "Crear, editar y eliminar Servicios de Mantenimiento",
          cambiosMoldes: "Crear, editar y eliminar Cambios de Moldes",
          cambiosTroqueles: "Crear, editar y eliminar Cambios de Troqueles",
        },
      },
      compras: {
        evolucionCostos: "Gráfica de Evolución de Costos",
        proveedores: "Crear y editar Proveedores",
        ingresosMP: "Listado de Ingresos de Materia Prima",
        historicoArticulos: "Crear, autorizar y eliminar solicitudes para actualizar costos de artículos",
      },
      ventas: {
        precioventapublico: "Configurar precios de venta de artículos",
        otssinvendedor: "Listado de Ordenes de Trabajo sin Vendedor",
        publicoventas: "Crear y configurar solicitudes de venta de artículos",
        montos: "Gráfica de Monto de Ventas",
        lineasComerciales: "Crear, editar y eliminar Líneas Comerciales",
        clientes: "Crear y editar Clientes",
      },
      configuracion: {
        planos: "Configuracion de planos y mapeo de areas",
        areas: "Crear, editar, eliminar y ordenar Áreas",
        ubicaciones: "Crear, editar, eliminar y ordenar Ubicaciones",
        almacenes: "Crear y editar los Almacenes",
        maquinasHerramientas: {
          title: "Categorías, grupos, catálogo e inventario de Máquinas y Herramientas",
          categorias: "Crear, editar y eliminar categorias de Máquinas y Herramientas",
          grupos: "Crear, editar y eliminar grupos de Máquinas y Herramientas",
          catalogo: "Crear, editar y eliminar Máquinas y Herramientas",
          inventario: "Crear, editar y eliminar el inventario de Máquinas y Herramientas",
        },
        articulos: "Edición y artículos dependientes de Artículos",
        recetas: "Configuración de Recetas",
        recetasAutomaticas: "Crear, editar y eliminar Recetas Automáticas",
        simulacion: "Configurar los parametros que se usaran para realizar la simulación",
        catsubpasos: "Crear y editar subpasos para Recetas",
        costodestajo: "Crear y modificar configuraciones de costos y tiempos de subpasos",
        usuarios: "Crear, editar y eliminar Usuarios",
        estaciones: "Crear, editar y eliminar estaciones o areas de trabajo",
        sincronizacion: "Historial de sincronizaciones externas realizadas",
      },
      seguridad: {
        control: "Panel de sesiones de operadores y operaciones",
        puntosImpresion: "Configurar los puntos de impresión",
        accesodispositivos: "Listado de los ultimos dispositivos que han accedido",
        permisos: "Crear, editar y eliminar roles y permisos de vistas",
        logs: "Registros de acciones en vistas que ha realizado el usuario",
      },
      perfil: {
        procesosCurso: "Listado y progreso de reportes generados",
        configuracion: "Cambiar contraseña y email del usuario"
      }
    }
  }
};