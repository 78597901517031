<template>
	<v-card
		class="my-4 mr-4 custom-card-class"
		:color="color"
		@click="onClickCard()"
	>
		<v-container
			fluid
			class="pa-0 fill-height"
			:class="{
				'justify-center': tipo == 'icon',
			}"
		>
			<v-btn
				v-if="tipo == 'image'"
				class="button-delete-small"
				fab
				color="error"
				top
				right
				absolute
				elevation="2"
				@click.stop.prevent="onClickDelete()"
			>
				<v-icon color="white" size="16">mdi-close</v-icon>
			</v-btn>
			<template v-if="tipo == 'image'">
				<v-col cols="12" class="pa-0 text-center">
					<div v-if="loadingImage" class="text-center">
						<v-progress-circular
							:size="50"
							color="primary"
							indeterminate
						></v-progress-circular>
						<p class="mb-0 text-subtitle-2">Cargando imagen</p>
					</div>
					<template v-else>
						<v-scale-transition hide-on-leave>
							<v-img
								v-if="localImage"
								:src="localImage"
								height="140"
							></v-img>
							<v-icon
								v-else
								size="70"
								color="grey"
								style="height: 140px"
								>mdi-file-image</v-icon
							>
						</v-scale-transition>
					</template>
				</v-col>
				<v-col cols="12" class="pa-0">
					<v-simple-table dense>
						<template v-slot:default>
							<tbody>
								<tr>
									<td
										class="text-center font-weight-bold"
										style="width: 40%"
									>
										Código del Molde
									</td>
									<td class="text-center">
										{{ molde.codigo | trimmText }}
									</td>
								</tr>
							</tbody>
						</template>
					</v-simple-table>
				</v-col>
			</template>
			<div v-else class="text-center">
				<v-icon color="grey darken-3">mdi-plus</v-icon>
				<p class="mb-0">Agregar molde</p>
			</div>
		</v-container>
	</v-card>
</template>

<script>
export default {
	props: {
		getImagen: {
			type: Function,
			default: () => {},
		},
		tipo: {
			type: String,
			required: true,
			validator: (value) => {
				const result = ["image", "icon"].indexOf(value) != -1;
				if (!result)
					console.error(
						`CardMolde.vue: la property 'tipo' debe ser 'image' o 'icon'`
					);
				return result;
			},
		},
		molde: {
			type: Object,
			default: null,
		},
		color: {
			type: String,
			default: "grey lighten-4",
		},
	},
	data() {
		return {
			localImage: null,
			loadingImage: false,
		};
	},
	mounted() {
		if (this.tipo == "image") this.loadImage();
	},
	destroyed() {
		this.localImage = null;
	},
	methods: {
		loadImage() {
			this.loadingImage = true;

			this.getImagen(this.molde, false)
				.then((imageBase64) => {
					this.localImage = imageBase64;
				})
				.catch(() => {
					this.localImage = null;
				})
				.finally(() => {
					this.loadingImage = false;
				});
		},
		onClickDelete() {
			this.$emit("click:delete", this.molde.idMolde);
			this.localImage = null;
		},
		onClickCard() {
			this.$emit("click", this.molde);
		},
	},
	filters: {
		trimmText(value) {
			return value.length > 22 ? value.slice(0, 19).concat("...") : value;
		},
	},
};
</script>
<style scoped>
.button-delete-small {
	right: -12px !important;
    top: -19px !important;
	height: 24px !important;
    width: 24px !important;
}

.custom-card-class {
	height: 200px;
	width: 200px;
}

table td + td {
	border-left: 1px solid #dddddd;
}
</style>