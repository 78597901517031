<template>
  <v-container fluid>
    <v-row>
      <v-col cols="2">
        <VueCtkDateTimePicker
          id="inicio"
          v-model="filtro.Desde"
          :maxDate="filtro.Hasta"
          label="Desde (Opcional)"
          format="YYYY-MM-DD HH:mm"
          formatted="YYYY-MM-DD HH:mm"
          noHeader
          overlay
          buttonNowTranslation="Ahora"
          @input="alertarActivas_update_page_action(1)"
          class="my-2"
        />
      </v-col>
      <v-col cols="2">
        <VueCtkDateTimePicker
          id="fin"
          v-model="filtro.Hasta"
          :min-date="filtro.Desde"
          label="Hasta (Opcional)"
          format="YYYY-MM-DD HH:mm"
          formatted="YYYY-MM-DD HH:mm"
          noHeader
          overlay
          buttonNowTranslation="Ahora"
          @input="alertarActivas_update_page_action(1)"
          class="my-2"
        />
      </v-col>
      <v-col cols="3">
        <v-autocomplete
          v-model="filtro.IdMaquinaIns"
          :items="maquinasIns"
          label="Máquina"
          item-value="idMaquinaIns"
          :item-text="maquinaLabel"
          clearable
          @input="alertarActivas_update_page_action(1)"
        ></v-autocomplete>
      </v-col>
      <v-col cols="3">
        <v-autocomplete
          v-model="filtro.MetodoNotificacion"
          label="Métodos de Notificación"
          :items="metodosNotificacion"
          item-value="id"
          item-text="value"
          clearable
          @input="alertarActivas_update_page_action(1)"
        ></v-autocomplete>
      </v-col>
      <v-col cols="2">
        <v-select
          v-model="filtro.Activas"
          :items="activas"
          item-text="text"
          item-value="value"
          label="Alertas Activas"
          clearable
          @input="alertarActivas_update_page_action(1)"
        ></v-select>
      </v-col>
      <v-col cols="12" class="pb-0 d-flex align-center justify-space-between">
        <download-btn
          text
          color="primary"
          className="my-0 pl-0"
          @click="descargarReporte()"
          label="Descargar Reporte de alertas activas"
        />
        <p class="mb-0 text-subtitle-2">Proxima Actualización en {{tiempo}}</p>
      </v-col>
      <v-col cols="12" md="12" sm="12" xl="12">
        <!-- desserts -->
        <v-data-table
          :headers="headers"
          :items="desserts"
          :loading="loadingTable"
          loading-text="Cargando... Espere, Por Favor."
          :server-items-length="alertarActivas_total"
        :items-per-page="alertarActivas_items_per_page"
        :page="alertarActivas_actual_page"
        @update:page="alertarActivas_update_page_action"
        @update:items-per-page="(ipp) => (alertarActivas_items_per_page = ipp)"
        @update:sort-by="alertarActivas_sort_by_action"
        :footer-props="{
          disableItemsPerPage: true,
          disablePagination: this.loadingTable,
        }"
        :disable-sort="true"
        >
          <template v-slot:[`item.maquina`]="{ item }">
            <span>{{
              "[" + item.nombre + "]" + " - " + item.marca + " " + item.modelo
            }}</span>
          </template>
          <template v-slot:[`item.inicio`]="{ item }">
            <span v-if="item.inicio == null">N/A</span>
            <span v-else>{{ item.inicio }} </span>
          </template>
          <template v-slot:[`item.fin`]="{ item }">
            <span v-if="item.fin == null">N/A</span>
            <span v-else>{{ item.fin }} </span>
          </template>

          <template v-slot:[`item.valorActivador`]="{ item }">
            <span v-if="item.valorActivador == null">N/A</span>
            <span v-else>{{ item.valorActivador }} </span>
          </template>

          <template v-slot:[`item.validacion`]="{ item }">
            <div
              v-if="
                item.valor === null &&
                  item.comparador === null &&
                  item.valor2 === null
              "
            >
              <span>N/A</span>
            </div>
            <div v-else>
              <span v-if="item.comparador != null">
                <p v-if="item.comparador == 1">
                  {{ "El valor es < Menor a " + item.valor }}
                </p>
                <p v-if="item.comparador == 2">
                  {{ "El valor es <= Menor o Igual a " + item.valor }}
                </p>
                <p v-if="item.comparador == 3">
                  {{ "El valor es = Igual a " + item.valor }}
                </p>
                <p v-if="item.comparador == 4">
                  {{ "El valor es != Diferente de " + item.valor }}
                </p>
                <p v-if="item.comparador == 5">
                  {{ "El valor es > Mayor que " + item.valor }}
                </p>
                <p v-if="item.comparador == 6">
                  {{ "El valor es >= Mayor o igual que " + item.valor }}
                </p>
                <p v-if="item.comparador == 7">
                  {{
                    "El valor se encuentra <> Entre " +
                      item.valor +
                      " y " +
                      item.valor
                  }}
                </p>
                <p v-if="item.comparador == 8">
                  {{
                    "El valor !<> No esta Entre " +
                      item.valor +
                      " y " +
                      item.valor2
                  }}
                </p>
              </span>
            </div>
          </template>

          <template v-slot:[`item.operacion`]="{ item }">
            <span v-if="item.operacion == null">N/A</span>
            <span v-else>
              <p v-if="item.operacion == 1">Suma</p>
              <p v-if="item.operacion == 2">Promedio</p>
              <p v-if="item.operacion == 3">Maximo</p>
              <p v-if="item.operacion == 4">Minimo</p>
              <p v-if="item.operacion == 5">Conteo</p>
            </span>
          </template>

          <template v-slot:[`item.unidad`]="{ item }">
            <span v-if="item.unidad == null">N/A</span>
            <span v-else>{{ item.unidad }}</span>
          </template>
          <template v-slot:[`item.usuarios`]="{ item }">
            <ul>
              <li v-for="(usuario, x) in item.usuarios" :key="x">
                <span>{{ usuario }}</span>
              </li>
            </ul>
          </template>
          <template v-slot:[`item.notificaciones`]="{ item }">
            <ul>
              <li v-for="(notificacion, x) in item.notificaciones" :key="x">
                <span>{{ notificacion }}</span>
              </li>
            </ul>
          </template>
        </v-data-table>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import axios from "axios";
import DownloadBtn from "../../DownloadBtn.vue";
import { mapState } from "vuex";
import moment from "moment";
export default {
  props: {
		active: {
			type: Boolean,
			default: false,
		},
	},
  components: { DownloadBtn },
  data() {
    return {
      segundos: 60,
      interval: null,
      loadingTable: false,
      inicio: null,
      fin: null,
      metodosNotificacionFilt: null,
      idMaquinaIns: null,
      maquinasIns: [],
      activas: [
        {
          value: true,
          text: "Alertas Activas",
        },
        {
          value: false,
          text: "Alertas Terminadas",
        },
      ],
      headers: [
        { text: "Maquina", value: "maquina", align: "center" },
        { text: "Clave", value: "clave", align: "center" },
        { text: "Tipo Alerta", value: "tipoAlerta", align: "center" },
        { text: "Fecha Inicio", value: "inicio", align: "center" },
        { text: "Fecha Fin", value: "fin", align: "center" },
        { text: "Valor Activador", value: "valorActivador", align: "center" },
        { text: "Condición", value: "validacion", align: "center" },
        { text: "Operacion", value: "operacion", align: "center" },
        { text: "Unidad", value: "unidad", align: "center" },
        { text: "Usuarios", value: "usuarios", align: "center" },
        { text: "Notificaciones", value: "notificaciones", align: "center" },
      ],
      desserts: [],
      metodosNotificacion: [
        {
          id: 1,
          value: "Notificación de ManagrX",
        },
        {
          id: 2,
          value: "WebPush",
        },
        {
          id: 3,
          value: "Correo Electronico",
        },
        {
          id: 4,
          value: "Telegram",
        },
      ],
      filtro: [
        {
          Desde: null,
          Hasta: null,
          IdMaquinaIns: null,
          MetodoNotificacion: null,
          Activas: null,
        },
      ],
      alertarActivas_total: 0,
      alertarActivas_items_per_page: 10,
      alertarActivas_actual_page: 1,
    };
  },
  mounted() {
    this.getMaquinasIns();
    this.iniciarAutorefresh();
  },
  created(){
    this.alertarActivas_update_page_action(1);
  },
  computed: {
    ...mapState(["token"]),
    tiempo(){
      return this.$utils.strings.toTime(this.segundos);
    },
    historial_fetch_url() {
      return (
        `/Alerta/AlertasActivas?page=${this.alertarActivas_actual_page}&size=${this.alertarActivas_items_per_page}&paginar=true` +
        this.alertarActivas_filtros_url
      );
    },
    alertarActivas_filtros_url(){
      var param = ``;
      if (this.filtro.Desde != null) param += `&desde=${this.filtro.Desde}`;
      if (this.filtro.Hasta != null) param += `&hasta=${this.filtro.Hasta}`;
      if (this.filtro.IdMaquinaIns != null) this.filtro.IdMaquinaIns.forEach((x) => (param += `&idMaquinaIns=${x || ""}`));
      if (this.filtro.MetodoNotificacion != null) param += `&metodoNotificacion=${this.filtro.MetodoNotificacion}`;
      if (this.filtro.Activas != null) param += `&activas=${this.filtro.Activas}`;
      return param;
    }
  },
  methods: {
    maquinaLabel: ({ nombre, marca, modelo }) =>
      `[${nombre}] - ${marca} ${modelo}`.trim(),
    iniciarAutorefresh(){
      this.interval = setInterval(() => {
        if (!this.active) return;

        if(this.segundos <= 0) {
          this.alertarActivas_update_page_action(this.alertarActivas_actual_page)
          this.segundos = 60;
        }
        else this.segundos -= 1;
      }, 1000);
    },
    getMaquinasIns() {
      axios
        .get("/MaquinaInstancias")
        .then((response) => {
          this.maquinasIns = response.data;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    descargarReporte() {
      var datetime = moment().format("YYYYMMDDHHmmss");

      var url = `/Alerta/XlsxAlertasActivas?access_token=${this.token}`;

      if (this.filtro.Desde != null) url += `&Desde=${this.filtro.Desde}`;
      if (this.filtro.Hasta != null) url += `&Hasta=${this.filtro.Hasta}`;
      if (this.filtro.IdMaquinaIns != null)
        url += `&IdMaquinaIns=${this.filtro.IdMaquinaIns}`;
      if (this.filtro.MetodoNotificacion != null)
        url += `&MetodoNotificacion=${this.filtro.MetodoNotificacion}`;
      if (this.filtro.Activas != null) url += `&Activas=${this.filtro.Activas}`;

      this.$utils.axios.downloadFile(
        url,
        "GET",
        "reporte-alarmas-activas-" + datetime + ".xlsx",
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
      );
    },
    alertarActivas_sort_by_action($sort) {},
    getHistorial() {
      return new Promise((resolve, reject) => {
        axios
          .get(this.historial_fetch_url)
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },
    async alertarActivas_update_page_action(page) {
      this.alertarActivas_actual_page = page;
      this.loadingTable = true;
      this.btnDounwload = false;
      try {
        let response = await this.getHistorial();
        this.desserts = response.data.result;
        this.alertarActivas_total = response.data.total;
        this.loadingTable = false;
        //this.btnDounwload = true;
      } catch (exception) {
        console.error(exception);
      }
    },
  },
  destroyed() {
		clearInterval(this.interval);
	}
};
</script>

<style>
.v-data-table-header th {
  white-space: nowrap;
}
</style>
