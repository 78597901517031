<template>
  <v-container v-if="permisoVista('recetas','r')">
    <node
      :show="showNode"
      :item="itemNode"
      :idArticuloTipo="number"
      @close="closeNode"
    ></node>
    <v-dialog v-model="eliminar" persistent max-width="500px" :key="'e'+eliminar.toString()">
      <v-card>
        <v-card-title class="headline" style="word-break: break-word">¿Está seguro que desea eliminar esta receta?</v-card-title>
        <v-card-text>Esta acción no se puede revertir y será permanente.</v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="eliminar = false; deletedItem = null;">Cancelar</v-btn>
          <v-btn color="red darken-1" text @click="eliminar = false; deleteItemDB(); ">Aceptar</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="clone" persistent max-width="500px" :key="'c'+clone.toString()">
      <v-card>
        <v-card-title>
          Agregar Nuevo
        </v-card-title>
        <v-card-text>
          <v-row>
            <v-col cols="12" md="12" sm="12">
              <v-text-field
                v-model="clonedItem.nombre"
                label="Nombre"
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="12" sm="12">
              <v-text-field
                v-model="clonedItem.codigo"
                label="Código"
              ></v-text-field>
            </v-col>
          </v-row>
          <ul>
            <li v-for="(e,idx) in errores" :key="'e'+idx" class="red--text">{{e}}</li>
          </ul>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="clone = false; clonedItem = defaultCloneItem;">Cancelar</v-btn>
          <v-btn color="red darken-1" text @click="launchEditRecipe();" :disabled="loadingCloning" :loading="loadingCloning">
                  Siguiente
                  <template v-slot:loader>
                    <v-progress-circular
                      indeterminate
                      :width="2"
                      :size="24"
                      color="red"
                    ></v-progress-circular>
                  </template>
                </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog
      :loading="loadingValidar"
      v-model="dialogValidar"
      persistent
      max-width="500px"
      :key="dialogValidar"
    >
      <v-card>
        <v-card-title>Validación de Receta</v-card-title>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col cols="12">
                <v-simple-table
                  v-if="
                    validRecetas.length > 0 &&
                    JSON.stringify(validRecetas) !== '[]'
                  "
                >
                  <template v-slot:default>
                    <thead>
                      <tr>
                        <th
                          class="text-left"
                          style="font-size: 16px"
                        >
                          Código
                        </th>
                        <th
                          class="left"
                          style="font-size: 16px"
                        >
                          Nombre
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr
                        v-for="item in validRecetas"
                        :key="`dep-${item.idArticulo}`"
                      >
                        <td>
                          {{ item.codigo }}
                        </td>
                        <td>
                          {{ item.nombre }}
                        </td>
                      </tr>
                    </tbody>
                  </template>
                </v-simple-table>
                <div 
                  v-if="
                    !loadingValidar &&
                    validRecetas.length === 0 &&
                    JSON.stringify(validRecetas) === '[]'
                  "
                >
                  <lottie :options="checkOptions" :height="200" :width="200"/>
                  <div class="text-h6 text-center">Este artículo no tiene recetas faltantes</div>
                </div>

              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="green darken-1"
            text
            @click="cancelarValidar()"
          >
            Cerrar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="dialogRefreshCostos" persistent max-width="450px">
      <v-card>
        <v-card-title>Actualizar Costos de Recetas</v-card-title>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col cols="12" class="pb-0">
                <lottie
                  id="attentionAnimation"
                  :options="attentionAnimationOptions"
                  :height="200"
                  :width="200"
                  :key="`error-${attentionAnimationKey}`"
                  class="py-0"
                />
              </v-col>
              <v-col cols="12" class="pt-0">
                <p class="text-subtitle-1 text-justify text--primary">
                  Se procederá a realizar la actualización de costos de producción de todos aquellos artículos que cuenten con una receta.<br>
                  Esta acción demorará aproximadamente 15 minutos, no inhabilitará la creación y edición de recetas, pero los precios pueden no estar actualizados durante ese tiempo.<br>
                  ¿Desea continar con esta acción?
                </p>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-btn text color="success" :disabled="updatingCostosRecetas" @click="cerrarDialog()">No, Cancelar</v-btn>
          <v-spacer></v-spacer>
          <v-btn text color="error" :disabled="updatingCostosRecetas" :loading="updatingCostosRecetas" @click="actualizarCostosRecetas()">Sí, Actualizar</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="dialogActualizacionYaSolicitada" persistent max-width="450px">
      <v-card>
        <v-card-title>Actualización de Costos en Curso</v-card-title>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col cols="12" class="py-0">
                <lottie
                  id="inProgressAnimation"
                  :options="inProgressAnimationOptions"
                  :height="300"
                  :width="300"
                  :key="`error-${inProgressAnimationKey}`"
                  class="py-0"
                />
              </v-col>
              <v-col cols="12" class="pt-0">
                <p class="text-subtitle-1 text-justify text--primary">
                  Ya se ha solicitado una actualización de costos el {{fechaActualizacionYaSolicitada}}.<br>
                  La acción puede demorar aproximadamente 15 minutos, por favor espere a que el proceso termine para realizar otra solicitud de actualización.
                </p>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text color="success" @click="cerrarDialog()">Aceptar</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-row class="align-center justify-space-between">
      <v-col cols="8" class="d-flex align-center">
        <v-btn v-if="permisoVista('recetas','c')" color="primary" dark class="mb-2 mr-4" @click="dialog = true" >Nuevo {{name}}</v-btn>
        <v-autocomplete
          v-if="odtAgrupadas"
          v-model="filtros.idArticuloFamilia"
          label="[Obligatorio] Familia"
          :items="familias"
          :item-text="familiaNombre"
          class="mb-2"
          item-value="idArticuloFamilia"
          hide-details
          clearable
          :disabled="loading"
          :loading="loadingFamilias"
          @change="getRecetasAction"
          style="max-width: 40%"
        ></v-autocomplete>
      </v-col>
      <v-col cols="4" class="d-flex">
        <v-text-field
          v-model="search"
          append-icon="search"
          label="Buscar por código o artículo"
          single-line
          hide-details
          @keyup="getRecetasOnEnterAction"
          @click:clear="clearSearchAction"
          @click:append="getRecetasAction"
          :clearable="true"
          :disabled="loading"
        ></v-text-field>
        <v-tooltip bottom color="black" class="white--text">
          <template v-slot:activator="{ on }">
            <v-btn
              icon
              text
              v-on="on"
              color="grey darken-1"
              class="pt-1"
              @click="showDialogRefreshCostos"
              :disabled="loading"
            >
              <v-icon size="24">mdi-refresh</v-icon>
            </v-btn>
          </template>
          <span class="white--text">Actualizar Costos de Recetas</span>
        </v-tooltip>
      </v-col>
    </v-row>
    <v-col class="d-flex justify-end">
      <p class="text--secondary mb-0">{{textoActualizacionCostos}}</p>
    </v-col>
     <v-data-table
      :headers="headersFiltrados"
      :items="recetas"
      :loading="loading"
      loading-text="Cargando... Espere, Por Favor."
      :server-items-length="recetas_total"
      :items-per-page="recetas_items_per_page"
      :page="recetas_actual_page"
      @update:page="recetas_update_page_action"
      @update:items-per-page="(ipp) => recetas_items_per_page = ipp"
      @update:sort-by="recetas_sort_by_action"
      :footer-props="{disableItemsPerPage:true, disablePagination: this.loading}"
      :disable-sort="true"
      :disable-pagination="loading"
    >
      <template v-slot:[`item.costoLabel`]="{ item }">
        {{costoLabel(item.costo)}}
      </template>
      <template v-slot:[`item.revision`]="{ item }">
        {{ item.revision || "N/A" }}
      </template>
      <template v-slot:[`item.action`]="{ item }">
        <v-tooltip bottom color="black" class="white--text">
          <template v-slot:activator="{ on }">
            <v-icon v-on="on"
              small
              class="mr-2"
              :disabled="loading"
              @click="validarReceta(item)"
            >
              mdi-check-circle-outline
            </v-icon>
          </template>
          <span class="white--text">Validación de Receta</span>
        </v-tooltip>
        <v-tooltip
          
          bottom
          color="black"
          class="white--text"
        >
          <template v-slot:activator="{ on }">
            <v-icon
              v-on="on"
              small
              class="mr-2"
              :disabled="loading"
              @click="nodeItem(item)"
            >
              mdi-graph-outline
            </v-icon>
          </template>
          <span class="white--text">Diagrama</span>
        </v-tooltip>
        <v-tooltip bottom color="black" class="white--text">
          <template v-slot:activator="{ on }">
            <v-icon v-on="on"
              v-if="permisoVista('recetas','c')"
              small
              class="mr-2"
              :disabled="loading"
              @click="copyItem(item)"
            >
              mdi-content-duplicate
            </v-icon>
          </template>
          <span class="white--text">Clonar Receta</span>
        </v-tooltip>
        <v-tooltip bottom color="black" class="white--text">
          <template v-slot:activator="{ on }">
            <v-icon v-on="on"
              v-if="permisoVista('recetas','c')"
              small
              class="mr-2"
              :disabled="loading"
              @click="cloneItem(item)"
            >
              mdi-content-copy
            </v-icon> 
          </template>
          <span class="white--text">Clonar Artículo y Receta</span>
        </v-tooltip>
        <v-tooltip bottom color="black" class="white--text">
          <template v-slot:activator="{ on }">
            <v-icon v-on="on"
              v-if="permisoVista('recetas','r')"
              small
              class="mr-2"
              :disabled="loading"
              @click="downloadItem(item)"
            >
              mdi-download
            </v-icon>
          </template>
          <span class="white--text">Descargar Receta</span>
        </v-tooltip>
        <v-tooltip bottom color="black" class="white--text">
          <template v-slot:activator="{ on }">
            <v-icon v-on="on"
              v-if="permisoVista('recetas','u')"
              small
              class="mr-2"
              :disabled="loading"
              @click="editItem(item)"
            >
              edit
            </v-icon>
          </template>
          <span class="white--text">Editar</span>
        </v-tooltip>
        <v-tooltip bottom color="black" class="white--text">
          <template v-slot:activator="{ on }">
            <v-icon v-on="on"
              v-if="permisoVista('recetas','d')"
              small
              :disabled="loading"
              @click="deleteItem(item)"
            >
              delete
            </v-icon>
          </template>
          <span class="white--text">Eliminar</span>
        </v-tooltip>
      </template>
      <template v-slot:no-data>
        <v-btn color="primary" @click="initialize">Refrescar</v-btn>
      </template>
    </v-data-table>
    <receta-detallada
      :show="dialog"
      :number="number"
      :mode="mode"
      :recipe="recipe"
      :name="newName"
      :nameClient="nombreEmpresa"
      @close="update"
    ></receta-detallada>
  </v-container>
</template>

<script>
import axios from "axios";
import recetadetallada from "./RecetaDetallada";
import node from "./Node";
import { debounce } from "debounce";
import Lottie from 'vue-lottie';
import * as checkData from '@/assets/check.json';
import * as attentionAnimation from '@/assets/attention.json';
import * as inProgressAnimation from '@/assets/inProgress.json';
import { mapState } from "vuex"

export default {
	components: {
		"receta-detallada": recetadetallada,
		node: node,
    'lottie': Lottie,
	},
	props: {
		number: {
			type: Number,
			default: 0,
		},
		name: {
			type: String,
			default: "",
		},
    namesClient:{
        type:String,
        default: "",
      }
	},
	data: () => ({
    loadingFamilias: false,
    familias: [],
    filtros: {
      idArticuloFamilia: null
    },
    fechaActualizacionYaSolicitada: "",
    dialogActualizacionYaSolicitada: false,
    updatingCostosRecetas: false,
    textoActualizacionCostos: "",
    inProgressAnimationOptions: {
      animationData: inProgressAnimation.default,
      loop:true,
      speeed: 1
    },
    inProgressAnimationKey: 0,
    attentionAnimationKey: 0,
    attentionAnimationOptions: {
      animationData: attentionAnimation.default,
      loop:false,
      speeed: 2
    },
    dialogRefreshCostos: false,
    checkOptions: {
      animationData: checkData.default,
      loop:false,
      speeed: 0.5
    },
      validRecetas: [],
      loadingValidar: false,
      dialogValidar: false,
      pos: 1,
      eliminar: false,
      deletedItem: null,
      dialog: false,
      mode:0,
      recipe: {},
      newName: '',
      search: '',
      loading: false,
      recetas:[],
      headers: [
        {
          text: 'Código',
          align: 'left',
          sortable: true,
          value: 'codigo',
        },
        {
          text: '',
          align: 'left',
          sortable: true,
          value: 'nombre',
        },
        {
          text: '# Pasos',
          align: 'left',
          sortable:true,
          value:'numPasos'
        },
        {
          text: 'Revisión',
          align: 'left',
          sortable:false,
          value:'revision'
        },
        {
          text: 'Costo',
          align: 'left',
          sortable:true,
          value:'costoLabel'
        },
        { text: 'Acciones', value: 'action', sortable: false, width:'25%', align:'center' },
      ],
      showNode:false,
      itemNode:null,
      clonedItem:{
        nombre:'',
        codigo:'',
      },
      defaultCloneItem:{
        nombre:'',
        codigo:'',
      },
      clone:false,
      errores:[],
      loadingCloning: false,
      recetas_total: null,
      recetas_items_per_page: 10,
      recetas_actual_page: 1,
    }),

    computed: {
      headersFiltrados(){
        return this.permisoVista('recetas','$')
          ? this.headers
          : this.headers.filter(head=>head.value!='costoLabel')
      },
      ...mapState(['token','odtAgrupadas','nombreEmpresa']),
      recetas_fetch_url() {
        let url = `/Recetas/RecetasTipoPaginar/${this.number}?precio=true&paginar=true&page=${this.recetas_actual_page}&size=${this.recetas_items_per_page}`;
        if (this.search) url += this.search.length ? `&nombreCodigo=${this.search}` : "";
        return url;
      }
    },

	watch: {},

	created() {
		if (!this.permisoVista("recetas", "$")) {
			this.headers = this.headers.filter((element) => {
				return element.value != "costoLabel";
			});
		}
	},
  mounted(){
    this.initialize();
  },

    methods: {
      familiaNombre: ({ codigo, nombre }) =>
			`${codigo ? `[${codigo}] -` : ""} ${nombre}`.trim(),
      actualizarCostosRecetas(){
        this.updatingCostosRecetas = true;
        axios
          .post('/Cache/ActualizarCostoArticulosReceta')
          .then( (response) => {
            this.updatingCostosRecetas = false;
            if(response.data.estado == 0){
              this.fechaActualizacionYaSolicitada = response.data.mensaje.split('el ')[1];
              this.inProgressAnimationKey++;
              this.dialogRefreshCostos = false;
              this.dialogActualizacionYaSolicitada = true;
              return;
            }
            this.cerrarDialog();
            this.getFechaActualizacionCostos();
          })
          .catch( error => {
            this.updatingCostosRecetas = false;
            console.log(error);
          })
      },
      getFechaActualizacionCostos(){
        axios
          .post(`/Cache/FechaActualizacionArticuloReceta`)
          .then( response => {
            this.textoActualizacionCostos = response.data == "Sin Información" ? "" : `Costos Actualizados al: ${response.data}`;
          })
          .catch( error => {
            console.log(error);
          })
      },
      cerrarDialog(){
        this.dialogRefreshCostos = false;
        this.dialogActualizacionYaSolicitada = false;
        this.fechaActualizacionYaSolicitada = "";
      },
      showDialogRefreshCostos(){
        this.attentionAnimationKey++;
        this.dialogRefreshCostos = true;
      },
      validarReceta(item) {
        
        this.validRecetas = [];
        this.dialogValidar = true;
        this.loadingValidar = true;
        axios
          .get(`/Articulos/ArticuloReceta/${item.idArticulo}`)
          .then((response) => {
            this.validRecetas = response.data;
            this.loadingValidar = false;
            
          })
          .catch((error) => console.log(error));
      },
      cancelarValidar() {
        this.dialogValidar = false;
        this.validRecetas = [];
      },
      getFamilias() {
        this.loadingFamilias = true;

        axios
          .get(`/ArticuloFamilias`)
          .then((response) => {
            this.familias = response.data;
          })
          .catch(console.log)
          .finally(() => {
            this.loadingFamilias = false;
          });
      },
      resetValues(){
        this.recetas = [];
        this.recetas_total = null;
        this.recetas_actual_page = 1;
      },
      async initialize () {
        this.getFamilias();
        this.getFechaActualizacionCostos();
        this.newName = this.toUpperCaseAfterSpace(this.name);
        this.headers[1].text = this.newName;
        if (this.odtAgrupadas && this.filtros.idArticuloFamilia == null){
          this.resetValues();
          return;
        }
        
        this.loading = true;
        try {
          let response = await this.getRecetas();
          this.recetas = response.data.result;
          this.recetas_total = response.data.total;
          this.loading = false;
        }
        catch (exception) {
          console.error(exception);
        }
      },
      costoLabel(costo){
        if(costo.min == costo.max) return "$"+costo.min.toFixed(2); 
        else return "$"+costo.min.toFixed(2)+" ~ "+"$"+costo.max.toFixed(2);
      },
      update(value) {
        this.dialog = value;
        if(!this.dialog){
          this.initialize();
          this.mode = 0;
          this.recipe = {};
        }
      },
      toUpperCaseAfterSpace(string){
        let s = string.toLowerCase();
        s = this.setCharAt(s,0,s[0].toUpperCase());
        for(var i = 0; i<s.length; i++){
          if( s[i] == ' ') s = this.setCharAt(s,i+1,s[i+1].toUpperCase());
        }
        return s;
      },
      setCharAt(str,index,chr) {
        if(index > str.length-1) return str;
        return str.substr(0,index) + chr + str.substr(index+1);
      },
      nodeItem(item){
        this.itemNode = item;
        this.showNode = true;
      },
      closeNode(event){
        this.showNode = false;
      },
      editItem(item){
        let me = this;
        this.mode = 1;
        axios
          .get("/Recetas/Mostrar/"+item.idReceta)
          .then(response => {
            me.recipe = response.data;
            this.dialog = true;
          })
          .catch(error =>{
            console.log(error);
          });
      },
      deleteItem(item){
        let me = this;
        this.eliminar = true;
        this.deletedItem = item;
      },
      deleteItemDB(){
        let me = this;
        this.loading = true;
        axios
          .delete("/Recetas/Eliminar/"+me.deletedItem.idReceta)
          .then(function(element){
            me.initialize();    
          })
          .catch(error =>{
            this.loading = false;
            console.log(error);
          });
      },
      copyItem(item){
        let me = this;
        this.mode = 3;
        axios
          .get("/Recetas/Mostrar/"+item.idReceta)
          .then(response => {
            me.recipe = response.data;
            this.dialog = true;
          })
          .catch(error =>{
            console.log(error);
          });
      },
      cloneItem(item){
        axios
        .get("/Articulos/Mostrar/"+item.idArticulo)
        .then(response => {
          this.clonedItem = response.data;
          this.clonedItem.idReceta = item.idReceta;
          this.clone = true;
        })
        .catch(error => {
          console.log(error);
        });
      },
      async validateClone(){
        this.errores = [];
        if(this.clonedItem.nombre.length < 2 || this.clonedItem.nombre.length > 200){
          this.errores.push("El Nombre debe tener al menos 2 caracteres y menos de 200.");
        }

			if (
				this.clonedItem.codigo.length < 2 ||
				this.clonedItem.codigo.length > 20
			) {
				this.errores.push(
					"El Código debe tener al menos 2 caracteres y menos de 20."
				);
			}

			if (this.errores.length > 0) return false;

			var response = await axios.get(
				"/Articulos/Existe?Nombre=" +
					this.clonedItem.nombre +
					"&Codigo=" +
					this.clonedItem.codigo
			);

			if (response.data) {
				this.errores.push(
					"Existe un Artículo Activo con el Mismo Nombre o Código"
				);
				return false;
			} else return true;
		},
		async launchEditRecipe() {
			this.loadingCloning = true;
			if (await this.validateClone()) {
				this.clone = false;
				let me = this;
				this.mode = 2;
				axios
					.get("/Recetas/Mostrar/" + this.clonedItem.idReceta)
					.then((response) => {
						this.loadingCloning = false;
						me.recipe = response.data;
						me.recipe.nombre = this.clonedItem.nombre;
						me.recipe.codigo = this.clonedItem.codigo;
						this.dialog = true;
					})
					.catch((error) => {
						console.log(error);
					});
			} else {
				this.loadingCloning = false;
			}
		},
		recetas_sort_by_action($sort) {},
		dialog_busqueda_keyevent_action($event) {
			if ($event.keyCode === 27) this.avanzada = false;
			if ($event.keyCode === 13) this.getRecetasAction();
		},
		clearSearchAction() {
			this.search = "";
			this.getRecetasAction();
		},
		getRecetas() {
			//* Realizamos peticion HTTP
			return new Promise((resolve, reject) => {
				axios
					.get(this.recetas_fetch_url,{
            params: this.filtros
          })
					.then((response) => resolve(response))
					.catch((error) => reject(error));
			});
		},
		async getRecetasAction() {
      if(this.odtAgrupadas && this.filtros.idArticuloFamilia == null){
        this.resetValues();
        return;
      }

			this.loading = true;
			this.recetas_actual_page = 1;
			try {
				let response = await this.getRecetas();
				this.recetas = response.data.result;
				this.recetas_total = response.data.total;
				this.loading = false;
			} catch (exception) {
				console.error(exception);
			}
		},
		getRecetasDebounce: debounce(async function () {
			this.loading = true;
			this.recetas_actual_page = 1;
			try {
				let response = await this.getRecetas();
				this.recetas = response.data.result;
				this.recetas_total = response.data.total;
				this.loading = false;
			} catch (exception) {
				console.error(exception);
			}
		}, 750),
		getRecetasDebounceAction($event) {
			if ($event instanceof KeyboardEvent) {
				//  Enter y Tab
				if ($event.keyCode === 13 || $event.keyCode === 9) return;
				//  Flechas teclado
				if ($event.keyCode >= 37 && $event.keyCode <= 40) return;
			}
			this.getRecetasDebounce();
		},
		getRecetasOnEnterAction($event) {
			if ($event instanceof KeyboardEvent) {
				//  Enter
				if ($event.keyCode === 13) this.getRecetasAction();
			}
		},
		async recetas_update_page_action(page) {
			this.recetas_actual_page = page;
			this.loading = true;
			try {
				let response = await this.getRecetas();
				this.recetas = response.data.result;
				this.recetas_total = response.data.total;
				this.loading = false;
			} catch (exception) {
				console.log(exception);
			}
		},
    downloadItem(item){
      window.open(`/api/Recetas/XlsxRecetaDescargar/${item.idReceta}?access_token=${this.token}`);
    }
	},
};
</script>