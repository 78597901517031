<template>
  <v-col cols="12" v-if="permisoVista('logs','r')">
    <v-row class="text-center">
			<v-col cols="4" md="4" sm="4" class="align-self-end">
				<VueCtkDateTimePicker
					id="inicio"
					v-model="filtros.inicio"
					label="Desde"
					format="YYYY-MM-DD HH:mm"
					formatted="YYYY-MM-DD HH:mm"
					noHeader
					overlay
					buttonNowTranslation="Ahora"
					@input="logs_update_page_action(1)"
				/>
			</v-col>
			<v-col cols="4" md="4" sm="4" class="align-self-end">
				<VueCtkDateTimePicker
					id="fin"
					v-model="filtros.fin"
					:min-date="filtros.inicio"
					label="Hasta"
					format="YYYY-MM-DD HH:mm"
					formatted="YYYY-MM-DD HH:mm"
					noHeader
					overlay
					buttonNowTranslation="Ahora"
					@input="logs_update_page_action(1)"
				/>
			</v-col>
      <v-col cols="4" md="4" sm="4" class="align-self-end">
				<v-autocomplete
					v-model="filtros.idUsuario"
					:items="usuarios"
					item-value="idUsuario"
					item-text="nombre"
					label="Usuario"
					hide-details
					clearable
					@change="logs_update_page_action(1)"
				></v-autocomplete>
			</v-col>
		</v-row>
    <v-row class="text-center mb-3">
      <v-col cols="4" md="4" sm="4" class="align-self-end">
				<v-autocomplete
					v-model="filtros.idVista"
					:items="vistas"
					item-value="idVista"
					item-text="nombre"
					label="Vista"
					hide-details
					clearable
					@change="logs_update_page_action(1)"
				></v-autocomplete>
			</v-col>
      <v-col cols="4" md="4" sm="4" class="align-self-end">
				<v-autocomplete
					v-model="filtros.idAccion"
					:items="acciones"
					item-value="idAccion"
					item-text="nombre"
					label="Acción"
					hide-details
					clearable
					@change="logs_update_page_action(1)"
				></v-autocomplete>
			</v-col>
      <v-col cols="4" md="4" sm="4" class="align-self-end">
				<v-autocomplete
					v-model="filtros.alerta"
					:items="alertas"
					item-value="value"
					item-text="nombre"
					label="Alerta"
					hide-details
					clearable
					@change="logs_update_page_action(1)"
				></v-autocomplete>
			</v-col>
		</v-row>
    <v-dialog v-model="dialog" persistent max-width="500px" :key="dialog">
      <v-card>
        <v-card-title>
          <span class="headline">Detalles del Log</span>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col justify="center" v-if="watchedItem.alert" cols="12" sm="12" md="12" class="red text-center headline white--text">
                Alerta
              </v-col>
              <v-col justify="center" cols="12" sm="12" md="12">
                <v-text-field readonly dense hide-details v-model="watchedItem.createdAt" label="Fecha"></v-text-field>
              </v-col>
              <v-col justify="center" cols="6" sm="6" md="6">
                <v-text-field readonly dense hide-details v-model="watchedItem.usuario" label="Usuario"></v-text-field>
              </v-col>
              <v-col justify="center" cols="6" sm="6" md="6">
                <v-text-field readonly dense hide-details v-model="watchedItem.vista" label="Vista"></v-text-field>
              </v-col>
              <v-col justify="center" cols="6" sm="6" md="6">
                <v-text-field readonly dense hide-details v-model="watchedItem.accion" label="Acción"></v-text-field>
              </v-col>
              <v-col justify="center" cols="6" sm="6" md="6">
                <v-text-field readonly dense hide-details v-model="watchedItem.ip" label="Dirección IP"></v-text-field>
              </v-col>
              <v-col justify="center" cols="12" sm="12" md="12">
                <v-textarea
                  :value="watchedItem.body"
                  readonly
                  rows="4"
                  row-height="20"
                  label="Body"
                ></v-textarea>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="dialog=false;">Cancelar</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="dialogLogsEspecializados" persistent max-width="600px">
      <v-card>
        <v-card-title>Logs Especializados</v-card-title>
        <v-card-text class="py-0">
          <v-container>
            <v-row>
              <v-col cols="12" class="text-center">
                <v-simple-table>
                  <template v-slot:default>
                    <thead>
                      <tr>
                        <th class="text-center" width="80%" style="font-size: 16px">Reporte</th>
                        <th class="text-center" width="20%" style="font-size: 16px">Acción</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>Logs de Creación / Edición de Recetas</td>
                        <td>
                          <download-btn
                            color="blue darken-1"
                            text
                            :retryTime="20"
                            @click="descargarReporteRecetas"
                            label="Descargar"
                          ></download-btn>
                        </td>
                      </tr>
                    </tbody>
                  </template>
                </v-simple-table>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text color="success" @click="cerrarDialogLogsEspecializados()">Cerrar</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="dialogCleanCache" persistent max-width="450px">
      <v-card>
        <v-card-title>Limpiar Caché del Sistema</v-card-title>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col cols="12" class="pb-0">
                <lottie
                  id="attentionAnimation"
                  :options="attentionAnimationOptions"
                  :height="200"
                  :width="200"
                  :key="`error-${attentionAnimationKey}`"
                  class="py-0"
                />
              </v-col>
              <v-col cols="12" class="pt-0">
                <p class="text-subtitle-1 text-justify text--primary">
                  Se procederá a realizar la limpieza de caché de los siguientes elementos:<br>
                  • Costos de Recetas<br>
                  • Artículos de Recetas<br>
                  • Ordenes de Trabajo<br>
                  • Montos estimados de Producción<br><br>
                  Esta acción puede demorar unos minutos. ¿Desea continar con esta acción?
                </p>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-btn text color="success" :disabled="cleaningCache" @click="cerrarDialogCache()">No, Cancelar</v-btn>
          <v-spacer></v-spacer>
          <v-btn text color="error" :disabled="cleaningCache" :loading="cleaningCache" @click="cleanCache()">Sí, Limpiar</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-row>
      <v-col cols="12" class="ma-0 d-flex">
        <download-btn
          :className="'pl-0 mr-auto'"
          color="blue darken-1"
          text
          @click="descargarLogs"
          label="Descargar Logs"
        ></download-btn>
        <v-btn color="primary" @click="showLogsEspecializados">Logs Especializados</v-btn>
        <v-tooltip bottom color="black" class="white--text">
          <template v-slot:activator="{ on }">
            <v-btn
              icon
              text
              v-on="on"
              color="grey darken-1"
              class="pt-1"
              @click="showDialogCleanCache"
              :disabled="loading"
            >
              <v-icon size="30">mdi-broom</v-icon>
            </v-btn>
          </template>
          <span class="white--text">Limpiar Caché<br>del Sistema</span>
        </v-tooltip>
      </v-col>
    </v-row>
    <v-data-table
      :headers="headers"
      :items="logs"
      :loading="loading"
      loading-text="Cargando... Espere, Por Favor."
      :server-items-length="logs_total"
			:items-per-page="logs_items_per_page"
			:page="logs_actual_page"
			@update:page="logs_update_page_action"
			@update:items-per-page="(ipp) => (logs_items_per_page = ipp)"
			:footer-props="{
				disableItemsPerPage: true,
				disablePagination: this.loading,
			}"
			:disable-sort="true"
			:disable-pagination="loading"
    >
      <template v-slot:item="{ item }">
        <tr>
          <td :class=" item.alert? 'red--text' :''">
            {{item.createdAt}}
          </td>
          <td :class=" item.alert? 'red--text' :''">
            {{item.alerta}}
          </td>
          <td :class=" item.alert? 'red--text' :''">
            {{item.usuario}}
          </td>
          <td :class=" item.alert? 'red--text' :''">
            {{item.vista}}
          </td>
          <td :class=" item.alert? 'red--text' :''">
            {{item.accion}}
          </td>
          <td :class=" item.alert? 'red--text' :''">
            {{item.ip}}
          </td>
          <td class="text-center">
            <v-tooltip bottom color="black" class="white--text">
          <template v-slot:activator="{ on }">
        <v-icon v-on="on"
              small
              class="mr-2"
              @click="watchItem(item)"
            >
              mdi-eye
            </v-icon>
             </template>
          <span class="white--text">Ver detalles</span>
        </v-tooltip>
          </td>
        </tr>
      </template>
      <template v-slot:no-data>
        <v-btn color="primary" @click="initialize">Refrescar</v-btn>
      </template>
    </v-data-table>
    <!--
    <v-row>
      <v-col justify="center" cols="12" sm="12" md="12">
        <p class="subtitle-1 font-weight-black">Exportar Registros</p>
        <p>El tamaño actual de los registros es de {{size}} Mb.</p>
        <v-btn color="primary" dark>Exportar Registros</v-btn>
        <v-btn color="error" dark>Eliminar Registros</v-btn>
      </v-col>
    </v-row>
    -->
  </v-col>
  
</template>

<script>
import axios from "axios";
import moment from "moment";
import DownloadBtn from "../../DownloadBtn.vue";
import Lottie from 'vue-lottie';
import * as attentionAnimation from '@/assets/attention.json';
import { mapState } from 'vuex';

  export default{
	components: { DownloadBtn, 'lottie': Lottie, },
    data() {
      return {
        cleaningCache: false,
        dialogCleanCache: false,
        attentionAnimationKey: 0,
        attentionAnimationOptions: {
          animationData: attentionAnimation.default,
          loop:false,
          speeed: 2
        },
        dialogLogsEspecializados: false,
        alertas: [
          {
            nombre: 'Sí',
            value: true
          },
          {
            nombre: 'No',
            value: false
          }
        ],
        vistas: [],
        acciones: [],
        usuarios: [],
        filtros: {
          inicio: null,
          fin: null,
          idUsuario: null,
          idVista: null,
          idAccion: null,
          alerta: null,
        },
        months: ["Enero", "Febrero", "Marzo","Abril", "Mayo", "Junio", "Julio", "Agosto", "Septiembre", "Octubre", "Noviembre", "Diciembre"],
        dialog: false,
        search: '',
        headers: [
          {
            text: 'Fecha',
            align: 'left',
            sortable: true,
            value: 'createdAt',
          },
          {
            text: 'Alerta',
            align: 'left',
            sortable: true,
            value: 'alert',
          },
          {
            text: 'Usuario',
            align: 'left',
            sortable: true,
            value: 'usuario',
          },
          {
            text: 'Vista',
            align: 'left',
            sortable: true,
            value: 'vista',
          },
          {
            text: 'Acción',
            align: 'left',
            sortable: true,
            value: 'accion',
          },
          {
            text: 'Dirección IP',
            align: 'left',
            sortable: true,
            value: 'ip',
          },
          { text: 'Acciones', value: 'action', sortable: false, width:'15%', align:'center' },
        ],
        loading: false,
        logs: [],
        //Paginacion logs y filtros (server side)
        logs_total: null,
        logs_items_per_page: 10,
        logs_actual_page: 1,
        //
        size: 0,
        watchedIndex: -1,
        watchedItem: {
          nombre: '',
        },
        defaultItem: {
          nombre: '',
        },
      }
    },

    created () {
      this.initialize();
      this.getUsuarios();
      this.getPermisos();
    },

    computed: {
      ...mapState(["token"]),
      logs_fetch_url() {
        let url = `/Logs?Paginar=true&Page=${this.logs_actual_page}&Size=${this.logs_items_per_page}`;

        if (this.$utils.isValid(this.filtros.inicio)) {
          url += "&Inicio=" + this.filtros.inicio;
        }

        if (this.$utils.isValid(this.filtros.fin)) {
          url += "&Fin=" + this.filtros.fin;
        }

        if (this.$utils.isValid(this.filtros.idUsuario)) {
          url += "&IdUsuario=" + this.filtros.idUsuario;
        }

        if (this.$utils.isValid(this.filtros.idVista)) {
          url += "&IdVista=" + this.filtros.idVista;
        }

        if (this.$utils.isValid(this.filtros.idAccion)) {
          url += "&IdAccion=" + this.filtros.idAccion;
        }

        if (this.$utils.isValid(this.filtros.alerta)) {
          url += "&Alerta=" + this.filtros.alerta;
        }

        return url;
      },
    },

    methods: {
      cleanCache(){
        this.cleaningCache = true;
        try {
          axios.put('/Cache/BorrarCaches')  
          axios.put('/Cache/BorrarCachesOTS')
          this.cerrarDialogCache();
        } catch (error) {
          console.log(error);
        }
        finally {
          this.cleaningCache = false;
        }
      },
      cerrarDialogCache(){
        this.dialogCleanCache = false;  
      },
      showDialogCleanCache(){
        this.attentionAnimationKey++;
        this.dialogCleanCache = true;
      },
      descargarReporteRecetas(){
        var datetime = moment().format('YYYYMMDDHHmmss');
        this.$utils.axios.downloadFile(`/Logs/XlsLogsRecetas?access_token=${this.token}`,'GET','reporte-logs-recetas-'+datetime+'.xlsx','application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'); 
      },
      cerrarDialogLogsEspecializados(){
        this.dialogLogsEspecializados = false;
      },
      showLogsEspecializados(){
        this.dialogLogsEspecializados = true;
      },
      descargarLogs(){
        var datetime = moment().format('YYYYMMDDHHmmss');
        this.$utils.axios.downloadFile(this.getUrlDescarga(),'GET','reporte-logs-'+datetime+'.xlsx','application/vnd.openxmlformats-officedocument.spreadsheetml.sheet');
      },
      getUrlDescarga(){
        let url = `/Logs/XlsLogs?Paginar=false`;

        if (this.$utils.isValid(this.filtros.inicio)) {
          url += "&Inicio=" + this.filtros.inicio;
        }

        if (this.$utils.isValid(this.filtros.fin)) {
          url += "&Fin=" + this.filtros.fin;
        }

        if (this.$utils.isValid(this.filtros.idUsuario)) {
          url += "&IdUsuario=" + this.filtros.idUsuario;
        }

        if (this.$utils.isValid(this.filtros.idVista)) {
          url += "&IdVista=" + this.filtros.idVista;
        }

        if (this.$utils.isValid(this.filtros.idAccion)) {
          url += "&IdAccion=" + this.filtros.idAccion;
        }

        if (this.$utils.isValid(this.filtros.alerta)) {
          url += "&Alerta=" + this.filtros.alerta;
        }

        return url;
      },
      getPermisos(){
        axios
          .get( '/Permisos/Informacion' )
          .then( response => {
            this.vistas = [];
            response.data.secciones.forEach( seccion => {
              seccion.vistas.forEach(vista => {
                this.vistas.push( vista );
              });
            })
            this.acciones = response.data.acciones;
          })
          .catch( error => {
            console.log( error );
          })
      },
      getUsuarios(){
        axios
          .get( '/Usuarios' )
          .then( response =>  {
            this.usuarios = response.data.map( usuario => ({
              idUsuario: usuario.idUsuario,
              nombre: usuario.nombre
            }));
          })
          .catch( error => {
            console.log( error );
          })
      },
      getLogs() {
        //* Realizamos peticion HTTP
        return new Promise((resolve, reject) => {
          axios
            .get(this.logs_fetch_url)
            .then((response) => resolve(response))
            .catch((error) => reject(error));
        });
      },
      async initialize() {
        this.loading = true;
        try {
          let response = await this.getLogs();
          this.logs = this.formateoLogs(response.data.result);
          this.logs_total = response.data.total;
          this.loading = false;
        } catch (exception) {
          console.error(exception);
        }
      },
      async logs_update_page_action(page) {
        this.logs_actual_page = page;
        this.loading = true;
        try {
          let response = await this.getLogs();
          this.logs = this.formateoLogs(response.data.result);
          this.logs_total = response.data.total;
          this.loading = false;
        } catch (exception) {
          console.log(exception);
        }
      },
      formateoLogs ( logs ) {
        logs.forEach( element => {
          element.alerta = element.alert ? 'Sí' : 'No';
          try{
            element.body = JSON.stringify(JSON.parse(element.body),null, 4);
          }
          catch(e){
          }
          element.createdAt = moment(element.createdAt).format("yyyy-MM-DD HH:mm");
        });

        return logs;
      },
      watchItem (item) {
        this.watchedIndex = this.logs.indexOf(item)
        this.watchedItem = Object.assign({}, item)
        this.dialog = true
      },
      close () {
        this.dialog = false
        this.watchedItem = Object.assign({}, this.defaultItem)
        this.watchedIndex = -1;
      },      
    },
  }
</script>