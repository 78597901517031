<template>
  <div>
    <v-dialog
      v-model="dialog"
      persistent
      max-width="70%"
    >
      <v-card>
        <v-card-title>
          Asignar Vendedor - {{assignedItem.clave}}
        </v-card-title>
        <v-card-text>
          <v-row>
            <v-col cols="12">
              <v-select
                v-model="idVendedor"
                :items="vendedores"
                item-value="idVendedor"
                :item-text="nombrePersona"
              ></v-select>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="green" text @click="closeAssign">Cancelar</v-btn>
          <v-btn color="red" text @click="save">Aceptar</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-row>
      <v-col cols="12" md="12">
        <v-data-table
          v-if="permisoVista('otssinvendedor','r')"
          :headers="headers"
          :items="ots"
          :search="search"
          :loading="loading"
          loading-text="Cargando... Espere, Por Favor."
        >
          <template v-slot:top>
            <v-toolbar flat color="white">
              <v-text-field
                v-model="search"
                append-icon="search"
                label="Buscar"
                single-line
                hide-details
              ></v-text-field>
            </v-toolbar>
          </template>
          <template v-slot:item.action="{ item }">
            <v-tooltip bottom color="black" class="white--text">
              <template v-slot:activator="{ on }">
                <v-icon v-on="on"
                  v-if="permisoVista('otssinvendedor','c')"
                  small
                  class="mr-2"
                  @click="assignSeller(item)"
                >
                  mdi-check-bold
                </v-icon>
             </template>
              <span class="white--text">Asignar Vendedor</span>
            </v-tooltip>
          </template>
        </v-data-table>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import axios from "axios";

export default {
  data: () => ({
    loading: false,
    ots:[],
    loadingVendedores: false,
    vendedores:[],
    headers: [
      {
        text: 'Clave',
        align: 'center',
        sortable: true,
        value: 'clave',
        width:'20%'
      },
      {
        text: 'Línea Comercial',
        align: 'center',
        sortable: true,
        value: 'comercialLinea',
      },
      {
        text: 'Cliente',
        align: 'center',
        sortable: true,
        value: 'cliente',
      },
      {
        text: 'Fecha',
        align: 'center',
        sortable: true,
        value: 'fechaCreacion',
      },
      { text: 'Acciones', value: 'action', sortable: false, width:'20%', align:'center' },
    ],
    assignedItem: {
      clave:"",
    },
    defaultAssignedItem: {
      clave:"",
    },
    idVendedor: -1,
  }),
  mounted(){
    this.initialize();
  },
  methods:{
    nombrePersona: persona => persona.nombre + " " + persona.paterno + " " + persona.materno,
    initialize(){
      this.getOTSSinVendedor();
      this.getVendedores();
    },
    getOTSSinVendedor(){
      this.loading = true;
      axios
      .get("/Vendedores/TrabajoOrdenSinVendedor")
      .then(response => {
        this.loading = false;
        this.ots = response.data;
      })
      .catch(error => {
        console.log(error);
      })
    },
    getVendedores(){
      this.loadingVendedores = true;
      axios
      .get("/Vendedores")
      .then(response => {
        this.loadingVendedores = false;
        this.vendedores = response.data;
      })
      .catch(error => {
        console.log(error);
        this.loadingVendedores = false;
      })
    },
    assignSeller(item){
      this.assignedItem = item;
      this.dialog = true;
    },
    closeAssign(){
      this.assignedItem = Object.assign({},this.defaultAssignedItem);
      this.dialog = false;
      this.idVendedor = -1;
    },
    save(){
      if(this.idVendedor <= 0){
        return;
      }
      axios
      .post("/Vendedores/VendedorATrabajoOrden/"+this.assignedItem.idTrabajoOrden+"/"+this.idVendedor)
      .then(() => {
        this.closeAssign();
        this.initialize();
      })
      .catch(error => {
        console.log(error);
      })
    }
  }
}
</script>