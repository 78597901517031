<template>
  <v-dialog v-model="dialogScrap" persistent max-width="700px">
			<v-card>
				<v-card-title>Reportar Scrap</v-card-title>
				<v-card-text>
          <v-row class="align-center pt-4">
			<!-- <v-col cols="12">
              <v-autocomplete
			  	v-model="idRemanufacturaMotivo"
				:items="motivosRefac"
				item-text="nombre"
				item-value="idRemanufacturaMotivo"
				label="Motivo de Remanufactura"
			  ></v-autocomplete>
            </v-col> -->
            <v-col cols="12" class="d-flex align-center">
              Cantidad de Scrap:
              <div style="width:15px" />
					    <number-input
                v-model="cantidad"
					    	controls
					    	inline
					    	size="small"
					    	min="0"
                :max="cantidadMaxima"
					    	center
                @change="cambioValor"
					    ></number-input>
              <div style="width:10px" />
              {{unidad}}
            </v-col>
          </v-row>
					<v-switch
						v-if="componentes.numPasos > 0"
        	  v-model="areaActual"
        	  label="¿Incluir Área Actual?"
        	  color="red"
        	  value="red"
        	></v-switch>
  				<v-treeview
            v-model="seleccionados"
					  v-if="componentes.idArticulo > -1 && !loadingComponentes"
            :items="componentesFiltrados"
						selection-type="independent"
				  	item-children="subComponentes"
				  	item-text="nombre"
				  	item-key="idArticulo"
            selectable
				  	open-all
            return-object
          >
					  <template v-slot:label="{ item, selected }">
					  	<v-row class="ma-0 pa-0 align-center">
					  		{{item.nombre}}
					  		<div style="width:20px"></div>
					  		<v-div style="max-width: 200px">
					  			<number-input
                    v-model="item.cantidadSeleccionada"
					  				controls
					  				inline
					  				size="small"
					  				min="0"
					  				:max="item.cantidad"
					  				:disabled="!selected"
					  				center
                    @change="actualizarCantidad($event,item)"
					  			></number-input>
					  		</v-div>
                <div style="width:20px"></div>
                {{item.unidad}}
					  	</v-row>
					  </template>
			  	</v-treeview>
			  	<div v-else>
						<br>
						<v-progress-linear
			  		color="primary"
			  		indeterminate
			  	></v-progress-linear>
					</div>
				<ul class="mt-3">
                  <li class="red--text" v-for="err,idx in erroresScrap" :key="idx">
                    {{ err }}
                  </li>
                </ul>
				</v-card-text>
				<v-card-actions>
					<v-spacer></v-spacer>
					<v-btn
						text
						color="red"
						@click="cerrarDialogScrap"
					>
						Cancelar
					</v-btn>
					<v-btn
						text
						color="green"
						:loading="savingScrap"
						@click="saveScrap"
					>
						Guardar
					</v-btn>
				</v-card-actions>
			</v-card>
		</v-dialog>
</template>

<script>
import axios from "axios";

export default {
	data:() =>({
		erroresScrap: [],
		idRemanufacturaMotivo: null,
		motivosRefac: [],
    savingScrap: false,
		dialogScrap: false,
    idParo:-1,
    cantidad: 0,
    cantidadMaxima:0,
		areaActual: true,
    loadingComponentes: false,
    unidad: "",
		componentes:{
			idArticulo: -1,
      idReceta: -1,
      nombre: "",
      cantidad: 0,
			numPasos: 0,
      componentesPasoActual: [],
      componentesOtrasAreas: [],
		},
    componentesFiltrados:[],
    defaultComponentes:{
			idArticulo: -1,
      idReceta: -1,
      nombre: "",
      cantidad: 0,
			numPasos: 0,
      componentesPasoActual: [],
      componentesOtrasAreas: [],
		},
    time: 0,
    interval: null,
    seleccionados:[],
  }),
  methods:{
    actualizarCantidad($event,item){
      console.log($event);
      console.log(item);
    },
    cambioValor(){
      this.loadingComponentes = true;
      if(this.interval != null) this.time = 1500;
      else{
        this.time = 1500;
        this.interval = setInterval(() => {
          if(this.time > 0) this.time -= 500;
          else{
            this.time = 1500;
            clearInterval(this.interval);
            this.interval = null;
            this.getComponentes();
          }
        },500);
      }
    },
    cantidadPredeterminada(subcomponentes){
			for (let i = 0; i < subcomponentes.length; i++) {
				subcomponentes[i].cantidadSeleccionada = subcomponentes[i].cantidad;
				subcomponentes[i].subComponentes = this.cantidadPredeterminada(subcomponentes[i].subComponentes);
			}
			
			return subcomponentes;
		},
    reportarScrap(item) {
		// this.getRemanufacturaMotivos();
      this.unidad = item.unidad;
			this.idParo = item.idParo;
			this.cantidadMaxima = item.cantidadReportada - (item.cantidadRetrabajo || 0) - (item.cantidadScrap);
			this.dialogScrap = true;
		},
		getRemanufacturaMotivos(){
			axios
				.get( "/RemanufacturaMotivos" )
				.then( response => {
					this.motivosRefac = response.data;
				})
				.catch( error => {
					console.log( error );
				})
		},
    getComponentes(){
      if(this.cantidad <= 0) {
				this.componentes = Object.assign({},this.defaultComponentes);
				return;
			}
			this.loadingComponentes = false;
			axios
			.put("/Paros/ComponentesArticuloParo/",{
        IdParo:this.idParo,
        Cantidad: this.cantidad,
      })
			.then(response => {
				
				this.componentes = response.data;
        this.seleccionados = [];
        var subcomponentes = [];
			  if(this.areaActual || this.componentes.numPasos == 1) subcomponentes = this.componentes.componentesPasoActual.concat(this.componentes.componentesOtrasAreas);			
			  else subcomponentes = this.componentes.componentesOtrasAreas;
		
			  this.componentesFiltrados = [{
			  	idArticulo: this.componentes.idArticulo,
					idReceta: this.componentes.idReceta,
			  	nombre: this.componentes.nombre,
			  	cantidad: this.componentes.cantidad,
			  	cantidadSeleccionada: this.componentes.cantidad,
          unidad: this.componentes.unidad,
			  	subComponentes: this.cantidadPredeterminada(subcomponentes)
			  }];

        setTimeout(() => {
					this.loadingComponentes = false;
				},500);
			})
			.catch(error => {
				console.log(error);
			})
		},
    actualizarCantidad($event,item){
      setTimeout(() => {
        var index = this.seleccionados.findIndex(s => s.idArticulo == item.idArticulo && s.idReceta == item.idReceta && s.cantidad == item.cantidad);
        if(index >= 0) this.seleccionados[index].cantidadSeleccionada = $event;
      },100);
    },
	validateScrap(seleccion){
		this.erroresScrap = [];

		// if(this.idRemanufacturaMotivo == 0)
		// 	this.erroresScrap.push("Debe seleccionar el Motivo de Remanufactura.");

		if(seleccion.length <= 0)
			this.erroresScrap.push("Debe indicar la Cantidad de Scrap y seleccionar al menos una casilla.")
		
		return this.erroresScrap.length == 0;
	},
    saveScrap(){
		var seleccion = this.seleccionados
				.filter(s => s.cantidadSeleccionada > 0.0)
				.map((s) =>  ({
			IdArticulo: s.idArticulo,
					IdReceta: s.idReceta,
					Cantidad: s.cantidadSeleccionada,
		}));

		if (!this.validateScrap(seleccion)) return;

		this.savingScrap = true;
			axios
			.post("/Paros/ReportarScrapParo",{
				IdParo: this.idParo,
				AreaActual: this.areaActual,
				Componentes: seleccion,
				Cantidad: this.cantidad,
				idRemanufacturaMotivo: this.idRemanufacturaMotivo
			})
			.then(() => {
				this.savingScrap = false;
				this.cerrarDialogScrap();
				this.$emit('reload',null);
			})
			.catch(error => {
				this.savingScrap = false;
				console.log(error);
			});

    },
    /*
    saveScrap() {
			this.savingScrap = true;
			this.cantidadScrap = parseFloat(
				this.cantidadScrap
			);
			axios
				.post("/Paros/ReportarScrapParo", this.
				.then(() => {
					this.savingScrap = false;
					this.cerrarDialogScrap();
					this.initialize();
				})
				.catch((error) => {
					this.savingScrap = false;
					console.log(error);
				});
		},
		
    */
		cerrarDialogScrap() {
			this.idRemanufacturaMotivo = null;
			this.dialogScrap = false;
			this.cantidadMaxima = 0;
			this.idParo = -1;
			this.erroresScrap = [];
      this.componentes = Object.assign({},this.defaultComponentes);
      this.areaActual = true;
			this.loadingComponentes = false;
      this.unidad = "";
      this.cantidad = 0;
			this.time = 0;
			if(this.interval != null){
				clearInterval(this.interval);
			}
			this.interval = null;
		},
  }
}
</script>