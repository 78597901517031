<template>
<v-col class="padding-2px">
  <v-container>
    <v-text-field
        single-line
        hide-details
        color="white"
        disabled
        readonly
    ></v-text-field>
    </v-container>
    
    <div class="board-column">
        <v-col :class="columnClass">
            {{headerText}}
        </v-col>
        <draggable
            :list="list"
            v-bind="$attrs"
            class="board-column-content"
            :set-data="setData"
        >
          <div v-for="item in list" :key="item.id" class="board-item">
              <v-row>
                <v-col cols="5" sm="5" md="5" class="align-self-center">
                  {{ itemText(item) }}
                </v-col>
                <v-col v-if="(typeof item.idPuesto !== 'undefined')" cols="5" sm="5" md="5">
                  <v-row>
                    <v-col cols="8" sm="8" md="8">
                      <v-text-field
                        v-model="item.cantidad"
                        type="number"
                        min="1"
                        label="Número"
                        single-line
                        dense
                        hide-details
                        @keyup="cambioValor"
                       
                      ></v-text-field>
                    </v-col>
                    <v-col cols="4" sm="4" md="4" class="align-self-center">
                      Emp
                    </v-col>
                  </v-row>
                </v-col>
                <v-col v-if="(typeof item.idDesconocido !== 'undefined')" cols="5" sm="5" md="5">
                  <v-row>
                    <v-col cols="8" sm="8" md="8">
                      <v-text-field
                        v-model="item.cantidad"
                        type="number"
                        min="1"
                        label="Cantidad"
                        single-line
                        dense
                        hide-details
                        @keyup="cambioValor"
                       
                      ></v-text-field>
                    </v-col>
                    <v-col cols="4" sm="4" md="4" class="align-self-center">
                      X
                    </v-col>
                  </v-row>
                </v-col>
                <v-col v-if="(typeof item.marca === 'undefined' && typeof item.idDesconocido === 'undefined' && typeof item.idPuesto === 'undefined')" cols="5" sm="5" md="5">
                  <v-row>
                    <v-col cols="8" sm="8" md="8">
                      <v-text-field
                        v-model="item.cantidad"
                        type="number"
                        min="1"
                        label="Cantidad"
                        single-line
                        dense
                        hide-details
                        @keyup="cambioValor"
                       
                      ></v-text-field>
                    </v-col>
                    <v-col cols="4" sm="4" md="4" class="align-self-center">
                      {{item.unidad}}
                    </v-col>
                  </v-row>
                </v-col>
                <v-col v-if="(typeof item.marca !== 'undefined')" cols="5" sm="5" md="5">
                  <v-row>
                    <v-col cols="8" sm="8" md="8">
                      <v-text-field
                        v-model="item.cantidad"
                        type="number"
                        min="1"
                        label="Cantidad"
                        single-line
                        dense
                        hide-details
                        @keyup="cambioValor"
                       
                      ></v-text-field>
                    </v-col>
                    <v-col cols="4" sm="4" md="4">
                      U
                    </v-col>
                  </v-row>
                </v-col>
                
                <v-col cols="2" sm="2" md="2" class="align-self-center">
                  &nbsp;
                  <v-icon
                    size="24px"
                    @click="itemDelete(item)"
                  >
                    mdi-close-circle-outline
                  </v-icon>
                </v-col>
              </v-row>
          </div>
        </draggable>
    </div>
</v-col>
</template>

<script>
import draggable from 'vuedraggable'

export default {
  name: 'DragBox',
  components: {
    draggable
  },
  data: () => ({
      search:'',
  }),
  props: {
    headerText: {
      type: String,
      default: 'Header'
    },
    options: {
      type: Object,
      default() {
        return {}
      }
    },
    itemText:{
        type:Function,
        default: item => item.name,
    },
    inputColor: {
        type:String,
        default:''
    },
    backgroundColor:{
        type:String,
        default: ''
    },
    list: {
      type: Array,
      default() {
        return []
      }
    }
  },
  watch:{
    list(){
      this.list.forEach(function(element){
        if(typeof element.cantidad === 'undefined' || element.cantidad == null){
          element.cantidad = 1;
        }
      });
    }
  },
  methods: {
    setData(dataTransfer) {
      dataTransfer.setData('Text', '')
    },
    itemDelete(item){
      item.cantidad = ""
      let index = this.list.indexOf(item);
      this.$emit('update:list', this.list.splice(index, 1));
    },
    cambioValor(){
      this.$emit('update:list', this.list.splice(0, 0));
    }
  },
  computed: {
    columnClass(){
        return 'board-column-header '+this.backgroundColor;
    }
  }
}
</script>
<style lang="scss" scoped>
.board-column {
      //min-width: 300px;
      min-height: 100px !important;
      overflow: hidden !important;
      background: #f0f0f0 !important;
      border-radius: 3px !important;
    
    .board-column-header {
      font-size: 1.25em !important; 
      height: 50px !important;
      line-height: 50px !important;
      overflow: hidden !important;
      padding: 0 20px !important;
      text-align: center !important;
      color: #fff !important;
      border-radius: 3px 3px 0 0 !important;
    }

  .board-column-content {
    overflow-y: scroll !important;
    overflow-x: hidden !important;
    border: 10px solid transparent !important;
    min-height: 60px !important;
    max-height: 115px !important;
    display: flex !important;
    justify-content: flex-start !important;
    flex-direction: column !important;
    align-items: center !important;

    .board-item {
      cursor: pointer !important;
      width: 99% !important;
      //height: 64px !important;
      margin: 5px 0 !important;
      background-color: #fff !important;
      text-align: left !important;
      //line-height: 54px;
      padding: 5px 10px !important;
      box-sizing: border-box !important;
      box-shadow: 0px 1px 3px 0 rgba(0, 0, 0, 0.2) !important;
    }
  } 
}
.padding-2px{
    padding: 2px !important;
}

.padding-0px{
  padding-top: 0px !important;
}

.no-padding{
    padding-top: 0px !important;
    padding-bottom: 0px !important;
    margin-top: auto;
    margin-bottom: auto;
}

.icon-size{
  font-size: 18px;
}

</style>

