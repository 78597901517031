import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VRow } from 'vuetify/lib/components/VGrid';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VContainer,[_c('div',{staticClass:"text-h6"},[_vm._v(_vm._s(_vm.nombreMaquinaIns(_vm.maquinaIns)))]),_c(VRow,[_c(VCol,{attrs:{"cols":"9"}},[_c('apexchart',{attrs:{"type":"line","height":"348","options":_vm.chartOptions,"series":_vm.datos}})],1),_c(VCol,{attrs:{"cols":"3"}},[_c(VCard,[_c(VCardText,{staticClass:"text-center align-self-center"},[_c('div',{staticClass:"subtitle-1 font-weight-bold"},[_vm._v(" # Lecturas ")]),_c('ICountUp',{staticClass:"subtitle-1 font-weight-medium",attrs:{"delay":1000,"endVal":_vm.numLecturas}})],1)],1),_c('br'),_c(VCard,[_c(VCardText,{staticClass:"text-center align-self-center"},[_c('div',{staticClass:"subtitle-1 font-weight-bold"},[_vm._v(" Temperatura ")]),_c('ICountUp',{staticClass:"subtitle-1 font-weight-medium blue--text",attrs:{"delay":1000,"endVal":_vm.promedioTemperatura,"options":{
              decimalPlaces: 2,
              separator: ',',
              decimal: '.',
              prefix:'',
              suffix:'°'
            }}})],1)],1),_c('br'),_c(VCard,[_c(VCardText,{staticClass:"text-center align-self-center"},[_c('div',{staticClass:"subtitle-1 font-weight-bold"},[_vm._v(" Humedad ")]),_c('ICountUp',{staticClass:"subtitle-1 font-weight-medium red--text",attrs:{"delay":1000,"endVal":_vm.promedioHumedad,"options":{
              decimalPlaces: 2,
              separator: ',',
              decimal: '.',
              prefix:'',
              suffix:'%'
            }}})],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }