import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VToolbar } from 'vuetify/lib/components/VToolbar';
import { VToolbarTitle } from 'vuetify/lib/components/VToolbar';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VDialog,{attrs:{"fullscreen":"","hide-overlay":"","transition":"dialog-bottom-transition"},model:{value:(_vm.show),callback:function ($$v) {_vm.show=$$v},expression:"show"}},[_c(VCard,[_c(VToolbar,{attrs:{"dark":"","color":"primary"}},[_c(VBtn,{attrs:{"icon":"","dark":""},on:{"click":_vm.close}},[_c(VIcon,[_vm._v("mdi-close")])],1),_c(VToolbarTitle,{staticClass:"white--text"},[_vm._v("DIAGRAMA DE "+_vm._s(_vm.item != null ? _vm.item.nombre : ""))]),_c(VSpacer)],1),_c('svg',{staticClass:"d3-tree-vi width-100-percent",attrs:{"id":'svg' + _vm.idArticuloTipo}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }