<template>
  <div>
    <br>
    <br>
    <v-progress-linear
      v-if="cargando"
      indeterminate
      color="blue darken-2"
    ></v-progress-linear>
    <div
      v-if="totalItems == 0"
    >
    </div>
    <v-data-table
      v-else
      :items-per-page="itemsPagina"
      :page="pagina"
      :items="trabajoOrdenes"
      :headers="headers"
      hide-default-footer
      class="tabla-avance"
    >
      <template v-slot:item="{ item }">
        <tr>
          <td class="text-center">
            {{item.clave}}
          </td>
          <td class="text-center">
            {{item.cliente}}
          </td>
          <td class="text-center">
            {{item.porcentajeCompletado.toFixed(2)}} %
          </td>
          <td class="text-center">
            <barra-avance
              v-model="item.areas"
              borderColor="#ffffff"
              :height="30"
            ></barra-avance>
          </td>
          <td :class="'text-center '+colorDiasRestantes(item.diasRestantes)">
            {{item.fechaEntrega}}
          </td>
          <td :class="'text-center '+colorDiasRestantes(item.diasRestantes)">
            {{item.diasRestantes}}
          </td>
        </tr>
      </template>
    </v-data-table>

  </div>
</template>

<script>
import axios from "axios"
import barraAvance from '@/components/BarraAvance';

export default {
  components: {
      barraAvance,
  },
  props:{
    update:{
      type: Number,
      default: 5,
    },
    pageInterval:{
      type: Number,
      default: 10,
    }
  },
  data: () => ({
    cargando: false,
    interval: null,
    intervalPagina: null,
    totalItems: 0,
    itemsPagina: 10,
    pagina: 1,
    trabajoOrdenes: [],
    headers: [
      {
        text: "OT",
        align: "center",
        sortable: false,
        value: "clave",
        width: '12%',
      },
      {
        text: "Cliente",
        align: "center",
        sortable: false,
        value: "cliente",
        width: '17%',
      },
      {
        text: "% Completado",
        align: "center",
        sortable: false,
        value: "porcentajeCompletado",
        width: '10%',
      },
      {
        text: "Áreas",
        align: "center",
        sortable: false,
        value: "areas",
        width: '37%',
      },
      {
        text: "Fecha Compromiso Entrega",
        align: "center",
        sortable: false,
        value: "fechaEntrega",
        width: '12%',
      },
      {
        text: "Dias Restantes Entrega",
        align: "center",
        sortable: false,
        value: "diasRestantes",
        width: '12%',
      },
    ]
  }),
  computed:{
    totalPaginas(){
      var v = Math.ceil(this.totalItems / this.itemsPagina);
      return (isNaN(v) || !isFinite(v)) ? 1 : v;
    }
  },
  mounted(){
    this.getData();
    this.interval = setInterval(() => {
      this.getData();
    },(this.update * 60 * 1000));

    this.intervalPagina = setInterval(() => {
      if(this.cargando || this.totalPaginas == 1) return;
      this.pagina = ((this.pagina + 1)> this.totalPaginas) ? 1 :  (this.pagina + 1);
    },(this.pageInterval * 1000))
  },
  destroyed(){
    clearInterval(this.interval);
    clearInterval(this.intervalPagina);
  },
  methods:{
    getData(){
      this.cargando = true;
      axios
      .get('/Reportes/TrabajoOrdenesReporte')
      .then(response => {
        this.cargando = false;
        this.trabajoOrdenes = response.data;
        this.totalItems = response.data.length;
        this.pagina = 1;
      })
      .catch(error => {
        this.cargando = false;
        console.log(error);
      })
    },
    colorDiasRestantes(dias){
      if(dias > 5)return 'green--text';
      else if(dias <= 5 && dias >0) return 'orange--text';
      return 'red--text'
    }
  }
}
</script>
<style scoped>
.tabla-avance >>> table thead tr th {
  padding-bottom: 1rem !important;
}
.tabla-avance >>> table thead tr th span {
  font-size: 1.25rem !important;
  font-weight: bold;
}
.tabla-avance >>> td {
  font-size: 1.125rem !important;
}
</style>