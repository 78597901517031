<template>  

 <ListaSimulacion>

 </ListaSimulacion>

</template>

<script>
import Lottie from "vue-lottie";
import LanzarSimulacion from './Configuracion/Simulacion/LanzarSimulacion.vue';
import ConfigSimulacion from './Configuracion/Simulacion/ConfigSimulacion.vue';
import ListaSimulacion from "./Configuracion/ListaSimulacion.vue";

export default {
	components: {
		lottie: Lottie,
    LanzarSimulacion,
    ConfigSimulacion,
    ListaSimulacion
	},
}
</script>

<style scoped>

</style>