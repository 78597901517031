import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VProgressLinear } from 'vuetify/lib/components/VProgressLinear';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VSwitch } from 'vuetify/lib/components/VSwitch';
import { VTreeview } from 'vuetify/lib/components/VTreeview';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VDialog,{attrs:{"persistent":"","max-width":"700px"},model:{value:(_vm.dialogScrap),callback:function ($$v) {_vm.dialogScrap=$$v},expression:"dialogScrap"}},[_c(VCard,[_c(VCardTitle,[_vm._v("Reportar Scrap")]),_c(VCardText,[_c(VRow,{staticClass:"align-center pt-4"},[_c(VCol,{staticClass:"d-flex align-center",attrs:{"cols":"12"}},[_vm._v(" Cantidad de Scrap: "),_c('div',{staticStyle:{"width":"15px"}}),_c('number-input',{attrs:{"controls":"","inline":"","size":"small","min":"0","max":_vm.cantidadMaxima,"center":""},on:{"change":_vm.cambioValor},model:{value:(_vm.cantidad),callback:function ($$v) {_vm.cantidad=$$v},expression:"cantidad"}}),_c('div',{staticStyle:{"width":"10px"}}),_vm._v(" "+_vm._s(_vm.unidad)+" ")],1)],1),(_vm.componentes.numPasos > 0)?_c(VSwitch,{attrs:{"label":"¿Incluir Área Actual?","color":"red","value":"red"},model:{value:(_vm.areaActual),callback:function ($$v) {_vm.areaActual=$$v},expression:"areaActual"}}):_vm._e(),(_vm.componentes.idArticulo > -1 && !_vm.loadingComponentes)?_c(VTreeview,{attrs:{"items":_vm.componentesFiltrados,"selection-type":"independent","item-children":"subComponentes","item-text":"nombre","item-key":"idArticulo","selectable":"","open-all":"","return-object":""},scopedSlots:_vm._u([{key:"label",fn:function(ref){
var item = ref.item;
var selected = ref.selected;
return [_c(VRow,{staticClass:"ma-0 pa-0 align-center"},[_vm._v(" "+_vm._s(item.nombre)+" "),_c('div',{staticStyle:{"width":"20px"}}),_c('v-div',{staticStyle:{"max-width":"200px"}},[_c('number-input',{attrs:{"controls":"","inline":"","size":"small","min":"0","max":item.cantidad,"disabled":!selected,"center":""},on:{"change":function($event){return _vm.actualizarCantidad($event,item)}},model:{value:(item.cantidadSeleccionada),callback:function ($$v) {_vm.$set(item, "cantidadSeleccionada", $$v)},expression:"item.cantidadSeleccionada"}})],1),_c('div',{staticStyle:{"width":"20px"}}),_vm._v(" "+_vm._s(item.unidad)+" ")],1)]}}],null,false,2477213240),model:{value:(_vm.seleccionados),callback:function ($$v) {_vm.seleccionados=$$v},expression:"seleccionados"}}):_c('div',[_c('br'),_c(VProgressLinear,{attrs:{"color":"primary","indeterminate":""}})],1),_c('ul',{staticClass:"mt-3"},_vm._l((_vm.erroresScrap),function(err,idx){return _c('li',{key:idx,staticClass:"red--text"},[_vm._v(" "+_vm._s(err)+" ")])}),0)],1),_c(VCardActions,[_c(VSpacer),_c(VBtn,{attrs:{"text":"","color":"red"},on:{"click":_vm.cerrarDialogScrap}},[_vm._v(" Cancelar ")]),_c(VBtn,{attrs:{"text":"","color":"green","loading":_vm.savingScrap},on:{"click":_vm.saveScrap}},[_vm._v(" Guardar ")])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }