<template>
  <v-col cols="12">
    <v-row>
      <v-col cols="4">
        <DownloadBtn
          text
          color="primary"
          className="my-0"
          @click="descargarReporte()"
          label="Descargar Reporte<br/>Operadores Sin Operaciones Recientes"
        />
      </v-col>
      <v-col cols="4">
      </v-col>
      <v-col cols="4">
        <v-select
          v-model="selected"
          :items="intervalos"
          item-text="text"
          item-value="value"
          label="Tiempo"
          @change="GetData()"
        ></v-select>
      </v-col>
    </v-row>
    <v-row class="px-0 ma-0">
      <v-col cols="12" class="ma-0 text-right text-subtitle-2">
        Proxima Actualización en {{tiempo}}
      </v-col>
    </v-row>
    <v-row>
      <v-col class="col-12">
        <v-data-table
          :headers="headers"
          :items="desserts"
          :loading="cargando"
          loading-text="Cargando... Espere por favor"
          :items-per-page="-1"
          class="elevation-1"
          hide-default-footer
        >
          <template v-slot:item="{ item }">
            <tr>
              <td class="text-center">
                {{ item.nombre }} {{ item.paterno }} {{ item.materno }}
              </td>
              <td class="text-center">{{ hour(item.tiempoNoReportado) }} Horas</td>
              <td class="text-center">{{ hour(item.tiempoNoTrabajado) }} Horas</td>
            </tr>
          </template></v-data-table
        >
      </v-col>
    </v-row>
  </v-col>
</template>

<script>
import axios from "axios";
import DownloadBtn from "../../DownloadBtn.vue";
import Abierto from '../OTS/Lista/Abierto.vue';

export default {
  components: { DownloadBtn, Abierto },
  data: () => ({
    selected: 15,
    intervalos: [
      {
        text: "15 Minutos",
        value: 15
      },
      {
        text: "30 Minutos",
        value: 30
      },
      {
        text: "1 Hora",
        value: 60
      },
      {
        text: "2 Horas",
        value: 120
      },
      {
        text: "8 Horas",
        value: 480
      }
    ],
    headers: [
      {
        text: "Operador",
        align: "center",
        sortable: true,
        value: "nombre",
      },
      {
        text: "Tiempo Sin Haber Reportado",
        align: "center",
        sortable: true,
        value: "tiempoNoReportado",
      },
      {
        text: "Tiempo No Trabajado",
        align: "center",
        sortable: true,
        value: "tiempoNoTrabajado",
      },
    ],
    desserts: [],
    interval: 60,
    segundos: 60,
    cargando: false,
    alert: false,
  }),
  mounted() {
    this.GetData();

    this.interval = setInterval(() => {
      if (this.segundos >= 0 && this.segundos <= 10) this.alert = true;

      if (this.segundos == 0) this.GetData();
      else this.segundos -= 1;
    }, 1000);
  },
  computed:{
    tiempo(){
      return this.$utils.strings.toTime(this.segundos);
    }
  },
  methods: {
    hour(time) {
      //console.log(time)
      //return moment(time).format("hh:mm");
      var horas = Math.floor(time/60);
      var minutos = time - (horas * 60)
      var r = "";
      if(horas < 10) r = `0${horas}:`;
      else r = `${horas}:`;

      if(minutos < 10) r += `0${minutos}`;
      else r += `${minutos}`;
      return r;
    
    },
    GetData() {
      if (this.cargando) return;

      this.cargando = true;
      this.segundos = 60;

      axios
        .get(`/Reportes/OperadorActividadDia?minutos=${this.selected}`)
        .then((response) => {
          this.desserts = response.data;
          this.cargando = false;
          this.alert = false;
        });
    },
    descargarReporte() {
      var url = `/Reportes/XLSOperadorActividadDia?minutos=${this.selected}`;

      this.$utils.axios.downloadFile(
        url,
        "GET",
        "Operadores-Sin-Operaciones-Recientes.xlsx",
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
      );
    },
  },
  destroyed() {
    clearInterval(this.interval);
  },
};
</script>