<template>
	<v-col cols="12" v-if="permisoVista('salidasauto','r')">
		<v-dialog v-model="dialogActualizar" persistent max-width="35%">
			<v-card>
				<v-card-text>
					<p class="pt-4 text-h5 font-weight-medium">
						¿Está seguro de desactivar la Salida Automática del
						siguiente artículo?
					</p>
					<p style="font-size: 16px !important">
						•
						<span
							v-if="itemSalida.codigo != null"
							style="font-size: 16px !important"
							class="font-weight-bold"
							>[{{ itemSalida.codigo }}] -
						</span>
						{{ itemSalida.nombre }}
					</p>
				</v-card-text>
				<v-card-actions>
					<v-spacer></v-spacer>
					<v-btn
						color="success"
						text
						:disabled="updatingSalida"
						@click="closeDialogActualizar()"
						>Cancelar</v-btn
					>
					<v-btn
						color="error"
						text
						:disabled="updatingSalida"
						:loading="updatingSalida"
						@click="actualizarSalida()"
						>Aceptar</v-btn
					>
				</v-card-actions>
			</v-card>
		</v-dialog>
		<v-data-table
			:items="salidas"
			:headers="salidasHeaders"
			:loading="loading"
		>
			<template v-slot:[`item.articulo`]="{ item }">
				{{ getNombreArticulo(item) }}
			</template>
			<template v-slot:[`item.salidaAutomatica`]="{ item }">
				<v-chip
					:color="item.salidaAutomatica ? 'green' : 'red'"
					outlined
					class="font-weight-bold"
				>
					{{ item.salidaAutomatica ? "Activada" : "Desactivada" }}
				</v-chip>
			</template>
			<template v-slot:[`item.actions`]="{ item }">
				<v-tooltip v-if="permisoVista('salidasauto','d')" bottom color="black" class="white--text">
					<template v-slot:activator="{ on }">
						<v-icon
							v-on="on"
							small
							class="mr-1"
							@click="showDialogActualizar(item)"
						>
							mdi-delete
						</v-icon>
					</template>
					<span class="white--text">Desactivar</span>
				</v-tooltip>
			</template>
		</v-data-table>
	</v-col>
</template>

<script>
import axios from "axios";
export default {
	data() {
		return {
			itemSalida: {
				idArticulo: 0,
				codigo: "",
				nombre: "string",
				revisionSalidadAutomatica: "string",
				salidaAutomatica: true,
			},
			defaultItemSalida: {
				idArticulo: 0,
				codigo: "",
				nombre: "",
				revisionSalidadAutomatica: "",
				salidaAutomatica: true,
			},
			salidas: [],
			dialogActualizar: false,
			loading: false,
			updatingSalida: false,
			salidasHeaders: [
				{
					text: "Artículo",
					align: "center",
					sortable: false,
					value: "articulo",
					width: "30%",
				},
				{
					text: "Revisión",
					align: "center",
					sortable: false,
					value: "revisionSalidadAutomatica",
					width: "30%",
				},
				{
					text: "Salida Automática",
					align: "center",
					sortable: false,
					value: "salidaAutomatica",
					width: "30%",
				},
				{
					text: "Acciones",
					align: "center",
					sortable: false,
					value: "actions",
					width: "10%",
				},
			],
		};
	},
	mounted() {
		this.initialize();
	},
	methods: {
		getNombreArticulo: (value) =>
			value.codigo != null
				? `[${value.codigo}] - ${value.nombre}`
				: `${value.nombre}`,
		actualizarSalida() {
			this.updatingSalida = true;
			axios
				.put(`/Inventario/DesactivarSalidaAutomaticaArticulo/${this.itemSalida.idArticulo}`)
				.then(() => {
					this.updatingSalida = false;
					this.closeDialogActualizar();
					this.getSalidas();
				})
				.catch((error) => {
					this.updatingSalida = false;
					console.log(error);
				});
		},
		closeDialogActualizar() {
			this.dialogActualizar = false;
			this.itemSalida = Object.assign({}, this.defaultItemSalida);
		},
		showDialogActualizar(item) {
			this.itemSalida = Object.assign({}, item);
			this.dialogActualizar = true;
		},
		initialize() {
			this.getSalidas();
		},
		getSalidas() {
			this.loading = true;
			axios
				.get("/Inventario/GetArticulosSalidaAutomatica")
				.then((response) => {
					this.loading = false;
					this.salidas = response.data;
				})
				.catch((error) => {
					this.loading = false;
					console.log(error);
				});
		},
	},
};
</script>