<template>
	<div>
		<v-tabs v-model="tab" background-color="transparent" grow>
			<v-tab
				@click="mantenimientosClick"
			>
				Mantenimientos
			</v-tab>
			<v-tab
				v-if="permisoVista('mantenimiento-inspecciones','r')"
				@click="inspeccionesClick"
			>
				Inspecciones
			</v-tab>

			<v-tabs-items v-model="tab" touchless>
				<v-tab-item
					eager
				>
					<Mantenimientos ref="mant" />
				</v-tab-item>
				<v-tab-item
					v-if="permisoVista('mantenimiento-inspecciones','r')"
					eager
				>
					<Inspecciones ref="ins" />
				</v-tab-item>
			</v-tabs-items>
		</v-tabs>
	</div>
</template>

<script>
import Inspecciones from './EnCurso/Inspecciones.vue';
import Mantenimientos from './EnCurso/Mantenimientos.vue';

export default {
	components: {Mantenimientos, Inspecciones},
	data: () => ({
		tab: 0,
	}),
	methods: {
		initialize() {
            this.mantenimientosClick();
			this.inspeccionesClick();
		},
		mantenimientosClick() {
			if(typeof this.$refs.mant === 'undefined') return;
			this.$refs.mant.initialize();
		},
		inspeccionesClick(){
			if(typeof this.$refs.ins === 'undefined') return;
			this.$refs.ins.initialize();
		},
	},
};
</script>