<template>
	<v-card class="flex d-flex flex-column" style="width: 18.75rem">
		<div
			class="text-center white--text py-2 rounded-t"
			:class="`${data.color} darken-1`"
		>
			<p
				class="text-h4 mb-1 font-weight-medium"
				style="font-size: 1.75rem !important"
			>
				{{ data.nombreMaquina }}
			</p>
			<p class="text-subtitle-1 mb-0">
				{{ data.tiempo }} - {{ getStatusText(data.status) }}
			</p>
		</div>
		<div
			class="d-flex justify-center align-center pa-0"
			:class="`${data.color} darken-3`"
			style="min-height: 13.4375rem"
		>
			<div class="pt-6 white--text text-center">
				<ICountUp
					:endVal="data.oee[0]"
					class="text-h4 font-weight-bold"
					:options="{ suffix: '%' }"
				/>
				<p
					class="mb-1 text-subtitle-1"
					style="font-size: 0.875rem !important"
				>
					OEE
				</p>
				<ICountUp
					:endVal="data.cantidad"
					class="text-h4 font-weight-bold"
				/>
				<p
					class="text-subtitle-1 text-uppercase"
					style="font-size: 0.875rem !important"
				>
					{{ data.unidad }}
				</p>
			</div>
			<div class="apex-custom-wrap">
				<apexchart
					type="radialBar"
					height="275"
					:options="data.chartOptions"
					:series="data.oee"
				></apexchart>
			</div>
		</div>
		<div class="flex white--text pa-0" :class="`${data.color} darken-1`">
			<v-container fluid>
				<v-row>
					<v-col
						v-for="(detalle, index) in data.detalles"
						:key="`detail-${index}`"
						cols="6"
						class="pt-1 pb-2"
					>
						<p class="mb-0 text-subtitle-1">
							{{ detalle.title }}
						</p>
						<p class="mb-0 text-subtitle-1 font-weight-bold">
							{{ detalle.text }}
						</p>
					</v-col>
				</v-row>
			</v-container>
		</div>
		<div
			class="d-flex pa-0 rounded-b"
			:class="`${data.color} darken-3`"
			style="height: 1.5625rem"
		>
			<v-col class="white--text d-flex justify-space-between py-0">
				<p class="mb-0 text-subtitle-1 font-weight-medium">
					{{ data.intervalo[0] }}
				</p>
				<p class="mb-0 text-subtitle-1 font-weight-medium">
					{{ data.intervalo[1] }}
				</p>
			</v-col>
		</div>
	</v-card>
</template>

<script>
import ICountUp from "vue-countup-v2";

export default {
	components: {
		ICountUp,
	},
	props: {
		data: {
			type: Object,
			required: true,
		},
	},
	data() {
		return {
			tiposStatus: [
				{
					id: 1,
					text: "Activo",
				},
				{
					id: 2,
					text: "Detenido",
				},
				{
					id: 3,
					text: "Inactivo",
				},
			],
		};
	},
	methods: {
		getStatusText(idStatus) {
			const result =
				this.tiposStatus.find(
					(tipoStatus) => tipoStatus.id == idStatus
				) || this.tiposStatus[2];
			return result.text;
		},
	},
};
</script>

<style scoped>
.apex-custom-wrap {
	position: absolute;
	top: 4.375rem;
}
.label-radial-chart {
	position: absolute;
	left: 7.5rem;
	top: 8.125rem;
}
</style>