<template>
  <v-container>
    <v-row v-if="internetStatus == 2">
      <v-col cols="text-body-1">
        <v-icon color="yellow">mdi-alert</v-icon>
        Se han detectado intermitencias en su conexión a Internet
      </v-col>
    </v-row>
    <v-row v-if="internetStatus == 3">
      <v-col cols="text-body-1">
        <v-icon color="red">mdi-alert</v-icon>
        Se han detectado problemas en su conexión a Internet
      </v-col>
    </v-row>
    <v-row v-if="estado == 0">
      <v-col cols="12" class="text-center">
        <div class="text-body-1">Reportar Error en ManagrX</div>
      </v-col>
      <v-col cols="12">
        <v-select
          v-model="editedItem.idAppError"
          label="Error"
          dense
          hide-details
          :items="tipoErrores"
          item-text="descripcion"
          item-value="idAppError"
        ></v-select>
      </v-col>
      <v-col cols="12">
        <v-textarea
          v-model="editedItem.observaciones"
          label="Observaciones"
          auto-grow
          rows="1"
          dense
          hide-details
        ></v-textarea>
      </v-col>
      <v-col cols="12">
        <v-btn small block color="primary" @click="save">Enviar Reporte</v-btn>
      </v-col>
    </v-row>
    <v-row v-if="estado == 1">
      <v-col cols="12" class="text-center">
        <v-progress-circular
          size="100"
          color="primary"
          indeterminate
        ></v-progress-circular>
        <br>
        <br>
        <div class="text-body-1">Cargando</div>
      </v-col>
    </v-row>
    <v-row v-if="estado == 2">
      <v-col cols="12" class="text-center">
        <v-icon color="green" size="80">mdi-check-circle-outline</v-icon>
        <br>
        <div class="text-body-1">Reporte Enviado Correctamente</div>
        <br>
        <v-btn small block color="primary" @click="reset">Aceptar</v-btn>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapState } from "vuex";
import axios from "axios"

export default {
  components: {
  },
  data: () => ({
    errores:[],
    tipoErrores:[],
    editedItem:{
      idAppError:-1,
      observaciones:null
    },
    defaultEditedItem:{
      idAppError:-1,
      observaciones:null
    },
    estado:0,
  }),
  computed:{
    ...mapState(["internetStatus"])
  },
  beforeMount(){
    this.getErrores();
  },
  methods:{
    getErrores(){
      axios
      .get("/Ayuda/ErroresApp")
      .then(response => {
        this.tipoErrores = response.data;
      })
      .catch(error => {
        console.log(error);
      })
    },
    validate(){
      if(this.editedItem.idAppError <= 0) this.errores.push("Debe de indicar un Error");
      return this.errores.length == 0;
    },
    save(){
      if(!this.validate()) return;

      let formData = new FormData();
      formData.append("idAppError", this.editedItem.idAppError);
      formData.append("observaciones", this.editedItem.observaciones);

      this.estado = 1;

      axios
      .post("/Ayuda/Reportar",formData)
      .then(() => {
        this.estado = 2;
      })
      .catch(error => {
        console.log(error);
      })
    },
    reset(){
      this.editedItem = Object.assign({},this.defaultEditedItem);
      this.estado = 0;
    }
  }
}
</script>