import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';
import { VToolbar } from 'vuetify/lib/components/VToolbar';
import { VTooltip } from 'vuetify/lib/components/VTooltip';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.permisoVista('retrabajo','r'))?_c(VCol,{attrs:{"cols":"12"}},[_c(VDialog,{key:_vm.eliminar,attrs:{"persistent":"","max-width":"500px"},model:{value:(_vm.eliminar),callback:function ($$v) {_vm.eliminar=$$v},expression:"eliminar"}},[_c(VCard,[_c(VCardTitle,{staticClass:"headline"},[_vm._v("¿Está seguro que desea eliminar este "),_c('br'),_vm._v("Proceso de Retrabajo?")]),_c(VCardText,[_vm._v("Esta acción no se puede revertir y será permanente.")]),_c(VCardActions,[_c(VSpacer),_c(VBtn,{attrs:{"color":"blue darken-1","text":""},on:{"click":function($event){_vm.eliminar = false;
						_vm.deletedItem = '';}}},[_vm._v("Cancelar")]),_c(VBtn,{attrs:{"color":"red darken-1","text":""},on:{"click":function($event){_vm.eliminar = false;
						_vm.deleteItemDB();}}},[_vm._v("Aceptar")])],1)],1)],1),_c(VDataTable,{attrs:{"headers":_vm.headers,"items":_vm.procesos,"loading":_vm.loading,"loading-text":"Cargando... Espere, Por Favor.","server-items-length":_vm.procesos_total,"items-per-page":_vm.procesos_items_per_page,"page":_vm.procesos_actual_page,"footer-props":{
			disableItemsPerPage: true,
			disablePagination: this.loading,
		},"disable-sort":true,"disable-pagination":_vm.loading},on:{"update:page":_vm.procesos_update_page_action,"update:items-per-page":function (ipp) { return (_vm.procesos_items_per_page = ipp); },"update:sort-by":_vm.procesos_sort_by_action},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c(VToolbar,{attrs:{"flat":"","color":"white"}},[(_vm.permisoVista('retrabajo','c'))?_c(VBtn,{staticClass:"mb-2",attrs:{"color":"primary","dark":""},on:{"click":function($event){return _vm.$refs.retrabajo.mostrarCrearRetrabajo()}}},[_vm._v("Nuevo Proceso")]):_vm._e(),_c(VSpacer),_c(VTextField,{attrs:{"append-icon":"search","label":"Buscar por código o artículo","single-line":"","hide-details":"","clearable":true,"disabled":_vm.loading},on:{"keyup":_vm.getProcesosOnEnterAction,"click:clear":_vm.clearSearchAction,"click:append":_vm.getProcesosAction},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1)]},proxy:true},{key:"item.action",fn:function(ref){
		var item = ref.item;
return [(_vm.permisoVista('retrabajo','u'))?_c(VTooltip,{staticClass:"white--text",attrs:{"bottom":"","color":"black"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
		var on = ref.on;
return [_c(VIcon,_vm._g({staticClass:"mr-2",attrs:{"small":""},on:{"click":function($event){return _vm.editItem(item)}}},on),[_vm._v(" edit ")])]}}],null,true)},[_c('span',{staticClass:"white--text"},[_vm._v("Editar")])]):_vm._e(),(_vm.permisoVista('retrabajo','d'))?_c(VTooltip,{staticClass:"white--text",attrs:{"bottom":"","color":"black"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
		var on = ref.on;
return [_c(VIcon,_vm._g({staticClass:"mr-2",attrs:{"small":""},on:{"click":function($event){return _vm.deleteItem(item)}}},on),[_vm._v(" delete ")])]}}],null,true)},[_c('span',{staticClass:"white--text"},[_vm._v("Borrar")])]):_vm._e()]}},{key:"no-data",fn:function(){return [_c(VBtn,{attrs:{"color":"primary"},on:{"click":_vm.initialize}},[_vm._v("Refrescar")])]},proxy:true}],null,false,222083933)}),_c('Retrabajo',{ref:"retrabajo",on:{"refresh":_vm.initialize}})],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }