<template>
  <v-dialog v-model="mostrar" persistent max-width="75%">
    <v-card>
      <v-card-title>
        {{paroItem.articuloProducir}} - {{paroItem.articuloFase}} - {{areaActual}}
      </v-card-title>
      <v-card-text>
        <v-row class="align-center pt-4">
          <!-- <v-col cols="12">
            <v-autocomplete
              v-model="idRemanufacturaMotivo"
              :items="motivosRefac"
              item-text="nombre"
              item-value="idRemanufacturaMotivo"
              label="Motivo de Remanufactura"
            ></v-autocomplete>
          </v-col> -->
          <v-col cols="12" class="d-flex align-center justify-content-center">
            Cantidad de Retrabajo:
            <div style="width:15px" />
				    <number-input
              v-model="cantidad"
				    	controls
				    	inline
				    	size="small"
				    	min="0"
              :max="cantidadMaxima"
				    	center
              @change="cambioValor"
				    ></number-input>
            <div style="width:10px" />
            {{unidad}}
          </v-col>
        </v-row>
        <div v-if="cantidad <= 0 || loading < 2 ">
					<br>
					<v-progress-linear
			  	color="primary"
			  	indeterminate
			  ></v-progress-linear>
				</div>
        <v-row v-if="loading >= 2 && this.procesos.length >= 1 && cantidad > 0" class="ma-0 pa-0">
          <v-col cols="12" md="12" sm="12" class="ma-0 pa-0">
            <v-checkbox
              v-model="nuevoProceso"
              label="Proceso de Retrabajo no Estandarizado"
            >
            </v-checkbox>
          </v-col>
          <v-col cols="12" md="12" sm="12" v-if="this.nuevoProceso == false" class="ma-0 pa-0">
            <v-autocomplete
              v-model="proceso"
              :item-text="nombreProceso"
              :items="procesos"
              item-value="idRetrabajoProceso"
              clearable
              @click:clear="resetProceso"
              label="Seleccione el Proceso de Retrabajo"
            >
            </v-autocomplete>
          </v-col>
        </v-row>
        <v-row v-if="loading >= 2 && cantidad > 0 && (this.procesos.length == 0 || this.nuevoProceso == true )"  class="ma-0 pa-0">
          <v-col v-for="(area, idx) in areasSeleccionables" :key="'a-'+idx" cols="3" class="my-0 py-0">
            <v-row class="ma-0 pa-0">
              <v-col cols="12" class="ma-0 pa-0">
                <v-checkbox
                  v-model="areas[idx].activo"
                  :label="area.area"
                  @change="cambioArea(areas[idx].activo,areas[idx].idArea)"
                ></v-checkbox>
              </v-col>
            </v-row>
            <v-row class="ma-0 pa-0 ">
              <v-col cols="12" class="ma-0 pa-0">
                <span style="font-size: 10px;">Tiempo Máximo (HH:MM:SS)</span>
                <time-picker
                  v-model="areas[idx].tiempo"
                  :disabled="!areas[idx].activo"
                  dense
                  :error="tiempoMinimo(areas[idx].tiempo)"
                ></time-picker>
              </v-col>
            </v-row>
          </v-col>
          <v-col cols="12">
            <br>
            <div class="text-h5 text-center">
              Elementos
            </div>
            <v-data-table
              :loading="loadingAdicionalInfo < 3"
              :headers="headers"
              :items="elementos"
              :items-per-page="-1"
              hide-default-footer
              disable-sort
            >
              <template v-slot:no-data>
                No se ha ingresado ningún elemento
              </template>
              <template v-slot:item.area="{ item }">
                <v-select
                  v-model="item.idArea"
                  :items="areasValidas"
                  item-value="idArea"
                  item-text="area"
                  dense
                  hide-details
                >
                </v-select>
              </template>
              <template v-slot:item.tipo="{ item }">
                <v-select
                  v-model="item.idTipo"
                  :items="tipos"
                  dense
                  hide-details
                >
                </v-select>
              </template>
              <template v-slot:item.info="{ item }">
                <template v-if="item.idTipo == 1">
                  <v-row class="ma-0 pa-0">
                    <v-col cols="4" class="ma-0 pa-2">
                      <v-row class="ma-0 pa-0">
                        <v-col cols="10" class="ma-0 pa-0">
                          <v-text-field-number
                            v-model="item.cantidad"
                            dense
                            hide-details
                            decimal
                            :label="'Cantidad'"
                            :min="0"
                          >
                          </v-text-field-number>
                        </v-col>
                        <v-col cols="2" class="ma-0 pa-0" style="align-self: center;">
                          {{ (item.articulo.numDimensiones != null && item.articulo.numDimensiones >= 2)? "Pza" : item.articulo.unidad }}                
                        </v-col>
                      </v-row>
                    </v-col>
                    <v-col cols="8" class="ma-0 pa-2">
                      <v-row class="ma-0 pa-0">
                        <v-col :cols="colsNumDimensiones(item.articulo.numDimensiones)[0]" class="ma-0 py-0 px-1">
                          <v-autocomplete
                            v-model="item.idArticulo"
                            label="Artículo"
                            :items="articulos"
                            :item-text="nombreArticulo"
                            item-value="idArticulo"
                            dense
                            hide-details
                            @change="cambioArticulo(item, item.idArticulo)"
                          ></v-autocomplete>
                        </v-col>
                        <v-col
                          v-if="colsNumDimensiones(item.articulo.numDimensiones)[1] > 0" 
                          :cols="colsNumDimensiones(item.articulo.numDimensiones)[1]"
                          class="ma-0 py-0 px-1"
                        >
                          <v-text-field-number
                            v-model="item.ejeX"
                            :label="'Largo ('+item.articulo.unidadBase+')'"
                            dense
                            hide-details
                            decimal
                            :min="0"
                          ></v-text-field-number>
                        </v-col>
                        <v-col
                          v-if="colsNumDimensiones(item.articulo.numDimensiones)[2] > 0" 
                          :cols="colsNumDimensiones(item.articulo.numDimensiones)[2]"
                          class="ma-0 py-0 px-1"
                        >
                          <v-text-field-number
                            v-model="item.ejeY"
                            :label="'Ancho ('+item.articulo.unidadBase+')'"
                            dense
                            hide-details
                            decimal
                            :min="0"
                          ></v-text-field-number>
                        </v-col>
                        <v-col
                          v-if="colsNumDimensiones(item.articulo.numDimensiones)[3] > 0" 
                          :cols="colsNumDimensiones(item.articulo.numDimensiones)[3]"
                          class="ma-0 py-0 px-1"
                        >
                          <v-text-field-number
                            v-model="item.ejeZ"
                            :label="'Alto ('+item.articulo.unidadBase+')'"
                            dense
                            hide-details
                            decimal
                            :min="0"
                          ></v-text-field-number>
                        </v-col>
                      </v-row>
                    </v-col>
                  </v-row>
                </template>
                <template v-if="item.idTipo == 2">
                  <v-row class="ma-0 pa-0">
                    <v-col cols="4" class="ma-0 pa-2">
                      <v-text-field-number
                        v-model="item.cantidad"
                        dense
                        hide-details
                        decimal
                        label="Cantidad"
                        :min="0"
                      >
                      </v-text-field-number>
                    </v-col>
                    <v-col cols="4" class="ma-0 pa-2">
                      <v-autocomplete
                        v-model="item.idPuesto"
                        label="Puesto"
                        :items="puestos"
                        item-text="nombre"
                        item-value="idPuesto"
                        dense
                        hide-details
                      ></v-autocomplete>
                    </v-col>
                    <v-col cols="4" class="ma-0 py-0 px-2 text-left">
                      <span style="font-size: 10px;">Tiempo(HH:MM:SS)</span>
                      <time-picker
                        v-model="item.tiempo"
                        dense
                        :error="tiempoMinimoIng(item.tiempo,item.idArea)"
                      ></time-picker>
                    </v-col>
                  </v-row>
                </template>
                <template v-if="item.idTipo == 3">
                  <v-row class="ma-0 pa-0">
                    <v-col cols="4" class="ma-0 pa-2">
                      <v-text-field-number
                        v-model="item.cantidad"
                        dense
                        hide-details
                        decimal
                        label="Cantidad"
                        :min="0"
                      >
                      </v-text-field-number>
                    </v-col>
                    <v-col cols="4" class="ma-0 pa-2">
                      <v-autocomplete
                        v-model="item.idMaquina"
                        :items="maquinas"
                        :item-text="nombreMaquina"
                        item-value="idMaquina"
                        label="Máquina"
                        dense
                        hide-details
                      ></v-autocomplete>
                    </v-col>
                    <v-col cols="4" class="ma-0 py-0 px-2 text-left">
                      <span style="font-size: 10px;">Tiempo(HH:MM:SS)</span>
                      <time-picker
                        v-model="item.tiempo"
                        dense
                        :error="tiempoMinimoIng(item.tiempo,item.idArea)"
                      ></time-picker>
                    </v-col>
                  </v-row>
                </template>
                <template v-if="item.idTipo == 4">
                  <v-row class="ma-0 pa-0">
                    <v-col cols="4" class="ma-0 pa-2">
                      <v-text-field-number
                        v-model="item.cantidad"
                        dense
                        hide-details
                        decimal
                        label="Cantidad"
                        :min="0"
                      >
                      </v-text-field-number>
                    </v-col>
                    <v-col cols="4" class="ma-0 pa-2">
                      <v-select
                        v-model="item.idHerramienta"
                        :items="herramientas"
                        :item-text="nombreMaquina"
                        item-value="idMaquina"
                        label="Herramienta"
                        dense
                        hide-details
                      ></v-select>
                    </v-col>
                    <v-col cols="4" class="ma-0 py-0 px-2 text-left">
                      <span style="font-size: 10px;">Tiempo(HH:MM:SS)</span>
                      <time-picker
                        v-model="item.tiempo"
                        dense
                        :error="tiempoMinimoIng(item.tiempo,item.idArea)"
                      ></time-picker>
                    </v-col>
                  </v-row>
                </template>
              </template>
              <template v-slot:item.actions="{ item }">
                <v-tooltip bottom color="black" class="white--text">
                  <template v-slot:activator="{ on }">
                    <v-icon v-on="on"
                      small
                      class="mr-2"
                      @click="borrarElemento(item)"
                    >
                      mdi-delete
                    </v-icon>
                  </template>
                  <span class="white--text">Eliminar</span>
                </v-tooltip>
              </template>
            </v-data-table>
            <v-btn
              :disabled="loadingAdicionalInfo < 3"
              color="primary"
              text
              @click="agregarElemento"
            >
              Agregar Elemento
            </v-btn>
          </v-col>
          <v-col cols="12" class="ma-0 pa-0">
            <v-checkbox
              v-model="estandarizar"
              label="¿Desea Estandarizar este Proceso de Remanufactura?"
            ></v-checkbox>
          </v-col>
          <v-col cols="6" class="ma-0 pa-0" v-if="estandarizar">
            <v-text-field
              v-model="codigo"
              label="Código del Proceso de Remanufactura"
            ></v-text-field>
          </v-col>
          <v-col cols="6" class="ma-0 py-0"  v-if="estandarizar">
            <v-text-field
              v-model="nombre"
              label="Nombre del Proceso de Remanufactura"
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row class="ma-0 mt-3 pa-0">
          <v-col cols="12" class="ma-0 pa-0">
            <ul>
              <li v-for="(error,idx) in errores" :key="idx">{{error}}</li>
            </ul>
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          color="green"
          text
          @click="cerrar"
        >
          Cancelar
        </v-btn>
        <v-btn
          color="red darken-1"
          text
          @click="save()"
          :disabled="saving"
          :loading="saving"
        >
          Guardar
          <template v-slot:loader>
            <v-progress-circular
              indeterminate
              :width="2"
              :size="24"
              color="red"
            ></v-progress-circular>
          </template>
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import axios from "axios";
import timepicker from '@/components/TimePicker';
import vtextfieldnumber from '@/components/VTextFieldNumber.vue'

export default {
  components:{
    'time-picker':timepicker,
    'v-text-field-number':vtextfieldnumber
  },
  props:{
    areas:{
      type: Array,
      default: () => {[]}
    }
  },
  watch:{
    nuevoProceso(){
      if(this.nuevoProceso == true && this.loadedAdicionalInfo == false){
        //this.loadAdicionalInfo();
        //this.loadedAdicionalInfo = true;
      }
    }
  },
  computed:{
    areaActual(){
      var idx = this.areas.findIndex(a => a.idArea == this.paroItem.idArea);
      if(idx >= 0) return this.areas[idx].nombre;
      else return "";
    }
  },
  data:() =>({
    mostrar: false,
    paroItem:{
      articuloFase: "",
      articuloProducir: "",
      idArea: 0,
      idParo: 0,
    },
    defaultParoItem:{
      articuloFase: "",
      articuloProducir: "",
      idArea: 0,
      idParo: 0,
    },
    cantidadMaxima: 0,
    seleccionados: [],
		areasSeleccionables: [],
    cantidad: 0,
    procesos:[],
    proceso: null,
    loading: 0,
    nuevoProceso: false,
    areas:[],
    tipos:[
      {
        text:"Materia Prima",
        value: 1,
      },
      {
        text:"Trabajador",
        value: 2,
      },
      {
        text:"Máquina",
        value: 3,
      },
      {
        text:"Herramienta",
        value: 4,
      }
    ],
    headers: [
      {
        text: "Área",
        align: "center",
        sortable: false,
        value: "area",
        width: "15%",
      },
      {
        text: "Tipo",
        align: "center",
        sortable: false,
        value: "tipo",
        width: "15%",
      },
      {
        text: "Información Adicional",
        align: "center",
        sortable: false,
        value: "info",
        width: "70%",
      },
      { text: 'Acciones', value: 'actions', sortable: false, width:'15%', align:'center' },
    ],
    elementos:[
    ],
    defaultElemento:{
      idArea: -1,
      idTipo: -1,
      idArticulo: -1,
      idPuesto: -1,
      idMaquina: -1,
      idHerramienta: -1,
      ejeX: 0,
      ejeY: 0,
      ejeZ: 0,
      tiempo: 0,
      articulo : {
        numDimensiones: null,
        unidad: "",
        unidadAuxiliar: "",
        unidadBase: ""
      }
    },
    defaultArticulo : {
        numDimensiones: null,
        unidad: "",
        unidadAuxiliar: "",
        unidadBase: ""
    },
    loadingAdicionalInfo: 0,
    loadedAdicionalInfo: false,
    maquinas:[],
    herramientas:[],
    puestos:[],
    articulos:[],
    estandarizar: false,
    nombre: "",
    codigo: "",
    errores:[],
    saving: false,
    motivosRefac: [],
    idRemanufacturaMotivo: null
  }),
  computed:{
    areasValidas(){
      return this.areas.filter(a => a.activo == true);
    }
  },
  methods:{
    nombreProceso: proceso => "[" + proceso.codigo + "] - " + proceso.nombre,
    nombreMaquina: maquina =>  maquina.marca +" - "+ maquina.modelo ,
    nombreArticulo: articulo => "[" + articulo.codigo + "] -"+articulo.nombre,
    getRemanufacturaMotivos(){
			axios
				.get( "/RemanufacturaMotivos" )
				.then( response => {
					this.motivosRefac = response.data;
				})
				.catch( error => {
					console.log( error );
				})
		},
    mostrarRetrabajo(item){
      // this.getRemanufacturaMotivos();
      this.paroItem = item;
      this.cantidadMaxima = item.cantidadReportada - (item.cantidadRetrabajo || 0) - (item.cantidadScrap || 0);
      this.mostrar = true;
    },
    cerrar(){
      this.mostrar = false;
      this.paroItem = Object.assign({},this.defaultParoItem);
      this.cantidadMaxima = 0;
      this.areasSeleccionables = [];
      this.seleccionados = [];
      this.cantidad = 0;
      this.procesos = [];
      this.proceso = null;
      this.nuevoProceso = false;
      this.areas = [];
      this.elementos = [];
      this.estandarizar = false;
      this.nombre = "";
      this.codigo = "";
      this.saving = false;
      this.idRemanufacturaMotivo = null;
    },
    resetProceso(){
      this.proceso = null;
    },
    cambioValor(){
      if(this.paroItem.idParo <= 0) return;

      this.loading = 0;
      axios
      .get("/Retrabajo/ProcesosRetrabajo/"+this.paroItem.idParo)
      .then(response => {
        this.procesos = response.data;
        if(this.procesos.length == 0){
          //this.loadAdicionalInfo();
          //this.loadedAdicionalInfo = true;
        }
        this.loading += 1;
      })
      .catch(error => {
        this.loading += 1;
        console.log(error);
      });

      axios
      .get("/Paros/AreasRetrabajo/"+this.paroItem.idParo)
      .then(response => {
        this.areasSeleccionables = response.data;
        this.areas = response.data.map(a => {
          return{
            idArea: a.idArea,
            area: a.area,
            activo: false,
            tiempo: 0,
          }
        })
        this.loading += 1;
      })
      .catch(error => {
        this.loading += 1;
        console.log(error);
      })
    },
    borrarElemento(item){
      this.elementos = this.elementos.filter( 
        elemento => JSON.stringify( elemento ) != JSON.stringify( item ) 
      );
    },
    agregarElemento(){
      this.elementos.push(Object.assign({}, this.defaultElemento));
    },
    loadAdicionalInfo(){
      this.getMaquinas();
      this.getPuestos();
      this.getArticulos();
    },
    getMaquinas () {
      axios
      .get("/Maquinas")
      .then(response =>  {
        this.maquinas = response.data.filter(function(el) {
          return (el.esMaquina == true);
        });
        this.herramientas = response.data.filter(function(el) {
          return (el.esMaquina == false);
        });
        this.loadingAdicionalInfo += 1;
      })
      .catch(error => {
        console.log(error);
      });
    },
    getPuestos(){
      axios
      .get("/Puestos")
      .then(response =>  {
        this.puestos = response.data.filter(p => p.produccion == true);
        this.loadingAdicionalInfo += 1;
       })
      .catch(error => {
        console.log(error);
      });
    },
    getArticulos(){
      axios
      .get("/Articulos/ArticulosProduccion?precio=true")
      .then(response =>  {
        this.articulos = response.data;
        this.loadingAdicionalInfo += 1;
       })
      .catch(error => {
        console.log(error);
      });
    },
    cambioArticulo(item, idArticulo){
      var art = this.articulos.find(a => a.idArticulo == idArticulo);
      if(!this.$utils.isValid(art)) item.articulo = Object.assign({}, this.defaultArticulo);
      else item.articulo = art;
    },
    colsNumDimensiones(numDimensiones){
      if(numDimensiones == null || numDimensiones == 1) return [12,0,0,0];
      else if (numDimensiones == 2) return [8,2,2,0];
      else return [6,2,2,2] 
    },
    tiempoMinimo(value){
      if(value > 0) return false;
      else return true;
    },
    tiempoMinimoIng(value, idArea){
      if(typeof value === 'undefined' || value == null) return true;
      if(value <= 0) return true;
      var idx = this.areas.findIndex(a => a.idArea == idArea);
      if(idx >= 0) return (value > this.areas[idx].tiempo);
      else return true;
    },
    cambioArea(value,idArea){
      if(!value){
        this.elementos.forEach(e => {
          if(e.idArea == idArea) e.idArea = -1; 
        })
      }
    },
    validate(){
      this.errores = [];
      if(this.cantidad <= 0) this.errores.push("La Cantidad debe de ser mayor a 0");

      // if(this.idRemanufacturaMotivo == 0) 
      //   this.errores.push("Debe seleccionar un motivo de Remanufactura");

      if(this.nuevoProceso || this.procesos.length <= 0){
        var numAreas = this.areas.filter(a => a.activo == true).length;
        if(numAreas <= 0) this.errores.push("No hay áreas activas.");

        var numAreasTiempo = this.areas.filter(a => a.activo == true && a.tiempo > 0).length;
        if(numAreas != numAreasTiempo) this.errores.push("Hay áreas activas que tienen un tiempo de 00:00:00 (HH:MM:SS).");

        if(this.elementos.length <= 0) this.errores.push("No se ha indicado elementos.");

        this.elementos.forEach((e, idx) => {
          if(e.idArea <= 0) this.errores.push("Falta indicar un área en el elemento "+ (idx + 1) + ".");
          if(e.idTipo == 1){
            if(e.cantidad <= 0) this.errores.push("La cantidad debe de ser mayor que cero en el elemento "+ (idx + 1) + ".");
            if(e.idArticulo <= 0) this.errores.push("Falta indicar el Artículo en el elemento "+ (idx + 1) + ".");

            if(e.articulo.numDimensiones != null && e.articulo.numDimensiones > 1){
              if(e.ejeX <= 0 && e.articulo.numDimensiones >= 2) this.errores.push("La cantidad en el Largo debe de ser mayor que cero en el elemento "+ (idx + 1) + ".");
              if(e.ejeY <= 0 && e.articulo.numDimensiones >= 2) this.errores.push("La cantidad en el Ancho debe de ser mayor que cero en el elemento "+ (idx + 1) + ".");
              if(e.ejeZ <= 0 && e.articulo.numDimensiones == 3) this.errores.push("La cantidad en el Alto debe de ser mayor que cero en el elemento "+ (idx + 1) + ".");
            }

          }
          else if(e.idTipo >= 2 && e.idTipo <= 4){
            if(e.cantidad <= 0) this.errores.push("La cantidad debe de ser mayor que cero en el elemento "+ (idx + 1) + ".");
            if(e.idTipo == 2 && e.idPuesto <= 0) this.errores.push("Falta indicar el Puesto en el elemento "+ (idx + 1) + ".");
            if(e.idTipo == 3 && e.idMaquina <= 0) this.errores.push("Falta indicar la Máquina en el elemento "+ (idx + 1) + ".");
            if(e.idTipo == 4 && e.idHerramienta <= 0) this.errores.push("Falta indicar la Herramienta en el elemento "+ (idx + 1) + "."); 
            
            if(e.tiempo <= 0) this.errores.push("El tiempo tiene que ser mayor que 00:00:00 (HH:MM:SS) en el elemento "+ (idx + 1) + ".");
            else{
              var area = this.areas.find(a => a.idArea == e.idArea);
              if(e.tiempo > area.tiempo) this.errores.push("El tiempo del elemento "+ (idx + 1) + " es mayor que el del Área de "+area.area+".");
            }
          }
          else this.errores.push("Falta indicar el tipo de recurso en el elemento "+ (idx + 1) + ".");
        });

        if(this.estandarizar){
          if(this.codigo.length < 4) this.errores.push("El Código del Proceso debe de tener al menos 4 caracteres");
          if(this.nombre.length < 6) this.errores.push("El Nombre del Proceso debe de tener al menos 6 caracteres");

          if(this.codigo.length >= 50) this.errores.push("El Código del Proceso debe de tener menos de 50 caracteres");
          if(this.nombre.length >= 100) this.errores.push("El Nombre del Proceso debe de tener menos de 100 caracteres");
        }
      }
      else{
        if(this.proceso == null || this.proceso <= 0) this.errores.push("Debe de seleccionar un Proceso de Retrabajo");
      }

      return this.errores.length == 0;
    },
    save(){
      if(!this.validate()) return;

      this.saving = true;
      
      var areas = this.areas.filter(a => a.activo == true).map(a => {
        return {
          IdArea: a.idArea,
          Tiempo: a.tiempo
        }
      });

      var ingredientes = this.elementos.map(e => {
        return {
          IdArea: e.idArea,
          IdArticulo: (e.idTipo == 1) ? e.idArticulo : null,
          IdMaquina: (e.idTipo == 3 || e.idTipo == 4) ? ( (e.idTipo == 3) ? e.idMaquina : e.idHerramienta) : null,
          IdPuesto: (e.idTipo == 2 ) ? e.idPuesto : null,
          IdDesconocidoIns: null,
          Cantidad: e.cantidad,
          Tiempo: (e.idTipo >= 2 && e.idTipo <= 4) ? e.tiempo : null,
          Remplazar: false,
          Proporcional: (e.idTipo == 1 && e.articulo.numDimensiones >= 2),
          Fraccion: (e.idTipo == 1 && e.articulo.numDimensiones >= 2) ? (e.articulo.numDimensiones ? (e.ejeX * e.ejeY):(e.ejeX * e.ejeY * e.ejeZ)): null,
          EjeX: (e.idTipo == 1 && e.articulo.numDimensiones >= 2) ? e.ejeX : null,
          EjeY: (e.idTipo == 1 && e.articulo.numDimensiones >= 2) ? e.ejeY : null,
          EjeZ: (e.idTipo == 1 && e.articulo.numDimensiones == 3) ? e.ejeZ : null,
        }
      });

      var obj = {
        IdParo: this.paroItem.idParo,
        IdRetrabajoProceso: (!(this.nuevoProceso || this.procesos.length <= 0))?this.proceso : null,
        Cantidad: this.cantidad,
        Guardar: (this.nuevoProceso || this.procesos.length <= 0) && this.estandarizar,
        Nombre: ((this.nuevoProceso || this.procesos.length <= 0) && this.estandarizar) ? this.nombre : null,
        Codigo: ((this.nuevoProceso || this.procesos.length <= 0) && this.estandarizar) ? this.codigo : null,
        Areas: (this.nuevoProceso || this.procesos.length <= 0) ? areas : null,
        Ingredientes: (this.nuevoProceso || this.procesos.length <= 0) ? ingredientes : null,
        idRemanufacturaMotivo: this.idRemanufacturaMotivo
      };

      axios
      .post("/Retrabajo/IniciarRetrabajo",obj)
      .then(() => {
        this.saving = false;
        this.cerrar();
				this.$emit('reload',null);
      })
      .catch(error => {
        this.saving = false;
        console.log(error);
      });
    }
  },
  created(){
    this.loadAdicionalInfo();
    this.loadedAdicionalInfo = true;
  }
}
</script>