import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';
import { VToolbar } from 'vuetify/lib/components/VToolbar';
import { VToolbarTitle } from 'vuetify/lib/components/VToolbar';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c(VRow,[_c(VCol,{staticClass:"pt-0",attrs:{"cols":"6"}},[_c('datePicker',{attrs:{"format":"YYYY-MM-DD","maxDate":_vm.fin,"label":"Desde (Obligatorio)","clearable":"","disabled":_vm.loading},on:{"input":function($event){return _vm.getAreas(_vm.IdOrdenTrabajo)}},model:{value:(_vm.filtros.desde),callback:function ($$v) {_vm.$set(_vm.filtros, "desde", $$v)},expression:"filtros.desde"}})],1),_c(VCol,{staticClass:"pt-0",attrs:{"cols":"6"}},[_c('datePicker',{attrs:{"minDate":_vm.filtros.desde,"format":"YYYY-MM-DD","label":"Hasta (Obligatorio)","clearable":"","disabled":_vm.loading},on:{"input":function($event){return _vm.getAreas(_vm.IdOrdenTrabajo)}},model:{value:(_vm.filtros.hasta),callback:function ($$v) {_vm.$set(_vm.filtros, "hasta", $$v)},expression:"filtros.hasta"}})],1),_c(VCol,{attrs:{"cols":"12"}},[_c('div',[(_vm.loading)?_c(VDataTable,{staticClass:"elevation-1",attrs:{"item-key":"name","headers":_vm.headers,"loading":_vm.loading,"loading-text":"Cargando... Espere por favor"}}):(_vm.desserts.length < 1)?_c(VDataTable,{staticClass:"elevation-1",attrs:{"item-key":"name","headers":_vm.headers}}):_vm._l((_vm.desserts),function(item,key){return _c(VDataTable,{key:key,staticClass:"elevation-1",attrs:{"headers":_vm.headers,"items":item.data,"items-per-page":5},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c(VToolbar,{attrs:{"flat":""}},[_c(VToolbarTitle,[_vm._v(_vm._s(item.semana))])],1)]},proxy:true},{key:"item.numPedido",fn:function(ref){
var item = ref.item;
return [(_vm.nombreEmpresa == 'MYC')?[(item.editNumPedido)?_c(VTextField,{staticStyle:{"text-align":"center"},attrs:{"append-outer-icon":"mdi-close","append-outer-icon-append":"Cancelar","append-icon":"mdi-content-save","append-icon-append":"Guardar","error":item.errorEstatusNumPedido,"error-messages":item.errorEstatusTextoNumPedido,"loading":_vm.animationNumPedido,"disabled":_vm.animationNumPedido},on:{"click:append-outer":function($event){return _vm.cancelNoPedido(item, key)},"click:append":function($event){return _vm.guardarNoPedido(item, key)}},model:{value:(item.numPedido),callback:function ($$v) {_vm.$set(item, "numPedido", $$v)},expression:"item.numPedido"}}):_c('p',{staticClass:"text-center"},[_vm._v(" "+_vm._s(item.numPedido == null ? "N/A" : item.numPedido)+" "),_c(VBtn,{attrs:{"color":"primary","icon":"","x-small":""},on:{"click":function($event){return _vm.editarNoPedido(item, key)}}},[_c(VIcon,[_vm._v("mdi-pencil")])],1)],1)]:[_vm._v(" "+_vm._s(item.numPedido == null ? "N/A" : item.numPedido)+" ")]]}},{key:"item.mycPedido",fn:function(ref){
var item = ref.item;
return [(_vm.nombreEmpresa == 'MYC')?[(item.editMycPedido)?_c(VTextField,{staticStyle:{"text-align":"center"},attrs:{"append-outer-icon":"mdi-close","append-outer-icon-append":"Cancelar","append-icon":"mdi-content-save","append-icon-append":"Guardar","error":item.errorEstatusMycPedido,"error-messages":item.errorEstatusTextoMycPedido,"loading":_vm.animationMycPedido,"disabled":_vm.animationMycPedido},on:{"click:append-outer":function($event){return _vm.cancelMycPedido(item, key)},"click:append":function($event){return _vm.guardarMycPedido(item, key)}},model:{value:(item.mycPedido),callback:function ($$v) {_vm.$set(item, "mycPedido", $$v)},expression:"item.mycPedido"}}):_c('p',{staticClass:"text-center"},[_vm._v(" "+_vm._s(item.mycPedido == null ? "N/A" : item.mycPedido)+" "),_c(VBtn,{attrs:{"color":"primary","icon":"","x-small":""},on:{"click":function($event){return _vm.editarMycPedido(item, key)}}},[_c(VIcon,[_vm._v("mdi-pencil")])],1)],1)]:[_vm._v(" "+_vm._s(item.mycPedido == null ? "N/A" : item.mycPedido)+" ")]]}},{key:"item.acumRemision",fn:function(ref){
var item = ref.item;
return [(_vm.nombreEmpresa == 'MYC')?[(item.EditAcumRemision)?_c(VTextField,{staticStyle:{"text-align":"center"},attrs:{"append-outer-icon":"mdi-close","append-outer-icon-append":"Cancelar","append-icon":"mdi-content-save","append-icon-append":"Guardar","error":item.errorEstatusAcumRemision,"error-messages":item.errorEstatusTextoAcumRemision,"loading":_vm.animationAcumRemision,"disabled":_vm.animationAcumRemision},on:{"click:append-outer":function($event){return _vm.cancelAcumRemision(item, key)},"click:append":function($event){return _vm.guardarAcumRemision(item, key)}},model:{value:(item.acumRemision),callback:function ($$v) {_vm.$set(item, "acumRemision", $$v)},expression:"item.acumRemision"}}):_c('p',{staticClass:"text-center"},[_vm._v(" "+_vm._s(item.acumRemision == null ? "N/A" : item.acumRemision)+" "),_c(VBtn,{attrs:{"color":"primary","icon":"","x-small":""},on:{"click":function($event){return _vm.editarAcumRemision(item, key)}}},[_c(VIcon,[_vm._v("mdi-pencil")])],1)],1)]:[_vm._v(" "+_vm._s(item.acumRemision == null ? "N/A" : item.acumRemision)+" ")]]}},{key:"item.observaciones",fn:function(ref){
var item = ref.item;
return [(_vm.nombreEmpresa == 'MYC')?[(item.editObservacion)?_c(VTextField,{staticStyle:{"text-align":"center"},attrs:{"append-outer-icon":"mdi-close","append-outer-icon-append":"Cancelar","append-icon":"mdi-content-save","append-icon-append":"Guardar","error":item.errorEstatusObervacion,"error-messages":item.errorEstatusTextoObervacion,"loading":_vm.animationObservacion,"disabled":_vm.animationObservacion},on:{"click:append-outer":function($event){return _vm.cancelObservacion(item, key)},"click:append":function($event){return _vm.guardarObservacion(item, key)}},model:{value:(item.observaciones),callback:function ($$v) {_vm.$set(item, "observaciones", $$v)},expression:"item.observaciones"}}):_c('p',{staticClass:"text-center"},[_vm._v(" "+_vm._s(item.observaciones == null ? "N/A" : item.observaciones)+" "),_c(VBtn,{attrs:{"color":"primary","icon":"","x-small":""},on:{"click":function($event){return _vm.editarObservacion(item, key)}}},[_c(VIcon,[_vm._v("mdi-pencil")])],1)],1)]:[_vm._v(" "+_vm._s(item.observaciones == null ? "N/A" : item.observaciones)+" ")]]}}],null,true)})})],2)]),_c(VCol,{attrs:{"cols":"12"}},[_c('DownloadBtn',{attrs:{"text":"","color":"primary","className":"my-0","label":"Descargar Reporte MP<br/>Semanal","disabled":_vm.filtros.desde == null || _vm.filtros.hasta == null ? true : false,"retryTime":20},on:{"click":_vm.report}})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }