<template>
 <v-row v-if="permisoVista('montos','r')">
    <v-col cols="3" md="3" sm="3">
      <v-autocomplete
        v-model="idsClientes"
        label="Cliente(s)"
        :items="clientes"
        item-value="idCliente"
        item-text="nombre"
        hide-details
        multiple
        clearable
        chips
        deletable-chips
        @change="getEstadisticas"
        :loading="loadingCliente"
      ></v-autocomplete>
    </v-col>
    <v-col cols="3" md="3" sm="3">
      <v-autocomplete
        v-model="idsVendedores"
        label="Vendedor(es)"
        :items="vendedores"
        item-value="idVendedor"
        :item-text="nombreVendedor"
        hide-details
        multiple
        clearable
        chips
        deletable-chips
        @change="getEstadisticas"
        :loading="loadingCliente"
      ></v-autocomplete>
    </v-col>
    <v-col cols="3" md="3" sm="3" class="align-self-end">
      <datePicker
        v-model="inicio"
        format="YYYY-MM-DD"
        :maxDate="fin"
        label="Desde"
        clearable
        maxToday
        @input="getEstadisticas"
      ></datePicker>
    </v-col>
    <v-col cols="3" md="3" sm="3" class="align-self-end">
      <datePicker
        v-model="fin"
        format="YYYY-MM-DD"
        :minDate="inicio"
        label="Hasta"
        clearable
        maxToday
        @input="getEstadisticas"
      ></datePicker>
    </v-col>
    <v-col cols="12" md="12" sm="12">
      <v-progress-linear
        v-if="loading"
        indeterminate
        color="primary"
      ></v-progress-linear>
      <apexchart ref="chart" type="bar" height="800" :options="chartOptions" :series="series"></apexchart>
    </v-col>
  </v-row>
</template>

<script>
import axios from "axios";
import datePicker from "@/components/DatePicker";
import { VueDatePicker } from '@mathieustan/vue-datepicker';
import { mapState } from "vuex";

export default {
  components:{
    'datePicker':datePicker
  },
  data: () => ({
    series:[],  
    chartOptions: {
      chart: {
        type: 'bar',
        height: 800,
        stacked: true,
        locales: [{
          name: "es",
          options: {
            months: ["Enero","Febrero","Marzo","Abril","Mayo","Junio","Julio","Agosto","Septiembre","Octubre","Noviembre","Diciembre"],
            shortMonths: ["Ene","Feb","Mar","Abr","May","Jun","Jul","Ago","Sep","Oct","Nov","Dic"],
            days: ["Domingo","Lunes","Martes","Miércoles","Jueves","Viernes","Sábado"],
            shortDays: ["Dom","Lun","Mar","Mie","Jue","Vie","Sab"],
            toolbar: {
              exportToSVG: "Descargar SVG",
              exportToPNG: "Descargar PNG",
              exportToCSV: "Descargar CSV",
              menu: "Menu",
              selection: "Seleccionar",
              selectionZoom: "Seleccionar Zoom",
              zoomIn: "Aumentar",
              zoomOut: "Disminuir",
              pan: "Navegación",
              reset: "Reiniciar Zoom"
            }
          }
        }],
        defaultLocale: "es"
      },
      plotOptions: {
        bar: {
          horizontal: true,
        },
      },
      colors: [],
      stroke: {
        width: 1,
        colors: ['#fff']
      },
      xaxis: {
        categories: [],
        labels: {
         
        }
      },
      yaxis: {
        title: {
          text: undefined
        },
      },
      tooltip: {
        y: {
          
        }
      },
      fill: {
        opacity: 1
      },
      legend: {
        position: 'top',
        horizontalAlign: 'left',
        offsetX: 40
      },
      dataLabels: {
        enabled: true,
        style: {
          colors: ['#fff', '#fff']
        }
      },
    },
    defaultChartOptions: {
      chart: {
        type: 'bar',
        height: 800,
        stacked: true,
        locales: [{
          name: "es",
          options: {
            months: ["Enero","Febrero","Marzo","Abril","Mayo","Junio","Julio","Agosto","Septiembre","Octubre","Noviembre","Diciembre"],
            shortMonths: ["Ene","Feb","Mar","Abr","May","Jun","Jul","Ago","Sep","Oct","Nov","Dic"],
            days: ["Domingo","Lunes","Martes","Miércoles","Jueves","Viernes","Sábado"],
            shortDays: ["Dom","Lun","Mar","Mie","Jue","Vie","Sab"],
            toolbar: {
              exportToSVG: "Descargar SVG",
              exportToPNG: "Descargar PNG",
              exportToCSV: "Descargar CSV",
              menu: "Menu",
              selection: "Seleccionar",
              selectionZoom: "Seleccionar Zoom",
              zoomIn: "Aumentar",
              zoomOut: "Disminuir",
              pan: "Navegación",
              reset: "Reiniciar Zoom"
            }
          }
        }],
        defaultLocale: "es"
      },
      plotOptions: {
        bar: {
          horizontal: true,
        },
      },
      colors: [function({value,seriesIndex, dataPointIndex, w}){
         var colores = ["#ffc93c","#07689f","#40a8c4", "#61b15a", "#adce74","#db6400","#ffa62b", "#ffd369", "#e05297", "#16a596"];
        // const generarColor = () => "#000000".replace(/0/g, () => (~~(Math.random() * 16)).toString(16))
        return colores[dataPointIndex%colores.length];
      }],
      stroke: {
        width: 1,
        colors: ['#fff']
      },
      xaxis: {
        categories: [],
        labels: {
         
        }
      },
      yaxis: {
        title: {
          text: undefined
        },
      },
      tooltip: {
        y: {
          
        }
      },
      fill: {
        opacity: 1
      },
      legend: {
        position: 'top',
        horizontalAlign: 'left',
        offsetX: 40
      },
      dataLabels: {
        enabled: true,
        style: {
          colors: ['#fff', '#fff']
        }
      },
    },
    loadingCliente: false,
    inicio:null,
    fin:null,
    loading:false,
    clientes:[],
    idsClientes:[],
    vendedores:[],
    idsVendedores:[],
    loadingVendedor: false,
  }),
  mounted(){
    this.initialize();  
  },
  computed:{
    fetchUrl(){
      var url = "/Ventas";
      var hayparametros =false;       

        //if(this.inicio!=null || this.fin!=null){
      //    url = "/Ventas?";
          if(this.inicio != null)
          { 
            if(hayparametros==false){
              hayparametros=true;
              url+="?";
            }
            else url+="&";

            url += "Inicio="+this.inicio;
          }

          if(this.fin != null)
          { 
            if(hayparametros==false){
              hayparametros=true;
              url+="?";
            }
            else url+="&";
            url += "Fin="+this.fin;
          }

      if (this.$utils.isValid(this.idsClientes) && this.idsClientes.length > 0){
        this.idsClientes.forEach(x => {
            if(hayparametros==false){
              hayparametros=true;
              url+="?";
            }
            else url+="&";
            url += `IdsClientes=${x || 0}`
          });
      }

      if (this.$utils.isValid(this.idsVendedores) && this.idsVendedores.length > 0){
        this.idsVendedores.forEach(x => {
            if(hayparametros==false){
              hayparametros=true;
              url+="?";
            }
            else url+="&";
            url += `IdsVendedores=${x || 0}`
          });
      }

      return url;
    },
    ...mapState([
      'nombreEmpresa'
    ])
  },
  methods:{
    nombreVendedor: vendedor => vendedor.nombre + " " + vendedor.paterno + " " + vendedor.materno,
    initialize(){
      this.getClientes();
      this.getEstadisticas();
      this.getVendedores();
    },
    getVendedores (){
      let me = this;
      me.loadingVendedor = true;
      axios
      .get("/Vendedores")
      .then(response =>  {
        me.vendedores = response.data;
        me.loadingVendedor = false;
      })
      .catch(error => {
        console.log(error);
      });
    },
    getClientes (){
      let me = this;
      me.loadingCliente = true;
      axios
      .get("/Clientes")
      .then(response =>  {
        me.clientes = response.data;
        this.clientes.unshift({
          idCliente:null,
          nombre:this.nombreEmpresa,
        });
        me.loadingCliente = false;
      })
      .catch(error => {
        console.log(error);
      });
    },
    getEstadisticas(){
      var url = this.fetchUrl;
      if(url == null) return;
      this.loading = true;
      axios
      .get(url)
      .then(response => {
        this.loading = false;
        
        var options = Object.assign({},this.defaultChartOptions);
        var datos = [];
         options.xaxis.categories = response.data.clientes;
         //options.colors = response.data.colors;
         datos.push({name:"Monto", data:response.data.series});
        this.series = datos;
         
       
        this.chartOptions = options;
      })
      .catch(error => {
        this.loading = false;
        console.log(error);
      });
    }
  },
}

</script>