<template>
	<div>
		<AgregarMolde
			ref="dialogMolde"
			:moldesSelected="moldesSelected"
			:idArticulo="idArticulo"
			:idsMaquinas="idsMaquinas"
			:getImagen="getImagen"
			@select="onSelectMolde"
		/>
		<v-slide-group show-arrows class="custom-slider-class" ref="slider">
			<v-slide-item
				v-for="molde in moldesSelected"
				:key="`molde-${molde.idMolde}`"
			>
				<CardMolde
					tipo="image"
					:molde="molde"
					:getImagen="getImagen"
					:key="`card-molde-${molde.idMolde}`"
					@click:delete="deleteMolde"
					@click="showDialogMolde"
				/>
			</v-slide-item>
			<v-slide-item>
				<CardMolde tipo="icon" @click="showDialogMolde()" />
			</v-slide-item>
		</v-slide-group>
	</div>
</template>

<script>
import axios from "axios";
import AgregarMolde from "./Moldes/AgregarMolde.vue";
import CardMolde from "./Moldes/CardMolde.vue";

export default {
	components: { AgregarMolde, CardMolde },
	props: {
		idArticulo: {
			type: Number,
			required: true,
		},
		receta: {
			type: Array,
			required: true,
		},
		value: {
			type: Array,
			default: () => [],
		},
	},
	data() {
		return {
			moldesSelected: this.value,
		};
	},
	computed: {
		idsMaquinas() {
			return this.receta.reduce((acc, itemReceta) => {
				if (itemReceta.hasOwnProperty("idMaquina"))
					acc.push(itemReceta.idMaquina);
				return acc;
			}, []);
		},
	},
	watch: {
		idsMaquinas(newValue, oldValue) {
			if (newValue.length >= oldValue.length) return;

			this.removerMoldesDeMaquinas();
		},
	},
	mounted() {
		if (this.moldesSelected.length > 2) {
			setTimeout(() => {
				this.$refs.slider.scrollTo();
			}, 200);
		}
	},
	methods: {
		getImagen(molde, thumbnail = false) {
			return new Promise((resolve, reject) => {
				if (molde?.imagen == null) {
					return reject();
				}

				axios({
					url: "/Moldes/ImagenMolde",
					method: "GET",
					responseType: "blob",
					params: {
						imagen: `${thumbnail ? "m_" : ""}${molde.imagen}`,
					},
				})
					.then(async (response) => {
						let blob = new Blob([response.data], {
							type: "application/octet-strean",
						});
						const imageBase64 =
							await this.$utils.images.blobToBase64(blob);
						resolve(imageBase64);
					})
					.catch(() => {
						reject();
					});
			});
		},
		removerMoldesDeMaquinas() {
			const moldesFiltrados = this.moldesSelected.filter((molde) =>
				this.idsMaquinas.includes(molde.idMaquina)
			);
			this.moldesSelected.splice(0,this.moldesSelected.length,...moldesFiltrados);
		},
		deleteMolde(idMolde) {
			this.moldesSelected.splice(
				this.moldesSelected.findIndex(
					(molde) => molde.idMolde == idMolde
				),
				1
			);
		},
		onSelectMolde(molde) {
			this.moldesSelected.push(molde);
			setTimeout(() => {
				this.$refs.slider.scrollTo();
			}, 200);
		},
		showDialogMolde(molde = null) {
			this.$refs.dialogMolde.showDialog(molde);
		},
	},
};
</script>

<style scoped>
.custom-slider-class >>> .v-slide-group__content {
	justify-content: center;
}

.custom-slider-class >>> .v-slide-group__next {
	min-width: min-content;
}

.custom-slider-class >>> .v-slide-group__prev {
	min-width: min-content;
}

.custom-slider-class >>> .v-icon {
	font-size: 40px;
}
</style>