<template>
  <v-col cols="12">
    <v-dialog
      v-model="dialog"
      max-width="500px"
      persistent
    >
      <v-card>
        <v-card-title>{{action == 0?'Nuevo':'Editar'}} Almacén</v-card-title>
        <v-card-text>
          <v-row>
            <v-col cols="12" md="12" sm="12">
              <v-text-field
                v-model="editedItem.nombre"
                label="Nombre del Almacén"
              >
              </v-text-field>
            </v-col>
            <v-col cols="12" md="12" sm="12">
              <v-text-field
                v-model="editedItem.codigo"
                label="Clave del Almacén"
              >
              </v-text-field>
            </v-col>
            <v-col cols="12" md="12" sm="12">
              <v-autocomplete
                v-model="editedItem.idCliente"
                :items="clientes"
                item-text="nombre"
                item-value="idCliente"
                label="Pertenece a"
              >
              </v-autocomplete>
            </v-col>
            <v-col cols="12" md="12" sm="12">
              <ul>
                <li v-for="(e,idx) in errores" :key="'e-'+idx">{{e}}</li>
              </ul>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="close()">Cancelar</v-btn>
          <v-btn color="red darken-1" text @click="save()" :disabled="saving" :loading="saving">
            Guardar
            <template v-slot:loader>
              <v-progress-circular
                indeterminate
                :width="2"
                :size="24"
                color="red"
              ></v-progress-circular>
            </template>
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-data-table
      v-if="permisoVista('almacenes','r')"
      :headers="headers"
      :items="almacenes"
      :search="search"
      :loading="loading"
      loading-text="Cargando... Espere, Por Favor."
    >
      <template v-slot:top>
        <v-toolbar flat color="white">
          <v-btn color="primary" v-if="permisoVista('almacenes','c')" @click="dialog = true;">Nuevo Almacén</v-btn>
          <v-spacer></v-spacer>
          <v-text-field
            v-model="search"
            append-icon="search"
            label="Buscar"
            single-line
            hide-details
          ></v-text-field>
        </v-toolbar>
      </template>
      <template v-slot:item.action="{ item }">
        <v-tooltip bottom color="black" class="white--text">
          <template v-slot:activator="{ on }">
        <v-icon v-on="on"
          v-if="permisoVista('almacenes','u')"
          small
          class="mr-2"
          @click="editItem(item)"
        >
          edit
        </v-icon>
          </template>
          <span class="white--text">Editar</span>
        </v-tooltip>
      </template>
    </v-data-table>
  </v-col>
</template>

<script>
import axios from "axios";
import { mapState } from 'vuex';

export default {
  data: () => ({
    almacenes:[],
    headers:[
       {
        text: 'Nombre',
        align: 'left',
        sortable: true,
        value: 'nombre',
      },
      {
        text: 'Clave',
        align: 'left',
        sortable: true,
        value: 'codigo',
      },
      {
        text: 'Pertenece A',
        align: 'left',
        sortable: true,
        value: 'cliente',
      },
      {
        text: "Acciones",
        align: 'center',
        sortable: false,
        value: 'action'
      }
    ],
    saving:false,
    dialog:false,
    almacenes:[],
    search:'',
    loading:false,
    action: 0,
    editedItem:{
      idAlmacen: -1,
      nombre:'',
      codigo:'',
      idCliente: -1,
    },
    defaultItem:{
      idAlmacen: -1,
      nombre:'',
      codigo:'',
      idCliente: -1,
    },
    errores:[],
  }),
  computed:{
     ...mapState({
       nombreEmpresa: 'nombreEmpresa',
     }),
  },
  created(){
    this.initialize();
  },
  methods:{
    initialize(){
      this.getAlmacenes();
      this.getClientes();
    },
    getAlmacenes(){
      axios
      .get("/Almacenes")
      .then(response => {
        response.data.forEach(e => {
          if(e.idCliente == null){
            e.cliente = this.nombreEmpresa;
          }
        });
        this.almacenes = response.data;
      })
      .catch(error => {
        console.log(error);
      })
    },
    getClientes(){
      axios
      .get("/Clientes")
      .then(response => {
        response.data.unshift({
          idCliente: null,
          nombre: this.nombreEmpresa,
        });
        this.clientes = response.data;
      })
      .catch(error => {
        console.log(error);
      });
    },
    editItem(item){
      this.editedItem = Object.assign({},item);
      this.action = 1;
      this.dialog = true;
    },
    validate(){
      this.errores = [];
      if(this.editedItem.nombre.length < 5 || this.editedItem.nombre.length > 100){
        this.errores.push("El Nombre debe tener al menos 5 caracteres y menos de 100.");
      }
      if(this.editedItem.codigo.length < 5 || this.editedItem.codigo.length > 20){
        this.errores.push("El Nombre debe tener al menos 5 caracteres y menos de 20.");
      }

      if(this.editedItem.idCliente == -1){
        this.errores.push("Debe de seleccionar a quien le pertenece el inventario");
      }
      
      return this.errores.length == 0;
    },
    close(){
      this.action = 0;
      this.dialog=false;
      this.errores=[];
      this.editedItem = Object.assign({},this.defaultItem);
    },
    save(){
      if(!this.validate()){
        return;
      }

      if(this.action == 0){
        this.saving = true;
        axios
        .post("/Almacenes/Crear",{
          Nombre: this.editedItem.nombre,
          Codigo: this.editedItem.codigo,
          IdCliente: this.editedItem.idCliente, 
        })
        .then(response => {
          this.initialize();
          this.saving = false;
          this.close();
        })
        .catch(error => {
          console.log(error);
          this.saving = false;
          this.errores = [];
          if(error.response.status == 400){
            this.errores.push("El Cliente tiene un Almacén Activo");
          }
          else if(error.response.status == 409){
            this.errores.push("Existe Otro Almacén con el mismo Nombre o Clave");
          }
        });
      }
      else{
        this.saving = true;
        axios
        .put("/Almacenes/Actualizar/"+this.editedItem.idAlmacen,{
          Nombre: this.editedItem.nombre,
          Codigo: this.editedItem.codigo,
          IdCliente: this.editedItem.idCliente, 
        })
        .then(response => {
          this.initialize();
          this.saving = false;
          this.close();
        })
        .catch(error => {
          console.log(error);
          this.saving = false;
        });
      }
    }
  }
}
</script>