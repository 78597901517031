<template>
	<div v-if="!newRecord && items.length > 0">
		<v-autocomplete
			v-model="itemValue"
			:label="label"
			:items="items"
			:loading="loading"
			:disabled="loading || disabled"
			:error-messages="itemError"
			append-outer-icon="mdi-reload"
			@focus="itemError = null"
			@click:append-outer="$emit('click:refresh')"
		></v-autocomplete>
		<div class="text-right">
			<a
				:class="{
					'grey--text disabled-anchor': loading || disabled,
				}"
				@click="toggle(true)"
				>Crear nuevo</a
			>
		</div>
	</div>
	<div v-else>
		<v-text-field
			v-model="itemValue"
			:label="label"
			:loading="loading"
			:disabled="loading || disabled"
			:error-messages="itemError"
			@focus="itemError = null"
		></v-text-field>
		<div v-if="items.length > 0" class="text-right">
			<a
				:class="{
					'grey--text disabled-anchor': loading || disabled,
				}"
				@click="toggle(false)"
				>Seleccionar existente</a
			>
		</div>
	</div>
</template>

<script>
export default {
	props: {
		items: {
			type: Array,
			required: true,
		},
		loading: {
			type: Boolean,
			default: false,
		},
		disabled: {
			type: Boolean,
			default: false,
		},
		value: {
			type: String,
			default: null,
		},
		errorMessage: {
			type: String,
			default: null,
		},
		label: {
			type: String,
			required: true
		}
	},
	data() {
		return {
			newRecord: false,
		};
	},
	computed: {
		itemValue: {
			get() {
				return this.value;
			},
			set(newValue) {
				this.$emit("input", newValue);
			},
		},
		itemError: {
			get() {
				return this.errorMessage;
			},
			set(newValue) {
				this.$emit("update:error-message", newValue);
			},
		},
	},
	methods: {
		toggle(value) {
			this.itemValue = null;
			this.itemError = null;
			this.newRecord = value;
		},
	},
};
</script>
<style scoped>
.disabled-anchor {
	pointer-events: none;
}
</style>