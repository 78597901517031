<template>
	<v-row v-if="mostrarVista('estadoproduccion', 'r')">
		<v-dialog v-model="dialogAlerta" persistent max-width="400px">
			<v-card>
				<v-card-title>{{ alertaItem.titulo }}</v-card-title>
				<v-card-text class="pb-0 text-center">
					<v-icon size="150" :color="alertaItem.color">{{ alertaItem.icono }}</v-icon>
					<p
						class="mb-0 text-h5"
						style="font-size: 1.25rem !important"
					>
						{{ alertaItem.mensaje }}
					</p>
				</v-card-text>
				<v-card-actions>
					<v-spacer></v-spacer>
					<v-btn
						text
						color="success"
						@click="closeDialogAlerta()"
						>Aceptar</v-btn
					>
				</v-card-actions>
			</v-card>
		</v-dialog>
		<v-col cols="3">
			<v-card v-if="!viewCamera" elevation="0">
				<v-row>
					<v-col cols="12" class="pb-0">
						<v-img
							class="black--text align-end"
							height="200px"
							src="https://i.imgur.com/mdRIzgI.png"
						>
							<p class="mb-0 pa-2 text-h5 text-center" style="line-height: normal !important">
								{{ errorCamera }}
							</p>
						</v-img>
					</v-col>
				</v-row>
				<v-row>
					<v-col cols="12" class="py-0">
						<v-text-field
							v-model="codigoIdentificacion"
							label="Código de Identificación"
							hide-details
							:disabled="saving || cargando"
							:error="errorCodigoLeido"
							@focus="errorCodigoLeido = false"
						></v-text-field>
					</v-col>
				</v-row>
				<v-row>
					<v-col cols="12" class="pt-0">
						<v-btn v-if="mostrarVista('estadoproduccion', 'r')"
							color="green"
							block
							:disabled="saving || cargando"
							@click="getInformacionCodigo"
						>
							Leer Código Manualmente
						</v-btn>
					</v-col>
				</v-row>
			</v-card>
			<v-card v-else class="mx-auto" max-width="350" elevation="0">
				<v-row class="ma-0 pa-0">
					<v-col cols="12" class="ma-0 pa-0">
						<qrcode-stream
							@init="onInit"
							@decode="onDecode"
						></qrcode-stream>
					</v-col>
					<v-col cols="12" class="ma-0 pa-0">
						<v-text-field
							v-model="codigoIdentificacion"
							label="Código de Identificación"
							hide-details
							:disabled="saving || cargando"
							:error="errorCodigoLeido"
							@focus="errorCodigoLeido = false"
						>
						</v-text-field>
					</v-col>
					<v-col cols="12" class="ma-0 pa-0">
						<v-btn v-if="mostrarVista('estadoproduccion', 'r')"
							color="green"
							block
							:disabled="saving || cargando"
							@click="getInformacionCodigo"
						>
							Leer Código Manualmente
						</v-btn>
					</v-col>
				</v-row>
			</v-card>
		</v-col>
		<v-col cols="9">
			<v-row>
				<v-col cols="6">
					<v-autocomplete
						v-model="filtros.idAlmacen"
						:label="
							filtros.idAlmacen == null
								? '[Obligatorio] Seleccione un almacén'
								: 'Almacén'
						"
						:items="almacenes"
						:item-text="nombreAlmacen"
						item-value="idAlmacen"
						clearable
						:disabled="loadingAlmacenes || saving"
						:loading="loadingAlmacenes"
						hide-details
					></v-autocomplete>
				</v-col>
				<v-col cols="6">
					<v-autocomplete
						v-model="filtros.idSubalmacen"
						label="[Opcional] Subalmacén"
						:items="subalmacenesFiltrados"
						:item-text="nombreSubalmacen"
						item-value="idSubalmacen"
						clearable
						:disabled="loadingSubalmacenes || saving"
						:loading="loadingSubalmacenes"
						hide-details
					></v-autocomplete>
				</v-col>
			</v-row>
			<v-row>
				<v-col cols="12">
					<v-data-table
						:items="qrsLeidos"
						:headers="qrsHeaders"
						:loading="cargando"
					>
						<template v-slot:[`item.articulo`]="{ item }">
							{{ articuloNombre(item) }}
						</template>
						<template v-slot:[`item.cliente`]="{ item }">
							{{ clienteNombre(item) }}
						</template>
						<template v-slot:[`item.cantidad`]="{ item }">
							{{ item.cantidad.toFixed(2) }} {{ item.unidad }}
						</template>
						<template v-slot:[`item.actions`]="{ item }">
							<v-tooltip bottom color="black" class="white--text">
								<template v-slot:activator="{ on }">
									<v-icon
										v-on="on"
										small
										:disabled="saving || cargando"
										@click="deleteItem(item)"
									>
										mdi-delete
									</v-icon>
								</template>
								<span class="white--text">Eliminar</span>
							</v-tooltip>
						</template>
					</v-data-table>
				</v-col>
			</v-row>
			<v-row>
				<v-col cols="12">
					<v-btn  v-if="mostrarVista('estadoproduccion', 'c')"
						:disabled="disabledBotonAgregar || saving"
						:loading="saving"
						outlined
						color="primary"
						block
						@click="agregarAlmacen();"
						>Agregar a Almacén</v-btn
					>
				</v-col>
			</v-row>
		</v-col>
	</v-row>
</template>

<script>
import { QrcodeStream } from "vue-qrcode-reader";
import axios from "axios";

export default {
	components: {
		QrcodeStream,
	},
	data() {
		return {
			alertaItem: {
				icono: "mdi-alert",
				titulo: "",
				mensaje: "",
				color: "warning"
			},
			defaultAlertaItem: {
				icono: "mdi-alert",
				titulo: "",
				mensaje: "",
				color: "warning"
			},
			dialogAlerta: false,
			saving: false,
			errorCodigoLeido: false,
			loadingAlmacenes: false,
			loadingSubalmacenes: false,
			subalmacenes: [],
			almacenes: [],
			filtros: {
				idAlmacen: null,
				idSubalmacen: null,
			},
			qrsLeidos: [],
			qrsHeaders: [
				{
					text: "Código QR",
					align: "center",
					value: "codigo",
				},
				{
					text: "Artículo",
					align: "center",
					value: "articulo",
				},
				{
					text: "OT",
					align: "center",
					value: "ot",
				},
				{
					text: "Cliente",
					align: "center",
					value: "cliente",
				},
				{
					text: "Cantidad",
					align: "center",
					value: "cantidad",
				},
				{
					text: "Acciones",
					align: "center",
					value: "actions",
				},
			],
			viewCamera: true,
			errorCamera: null,
			codigoIdentificacion: null,
			cargando: false,
		};
	},
	computed: {
		disabledBotonAgregar() {
			return this.qrsLeidos.length == 0 || this.filtros.idAlmacen == null;
		},
		subalmacenesFiltrados() {
			if (this.filtros.idAlmacen == null) return this.subalmacenes;
			return this.subalmacenes.filter(
				(subalmacen) => subalmacen.idAlmacen == this.filtros.idAlmacen
			);
		},
	},
	mounted() {
		this.initialize();
	},
	methods: {
		nombreAlmacen: ({ codigo, nombre }) => `[${codigo}] - ${nombre}`.trim(),
		nombreSubalmacen: ({ clave, subalmacen }) =>
			`[${clave}] - ${subalmacen}`.trim(),
		resetValores(){
			Object.keys(this.filtros).forEach( (key) => this.filtros[key] = null );
			this.qrsLeidos = [];
		},
		agregarAlmacen(){
			this.saving = true;

			const payload = {
				idAlmacen: this.filtros.idAlmacen,
				idSubAlmacen: this.filtros.idSubalmacen,
				idsProdEtiquetas: this.qrsLeidos.map( (qr) => qr.idProdEtiqueta )
			}

			axios
				.post('/Inventario/ConvertirProdEtiquetas',payload)
				.then( () => {
					this.alertaItem.titulo = "Artículo(s) agregado(s) correctamente";
					this.alertaItem.mensaje = "Los artículos han sido agregados correctamente al almacén seleccionado";
					this.alertaItem.icono = "mdi-check-circle";
					this.alertaItem.color = "success";
					this.dialogAlerta = true;
					this.resetValores();
				})
				.catch( (error) => {
					console.log(error);
					this.alertaItem.titulo = "Error al agregar al almacén";
					this.alertaItem.mensaje = "Ha ocurrido un error al agregar los artículos al almacén seleccionado, vuelva a intentarlo";
					this.alertaItem.icono = "mdi-close-circle";
					this.alertaItem.color = "error";
					this.dialogAlerta = true;
				})
				.finally( () => {
					this.saving = false;
				});
		},
		clienteNombre: (value) =>
			value.clienteCodigo != null
				? `[${value.clienteCodigo}] - ${value.clienteNombre}`
				: value.clienteNombre,
		articuloNombre: (value) =>
			value.articuloCodigo != null
				? `[${value.articuloCodigo}] - ${value.articuloNombre}`
				: `${value.articuloNombre}`,
		closeDialogAlerta() {
			this.dialogAlerta = false;

			setTimeout(() => {
				this.alertaItem = Object.assign({},this.defaultAlertaItem);
			}, 200);
		},
		deleteItem(item) {
			const index = this.qrsLeidos.findIndex(
				(qrLeido) => qrLeido.idProdEtiqueta == item.idProdEtiqueta
			);
			this.qrsLeidos.splice(index, 1);
		},
		getAlmacenes() {
			this.loadingAlmacenes = true;

			axios
				.get("/Almacenes")
				.then((response) => {
					this.almacenes = response.data;
				})
				.catch((error) => {
					this.almacenes = [];
					console.log(error);
				})
				.finally(() => {
					this.loadingAlmacenes = false;
				});
		},
		getSubalmacenes() {
			this.loadingSubalmacenes = true;

			axios
				.get("/Subalmacenes")
				.then((response) => {
					this.subalmacenes = response.data;
				})
				.catch((error) => {
					this.subalmacenes = [];
					console.log(error);
				})
				.finally(() => {
					this.loadingSubalmacenes = false;
				});
		},
		initialize() {
			this.getAlmacenes();
			this.getSubalmacenes();
		},
		onDecode(code) {
			this.codigoIdentificacion = code;
			this.getInformacionCodigo();
		},
		procesarDataQR(data) {
			if (data.transformado) {
				this.alertaItem.titulo = "El artículo ya existe en el almacén";
				this.alertaItem.mensaje = "El código QR del artículo que ha introducido ya existe en el almacén";
				this.alertaItem.icono = "mdi-alert";
				this.alertaItem.color = "error";
				this.dialogAlerta = true;
				return;
			}

			const existe = this.qrsLeidos.some( (qr) => qr.idProdEtiqueta == data.idProdEtiqueta );

			if(existe){
				this.alertaItem.titulo = "El código QR ya ha sido leído";
				this.alertaItem.mensaje = "El código QR del artículo que ha introducido ya ha sido leído";
				this.alertaItem.icono = "mdi-alert-circle";
				this.alertaItem.color = "warning";
				this.dialogAlerta = true;
				return;
			}

			this.qrsLeidos.push(data);
		},
		getInformacionCodigo() {
			if (!this.codigoIdentificacion) return;

			this.cargando = true;
			axios
				.get(`/Inventario/ObtenerProdEtiqueta`, {
					params: {
						codigo: this.codigoIdentificacion.replace(/[@-]/g, ""),
					},
				})
				.then((response) => {
					this.procesarDataQR(response.data);
					this.codigoIdentificacion = null;
				})
				.catch((error) => {
					this.alertaItem.titulo = "Error al leer el código QR";
					this.alertaItem.mensaje = "Ha ocurrido un error al leer el código QR, vuelva a intentarlo";
					this.alertaItem.icono = "mdi-close-circle";
					this.alertaItem.color = "error";
					this.dialogAlerta = true;
					console.log(error);
				})
				.finally(() => {
					this.cargando = false;
				});
		},
		async onInit(promise) {
			try {
				const { capabilities } = await promise;
			} catch (error) {
				if (error.name === "InsecureContextError") {
					this.viewCamera = false;
					this.errorCamera = "Conexion de camara no segura";
					return;
				}

				if (error.name === "NotAllowedError") {
					this.viewCamera = false;
					this.errorCamera =
						"Se denegó el permiso de acceso a la cámara";
					return;
				}
				if (error.name === "NotFoundError") {
					this.viewCamera = false;
					this.errorCamera = "No hay ningun dispositivo instalado";
					return;
				}

				if (error.name === "NotSupportedError") {
					this.viewCamera = false;
					this.errorCamera =
						"No funciona a traves de HTTP o localhost";
					return;
				}

				if (error.name === "NotReadableError") {
					this.viewCamera = false;
					this.errorCamera = "La cámara se encuentra en uso";
					return;
				}
				if (error.name === "OverconstrainedError") {
					this.viewCamera = false;
					this.errorCamera =
						"did you requested the front camera although there is none?";
					return;
				}
				if (error.name === "StreamApiNotSupportedError") {
					this.viewCamera = false;
					this.errorCamera =
						"El navegador no cuenta con las funciones suficientes";
					return;
				}
			}
		},
	},
};
</script>
