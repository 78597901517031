<template>
  <v-dialog persistent v-model="show" max-width="70%" key="varianteReceta">
    <v-card v-if="!elegir">
      <v-card-text class="text-center">  
        <v-row class="justify-center">
            <v-col cols="12" md="12" sm="12">
              <h3>Cargando</h3>
            </v-col>
            <v-col cols="12" md="12" sm="12">
              <v-progress-circular
                :size="75"
                color="green"
                indeterminate
              ></v-progress-circular>
            </v-col>
          </v-row>
      </v-card-text>
    </v-card>
    <v-card v-if="elegir && receta">
      <v-card-title>
        VARIANTES DE {{titulo}}
      </v-card-title>
      <v-card-text>
        <v-data-table :items="informacion" hide-default-footer :items-per-page="-1">
          <template v-slot:body="{ items }">
              <tbody>
                <tr 
                  v-for="subheader,idxSubhead in subheadersVariantes"
                  :key="`subheader-${idxSubhead}`"
                  :class="subheader.value == 'acciones' && 'transparent'"
                >
                  <template> <!-- Headers -->
                    <td 
                      v-if="getFromSubheader(subheader,'index')==0"
                      :rowspan="getFromSubheader(subheader,'length')"
                      :colspan="subheader.value=='conceptos'&&2"
                      class="text-center font-weight-bold text-uppercase"
                      :style="subheader.value != 'acciones' && 'background-color: #f2f2f2'"
                    >
                      {{subheader.group}}
                    </td>
                    <td v-if="idxSubhead > 0" class="text-center text-uppercase">
                      {{subheader.text}}
                    </td>
                  </template>
                  <template> <!-- Body -->
                    <td 
                      v-for="(info,idx) in items"
                      :key="`info-${info.idReceta}`"
                      :style="subheader.value != 'acciones' && getBackgroundColor(idx)"
                      :class="subheader.value == 'acciones' && 'px-2' "
                    >
                      <template v-if="subheader.value == 'conceptos'">
                        <p
                          class="text-center mb-0 mt-4 font-weight-bold" 
                          style="font-size: 16px !important"
                          :class="posicion==idx&&'white--text'"
                        >
                          {{info.titulo}}
                        </p>
                        <p class="mb-0 mb-4 text-center" :class="posicion==idx&&'white--text'">
                          Descripción: {{info.descripcion==null?'N/D':info.descripcion}}
                        </p>
                      </template>
                      <template v-if="subheader.value == 'precio'">
                        <p class="mb-0 text-center" :class="textColor(info.precioMin,posicion==idx)">
                          {{info.precioMin < 0 ? '- $ ' : posicion == idx ? '$ ':'+ $ '}}
                          {{Math.abs(info.precioMin).toFixed(2)}}
                        </p>
                      </template>
                      <template v-if="subheader.value == 'tiempo'">
                        <p class="mb-0 text-center" :class="textColor(info.tiempoMin,posicion==idx)">
                          {{info.tiempoMin < 0 ? '- ' : posicion == idx ? '': '+ '}}
                          {{mostrarTiempo(Math.abs(info.tiempoMin))}}
                        </p>
                      </template>
                      <template
                        v-if="
                          subheader.value.split(':')[0]=='articulo' ||
                          subheader.value.split(':')[0]=='maquina' ||
                          subheader.value.split(':')[0]=='puesto'
                        "
                      >
                        <p 
                          class="mb-0 text-center"
                          :class="
                            textColor(
                              getFromArray(
                                subheader.value.split(':')[0]=='articulo'
                                  ? info.articulos
                                  : subheader.value.split(':')[0]=='maquina'
                                    ? info.maquinas
                                    : info.puestos,
                                subheader.value.split(':')[1],
                                idx,
                                'cantidad'
                              ),
                              posicion==idx
                            )
                          "
                        >
                          {{
                            getFromArray(
                              subheader.value.split(':')[0]=='articulo'
                                ? info.articulos
                                : subheader.value.split(':')[0]=='maquina'
                                  ? info.maquinas
                                  : info.puestos,
                              subheader.value.split(':')[1],
                              idx,
                              'text'
                            )
                          }}
                        </p>
                      </template>
                      <template v-if="subheader.value == 'acciones'">
                        <v-btn 
                          class="mt-1"
                          color="#1976D2"
                          block
                          :disabled="posicion == idx"
                          @click="posicion = idx"
                          >Seleccionar</v-btn
                        >
                      </template>
                    </td>
                  </template>
                </tr>
              </tbody>
          </template>
        </v-data-table>
        <v-slide-group
          v-if="false"
          v-model="posicion"
          class="pa-4"
          show-arrows
          center-active
          mandatory
        >
          <v-slide-item
            v-for="info in informacion"
            :key="info.idReceta"
            v-slot:default="{ active, toggle }"
          >
            <v-card
              :color="active ? 'blue' : 'grey lighten-4'"
              class="ma-4 overflow-y-auto overflow-x-hidden"
              height="200"
              width="275"
              @click="toggle"
            >
              <v-row justify="center">
                <v-card-title :class="'pa-2' + (active?' white--text':'')">{{info.titulo}}</v-card-title>
              </v-row>
              <v-divider class="mx-4" :dark="active"></v-divider>
              <v-card-text>
                <div :class="textColor(info.precioMin,active)">
                  {{info.descripcion}}
                </div>
                <div :class="textColor(info.precioMin,active)" v-if="permisoVista('listas','$')">
                  Precio Mínimo: {{ (info.precioMin < 0) ?'-':''}} ${{Math.abs(info.precioMin).toFixed(2)}}  
                </div>
                <div :class="textColor(info.tiempoMin,active)">
                  Tiempo Ideal: {{ (info.tiempoMin < 0) ?'-':''}} {{mostrarTiempo(Math.abs(info.tiempoMin))}}  
                </div>
                </v-card-text>
                <v-divider class="mx-4" :dark="active"></v-divider> 
                <!--
                * Codigos 
                * -1 Excluye
                * 0 Diferente Cantidad
                * 1 Incluye
                -->
                <v-card-text v-if="info.articulos.length > 0" class="pa-top-0 pa-bottom-0">
                  <b>Articulos:</b>
                  <ul>
                    <li v-for="(art, idx) in info.articulos" :key="'a-'+idx+'-'+info.idArticulo+'-'+info.idReceta" :class="codigoColor(art.codigo)">{{art.mensaje}}</li>
                  </ul>
                </v-card-text>
                <v-card-text v-if="info.maquinas.length > 0" class="pa-top-0 pa-bottom-0">
                  <b>Máquinas:</b>
                  <ul>
                    <li v-for="(maq, idx) in info.maquinas" :key="'m-'+idx+'-'+info.idMaquina+'-'+info.idReceta" :class="codigoColor(maq.codigo)">{{maq.mensaje}}</li>
                  </ul>
                </v-card-text>
                <v-card-text v-if="info.puestos.length > 0" class="pa-top-0 pa-bottom-0">
                  <b>Puestos:</b>
                  <ul>
                    <li v-for="(pue, idx) in info.puestos" :key="'p-'+idx+'-'+info.idPuesto+'-'+info.idReceta" :class="codigoColor(pue.codigo)">{{pue.mensaje}}</li>
                  </ul>
                </v-card-text>
                <br>
                <br>
            </v-card>
          </v-slide-item>
        </v-slide-group>
        <v-btn class="mt-8" block color="primary" dark @click="elegirReceta">Seleccionar Opción {{posicion +1}}</v-btn>
      </v-card-text>
    </v-card>
    <v-card v-if="elegir && mp">
      <v-card-title>
        VARIANTES DE MATERIA PRIMA
      </v-card-title>
      <v-card-text>
        <v-simple-table>
          <template v-slot:default>
            <thead>
              <tr>
                <th class="text-left">Componente</th>
                <th class="text-left">Variante</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="cambio in cambios" :key="'c'+cambio.idReceta+'-'+cambio.idArticulo">
                <td>{{ cambio.pieza }}</td>
                <td>
                  <v-autocomplete
                    v-model="cambio.idArticuloRemplaza"
                    :items="obtenerVariantesMP(cambio.idReceta, cambio.idArticulo)"
                    item-text="nombre"
                    item-value="idArticulo"
                  >
                  </v-autocomplete>
                </td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>
        <v-btn block color="primary" dark @click="elegirVariantes">Seleccionar Variantes</v-btn>
      </v-card-text>
    </v-card>
    <v-card v-if="elegir && tiempoCero">
      <v-card-title>
        Recetas sin Tiempo de Producción
      </v-card-title>
      <v-card-text>
        <div v-if="recetasTiempoCero.length == 1" class="text-center subtitle-1 font-weight-bold red--text">Un elemento de la receta no tiene definido un tiempo ideal de realización.</div>
        <div v-else class="text-center subtitle-1 font-weight-bold red--text">Más de un elemento de la receta no tiene definido un tiempo de realización.</div>
        <br>
        <v-row class="text--center justify-center">
          <v-col cols="8" sm="8" md="8" class="pa-0">Tiempo Aproximado (HH:MM:SS)</v-col>
          <v-col cols="8" sm="8" md="8" class="pa-0">
            <time-picker
              v-model="tiempo"
              dense
              label="Tiempo Ideal (HH:MM:SS)"
              @input="cambioValorTiempo"
              :error="tiempoError"
            ></time-picker>
          </v-col>
        </v-row>
        <v-simple-table
          dense
        >
          <template v-slot:default>
            <thead>
              <tr>
                <th class="text-left">Elemento</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(msg,idx) in recetasTiempoCero" :key="'msg-'+idx">
                <td>{{ msg }}</td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>
        <v-btn block color="primary" dark @click="elegirTiempo">Finalizar</v-btn>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import axios from 'axios';
import timepicker from '@/components/TimePicker';


export default {
    components:{
      'time-picker':timepicker,
    },
    data: () => ({
      subheadersVariantes: [
        {
          text: "Conceptos",
          align: "center",
          value: "conceptos",
          group: "Conceptos"
        },
        {
          text: "Precio Mínimo",
          align: "center",
          value: "precio",
          group: "Generales"
        },
        {
          text: "Tiempo Ideal",
          align: "center",
          value: "tiempo",
          group: "Generales"
        }
      ],
      defaultSubheadersVariantes: [
        {
          text: "Conceptos",
          align: "center",
          value: "conceptos",
          group: "Conceptos"
        },
        {
          text: "Precio Mínimo",
          align: "center",
          value: "precio",
          group: "Generales"
        },
        {
          text: "Tiempo Ideal",
          align: "center",
          value: "tiempo",
          group: "Generales"
        }
      ],
      titulo:"",
      posicion: 0,
      mostrar:true,
      elegir:false,
      receta:false,
      tiempoCero:false,
      mp: false,
      articulo:null,
      articulosrevisar:[],
      recetas:[],
      informacion:[],
      cambios:[],
      alternativas:[],
      recetasTiempoCero:[],
      tiempo:0,
      tiempoError: true,
    }),
    props:{
      show:{
        type:Boolean,
        default: false,
      },
      idArticulo:{
        type: Number,
        default: 0,
      }
    },
    watch:{
      show(){
        if(this.show == true){
          this.getVariantes();
        }
        else{
          this.reiniciar();
          if(this.idArticulo <= 0 || this.idArticulo == null) return;
        }
      },
      posicion(){
        this.calcularInformacion();
      },
    },
    methods:{
      getBackgroundColor(idx){
        return this.posicion == idx ? 'background-color: #1976D2' : '';
      },
      getFromArray(arreglo,idObjeto,idx,option = 'text'){
        const idKey = Object.keys(arreglo.length > 0 ? arreglo[0] : {}).find( key => key.slice(0,2) == 'id' );
        const result = arreglo.find( elemento => elemento[idKey] == idObjeto);
        if(option == 'text'){
          return result
            ? `${ 
                result.cantidad < 0 
                ? `- ${Math.abs(result.cantidad)}` 
                : this.posicion != idx && result.codigo != 2 && result.codigo != 3
                  ? `+ ${result.cantidad}`
                  : result.cantidad
              } 
              ${ result.unidad ? this.$utils.strings.pluralizar(result.unidad,Math.abs(result.cantidad),true) : 'U' }
              ${result.tiempo != null?` ${ this.posicion == idx || result.tiempo == 0 ? ' ' : result.tiempo < 0 ? '-' : '+'} ${this.mostrarTiempo(Math.abs(result.tiempo))}`:''}`
            : 'N/A';
        }
        else{
          return result 
            ? result.codigo != 2 
              ? result.codigo != 3 
                ? result.cantidad
                : result.tiempo
              : 0 
            : -1;
        }
      },
      getFromSubheader(subheader,option = 'index'){
        const filtrado = this.subheadersVariantes.filter(sub => sub.group == subheader.group);
        return option == 'index' ? filtrado.indexOf(subheader) : filtrado.length;
      },
      reiniciar(){
        this.titulo = "";
        this.posicion = 0;
        this.mostrar = true;
        this.elegir = false;
        this.receta = false;
        this.tiempoCero = false;
        this.mp = false;
        this.articulo = null;
        this.articulosrevisar = [];
        this.recetas = [];
        this.informacion = [];
        this.cambios = [];
        this.alternativas = [];
        this.recetasTiempoCero = [];
        this.tiempo = 0;
        this.tiempoError = true;
      },
      textColor(monto,activo){
        if(activo) return 'white--text ';
        else{
          if(monto < 0) return 'green--text ';
          else if(monto > 0) return 'red--text ';
          else return '';
        }
      },
      mostrarTiempo(tiempo){
        var horas = Math.trunc(tiempo/3600.0);
        tiempo -= horas * 3600;
        var minutos = Math.trunc(tiempo/60.0);
        tiempo -= minutos * 60;
        return ("0" + horas).slice(-2)+":"+("0" + minutos).slice(-2)+":"+("0" + tiempo).slice(-2);
      },
      elegirReceta(){
        this.elegir = false;
        this.receta = false;
        this.articulo.recetas[this.posicion].articulos.forEach(art => {
          if(art.recetas != null){
            this.articulosrevisar.push(art);
          }
          else{
            if(art.remplazar == true){
              this.cambios.push({
                pieza: this.articulo.nombre,
                idReceta: this.articulo.recetas[this.posicion].idReceta,
                idArticulo: art.idArticulo,
                idArticuloRemplaza: art.idArticulo,
              });
            }
          }
        });
        this.recetas.push({
          idArticulo:this.articulo.recetas[this.posicion].idArticulo,
          idReceta:this.articulo.recetas[this.posicion].idReceta,
        });
        this.posicion = 0;
        this.titulo = "";
        this.articulo = null;
        this.articulosrevisar.shift();
        this.moverArticulos();
      },
      elegirVariantes(){
        this.elegir = false;
        this.mp = false;
        this.getTiempoCero();
      },
      elegirTiempo(){
        this.elegir = false;
        this.tiempoCero = false;
        this.finalizar();
      },
      cambioValorTiempo(event){
        if(event <= 0){
          this.tiempoError = true;
          this.tiempo = 0;
        }
        else{
          this.tiempoError = false;
          this.tiempo = event;
        }
      },
      moverArticulos(){
        if(this.articulosrevisar.length > 0){
          if(this.articulosrevisar[0].recetas != null){
            if(this.recetas.findIndex(receta => receta.idArticulo == this.articulosrevisar[0].idArticulo) > -1){
              this.articulosrevisar.shift();
              this.moverArticulos();
            }
            else{
              if(this.articulosrevisar[0].recetas.length == 1){
                this.articulosrevisar[0].recetas[0].articulos.forEach(art => {
                  if(art.recetas != null){
                    this.articulosrevisar.push(art);
                  }
                  else{
                    if(art.remplazar == true){
                      this.cambios.push({
                        pieza: this.articulosrevisar[0].nombre,
                        idReceta: this.articulosrevisar[0].recetas[0].idReceta,
                        idArticulo: art.idArticulo,
                        idArticuloRemplaza: art.idArticulo,
                      });
                    }
                  }
                });
                if(this.recetas.findIndex(receta => receta.idArticulo == this.articulosrevisar[0].idArticulo) < 0){
                  this.recetas.push({
                    idArticulo:this.articulosrevisar[0].recetas[0].idArticulo,
                    idReceta:this.articulosrevisar[0].recetas[0].idReceta,
                  });
                }
                this.articulosrevisar.shift();
                this.moverArticulos();
              }
              else if(this.articulosrevisar[0].recetas.length > 1){
                this.articulo = this.articulosrevisar[0];
                this.calcularInformacion();
                this.elegir = true;
                this.receta = true;
              }
            }
          }
          else{
           this.articulosrevisar.shift();
          }
        }
        else{
          this.getVariantesMP();  
        }
      },
      procesarHeaders(){
        const machines = [];
        const articles = [];
        const jobs = [];
        this.informacion.forEach( info => {
          /// ARTICULOS //
          info.articulos.forEach( articulo => {
            const splitted = articulo.mensaje.split(':');
            articles.push({
              idArticulo: articulo.idArticulo,
              nombre: splitted[splitted.length-1].trim()
            });
          });
          /// MAQUINAS //
          info.maquinas.forEach( maquina => {
            const splitted = maquina.mensaje.split(':');
            machines.push({
              idMaquina: maquina.idMaquina,
              nombre: splitted[splitted.length-1].trim()
            });
          });
          /// PUESTOS ///
          info.puestos.forEach( puesto => {
            const splitted = puesto.mensaje.split(':');
            jobs.push({
              idPuesto: puesto.idPuesto,
              nombre: splitted[splitted.length-1].trim()
            });
          });
        });

        const idsArticulosUnicos = [ ...new Set(articles.map( artic => artic.idArticulo )) ].sort( (a,b) => a - b );
        const idsMaquinasUnicos = [ ...new Set(machines.map( maq => maq.idMaquina )) ].sort( (a,b) => a - b );
        const idsPuestosUnicos = [ ...new Set(jobs.map( puesto => puesto.idPuesto )) ].sort( (a,b) => a - b );

        const articulosUnicos = idsArticulosUnicos.map( idArticulo => articles.find( artic => artic.idArticulo == idArticulo ) )
        const maquinasUnicas = idsMaquinasUnicos.map( idMaquina => machines.find( maq => maq.idMaquina == idMaquina ) );
        const puestosUnicos = idsPuestosUnicos.map( idPuesto => jobs.find( puesto => puesto.idPuesto == idPuesto ) );

        this.subheadersVariantes = [ ...this.defaultSubheadersVariantes ];

        articulosUnicos.forEach( articulo => {
          this.subheadersVariantes.push({
            text: articulo.nombre,
            align: "center",
            value: `articulo:${articulo.idArticulo}`,
            group: "Artículos"
          });
        });

        maquinasUnicas.forEach( maquina => {
          this.subheadersVariantes.push({
            text: maquina.nombre,
            align: "center",
            value: `maquina:${maquina.idMaquina}`,
            group: "Máquinas"
          });
        });

        puestosUnicos.forEach( puesto => {
          this.subheadersVariantes.push({
            text: puesto.nombre,
            align: "center",
            value: `puesto:${puesto.idPuesto}`,
            group: "Puestos"
          });
        });

        this.subheadersVariantes.push({
          text: "",
          align: "center",
          value: 'acciones',
          group: ""
        });
      },
      quitarSinDiferencias(){
        const articulos = [];
        const maquinas = [];
        const puestos = [];
        this.informacion.forEach( info => {
          info.puestos.forEach( puesto => {
            if(puesto.codigo == 2){
              puestos.push( puesto );
            }
          });
          info.articulos.forEach( articulo => {
            if(articulo.codigo == 2){
              articulos.push( articulo );
            }
          });
          info.maquinas.forEach( maquina => {
            if(maquina.codigo == 2){
              maquinas.push( maquina );
            }
          });
        });

        this.removeNoDiff( articulos,'articulos' );
        this.removeNoDiff( maquinas,'maquinas' );
        this.removeNoDiff( puestos,'puestos' );
      },
      removeNoDiff(arreglo,idKeyArreglo){
        const idKey = Object.keys(arreglo.length > 0 ? arreglo[0] : {}).find( key => key.slice(0,2) == 'id' );
        const grouped = this.arrayGroupBy(arreglo,idKey);
        Object.keys(grouped).forEach( key => {
          if(grouped[key].length == this.informacion.length){
            const diferencias = [ ...new Set(grouped[key].map( elemento => elemento.cantidad )) ];
            if(diferencias.length == 1){ // No hay diferencias en ninguna opcion
              this.informacion.forEach( info => {
                info[idKeyArreglo] = info[idKeyArreglo].filter( elemento => elemento[idKey] != key);
              })
            }
          }
        });
      },
      arrayGroupBy(arreglo,idKey){
        return arreglo.reduce( (groups, item) => {
          if(Object.keys(groups).includes(item[idKey])) return groups;

          groups[item[idKey]] = arreglo.filter( elemento => elemento[idKey] == item[idKey] );
          return groups;
        }, {});
      },
      calcularInformacion(){
        if(this.articulo == null) return; 
        this.informacion = [];
        this.titulo = this.articulo.nombre;
        this.articulo.recetas.forEach((opcion,idx) => {
          opcion.articulos = this.regroupBy(opcion.articulos);
          opcion.maquinas = this.regroupBy(opcion.maquinas);
          opcion.puestos = this.regroupBy(opcion.puestos);

          this.informacion.push({
            titulo: "Opción "+ (idx+1),
            descripcion: opcion.descripcion,
            idReceta: opcion.idReceta,
            precioMin: this.posicion != idx ? opcion.costoMin - this.articulo.recetas[this.posicion].costoMin : opcion.costoMin,
            precioMax: this.posicion != idx ? opcion.costoMax - this.articulo.recetas[this.posicion].costoMax : opcion.costoMax,
            tiempoMin: this.posicion != idx ? opcion.tiempoMin - this.articulo.recetas[this.posicion].tiempoMin : opcion.tiempoMin,
            tiempoMax: this.posicion != idx ? opcion.tiempoMax - this.articulo.recetas[this.posicion].tiempoMax : opcion.tiempoMax,
            articulos: this.obtenerDiferenciasArticulos(idx),
            maquinas: this.obtenerDiferenciasMaquinas(idx),
            puestos: this.obtenerDiferenciasPuestos(idx),
          });
        });

        this.quitarSinDiferencias();

        this.procesarHeaders();
      },
      obtenerDiferenciasArticulos(pos)
      {
        var diferencias = [];
        /*
         * Codigos 
         * -1 Excluye
         * 0 Diferente Cantidad
         * 1 Incluye
         * 2 No hay diferencia
         */
        // if(this.posicion != pos || true){
          var revisadas = [];
          this.articulo.recetas[pos].articulos.forEach(art => {
            var pos = this.articulo.recetas[this.posicion].articulos.findIndex(a => a.idArticulo == art.idArticulo);
            if(pos == -1){
              var dif = {
                codigo: 1,
                mensaje: "+" + art.cantidad + ": " + art.nombre,
                idArticulo: art.idArticulo,
                cantidad: art.cantidad,
                unidad: art.unidad
              };
              diferencias.push(dif);
            }
            else
            {
              var difCant = (this.articulo.recetas[this.posicion].articulos[pos].cantidad - art.cantidad) * -1;
              if(difCant > 0)
              {
                var dif = {
                  codigo: 0,
                  mensaje: "+" + difCant + ": " + art.nombre,
                  idArticulo: art.idArticulo,
                  cantidad: difCant,
                  unidad: art.unidad
                };
                diferencias.push(dif);
              }
              if(difCant < 0)
              {
                var dif = {
                  codigo: 0,
                  mensaje: difCant + ": " + art.nombre,
                  idArticulo: art.idArticulo,
                  cantidad: difCant,
                  unidad: art.unidad
                };
                diferencias.push(dif);
              }
              if(Math.abs(difCant) == 0){
                const dif = {
                  codigo: 2,
                  mensaje: art.cantidad + ": " + art.nombre,
                  idArticulo: art.idArticulo,
                  cantidad: art.cantidad,
                  unidad: art.unidad
                };
                diferencias.push(dif);
              }
              revisadas.push(art.idArticulo);
            }
          });
          this.articulo.recetas[this.posicion].articulos.filter(articulo => {
            return (revisadas.indexOf(articulo.idArticulo) == -1);
          })
          .forEach(art => {
            var dif = {
              codigo: -1,
              mensaje: "-"+ art.cantidad + ": " + art.nombre,
              idArticulo: art.idArticulo,
              cantidad: art.cantidad * -1,
              unidad: art.unidad
            };
            diferencias.push(dif);
          });

        // }
        return diferencias;
      },
      obtenerDiferenciasMaquinas(pos)
      {
        var diferencias = [];
        /*
         * Codigos 
         * -1 Excluye
         * 0 Diferente Cantidad
         * 1 Incluye
         * 2 No hay diferencia
         * 3 Diferente Tiempo
         */
        // if(this.posicion != pos){
          var revisadas = [];
          this.articulo.recetas[pos].maquinas.forEach(maq => {
            var pos = this.articulo.recetas[this.posicion].maquinas.findIndex(a => a.idMaquina == maq.idMaquina);
            if(pos == -1){
              var dif = {
                codigo: 1,
                mensaje: "+" + maq.cantidad + ": " + maq.marca+" - "+maq.modelo,
                cantidad: maq.cantidad,
                idMaquina: maq.idMaquina,
                tiempo: maq.tiempo,
              };
              diferencias.push(dif);
            }
            else
            {
              const difCant = (this.articulo.recetas[this.posicion].maquinas[pos].cantidad - maq.cantidad) * -1;
              const difTiempo = (this.articulo.recetas[this.posicion].maquinas[pos].tiempo - maq.tiempo) * -1;
              if(difCant > 0)
              {
                var dif = {
                  codigo: 0,
                  mensaje: "+" + difCant + ": " + maq.marca+" - "+maq.modelo,
                  cantidad: difCant,
                  idMaquina: maq.idMaquina,
                  tiempo: difTiempo,
                };
                diferencias.push(dif);
              }
              else if(difCant < 0)
              {
                var dif = {
                  codigo: 0,
                  mensaje:  difCant + ": " + maq.marca+" - "+maq.modelo,
                  cantidad: difCant,
                  idMaquina: maq.idMaquina,
                  tiempo: difTiempo,
                };
                diferencias.push(dif);
              }
              else{
                if(difTiempo > 0){
                  var dif = {
                    codigo: 3,
                    mensaje: "+" + this.mostrarTiempo(Math.abs(difTiempo)) + ": " + maq.marca+" - "+maq.modelo,
                    cantidad: difCant == 0 ? maq.cantidad : difCant,
                    idMaquina: maq.idMaquina,
                    tiempo: difTiempo,
                  };
                  diferencias.push(dif);
                }
                else if(difTiempo < 0){
                  var dif = {
                    codigo: 3,
                    mensaje: "-" + this.mostrarTiempo(Math.abs(difTiempo)) + ": " + maq.marca+" - "+maq.modelo,
                    cantidad: difCant == 0 ? maq.cantidad : difCant,
                    idMaquina: maq.idMaquina,
                    tiempo: difTiempo,
                  };
                  diferencias.push(dif);
                }
                else {
                  const dif = {
                    codigo: 2,
                    mensaje: maq.cantidad + ": " + maq.marca+" - "+maq.modelo,
                    cantidad: maq.cantidad,
                    idMaquina: maq.idMaquina,
                    tiempo: maq.tiempo,
                  };
                  diferencias.push(dif);
                }
              }
              revisadas.push(maq.idMaquina);
            }
          });
          this.articulo.recetas[this.posicion].maquinas.filter(maquina => {
            return (revisadas.indexOf(maquina.idMaquina) == -1);
          })
          .forEach(maq => {
            var dif = {
              codigo: -1,
              mensaje: "-"+maq.cantidad + ": " + maq.marca+" - "+maq.modelo,
              cantidad: maq.cantidad * -1,
              idMaquina: maq.idMaquina,
              tiempo: maq.tiempo * -1,
            };
            diferencias.push(dif);
          });

        // }
        return diferencias;
      },
      obtenerDiferenciasPuestos(pos)
      {
        var diferencias = [];
        /*
         * Codigos 
         * -1 Excluye
         * 0 Diferente Cantidad
         * 1 Incluye
         * 2 No hay diferencia
         * 3 Diferente Tiempo
         */
        // if(this.posicion != pos){
          var revisadas = [];
          this.articulo.recetas[pos].puestos.forEach(pue => {
            var pos = this.articulo.recetas[this.posicion].puestos.findIndex(a => a.idPuesto == pue.idPuesto);
            if(pos == -1){
              var dif = {
                codigo: 1,
                mensaje: "+" + pue.cantidad + ": " + pue.nombre,
                idPuesto: pue.idPuesto,
                cantidad: pue.cantidad,
                tiempo: pue.tiempo,
              };
              diferencias.push(dif);
            }
            else
            {
              var difCant = (this.articulo.recetas[this.posicion].puestos[pos].cantidad - pue.cantidad) * -1;
              var difTiempo = (this.articulo.recetas[this.posicion].puestos[pos].tiempo - pue.tiempo) * -1;
              if(difCant > 0)
              {
                var dif = {
                  codigo: 0,
                  mensaje: "+" + difCant + ": " + pue.nombre,
                  idPuesto: pue.idPuesto,
                  cantidad: difCant,
                  tiempo: difTiempo,
                };
                diferencias.push(dif);
              }
              else if(difCant < 0)
              {
                var dif = {
                  codigo: 0,
                  mensaje:  difCant + ": " + pue.nombre,
                  idPuesto: pue.idPuesto,
                  cantidad: difCant,
                  tiempo: difTiempo,
                };
                diferencias.push(dif);
              }
              else{
                if(difTiempo > 0){
                  var dif = {
                    codigo: 3,
                    mensaje: "+" + this.mostrarTiempo(Math.abs(difTiempo)) + ": " + pue.nombre,
                    idPuesto: pue.idPuesto,
                    cantidad: difCant == 0 ? pue.cantidad : difCant,
                    tiempo: difTiempo,
                  };
                  diferencias.push(dif);
                }
                else if(difTiempo < 0){
                  var dif = {
                    codigo: 3,
                    mensaje: "-" + this.mostrarTiempo(Math.abs(difTiempo)) + ": " + pue.nombre,
                    idPuesto: pue.idPuesto,
                    cantidad: difCant == 0 ? pue.cantidad : difCant,
                    tiempo: difTiempo,
                  };
                  diferencias.push(dif);
                }
                else {
                  const dif = {
                    codigo: 2,
                    mensaje: pue.cantidad + ": " + pue.nombre,
                    idPuesto: pue.idPuesto,
                    cantidad: pue.cantidad,
                    tiempo: pue.tiempo,
                  };
                  diferencias.push(dif);
                }
              }
              revisadas.push(pue.idPuesto);
            }
          });
          this.articulo.recetas[this.posicion].puestos.filter(pueuina => {
            return (revisadas.indexOf(pueuina.idPuesto) == -1);
          })
          .forEach(pue => {
            var dif = {
              codigo: -1,
              mensaje: "-"+pue.cantidad + ": " + pue.nombre,
              idPuesto: pue.idPuesto,
              cantidad: pue.cantidad * -1,
              tiempo: pue.tiempo * -1,
            };
            diferencias.push(dif);
          });

        // }
        return diferencias;
      },
      obtenerVariantesMP(idReceta, idArticulo){
        var pos = this.alternativas.findIndex(alt => alt.idArticulo == idArticulo && alt.idReceta == idReceta);
        if(pos < 0) return [];
        else return this.alternativas[pos].alternativas;
      },
      regroupBy(arreglo){
        const idKeyObjeto = Object.keys(arreglo.length > 0 ? arreglo[0] : {}).find( key => key.slice(0,2) == 'id' );
        const grouped = this.arrayGroupBy(arreglo,idKeyObjeto);
        const regrouped = [];
        Object.keys(grouped).forEach( key => {
          let cantidad = 0;
          let tiempo = 0;

          grouped[key].forEach( (elemento) => {
            cantidad += elemento.cantidad;
            tiempo += elemento.tiempo;
          });

          if(grouped[key].length > 0){
            const objeto = {
              ...grouped[key][0],
              cantidad,
              tiempo
            };
            regrouped.push( objeto );
          }
        });
        
        return regrouped;
      },
      getTiempoCero(){
        axios
        .post('/Recetas/TiempoCero',this.recetas)
        .then(response => {
          this.recetasTiempoCero = response.data;
          if(this.recetasTiempoCero.length == 0){
            this.finalizar();
          }
          else{
            this.elegir = true;
            this.tiempoCero = true;
          }
        })
        .catch(error => {
          console.log(error);
        })
      },
      getVariantes(){
        if(this.idArticulo <= 0 || this.idArticulo == null) return;
        axios
          .get("/Trabajoordenes/VariantesReceta/"+this.idArticulo)
          .then(response =>{
            this.articulosrevisar.push(response.data);
            this.moverArticulos();
          })
          .catch(error =>{
            console.log(error);
          });
      },
      finalizar(){
        var obj = {
          recetas: this.recetas,
          cambios: this.cambios.filter(element => {
            return element.idArticulo != element.idArticuloRemplaza;
          }).map(element => {
            return {
              IdReceta: element.idReceta,
              IdArticulo: element.idArticulo,
              IdArticuloRemplaza: element.idArticuloRemplaza,
            }
          }),
          tiempo: this.tiempo,
        }
        this.reiniciar();
        this.$emit('change',obj);
      },
      getVariantesMP(){
        if(this.cambios.length == 0){
          this.getTiempoCero();
        }
        else{
          axios
          .post("/Articulos/ObtenerVariantes",
            this.cambios.map(cambio => {
              return {
                IdReceta: cambio.idReceta,
                idArticulo: cambio.idArticulo,
              }
            })
          )
          .then(response => {
            this.alternativas = response.data;
            this.elegir = true;
            this.mp = true;
          })
          .catch(error => {
            console.log(error);
          });
        }
      },
      codigoColor(codigo){
        /*
        * Codigos 
        * -1 Excluye
        * 0 Diferente Cantidad
        * 1 Incluye
        */
        switch (codigo) {
          case -1:
            return 'green--text';
            break;
          case 0:
            return;
            break;
          case 1:
            return 'red--text';
            break;
          default:
            return;
            break;
        }
      }
    }
}
</script>

<style scoped>
  .pa-top-0{
    padding-top: 0px !important;
  }
  .pa-bottom-0{
    padding-bottom: 0px !important;
  }
</style>