<template>
    <div>
        <v-col cols="12">
            <v-row v-if="loading">
                <v-col cols="12" class="text-center">
                    <v-progress-circular size="100" color="primary" indeterminate></v-progress-circular>
                    <br>
                    <br>
                    <div class="text-h4">Cargando</div>
                </v-col>
            </v-row>
            <v-row v-else-if="vinculado">
                <v-col cols="12" class="text-center">
                    <v-icon :size="256" color="green">
                        mdi-check-circle-outline
                    </v-icon>
                    <br>
                    <span class="text-h4">Su cuenta se encuentra vinculada a Whatsapp</span>
                    <br>
                    <span>A partir de este momento podra recibir alertas y mensajes de ManagrX en su dispositivo.</span>
                    <br>
                    <v-btn @click="dialogWhatsapp = true" color="red">Información</v-btn>
                </v-col>
            </v-row>
            <v-row v-else-if="!vinculado">
                <v-col cols="12" class="text-center">
                    <v-icon :size="256" color="red">
                        mdi-close-circle-outline
                    </v-icon>
                    <br>
                    <span class="text-h4">Su cuenta no se encuentra vinculada a Whatsapp</span>
                    <br>
                    <br>
                    <v-row>
                        <v-col cols="12">
                            <v-btn color="blue" @click="dialogWhatsapp = true">
                                <v-icon>mdi-whatsapp</v-icon> Vincular mi Whatsapp
                            </v-btn>
                        </v-col>
                    </v-row>
                </v-col>
            </v-row>
        </v-col>
        <v-dialog v-model="dialogWhatsapp" persistent max-width="490" max-height="490">
            <v-card max-width="490" max-height="490">
                <v-stepper v-model="e1">
                    <v-stepper-header>
                        <v-stepper-step :complete="e1 > 1" step="1">
                            Número de telefono
                        </v-stepper-step>

                        <v-stepper-step :complete="validacionCode" step="2">
                            {{ validacionCode == true ? " Código Verificador" : "Código de Verificación" }}
                        </v-stepper-step>
                    </v-stepper-header>

                    <v-stepper-items>
                        <v-stepper-content step="1">
                            <v-row>
                                <v-col cols="12">
                                    <v-autocomplete v-model="selectedAreaCode" :items="areaCodes" item-text="text"
                                        item-value="value" label="Código de área" :disabled="block"></v-autocomplete>
                                </v-col>
                                <v-col cols="12">
                                    <v-text-field v-model="phoneNumber" label="Número de teléfono" type="tel"
                                        :disabled="block"></v-text-field>
                                </v-col>
                            </v-row>
                            <v-btn color="primary" @click="block == false ? EnviarCodigoValidacion() : e1 = 2"
                                :disabled="loadValidacion" :loading="loadValidacion">
                                {{ errorCode ? "Reenviar código de verificación" : block == false ? "Enviar código de verificación": "Continuar" }}
                            </v-btn>

                            <v-btn text>
                                Cancel
                            </v-btn>
                        </v-stepper-content>

                        <v-stepper-content step="2">
                            <v-row>
                                <v-col cols="12">
                                    <v-otp-input v-model="codigoValidacion" length="6" :disabled="block"></v-otp-input>
                                    <div v-if="errorVidacion != null">
                                        <p style="color:red">{{ errorVidacion }}</p>
                                    </div>
                                    <span>No recibiste tu código <b style="font-size: small; cursor: pointer;"
                                            @click="block == false ? EnviarCodigoValidacion() : true">Reenviar
                                            codigo</b></span>
                                </v-col>
                            </v-row>
                            <v-btn color="primary" v-if="block && e1 == 2" @click="e1 = 1">
                                Regresar
                            </v-btn>

                            <v-btn color="green darken-1" text @click="ValidarCodigo()"
                                :disabled="loadValidacion || block" :loading="loadValidacion">
                                Validar
                            </v-btn>
                            <v-btn color="green darken-1" text @click="dialogWhatsapp = false"
                                :disabled="loadValidacion" :loading="loadValidacion">
                                Cerrar
                            </v-btn>
                        </v-stepper-content>
                    </v-stepper-items>
                </v-stepper>


                <!-- <v-card-actions>
                    <v-spacer></v-spacer>
                    
                </v-card-actions> -->
            </v-card>
        </v-dialog>
    </div>

</template>

<script>
import axios from "axios";
import { mapState } from "vuex";

export default {
    components: {
    },
    data() {
        return {
            loadValidacion: false,
            selectedAreaCode: null,
            phoneNumber: null,
            loading: false,
            vinculado: true,
            dialogWhatsapp: false,
            areaCodes: [],
            e1: 1,
            errorCode: false,
            codigoValidacion: null,
            validacionCode: false,
            errorVidacion: null,
            block: false
        };
    },
    computed: {
        ...mapState(["whatsapp"])
    },
    mounted() {
        this.codesNumber();
    },
    methods: {
        codesNumber() {
            axios.get('https://restcountries.com/v3.1/all')
                .then(response => {
                    var codes = response.data.map(function (country) {
                        var rootWithoutPlus = country.idd.root == undefined ? "" : country.idd.root.replace('+', '');
                        var suffix = country.idd.suffixes == undefined ? "" : country.idd.suffixes.length ? country.idd.suffixes[0] : '';
                        var fullCode = rootWithoutPlus + suffix;
                        return {
                            text: country.name.common + " (+" + fullCode + ")",
                            value: fullCode
                        };
                    });

                    codes.sort(function (a, b) {
                        if (a.text < b.text) {
                            return -1;
                        }
                        if (a.text > b.text) {
                            return 1;
                        }
                        return 0;
                    });

                    this.areaCodes = codes;
                })
                .catch(error => {
                    console.error(error);
                });
        },
        
        EnviarCodigoValidacion() {
            if (this.selectedAreaCode == null) return;
            if (this.phoneNumber == null || this.phoneNumber == "") return;

            this.loadValidacion = true;
            axios
                .get("/Whatsapp/EnviarCodigoValidacion", {
                    params: {
                        code: this.selectedAreaCode,
                        numero: this.phoneNumber
                    },
                })
                .then((response) => {
                    if (response.data == 200) {
                        this.loadValidacion = false;
                        this.e1 = 2
                        this.errorCode = false;
                    } else {
                        this.loadValidacion = false;
                        this.errorCode = true;
                    }
                })
                .catch((error) => {
                    this.loadValidacion = false;
                    console.log(error);
                });

        },
        ValidarCodigo() {
            if (this.codigoValidacion == null) return;
            this.errorVidacion = null;
            this.loadValidacion = true;
            axios
                .get("/Whatsapp/ValidarCodigo", {
                    params: {
                        codigo: this.codigoValidacion,
                    },
                })
                .then((response) => {
                    this.validacionCode = response.data;
                    this.block = true;
                    this.vinculado = true
                    this.loadValidacion = false;
                })
                .catch((error) => {
                    this.loadValidacion = false;
                    console.error(error.response.data.msg);
                    console.error(error.response.data.msg);
                    this.errorVidacion = error.response.data.msg.replace("[ MX EXCEPTION ] : ", "");
                });

        },
        InformacionUsuario() {
            this.loading = true;
            axios
                .get("/Whatsapp/InformacionUsuario")
                .then(response => {
                    console.log(response.data)
                    if (response.data.informacionUsuarios != null) {
                        this.selectedAreaCode = response.data.informacionUsuarios.codigoPais
                        this.phoneNumber = response.data.informacionUsuarios.numeroTelefono
                        this.codigoValidacion = response.data.informacionUsuarios.codigo
                    }

                    this.block = response.data.block
                    this.validacionCode = response.data.block
                    this.vinculado = response.data.block
                })
                .catch(error => {
                    console.log(error)
                })
                .finally(() => {
                    this.loading = false;
                })

        },
        reset() {
            this.InformacionUsuario();
        }
    },
};
</script>

<style></style>