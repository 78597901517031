<template>
  <v-col v-if="permisoVista('briarbol', 'r')"
  cols="12" class="pa-0"
  >
    <OrganizationChart
      id="chart"
      :datasource="datos"
      pan
      zoom
      class="customNode"
    >
      <template slot-scope="{ nodeData }">
        <v-card
          :loading="loading"
          class="mx-auto"
          min-height="250"
          max-height="250"
          min-width="200"
          max-width="200"
        >
          <v-system-bar
            :height="10"
            :color="nodeData.color != null && nodeData.color"
            class="rounded-t-lg"
          ></v-system-bar>
          <div
            class="text-center d-flex flex-column align-center justify-center"
          >
            <Avatar
              :image="nodeData.imagen == null ? false : true"
              :url="nodeData.imagen == null ? nodeData.name : nodeData.imagen"
              :name="nodeData.name"
              :key="x"
            ></Avatar>
          </div>

          <v-card-title
            class="text-center d-flex flex-column align-center justify-center"
            >{{ nodeData.name }}</v-card-title
          >

          <v-card-text>
            <div class="mt-n2 text-subtitle-1">
              {{ nodeData.title }}
            </div>
          </v-card-text>
        </v-card>
      </template>
    </OrganizationChart>
  </v-col>
</template>

<script>
import axios from "axios";
import OrganizationChart from "vue-organization-chart";
import "vue-organization-chart/dist/orgchart.css";
import { mapState } from "vuex";
import Avatar from "../../Avatar.vue";

export default {
  components: {
    OrganizationChart,
    Avatar,
  },
  data() {
    return {
      x: 0,
      datos: {
      },
      colores: [
        "red",
        "orange",
        "blue",
        "green",
        "purple",
        "pink accent-2",
        "teal",
        "cyan",
        "lime",
        "yellow",
      ],
    };
  },
  mounted() {
    this.initialize();
  },
  computed: {
    ...mapState(["nombreEmpresa", "token"]),
  },
  methods: {
    randomNumber() {
      return Math.floor(Math.random() * (1 - 100 + 1) + 100);
    },
    initialize() {
      this.getDatos();
    },
    definirColores(arreglo, indice = 0) {
      let color = this.colores[indice];
      arreglo.forEach((item) => {
        item.color = color;
        this.definirColores(item.children || [], indice + 1);
      });
      return arreglo[0];
    },
    getDatos() {
      axios
        .get("/Brigada/DiagramaBrigadasArbol")
        .then((response) => {
          response.data.ds.children = response.data.ds.children.filter(
            (ch) => ch.children.length > 0
          );
          this.datos = this.definirColores([response.data.ds]);
          this.x++;
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },
};
</script>
<style scoped>
.customNode {
  background-color: rgb(247, 247, 247);
  height: 45rem;
}
.customNode >>> .orgchart .node {
  width: min-content;
}

.orgchart-container {
  border: none;
  width: calc(100% - 0px);
}

.customNode >>> .orgchart .lines .topLine {
  border-top: 1px solid rgba(0, 0, 0, 0.5);
}
.customNode >>> .orgchart .lines .rightLine {
  border-right: 1px solid rgba(0, 0, 0, 0.5);
}
.customNode >>> .orgchart .lines .downLine {
  background-color: rgba(0, 0, 0, 0.5);
}
.customNode >>> .orgchart .lines .leftLine {
  border-left: 1px solid rgba(0, 0, 0, 0.5);
}

.customNode >>> tbody tr:nth-of-type(odd) {
  background-color: transparent;
}
</style>
