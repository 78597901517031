<template>
	<div>
		<v-tabs v-model="tab" background-color="transparent" grow>
			<v-tab @click="onClickPorArticulo"
				>Por Artículo</v-tab
			>

			<v-tab @click="onClickMovimientoEspecifico"
				>Por Movimiento Especifico</v-tab
			>

            <v-tabs-items v-model="tab" touchless>
				<v-tab-item eager>
					<IngresosMPArticulo ref="ingmparticulo" />
				</v-tab-item>
				
                <v-tab-item eager>
                    <IngresosMP ref="ingmp" />
                </v-tab-item>
            </v-tabs-items>
		</v-tabs>
	</div>
</template>

<script>
import IngresosMP from "./IngresosMP/IngresosMP.vue";
import IngresosMPArticulo from './IngresosMP/IngresosMPArticulo.vue';

export default {
	components: { IngresosMP, IngresosMPArticulo },
	data(){
		return {
			tab: 0
		}
	},
	methods: {
		initialize(){
			this.onClickPorArticulo();
		},
		onClickPorArticulo() {
			if (typeof this.$refs.ingmparticulo === "undefined") return;
			this.$refs.ingmparticulo.initialize();
		},
		onClickMovimientoEspecifico() {
			if (typeof this.$refs.ingmp === "undefined") return;
			this.$refs.ingmp.initialize();
		},
	},
};
</script>