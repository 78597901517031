<template>
	<v-col cols="12">
		<v-dialog v-model="dialog" persistent max-width="40%">
			<v-card>
				<v-card-text class="pb-0">
					<p class="text-center headline pt-4 pb-0 mb-0">
						Se ha enviado un email a su correo nuevo para confirmar el cambio
					</p>
					<lottie
						:options="checkOptions"
						:height="200"
						:width="200"
						class="py-0"
					/>
				</v-card-text>
				<v-card-actions class="pt-0">
					<v-btn color="primary" block large @click="logout"
						>Aceptar</v-btn
					>
				</v-card-actions>
			</v-card>
		</v-dialog>

		<v-form v-model="valid" ref="form" lazy-validation>
			<v-row class="d-flex justify-center">
				<v-col cols="8">
					<div class="headline">Cambiar E-mail</div>
				</v-col>
				<v-col cols="8">
					<v-text-field
						v-model="emailItem.nuevoEmail"
						prepend-icon="mdi-email"
						label="Nuevo E-mail*"
						type="text"
						required
						:rules="emailRules"
					></v-text-field>
					<p class="mt-2 mb-0">*campo requerido</p>
				</v-col>
				<v-col cols="8" class="d-flex justify-end">
					<v-btn 
                        :disabled="loadingEmail"
                        :loading="loadingEmail"
                        color="primary mx-2"
                        @click="save"
                        > Guardar </v-btn
                    >
				</v-col>
			</v-row>
		</v-form>
	</v-col>
</template>

<script>
import axios from "axios";
import Lottie from "vue-lottie";
import * as checkData from "@/assets/mail.json";

export default {
	components: {
		lottie: Lottie,
	},
	data() {
		return {
            loadingEmail: false,
			checkOptions: {
				animationData: checkData.default,
				loop: true,
				speeed: 1,
			},
			dialog: false,
			show1: false,
			show2: false,
			valid: false,
			emailItem: {
				anteriorPassword: "",
				nuevoEmail: "",
			},
			defaultEmailItem: {
				anteriorPassword: "",
				nuevoEmail: "",
			},
            emailRules: [
                v => !!v || 'E-mail requerido',
                v => /.+@.+/.test(v) || 'E-mail no valido',
            ],
		};
	},
	methods: {
		save() {
            this.loadingEmail = true;
            if ( !this.validEmail() ){
                this.loadingEmail = false;
                return;
            }
			if (!this.valid){
                this.loadingEmail = false;
                return;
            }
			axios
				.put("/Usuarios/CambioCorreoSolicitud", {
					email: this.emailItem.nuevoEmail,
				})
				.then(() => {
					this.dialog = true;
					this.reset();
				})
				.catch((error) => {
                    this.loadingEmail = false;
					console.log(error);
				});
		},
		reset() {
            this.loadingEmail = false;
			this.emailItem = Object.assign({}, this.defaultEmailItem);
		},
        validEmail(){
            if ( 
                !!this.emailItem.nuevoEmail 
                && /.+@.+/.test(this.emailItem.nuevoEmail) 
            )
                return true;
            else return false;
        },
		logout() {
            this.loadingEmail = false;
			this.$store.dispatch("logout").then(() => {
				this.$router.push("/");
			});
		},
	},
};
</script>