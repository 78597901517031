<template>
  <v-col cols="12">
    <v-dialog
      v-model="dialogWatch"
      max-width="1200px"
      persistent
    >
      <v-card>
        <v-card-title>
          {{watchedItem.articulo}}
        </v-card-title>
        <v-card-text>
          <v-data-table
            :items="watchedItem.detalles"
            :headers="headersDetalles"
            :items-per-page="-1"
            hide-default-footer
          >
            <template v-slot:item="{ item }">
              <tr>
                <td class="text-center">{{item.clave}}</td>
                <td :class="'text-center justify-center '+(item.faltantes.length == 0?'green  white--text':'yellow black--text')">
                  <div v-if="item.faltantes.length == 0">
                    Disponible
                  </div>
                  <div v-else>
                    <v-tooltip bottom color="black" class="white--text">
                      <template v-slot:activator="{ on }">
                        <span class="white--text" v-on="on">Insuficiente</span>
                      </template>
                      <span class="white--text text-left">
                        <ul>
                          <li v-for="(msg, idx) in item.faltantes" :key="'faltante-'+idx">{{msg}}</li>
                        </ul>
                      </span>
                    </v-tooltip>
                  </div>
                </td>
                <td class="text-center">{{item.cantidad.toFixed(2)}}</td>
                <td class="text-center">{{(item.cliente != null)?item.cliente:nombreEmpresa}}</td>
                <td class="text-center">{{item.rfc}}</td>
                <td class="text-center">{{item.comercialLinea}}</td>
                <td :class="'text-center '+colors[item.estadoProduccion]+' '+textcolors2[item.estadoProduccion]">
                  <div v-if="item.estadoProduccion == 0">
                    <v-icon small>mdi-pause</v-icon>No Iniciado
                    <br>
                    <a href="#" class="blue--text" @click="launchItem(watchedItem.idArticulo,[item.idTrabajoOrdenDetalle])">
                      <v-icon class="blue--text">mdi-rocket</v-icon>
                      Iniciar Producción
                    </a>
                  </div>
                  <div v-if="item.estadoProduccion == 1"><v-icon color="white" small>mdi-hammer</v-icon>&nbsp;En Progreso</div>
                  <div v-if="item.estadoProduccion == 2"><v-icon class="white--text" small>mdi-alert</v-icon>&nbsp;Pausado</div>
                  <div v-if="item.estadoProduccion == 3"><v-icon class="white--text" small>mdi-check</v-icon>&nbsp;Completado</div>
                  <div v-if="item.estadoProduccion == 4"><v-icon class="white--text" small>mdi-circle-half-full</v-icon>&nbsp;Producción Parcial</div>

                </td>
                <td class="text-center" >
                  <v-tooltip
                    color="black" class="white--text"
                    v-if="item.estadoProduccion == 1"    
                     bottom
                  >
                    <template v-slot:activator="{ on }">
                      <v-icon v-on="on"
                        v-if="permisoVista('listas','u')"
                        small
                        @click="pauseItem(watchedItem.idArticulo,[item.idTrabajoOrdenDetalle])"
                      >
                        mdi-pause
                      </v-icon>
                    </template>
                    <span class="white--text">Pausar</span>
                  </v-tooltip>


                  <v-tooltip
                    color="black" class="white--text"
                    v-if="item.estadoProduccion == 2"
                     bottom
                  >
                    <template v-slot:activator="{ on }">
                      <v-icon v-on="on"
                        v-if="permisoVista('listas','u')"
                        small
                        @click="resumeItem(watchedItem.idArticulo,[item.idTrabajoOrdenDetalle])"
                      >
                        mdi-play
                      </v-icon>                    
                    </template>
                    <span class="white--text">Reanudar</span>
                  </v-tooltip>
                </td>
              </tr>
            </template>
          </v-data-table>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="green" text @click="closeWatch">Cerrar</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="dialogProducir"
      max-width="800px"
      persistent
    >
      <v-card>
        <v-card-title></v-card-title>
        <v-card-text class="text-center">
          <h3>¿Desea Iniciar la Producción de {{watchedItem.articulo}} de las siguiente OTS?</h3>
          <div v-for="(clave,idx) in claves" :key="'clave-'+idx" class="text-h6">
            {{clave}}
          </div>
          <v-row v-if="launchState == 0">
            <v-col v-if="faltaMP" cols="12" md="12" sm="12" class="red--text">
              <h4>Cantidad Insuficiente de Materia Prima para garantizar el cumplimiento de la ODT.</h4>
            </v-col>
            <v-col cols="6" md="6" sm="6">
              <v-btn depressed color="green" @click="launchProduccion()">Si</v-btn>
            </v-col>
            <v-col cols="6" md="6" sm="6">
              <v-btn depressed color="red" @click="closeLaunch()">No</v-btn>
            </v-col>
          </v-row>
          <v-row v-if="launchState == 1" class="justify-center">
            <v-col cols="12" md="12" sm="12">
              <h4>Cargando</h4>
            </v-col>
            <v-col cols="12" md="12" sm="12">
              <v-progress-circular
                :size="75"
                color="green"
                indeterminate
              ></v-progress-circular>
            </v-col>
          </v-row>
          <v-row v-if="launchState == 2" class="justify-center">
            <v-col cols="12" md="12" sm="12">
              <h5>Se ha producido un Error. Intente una vez más.</h5>
            </v-col>
            <v-col cols="12" md="12" sm="12">
              <v-btn depressed color="red" text @click="closeLaunch()">Cerrar</v-btn>
            </v-col>
          </v-row>
          <v-row v-if="launchState == 3" class="justify-center">
            <v-col class="text-center">
              <lottie :options="checkOptions" :height="200" :width="200"/>
            </v-col>
            <v-col cols="12" md="12" sm="12">
              <h5>La Producción del Artículo {{watchedItem.articulo}} se ha agregado a Producción Correctamente.</h5>
            </v-col>
            <v-col cols="12" md="12" sm="12">
              <v-btn depressed color="green" text @click="closeLaunch()">Aceptar</v-btn>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="dialogPause"
      max-width="800px"
      persistent
    >
      <v-card>
        <v-card-title></v-card-title>
        <v-card-text class="text-center">
          <h3>¿Desea Pausar la Producción de {{watchedItem.articulo}} de las siguiente OTS?</h3>
          <div v-for="(clave,idx) in claves" :key="'clave-'+idx" class="text-h6">
            {{clave}}
          </div>
          <v-row v-if="pauseState == 0">
            <v-col cols="6" md="6" sm="6">
              <v-btn depressed color="green" @click="launchPause()">Si</v-btn>
            </v-col>
            <v-col cols="6" md="6" sm="6">
              <v-btn depressed color="red" @click="closePause()">No</v-btn>
            </v-col>
          </v-row>
          <v-row v-if="pauseState == 1" class="justify-center">
            <v-col cols="12" md="12" sm="12">
              <h4>Cargando</h4>
            </v-col>
            <v-col cols="12" md="12" sm="12">
              <v-progress-circular
                :size="75"
                color="green"
                indeterminate
              ></v-progress-circular>
            </v-col>
          </v-row>
          <v-row v-if="pauseState == 2" class="justify-center">
            <v-col cols="12" md="12" sm="12">
              <h5>Se ha producido un Error. Intente una vez más.</h5>
            </v-col>
            <v-col cols="12" md="12" sm="12">
              <v-btn depressed color="red" text @click="closePause()">Cerrar</v-btn>
            </v-col>
          </v-row>
          <v-row v-if="pauseState == 3" class="justify-center">
            <v-col class="text-center">
              <lottie :options="checkOptions" :height="200" :width="200"/>
            </v-col>
            <v-col cols="12" md="12" sm="12">
              <h5>La Producción del Artículo {{watchedItem.articulo}} se ha Pausado Correctamente.</h5>
            </v-col>
            <v-col cols="12" md="12" sm="12">
              <v-btn depressed color="green" text @click="closePause()">Aceptar</v-btn>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="dialogResume"
      max-width="800px"
      persistent
    >
      <v-card>
        <v-card-title></v-card-title>
        <v-card-text class="text-center">
          <h3>¿Desea Reanudar la Producción de {{watchedItem.articulo}} de las siguiente OTS?</h3>
          <div v-for="(clave,idx) in claves" :key="'clave-'+idx" class="text-h6">
            {{clave}}
          </div>
          <v-row v-if="resumeState == 0">
            <v-col cols="6" md="6" sm="6">
              <v-btn depressed color="green" @click="launchResume()">Si</v-btn>
            </v-col>
            <v-col cols="6" md="6" sm="6">
              <v-btn depressed color="red" @click="closeResume()">No</v-btn>
            </v-col>
          </v-row>
          <v-row v-if="resumeState == 1" class="justify-center">
            <v-col cols="12" md="12" sm="12">
              <h4>Cargando</h4>
            </v-col>
            <v-col cols="12" md="12" sm="12">
              <v-progress-circular
                :size="75"
                color="green"
                indeterminate
              ></v-progress-circular>
            </v-col>
          </v-row>
          <v-row v-if="resumeState == 2" class="justify-center">
            <v-col cols="12" md="12" sm="12">
              <h5>Se ha producido un Error. Intente una vez más.</h5>
            </v-col>
            <v-col cols="12" md="12" sm="12">
              <v-btn depressed color="red" text @click="closeResume()">Cerrar</v-btn>
            </v-col>
          </v-row>
          <v-row v-if="resumeState == 3" class="justify-center">
            <v-col class="text-center">
              <lottie :options="checkOptions" :height="200" :width="200"/>
            </v-col>
            <v-col cols="12" md="12" sm="12">
              <h5>La Producción del Artículo {{watchedItem.articulo}} se ha Reanudado Correctamente.</h5>
            </v-col>
            <v-col cols="12" md="12" sm="12">
              <v-btn depressed color="green" text @click="closeResume()">Aceptar</v-btn>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-data-table
      :items="articulos"
      :headers="headers"
      :loading="loading"
    >
      <template v-slot:item="{ item }">
        <tr>
          <td class="text-center">
            {{item.codigo}}
          </td>
          <td class="text-center">
            {{item.articulo}}
          </td>
          <td class="text-center">
            {{item.cantidad.toFixed(2)}}
          </td>
          <td class="ma-0 pa-0">
            <v-row 
              v-for="(estado,idx) in item.estados" 
              :key="'estado-'+idx" 
              :style="'min-height:30px !important; width: 100%; height: '+widthDinamic(item.estados.length)+';'"
              :class="'hand-mouse ma-0 pa-1 '+colors[estado.estado]+' '+textcolors[estado.estado]+'--text'"
              @click="selectAllType(item, estado.estado)"
            >
              <v-col cols="12" md="12" sm="12" class="ma-0 pa-0 align-self-center text-center">
                <v-icon small :color="textcolors[estado.estado]">{{icons[estado.estado]}}</v-icon> {{estado.cantidad}} de {{totalEstados(item.estados)}} {{states[estado.estado]}}
              </v-col>
            </v-row>
          </td>
          <td>
            <v-row class="pa-0 ma-0">
              <v-tooltip color="black" class="white--text" top v-for="(detalle, idx) in item.detalles" :key="'detalle-'+idx">
                <template v-slot:activator="{ on, attrs }">
                  <div
                    v-bind="attrs"
                    v-on="on"
                  >
                    <v-checkbox
                      v-model="item.seleccion"
                      hide-details
                      :value="detalle.idTrabajoOrdenDetalle"
                      :class="'shrink mr-2 mt-0 '"
                    >
                      <template v-slot:label>
                        <div :class="colors[detalle.estadoProduccion]+'--text'">
                          {{detalle.clave}}
                        </div>
                      </template>
                    </v-checkbox>
                  </div>
                </template>
                <div class="white--text">
                  <strong>Clave:</strong> {{detalle.clave}}
                  <br>
                  <strong>Cantidad:</strong> {{detalle.cantidad.toFixed(2)}}
                  <br>
                  <strong>Estado:</strong> {{ $utils.strings.partialMayus(states[detalle.estadoProduccion],0,1)}}
                  <br>
                  <strong v-if="detalle.cliente != null">Cliente:</strong> {{ detalle.cliente }}
                  <br v-if="detalle.cliente != null">
                  <strong v-if="detalle.rfc != null">RFC:</strong> {{ detalle.rfc }}
                  <br v-if="detalle.rfc != null">
                  <strong>Línea Comercial:</strong> {{ detalle.comercialLinea }}
                  <br>
                </div>
              </v-tooltip>
            </v-row>  
          </td>
          <td class="text-center">
            <v-tooltip
              color="black" class="white--text"
              v-if="getTypeSelection(item) == 0"
               bottom
            >
              <template v-slot:activator="{ on }">
                <v-icon v-on="on"
                  small
                  @click="lauchMultiple(item)"
                >
                  mdi-rocket
                </v-icon>
              </template>
              <span class="white--text">Lanzar a Producción</span>
            </v-tooltip>

            <v-tooltip
              color="black" class="white--text"
              v-if="getTypeSelection(item) == 2"
               bottom
            >
              <template v-slot:activator="{ on }">
                <v-icon v-on="on"
                  v-if="permisoVista('listas','u')"
                  small
                  @click="resumeMultiple(item)"
                >
                  mdi-play
                </v-icon>
              </template>
              <span class="white--text">Reanudar</span>
            </v-tooltip>

            <v-tooltip
              color="black" class="white--text"
              v-if="getTypeSelection(item) == 1"
               bottom
            >
              <template v-slot:activator="{ on }">
                <v-icon v-on="on"
                  v-if="permisoVista('listas','u')"
                  small
                  @click="pauseMultiple(item)"
                >
                  mdi-pause
                </v-icon>
              </template>
              <span class="white--text">Pausar</span>
            </v-tooltip>

            <v-tooltip
              color="black" class="white--text"
               bottom
            >
              <template v-slot:activator="{ on }">
                <v-icon v-on="on"
                  small
                  @click="unselectAll(item)"
                >
                  mdi-checkbox-blank-off-outline
                </v-icon>
              </template>
              <span class="white--text">Deseleccionar</span>
            </v-tooltip>

            <v-tooltip
              v-if="permisoVista('listas','r')"
              color="black" class="white--text"
               bottom
            >
              <template v-slot:activator="{ on }">
                <v-icon v-on="on"
                  small
                  @click="watchIndex(item)"
                >
                  mdi-eye
                </v-icon>
              </template>
              <span class="white--text">Ver detalles</span>
            </v-tooltip>
          </td>
        </tr>
      </template>
    </v-data-table>
  </v-col>
</template>

<script>
import axios from "axios";
import { mapState } from 'vuex';
import Lottie from 'vue-lottie';
import * as checkData from '@/assets/check.json';


export default {
  components:{
    'lottie': Lottie
  },
  data: () => ({
    checkOptions: {animationData: checkData.default, loop:false, speeed: 0.5},
    colors:['','orange','red','green','blue'],
    textcolors:['blue','white','white','white','white'],
    textcolors2:['','white--text','white--text','white--text','white--text'],
    states:['sin Iniciar','en Progreso', 'en Pausa', 'Completado', 'Producción Parcial'],
    icons:['mdi-rocket','mdi-hammer','mdi-alert','mdi-check','mdi-circle-half-full'],
    articulos:[],
    loading:false,
    headers:[
      {
        text: 'Código',
        align: 'center',
        sortable: false,
        value: 'codigo',
        width: '10%',
      },
      {
        text: 'Artículo',
        align: 'center',
        sortable: false,
        value: 'articulo',
        width: '20%',
      },
      {
        text: 'Cantidad',
        align: 'center',
        sortable: false,
        value: 'cantidad',
        width: '8%',
      },
      {
        text: 'Estados',
        align: 'center',
        sortable: false,
        value: 'estados',
        width: '10%',
      },
      {
        text: 'Detalles',
        align: 'center',
        sortable: false,
        value: 'detalles',
      },
      {
        text: 'Acciones',
        align: 'center',
        sortable: false,
        value: 'actions',
        width: '10%'
      },
    ],
    headersDetalles:[
      {
        text: 'Código',
        align: 'center',
        sortable: false,
        value: 'codigo',
        width: '13%',
      },
      {
        text: 'M.P.',
        align: 'center',
        sortable: false,
        value: 'faltantes',
        width: '13%',
      },
      {
        text: 'Cantidad',
        align: 'center',
        sortable: false,
        value: 'cantidad',
        width: '13%',
      },
      {
        text: 'Cliente',
        align: 'center',
        sortable: false,
        value: 'cliente',
        width: '13%',
      },
      {
        text: 'RFC',
        align: 'center',
        sortable: false,
        value: 'rfc',
        width: '13%',
      },
      {
        text: 'Línea Comercial',
        align: 'center',
        sortable: false,
        value: 'comercialLinea',
        width: '13%',
      },
      {
        text: 'Estado',
        align: 'center',
        sortable: false,
        value: 'estadoProduccion',
        width: '13%',
      },
      {
        text: 'Acciones',
        align: 'center',
        sortable: false,
        value: 'actions',
        width: '8%',
      },
    ],
    watchedIndex:-1,
    defaultItem:{
      articulo: "",
      cantidad: 0,
      codigo: "",
      detalles: [],
      estados: [],
      idArticulo: -1,
      seleccion: [],
    },
    idsTrabajoOrdenDetalles:[],
    idArticulo:-1,
    dialogProducir: false,
    launchState: 0,
    dialogPause: false,
    pauseState: 0,
    dialogResume: false,
    resumeState: 0,
  }),
  created(){
    this.initialize();
  },
  computed: {
    watchedItem(){
      if(this.watchedIndex < 0) return this.defaultItem;
      else return this.articulos[this.watchedIndex];
    },
    faltaMP(){
      var idx = this.articulos.findIndex(a => a.idArticulo == this.idArticulo);
      if(idx >= 0){
        var result = this.articulos[idx].detalles.filter(detalle => {
          return (this.idsTrabajoOrdenDetalles.indexOf(detalle.idTrabajoOrdenDetalle) >= 0);
        })
        .map(detalle => {
          return (detalle.faltantes != null && detalle.faltantes.length > 0)?1:0;
        }).reduce((a, b) => a + b, 0);
        return result > 0;
      }
      else return false;
    },
    claves(){
      if(this.idArticulo <= 0) return [];
      else{
        var idx = this.articulos.findIndex(a => a.idArticulo == this.idArticulo);
        if(idx < 0) return [];
        else{
          return this.articulos[idx].detalles.filter(detalle => {
            return this.idsTrabajoOrdenDetalles.indexOf(detalle.idTrabajoOrdenDetalle) >= 0;
          })
          .map(detalle => {
            return detalle.clave;
          });
        }
      }
    },
    ...mapState({
      nombreEmpresa: 'nombreEmpresa',
    }),
  },
  methods:{
    initialize(){
      this.getArticulosOrdenes();
    },
    widthDinamic(count){
      var x = (count <= 0)?100:(100 / count);
      return x.toFixed(2)+'%';
    },
    totalEstados(estados){
      var cantidad = 0;
      estados.forEach(estado => {
        cantidad += estado.cantidad;
      });
      return cantidad;
    },
    selectAllType(item,estadoProduccion){
      var idsTrabajoOrdenDetalles = item.detalles.filter(detalle => {
        return detalle.estadoProduccion == estadoProduccion;
      })
      .map(detalle => {
        return detalle.idTrabajoOrdenDetalle;
      });

      var idx = this.articulos.indexOf(item);
      if(idx >= 0){
        this.articulos[idx].seleccion = idsTrabajoOrdenDetalles;
      }
    },
    unselectAll(item){
      var idx = this.articulos.indexOf(item);
      if(idx >= 0){
        this.articulos[idx].seleccion = [];
      }
    },
    getTypeSelection(item){
      if(item.seleccion.length == 0) return -1;
      var detalles = item.detalles.filter(detalle => {
        return  item.seleccion.indexOf(detalle.idTrabajoOrdenDetalle) >= 0;
      })
      .map(detalle => {
        return {
          idTrabajoOrdenDetalle: detalle.idTrabajoOrdenDetalle,
          estadoProduccion: detalle.estadoProduccion,
        };
      });
      
      var result = this.$utils.arrays.groupBy(detalles,'estadoProduccion');
      if(result.length > 1) return -1;
      else return result[0].key;
    },
    getArticulosOrdenes(){
      this.loading = true;
      axios
      .get("/TrabajoOrdenes/Articulos")
      .then(response => {
        this.articulos = response.data.map(a => {
          a.seleccion = [];
          return a;
        });
        this.loading = false;
      })
      .catch(error => {
        console.log(error);
        this.loading = false;
      })
    },
    watchIndex(item){
      this.watchedIndex = this.articulos.indexOf(item);
      this.dialogWatch = true;
    },
    closeWatch(){
      this.watchedIndex = -1;
      this.dialogWatch = false;
    },
    lauchMultiple(item){
      this.launchItem(item.idArticulo,item.seleccion);
    },
    resumeMultiple(item){
      this.resumeItem(item.idArticulo,item.seleccion);
    },
    pauseMultiple(item){
      this.pauseItem(item.idArticulo,item.seleccion);
    },  
    launchItem(idArticulo,idsTrabajoOrdenDetalles){
      this.dialogProducir = true;
      this.idArticulo = idArticulo;
      this.idsTrabajoOrdenDetalles = idsTrabajoOrdenDetalles;
    },
    pauseItem(idArticulo,idsTrabajoOrdenDetalles){
      this.dialogPause = true;
      this.idArticulo = idArticulo;
      this.idsTrabajoOrdenDetalles = idsTrabajoOrdenDetalles;
    },
    resumeItem(idArticulo,idsTrabajoOrdenDetalles){
      this.dialogResume = true;
      this.idArticulo = idArticulo;
      this.idsTrabajoOrdenDetalles = idsTrabajoOrdenDetalles;
    },
    genParameters(){
      var detalles = "";
      this.idsTrabajoOrdenDetalles.forEach((ot,idx) => {
        if(idx != 0) detalles += "&";
        detalles += ("IdsTrabajoOrdenDetalles="+ot);
      })
      return detalles;
    },
    launchProduccion(){     
      this.launchState = 1;
      axios
      .post("/TrabajoOrdenes/ProducirMultiples?"+this.genParameters())
      .then(() => {
        this.launchState = 3;
        this.getArticulosOrdenes();
      })
      .catch(error => {
        this.launchState = 2;
        console.log(error);
      })
    },
    launchPause(){     
      this.pauseState = 1;
      axios
      .put("/TrabajoOrdenes/PausarMultiples?"+this.genParameters())
      .then(() => {
        this.pauseState = 3;
        this.getArticulosOrdenes();
      })
      .catch(error => {
        this.pauseState = 2;
        console.log(error);
      })
    },
    launchResume(){     
      this.resumeState = 1;
      axios
      .put("/TrabajoOrdenes/ReanudarMultiples?"+this.genParameters())
      .then(() => {
        this.resumeState = 3;
        this.getArticulosOrdenes();
      })
      .catch(error => {
        this.resumeState = 2;
        console.log(error);
      })
    },
    closeLaunch(){
      this.dialogProducir = false;
      this.launchState = 0;
      this.closeDialog();
    },
    closePause(){
      this.dialogPause = false;
      this.pauseState = 0;
      this.closeDialog();
    },
    closeResume(){
      this.dialogResume = false;
      this.resumeState = 0;
      this.closeDialog();
    },
    closeDialog(){
      this.idArticulo = -1;
      this.idsTrabajoOrdenDetalles = [];
    }
  }
}
</script>

<style scoped>
.hand-mouse{
  cursor:pointer;
  height:42px;
  width:42px;
}
</style>