<template>
  <v-col cols="12">
    <v-tabs vertical v-if="permisoVista('recetas','r')">
      <v-tab v-html="tipo.nombre.replace(' ','<br>')" v-for="tipo in tipos" :key="tipo.idArticuloTipo" @click="updateReceta(tipo.idArticuloTipo)">
      </v-tab>
      <v-tab-item v-for="tipo in tipos" :key="tipo.idArticuloTipo">
        <subreceta :name="tipo.nombre" :number="tipo.idArticuloTipo" :ref="'receta'+tipo.idArticuloTipo"></subreceta>
      </v-tab-item>
    </v-tabs>
  </v-col>
</template>

<script>
import axios from "axios";
import subreceta from "./SubReceta/Receta"

  export default {
    components: {
      subreceta
    },
    data: () => ({
      tipos: [],
      loading: false,
    }),
    beforeMount(){
      this.initialize();
    },
    methods: {
      initialize(){
        let me = this;
        axios
        .get("/ArticuloTipos")
        .then(response => {
          me.tipos = response.data.filter(function(element){
            return (element.idArticuloTipo == 2 || element.idArticuloTipo == 3 || element.receta == true);
          }).sort(function(a,b) {
            return a.posicion - b.posicion;
          })

          if(this.tipos.length > 0){
            if(this.tipos[0]){
              this.updateReceta(this.tipos[0].idArticuloTipo);
            }
          }
        })
        .catch(error =>{
          console.log(error);
        })
      },
      updateReceta(idArticuloTipo){
        if (typeof this.$refs['receta'+idArticuloTipo] === 'undefined' || this.$refs['receta'+idArticuloTipo].length < 1) return;
        this.$refs['receta'+idArticuloTipo][0].initialize();
      }
    }
  }
</script>