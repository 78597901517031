<template>
	<v-card class="flex d-flex flex-column" style="width: 18.75rem">
		<signal-r
      path="/hub/mqtt"
			@change="acciones"
    ></signal-r>
		<div
			class="text-center white--text rounded-t"
			:class="`${color} darken-1`"
		>
			<div class="d-flex align-center justify-space-between">
				<v-btn
					class="ma-0"
					icon
					color="white"
					@click="$emit('clickAlert', instancia)"
					><v-icon>{{
						instancia.notificacion ? "mdi-bell" : "mdi-bell-off"
					}}</v-icon></v-btn
				>
				<v-btn
					class="ma-0"
					icon
					color="white"
					@click="$emit('clickConfig', instancia)"
					><v-icon>mdi-cog</v-icon></v-btn
				>
			</div>
			<p
				class="mb-0 text-h4 font-weight-medium"
				style="
					font-size: 1.75rem !important;
					line-height: 2rem !important;
				"
			>
				{{ instancia.nombre }}
			</p>
			<div class="pt-1" style="min-height: 7px">
				<v-progress-linear
					v-if="loadingData"
					color="white"
					indeterminate
					rounded
					height="3"
				></v-progress-linear>
			</div>
		</div>
		<div :class="`${color} darken-3`" style="min-height: 13.4375rem">
			<div class="d-flex justify-center align-center pa-0">
				<div
					class="white--text text-center"
					style="padding-top: 5.125rem !important"
				>
					<ICountUp
						:endVal="porcentaje"
						:key="contadorKey"
						class="text-h4 font-weight-bold"
						:options="{ suffix: '%' }"
					/>
				</div>
				<div class="apex-radial-custom-wrap">
					<apexchart
						type="radialBar"
						height="250"
						:key="graficaRadialKey"
						:options="instancia.chartOptions"
						:series="[porcentajeEquivalente]"
					></apexchart>
				</div>
			</div>
			<div
				class="d-flex align-end justify-center"
				style="padding-top: 4.6875rem !important"
			>
				<p class="mb-0 white--text text-subtitle-1 font-weight-bold">
					Prom: {{ Math.floor(promedio) }}
				</p>
				<p
					class="
						mb-0
						mx-2
						white--text
						text-subtitle-1
						font-weight-bold
					"
				>
					Min: {{ Math.floor(minimo) }}
				</p>
				<p class="mb-0 white--text text-subtitle-1 font-weight-bold">
					Max: {{ Math.floor(maximo) }}
				</p>
			</div>
		</div>
		<div class="flex white--text pa-0" :class="`${color} darken-1`">
			<v-container fluid class="pa-0">
				<div class="apex-lineal-custom-wrap">
					<apexchart
						class="chartLinealContainer"
						type="line"
						height="260"
						:key="linealChartKey"
						:options="instancia.linealChartOptions"
						:series="instancia.linealSeries"
					></apexchart>
					<p
						class="
							mb-0
							text-subtitle-1 text-center
							white--text
							font-weight-bold
							sublabel-lineal-chart
						"
					>
						{{ ultimosMensaje }}
					</p>
					<div class="px-2">
						<v-slider
							v-model="salto"
							:min="0"
							:max="10"
							step="1"
							ticks="always"
							tick-size="4"
							color="white"
							hide-details
							@change="getDatosGraficaLineal"
        				></v-slider>
						<p class="mb-1 text-subtitle-1 text-center white--text font-weight-bold">Nivel de detalle</p>
					</div>
				</div>
			</v-container>
		</div>
	</v-card>
</template>

<script>
import ICountUp from "vue-countup-v2";
import axios from "axios";
import SignalR from '@/components/SignalR.vue';

export default {
	components: {
		ICountUp,
		SignalR
	},
	props: {
		instancia: {
			type: Object,
			required: true,
		},
		color: {
			type: String,
			default: "blue",
		},
		idMaquinaIns: {
			type: Number,
			default: 0,
		},
	},
	data() {
		return {
			linealChartKey: 0,
			maximo: 0,
			minimo: 0,
			porcentaje: 0,
			porcentajeEquivalente: 0,
			promedio: 0,
			contadorKey: 0,
			graficaRadialKey: 0,
			loadingUltimoValor: false,
			loadingGraficaLineal: false,
			tiempo: 0,
			salto: 1,
		};
	},
	beforeMount() {
		this.intialize();
	},
	computed: {
		loadingData(){
			return this.loadingUltimoValor || this.loadingGraficaLineal;
		},
		ultimosMensaje(){
			if(this.tiempo <= 0) return "Cargando ...";
			if(this.tiempo < 60) return `Ultimos ${parseInt(this.tiempo)} Segundos`
			else return `Ultimos ${parseInt(this.tiempo / 60)} Minutos`
		}
	},
	methods: {
		acciones(event){
			if(event.toLowerCase().split('-')[0] == 'idmaquinainstanciagraficadinamica' && parseInt(event.toLowerCase().split('-')[1]) == this.instancia.idMaquinaInstanciaGraficaDinamica){
				this.getUltimoValor();
				this.getDatosGraficaLineal();
			}
		},
		intialize() {
			this.getDatosGraficaLineal();
			this.getUltimoValor();
		},
		getUltimoValor() {
			this.loadingUltimoValor = true;

			axios
				.get("/GraficaDinamica/UltimoValor", {
					params: {
						idMaquinaIns: this.idMaquinaIns,
						idMaquinaInstanciaGraficaDinamica:
							this.instancia.idMaquinaInstanciaGraficaDinamica,
					},
				})
				.then((response) => {
					this.porcentaje = response.data.porcentaje;
					this.porcentajeEquivalente =
						response.data.porcentaje * 0.75;
					this.instancia.chartOptions.fill.type =
						this.porcentajeEquivalente < 30 ? "solid" : "gradient";
					this.graficaRadialKey++;
				})
				.catch(console.log)
				.finally( () => {
					this.loadingUltimoValor = false;
				})
		},
		getDatosGraficaLineal() {
			this.loadingGraficaLineal = true;

			axios
				.get("/GraficaDinamica/DatosUltimosMinutos", {
					params: {
						idMaquinaIns: this.idMaquinaIns,
						idMaquinaInstanciaGraficaDinamica:
							this.instancia.idMaquinaInstanciaGraficaDinamica,
						limite: 25,
						minutos: 5,
						salto: this.salto
					},
				})
				.then((response) => {
					this.instancia.linealChartOptions.xaxis.type='datetime';
					this.instancia.linealChartOptions.xaxis.labels.datetimeUTC = false;
					this.instancia.linealChartOptions.xaxis.categories = response.data.categories;
					this.instancia.linealSeries = [
						{
							name: this.instancia.nombre,
							data: response.data.series,
						},
					];
					this.instancia.linealChartOptions.tooltip = {
						y: {
							formatter: (seriesName) =>
								`${seriesName} ${
									this.instancia.prefijo || ""
								}`.trim(),
						},
					};
					this.instancia.linealChartOptions.yaxis.title.text =
						this.instancia.sufijo?.trim().toLowerCase();

					this.maximo = response.data.maximo;
					this.minimo = response.data.minimo;
					this.promedio = response.data.promedio;

					this.tiempo = response.data.segundos;

					this.linealChartKey++;
				})
				.catch(console.log)
				.finally( () => {
					this.loadingGraficaLineal = false;
				})
		},
	},
};
</script>

<style scoped>
.apex-radial-custom-wrap {
	position: absolute;
	top: 4rem;
}

.label-radial-chart {
	position: absolute;
	left: 7.5rem;
	top: 8.125rem;
}

.chartLinealContainer >>> .apexcharts-tooltip {
	color: #000000;
}

.chartLinealContainer >>> .apexcharts-tooltip-series-group.active {
	background: #ffffff !important;
}

.sublabel-lineal-chart {
	position: relative;
	/* left: 5.25rem; */
	bottom: 0.625rem;
}

.chartLinealContainer >>> .apexcharts-tooltip-marker {
	background-color: #1e88e5 !important;
}

.chartLinealContainer >>> .apexcharts-yaxis-title-text {
	font-size: 0.75rem !important;
	font-weight: 400;
	line-height: 1.75rem;
	letter-spacing: 0.009375em !important;
	font-family: Roboto, sans-serif !important;
}
</style>