import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VSimpleTable } from 'vuetify/lib/components/VDataTable';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VDialog,{attrs:{"max-width":"700"},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c(VCard,[_c(VCardTitle,{staticClass:"text-center"},[_vm._v("Mostrar / Ocultar Columnas")]),_c(VCardText,[_c(VSimpleTable,{attrs:{"height":"300px"},scopedSlots:_vm._u([{key:"default",fn:function(){return [_c('thead',[_c('tr',[_c('th',{staticClass:"text-center",staticStyle:{"font-weight":"bold","font-size":"15px"}},[_vm._v(" Columnas ")]),_c('th',{staticClass:"text-center",staticStyle:{"font-weight":"bold","font-size":"15px"}},[_vm._v(" Estado ")])])]),_c('tbody',_vm._l((_vm.headers),function(header){return _c('tr',{key:header.text,staticClass:"text-center"},[(header.text !== 'Acciones')?[_c('td',[_vm._v(_vm._s(header.text))]),_c('td',[(!_vm.hiddenHeaders.includes(header))?_c(VIcon,{on:{"click":function($event){return _vm.visible_off(header)}}},[_vm._v("mdi-eye-outline")]):_vm._e(),(_vm.hiddenHeaders.includes(header))?_c(VIcon,{on:{"click":function($event){return _vm.visible_on(header)}}},[_vm._v("mdi-eye-off-outline")]):_vm._e()],1)]:_vm._e()],2)}),0)]},proxy:true}])})],1),_c(VCardActions,[_c(VBtn,{staticClass:"ml-auto",attrs:{"text":"","color":"primary"},on:{"click":function($event){return _vm.showAll();}}},[_vm._v("Mostrar Todos")]),_c(VBtn,{attrs:{"text":"","color":"success"},on:{"click":function($event){return _vm.hide_dialog();}}},[_vm._v("Cancelar")]),_c(VBtn,{attrs:{"text":"","color":"error"},on:{"click":function($event){return _vm.update_headers();}}},[_vm._v("Actualizar")])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }