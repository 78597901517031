<template>
  <v-col cols="12">
    <v-row>
      <v-col cols="4" md="4" sm="4" class="align-self-end">
        <datePicker
          v-model="inicio"
          format="YYYY-MM-DD"
          :maxDate="fin"
          label="Desde (Obligatorio)"
          clearable
          maxToday
          @input="getOperadores();getEstadisticas();resetPersonas()"
        ></datePicker>
      </v-col>
      <v-col cols="4" md="4" sm="4" class="align-self-end">
        <datePicker
          v-model="fin"
          format="YYYY-MM-DD"
          :minDate="inicio"
          label="Hasta (Obligatorio)"
          clearable
          maxToday
          @input="getOperadores();getEstadisticas();resetPersonas()"
        ></datePicker>
      </v-col>
      <v-col cols="4" md="4" sm="4">
        <v-autocomplete
          v-model="idsPersonas"
          label="Operador(es) (Obligatorio)"
          :items="personas"
          item-value="idPersona"
          :item-text="nombrePersona"
          hide-details
          multiple
          clearable
          chips
          deletable-chips
          @change="getEstadisticas"
          :loading="loadingOperador"
        ></v-autocomplete>
      </v-col>
      <v-col cols="6" md="6" sm="6" class="align-self-end">
        <v-autocomplete
          v-model="idArea"
          :items="areas"
          item-text="nombre"
          item-value="idArea"
          label="Área (Opcional)"
          clearable
          hide-details
          @change="getEstadisticas"
        ></v-autocomplete>
      </v-col>
      <v-col cols="6" md="6" sm="6" class="align-self-end">
        <v-autocomplete
          v-model="idTurno"
          :items="turnos"
          item-text="nombre"
          item-value="idTurno"
          label="Turno (Opcional)"
          clearable
          hide-details
          @change="getEstadisticas"
        ></v-autocomplete>
      </v-col>
      <v-col cols="12" class="pb-0">
        <download-btn
          text
          color="primary"
          className="my-0"
          @click="descargarReporte()"
          label="Descargar Reporte de<br/>Rendimiento por Intervalo"
        />
      </v-col>
      <v-col cols="12" md="12" sm="12">
        <v-progress-linear
          v-if="loading"
          indeterminate
          color="primary"
        ></v-progress-linear>
        <apexchart ref="chart" type="bar" height="500" :options="chartOptions" :series="series"></apexchart>
      </v-col>
    </v-row>
  </v-col>
</template>

<script>
import moment from "moment";
import axios from "axios";
import datePicker from "@/components/DatePicker";
import { VueDatePicker } from '@mathieustan/vue-datepicker';
import DownloadBtn from '../../../DownloadBtn.vue';

export default {
	components: { DownloadBtn },
  data: () => ({
    series:[],
    chartOptions: {
      chart: {
        type: 'bar',
        height: 350,
        stacked: true,
        locales: [{
          name: "es",
          options: {
            months: ["Enero","Febrero","Marzo","Abril","Mayo","Junio","Julio","Agosto","Septiembre","Octubre","Noviembre","Diciembre"],
            shortMonths: ["Ene","Feb","Mar","Abr","May","Jun","Jul","Ago","Sep","Oct","Nov","Dic"],
            days: ["Domingo","Lunes","Martes","Miércoles","Jueves","Viernes","Sábado"],
            shortDays: ["Dom","Lun","Mar","Mie","Jue","Vie","Sab"],
            toolbar: {
              exportToSVG: "Descargar SVG",
              exportToPNG: "Descargar PNG",
              exportToCSV: "Descargar CSV",
              menu: "Menu",
              selection: "Seleccionar",
              selectionZoom: "Seleccionar Zoom",
              zoomIn: "Aumentar",
              zoomOut: "Disminuir",
              pan: "Navegación",
              reset: "Reiniciar Zoom"
            }
          }
        }],
        defaultLocale: "es"
      },
      plotOptions: {
        bar: {
          horizontal: true,
        },
      },
      stroke: {
        width: 1,
        colors: ['#fff']
      },
      xaxis: {
        categories: [],
        labels: {
          formatter: function (val) {
            function zeroPrefix(num, digit) {
              if(digit <= 0) return '0';
              if(num > Math.pow(10,digit-1)) return `${num}`;

              var zero = '';
              for(var i = 0; i < digit; i++) {
                zero += '0';
              }
              return (zero + num).slice(-digit);
            }

            var horas = Math.trunc(val / 60);
            var minutos = val - (horas * 60);

            return (horas<= 9?zeroPrefix(horas,2):horas)+":"+zeroPrefix(minutos,2);
          }
        }
      },
      yaxis: {
        title: {
          text: undefined
        },
      },
      tooltip: {
        y: {
          formatter: function (val) {
            function zeroPrefix(num, digit) {
              if(digit <= 0) return '0';
              if(num > Math.pow(10,digit-1)) return `${num}`;

              var zero = '';
              for(var i = 0; i < digit; i++) {
                zero += '0';
              }
              return (zero + num).slice(-digit);
            }

            var horas = Math.trunc(val / 60);
            var minutos = val - (horas * 60);

            return (horas<= 9?zeroPrefix(horas,2):horas)+":"+zeroPrefix(minutos,2);
          }
        }
      },
      fill: {
        opacity: 1
      },
      legend: {
        position: 'top',
        horizontalAlign: 'left',
        offsetX: 40
      },
      dataLabels: {
        enabled: true,
        formatter: function(val, opts) {
          function zeroPrefix(num, digit) {
            if(digit <= 0) return '0';
            if(num > Math.pow(10,digit-1)) return `${num}`;
            var zero = '';
            for(var i = 0; i < digit; i++) {
              zero += '0';
            }
            return (zero + num).slice(-digit);
          }

          var horas = Math.trunc(val / 60);
          var minutos = val - (horas * 60);

          return (horas<= 9?zeroPrefix(horas,2):horas)+":"+zeroPrefix(minutos,2);
        },
        style: {
          colors: ['#fff', '#fff']
        }
      },
    },
    defaultChartOptions: {
      chart: {
        type: 'bar',
        height: 350,
        stacked: true,
        locales: [{
          name: "es",
          options: {
            months: ["Enero","Febrero","Marzo","Abril","Mayo","Junio","Julio","Agosto","Septiembre","Octubre","Noviembre","Diciembre"],
            shortMonths: ["Ene","Feb","Mar","Abr","May","Jun","Jul","Ago","Sep","Oct","Nov","Dic"],
            days: ["Domingo","Lunes","Martes","Miércoles","Jueves","Viernes","Sábado"],
            shortDays: ["Dom","Lun","Mar","Mie","Jue","Vie","Sab"],
            toolbar: {
              exportToSVG: "Descargar SVG",
              exportToPNG: "Descargar PNG",
              exportToCSV: "Descargar CSV",
              menu: "Menu",
              selection: "Seleccionar",
              selectionZoom: "Seleccionar Zoom",
              zoomIn: "Aumentar",
              zoomOut: "Disminuir",
              pan: "Navegación",
              reset: "Reiniciar Zoom"
            }
          }
        }],
        defaultLocale: "es"
      },
      plotOptions: {
        bar: {
          horizontal: true,
        },
      },
      stroke: {
        width: 1,
        colors: ['#fff']
      },
      xaxis: {
        categories: [],
        labels: {
          formatter: function (val) {
            function zeroPrefix(num, digit) {
              if(digit <= 0) return '0';
              if(num > Math.pow(10,digit-1)) return `${num}`;

              var zero = '';
              for(var i = 0; i < digit; i++) {
                zero += '0';
              }
              return (zero + num).slice(-digit);
            }

            var horas = Math.trunc(val / 60);
            var minutos = val - (horas * 60);

            return (horas<= 9?zeroPrefix(horas,2):horas)+":"+zeroPrefix(minutos,2);
          }
        }
      },
      yaxis: {
        title: {
          text: undefined
        },
      },
      tooltip: {
        y: {
          formatter: function (val) {
            function zeroPrefix(num, digit) {
              if(digit <= 0) return '0';
              if(num > Math.pow(10,digit-1)) return `${num}`;

              var zero = '';
              for(var i = 0; i < digit; i++) {
                zero += '0';
              }
              return (zero + num).slice(-digit);
            }

            var horas = Math.trunc(val / 60);
            var minutos = val - (horas * 60);

            return (horas<= 9?zeroPrefix(horas,2):horas)+":"+zeroPrefix(minutos,2);
          }
        }
      },
      fill: {
        opacity: 1
      },
      legend: {
        position: 'top',
        horizontalAlign: 'left',
        offsetX: 40
      },
      dataLabels: {
        enabled: true,
        formatter: function(val, opts) {
          function zeroPrefix(num, digit) {
            if(digit <= 0) return '0';
            if(num > Math.pow(10,digit-1)) return `${num}`;
            var zero = '';
            for(var i = 0; i < digit; i++) {
              zero += '0';
            }
            return (zero + num).slice(-digit);
          }

          var horas = Math.trunc(val / 60);
          var minutos = val - (horas * 60);

          return (horas<= 9?zeroPrefix(horas,2):horas)+":"+zeroPrefix(minutos,2);
        },
        style: {
          colors: ['#fff', '#fff']
        }
      },
    },
    loadingOperador:false,
    personas:[],
    idsPersonas:[],
    inicio:null,
    fin:null,
    loading:false,
    areas: [],
    idArea: null,
    turnos: [],
    idTurno: null
  }),
  computed:{
    fetchUrl(){
      var url = "/Reportes/ReporteTiempoMuertoRango?";

      if(this.inicio != null) url += "Inicio="+this.inicio;
      else return null;
      if(this.fin != null) url += "&Fin="+this.fin;
      else return null;

      if (this.$utils.isValid(this.idsPersonas) && this.idsPersonas.length > 0){
        this.idsPersonas.forEach(x => (url += `&IdOperador=${x || 0}`));
      }
      else return null;

      return url;
    }
  },
  mounted(){
    this.getAreas();
    this.getTurnos();
  },
  methods:{
    nombrePersona: persona => persona.nombre + " " + persona.paterno + " " + persona.materno,
    resetPersonas(){
      this.idsPersonas = [];
    },
    descargarReporte(){
      //ENDPOINT para descargar reporte por Dia
      var url = "/Reportes/ReporteTiempoMuertoRangoXlsx?";

      if(this.inicio != null) url += "Inicio="+this.inicio;
      else return;
      if(this.fin != null) url += "&Fin="+this.fin;
      else return;

      if (this.$utils.isValid(this.idsPersonas) && this.idsPersonas.length > 0){
        this.idsPersonas.forEach(x => (url += `&IdOperador=${x || 0}`));
      }

      this.$utils.axios.downloadFile(url,'GET','reporte-rendimiento-rango-'+this.inicio+'-'+this.fin+'.xlsx','application/vnd.openxmlformats-officedocument.spreadsheetml.sheet');
    },
    getTurnos(){
      axios
        .get('/Turnos')
        .then( response => {
          this.turnos = response.data;
        })
        .catch( error => {
          console.log( error );
        })
    },
    getAreas(){
      axios
        .get('/Areas')
        .then( response => {
          this.areas = response.data.filter(area => area.idArea > 1 );
        })
        .catch( error => {
          console.log(error);
        })
    },
    getOperadores (){
      this.idsPersonas = [];
      this.personas = [];
      this.series = [];
      this.chartOptions = JSON.parse(JSON.stringify(this.defaultChartOptions));
      this.loadingOperador = true;
      axios
      .get("/Personas/OperadoresRendimientoIntervalo",{
        params:{
          desde: this.inicio,
          hasta: this.fin
        }
      })
      .then(response =>  {
        this.personas = response.data;
        this.loadingOperador = false;
      })
      .catch(error => {
        this.loadingOperador = false;
        console.log(error);
      });
    },
    getEstadisticas(){
      var url = this.fetchUrl;
      if(url == null) {
        this.series = [];
        this.chartOptions = JSON.parse(JSON.stringify(this.defaultChartOptions));
        return;
      };
      this.loading = true;
      axios
      .get(url,{
        params:{
          idArea: this.idArea,
          idTurno: this.idTurno
        }
      })
      .then(response => {
        this.loading = false;
        this.series = response.data.series;
        var options = JSON.parse(JSON.stringify(this.defaultChartOptions));
        options.xaxis.categories = response.data.operadores;
        this.chartOptions = options;
      })
      .catch(error => {
        this.loading = false;
        console.log(error);
      });
    }
  }
};
</script>