<template>
	<v-col cols="12">
		<v-dialog v-model="dialogDelete" persistent max-width="40%">
			<v-card>
				<v-card-text>
					<p class="text-h5 pt-4 font-weight-medium text--primary">
						¿Está seguro que desea eliminar este Subalmacén?
					</p>
					<p class="subtitle-1 text--primary">
						Esta acción no se puede revertir y será permanente.
					</p>
				</v-card-text>
				<v-card-actions>
					<v-spacer></v-spacer>
					<v-btn
						text
						color="success"
						:disabled="deleting"
						@click="closeDialog()"
						>Cancelar</v-btn
					>
					<v-btn
						text
						color="error"
						:disabled="deleting"
						:loading="deleting"
						@click="deleteSubalmacen()"
						>Eliminar</v-btn
					>
				</v-card-actions>
			</v-card>
		</v-dialog>
		<v-dialog v-model="dialog" persistent max-width="40%">
			<v-card>
				<v-card-title
					>{{
						editedItem.idSubalmacen ? "Editar" : "Nuevo"
					}}
					Subalmacén</v-card-title
				>
				<v-card-text>
					<v-container>
						<v-row>
							<v-col cols="12">
								<v-text-field
									v-model="editedItem.clave"
									label="Código del Subalmacén"
									clearable
									:error-messages="errorCodigo"
									@focus="errorCodigo = ''"
								></v-text-field>
							</v-col>
							<v-col cols="12">
								<v-text-field
									v-model="editedItem.subalmacen"
									label="Nombre del Subalmacén"
									clearable
									:error-messages="errorNombre"
									@focus="errorNombre = ''"
								></v-text-field>
							</v-col>
							<v-col cols="12">
								<v-autocomplete
									v-model="editedItem.idAlmacen"
									label="Almacén"
									:items="almacenes"
									:item-text="nombreAlmacen"
									item-value="idAlmacen"
									clearable
									:error-messages="errorAlmacen"
									@focus="errorAlmacen = ''"
								></v-autocomplete>
							</v-col>
						</v-row>
					</v-container>
				</v-card-text>
				<v-card-actions>
					<v-spacer></v-spacer>
					<v-btn
						color="success"
						text
						:disabled="saving"
						@click="closeDialog()"
						>Cancelar</v-btn
					>
					<v-btn
						color="error"
						text
						:disabled="saving"
						:loading="saving"
						@click="save()"
						>Guardar</v-btn
					>
				</v-card-actions>
			</v-card>
		</v-dialog>
		<v-row class="justify-space-between">
			<v-col cols="4">
				<v-btn color="primary" :disabled="loading" @click="showDialog()"
					>Nuevo Subalmacén</v-btn
				>
			</v-col>
			<v-col cols="6">
				<v-text-field
					v-model.trim="search"
					label="Buscar"
					hide-details
					clearable
					append-icon="mdi-magnify"
				></v-text-field>
			</v-col>
		</v-row>
		<v-row>
			<v-col cols="12">
				<v-data-table
					:items="subalmacenes"
					:headers="subalmacenesHeaders"
					:loading="loading"
					:search="search"
				>
					<template v-slot:[`item.nombreAlmacen`]="{ item }">
						{{ getNombreAlmacen(item) }}
					</template>
					<template v-slot:[`item.actions`]="{ item }">
						<v-tooltip bottom color="black" class="white--text">
							<template v-slot:activator="{ on }">
								<v-icon
									v-on="on"
									small
									class="mr-2"
									:disabled="loading"
									@click="showDialog(item)"
								>
									edit
								</v-icon>
							</template>
							<span class="white--text">Editar</span>
						</v-tooltip>
						<v-tooltip bottom color="black" class="white--text">
							<template v-slot:activator="{ on }">
								<v-icon
									v-on="on"
									small
									:disabled="loading"
									@click="showDialogDelete(item)"
								>
									delete
								</v-icon>
							</template>
							<span class="white--text">Eliminar</span>
						</v-tooltip>
					</template>
				</v-data-table>
			</v-col>
		</v-row>
	</v-col>
</template>

<script>
import axios from "axios";
export default {
	data() {
		return {
			almacenes: [],
			dialogDelete: false,
			deleting: false,
			saving: false,
			errorCodigo: "",
			errorNombre: "",
			errorAlmacen: "",
			search: null,
			subalmacenes: [],
			subalmacenesHeaders: [
				{
					text: "Código",
					align: "center",
					value: "clave",
				},
				{
					text: "Nombre",
					align: "center",
					value: "subalmacen",
				},
				{
					text: "Almacén",
					align: "center",
					value: "nombreAlmacen",
				},
				{
					text: "Acciones",
					align: "center",
					value: "actions",
				},
			],
			loading: false,
			editedItem: {
				idSubalmacen: null,
				clave: null,
				subalmacen: null,
				idAlmacen: null,
				codigo: null,
				nombreAlmacen: null,
			},
			defaultEditedItem: {
				idSubalmacen: null,
				clave: null,
				subalmacen: null,
				idAlmacen: null,
				codigo: null,
				nombreAlmacen: null,
			},
			dialog: false,
		};
	},
	mounted() {
		this.initialize();
	},
	methods: {
		nombreAlmacen: ({ codigo, nombre }) => `[${codigo}] - ${nombre}`.trim(),
		deleteSubalmacen() {
			this.deleting = true;

			axios
				.put(`/Subalmacenes/Desactivar/${this.editedItem.idSubalmacen}`)
				.then(() => {
					this.deleting = false;
					this.closeDialog();
					this.initialize();
				})
				.catch((error) => {
					this.deleting = false;
					console.log(error);
				});
		},
		validate() {
			this.errorCodigo = "";
			this.errorNombre = "";
			this.errorAlmacen = "";

			if (this.editedItem.clave == null) {
				this.errorCodigo = "Requerido";
			} else if (this.editedItem.clave.length < 5) {
				this.errorCodigo = "Al menos 5 caracteres";
			}

			if (this.editedItem.subalmacen == null) {
				this.errorNombre = "Requerido";
			} else if (this.editedItem.subalmacen.length < 5) {
				this.errorNombre = "Al menos 5 caracteres";
			}

			if (this.editedItem.idAlmacen == null) {
				this.errorAlmacen = "Requerido";
			}

			return (
				this.errorCodigo == "" &&
				this.errorNombre == "" &&
				this.errorAlmacen == ""
			);
		},
		save() {
			if (!this.validate()) return;

			this.saving = true;

			axios({
				url: this.editedItem.idSubalmacen
					? `/Subalmacenes/Actualizar/${this.editedItem.idSubalmacen}`
					: "/Subalmacenes/Crear",
				method: this.editedItem.idSubalmacen ? "PUT" : "POST",
				data: {
					idAlmacen: this.editedItem.idAlmacen,
					clave: this.editedItem.clave,
					nombre: this.editedItem.subalmacen,
				},
			})
				.then(() => {
					this.saving = false;
					this.closeDialog();
					this.initialize();
				})
				.catch((error) => {
					this.saving = false;
					if (error.response) {
						if (error.response.status == 409) {
							this.errorCodigo = "Ya existe";
						}
					}
					console.log(error);
				});
		},
		closeDialog() {
			this.dialog = false;
			this.dialogDelete = false;
			this.$nextTick(() => {
				this.editedItem = { ...this.defaultEditedItem };
				this.errorCodigo = "";
				this.errorNombre = "";
				this.errorAlmacen = "";
			});
		},
		showDialogDelete(item) {
			this.editedItem = { ...item };
			this.dialogDelete = true;
		},
		showDialog(item = null) {
			this.editedItem = Object.assign({}, item || this.defaultEditedItem);
			this.dialog = true;
		},
		getNombreAlmacen: ({ codigo, nombreAlmacen }) =>
			`[${codigo}] - ${nombreAlmacen}`.trim(),
		initialize() {
			this.getAlmacenes();
			this.getSubalmacenes();
		},
		getAlmacenes() {
			axios
				.get("/Almacenes")
				.then((response) => {
					this.almacenes = response.data;
				})
				.catch(console.log);
		},
		getSubalmacenes() {
			this.loading = true;

			axios
				.get("/Subalmacenes")
				.then((response) => {
					this.subalmacenes = response.data;
				})
				.catch(console.log)
				.finally(() => {
					this.loading = false;
				});
		},
	},
};
</script>