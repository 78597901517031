import { VCol } from 'vuetify/lib/components/VGrid';
import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VProgressLinear } from 'vuetify/lib/components/VProgressLinear';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VToolbar } from 'vuetify/lib/components/VToolbar';
import { VToolbarTitle } from 'vuetify/lib/components/VToolbar';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c(VCol,{staticClass:"pt-0",attrs:{"cols":"12"}},[_c(VRow,[_c(VCol,{attrs:{"cols":"12","md":"6","sm":"12"}},[_c('datePicker',{attrs:{"format":"YYYY-MM-DD","maxDate":_vm.filtros.hasta,"label":"Fecha Inicio (Obligatorio)","clearable":"","maxToday":"","disabled":_vm.cargando},on:{"input":function($event){return _vm.getData()}},model:{value:(_vm.filtros.desde),callback:function ($$v) {_vm.$set(_vm.filtros, "desde", $$v)},expression:"filtros.desde"}})],1),_c(VCol,{attrs:{"cols":"12","md":"6","sm":"12"}},[_c('datePicker',{attrs:{"format":"YYYY-MM-DD","minDate":_vm.filtros.desde,"label":"Fecha Final (Obligatorio)","clearable":"","maxToday":"","disabled":_vm.cargando},on:{"input":function($event){return _vm.getData()}},model:{value:(_vm.filtros.hasta),callback:function ($$v) {_vm.$set(_vm.filtros, "hasta", $$v)},expression:"filtros.hasta"}})],1),(_vm.cargando)?_c(VCol,{attrs:{"cols":"12","md":"12","sm":"12"}},[_c(VProgressLinear,{attrs:{"color":"primary","height":"5","indeterminate":""}}),_c('div',{staticClass:"text-center mt-10"},[_c('p',[_vm._v("Seleccione fechas para mostrar las gráficas.")])])],1):_c(VRow,[_c(VCol,{attrs:{"cols":"12","md":"6","sm":"12"}},[(_vm.dataReportes.length > 0)?_c(VDataTable,{staticClass:"elevation-3 mb-10 mt-3 border",attrs:{"headers":_vm.headersReportes,"items":_vm.dataReportes},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c(VToolbar,{attrs:{"flat":""}},[_c(VToolbarTitle,[_vm._v("Salidas de Materia Prima por Categoría")]),_c(VDivider,{staticClass:"mx-4",attrs:{"inset":"","vertical":""}})],1)]},proxy:true},{key:"item",fn:function(ref){
var item = ref.item;
return [_c('tr',[(item.view)?_c('td',{style:({
                    backgroundColor:
                      item.color === 'white' ? item.color : item.color + '25',
                  })},[_vm._v(" "+_vm._s(item.categoria)+" ")]):_vm._e(),(item.view)?_c('td',{style:({
                    backgroundColor: item.color,
                    color: item.color !== 'white' ? 'whitesmoke' : 'black',
                    fontWeight: item.categoria.includes('Total')
                      ? 'bold'
                      : 'normal',
                  })},[_vm._v(" "+_vm._s(item.conteo.toLocaleString('es-MX'))+" ")]):_vm._e()])]}}],null,false,2823688736)}):_vm._e()],1),_c(VCol,{attrs:{"cols":"12","md":"6","sm":"12"}},[(_vm.dataTrazabilidad.length > 0)?_c(VDataTable,{staticClass:"elevation-3 mb-10 mt-3 border",attrs:{"headers":_vm.headersTrazabilidad,"items":_vm.dataTrazabilidad},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c(VToolbar,{attrs:{"flat":""}},[_c(VToolbarTitle,[_vm._v("PP sin HDPE y sin PEMSA")]),_c(VDivider,{staticClass:"mx-4",attrs:{"inset":"","vertical":""}})],1)]},proxy:true},{key:"item",fn:function(ref){
                  var item = ref.item;
return [_c('tr',[_c('td',{style:({
                    backgroundColor:
                      item.color === 'white' ? item.color : item.color + '25',
                    fontWeight:
                      item.categoria.includes('Total') ||
                      item.categoria === ' '
                        ? 'bold'
                        : 'normal',
                  })},[_vm._v(" "+_vm._s(item.categoria)+" ")]),_c('td',{style:({
                    backgroundColor: item.color,
                    color: item.color !== 'white' ? 'whitesmoke' : 'black',
                    fontWeight:
                      item.categoria.includes('Total') ||
                      item.categoria === ' '
                        ? 'bold'
                        : 'normal',
                  })},[_vm._v(" "+_vm._s(item.cantidad.toLocaleString('es-MX'))+" ")])])]}}],null,false,3781809802)}):_vm._e(),_c(VDivider)],1),_c(VCol,{attrs:{"cols":"12","md":"6","sm":"12"}},[(_vm.dataPPSinHDPE.length > 0)?_c(VDataTable,{staticClass:"elevation-3 mb-10 mt-3 border",attrs:{"headers":_vm.headersPPSinHDPE,"items":_vm.dataPPSinHDPE},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c(VToolbar,{attrs:{"flat":""}},[_c(VToolbarTitle,[_vm._v("PP sin HDPE")]),_c(VDivider,{staticClass:"mx-4",attrs:{"inset":"","vertical":""}})],1)]},proxy:true},{key:"item",fn:function(ref){
                  var item = ref.item;
return [_c('tr',[_c('td',{style:({
                    backgroundColor:
                      item.color === 'white' ? item.color : item.color + '25',
                  })},[_vm._v(" "+_vm._s(item.categoria)+" ")]),_c('td',{style:({
                    backgroundColor: item.color,
                    color:
                      item.color !== 'white' && item.color !== '#FFFF00'
                        ? 'whitesmoke'
                        : 'black',
                  })},[_vm._v(" "+_vm._s(item.cantidad.toLocaleString('es-MX'))+" ")])])]}}],null,false,180604259)}):_vm._e(),_c(VDivider)],1),_c(VCol,{attrs:{"cols":"12","md":"6","sm":"12"}},[(_vm.dataComparativos.length > 0)?_c(VDataTable,{staticClass:"elevation-3 mb-10 mt-3 border",attrs:{"headers":_vm.headersComparativos,"items":_vm.dataComparativos},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c(VToolbar,{attrs:{"flat":""}},[_c(VToolbarTitle,[_vm._v("Comparativos")]),_c(VDivider,{staticClass:"mx-4",attrs:{"inset":"","vertical":""}})],1)]},proxy:true},{key:"item",fn:function(ref){
                  var item = ref.item;
return [_c('tr',[_c('td',{style:({
                    backgroundColor: '#FFFF00',
                  })},[_vm._v(" "+_vm._s(item.categoria)+" ")]),_c('td',{style:({
                    backgroundColor: item.color,
                    color:
                      item.color !== 'white' && item.color !== '#FFFF00'
                        ? 'whitesmoke'
                        : 'black',
                    fontWeight: item.categoria.includes('Total')
                      ? 'bold'
                      : 'normal',
                  })},[_vm._v(" "+_vm._s(item.cantidad.toLocaleString('es-MX'))+" ")])])]}}],null,false,2580048962)}):_vm._e(),_c(VDivider)],1),_c(VCol,{attrs:{"cols":"12","md":"12","sm":"12"}},[(_vm.series.length > 0)?_c('apexchart',{attrs:{"type":"bar","height":"500","options":_vm.chartOptions,"series":_vm.series}}):_vm._e()],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }