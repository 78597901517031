import { VCol } from 'vuetify/lib/components/VGrid';
import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VProgressLinear } from 'vuetify/lib/components/VProgressLinear';
import { VTooltip } from 'vuetify/lib/components/VTooltip';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.permisoVista('lotes','r'))?_c(VCol,{attrs:{"cols":"12"}},[_c('signalr',{attrs:{"path":"/hub/procesos"},on:{"change":_vm.cambio}}),_c(VDataTable,{attrs:{"items":_vm.procesos,"headers":_vm.headers},scopedSlots:_vm._u([{key:"item.progreso",fn:function(ref){
var item = ref.item;
return [_c(VProgressLinear,{attrs:{"color":"teal","buffer-value":"0","value":item.progreso,"stream":""}}),_vm._v(" "+_vm._s(item.observaciones)+" ")]}},{key:"item.action",fn:function(ref){
var item = ref.item;
return [(item.nombreArchivo != null)?_c(VTooltip,{staticClass:"white--text",attrs:{"bottom":"","color":"black"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c(VIcon,_vm._g({attrs:{"small":""},on:{"click":function($event){return _vm.descargarArchivo(item.nombreArchivo)}}},on),[_vm._v("mdi-download")])]}}],null,true)},[_c('span',{staticClass:"white--text"},[_vm._v("Descargar Archivo")])]):_vm._e(),(_vm.permisoVista('lotes','d'))?_c(VTooltip,{staticClass:"white--text",attrs:{"bottom":"","color":"black"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c(VIcon,_vm._g({attrs:{"small":""},on:{"click":function($event){return _vm.eliminarProceso(item.idProcesoLote)}}},on),[_vm._v("delete")])]}}],null,true)},[_c('span',{staticClass:"white--text"},[_vm._v("Eliminar")])]):_vm._e()]}}],null,false,3891629485)})],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }