<template>
  <div>
    <v-dialog
			v-model="cargaMasiva"
			persistent
			max-width="40%"
		>
			<v-card>
				<v-card-title>
					Carga Masiva de Proveedores
				</v-card-title>
				<template v-if="estadoCarga == 0">
					<v-card-text>
						<v-file-input
      			  v-model="document"
      			  label="Formato de Carga Masiva de Proveedores (.csv)"
      			  accept=".csv"
      			  counter
      			  show-size
      			  @change="cambioFicheroActualizacion"
      			></v-file-input>
					</v-card-text>
					<v-card-actions>
						<v-spacer></v-spacer>
						<v-btn @click="cerrarCargaMasiva" text color="green">Cancelar</v-btn>
						<v-btn @click="realizarCargaMasiva" text color="red">Guardar</v-btn>
					</v-card-actions>
				</template>
        <template v-if="estadoCarga == 1">
					<v-card-text class="text-center">
						<v-progress-circular
							indeterminate
							size="100"
							width="5"
							color="primary"
						></v-progress-circular>
						<div class="text-h6">Cargando</div>
					</v-card-text>
				</template>
				<template v-if="estadoCarga == 2">
					<v-card-text class="text-center">
						<lottie
							id="inProgressAlerta"
							:options="inProgressAnimationOptions"
							:width="400"
							:key="`inprogress-${lottieKey}`"
							class="py-0"
						/>
						<div class="text-h5 font-weight-bold">Carga Masiva en Progreso.</div>
						<div class="text-h5">Se le notificará cuando la tarea haya concluido.</div>
					</v-card-text>
					<v-card-actions>
						<v-spacer></v-spacer>
							<v-btn text color="green" @click="cerrarCargaMasiva">Aceptar</v-btn>
						<v-spacer></v-spacer>
					</v-card-actions>
				</template>
				<template v-if="estadoCarga == 3">
					<v-card-text class="text-center">
						<v-icon
							size="100"
							color="red"
						>
							mdi-close-circle-outline
						</v-icon>
						<div class="text-h6">Ha sucedido un Error. Intente nuevamente.</div>
					</v-card-text>
					<v-card-actions>
						<v-spacer></v-spacer>
							<v-btn text color="green" @click="cerrarCargaMasiva">Aceptar</v-btn>
						<v-spacer></v-spacer>
					</v-card-actions>
				</template>
			</v-card>
		</v-dialog>
    <v-row>
      <v-col cols="8">
        <v-btn
          color="primary"
          dark
          class="mb-2"
          v-if="permisoVista('proveedores', 'c')"
          @click="dialog = true"
          >Nuevo Proveedor</v-btn
        >
      </v-col>
      <v-col cols="2">
        <download-btn
          color="blue darken-1"
          text
          @click="descargarFormaro"
          label="Descargar Formato<br/>De Carga Masiva"
        ></download-btn>
      </v-col>
      <v-col cols="2">
        <v-btn color="blue darken-1" text @click="cargaMasiva = true">
          Carga Masiva de<br />proveedores
        </v-btn>
      </v-col>
      <v-col cols="12">
        <template>
          <v-row justify="center">
            <v-dialog
              v-model="eliminar"
              persistent
              max-width="500px"
              :key="eliminar"
            >
              <v-card>
                <v-card-title class="headline"
                  >¿Está seguro que desea eliminar este proveedor?</v-card-title
                >
                <v-card-text
                  >Esta acción no se puede revertir y será
                  permanente.</v-card-text
                >
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn
                    color="blue darken-1"
                    text
                    @click="
                      eliminar = false;
                      delItem = '';
                    "
                    >Cancelar</v-btn
                  >
                  <v-btn
                    color="red darken-1"
                    text
                    @click="
                      eliminar = false;
                      deleteItemDB();
                    "
                    >Aceptar</v-btn
                  >
                </v-card-actions>
              </v-card>
            </v-dialog>
          </v-row>
        </template>
        <v-data-table
          v-if="permisoVista('proveedores', 'r')"
          :headers="headers"
          :items="proveedores"
          :search="search"
          :loading="loading"
          loading-text="Cargando... Espere, Por Favor."
        >
          <template v-slot:top>
            <v-toolbar flat color="white">
              <v-dialog
                v-model="dialog"
                persistent
                max-width="500px"
                :key="dialog"
              >
                <!-- <template v-slot:activator="{ on }">
                  <v-btn
                    color="primary"
                    dark
                    class="mb-2"
                    v-on="on"
                    v-if="permisoVista('proveedores', 'c')"
                    >Nuevo Proveedor</v-btn
                  >
                </template> -->
                <v-card>
                  <v-card-title>
                    <span class="headline">{{ formTitle }}</span>
                  </v-card-title>
                  <v-card-text>
                    <v-container>
                      <v-row>
                        <v-col justify="center" cols="12" sm="12" md="12">
                          <v-text-field
                            v-model="editedItem.nombre"
                            hide-details
                            label="Nombre o Razón Social del Proveedor"
                          ></v-text-field>
                        </v-col>
                        <v-col justify="center" cols="12" sm="12" md="12">
                          <v-text-field
                            v-model="editedItem.codigo"
                            hide-details
                            label="Clave del Proveedor"
                          ></v-text-field>
                        </v-col>
                        <v-col justify="center" cols="12" sm="12" md="12">
                          <v-text-field
                            v-model="editedItem.rfc"
                            hide-details
                            label="RFC del Proveedor"
                          ></v-text-field>
                        </v-col>
                        <v-col justify="center" cols="12" sm="12" md="12">
                          <v-text-field
                            v-model="editedItem.telefono"
                            hide-details
                            label="Teléfono del Proveedor"
                          ></v-text-field>
                        </v-col>
                        <v-col justify="center" cols="12" sm="12" md="12">
                          <v-text-field
                            v-model="editedItem.email"
                            hide-details
                            label="Correo Electrónico del Proveedor"
                          ></v-text-field>
                        </v-col>
                      </v-row>
                      <ul>
                        <li class="red--text" v-for="ex in errores" :key="ex">
                          {{ ex }}
                        </li>
                      </ul>
                    </v-container>
                  </v-card-text>
                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn
                      color="blue darken-1"
                      text
                      @click="
                        dialog = false;
                        errores = [];
                      "
                      >Cancelar</v-btn
                    >
                    <v-btn
                      color="red darken-1"
                      text
                      @click="save()"
                      :disabled="saving"
                      :loading="saving"
                    >
                      Guardar
                      <template v-slot:loader>
                        <v-progress-circular
                          indeterminate
                          :width="2"
                          :size="24"
                          color="red"
                        ></v-progress-circular>
                      </template>
                    </v-btn>
                  </v-card-actions>
                </v-card>
              </v-dialog>
              <v-spacer></v-spacer>
              <v-text-field
                v-model="search"
                append-icon="search"
                label="Buscar"
                single-line
                hide-details
              ></v-text-field>
            </v-toolbar>
          </template>
          <template v-slot:item.action="{ item }">
            <v-tooltip bottom color="black" class="white--text">
              <template v-slot:activator="{ on }">
                <v-icon
                  v-on="on"
                  v-if="permisoVista('proveedores', 'u')"
                  small
                  class="mr-2"
                  @click="editItem(item)"
                >
                  edit
                </v-icon>
              </template>
              <span class="white--text">Editar</span>
            </v-tooltip>
          </template>
          <template v-slot:no-data>
            <v-btn color="primary" @click="initialize">Refrescar</v-btn>
          </template>
        </v-data-table>
        <v-snackbar v-model="alerta" top color="error">
          Existe un Proveedor Activo con el mismo RFC o Clave
          <v-btn text @click="alerta = false"> Cerrar </v-btn>
        </v-snackbar>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import axios from "axios";
import * as inProgressAnimation from '@/assets/inProgress.json';

export default {
  data: () => ({
    lottieKey: 0,
    inProgressAnimationOptions: {
				animationData: inProgressAnimation.default,
				loop: true,
				speed: 1
			},
    saving: false,
    dialog: false,
    alerta: false,
    eliminar: false,
    ficheroActualizacion:null,
    search: "",
    headers: [
      {
        text: "Proveedor",
        align: "left",
        sortable: true,
        value: "nombre",
      },
      {
        text: "Codigo",
        align: "left",
        sortable: true,
        value: "codigo",
      },
      {
        text: "RFC",
        align: "center",
        sortable: true,
        value: "rfc",
      },
      {
        text: "Teléfono",
        align: "center",
        sortable: true,
        value: "telefono",
      },
      {
        text: "Correo Electrónico",
        align: "center",
        sortable: true,
        value: "email",
      },
      {
        text: "Acciones",
        value: "action",
        sortable: false,
        width: "20%",
        align: "center",
      },
    ],
    loading: false,
    proveedores: [],
    delItem: "",
    action: 0,
    errores: [],
    editedIndex: -1,
    editedItem: {
      nombre: "",
      codigo: "",
      rfc: "",
      telefono: "",
      email: "",
      comercialLineas: [],
    },
    defaultItem: {
      nombre: "",
      codigo: "",
      rfc: "",
      telefono: "",
      email: "",
    },
    cargaMasiva: false,
    document : null,
    estadoCarga : 0
  }),

  computed: {
    formTitle() {
      return this.editedIndex === -1 ? "Nuevo Proveedor" : "Editar Proveedor";
    },
  },

  watch: {
    dialog(val) {
      val || this.close();
    },
  },

  created() {
    this.initialize();
  },

  methods: {
    initialize() {
      let me = this;
      me.loading = true;
      axios
        .get("/Proveedores")
        .then((response) => {
          me.proveedores = response.data;
          me.loading = false;
        })
        .catch((error) => {
          console.log(error);
        });
    },

    editItem(item) {
      this.action = 1;
      this.editedIndex = this.proveedores.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialog = true;
    },

    deleteItem(item) {
      this.eliminar = true;
      this.delItem = item;
    },
    deleteItemDB() {
      let me = this;
      let item = this.delItem;
      this.delItem = "";
      axios
        .put("/Proveedores/Desactivar/" + item.idProveedor)
        .then((response) => {
          me.initialize();
        })
        .catch((error) => {
          console.log(error);
        });
    },
    close() {
      (this.action = 0), (this.dialog = false);
      setTimeout(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      }, 300);
    },
    validate() {
      this.errores = [];
      var error = false;
      if (
        this.editedItem.nombre.length < 2 ||
        this.editedItem.nombre.length > 200
      ) {
        this.errores.push(
          "El nombre debe tener más de 2 caracteres y menos de 200."
        );
        error = true;
      }
      if (
        this.editedItem.codigo.length < 2 ||
        this.editedItem.codigo.length > 50
      ) {
        this.errores.push(
          "La clave debe tener más de 2 caracteres y menos de 50."
        );
        error = true;
      }
      if (this.editedItem.rfc != null && this.editedItem.rfc.length > 0) {
        if (this.editedItem.rfc.length < 8 || this.editedItem.rfc.length > 20) {
          this.errores.push(
            "El RFC debe tener mínimo 8 caracteres y menos de 20."
          );
          error = true;
        }
      }

      if (
        this.editedItem.telefono != null &&
        this.editedItem.telefono.length > 0
      ) {
        if (this.editedItem.telefono.length < 5) {
          this.errores.push("El Teléfono debe tener mínimo 5 dígitos.");
          error = true;
        } else {
          if (!this.soloNumeros(this.editedItem.telefono)) {
            this.errores.push("El Teléfono debe tener solo dígitos.");
            error = true;
          }
        }
      }

      if (this.editedItem.email != null && this.editedItem.email.length > 0) {
        if (this.editedItem.email.length < 7) {
          this.errores.push(
            "El Correo Electrónico debe tener mínimo 7 caracteres."
          );
          error = true;
        } else {
          if (!this.validEmail(this.editedItem.email)) {
            this.errores.push(
              "El Correo Electrónico no cumple con la sintaxis"
            );
            error = true;
          }
        }
      }

      return !error;
    },
    soloNumeros(cadena) {
      return /^\d+$/.test(cadena);
    },
    validEmail(cadena) {
      return /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(cadena);
    },
    descargarFormaro(){
      this.$utils.axios.downloadFile('/Proveedores/DescargarFormatoCargaMasivaProveedores?access_token='+this.token,'GET','Formato_Carga_Masiva_Proveedores.csv','text/csv');
    },
    realizarCargaMasiva(){
			if (this.document != null) {
        this.estadoCarga = 1;
				let formData = new FormData();
        formData.append("formfile", this.document);
        axios
          .post("/Proveedores/CargaMasivaProveedores", formData, {
            headers: {
              "Content-Type": "multipart/form-data"
            }
          })
          .then(() => {
            this.estadoCarga = 2;
						this.initialize();
          })
          .catch(error => {
            this.estadoCarga = 3;
            console.log(error);
          });
      }
		},
    cerrarCargaMasiva(){
			this.cargaMasiva = false;
			this.estadoCarga = 0;
			this.document = null;
		},
    cambioFicheroActualizacion(event) {
        if (typeof event === "undefined" || event == null)
          this.document = null;
        else this.document = event;
    },
    save() {
      this.action = 0;
      if (this.validate()) {
        let me = this;
        me.saving = true;
        if (this.editedIndex > -1) {
          //Código para editar
          axios
            .put(
              "/Proveedores/Actualizar/" +
                me.proveedores[me.editedIndex].idProveedor,
              {
                Nombre: me.editedItem.nombre,
                Codigo: me.editedItem.codigo,
                RFC:
                  me.editedItem.rfc != null && me.editedItem.rfc.length > 0
                    ? me.editedItem.rfc
                    : null,
                Telefono:
                  me.editedItem.telefono != null &&
                  me.editedItem.telefono.length > 0
                    ? me.editedItem.telefono
                    : null,
                Email:
                  me.editedItem.email != null && me.editedItem.email.length > 0
                    ? me.editedItem.email
                    : null,
                ComercialLineas: me.editedItem.comercialLineas
              }
            )
            .then((response) => {
              me.saving = false;
              me.close();
              me.initialize();
            })
            .catch((error) => {
              me.saving = false;
              if (error.response) {
                if (error.response.status == 409) {
                  me.alerta = true;
                  me.errores.push(
                    "Existe un Proveedor Activo con el Mismo RFC o Clave"
                  );
                }
              }
            });
        } else {
          axios
            .post("/Proveedores/Crear", {
              Nombre: me.editedItem.nombre,
              Codigo: me.editedItem.codigo,
              RFC: me.editedItem.rfc,
              Telefono: me.editedItem.telefono,
              Email: me.editedItem.email,
              ComercialLineas: me.editedItem.comercialLineas,
            })
            .then((response) => {
              me.saving = false;
              me.close();
              me.initialize();
            })
            .catch((error) => {
              me.saving = false;
              if (error.response) {
                if (error.response.status == 409) {
                  me.alerta = true;
                  me.errores.push(
                    "Existe un Proveedor Activa con el Mismo RFC o Clave"
                  );
                }
              }
            });
        }
      }
    },
  },
};
</script>
