<template>
  <div v-if="permisoVista('brigadap', 'r')">
    <v-btn v-if="permisoVista('brigadap', 'c')"
    color="primary" dark class="mb-2" @click="dialog = true"
    >
      Nuevo puesto de brigada
    </v-btn>
    <v-dialog v-model="dialog" max-width="500px">
      <v-card>
        <v-card-title>
          <span class="text-h5">{{ formTitle }}</span>
        </v-card-title>

        <v-card-text>
          <v-container>
            <v-row>
              <v-col cols="12" sm="12" md="6">
                <v-text-field
                  v-model="editedItem.nombre"
                  label="Nombre"
                  :error="editedItem.errorNombreEstatus"
                  :error-messages="editedItem.errorNombre"
                  :key="up"
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="12" md="6">
                <v-text-field
                  v-model="editedItem.abreviatura"
                  label="Abreviatura"
                  :error="editedItem.errorAbreviaturaEstatus"
                  :error-messages="editedItem.errorAbreviatura"
                  :key="up"
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="12" md="12">
                <v-text-field
                  v-model="editedItem.posicion"
                  label="Posicion"
                  type="number"
                  min="1"
                  :error="editedItem.errorPosicionEstatus"
                  :error-messages="editedItem.errorposicion"
                  :key="up"
                ></v-text-field>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="close">
            CANCELAR
          </v-btn>
          <v-btn color="red darken-1" text @click="save">
            ACEPTAR
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="dialogDelete" max-width="500px">
      <v-card>
        <v-card-title class="headline"
          >¿Está seguro que desea eliminar este elemento?</v-card-title
        >
        <v-card-text
          >Esta acción no se puede revertir y será permanente.</v-card-text
        >
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="closeDelete"
            >CANCELAR</v-btn
          >
          <v-btn color="red darken-1" text @click="deleteItemConfirm"
            >ACEPTAR</v-btn
          >
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-data-table
      :headers="headers"
      :items="brigadabuestos"
      sort-by="calories"
      :loading="loadData"
    >
      <template v-slot:item.actions="{ item }">
        <v-icon v-if="permisoVista('brigadap','u')"
        small class="mr-2" @click="editItem(item)"
        >
          mdi-pencil
        </v-icon>
        <v-icon v-if="permisoVista('brigadap','d')"
        small @click="deleteItem(item)"
        >
          mdi-delete
        </v-icon>
      </template>
      <template v-slot:no-data>
        <v-btn color="primary" @click="initialize">
          Reset
        </v-btn>
      </template>
    </v-data-table>
  </div>
</template>

<script>
import axios from "axios";
export default {
  data: () => ({
    up: 0,
    loadData: false,
    dialog: false,
    dialogDelete: false,
    headers: [
      { text: "Nombre", value: "nombre" },
      { text: "Abreviatura", value: "abreviatura" },
      { text: "Posicion", value: "posicion" },
      { text: "Acciones", value: "actions", sortable: false },
    ],
    brigadabuestos: [],
    editedIndex: -1,
    editedItem: {
      idBrigadaPuesto: null,
      nombre: null,
      abreviatura: null,
      posicion: null,
      errorNombreEstatus: false,
      errorNombre: null,
      errorAbreviaturaEstatus: false,
      errorAbreviatura: null,
      errorPosicionEstatus: false,
      errorposicion: null,
    },
    defaultItem: {
      idBrigadaPuesto: null,
      nombre: null,
      abreviatura: null,
      posicion: null,
      errorNombreEstatus: false,
      errorNombre: null,
      errorAbreviaturaEstatus: false,
      errorAbreviatura: null,
      errorPosicionEstatus: false,
      errorposicion: null,
    },
  }),

  computed: {
    formTitle() {
      return this.editedIndex === -1 ? "Nuevo Puesto" : "Editar Puesto";
    },
  },

  watch: {
    dialog(val) {
      val || this.close();
    },
    dialogDelete(val) {
      val || this.closeDelete();
    },
  },

  created() {
    this.initialize();
  },

  methods: {
    initialize() {
      this.getBrigadasPuestos();
    },

    editItem(item) {
      this.editedIndex = this.brigadabuestos.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialog = true;
    },

    deleteItem(item) {
      this.editedIndex = this.brigadabuestos.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialogDelete = true;
    },

    deleteItemConfirm() {
      axios
        .put("/BrigadaPuestos/Desactivar/" + this.editedItem.idBrigadaPuesto)
        .then((response) => {
          this.dialog = false;
          this.close();
          this.initialize();
        })
        .catch((error) => {
          this.dialog = false;
        });
      this.closeDelete();
    },

    close() {
      this.dialog = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },

    closeDelete() {
      this.dialogDelete = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },
    validacion() {
      if (this.editedItem.nombre == null || this.editedItem.nombre == "") {
        this.editedItem.errorNombreEstatus = true;
        this.editedItem.errorNombre = "Ingrese un nombre para el puesto";
      } else {
        this.editedItem.errorNombreEstatus = null;
        this.editedItem.errorNombre = null;
      }

      if (
        this.editedItem.abreviatura == null ||
        this.editedItem.abreviatura == ""
      ) {
        this.editedItem.errorAbreviaturaEstatus = true;
        this.editedItem.errorAbreviatura = "Ingrese una abreviatura";
      } else {
        this.editedItem.errorAbreviaturaEstatus = null;
        this.editedItem.errorAbreviatura = null;
      }

      if (
        this.editedItem.posicion == null ||
        this.editedItem.posicion == "" ||
        this.editedItem.posicion < 1
      ) {
        this.editedItem.errorPosicionEstatus = true;
        this.editedItem.errorposicion = "Ingrese una posicion mayor a 0";
      } else {
        this.editedItem.errorPosicionEstatus = null;
        this.editedItem.errorposicion = null;
      }
      this.up++;
      if (
        this.editedItem.errorAbreviaturaEstatus == null &&
        this.editedItem.errorPosicionEstatus == null &&
        this.editedItem.errorNombreEstatus == null
      )
        return false;
      else return true;
    },
    save() {
      if (this.validacion()) return;

      var obj = {
        nombre: this.editedItem.nombre,
        abreviatura: this.editedItem.abreviatura,
        posicion: parseInt(this.editedItem.posicion),
      };
      this.loadData = true;
      if (this.editedIndex > -1) {
        axios
          .put(
            "/BrigadaPuestos/Actualizar/" + this.editedItem.idBrigadaPuesto,
            obj
          )
          .then((response) => {
            this.dialog = false;
            this.close();
            this.initialize();
          })
          .catch((error) => {
            /* console.log(error)
            this.dialog = false; */
            if (error.response) {
              if (error.response.status == 409) {
                this.editedItem.errorNombreEstatus = true;
                this.editedItem.errorNombre = "Ingrese un nombre para el puesto diferente";
                this.editedItem.errorAbreviaturaEstatus = true;
                this.editedItem.errorAbreviatura = "Ingrese una abreviatura diferente";
                this.editedItem.errorPosicionEstatus = true;
                this.editedItem.errorposicion = "Ingrese una posicion mayor a 0 diferente";
              }
            }
          });
      } else {
        axios
          .post("/BrigadaPuestos/Crear", obj)
          .then(() => {
            this.dialog = false;
            this.close();
            this.initialize();
          })
          .catch((error) => {
            if (error.response) {
              if (error.response.status == 409) {
                this.editedItem.erroridBrigadaEstatus = true;
                this.editedItem.erroridBrigada =
                  "Selecciona una brigada diferente";
                this.editedItem.erroridPersonaEstatus = true;
                this.editedItem.erroridPersona =
                  "Seleccione una persona diferente";
                this.editedItem.erroridBrigadaPuestoEstatus = true;
                this.editedItem.erroridBrigadaPuesto =
                  "Seleccione un puesto diferente";
              }
            }
          });
      }
      this.close();
    },
    getBrigadasPuestos() {
      this.loadData = true;
      axios
        .get("/BrigadaPuestos")
        .then((response) => {
          this.brigadabuestos = response.data;
          this.loadData = false;
        })
        .catch((error) => {
          console.log(error);
          this.loadData = false;
        });
    },
  },
};
</script>
