<template>
  <v-col cols="12">
    <template>
        <v-dialog v-model="eliminar" persistent max-width="500px" :key="eliminar">
          <v-card>
            <v-card-title class="headline">¿Está seguro que desea eliminar<br/>esta máquina?</v-card-title>
            <v-card-text>Esta acción no se puede revertir y será permanente.</v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="blue darken-1" text @click="eliminar = false; delItem = '';">Cancelar</v-btn>
              <v-btn color="red darken-1" text @click="eliminar = false; deleteItemDB()">Aceptar</v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
    </template>
    <template>
        <v-dialog v-model="dialogAddImg" persistent max-width="500px" :key="dialogAddImg">
          <v-card>
            <v-card-title class="headline">Agregar Nueva Imagen</v-card-title>
            <v-card-text>
              <v-col v-if="erroresImagen.length>0" justify="center" cols="12" sm="12" md="12">
                 <ul>
                    <li class="red--text" v-for="ex in erroresImagen" :key="ex">{{ ex }}</li>
                  </ul>
              </v-col>
              <v-col justify="center" cols="12" sm="12" md="12">
                <v-text-field v-model="uploadImg.descripcion" label="Descripción de la Imagen"></v-text-field>
                <v-file-input v-model="imagenArchivo" @change="vistaPrevia()" accept=".jpg,.jpeg,.png,.gif" label="Selecione una Imagen"></v-file-input>
                Vista Previa de la Imagen:
                <v-img :src="imagenVistaPrevia" :key="imagenVistaPrevia"></v-img>
              </v-col>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="blue darken-1" text @click="dialogAddImg = false; imagenVistaPrevia = ''; imagenArchivo=null; uploadImg = defaultUploadImg; erroresImagen=[];">Cancelar</v-btn>
              <v-btn color="red darken-1" text @click="eliminar = false; agregarImagenDB()">Aceptar</v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
    </template>
    <template>
        <v-dialog v-model="dialogImg" persistent max-width="500px" :key="dialogImg">
          <v-card>
            <v-card-text>
              <br>
              <v-text-field readonly v-model="imagen.descripcion" label="Descripción de la Imagen"></v-text-field>
                <v-card flat tile class="d-flex">
                  <v-img
                    :src="imagen.img"
                    aspect-ratio="1"
                    class="grey lighten-2"
                  >
                    <template v-slot:placeholder>
                      <v-row
                        class="fill-height ma-0"
                        align="center"
                        justify="center"
                      >
                        <v-progress-circular indeterminate color="grey lighten-5"></v-progress-circular>
                      </v-row>
                    </template>
                  </v-img>
                </v-card>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="red darken-1" text @click="dialogImg = false; deleteImgDB();">Eliminar</v-btn>
              <v-btn color="blue darken-1" text @click="dialogImg = false; imagen=defaultImagen;">Aceptar</v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
    </template>
    <template>
        <v-dialog v-model="view" persistent max-width="500px" :key="view">
          <v-card>
            <v-card-title class="headline">Detalles de {{viewedItem.nombre}}</v-card-title>
            <v-card-text>
                <v-container>
                  <v-row>
                    <v-col>
                      <v-autocomplete
                        v-model="viewedItem.esMaquina"
                        label="Tipo"
                        :items="tipos"
                        item-value="value"
                        item-text="name"
                        readonly
                      >
                      </v-autocomplete>
                    </v-col>
                    <v-col cols="12">
                      <v-autocomplete
                        v-model="viewedItem.idMaquinaCategoria"
                        label="Categoría"
                        :items="categorias"
                        item-value="idMaquinaCategoria"
                        item-text="nombre"
                        readonly
                      ></v-autocomplete>
                    </v-col>
                    <v-col class="d-flex" cols="12" sm="12" v-if="!esAlgunaCategoriaEspecifica">
                      <v-autocomplete
                        v-model="viewedItem.idMaquinaTipo"
                        :items="clasificaciones"
                        label="Grupo de Máquinas"
                        item-text="nombre"
                        readonly
                        item-value="idMaquinaTipo"
                      ></v-autocomplete>
                    </v-col>
                    <v-col cols="12" sm="12" md="12" v-if="esAlgunaCategoriaEspecifica">
                      <v-autocomplete
                        v-model="viewedItem.idUbicacion"
                        label="Ubicación"
                        :items="ubicaciones"
                        item-value="idUbicacion"
                        item-text="nombre"
                        readonly
                      >
                      </v-autocomplete>
                    </v-col>
                    <template v-if="!esAlgunaCategoriaEspecifica">
                      <v-col class="d-flex" cols="12" sm="12">
                        <v-autocomplete
                          v-model="viewedItem.idArea"
                          :items="areas"
                          label="Área"
                          item-text="nombre"
                          readonly
                          item-value="idArea"
                        ></v-autocomplete>
                      </v-col>
                    </template>
                    <v-col cols="12" v-if="esCategoriaEspecifica(tiposCategorias[0])">
                      <v-select
                        v-model="viewedItem.tipoBascula"
                        label="Tipo de Bascula"
                        :items="tiposBascula"
                        readonly
                      ></v-select>
                    </v-col>
                    <v-col justify="center" cols="6" sm="6" md="6">
                      <v-text-field readonly v-model="viewedItem.marca" :label="esAlgunaCategoriaEspecifica ? 'Marca' : 'Marca de la '+tipoLabel"></v-text-field>
                    </v-col>
                    <v-col justify="center" cols="6" sm="6" md="6">
                      <v-text-field readonly v-model="viewedItem.modelo" :label="esAlgunaCategoriaEspecifica ? 'Modelo' : 'Modelo de la '+tipoLabel"></v-text-field>
                    </v-col>
                    <v-col cols="12" v-if="esCategoriaEspecifica(tiposCategorias[2])">
                      <v-select
                        v-model="viewedItem.tipoTransporte"
                        label="Tipo de Unidad"
                        :items="tiposTransporte"
                        readonly
                      ></v-select>
                    </v-col>
                    <v-subheader>Número de Personas para Operar esta {{tipoLabel}}</v-subheader>
                    <v-col justify="center" cols="12" sm="12" md="12">
                      <br>
                      <v-slider
                        v-model="viewedItem.numOperadores"
                        min="0"
                        max="10"
                        thumb-label="always"
                        readonly
                      >
                      </v-slider>
                    </v-col>
                    <v-col cols="12" v-if="esCategoriaEspecifica(tiposCategorias[2])">
                      <v-text-field
                        v-model="viewedItem.kilometrajeActual"
                        label="Kilometraje actual"
                        :loading="loadingKilometrajeActual"
                        suffix="Km"
                        readonly
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" v-if="esCategoriaEspecifica(tiposCategorias[2])">
                      <v-text-field
                        v-model="viewedItem.version"
                        label="Versión"
                        readonly
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" v-if="esCategoriaEspecifica(tiposCategorias[2])">
                      <v-text-field
                        v-model="viewedItem.placas"
                        label="Placas"
                        readonly
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" v-if="esAlgunaCategoriaEspecifica">
                      <v-text-field
                        v-model="viewedItem.numSerie"
                        label="Número de serie"
                        readonly
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" v-if="esCategoriaEspecifica(tiposCategorias[2])">
                      <v-text-field
                        v-model="viewedItem.numDeMotor"
                        label="No. de motor"
                        readonly
                      ></v-text-field>
                    </v-col>
                    <v-col justify="center" cols="12" sm="12" md="12" v-if="permisoVista('maquinas','$')">
                      <v-text-field readonly type="number" v-model="viewedItem.costoHora" :label="'Costo Por Hora de la '+tipoLabel"></v-text-field>
                    </v-col>
                    <v-col justify="center" cols="12" sm="12" md="12">
                      <v-text-field readonly v-model="viewedItem.descripcionGeneral" :label="'Descripción General de la '+tipoLabel"></v-text-field>
                    </v-col>
                    <v-col justify="center" cols="12" sm="12" md="12">
                      <v-text-field readonly v-model="viewedItem.descripcionTecnica" :label="'Descripción Técnica de la '+tipoLabel"></v-text-field>
                    </v-col>
                    <v-col justify="center" cols="6" sm="6" md="6">
                      <v-text-field readonly v-model="viewedItem.fechaFabricacion" :label="'Fecha de Fabricación de la '+tipoLabel"></v-text-field>
                    </v-col>
                    <v-col justify="center" cols="6" sm="6" md="6">
                      <v-text-field readonly v-model="viewedItem.fechaCompra" :label="'Fecha de Compra de la '+tipoLabel"></v-text-field>
                    </v-col>
                    <v-col cols="12" v-if="esCategoriaEspecifica(tiposCategorias[2]) && viewedItem.tipoTransporte == 1">
                      <v-text-field
                        v-model="viewedItem.fechaVencimientoVerificacion"
                        label="Fecha de Vencimiento Verificación"
                        readonly
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" v-if="esCategoriaEspecifica(tiposCategorias[2]) && viewedItem.tipoTransporte == 2">
                      <v-text-field
                        v-model="viewedItem.tarjetaMercantilVencimiento"
                        label="Tarjeta Mercantil Vencimiento"
                        readonly
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" v-if="esCategoriaEspecifica(tiposCategorias[0])">
                      <v-text-field
                        v-model="viewedItem.alcanceMaxMedicion"
                        label="Alcance Máximo de Medición"
                        type="number"
                        hide-spin-buttons
                        readonly
                      ></v-text-field>
                    </v-col>
                    <template v-if="esCategoriaEspecifica(tiposCategorias[1])">
                      <v-col cols="12">
                        <v-text-field
                          v-model="viewedItem.motor"
                          label="Motor"
                          readonly
                        ></v-text-field>
                      </v-col>
                      <v-col cols="6">
                        <v-text-field
                          v-model="viewedItem.capacidad"
                          label="Capacidad (libras)"
                          type="number"
                          hide-spin-buttons
                          readonly
                        ></v-text-field>
                      </v-col>
                      <v-col cols="6">
                        <v-text-field
                          v-model="viewedItem.numCilindros"
                          label="Número de cilindros"
                          type="number"
                          hide-spin-buttons
                          readonly
                        ></v-text-field>
                      </v-col>
                      <v-col cols="6">
                        <v-text-field
                          v-model="viewedItem.mastilEtapas"
                          label="Mástil (Etapas)"
                          type="number"
                          hide-spin-buttons
                          readonly
                        ></v-text-field>
                      </v-col>
                      <v-col cols="6">
                        <v-text-field
                          v-model="viewedItem.propuestaNuevaNomenclatura"
                          label="Propuesta de nueva nomenclatura"
                          readonly
                        ></v-text-field>
                      </v-col>
                      <v-col cols="12">
                        <v-autocomplete
                          v-model="viewedItem.idUsuario"
                          label="Validado por"
                          :items="usuarios"
                          item-text="nombre"
                          item-value="idUsuario"
                          readonly
                        ></v-autocomplete>
                      </v-col>
                    </template>
                    <v-col justify="center" cols="12" sm="12" md="12">
                      <v-autocomplete
                        v-model="viewedItem.habilidades"
                        :items="habilidades"
                        label="Habilidades"
                        item-text="nombre"
                        chips
                        multiple
                        readonly
                        item-value="idHabilidad"
                      ></v-autocomplete>
                    </v-col>
                    <v-expansion-panels cols="12" sm="12" md="12" multiple accordion>
                      <v-expansion-panel>
                        <v-expansion-panel-header>Imagenes:</v-expansion-panel-header>
                        <v-expansion-panel-content cols="12" sm="12" md="12">
                          <v-row>
                          <v-col class ="d-flex child-flex" cols="4">
                            <v-card flat tile class="d-flex" outlined @click="agregarImagen()">
                              <v-row
                                class="fill-height ma-0"
                                align="center"
                                justify="center"
                              >
                                <v-icon>add</v-icon>
                              </v-row>
                            </v-card>
                          </v-col>
                          <v-col
                            v-for="img in this.viewedItem.imagenes"
                            :key="img.idImagen"
                            @click="imagenCompleta(img)"
                            class="d-flex child-flex"
                            cols="4"
                          >
                            <v-card flat tile class="d-flex">
                              <v-img
                                :src="img.img"
                                aspect-ratio="1"
                                class="grey lighten-2"
                              >
                                <template v-slot:placeholder>
                                  <v-row
                                    class="fill-height ma-0"
                                    align="center"
                                    justify="center"
                                  >
                                    <v-progress-circular indeterminate color="grey lighten-5"></v-progress-circular>
                                  </v-row>
                                </template>
                              </v-img>
                            </v-card>
                          </v-col>
                          </v-row>
                        </v-expansion-panel-content>
                      </v-expansion-panel>
                      <v-expansion-panel>
                        <v-expansion-panel-header>
                          Operarios
                        </v-expansion-panel-header>
                        <v-expansion-panel-content>
                           <v-simple-table>
                              <thead>
                                <tr>
                                  <th class="text-center">Nombre</th>
                                  <th class="text-center"># Habilidades</th>
                                  <th class="text-center">Porcentaje</th>
                                </tr>
                              </thead>
                              <tbody>
                                <tr v-for="persona in viewedItem.personas" :key="persona.idPersona">
                                  <td class="text-center">{{ getNombreCompleto(persona.idPersona)  }}</td>
                                  <td class="text-center">{{ persona.nHabilidades }}</td>
                                  <td class="text-center">{{ ( isNaN((persona.nHabilidades/persona.hRequeridas)*100) ? 100 : ((persona.nHabilidades/persona.hRequeridas)*100) ).toFixed(2)  }}%</td>
                                </tr>
                              </tbody>
                          </v-simple-table>
                        </v-expansion-panel-content>
                      </v-expansion-panel>
                    </v-expansion-panels>
                  </v-row>
                </v-container>
              </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="red darken-1" text @click="close()" :disabled="loadingKilometrajeActual">Aceptar</v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
    </template>
    <v-row>

      <v-col class="d-flex justify-start" cols="6"><v-btn color="primary" :disabled="loading" dark class="mb-2" @click="dialog=true" v-if="permisoVista('maquinas','c')">Nueva Máquina/Herramienta</v-btn></v-col>
      <v-col class="d-flex justify-end" cols="6">
        
          <DownloadBtn
          text
          color="primary"  
          @click="descargarFormato()"
          label="Descargar formato de <br> carga masiva"
        />

        <v-btn
					
					text
					color="primary"
					class="mr-3"
					v-on="on"
					:disabled="loadingSubir || loadingDescargar"
					:loading="loadingSubir"
					@click="cargaMasiva = true"
				>
					subir archivo para <br> Carga Masiva
				</v-btn>
        
        </v-col>
      <v-col cols="12">
        <v-data-table
          v-if="permisoVista('maquinas','r')"
          :headers="computedHeaders"
          :items="maquinas"
          :search="search"
          :loading="loading"
          loading-text="Cargando... Espere, Por Favor."
      >
      <template v-slot:top>
        <v-toolbar flat color="white">
          <v-text-field
            v-model="search"
            append-icon="search"
            label="Buscar"
            single-line
            hide-details
          ></v-text-field>
        </v-toolbar>
      </template>
      <template v-slot:[`item.idUbicacion`]="{ item }">
        {{ getNombreUbicacion(item.idUbicacion) }}
      </template>
      <template v-slot:[`item.maquinaTipo`]="{ item }">
        {{ `${item.maquinaTipo==null?'N/A':item.maquinaTipo}` }}
      </template>
      <template v-slot:[`item.action`]="{ item }">
        <v-tooltip bottom color="black" class="white--text">
          <template v-slot:activator="{ on }">
        <v-icon v-on="on"
          v-if="permisoVista('maquinas','r')"
          small
          :disabled="loading"
          class="mr-2"
          @click="viewItem(item)"
        >
          mdi-eye
        </v-icon>
         </template>
          <span class="white--text">Ver detalles</span>
        </v-tooltip>
        <v-tooltip bottom color="black" class="white--text">
          <template v-slot:activator="{ on }">
        <v-icon v-on="on"
          v-if="permisoVista('maquinas','u')"
          small
          :disabled="loading"
          class="mr-2"
          @click="editItem(item)"
        >
          edit
        </v-icon>
         </template>
          <span class="white--text">Editar</span>
        </v-tooltip>
        <v-tooltip bottom color="black" class="white--text">
          <template v-slot:activator="{ on }">
        <v-icon v-on="on"
          v-if="permisoVista('maquinas','d')"
          small
          :disabled="loading"
          @click="deleteItem(item)"
        >
          delete
        </v-icon>
         </template>
          <span class="white--text">Eliminar</span>
        </v-tooltip>
      </template>
      <template v-slot:no-data>
        <v-btn color="primary" @click="initialize">Refrescar</v-btn>
      </template>
    </v-data-table>
      </v-col>
      <v-dialog v-model="dialog" persistent max-width="500px" :key="dialog">
            <v-card>
              <v-card-title>
                <span class="headline">{{ formTitle }}</span>
              </v-card-title>
              <v-card-text>
                <v-container>
                  <v-row>
                    <v-col justify="center" cols="12" sm="12" md="12">
                      <v-autocomplete
                        v-model="editedItem.esMaquina"
                        label="Tipo"
                        :items="tipos"
                        item-value="value"
                        item-text="name"
                        @change="resetIdMaquina()"
                        :error-messages="erroresItem.errorEsMaquina"
                        @focus="erroresItem.errorEsMaquina = null"
                      >
                      </v-autocomplete>
                    </v-col>
                    <v-col justify="center" cols="12" sm="12" md="12">
                      <v-autocomplete
                        v-model="editedItem.idMaquinaCategoria"
                        label="Categoría"
                        :items="categorias"
                        item-value="idMaquinaCategoria"
                        item-text="nombre"
                        :error-messages="erroresItem.erroridMaquinaCategoria"
                        @focus="erroresItem.erroridMaquinaCategoria = null"
                        @change="onChangeCategoria()"
                      >
                      </v-autocomplete>
                    </v-col>
                    <v-col cols="12" sm="12" md="12" v-if="esAlgunaCategoriaEspecifica">
                      <v-autocomplete
                        v-model="editedItem.idUbicacion"
                        label="Ubicación"
                        :items="ubicaciones"
                        item-value="idUbicacion"
                        item-text="nombre"
                        :error-messages="erroresItem.errorUbicacion"
                        @focus="erroresItem.errorUbicacion = null"
                      >
                      </v-autocomplete>
                    </v-col>
                    <v-col class="d-flex" cols="11" sm="11">
                      <v-autocomplete
                        v-model="editedItem.idMaquina"
                        :items="catMaquinasFiltrado"
                        :label="esAlgunaCategoriaEspecifica ? 'Modelos' : 'Modelos de '+tipoLabel+'s'"
                        :item-text="marcaModelo"
                        :persistent-hint="editedItem.idMaquinaCategoria == null"
                        :hint="editedItem.idMaquinaCategoria == null ? 'Debe seleccionar una Categoría' : ''"
                        :disabled="loadingArea || editedItem.idMaquinaCategoria == null"
                        :loading="loadingArea"
                        item-value="idMaquina"
                        :error-messages="erroresItem.erroridMaquina"
                        @focus="erroresItem.erroridMaquina = null"
                      >
                        <template v-slot:item="data">
                          <v-list-item-content>
                            <v-list-item-title>
                              {{ `${data.item.marca} - ${data.item.modelo}` }}
                            </v-list-item-title>
                            <v-list-item-subtitle>
                              {{ data.item.descripcionGeneral }}
                            </v-list-item-subtitle>
                          </v-list-item-content>
                        </template>
                      </v-autocomplete>
                    </v-col>
                    <v-icon
                      class="mr-2"
                      :disabled="loadingArea"
                      @click="getCatMaquinas()"
                    >
                      mdi-reload
                    </v-icon>
                    <v-col cols="12" v-if="esCategoriaEspecifica(tiposCategorias[2])">
                      <v-select
                        v-model="editedItem.tipoTransporte"
                        label="Tipo de Unidad"
                        :items="tiposTransporte"
                        :disabled="saving"
                        :error-messages="erroresItem.errorTipoTransporte"
                        @focus="erroresItem.errorTipoTransporte = null"
                        @change="onChangeTipoTransporte"
                      ></v-select>
                    </v-col>
                    <template v-if="!esAlgunaCategoriaEspecifica">
                      <v-col class="d-flex" cols="11" sm="11">
                        <v-autocomplete
                          v-model="editedItem.idMaquinaTipo"
                          :items="clasificaciones"
                          label="Grupo de Máquinas"
                          item-text="nombre"
                          :disabled="loadingClasificacion"
                          :loading="loadingClasificacion"
                          item-value="idMaquinaTipo"
                        ></v-autocomplete>
                      </v-col>
                      <v-icon
                        class="mr-2"
                        :disabled="loadingClasificacion"
                        @click="getClasificaciones()"
                      >
                        mdi-reload
                      </v-icon>
                    </template>
                    <template v-if="!esAlgunaCategoriaEspecifica">
                      <v-col class="d-flex" cols="11" sm="11">
                        <v-autocomplete
                          v-model="editedItem.idArea"
                          :items="areas"
                          label="Área"
                          item-text="nombre"
                          :disabled="loadingArea"
                          :loading="loadingArea"
                          item-value="idArea"
                          :error-messages="erroresItem.erroridArea"
                          @focus="erroresItem.erroridArea = null"
                        ></v-autocomplete>
                      </v-col>
                      <v-icon
                        class="mr-2"
                        :disabled="loadingArea"
                        @click="getAreas()"
                      >
                        mdi-reload
                      </v-icon>
                    </template>
                    <v-col justify="center" cols="12" sm="12" md="12">
                      <v-text-field 
                        v-model="editedItem.nombre" 
                        :label="computedLabel"
                        :error-messages="erroresItem.errornombre"
                        @focus="erroresItem.errornombre = null"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" v-if="esCategoriaEspecifica(tiposCategorias[0])">
                      <v-select
                        v-model="editedItem.tipoBascula"
                        label="Tipo de Bascula"
                        :items="tiposBascula"
                        :disabled="saving"
                        :error-messages="erroresItem.errorTipoBascula"
                        @focus="erroresItem.errorTipoBascula = null"
                      ></v-select>
                    </v-col>
                    <v-col cols="12" v-if="esCategoriaEspecifica(tiposCategorias[2])">
                      <v-text-field
                        v-model="editedItem.version"
                        label="Versión"
                        clearable
                        :disabled="saving"
                        :error-messages="erroresItem.errorVersion"
                        @focus="erroresItem.errorVersion = null"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" v-if="esCategoriaEspecifica(tiposCategorias[2])">
                      <v-text-field
                        v-model="editedItem.placas"
                        label="Placas"
                        :disabled="saving"
                        :error-messages="erroresItem.errorPlacas"
                        @focus="erroresItem.errorPlacas = null"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" v-if="esAlgunaCategoriaEspecifica">
                      <v-text-field
                        v-model="editedItem.numSerie"
                        label="Número de serie"
                        :disabled="saving"
                        :error-messages="erroresItem.errorNumSerie"
                        @focus="erroresItem.errorNumSerie = null"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" v-if="esCategoriaEspecifica(tiposCategorias[2])">
                      <v-text-field
                        v-model="editedItem.numDeMotor"
                        label="No. de motor"
                        :disabled="saving"
                        :error-messages="erroresItem.errorNumMotor"
                        @focus="erroresItem.errorNumMotor = null"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="6">
                      <MenuDatepicker
                        v-model="editedItem.fechaFabricacion"
                        :label="`Fecha de Fabricación de la ${tipoLabel}`"
                        :max-date="today"
                        clearable
                        :disabled="saving"
                        :errorMessage="erroresItem.errorfechaFabricacion"
                        @focus="erroresItem.errorfechaFabricacion = null"
                      />
                    </v-col>
                    <v-col cols="6">
                      <MenuDatepicker
                        v-model="editedItem.fechaCompra"
                        :label="`Fecha de Compra de la ${tipoLabel}`"
                        :max-date="today"
                        clearable
                        :disabled="saving"
                        :errorMessage="erroresItem.errorfechaCompra"
                        @focus="erroresItem.errorfechaCompra = null"
                      />
                    </v-col>
                    <v-col cols="12" v-if="esCategoriaEspecifica(tiposCategorias[2]) && editedItem.tipoTransporte == 1">
                      <MenuDatepicker
                        v-model="editedItem.fechaVencimientoVerificacion"
                        label="Fecha de Vencimiento Verificación"
                        clearable
                        :disabled="saving"
                      />
                    </v-col>
                    <v-col cols="12" v-if="esCategoriaEspecifica(tiposCategorias[2]) && editedItem.tipoTransporte == 2">
                      <MenuDatepicker
                        v-model="editedItem.tarjetaMercantilVencimiento"
                        label="Tarjeta Mercantil Vencimiento"
                        clearable
                        :disabled="saving"
                      />
                    </v-col>
                    <v-col cols="12" v-if="esCategoriaEspecifica(tiposCategorias[0])">
                      <v-text-field
                        v-model="editedItem.alcanceMaxMedicion"
                        label="Alcance Máximo de Medición"
                        type="number"
                        hide-spin-buttons
                        :disabled="saving"
                        :error-messages="erroresItem.errorAlcanceMaxMed"
                        @focus="erroresItem.errorAlcanceMaxMed = null"
                      ></v-text-field>
                    </v-col>
                    <template v-if="esCategoriaEspecifica(tiposCategorias[1])">
                      <v-col cols="12">
                        <v-autocomplete-textfield
                          v-model="editedItem.motor"
                          :items="motoresMontacargas"
                          label="Motor"
                          :loading="loadingMotoresMontacargas"
                          :disabled="saving"
                          @click:refresh="getMotoresMontacargas"
                          :errorMessage="erroresItem.errorMotor"
                          @update:error-message="erroresItem.errorMotor = $event"
                        ></v-autocomplete-textfield>
                      </v-col>
                      <v-col cols="6">
                        <v-text-field
                          v-model="editedItem.capacidad"
                          label="Capacidad (libras)"
                          type="number"
                          hide-spin-buttons
                          :disabled="saving"
                          :error-messages="erroresItem.errorCapacidad"
                          @focus="erroresItem.errorCapacidad = null"
                        ></v-text-field>
                      </v-col>
                      <v-col cols="6">
                        <v-text-field
                          v-model="editedItem.numCilindros"
                          label="No. de cilindros"
                          type="number"
                          hide-spin-buttons
                          :disabled="saving"
                          :error-messages="erroresItem.errorNumCilindros"
                          @focus="erroresItem.errorNumCilindros = null"
                        ></v-text-field>
                      </v-col>
                      <v-col cols="6">
                        <v-text-field
                          v-model="editedItem.mastilEtapas"
                          label="Mástil (Etapas)"
                          type="number"
                          hide-spin-buttons
                          :disabled="saving"
                          :error-messages="erroresItem.errorMastilEtapas"
                          @focus="erroresItem.errorMastilEtapas = null"
                        ></v-text-field>
                      </v-col>
                      <v-col cols="6">
                        <v-text-field
                          v-model="editedItem.propuestaNuevaNomenclatura"
                          label="Propuesta de nueva nomenclatura"
                          clearable
                          :disabled="saving"
                        ></v-text-field>
                      </v-col>
                      <v-col cols="12">
                        <v-autocomplete
                          v-model="editedItem.idUsuario"
                          label="Validado por"
                          :items="usuarios"
                          item-text="nombre"
                          item-value="idUsuario"
                          :loading="loadingUsuarios"
                          :disabled="saving || loadingUsuarios"
                          :error-messages="erroresItem.errorUsuario"
                          @focus="erroresItem.errorUsuario = null"
                        ></v-autocomplete>
                      </v-col>
                    </template>
                    <template v-if="!esAlgunaCategoriaEspecifica">
                      <v-col cols="12">
                        <InputToken
                          :key="`intok1-${chkey}`"
                          v-model="editedItem.token"
                          label="Token 1"
                          appendIcon1="mdi-lock"
                          appendIcon2="mdi-lock-open"
                          :locked="editedIndex!=-1"
                          :error-messages="erroresItem.errortoken"
                          @focus="erroresItem.errortoken = null"
                          @input="editedItem.token=$event"
                        />
                      </v-col>
                      <v-col cols="12">
                        <InputToken
                          :key="`intok2-${chkey}`"
                          v-model="editedItem.token2"
                          label="Token 2"
                          appendIcon1="mdi-lock"
                          appendIcon2="mdi-lock-open"
                          :locked="editedIndex!=-1"
                          :error-messages="erroresItem.errortoken2"
                          @focus="erroresItem.errortoken2 = null"
                          @input="editedItem.token2=$event"
                        />
                      </v-col>
                    </template>
                  </v-row>
                  <ul>
                    <li class="red--text" v-for="ex in errores" :key="ex">{{ ex }}</li>
                  </ul>
                </v-container>
              </v-card-text>

              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="blue darken-1" text @click="dialog=false; errores=[];">Cancelar</v-btn>
                <v-btn color="red darken-1" text @click="save()" :disabled="saving" :loading="saving">
                  Guardar
                  <template v-slot:loader>
                    <v-progress-circular
                      indeterminate
                      :width="2"
                      :size="24"
                      color="red"
                    ></v-progress-circular>
                  </template>
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
    </v-row>
    <v-dialog
			v-model="cargaMasiva"
			persistent
			max-width="500px"
			key="cargaMasiva"
		>
			<v-card>
				<v-card-title
					>Carga Masiva de Inventario</v-card-title
				>
				<v-card-text v-if="errorCargaMasiva">
					<v-col cols="12" md="12" sm="12" class="text-center">
						<h5>Se ha producido un error. Intente una vez más.</h5>
					</v-col>
					<v-col cols="12" md="12" sm="12" class="text-center">
						<v-btn
							depressed
							color="red"
							text
							@click="
								cargaMasiva = false;
								ficheroCarga = null;
								cargaCargaCompleta = false;
								loadingCargaMasiva = false;
								errorCargaMasiva = false;
								selectedUpdateFile = null;
							"
							>Cerrar</v-btn
						>
					</v-col>
				</v-card-text>
				<v-card-text v-if="cargaCargaCompleta">
					<v-col cols="12" md="12" sm="12" class="text-center">
						<!-- errores -->
            <h4 v-if="erroresCarga.length <= 0">Carga Realizada Correctamente</h4>
            <h4 v-else>Uno o varios errores al intentar la cargar</h4>
            <template v-if="erroresCarga.length > 0">
              <template v-for="(item, index) in erroresCarga">
                <p class="mb-1" :key="index" style="font-size: 16px !important">
                  <v-icon color="red">mdi-alert-circle-outline</v-icon> {{item}}
                </p>
                <v-divider :key="`divider-${index}`" />
              </template>
            </template>
					</v-col>
					<v-col cols="12" md="12" sm="12" class="text-center">
						<v-btn
							depressed
							color="success"
							text
							@click="
								cargaMasiva = false;
								ficheroCarga = null;
								cargaCargaCompleta = false;
								loadingCargaMasiva = false;
								errorCargaMasiva = false;
								selectedUpdateFile = null;
							"
							>Cerrar</v-btn
						>
					</v-col>
				</v-card-text>
				<v-card-text v-if="loadingCargaMasiva && !cargaCargaCompleta">
					<v-col cols="12" md="12" sm="12" class="text-center">
						<h4>Cargando</h4>
					</v-col>
					<v-col cols="12" md="12" sm="12" class="text-center">
						<v-progress-circular
							:size="75"
							color="green"
							indeterminate
						></v-progress-circular>
					</v-col>
				</v-card-text>
				<v-card-text
					v-if="
						!loadingCargaMasiva &&
						!cargaCargaCompleta &&
						!errorCargaMasiva
					"
					class="text-center"
				>
					<v-file-input
						v-model="ficheroCarga"
						accept=".csv"
						@change="cambioficheroCompras"
						show-size
						label="Fichero Carga .CSV"
					></v-file-input>

					<br />
					<br />
					<v-row>
						
						<v-btn
							color="blue darken-1"
							text
							@click="
								cargaMasiva = false;
								ficheroCarga = null;
								cargaCargaCompleta = false;
								loadingCargaMasiva = false;
								errorCargaMasiva = false;
								selectedUpdateFile = null;
							"
							>Cancelar</v-btn
						>
						<v-btn
							color="red darken-1"
							text
							@click="cargaMasivaPost"
							>Aceptar</v-btn
						>
					</v-row>
				</v-card-text>
			</v-card>
		</v-dialog>
    <v-snackbar
      v-model="alerta"
      top
      color="error"
    >
      Existe una Máquina/Herramienta Activa con el mismo código/numero de serie
      <v-btn
        text
        @click="alerta = false"
      >
        Cerrar
      </v-btn>
    </v-snackbar>
  </v-col>
</template>

<script>
import axios from "axios";
import InputToken from './InputToken.vue';
import DownloadBtn from '@/components/DownloadBtn.vue';
import { mapState } from "vuex"
import moment from "moment";
import VAutocompleteTextfield from '../../VAutocompleteTextfield.vue';
import MenuDatepicker from '../../MenuDatepicker.vue';

  export default {
    components: {
      InputToken,
      DownloadBtn,
      VAutocompleteTextfield,
      MenuDatepicker
    },
    data: () => ({
      loadingKilometrajeActual: false,
      loadingMotoresMontacargas: false,
      motoresMontacargas: [],
      loadingAgencias: false,
      agencias: [],
      loadingModificaciones: false,
      modificaciones: [],
      tiposTransporte: [
        {
          text: "UTILITARIA",
          value: 1
        },
        {
          text: "MERCANTIL",
          value: 2
        }
      ],
      tiposCategorias: ['bascula','montacargas','transporte'],
      tiposBascula: [
        {
          text: "PLATO O PLATAFORMA",
          value: 1
        },
        {
          text: "MODULO",
          value: 2
        }
      ],
      loadingUsuarios: false,
      usuarios: [],
      ubicaciones: [],
      erroresItem: {
        errorNumSerie: null,
        errorAlcanceMaxMed: null,
        errorEsMaquina: null,
        erroridMaquinaCategoria: null,
        erroridArea: null,
        erroridMaquina: null,
        errornombre: null,
        errorfechaFabricacion: null,
        errorfechaCompra: null,
        errortoken: null,
        errortoken2: null,
        errorCapacidad: null,
        errorMotor: null,
        errorNumCilindros: null,
        errorMastilEtapas: null,
        errorUsuario: null,
        errorTipoBascula: null,
        errorUbicacion: null,
        errorTipoTransporte: null,
        errorVersion: null,
        errorPlacas: null,
        errorNumMotor: null
      },
      chkey: 0,
      saving: false,
      tipos:[
        {
          name:'Máquina',
          value:true
        },
        {
          name:'Herramienta',
          value:false
        }
      ],
      loadingCatMaquinas: false,
      catMaquinas: [],
      loadingHabilidades: false,
      habilidades: [],
      loadingOperador: false,
      personas: [],
      alerta:false,
      imagenVistaPrevia: '',
      imagenArchivo: null,
      dialogAddImg: false,
      dialogImg: false,
      areas:[],
      erroresCarga:[],
      defaultImagen:{
        img: '',
        ruta: '',
        idImagen: '',
        descripcion: 'Cargando... Espere por Favor',
      },
      imagen: {
        img: '',
        ruta: '',
        idImagen: '',
        descripcion: 'Cargando... Espere por Favor',
      },
      loadingArea: false,
      dialog: false,
      eliminar: false,
      view: false,
      search: '',
      headers: [
        {
          text: 'Tipo',
          align: 'left',
          sortable: true,
          value: 'tipo',
        },
        {
          text: 'Área',
          align: 'left',
          sortable: true,
          value: 'area',
        },
        {
          text: 'Categoría',
          align: 'left',
          sortable: true,
          value: 'categoria',
        },
        {
          text: 'Grupo',
          align: 'left',
          sortable: true,
          value: 'maquinaTipo',
        },
        {
          text: 'Nombre/Código',
          align: 'left',
          sortable: true,
          value: 'nombre',
        },
        {
          text: 'Marca',
          align: 'left',
          sortable: true,
          value: 'marca',
        },
        {
          text: 'Modelo',
          align: 'left',
          sortable: true,
          value: 'modelo',
        },
        {
          text: 'Descripción General',
          align: 'left',
          sortable: true,
          value: 'descripcionGeneral',
        },
        { text: 'Acciones', value: 'action', sortable: false, width:'15%', align:'center' },
      ],
      loading: false,
      maquinas: [],
      delItem: '',
      action: 0,
      errores: [],
      erroresImagen:[],
      uploadImg:{
        idMaquina: '',
        mime: '',
        imagen:'',
        descripcion:'',
      },
      defaultUploadImg:{
        idMaquina: '',
        mime: '',
        imagen:'',
        descripcion:'',
      },
      viewedIndex: -1,
      viewedItem: null,
      editedIndex: -1,
      editedItem: null,
      defaultItem: {
        idArea:-1,
        idMaquina:-1,
        idUsuario: null,
        idMaquinaCategoria: null,
        idUbicacion: null,
        categoria: '',
        marca:'',
        modelo:'',
        numOperadores:0,
        descripcionGeneral:'',
        descripcionTecnica:'',
        costoHora:0.0,
        habilidades: [],
        numSerie: null,
        idMaquinaTipo: null,
        nombre: '',
        esMaquina:true,
        fechaFabricacion:null,
        fechaCompra:null,
        imagenes: [],
        token: "",
        token2: "",
        alcanceMaxMedicion: null,
        capacidad: null,
        motor: null,
        numCilindros: null,
        mastilEtapas: null,
        propuestaNuevaNomenclatura: null,
        tipoBascula: null,
        tipoTransporte: null,
        version: null,
        placas: null,
        numDeMotor: null,
        fechaVencimientoVerificacion: null,
        tarjetaMercantilVencimiento: null,
        kilometrajeActual: null
      },
      categorias:[],
      loadingCategoria: false,
      clasificaciones:[],
      loadingClasificacion: false,
      cargaMasiva: false,
			ficheroCarga: null,
			cargaCargaCompleta: false,
			loadingCargaMasiva: false,
			errorCargaMasiva: false,
			selectedUpdateFile: null,
    }),

    computed: {
      ...mapState(["token","nombreEmpresa"]),
      esIndasa(){
        return this.nombreEmpresa.toUpperCase() == "INDASA";
      },
      computedHeaders(){
        let headers = this.headers.slice();
        if(this.esIndasa){
          let customHeader = headers.find(header => header.value == 'nombre');
          customHeader.text = 'Número Económico';
          headers = headers.filter((header) => header.value != 'tipo' && header.value != 'area');
          headers.unshift({
            text: 'Ubicación',
            align: 'left',
            sortable: true,
            value: 'idUbicacion',
          });
        }
        return headers;
      },
      today(){
        return (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substring(0, 10);
      },
      computedLabel(){
        let label = `Nombre / Código de la ${this.tipoLabel}`;
        if(this.esAlgunaCategoriaEspecifica) label = `Número Económico`;
        return label;
      },
      esAlgunaCategoriaEspecifica(){
        const currentObject = this.view ? 'viewedItem' : 'editedItem';
        const categoria = this.categorias.find( (categoria) => categoria.idMaquinaCategoria == this[currentObject].idMaquinaCategoria );
        if(!categoria) return false;
        const nombreCategoria = categoria.nombre.toLowerCase().trim();
        return (nombreCategoria.includes(this.tiposCategorias[0]) || nombreCategoria.includes(this.tiposCategorias[1]) || nombreCategoria.includes(this.tiposCategorias[2]));
      },
      formTitle () {
        return this.editedIndex === -1 ? 'Nueva '+this.tipoLabel : 'Editar '+this.tipoLabel
      },
      tipoLabel(){
        if(this.view){
          return  this.viewedItem.esMaquina?'Máquina' : 'Herramienta';
        }
        if(this.dialog){
          return  this.editedItem.esMaquina?'Máquina' : 'Herramienta';
        }
      },
      catMaquinasFiltrado(){
        let me = this;
        return this.catMaquinas.filter(function(el) {
          return (el.esMaquina === me.editedItem.esMaquina && el.idMaquinaCategoria == me.editedItem.idMaquinaCategoria);
        });
      },
    },

    watch: {
      dialog (val) {
        val || this.close()
      },
    },
    beforeMount(){
      if(this.esIndasa){
        this.headers = this.headers.filter( header => header.value != 'maquinaTipo' );
      }
      this.editedItem = structuredClone(this.defaultItem);
      this.viewedItem = structuredClone(this.defaultItem);
    },
    created () {
      this.initialize();
    },
    methods: {
      getNombreUbicacion(idUbicacion){
        const ubicacion = this.ubicaciones.find( ubicacion => ubicacion.idUbicacion == idUbicacion );
        return ubicacion?.nombre || "N/A";
      },
      onChangeTipoTransporte(){
        this.editedItem.fechaVencimientoVerificacion = null;
        this.editedItem.tarjetaMercantilVencimiento = null;
      },
      esCategoriaEspecifica(nombreCategoria){
        const currentObject = this.view ? 'viewedItem' : 'editedItem';
        const categoria = this.categorias.find( (categoria) => categoria.idMaquinaCategoria == this[currentObject].idMaquinaCategoria );
        return categoria?.nombre.includes(nombreCategoria.trim().toUpperCase()) || false;
      },
      resetValuesCategoria(){
        this.editedItem.numEconomico = null;
        this.editedItem.alcanceMaxMedicion = null;
        this.erroresItem.errorAlcanceMaxMed = null;
        this.editedItem.motor = null;
        this.erroresItem.errorMotor = null;
        this.editedItem.capacidad = null;
        this.erroresItem.errorCapacidad = null;
        this.editedItem.numCilindros = null;
        this.erroresItem.errorNumCilindros = null;
        this.editedItem.mastilEtapas = null;
        this.erroresItem.errorMastilEtapas = null;
        this.editedItem.propuestaNuevaNomenclatura = null;
        this.editedItem.idUsuario = null;
        this.erroresItem.errorUsuario = null;
        this.editedItem.tipoBascula = null;
        this.erroresItem.errorTipoBascula = null;
        this.editedItem.tipoTransporte = null;
        this.erroresItem.errorTipoTransporte = null;
        this.editedItem.version = null;
        this.erroresItem.errorVersion = null;
        this.editedItem.placas = null;
        this.erroresItem.errorPlacas = null;
        this.editedItem.numDeMotor = null;
        this.erroresItem.errorNumMotor = null;
        this.onChangeTipoTransporte();
      },
      onChangeCategoria(){
        this.resetIdMaquina();
        if(this.esAlgunaCategoriaEspecifica){
          this.editedItem.idArea = 1;
          this.resetValuesCategoria();
        }
        else {
          this.editedItem.idArea = -1;
        }
      },
      marcaModelo: item => item.marca +' - '+item.modelo,
      resetIdMaquina(){
        this.editedItem.idMaquina = -1;
      },
      getNombreCompleto(idPersona){
        var o = this.personas.find(x => x.idPersona == idPersona);
        if(o != null) return o.nombre+" "+o.paterno+" "+o.materno;
        else return "";
      },
      agregarImagenDB(){
        let me = this;
        let mime = me.imagenVistaPrevia.substring(5,me.imagenVistaPrevia.indexOf(";"));
        let data = me.imagenVistaPrevia.substring(me.imagenVistaPrevia.indexOf("base64,")+7);
        me.erroresImagen = [];
        
        if(data == null || data == '' || mime == null || mime == ''){
          me.erroresImagen.push('Falta Seleccionar una Imagen');
        }

        if(me.uploadImg.descripcion.length<5){
          me.erroresImagen.push('La Descripción debe de tener al menos de 5 caracteres');
        }

        if(me.erroresImagen.length>0){
          return;
        }
        axios
          .post("/MaquinaInstancias/AgregarImagen", {
            IdMaquinaIns: me.viewedItem.idMaquinaIns,
            Mime:mime,
            Imagen: data,
            Descripcion:me.uploadImg.descripcion
          })
          .then(response =>  {
            me.dialogAddImg = false;
            me.imagenVistaPrevia = '';
            me.imagenArchivo=null;
            me.uploadImg = me.defaultUploadImg;
            me.getThumbnails(me.viewedItem.idMaquinaIns);
          })
          .catch(error => {
            console.log(error);
        });
      },
      getBase64(file) {
        return new Promise((resolve, reject) => {
          const reader = new FileReader();
          reader.readAsDataURL(file);
          reader.onload = () => resolve(reader.result);
          reader.onerror = error => reject(error);
        });
      },
      vistaPrevia(){
        let me = this;
        if(me.imagenArchivo != null){
          this.getBase64(me.imagenArchivo).then(
            data => {
              me.imagenVistaPrevia = data;
            }
          );
        }
        else{
          me.imagenVistaPrevia = '';
        }
      },
      agregarImagen(){
        this.dialogAddImg = true;
      },
      getCatMaquinas (){
        let me = this;
        me.loadingCatMaquinas = true;
        axios
        .get("/Maquinas/Basico")
        .then(response =>  {
          me.catMaquinas = response.data;
          me.loadingCatMaquinas = false;
        })
        .catch(error => {
          console.log(error);
        });
      },
      getAreas (){
        let me = this;
        me.loadingArea = true;
        axios
        .get("/Areas")
        .then(response =>  {
          me.areas = response.data;
          me.loadingArea = false;
        })
        .catch(error => {
          console.log(error);
        });
      },
      getClasificaciones(){
        this.loadingClasificacion = true;
        axios
        .get("/MaquinaTipos")
        .then(response =>  {
          this.clasificaciones = response.data;
          this.loadingClasificacion = false;
        })
        .catch(error => {
          this.loadingClasificacion = false;
          console.log(error);
        });
      },
      getCategorias(){
        this.loadingCategoria = true;
        axios
        .get("/MaquinaCategorias")
        .then(response =>  {
          response.data.push({
            idMaquinaCategoria: null,
            nombre: "SIN ASIGNAR",
            descripcion:"",
            condicion: true,
          });
          this.categorias = response.data;
          this.loadingCategoria = false;
        })
        .catch(error => {
          console.log(error);
        });
      },
      getOperadores (){
        let me = this;
        me.loadingOperador = true;
        axios
        .get("/Personas/OperadoresBasico")
        .then(response =>  {
          me.personas = response.data;
          me.loadingOperador = false;
        })
        .catch(error => {
          console.log(error);
        });
      },
      getHabilidades (){
        let me = this;
        me.loadingHabilidades = true;
        axios
        .get("/Habilidades")
        .then(response =>  {
          me.habilidades = response.data;
          me.loadingHabilidades = false;
        })
        .catch(error => {
          console.log(error);
        });
      },
      imagenCompleta(item){
        let me = this;
        me.dialogImg = true;
        axios
        .get("/MaquinaInstancias/ImagenCompleta/"+me.viewedItem.idMaquinaIns+"/imagen/"+item.idImagen)
        .then(response =>  {
          me.imagen = response.data;
        })
        .catch(error => {
          console.log(error);
        });
      },
      getMotoresMontacargas(){
        this.loadingMotoresMontacargas = true;
        axios
          .get('/MaquinaInstancias/GetMotoresMontacargas')
          .then( (response) => {
            this.motoresMontacargas = response.data;
          })
          .catch(console.log)
          .finally( () => {
            this.loadingMotoresMontacargas = false;
          });
      },
      initialize () {
        this.loading = true;
        this.getAreas();
        this.getHabilidades();
        this.getOperadores();
        this.getCatMaquinas();
        this.getCategorias();
        this.getClasificaciones();
        this.getUbicaciones();
        this.getUsuarios();
        this.getMotoresMontacargas();

        axios
        .get("/MaquinaInstancias")
        .then(response =>  {
          this.maquinas = response.data;
          this.loading = false;
        })
        .catch(error => {
          console.log(error);
        });
      },
      getKilometrajeActual(idMaquinaInstancia){
        this.loadingKilometrajeActual = true;

        axios
          .get('/Mantenimientos/GetUltimoKilometrajeReportado',{
            params: { idMaquinaInstancia }
          })
          .then((response) => {
            this.viewedItem.kilometrajeActual = this.$utils.strings.formatearCifra(response.data);
          })
          .catch((error) => {
            console.log(error);
          })
          .finally(() => {
            this.loadingKilometrajeActual = false;
          });
      },
      getUsuarios(){
        this.loadingUsuarios = true;

        axios
          .get('/Usuarios')
          .then( (response) => {
            this.usuarios = response.data;
          })
          .catch(console.log)
          .finally( () => {
            this.loadingUsuarios = false;
          })
      },
      getUbicaciones(){
        axios
          .get('/Ubicaciones')
          .then( (response) => {
            this.ubicaciones = response.data;
          })
          .catch(console.log);
      },
      getThumbnails(idMaquina){
        let me = this;
        axios
        .get("/MaquinaInstancias/GetThumbnails/"+idMaquina)
        .then(response =>  {
          me.viewedItem.imagenes = response.data;
        })
        .catch(error => {
          console.log(error);
        });
      },
      viewItem (item) {
        this.viewedIndex = this.maquinas.indexOf(item)
        this.viewedItem = structuredClone(item);
        this.getThumbnails(this.viewedItem.idMaquinaIns);
        this.getKilometrajeActual(item.idMaquinaIns);
        this.view = true
      },
      editItem (item) {
        this.action = 1;
        this.editedIndex = this.maquinas.indexOf(item)
        this.editedItem = structuredClone(item);
        this.dialog = true
      },

      deleteItem (item) {
        this.eliminar = true;
        this.delItem = structuredClone(item);
      },
      deleteImgDB(){
        let me = this;
        axios
        .delete("/MaquinaInstancias/EliminarImagen/"+me.viewedItem.idMaquinaIns+"/imagen/"+me.imagen.idImagen)
        .then(response =>  {
          me.imagen=me.defaultImagen;
          me.getThumbnails(me.viewedItem.idMaquinaIns);
        })
        .catch(error => {
          console.log(error);
        });
      },
      deleteItemDB(){
        let me = this;
        let item = this.delItem;
        this.loading = true;
        this.delItem = '';
        axios
          .put("/MaquinaInstancias/Desactivar/" + item.idMaquinaIns)
          .then(response =>  {
            me.initialize();
          })
          .catch(error => {
            this.loading = false;
            console.log(error);
          });
      },
      resetErrors(){
        Object.keys(this.erroresItem).forEach( (key) => this.erroresItem[key] = null);
        this.errores = [];
      },
      close () {
        this.resetErrors();
        this.action = 0,
        this.dialog = false
        this.view = false
        setTimeout(() => {
          this.editedItem = structuredClone(this.defaultItem);
          this.viewedItem = structuredClone(this.defaultItem);
          this.editedIndex = -1
          this.viewedIndex = -1
        }, 200)
      },
      makeValidation(string, min, max, msgError){
        if (string?.length < min || string?.length > max)
        {
            return msgError;
        }
        else return null;
      },
      validate(){
        this.resetErrors();

        if(this.editedItem.esMaquina == null){
          this.erroresItem.errorEsMaquina = "Debe indicar el Tipo";
        }

        if(this.editedItem.idMaquinaCategoria == null){
          this.erroresItem.erroridMaquinaCategoria = "Debe indicar la Categoría";
        }

        if(this.editedItem.idArea == -1 || this.editedItem.idArea == null){
          this.erroresItem.erroridArea = 'Debe de seleccionar una Área';
        }
        if(this.editedItem.idMaquina == -1 || this.editedItem.idMaquina == null){
          this.erroresItem.erroridMaquina = 'Debe de seleccionar una '+this.tipoLabel;
        }

        var val = null;
        val = this.makeValidation(this.editedItem.nombre,1,50,"Debe tener más de 1 caracter y menos de 50.");
        if(val != null) this.erroresItem.errornombre = val;

        if(this.editedItem.fechaFabricacion == null ){
          this.erroresItem.errorfechaFabricacion = "Debe indicar la Fecha de Fabricación";
        }

        // if(this.editedItem.fechaCompra == null ){
        //   this.erroresItem.errorfechaCompra = "Debe indicar la Fecha de Compra";
        // }

        if(this.editedItem.token?.length > 255){
          this.erroresItem.errortoken = 'El Token debe tener una longitud menor a 255 caracteres.';
        }

        if(this.editedItem.token2?.length > 255){
          this.erroresItem.errortoken2 = 'El Token2 debe tener una longitud menor a 255 caracteres.';
        }

        if(this.esAlgunaCategoriaEspecifica){
          if(this.editedItem.idUbicacion == null) this.erroresItem.errorUbicacion = "Requerido";
        }

        if(this.esCategoriaEspecifica(this.tiposCategorias[0])){
          if(this.editedItem.tipoBascula == null) this.erroresItem.errorTipoBascula = "Requerido";
          
          const parsedAlcanceMaxMedicion = parseFloat(this.editedItem.alcanceMaxMedicion);
          if(isNaN(parsedAlcanceMaxMedicion)){
            this.erroresItem.errorAlcanceMaxMed = "Invalido"
          }
          else if(parsedAlcanceMaxMedicion < 0){
            this.erroresItem.errorAlcanceMaxMed = "Debe ser mayor o igual a 0"
          }
        }

        if(this.esCategoriaEspecifica(this.tiposCategorias[1])){
          const parsedCapacidad = parseFloat(this.editedItem.capacidad);
          if(isNaN(parsedCapacidad)){
            this.erroresItem.errorCapacidad = "Requerido"
          }
          else if(parsedCapacidad <= 0){
            this.erroresItem.errorCapacidad = "Debe ser mayor a 0"
          }

          const parsedNumCilindros = parseInt(this.editedItem.numCilindros);
          if(isNaN(parsedNumCilindros)){
            this.erroresItem.errorNumCilindros = "Requerido"
          }
          else if(parsedNumCilindros <= 0){
            this.erroresItem.errorNumCilindros = "Debe ser mayor a 0"
          }

          const parsedMastilEtapas = parseInt(this.editedItem.mastilEtapas);
          if(isNaN(parsedMastilEtapas)){
            this.erroresItem.errorMastilEtapas = "Requerido"
          }
          else if(parsedMastilEtapas < 0){
            this.erroresItem.errorMastilEtapas = "Debe ser mayor o igual a 0"
          }

          // if(this.editedItem.idUsuario == null){
          //   this.erroresItem.errorUsuario = "Requerido";
          // }
        }

        if(this.esCategoriaEspecifica(this.tiposCategorias[2])){
          if(this.editedItem.tipoTransporte == null) this.erroresItem.errorTipoTransporte = "Requerido";

          if(!this.editedItem.version) this.erroresItem.errorVersion = "Requerido";

          if(!this.editedItem.placas) this.erroresItem.errorPlacas = "Requerido";
          else if(this.editedItem.placas.length > 20) this.erroresItem.errorPlacas = "Máximo 20 caracteres";

          if(!this.editedItem.numDeMotor) this.erroresItem.errorNumMotor = "Requerido";
        }

        const hayErrores = Object.values(this.erroresItem).some( (value) => value != null );
        if(hayErrores) this.errores.push("Hay uno o mas errores, porfavor reviselos y vuelva a intentarlo")

        return !hayErrores;
      },
      save () {
        this.action = 0;
        if(!this.validate()) return;
        this.saving = true;

        axios({
          url: this.editedIndex > -1
            ? `/MaquinaInstancias/Actualizar/${this.maquinas[this.editedIndex].idMaquinaIns}`
            : '/MaquinaInstancias/Crear',
          method: this.editedIndex > -1
            ? 'PUT'
            : 'POST',
          data: {
            IdArea: this.editedItem.idArea,
            IdMaquina:this.editedItem.idMaquina,
            idMaquinaTipo: this.editedItem.idMaquinaTipo,
            idUbicacion: this.editedItem.idUbicacion,
            idUsuario: this.editedItem.idUsuario,
            numSerie: this.editedItem.numSerie,
            Nombre: this.editedItem.nombre,
            FechaFabricacion: this.editedItem.fechaFabricacion,
            FechaCompra: this.editedItem.fechaCompra,
            token: this.editedItem.token,
            token2: this.editedItem.token2,
            alcanceMaxMedicion: this.editedItem.alcanceMaxMedicion ? parseFloat(this.editedItem.alcanceMaxMedicion) : null,
            capacidad: this.editedItem.capacidad ? parseFloat(this.editedItem.capacidad) : null,
            motor: this.editedItem.motor || null,
            numCilindros: this.editedItem.numCilindros ? parseInt(this.editedItem.numCilindros) : null,
            mastilEtapas: this.editedItem.mastilEtapas ? parseInt(this.editedItem.mastilEtapas) : null,
            propuestaNuevaNomenclatura: this.editedItem.propuestaNuevaNomenclatura || null,
            tipoBascula: parseInt(this.editedItem.tipoBascula) || null,
            tipoTransporte: parseInt(this.editedItem.tipoTransporte) || null,
            version: this.editedItem.version,
            placas: this.editedItem.placas,
            numDeMotor: this.editedItem.numDeMotor,
            fechaVencimientoVerificacion: this.editedItem.fechaVencimientoVerificacion,
            tarjetaMercantilVencimiento: this.editedItem.tarjetaMercantilVencimiento
          }
        })
          .then(() =>  {
            this.saving = false;
            this.close();
            this.initialize();
          })
          .catch(error => {
            this.saving = false;
            if (error.response?.status == 409) {
              const msg = error.response?.data.msg.split(':')[1]
              // this.alerta = true;
              if (msg.includes('error')){
                if(this.esAlgunaCategoriaEspecifica){
                  this.erroresItem.errornombre = "Ya existe";
                  this.errores.push(`Existe una unidad activa con el mismo Número Económico`);
                }
                else {
                  this.erroresItem.errornombre = "Ya existe";
                  this.errores.push("Existe una Máquina/Herramienta activa con el mismo Nombre/Código");
                }
              }
              else
                this.errores.push(msg);
            }
          });
      },
      cargaMasivaPost(){
        if (this.ficheroCarga == null) return;
        this.loadingCargaMasiva = true;
				let formData = new FormData();
				formData.append("formfile", this.ficheroCarga);
				axios({
          url: this.esIndasa
            ? "/MaquinaInstancias/CargaMasivaUnidadesIndasa"
            : "/MaquinaInstancias/CargaMasivaMaquinasInstancia",
          method: 'POST',
          data: formData,
          headers: {
            "Content-Type": "multipart/form-data",
          }
        })
					.then((response) => {
            this.erroresCarga = response.data
						this.cargaCargaCompleta = true;
						this.loadingCargaMasiva = false;
						this.initialize();
					})
					.catch((error) => {
						console.log(error);
						this.errorCargaMasiva = true;
						this.loadingCargaMasiva = false;
					});
    },
    descargarFormato(){
      var datetime = moment().format('YYYYMMDDHHmmss');
      let url = '/MaquinaInstancias/PlantillaCargaMasivaMaquinasInstancia';
      let fileName = 'CargaMasiva_Maquinas';
      if(this.esIndasa){
        url = '/MaquinaInstancias/PlantillaCargaMasivaUnidadesIndasa';
        fileName = 'CargaMasiva_Unidades';
      }
      this.$utils.axios.downloadFile(url+'?access_token='+this.token,'GET',fileName+'_'+datetime+'.csv','text/csv');
    }
    },
  }
</script>