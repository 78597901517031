<template>
  <v-col cols="12">
    <template>
      <v-row justify="center">
        <v-dialog v-model="eliminar" persistent max-width="500px" :key="eliminar">
          <v-card>
            <v-card-title class="headline">¿Está seguro que desea eliminar este vendedor?</v-card-title>
            <v-card-text>Esta acción no se puede revertir y será permanente.</v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="blue darken-1" text @click="eliminar = false; delItem = '';">Cancelar</v-btn>
              <v-btn color="red darken-1" text @click="eliminar = false; deleteItemDB()">Aceptar</v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-row>
    </template>
    <v-data-table
      v-if="permisoVista('vendedores','r')"
      :headers="headers"
      :items="vendedores"
      :search="search"
      :loading="loading"
      loading-text="Cargando... Espere, Por Favor."
    >
      <template v-slot:top>
        <v-toolbar flat color="white">
          <v-dialog v-model="dialog" persistent max-width="500px" :key="dialog">
            <template v-slot:activator="{ on }">
              <v-btn color="primary" dark class="mb-2" v-on="on" v-if="permisoVista('vendedores','c')">Nuevo Vendedor</v-btn>
            </template>
            <v-card>
              <v-card-title>
                <span class="headline">{{ formTitle }}</span>
              </v-card-title>
              <v-card-text>
                <v-container>
                  <v-row>
                    <v-col justify="center" cols="12" sm="12" md="12">
                      <v-select
                        v-model="editedItem.interno"
                        label="Tipo de Vendedor"
                        :items="tiposVendedor"
                        item-value="value"
                        item-text="text"
                        hide-details
                      ></v-select>
                    </v-col>
                    <v-col v-if="!editedItem.interno" justify="center" cols="12" sm="12" md="12">
                      <v-text-field v-model="editedItem.nombre" hide-details label="Nombre del Vendedor"></v-text-field>
                    </v-col>
                    <v-col v-if="!editedItem.interno" justify="center" cols="12" sm="12" md="12">
                      <v-text-field v-model="editedItem.paterno" hide-details label="Apellido Paterno del Vendedor"></v-text-field>
                    </v-col>
                    <v-col v-if="!editedItem.interno" justify="center" cols="12" sm="12" md="12">
                      <v-text-field v-model="editedItem.materno" hide-details label="Apellido Materno del Vendedor"></v-text-field>
                    </v-col>
                    <v-col v-if="!editedItem.interno" justify="center" cols="12" sm="12" md="12">
                      <v-text-field v-model="editedItem.telefono" hide-details label="Teléfono del Vendedor"></v-text-field>
                    </v-col>
                    <v-col v-if="editedItem.interno" justify="center" cols="12" sm="12" md="12">
                      <v-select
                        v-model="editedItem.idPersona"
                        :items="personas"
                        :loading="loadingPersonas"
                        :item-text="nombrePersona"
                        item-value="idPersona"
                        hide-details
                        label="Empleado"
                      ></v-select>
                    </v-col>
                  </v-row>
                  <ul>
                    <li class="red--text" v-for="ex in errores" :key="ex">{{ ex }}</li>
                  </ul>
                </v-container>
              </v-card-text>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="blue darken-1" text @click="dialog=false; errores=[];">Cancelar</v-btn>
                <v-btn color="red darken-1" text @click="save()" :disabled="saving" :loading="saving">
                  Guardar
                  <template v-slot:loader>
                    <v-progress-circular
                      indeterminate
                      :width="2"
                      :size="24"
                      color="red"
                    ></v-progress-circular>
                  </template>
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
          <v-spacer></v-spacer>
          <v-text-field
            v-model="search"
            append-icon="search"
            label="Buscar"
            single-line
            hide-details
          ></v-text-field>
        </v-toolbar>
      </template>
      <template v-slot:item.interno="{ item }">
        {{item.interno ? 'Interno': 'Externo'}}
      </template>
      <template v-slot:item.action="{ item }">
        <v-tooltip bottom color="black" class="white--text">
          <template v-slot:activator="{ on }">
            <v-icon v-on="on"
              v-if="permisoVista('vendedores','u')"
              small
              class="mr-2"
              @click="editItem(item)"
            >
              edit
            </v-icon>
         </template>
          <span class="white--text">Editar</span>
        </v-tooltip>
        <v-tooltip bottom color="black" class="white--text">
          <template v-slot:activator="{ on }">
            <v-icon v-on="on"
              v-if="permisoVista('vendedores','d')"
              small
              class="mr-2"
              @click="deleteItem(item)"
            >
              delete
            </v-icon>
         </template>
          <span class="white--text">Eliminar</span>
        </v-tooltip>
      </template>
      <template v-slot:no-data>
        <v-btn color="primary" @click="initialize">Refrescar</v-btn>
      </template>
    </v-data-table>
    <v-snackbar
      v-model="alerta"
      top
      color="error"
    >
      Existe un Vendedor Activo con el mismo Id de Persona
      <v-btn
        text
        @click="alerta = false"
      >
        Cerrar
      </v-btn>
    </v-snackbar>
  </v-col>
  
</template>

<script>
import axios from "axios";

  export default {
    data: () => ({
      tiposVendedor:[
        {
          value: true,
          text: "Interno"
        },
        {
          value: false,
          text: "Externo"
        }
      ],
      saving: false,
      dialog: false,
      alerta: false,
      eliminar: false,
      search: '',
      headers: [
        {
          text: 'Tipo de Vendedor',
          align: 'center',
          sortable: true,
          value: 'interno',
          width:'20%'
        },
        {
          text: 'Nombre',
          align: 'center',
          sortable: true,
          value: 'nombre',
        },
        {
          text: 'Paterno',
          align: 'center',
          sortable: true,
          value: 'paterno',
        },
        {
          text: 'Materno',
          align: 'center',
          sortable: true,
          value: 'materno',
        },
        { text: 'Acciones', value: 'action', sortable: false, width:'20%', align:'center' },
      ],
      loading: false,
      vendedores: [],
      delItem: '',
      action: 0,
      errores: [],
      editedIndex: -1,
      editedItem: {
        interno: true,
        idPersona: -1,
        nombre: '',
        paterno:'',
        materno:'',
        telefono: ''
      },
      defaultItem: {
        interno: true,
        idPersona: -1,
        nombre: '',
        paterno:'',
        materno:'',
        telefono: ''
      },
      loadingPersonas: false,
      personas:[],
    }),

    computed: {
      formTitle () {
        return this.editedIndex === -1 ? 'Nuevo Vendedor' : 'Editar Vendedor'
      }
    },

    watch: {
      dialog (val) {
        val || this.close()
      }
    },

    created () {
      this.initialize()
    },

    methods: {
      nombrePersona: persona => persona.nombre + " " + persona.paterno + " " + persona.materno,
      initialize () {
        this.loading = true;
        this.getPersonas();
        this.getVendedores();
      },
      getVendedores(){
        axios
          .get("/Vendedores")
          .then(response =>  {
            this.vendedores = response.data;
            this.loading = false;
          })
          .catch(error => {
            console.log(error);
          });
      },

      editItem (item) {
        this.action = 1;
        this.editedIndex = this.vendedores.indexOf(item)
        this.editedItem = Object.assign({}, item)
        this.dialog = true
      },

      deleteItem (item) {
        this.eliminar = true;
        this.delItem = item;
      },
      deleteItemDB(){
        let me = this;
        let item = this.delItem;
        this.delItem = '';
        axios
          .put("/Vendedores/Desactivar/" + item.idVendedor)
          .then(response =>  {
            me.initialize();
          })
          .catch(error => {
            console.log(error);
          });
      },
      close () {
        this.action = 0,
        this.dialog = false
        setTimeout(() => {
          this.editedItem = Object.assign({}, this.defaultItem)
          this.editedIndex = -1
        }, 300)
      },
      validate(){
        this.errores =  [];

        if(this.editedItem.interno){
          if (this.editedItem.idPersona <= 0){
            this.errores.push("Debe de Seleccionar una Persona.");
          }  
        }

        else{
          if (this.editedItem.nombre.length < 2 ||this.editedItem.nombre.length > 200){
            this.errores.push("El nombre debe tener más de 2 caracteres y menos de 200.");
          }

          if (this.editedItem.paterno.length < 2 ||this.editedItem.paterno.length > 200){
            this.errores.push("El apellido paterno debe tener más de 2 caracteres y menos de 200.");
          }
        }

        return this.errores.length == 0;
      },
      soloNumeros(cadena){
        return (/^\d+$/.test(cadena));
      },
      validEmail(cadena){
         return (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(cadena));
      },
      save () {
        this.action = 0;
        if(this.validate()){
          let me = this;
          me.saving = true;
          if (this.editedIndex > -1)
          {
            //Código para editar
            axios
              .put("/Vendedores/Actualizar/"+me.vendedores[me.editedIndex].idVendedor, {
                Interno: me.editedItem.interno,
                IdPersona: me.editedItem.interno ? me.editedItem.idPersona : null,
                Nombre: (!me.editedItem.interno) ? me.editedItem.nombre : null,
                Paterno: (!me.editedItem.interno) ? me.editedItem.paterno: null,
                Materno: (!me.editedItem.interno && me.editedItem.materno != null && me.editedItem.materno.length > 0) ? me.editedItem.materno: null,
                Telefono: (!me.editedItem.interno && me.editedItem.telefono != null && me.editedItem.telefono.length > 0)?me.editedItem.telefono:null,
              })
              .then(response =>  {
                me.saving = false;
                me.close();
                me.initialize();
              })
              .catch(error => {
                me.saving = false;
                if (error.response) {
                  if(error.response.status == 409){
                    me.alerta = true;
                    me.errores.push("Existe un Vendedor Activa con el Mismo RFC");
                  }
                }
              });
          }
          else
          {
            axios
              .post("/Vendedores/Crear", {
                Interno: me.editedItem.interno,
                IdPersona: me.editedItem.interno ? me.editedItem.idPersona : null,
                Nombre: (!me.editedItem.interno) ? me.editedItem.nombre : null,
                Paterno: (!me.editedItem.interno) ? me.editedItem.paterno: null,
                Materno: (!me.editedItem.interno && me.editedItem.materno != null && me.editedItem.materno.length > 0) ? me.editedItem.materno: null,
                Telefono: (!me.editedItem.interno && me.editedItem.telefono != null && me.editedItem.telefono.length > 0)?me.editedItem.telefono:null,
              })
              .then(response =>  {
                me.saving = false;
                me.close();
                me.initialize();
              })
              .catch(error => {
                me.saving = false;
                if (error.response) {
                  if(error.response.status == 409){
                    me.alerta = true;
                    me.errores.push("Existe un Vendedor Activa con el Mismo RFC");
                  }
                }
              });
          }
        }
      },
      getPersonas() {
        this.loadingPersonas = true;
        axios
        .get("/Vendedores/Personas")
        .then(response =>  {
          this.personas = response.data;
          this.loadingPersonas = false;
        })
        .catch(error => {
          console.log(error);
        });
      },
    },
  }
</script>