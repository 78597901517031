import { VCard } from 'vuetify/lib/components/VCard';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VTabs } from 'vuetify/lib/components/VTabs';
import { VTabsItems } from 'vuetify/lib/components/VTabs';
import { VTabsSlider } from 'vuetify/lib/components/VTabs';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VContainer,[_c(VRow,{attrs:{"justify-sm":"center"}},[_c(VCard,{attrs:{"width":"90%","fill":""}},[_c(VTabs,{attrs:{"background-color":"blue lighten-2 accent-4","centered":"","dark":"","icons-and-text":""},model:{value:(_vm.tab),callback:function ($$v) {_vm.tab=$$v},expression:"tab"}},[_c(VTabsSlider)],1),_c(VTabsItems,{attrs:{"touchless":""},model:{value:(_vm.tab),callback:function ($$v) {_vm.tab=$$v},expression:"tab"}})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }