<template>
	<div>
		<v-tabs v-model="tab" background-color="transparent" grow centered>
			<v-tab
				v-if="esIndasa && mostrarVista('mantenimientosporprogramar')"
				v-tooltip.bottom="modoAyuda && $help.mantenimiento.statusMantenimientos.mantPorProgramar"
				@click="mantenimientosPorProgramarClick"
			>
				Mantenimientos<br>Por Programar
			</v-tab>
			
			<v-tab v-tooltip.bottom="modoAyuda && $help.mantenimiento.statusMantenimientos.mantEncurso" v-if="mostrarVista('mantenimiento-historial')" @click="mantenimientosCursoClick">
				Mantenimientos<br />En Curso
			</v-tab>

			<v-tab v-tooltip.bottom="modoAyuda && $help.mantenimiento.statusMantenimientos.mantRealizados" v-if="mostrarVista('mantenimiento-historial')" @click="mantenimientosClick">
				Mantenimientos<br/>Realizados
			</v-tab>

			<v-tab v-tooltip.bottom="modoAyuda && $help.mantenimiento.statusMantenimientos.insCurso" v-if="mostrarVista('mantenimiento-inspecciones')" @click="inspeccionesCursoClick">
				Inspecciones<br />En Curso
			</v-tab>

			<v-tab v-tooltip.bottom="modoAyuda && $help.mantenimiento.statusMantenimientos.insRealizadas" v-if="mostrarVista('mantenimiento-inspecciones')" @click="inspeccionesClick"> Inspecciones<br/>Realizadas </v-tab>

			<v-tabs-items v-model="tab" touchless>
				<v-tab-item v-if="esIndasa && mostrarVista('mantenimientosporprogramar')" eager>
					<MantenimientosPorProgramar ref="mantPorProgramar" />
				</v-tab-item>
				
				<v-tab-item eager>
					<Mantenimientos ref="mantCurso" />
				</v-tab-item>

				<v-tab-item eager>
					<Historial ref="hist" />
				</v-tab-item>

				<v-tab-item eager>
					<Inspecciones ref="insCurso" />
				</v-tab-item>
				<v-tab-item eager>
					<InspeccionesTerminadas ref="ins" />
				</v-tab-item>
			</v-tabs-items>
		</v-tabs>
	</div>
</template>

<script>
import Historial from "./Historial.vue";
import InspeccionesTerminadas from "./InspeccionesTerminadas.vue";
import Inspecciones from "./EnCurso/Inspecciones.vue";
import Mantenimientos from "./EnCurso/Mantenimientos.vue";
import MantenimientosPorProgramar from './MantenimientosPorProgramar.vue';
import { mapState } from 'vuex';

export default {
	components: {
		Historial,
		InspeccionesTerminadas,
		Mantenimientos,
		Inspecciones,
		MantenimientosPorProgramar,
	},
	data: () => ({
		tab: 0,
	}),
	computed: {
		...mapState(["modoAyuda","nombreEmpresa"]),
		esIndasa(){
			return this.nombreEmpresa.toUpperCase() == "INDASA";
		},
	},
	methods: {
		initialize() {
			this.mantenimientosPorProgramarClick();
			this.mantenimientosCursoClick();
            this.mantenimientosClick();
            this.inspeccionesCursoClick();
            this.inspeccionesClick();
		},
		mantenimientosCursoClick() {
			if (this.$refs.mantCurso == null) return;
			this.$refs.mantCurso.initialize();
		},
		mantenimientosClick() {
			if (this.$refs.hist == null) return;
			this.$refs.hist.initialize();
		},
		inspeccionesCursoClick() {
			if (this.$refs.insCurso == null) return;
			this.$refs.insCurso.initialize();
		},
		inspeccionesClick() {
			if (this.$refs.ins == null) return;
			this.$refs.ins.initialize();
		},
		mantenimientosPorProgramarClick(){
			if (this.$refs.mantPorProgramar == null) return;
			this.$refs.mantPorProgramar.initialize();
		}
	},
};
</script>