<template>
  <v-col cols="12">
    <v-row>
      <v-card 
        width="100%"
        flat
        fill
      >
        <v-tabs vertical v-if="permisoVista('almacen','r')">
          <v-tab v-tooltip.bottom="modoAyuda && $help.almacen.configuracionAlmacen.unidades" @click="updateUnidades()">
            <v-icon left>mdi-scale-balance</v-icon>
            Unidades
          </v-tab>
          <v-tab v-tooltip.bottom="modoAyuda && $help.almacen.configuracionAlmacen.categorias" @click="updateArticuloCategoria()">
            <v-icon left>mdi-shape</v-icon>
            Categorías 
          </v-tab>
          <v-tab v-tooltip.bottom="modoAyuda && $help.almacen.configuracionAlmacen.tipos" @click="updateArticuloTipo()">
            <v-icon left>mdi-sort</v-icon>
            Tipos 
          </v-tab>
          <v-tab v-tooltip.bottom="modoAyuda && $help.almacen.configuracionAlmacen.clases" @click="updateClaseArticulo()">
            <v-icon left>mdi-layers</v-icon>
            Clases
          </v-tab>
          <v-tab v-tooltip.bottom="modoAyuda && $help.almacen.configuracionAlmacen.familias" @click="updateArticuloFamilia()">
            <v-icon left>mdi-group</v-icon>
            Familias de<br>Productos
          </v-tab>
          <v-tab v-tooltip.bottom="modoAyuda && $help.almacen.configuracionAlmacen.calidad" @click="updateCalidades()">
            <v-icon left>mdi-certificate</v-icon>
            Calidad
          </v-tab>
          <v-tab v-tooltip.bottom="modoAyuda && $help.almacen.configuracionAlmacen.tiempounidades" v-if="mostrarVista('tiempounidades')" @click="updateTiempoUnidades()">
            <v-icon left>mdi-clock-outline</v-icon>
            Unidades <br/>de Tiempo
          </v-tab>

          <v-tab v-tooltip.bottom="modoAyuda && $help.almacen.configuracionAlmacen.correos" @click="updateCorreos()">
            <v-icon left>mdi-email-multiple</v-icon>
            Configuración<br/>Correos
          </v-tab>

          <v-tab-item>
            <v-card flat color="gray">
              <v-card-text>
                <configuracionUnidad ref="unidades"></configuracionUnidad>
              </v-card-text>
            </v-card>
          </v-tab-item>

          <v-tab-item>
            <v-card flat color="gray">
              <v-card-text>
                <configuracionArticuloCategoria ref="articuloCategoria"></configuracionArticuloCategoria>
              </v-card-text>
            </v-card>
          </v-tab-item>

          <v-tab-item>
            <v-card flat color="gray">
              <v-card-text>
                <configuracionArticuloTipo ref="articuloTipo"></configuracionArticuloTipo>
              </v-card-text>
            </v-card>
          </v-tab-item>

          <v-tab-item>
            <v-card flat color="gray">
              <v-card-text>
                <ArticuloClases ref="clases" />
              </v-card-text>
            </v-card>
          </v-tab-item>

          <v-tab-item>
            <v-card flat color="gray">
              <v-card-text>
                <configuracionArticuloFamilia ref="articuloFamilia"></configuracionArticuloFamilia>
              </v-card-text>
            </v-card>
          </v-tab-item>

          <v-tab-item>
            <v-card flat color="gray">
              <v-card-text>
                <CalidadTipos ref="calidad" />
              </v-card-text>
            </v-card>
          </v-tab-item>

          <v-tab-item>
            <v-card flat color="gray">
              <v-card-text>
                <Tiempos ref="tiempos" />
              </v-card-text>
            </v-card>
          </v-tab-item>

          <v-tab-item>
            <v-card flat color="gray">
              <v-card-text>
                <Correos ref="emails" />
              </v-card-text>
            </v-card>
          </v-tab-item>
        </v-tabs>
      </v-card>
    </v-row>
  </v-col>
</template>

<script>
import Unidad from "./Unidad.vue";
import ArticuloTipo from "./ArticuloTipo.vue";
import ArticuloCategoria from "./ArticuloCategoria.vue";
import ArticuloFamilia from "./ArticuloFamilia.vue";
import CalidadTipos from './CalidadTipos.vue';
import Tiempos from './Tiempos.vue';
import Correos from './Correos.vue';
import { mapState } from 'vuex';
import ArticuloClases from './ArticuloClases.vue';

export default {
  data() {
    return {
      tab: null,
    };
  },
  computed:{
    ...mapState(["modoAyuda"])
  },
  methods: {
    refresh(){
      this.updateUnidades();
      this.updateArticuloCategoria();
      this.updateArticuloTipo();
      this.updateArticuloFamilia();
      this.updateCalidades();
    },
    updateClaseArticulo(){
      if(typeof this.$refs.clases === 'undefined') return;
      this.$refs.clases.initialize();
    },
    updateUnidades(){
      if(typeof this.$refs.unidades === 'undefined') return;
      this.$refs.unidades.initialize();
    },
    updateArticuloCategoria(){
      if(typeof this.$refs.articuloCategoria === 'undefined') return;
      this.$refs.articuloCategoria.initialize();
    },
    updateArticuloTipo(){
      if(typeof this.$refs.articuloTipo === 'undefined') return;
      this.$refs.articuloTipo.initialize();
    },
    updateArticuloFamilia(){
      if(typeof this.$refs.articuloFamilia === 'undefined') return;
      this.$refs.articuloFamilia.initialize();
    },
    updateCalidades(){
      if(typeof this.$refs.calidad === 'undefined') return;
      this.$refs.calidad.initialize();
    },
    updateTiempoUnidades(){
      if(typeof this.$refs.calidad === 'undefined') return;
      this.$refs.calidad.initialize();
    },
    updateCorreos(){
      if(typeof this.$refs.emails === 'undefined') return;
      this.$refs.emails.initialize();
    }
  },
  components: {
    configuracionUnidad: Unidad,
    configuracionArticuloTipo: ArticuloTipo,
    configuracionArticuloCategoria: ArticuloCategoria,
    configuracionArticuloFamilia: ArticuloFamilia,
    CalidadTipos,
    Tiempos,
    Correos,
    ArticuloClases,
  }
};
</script>