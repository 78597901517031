<template>
	<v-col cols="12">
		<v-dialog v-model="loading" max-width="400px" persistent>
			<v-card>
				<v-card-text class="text-center" v-show="!alerta">
					<div class="display-1 pt-4 pb-2">Cargando</div>
					<v-progress-circular
						class="pa-2 ma-2 mt-4"
						:size="100"
						:width="8"
						color="primary"
						indeterminate
					></v-progress-circular>
				</v-card-text>
				<v-card-text class="text-center" v-show="alerta">
					<v-icon
						class="pt-2"
						color="red"
						:size="100"
						v-if="errorDialog"
						>mdi-close-circle-outline</v-icon
					>
					<v-icon class="pt-2" color="green" :size="100" v-else
						>mdi-check-circle-outline</v-icon
					>
					<div class="display-1 pa-3" v-if="errorDialog">
						Error al cargar la factura
					</div>
					<div class="display-1 pa-3" v-else>
						Factura cargada correctamente
					</div>
					<v-btn block color="primary" x-large @click="cerrarDialog"
						>Aceptar</v-btn
					>
				</v-card-text>
			</v-card>
		</v-dialog>
		<AlertDialog
			icon="mdi-check-circle-outline"
			color="green"
			ref="alerta"
		/>
		<v-col cols="12" md="12">
			<v-file-input
				v-model="ficheroFactura"
				label="Fichero de Factura (.xml)"
				accept=".xml"
				counter
				multiple
				show-size
				@change="cambioFicheroFacturas"
			></v-file-input>
			<v-btn
				block
				color="blue"
				:disabled="ficheroFactura === null"
				@click="cargarFactura"
			>
				<v-icon> mdi-upload </v-icon>
				Cargar Factura
			</v-btn>
		</v-col>
	</v-col>
</template>

<script>
import axios from "axios";
import AlertDialog from "@/components/AlertDialog.vue";
import LoadingDialog from "@/components/LoadingDialog.vue";

export default {
	components: { LoadingDialog, AlertDialog },
	data: () => ({
		ficheroFactura: null,
		loading: false,
		alerta: false,
		errorDialog: false,
	}),
	methods: {
		cambioFicheroFacturas(event) {
			if (typeof event === "undefined" || event === null)
				this.ficheroFactura = null;
			else this.ficheroFactura = event;
		},
		cargarFactura() {
			if (this.ficheroFactura !== null) {
				this.loading = true;
				let formData = new FormData();
				this.ficheroFactura.forEach((factura) => {
					formData.append("file", factura);
				});
				axios
					.post("/Mantenimientos/ReportarFacturas", formData, {
						headers: {
							"Content-Type": "multipart/form-data",
						},
					})
					.then(() => {
						this.alerta = true;
						this.ficheroFactura = null;
					})
					.catch((error) => {
						this.errorDialog = true;
						this.alerta = true;
						console.log(error);
					});
			}
		},
		cerrarDialog() {
			this.loading = false;
			this.errorDialog = false;
			this.alerta = false;
		},
	},
};
</script>

<style>
</style>