<template>
  <v-avatar class="mt-3" size="100">
    <img
      :src="
        image
          ? currentImageOriginal
          : `https://ui-avatars.com/api/?name=${name}&size=64`
      "
      contain
      
      aspect-ratio="1"
    />
  </v-avatar>
</template>

<script>
import axios from "axios";
import { mapState } from "vuex";
export default {
  props: {
    image: { type: Boolean, default: false },
    url: { type: String, required: true },
    name: { type: String, required: true },
  },
  data() {
    return {
      currentImageOriginal: null,
    };
  },
  mounted() {
    if (this.image) this.showImage(this.url);
  },
  computed: {
    ...mapState(["token"]),
  },
  beforeUpdate() {},
  methods: {
    showImage(item) {
      
      const url = `/Personas/ImagenPersona?imagen=${item}&access_token=${this.token}`;
      
      axios({
        url: url,
        method: "GET",
        responseType: "blob",
        data: null,
      })
        .then(async (response) => {
          let blob = new Blob([response.data], {
            type: "application/octet-stream",
          });
          this.currentImageOriginal = await this.$utils.images.blobToBase64(
            blob
          );
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },
};
</script>
