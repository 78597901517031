<template>
  <v-container fluid>
    <v-row>
      <v-col cols="12" md="3" sm="3">
        <datePicker v-model="desde" format="YYYY-MM-DD" :maxDate="fin" label="Desde (Obligatorio)" clearable maxToday
          @input="getDatos()" :disabled="cargando"></datePicker>
      </v-col>
      <v-col cols="12" md="3" sm="3">
        <datePicker v-model="hasta" :minDate="desde" format="YYYY-MM-DD" label="Hasta (Obligatorio)" clearable maxToday
          @input="getDatos()" :disabled="cargando"></datePicker>
      </v-col>
      <v-col cols="12" md="3" sm="3">
        <v-select v-model="idTipoMmto" class="" :items="tiposMantenimientos" label="Tipo de Mantenimiento (Opcional)"
          item-text="text" item-value="value" hide-details clearable @change="getDatos()"></v-select>
      </v-col>
      <v-col cols="12" md="3" sm="3">
        <v-autocomplete v-model="idSubdepartamento" label="Subdepartamento (Opcional)" clearable :items="subdepartamentos"
          :item-text="nombreSubdepto" item-value="idSubdepartamento" hide-details :disabled="cargando || loadingSubdeptos"
          :loading="loadingSubdeptos" @change="getDatos"></v-autocomplete>
      </v-col>
      <v-col cols="12" md="12" sm="12" xl="12">
        <v-progress-linear v-if="cargando" color="primary" height="5" indeterminate></v-progress-linear>
        <apexchart type="bar" height="350" :options="chartOptions" :series="series" :key="`graph-${graphKey}`">
        </apexchart>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import axios from "axios";
export default {
  data: () => ({
    desde: null,
    hasta: null,
    idTipoMmto: null,
    idSubdepartamento: null,
    cargando: false,
    subdepartamentos: [],
    loadingSubdeptos: false,
    categories: [],
    series: [],
    graphKey: 1,
    tiposMantenimientos: [
      {
        text: "Preventivo",
        value: 1,
      },
      {
        text: "Correctivo",
        value: 2,
      },
      {
        text: "Inspección",
        value: 3,
      },
      {
        text: "Servicio",
        value: 4,
      },
    ],
  }),
  mounted() {
    this.initialize();
  },
  computed: {
    chartOptions() {
      Apex.colors = ["#00CB3D", "#E1F70D"];
      return {
        chart: {
          type: "bar",
          height: 350,
          stacked: true,
          toolbar: {
            show: true,
          },
          zoom: {
            enabled: true,
          },
        },
        responsive: [
          {
            breakpoint: 480,
            options: {
              legend: {
                position: "bottom",
                offsetX: -10,
                offsetY: 0,
              },
            },
          },
        ],
        plotOptions: {
          bar: {
            horizontal: false,
            dataLabels: {
              total: {
                enabled: true,
                formatter: function(val) {
                  return val.toFixed(2) + " %";
                },
                style: {
                  fontSize: "13px",
                  fontWeight: 900,
                },
              },
            },
            
          },
        },
        xaxis: {
          type: "category",
          categories: this.categories,
        },
        yaxis: {
					max: 100,
					min: 0,
					labels: {
						formatter: function (val) {
							return val.toFixed(2) + " %";
						},
					}
				},
        tooltip: {
					y: {
						formatter: function (val) {
							return val.toFixed(2) + " %";
						},
					}
				},
        legend: {
          position: "right",
          offsetY: 10,
        },
        fill: {
          opacity: 1,
          colors: ["#00CB3D", "#E1F70D"]
        },
      }
    },
    urlDatos() {
      return `/Reportes/ReporteMecanicosMantenimientosAsignados?&desde=${this.desde}&hasta=${this.hasta}`;
    },
  },
  methods: {
    initialize() {
      this.getSubdepartamentos();
    },
    nombreSubdepto: ({ codigo, nombre }) =>
      codigo ? `[${codigo}] - ${nombre}`.trim() : nombre.trim(),
    getSubdepartamentos() {
      this.loadingSubdeptos = true;
      axios
        .get("/Subdepartamento")
        .then((response) => {
          this.subdepartamentos = response.data;
        })
        .catch(console.log)
        .finally(() => {
          this.loadingSubdeptos = false;
        });
    },
    getDatos() {
      if (this.desde == null) return;
      if (this.hasta == null) return;

      var url = this.urlDatos

      if (this.idTipoMmto != null) url += `&TipoMantenimiento=${this.idTipoMmto}`
      if (this.idSubdepartamento != null) url += `&idSubdepartamento=${this.idSubdepartamento}`

      this.cargando = true;
      axios.get(url)
      .then((response) => {
        this.categories = response.data.categories;
        this.series = response.data.series;
        this.graphKey++;
        this.cargando = false;
      });
    },
  },
};
</script>