<template>
  <v-col cols="12" class="px-8" v-if="permisoVista('dashboard','r')">
    <v-row>
      <v-col cols="4" md="4" sm="4" class="align-self-end">
        <datePicker
          v-model="desde"
          format="YYYY-MM-DD"
          :maxDate="fin"
          label="Desde (Obligatorio)"
          clearable
          maxToday
          @input="obtenerDatos"
          :disabled="cargando"
        ></datePicker>
      </v-col>
      <v-col cols="4" md="4" sm="4" class="align-self-end">
        <datePicker
          v-model="hasta"
          :minDate="desde"
          format="YYYY-MM-DD"
          label="Hasta (Obligatorio)"
          clearable
          maxToday
          @input="obtenerDatos"
          :disabled="cargando"
        ></datePicker>
      </v-col>
      <v-col cols="4" md="4" sm="4" class="align-self-end">
        <v-autocomplete
					v-model="idTurno"
					:items="turnos"
					item-text="nombre"
					item-value="idTurno"
					label="Turno (Opcional)"
					hide-details
					clearable
					@change="obtenerDatos"
					:disabled="cargando"
				></v-autocomplete>
      </v-col>
      <!-- <v-col cols="3" class="align-self-end">
        <VueCtkDateTimePicker
          v-model="desdeHora"
          label="Hora Inicio"
          format="hh:mm a"
          formatted="hh:mm a"
          outputFormat="HH:mm"
          inputSize="sm"
          :minuteInterval="10"
          onlyTime
          noLabel
          @input="obtenerDatos"
          :disabled="cargando"
        />
      </v-col>
      <v-col cols="3" class="align-self-end">
        <VueCtkDateTimePicker
          v-model="hastaHora"
          label="Hora Fin"
          format="hh:mm a"
          formatted="hh:mm a"
          outputFormat="HH:mm"
          inputSize="sm"
          :minuteInterval="10"
          onlyTime
          noLabel
          @input="obtenerDatos"
          :disabled="cargando"
        />
      </v-col> -->
    </v-row>
    <v-row :class="!active&&'mb-4'">
      <v-col cols="12" class="pa-0 ma-0">
        <v-btn v-if="realizarPeticion && !cargando" color="primary" x-small @click="maquinasArticulosSeleccion.push(Object.assign({},maquinaArticuloDefault))">Agregar Máquina</v-btn>
      </v-col>
      <v-col cols="12" class="pa-0 ma-0">
        <v-data-table
          v-if="realizarPeticion && !cargando"
          :items="maquinasArticulosSeleccion"
          :headers="headers"
          hide-default-footer
        >
          <template v-slot:item.idMaquinaIns ="{ item }">
            <v-autocomplete
              v-model="item.idMaquinaIns" 
              :items="maquinasFiltradas"
              item-value="idMaquinaIns"
              placeholder="(Obligatorio)"
              :item-text="nombreMaquina"
              hide-details
              dense
              @change="item.idArticulo = -1; obtenerDatosAvanzados();"
            ></v-autocomplete>
          </template>
          <template v-slot:item.idArticulo ="{ item }">
            <v-autocomplete
              v-model="item.idArticulo" 
              :items="articulosFiltrados(item.idMaquinaIns)"
              item-value="idArticulo"
              placeholder="(Obligatorio)"
              item-text="articulo"
              hide-details
              dense
              @change="obtenerDatosAvanzados()"
            ></v-autocomplete>
          </template>
          <template v-slot:item.actions ="{ item }">
            <v-icon
              v-if="maquinasArticulosSeleccion.indexOf(item) != 0"
              small
              @click="eliminarElemento(item)"
            >delete</v-icon>
          </template>
        </v-data-table>
      </v-col>
    </v-row>
    <v-row v-if="realizarPeticion && realizarPeticionAvanzada && active" class="px-0 ma-0">
      <v-col cols="12" class="ma-0 text-right text-subtitle-2">
        Proxima Actualización en {{tiempo}}
      </v-col>
    </v-row>
    <v-progress-linear
      v-if="cargandoAvanzado"
			color="primary"
			height="5"
			indeterminate
		></v-progress-linear>
    <apexchart type="bar" height="350" :options="chartOptions" :series="series"></apexchart>
  </v-col>
</template>

<script>
import axios from "axios"

export default {
  props:{
    active:{
      type: Boolean,
      default: false,
    }
  },
  computed:{
    realizarPeticion(){
      if(this.desdeHora != null && this.hastaHora != null && this.desde != null && this.hasta != null) return true;
      else if(this.desdeHora == null && this.hastaHora == null && this.desde != null && this.hasta != null) return true;
      else return false;
      
    },
    realizarPeticionAvanzada(){
      var n = 0;

      this.maquinasArticulosSeleccion.forEach(mas => {
        if(mas.idArticulo > 0 && mas.idMaquinaIns > 0) n++;
      });

      return (n == this.maquinasArticulosSeleccion.length);
    },
    url(){
      var url = `/Dashboard/Grafica5MaquinaArticulos?Desde=${this.desde}&Hasta=${this.hasta}`;
      if(this.desdeHora != null && this.hastaHora != null) url += `&DesdeHora=${this.desdeHora}&HastaHora=${this.hastaHora}`;
      if(this.idsMaquinasIns.length > 0){
        this.idsMaquinasIns.forEach(idMaquinaIns => {
          url += `&idsMaquinasIns=${idMaquinaIns}`;
        })
      }
      return url;
    },
    chartOptions(){
      return {
        states: {
          active: {
              allowMultipleDataPointsSelection: false,
          }
        },
        chart: {
          type: 'bar',


          height: 350,
        },
        plotOptions: {
          bar: {
            horizontal: false,
            columnWidth: '55%',
            endingShape: 'rounded'
          },
        },
        dataLabels: {
          enabled: false
        },
        stroke: {
          show: true,
          width: 2,
          colors: ['transparent']
        },
        xaxis: {
          categories: this.categories.map(c => c.maquinaIns),
        },
        yaxis: {
          /*
          title: {
            text: '$ (thousands)'
          }
          */
        },
        fill: {
          opacity: 1
        },
        tooltip: {
          /*
          y: {
            formatter: function (val) {
              return "$ " + val + " thousands"
            }
          }
          */
        }
      }
    },
    tiempo(){
      return this.$utils.strings.toTime(this.segundos);
    },
    maquinaInsFiltradas(){
      var idsMaquinaIns = this.$utils.arrays.unique(this.maquinasArticulos.map(ma => ma.idMaquinaIns));
      return this.maquinasIns.filter(mi =>{
        return (idsMaquinaIns.indexOf(mi.idMaquinaIns) >= 0);
      })

    }
  },
  data: () => ({
    maquinasFiltradas: [],
    interval: null,
    segundos: 6,
    cargando: false,
    desde: null,
    hasta:null,
    desdeHora: null,
    hastaHora:null,
    categories: [],
    series: [],
    maquinasIns:[],
    idsMaquinasIns: [],
    headers:[
      {
        text: "Máquina",
        align: "center",
        sortable: false,
        value: "idMaquinaIns",
        width: "42%",
      },
      {
        text: "Artículo",
        align: "center",
        sortable: false,
        value: "idArticulo",
        width: "42%",
      },
      {
        text: "Acciones",
        align: "center",
        sortable: false,
        value: "actions",
        width: "16%",
      },
    ],
    maquinasArticulosSeleccion:[],
    maquinaArticuloDefault:{
      idMaquinaIns:-1,
      idArticulo: -1,
    },
    cargandoAvanzado: false,
    idTurno: null,
    turnos: []
  }),
  methods:{
    nombreMaquina: maquina => ` [${maquina.nombre}] - ${maquina.marca} ${maquina.modelo}`,
    getTurnos() {
			axios
			.get("/Turnos")
			.then((response) => {
				this.turnos = response.data;
			})
			.catch((error) => {
				console.log(error);
			});
		},
    obtenerDatos(){
      if(!this.realizarPeticion || this.cargando) {
        this.categories = [];
        this.series = [];
        this.maquinasArticulosSeleccion = [];
        this.maquinasArticulosSeleccion.push(Object.assign({},this.maquinaArticuloDefault));
        this.maquinasArticulos = [];
        this.maquinasFiltradas = [];
        return;
      };

      this.cargando = true;

      axios
      .get(this.url,{
        params: {
          IdTurno: this.idTurno
        }
      })
      .then(response => {
        this.maquinasArticulosSeleccion = [];
        this.maquinasArticulosSeleccion.push(Object.assign({},this.maquinaArticuloDefault));
        this.maquinasArticulos = response.data;
        this.filtrarMaquinas();
        this.cargando = false;
      })
      .catch(error => {
        this.cargando = false;
        console.log(error);
      })
    },
    filtrarMaquinas(){
      var idsMaquinaIns = this.$utils.arrays.unique(this.maquinasArticulos.map(ma => ma.idMaquinaIns));
      this.maquinasFiltradas = this.maquinasIns.filter(mi => idsMaquinaIns.indexOf(mi.idMaquinaIns) >= 0);
    },
    getMaquinasIns(){
      axios
      .get("/MaquinaInstancias")
      .then(response => {
        this.maquinasIns = response.data;
      })
      .catch(error => {
        console.log(error);
      })
    },
    articulosFiltrados(idMaquinaIns){
      if(this.idMaquinaIns <= 0) return [];
      return this.maquinasArticulos.filter(ma => ma.idMaquinaIns == idMaquinaIns);
    },
    obtenerDatosAvanzados(){
      if(!this.realizarPeticion || !this.realizarPeticionAvanzada || this.cargando || this.cargandoAvanzado) {
        this.categories = [];
        this.series = [];
        return;
      };

      var obj = {
        Desde: this.desde,
        Hasta: this.hasta,
        DesdeHora: null,
        HastaHora: null,
        MaquinasArticulos: this.maquinasArticulosSeleccion,
        IdTurno: this.idTurno
      };

      if(this.desdeHora != null && this.hastaHora != null){
        obj.DesdeHora = this.desdeHora;
        obj.HastaHora = this.hastaHora 
      };

      this.cargandoAvanzado = true;
      this.segundos = 60;

      axios
      .post('/Dashboard/Grafica5',obj)
      .then(response => {
        this.categories = response.data.categories;
        this.series = response.data.series;
        this.cargandoAvanzado = false;
      })
      .catch(error => {
        this.cargandoAvanzado = false;
        console.log(error);
      })
    },
    eliminarElemento(item){
      var idx = this.maquinasArticulosSeleccion.indexOf(item)
      if(idx <= -1) return;
      this.maquinasArticulosSeleccion.splice(idx,1);
      this.obtenerDatosAvanzados();
    }
  },
  mounted(){
    this.interval = setInterval(() => {
      if(!this.active) return;

      if(this.segundos == 0) {
        this.obtenerDatosAvanzados();
      }
      else this.segundos -= 1;
    },1000);
    this.getMaquinasIns();
    this.getTurnos();
  },
  destroyed(){
    clearInterval(this.interval);
  }
}
</script>