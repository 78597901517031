import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VFlex } from 'vuetify/lib/components/VGrid';
import { VForm } from 'vuetify/lib/components/VForm';
import { VLayout } from 'vuetify/lib/components/VGrid';
import { VMain } from 'vuetify/lib/components/VMain';
import { VProgressLinear } from 'vuetify/lib/components/VProgressLinear';
import { VSnackbar } from 'vuetify/lib/components/VSnackbar';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VMain,[_c('password-recovery',{ref:"pwdrecover"}),_c(VContainer,{attrs:{"fill-height":"","fluid":"","step":"1"}},[_c(VLayout,{attrs:{"align-center":"","justify-center":"","step":"1"}},[_c(VFlex,{attrs:{"xs12":"","sm8":"","md4":""}},[_c(VCard,{staticClass:"elevation-12"},[_c(VCardTitle,{staticClass:"justify-center"},[_c('img',{attrs:{"src":"/img/logotipo-300x115.png"}})]),_c(VCardText,[(_vm.loading)?_c(VProgressLinear,{attrs:{"indeterminate":""}}):_vm._e(),(!_vm.doubleFactor)?_c(VForm,[_c(VTextField,{ref:"username",attrs:{"disabled":_vm.loading,"rules":[
									function () { return !!_vm.username ||
										'El campo es requerido'; } ],"prepend-icon":"mdi-account","label":"Login","placeholder":"Email","required":""},model:{value:(_vm.username),callback:function ($$v) {_vm.username=$$v},expression:"username"}}),_c(VTextField,{ref:"password",attrs:{"disabled":_vm.loading,"rules":[
									function () { return !!_vm.password ||
										'El campo es requerido'; } ],"append-icon":_vm.showPassword ? 'mdi-eye-off' : 'mdi-eye',"type":_vm.showPassword ? 'text' : 'password',"prepend-icon":"mdi-lock","label":"Password","placeholder":"*********","counter":"","required":""},on:{"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.login.apply(null, arguments)},"click:append":function($event){_vm.showPassword = !_vm.showPassword}},model:{value:(_vm.password),callback:function ($$v) {_vm.password=$$v},expression:"password"}})],1):_vm._e(),_c('div',{attrs:{"align":"left"}},[_c('a',{staticClass:"text-decoration-none primary--text",on:{"click":_vm.mostrarDialog}},[_vm._v("¿Olvidaste tu contraseña?")])]),(_vm.doubleFactor)?_c(VForm,[_c(VTextField,{ref:"code",attrs:{"disabled":_vm.loading,"rules":[
									function () { return !!_vm.password ||
										'El campo es requerido'; } ],"append-icon":_vm.showPassword ? 'mdi-eye-off' : 'mdi-eye',"type":_vm.showPassword ? 'text' : 'password',"prepend-icon":"mdi-lock","label":"Token de Acceso","placeholder":"*********","counter":"","required":""},on:{"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.loginDF.apply(null, arguments)},"click:append":function($event){_vm.showPassword = !_vm.showPassword}},model:{value:(_vm.code),callback:function ($$v) {_vm.code=$$v},expression:"code"}})],1):_vm._e()],1),_c(VDivider,{staticClass:"mt-3"}),_c(VCardActions,[_c(VSpacer),(_vm.doubleFactor)?_c(VBtn,{attrs:{"align-center":"","justify-center":"","color":"red"},on:{"click":_vm.cancelDF}},[_vm._v("Cancelar ")]):_vm._e(),(_vm.doubleFactor)?_c(VBtn,{attrs:{"align-center":"","disabled":_vm.loading,"justify-center":"","color":"green"},on:{"click":_vm.loginDF}},[_vm._v("Enviar Token ")]):_vm._e(),(!_vm.doubleFactor)?_c(VBtn,{attrs:{"align-center":"","disabled":_vm.loading,"justify-center":"","color":"green"},on:{"click":_vm.login}},[_vm._v("Login ")]):_vm._e()],1),_c(VSnackbar,{attrs:{"color":"red","top":true},model:{value:(_vm.snackbar),callback:function ($$v) {_vm.snackbar=$$v},expression:"snackbar"}},[_vm._v(" "+_vm._s(_vm.errorMessages)+" "),_c(VBtn,{attrs:{"dark":"","text":""},on:{"click":function($event){_vm.snackbar = false}}},[_vm._v(" Cerrar ")])],1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }