<template>
	<v-card flat>
		<v-card-text v-if="errorActualizacionMasiva">
			<v-col cols="12" md="12" sm="12" class="text-center">
				<h3>Se ha producido un Error. Intente una vez más.</h3>
			</v-col>
			<v-col cols="12" md="12" sm="12" class="text-center">
				<v-btn
					block
					depressed
					color="red"
					text
					@click="
						actualizacionMasiva = false;
						ficheroActualizacion = null;
						cargaActualizacionCompleta = false;
						loadingActualizacionMasiva = false;
						errorActualizacionMasiva = false;
						selectedUpdateFile = null;
					"
					>Aceptar</v-btn
				>
			</v-col>
		</v-card-text>
		<v-card-text v-if="cargaActualizacionCompleta">
			<v-col cols="12" md="12" sm="12" class="text-center">
				<h3 v-if="updateLink === ''">
					Carga Realizada Satisfactoriamente
				</h3>
				<div v-else>
					<h3>Carga Realizada Parcialmente</h3>
					<a
						:href="
							'/api/Articulos/ReporteCargaMasiva/' +
							updateLink +
							'?access_token=' +
							token
						"
						target="_blank"
						>Archivo de Reporte</a
					>
				</div>
			</v-col>
			<v-col cols="12" md="12" sm="12" class="text-center">
				<v-btn
					depressed
                    x-large
					color="green"
					text
					@click="
						actualizacionMasiva = false;
						ficheroActualizacion = null;
						cargaActualizacionCompleta = false;
						loadingActualizacionMasiva = false;
						errorActualizacionMasiva = false;
						selectedUpdateFile = null;
					"
					>Aceptar</v-btn
				>
			</v-col>
		</v-card-text>
		<v-card-text
			v-if="loadingActualizacionMasiva && !errorActualizacionMasiva"
		>
			<v-col cols="12" md="12" sm="12" class="text-center">
				<h3>Cargando</h3>
			</v-col>
			<v-col cols="12" md="12" sm="12" class="text-center">
				<v-progress-circular
					:size="75"
					color="green"
					indeterminate
				></v-progress-circular>
			</v-col>
		</v-card-text>
		<v-card-text
			v-if="!loadingActualizacionMasiva && !cargaActualizacionCompleta && !errorActualizacionMasiva"
		>
			<p class="mb-0 text-h6 text--secondary">
				Actualización Masiva de Artículos
			</p>
			<v-file-input
				v-model="selectedUpdateFile"
				accept=".xls, .xlsx"
				@change="cambioficheroActualizacion"
				counter
				show-size
				clearable
				label="Fichero de Actualizacion .XLS o .XLSX"
			></v-file-input>
			<v-row>
				<v-col class="text-center">
					<a
						:href="
							'/api/Articulos/PlantillaActualizacionMasiva?access_token=' +
							token
						"
						target="_blank"
						class="caption"
					>
						<v-icon color="blue">mdi-download</v-icon>Descargar
						Plantilla de Actualización Masiva de Artículos
					</a>
				</v-col>
			</v-row>
			<v-row>
				<v-col cols="12" class="py-0">
					<v-btn
						block
						color="blue"
						outlined
						class="my-2 mb-4"
						@click="actualizacionMasivaPost"
						>Actualizar Artículos</v-btn
					>
				</v-col>
			</v-row>
		</v-card-text>
	</v-card>
</template>

<script>
import axios from "axios";
import { mapState } from "vuex";
export default {
	data() {
		return {
			actualizacionMasiva: false,
			loadingActualizacionMasiva: false,
			ficheroActualizacion: null,
			cargaActualizacionCompleta: false,
			selectedUpdateFile: null,
			errorActualizacionMasiva: false,
			cargaMasiva: false,
			loadingCargaMasiva: false,
			ficheroCarga: null,
			cargaCargaCompleta: false,
			selectedCreateFile: null,
			errorCargaMasiva: false,
			cargaLink: "",
			updateLink: "",
		};
	},
	computed: {
		...mapState({
			cargasMasivas: "cargasMasivas",
			token: "token",
		}),
	},
	mounted() {
        this.initialize();
	},
	methods: {
        initialize(){
            this.resetValues();
        },
		resetValues() {
			this.actualizacionMasiva = false;
			this.ficheroActualizacion = null;
			this.cargaActualizacionCompleta = false;
			this.loadingActualizacionMasiva = false;
			this.errorActualizacionMasiva = false;
			this.selectedUpdateFile = null;
		},
		cambioficheroActualizacion(event) {
			if (typeof event === "undefined" || event == null)
				this.ficheroActualizacion = null;
			else this.ficheroActualizacion = event;
		},
		actualizacionMasivaPost() {
            if (this.ficheroActualizacion != null) {
                this.loadingActualizacionMasiva = true;
				let formData = new FormData();
				formData.append("file", this.ficheroActualizacion);
				axios
					.post("/Articulos/ActualizacionMasiva", formData, {
						headers: {
							"Content-Type": "multipart/form-data",
						},
					})
					.then((response) => {
						this.updateLink = response.data;
						this.cargaActualizacionCompleta = true;
						this.loadingActualizacionMasiva = false;
					})
					.catch((error) => {
						console.log(error);
						this.loadingActualizacionMasiva = false;
						this.errorActualizacionMasiva = true;
					});
			}
		},
	},
};
</script>