import { VCol } from 'vuetify/lib/components/VGrid';
import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSelect } from 'vuetify/lib/components/VSelect';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VCol,{attrs:{"cols":"12"}},[_c(VRow,[_c(VCol,{attrs:{"cols":"4"}},[_c('DownloadBtn',{attrs:{"text":"","color":"primary","className":"my-0","label":"Descargar Reporte<br/>Operadores Sin Operaciones Recientes"},on:{"click":function($event){return _vm.descargarReporte()}}})],1),_c(VCol,{attrs:{"cols":"4"}}),_c(VCol,{attrs:{"cols":"4"}},[_c(VSelect,{attrs:{"items":_vm.intervalos,"item-text":"text","item-value":"value","label":"Tiempo"},on:{"change":function($event){return _vm.GetData()}},model:{value:(_vm.selected),callback:function ($$v) {_vm.selected=$$v},expression:"selected"}})],1)],1),_c(VRow,{staticClass:"px-0 ma-0"},[_c(VCol,{staticClass:"ma-0 text-right text-subtitle-2",attrs:{"cols":"12"}},[_vm._v(" Proxima Actualización en "+_vm._s(_vm.tiempo)+" ")])],1),_c(VRow,[_c(VCol,{staticClass:"col-12"},[_c(VDataTable,{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"items":_vm.desserts,"loading":_vm.cargando,"loading-text":"Cargando... Espere por favor","items-per-page":-1,"hide-default-footer":""},scopedSlots:_vm._u([{key:"item",fn:function(ref){
var item = ref.item;
return [_c('tr',[_c('td',{staticClass:"text-center"},[_vm._v(" "+_vm._s(item.nombre)+" "+_vm._s(item.paterno)+" "+_vm._s(item.materno)+" ")]),_c('td',{staticClass:"text-center"},[_vm._v(_vm._s(_vm.hour(item.tiempoNoReportado))+" Horas")]),_c('td',{staticClass:"text-center"},[_vm._v(_vm._s(_vm.hour(item.tiempoNoTrabajado))+" Horas")])])]}}])})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }