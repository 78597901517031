<template>
  <v-col cols="12" v-if="permisoVista('departamentos','r')">
    <v-dialog v-model="dialogDelete" persistent max-width="40%">
      <v-card>
        <v-card-text>
          <p class="text-h5 pt-4 font-weight-medium text--primary">
            ¿Está seguro que desea eliminar este Departamento?
          </p>
          <p class="subtitle-1 text--primary">
            Esta acción no se puede revertir y será permanente.
          </p>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            text
            color="success"
            :disabled="deleting"
            @click="closeDialog()"
            >Cancelar</v-btn
          >
          <v-btn
            text
            color="error"
            :disabled="deleting"
            :loading="deleting"
            @click="deleteDepartamento()"
            >Eliminar</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="dialog" persistent max-width="40%">
      <v-card>
        <v-card-title>{{ editedItem.idDepartamento ? 'Editar' : 'Crear' }} Departamento</v-card-title>
        <v-card-text>
          <v-container fluid>
            <v-row>
              <v-col cols="12">
                <v-text-field
                  v-model="editedItem.codigo"
                  label="[Opcional] Código del departamento"
                  clearable
                  :error-messages="errorCodigo"
                  @focus="errorCodigo = ''"
                ></v-text-field>
              </v-col>
              <v-col cols="12">
                <v-text-field
                  v-model="editedItem.nombre"
                  label="Nombre del departamento"
                  clearable
                  :error-messages="errorNombre"
                  @focus="errorNombre = ''"
                ></v-text-field>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn :disabled="saving" color="success" text @click="closeDialog()"
            >Cancelar</v-btn
          >
          <v-btn
            :disabled="saving"
            :loading="saving"
            color="error"
            text
            @click="save()"
            >Guardar</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-row>
      <v-col cols="4">
        <v-btn v-if="permisoVista('departamentos','c')" color="primary" :disabled="loading" @click="showDialog()">
          Crear Departamento
        </v-btn>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        <v-data-table
          :items="departamentos"
          :headers="departamentosHeaders"
          :loading="loading"
        >
          <template v-slot:[`item.codigo`]="{ item }">
            {{ item.codigo || 'N/A' }}
          </template>
          <template v-slot:[`item.actions`]="{ item }">
            <v-tooltip v-if="permisoVista('departamentos','u')" bottom color="black" class="white--text">
              <template v-slot:activator="{ on }">
                <v-icon v-on="on" small class="mr-2" @click="showDialog(item)">
                  edit
                </v-icon>
              </template>
              <span class="white--text">Editar</span>
            </v-tooltip>
            <v-tooltip v-if="permisoVista('departamentos','d')" bottom color="black" class="white--text">
              <template v-slot:activator="{ on }">
                <v-icon
                  v-on="on"
                  small
                  class="mr-2"
                  @click="showDialog(item, true)"
                >
                  delete
                </v-icon>
              </template>
              <span class="white--text">Eliminar</span>
            </v-tooltip>
          </template>
        </v-data-table>
      </v-col>
    </v-row>
  </v-col>
</template>

<script>
import axios from "axios";
export default {
  data() {
    return {
      deleting: false,
      saving: false,
      errorCodigo: "",
      errorNombre: "",
      dialog: false,
      dialogDelete: false,
      departamentos: [],
      departamentosHeaders: [
        {
          text: "Código",
          align: "center",
          value: "codigo",
        },
        {
          text: "Nombre",
          align: "center",
          value: "nombre",
        },
        {
          text: "Acciones",
          align: "center",
          value: "actions",
        },
      ],
      loading: false,
      editedItem: {
        idDepartamento: null,
        codigo: null,
        nombre: null,
      },
      defaultEditedItem: {
        idDepartamento: null,
        codigo: null,
        nombre: null,
      },
    };
  },
  mounted() {
    this.initialize();
  },
  methods: {
    deleteDepartamento() {
      this.deleting = true;

			axios
          .put(`/Departamento/Eliminar/${this.editedItem.idDepartamento}`)
          .then(() => {
            this.deleting = false;
            this.closeDialog();
            this.getDepartamentosPuestos();
          })
          .catch((error) => {
            this.deleting = false;
            console.error(error);
          });
		},
    validate() {
      this.errorCodigo = "";
      this.errorNombre = "";

      if (this.editedItem.codigo) {
        if (this.editedItem.codigo.length < 5) {
          this.errorCodigo = "Mínimo 5 caracteres";
        }
      }

      if (!!!this.editedItem.nombre) {
        this.errorNombre = "Requerido";
      } else if (this.editedItem.nombre.length < 5) {
        this.errorNombre = "Mínimo 5 caracteres";
      }

      return this.errorCodigo == "" && this.errorNombre == "";
    },
    save() {
      if (!this.validate()) return;
			
      this.saving = true;
      axios({
        url: this.editedItem.idDepartamento
          ? `/Departamento/Actualizar/${this.editedItem.idDepartamento}`
          : '/Departamento/Crear',
        method: this.editedItem.idDepartamento
          ? 'PUT'
          : 'POST',
        data: {
          codigo: this.editedItem.codigo,
          nombre: this.editedItem.nombre,
        }
      })
        .then(() => {
          this.saving = false;
          this.closeDialog();
          this.getDepartamentosPuestos();
        })
        .catch((error) => {
          if(error.response.status == 409){
            this.errorNombre = 'Ya existe';
          }
          this.saving = false;
          console.error(error);
        });
    },
    closeDialog() {
      this.dialog = false;
      this.dialogDelete = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultEditedItem);
        this.errorCodigo = "";
        this.errorNombre = "";
      });
    },
    showDialog(item = null, dialogDelete = false) {
      this.editedItem = Object.assign({}, item || this.defaultEditedItem);

      if (dialogDelete) this.dialogDelete = true;
      else this.dialog = true;
    },
    initialize() {
      this.getDepartamentosPuestos();
    },
    getDepartamentosPuestos() {
      this.loading = true;
      axios
        .get("/Departamento")
        .then((response) => {
          this.departamentos = response.data;
          this.loading = false;
        })
        .catch((error) => {
          this.loading = false;
          console.log(error);
        });
    },
		
  },
};
</script>
