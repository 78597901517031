<template>
	<v-col cols="12" v-if="permisoVista('sincronizacion-externa','r')">
		<v-row class="text-center">
			<v-col cols="6" md="6" sm="6">
				<VueCtkDateTimePicker
					id="inicio"
					v-model="filtros.inicio"
					label="Desde"
					format="YYYY-MM-DD HH:mm"
					formatted="YYYY-MM-DD HH:mm"
					noHeader
					overlay
					buttonNowTranslation="Ahora"
					@input="getSincronizaciones()"
				/>
			</v-col>
			<v-col cols="6" md="6" sm="6">
				<VueCtkDateTimePicker
					id="fin"
					v-model="filtros.fin"
					:min-date="filtros.inicio"
					label="Hasta"
					format="YYYY-MM-DD HH:mm"
					formatted="YYYY-MM-DD HH:mm"
					noHeader
					overlay
					buttonNowTranslation="Ahora"
					@input="getSincronizaciones()"
				/>
			</v-col>
		</v-row>
		<v-data-table
			:headers="headers"
			:items="sincronizaciones"
			:loading="loading"
			loading-text="Cargando... Espere, Por Favor."
			:footer-props="{
				disableItemsPerPage: true,
				disablePagination: this.loading,
			}"
			:disable-sort="true"
			:disable-pagination="loading"
		></v-data-table>
	</v-col>
</template>

<script>
import axios from "axios";
export default {
	data() {
		return {
			filtros: {
				inicio: null,
				fin: null,
			},
			loading: false,
			sincronizaciones: [],
			headers: [
				{
					text: "Fecha",
					align: "left",
					sortable: true,
					value: "fecha",
				},
				{
					text: "Tipo",
					align: "left",
					sortable: true,
					value: "tipo",
				},
				{
					text: "IP",
					align: "left",
					sortable: true,
					value: "ip",
				},
				{
					text: "País",
					align: "left",
					sortable: true,
					value: "pais",
				},
			],
		};
	},
    created () {
      this.initialize();
    },
	methods: {
		initialize() {
			this.getSincronizaciones();
		},
		getSincronizaciones() {
			axios
				.get("/SincronizacionExterna/GetSincronizacionExterna",{
                    params: this.filtros
                })
				.then((response) => {
					this.sincronizaciones = response.data;
				})
				.catch((error) => {
					console.log(error);
				});
		},
	},
};
</script>