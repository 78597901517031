<template>
	<v-container>
		<v-dialog v-model="dialogDelete" persistent max-width="40%">
			<v-card>
				<v-card-text>
					<p class="text-h5 pt-2 font-weight-medium text--primary">
						¿Está seguro que desea eliminar esta Clase?
					</p>
					<p class="subtitle-1">
						Esta acción no se puede revertir y será permanente.
					</p>
				</v-card-text>
				<v-card-actions>
					<v-spacer></v-spacer>
					<v-btn
						text
						color="success"
						:disabled="deleting"
						@click="cerrarDialog()"
						>Cancelar</v-btn
					>
					<v-btn
						text
						color="error"
						:disabled="deleting"
						:loading="deleting"
						@click="eliminarClase()"
						>Eliminar</v-btn
					>
				</v-card-actions>
			</v-card>
		</v-dialog>
		<v-dialog v-model="dialogCrear" persistent max-width="40%">
			<v-card>
				<v-card-title
					>{{
						itemClase.idArticuloClase == null ? "Crear" : "Editar"
					}}
					Clase</v-card-title
				>
				<v-card-text>
					<v-col cols="12">
						<v-text-field
							v-model="itemClase.clase"
							label="Nombre"
							:error-messages="errorClase"
							@focus="errorClase = ''"
						></v-text-field>
					</v-col>
				</v-card-text>
				<v-card-actions>
					<v-spacer></v-spacer>
					<v-btn
						text
						color="success"
						:disabled="saving"
						@click="cerrarDialog()"
						>Cancelar</v-btn
					>
					<v-btn
						text
						color="error"
						:disabled="saving"
						:loading="saving"
						@click="save()"
						>Guardar</v-btn
					>
				</v-card-actions>
			</v-card>
		</v-dialog>
		<v-row>
			<v-col cols="4" class="mt-0 pt-0">
				<v-btn color="primary" @click="showDialog">Nueva Clase</v-btn>
			</v-col>
			<v-col cols="6" class="mt-0 pt-0 ml-auto">
				<v-text-field
					class="pt-2"
					v-model="search"
					append-icon="search"
					label="Buscar"
					single-line
					hide-details
				></v-text-field>
			</v-col>
		</v-row>
		<v-data-table
			:items="clases"
			:headers="headersClases"
			:loading="loading"
			:search="search"
		>
			<template v-slot:item.actions="{ item }">
				<v-tooltip bottom color="black" class="white--text">
					<template v-slot:activator="{ on }">
						<v-icon
							v-on="on"
							small
							class="mr-2"
							@click="showDialogEdit(item)"
						>
							edit
						</v-icon>
					</template>
					<span class="white--text">Editar</span>
				</v-tooltip>
				<v-tooltip bottom color="black" class="white--text">
					<template v-slot:activator="{ on }">
						<v-icon
							v-on="on"
							small
							class="mr-2"
							@click="showDialogDelete(item)"
						>
							delete
						</v-icon>
					</template>
					<span class="white--text">Eliminar</span>
				</v-tooltip>
			</template>
		</v-data-table>
	</v-container>
</template>

<script>
import axios from "axios";

export default {
	data() {
		return {
			search: "",
			errorClase: "",
			dialogDelete: false,
			dialogCrear: false,
			itemClase: {
				idArticuloClase: null,
				clase: "",
			},
			defaultItemClase: {
				idArticuloClase: null,
				clase: "",
			},
			headersClases: [
				{
					text: "Clase",
					align: "left",
					sortable: true,
					value: "clase",
					width: "90%",
				},
				{
					text: "Acciones",
					value: "actions",
					sortable: false,
					width: "10%",
				},
			],
			clases: [],
			loading: false,
			saving: false,
			deleting: false,
		};
	},
	mounted() {
		this.initialize();
	},
	methods: {
		eliminarClase() {
			this.deleting = true;
			axios
				.put(
					`/Articulos/EliminarArticuloClase/${this.itemClase.idArticuloClase}`
				)
				.then(() => {
					this.deleting = false;
					this.cerrarDialog();
					this.getClases();
				})
				.catch((error) => {
					this.deleting = false;
					console.log(error);
				});
		},
		validate() {
			this.errorClase = "";

			if (
				this.itemClase.clase.length < 4 ||
				this.itemClase.clase.length > 50
			) {
				this.errorClase =
					"La clase debe tener más de 3 caracteres y menos de 50.";
			}

			return this.errorClase == "";
		},
		save() {
			if (!this.validate()) return;
			this.saving = true;
			axios({
				url:
					this.itemClase.idArticuloClase == null
						? "/Articulos/CrearArticuloClase"
						: "/Articulos/ActualizarArticuloClase",
				method:
					this.itemClase.idArticuloClase == null ? "POST" : "PUT",
				params: this.itemClase.idArticuloClase == null ? {
					clase: this.itemClase.clase,
				} : null,
				data:
					this.itemClase.idArticuloClase != null
						? {
								idArticuloClase:
									this.itemClase.idArticuloClase,
								clase: this.itemClase.clase,
						  }
						: null,
			})
				.then(() => {
					this.saving = false;
					this.cerrarDialog();
					this.getClases();
				})
				.catch((error) => {
					this.saving = false;
					if (error.response) {
						if (error.response.status == 409) {
							this.errorClase =
								"Existe una Clase activa con el mismo nombre";
						}
					}
				});
		},
		initialize() {
			this.getClases();
		},
		showDialogEdit(item) {
			this.itemClase = { ...item };
			this.dialogCrear = true;
		},
		showDialogDelete(item) {
			this.itemClase = { ...item };
			this.dialogDelete = true;
		},
		showDialog() {
			this.dialogCrear = true;
		},
		cerrarDialog() {
			this.errorClase = "";
			this.itemClase = { ...this.defaultItemClase };
			this.dialogDelete = false;
			this.dialogCrear = false;
		},
		getClases() {
			this.loading = true;
			axios
				.get("/Articulos/ListarArticuloClase")
				.then((response) => {
					this.clases = response.data;
				})
				.catch((error) => {
					console.log(error);
				})
				.finally(() => {
					this.loading = false;
				});
		},
	},
};
</script>