<template>
	<v-col cols="12">
		<v-dialog v-model="dialog" persistent max-width="900px">
			<v-card>
				<v-card-title
					>Asignar Costo - {{ editedItem.articulo }}</v-card-title
				>
				<v-card-text>
					<v-container fluid>
						<v-row>
							<v-col cols="12">
								<v-data-table
									v-model="movimientosSelected"
									:items="movimientos"
									:headers="movimientosHeaders"
									:loading="loadingMovimientos"
									item-key="idMovimiento"
									show-select
									@toggle-select-all="selectAllMovimientos"
								>
									<template
										v-slot:[`item.cantidad`]="{ item }"
									>
										{{ getCantidadText(item) }}
									</template>
								</v-data-table>
							</v-col>
						</v-row>
						<v-row class="justify-space-between">
							<v-col cols="5" class="py-0">
								<v-text-field
									v-model="editedItem.costo"
									label="Costo Por Pieza"
									prefix="$"
									type="number"
									hide-spin-buttons
									clearable
									:error-messages="errores.errorCosto"
									@focus="errores.errorCosto = null"
									:disabled="loadingMovimientos || saving"
								></v-text-field>
							</v-col>
							<v-col cols="5" class="py-0">
								<datePicker
									v-model="editedItem.fechaCompra"
									format="YYYY-MM-DD"
									label="Fecha de compra"
									clearable
									@input="errores.errorFecha = null"
									:disabled="loadingMovimientos || saving"
								></datePicker>
								<v-slide-y-transition>
									<p
										v-if="errores.errorFecha"
										class="mb-0 pt-2 red--text"
										style="font-size: 0.75rem !important"
									>
										{{ errores.errorFecha }}
									</p>
								</v-slide-y-transition>
							</v-col>
						</v-row>
						<v-slide-y-transition>
							<v-row v-if="errores.errorNoSelected">
								<v-col cols="12" class="pt-4 pb-0">
									<p
										v-if="errores.errorNoSelected"
										class="mb-0 red--text"
									>
										- {{ errores.errorNoSelected }}
									</p>
								</v-col>
							</v-row>
						</v-slide-y-transition>
					</v-container>
				</v-card-text>
				<v-card-actions>
					<v-spacer></v-spacer>
					<v-btn
						text
						color="success"
						:disabled="loadingMovimientos || saving"
						@click="onClickCancelar"
						>Cancelar</v-btn
					>
					<v-btn
						text
						color="error"
						:disabled="loadingMovimientos || saving"
						:loading="saving"
						@click="save"
						>Guardar</v-btn
					>
				</v-card-actions>
			</v-card>
		</v-dialog>
		<v-row>
			<v-col cols="4">
				<v-autocomplete
					v-model="filtros.idArticulo"
					:items="articulos"
					:item-text="nombreArticulo"
					item-value="idArticulo"
					label="Buscar"
					hide-details
					clearable
					:disabled="loadingArticulos"
					@change="getArticulosMP()"
				></v-autocomplete>
			</v-col>
		</v-row>
		<v-row>
			<v-col cols="12">
				<v-data-table
					:items="articulos"
					:headers="headersArticulos"
					:loading="loadingArticulos"
				>
					<template v-slot:[`item.cantidad`]="{ item }">
						{{ getCantidadText(item) }}
					</template>
					<template v-slot:[`item.actions`]="{ item }">
						<v-tooltip bottom color="black" class="white--text">
							<template v-slot:activator="{ on }">
								<v-icon
									v-on="on"
									small
									@click="asignarCosto(item)"
								>
									mdi-check-bold
								</v-icon>
							</template>
							<span class="white--text">Asignar Costo</span>
						</v-tooltip>
					</template>
				</v-data-table>
			</v-col>
		</v-row>
	</v-col>
</template>

<script>
import axios from "axios";
export default {
	data() {
		return {
			filtros: {
				idArticulo: null,
			},
			errores: {
				errorNoSelected: null,
				errorCosto: null,
				errorFecha: null,
			},
			movimientosSelected: [],
			dialog: false,
			movimientos: [],
			movimientosHeaders: [
				{
					text: "Cantidad",
					align: "start",
					value: "cantidad",
				},
				{
					text: "Responsable",
					align: "start",
					value: "responsable",
				},
				{
					text: "Fecha de Creación",
					align: "start",
					value: "fechaCreacion",
				},
				{
					text: "Fecha de Recepción",
					align: "start",
					value: "fechaRecepcion",
				},
				{
					text: "Fecha de Ingreso",
					align: "start",
					value: "fechaIngreso",
				},
			],
			saving: false,
			loadingMovimientos: false,
			editedItem: {
				idArticulo: null,
				articulo: null,
				costo: null,
				fechaCompra: null,
			},
			defaultEditedItem: {
				idArticulo: null,
				articulo: null,
				costo: null,
				fechaCompra: null,
			},
			articulos: [],
			loadingArticulos: false,
			headersArticulos: [
				{
					text: "Código",
					align: "center",
					value: "codigo",
				},
				{
					text: "Artículo",
					align: "center",
					value: "articulo",
				},
				{
					text: "# de Movimientos",
					align: "center",
					value: "numMovimientos",
				},
				{
					text: "Cantidad Total",
					align: "center",
					value: "cantidad",
				},
				{
					text: "Acciones",
					align: "center",
					value: "actions",
				},
			],
		};
	},
	watch: {
		movimientosSelected() {
			if (this.errores.errorNoSelected != null) {
				this.errores.errorNoSelected = null;
			}
		},
	},
	mounted() {
		this.initialize();
	},
	methods: {
		nombreArticulo: ({ codigo, articulo }) => `[${codigo}] - ${articulo}`,
		getCantidadText: ({ cantidad, unidad }) =>
			`${cantidad.toFixed(2)} ${unidad}`,
		selectAllMovimientos({ value }) {
			this.movimientosSelected = value ? this.movimientos.slice() : [];
		},
		validate() {
			Object.keys(this.errores).forEach((key) => {
				this.errores[key] = null;
			});

			if (this.movimientosSelected.length == 0) {
				this.errores.errorNoSelected =
					"Debe seleccionar al menos una entrada";
			}

			if (this.editedItem.costo == null) {
				this.errores.errorCosto = "Requerido";
			} else if (isNaN(parseFloat(this.editedItem.costo))) {
				this.errores.errorCosto = "Inválido";
			} else if (parseFloat(this.editedItem.costo) <= 0) {
				this.errores.errorCosto = "Inválido";
			}

			if (this.editedItem.fechaCompra == null)
				this.errores.errorFecha = "Requerido";

			return Object.values(this.errores).every((value) => value == null);
		},
		save() {
			if (!this.validate()) return;

			this.saving = true;

			const payload = {
				idsMovimientos: this.movimientosSelected.map(
					(articulo) => articulo.idMovimiento
				),
				fecha: this.editedItem.fechaCompra,
				costoPorPieza: parseFloat(this.editedItem.costo),
				idArticulo: this.editedItem.idArticulo,
			};

			axios
				.post("/Compras/AsignarPrecioPiezas", payload)
				.then(() => {
					this.saving = false;
					this.initialize();
					this.onClickCancelar();
				})
				.catch((error) => {
					this.saving = false;
					console.log(error);
				});
		},
		onClickCancelar() {
			this.dialog = false;

			setTimeout(() => {
				Object.keys(this.errores).forEach((key) => {
					this.errores[key] = null;
				});
				this.editedItem = Object.assign({}, this.defaultEditedItem);
				this.movimientos = [];
				this.movimientosSelected = [];
			}, 200);
		},
		initialize() {
			this.articulos = [];
			this.getArticulosMP();
		},
		asignarCosto(item) {
			this.editedItem = Object.assign({}, item);
			this.dialog = true;

			this.getMovimientosPorArticulo();
		},
		getMovimientosPorArticulo() {
			this.loadingMovimientos = true;

			axios
				.get("/Compras/MovimientosPorArticulo", {
					params: {
						idArticulo: this.editedItem.idArticulo,
					},
				})
				.then((response) => {
					this.movimientos = response.data;
				})
				.catch((error) => {
					this.movimientos = [];
					console.log(error);
				})
				.finally(() => {
					this.loadingMovimientos = false;
				});
		},
		getArticulosMP() {
			this.loadingArticulos = true;

			axios
				.get("/Compras/IngresosSinCostoAgrupados", {
					params: this.filtros,
				})
				.then((response) => {
					this.articulos = response.data;
				})
				.catch((error) => {
					this.articulos = [];
					console.log(error);
				})
				.finally(() => {
					this.loadingArticulos = false;
				});
		},
	},
};
</script>