<template>
	<v-container>
		<v-dialog v-model="dialogTerminar" persistent max-width="60%">
			<v-card>
				<v-card-title>Terminar Mantenimiento</v-card-title>
				<v-card-text>
					<v-simple-table>
						<template v-slot:default>
							<thead>
								<tr>
									<th
										class="text-center"
										width="40%"
										style="font-size: 16px"
									>
										Comprobación
									</th>
									<th
										class="text-center"
										width="50%"
										style="font-size: 16px"
									>
										Estado
									</th>
									<th
										class="text-center"
										width="10%"
										style="font-size: 16px"
									>
										Recursos de referencia
									</th>
								</tr>
							</thead>
							<tbody>
								<tr
									v-for="(item, idx) in tareaMantenimiento"
									:key="idx"
								>
									<td class="text-center">
										<p
											class="mb-0"
											style="font-size: 14px !important"
										>
											{{ item.comprobacion }}
										</p>
										<v-textarea
											v-if="item.satisfactoria == 1"
											class="mt-13"
											v-model="item.comentario"
											outlined
											label="Comentarios"
											auto-grow
											rows="1"
											hide-details
										></v-textarea>
									</td>
									<td>
										<p class="pt-3 mb-0 text-center">
											¿Satisfactoria?
										</p>
										<div class="d-flex justify-center">
											<v-btn-toggle
												v-model="item.satisfactoria"
												class="my-3"
												@change="
													item.idsDocumentos = [];
													item.comentario = null;
												"
											>
												<v-btn
													depressed
													small
													class="ma-0"
													:color="
														item.satisfactoria == 0
															? 'success'
															: 'grey lighten-1'
													"
													>Si</v-btn
												>
												<v-btn
													depressed
													small
													class="ma-0"
													:color="
														item.satisfactoria == 1
															? 'error'
															: 'grey lighten-1'
													"
													>No</v-btn
												>
											</v-btn-toggle>
										</div>
										<div
											v-if="item.satisfactoria == 1"
											class="mt-6 text-center"
											bottom
										>
											<template
												v-for="(
													documento, idx
												) in item.idsDocumentos"
											>
												<div
													class="
														d-inline-block
														mx-2
														py-2
													"
													:key="'links-' + idx"
													style="font-size: 14px"
												>
													<a
														class="
															text-decoration-none
														"
														>{{
															documento.nombre
														}}</a
													>
													<v-icon
														small
														@click="
															removerRecurso(
																item,
																documento
															)
														"
														>mdi-close</v-icon
													>
												</div>
											</template>
											<div
												class="
													d-block d-flex
													justify-center
												"
											>
												<v-btn
													color="light-blue lighten-1"
													outlined
													dark
													small
													@click="
														showDialogRecursos(
															item.idMantenimientoComprobacion
														)
													"
													>Agregar Evidencia</v-btn
												>
											</div>
										</div>
									</td>
									<td>
										<ul>
											<li
												v-for="(
													doc, idx
												) in item.documentos"
												:key="`doc-${idx}`"
												class="pb-1"
											>
												<a
													:href="`/api/Documentos/Descargar/${doc}?access_token=${token}`" 
													class="text-decoration-none"
													target="_blank"
													>{{ doc }}</a
												>
											</li>
											<li
												v-for="(
													link, idx
												) in item.links"
												:key="`link-${idx}`"
												class="pb-1"
											>
												<a
													:href="
														link.includes('http')
														? link
														: `https://${link}`
													"
													target="_blank"
													class="text-decoration-none"
													>{{ link }}</a
												>
											</li>
										</ul>
									</td>
								</tr>
							</tbody>
						</template>
					</v-simple-table>
					<v-row>
						<v-col cols="12" md="12" sm="12" class="mt-4">
							<v-textarea
                                v-model="recomendacion"
                                outlined
                                label="Recomendaciones"
                                auto-grow
                                rows="1"
                                hide-details
                            ></v-textarea>
						</v-col>
					</v-row>
					<ul class="my-3">
						<li class="red--text" v-for="ex in errores" :key="ex">
							{{ ex }}
						</li>
					</ul>
				</v-card-text>
				<v-card-actions>
					<v-spacer></v-spacer>
					<v-btn
						color="green"
						text
						:disabled="loadingFinish"
						small
						@click="cerrarDialogTerminar"
						>Cancelar</v-btn
					>
					<v-btn
						color="red"
						text
						small
						:disabled="loadingFinish"
						:loading="loadingFinish"
						@click="terminarTarea"
						>Guardar</v-btn
					>
				</v-card-actions>
			</v-card>
		</v-dialog>
		<v-dialog v-model="dialogRecursos" persistent max-width="40%">
			<v-card>
				<v-card-title>Agregar Evidencias</v-card-title>
				<v-card-text>
					<v-file-input
						v-model="itemRecurso.idsDocumentos"
						label="Seleccione sus archivos"
						accept=".pdf,.doc,.docx,.xls,.xlsx,.ppt,.pptx,.jpg,.jpeg,.png,.gif,.svg"
						counter
						multiple
						show-size
						@change="cambioFicheroRecurso"
					></v-file-input>
					<div class="d-flex justify-center">
						<v-btn
							color="red"
							class="mx-2"
							:disabled="subiendoArchivos"
							@click="cerrarDialogRecursos"
							>Cancelar</v-btn
						>
						<v-btn
							color="green"
							class="mx-2"
							:loading="subiendoArchivos"
							:disabled="validBtn || subiendoArchivos"
							@click="agregarRecurso"
							>Agregar</v-btn
						>
					</div>
				</v-card-text>
			</v-card>
		</v-dialog>
	</v-container>
</template>

<script>
import axios from "axios";
import { mapState } from 'vuex';
export default {
	data() {
		return {
			errores: [],
			idMantenimientoTarea: 0,
			dialogTerminar: false,
			dialogRecursos: false,
			tareaMantenimiento: [],
			itemRecurso: {
				idMantenimientoComprobacion: -1,
				idsDocumentos: [],
			},
			recomendacion: "",
			subiendoArchivos: false,
		};
	},
	computed: {
		validBtn() {
			return !(
				this.itemRecurso.idsDocumentos !== null &&
				this.itemRecurso.idsDocumentos !== undefined &&
				this.itemRecurso.idsDocumentos?.length > 0
			);
		},
		...mapState(["token"])
	},
	methods: {
		validar(){
			this.errores = [];

			const status = this.tareaMantenimiento.filter(
				(tarea) => tarea.satisfactoria == undefined
			);

			if (status.length > 0) {
				this.errores.push(
					"Debe indicar el estado de cada comprobación."
				);
			}

			return this.errores.length == 0;
		},
		terminarTarea() {

			if (!this.validar()) return;
			
			const item = {
				idMantenimientoTarea: this.idMantenimientoTarea,
				comprobaciones: this.tareaMantenimiento.map((tarea) => ({
					idMantenimientoComprobacion:
						tarea.idMantenimientoComprobacion,
					satisfactoria: !tarea.satisfactoria,
					comentario: tarea.comentario,
					idsDocumentos: tarea.idsDocumentos.map(
						(doc) => doc.idDocumento
					),
				})),
				recomendacion: this.recomendacion
			};
			this.loadingFinish = true;
			axios
				.put("/Mantenimientos/TerminarTareaMantenimiento", item)
				.then(() => {
					this.loadingFinish = false;
					this.cerrarDialogTerminar();
				})
				.catch((error) => {
					this.loadingFinish = false;
					console.log(error);
				});
		},
		showDialogRecursos(idMantenimientoComprobacion) {
			this.itemRecurso.idMantenimientoComprobacion =
				idMantenimientoComprobacion;
			this.dialogRecursos = true;
		},
		cerrarDialogRecursos() {
			this.itemRecurso = {
				idMantenimientoComprobacion: -1,
				idsDocumentos: [],
			};
			this.dialogRecursos = false;
		},
		agregarRecurso() {
			if (
				this.itemRecurso.idsDocumentos !== null &&
				this.itemRecurso.idsDocumentos?.length > 0
			) {
				let documentos = new FormData();
				this.itemRecurso.idsDocumentos.forEach((doc) => {
					documentos.append("ficheros", doc);
				});

				this.subiendoArchivos = true;

				axios
					.post("/Documentos/Cargar", documentos, {
						headers: {
							"Content-Type": "multipart/form-data",
						},
					})
					.then((response) => {
						const documentosSubidos = response.data.map((doc) => ({
							idDocumento: doc.idDocumento,
							nombre: doc.nombre,
						}));

						if (documentosSubidos.length > 0) {
							this.tareaMantenimiento =
								this.tareaMantenimiento.map((item) => {
									if (
										item.idMantenimientoComprobacion ==
										this.itemRecurso
											.idMantenimientoComprobacion
									) {
										item.idsDocumentos =
											item.idsDocumentos.concat(
												documentosSubidos
											);
									}
									return item;
								});
						}
					})
					.catch((error) => {
						console.log(error);
					})
					.finally(() => {
						this.subiendoArchivos = false;
						this.cerrarDialogRecursos();
					});
			}

			if (
				this.itemRecurso.idsDocumentos == null ||
				this.itemRecurso.idsDocumentos?.length == 0
			)
				this.cerrarDialogRecursos();
		},
		removerRecurso(itemDelete, resourceDelete) {
			axios
				.put(`/Documentos/Eliminar/${resourceDelete.nombre}`)
				.then(() => {
					this.tareaMantenimiento = this.tareaMantenimiento.map(
						(item) => {
							if (
								item.idMantenimientoComprobacion ==
								itemDelete.idMantenimientoComprobacion
							) {
								item.idsDocumentos = item.idsDocumentos.filter(
									(doc) =>
										doc.idDocumento !=
										resourceDelete.idDocumento
								);
							}
							return item;
						}
					);
				})
				.catch((error) => {
					console.log(error);
				});
		},
		cambioFicheroRecurso(event) {
			if (typeof event === "undefined" || event === null)
				this.itemRecurso.idsDocumentos = null;
			else this.itemRecurso.idsDocumentos = event;
		},
		cerrarDialogTerminar() {
			this.errores = [];
			this.tareaMantenimiento = [];
			this.recomendacion = "";
			this.$emit("refresh");
			this.dialogTerminar = false;
		},
		terminarMantenimiento(idMantenimientoTarea) {
			this.idMantenimientoTarea = idMantenimientoTarea;
			axios
				.get(
					`/Mantenimientos/TareaMantenimientoComprobaciones/${idMantenimientoTarea}`
				)
				.then((response) => {
					this.tareaMantenimiento = response.data.map((tarea) => ({
						...tarea,
						idsDocumentos: [],
					}));
					this.dialogTerminar = true;
				})
				.catch((error) => {
					console.log(error);
				});
		},
	},
};
</script>

<style>
</style>