<template>
  <VueDatePicker
    v-model="value"
    no-header
    :format="format"
    :min-date="minDateConvert"
    :max-date="maxDateConvert"
    @onChange="onChange"
  >
    <template #activator>
      <v-text-field
        :label="label"
        v-model="dateLabel"
        @click:clear="onClear"
        :clearable="clearable"
        ref="activator"
        readonly
        :hide-details="hideDetails"
        :disabled="disabled"
        :error-messages="errorMessages"
        @focus="focusFunc()"
      ></v-text-field>
    </template>
  </VueDatePicker>
</template>

<script>
import moment from 'moment';
import { VueDatePicker } from '@mathieustan/vue-datepicker';
import '@mathieustan/vue-datepicker/dist/vue-datepicker.min.css';

export default {
  props:{
    value:{
      type:String,
      default:null,
    },
    format:{
      type:String,
      default:"MM-DD-YYYY"
    },
    label:{
      type:String,
      default:null,
    },
    clearable:{
      type:Boolean,
      default:false,
    },
    minDate:{
      type:String,
      default:null
    },
    maxDate:{
      type:String,
      default:null
    },
    maxToday:{
      type:Boolean,
      default:false
    },
    disabled:{
      type: Boolean,
      default: false
    },
    errorMessages:{
      type: String,
      default: ''
    },
    focus: {
      type: Function,
      default: () =>{}
    },
    hideDetails:{
      type: Boolean,
      default: true
    }
  },
  components:{
    VueDatePicker
  },
  computed:{
    dateLabel(){
      if(this.value == null || this.value.length == 0) return "";
      else {
        return moment(this.value).format(this.format); 
      }
    },
    minDateConvert(){
      if(this.minDate == null || this.minDate.length == 0) return "";
      else return moment(this.minDate, this.format).format("YYYY-MM-DD");
    },
    maxDateConvert(){
      if( (this.maxDate == null || this.maxDate.length == 0) && !this.maxToday) return "";
      else {
        var max = moment();

        if(this.maxDate != null && this.maxDate.length > 0) max = moment(this.maxDate, this.format);
        
        var now = moment();

        if(now < max) return now.format("YYYY-MM-DD");
        else return max.format("YYYY-MM-DD");
      }
    }
  },
  methods:{
    setValue(value){ 
      this.value = value;
    },
    onClear(){
      this.value = null;
      this.$emit('input',null);
    },
    onChange(){
      this.$emit('input',this.dateLabel);
    },
    focusFunc(){
      this.$emit('focus');
    }
  }
}
</script>