<template>
  <v-container fluid>
    <v-row>
      <v-col cols="4">
        <v-autocomplete
          v-model="filtro.idMaquinaIns"
          label="Máquina (Opcional)"
          :items="maquinasIns"
          :item-text="nombreMaquina"
          item-value="idMaquinaIns"
          hide-details
          clearable
          :disabled="loadingMaquinasIns"
          :loading="loadingMaquinasIns"
          @change="getData()"
        ></v-autocomplete>
      </v-col>
      <v-col cols="4">
        <VueCtkDateTimePicker
          id="inicio"
          v-model="filtro.Desde"
          :maxDate="filtro.Hasta"
          label="Desde (Opcional)"
          format="YYYY-MM-DD HH:mm"
          formatted="YYYY-MM-DD HH:mm"
          noHeader
          overlay
          buttonNowTranslation="Ahora"
          @input="getData()"
          class="my-2"
        />
      </v-col>
      <v-col cols="4">
        <VueCtkDateTimePicker
          id="fin"
          v-model="filtro.Hasta"
          :min-date="filtro.Desde"
          label="Hasta (Opcional)"
          format="YYYY-MM-DD HH:mm"
          formatted="YYYY-MM-DD HH:mm"
          noHeader
          overlay
          buttonNowTranslation="Ahora"
          @input="getData()"
          class="my-2"
        />
      </v-col>
      <v-col cols="12" class="pb-0 d-flex align-center justify-space-between">
        <download-btn
          text
          color="primary"
          className="my-0 pl-0"
          @click="descargarReporte()"
          label="Descargar Reporte de<br>Inactividad de Máquinas"
        />
      </v-col>
      <v-col cols="12"
        ><v-data-table
          :headers="headers"
          :items="maquinasInactivas"
          :loading="loading"
          sort-by="calories"
        >
          <template v-slot:no-data>
            <v-btn color="primary" @click="initialize">
              Refrescar
            </v-btn>
          </template>
        </v-data-table></v-col
      >
    </v-row>
  </v-container>
</template>

<script>
import axios from "axios";
import moment from "moment";
export default {
  data: () => ({
    loading: false,
    loadingMaquinasIns: false,
    maquinasIns: [],
    dialog: false,
    dialogDelete: false,
    headers: [
      {
        text: "Máquina Instancia",
        align: "center",
        sortable: false,
        value: "maquinaInstancia",
      },
      { text: "MQTTClave", value: "mqttClave" },
      { text: "Fecha Inicio", value: "fechaInicio" },
      { text: "Fecha Fin", value: "fechaFin" },
    ],
    maquinasInactivas: [],
    editedIndex: -1,
    filtro: {
      idMaquinaIns: null,
      Desde: null,
      Hasta: null,
    },
  }),
  mounted() {
    this.initialize();
  },
  methods: {
    nombreMaquina: ({ nombre, marca, modelo }) =>
			`[${nombre}] - ${marca} ${modelo}`,
    initialize() {
      this.getMaquinasIns();
      this.getData();
    },
    getMaquinasIns(){
      this.loadingMaquinasIns = true;

      axios
        .get('/MaquinaInstancias/Basico')
        .then( (response) => {
          this.maquinasIns = response.data;
        })
        .catch( (error) => {
          console.log(error);
          this.maquinasIns = [];
        })
        .finally( () => {
          this.loadingMaquinasIns = false;
        })
    },
    getData() {
      this.loading = true;

      axios
        .get('/Maquinas/InnactividadMaquina',{
          params: {
            idMaquinaIns: this.filtro.idMaquinaIns,
            fechaInicio: this.filtro.Desde,
            fechaFin: this.filtro.Hasta
          }
        })
        .then((response) => {
          this.maquinasInactivas = response.data;
        })
        .catch((error) => {
          console.log(error);
        })
        .finally( () => {
          this.loading = false;
        })
    },
    descargarReporte() {
      var datetime = moment().format("YYYYMMDDHHmmss");

      var url = "/Maquinas/XlsxInactividadMaquina";
      var hayparametros = false;

      if (this.filtro.Desde != null) {
        if (hayparametros == false) {
          hayparametros = true;
          url += "?";
        } else url += "&";

        url += "fechaInicio=" + this.filtro.Desde;
      }

      if (this.filtro.Hasta != null) {
        if (hayparametros == false) {
          hayparametros = true;
          url += "?";
        } else url += "&";
        url += "fechaFin=" + this.filtro.Hasta;
      }

      if (this.filtro.idMaquinaIns != null) {
        if (hayparametros == false) {
          hayparametros = true;
          url += "?";
        } else url += "&";
        url += "idMaquinaIns=" + this.filtro.idMaquinaIns;
      }

      this.$utils.axios.downloadFile(
        url,
        "GET",
        "reporte-inactividad-maquinas-" + datetime + ".xlsx",
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
      );
    },
  },
};
</script>
