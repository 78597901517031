<template>
	<v-col cols="12" v-if="permisoVista('costodestajo','r')">
		<v-dialog v-model="dialog" persistent max-width="50%">
			<v-card>
				<v-card-title
					>{{ editedItemIndex == -1 ? "Crear Nueva" : "Editar" }}
					Configuración
				</v-card-title>
				<v-card-text>
					<v-row>
						<v-col cols="6">
							<v-autocomplete
								v-model="editedItem.idArticulo"
								:items="articulosComputed"
								:item-text="nombreArticulo"
								item-value="idArticulo"
								label="Artículo"
								:clearable="editedItemIndex==-1"
								:readonly="editedItemIndex>-1"
								:error-messages="errorArticulo"
								@focus="errorArticulo = ''"
								@change="
									editedItem.idReceta=null;
									editedItem.idPaso=null;
									editedItem.idSubpaso=null;
								"
							></v-autocomplete>
						</v-col>
						<v-col cols="6">
							<v-autocomplete
								v-model="editedItem.idReceta"
								:items="recetasComputed"
								item-text="descripcion"
								item-value="idReceta"
								label="Receta"
								:clearable="editedItemIndex==-1"
								:readonly="editedItemIndex>-1"
								:error-messages="errorReceta"
								@focus="errorReceta = ''"
								@change="
									editedItem.idPaso=null;
									editedItem.idSubpaso=null;
								"
							></v-autocomplete>
						</v-col>
						<v-col cols="6">
							<v-autocomplete
								v-model="editedItem.idPaso"
                :items="areasComputed"
								item-text="area"
                item-value="idPaso"
								label="Área"
								:clearable="editedItemIndex==-1"
								:readonly="editedItemIndex>-1"
								:error-messages="errorArea"
								@focus="errorArea = ''"
								@change="
									editedItem.idSubpaso=null;
								"
							></v-autocomplete>
						</v-col>
						<v-col cols="6">
							<v-autocomplete
								v-model="editedItem.idSubpaso"
                :items="subpasosComputed"
                :item-text="nombreSubpaso"
                item-value="idSubpaso"
								label="Subpaso"
								:clearable="editedItemIndex==-1"
								:readonly="editedItemIndex>-1"
								:error-messages="errorSubpaso"
								@focus="errorSubpaso = ''"
								@change="changeSubpaso"
							></v-autocomplete>
						</v-col>
						<v-col cols="6">
							<v-text-field
								v-model="editedItem.costoDestajo"
								label="Costo por Destajo"
								type="number"
								:min="0"
								prefix="$"
								:error-messages="errorCosto"
								@focus="errorCosto = ''"
							></v-text-field>
						</v-col>
						<v-col cols="6">
							<time-picker
								v-model="editedItem.tiempo"
								label="Tiempo del Subpaso (HH:MM:SS)"
								:error="errorTiempo"
								:disabled="editedItem.idSubpaso==null"
								@focus="errorTiempo=false"
							></time-picker>
						</v-col>
					</v-row>
				</v-card-text>
				<v-card-actions>
					<v-spacer />
					<v-btn
						color="success"
						text
						:disabled="saving"
						@click="cerrarDialog()"
						>Cancelar</v-btn
					>
					<v-btn
						color="error"
						text
						:disabled="saving"
						:loading="saving"
						@click="guardar()"
						>Guardar</v-btn
					>
				</v-card-actions>
			</v-card>
		</v-dialog>
		<v-dialog
			v-model="cargaMasiva"
			persistent
			max-width="40%"
		>
			<v-card>
				<v-card-title>
					Cargar Formato De Carga Masiva 
				</v-card-title>
				<template v-if="estadoCarga == 0">
					<v-card-text>
						<v-file-input
      			  v-model="ficheroActualizacion"
      			  label="Fichero de Tabulación de Costos de Destajo (.csv)"
      			  accept=".csv"
      			  counter
      			  show-size
      			  @change="cambioFicheroActualizacion"
      			></v-file-input>
					</v-card-text>
					<v-card-actions>
						<v-spacer></v-spacer>
						<v-btn @click="cerrarCargaMasiva" text color="green">Cancelar</v-btn>
						<v-btn @click="realizarCargaMasiva" text color="red">Guardar</v-btn>
					</v-card-actions>
				</template>
				<template v-if="estadoCarga == 1">
					<v-card-text class="text-center">
						<v-progress-circular
							indeterminate
							size="100"
							width="5"
							color="primary"
						></v-progress-circular>
						<div class="text-h6">Cargando</div>
					</v-card-text>
				</template>
				<template v-if="estadoCarga == 2">
					<v-card-text class="text-center">
						<lottie
							id="inProgressAlerta"
							:options="inProgressAnimationOptions"
							:width="400"
							:key="`inprogress-${lottieKey}`"
							class="py-0"
						/>
						<div class="text-h5 font-weight-bold">Carga Masiva en Progreso.</div>
						<div class="text-h5">Se le notificará cuando la tarea haya concluido.</div>
					</v-card-text>
					<v-card-actions>
						<v-spacer></v-spacer>
							<v-btn text color="green" @click="cerrarCargaMasiva">Aceptar</v-btn>
						<v-spacer></v-spacer>
					</v-card-actions>
				</template>
				<template v-if="estadoCarga == 3">
					<v-card-text class="text-center">
						<v-icon
							size="100"
							color="red"
						>
							mdi-close-circle-outline
						</v-icon>
						<div class="text-h6">Ha sucedido un Error. Intente nuevamente.</div>
					</v-card-text>
					<v-card-actions>
						<v-spacer></v-spacer>
							<v-btn text color="green" @click="cerrarCargaMasiva">Aceptar</v-btn>
						<v-spacer></v-spacer>
					</v-card-actions>
				</template>

			</v-card>
		</v-dialog>
		<v-dialog v-model="dialogDelete" persistent max-width="40%">
			<v-card>
				<v-card-text>
					<p class="text-h5 pt-4 font-weight-medium text--primary">
						¿Está seguro que desea eliminar este Costo Destajo?
					</p>
					<p class="subtitle-1">
						Esta acción no se puede revertir y será permanente.
					</p>
				</v-card-text>
				<v-card-actions>
					<v-spacer></v-spacer>
					<v-btn
						text
						color="success"
						:disabled="deleting"
						@click="cerrarDialogDelete()"
						>Cancelar</v-btn
					>
					<v-btn
						text
						color="error"
						:disabled="deleting"
						:loading="deleting"
						@click="deleteCostoDestajo()"
						>Eliminar</v-btn
					>
				</v-card-actions>
			</v-card>
		</v-dialog>
		<v-row>
			<v-col cols="12" class="d-flex">
				<v-btn 
					v-if="permisoVista('costodestajo','c')"
					color="primary" 
					class="mr-auto" 
					@click="showDialog()"
					>Crear Nueva Configuración</v-btn
				>
				<v-btn color="primary" text @click="abrirCargaMasiva">Cargar Formato<br> De Carga Masiva</v-btn>
			</v-col>
		</v-row>
		<v-row class="mt-0">
			<v-col cols="1">
				<v-switch
					v-model="porCodigo"
					:label="porCodigo ? 'Por Código':'Por Artículo'"
					@change="resetear"
				></v-switch>
			</v-col>
			<v-col cols="5" v-if="porCodigo">
				<v-text-field
					v-model="filtros.codigoArticulo"
					label="Código del Artículo"
					hide-details
					clearable
					@keydown="getCostoDestajosDebounce()"
				></v-text-field>
			</v-col>
			<v-col cols="5" v-if="!porCodigo">
				<v-autocomplete
					v-model="filtros.idArticulo"
					:items="articulosNombres"
					:item-text="articuloNombre"
					item-value="idArticulo"
					label="Artículo"
					hide-details
					clearable
					@change="getCostoDestajos()"
				></v-autocomplete>
			</v-col>
			<v-col cols="6">
				<v-autocomplete
					v-model="filtros.idArea"
					:items="areas"
					item-text="nombre"
					item-value="idArea"
					label="Área"
					hide-details
					clearable
					@change="getCostoDestajos()"
				></v-autocomplete>
			</v-col>
		</v-row>
		<v-row class="mt-0">
			<v-col cols="12">
				<download-btn
					v-if="permisoVista('costodestajo','r')"
      	  color="blue darken-1"
      	  text
      	  @click="descargarTabulador"
      	  label="Descargar Formato<br/>De Carga Masiva"
      	></download-btn>
			</v-col>
		</v-row>
		<v-row>
			<v-col cols="12">
				<v-data-table
					:items="destajos"
					:headers="destajosHeadersFiltrados"
					:loading="loading"
				>
					<template v-slot:item.articulo="{ item }">
						{{ nombreArticulo(item) }}
					</template>
					<template v-slot:item.subpaso="{ item }">
						{{ nombreSubpaso(item) }}
					</template>
					<template v-slot:item.costo="{ item }">
						<p class="mb-0">$ {{ item.costo.toFixed(2) }}</p>
					</template>
					<template v-slot:item.tiempo="{ item }">
						{{ getTiempoFormat(item.tiempo) }}
					</template>
					<template v-slot:item.actions="{ item }">
						<v-tooltip bottom color="black" class="white--text">
							<template v-slot:activator="{ on }">
								<v-icon
									v-if="permisoVista('costodestajo','u')"
									v-on="on"
									small
									class="mr-2"
									@click="showEditDialog(item)"
								>
									edit
								</v-icon>
							</template>
							<span class="white--text">Editar</span>
						</v-tooltip>
						<v-tooltip bottom color="black" class="white--text">
							<template v-slot:activator="{ on }">
								<v-icon 
									v-if="permisoVista('costodestajo','d')"
									v-on="on" 
									small 
									@click="showDialogDelete(item)
									"> delete </v-icon
								>
							</template>
							<span class="white--text">Eliminar</span>
						</v-tooltip>
					</template>
				</v-data-table>
			</v-col>
		</v-row>
	</v-col>
</template>

<script>
import axios from "axios";
import { mapState } from "vuex"
import DownloadBtn from '@/components/DownloadBtn.vue';
import moment from "moment"
import TimePicker from '../../TimePicker.vue';
import Lottie from 'vue-lottie';
import { debounce } from "debounce";
import * as inProgressAnimation from '@/assets/inProgress.json';

export default {
	components: { 
    DownloadBtn,
	TimePicker,
	'lottie': Lottie,
  },
	data() {
		return {
			porCodigo: true,
			lottieKey: 0,
			inProgressAnimationOptions: {
				animationData: inProgressAnimation.default,
				loop: true,
				speed: 1
			},
			areas: [],
			articulosNombres: [],
			filtros:{
				codigoArticulo: null,
				idArticulo: null,
				idArea: null
			},
			errorTiempo: false,
			destajos: [],
			destajosHeaders: [
				{
					text: "Artículo",
					align: "center",
					value: "articulo",
					sortable: false,
				},
				{
					text: "Área",
					align: "center",
					value: "area",
					sortable: false,
				},
				{
					text: "Subpaso",
					align: "center",
					value: "subpaso",
					sortable: false,
				},
				{
					text: "Costo",
					align: "center",
					value: "costo",
					sortable: false,
				},
				{
					text: "Tiempo",
					align: "center",
					value: "tiempo",
					sortable: false,
				},
				{
					text: "Acciones",
					align: "center",
					value: "actions",
					width: "5%",
					sortable: false,
				},
			],
			loading: false,
			dialog: false,
			editedItem: {
				idArticulo: null,
				idReceta: null,
				idArea: null,
        idPaso: null,
				idSubpaso: null,
				costoDestajo: 0,
				tiempo: 0
			},
			defaultEditedItem: {
				idArticulo: null,
				idReceta: null,
				idArea: null,
        idPaso: null,
				idSubpaso: null,
				costoDestajo: 0,
				tiempo: 0
			},
			editedItemIndex: -1,
			errorArticulo: "",
      errorReceta: "",
      errorArea: "",
      errorSubpaso: "",
      errorCosto: "",
	  		deleting: false,
			dialogDelete: false,
			saving: false,
			articulosDatos: [],
			cargaMasiva: false,
			estadoCarga: 0,
			ficheroActualizacion: null,
		};
	},
	mounted() {
		this.initialize();
	},
	computed: {
		destajosHeadersFiltrados() {
			return this.permisoVista("costodestajo", "$")
				? this.destajosHeaders
				: this.destajosHeaders.filter((head) => head.value != "costo");
		},
		...mapState(["token"]),
    subpasosComputed(){
      const subpasos = [];
      this.articulosDatos.forEach( articulo => {
        if(articulo.idArticulo == this.editedItem.idArticulo){
          articulo.recetas.forEach( receta => {
            if(receta.idReceta == this.editedItem.idReceta){
              receta.areas.forEach( area => {
                if(
                  area.idArea == this.editedItem.idArea ||
                  area.idPaso == this.editedItem.idPaso
                ){
                  subpasos.push( ...area.subpasos.map( subpaso => ({
                    idSubpaso: subpaso.idSubpaso,
                    subpasoCodigo: subpaso.codigo,
                    subpasoNombre: subpaso.nombre,
                  })));
                }
              });
            }
          });
        }
      });
      return subpasos;
    },
    areasComputed(){
      const areas = [];
      this.articulosDatos.forEach( articulo => {
        if(articulo.idArticulo == this.editedItem.idArticulo){
          articulo.recetas.forEach( receta => {
            if(receta.idReceta == this.editedItem.idReceta){
              areas.push(...receta.areas.map( area => ({
                idPaso: area.idPaso,
                idArea: area.idArea,
                area: area.area,
              })));
            }
          });
        }
      });
      return areas;
    },
		recetasComputed() {
			const recetas = [];
      this.articulosDatos.forEach( articulo => {
        if(articulo.idArticulo == this.editedItem.idArticulo){
          recetas.push( ...articulo.recetas.map( receta => ({
            idReceta: receta.idReceta,
            descripcion: receta.descripcion,
          })));
        }
      });
      return recetas;
		},
		articulosComputed() {
			return this.articulosDatos.map((articulo) => ({
				idArticulo: articulo.idArticulo,
				articuloCodigo: articulo.codigo,
				articuloNombre: articulo.nombre,
			}));
		},
	},
	methods: {
		resetear(){
			this.filtros.idArticulo = null;
			this.filtros.codigoArticulo = null;
			this.getCostoDestajos();
		},
		articuloNombre: (value) =>
			value.codigo != null
				? `[${value.codigo}] - ${value.nombre}`
				: `${value.nombre}`,
		changeSubpaso(){
			if(this.editedItem.idSubpaso == null){
				this.$set(this.editedItem,'tiempo',0);
			}
		},
		noTrim: (num) => ("0"+num).slice(-2),
		getTiempoFormat(tiempo){
			if(tiempo == undefined) return "N/A";
			const hours = Math.floor(moment.duration(tiempo,'seconds').asHours());
			const minutes = moment.duration(tiempo,'seconds').minutes();
			const seconds = moment.duration(tiempo,'seconds').seconds();
			return this.noTrim(hours) + ':' + this.noTrim(minutes) + ':' + this.noTrim(seconds);
		},
		nombreArticulo: (item) =>
			`[${item.articuloCodigo}] - ${item.articuloNombre}`,
		nombreSubpaso: (item) => {
      if(item.subpasoCodigo != null && item.subpasoNombre != null){
        if(item.subpasoCodigo == null) return `${item.subpasoNombre}`;
        else return `[${item.subpasoCodigo}] - ${item.subpasoNombre}`;
      }
      else{
        return 'N/A';
      }
    },
		descargarTabulador(){
      var datetime = moment().format('YYYYMMDDHHmmss');
      var url = '/CostoDestajo/DescargaFormatoCostoDestajo?access_token='+this.token;
			if(this.porCodigo & this.filtros.codigoArticulo != null && this.filtros.codigoArticulo.length > 0) url+=`&codigo=${this.filtros.codigoArticulo}`;
			if(!this.porCodigo && this.filtros.idArticulo != null && this.filtros.idArticulo > 0 ) url+=`&idArticulo=${this.filtros.idArticulo}`;
			if(this.filtros.idArea != null && this.filtros.idArea > 0 ) url+=`&idArea=${this.filtros.idArea}`;
			this.$utils.axios.downloadFile(url,'GET','tabulador-destajo-'+datetime+'.csv','text/csv');
    },
		deleteCostoDestajo(){
			this.deleting = true;
			axios
				.put(`/CostoDestajo/Desacivar/${this.editedItem.idPaso}${this.editedItem.idSubpaso!=null?`?idSubpaso=${this.editedItem.idSubpaso}`:''}`)
				.then( () => {
					this.deleting = false;
					this.cerrarDialogDelete();
					this.initialize();
				})
				.catch( error => {
					this.deleting = false;
					console.log(error);
				})
		},
		showDialogDelete(item){
			this.editedItem = Object.assign({},item);
			this.dialogDelete = true;
		},
		cerrarDialogDelete(){
			this.dialogDelete = false;
			this.editedItem = Object.assign({},this.defaultEditedItem);
		},
		showEditDialog(item) {
			this.editedItemIndex = this.destajos.indexOf(item);
            item.costoDestajo = item.costo;
			this.editedItem = Object.assign({}, item);
			this.dialog = true;
		},
		showDialog() {
			this.editedItemIndex = -1;
			this.editedItem = Object.assign({}, this.defaultEditedItem);
			this.dialog = true;
		},
		validate() {
      this.errorArticulo = "";
      this.errorReceta = "";
      this.errorArea = "";
      this.errorSubpaso = "";
      this.errorCosto = "";
	  		this.errorTiempo = false;

			if (this.editedItem.idArticulo == null) {
				this.errorArticulo = "Debe seleccionar un Artículo";
			}

			if (this.editedItem.idReceta == null) {
				this.errorReceta = "Debe seleccionar una Receta";
			}

			if (this.editedItem.idPaso == null) {
				this.errorArea = "Debe seleccionar un Área";
			}

			if (this.subpasosComputed.length > 0 && this.editedItem.idSubpaso == null) {
				this.errorSubpaso = "Debe seleccionar un Subpaso";
			}

			if (
				isNaN(parseFloat(this.editedItem.costoDestajo)) ||
				parseFloat(this.editedItem.costoDestajo) <= 0
			) {
				this.errorCosto = "Debe ingresar una cantidad valida";
			}

			if(this.editedItem.idSubpaso != null){
				if(this.editedItem.tiempo <= 0 ){
					this.errorTiempo = true;
				}
			}

			return this.errorArticulo == "" &&
        this.errorReceta == "" &&
        this.errorArea == "" &&
        this.errorSubpaso == "" &&
        this.errorCosto == "" &&
		!this.errorTiempo
		},
		guardar() {
			if (!this.validate()) return;
			this.saving = true;
      const obj = {
        idReceta: this.editedItem.idReceta,
        idPaso: this.editedItem.idPaso, //revisar
        idSubpaso: this.editedItem.idSubpaso,
        costoDestajo: parseFloat(this.editedItem.costoDestajo),
		tiempo: this.editedItem.tiempo
      }

			axios
				.put("/CostoDestajo/CrearEditar", obj)
				.then(() => {
					this.saving = false;
					this.cerrarDialog();
          this.initialize();
				})
				.catch((error) => {
					this.saving = false;
					console.log(error);
				});
		},
		cerrarDialog() {
			this.dialog = false;
			this.editedItem = Object.assign({}, this.defaultEditedItem);
			this.editedItemIndex = -1;
    	this.errores = [];
		},
		initialize() {
			this.getAreas();
			this.getArticulosNombres();
			this.getCostoDestajos();
			this.getListarArticulosDatos();
		},
		getAreas(){
			axios
				.get('/Areas')
				.then( response => {
					this.areas = response.data.filter( area => area.idArea > 1 )
				})
				.catch( error => {
					console.log(error);
				})
		},
		getArticulosNombres(){
			axios
				.get('/Articulos/ArticulosNombres',{
					params:{
						componentes: true
					}
				})
				.then( response => {
					this.articulosNombres = response.data;
				})
				.catch( error => {
					console.log(error);
				})
		},
		getListarArticulosDatos() {
			axios
				.get("/CostoDestajo/ListarArticulosDatos")
				.then((response) => {
					this.articulosDatos = response.data;
				})
				.catch((error) => {
					console.log(error);
				});
		},
		getCostoDestajosDebounce: debounce(function () {
			this.getCostoDestajos();
		}, 1000),
		getCostoDestajos() {
			this.loading = true;
			axios
				.get("/CostoDestajo/Listar",{
					params:{
						codigo: this.porCodigo ? this.filtros.codigoArticulo : null,
						idArticulo: this.porCodigo ? null : this.filtros.idArticulo,
						idArea: this.filtros.idArea
					}
				})
				.then((response) => {
					this.destajos = response.data;
					this.loading = false;
				})
				.catch((error) => {
					this.loading = false;
					console.log(error);
				});
		},
		abrirCargaMasiva(){
			this.cargaMasiva = true;
		},
		cerrarCargaMasiva(){
			this.cargaMasiva = false;
			this.estadoCarga = 0;
			this.ficheroActualizacion = null;
		},
		cambioFicheroActualizacion(event) {
      if (typeof event === "undefined" || event == null)
        this.ficheroActualizacion = null;
      else this.ficheroActualizacion = event;
    },
		realizarCargaMasiva(){
			if (this.ficheroActualizacion != null) {
        this.estadoCarga = 1;
				let formData = new FormData();
        formData.append("reportePrecios", this.ficheroActualizacion);
        axios
          .post("/CostoDestajo/CargaMasivaCostoDestajo", formData, {
            headers: {
              "Content-Type": "multipart/form-data"
            }
          })
          .then(() => {
            this.estadoCarga = 2;
						this.getCostoDestajos();
          })
          .catch(error => {
            this.estadoCarga = 3;
            console.log(error);
          });
      }
		}
	},
};
</script>