import { VCol } from 'vuetify/lib/components/VGrid';
import { VProgressLinear } from 'vuetify/lib/components/VProgressLinear';
import { VRow } from 'vuetify/lib/components/VGrid';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VRow,[_c(VCol,{attrs:{"cols":"12"}},[_c('div',{staticClass:"text-center text-h3"},[_vm._v(" Reporte de Piezas Terminadas ")]),(_vm.cargando)?_c(VProgressLinear,{attrs:{"indeterminate":"","color":"blue darken-2"}}):_vm._e(),_c('Bar',{attrs:{"chart-options":_vm.chartOptions,"chart-data":_vm.chartData}})],1),_c(VCol,{staticClass:"d-flex align-center justify-center",attrs:{"cols":"12"}},[_c('p',{staticClass:"mb-0 mr-2 text-h1 font-weight-medium"},[_vm._v("Total:")]),_c('ICountUp',{key:_vm.contadorKey,staticClass:"text-h1 font-weight-medium",attrs:{"delay":500,"endVal":_vm.totalPiezasTerminadas,"options":{
        decimalPlaces: 2,
        decimal: '.',
        suffix: ' pzas'
      }}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }