import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';
import { VTooltip } from 'vuetify/lib/components/VTooltip';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.permisoVista('ubicaciones', 'r'))?_c(VContainer,{attrs:{"fluid":""}},[_c(VDialog,{attrs:{"persistent":"","max-width":"500px"},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c(VCard,[_c(VCardTitle,[_vm._v(_vm._s(_vm.editedItem.idUbicacion == null ? "Crear" : "Editar")+" Ubicación")]),_c(VCardText,[_c(VContainer,{attrs:{"fluid":""}},[_c(VRow,[_c(VCol,{attrs:{"cols":"12"}},[_c(VTextField,{attrs:{"label":"Nombre","disabled":_vm.saving,"error-messages":_vm.errorNombre,"clearable":""},on:{"focus":function($event){_vm.errorNombre = null}},model:{value:(_vm.editedItem.nombre),callback:function ($$v) {_vm.$set(_vm.editedItem, "nombre", $$v)},expression:"editedItem.nombre"}})],1)],1)],1)],1),_c(VCardActions,[_c(VSpacer),_c(VBtn,{attrs:{"text":"","color":"success","disabled":_vm.saving},on:{"click":_vm.closeDialog}},[_vm._v("Cancelar")]),_c(VBtn,{attrs:{"text":"","color":"error","disabled":_vm.saving,"loading":_vm.saving},on:{"click":_vm.save}},[_vm._v("Guardar")])],1)],1)],1),_c(VDialog,{attrs:{"persistent":"","max-width":"500px"},model:{value:(_vm.dialogDelete),callback:function ($$v) {_vm.dialogDelete=$$v},expression:"dialogDelete"}},[_c(VCard,[_c(VCardTitle,{staticStyle:{"word-break":"break-word"}},[_vm._v("¿Está seguro que desea eliminar esta Ubicación?")]),_c(VCardText,[_c('p',{staticClass:"subtitle-1 text--secondary"},[_vm._v(" Esta acción será permanente y no se puede revertir. ")])]),_c(VCardActions,[_c(VSpacer),_c(VBtn,{attrs:{"color":"success","disabled":_vm.deleting,"text":""},on:{"click":_vm.closeDialog}},[_vm._v("Cancelar")]),_c(VBtn,{attrs:{"color":"error","disabled":_vm.deleting,"loading":_vm.deleting,"text":""},on:{"click":_vm.deleteItem}},[_vm._v("Aceptar")])],1)],1)],1),_c(VRow,{class:{
                'justify-space-between': _vm.permisoVista('ubicaciones', 'c'),
                'justify-end': !_vm.permisoVista('ubicaciones', 'c')
            }},[_c(VCol,{attrs:{"cols":"4"}},[(_vm.permisoVista('ubicaciones', 'c'))?_c(VBtn,{attrs:{"color":"primary","disabled":_vm.loading},on:{"click":function($event){return _vm.showDialog()}}},[_vm._v(" Crear Ubicación ")]):_vm._e()],1),_c(VCol,{attrs:{"cols":"4"}},[_c(VTextField,{attrs:{"label":"Buscar","clearable":"","prepend-inner-icon":"mdi-magnify","disabled":_vm.loading},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1)],1),_c(VRow,[_c(VCol,{attrs:{"cols":"12"}},[_c(VDataTable,{attrs:{"items":_vm.ubicaciones,"headers":_vm.ubicacionesHeaders,"loading":_vm.loading,"search":_vm.search},scopedSlots:_vm._u([{key:"item.actions",fn:function(ref){
            var item = ref.item;
return [_c(VTooltip,{staticClass:"white--text",attrs:{"bottom":"","color":"black"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
            var on = ref.on;
return [(_vm.permisoVista('ubicaciones', 'u'))?_c(VIcon,_vm._g({staticClass:"mr-2",attrs:{"small":"","disabled":_vm.loading},on:{"click":function($event){return _vm.showDialog(item)}}},on),[_vm._v(" edit ")]):_vm._e()]}}],null,true)},[_c('span',{staticClass:"white--text"},[_vm._v("Editar")])]),_c(VTooltip,{staticClass:"white--text",attrs:{"bottom":"","color":"black"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
            var on = ref.on;
return [(_vm.permisoVista('ubicaciones', 'd'))?_c(VIcon,_vm._g({attrs:{"small":"","disabled":_vm.loading},on:{"click":function($event){return _vm.showDialog(item, true)}}},on),[_vm._v(" delete ")]):_vm._e()]}}],null,true)},[_c('span',{staticClass:"white--text"},[_vm._v("Eliminar")])])]}}],null,true)})],1)],1)],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }