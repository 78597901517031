import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VTab } from 'vuetify/lib/components/VTabs';
import { VTabItem } from 'vuetify/lib/components/VTabs';
import { VTabs } from 'vuetify/lib/components/VTabs';
import { VTabsItems } from 'vuetify/lib/components/VTabs';
import { VTabsSlider } from 'vuetify/lib/components/VTabs';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VRow,{attrs:{"justify-sm":"center"}},[_c(VCard,{staticClass:"mt-4",attrs:{"width":_vm.anchoMaximo,"fill":""}},[_c(VTabs,{attrs:{"show-arrows":"","background-color":_vm.colorTabs,"centered":"","dark":"","icons-and-text":""},model:{value:(_vm.tab),callback:function ($$v) {_vm.tab=$$v},expression:"tab"}},[_c(VTabsSlider),(_vm.mostrarVista('lotes'))?_c(VTab,{directives:[{name:"tooltip",rawName:"v-tooltip.bottom",value:(_vm.modoAyuda && _vm.$help.perfil.procesosCurso),expression:"modoAyuda && $help.perfil.procesosCurso",modifiers:{"bottom":true}}],attrs:{"href":"#tab-1"},on:{"click":function($event){return _vm.updateLotes()}}},[_vm._v(" Procesos"),_c('br'),_vm._v("en Curso "),_c(VIcon,[_vm._v("mdi-progress-wrench")])],1):_vm._e(),_c(VTab,{directives:[{name:"tooltip",rawName:"v-tooltip.bottom",value:(_vm.modoAyuda && _vm.$help.perfil.configuracion),expression:"modoAyuda && $help.perfil.configuracion",modifiers:{"bottom":true}}],attrs:{"href":"#tab-2"},on:{"click":_vm.configUsuario}},[_vm._v(" Configuración"),_c('br'),_vm._v("Usuario "),_c(VIcon,[_vm._v("mdi-account-cog")])],1)],1),_c(VTabsItems,{model:{value:(_vm.tab),callback:function ($$v) {_vm.tab=$$v},expression:"tab"}},[(_vm.mostrarVista('lotes'))?_c(VTabItem,{key:1,attrs:{"value":'tab-1'}},[_c(VCard,{attrs:{"flat":""}},[_c(VCardText,[_c('PerfilLotes',{ref:"lotes"})],1)],1)],1):_vm._e(),_c(VTabItem,{key:2,attrs:{"value":'tab-2'}},[_c(VCard,{attrs:{"flat":""}},[_c(VCardText,[_c('ListaConfigUser',{ref:"usuarioConfig"})],1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }