import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSpacer } from 'vuetify/lib/components/VGrid';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VDialog,{attrs:{"persistent":"","max-width":"70%"},model:{value:(_vm.dialogHistorico),callback:function ($$v) {_vm.dialogHistorico=$$v},expression:"dialogHistorico"}},[_c(VCard,[_c(VCardTitle,[_vm._v(" Tiempo Promedio Histórico ")]),_c(VCardText,[_c(VContainer,{directives:[{name:"show",rawName:"v-show",value:( _vm.tiempoPromedio > 0 && _vm.desviacionEstandar > 0 && _vm.numFrecuencias > 0 ),expression:" tiempoPromedio > 0 && desviacionEstandar > 0 && numFrecuencias > 0 "}]},[_c(VRow,[_c(VCol,{staticClass:"text-center",attrs:{"cols":"6","sm":"6","md":"6"}},[_c('div',{staticStyle:{"font-size":"18px"}},[_c('div',{staticClass:"font-weight-bold",staticStyle:{"font-size":"18px"}},[_vm._v("Tiempo Promedio:")]),_vm._v(" "+_vm._s(new Date(_vm.tiempoPromedio * 1000).toISOString().substr(11, 8))+" ")])]),_c(VCol,{staticClass:"text-center",attrs:{"cols":"6","sm":"6","md":"6"}},[_c('div',{staticStyle:{"font-size":"18px"}},[_c('div',{staticClass:"font-weight-bold",staticStyle:{"font-size":"18px"}},[_vm._v("Desviación Estandar:")]),_vm._v(" "+_vm._s(new Date(_vm.desviacionEstandar * 1000).toISOString().substr(11, 8))+" ")])])],1),_c(VRow,[_c(VCol,{attrs:{"cols":"12","md":"12","sm":"12"}},[_c('HistoricoDisperso',{ref:"ghistoric"})],1)],1)],1),_c(VContainer,{directives:[{name:"show",rawName:"v-show",value:( _vm.tiempoPromedio === 0 || _vm.desviacionEstandar === 0 || _vm.numFrecuencias === 0 ),expression:" tiempoPromedio === 0 || desviacionEstandar === 0 || numFrecuencias === 0 "}]},[_c(VRow,[_c(VCol,{attrs:{"cols":"6","sm":"6","md":"6"}},[_c('h4',[_vm._v(" Sin Información Disponible ")])])],1)],1)],1),_c(VCardActions,[_c(VSpacer),_c(VBtn,{attrs:{"color":"blue darken-1","text":""},on:{"click":function($event){return _vm.close()}}},[_vm._v("Cerrar")])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }