import { VAutocomplete } from 'vuetify/lib/components/VAutocomplete';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VProgressLinear } from 'vuetify/lib/components/VProgressLinear';
import { VRow } from 'vuetify/lib/components/VGrid';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VContainer,[_c(VRow,[_c(VCol,{staticClass:"align-self-end",attrs:{"cols":"6","md":"6","sm":"6"}},[_c('datePicker',{attrs:{"format":"YYYY-MM-DD","maxDate":_vm.fin,"label":"Desde","clearable":"","maxToday":""},on:{"input":_vm.getEstadisticas},model:{value:(_vm.inicio),callback:function ($$v) {_vm.inicio=$$v},expression:"inicio"}})],1),_c(VCol,{staticClass:"align-self-end",attrs:{"cols":"6","md":"6","sm":"6"}},[_c('datePicker',{attrs:{"format":"YYYY-MM-DD","minDate":_vm.inicio,"label":"Hasta","clearable":"","maxToday":""},on:{"input":_vm.getEstadisticas},model:{value:(_vm.fin),callback:function ($$v) {_vm.fin=$$v},expression:"fin"}})],1)],1),_c(VRow,[_c(VCol,{attrs:{"cols":"5","md":"5","sm":"5"}},[_c(VAutocomplete,{attrs:{"label":"Áreas","items":_vm.areas,"item-value":"idArea","item-text":"nombre","loading":_vm.loadingAreas,"multiple":"","clearable":""},model:{value:(_vm.idsAreas),callback:function ($$v) {_vm.idsAreas=$$v},expression:"idsAreas"}})],1),_c(VCol,{attrs:{"cols":"5","md":"5","sm":"5"}},[_c(VAutocomplete,{attrs:{"label":"Máquinas Instancias","item-text":_vm.nombreMaquinaIns,"item-value":"idMaquinaIns","items":_vm.maquinasFiltradas,"loading":_vm.loadingMaquinas,"multiple":"","clearable":""},model:{value:(_vm.idsMaquinaIns),callback:function ($$v) {_vm.idsMaquinaIns=$$v},expression:"idsMaquinaIns"}})],1),_c(VCol,{attrs:{"cols":"2","md":"2","sm":"2"}},[_c(VBtn,{attrs:{"color":"primary","disabled":_vm.inicio == null || _vm.fin == null},on:{"click":_vm.getData}},[_vm._v("Calcular OEE")])],1)],1),_c(VDataTable,{attrs:{"headers":_vm.headers,"items":_vm.items,"loading":_vm.loading,"disable-sort":"","hide-default-footer":"","id":"oee"},scopedSlots:_vm._u([{key:"item",fn:function(ref){
var item = ref.item;
var index = ref.index;
return [_c('tr',[_c('td',{staticClass:"text-center font-weight-bold"},[_vm._v(_vm._s(item.concepto))]),_c('td',{staticClass:"text-center"},[_c(VProgressLinear,{attrs:{"value":(item.global * 100),"color":_vm.colorBarra(item.global * 100),"height":"25"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var value = ref.value;
return [_c('strong',[_vm._v(_vm._s(value.toFixed(2))+"%")])]}}],null,true)})],1),_c('td',{staticClass:"text-center"},[_c(VProgressLinear,{attrs:{"value":(item.promedio * 100),"color":_vm.colorBarra(item.promedio * 100),"height":"25"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var value = ref.value;
return [_c(VIcon,{attrs:{"color":"black"}},[_vm._v(_vm._s(_vm.obtenerIcono(item.global,item.promedio)))]),_c('strong',[_vm._v(_vm._s(value.toFixed(2))+"%")])]}}],null,true)})],1),_vm._l((item.idsMaquinaIns),function(id){return _c('td',{key:'maquina'+id,staticClass:"text-center"},[_c(VProgressLinear,{attrs:{"value":(item['maquina'+id] * 100),"color":_vm.colorBarra(item['maquina'+id] * 100),"height":"25"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var value = ref.value;
return [_c(VIcon,{attrs:{"color":"black"}},[_vm._v(_vm._s(_vm.obtenerIcono(item.global,item['maquina'+id])))]),_c('strong',[_vm._v(_vm._s(value.toFixed(2))+"%")]),(index == 1 && item['maquinaOps'+id] > 0)?_c(VIcon,{attrs:{"color":"yellow"}},[_vm._v("mdi-alert")]):_vm._e()]}}],null,true)})],1)})],2)]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }