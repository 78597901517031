<template>
  <v-container>
    <div class="text-h6">{{nombreMaquinaIns(maquinaIns)}}</div>
    <v-row>
      <v-col cols="9">
        <apexchart type="line" height="348" :options="chartOptions" :series="datos"></apexchart>
      </v-col>
      <v-col cols="3">
        <v-card>
          <v-card-text class="text-center align-self-center">
            <div class="subtitle-1 font-weight-bold">
              # Lecturas
            </div>
            <ICountUp
              :delay="1000"
              :endVal="numLecturas"
              class="subtitle-1 font-weight-medium"
            />
          </v-card-text>
        </v-card>
        <br>
        <v-card>
          <v-card-text class="text-center align-self-center">
            <div class="subtitle-1 font-weight-bold">
              Temperatura
            </div>
            <ICountUp
              :delay="1000"
              :endVal="promedioTemperatura"
              class="subtitle-1 font-weight-medium blue--text"
              :options="{
                decimalPlaces: 2,
                separator: ',',
                decimal: '.',
                prefix:'',
                suffix:'°'
              }"
            />
          </v-card-text>
        </v-card>
        <br>
        <v-card>
          <v-card-text class="text-center align-self-center">
            <div class="subtitle-1 font-weight-bold">
              Humedad
            </div>
            <ICountUp
              :delay="1000"
              :endVal="promedioHumedad"
              class="subtitle-1 font-weight-medium red--text"
                :options="{
                decimalPlaces: 2,
                separator: ',',
                decimal: '.',
                prefix:'',
                suffix:'%'
              }"
            />
          </v-card-text>
        </v-card>

      </v-col>
    </v-row>
    
  </v-container>
</template>


<script>
import axios from "axios"
import ICountUp from 'vue-countup-v2';
import moment from "moment";

export default {
   components:{
    ICountUp,
  },
  props:{
    maquinaIns:{
      type: Object,
      default: () => ({
        idMaquinaIns: -1,
        marca: "",
        modelo: "",
        nombre: "",
      }),
      required: true
    },
    minutos: {
      type: Number,
      default: 5,
      required: true,
    }
  },
  data:() => ({
    lecturas:[],
    actualizacionesPendientes: 0,
    actualizando: false,

    chartOptions: {
      chart: {
        height: 350,
        type: 'line',
        zoom: {
          enabled: false
        }
      },
      dataLabels: {
        enabled: false
      },
      stroke: {
        curve: 'straight'
      },
      grid: {
        row: {
          colors: ['#f3f3f3', 'transparent'], // takes an array which will be repeated on columns
          opacity: 0.5
        },
      },
      xaxis: {
        type: 'datetime',
        labels: {
          datetimeUTC: false
        }
      },
      tooltip: {
        x: {
          formatter: function (val) {
            return moment(val).format("YYYY-MM-DD HH:mm:ss");
          }
        }
      }
    }
  }),
  computed:{
    datos(){
      return [{
        name: "Temperatura",
        data: this.lecturas.map(l => ({
          x: l.fecha,
          y: l.temperatura
        }))
      },
      {
        name: "Humedad",
        data: this.lecturas.map(l => ({
          x: l.fecha,
          y: l.humedad
        }))
      }];
    },
    numLecturas(){
      return this.lecturas.length;
    },
    promedioTemperatura(){
      var x = this.lecturas.reduce((p,c,_,a) => p + c.temperatura/a.length,0); 
      //console.log(x);
      return x;
    },
    promedioHumedad(){
      var x = this.lecturas.reduce((p,c,_,a) => p + c.humedad/a.length,0); 
      //console.log(x);
      return x;
    }
  },
  mounted(){
    axios
    .get(`/Sensores?IdMaquinaIns=${this.maquinaIns.idMaquinaIns}&MinutosAntes=${this.minutos}`)
    .then(response => {
      this.lecturas = response.data;
    })
    .catch(error => {
      console.log(error);
    })
  },
  methods:{
    nombreMaquinaIns: (item) => `${item.marca} ${item.modelo} - [${item.nombre}]`,
    actualizar(){
      this.actualizacionesPendientes += 1;
      if(this.actualizando == false) this.actualizarDatos();
    },
    actualizarDatos(){
      this.actualizando = true;
      var max = Math.max.apply(null, this.lecturas.map(x => x.idMaquinaInstanciaSensorLectura));
      axios
      .get(`/Sensores?IdMaquinaIns=${this.maquinaIns.idMaquinaIns}&IdMaquinaInstanciaSensorLectura=${max}`)
      .then(response => {
        this.lecturas = this.lecturas.concat(response.data);
        this.actualizacionesPendientes -= 1;
        if(this.actualizacionesPendientes > 0) this.actualizarDatos();
        else this.actualizando = false;
      })
      .catch(error => {
        console.log(error);
      })

    }
  }
}
</script>