import { VAutocomplete } from 'vuetify/lib/components/VAutocomplete';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VBtnToggle } from 'vuetify/lib/components/VBtnToggle';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VMenu } from 'vuetify/lib/components/VMenu';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VMenu,{attrs:{"offset-y":"","close-on-content-click":false},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c(VAutocomplete,_vm._g(_vm._b({attrs:{"label":_vm.label,"items":_vm.items,"item-value":"value","item-text":"text","hide-details":"","dense":"","multiple":"","chips":"","clearable":"","deletable-chips":"","readonly":_vm.readonly},on:{"change":_vm.sortItems,"update":_vm.sortItems,"mousedown":_vm.preventEvent,"mouseup":_vm.preventEvent,"focus":_vm.preventEvent,"blur":_vm.preventEvent,"click:append":_vm.preventEvent},model:{value:(_vm.value),callback:function ($$v) {_vm.value=$$v},expression:"value"}},'v-autocomplete',attrs,false),on))]}}])},[_c(VCard,[_c(VCardText,[_c(VBtnToggle,{staticClass:"d-table",attrs:{"multiple":""},on:{"change":_vm.sortItems},model:{value:(_vm.value),callback:function ($$v) {_vm.value=$$v},expression:"value"}},_vm._l((_vm.items),function(item,idx){return _c(VBtn,{key:'btn'+idx,staticClass:"black--text"},[_vm._v(_vm._s(item.text))])}),1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }