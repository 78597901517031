<template>
	<v-row>
		<v-col cols="12">
			<v-data-table
				:items="porcentajes.porcentajeMinAvances"
				:headers="porcentajesHeaders"
				:loading="loading"
				no-data-text="No hay datos disponibles"
				no-results-text="No hay resultados disponibles"
			>
				<template v-slot:item.fraccion="{ item, index }">
					<div v-if="indexEditar == index">
						<v-text-field
							v-model="item.newFraccion"
							type="number"
							class="centered-input"
							:error-messages="erroresCantidad[index]"
							@focus="erroresCantidad[index] = ''"
						></v-text-field>
					</div>
					<div v-else>
						<p class="mb-0">
							{{
								item.fraccion != null
									? item.fraccion.toFixed(2)
									: "N/A"
							}}
						</p>
					</div>
				</template>
				<template v-slot:item.actions="{ item, index }">
					<v-tooltip
						color="black"
						class="white--text"
						bottom
						v-if="permisoVista('listas', 'u') && indexEditar == -1"
					>
						<template v-slot:activator="{ on }">
							<v-icon
								v-on="on"
								size="18"
								@click="editarCantidad(item, index)"
							>
								edit
							</v-icon>
						</template>
						<span class="white--text">Editar</span>
					</v-tooltip>
					<v-tooltip
						color="black"
						class="white--text"
						bottom
						v-if="
							permisoVista('listas', 'u') && indexEditar == index
						"
					>
						<template v-slot:activator="{ on }">
							<v-icon
								v-on="on"
								size="18"
								@click="guardarCantidad(item, index)"
							>
								save
							</v-icon>
						</template>
						<span class="white--text">Guardar</span>
					</v-tooltip>
					<v-tooltip
						color="black"
						class="white--text"
						bottom
						v-if="
							permisoVista('listas', 'u') && indexEditar == index
						"
					>
						<template v-slot:activator="{ on }">
							<v-icon
								v-on="on"
								size="18"
								@click="cancelarCantidad(item, index)"
							>
								mdi-close-circle
							</v-icon>
						</template>
						<span class="white--text">Cancelar</span>
					</v-tooltip>
				</template>
			</v-data-table>
		</v-col>
	</v-row>
</template>

<script>
import axios from "axios";
export default {
	props: {
		porcentajeItem: {
			type: Object,
			default: () => {},
		},
	},
	data() {
		return {
			erroresCantidad: [],
			indexEditar: -1,
			loading: false,
			porcentajes: {
                idTrabajoOrdenDetalle: 0,
				porcentajeMinAvances: [],
            },
			defaultPorcentajes: {
				idTrabajoOrdenDetalle: 0,
				porcentajeMinAvances: [],
			},
			porcentajesHeaders: [
				{
					text: "Área",
					align: "center",
					sortable: false,
					value: "area",
				},
				{
					text: "Cantidad Mínima a Reportar",
					align: "center",
					sortable: false,
					value: "fraccion",
				},
				{
					text: "",
					align: "center",
					sortable: false,
					width: "10%",
					value: "actions",
				},
			],
		};
	},
	mounted() {
		this.initialize();
	},
	methods: {
		resetValores() {
			this.erroresCantidad = [];
			this.indexEditar = -1;
			this.loading = false;
			this.porcentajes = Object.assign({}, this.defaultPorcentajes);
		},
		cancelarCantidad(item, index) {
			this.indexEditar = -1;
			this.erroresCantidad = [];
		},
		editarCantidad(item, index) {
			item.newFraccion = item.fraccion.toFixed(2);
			this.indexEditar = index;
		},
		validarCantidad(item, index) {
			this.erroresCantidad = [];

			if (
				item.newFraccion == "" ||
				item.newFraccion == null ||
				isNaN(item.newFraccion)
			) {
				this.erroresCantidad[index] = "Cantidad requerida";
			} else if (parseFloat(item.newFraccion) <= 0) {
				this.erroresCantidad[index] = "La cantidad debe ser mayor a 0";
			}

			return this.erroresCantidad.length == 0;
		},
		guardarCantidad(item, index) {
			if (!this.validarCantidad(item, index)) return;

			axios
				.put(
					`/TrabajoOrdenes/ActualizarPorcentajeMinAvance/${
						item.idPorcentajeMinAvance
					}?fraccion=${parseFloat(item.newFraccion)}`
				)
				.then(() => {
					this.indexEditar = -1;
					this.getPorcentajes();
				})
				.catch((error) => {
					console.log(error);
				});
		},
		initialize() {
			this.resetValores();
			this.getPorcentajes();
		},
		getPorcentajes() {
			this.loading = true;
			axios
				.get(`/TrabajoOrdenes/GetOTDPorcentajeMinAvance`, {
					params: {
						idOrdenTrabajoDetalle:
							this.porcentajeItem.idTrabajoOrdenDetalle,
					},
				})
				.then((response) => {
					this.loading = false;
					this.porcentajes = response.data;
				})
				.catch((error) => {
					this.loading = false;
					console.log(error);
				});
		},
	},
};
</script>
<style scoped>
.centered-input >>> input {
	text-align: center;
}
</style>