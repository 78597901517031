<template>
  <div>
    <v-col cols="12" class="px-8">
      <v-row class="mt-0">
        <v-col cols="3">
          <v-select v-model="filtrosInspecciones.tipo" :items="tipos" label="Tipo (Obligatorio)" item-text="texto"
            item-value="id" :disabled="loadingTabla" @change="getReporteGeneralInspecciones()" />
        </v-col>
        <v-col cols="3">
          <v-autocomplete v-model="filtrosInspecciones.IdMantenimientoInspeccion" :items="mantenimientoInspecciones"
            :item-text="nombreMantenimiento" item-value="idMantenimientoInspeccion" label="Inspecciones (Obligatorio)"
            :loading="loadingMInspecciones" :disabled="loadingTabla"
            @change="getInstancias(); getReporteGeneralInspecciones()" />
        </v-col>
        <v-col cols="3">
          <datePicker v-model="filtrosInspecciones.desde" format="YYYY-MM-DD" label="Desde (Obligatorio)" clearable
            :max-date="fechaMaxima" :disabled="loadingTabla" />
        </v-col>
        <v-col cols="3">
          <datePicker v-model="filtrosInspecciones.hasta" format="YYYY-MM-DD" label="Hasta (Obligatorio)" clearable
            :min-date="filtrosInspecciones.desde" :disabled="loadingTabla" maxToday />
        </v-col>
      </v-row>
      <v-row class="mt-0">
        <v-expansion-panels>
          <v-expansion-panel>
            <v-expansion-panel-header>
              <div>
                <v-icon>mdi-target</v-icon> Filtros Avanzados (Opcionales)
              </div>
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <v-row>
                <v-col cols="6">
                  <v-autocomplete v-model="filtrosInspecciones.idMaquinaIns" :items="instancias" item-text="nombre"
                    item-value="idMaquinaIns" label="Instancia (Opcional)" :loading="loadingInstancias"
                    @change="getReporteGeneralInspecciones()" :disabled="loadingTabla" />
                </v-col>
                <v-col cols="6">
                  <v-autocomplete v-model="filtrosInspecciones.idUbicacion" :items="ubicaciones" item-text="nombre"
                    item-value="idUbicacion" label="Ubicación (Opcional)" :loading="loadingUbicaciones"
                    @change="getReporteGeneralInspecciones()" :disabled="loadingTabla" />
                </v-col>
              </v-row>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
      </v-row>
    </v-col>
    <v-col cols="12">
      <v-simple-table class="custom-table" fixed-header>
        <template v-slot:default>
          <thead>
            <tr>
              <template v-for="(head, idx) in headersComputed">
                <template v-if="head.value == 'datos'">
                  <template v-for="headerComp, idxHeadComp in headersComprobaciones">
                    <th class="text-center header-font-size white--text header-color-comprobacion"
                      :key="`headerComp-${idx}-${idxHeadComp}`">
                      <span class="vertical-text">{{ headerComp.text }}</span>
                    </th>
                  </template>
                </template>
                <th v-else class="text-center header-font-size white--text header-color-base" style="min-width: 150px;">
                  {{ head.text }}
                </th>
              </template>
            </tr>
          </thead>
          <thead v-if="loadingTabla">
            <tr class="v-data-table__progress">
              <th :colspan="headersLength" class="column fixed-header-loading">
                <div role="progressbar" aria-valuemin="0" aria-valuemax="100"
                  class="v-progress-linear v-progress-linear--absolute v-progress-linear--visible theme--light"
                  style="height: 4px">
                  <div class="v-progress-linear__background primary" style="
												opacity: 0.3;
												left: 0%;
												width: 100%;
											"></div>
                  <div class="v-progress-linear__buffer"></div>
                  <div class="v-progress-linear__indeterminate v-progress-linear__indeterminate--active">
                    <div class="v-progress-linear__indeterminate long primary"></div>
                    <div class="v-progress-linear__indeterminate short primary"></div>
                  </div>
                </div>
              </th>
            </tr>
          </thead>
          <tbody>
            <template v-if="hayData">
              <tr v-for="(item, idxTr) in reportesGenerales" :key="`tr-${idxTr}`">
                <template v-for="(head, idxTd) in headersComputed">
                  <td v-if="head.value == 'cabecera'" class="text-center" :key="`td-${idxTd}`">
                    {{ item[head.value] }}
                  </td>
                  <td v-else-if="head.value == 'status'" class="text-center px-0" :key="`td-${idxTd}`">
                    <v-progress-linear class="custom-progress-linear" :value="item[head.value]"
                      :color="getColorEstatus(item[head.value])" style="margin-top: 2px" height="96%">
                      <template v-slot:default="{ value }">
                        <p class="mb-0 font-weight-bold" style="font-size: 14px !important">{{ Math.ceil(value) }}%</p>
                      </template>
                    </v-progress-linear>
                  </td>
                  <td v-else-if="head.value == 'cumplimientoInspecciones'" class="text-center px-0"
                    :key="`td-${idxTd}`">
                    <v-progress-linear class="custom-progress-linear" :value="item[head.value]"
                      :color="getColorEstatus(item[head.value])" style="margin-top: 2px" height="96%">
                      <template v-slot:default="{ value }">
                        <p class="mb-0 font-weight-bold" style="font-size: 14px !important">{{ Math.ceil(value) }}%</p>
                      </template>
                    </v-progress-linear>
                  </td>
                  <td v-else-if="head.value == 'enTiempoInspecciones'" class="text-center px-0" :key="`td-${idxTd}`">
                    <v-progress-linear class="custom-progress-linear" :value="item[head.value]"
                      :color="getColorEstatus(item[head.value])" style="margin-top: 2px" height="96%">
                      <template v-slot:default="{ value }">
                        <p class="mb-0 font-weight-bold" style="font-size: 14px !important">{{ Math.ceil(value) }}%</p>
                      </template>
                    </v-progress-linear>
                  </td>
                  <td v-else-if="head.value != 'datos'" class="text-center" :key="`td-${idxTd}`">
                    {{ item[head.value] }}
                  </td>
                  <td v-else v-for="(header, indx) in headersComprobaciones" class="text-center">
                    {{ item[head.value][indx].promedio }}%
                  </td>
                </template>
              </tr>
            </template>
            <tr v-else-if="!hayData && !loadingTabla">
              <td :colspan="headersLength" class="text-center td-text-secondary">
                No hay datos disponibles
              </td>
            </tr>
            <tr v-else-if="!hayData && loadingTabla">
              <td :colspan="headersLength" class="text-center td-text-secondary">
                Cargando...
              </td>
            </tr>
          </tbody>
        </template>
      </v-simple-table>
    </v-col>
    <v-col cols="12" v-if="hayDataGrafica">
      <apexchart type="bar" height="350" @dataPointSelection="dataPointSelectionHandler" :options="chartOptions"
        :series="series"></apexchart>
    </v-col>
  </div>
</template>

<script>
import axios from 'axios';

export default {
  data() {
    return {
      filtrosInspecciones: {
        tipo: null,
        IdMantenimientoInspeccion: null,
        desde: null,
        hasta: null,
        idMaquinaIns: null,
        idUbicacion: null
      },
      filtrosInspeccionesDefault: {
        tipo: null,
        IdMantenimientoInspeccion: null,
        desde: null,
        hasta: null,
        idMaquinaIns: null,
        idUbicacion: null
      },

      tipos: [
        {
          id: 1,
          texto: "Máquina"
        }, {
          id: 2,
          texto: "Pregunta"
        }
      ],
      mantenimientoInspecciones: [],
      instancias: [],
      ubicaciones: [],

      reportesGenerales: [],
      headers: [
        {
          text: "Inspección",
          value: "cabecera",
        }, {
          text: "Datos",
          value: "datos",
        }, {
          text: "Calificación total",
          value: "status"
        }, {
          text: "% Cumplimiento de inspecciones",
          value: "cumplimientoInspecciones"
        }, {
          text: "% Inspecciones en tiempo",
          value: "enTiempoInspecciones"
        }
      ],
      headersComprobaciones: [],
      series: [],
      categories: [],

      loadingTabla: false,
      loadingMInspecciones: false,
      loadingInstancias: false,
      loadingUbicaciones: false,
    };
  },
  computed: {
    fechaMaxima() {
      if (this.filtrosInspecciones.hasta) {
        return this.filtrosInspecciones.hasta
      }
      const hoy = new Date();
      hoy.setDate(hoy.getDate() + 5);
      const año = hoy.getFullYear();
      const mes = (hoy.getMonth() + 1).toString().padStart(2, '0');
      const día = hoy.getDate().toString().padStart(2, '0');
      return `${año}-${mes}-${día}`;
    },
    hayData() {
      if (this.reportesGenerales == null || this.reportesGenerales == undefined) return false
      return this.reportesGenerales.length > 0;
    },
    hayDataGrafica() {
      if (this.series.length <= 0 || this.categories <= 0) {
        return false
      }
      return true
    },
    headersLength() {
      return this.headersComputed.length + this.headersComprobaciones.length - 1;
      //return this.headers.length + this.headersComprobaciones.length - 1;
    },
    chartOptions() {
      return {
        states: {
          active: {
            allowMultipleDataPointsSelection: false,
          }
        },
        chart: {
          type: 'bar',
          height: 350,
        },
        plotOptions: {
          bar: {
            horizontal: false,
            columnWidth: '55%',
            endingShape: 'rounded'
          },
        },
        dataLabels: {
          enabled: false
        },
        stroke: {
          show: true,
          width: 2,
          colors: ['transparent']
        },
        xaxis: {
          categories: this.categories,
        },
        yaxis: {
        },
        fill: {
          opacity: 1
        },
        tooltip: {
        }
      }
    },
    headersComputed() {
      if (this.filtrosInspecciones.tipo == 2) {
        return this.headers.filter(header =>
          header.value !== 'cumplimientoInspecciones' &&
          header.value !== 'enTiempoInspecciones'
        );
      }

      return this.headers;
    }
  },
  mounted() {
    this.initialize();
  },
  methods: {
    nombreMantenimiento: (item) => `${item.codigo} - ${item.nombre}`,

    initialize() {
      this.getMantenimientoInspecciones();
      this.getUbicaciones();
    },

    getMantenimientoInspecciones() {
      this.loadingMInspecciones = true;
      axios
        .get("Mantenimientos/ListaInspeccionesBasica")
        .then(response => {
          this.mantenimientoInspecciones = response.data;
        })
        .catch(error => {
          console.log(error)
        })
        .finally(() => {
          this.loadingMInspecciones = false;
        })
    },
    getInstancias() {
      this.filtrosInspecciones.idMaquinaIns = null;
      if (!this.filtrosInspecciones.IdMantenimientoInspeccion) return

      this.loadingInstancias = true;
      axios
        .get(`Mantenimientos/MaquinasChecklistInspeccion/${this.filtrosInspecciones.IdMantenimientoInspeccion}`)
        .then(response => {
          this.instancias = response.data;
        })
        .catch(error => {
          console.log(error)
        })
        .finally(() => {
          this.loadingInstancias = false;
        })
    },
    getUbicaciones() {
      this.loadingUbicaciones = true;
      axios
        .get("/Ubicaciones")
        .then(response => {
          this.ubicaciones = response.data;
        })
        .catch(error => {
          console.log(error)
        })
        .finally(() => {
          this.loadingUbicaciones = false;
        })
    },

    validacion() {
      if (!this.filtrosInspecciones.tipo) {
        return false;
      }
      if (!this.filtrosInspecciones.IdMantenimientoInspeccion) {
        return false;
      }
      if (!this.filtrosInspecciones.desde) {
        return false;
      }
      if (!this.filtrosInspecciones.hasta) {
        return false;
      }
      return true;
    },
    getReporteGeneralInspecciones() {

      let validacion = this.validacion();
      if (validacion) {
        this.reportesGenerales = [];
        this.headersComprobaciones = [];
        this.series = [];
        this.categories = [];
        this.loadingTabla = true;
        axios
          .get("/Mantenimientos/InspeccionesReporteGeneralCheckList", {
            params: {
              tipo: this.filtrosInspecciones.tipo,
              IdMantenimientoInspeccion: this.filtrosInspecciones.IdMantenimientoInspeccion,
              desde: this.filtrosInspecciones.desde,
              hasta: this.filtrosInspecciones.hasta,
              idMaquinaIns: this.filtrosInspecciones.idMaquinaIns,
              idUbicacion: this.filtrosInspecciones.idUbicacion
            }
          })
          .then(response => {
            if (response.data.datosTabla != null && response.data.datosTabla[0]) {
              this.reportesGenerales = response.data.datosTabla;
              this.headersComprobaciones = response.data.datosTabla[0].datos.map(item => ({
                text: item.nombre,
                value: item.promedio
              }));
            }
            if (response.data.grafica != null) {
              this.series = response.data.grafica.series
              this.categories = response.data.grafica.categories
            }
          })
          .catch(error => {
            console.log(error)
          })
          .finally(() => {
            this.loadingTabla = false;
          })
      }
    },

    getColorEstatus(value) {
      const status = Math.ceil(value);
      if (status < 70) return "error";
      else if (status >= 70 && status <= 80) return "amber";
      else return "success";
    },
  },
  watch: {
    'filtrosInspecciones.desde'(newValue) {
      this.getReporteGeneralInspecciones();
    },

    'filtrosInspecciones.hasta'(newValue) {
      this.getReporteGeneralInspecciones();
    }
  }
};
</script>
<style scoped>
.vertical-text {
  writing-mode: vertical-rl;
  transform: rotate(180deg);
  text-align: center;
  white-space: normal;
}

.background-error {
  background-color: #ffcdd2;
}

.background-success {
  background-color: #c8e6c9;
}

.custom-progress-linear>>>.v-progress-linear__background {
  height: inherit;
}

.header-font-size {
  font-size: 17px !important;
}

.header-color-base {
  background-color: #002060 !important;
}

.header-color-comprobacion {
  background-color: #757575 !important;
}

.header-color-critico {
  background-color: #D32F2F !important;
}

.td-text-secondary {
  font-size: 1rem !important;
  color: rgba(0, 0, 0, 0.38);
}

.custom-height>>>.v-data-table__wrapper {
  max-height: 720px;
}

.background-celda-promedio {
  background-color: rgb(255 235 156 / 60%);
  color: #9C6500;
}

.fixed-header-loading {
  border-bottom: 0 !important;
  position: sticky !important;
  top: 0 !important;
  z-index: 1 !important;
}
</style>