import { VCol } from 'vuetify/lib/components/VGrid';
import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VRow } from 'vuetify/lib/components/VGrid';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.permisoVista('sincronizacion-externa','r'))?_c(VCol,{attrs:{"cols":"12"}},[_c(VRow,{staticClass:"text-center"},[_c(VCol,{attrs:{"cols":"6","md":"6","sm":"6"}},[_c('VueCtkDateTimePicker',{attrs:{"id":"inicio","label":"Desde","format":"YYYY-MM-DD HH:mm","formatted":"YYYY-MM-DD HH:mm","noHeader":"","overlay":"","buttonNowTranslation":"Ahora"},on:{"input":function($event){return _vm.getSincronizaciones()}},model:{value:(_vm.filtros.inicio),callback:function ($$v) {_vm.$set(_vm.filtros, "inicio", $$v)},expression:"filtros.inicio"}})],1),_c(VCol,{attrs:{"cols":"6","md":"6","sm":"6"}},[_c('VueCtkDateTimePicker',{attrs:{"id":"fin","min-date":_vm.filtros.inicio,"label":"Hasta","format":"YYYY-MM-DD HH:mm","formatted":"YYYY-MM-DD HH:mm","noHeader":"","overlay":"","buttonNowTranslation":"Ahora"},on:{"input":function($event){return _vm.getSincronizaciones()}},model:{value:(_vm.filtros.fin),callback:function ($$v) {_vm.$set(_vm.filtros, "fin", $$v)},expression:"filtros.fin"}})],1)],1),_c(VDataTable,{attrs:{"headers":_vm.headers,"items":_vm.sincronizaciones,"loading":_vm.loading,"loading-text":"Cargando... Espere, Por Favor.","footer-props":{
			disableItemsPerPage: true,
			disablePagination: this.loading,
		},"disable-sort":true,"disable-pagination":_vm.loading}})],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }