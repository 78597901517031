<template>
	<v-col cols="12" v-if="permisoVista('multiplicadores', 'r')">
		<v-data-table :items="piezas" :headers="headersPiezas">
			<template v-slot:item.cantidad="{ item,idx }">
				<number-input
					v-if="permisoVista('multiplicadores', 'u')"
                    :key="`numin-${idx}`"
                    :value="item.cantidad"
					controls
					inline
					size="small"
					min="1"
					:step="1"
					center
					@change="realizarPeticion($event,item)"
				></number-input>
			</template>
		</v-data-table>
	</v-col>
</template>

<script>
import { debounce } from "debounce";
import axios from "axios";

export default {
	data() {
		return {
            loading: false,
			piezas: [
				// {
				// 	idMaquinaIns: 1,
				// 	codigo: "T500",
				// 	marcaModelo: "Oster TX-500",
				// 	cantidad: 3,
				// },
				// {
				// 	idMaquinaIns: 2,
				// 	codigo: "T600",
				// 	marcaModelo: "Oster TX-500",
				// 	cantidad: 6,
				// },
				// {
				// 	idMaquinaIns: 3,
				// 	codigo: "T700",
				// 	marcaModelo: "Oster TX-500",
				// 	cantidad: 9,
				// },
			],
			headersPiezas: [
				{
					text: "Código",
					align: "center",
					sortable: false,
					value: "codigo",
				},
				{
					text: "Marca y Modelo",
					align: "center",
					sortable: false,
					value: "marcaModelo",
				},
				{
					text: "Cantidad por Disparo",
					align: "center",
					sortable: false,
					value: "cantidad",
				},
			],
			itemPiezas: {
				idMaquinaIns: 0,
				marcaModelo: "",
				codigo: "",
				cantidad: 1,
			},
			defaultItemPiezas: {
				idMaquinaIns: 0,
				marcaModelo: "",
				codigo: "",
				cantidad: 1,
			},
		};
	},
    mounted(){
        this.initialize();
    },
	methods: {
        initialize(){
            this.piezas = [];
            this.getPiezas();
        },
		getPiezas() {
			axios
				.get("/MaquinaMultiplicador/Listar")
				.then((response) => {
					this.piezas = response.data;
				})
				.catch((error) => {
					console.log(error);
				});
		},
		realizarPeticion(event,item) {
			this.getActualizarPiezasDebounce(event,item);
		},
		getActualizarPiezasDebounce: debounce(async function (event,item) {
			try {
			    // console.log("ITEM: ", item);
                // this.loading = true;
                item.cantidad = Math.trunc(event)
				this.itemPiezas = Object.assign({},item);
				await this.actualizarMultiplicador();
				this.getPiezas();
			} catch (exception) {
				console.error(exception);
			} finally {
                // this.loading = false;
				this.itemPiezas = Object.assign({},this.defaultItemPiezas);
			}
		}, 600),
        actualizarMultiplicador() {
			return new Promise((resolve, reject) => {
				axios
					.put("/MaquinaMultiplicador/CrearActualizar", {
						idMaquinaIns: this.itemPiezas.idMaquinaIns,
						cantidad: this.itemPiezas.cantidad,
					})
					.then(() => {
						resolve();
					})
					.catch((error) => {
						reject(error);
					});
			});
		},
	},
};
</script>