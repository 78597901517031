<template>
	<v-col cols="12" v-if="permisoVista('cat-mantenimientocambiotroquel', 'r')">
		<v-dialog v-model="dialog" max-width="800px" persistent>
			<v-card>
				<v-card-title v-if="editedItem.idMantenimientoCambioTroquel == null"
					>Agregar Cambio de Troquel</v-card-title
				>
				<v-card-title v-else
					>{{ tipoEdicion == 1 ? 'Editar' : 'Clonar' }} Cambio de Troquel</v-card-title
				>
				<v-card-text>
					<v-row>
						<v-col cols="6" md="6" sm="6">
							<v-text-field
								v-model="editedItem.codigo"
								label="Código"
								:error-messages="erroresItem.errorCodigo"
								@focus="erroresItem.errorCodigo = null"
							></v-text-field>
						</v-col>
						<v-col cols="6" md="6" sm="6">
							<v-text-field
								v-model="editedItem.nombre"
								label="Nombre"
								:error-messages="erroresItem.errorNombre"
								@focus="erroresItem.errorNombre = null"
							></v-text-field>
						</v-col>
						<v-col cols="6" md="6" sm="6">
							<v-textarea
								v-model="editedItem.descripcion"
								label="Descripción"
								hide-details
								auto-grow
								rows="1"
							></v-textarea>
						</v-col>
						<v-col cols="6">
							<v-autocomplete
								v-model="editedItem.idMantenimientoCategoria"
								label="Categoria de Mantenimiento"
								:items="categorias"
								item-text="nombre"
								item-value="idMantenimientoCategoria"
								clearable
								:disabled="loadingCategorias"
								:loading="loadingCategorias"
								:error-messages="erroresItem.errorCategoria"
								@focus="erroresItem.errorCategoria = null"
							></v-autocomplete>
						</v-col>
						<v-col cols="6" md="6" sm="6">
							<v-autocomplete
								v-model="editedItem.idMaquina"
								label="Modelo"
								:items="maquinasComputed"
								:item-text="marcaModelo"
								item-value="idMaquina"
								:menu-props="{
									maxWidth: 'min-content'
								}"
								:error-messages="erroresItem.errorModelo"
								@focus="erroresItem.errorModelo = null"
								@change="onChangeModelo"
							>
								<template v-slot:item="data">
									<v-list-item-content>
										<v-list-item-title>{{data.item.marca+' - '+data.item.modelo}}</v-list-item-title>
										<v-list-item-subtitle>{{data.item.categoria}}</v-list-item-subtitle>
										<v-list-item-subtitle v-if="data.item.descripcionGeneral!=null">{{$utils.strings.truncate(data.item.descripcionGeneral,50)}}</v-list-item-subtitle>
									</v-list-item-content>
								</template>
							</v-autocomplete>
						</v-col>
						<v-col cols="6" md="6" sm="6" class="mt-0 pt-0">
							<MaquinaInsAutocomplete
								v-model="editedItem.idsMaquinaIns"
								label="[Opcional] Máquina(s)"
								:items="computedIns"
								:persistent-hint="editedItem.idMaquina == null"
								:hint="editedItem.idMaquina == null ? 'Seleccione un Modelo' : ''"
								:disabled="editedItem.idMaquina == null"
								chips
								multiple
								deletable-chips
								item-text="nombre"
								item-value="idMaquinaIns"
							/>
						</v-col>
						<v-col cols="6" v-if="showSubdepto">
							<v-autocomplete
								v-model="editedItem.idSubdepartamento"
								label="Subdepartamento"
								:items="subdepartamentos"
								:item-text="nombreSubdepto"
								item-value="idSubdepartamento"
								clearable
								:error-messages="erroresItem.errorSubdepto"
								@focus="erroresItem.errorSubdepto = null"
							></v-autocomplete>
						</v-col>
					</v-row>
					<v-row>
						<v-col cols="3">
							<p class="text-center mb-0">Tipo de asignación</p>
							<v-radio-group
								v-model="editedItem.tipoAsignacion"
								column
								class="mt-0"
								hide-details
								@change="onChangeTipoAsignacion"
							>
								<v-radio
									label="Personas"
									class="mb-0 text-center"
									:value="1"
								></v-radio>
								<v-radio
									label="Proveedor"
									:value="2"
								></v-radio>
							</v-radio-group>
						</v-col>
						<template v-if="editedItem.tipoAsignacion == 1">
							<v-col cols="3">
								<p class="text-center">Personas requeridas</p>
								<div class="d-flex align-center justify-center">
									<number-input
										v-model="editedItem.numPersonas"
										controls
										inline
										size="small"
										:min="1"
										center
									></number-input>
								</div>
							</v-col>
							<v-col cols="6">
								<v-autocomplete
									v-model="editedItem.asignados"
									label="[Opcional] Asignar por defecto a"
									:items="personas"
									:item-text="nombrePersona"
									item-value="idPersona"
									multiple
									chips
									deletable-chips
									clearable
									:error-messages="erroresItem.errorAsignados"
									@focus="erroresItem.errorAsignados = null"
								></v-autocomplete>
							</v-col>
						</template>
						<template v-else-if="editedItem.tipoAsignacion == 2">
							<v-col cols="6">
								<v-autocomplete
									v-model="editedItem.idMantenimientoProveedor"
									label="[Opcional] Proveedor por defecto"
									:items="proveedores"
									:item-text="$utils.mantenimientos.getNombreProveedor"
									item-value="idMantenimientoProveedor"
									clearable
									hide-details
								></v-autocomplete>
							</v-col>
						</template>
					</v-row>
					<ListadoPorHacer class="mt-10" ref="todo" :item="editedItem" />
					<v-slide-y-transition>
						<v-row v-if="errores.length > 0"  class="pt-4">
							<ul>
								<li
									class="red--text"
									v-for="(error, idx) in errores"
									:key="'error' + idx"
								>
									{{ error }}
								</li>
							</ul>
						</v-row>
					</v-slide-y-transition>
				</v-card-text>
				<v-card-actions>
					<v-spacer></v-spacer>
					<v-btn
						color="green"
						text
						:disabled="guardando"
						@click="closeCambioTroquel"
						>Cancelar</v-btn
					>
					<v-btn
						color="red"
						text
						:loading="guardando"
						:disabled="guardando"
						@click="guardarCambioTroquel"
						>{{ textButtonSave }}</v-btn
					>
				</v-card-actions>
			</v-card>
		</v-dialog>
		<v-dialog v-model="dialogEliminar" max-width="500px" persistent>
			<v-card>
				<v-card-title
					style="word-break: normal"
					>¿Está seguro que desea eliminar este
					cambio de troquel?</v-card-title
				>
				<v-card-text
					>Esta acción no se puede revertir y será
					permanente.</v-card-text
				>
				<v-card-actions>
					<v-spacer></v-spacer>
					<v-btn
						color="success"
						text
						:disabled="deleting"
						@click="closeDialogEliminar"
						>Cancelar</v-btn
					>
					<v-btn
						color="error"
						text
						@click="eliminarCambioTroquel"
						:disabled="deleting"
						:loading="deleting"
					>
						Eliminar
					</v-btn>
				</v-card-actions>
			</v-card>
		</v-dialog>
		<v-dialog v-model="dialogExtemporaneo" max-width="55%" persistent>
			<v-card>
				<v-card-title class="pa-4 pb-4 pt-5">
					Reporte Extemporaneo
				</v-card-title>
				<v-card-text style="min-height: 300px">
					<v-row>
						<v-col cols="12">
							<HeaderInfoMantenimiento
								:maquinas="maquinas"
								:items="[{...reportedItem}]"
							/>
						</v-col>
					</v-row>
					<v-row>
						<v-col cols="12" md="12" sm="12">
							<v-autocomplete
								v-model="reportedItem.responsable"
								:items="personas"
								item-value="idPersona"
								:item-text="nombrePersona"
								:disabled="saving"
								label="Responsable"
								:error-messages="erroresItem.errorResponsable"
								@focus="erroresItem.errorResponsable = null"
							>
							</v-autocomplete>
						</v-col>
						<AsignacionMantenimiento
							:tipoAsignacion.sync="reportedItem.tipoAsignacion"
							:mecanicos.sync="reportedItem.mecanicos"
							:idMantenimientoProveedor.sync="reportedItem.idMantenimientoProveedor"
							:error-mecanicos.sync="erroresItem.errorMecanicos"
							:error-proveedor.sync="erroresItem.errorProveedor"
							:disabled="saving"
							:items="{personas, proveedores}"
						/>
						<v-col cols="12" md="12" sm="12">
							<MaquinaInsAutocomplete
								v-model="reportedItem.instancia"
								label="Máquina"
								:items="computedIns"
								item-text="nombre"
								item-value="idMaquinaIns"
								:disabled="saving || loadingKilometrajeMin"
								:error-message="erroresItem.errorInstancia"
								@focus="erroresItem.errorInstancia = null"
								@change="onChangeMaquinaIns"
							/>
						</v-col>
					</v-row>
					<v-row>
						<v-col cols="12" md="12" sm="12">
							<VueCtkDateTimePicker
								v-model="reportedItem.inicio"
								label="Inicio"
								format="YYYY-MM-DD HH:mm"
								formatted="YYYY-MM-DD HH:mm"
								noHeader
								overlay
								:disabled="saving"
								buttonNowTranslation="Ahora"
								@input="erroresItem.errorFechaInicio = null"
							/>
							<v-slide-y-transition>
								<p
									v-if="erroresItem.errorFechaInicio"
									class="mt-1 ml-1 mb-0 error--text"
									style="font-size: 12px !important"
								>
									{{erroresItem.errorFechaInicio}}
								</p>
							</v-slide-y-transition>
						</v-col>
						<v-col cols="12" md="12" sm="12">
							<VueCtkDateTimePicker
								v-model="reportedItem.fin"
								:min-date="reportedItem.inicio"
								label="Termino"
								format="YYYY-MM-DD HH:mm"
								formatted="YYYY-MM-DD HH:mm"
								noHeader
								overlay
								:disabled="saving"
								buttonNowTranslation="Ahora"
								@input="erroresItem.errorFechaFin = null"
							/>
							<v-slide-y-transition>
								<p
									v-if="erroresItem.errorFechaFin"
									class="mt-1 ml-1 mb-0 error--text"
									style="font-size: 12px !important"
								>
									{{erroresItem.errorFechaFin}}
								</p>
							</v-slide-y-transition>
						</v-col>
					</v-row>
					<v-row>
						<Comprobaciones
							v-if="dialogExtemporaneo"
							ref="checklist"
							:disabled="saving"
							:idMantenimientoCambioTroquel="reportedItem.idMantenimientoCambioTroquel"
							:loadingComprobaciones.sync="loadingComprobaciones"
						/>
					</v-row>
					<v-row>
						<v-col cols="12" md="12" sm="12">
							<v-autocomplete-textarea
								v-if="dialogExtemporaneo"
                                v-model="reportedItem.recomendacion"
                                label="Descripción o Recomendación"
                                outlined
                                auto-grow
                                rows="1"
								:items="recomendaciones"
								:loading="loadingRecomendaciones"
								:disabled="saving"
								:error-message="erroresItem.errorRecomendacion"
								@click:refresh="getRecomendaciones"
								@update:error-message="erroresItem.errorRecomendacion = $event"
                            ></v-autocomplete-textarea>
						</v-col>
					</v-row>
					<v-slide-y-transition>
						<ul class="mt-3" v-if="erroresReported.length > 0">
							<li
								class="red--text"
								v-for="(error, idx) in erroresReported"
								:key="'error-' + idx"
							>
								{{ error }}
							</li>
						</ul>
					</v-slide-y-transition>
				</v-card-text>
				<v-card-actions>
					<v-spacer></v-spacer>
					<v-btn
						text
						color="green"
						@click="closeDialogReportes"
						:disabled="saving || loadingComprobaciones"
						>Cancelar</v-btn
					>
					<v-btn
						text
						color="red"
						@click="reportarExtemporaneo"
						:disabled="saving || loadingComprobaciones"
						:loading="saving"
					>
						Guardar
					</v-btn>
				</v-card-actions>
			</v-card>
		</v-dialog>
		<v-dialog v-model="dialogTiempoReal" max-width="50%" persistent>
			<v-card>
				<v-card-title class="pa-4 pb-4 pt-5">
					Reporte En Tiempo Real
				</v-card-title>
				<v-card-text style="min-height: 300px">
					<v-row>
						<v-col cols="12">
							<HeaderInfoMantenimiento
								:maquinas="maquinas"
								:items="[{...reportedItem}]"
							/>
						</v-col>
					</v-row>
					<v-row>
						<v-col cols="12" md="12" sm="12">
							<v-autocomplete
								v-model="reportedItem.responsable"
								:items="personas"
								item-value="idPersona"
								:item-text="nombrePersona"
								label="Responsable"
								:disabled="saving"
								:error-messages="erroresItem.errorResponsable"
								@focus="erroresItem.errorResponsable = null"
							></v-autocomplete>
						</v-col>
						<AsignacionMantenimiento
							:tipoAsignacion.sync="reportedItem.tipoAsignacion"
							:mecanicos.sync="reportedItem.mecanicos"
							:idMantenimientoProveedor.sync="reportedItem.idMantenimientoProveedor"
							:error-mecanicos.sync="erroresItem.errorMecanicos"
							:error-proveedor.sync="erroresItem.errorProveedor"
							:disabled="saving"
							:items="{personas, proveedores}"
						/>
						<v-col cols="12" md="12" sm="12">
							<MaquinaInsAutocomplete
								v-model="reportedItem.instancia"
								label="Máquina"
								:items="computedIns"
								item-text="nombre"
								item-value="idMaquinaIns"
								:disabled="saving"
								:error-message="erroresItem.errorInstancia"
								@focus="erroresItem.errorInstancia = null"
							/>
						</v-col>
					</v-row>
					<v-slide-y-transition>
						<ul v-if="erroresReported.length > 0" class="mt-3">
							<li
								class="red--text"
								v-for="(error, idx) in erroresReported"
								:key="'error-' + idx"
							>
								{{ error }}
							</li>
						</ul>
					</v-slide-y-transition>
				</v-card-text>
				<v-card-actions>
					<v-spacer></v-spacer>
					<v-btn
						text
						color="green"
						@click="closeDialogReportes"
						:disabled="saving"
						>Cancelar</v-btn
					>
					<v-btn
						text
						color="red"
						@click="reportarTiempoReal"
						:disabled="saving"
						:loading="saving"
					>
						Guardar
					</v-btn>
				</v-card-actions>
			</v-card>
		</v-dialog>
		<v-dialog v-model="dialogProgramar" max-width="45%" persistent>
			<v-card>
				<v-card-title class="pa-4 pb-4 pt-5">
					Programar Cambio de Troquel
				</v-card-title>
				<v-card-text style="min-height: 300px">
					<v-row>
						<v-col cols="12">
							<HeaderInfoMantenimiento
								:maquinas="maquinas"
								:items="[{...reportedItem}]"
							/>
						</v-col>
					</v-row>
					<v-row>
						<v-col cols="12" md="12" sm="12">
							<MaquinaInsAutocomplete
								v-model="reportedItem.instancia"
								label="Máquina"
								:items="computedIns"
								item-text="nombre"
								item-value="idMaquinaIns"
								:disabled="savingProgrammed"
								:error-message="erroresItem.errorInstancia"
								@focus="erroresItem.errorInstancia = null"
							/>
						</v-col>
					</v-row>
					<v-row>
						<v-col cols="6" md="6" sm="6" class="my-3">
							<MenuDatepicker
								v-model="reportedItem.fechaInicioPlaneado"
								label="Fecha de Inicio planeado"
								clearable
								:disabled="savingProgrammed"
								:errorMessage="erroresItem.errorFechaInicio"
								@focus="erroresItem.errorFechaInicio = null"
							/>
						</v-col>
						<v-col cols="6" md="6" sm="6" class="my-3">
							<v-autocomplete
								v-model="reportedItem.tiempoEstimado"
								label="Tiempo estimado"
								:items="bloques"
								:disabled="savingProgrammed"
								:error-messages="erroresItem.errorTiempoEstimado"
								@focus="erroresItem.errorTiempoEstimado = null"
							></v-autocomplete>
      					</v-col>
					</v-row>
					<v-slide-y-transition>
						<ul class="mt-3" v-if="erroresReported.length > 0">
							<li
								class="red--text"
								v-for="(error, idx) in erroresReported"
								:key="'error-' + idx"
							>
								{{ error }}
							</li>
						</ul>
					</v-slide-y-transition>
				</v-card-text>
				<v-card-actions>
					<v-spacer></v-spacer>
					<v-btn
						text
						color="green"
						@click="closeProgrammed()"
						:disabled="savingProgrammed"
						>Cancelar</v-btn
					>
					<v-btn
						text
						color="red"
						@click="programarMantenimiento"
						:disabled="savingProgrammed"
						:loading="savingProgrammed"
					>
						Guardar
					</v-btn>
				</v-card-actions>
			</v-card>
		</v-dialog>
		<Recomendaciones ref="recomen" />
		<v-row class="justify-space-between">
			<v-col cols="4">
				<v-btn
					v-if="permisoVista('cat-mantenimientocambiotroquel', 'c')"
					color="primary"
					:disabled="loading"
					@click="dialog = true"
					>Agregar Cambio de Troquel</v-btn
				>
			</v-col>
			<v-col cols="4" class="text-right">
				<v-text-field
					v-model="search"
					label="Buscar"
					prepend-inner-icon="mdi-magnify"
					clearable
					:disabled="loading"
				></v-text-field>
			</v-col>
		</v-row>
		<v-data-table
			:items="cambiosTroqueles"
			:headers="headersHorario"
			:loading="loading"
			:search="search"
			loading-text="Cargando... Espere, Por Favor."
		>
			<template v-slot:[`item.actions`]="{ item }">
				<v-tooltip
					v-if="permisoVista('cat-mantenimientocambiotroquel', 'c')"
					bottom
					color="black"
					class="white--text"
				>
					<template v-slot:activator="{ on }">
						<v-icon
							v-on="on"
							small
							class="mr-1"
							:disabled="loading"
							@click="editMantenimiento(item,2)"
						>
							mdi-content-duplicate
						</v-icon>
					</template>
					<span class="white--text">Clonar</span>
				</v-tooltip>
				<v-tooltip
					v-if="permisoVista('cat-mantenimientocambiotroquel', 'c')"
					bottom
					color="black"
					class="white--text"
				>
					<template v-slot:activator="{ on }">
						<v-icon v-on="on" :disabled="loading" class="mr-1" small @click="onClickProgramar(item)">
							mdi-clock
						</v-icon>
					</template>
					<span class="white--text">Programar Mantenimiento</span>
				</v-tooltip>
				<v-tooltip
					v-if="permisoVista('cat-mantenimientocambiotroquel', 'c')"
					bottom
					color="black"
					class="white--text"
				>
					<template v-slot:activator="{ on }">
						<v-icon v-on="on" class="mr-1" :disabled="loading" small @click="onClickReporteExtemporaneo(item)">
							mdi-clipboard-text-play
						</v-icon>
					</template>
					<span class="white--text">Reporte Extemporaneo</span>
				</v-tooltip>
				<v-tooltip
					v-if="permisoVista('cat-mantenimientocambiotroquel', 'c')"	
					bottom
					color="black"
					class="white--text"
				>
					<template v-slot:activator="{ on }">
						<v-icon v-on="on" class="mr-1" :disabled="loading" small @click="onClickReporteTiempoReal(item)">
							mdi-script-text-play
						</v-icon>
					</template>
					<span class="white--text">Reporte Tiempo Real</span>
				</v-tooltip>
				<v-tooltip
					v-if="permisoVista('cat-mantenimientocambiotroquel', 'u')"
					bottom
					color="black"
					class="white--text"
				>
					<template v-slot:activator="{ on }">
						<v-icon
							v-on="on"
							small
							:disabled="loading"
							class="mr-1"
							@click="editMantenimiento(item)"
						>
							edit
						</v-icon>
					</template>
					<span class="white--text">Editar</span>
				</v-tooltip>
				<v-tooltip
					v-if="permisoVista('cat-mantenimientocambiotroquel', 'd')"
					bottom
					color="black"
					class="white--text"
				>
					<template v-slot:activator="{ on }">
						<v-icon
							v-on="on"
							small
							:disabled="loading"
							class="mr-1"
							@click="onClickDelete(item)"
						>
							delete
						</v-icon>
					</template>
					<span class="white--text">Eliminar</span>
				</v-tooltip>
			</template>
		</v-data-table>

	</v-col>
</template>

<script>
import axios from "axios";
import Lista from '../../OTS/Lista.vue';
import ListadoPorHacer from './ListadoPorHacer.vue';
import Comprobaciones from '../Comprobaciones.vue';
import Recomendaciones from './Recomendaciones.vue';
import { mapState } from 'vuex';
import MaquinaInsAutocomplete from '../MaquinaInsAutocomplete.vue';
import HeaderInfoMantenimiento from './HeaderInfoMantenimiento.vue';
import MenuDatepicker from '../../../MenuDatepicker.vue';
import VAutocompleteTextarea from '../../../VAutocompleteTextarea.vue';
import AsignacionMantenimiento from '../AsignacionMantenimiento.vue';
import KilometrajeTextfield from '../KilometrajeTextfield.vue';

export default {
	components: {
		Lista,
		ListadoPorHacer,
		Comprobaciones,
		Recomendaciones,
		MaquinaInsAutocomplete,
		HeaderInfoMantenimiento,
		MenuDatepicker,
		VAutocompleteTextarea,
		AsignacionMantenimiento,
		KilometrajeTextfield
	},
	data: () => ({
		categoriasMaquinas: [],
		loadingCategoriasMaquinas: false,
		search: null,
		loadingKilometrajeMin: false,
		loadingComprobaciones: false,
		loadingProveedores: false,
    	proveedores: [],
		loadingRecomendaciones: false,
		recomendaciones: [],
		proveedores: [],
		loadingCategorias: false,
    	categorias: [],
		erroresItem: {
			errorCodigo: null,
			errorNombre: null,
			errorModelo: null,
			errorSubdepto: null,
			errorAsignados: null,
			errorHorarios: null,
			errorCategoria: null,
			errorInstancia: null,
			errorFechaInicio: null,
			errorTiempoEstimado: null,
			errorFechaFin: null,
			errorResponsable: null,
			errorMecanicos: null,
			errorImporteNeto: null,
			errorKilometraje: null,
			errorHorasTrabajadas: null,
			errorRecomendacion: null,
			errorProveedor: null,
			errorCategoriaMaquina: null,
		},
		tipoEdicion: 1,
		subdepartamentos: [],
		savingProgrammed: false,
		dialogTiempoReal: false,
		instancias: [],
		saving: false,
		erroresReported: [],
		dialogExtemporaneo: false,
		guardando: false,
		dialog: false,
		dialogInsumos: false,
		editedItem: null,
		defaultEditedItem: {
			idMantenimientoCambioTroquel: null,
			idMantenimientoCategoria: null,
			idSubdepartamento: null,
			codigo: "",
			nombre: "",
			descripcion: "",
			idMaquina: null,
			comprobaciones: [],
			numPersonas: 1,
			asignados: [],
			label: "",
			responsable: null,
			mecanicos: [],
			instancia: null,
			inicio: null,
			fin: null,
			recomendacion: "",
			fechaInicioPlaneado: null,
			tiempoEstimado: null,
			idProveedor: null,
			importeNeto: null,
			kilometraje: null,
			horasTrabajadas: null,
			tipoAsignacion: 1,
      		idMantenimientoProveedor: null,
			idsMaquinaIns: []
		},
		reportedItem: null,
		personas: [],
		maquinas: [],
		headers: [
			{
				text: "Enunciado",
				align: "left",
				sortable: false,
				value: "enunciado",
				width: "80%,",
			},
			{
				text: "Acciones",
				align: "center",
				sortable: false,
				value: "actions",
				width: "20%,",
			},
		],
		alerta: false,
		errores: [],
		cambiosTroqueles: [],
		loading: false,
		headersHorario: [
			{
				text: "Código",
				align: "center",
				sortable: false,
				value: "codigo",
			},
			{
				text: "Nombre",
				align: "center",
				sortable: false,
				value: "nombre",
			},
			{
				text: "Categoría Mantenimiento",
				align: "center",
				sortable: false,
				value: "nombreCategoria",
			},
			{
				text: "Categoría Máquina",
				align: "center",
				sortable: false,
				value: "categoriaMaquina"
			},
			{
				text: "Modelo",
				align: "center",
				sortable: false,
				value: "maquina",
			},
			{
				text: "Acciones",
				align: "center",
				sortable: false,
				value: "actions",
				width: "10%,",
			},
		],
		dialogEliminar: false,
		eliminarItem: null,
		deleting: false,
		saving: false,
		bloques:[
			{
				value:1,
				text: "1 Minuto"
			},
			{
				value:2,
				text: "2 Minutos"
			},
			{
				value:5,
				text: "5 Minutos"
			},
			{
				value:10,
				text: "10 Minutos"
			},
			{
				value:30,
				text: "30 Minutos"
			},
			{
				value:60,
				text: "1 Hora"
			},
		],
	}),
	beforeMount(){
		this.editedItem = structuredClone(this.defaultEditedItem);
		this.eliminarItem = structuredClone(this.defaultEditedItem);
		this.reportedItem = structuredClone(this.defaultEditedItem);
	},
	mounted() {
		this.initialize();
	},
	computed: {
		...mapState(['nombreEmpresa']),
		idsCategoriasMaquinasMoldes(){
			return this.categoriasMaquinas.reduce((acc,categoria) => {
				if(categoria.nombre.toLowerCase().includes('troquel')) acc.push(categoria.idMaquinaCategoria);
				return acc;
			},[]);
		},
		maquinasComputed(){
			return this.maquinas.filter(maquina => this.idsCategoriasMaquinasMoldes.includes(maquina.idMaquinaCategoria));
		},
		computeColImporteNeto(){
			return this.$utils.mantenimientos.esCategoriaEspecifica(this.maquinas,'basculas',this.reportedItem.idMaquina)
				? 12
				: 6;
		},
		esIndasa(){
			return this.nombreEmpresa.toUpperCase() == "INDASA";
		},
		today(){
			return (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substring(0, 10);
		},
		showSubdepto(){
			if(this.esIndasa) return false;
			return true;
		},
		textButtonSave(){
			return this.editedItem.idMantenimientoCambioTroquel == null
				? 'Agregar'
				: this.tipoEdicion == 1
					? 'Actualizar'
					: 'Clonar'
		},
		computedIns() {
			var actualEditedItem = this.reportedItem.idMantenimientoCambioTroquel != null ? 'reportedItem' : 'editedItem';
			const ins = this.instancias.filter((instancia) =>instancia.idMaquina == this[actualEditedItem].idMaquina);
			return ins;
		},
	},
	methods: {
		nombreSubdepto: ({ codigo, nombre }) => codigo ? `[${codigo}] - ${nombre}`.trim() : nombre.trim(),
    	nombrePersona: ({ nombre, paterno, materno }) =>
			`${nombre ? `${nombre} ` : ""}${paterno ? `${paterno} ` : ""}${
				materno ? `${materno} ` : ""
			}`.trim(),
		onChangeModelo(){
			this.resetMaquinaIns();
		},
		onChangeCategoriaMaquina(){
			this.editedItem.idMaquina = null;
			this.resetMaquinaIns();
		},
		onChangeMaquinaIns(){
			if(this.esIndasa && this.$utils.mantenimientos.esCategoriaEspecifica(this.maquinas,'transporte',this.reportedItem.idMaquina)){
				this.erroresItem.errorKilometraje = null;
				this.$refs.kilometrajeTextfield.getUltimoKilometrajeReportado(this.reportedItem.instancia);
			}
		},
		resetMaquinaIns() {
			this.editedItem.idsMaquinaIns = [];
		},
		onChangeTipoAsignacion(){
			this.editedItem.numPersonas = 1;
			this.editedItem.asignados = [];
			this.editedItem.idMantenimientoProveedor = null;
		},
		getRecomendaciones(){
			this.loadingRecomendaciones = true;

			axios
				.get('/Mantenimientos/GetRecomendacionesUnicas')
				.then( (response) => {
					this.recomendaciones = response.data;
				})
				.catch(console.log)
				.finally( () => {
					this.loadingRecomendaciones = false;
				});
		},
		resetErrores(){
			this.erroresReported = [];
			this.errores = [];
			Object.keys(this.erroresItem).forEach( (key) => this.erroresItem[key] = null );
		},
		onClickProgramar(item){
			this.reportedItem = structuredClone(item);
			this.dialogProgramar = true;
		},
		showRecomendaciones(item){
			this.$refs.recomen.showDialogRecomendaciones(item);
		},
		guardarCambioTroquel(){
			this.save();
		},
		getInstancias() {
			axios
				.get("/MaquinaInstancias")
				.then((response) => {
					this.instancias = response.data;
				})
				.catch((error) => {
					console.log(error);
				});
		},
		validateTipoAsignacion(){
			if(this.reportedItem.tipoAsignacion == 1){
				if (
					this.reportedItem.mecanicos == null ||
					this.reportedItem.mecanicos?.length == 0
				) {
					this.$set(this.erroresItem, "errorMecanicos", "Requerido al menos uno");
				}
			}
			else {
				if(this.reportedItem.idMantenimientoProveedor == null){
					this.$set(this.erroresItem, "errorProveedor", "Requerido");
				}
			}
		},
		validReporteExtemporaneo() {
			this.resetErrores();

			if (this.reportedItem.responsable == null) {
				this.erroresItem.errorResponsable = "Requerido";
			}
			
			this.validateTipoAsignacion();
			
			if (this.reportedItem.inicio == null) {
				this.erroresItem.errorFechaInicio = "Requerido";
			}
			if (this.reportedItem.fin == null) {
				this.erroresItem.errorFechaFin = "Requerido";
			}

			if ( this.reportedItem.instancia == null ){
				this.erroresItem.errorInstancia = "Requerido";
			}

			const comprobacionesValidas = this.$refs.checklist.validate();

			const hayErrores = Object.values(this.erroresItem).some( (value) => value != null ) || !comprobacionesValidas;
			if(hayErrores){
				this.erroresReported = ["Hay uno o mas errores, porfavor reviselos y vuelva a intentarlo"];
			}

			return !hayErrores;
		},
		validReporteTiempoReal() {
			this.resetErrores();

			if (this.reportedItem.responsable == null) {
				this.erroresItem.errorResponsable = "Requerido";
			}

			this.validateTipoAsignacion();

			if ( this.reportedItem.instancia == null ){
				this.erroresItem.errorInstancia = "Requerido";
			}

			const hayErrores = Object.values(this.erroresItem).some( (value) => value != null );
			if(hayErrores){
				this.erroresReported = ["Hay uno o mas errores, porfavor reviselos y vuelva a intentarlo"];
			}

			return !hayErrores;
		},
		validProgrammed(){
			this.resetErrores();

			if ( this.reportedItem.instancia == null ){
				this.erroresItem.errorInstancia = "Requerido";
			}
			
			if (this.reportedItem.fechaInicioPlaneado == null){
				this.erroresItem.errorFechaInicio = "Requerido";
			}

			if (
				this.reportedItem.tiempoEstimado === null ||
				this.reportedItem.tiempoEstimado == undefined
			) {
				this.erroresItem.errorTiempoEstimado = "Requerido";
			}

			const hayErrores = Object.values(this.erroresItem).some( (value) => value != null );
			if(hayErrores){
				this.erroresReported.push("Hay uno o mas errores, porfavor reviselos y vuelva a intentarlo");
				setTimeout(() => {
					this.erroresReported = [];
				}, 2000);
			}

			return !hayErrores;
		},
		programarMantenimiento(){
			if( !this.validProgrammed() ) return;
			const obj = {
				idMantenimientoCambioTroquel: this.reportedItem.idMantenimientoCambioTroquel,
				idMaquinaIns: this.reportedItem.instancia,
				inicioPlaneado: this.reportedItem.fechaInicioPlaneado,
				tiempoEstimado: this.reportedItem.tiempoEstimado
			};
			this.savingProgrammed = true;
			
			axios
				.post("/Mantenimientos/ProgramarMttoCambioMoldeTroquel",obj)
				.then( () => {
					this.savingProgrammed = false;
					this.closeProgrammed();
					this.initialize();
				})
				.catch( error => {
					this.savingProgrammed = false;
					console.log( error );
				});
		},
		reportarExtemporaneo() {
			if (!this.validReporteExtemporaneo()) return;
      		const item = this.$refs.checklist.terminarTarea();
			const obj = {
				idMantenimientoCambioTroquel: this.reportedItem.idMantenimientoCambioTroquel,
				idsMaquinaIns: this.reportedItem.instancia,
				idPersonaResponsable: this.reportedItem.responsable,
				mecanicos: this.reportedItem.mecanicos,
				fechaInicioRealizo: this.reportedItem.inicio,
				fechaFinRealizo: this.reportedItem.fin,
				recomendacion: this.reportedItem.recomendacion,
				comprobaciones: item.comprobaciones,
				importeNeto: parseFloat(this.reportedItem.importeNeto) || null,
				kilometraje: parseFloat(this.reportedItem.kilometraje) || null,
				horasTrabajadas: parseFloat(this.reportedItem.horasTrabajadas) || null,
				tipoAsignacion: this.reportedItem.tipoAsignacion,
				idMantenimientoProveedor: this.reportedItem.idMantenimientoProveedor
			};

			this.saving = true;
			axios
				.post(
					"/Mantenimientos/ReporteExtemporaneoMttoCambioMoldeTroquel",
					obj
				)
				.then(() => {
					this.saving = false;
					this.closeDialogReportes();
					this.initialize();
				})
				.catch((error) => {
					console.log(error);
					this.saving = false;
				});
		},
		reportarTiempoReal() {
			if (!this.validReporteTiempoReal()) return; 
			var obj = {
				idMantenimientoCambioTroquel: this.reportedItem.idMantenimientoCambioTroquel,
				idMaquinaIns: this.reportedItem.instancia,
				idPersonaResponsable: this.reportedItem.responsable,
				mecanicos: this.reportedItem.mecanicos,
				tipoAsignacion: this.reportedItem.tipoAsignacion,
				idMantenimientoProveedor: this.reportedItem.idMantenimientoProveedor
			};

			this.saving = true;
			axios
				.post(
					"/Mantenimientos/ReporteTiempoRealMttoCambioMoldeTroquel",
					obj
				)
				.then(() => {
					this.saving = false;
					this.closeDialogReportes();
					this.initialize();
				})
				.catch((error) => {
					console.log(error);
					this.saving = false;
				});
		},
		getPersonas() {
			axios
				.get("/Mantenimientos/PersonasMantenimiento")
				.then((response) => {
					this.personas = response.data;
				})
				.catch((error) => {
					console.log(error);
					this.personas = [];
				});
		},
		onClickReporteExtemporaneo(item) {
			this.reportedItem = structuredClone(item);
			this.reportedItem.mecanicos = item.asignados.slice();
			this.dialogExtemporaneo = true;
		},
		onClickReporteTiempoReal(item){
			this.reportedItem = structuredClone(item);
			this.reportedItem.mecanicos = item.asignados.slice();
			this.reportedItem.label = this.reportedLabel();
			this.dialogTiempoReal = true;
		},
		maquinaLabel(idMaquina) {
			const maquina = this.maquinas.find(
				(maquina) => maquina.idMaquina === idMaquina
			);
			return this.$utils.isValid(maquina)
				? maquina.marca + " - " + maquina.modelo
				: "N/A";
		},
		reportedLabel() {
			return `[${this.reportedItem.codigo}] ${
				this.reportedItem.nombre
			} - ${this.maquinaLabel(this.reportedItem.idMaquina)}`;
		},
		marcaModelo: (item) => item.marca + " - " + item.modelo,
		initialize() {
			this.getMaquinas();
			this.getMantenimientosCambiosTroqueles();
			this.getPersonas();
			this.getInstancias();
			this.getSubdepartamentos();
			this.getCategoriasMantenimiento();
			this.getRecomendaciones();
			this.getProveedoresMantenimiento();
			this.getCategoriasMaquinas();
		},
		getCategoriasMaquinas(){
			this.loadingCategoriasMaquinas = true;
			axios
				.get('/MaquinaCategorias')
				.then((response) => {
					this.categoriasMaquinas = response.data;
				})
				.catch(console.log)
				.finally(() => {
					this.loadingCategoriasMaquinas = false;
				});
		},
		getProveedoresMantenimiento(){
			this.loadingProveedores = true;

			axios
				.get('/MantenimientoProveedores')
				.then( (response) => {
					this.proveedores = response.data;
				})
				.catch(console.log)
				.finally( () => {
					this.loadingProveedores = false;
				});
		},
		getCategoriasMantenimiento() {
			this.loadingCategorias = true;

			axios
				.get("/MantenimientoCategorias")
				.then((response) => {
					this.categorias = response.data;
				})
				.catch((error) => {
					console.log(error);
					this.categorias = [];
				})
				.finally(() => {
					this.loadingCategorias = false;
				});
		},
		getSubdepartamentos(){
			axios
				.get('/Subdepartamento')
				.then( response => {
					this.subdepartamentos = response.data;
				})
				.catch(console.log)
		},
		closeCambioTroquel(){
			this.dialog = false;
			setTimeout(() => {
				this.editedItem = structuredClone(this.defaultEditedItem);
				this.resetErrores();
				this.tipoEdicion = 1;
				this.$refs.todo.togglePanel(0);
			}, 200);
		},
		closeDialogReportes() {
			this.dialogExtemporaneo = false;
			this.dialogTiempoReal = false;
			this.reportedItem = structuredClone(this.defaultEditedItem);
			this.resetErrores();
		},
		closeProgrammed() {
			this.dialogProgramar = false;
			this.reportedItem = structuredClone(this.defaultEditedItem);
			this.resetErrores();
		},
		getMaquinas() {
			axios
				.get("/Maquinas")
				.then((response) => {
					this.maquinas = response.data.filter(function (el) {
						return el.esMaquina == true;
					});
				})
				.catch((error) => {
					console.log(error);
				});
		},
		getMantenimientosCambiosTroqueles() {
			this.loading = true;
			axios
				.get("/Mantenimientos/GetMantenimientosCambiosTroqueles")
				.then((response) => {
					this.cambiosTroqueles = response.data;
				})
				.catch(console.log)
				.finally(() => {
					this.loading = false;
				});
		},
		editMantenimiento(item,tipoEdicion = 1) {
			this.editedItem = structuredClone(item);
			this.editedItem.comprobaciones = this.$utils.mantenimientos.mapComprobaciones(structuredClone(item.comprobaciones));
			this.tipoEdicion = tipoEdicion;
			if(this.tipoEdicion == 2){
				this.editedItem.codigo = null;
				this.editedItem.nombre = null;
			}
			this.dialog = true;
		},
		onClickDelete(item) {
			this.dialogEliminar = true;
			this.eliminarItem = structuredClone(item);
		},
		eliminarCambioTroquel() {
			this.deleting = true;
			axios
				.put("/Mantenimientos/DesactivarMantenimientoCambioMoldeTroquel/", {
					idMantenimientoCambioTroquel: this.eliminarItem.idMantenimientoCambioTroquel
				})
				.then(() => {
					this.deleting = false;
					this.closeDialogEliminar();
					this.getMantenimientosCambiosTroqueles();
				})
				.catch((error) => {
					this.deleting = false;
					console.log(error);
				});
		},
		closeDialogEliminar() {
			this.dialogEliminar = false;
			this.eliminarItem = structuredClone(this.defaultEditedItem);
		},
		validate() {
			this.resetErrores();

			if (
				!this.editedItem.codigo ||
				this.editedItem.codigo.length < 5 ||
				this.editedItem.codigo.length > 100
			) {
				this.erroresItem.errorCodigo = "Debe tener entre 5 y 100 caracteres";
			}
			if (
				!this.editedItem.nombre ||
				this.editedItem.nombre.length < 5 ||
				this.editedItem.nombre.length > 1000
			) {
				this.erroresItem.errorNombre = "Debe tener entre 5 y 100 caracteres";
			}

			if(this.esIndasa){
				if(this.editedItem.idMantenimientoCategoria == null){
					this.erroresItem.errorCategoria = "Requerido";
				}
			}
			
			if (this.editedItem.idMaquina == null) {
				this.erroresItem.errorModelo = "Requerido";
			}

			if(!this.esIndasa){
				if(this.editedItem.idSubdepartamento == null){
					this.erroresItem.errorSubdepto = "Requerido";
				}
			}

			if(this.editedItem.numPersonas > 0){
				if(this.editedItem.asignados.length > 0 && this.editedItem.asignados.length != this.editedItem.numPersonas){
					this.erroresItem.errorAsignados = "Debe ser igual al número de personas requeridas";
				}
			}

			const comprobacionesValidas = this.$refs.todo.validate();

			const hayErrores = Object.values(this.erroresItem).some( (value) => value != null ) || !comprobacionesValidas;
			if(hayErrores){
				this.errores.push("Hay uno o mas errores, porfavor reviselos y vuelva a intentarlo");
				setTimeout(() => {
					this.errores = [];
				}, 3000);
			}

			return !hayErrores;
		},
		save() {
			if (!this.validate()) return; 
			this.guardando = true;
			
			const comprobaciones = this.$refs.todo.getListado();
			
			axios({
				url: this.editedItem.idMantenimientoCambioTroquel == null || this.tipoEdicion == 2
					? "/Mantenimientos/CrearMantenimientoCambioMoldeTroquel"
					: "/Mantenimientos/ActualizarMantenimientoCambioMoldeTroquel",
				method: this.editedItem.idMantenimientoCambioTroquel == null || this.tipoEdicion == 2
					? "POST"
					: "PUT",
				data: {
					idMantenimientoCambioTroquel: this.editedItem.idMantenimientoCambioTroquel,
					idMaquina: this.editedItem.idMaquina,
					idsMaquinaIns: this.editedItem.idsMaquinaIns,
					codigo: this.editedItem.codigo,
					nombre: this.editedItem.nombre,
					descripcion: this.editedItem.descripcion,
					idSubdepartamento: this.editedItem.idSubdepartamento,
					comprobaciones,
					listaComprobacion: comprobaciones,
					numPersonas: this.editedItem.numPersonas,
					asignados: this.editedItem.asignados,
        			idMantenimientoCategoria: this.editedItem.idMantenimientoCategoria,
					tipoAsignacion: this.editedItem.tipoAsignacion,
					idMantenimientoProveedor: this.editedItem.idMantenimientoProveedor,
					esMolde: false
				}
			})
				.then(() => {
					this.guardando = false;
					this.closeCambioTroquel();
					this.getMantenimientosCambiosTroqueles();
				})
				.catch((error) => {
					console.log(error);
					this.guardando = false;
					if (error.response?.status == 409) {
						this.$set(this.erroresItem, "errorCodigo", "Ya existe");
					}
				});
		},
	},
};
</script>