<template>
  <div>
    <v-dialog v-model="dialogAgregarIntegrante" max-width="500px">
      <v-card>
        <v-card-title> Agregar Integrante </v-card-title>
        <v-card-text>
          <v-row>
            <v-col cols="12" md="12" sm="12">
              <v-autocomplete
                v-model="integrantes"
                :loading="loadingOperadores"
                :items="operadores"
                :item-text="nombrePersona"
                item-value="idPersona"
                label="Operadores"
                multiple
                chips
              ></v-autocomplete>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="green" text @click="resetIntegrantes">Cancelar</v-btn>
          <v-btn color="red" text @click="iniciarSesionIntegrantes"
            >Iniciar Sesión</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="errorOperacionCurso" max-width="500px">
      <v-card>
        <v-card-text class="text-center justify-center">
          <v-icon size="200px" color="red">mdi-alert</v-icon>
          <div class="headline">
            No se puede cerrar la Sesión, tiene una Operación en Curso.
          </div>
          <br />
          <div
            class="v-btn v-btn--contained blue theme--dark display-2 ma-5 pa-5"
            @click="errorOperacionCurso = false"
          >
            Cerrar
          </div>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-dialog v-model="cerrarSesionMostrar" persistent max-width="500px">
      <v-card>
        <v-card-title>Cerrar Sesión</v-card-title>
        <v-card-text>
          ¿Esta seguro que desea cerrar la sesion de{{
            sesion != null && sesion.idEquipo != null ? "l" : ""
          }}
          {{ sesion.nombre }}?
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text color="green" @click="resetCerrarSesion">Cancelar</v-btn>
          <v-btn text color="red" @click="cerrarSesionRun">Cerrar Sesión</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="iniciarSesionMostrar" persistent max-width="500px">
      <v-card>
        <v-card-title> Iniciar Sesión </v-card-title>
        <v-card-text>
          <v-row class="ma-0 pa-0">
            <v-col cols="12" md="12" sm="12" class="ma-0 pa-0">
              <v-autocomplete
                v-model="iniciarSesionItem.idsOperadores"
                :loading="loadingOperadores"
                :items="operadores"
                :item-text="nombrePersona"
                item-value="idPersona"
                label="Operadores"
                multiple
                chips
              >
              </v-autocomplete>
            </v-col>
            <v-col cols="12" md="12" sm="12">
              <v-switch
                v-model="iniciarSesionItem.sesionesIndividuales"
                label="Sesiones Individuales"
              ></v-switch>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text color="green" @click="resetIniciarSesion">Cancelar</v-btn>
          <v-btn text color="red" @click="iniciarSesionRun"
            >Iniciar Sesión</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-card flat>
      <v-container>
        <v-row v-if="equiposPredefinidosFiltrados.length > 0">
          <v-col cols="12" md="12" sm="12" class="ma-0 pa-0 text-center">
            <div class="text-h5">Equipos Predefinidos</div>
          </v-col>
          <v-col cols="12" md="12" sm="12" class="ma-0 pa-0">
            <v-slide-group class="pa-4" show-arrows center-active>
              <v-card
                v-for="(equipo, idx) in equiposPredefinidosFiltrados"
                :key="'equipo' + idx"
                :color="'white'"
                class="ma-4 pa-3 scroll-equipo"
                height="160"
                width="250"
              >
                <div class="headline text-center">{{ equipo.nombre }}</div>
                <v-btn
                  x-small
                  color="green"
                  block
                  @click="iniciarEquipoPredefinido(equipo)"
                  >Iniciar Equipo</v-btn
                >
                <ul>
                  <li
                    v-for="(integrante, idx2) in equipo.integrantes"
                    :key="'int-' + idx + '-' + idx2"
                  >
                    {{ integrante }}
                  </li>
                </ul>
              </v-card>
            </v-slide-group>
          </v-col>
        </v-row>
        <v-btn
          color="primary"
          v-if="permisoVista('control', 'c')"
          @click="iniciarSesion"
          >Iniciar Sesión</v-btn
        >
        <v-row>
          <v-col
            cols="12"
            md="12"
            sm="12"
            v-if="sesiones.length === 0 && sinDatos"
          >
            <v-card flat>
              <div class="text-center mt-16 pt-16">
                <v-progress-circular
                  :size="70"
                  :width="7"
                  color="green"
                  indeterminate
                ></v-progress-circular>
                <h2>Cargando...</h2>
              </div>
            </v-card>
          </v-col>
          <v-col
          v-else
            v-for="(sesion, idx) in sesiones"
            :key="'sesion-' + idx"
            cols="3"
            md="3"
            sm="3"
          >
            <v-card>
              <v-container>
                <v-row>
                  <v-col cols="10">
                    <div class="text-h6 justify-center">
                      {{ sesion.nombre }}
                    </div>
                    <ul v-if="sesion.integrantes != null">
                      <div
                        v-for="(integrante, i) in sesion.integrantes"
                        :key="'equipo-' + idx + '-' + i"
                      >
                        <v-icon
                          color="red"
                          @click="logoutIntegrante(sesion, integrante)"
                          >mdi-close</v-icon
                        >
                        {{ integrante.nombre }}
                      </div>
                    </ul>
                    Operaciones en Curso:
                    <br />
                    {{ numOps(sesion) }}
                  </v-col>
                  <v-col cols="2">
                    <v-row class="ma-0 pa-0" justify="center">
                      <v-btn icon i>
                        <v-icon color="black" @click="cerrarSesion(sesion)"
                          >mdi-close</v-icon
                        >
                      </v-btn>
                      <v-btn icon>
                        <v-icon color="black" @click="agregarIntegrante(sesion)"
                          >mdi-plus</v-icon
                        >
                      </v-btn>
                    </v-row>
                  </v-col>
                </v-row>
              </v-container>
            </v-card>
          </v-col>
          <v-col v-if="sesiones.length == 0 && !sinDatos" cols="12" md="12" sm="12">
            <v-card flat>
              <div class="h2 text-center">
                No hay Operadores Registrados en Esta Área
              </div>
            </v-card>
          </v-col>
        </v-row>
      </v-container>
    </v-card>
  </div>
</template>

<script>
import axios from "axios";
import underscore from "underscore";

export default {
  props: {
    area: {
      type: Object,
      default: {
        area: "",
        idArea: -1,
        operacionesCurso: 0,
      },
    },
  },
  data: () => ({
    idsTurnos: [],
    sesion: {
      nombre: "",
      idEquipo: null,
      idOperador: null,
    },
    sesiones: [],
    cerrarSesionMostrar: false,
    defaultSesion: {
      nombre: "",
      idEquipo: null,
      idOperador: null,
    },
    iniciarSesionMostrar: false,
    operadores: [],
    loadingOperadores: false,
    iniciarSesionItem: {
      idsOperadores: [],
      sesionesIndividuales: false,
    },
    defaultIniciarSesion: {
      idsOperadores: [],
      sesionesIndividuales: false,
    },
    operaciones: [],
    errorOperacionCurso: false,
    equiposPredefinidos: [],
    dialogAgregarIntegrante: false,
    integrantes: [],
    sesionItem: null,
    sinDatos:true
  }),
  computed: {
    equiposPredefinidosFiltrados() {
      return this.equiposPredefinidos.filter((ep) => {
        var flag = true;
        ep.idsPersonas.forEach((i) => {
          if (this.operadores.findIndex((o) => o.idPersona == i) < 0)
            flag = false;
        });
        return flag;
      });
    },
  },
  methods: {
    nombrePersona: (persona) =>
      persona.nombre + " " + persona.paterno + " " + persona.materno,
    iniciarSesion() {
      this.iniciarSesionMostrar = true;
      this.getOperadoresDisponibles();
    },
    iniciarEquipoPredefinido(equipo) {
      axios
        .post("/ComandoYControl/IniciarSesion", {
          IdArea: this.area.idArea,
          IdsOperadores: equipo.idsPersonas,
          SesionesIndividuales: false,
        })
        .then(() => {
          this.sesionesAbiertas();
        })
        .catch((error) => {
          console.log(error);
        });
    },
    agregarIntegrante(sesion) {
      this.dialogAgregarIntegrante = true;
      this.sesionItem = sesion;
      this.getOperadoresDisponibles();
    },
    resetIntegrantes() {
      this.integrantes = [];
      this.sesionItem = null;
      this.dialogAgregarIntegrante = false;
    },
    iniciarSesionIntegrantes() {
      axios
        .put("/ComandoYControl/IniciarSesionIntegrantes", {
          IdArea: this.area.idArea,
          IdOperador: this.sesionItem.idOperador,
          IdEquipo: this.sesionItem.idEquipo,
          IdsPersonas: this.integrantes,
        })
        .then(() => {
          this.resetIntegrantes();
          this.sesionesAbiertas();
        })
        .catch((error) => {
          console.log(error);
        });
    },
    logoutIntegrante(sesion, integrante) {
      axios
        .put("/ComandoYControl/CerrarSesionIntegrante", {
          IdArea: this.area.idArea,
          IdOperador: integrante.idOperador,
          IdEquipo: sesion.idEquipo,
        })
        .then(() => {
          this.sesionesAbiertas();
        })
        .catch((error) => {
          console.log(error);
        });
    },
    getOperadoresDisponibles() {
      this.loadingOperadores = true;
      axios
        .get("/ComandoYControl/OperadoresDisponibles")
        .then((response) => {
          this.loadingOperadores = false;
          this.operadores = response.data.filter(
            (o) => this.idsTurnos.includes(o.idTurno)
          );
        })
        .catch((error) => {
          this.loadingOperadores = false;
          console.log(error);
        });
    },
    iniciarSesionRun() {
      axios
        .post("/ComandoYControl/IniciarSesion", {
          IdArea: this.area.idArea,
          IdsOperadores: this.iniciarSesionItem.idsOperadores,
          SesionesIndividuales: this.iniciarSesionItem.sesionesIndividuales,
        })
        .then(() => {
          this.resetIniciarSesion();
          this.sesionesAbiertas();
        })
        .catch((error) => {
          console.log(error);
        });
    },
    resetIniciarSesion() {
      this.iniciarSesionMostrar = false;
      this.iniciarSesionItem = Object.assign({}, this.defaultIniciarSesion);
      this.iniciarSesionItem.idsOperadores = [];
    },
    numOps(sesion) {
      if (
        typeof sesion.numOperaciones === "undefined" ||
        sesion.numOperaciones == 0
      )
        return "Ninguna";
      else if (sesion.numOperaciones == 1) return "1 Operación";
      else return sesion.numOperaciones + " Operación";
    },
    operacionesCurso() {
      axios
        .get("/ComandoYControl/OperacionesProgreso/" + this.area.idArea)
        .then((response) => {
          this.operaciones = response.data;
          this.asignarOperaciones();
        })
        .catch((error) => {
          console.log(error);
        });
    },
    getEquiposPredeterminados() {
      axios
        .get("/ComandoYControl/EquiposPredefinidos/" + this.area.idArea)
        .then((response) => {
          this.equiposPredefinidos = response.data;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    getTurnos(){
      return new Promise( (resolve) => {
        axios
          .get('/Turnos')
          .then( response => {
            this.idsTurnos = response.data.map(turno => turno.idTurno);
          })
          .catch( error => {
            console.log(error);
          })
          .finally( () => {
            resolve();
          })
      });
    },
    async sesionesAbiertas() {
      await this.getTurnos();
      this.getOperadoresDisponibles();
      this.operacionesCurso();
      this.getEquiposPredeterminados();
      this.sesiones = [];
      axios
        .get("/ComandoYControl/SesionesAbiertas/" + this.area.idArea)
        .then((response) => {
          this.sesiones = response.data.map((s) => {
            s.numOperaciones = 0;
            return s;
          });
          if(this.sesiones.length == 0) this.sinDatos = false;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    asignarOperaciones() {
      if (this.operaciones.length == 0 || this.sesiones.length == 0) {
        setTimeout(() => {
          this.asignarOperaciones();
        }, 100);
      } else {
        this.sesiones.forEach((s) => {
          s.numOperaciones = 0;
          if (s.idEquipo == null) {
            this.operaciones.forEach((operacion) => {
              s.numOperaciones += operacion.operacionesEnProgreso.filter(
                (o) => {
                  return o.operadoresReales[0] == s.idOperador;
                }
              ).length;
            });
          } else {
            this.operaciones.forEach((operacion) => {
              s.numOperaciones += operacion.operacionesEnProgreso.filter(
                (o) => {
                  return underscore.isMatch(
                    s.integrantes.map((i) => i.idOperador).sort(),
                    o.operadoresReales.sort()
                  );
                }
              ).length;
            });
          }
        });
      }
    },
    limpiar() {
      this.sesiones = [];
    },
    cerrarSesion(sesion) {
      if (!this.permisoVista("control", "c")) {
        return;
      }
      this.sesion = sesion;
      this.cerrarSesionMostrar = true;
    },
    resetCerrarSesion() {
      this.sesion = Object.assign({}, this.defaultSesion);
      this.cerrarSesionMostrar = false;
    },
    cerrarSesionRun() {
      axios
        .put("/ComandoYControl/CerrarSesion", {
          IdArea: this.area.idArea,
          IdEquipo: this.sesion.idEquipo,
          IdPersona: this.sesion.idOperador,
        })
        .then(() => {
          this.sesionesAbiertas();
          this.resetCerrarSesion();
        })
        .catch((error) => {
          if (error.response.status == 409) {
            this.resetCerrarSesion();
            this.errorOperacionCurso = true;
          } else console.log(error);
        });
    },
  },
};
</script>

<style scoped>
.scroll-equipo {
  max-height: 140px; /* or any height you want */
  overflow-y: auto;
  overflow-x: hidden;
}
</style>