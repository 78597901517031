<template>
  <v-col cols="12">
    <template>
      <v-row justify="center">
        <v-dialog v-model="eliminar" persistent max-width="500px" :key="eliminar">
          <v-card>
            <v-card-title class="headline">¿Está seguro que desea eliminar este <br/>equipo predefinido de operadores?</v-card-title>
            <v-card-text>Esta acción no se puede revertir y será permanente.</v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="blue darken-1" text @click="eliminar = false; delItem = '';">Cancelar</v-btn>
              <v-btn color="red darken-1" text @click="eliminar = false; deleteItemDB()">Aceptar</v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-row>
    </template>
    <v-data-table
      v-if="permisoVista('equipospredefinidos','r')"
      :headers="headers"
      :items="equipospredefinidos"
      :search="search"
      :loading="loading"
      loading-text="Cargando... Espere, Por Favor."
    >
      <template v-slot:top>
        <v-toolbar flat color="white">
          <v-dialog v-model="dialog" persistent max-width="500px" :key="dialog">
            <template v-slot:activator="{ on }">
              <v-btn color="primary" dark class="mb-2" v-on="on" v-if="permisoVista('equipospredefinidos','c')">Nuevo Equipo Predefinido<br/>de Operadores</v-btn>
            </template>
            <v-card>
              <v-card-title>
                <span class="headline">{{ formTitle }}</span>
              </v-card-title>
              <v-card-text>
                <v-container>
                  <v-row>
                    <v-col justify="center" cols="12" sm="12" md="12">
                      <v-text-field 
                        v-model="editedItem.nombre" 
                        label="Nombre del Equipo"
                        :error-messages="
                          errores[0]!=undefined &&
                          errores[0]
                        "
                      ></v-text-field>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col justify="center" cols="12" sm="12" md="12">
                      <v-autocomplete
                        v-model="editedItem.idArea"
                        :items="areas"
                        item-value="idArea"
                        item-text="nombre"
                        label="Área"
                        :error-messages="
                          errores[1]!=undefined &&
                          errores[1]
                        "
                      ></v-autocomplete>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col justify="center" cols="12" sm="12" md="12">
                      <v-autocomplete
                        v-model="editedItem.idTurno"
                        label="Turno"
                        :items="turnos"
                        item-value="idTurno"
                        item-text="nombre"
                        @change="resetIntegrantes"
                        :error-messages="
                          errores[2]!=undefined &&
                          errores[2]
                        "
                      ></v-autocomplete>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col justify="center" cols="12" sm="12" md="12">
                      <v-autocomplete
                        v-model="editedItem.idsPersonas"
                        label="Integrantes"
                        :items="personasFiltradas"
                        :item-text="nombrePersona"
                        item-value="idPersona"
                        multiple
                        chips
                        :error-messages="
                          errores[3]!=undefined &&
                          errores[3]
                        "
                      ></v-autocomplete>
                    </v-col>
                  </v-row>
                  <!-- <ul>
                    <li class="red--text" v-for="ex in errores" :key="ex">{{ ex }}</li>
                  </ul> -->
                </v-container>
              </v-card-text>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="blue darken-1" text @click="dialog=false; errores=[];">Cancelar</v-btn>
                <v-btn color="red darken-1" text @click="save()" :disabled="saving" :loading="saving">
                  Guardar
                  <template v-slot:loader>
                    <v-progress-circular
                      indeterminate
                      :width="2"
                      :size="24"
                      color="red"
                    ></v-progress-circular>
                  </template>
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
          <v-spacer></v-spacer>
          <v-text-field
            v-model="search"
            append-icon="search"
            label="Buscar"
            single-line
            hide-details
          ></v-text-field>
        </v-toolbar>
      </template>
      <template v-slot:item.action="{ item }">
        <v-tooltip bottom color="black" class="white--text">
          <template v-slot:activator="{ on }">
        <v-icon v-on="on"
          v-if="permisoVista('equipospredefinidos','u')"
          small
          class="mr-2"
          @click="editItem(item)"
        >
          edit
        </v-icon>
         </template>
          <span class="white--text">Editar</span>
        </v-tooltip>
        <v-tooltip bottom color="black" class="white--text">
          <template v-slot:activator="{ on }">
        <v-icon v-on="on"
          v-if="permisoVista('equipospredefinidos','d')"
          small
          @click="deleteItem(item)"
        >
          delete
        </v-icon>
         </template>
          <span class="white--text">Eliminar</span>
        </v-tooltip>
      </template>
      <template v-slot:no-data>
        <v-btn color="primary" @click="initialize">Refrescar</v-btn>
      </template>
    </v-data-table>
    <v-snackbar
      v-model="alerta"
      top
      color="error"
    >
      Existe un Equipo Predefinido de Operadores con el mismo nombre
      <v-btn
        text
        @click="alerta = false"
      >
        Cerrar
      </v-btn>
    </v-snackbar>
  </v-col>
  
</template>

<script>
import axios from "axios";

  export default {
    data: () => ({
      saving: false,
      dialog: false,
      alerta: false,
      eliminar: false,
      search: '',
      headers: [
        {
          text: 'Equipo Predefinido de Operadores',
          align: 'left',
          sortable: true,
          value: 'nombre',
        },
        { text: 'Acciones', value: 'action', sortable: false, width:'35%', align:'center' },
      ],
      loading: false,
      equipospredefinidos: [],
      delItem: '',
      action: 0,
      errores: [],
      editedIndex: -1,
      editedItem: {
        nombre: '',
        idTurno:-1,
        idArea: -1,
        idsPersonas:[],
      },
      defaultItem: {
        nombre: '',
        idTurno:-1,
        idArea: -1,
        idsPersonas:[],
      },
      areas:[],
      turnos:[],
      personas:[],
    }),

    computed: {
      formTitle () {
        return this.editedIndex === -1 ? 'Nuevo Equipo Predefinido de Operadores' : 'Editar Equipo Predefinido de Operadores'
      },
      personasFiltradas(){
        return this.personas.filter(p => p.idTurno == this.editedItem.idTurno);
      }
    },

    watch: {
      dialog (val) {
        val || this.close()
      }
    },

    created () {
      this.initialize()
    },
    mounted () {
      this.initialize()
    },

    methods: {
      nombrePersona: persona => persona.nombre + " " + persona.paterno + " " + persona.materno,
      initialize () {
        let me = this;
        this.getAreas();
        this.getTurnos();
        this.getPersonas();
        me.loading = true;
        axios
        .get("/EquipoPredefinidos")
        .then(response =>  {
          me.equipospredefinidos = response.data;
          me.loading = false;
        })
        .catch(error => {
          console.log(error);
        });
      },
      getAreas(){
        axios
        .get("/Areas")
        .then(response => {
          this.areas = response.data.filter(a => a.idArea > 1);
        })
        .catch(error => {
          console.log(error);
        })
      },
      getPersonas(){
        axios
        .get("/Personas/Operadores")
        .then(response => {
          this.personas = response.data;
        })
        .catch(error => {
          console.log(error);
        });
      },
      getTurnos(){
        axios
        .get("/Turnos")
        .then(response => {
          this.turnos = response.data;
        })
        .catch(error => {
          console.log(error);
        })
      },
      resetIntegrantes(){
        this.editedItem.idsPersonas = [];
      },
      editItem (item) {
        this.action = 1;
        this.editedIndex = this.equipospredefinidos.indexOf(item)
        this.editedItem = Object.assign({}, item)
        this.dialog = true
      },

      deleteItem (item) {
        this.eliminar = true;
        this.delItem = item;
      },
      deleteItemDB(){
        let me = this;
        let item = this.delItem;
        this.delItem = '';
        axios
          .put("/EquipoPredefinidos/Desactivar/" + item.idEquipoPredefinido)
          .then(response =>  {
            me.initialize();
          })
          .catch(error => {
            console.log(error);
          });
      },
      close () {
        this.action = 0,
        this.dialog = false
        setTimeout(() => {
          this.editedItem = Object.assign({}, this.defaultItem)
          this.editedItem.idsPersonas = [];
          this.editedIndex = -1
        }, 300)
      },
      validate(){
        this.errores =  [];
        if (this.editedItem.nombre.length < 2 || this.editedItem.nombre.length > 50){
          this.errores[0] = "El nombre del equipo debe tener más de 2 caracteres y menos de 50.";
        }
        if(this.editedItem.idArea <= 0){
          this.errores[1] = "Debe de seccionar una Área";
        }
        if(this.editedItem.idTurno <= 0){
          this.errores[2] = "Debe de seccionar un Turno";
        }
        if(this.editedItem.idsPersonas.length < 2){
          this.errores[3] = "El Número de Integrantes debe de ser al menos 2 Personas";
        }

        return this.errores.length == 0;
      },
      save () {
        this.action = 0;
        if(this.validate()){
          let me = this;
          me.saving = true;
          if (this.editedIndex > -1)
          {
            //Código para editar
            axios
              .put("/EquipoPredefinidos/Actualizar", {
                IdEquipoPredefinido: me.editedItem.idEquipoPredefinido,
                Nombre: me.editedItem.nombre,
                IdArea: me.editedItem.idArea,
                IdTurno: me.editedItem.idTurno,
                IdsPersonas: me.editedItem.idsPersonas,
              })
              .then(response =>  {
                me.saving = false;
                me.close();
                me.initialize();
              })
              .catch(error => {
                me.saving = false;
                if (error.response) {
                  if(error.response.status == 409){
                    this.alerta = true;
                  }
                }
              });
          }
          else
          {
            axios
              .post("/EquipoPredefinidos/Crear", {
                Nombre: me.editedItem.nombre,
                IdArea: me.editedItem.idArea,
                IdTurno: me.editedItem.idTurno,
                IdsPersonas: me.editedItem.idsPersonas,
              })
              .then(response =>  {
                me.saving = false;
                me.close();
                me.initialize();
              })
              .catch(error => {
                me.saving = false;
                if (error.response) {
                  if(error.response.status == 409){
                    this.alerta = true;
                  }
                }
              });
          }
        }
      },
    },
  }
</script>