<template>
  <v-container>
    <v-dialog v-model="showComentarioGeneral" persistent max-width="500px">
      <v-card>
        <v-card-title>General</v-card-title>
        <v-card-text>
          <v-row>
            <v-col cols="12">
              <v-data-table
                :items="comenDialog"
                :headers="headersImgTable"
                :loading="loading"
              >
              <template v-slot:[`item.comentarioGeneral`]="{ item }">
                <p class="mb-0" v-if="item['comentarioGeneral'] == null">N/A</p> 
                <p class="mb-0" v-else> {{ item.comentarioGeneral }} </p>
              </template> 
              <template v-slot:[`item.imagenGeneral`]="{ item }">
                  <p class="mb-0" v-if="item['imagenGeneral'] == null">N/A</p>
                  <!-- <p class="mb-0" v-else> {{ item.imagenGeneral }} </p> -->
                  <v-tooltip
                      v-else
                      bottom
                      color="black"
                      class="white--text text-center"
                  >
                    <template v-slot:activator="{ on }">
                      <v-icon v-on="on" size="20" @click="showImageGeneral(item)">
                        mdi-eye
                      </v-icon>
                    </template>
                    <span class="white--text">Ver Imagen</span>
                  </v-tooltip>
              </template>
              </v-data-table>  
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="success" text @click="cerrarComentarioGeneral()"
          >Cerrar
          </v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-col cols="12" v-if="permisoVista('comprobaciones','r')">
      <v-row>
        <v-col cols="4">
          <v-autocomplete
              v-model="filtros.idComprobacionLista"
              :items="listas"
              :item-text="nombreComprobacionLista"
              item-value="idComprobacionLista"
              label="Lista de Comprobación (Obligatorio)"
              hide-details
              clearable
              @change="getListas()"
          ></v-autocomplete>
        </v-col>
        <v-col cols="4">
          <v-autocomplete
              v-model="filtros.clave"
              :items="claves"
              item-value="idTrabajoOrden"
              item-text="clave"
              label="OT"
              hide-details
              clearable
              @change="getListas()"
          ></v-autocomplete>
        </v-col>
        <v-col cols="4">
          <v-autocomplete
              v-model="filtros.idArea"
              :items="areas"
              item-value="idArea"
              item-text="nombre"
              label="Área"
              hide-details
              clearable
              @change="getListas()"
          ></v-autocomplete>
        </v-col>
        <v-col cols="4">
          <v-autocomplete
              v-model="filtros.idArticulo"
              :items="articulos"
              item-value="idArticulo"
              :item-text="nombreArticulo"
              label="Artículo"
              hide-details
              clearable
              @change="getListas()"
          ></v-autocomplete>
        </v-col>
        <v-col cols="4">
          <datePicker
              v-model="inicio"
              format="YYYY-MM-DD"
              :maxDate="fin"
              label="Desde (Opcionales)"
              clearable
              maxToday
              @input="getListas()"
          ></datePicker>
        </v-col>
        <v-col cols="4">
          <datePicker
              v-model="fin"
              format="YYYY-MM-DD"
              :minDate="inicio"
              label="Hasta (Opcionales)"
              clearable
              maxToday
              @input="getListas()"
          ></datePicker>
        </v-col>
        <v-col cols="4">
          <DownloadBtn
              text
              color="primary"
              className="my-0"
              @click="report"
              label="Descargar Reporte de<br/>Comprobación"
          />
        </v-col>
      </v-row>
      <v-dialog
          v-model="dialogShowImage"
          max-width="50%"
          @click:outside="closeShowImage()"
      >
        <v-card>
          <v-card-title class="py-1 d-flex justify-end">
            <v-btn color="primary" large text @click="closeShowImage()">
              Cerrar
              <v-icon right>mdi-close-thick</v-icon>
            </v-btn>
          </v-card-title>
          <v-card-text>
            <v-col cols="12" md="12" sm="12" class="text-center">
              <img :src="currentImage" style="max-width: 100%" alt="Imagen"/>
            </v-col>
          </v-card-text>
        </v-card>
      </v-dialog>
      <v-dialog 
          v-model="dialogShowImages"
          max-width="50%"
          @click:outside="closeShowImages()"
      >
        <v-card>
          <v-card-title class="py-1 d-flex justify-end">
            <v-btn color="primary" large text @click="closeShowImages()">
              Cerrar
              <v-icon right>mdi-close-thick</v-icon>
            </v-btn>
          </v-card-title>
          <v-card-text>
            <v-col cols="12" md="12" sm="12" class="text-center">
              <v-carousel>
                <v-carousel-item v-for="(image, index) in currentImages" :key="index">
                  <img :src="image" style="max-width: 100%" alt="Imagen"/>
                </v-carousel-item>
              </v-carousel>
            </v-col>
          </v-card-text>
        </v-card>
      </v-dialog> <!--Diálogo nuevo para mostrar las imágenes recibidas del checklist de calidad-->
      <template v-if="selected != null">
        <v-row>
          <v-col
              cols="6"
              v-for="(item, idx) in preguntas"
              :key="`pregunta-${idx}`"
              class="text-center"
          >
            <p
                class="
              mb-0
              align-self-center
              text-subtitle-1 text--primary text-center
            "
            >
              {{ item['pregunta'] }}
            </p>
            <apexchart
                height="350"
                :options="
              item['tipoPregunta'] === 1 ? chartOptionsSiNo : chartOptionsFrecuencia
            "
                :series="
              item['tipoPregunta'] === 1
                ? [{ data: item.series1, name: 'Frecuencia' }]
                : [{ data: item.series2, name: 'Frecuencia' }]
            "
            />
          </v-col>
          <v-col
              cols="12"
              v-if="preguntas.length > 0"
              :class="comprobacionSelected == null && 'mb-5'"
          >
            <v-select
                v-model="comprobacionSelected"
                label="Detalles de Comprobación"
                placeholder="Seleccione una comprobación"
                persistent-placeholder
                :items="preguntas"
                item-text="pregunta"
                item-value="idComprobacion"
                outlined
                hide-details
                clearable
            ></v-select>
          </v-col>
          <v-col cols="12" v-if="comprobacionSelected != null">
            <v-data-table
                :items="tablaResultadosFiltrada"
                :headers="headersResultados"
                :loading="loading"
            >
              <template v-slot:[`item.cantidad`]="{ item }">
                {{ item.cantidad.toFixed(3) }}
              </template>
              <template v-slot:[`item.respuesta`]="{ item }">
                {{ item.respuesta != null ? item.respuesta : "N/A" }}
              </template>
              <template v-slot:[`item.comentario`]="{ item }">
                {{ item.comentario != null ? item.comentario : "N/A" }}
              </template>
              <template v-slot:[`item.imagenes`]="{ item }">
                <p class="mb-0" v-if="item.imagenes.length == 0">N/A</p>
                <v-tooltip
                    v-else
                    bottom
                    color="black"
                    class="white--text text-center"
                >
                  <template v-slot:activator="{ on }">
                    <v-icon v-on="on" size="20" @click="showImages(item)">
                      mdi-eye
                    </v-icon>
                  </template>
                  <span class="white--text">Ver Imágenes</span>
                </v-tooltip>
              </template>
              <template v-slot:[`item.comentarioGeneral`]="{ item }">
                <p class="mb-0" v-if="!item['comentarioGeneral'] && item['imagenGeneral'] == null">N/A</p>
                <v-tooltip
                    v-else
                    bottom
                    color="black"
                    class="white--text text-center"
                >
                  <template v-slot:activator="{ on }">
                    <v-icon v-on="on" size="20" @click="openComentarioGeneral(item)">
                      mdi-eye
                    </v-icon>
                  </template>
                  <span class="white--text">Comentario General</span>
                </v-tooltip>
              </template>
            </v-data-table>
          </v-col>
        </v-row>
      </template>
      <v-row v-if="loading">
        <v-col cols="12">
          <v-alert color="blue-grey" text class="mb-0">
            <div class="text-center" style="font-size: 16px">Cargando</div>
          </v-alert>
        </v-col>
      </v-row>
      <v-row
          v-if="
        !loading &&
        filtros.idComprobacionLista != null &&
        (selected == null || preguntas.length === 0)
      "
      >
        <v-col cols="12">
          <v-alert color="blue-grey" text class="mb-0">
            <div class="text-center" style="font-size: 16px">
              No hay datos disponibles
            </div>
          </v-alert>
        </v-col>
      </v-row>
      <v-row v-if="!loading && filtros.idComprobacionLista == null">
        <v-col cols="12">
          <v-alert type="primary" text class="mb-0">
            <div class="text-center" style="font-size: 16px">
              Seleccione una lista de comprobación
            </div>
          </v-alert>
        </v-col>
      </v-row>
    </v-col>
  </v-container>
</template>

<script>
import axios from "axios";
import {mapState} from "vuex";
import DownloadBtn from "../../../DownloadBtn.vue";

export default {
  components: {DownloadBtn},
  data() {
    return {
      comenDialog: [],
      imgGene: [],
      showComentarioGeneral: false,
      comentarioGeneralSelected: null,
      dialogShowImage: false,
      dialogShowImages: false,
      currentImage: null,
      currentImages: [],
      comprobacionSelected: null,
      headersResultados: [
        {
          text: "Clave ODT",
          align: "center",
          sortable: false,
          value: "claveOT",
        },
        {
          text: "Artículo Producir",
          align: "center",
          sortable: false,
          value: "articuloOTD",
        },
        {
          text: "Artículo Revisado",
          align: "center",
          sortable: false,
          value: "articulo",
        },
        {
          text: "Revisado por",
          align: "center",
          sortable: false,
          value: "reviso",
        },
        {
          text: "Cantidad Revisada",
          align: "center",
          sortable: false,
          value: "cantidad",
        },
        {
          text: "Respuesta",
          align: "center",
          sortable: false,
          value: "respuesta",
        },
        {
          text: "Comentario",
          align: "center",
          sortable: false,
          value: "comentario",
        },
        {
          text: "Imágenes",
          align: "center",
          sortable: false,
          value: "imagenes",
        },
        {
          text: "Comentario General",
          align: "center",
          sortable: false,
          value: "comentarioGeneral",
        },
      ],
      headersImgTable: [
        {
          text: "Comentario General",
          align: "center",
          sortable: false,
          value: "comentarioGeneral",
        },
        {
          text: "Imagen General",
          align: "center",
          sortable: false,
          value: "imagenGeneral",
        },
      ],
      tablaResultados: [],
      filtros: {
        idComprobacionLista: null,
        clave: null,
        idArea: null,
        idArticulo: null,
      },
      listas: [],
      claves: [],
      areas: [],
      articulos: [],
      preguntas: [],
      categorias: [],
      selected: [],
      loading: false,
      chartOptionsSiNo: {
        chart: {
          type: "bar",
          animations: {
            speed: 200,
          },
        },
        dataLabels: {
          enabled: false,
        },
        plotOptions: {
          bar: {
            distributed: true,
          },
        },
        xaxis: {
          categories: ["Si", "No"],
        },
        colors: ["#009129", "#DE0000"],
      },
      chartOptionsFrecuencia: {
        chart: {
          height: 350,
          type: "scatter",
          zoom: {
            enabled: true,
            type: "xy",
          },
        },
        xaxis: {
          tickAmount: 10,
          labels: {
            formatter: function (val) {
              return parseFloat(val).toFixed(1);
            },
          },
        },
        yaxis: {
          tickAmount: 7,
        },
        colors: ["#1691DE"],
      },
      inicio: null,
      fin: null,
    };
  },
  computed: {
    ...mapState(["token"]),
    tablaResultadosFiltrada() {
      if (this.comprobacionSelected == null) return [];
      const filtrado = this.preguntas.find(
          (pregunta) => pregunta.idComprobacion === this.comprobacionSelected
      );
      return filtrado != null ? filtrado.tabla : [];
    },
  },
  mounted() {
    this.initialize();
  },
  methods: {
    cerrarComentarioGeneral() {
      this.showComentarioGeneral = false;
      this.comentarioGeneralSelected = null;
    },
    openComentarioGeneral(item) {
      this.comenDialog = [item];
      //this.imgGene = [item.imagenGeneral];
      this.showComentarioGeneral = true;
      //console.log(item.imagenGeneral)
    },
    showImageGeneral(item) {
      const url = `/Comprobaciones/ImagenComprobacion?imagen=${item.imagenGeneral}&access_token=${this.token}`;
      axios({
        url: url,
        method: "GET",
        responseType: "blob",
        data: null,
      })
          .then(async (response) => {
            let blob = new Blob([response.data], {
              type: "application/octet-stream",
            });
            this.currentImage = await this.$utils.images.blobToBase64(blob);
            //console.log(item.imagen)
            this.dialogShowImage = true;
          })
          .catch((error) => {
            console.log(error);
          });
    },
    openImgGeneral() {
      this.dialogShowImage = true;
    },
    closeShowImage() {
      this.dialogShowImage = false;
      this.currentImage = "";
    },
    closeShowImages() {
      this.dialogShowImages = false;
      this.currentImages = [];
    },
    showImages(item) {  //Función para mostrar el arreglo de imágenes
      this.currentImages = [];
      for (const imagen of item.imagenes) {
      const url = `/Comprobaciones/ImagenComprobacion?imagen=${imagen}&access_token=${this.token}`;
      axios({
        url: url,
        method: "GET",
        responseType: "blob",
        data: null,
      })
          .then(async (response) => {
            let blob = new Blob([response.data], {
              type: "application/octet-stream",
            });
            const base64Image = await this.$utils.images.blobToBase64(blob);
            this.currentImages.push(base64Image); 
            this.dialogShowImages = true;
          })
          .catch((error) => {
            console.log(error);
          });
        } 
    },
    nombreArticulo: (value) =>
        value.codigo != null
            ? `[${value.codigo}] - ${value.nombre}`
            : `${value.nombre}`,

    nombreComprobacionLista: (value) => `[${value.codigo}] - ${value.nombre}`,
    initialize() {
      this.getClaves();
      this.getAreas();
      this.getArticulos();
      this.getComprobacionesListas();
    },
    getListas() {
      this.preguntas = [];
      this.comprobacionSelected = null;
      if (this.filtros.idComprobacionLista == null) return;
      this.loading = true;
      var url = `/Comprobaciones/Estadisticas/${this.filtros.idComprobacionLista}`;
      var parametros = [];

      if (this.filtros.clave > 0)
        parametros.push(`IdTrabajoOrden=${this.filtros.clave}`);
      if (this.filtros.idArea > 0)
        parametros.push(`IdArea=${this.filtros.idArea}`);
      if (this.filtros.idArticulo > 0)
        parametros.push(`IdTrabajoOrden=${this.filtros.idArticulo}`);

      parametros.push(`desde=${this.inicio === null ? "" : this.inicio}`);

      parametros.push(`hasta=${this.fin === null ? "" : this.fin}`);

      if (parametros.length > 0) {
        url += `?${parametros.join("&")}`;
      }

      axios
          .get(url)
          .then((response) => {
            this.loading = false;
            this.preguntas = response.data;
            //console.log(this.preguntas);
          })
          .catch((error) => {
            this.loading = false;
            console.log(error);
          });
    },
    getComprobacionesListas() {
      axios
          .get("/Comprobaciones/Headers")
          .then((response) => {
            this.listas = response.data;
          })
          .catch((error) => {
            console.log(error);
          });
    },
    getClaves() {
      axios
          .get("/TrabajoOrdenes/TrabajoOrdenHeaders")
          .then((response) => {
            this.claves = response.data;
          })
          .catch((error) => {
            console.log(error);
          });
    },
    getAreas() {
      axios
          .get("/Areas")
          .then((response) => {
            this.areas = response.data.filter((a) => a.idArea > 1);
          })
          .catch((error) => {
            console.log(error);
          });
    },
    getArticulos() {
      axios
          .get("/Articulos/ArticulosNombres?componentes=true&activo=true")
          .then((response) => {
            this.articulos = response.data;
          })
          .catch((error) => {
            console.log(error);
          });
    },
    report() {
      var url = "/Comprobaciones/XLSXeSTADISTICA?";
      if (this.filtros.idComprobacionLista != null)
        url += "IdComprobacionLista=" + this.filtros.idComprobacionLista;
      else return;

      url +=
          "&IdTrabajoOrden=" +
          (this.filtros.clave == null ? "" : this.filtros.clave);
      url +=
          "&IdArea=" + (this.filtros.idArea == null ? "" : this.filtros.idArea);
      url +=
          "&IdArticulo=" +
          (this.filtros.idArticulo == null ? "" : this.filtros.idArea);

      url += "&desde=" + (this.inicio == null ? "" : this.inicio);
      url += "&hasta=" + (this.fin == null ? "" : this.fin);

      this.$utils.axios.downloadFile(
          url,
          "GET",
          "reporte-de-comprobacion.xlsx",
          "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
      );
    },
  },
};
</script>
