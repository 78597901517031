<template>
	<v-card elevation="3" :class="{ 'shake-horizontal': !!item.animar }">
		<v-card-text :class="computedBorderColor">
			<v-container fluid>
				<v-row>
					<v-col cols="12" class="pa-0 text-left">
						<p
							class="mb-0 text-body-2"
							:class="{
								'blink-2': !!item.animarTexto,
								'red--text': !!item.animarTexto,
								'text--primary': !!!item.animarTexto
							}"
						>
							<span
								class="
									text-body-2
									font-weight-bold
								"
								:class="{
									'blink-2': !!item.animarTexto,
									'red--text': !!item.animarTexto,
									'text--primary': !!!item.animarTexto
								}"
							>
								{{ $utils.mantenimientos.getTipoMantenimiento(item) }}:
							</span>
							{{ getNombreTarea }}
						</p>
						<p class="mb-0 text-body-2 text--primary">
							<span
								class="
									text-body-2 text--primary
									font-weight-bold
								"
								>Máquina:</span
							>
							{{ item.maquinaIns }}
						</p>
						<p class="mb-0 text-body-2 text--primary">
							<span
								class="
									text-body-2 text--primary
									font-weight-bold
								"
								>Folio:</span
							>
							{{ item.folio }}
						</p>
						<p class="mb-0 text-body-2 text--primary">
							<span
								class="
									text-body-2 text--primary
									font-weight-bold
								"
								>Subdepartamento:</span
							>
							{{ item.nombreSubdepartamento || "N/D" }}
						</p>
						<p class="mb-0 text-body-2 text--primary">
							<span
								class="
									text-body-2 text--primary
									font-weight-bold
								"
								>Inicio:</span
							>
							{{ item.inicio }}
						</p>
						<p class="mb-0 text-body-2 text--primary">
							<span
								class="
									text-body-2 text--primary
									font-weight-bold
								"
								>Duración:</span
							>
							{{ item.duracion }}
						</p>
					</v-col>
				</v-row>
			</v-container>
		</v-card-text>
	</v-card>
</template>
<script>
export default {
	props: {
		item: {
			type: Object,
			default: () => ({}),
		},
	},
	computed: {
		getNombreTarea() {
			return `[${this.item.codigo}] - ${this.item.nombre}`.trim();
		},
		computedBorderColor() {
			switch (this.item.tipo) {
				case 1:
					return "border-left-green";
				case 3:
					return "border-left-pink";
				case 4:
					return "border-left-orange";
				case 5:
					return "border-left-indigo";
				case 6:
					return "border-left-deep-purple";
				default:
					return "border-left-blue";
			}
		},
	},
};
</script>
<style scoped>
.border-left-orange {
	border-left: 0.5rem solid #FF9800;
}

.border-left-green {
	border-left: 0.5rem solid #4caf50;
}

.border-left-pink {
	border-left: 0.5rem solid #e91e63;
}

.border-left-blue {
	border-left: 0.5rem solid #2196f3;
}

.border-left-indigo {
	border-left: 0.5rem solid #3F51B5;
}

.border-left-deep-purple {
	border-left: 0.5rem solid #673AB7;
}

.shake-horizontal {
	-webkit-animation: shake-horizontal 800ms cubic-bezier(0.455, 0.030, 0.515, 0.955) both;
	        animation: shake-horizontal 800ms cubic-bezier(0.455, 0.030, 0.515, 0.955) both;
}

@-webkit-keyframes shake-horizontal {
  0%,
  100% {
    -webkit-transform: translateX(0);
            transform: translateX(0);
  }
  10%,
  30%,
  50%,
  70% {
    -webkit-transform: translateX(-10px);
            transform: translateX(-10px);
  }
  20%,
  40%,
  60% {
    -webkit-transform: translateX(10px);
            transform: translateX(10px);
  }
  80% {
    -webkit-transform: translateX(8px);
            transform: translateX(8px);
  }
  90% {
    -webkit-transform: translateX(-8px);
            transform: translateX(-8px);
  }
}
@keyframes shake-horizontal {
  0%,
  100% {
    -webkit-transform: translateX(0);
            transform: translateX(0);
  }
  10%,
  30%,
  50%,
  70% {
    -webkit-transform: translateX(-10px);
            transform: translateX(-10px);
  }
  20%,
  40%,
  60% {
    -webkit-transform: translateX(10px);
            transform: translateX(10px);
  }
  80% {
    -webkit-transform: translateX(8px);
            transform: translateX(8px);
  }
  90% {
    -webkit-transform: translateX(-8px);
            transform: translateX(-8px);
  }
}

.blink-2 {
	-webkit-animation: blink-2 1500ms both;
	        animation: blink-2 1500ms both;
}

@-webkit-keyframes blink-2 {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0.2;
  }
  100% {
    opacity: 1;
  }
}
@keyframes blink-2 {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0.2;
  }
  100% {
    opacity: 1;
  }
}
</style>