/**
 * Define all of your application routes here
 * for more information on routes, see the
 * official documentation https://router.vuejs.org/en/
 */
import store from '../store'
export default [
  {
    path: '*',
    meta: {
      name: '',
      requiresAuth: true
    },
    redirect: {
      path: '/dashboard/reportes'
    }
  },
  // This  allows you to have pages apart of the app but no rendered inside the dash
  {
    path: '/',
    meta: {
      name: '',
      requiresAuth: false
    },
    component: () =>
      import(/* webpackChunkName: "routes" */ `@/views/LoginHome.vue`),
    // redirect if already signed in
    beforeEnter: (to, from, next) => {
      if (store.getters.authorized) {
        const default_route = localStorage.getItem("default_route");
        if(default_route == null){
          store.dispatch('logout');
          router.go();
        }
        next(default_route)
      } else {
        next()
      }
    },
    children: [
      {
        path: '',
        component: () => import(`@/components/LoginForm.vue`)
      }
    ]
  },
  // add any extra routes that you want rendered in the dashboard as a child below. Change toolbar names here
  {
    path: '/dashboard',
    meta: {
      name: 'Dashboard View',
      requiresAuth: true
    },
    component: () => import(`@/views/DashboardView.vue`),
    children: [
      {
        path: '/',
        meta: {
          name: '',
          requiresAuth: true
        },
        redirect: {
          path: localStorage.getItem('default_route')
        }
      },
      {
        path: 'validacion',
        meta: {
          name: '',
          requiresAuth: true
        },
        redirect: {
          path: localStorage.getItem('default_route')
        }
      },
      {
        path: 'home',
        meta: {
          name: 'Dashboard',
          requiresAuth: true
        },
        component: () => import(`@/components/DashViews/Dashboard.vue`)
      },
      {
        path: 'configuracion',
        meta: {
          name: 'Configuración',
          requiresAuth: true
        },
        component: () => import(`@/components/DashViews/Configuracion.vue`)
      },
      {
        path: 'recursos-humanos',
        meta: {
          name: 'Recursos Humanos',
          requiresAuth: true
        },
        component: () => import(`@/components/DashViews/RH.vue`)
      },
      {
        path: 'almacen',
        meta: {
          name: 'Almacén',
          requiresAuth: true
        },
        component: () => import(`@/components/DashViews/Inventario.vue`)
      },
      // {
      //   path: 'recursos',
      //   meta: {
      //     name: 'Recursos',
      //     requiresAuth: true
      //   },
      //   component: () => import(`@/components/DashViews/Recursos.vue`)
      // },
      {
        path: 'mantenimiento',
        meta: {
          name: 'Mantenimiento',
          requiresAuth: true
        },
        component: () => import(`@/components/DashViews/Mantenimiento.vue`)
      },
      {
        path: 'produccion',
        meta: {
          name: 'Producción',
          requiresAuth: true
        },
        component: () => import(`@/components/DashViews/Produccion.vue`)
      },
      {
        path: 'calidad',
        meta: {
          name: 'Calidad',
          requiresAuth: true
        },
        component: () => import(`@/components/DashViews/Calidad.vue`)
      },
      // {
      //   path: 'ots',
      //   meta: {
      //     name: 'OT\'S',
      //     requiresAuth: true
      //   },
      //   component: () => import(`@/components/DashViews/OTS.vue`)
      // },
      // {
      //   path: 'control',
      //   meta: {
      //     name: 'Comando y Control',
      //     requiresAuth: true
      //   },
      //   component: () => import(`@/components/DashViews/Control.vue`)
      // },
      {
        path: 'reportes',
        meta: {
          name: 'Reportes',
          requiresAuth: true
        },
        component: () => import(`@/components/DashViews/Reportes.vue`)
      },
      {
        path: 'ventas',
        meta: {
          name: 'Ventas',
          requiresAuth: true
        },
        component: () => import(`@/components/DashViews/Ventas.vue`)
      },
      {
        path: 'compras',
        meta: {
          name: 'Compras',
          requiresAuth: true
        },
        component: () => import(`@/components/DashViews/Compras.vue`)
      },
      {
        path: 'seguridad',
        meta: {
          name: 'Seguridad',
          requiresAuth: true
        },
        component: () => import(`@/components/DashViews/Seguridad.vue`)
      },
      {
        path: 'perfil',
        meta: {
          name: 'Perfil',
          requiresAuth: true
        },
        component: () => import(`@/components/DashViews/Perfil.vue`)
      },
      {
        path: 'descargas',
        meta: {
          name: 'Descargas',
          requiresAuth: true
        },
        component: () => import(`@/components/DashViews/Descargas.vue`)
      } 
    ]
  }
]
