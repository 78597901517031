import { VCol } from 'vuetify/lib/components/VGrid';
import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';
import { VTooltip } from 'vuetify/lib/components/VTooltip';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VRow,[_c(VCol,{attrs:{"cols":"12"}},[_c(VDataTable,{attrs:{"items":_vm.porcentajes.porcentajeMinAvances,"headers":_vm.porcentajesHeaders,"loading":_vm.loading,"no-data-text":"No hay datos disponibles","no-results-text":"No hay resultados disponibles"},scopedSlots:_vm._u([{key:"item.fraccion",fn:function(ref){
var item = ref.item;
var index = ref.index;
return [(_vm.indexEditar == index)?_c('div',[_c(VTextField,{staticClass:"centered-input",attrs:{"type":"number","error-messages":_vm.erroresCantidad[index]},on:{"focus":function($event){_vm.erroresCantidad[index] = ''}},model:{value:(item.newFraccion),callback:function ($$v) {_vm.$set(item, "newFraccion", $$v)},expression:"item.newFraccion"}})],1):_c('div',[_c('p',{staticClass:"mb-0"},[_vm._v(" "+_vm._s(item.fraccion != null ? item.fraccion.toFixed(2) : "N/A")+" ")])])]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
var index = ref.index;
return [(_vm.permisoVista('listas', 'u') && _vm.indexEditar == -1)?_c(VTooltip,{staticClass:"white--text",attrs:{"color":"black","bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c(VIcon,_vm._g({attrs:{"size":"18"},on:{"click":function($event){return _vm.editarCantidad(item, index)}}},on),[_vm._v(" edit ")])]}}],null,true)},[_c('span',{staticClass:"white--text"},[_vm._v("Editar")])]):_vm._e(),(
						_vm.permisoVista('listas', 'u') && _vm.indexEditar == index
					)?_c(VTooltip,{staticClass:"white--text",attrs:{"color":"black","bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
					var on = ref.on;
return [_c(VIcon,_vm._g({attrs:{"size":"18"},on:{"click":function($event){return _vm.guardarCantidad(item, index)}}},on),[_vm._v(" save ")])]}}],null,true)},[_c('span',{staticClass:"white--text"},[_vm._v("Guardar")])]):_vm._e(),(
						_vm.permisoVista('listas', 'u') && _vm.indexEditar == index
					)?_c(VTooltip,{staticClass:"white--text",attrs:{"color":"black","bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
					var on = ref.on;
return [_c(VIcon,_vm._g({attrs:{"size":"18"},on:{"click":function($event){return _vm.cancelarCantidad(item, index)}}},on),[_vm._v(" mdi-close-circle ")])]}}],null,true)},[_c('span',{staticClass:"white--text"},[_vm._v("Cancelar")])]):_vm._e()]}}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }