<template>
	<v-col cols="12" v-if="permisoVista('listas', 'r')">
		<v-dialog v-model="dialogDetalles" persistent max-width="95%">
			<v-card>
				<v-card-title>Detalles de {{ editedItem.area }}</v-card-title>
				<v-card-text>
					<v-row>
						<v-col cols="12">
							<v-text-field
								v-model="searchDetalle"
								label="Buscar"
								prepend-inner-icon="mdi-magnify"
								hide-details
								clearable
								:disabled="loadingDetalles"
							></v-text-field>
						</v-col>
					</v-row>
					<v-row>
						<v-col cols="12">
							<v-data-table
								:items="detalles"
								:headers="detallesHeaders"
								:loading="loadingDetalles"
								:search="searchDetalle"
							>
								<template v-slot:[`item.porcentaje`]="{ item }">
									<p
										class="mb-0"
										:class="colorAvance(item.porcentaje)"
									>
										{{ item.porcentaje.toFixed(2) }} %
									</p>
								</template>
								<template v-slot:[`item.piezasPlaneadas`]="{ item }">
									{{ getPiezasText(item,'piezasPlaneadas') }}
								</template>
								<template v-slot:[`item.piezasProducidas`]="{ item }">
									{{ getPiezasText(item,'piezasProducidas') }}
								</template>
								<template v-slot:[`item.diferencia`]="{ item }">
									{{ getPiezasText(item,'diferencia') }}
								</template>
							</v-data-table>
						</v-col>
					</v-row>
				</v-card-text>
				<v-card-actions>
					<v-spacer></v-spacer>
					<v-btn color="success" text @click="closeDialogDetalles()"
						>Cerrar</v-btn
					>
				</v-card-actions>
			</v-card>
		</v-dialog>
		<v-row>
			<v-col cols="12">
				<v-data-table
					:items="datos"
					:headers="datosHeaders"
					:loading="loading"
				>
					<template v-slot:[`item.porcentaje`]="{ item }">
						<p class="mb-0" :class="colorAvance(item.porcentaje)">
							{{ item.porcentaje.toFixed(2) }} %
						</p>
						<p class="mb-0 pt-2">
							<v-progress-linear
								:color="
									colorAvance(item.porcentaje).split('--')[0]
								"
								buffer-value="0"
								:value="item.porcentaje"
								stream
							></v-progress-linear>
						</p>
					</template>
					<template v-slot:[`item.avance`]="{ item }">
						<p v-if="item.avance.length == 0" class="mb-0">N/D</p>
						<BarraAvance
							v-else
							v-model="item.avance"
							itemTitle="Artículo"
							itemText="nombre"
							borderColor="#ffffff"
							:height="30"
						/>
					</template>
					<template v-slot:[`item.estado`]="{ item }">
						<p v-if="item.estado.length == 0" class="mb-0">N/D</p>
						<template v-else>
							<p
								v-for="(item, idx) in item.estado"
								class="mb-0"
								:key="`estado-${idx}`"
							>
								• {{ item }}
							</p>
						</template>
					</template>
					<template v-slot:[`item.actions`]="{ item }">
						<v-tooltip bottom color="black" class="white--text">
							<template v-slot:activator="{ on }">
								<v-icon
									v-on="on"
									small
									@click="showDialogDetalles(item)"
								>
									mdi-eye
								</v-icon>
							</template>
							<span class="white--text">Ver Detalles</span>
						</v-tooltip>
					</template>
				</v-data-table>
			</v-col>
		</v-row>
	</v-col>
</template>

<script>
import axios from "axios";
import BarraAvance from "../../BarraAvance.vue";
export default {
	components: { BarraAvance },
	data() {
		return {
			searchDetalle: null,
			detalles: [],
			detallesHeaders: [
				{
					text: "ODT",
					align: "center",
					value: "ordenTrabajo",
				},
				{
					text: "Línea Comercial",
					align: "center",
					value: "lineaComercial",
				},
				{
					text: "Cliente",
					align: "center",
					value: "cliente",
				},
				{
					text: "Código",
					align: "center",
					value: "codigoArticulo",
				},
				{
					text: "Descripción",
					align: "center",
					value: "articulo",
				},
				{
					text: "% Completado",
					align: "center",
					value: "porcentaje",
				},
				{
					text: "Estado",
					align: "center",
					value: "estado",
				},
				{
					text: "Piezas Planeadas",
					align: "center",
					value: "piezasPlaneadas",
				},
				{
					text: "Piezas Producidas",
					align: "center",
					value: "piezasProducidas",
				},
				{
					text: "Diferencia",
					align: "center",
					value: "diferencia",
				},
			],
			loadingDetalles: false,
			datos: [],
			datosHeaders: [
				{
					text: "Área",
					align: "center",
					value: "area",
				},
				{
					text: "% Completado",
					align: "center",
					value: "porcentaje",
				},
				{
					text: "Porcentaje de Avance",
					align: "center",
					value: "avance",
				},
				/*
				{
					text: "Estado",
					align: "center",
					value: "estado",
				},
				*/
				{
					text: "Acciones",
					align: "center",
					value: "actions",
				},
			],
			loading: false,
			dialogDetalles: false,
			editedItem: {
				idArea: null,
				area: "",
				porcentaje: 0,
				avance: [],
				estado: [],
			},
			defaultEditedItem: {
				idArea: null,
				area: "",
				porcentaje: 0,
				avance: [],
				estado: [],
			},
		};
	},
	mounted() {
		this.initialize();
	},
	methods: {
		getPiezasText: (item,cantidadKey) => item[cantidadKey] != null 
			? `${item[cantidadKey].toFixed(2)} ${item.unidad}`
			: "N/A",
		toggleShowMore(item){
			this.$set(item,'showMore',!!!item.showMore);
		},
		colorAvance(porcentaje) {
			if (porcentaje <= 0.0) return "red--text";
			else if (porcentaje < 100.0) return "orange--text";
			else return "green--text";
		},
		closeDialogDetalles() {
			this.dialogDetalles = false;
			this.$nextTick(() => {
				this.searchDetalle = null;
				this.detalles = [];
				this.editedItem = JSON.parse(
					JSON.stringify(this.defaultEditedItem)
				);
			});
		},
		showDialogDetalles(item) {
			this.editedItem = JSON.parse(JSON.stringify(item));
			this.dialogDetalles = true;
			this.getDetalles(item.idArea);
		},
		getDetalles(idArea) {
			this.loadingDetalles = true;

			axios
				.get(`/TrabajoOrdenes/ReporteAreaOTS/${idArea}`)
				.then((response) => {
					this.detalles = response.data;
				})
				.catch(console.log)
				.finally(() => {
					this.loadingDetalles = false;
				});
		},
		initialize() {
			this.getDatos();
		},
		getDatos() {
			this.loading = true;

			axios
				.get("/TrabajoOrdenes/ReporteAreas")
				.then((response) => {
					this.datos = response.data.areas;
				})
				.catch(console.log)
				.finally(() => {
					this.loading = false;
				});
		},
	},
};
</script>