import { VAutocomplete } from 'vuetify/lib/components/VAutocomplete';
import { VChip } from 'vuetify/lib/components/VChip';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VProgressLinear } from 'vuetify/lib/components/VProgressLinear';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VTooltip } from 'vuetify/lib/components/VTooltip';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.permisoVista('contador','r'))?_c(VCol,{staticClass:"px-8",attrs:{"cols":"12"}},[_c(VRow,[_c(VCol,{attrs:{"cols":"4","md":"4","sm":"4"}},[_c(VAutocomplete,{attrs:{"label":"Desde (Obligatorio)","items":_vm.rangos},on:{"change":_vm.ejecutar},model:{value:(_vm.minutos),callback:function ($$v) {_vm.minutos=$$v},expression:"minutos"}})],1),_c(VCol,{attrs:{"cols":"4","md":"4","sm":"4"}},[_c(VAutocomplete,{attrs:{"label":"Bloques de Tiempo (Obligatorio)","items":_vm.bloques},on:{"change":_vm.ejecutar},model:{value:(_vm.bloque),callback:function ($$v) {_vm.bloque=$$v},expression:"bloque"}})],1),_c(VCol,{attrs:{"cols":"4","md":"4","sm":"4"}},[_c(VAutocomplete,{attrs:{"label":"Máquina (Obligatorio)","items":_vm.maquinas,"item-value":"idMaquinaIns","item-text":_vm.nombreMaquina,"clearable":""},on:{"change":_vm.ejecutar},model:{value:(_vm.idMaquinaIns),callback:function ($$v) {_vm.idMaquinaIns=$$v},expression:"idMaquinaIns"}})],1)],1),(_vm.realizarPeticion)?_c(VRow,{staticClass:"px-0 ma-0"},[_c(VCol,{staticClass:"ma-0 text-right text-subtitle-2",attrs:{"cols":"12"}},[_vm._v(" Proxima Actualización en "+_vm._s(_vm.tiempo)+" ")])],1):_vm._e(),(_vm.loading)?_c(VProgressLinear,{attrs:{"color":"primary","indeterminate":""}}):_vm._e(),(_vm.estados.length > 0)?_c('table',{style:('width:100%;border: 0 px solid #666666;')},[_c('tbody',[_c('tr',{staticStyle:{"border":"0 px solid #666666"}},_vm._l((_vm.estados),function(estado,idx){return _c(VTooltip,{key:("e-" + idx),staticClass:"white--text",attrs:{"top":"","color":"black"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('td',_vm._g(_vm._b({class:("text-center " + ((estado.estado ? 'green' : 'red'))),style:(("border: 0 px solid #666666; padding-top: 10px; padding-bottom: 10px; background-color: " + ((estado.estado ? 'green' : 'red')) + "; width:'" + _vm.dimension + "'; height: 20px;"))},'td',attrs,false),on))]}}],null,true)},[_c('span',{staticClass:"white--text"},[_c('strong',[_vm._v("Desde ")]),_vm._v(_vm._s(estado.desde)+" "),_c('br'),_c('strong',[_vm._v("Hasta: ")]),_vm._v(_vm._s(estado.hasta)+" ")])])}),1)])]):_vm._e(),_vm._l((_vm.etiquetas),function(e,idx){return _c(VChip,{key:'e-'+idx,staticClass:"mr-2 mt-2"},[_vm._v(_vm._s(e.etiqueta))])}),_c('apexchart',{attrs:{"type":"line","height":"350","options":_vm.chartOptions,"series":_vm.datos}})],2):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }