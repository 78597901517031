// This is your plugin object. It can be exported to be used anywhere.
const Security = {
  // The install method is all that needs to exist on the plugin object.
  // It takes the global Vue object as well as user-defined options.
  install(Vue, options) {
    // We call Vue.mixin() here to inject functionality into all components.
    Vue.mixin({
      // Anything added to a mixin will be injected into all components.
      methods: {
        mostrarVista(vista) {
          if (this.$store.state.routes == null) return false;
          return (this.$store.state.routes.filter(function (element) {
            return element.vistas.filter(function (e) {
              return e.nombre == vista;
            }).length > 0;
          }).length > 0);
        },
        permisoVista(vista, permiso) {
          if (this.$store.state.routes == null) return false;
          return (this.$store.state.routes.filter(function (element) {
            return element.vistas.filter(function (e) {
              return e.nombre === vista && (e.permisos.filter(function(p){
                return p === permiso;
              }).length > 0);
            }).length > 0;
          }).length > 0);
        },
        // permisoVista(vista, permiso){
        //   if (this.$store.state.routes == null) return false;
        //   return (this.$store.state.routes.filter(function (element) {
        //     return element.vistas.filter(function (e) {
        //       e.nombre === 'listas' && console.log(`VISTA: ${e.nombre} -> PERMISOS PERMITIDOS [${e.permisos}]`);
        //       const tmpPermisos = e.nombre === vista && (e.permisos.filter(function (p) {
        //         return p === permiso;
        //       }).length > 0)
        //       if ( tmpPermisos ) {
        //         console.log(`CONCEDIDO -> PERMISO[${permiso}] para la VISTA '${vista}'[${e.permisos}]`);
        //         return true;
        //       } else {
        //         return false;
        //       }
        //     }).length > 0;
        //   }).length > 0);
        // }
      }
    });
  }
};

export default Security;