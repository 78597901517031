<template>
	<v-container fluid>
		<v-row>
			<v-col cols="4">
				<datePicker
					v-model="filtros.desde"
					format="YYYY-MM-DD"
					:maxDate="fin"
					label="Desde (Obligatorio)"
					clearable
					maxToday
					@input="getDatos()"
					:disabled="cargando"
				></datePicker>
			</v-col>
			<v-col cols="4">
				<datePicker
					v-model="filtros.hasta"
					:minDate="desde"
					format="YYYY-MM-DD"
					label="Hasta (Obligatorio)"
					clearable
					maxToday
					@input="getDatos()"
					:disabled="cargando"
				></datePicker>
			</v-col>
			<v-col cols="4">
				<v-select
					v-model="filtros.tipoMantenimiento"
					:items="tiposMantenimientos"
					label="Tipo de Mantenimiento (Opcional)"
					item-text="text"
					item-value="value"
					hide-details
					clearable
					@change="getDatos()"
				></v-select>
			</v-col>
		</v-row>
		<v-row>
			<v-col cols="12">
				<v-progress-linear
					v-if="cargando"
					color="primary"
					height="5"
					indeterminate
				></v-progress-linear>
				<apexchart
					type="bar"
					height="350"
					:options="chartOptions"
					:series="series"
					:key="`graph-${graphKey}`"
				>
				</apexchart>
			</v-col>
		</v-row>
	</v-container>
</template>

<script>
import axios from "axios";
export default {
	data() {
		return {
			filtros: {
				desde: null,
				hasta: null,
				tipoMantenimiento: null,
			},
			cargando: false,
			categories: [],
			series: [],
			graphKey: 1,
			tiposMantenimientos: [
				{
					text: "Preventivo",
					value: 1,
				},
				{
					text: "Correctivo",
					value: 2,
				},
				{
					text: "Inspección",
					value: 3,
				},
				{
					text: "Servicio",
					value: 4,
				},
			],
		};
	},
	mounted() {
		this.initialize();
	},
	computed: {
		chartOptions() {
			Apex.colors = ["#00CB3D", "#E1F70D"];
			return {
				chart: {
					type: "bar",
					height: 350,
					stacked: true,
					toolbar: {
						show: true,
					},
					zoom: {
						enabled: true,
					},
				},
				responsive: [
					{
						breakpoint: 480,
						options: {
							legend: {
								position: "bottom",
								offsetX: -10,
								offsetY: 0,
							},
						},
					},
				],
				plotOptions: {
					bar: {
						horizontal: false,
						dataLabels: {
							total: {
								enabled: true,
								style: {
									fontSize: "13px",
									fontWeight: 900,
								},
							},
						},
					},
				},
				xaxis: {
					type: "category",
					categories: this.categories,
				},
				legend: {
					position: "right",
					offsetY: 40,
				},
				fill: {
					opacity: 1,
					colors: ["#00CB3D", "#E1F70D"],
				},
			};
		},
	},
	methods: {
		initialize() {},
		getDatos() {
			if (this.filtros.desde == null || this.filtros.hasta == null) return;
			this.cargando = true;

			axios
				.get("/Reportes/Reportedecumplimientodemantenimientospormaquina",{
                    params: this.filtros
                })
				.then((response) => {
					this.categories = response.data.categories;
					this.series = response.data.series;
					this.graphKey++;
				})
                .catch( (error) => {
                    console.log(error);
                    this.categories = [];
                    this.series = [];
                })
                .finally( () => {
                    this.cargando = false;
                });
		},
	},
};
</script>