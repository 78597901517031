<template>
  <!-- <v-container> -->
    <v-row justify-sm="center">
      <v-card 
        class="mt-4"
        :width="anchoMaximo"
        fill
      >
        <v-tabs show-arrows v-model="tab" :background-color="colorTabs" centered dark icons-and-text>
          <v-tabs-slider></v-tabs-slider>

          <!-- <v-tab v-tooltip.bottom="modoAyuda && $help.mantenimiento.calendarioMantenimiento" href="#tab-1" v-if="mostrarVista('mantenimientopreventivo-calendario')" @click="updateMantenimientoCalendario()">
            Calendario de <br> Mantenimientos
            <v-icon>mdi-calendar-clock</v-icon>
          </v-tab> -->
          
          <v-tab v-if="mostrarVista('mantenimientopreventivo-calendario')" v-tooltip.bottom="modoAyuda && $help.mantenimiento.calendarioMantenimiento" href="#tab-2" @click="updateMantenimientoCalendario()">
            Calendario de <br> Mantenimientos
            <v-icon>mdi-calendar-clock</v-icon>
          </v-tab>

          <v-tab v-if="mostrarVista('mantenimientopreventivo-calendario')" v-tooltip.bottom="modoAyuda && $help.mantenimiento.tablero" href="#tab-3" @click="updateTablero()">
            Tareas de <br> Mantenimiento
            <v-icon>mdi-table-cog</v-icon>
          </v-tab>

          <v-tab
            v-if="
              mostrarVista('mantenimiento-inspecciones') ||
              mostrarVista('cat-mantenimientopreventivo') ||
              mostrarVista('cat-mantenimientocorrectivo') ||
              mostrarVista('cat-mantenimientoservicio')
            "
            v-tooltip.bottom="modoAyuda && $help.mantenimiento.catalogosMantenimiento.title"
            href="#tab-4"
            @click="updateCatalogoMantenimientos()"
          >
            Catálogos<!--  de<br/>Inspecciónes y<br/>Mantenimientos -->
            <v-icon>mdi-format-list-checkbox</v-icon>
          </v-tab>

          <v-tab v-tooltip.bottom="modoAyuda && $help.mantenimiento.moldes" href="#tab-5" v-if="mostrarVista('mantenimiento-inspecciones') && moldes" @click="updateMoldes()">
            Moldes
            <v-icon>mdi-view-grid-outline</v-icon>
          </v-tab>

          <v-tab v-tooltip.bottom="modoAyuda && $help.mantenimiento.ciclosInspecciones" href="#tab-6" @click="clickCiclos()" v-if="mostrarVista('mantenimiento-inspecciones')">
            Ciclos de<br/>Inspecciones
            <v-icon>mdi-cog-sync</v-icon>
          </v-tab>

          <v-tab v-tooltip.bottom="modoAyuda && $help.mantenimiento.usoMaquinas" href="#tab-7" v-if="mostrarVista('usomaquinas')">
            Uso de<br/>Máquinas
            <v-icon>mdi-calendar-check</v-icon>
          </v-tab>

          <v-tab v-tooltip.bottom="modoAyuda && $help.mantenimiento.cargarCostos" href="#tab-8" v-if="mostrarVista('reportar-factura') || mostrarVista('facturas-sindesglosar') || mostrarVista('movimientos-sindesglosar')" @click="updateMantenimientoFacturas()">
            Carga de<br/>Costos
            <v-icon>mdi-receipt</v-icon>
          </v-tab>

          <v-tab v-tooltip.bottom="modoAyuda && $help.mantenimiento.bloqueosMaquinas" href="#tab-9" v-if="mostrarVista('bloqueomaquina')" @click="updateBloqueoMantenimientos()">
            Bloqueos de <br/> Máquinas
            <v-icon>mdi-pause-octagon</v-icon>
          </v-tab>

          <v-tab v-tooltip.bottom="modoAyuda && $help.mantenimiento.alertasMantenimiento" href="#tab-10" v-if="mostrarVista('alertasmantenimientos')" @click="updateAlertasMantenimientos()">
            Alertas de <br/> Mantenimiento
            <v-icon>mdi-alert</v-icon>
          </v-tab>

          <v-tab v-tooltip.bottom="modoAyuda && $help.mantenimiento.statusMantenimientos.title" href="#tab-11" v-if="mostrarVista('mantenimiento-historial') || mostrarVista('mantenimiento-inspecciones')" @click="clickStatusMants()">
            Status de<br/>Mantenimientos
            <v-icon>mdi-list-status</v-icon>
          </v-tab>

        </v-tabs>

        <v-tabs-items v-model="tab">

          <!-- <v-tab-item :key="1" :value="'tab-1'" v-if="mostrarVista('mantenimientopreventivo-calendario')">
            <v-card flat>
              <v-card-text>
                <mantenimientoCalendario ref="mantenimientocalendario"></mantenimientoCalendario>
              </v-card-text>
            </v-card>
          </v-tab-item> -->
          
          <v-tab-item :key="2" :value="'tab-2'" v-if="mostrarVista('mantenimientopreventivo-calendario')">
            <v-card flat>
              <v-card-text>
                <CalendarioMantenimientos ref="mantenimientocalendario" />
              </v-card-text>
            </v-card>
          </v-tab-item>

          <v-tab-item :key="3" :value="'tab-3'">
            <v-card flat>
              <v-card-text>
                <Tablero ref="board" />
              </v-card-text>
            </v-card>
          </v-tab-item>

          <v-tab-item :key="4" :value="'tab-4'">
            <v-card flat>
              <v-card-text>
                <MantenimientoCatalogos ref="catalogosmantenimiento" />
              </v-card-text>
            </v-card>
          </v-tab-item>

          <v-tab-item :key="5" :value="'tab-5'" v-if="permisoVista('mantenimiento-inspecciones','r')">
            <v-card flat color="gray">
              <v-card-text class="pt-1">
                <ListaMoldes ref="listadoMoldes" />
              </v-card-text>
            </v-card>
          </v-tab-item>

          <v-tab-item :key="6" :value="'tab-6'" v-if="permisoVista('mantenimiento-inspecciones','r')">
            <v-card flat>
              <v-card-text>
                <Ciclos ref="cycles"/>
              </v-card-text>
            </v-card>
          </v-tab-item>

          <v-tab-item :key="7" :value="'tab-7'"  v-if="mostrarVista('usomaquinas')">
            <v-card flat>
              <v-card-text>
                <MantenimientoMaquinasUso ref="maquinasuso" />
              </v-card-text>
            </v-card>
          </v-tab-item>

          <v-tab-item :key="8" :value="'tab-8'" v-if="mostrarVista('reportar-factura') || mostrarVista('facturas-sindesglosar') || mostrarVista('movimientos-sindesglosar')">
            <v-card flat>
              <v-card-text>
                <MantenimientoFacturas ref="facturasMantenimiento" />
              </v-card-text>
            </v-card>
          </v-tab-item>

          <v-tab-item :key="9" :value="'tab-9'">
            <v-card flat>
              <v-card-text>
                <BloqueoMaquinas ref="bloq" />
              </v-card-text>
            </v-card>
          </v-tab-item>

          <v-tab-item :key="10" :value="'tab-10'">
            <v-card flat>
              <v-card-text>
                <Alertas ref="aler" />
              </v-card-text>
            </v-card>
          </v-tab-item>

          <v-tab-item :key="11" :value="'tab-11'"
           v-if="mostrarVista('mantenimiento-historial') || mostrarVista('mantenimiento-inspecciones')">
            <v-card flat>
              <v-card-text>
                <StatusMantenimientos ref="statusMant" />
              </v-card-text>
            </v-card>
          </v-tab-item>

        </v-tabs-items>
      </v-card>
    </v-row>
  <!-- </v-container> -->
</template>

<script>
import { mapState } from 'vuex';
import Alertas from './Mantenimiento/Alertas.vue';
import BloqueoMaquinas from './Mantenimiento/BloqueoMaquinas.vue';
import CalendarioMantenimientos from './Mantenimiento/CalendarioMantenimientos.vue';
import Calendario from "./Mantenimiento/CalendarioPreventivo.vue";
import Ciclos from './Mantenimiento/Ciclos.vue';
import ListaCatalogos from './Mantenimiento/ListaCatalogos.vue';
import ListaFacturas from './Mantenimiento/ListaFacturas.vue';
import MaquinasUso from "./Mantenimiento/MaquinasUso.vue"
import StatusMantenimientos from './Mantenimiento/StatusMantenimientos.vue';
import Tablero from './Mantenimiento/Tablero.vue';
import ListaMoldes from "./Mantenimiento/ListaMoldes.vue";

export default {
  data() {
    return {
      tab: null,
      text:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat."
    };
  },
  methods:{
    updateCatalogoMantenimientos(){
      if(typeof this.$refs.catalogosmantenimiento === 'undefined') return;
      this.$refs.catalogosmantenimiento.initialize();
    },
    updateTablero(){
      if(typeof this.$refs.board === 'undefined') return;
      this.$refs.board.initialize();
    },
    updateMantenimientoCalendario(){
      if(typeof this.$refs.mantenimientocalendario === 'undefined') return;
      this.$refs.mantenimientocalendario.initialize();
    },
    updateMantenimientoHistorial(){
      if(typeof this.$refs.realizados === 'undefined') return;
      this.$refs.realizados.initialize();
    },
    updateMantenimientoEnCurso(){
      if(typeof this.$refs.mantenimientocurso === 'undefined') return;
      this.$refs.mantenimientocurso.initialize();
    },
    updateMantenimientoFacturas(){
      if(typeof this.$refs.facturasMantenimiento === 'undefined') return;
      this.$refs.facturasMantenimiento.initialize();
    },
    updateAlertasMantenimientos(){
      if(typeof this.$refs.aler === 'undefined') return;
      this.$refs.aler.initialize();
    },
    updateBloqueoMantenimientos(){
      if(typeof this.$refs.bloq === 'undefined') return;
      this.$refs.bloq.initialize();
    },
    clickCiclos(){
      if(typeof this.$refs.cycles === 'undefined') return;
      this.$refs.cycles.initialize();
    },
    clickStatusMants(){
      if(typeof this.$refs.statusMant === 'undefined') return;
      this.$refs.statusMant.initialize();
    },
    updateMoldes() {
      if (typeof this.$refs.listadoMoldes === "undefined") return;
      this.$refs.listadoMoldes.initialize();
    }
  },
  computed: {
    ...mapState(["anchoMaximo","colorTabs","modoAyuda", 'moldes'])
  },
  components: {
    MantenimientoCatalogos: ListaCatalogos,
    MantenimientoCalendario: Calendario,
    MantenimientoFacturas: ListaFacturas,
    MantenimientoMaquinasUso: MaquinasUso,
    Ciclos,
    Alertas,
    BloqueoMaquinas,
    StatusMantenimientos,
    Tablero,
    CalendarioMantenimientos,
    ListaMoldes
  }
};
</script>