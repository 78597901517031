import { VAutocomplete } from 'vuetify/lib/components/VAutocomplete';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VProgressCircular } from 'vuetify/lib/components/VProgressCircular';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSnackbar } from 'vuetify/lib/components/VSnackbar';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';
import { VToolbar } from 'vuetify/lib/components/VToolbar';
import { VTooltip } from 'vuetify/lib/components/VTooltip';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VContainer,[[_c(VRow,{attrs:{"justify":"center"}},[_c(VDialog,{key:_vm.eliminar,attrs:{"persistent":"","max-width":"500px"},model:{value:(_vm.eliminar),callback:function ($$v) {_vm.eliminar=$$v},expression:"eliminar"}},[_c(VCard,[_c(VCardTitle,{staticClass:"headline"},[_vm._v("¿Está seguro que desea eliminar este tipo?")]),_c(VCardText,[_vm._v("Esta acción no se puede revertir y será permanente.")]),_c(VCardActions,[_c(VSpacer),_c(VBtn,{attrs:{"color":"blue darken-1","text":""},on:{"click":function($event){_vm.eliminar = false;
                _vm.delItem = '';}}},[_vm._v("Cancelar")]),_c(VBtn,{attrs:{"color":"red darken-1","text":""},on:{"click":function($event){_vm.eliminar = false;
                _vm.deleteItemDB();}}},[_vm._v("Aceptar")])],1)],1)],1)],1)],_c(VDialog,{attrs:{"persistent":"","max-width":"60%"},model:{value:(_vm.dialogOrdenar),callback:function ($$v) {_vm.dialogOrdenar=$$v},expression:"dialogOrdenar"}},[_c(VCard,[_c(VCardTitle,[_vm._v("Ordenar")]),_c(VCardText,[_c(VContainer,[_c(VRow,[_c(VCol,{attrs:{"cols":"12","md":"12","sm":"12"}},[(_vm.permisoVista('almacen', 'u'))?_c('draggable',_vm._b({staticClass:"list-group",attrs:{"tag":"ul"},on:{"start":function($event){_vm.drag = true},"end":function($event){_vm.drag = false}},model:{value:(_vm.tiposOrden),callback:function ($$v) {_vm.tiposOrden=$$v},expression:"tiposOrden"}},'draggable',_vm.dragOptions,false),[_c('transition-group',{attrs:{"type":"transition","name":!_vm.drag ? 'flip-list' : null}},_vm._l((_vm.tiposOrden),function(element){return _c(VCard,{key:element.idArticuloTipo,attrs:{"disabled":element.idArticuloTipo === 3}},[_c(VCardText,[_c(VIcon,[_vm._v(" mdi-menu ")]),_vm._v(" "+_vm._s(element.nombre)+" ")],1)],1)}),1)],1):_vm._e()],1)],1)],1)],1),_c(VCardActions,{staticClass:"d-flex justify-center"},[(_vm.permisoVista('areas', 'u'))?_c(VBtn,{staticClass:"mt-0",attrs:{"color":"primary","disabled":_vm.savingOrdenar},on:{"click":_vm.cancelarOrdenar}},[_vm._v(" Cancelar ")]):_vm._e(),(_vm.permisoVista('areas', 'u'))?_c(VBtn,{staticClass:"mt-0",attrs:{"color":"error","disabled":_vm.savingOrdenar,"loading":_vm.savingOrdenar},on:{"click":_vm.saveOrder}},[_vm._v(" Guardar Orden ")]):_vm._e()],1)],1)],1),(_vm.permisoVista('almacen', 'r'))?_c(VDataTable,{attrs:{"headers":_vm.headers,"items":_vm.tipos,"search":_vm.search,"loading":_vm.loading,"loading-text":"Cargando... Espere, Por Favor."},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c(VToolbar,{attrs:{"flat":"","color":"white"}},[_c(VDialog,{key:_vm.dialog,attrs:{"persistent":"","max-width":"500px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                var on = ref.on;
return [(_vm.permisoVista('almacen', 'c'))?_c(VBtn,_vm._g({staticClass:"mb-2",attrs:{"color":"primary","dark":""}},on),[_vm._v("Nuevo Tipo")]):_vm._e()]}}],null,false,688775819),model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c(VCard,[_c(VCardTitle,[_c('span',{staticClass:"headline"},[_vm._v(_vm._s(_vm.formTitle))])]),_c(VCardText,[_c(VContainer,[_c(VRow,[_c(VCol,{attrs:{"justify":"center","cols":"12","sm":"12","md":"12"}},[_c(VTextField,{attrs:{"label":"Nombre del Tipo"},model:{value:(_vm.editedItem.nombre),callback:function ($$v) {_vm.$set(_vm.editedItem, "nombre", $$v)},expression:"editedItem.nombre"}})],1),_c(VCol,{attrs:{"justify":"center","cols":"12","sm":"12","md":"12"}},[_c(VTextField,{attrs:{"label":"Descripción del Tipo"},model:{value:(_vm.editedItem.descripcion),callback:function ($$v) {_vm.$set(_vm.editedItem, "descripcion", $$v)},expression:"editedItem.descripcion"}})],1),(
                      _vm.editedItem.idArticuloTipo >= 4 ||
                      typeof _vm.editedItem.idArticuloTipo === 'undefined'
                    )?_c(VCol,{attrs:{"justify":"center","cols":"12","sm":"12","md":"12"}},[_c(VAutocomplete,{attrs:{"items":_vm.sn,"label":"¿Require de una Receta?"},model:{value:(_vm.editedItem.receta),callback:function ($$v) {_vm.$set(_vm.editedItem, "receta", $$v)},expression:"editedItem.receta"}})],1):_vm._e(),(
                      (_vm.editedItem.idArticuloTipo >= 4 ||
                        typeof _vm.editedItem.idArticuloTipo === 'undefined') &&
                      _vm.editedItem.receta == false
                    )?_c(VCol,{attrs:{"justify":"center","cols":"12","sm":"12","md":"12"}},[_c(VAutocomplete,{attrs:{"items":_vm.sn,"label":"¿Es parte de la produccion?"},model:{value:(_vm.editedItem.produccion),callback:function ($$v) {_vm.$set(_vm.editedItem, "produccion", $$v)},expression:"editedItem.produccion"}})],1):_vm._e()],1),_c('ul',_vm._l((_vm.errores),function(ex){return _c('li',{key:ex,staticClass:"red--text"},[_vm._v(" "+_vm._s(ex)+" ")])}),0)],1)],1),_c(VCardActions,[_c(VSpacer),_c(VBtn,{attrs:{"color":"blue darken-1","text":""},on:{"click":function($event){_vm.dialog = false;
                  _vm.errores = [];}}},[_vm._v("Cancelar")]),_c(VBtn,{attrs:{"color":"red darken-1","text":"","disabled":_vm.saving,"loading":_vm.saving},on:{"click":function($event){return _vm.save()}},scopedSlots:_vm._u([{key:"loader",fn:function(){return [_c(VProgressCircular,{attrs:{"indeterminate":"","width":2,"size":24,"color":"red"}})]},proxy:true}],null,false,3597384639)},[_vm._v(" Guardar ")])],1)],1)],1),_c(VSpacer),_c(VTextField,{attrs:{"append-icon":"search","label":"Buscar","single-line":"","hide-details":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),_c('div',{staticClass:"d-flex justify-end"},[_c(VBtn,{directives:[{name:"show",rawName:"v-show",value:(_vm.permisoVista('almacen', 'u')),expression:"permisoVista('almacen', 'u')"}],staticClass:"mt-0",attrs:{"color":"primary"},on:{"click":function($event){_vm.dialogOrdenar = true}}},[_c(VIcon,{attrs:{"left":""}},[_vm._v("mdi-sort")]),_vm._v("Ordenar ")],1)],1)]},proxy:true},{key:"item.action",fn:function(ref){
                  var item = ref.item;
return [_c(VTooltip,{staticClass:"white--text",attrs:{"bottom":"","color":"black"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                  var on = ref.on;
return [(_vm.permisoVista('almacen', 'u'))?_c(VIcon,_vm._g({staticClass:"mr-2",attrs:{"small":""},on:{"click":function($event){return _vm.editItem(item)}}},on),[_vm._v(" edit ")]):_vm._e()]}}],null,true)},[_c('span',{staticClass:"white--text"},[_vm._v("Editar")])]),_c(VTooltip,{staticClass:"white--text",attrs:{"bottom":"","color":"black"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                  var on = ref.on;
return [(item.idArticuloTipo > 3 && _vm.permisoVista('almacen', 'd'))?_c(VIcon,_vm._g({attrs:{"small":""},on:{"click":function($event){return _vm.deleteItem(item)}}},on),[_vm._v(" delete ")]):_vm._e()]}}],null,true)},[_c('span',{staticClass:"white--text"},[_vm._v("Eliminar")])])]}},{key:"no-data",fn:function(){return [_c(VBtn,{attrs:{"color":"primary"},on:{"click":_vm.initialize}},[_vm._v("Refrescar")])]},proxy:true}],null,false,479365803)}):_vm._e(),_c(VSnackbar,{attrs:{"top":"","color":"error"},model:{value:(_vm.alerta),callback:function ($$v) {_vm.alerta=$$v},expression:"alerta"}},[_vm._v(" Existe un Tipo Activo con el mismo nombre "),_c(VBtn,{attrs:{"text":""},on:{"click":function($event){_vm.alerta = false}}},[_vm._v(" Cerrar ")])],1)],2)}
var staticRenderFns = []

export { render, staticRenderFns }