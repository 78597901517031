<template>
	<v-col cols="12" v-if="permisoVista('ordenesventas','r')">
		<v-row>
			<v-col cols="12">
				<v-data-table
					:items="ordenesIncompletas"
					:headers="headersOrdenesIncompletas"
					:loading="loading"
				>
					<template v-slot:item.articulos="{ item }">
						<p v-if="item.articulos.length == 0" class="mb-0">
							N/A
						</p>
						<v-simple-table v-else class="transparent my-1">
							<template v-slot:default>
								<thead>
									<th class="text-center"></th>
									<th class="text-center"></th>
								</thead>
								<tbody>
									<tr
										v-for="articulo in item.articulos"
										:key="articulo.idArticulo"
										class="transparent"
									>
										<td>
											{{ getNombreArticulo(articulo) }}
										</td>
										<td class="text-left">
											{{
												`${
													articulo.cantidadEntregada == 0
														? articulo.cantidadEntregada
														: articulo.cantidadEntregada.toFixed(2)
												} ${$utils.strings.pluralizar(
													articulo.unidad,
													articulo.cantidadEntregada
												)}`
											}}
											{{
												` de ${
													articulo.cantidad == 0
														? articulo.cantidad
														: articulo.cantidad.toFixed(2)
												} ${$utils.strings.pluralizar(
													articulo.unidad,
													articulo.cantidad
												)}`
											}}
										</td>
									</tr>
								</tbody>
							</template>
						</v-simple-table>
					</template>
				</v-data-table>
			</v-col>
		</v-row>
	</v-col>
</template>

<script>
import axios from "axios";
export default {
	data() {
		return {
			TemplateordenesIncompletas: [],
			headersOrdenesIncompletas: [
				{
					text: "Clave",
					align: "center",
					value: "clave",
				},
				{
					text: "Cliente",
					align: "center",
					value: "cliente",
				},
				{
					text: "Artículos",
					align: "center",
					value: "articulos",
				},
				{
					text: "Fecha de Compromiso",
					align: "center",
					value: "fechaCompromiso",
				},
			],
			loading: false,
		};
	},
	mounted() {
		this.initialize();
	},
	methods: {
		getNombreArticulo: (articulo) =>
			articulo.codigo != null
				? `[${articulo.codigo}] - ${articulo.nombre}`
				: articulo.nombre,
		initialize() {
			this.getOrdenesVentaIncompletas();
		},
		getOrdenesVentaIncompletas() {
			this.loading = true;
			axios
				.get("/Inventario/OrdenVentaPublico")
				.then((response) => {
					this.ordenesIncompletas = response.data;
					this.loading = false;
				})
				.catch((error) => {
					this.loading = false;
					console.log(error);
				});
		},
	},
};
</script>