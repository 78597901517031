<template>
	<v-col cols="12">
		<v-dialog v-model="dialogDesglosar" max-width="85%" persistent>
			<v-card class="pb-6 pt-5">
				<v-card-title class="pb-2 pt-2">
					Desglosar Movimiento
				</v-card-title>
				<v-card-text>
					<v-row>
						<v-col cols="12" md="12" sm="12">
							<div class="d-flex">
								<v-btn
									color="primary"
									@click="showDialogHistorial"
									:loading="loadingHistorial"
									:disabled="loadingHistorial || savingDesglosar"
									>Seleccionar Mantenimientos</v-btn
								>
								<v-btn
									v-if="movimientoDesglose.payload!== undefined"
									color="primary"
									class="ml-auto"
									@click="distribuirTotal"
									:disabled="loadingHistorial || savingDesglosar"
									>Distribuir {{ parseFloat(movimientoDesglose.cantidadFaltante).toFixed(2) }} {{movimientoDesglose.unidad}} </v-btn
								>

							</div>
							<v-data-table
								:items="movimientoDesglose.payload"
								:headers="headersDesglose"
								:footer-props="{
									disableItemsPerPage: true,
									disablePagination: this.loading,
								}"
								:disable-sort="true"
                hide-default-footer
                :items-per-page="-1"
							>
								<template
									v-slot:item.cantidad="{ index }"
								>
									<v-text-field
										v-model="cantidades[index]"
										:rules="[
											cantidadRule(cantidades[index]),
										]"
										class="centered-input"
										@blur="
											convert3D(cantidades[index], index)
										"
									></v-text-field>
								</template>
								<template
									v-slot:item.porcentaje="{ index }"
								>
									<!-- <v-text-field
										v-model="porcentajes[index]"
										:rules="[
											numberRule(porcentajes[index]),
										]"
										class="centered-input"
										suffix="%"
										@blur="
											convert2D(porcentajes[index], index)
										"
									></v-text-field> -->
									{{  porcentajes[index] }} %
								</template>
								
							</v-data-table>
						</v-col>
					</v-row>
					
					<ul class="d-flex">
						<li
							class="red--text"
							v-for="(error, idx) in errores"
							:key="'error' + idx"
						>
							{{ error }}
						</li>
						<div
							v-if="movimientoDesglose.payload!== undefined"
							:class="[errores.length > 0 ? ( totality !== 0 ? 'orange--text' : 'black--text' ):'green--text']"
							class="ml-auto"
							:key="'error' + idx"
						>
							SUMATORIA:  {{ (totality).toFixed(2) }} {{movimientoDesglose.unidad}}
						</div>
						
					</ul>
				</v-card-text>
				<v-card-actions>
					<v-spacer></v-spacer>
					<v-btn
						color="red"
						@click="closeDesglosar"
						:disabled="savingDesglosar"
						>Cancelar</v-btn
					>
					<v-btn
						color="green"
						:disabled="
							savingDesglosar ||
							!cantidadesValidos
						"
						:loading="savingDesglosar"
						@click="guardarDesglosar"
					>
						Guardar
						<template v-slot:loader>
              <v-progress-circular
                indeterminate
                :width="2"
                :size="24"
                color="red"
              ></v-progress-circular>
            </template>
					</v-btn>
				</v-card-actions>
			</v-card>
		</v-dialog>
		<v-dialog v-model="dialogHistorial" max-width="90%" persistent>
			<v-card>
				<v-toolbar color="primary" dark>
					<v-btn icon dark @click="cerrarDialogHistorial">
						<v-icon>mdi-close</v-icon>
					</v-btn>

					<v-toolbar-title style="color: white"
						>Seleccionar Mantenimientos</v-toolbar-title
					>

					<v-spacer></v-spacer>

					<v-btn dark text @click="clickGuardarSelected">
						Seleccionar
					</v-btn>
				</v-toolbar>
				<v-card-text>
					<Historial ref="historyMan" :seleccionable="true" />
				</v-card-text>
			</v-card>
		</v-dialog>
		<v-data-table
			:items="sinDesglosar"
			:headers="headersSinDesglosar"
			:loading="loading"
			loading-text="Cargando.. Por favor espere"
		>
			<template v-slot:item.articulo="{ item }">
				[{{item.articuloCodigo}}] - {{ item.articulo }}
			</template>
      <template v-slot:item.cantidad="{ item }">
				{{ item.cantidad.toFixed(2) }} {{ item.unidad }}
			</template>
      <template v-slot:item.costoUnitario="{ item }">
				$ {{item.costoUnitario.toFixed(2) }}
			</template>
      <template v-slot:item.costoTotal="{ item }">
				$ {{item.costoTotal.toFixed(2) }}
			</template>
			<template v-slot:item.actions="{ item }">
				<v-tooltip
					v-if="permisoVista('movimientos-sindesglosar', 'c') && permisoVista('movimientos-sindesglosar', '$')"
					
					bottom
					color="black"
					class="white--text"
				>
					<template v-slot:activator="{ on }">
						<v-icon v-on="on" small @click="desglosarMovimiento(item)">
							mdi-file-tree
						</v-icon>
					</template>
					<span class="white--text">Desglosar</span>
				</v-tooltip>
			</template>
		</v-data-table>
	</v-col>
</template>

<script>
import axios from "axios";
import moment from "moment";
import Historial from "./Historial.vue";

export default {
	components: { Historial },
	data: () => ({
		totality: 0,
		errores: [],
		porcentajes: [],
		porcentajesValidos: false,
		cantidades: [],
		cantidadesValidos: false,
		loadingHistorial: false,
		movimientoDesglose: [
			{
				idMovimientoDetalle: 0,
				cantidadFaltante: 0,
				payload: [],
        unidad:"",
			},
		],
		defaultmovimientoDesglose: [
			{
				idMovimientoDetalle: 0,
				cantidadFaltante: 0,
				payload: [],
        unidad:"",
			},
		],
		dialogHistorial: false,
		savingDesglosar: false,
		dialogDesglosar: false,
		loading: false,
		sinDesglosar: [],
		cantidad: null,
		headersSinDesglosar: [
			{
				text: "Artículo",
				align: "center",
				sortable: false,
				value: "articulo",
			},
			{
				text: "Código QR",
				align: "center",
				sortable: false,
				value: "codigo",
			},
			{
				text: "Cantidad",
				align: "center",
				sortable: false,
				value: "cantidad",
			},
      {
				text: "Costo Unitario",
				align: "center",
				sortable: false,
				value: "costoUnitario",
			},
			{
				text: "Costo Total",
				align: "center",
				sortable: false,
				value: "costoTotal",
			},
			{
				text: "Acciones",
				align: "center",
				sortable: false,
				value: "actions",
			},
		],
		headersDesglose: [
			{
				text: "Tipo",
				align: "center",
				sort: false,
				value: "mantenimientoTipo",
			},
			{
				text: "Código",
				align: "center",
				sort: false,
				value: "mantenimientoCodigo",
			},
			{
				text: "Nombre",
				align: "center",
				sort: false,
				value: "mantenimiento",
			},
			{
				text: "Inicio",
				align: "center",
				sort: false,
				value: "inicioRealizo",
			},
			{
				text: "Termino",
				align: "center",
				sort: false,
				value: "finRealizo",
			},
			{
				text: "Máquina",
				align: "center",
				sort: false,
				value: "maquina",
			},
			{
				text: "Máquina Instancia",
				align: "center",
				sort: false,
				value: "maquinaIns",
			},
			{
				text: "Cantidad",
				align: "center",
				sort: false,
				value: "cantidad",
			},
			{
				text: "Porcentaje",
				align: "center",
				sort: false,
				value: "porcentaje",
			},
		],
		numberRule: (num) => {
			if (!isNaN(parseFloat(num)) && num > 0 && num <= 100) return true;
			return "Invalido.";
		},
		cantidadRule: (num) => {
			if (!isNaN(parseFloat(num)) && num > 0) return true;
			return "Invalido.";
		},
	}),
	created() {
		this.initialize();
		if(!this.permisoVista('movimientos-sindesglosar', '$')) this.headersSinDesglosar = this.headersSinDesglosar.filter(x => x.value != 'costoUnitario' && x.value != 'costoTotal');
	},
	watch: {
		// porcentajes() {
		// 	if (this.movimientoDesglose !== undefined) {
		// 		if (this.movimientoDesglose.payload !== undefined) {
		// 			this.validarPorcentajes();
		// 		}
		// 	}
		// },
		cantidades() {
			if (this.movimientoDesglose !== undefined) {
				if (this.movimientoDesglose.payload !== undefined) {
					this.validarCantidades();
				}
			}
		},
	},
	methods: {
		convert2D(num, index) {
			if (this.numberRule(num) === true) {
				this.porcentajes[index] = parseFloat(num).toFixed(2);
				// this.validarPorcentajes();
			}
		},
		convert3D(num, index) {
			if (this.cantidadRule(num) === true) {
				this.cantidades[index] = parseFloat(num).toFixed(2);
				this.validarCantidades();
			}
		},
		validarPorcentajes() {
			this.errores = [];
			let porciento = 0;
			this.porcentajes.forEach((porcentaje) => {
				if (this.numberRule(porcentaje) === true) {
					porciento += parseFloat(porcentaje);
				}
			});
			if (porciento === 100) this.porcentajesValidos = true;
			else {
				this.porcentajesValidos = false;
				this.errores.push("Los porcentajes no suman el 100%");
			}
		},
		validarCantidades() {
			this.errores = [];
			this.calcularSumatoria();
			if (this.movimientoDesglose.cantidadFaltante === this.totality)
				this.cantidadesValidos = true;
			else {
				this.cantidadesValidos = false;
				this.errores.push(
					`La SUMATORIA no es igual al TOTAL (${(this.movimientoDesglose.cantidadFaltante).toFixed(2)} ${(this.movimientoDesglose.unidad)}) de la movimiento.`
				);
			}
		},
		calcularSumatoria(){
			this.totality = 0;
			this.cantidades.forEach((cantidad,index) => {
				if (this.cantidadRule(cantidad) === true) {
					this.porcentajes[index] = this.calcularPorcentaje(this.movimientoDesglose.cantidadFaltante,parseFloat(cantidad));
					this.totality += parseFloat(cantidad);
				}
			});
			this.totality = parseFloat(parseFloat(this.totality).toFixed(2));
		},
		lblcodNom: (item) =>
			item !== null &&
			`[${item.mantenimientoCodigo}] - ${item.mantenimiento}`,
		showDialogHistorial() {
			this.loadingHistorial = true;
			setTimeout(() => {
				this.dialogHistorial = true;
			}, 250);
		},
		clickGuardarSelected() {
			const items = this.$refs.historyMan.clickSeleccionados();
			this.movimientoDesglose.payload = items.map((mantenimiento) => ({
				...mantenimiento,
				fechaAplicacion: null,
				porcentaje: 0,
				cantidad: 0,
			}));
			this.dialogHistorial = false;
			this.loadingHistorial = false;
			this.totality = 0;
			this.porcentajes = [];
			this.cantidades = [];
			this.errores = [];
			this.porcentajesValidos = false;
			this.cantidadesValidos = false;
		},
		calcularPorcentaje(total,cantidad){
			return ((cantidad*100)/total).toFixed(2);
		},
		distribuirTotal(){
			this.totality = 0;
			this.cantidades =[];
			this.porcentajes = [];
			this.movimientoDesglose.payload.forEach((item, index) => {
				this.cantidades[index] = (this.movimientoDesglose.cantidadFaltante / this.movimientoDesglose.payload.length).toFixed(2);
				this.porcentajes[index] = this.calcularPorcentaje(this.movimientoDesglose.cantidadFaltante,this.cantidades[index]);
			});
			this.calcularSumatoria();
			const residuo = parseFloat(this.movimientoDesglose.cantidadFaltante) - parseFloat(this.totality);
			if ( residuo !== 0) this.cantidades[0] = (parseFloat(this.cantidades[0]) + parseFloat(parseFloat(residuo).toFixed(2))).toFixed(2);  
			setTimeout(() => {
				// this.validarPorcentajes();
				this.validarCantidades();
			}, 250);
		},
		initialize() {
			this.totality = 0;
			this.porcentajes = [];
			this.cantidades = [];
			this.errores = [];
			this.porcentajesValidos = false;
			this.cantidadesValidos = false;
			this.getSinDesglosar();
		},
		getSinDesglosar() {
			this.loading = true;
			axios
				.get("/Mantenimientos/MovimientosSinDesglosar")
				.then((response) => {
          this.sinDesglosar = response.data;
					this.loading = false;
				})
				.catch((error) => console.log(error));
		},
		desglosarMovimiento(item) {
			this.movimientoDesglose.idMovimientoDetalle = item.idMovimientoDetalle;
			this.movimientoDesglose.cantidadFaltante = item.cantidadFaltante;
			this.movimientoDesglose.unidad = item.unidad;
      this.dialogDesglosar = true;
		},
		cerrarDialogHistorial() {
			this.dialogHistorial = false;
			setTimeout(() => {
				this.$refs.historyMan.resetSeleccionados();
			}, 250);
			this.loadingHistorial = false;
		},
		closeDesglosar() {
			this.totality = 0;
			this.porcentajes = [];
			this.cantidades = [];
			this.errores = [];
			this.porcentajesValidos = false;
			this.cantidadesValidos = false;
			this.dialogDesglosar = false;
			setTimeout(() => {
				if(this.$refs.historyMan !== undefined)
					this.$refs.historyMan.fullresetSeleccionados();
			}, 250);
			this.movimientoDesglose = Object.assign(
				[],
				this.defaultmovimientoDesglose
			);
		},
		guardarDesglosar() {
			const fechaNow = moment().format("yyyy-MM-DD HH:mm");

			const payload = this.movimientoDesglose.payload.map(
				(mantenimiento, index) => ({
					idMantenimientoTarea: mantenimiento.idMantenimientoTarea,
					fechaAplicacion: fechaNow,
					porcentaje: parseFloat(this.porcentajes[index]),
					cantidad: parseFloat(this.cantidades[index]),
				})
			);
			this.savingDesglosar = true;

			axios
				.post(`/Mantenimientos/DesglosarMovimiento/${this.movimientoDesglose.idMovimientoDetalle}`,
					payload
				)
				.then(() => {
					this.closeDesglosar();
					this.initialize();
					this.savingDesglosar = false;
				})
				.catch((error) => {
					this.savingDesglosar = false;
					console.log(error);
				});
		},
	},
};
</script>
<style scoped>
.centered-input >>> input {
	text-align: center !important;
}
</style>
