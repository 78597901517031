import { VCol } from 'vuetify/lib/components/VGrid';
import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSimpleTable } from 'vuetify/lib/components/VDataTable';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.permisoVista('ordenesventas','r'))?_c(VCol,{attrs:{"cols":"12"}},[_c(VRow,[_c(VCol,{attrs:{"cols":"12"}},[_c(VDataTable,{attrs:{"items":_vm.ordenesIncompletas,"headers":_vm.headersOrdenesIncompletas,"loading":_vm.loading},scopedSlots:_vm._u([{key:"item.articulos",fn:function(ref){
var item = ref.item;
return [(item.articulos.length == 0)?_c('p',{staticClass:"mb-0"},[_vm._v(" N/A ")]):_c(VSimpleTable,{staticClass:"transparent my-1",scopedSlots:_vm._u([{key:"default",fn:function(){return [_c('thead',[_c('th',{staticClass:"text-center"}),_c('th',{staticClass:"text-center"})]),_c('tbody',_vm._l((item.articulos),function(articulo){return _c('tr',{key:articulo.idArticulo,staticClass:"transparent"},[_c('td',[_vm._v(" "+_vm._s(_vm.getNombreArticulo(articulo))+" ")]),_c('td',{staticClass:"text-left"},[_vm._v(" "+_vm._s(((articulo.cantidadEntregada == 0 ? articulo.cantidadEntregada : articulo.cantidadEntregada.toFixed(2)) + " " + (_vm.$utils.strings.pluralizar( articulo.unidad, articulo.cantidadEntregada ))))+" "+_vm._s((" de " + (articulo.cantidad == 0 ? articulo.cantidad : articulo.cantidad.toFixed(2)) + " " + (_vm.$utils.strings.pluralizar( articulo.unidad, articulo.cantidad ))))+" ")])])}),0)]},proxy:true}],null,true)})]}}],null,false,2072199258)})],1)],1)],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }