import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VRow } from 'vuetify/lib/components/VGrid';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VDialog,{attrs:{"max-width":"1000px"},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c(VCard,[_c(VCardText,{staticClass:"text-center"},[_c('br'),_c('br'),_c('div',{staticClass:"text-h5"},[_vm._v(_vm._s(_vm.message))]),_c('br'),(_vm.subMessage != null)?_c('div',{staticClass:"text-h6"},[_vm._v(_vm._s(_vm.subMessage))]):_vm._e(),_c('br'),_c(VRow,[_c(VCol,{attrs:{"cols":"6","md":"6","sm":"6"}},[_c(VBtn,{attrs:{"x-large":"","color":"green"},on:{"click":function($event){return _vm.answer(true)}}},[_vm._v("Si")])],1),_c(VCol,{attrs:{"cols":"6","md":"6","sm":"6"}},[_c(VBtn,{attrs:{"x-large":"","color":"red"},on:{"click":function($event){return _vm.answer(false)}}},[_vm._v("No")])],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }