<!-- -->
<template>
  <!-- <v-container> -->
    <v-row justify-sm="center">
      <v-card 
        class="mt-4"
        :width="anchoMaximo"
        fill
      >
        <v-tabs show-arrows v-model="tab" background-color="blue lighten-2 accent-4" centered dark icons-and-text>
          <v-tabs-slider></v-tabs-slider>

          <v-tab href="#tab-1" v-if="mostrarVista('dashboard') && !mqtt">
            Dashboard
            <v-icon>mdi-chart-box</v-icon>
          </v-tab>

          <v-tab href="#tab-2" v-if="!mqtt">
            Reportes<br/>Dinámicos
            <v-icon>mdi-file-chart</v-icon>
          </v-tab>

          <v-tab href="#tab-3" v-if="!mqtt">
            Analítica<br/>de Datos 
            <v-icon>mdi-chart-multiple</v-icon>
          </v-tab>

          <v-tab href="#tab-4" v-if="mqtt">
            Reporte<br>MQTT
            <v-icon>mdi-chart-multiple</v-icon>
          </v-tab>

          <!-- <v-tab href="#tab-4">
            Reportes de<br/>almacén 
            <v-icon>mdi-warehouse</v-icon>
          </v-tab>

          <v-tab href="#tab-5">
            Reportes<br/>de RH 
            <v-icon>mdi-account-details</v-icon>
          </v-tab>

          <v-tab href="#tab-6">
            Reportes de<br/>producción 
            <v-icon>mdi-chart-histogram</v-icon>
          </v-tab> -->
          
        </v-tabs>

        <v-tabs-items v-model="tab" touchless>
          <!--<v-tab-item :key="1" :value="'tab-1'" v-if="mostrarVista('maquinascomparativa')">-->

          <v-tab-item v-if="!mqtt" :key="1" :value="'tab-1'" >
            <v-card flat>
              <v-card-text class="pt-0">
                <Dashboard :tipo="1" />
              </v-card-text>
            </v-card>
          </v-tab-item>

          <v-tab-item v-if="!mqtt" :key="2" :value="'tab-2'" >
            <v-card flat>
              <v-card-text class="pa-0">
                <ReportesDelDia  />
              </v-card-text>
            </v-card>
          </v-tab-item>

          <v-tab-item  v-if="!mqtt" :key="3" :value="'tab-3'" >
            <Dashboard :tipo="2" />
          </v-tab-item>

          <v-tab-item v-if="mqtt" :key="4" :value="'tab-4'" >
            <ReporteMonterrey />
          </v-tab-item>

        </v-tabs-items>
      </v-card>
    </v-row>
  <!-- </v-container> -->
</template>

<script>
import { mapState } from 'vuex';
import Dashboard from './Dashboard.vue';
import ReportesDelDia from './Reportes/ReportesDelDia.vue';
import ReporteMonterrey from "./Reportes/ReporteMonterrey.vue"

export default {
  data() {
    return {
      tab: null,
    };
  },
  computed: {
    ...mapState(["anchoMaximo","mqtt"])
  },
  methods:{
    
  },
  components: {
    Dashboard,
    ReportesDelDia,
    ReporteMonterrey: ReporteMonterrey
  }
};
</script>