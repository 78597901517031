<template>
  <v-col cols="12">

    <v-dialog
          
          v-model="dialogMaquinas"
          persistent
          max-width="680px"
          
        >
          <v-card>
            <v-card-title>Máquinas Dependientes</v-card-title>
            <v-card-text class="py-0 px-2">
              <v-container>
                <v-row>
                  <v-col cols="12">
                    <div v-if="
                    loadingMaquinasInde &&
                        JSON.stringify(dependientes) == '[]'" class="d-flex justify-center">
                          <v-progress-circular
                            :size="70"
                            :width="7"
                            color="green"
                            indeterminate
                          ></v-progress-circular>
                          <br/>
                          <br/>
                          <br/>
                          <br/>
                          <h1>Cargando</h1>
                        </div>
                    <v-simple-table
                      v-if="
                        dependientes.length > 0 &&
                        JSON.stringify(dependientes) !== '[]'
                      "
                    >
                      <template v-slot:default>
                        <thead>
                          <tr>
                            <th class="text-left" style="font-size: 16px">
                              Artículo
                            </th>
                            <th class="left" style="font-size: 16px">Codigo</th>
                            <th class="left" style="font-size: 16px">Area</th>
                            <th class="left" style="font-size: 16px">Tipo</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr
                            v-for="(item, x) in dependientes"
                            :key="`dep-${x}`"
                          >
                            <td>
                              {{
                                item.articulo
                              }}
                            </td>
                            <td>
                              {{ item.codigo }}
                            </td>
                            <td>
                              {{ item.area }}
                            </td>
                            <td>
                              {{ item.tipo }}
                            </td>
                            <!-- <td>
                              <v-switch
                                v-model="item.critico"
                                color="success"
                                v-tooltip.bottom="item.critico ? 'Desactivar' : 'Activar'"
                                :disabled="!enableSwitchArticDep"
                                @change="changeSwitchArticuloDependiente(item,$event)"
                              ></v-switch>
                            </td> -->
                          </tr>
                        </tbody>
                      </template>
                    </v-simple-table>
                    <div
                      v-if="
                        !loadingMaquinasInde &&
                        dependientes.length === 0 &&
                        JSON.stringify(dependientes) === '[]'
                      "
                      class="text-center text-h6"
                    >
                      Este artículo no se emplea en ninguna Receta.
                    </div>
                  </v-col>
                </v-row>
              </v-container>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="green darken-1" text @click="dialogMaquinas = false">
                Cerrar
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>

    <template>
      <v-dialog v-model="eliminar" persistent max-width="500px" :key="eliminar">
        <v-card>
          <v-card-title class="headline">¿Está seguro que desea eliminar esta máquina?</v-card-title>
          <v-card-text>Esta acción no se puede revertir y será permanente.</v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="blue darken-1" text @click="eliminar = false; delItem = '';">Cancelar</v-btn>
            <v-btn color="red darken-1" text @click="eliminar = false; deleteItemDB()">Aceptar</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </template>
    <template>
        <v-dialog v-model="view" persistent max-width="500px" :key="view">
          <v-card>
            <v-card-title class="headline">Detalles de {{viewedItem.marca}} - {{viewedItem.modelo}}</v-card-title>
            <v-card-text>
                <v-container>
                  <v-row>
                    <v-col justify="center" cols="12" sm="12" md="12">
                      <v-autocomplete
                        v-model="viewedItem.esMaquina"
                        label="Tipo"
                        :items="tipos"
                        item-value="value"
                        item-text="name"
                        readonly
                      >
                      </v-autocomplete>
                    </v-col>
                    <v-col justify="center" cols="6" sm="6" md="6">
                      <v-text-field readonly v-model="viewedItem.marca" label="Marca de la Máquina"></v-text-field>
                    </v-col>
                    <v-col justify="center" cols="6" sm="6" md="6">
                      <v-text-field readonly v-model="viewedItem.modelo" label="Modelo de la Máquina"></v-text-field>
                    </v-col>
                    <v-subheader>Número de Personas para Operar esta {{tipoLabel}}</v-subheader>
                    <v-col justify="center" cols="12" sm="12" md="12">
                      <br>
                      <v-slider
                        v-model="viewedItem.numOperadores"
                        min="0"
                        max="10"
                        readonly
                        thumb-label="always"
                      >
                      </v-slider>
                    </v-col>
                    <v-col justify="center" cols="12" sm="12" md="12">
                      <v-text-field readonly v-model="viewedItem.descripcionGeneral" label="Descripción General de la Máquina"></v-text-field>
                    </v-col>
                    <v-col justify="center" cols="12" sm="12" md="12">
                      <v-text-field readonly v-model="viewedItem.descripcionTecnica" label="Descripción Técnica de la Máquina"></v-text-field>
                    </v-col>
                    <v-col justify="center" cols="12" sm="12" md="12" v-if="permisoVista('cat-maquinas','$')">
                      <v-text-field readonly type="number" v-model="viewedItem.costoHora" :label="'Costo Por Hora de la '+tipoLabel"></v-text-field>
                    </v-col>
                    <v-col justify="center" cols="12" sm="12" md="12">
                      <v-autocomplete
                        v-model="viewedItem.habilidades"
                        :items="habilidades"
                        label="Habilidades"
                        item-text="nombre"
                        chips
                        multiple
                        readonly
                        item-value="idHabilidad"
                      ></v-autocomplete>
                    </v-col>
                    <!-- <v-col cols="3" md="3" sm="3">
                        <v-text-field
                          v-model="viewedItem.capacidadMaximaProduccion"
                          label="Cantidad"
                          readonly
                        ></v-text-field>
                      </v-col>
                      <v-col cols="3" md="3" sm="3">
                        <v-autocomplete
                          v-model="viewedItem.idUnidadProduccion"
                          label="Unidad"
                          :items="unidades"
                          item-value="idUnidad"
                          item-text="abreviatura"
                          readonly
                        ></v-autocomplete>
                      </v-col>
                      <v-col cols="3" md="3" sm="3" class="text-center" style="align-self: center !important;">
                        cada
                      </v-col>
                      <v-col cols="3" md="3" sm="3">
                        <v-autocomplete
                          v-model="viewedItem.idTiempoUnidad"
                          label="Tiempo"
                          :items="tiempoUnidades"
                          item-value="idTiempoUnidad"
                          item-text="abreviatura"
                          readonly
                        ></v-autocomplete>
                      </v-col> -->
                    <v-expansion-panels cols="12" sm="12" md="12" multiple accordion>
                      <v-expansion-panel>
                        <v-expansion-panel-header>
                          Operarios
                        </v-expansion-panel-header>
                        <v-expansion-panel-content>
                           <v-simple-table>
                              <thead>
                                <tr>
                                  <th class="text-center">Nombre</th>
                                  <th class="text-center"># Habilidades</th>
                                  <th class="text-center">Porcentaje</th>
                                </tr>
                              </thead>
                              <tbody>
                                <tr v-for="persona in viewedItem.personas" :key="persona.idPersona">
                                  <td class="text-center">{{ getNombreCompleto(persona.idPersona)  }}</td>
                                  <td class="text-center">{{ persona.nHabilidades }}</td>
                                  <td class="text-center">{{ (persona.hRequeridas > 0) ? ((persona.nHabilidades/persona.hRequeridas)*100).toFixed(2) : "100.00" }}%</td>
                                </tr>
                              </tbody>
                          </v-simple-table>
                        </v-expansion-panel-content>
                      </v-expansion-panel>
                    </v-expansion-panels>
                  </v-row>
                </v-container>
              </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="red darken-1" text @click="view = false ">Aceptar</v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
    </template>
    <v-data-table
      v-if="permisoVista('cat-maquinas','r')"
      :headers="headers"
      :items="maquinas"
      :search="search"
      :loading="loading"
      loading-text="Cargando... Espere, Por Favor."
    >
      <template v-slot:top>
        <v-toolbar flat color="white">
          <v-dialog v-model="dialog" persistent max-width="500px" :key="dialog">
            <template v-slot:activator="{ on }">
              <v-btn color="primary" dark class="mb-2" v-on="on" v-if="permisoVista('cat-maquinas','c')">Nuevo modelo</v-btn>
            </template>
            <v-card>
              <v-card-title>
                <span class="headline">{{ formTitle }}</span>
              </v-card-title>
              <v-card-text>
                <v-container>
                  <v-row>
                    <v-col justify="center" cols="12" sm="12" md="12">
                      <v-autocomplete
                        v-model="editedItem.esMaquina"
                        label="Tipo"
                        :items="tipos"
                        item-value="value"
                        item-text="name"
                      >
                      </v-autocomplete>
                    </v-col>
                    <v-col class="d-flex" cols="11" sm="11">
                      <v-autocomplete
                        v-model="editedItem.idMaquinaCategoria"
                        :items="categorias"
                        label="Categoría"
                        item-text="nombre"
                        :disabled="loadingCategoria"
                        :loading="loadingCategoria"
                        item-value="idMaquinaCategoria"
                      ></v-autocomplete>
                    </v-col>
                    <v-icon
                      class="mr-2"
                      :disabled="loadingCategoria"
                      @click="getCategorias()"
                    >
                      mdi-reload
                    </v-icon>
                    <v-col justify="center" cols="6" sm="6" md="6">
                      <v-text-field v-model="editedItem.marca" :label="'Marca de la '+tipoLabel"></v-text-field>
                    </v-col>
                    <v-col justify="center" cols="6" sm="6" md="6">
                      <v-text-field v-model="editedItem.modelo" :label="'Modelo de la '+tipoLabel"></v-text-field>
                    </v-col>
                    <v-subheader>Número de Personas para Operar esta {{tipoLabel}}</v-subheader>
                    <v-col justify="center" cols="12" sm="12" md="12">
                      <br>
                      <v-slider
                        v-model="editedItem.numOperadores"
                        min="0"
                        max="10"
                        thumb-label="always"
                      >
                      </v-slider>
                    </v-col>
                    <v-col justify="center" cols="12" sm="12" md="12">
                      <v-text-field v-model="editedItem.descripcionGeneral" :label="'Descripción General de la '+tipoLabel"></v-text-field>
                    </v-col>
                    <v-col justify="center" cols="12" sm="12" md="12">
                      <v-text-field v-model="editedItem.descripcionTecnica" :label="'Descripción Técnica de la '+tipoLabel"></v-text-field>
                    </v-col>
                    <v-col justify="center" cols="12" sm="12" md="12" v-if="permisoVista('cat-maquinas','$')">
                      <v-text-field type="number" v-model="editedItem.costoHora" :label="'Costo Por Hora de la '+tipoLabel"></v-text-field>
                    </v-col>
                    <v-col class="d-flex" cols="11" sm="11">
                      <v-autocomplete
                        v-model="editedItem.habilidades"
                        :items="habilidades"
                        label="Habilidades"
                        item-text="nombre"
                        chips
                        multiple
                        :disabled="loadingHabilidades"
                        :loading="loadingHabilidades"
                        item-value="idHabilidad"
                      ></v-autocomplete>
                    </v-col>
                    <v-icon
                      class="mr-2"
                      :disabled="loadingHabilidades"
                      @click="getHabilidades()"
                    >
                      mdi-reload
                    </v-icon>
                      <!-- <v-col cols="3" md="3" sm="3">
                        <v-text-field
                          v-model="editedItem.capacidadMaximaProduccion"
                          label="Cantidad"
                          clearable
                        ></v-text-field>
                      </v-col>
                      <v-col cols="3" md="3" sm="3">
                        <v-autocomplete
                          v-model="editedItem.idUnidadProduccion"
                          :items="unidades"
                          label="Unidad"
                          item-text="abreviatura"
                          :disabled="loadingUnidades"
                          :loading="loadingUnidades"
                          item-value="idUnidad"
                        ></v-autocomplete>
                      </v-col>
                      <v-col cols="3" md="3" sm="3" class="text-center" style="align-self: center !important;">
                        cada
                      </v-col>
                      <v-col cols="3" md="3" sm="3">
                        <v-autocomplete
                          v-model="editedItem.idTiempoUnidad"
                          :items="tiempoUnidades"
                          label="Tiempo"
                          item-text="abreviatura"
                          :disabled="loadingTiempo"
                          :loading="loadingTiempo"
                          item-value="idTiempoUnidad"
                        ></v-autocomplete>
                      </v-col> -->
                  </v-row>
                  <ul>
                    <li class="red--text" v-for="ex in errores" :key="ex">{{ ex }}</li>
                  </ul>
                </v-container>
              </v-card-text>

              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="blue darken-1" text @click="dialog=false; errores=[];">Cancelar</v-btn>
                <v-btn color="red darken-1" text @click="save()" :disabled="saving" :loading="saving">
                  Guardar
                  <template v-slot:loader>
                    <v-progress-circular
                      indeterminate
                      :width="2"
                      :size="24"
                      color="red"
                    ></v-progress-circular>
                  </template>
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
          <v-spacer></v-spacer>
          <v-text-field
            v-model="search"
            append-icon="search"
            label="Buscar"
            single-line
            hide-details
          ></v-text-field>
        </v-toolbar>
      </template>
      <template v-slot:[`item.action`]="{ item }">
        <v-tooltip bottom color="black" class="white--text">
          <template v-slot:activator="{ on }">
        <v-icon v-on="on"
          v-if="permisoVista('cat-maquinas','r')"
          small
          class="mr-2"
          @click="showDialogMaquinas(item)"
        >
          mdi-file-tree-outline
        </v-icon>
         </template>
          <span class="white--text">Maquinas dependientes</span>
        </v-tooltip>
        <v-tooltip bottom color="black" class="white--text">
          <template v-slot:activator="{ on }">
        <v-icon v-on="on"
          v-if="permisoVista('cat-maquinas','r')"
          small
          class="mr-2"
          @click="viewItem(item)"
        >
          mdi-eye
        </v-icon>
         </template>
          <span class="white--text">Ver detalles</span>
        </v-tooltip>
        <v-tooltip bottom color="black" class="white--text">
          <template v-slot:activator="{ on }">
        <v-icon v-on="on"
          v-if="permisoVista('cat-maquinas','u')"
          small
          class="mr-2"
          @click="editItem(item)"
        >
          edit
        </v-icon>
         </template>
          <span class="white--text">Editar</span>
        </v-tooltip>
        <v-tooltip bottom color="black" class="white--text">
          <template v-slot:activator="{ on }">
        <v-icon v-on="on"
          v-if="permisoVista('cat-maquinas','d')"
          small
          @click="deleteItem(item)"
        >
          delete
        </v-icon>
         </template>
          <span class="white--text">Eliminar</span>
        </v-tooltip>
      </template>
      <template v-slot:no-data>
        <v-btn color="primary" @click="initialize">Refrescar</v-btn>
      </template>
    </v-data-table>
    <!-- ******************* -->
    <v-dialog
      v-model="msgerror"
      max-width="290"
    >
      <v-card>
        <div class="d-flex justify-center">
          <v-icon size="90" color="red">mdi-alert-octagon</v-icon>
        </div>
        <v-card-subtitle class="pb-n5 mb-n5"><p class="pb-n1 mb-1 font-weight-black">Error</p></v-card-subtitle>
        <v-card-text class="pb-n8 mb-n8">
          Ya existe un registro con los mismo criterios ingresados.
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="blue darken-1"
            text
            @click="msgerror = false"
          >
            Entendido
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- ******************* -->
  </v-col>
</template>

<script>
import axios from "axios";

  export default {
    data: () => ({
      loadingMaquinasInde: false,
      dependientes:[],
      dialogMaquinas: false,
      tiempoUnidades: [],
      unidades: [],
      loadingTiempo: false,
      loadingUnidades: false,
      saving: false,
      tipos:[
        {
          name:'Máquina',
          value:true
        },
        {
          name:'Herramienta',
          value:false
        }
      ],
      loadingHabilidades: false,
      habilidades: [],
      loadingOperador: false,
      personas: [],
      alerta:false,
      dialog: false,
      eliminar: false,
      view: false,
      search: '',
      headers: [
        {
          text: 'Tipo',
          align: 'left',
          sortable: true,
          value: 'tipo',
        },
        {
          text: 'Categoría',
          align: 'left',
          sortable: true,
          value: 'categoria',
        },
        {
          text: 'Marca',
          align: 'left',
          sortable: true,
          value: 'marca',
        },
        {
          text: 'Modelo',
          align: 'left',
          sortable: true,
          value: 'modelo',
        },
        {
          text: 'Descripción General',
          align: 'left',
          sortable: true,
          value: 'descripcionGeneral',
        },
        { text: 'Acciones', value: 'action', sortable: false, width:'25%', align:'center' },
      ],
      loading: false,
      maquinas: [],
      delItem: '',
      action: 0,
      errores: [],
      viewedIndex: -1,
      viewedItem: {
        marca:'',
        modelo:'',
        descripcionGeneral:'',
        descripcionTecnica:'',
        fechaFabricacion:'',
        fechaCompra:'',
        esMaquina:true,
        numOperadores:0,
        costoHora:0.0,
        habilidades: [],
        capacidadMaximaProduccion: 0,
        idUnidadProduccion: 0,
        idTiempoUnidad: 0
      },
      editedIndex: -1,
      editedItem: {
        idMaquinaCategoria:null,
        marca:'',
        modelo:'',
        descripcionGeneral:'',
        descripcionTecnica:'',
        fechaFabricacion:'',
        fechaCompra:'',
        esMaquina:true,
        numOperadores:0,
        costoHora:0.0,
        habilidades: [],
        capacidadMaximaProduccion: 0,
        idUnidadProduccion: 0,
        idTiempoUnidad: 0
      },
      defaultItem: {
        idMaquinaCategoria:null,
        marca:'',
        modelo:'',
        descripcionGeneral:'',
        descripcionTecnica:'',
        fechaFabricacion:'',
        fechaCompra:'',
        esMaquina:true,
        numOperadores:0,
        costoHora:0.0,
        habilidades: [],
        capacidadMaximaProduccion: 0,
        idUnidadProduccion: 0,
        idTiempoUnidad: 0
      },
      categorias: [],
      loadingCategoria: false,
      msgerror: false
    }),

    computed: {
      formTitle () {
        return this.editedIndex === -1 ? 'Crear Nuevo Modelo' : 'Editar Modelo';
      },

      tipoLabel(){
        if(this.view){
          return  this.viewedItem.esMaquina?'Máquina' : 'Herramienta';
        }
        if(this.dialog){
          return  this.editedItem.esMaquina?'Máquina' : 'Herramienta';
        }
      }
    },

    watch: {
      dialog (val) {
        val || this.close()
      },
    },

    created () {
      this.initialize();
    },
    methods: {
      showDialogMaquinas(item){
        this.dialogMaquinas = true;
        this.editedIndex = this.maquinas.indexOf(item)
        this.editedItem = Object.assign({}, item)
        this.getMaquinasLista()
      },
      getUnidades(){
        this.loadingUnidades = true;
        axios
          .get( '/Unidades' )
          .then( response =>  {
            this.unidades = response.data;
            this.loadingUnidades = false;
          })
          .catch( error => {
            this.loadingUnidades = false;
            console.log( error );
          })
      },
      getTiempos(){
        this.loadingTiempo = true;
        axios
          .get( '/TiempoUnidades' )
          .then( response =>  {
            this.tiempoUnidades = response.data;
            this.loadingTiempo = false;
          })
          .catch( error => {
            this.loadingTiempo = false;
            console.log( error );
          })
      },
      getNombreCompleto(idPersona){
        var o = this.personas.find(x => x.idPersona == idPersona);
        if(o != null) return o.nombre+" "+o.paterno+" "+o.materno;
        else return "";
      },
      getOperadores (){
        let me = this;
        me.loadingOperador = true;
        axios
        .get("/Personas/OperadoresBasico")
        .then(response =>  {
          me.personas = response.data;
          me.loadingOperador = false;
        })
        .catch(error => {
          console.log(error);
        });
      },
      getCategorias(){
        this.loadingCategoria = true;
        axios
        .get("/MaquinaCategorias")
        .then(response =>  {
          response.data.push({
            idMaquinaCategoria: null,
            nombre: "SIN ASIGNAR",
            descripcion:"",
            condicion: true,
          });
          this.categorias = response.data;
          this.loadingCategoria = false;
        })
        .catch(error => {
          console.log(error);
        });
      },
      getHabilidades (){
        let me = this;
        me.loadingHabilidades = true;
        axios
        .get("/Habilidades")
        .then(response =>  {
          me.habilidades = response.data;
          me.loadingHabilidades = false;
          /* 
          ssss
          */
        })
        .catch(error => {
          console.log(error);
        });
      },
      initialize () {
        let me = this;
        me.loading = true;
        me.getHabilidades();
        me.getOperadores();
        me.getCategorias();
        me.getUnidades();
        me.getTiempos();
        axios
        .get("/Maquinas")
        .then(response =>  {
          me.maquinas = response.data;
          me.loading = false;
        })
        .catch(error => {
          console.log(error);
        });
      },
      viewItem (item) {
        this.viewedIndex = this.maquinas.indexOf(item)
        this.viewedItem = Object.assign({}, item)
        this.view = true
      },
      editItem (item) {
        this.action = 1;
        this.editedIndex = this.maquinas.indexOf(item)
        this.editedItem = Object.assign({}, item)
        this.dialog = true
      },

      deleteItem (item) {
        this.eliminar = true;
        this.delItem = item;
      },
      deleteItemDB(){
        let me = this;
        let item = this.delItem;
        this.delItem = '';
        axios
          .put("/Maquinas/Desactivar/" + item.idMaquina)
          .then(response =>  {
            me.initialize();
          })
          .catch(error => {
            console.log(error);
          });
      },
      close () {
        this.action = 0,
        this.dialog = false
        setTimeout(() => {
          this.editedItem = Object.assign({}, this.defaultItem)
          this.editedIndex = -1
        }, 300)
      },
      makeValidation(string, min, max, msgError){
        if (string.length < min || string.length > max)
        {
            return msgError;
        }
        else return null;
      },
      validate(){
        this.errores =  [];

        var val = null;

        val = this.makeValidation(this.editedItem.marca,2,50,"La marca debe tener al menos 2 caracteres y menos de 50.");
        if(val != null) this.errores.push(val);

        val = this.makeValidation(this.editedItem.modelo,1,50,"El modelo debe tener al menos 1 caracteres y menos de 50.");
        if(val != null) this.errores.push(val);

        if(this.editedItem.esMaquina == -1){
          this.errores.push('Debe de seleccionar si es una Máquina o Herramienta');
        }

        if(this.editedItem.idMaquinaCategoria <= 0 || this.editedItem.idMaquinaCategoria == null){
          this.errores.push('Debe de seleccionar una Categoría');
        }

        if(this.editedItem.numOperadores == -1){
          this.errores.push('Debe indicar el número de trabajadores para operar esta Máquina/Herramienta');
        }

        // if( isNaN(parseFloat(this.editedItem.capacidadMaximaProduccion)) || this.editedItem.capacidadMaximaProduccion <= 0){
        //   this.errores.push('Debe introducir una Cantidad valida.');
        // }

        // if(this.editedItem.idUnidadProduccion <= 0){
        //   this.errores.push('Debe seleccionar el tipo de Unidad.');
        // }

        // if(this.editedItem.idTiempoUnidad <= 0){
        //   this.errores.push('Debe seleccionar la unidad de Tiempo.');
        // }

        return this.errores.length == 0;
      },
      getMaquinasLista(){
        axios
        .get("/Maquinas/MaquinaDependientes/"+this.editedItem.idMaquina)
        .then((response) => {
          this.dependientes = response.data;
          this.loadingMaquinasInde = false;
        })
        .catch((error) => {
          this.loadingMaquinasInde = false;
          console.log(error);
        });
      },
      save () {
        this.action = 0;
        if(this.validate()){
          let me = this;
          me.saving = true;
          if (this.editedIndex > -1)
          {
            //Código para editar
            axios
              .put("/Maquinas/Actualizar/"+me.maquinas[me.editedIndex].idMaquina, {
                IdMaquinaCategoria: me.editedItem.idMaquinaCategoria,
                Marca: me.editedItem.marca,
                Modelo: me.editedItem.modelo,
                EsMaquina: me.editedItem.esMaquina,
                NumOperadores: me.editedItem.numOperadores,
                DescripcionGeneral: me.editedItem.descripcionGeneral,
                DescripcionTecnica:  me.editedItem.descripcionTecnica,
                CostoHora: me.editedItem.costoHora,
                Habilidades: me.editedItem.habilidades,
                // capacidadMaximaProduccion: parseFloat(me.editedItem.capacidadMaximaProduccion),
                // idUnidadProduccion: me.editedItem.idUnidadProduccion,
                // idTiempoUnidad: me.editedItem.idTiempoUnidad
              })
              .then(() =>  {
                me.saving = false;
                me.close();
                me.initialize();
              })
               .catch(error => {
                me.saving = false;
                me.msgerror=true;
                console.log(error);
              });
          }
          else
          {
            axios
              .post("/Maquinas/Crear", {
                IdMaquinaCategoria: me.editedItem.idMaquinaCategoria,
                Marca: me.editedItem.marca,
                Modelo: me.editedItem.modelo,
                EsMaquina: me.editedItem.esMaquina,
                NumOperadores: me.editedItem.numOperadores,
                DescripcionGeneral: me.editedItem.descripcionGeneral,
                DescripcionTecnica:  me.editedItem.descripcionTecnica,
                CostoHora: me.editedItem.costoHora,
                Habilidades: me.editedItem.habilidades,
                // capacidadMaximaProduccion: parseFloat(me.editedItem.capacidadMaximaProduccion),
                // idUnidadProduccion: me.editedItem.idUnidadProduccion,
                // idTiempoUnidad: me.editedItem.idTiempoUnidad
              })
              .then(() =>  {
                me.saving = false;
                me.close();
                me.initialize();
              })
              .catch(error => {
                me.saving = false;
                me.msgerror=true;
                console.log(error);
              });
          }
        }
      },
    },
  }
</script>