<template>
    <div>
      <v-col cols="12" class="pt-0">
        <v-row>
          <v-col cols="3">
            <datePicker
              v-model="filtros.desde"
              format="YYYY-MM-DD"
              :maxDate="filtros.hasta"
              label="Desde (Obligatorio)"
              clearable
              maxToday
              @input="getInfo()"
              :disabled="cargando"
            ></datePicker>
          </v-col>
          <v-col cols="3">
            <datePicker
              v-model="filtros.hasta"
              format="YYYY-MM-DD"
              :minDate="filtros.desde"
              label="Hasta (Obligatorio)"
              clearable
              @input="getInfo()"
              :disabled="cargando"
            ></datePicker>
          </v-col>
          <v-col cols="6" class="text-right">
            <v-btn
              color="primary"
              :disabled="disabledBtnRefresh"
              @click="getInfo()"
            >
              <v-icon left>mdi-refresh</v-icon> Refrescar
            </v-btn>
          </v-col>
          <v-col cols="12" md="12" sm="12">
            <v-row class="d-flex justify-center">
              <v-col cols="12" md="3" sm="12">
                <v-card class="fill-height d-flex">
                  <v-card-text class="text-center align-self-center">
                    <div class="subtitle-1 font-weight-bold">
                      Kilogramos Usados
                    </div>
                    <!-- :endVal="estadisticas.montoTotalEstimadoProduccion" -->
                    <ICountUp
                      v-if="loadingEstadisticas"
                      :delay="1000"
                      :endVal="this.apex.kgUtilizados"
                      class="subtitle-1 font-weight-medium"
                      :options="{
                        decimalPlaces: 2,
                        separator: ',',
                        decimal: '.',
                        prefix: '',
                      }"
                    />
                    <div v-else class="text-center">
                      <v-progress-circular
                        :size="25"
                        color="primary"
                        indeterminate
                      ></v-progress-circular>
                    </div>
                  </v-card-text>
                </v-card>
              </v-col>
              <v-col cols="12" md="3" sm="12">
                <v-card class="fill-height d-flex">
                  <v-card-text class="text-center align-self-center">
                    <div class="subtitle-1 font-weight-bold">
                      Cantidad de Laminas
                    </div>
                    <!-- :endVal="estadisticas.montoTotalEstimadoProduccion" -->
                    <ICountUp
                      v-if="loadingEstadisticas"
                      :delay="1000"
                      :endVal="this.apex.cantidadLaminas"
                      class="subtitle-1 font-weight-medium"
                      :options="{
                        decimalPlaces: 2,
                        separator: ',',
                        decimal: '.',
                        prefix: '',
                      }"
                    />
                    <div v-else class="text-center">
                      <v-progress-circular
                        :size="25"
                        color="primary"
                        indeterminate
                      ></v-progress-circular>
                    </div>
                  </v-card-text>
                </v-card>
              </v-col>
              <v-col cols="12" md="3" sm="12">
                <v-card class="fill-height d-flex">
                  <v-card-text class="text-center align-self-center">
                    <div class="subtitle-1 font-weight-bold">
                      Piezas Fabricadas
                    </div>
                    <ICountUp
                      v-if="loadingEstadisticas"
                      :delay="1000"
                      :endVal="this.apex.pzaFabricadas"
                      class="subtitle-1 font-weight-medium"
                      :options="{
                        decimalPlaces: 2,
                        separator: ',',
                        decimal: '.',
                        prefix: '',
                      }"
                    />
                    <div v-else class="text-center">
                      <v-progress-circular
                        :size="25"
                        color="primary"
                        indeterminate
                      ></v-progress-circular>
                    </div>
                  </v-card-text>
                </v-card>
              </v-col>
            </v-row>
          </v-col>
          <v-col cols="12" md="12" sm="12" v-if="cargando">
            <v-progress-linear
              color="primary"
              height="5"
              indeterminate
            ></v-progress-linear>
          </v-col>
          <v-col v-else cols="12" md="12" sm="12">
            <!-- <apexchart
              type="bar"
              height="350"
              :options="chartOptions"
              :series="series"
            ></apexchart> -->
            <apexchart
              type="bar"
              height="500"
              :options="chartOptions"
              :series="series"
            ></apexchart>
          </v-col>
        </v-row>
      </v-col>
    </div>
  </template>
    
    <script>
  import axios from "axios";
  import ICountUp from "vue-countup-v2";
  /* 
    import { mapState } from "vuex"; */
  /* import DownloadBtn from "../../../DownloadBtn.vue"; */
  
  export default {
    components: {
      ICountUp,
    },
    data() {
      return {
        loadingEstadisticas: true,
        cargando: false,
        filtros: {
          desde: null,
          hasta: null,
        },
        categories: [],
        series: [],
        loadingMaquinas: false,
        apex: {
          kgUtilizados: 0,
          cantidadLaminas: 0,
          pzaFabricadas: 0,
        },
        colors: [
          '#008FFB',
          '#00E396',
          '#FEB019',
          '#FF4560',
          '#775DD0',
          '#546E7A',
          '#26a69a',
          '#D10CE8'
        ],
      };
    },
    mounted() {
      this.initialize();
    },
    computed: {
      disabledBtnRefresh(){
        return this.cargando || Object.values(this.filtros).some(value => value == null);
      },
      chartOptions() {
        return {
          colors: this.colors,
          states: {
            active: {
              allowMultipleDataPointsSelection: false,
            },
          },
          chart: {
            type: "bar",
            height: 500,
            barWidth: '10',
            stacked: true,
            toolbar: {
              show: true,
            },
            zoom: {
              enabled: true,
            },
          },
          plotOptions: {
            bar: {
              horizontal: false,
              columnWidth: "100%",
              dataLabels: {
                total: {
                  enabled: true,
                  style: {
                    fontSize: "13px",
                    fontWeight: 900,
                  },
                },
              },
            },
          },
          dataLabels: {
            enabled: false,
            formatter: function (val) {
              return val.toFixed(2)+' Kg';
            }
          },
          stroke: {
            show: true,
            width: 2,
            colors: ["transparent"],
          },
          xaxis: {
            categories: this.categories,
             labels: {
              maxHeight: 200,
            }
          },
          yaxis: {
            labels: {
              formatter: function (val) {
                return val.toFixed(2)+' Kg';
              }
            }
          },
          fill: {
            opacity: 1,
          },
          tooltip: {
            y: {
              formatter: function (val) {
                return val.toFixed(2)+' Kg';
              },
            },
          },
        };
      },
    },
    methods: {
      initialize() {
        this.getInfo();
      },
      getInfo() {  //Función que conecta con el end point para obtener datos a mostrar en tabla. 
        if (this.filtros.desde == null) return;
        if (this.filtros.hasta == null) return;

        this.cargando = true;
        this.loadingEstadisticas = false;
        axios
          .get(`/Reportes/ReporteGeneralFlujo/`, {
            params: {
                desde: this.filtros.desde,
                hasta: this.filtros.hasta,
            },
          })
          .then((response) => {
            this.categories = response.data.categorias;
            this.series = response.data.series;
            this.apex.kgUtilizados = response.data.kgUtilizados;
            this.apex.cantidadLaminas = response.data.cantidadLaminas;
            this.apex.pzaFabricadas = response.data.pzaFabricadas;

            this.cargando = false;
            this.loadingEstadisticas = true;
          })
          .catch((error) => {
            console.log(error);
            this.cargando = false;
            this.loadingEstadisticas = true;
          });
      },
    },
  };
  </script>