<template>
	<v-container fluid>
		<v-row class="justify-space-between align-end">
			<v-col cols="4">
				<DownloadBtn
					color="blue darken-1"
					text
					className="my-0"
					@click="descargarReporte()"
					:disabled="loading"
					label="Descargar Reporte de <br>M.P. por Entregar"
				/>
			</v-col>
			<v-col cols="4">
				<v-text-field
					v-model="search"
					label="Buscar"
					hide-details
					clearable
					prepend-inner-icon="mdi-magnify"
				></v-text-field>
			</v-col>
		</v-row>
		<v-row>
			<v-col cols="12">
				<v-data-table
					:items="data"
					:headers="dataHeaders"
					:loading="loading"
					:search="search"
				>
					<template v-slot:[`item.articuloProducir`]="{ item }">
						{{
							getCodigoNombre(
								item.articuloProducirCodigo,
								item.articuloProducirNombre
							)
						}}
					</template>
					<template v-slot:[`item.almacen`]="{ item }">
						{{ getCodigoNombre(item.codigoAlmacen, item.almacen) }}
					</template>
					<template v-slot:[`item.articulo`]="{ item }">
						{{
							getCodigoNombre(item.codigoArticulo, item.articulo)
						}}
					</template>
					<template v-slot:[`item.cantidadEntregar`]="{ item }">
						{{ item.cantidadEntregar.toFixed(2) }} {{ item.unidad }}
					</template>
					<template v-slot:[`item.cantidadSalida`]="{ item }">
						{{ item.cantidadSalida.toFixed(2) }} {{ item.unidad }}
					</template>
					<template v-slot:[`item.cantidadRestante`]="{ item }">
						{{ item.cantidadRestante.toFixed(2) }} {{ item.unidad }}
					</template>
				</v-data-table>
			</v-col>
		</v-row>
	</v-container>
</template>

<script>
import moment from "moment";
import DownloadBtn from "../../DownloadBtn.vue";
import { mapState } from "vuex";
import axios from "axios";

export default {
	components: { DownloadBtn },
	data() {
		return {
			search: null,
			data: [],
			dataHeaders: [
				{
					text: "OT",
					align: "center",
					value: "claveOT",
				},
				{
					text: "Artículo a Producir",
					align: "center",
					value: "articuloProducir",
				},
				{
					text: "Almacén",
					align: "center",
					value: "almacen",
				},
				{
					text: "Artículo",
					align: "center",
					value: "articulo",
				},
				{
					text: "Cantidad Entregar",
					align: "center",
					value: "cantidadEntregar",
				},
				{
					text: "Cantidad Salida",
					align: "center",
					value: "cantidadSalida",
				},
				{
					text: "Cantidad Restante",
					align: "center",
					value: "cantidadRestante",
				},
			],
			loading: false,
		};
	},
	mounted() {
		this.initialize();
	},
	computed: {
		...mapState(["token"]),
	},
	methods: {
		initialize() {
			this.getDatos();
		},
		descargarReporte() {
			var datetime = moment().format("YYYYMMDDHHmmss");
			this.$utils.axios.downloadFile(
				`/Reportes/ReporteMovimientoReservasXlsx?access_token=${this.token}`,
				"GET",
				"reporte-materiaprima-porentregar-" + datetime + ".xlsx",
				"application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
			);
		},
		getCodigoNombre: (code, name) => {
			let codeName = "";
			codeName += code ? `[${code}] - ` : "";
			codeName += name;
			return codeName.trim();
		},
		getDatos() {
			this.loading = true;

			axios
				.get("/Reportes/ReporteMovimientoReservas")
				.then((response) => {
					this.data = response.data;
				})
				.catch(console.log)
				.finally(() => {
					this.loading = false;
				});
		},
	},
};
</script>