<template>
	<v-col cols="12">
		<v-dialog v-model="dialog" max-width="800px" persistent>
			<v-card>
				<v-card-title
					>{{
						editedItem.idMantenimientoServicio == null
							? "Agregar"
							: tipoEdicion == 1 ? "Editar" : "Clonar"
					}}
					Servicio de Mantenimiento</v-card-title
				>
				<v-card-text>
					<v-row>
						<v-col cols="6" md="6" sm="6">
							<v-text-field
								v-model="editedItem.codigo"
								label="Código"
								:error-messages="erroresItem.errorCodigo"
								@focus="$set(erroresItem, 'errorCodigo', null)"
							></v-text-field>
						</v-col>
						<v-col cols="6" md="6" sm="6">
							<v-text-field
								v-model="editedItem.nombre"
								label="Nombre"
								:error-messages="erroresItem.errorNombre"
								@focus="$set(erroresItem, 'errorNombre', null)"
							></v-text-field>
						</v-col>
						<v-col cols="6" md="6" sm="6">
							<v-textarea
								v-model="editedItem.descripcion"
								label="Descripción"
								hide-details
								auto-grow
								rows="1"
							></v-textarea>
						</v-col>
						<v-col cols="6">
							<v-autocomplete
								v-model="editedItem.idMantenimientoCategoria"
								label="Categoria del Servicio"
								:items="categorias"
								item-text="nombre"
								item-value="idMantenimientoCategoria"
								clearable
								:disabled="loadingCategorias"
								:loading="loadingCategorias"
								:error-messages="erroresItem.errorCategoria"
								@focus="erroresItem.errorCategoria = null"
							></v-autocomplete>
						</v-col>
						<v-col cols="6" v-if="showSubdepto">
							<v-autocomplete
								v-model="editedItem.idSubdepartamento"
								label="Subdepartamento"
								:items="subdepartamentos"
								:item-text="nombreSubdepto"
								item-value="idSubdepartamento"
								clearable
								:error-messages="erroresItem.errorSubdepto"
								@focus="erroresItem.errorSubdepto = null"
							></v-autocomplete>
						</v-col>
					</v-row>
					<v-row>
						<v-col cols="3">
							<p class="text-center mb-0">Tipo de asignación</p>
							<v-radio-group
								v-model="editedItem.tipoAsignacion"
								column
								class="mt-0"
								hide-details
								@change="onChangeTipoAsignacion"
							>
								<v-radio
									label="Personas"
									class="mb-0 text-center"
									:value="1"
								></v-radio>
								<v-radio
									label="Proveedor"
									:value="2"
								></v-radio>
							</v-radio-group>
						</v-col>
						<template v-if="editedItem.tipoAsignacion == 1">
							<v-col cols="3">
								<p class="text-center">Personas requeridas</p>
								<div class="d-flex align-center justify-center">
									<number-input
										v-model="editedItem.numPersonas"
										controls
										inline
										size="small"
										:min="1"
										center
									></number-input>
								</div>
							</v-col>
							<v-col cols="6">
								<v-autocomplete
									v-model="editedItem.asignados"
									label="[Opcional] Asignar por defecto a"
									:items="personas"
									:item-text="nombrePersona"
									item-value="idPersona"
									multiple
									chips
									deletable-chips
									clearable
									:error-messages="erroresItem.errorAsignacionDefecto"
									@focus="$set(erroresItem, 'errorAsignacionDefecto', null)"
								></v-autocomplete>
							</v-col>
						</template>
						<template v-else-if="editedItem.tipoAsignacion == 2">
							<v-col cols="6">
								<v-autocomplete
									v-model="editedItem.idMantenimientoProveedor"
									label="[Opcional] Proveedor por defecto"
									:items="proveedores"
									:item-text="$utils.mantenimientos.getNombreProveedor"
									item-value="idMantenimientoProveedor"
									clearable
									hide-details
								></v-autocomplete>
							</v-col>
						</template>
						<ListadoPorHacer class="mt-5" ref="todo" :item="editedItem" />
					</v-row>
					<v-slide-y-transition>
						<v-row v-if="erroresComprobaciones.length > 0">
							<v-col cols="12">
								<ul>
									<li
										class="red--text"
										v-for="(
											error, idx
										) in erroresComprobaciones"
										:key="'error-' + idx"
									>
										{{ error }}
									</li>
								</ul>
							</v-col>
						</v-row>
					</v-slide-y-transition>
				</v-card-text>
				<v-card-actions>
					<v-spacer></v-spacer>
					<v-btn
						color="green"
						text
						:disabled="guardando"
						@click="closeServicio"
						>Cancelar</v-btn
					>
					<v-btn
						color="red"
						text
						:loading="guardando"
						:disabled="guardando"
						@click="guardarServicio"
						>{{ textButtonSave }}</v-btn
					>
				</v-card-actions>
			</v-card>
		</v-dialog>
		<v-dialog v-model="dialogEliminar" persistent max-width="500px">
			<v-card>
				<v-card-title style="word-break: break-word"
					>¿Está seguro que desea eliminar este servicio de
					mantenimiento?</v-card-title
				>
				<v-card-text
					>Esta acción no se puede revertir y será
					permanente.</v-card-text
				>
				<v-card-actions>
					<v-spacer></v-spacer>
					<v-btn color="green" text @click="closeEliminarServicio"
						>Cancelar</v-btn
					>
					<v-btn
						color="red darken-1"
						text
						@click="runRemoverServicio"
						:disabled="deleting"
						:loading="deleting"
					>
						Eliminar
						<template v-slot:loader>
							<v-progress-circular
								indeterminate
								:width="2"
								:size="24"
								color="red"
							></v-progress-circular>
						</template>
					</v-btn>
				</v-card-actions>
			</v-card>
		</v-dialog>
		<v-dialog v-model="dialogProgramarServicio" max-width="45%" persistent>
			<v-card>
				<v-card-title class="pa-4 pb-4 pt-5">
					Programar Servicio de Mantenimiento
				</v-card-title>
				<v-card-text>
					<v-row>
						<v-col cols="12">
							<HeaderInfoMantenimiento
								:items="[{...reportedItem}]"
								esServicio
								:headers="headersServicioProgramado"
							/>
						</v-col>
					</v-row>
					<v-row>
						<v-col cols="6">
							<MenuDatepicker
								v-model="reportedItem.fechaInicioPlaneado"
								label="Fecha de Inicio planeado"
								clearable
								:disabled="programming"
								:errorMessage="erroresItem.errorFechaInicio"
								@focus="erroresItem.errorFechaInicio = null"
							/>
						</v-col>
						<v-col cols="6">
							<v-autocomplete
								v-model="reportedItem.tiempoEstimado"
								label="Tiempo estimado"
								:items="bloquesTiempo"
								:disabled="programming"
								:error-messages="erroresItem.errorTiempoEstimado"
								@focus="
									$set(erroresItem, 'errorTiempoEstimado', null)
								"
							></v-autocomplete>
						</v-col>
					</v-row>
				</v-card-text>
				<v-card-actions>
					<v-spacer></v-spacer>
					<v-btn
						text
						color="success"
						@click="closeDialogProgramarServicio()"
						:disabled="programming"
						>Cancelar</v-btn
					>
					<v-btn
						text
						color="error"
						@click="programarServicio()"
						:disabled="programming"
						:loading="programming"
						>Aceptar</v-btn
					>
				</v-card-actions>
			</v-card>
		</v-dialog>
		<v-dialog
			v-model="dialogReportes"
			persistent
			max-width="700px"
		>
			<v-card>
				<v-card-title>Reporte {{ reportedItem.tipo == 1 ? 'Extemporáneo' : 'en Tiempo Real' }}</v-card-title>
				<v-card-text>
					<v-container fluid>
						<v-row>
							<v-col cols="12">
								<HeaderInfoMantenimiento
									:items="[{...reportedItem}]"
									esServicio
									:headers="headersServicioProgramado"
								/>
							</v-col>
							<v-col cols="12">
								<v-autocomplete
									v-model="reportedItem.responsable"
									label="Responsable"
									:items="personas"
									item-value="idPersona"
									:item-text="nombrePersona"
									:disabled="reporting"
									:error-messages="
										erroresItem.errorResponsable
									"
									@focus="
										$set(erroresItem, 'errorResponsable', null)
									"
								></v-autocomplete>
							</v-col>
							<AsignacionMantenimiento
								:tipoAsignacion.sync="reportedItem.tipoAsignacion"
								:mecanicos.sync="reportedItem.mecanicos"
								:idMantenimientoProveedor.sync="reportedItem.idMantenimientoProveedor"
								:error-mecanicos.sync="erroresItem.errorMecanicos"
								:error-proveedor.sync="erroresItem.errorProveedor"
								:disabled="reporting"
								:items="{personas, proveedores}"
							/>
							<template v-if="reportedItem.tipo == 1">
								<template v-if="esIndasa">
									<v-col cols="12">
										<v-text-field
											v-model="reportedItem.importeNeto"
											label="Importe Neto"
											type="number"
											hide-spin-buttons
											prefix="$"
											clearable
											:error-messages="erroresItem.errorImporteNeto"
											@focus="erroresItem.errorImporteNeto = null"
										></v-text-field>
									</v-col>
								</template>
								<v-col cols="12">
									<VueCtkDateTimePicker
										v-model="reportedItem.fechaInicio"
										label="Inicio"
										format="YYYY-MM-DD HH:mm"
										formatted="YYYY-MM-DD HH:mm"
										noHeader
										overlay
										buttonNowTranslation="Ahora"
										:error="erroresItem.errorFechaInicio != null"
										@input="
											resetErrorFecha(
												reportedItem,
												erroresItem,
												'fechaInicio',
												'errorFechaInicio'
											)
										"
									/>
									<v-slide-y-transition>
										<p
											v-if="erroresItem.errorFechaInicio"
											class="red--text pt-1 mb-0"
											style="font-size: 12px !important"
										>
											{{ erroresItem.errorFechaInicio }}
										</p>
									</v-slide-y-transition>
								</v-col>
								<v-col cols="12">
									<VueCtkDateTimePicker
										v-model="reportedItem.fechaFin"
										:min-date="reportedItem.fechaInicio"
										label="Termino"
										format="YYYY-MM-DD HH:mm"
										formatted="YYYY-MM-DD HH:mm"
										noHeader
										overlay
										buttonNowTranslation="Ahora"
										:error="erroresItem.errorFechaFin != null"
										@input="
											resetErrorFecha(
												reportedItem,
												erroresItem,
												'fechaFin',
												'errorFechaFin'
											)
										"
									/>
									<v-slide-y-transition>
										<p
											v-if="erroresItem.errorFechaFin"
											class="red--text pt-1 mb-0"
											style="font-size: 12px !important"
										>
											{{ erroresItem.errorFechaFin }}
										</p>
									</v-slide-y-transition>
								</v-col>
							</template>
						</v-row>
						<template v-if="reportedItem.tipo == 1">
							<v-row>
								<v-col cols="12" class="pa-0">
									<Comprobaciones
										v-if="dialogReportes"
										ref="checklist"
										:disabled="reporting"
										:tipoReporte="4"
										:idMantenimientoServicio="reportedItem.idMantenimientoServicio"
										:loadingComprobaciones.sync="loadingComprobaciones"
									/>
								</v-col>
							</v-row>
							<v-row>
								<v-col cols="12">
									<v-autocomplete-textarea
										v-if="dialogReportes"
										v-model="reportedItem.recomendacion"
										label="Descripción o Recomendación"
										outlined
										auto-grow
										rows="1"
										:items="recomendaciones"
										:loading="loadingRecomendaciones"
										:disabled="reporting"
										:error-message="erroresItem.errorRecomendacion"
										@click:refresh="getRecomendaciones"
										@update:error-message="erroresItem.errorRecomendacion = $event"
									></v-autocomplete-textarea>
								</v-col>
							</v-row>
						</template>
						<v-slide-y-transition>
							<v-row v-if="erroresComprobaciones.length > 0">
								<v-col cols="12">
									<ul>
										<li
											class="red--text"
											v-for="(
												error, idx
											) in erroresComprobaciones"
											:key="'error-' + idx"
										>
											{{ error }}
										</li>
									</ul>
								</v-col>
							</v-row>
						</v-slide-y-transition>
					</v-container>
				</v-card-text>
				<v-card-actions>
					<v-spacer></v-spacer>
					<v-btn
						text
						color="success"
						:disabled="reporting || loadingComprobaciones"
						@click="cerrarDialogReportes()"
						>Cerrar</v-btn
					>
					<v-btn
						text
						color="error"
						:disabled="reporting || loadingComprobaciones"
						:loading="reporting"
						@click="realizarReporte()"
						>Guardar</v-btn
					>
				</v-card-actions>
			</v-card>
		</v-dialog>
		<v-row class="justify-space-between">
			<v-col cols="4">
				<v-btn
					v-if="permisoVista('cat-mantenimientoservicio', 'c')"
					color="primary"
					:disabled="loading"
					@click="dialog = true"
					>Agregar Servicio</v-btn
				>
			</v-col>
			<v-col cols="4">
				<v-text-field
					v-model="search"
					label="Buscar"
					prepend-inner-icon="mdi-magnify"
					clearable
					:disabled="loading"
				></v-text-field>
			</v-col>
		</v-row>
		<v-data-table
			:items="servicios"
			:headers="headersServicios"
			:loading="loading"
			:search="search"
			loading-text="Cargando... Espere, Por Favor."
		>
			<template v-slot:[`item.actions`]="{ item }">
				<v-tooltip
					v-if="permisoVista('cat-mantenimientoservicio', 'c')"
					bottom
					color="black"
					class="white--text"
				>
					<template v-slot:activator="{ on }">
						<v-icon
							v-on="on"
							small
							:disabled="loading"
							class="mr-1"
							@click="editServicio(item,2)"
						>
							mdi-content-duplicate
						</v-icon>
					</template>
					<span class="white--text">Clonar</span>
				</v-tooltip>
				<v-tooltip
					v-if="permisoVista('cat-mantenimientoservicio', 'c')"
					bottom
					color="black"
					class="white--text"
				>
					<template v-slot:activator="{ on }">
						<v-icon
							v-on="on"
							small
							:disabled="loading"
							class="mr-1"
							@click="showDialogProgramarServicio(item)"
						>
							mdi-clock
						</v-icon>
					</template>
					<span class="white--text">Programar Servicio</span>
				</v-tooltip>
				<v-tooltip
					v-if="permisoVista('cat-mantenimientoservicio', 'c')"
					bottom
					color="black"
					class="white--text"
				>
					<template v-slot:activator="{ on }">
						<v-icon v-on="on" small :disabled="loading" class="mr-1" @click="showDialogReportes(item,1)">
							mdi-clipboard-text-play
						</v-icon>
					</template>
					<span class="white--text">Reporte Extemporáneo</span>
				</v-tooltip>
				<v-tooltip
					v-if="permisoVista('cat-mantenimientoservicio', 'c')"
					bottom
					color="black"
					class="white--text"
				>
					<template v-slot:activator="{ on }">
						<v-icon v-on="on" small :disabled="loading" class="mr-1" @click="showDialogReportes(item,2)">
							mdi-script-text-play
						</v-icon>
					</template>
					<span class="white--text">Reporte Tiempo Real</span>
				</v-tooltip>
				<v-tooltip
					v-if="permisoVista('cat-mantenimientoservicio', 'u')"
					bottom
					color="black"
					class="white--text"
				>
					<template v-slot:activator="{ on }">
						<v-icon v-on="on" small :disabled="loading" class="mr-1" @click="editServicio(item)">
							edit
						</v-icon>
					</template>
					<span class="white--text">Editar</span>
				</v-tooltip>
				<v-tooltip
					v-if="permisoVista('cat-mantenimientoservicio', 'd')"
					bottom
					color="black"
					class="white--text"
				>
					<template v-slot:activator="{ on }">
						<v-icon v-on="on" small :disabled="loading" @click="removeServicio(item)">
							delete
						</v-icon>
					</template>
					<span class="white--text">Eliminar</span>
				</v-tooltip>
			</template>
		</v-data-table>
	</v-col>
</template>

<script>
import axios from "axios";
import ListadoPorHacer from "./ListadoPorHacer.vue";
import Comprobaciones from "../Comprobaciones.vue";
import { mapState } from 'vuex';
import HeaderInfoMantenimiento from './HeaderInfoMantenimiento.vue';
import AsignacionMantenimiento from '../AsignacionMantenimiento.vue';

export default {
	components: { ListadoPorHacer, Comprobaciones, HeaderInfoMantenimiento, AsignacionMantenimiento },
	data() {
		return {
			search: null,
			loadingComprobaciones: false,
			loadingProveedores: false,
    		proveedores: [],
			loadingRecomendaciones: false,
			recomendaciones: [],
			proveedores: [],
			loadingCategorias: false,
    		categorias: [],
			tipoEdicion: 1,
			comprobacionKey: 0,
			personas: [],
			dialogReportes: false,
			reporting: false,
			erroresComprobaciones: [],
			programming: false,
			bloquesTiempo: [
				{
					value: 60,
					text: "1 Minuto",
				},
				{
					value: 120,
					text: "2 Minutos",
				},
				{
					value: 300,
					text: "5 Minutos",
				},
				{
					value: 600,
					text: "10 Minutos",
				},
				{
					value: 1800,
					text: "30 Minutos",
				},
				{
					value: 3600,
					text: "1 Hora",
				},
			],
			dialogProgramarServicio: false,
			reportedItem: null,
			erroresItem: {
				errorCodigo: null,
				errorNombre: null,
				errorSubdepto: null,
				errorTiempoEstimado: null,
				errorResponsable: null,
				errorMecanicos: null,
				errorFechaInicio: null,
				errorFechaFin: null,
				errorAsignacionDefecto: null,
				errorImporteNeto: null,
				errorRecomendacion: null,
				errorProveedor: null
			},
			subdepartamentos: [],
			guardando: false,
			dialog: false,
			editedItem: null,
			defaultEditedItem: {
				tipo: 1,
				idMantenimientoServicio: null,
				idMantenimientoCategoria: null,
				fechaInicioPlaneado: null,
				tiempoEstimado: null,
				responsable: null,
				mecanicos: null,
				fechaInicio: null,
				fechaFin: null,
				recomendacion: null,
				idSubdepartamento: null,
				codigo: "",
				nombre: "",
				descripcion: "",
				numPersonas: 1,
				comprobaciones: [],
				asignados: [],
				importeNeto: null,
				tipoAsignacion: 1,
      			idMantenimientoProveedor: null,
			},
			servicios: [],
			loading: false,
			headersServicios: [
				{
					text: "Código",
					align: "center",
					sortable: false,
					value: "codigo",
				},
				{
					text: "Nombre",
					align: "center",
					sortable: false,
					value: "nombre",
				},
				{
					text: "Categoría del Servicio",
					align: "center",
					sortable: false,
					value: "nombreCategoria",
				},
				{
					text: "Acciones",
					align: "center",
					sortable: false,
					value: "actions",
					width: "15%",
				},
			],
			dialogEliminar: false,
			eliminarItem: null,
			deleting: false,
		};
	},
	computed: {
		...mapState(['nombreEmpresa']),
		esIndasa(){
			return this.nombreEmpresa.toUpperCase() == "INDASA";
		},
		showSubdepto(){
			if(this.esIndasa) return false;
			return true;
		},
		textButtonSave(){
			return this.editedItem.idMantenimientoServicio == null
				? 'Agregar'
				: this.tipoEdicion == 1
					? 'Actualizar'
					: 'Clonar'
		},
		headersServicioProgramado() {
			return this.headersServicios.filter(
				(header) => header.value != "actions"
			);
		},
	},
	beforeMount(){
		this.editedItem = structuredClone(this.defaultEditedItem);
		this.eliminarItem = structuredClone(this.defaultEditedItem);
		this.reportedItem = structuredClone(this.defaultEditedItem);
	},
	mounted() {
		this.initialize();
	},
	methods: {
		onChangeTipoAsignacion(){
			this.editedItem.numPersonas = 1;
			this.editedItem.asignados = [];
			this.editedItem.idMantenimientoProveedor = null;
		},
		nombrePersona: ({ nombre, paterno, materno }) =>
			`${nombre ? `${nombre} ` : ""}${paterno ? `${paterno} ` : ""}${
				materno ? `${materno} ` : ""
			}`.trim(),
		resetErrores(){
			this.erroresComprobaciones = [];
			Object.keys(this.erroresItem).forEach( (key) => this.erroresItem[key] = null );
		},
		validateTipoAsignacion(){
			if(this.reportedItem.tipoAsignacion == 1){
				if (
					this.reportedItem.mecanicos == null ||
					this.reportedItem.mecanicos?.length == 0
				) {
					this.$set(this.erroresItem, "errorMecanicos", "Requerido al menos uno");
				}
			}
			else {
				if(this.reportedItem.idMantenimientoProveedor == null){
					this.$set(this.erroresItem, "errorProveedor", "Requerido");
				}
			}
		},
		validarReporte() {
			this.resetErrores();
			let comprobacionesValidas = true;

			if (this.reportedItem.responsable == null) {
				this.$set(this.erroresItem, "errorResponsable", "Requerido");
			}

			this.validateTipoAsignacion();

			if (this.reportedItem.tipo == 1) {
				if (this.reportedItem.fechaInicio == null) {
					this.$set(this.erroresItem, "errorFechaInicio", "Requerido");
				}
	
				if (this.reportedItem.fechaFin == null) {
					this.$set(this.erroresItem, "errorFechaFin", "Requerido");
				}

				if(this.esIndasa){
					if(this.reportedItem.importeNeto != null){
						const parsedImporteNeto = parseFloat(this.reportedItem.importeNeto);
						if(isNaN(parsedImporteNeto)) this.erroresItem.errorImporteNeto = "Invalido";
						else if (parsedImporteNeto < 0) this.erroresItem.errorImporteNeto = "Debe ser mayor o igual a 0";
					}
					
					if(!this.reportedItem.recomendacion) this.erroresItem.errorRecomendacion = "Requerido";
				}
	
				comprobacionesValidas = this.$refs.checklist.validate();

			}

			const hayErrores = Object.values(this.erroresItem).some( (value) => value != null ) || !comprobacionesValidas;
			if(hayErrores){
				this.erroresComprobaciones = ["Hay uno o mas errores, porfavor reviselos y vuelva a intentarlo"];
			}

			return !hayErrores;
		},
		realizarReporte(){
			if (!this.validarReporte()) return;
			const item = this.reportedItem.tipo == 1 ? this.$refs.checklist.terminarTarea() : null;

			this.reporting = true;

			let payload = {
				idMantenimientoServicio: this.reportedItem.idMantenimientoServicio,
				idPersonaResponsable: this.reportedItem.responsable,
				mecanicos: this.reportedItem.mecanicos,
				tipoAsignacion: this.reportedItem.tipoAsignacion,
				idMantenimientoProveedor: this.reportedItem.idMantenimientoProveedor
			}

			if(this.reportedItem.tipo == 1){
				payload = {
					...payload,
					fechaInicioRealizo: this.reportedItem.fechaInicio,
					fechaFinRealizo: this.reportedItem.fechaFin,
					recomendacion: this.reportedItem.recomendacion,
					comprobaciones: item.comprobaciones,
					idProveedor: this.editedItem.idProveedor,
					importeNeto: parseFloat(this.reportedItem.importeNeto) || null,
					kilometraje: parseFloat(this.reportedItem.kilometraje) || null,
					horasTrabajadas: parseFloat(this.reportedItem.horasTrabajadas) || null
				}
			}

			axios({
				url: this.reportedItem.tipo == 1
					? '/Mantenimientos/ExtemporaneoMantenimientoServicioTarea'
					: '/Mantenimientos/IniciarMantenimientoServicioTarea',
				method: 'POST',
				data: payload
			})
				.then( () => {
					this.reporting = false;
					this.cerrarDialogReportes();
					this.initialize();
				})
				.catch( (error) => {
					this.reporting = false;
					console.log(error);
					if (error.response?.status == 409) {
						this.erroresComprobaciones = ["Este servicio ya está siendo reportado en tiempo real, espere a que finalice para iniciar uno nuevo"];
					}
				});
		},
		cerrarDialogReportes(){
			this.dialogReportes = false;
			setTimeout(() => {
				this.reportedItem = structuredClone(this.defaultEditedItem);
				this.resetErrores();
			}, 200);
		},
		resetErrorFecha(item, errorItem, key, errorKey) {
			if (item[key] != null) {
				this.$set(errorItem, errorKey, null);
			}
		},
		showDialogReportes(item, tipo){
			this.reportedItem = structuredClone(item);
			this.reportedItem.mecanicos = item.asignados.slice();
			this.reportedItem.tipo = tipo;
			this.dialogReportes = true;
		},
		onChangeFechaInicio() {
			this.$set(this.erroresItem, "fechaInicio", null);
		},
		validateProgramarServicio() {
			this.resetErrores();

			if (this.reportedItem.fechaInicioPlaneado == null) {
				this.$set(this.erroresItem, "errorFechaInicio", "Requerido");
			}

			if (this.reportedItem.tiempoEstimado == null) {
				this.$set(this.erroresItem, "errorTiempoEstimado", "Requerido");
			}

			return Object.values(this.erroresItem).every((value) => value == null);
		},
		programarServicio() {
			if (!this.validateProgramarServicio()) return;

			this.programming = true;

			axios
				.post("/Mantenimientos/CrearMantenimientoServicioTarea", {
					idMantenimientoServicio:
						this.reportedItem.idMantenimientoServicio,
					inicioPlaneado: this.reportedItem.fechaInicioPlaneado,
					tiempoEstimado: this.reportedItem.tiempoEstimado,
				})
				.then(() => {
					this.programming = false;
					this.closeDialogProgramarServicio();
				})
				.catch((error) => {
					console.log(error);
					this.programming = false;
				});
		},
		closeDialogProgramarServicio() {
			this.dialogProgramarServicio = false;
			this.$nextTick(() => {
				this.reportedItem = structuredClone(this.defaultEditedItem);
				this.resetErrores();
			});
		},
		showDialogProgramarServicio(item) {
			this.reportedItem = structuredClone(item);
			this.dialogProgramarServicio = true;
		},
		nombreSubdepto: ({ codigo, nombre }) =>
			codigo ? `[${codigo}] - ${nombre}`.trim() : nombre.trim(),
		guardarServicio() {
			this.save();
		},
		initialize() {
			this.getServicios();
			this.getPersonasMantenimiento();
			this.getSubdepartamentos();
			this.getCategoriasMantenimiento();
			this.getRecomendaciones();
			this.getProveedoresMantenimiento();
		},
		getProveedoresMantenimiento(){
			this.loadingProveedores = true;

			axios
				.get('/MantenimientoProveedores')
				.then( (response) => {
					this.proveedores = response.data;
				})
				.catch(console.log)
				.finally( () => {
					this.loadingProveedores = false;
				});
		},
		getRecomendaciones(){
			this.loadingRecomendaciones = true;

			axios
				.get('/Mantenimientos/GetRecomendacionesUnicas')
				.then( (response) => {
					this.recomendaciones = response.data;
				})
				.catch(console.log)
				.finally( () => {
					this.loadingRecomendaciones = false;
				});
		},
		getCategoriasMantenimiento() {
			this.loadingCategorias = true;

			axios
				.get("/MantenimientoCategorias")
				.then((response) => {
					this.categorias = response.data;
				})
				.catch((error) => {
					console.log(error);
					this.categorias = [];
				})
				.finally(() => {
					this.loadingCategorias = false;
				});
		},
		getPersonasMantenimiento(){
			axios
				.get("/Mantenimientos/PersonasMantenimiento")
				.then((response) => {
					this.personas = response.data;
				})
				.catch((error) => {
					console.log(error);
				});
		},
		getSubdepartamentos() {
			axios
				.get("/Subdepartamento")
				.then((response) => {
					this.subdepartamentos = response.data;
				})
				.catch(console.log);
		},
		closeServicio() {
			this.dialog = false;
			setTimeout(() => {
				this.editedItem = structuredClone(this.defaultEditedItem);
				this.resetErrores();
				this.tipoEdicion = 1;
				this.$refs.todo.togglePanel(0);
			}, 200);
		},
		getServicios() {
			this.loading = true;
			axios
				.get("/Mantenimientos/ListarMantenimientoServicio")
				.then((response) => {
					this.servicios = response.data;
				})
				.catch(console.log)
				.finally(() => {
					this.loading = false;
				});
		},
		editServicio(item,tipoEdicion = 1) {
			this.editedItem = structuredClone(item);
			this.editedItem.comprobaciones = this.$utils.mantenimientos.mapComprobaciones(structuredClone(item.comprobaciones));
			this.tipoEdicion = tipoEdicion;
			if(this.tipoEdicion == 2){
				this.editedItem.codigo = null;
				this.editedItem.nombre = null;
			}
			this.dialog = true;
		},
		removeServicio(item) {
			this.dialogEliminar = true;
			this.eliminarItem = structuredClone(item);
		},
		runRemoverServicio() {
			this.deleting = true;
			axios
				.put(
					`/Mantenimientos/DesactivarMantenimientoServicio/${this.eliminarItem.idMantenimientoServicio}`
				)
				.then(() => {
					this.deleting = false;
					this.closeEliminarServicio();
					this.getServicios();
				})
				.catch((error) => {
					this.deleting = false;
					console.log(error);
				});
		},
		closeEliminarServicio() {
			this.dialogEliminar = false;
			this.$nextTick(() => {
				this.eliminarItem = structuredClone(this.defaultEditedItem);
			});
		},
		validate() {
			this.resetErrores();

			if (
				!this.editedItem.codigo ||
				this.editedItem.codigo.length < 5 ||
				this.editedItem.codigo.length > 100
			) {
				this.erroresItem.errorCodigo = "Debe tener entre 5 y 100 caracteres";
			}
			if (
				!this.editedItem.nombre ||
				this.editedItem.nombre.length < 5 ||
				this.editedItem.nombre.length > 1000
			) {
				this.erroresItem.errorNombre = "Debe tener entre 5 y 100 caracteres";
			}

			if(this.esIndasa){
				if(this.editedItem.idMantenimientoCategoria == null){
					this.erroresItem.errorCategoria = "Requerido";
				}
			}

			if(this.editedItem.numPersonas > 0){
				if(this.editedItem.asignados.length > 0 && this.editedItem.asignados.length != this.editedItem.numPersonas){
					this.erroresItem.errorAsignacionDefecto = "Debe ser igual al número de personas requeridas";
				}
			}

			const comprobacionesValidas = this.$refs.todo.validate();

			const hayErrores = Object.values(this.erroresItem).some( (value) => value != null ) || !comprobacionesValidas;
			if(hayErrores){
				this.erroresComprobaciones = ["Hay uno o mas errores, porfavor reviselos y vuelva a intentarlo"];
			}

			return !hayErrores;
		},
		save() {
			if (!this.validate()) return;
			this.guardando = true;

			if(this.esIndasa){
				this.$utils.mantenimientos.asignarSubdeptoDefault(this.subdepartamentos,this.editedItem);
			}
			
			const comprobaciones = this.$refs.todo.getListado();
			axios({
				url:
					this.editedItem.idMantenimientoServicio == null || this.tipoEdicion == 2
						? "/Mantenimientos/CrearMantenimientoServicio"
						: "/Mantenimientos/ActualizarMantenimientoServicio",
				method:
					this.editedItem.idMantenimientoServicio == null || this.tipoEdicion == 2
						? "POST"
						: "PUT",
				data: {
					idMantenimientoServicio:
						this.editedItem.idMantenimientoServicio == null || this.tipoEdicion == 2
							? null
							: this.editedItem.idMantenimientoServicio,
					idSubdepartamento: this.editedItem.idSubdepartamento,
					codigo: this.editedItem.codigo,
					nombre: this.editedItem.nombre,
					descripcion: this.editedItem.descripcion,
					numPersonas: this.editedItem.numPersonas,
					comprobaciones:
						this.editedItem.idMantenimientoServicio == null || this.tipoEdicion == 2
							? comprobaciones
							: null,
					listaComprobacion:
						this.editedItem.idMantenimientoServicio == null || this.tipoEdicion == 2
							? null
							: comprobaciones,
					asignados: this.editedItem.asignados,
        			idMantenimientoCategoria: this.editedItem.idMantenimientoCategoria,
					tipoAsignacion: this.editedItem.tipoAsignacion,
					idMantenimientoProveedor: this.editedItem.idMantenimientoProveedor
				},
			})
				.then(() => {
					this.guardando = false;
					this.closeServicio();
					this.getServicios();
				})
				.catch((error) => {
					console.log(error);
					this.guardando = false;
					if (error.response?.status == 409) {
						this.$set(this.erroresItem, "errorCodigo", "Ya existe");
					}
				});
		},
	},
};
</script>