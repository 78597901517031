<template>
  <v-col cols="12" class="px-10" v-if="permisoVista('reporterendimiento','r')">
    <v-row>
      <v-col cols="12" md="12" sm="12">
        <datePicker
          v-model="fecha"
          label="Fecha (Obligatorio)"
          format="YYYY-MM-DD"
          clearable
          maxToday
          @input="ejecutar"
        ></datePicker>
      </v-col>
    </v-row>
    <v-row v-if="realizarPeticion" class="px-0 ma-0">
      <v-col cols="12" class="ma-0 text-right text-subtitle-2">
        Proxima Actualización en {{tiempo}}
      </v-col>
    </v-row>
    <v-progress-linear
      v-if="loading"
			color="primary"
			indeterminate
		></v-progress-linear>
    <apexchart type="bar" height="350" :options="chartOptions" :series="series"></apexchart>
  </v-col>
</template>


<script>
import axios from "axios";
import VTextFieldDatetime from '@/components/VTextFieldDatetime.vue';

export default {
  components:{
    'v-text-field-datetime': VTextFieldDatetime,
  },
  data: () => ({
    categories:[],
    series:[],
    fecha: null,
    intervalo: null,
    loading: false,
    segundos: 0,
  }),
  computed:{
    chartOptions(){
      return {
        chart: {
          type: 'bar',
          height: 350
        },
        plotOptions: {
          bar: {
            horizontal: false,
            columnWidth: '100%',
            endingShape: 'rounded'
          },
        },
        dataLabels: {
          enabled: false
        },
        xaxis: {
          categories: this.categories,
        },
        yaxis: [
          {
            seriesName: 'Tiempo Productivo',
            title: {
              text: "# Horas"
            }
          },
          {
            seriesName: 'Tiempo Productivo',
            show: false,
          },
          {
            seriesName: 'Tiempo Productivo',
            show: false,
          },
          {
            seriesName: 'Tiempo Productivo',
            show: false,
          }
          /*
          ,
          {
            opposite: true,
            seriesName: '# Pza Fabricadas',
            title: {
              text: "# Fabricadas"
            }
          },
          {
            seriesName: '# Pza Incidentes',
            opposite: true,
            title: {
              text: "# Defectos"
            }
          },
          {
            seriesName: '# Pza Incidentes',
            show: false,
          },
          {
            seriesName: '# Pza Incidentes',
            show: false,
          }
          */
        ],
        fill: {
          opacity: 1
        },
        legend: {
          onItemClick: {
              toggleDataSeries: false
          }
        }
      }
    },
    urlDatos(){
      return `/Reportes/ReporteMaquinaRendimiento?Fecha=${this.fecha}`;
    },
    realizarPeticion(){
      return this.$utils.isValid(this.fecha);
    },
    tiempo(){
      return this.$utils.strings.toTime(this.segundos);
    }
  },
  mounted(){
    this.getDatos();
    this.intervalo = setInterval(() => {
      if(this.segundos == 0){
        this.getDatos();
      }
      else{
        this.segundos -= 1;
      }
    },1000);
  },
  destroyed(){
    clearInterval(this.intervalo);
    this.intervalo = null;
  },
  methods:{
    ejecutar(){
      if(this.realizarPeticion)
      {
        this.getDatos();
      }
    },
    getDatos(){
      if(!this.realizarPeticion) return;
      this.loading = true;
      this.segundos = 60;

      axios
      .get(this.urlDatos)
      .then(response => {
        this.series = response.data.series;
        this.categories = response.data.categories;
        this.loading = false;
      })
    }
  },
}
</script>