<template>
	<div>
		<v-tabs v-model="tab" background-color="transparent" grow>
			<v-tab v-if="permisoVista('listas', 'c') && !odtAgrupadas" @click="odtSencillaClick">
				Sencilla
			</v-tab>
			<v-tab v-if="permisoVista('listas', 'c') && !odtAgrupadas" @click="odtMultipleClick">
				Multiple
			</v-tab>
			<v-tab v-if="odtAgrupadas" @click="odtAgrupadasClick">
				Sencilla
			</v-tab>

			<v-tabs-items v-model="tab" touchless>
				<v-tab-item v-if="!odtAgrupadas" eager>
					<Detalles
						ref="odtsencilla"
						:articles="articulos"
						:comercialLines="lineascomerciales"
						:warehouse="almacenes"
						:articTags="tags"
						:multiple="false"
						:iniciarAutoNuevaODT="iniciarAutoNuevaODT"
					/>
				</v-tab-item>
				<v-tab-item v-if="!odtAgrupadas" eager>
					<Detalles
						ref="odtmultiple"
						:articles="articulos"
						:comercialLines="lineascomerciales"
						:warehouse="almacenes"
						:articTags="tags"
						:multiple="true"
						:iniciarAutoNuevaODT="iniciarAutoNuevaODT"
					/>
				</v-tab-item>
				<v-tab-item v-if="odtAgrupadas" eager>
					<NuevaODTGrupos
						ref="odtgrupos"
						:comercialLines="lineascomerciales"
						:warehouse="almacenes"
						:articTags="tags"
						:iniciarAutoNuevaODT="iniciarAutoNuevaODT"
					/>
				</v-tab-item>
			</v-tabs-items>
		</v-tabs>
	</div>
</template>

<script>
import axios from "axios";
import Detalles from "../OTS/Lista/Abiertos/Detalles.vue";
import { mapState } from 'vuex';
import NuevaODTGrupos from '../OTS/Lista/Abiertos/NuevaODTGrupos.vue';

export default {
	components: {
		Detalles,
		NuevaODTGrupos,
	},
	data: () => ({
		tab: 0,
		articulos: [],
		lineascomerciales: [],
		almacenes: [],
		tags: []
	}),
	computed: {
		...mapState(['iniciarAutoNuevaODT','odtAgrupadas'])
	},
	mounted(){
		this.initialize();
	},
	methods: {
		initialize() {
			this.getArticulos();
			this.getComercialLineas();
			this.getAlmacenes();
			this.getTags();
		},
		getAlmacenes() {
			axios
				.get("/Almacenes")
				.then((response) => {
					this.almacenes = response.data;
				})
				.catch((error) => {
					console.log(error);
				});
		},
		getComercialLineas() {
			axios
				.get("/ComercialLineas")
				.then((response) => {
					this.lineascomerciales = response.data;
				})
				.catch((error) => {
					console.log(error);
				});
		},
		getArticulos() {
			axios
				.get("/Articulos/OTS")
				.then((response) => {
					this.articulos = response.data;
				})
				.catch((error) => {
					console.log(error);
				});
		},
		getTags() {
			axios
				.get("/Articulos/ListTag")
				.then((response) => {
					this.tags = response.data.map((etiqueta) => ({
						idTag: etiqueta.idTag,
						tag: etiqueta.nombre,
					}));
				})
				.catch((error) => {
					console.log(error);
				});
		},
		odtSencillaClick() {
			if (typeof this.$refs.odtsencilla === "undefined") return;
			this.$refs.odtsencilla.initialize();
		},
		odtMultipleClick() {
			if (typeof this.$refs.odtmultiple === "undefined") return;
			this.$refs.odtmultiple.initialize();
		},
		odtAgrupadasClick(){
			if (typeof this.$refs.odtgrupos === "undefined") return;
			this.$refs.odtgrupos.initialize();
		}
	},
};
</script>