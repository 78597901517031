<template>
<v-col cols="12" v-if="permisoVista('disponibilidad','r')">
    <v-row>
    <v-col cols="3">
      <v-autocomplete
        v-model="turno"
        :items="turnos"
        item-text="nombre"
        item-value="idTurno"
        label="Turno"        
        return-object
        clearable
        @click:clear="resetTurno"
        @change="getDatos"
      >
      </v-autocomplete>
    </v-col>
    <v-col cols="3">
      <v-autocomplete
        v-model="idsPersonasExcluir"
        :items="personasTurno"
        :item-text="nombrePersona"
        item-value="idPersona"
        multiple
        :label="personaExcluir"
        clearable
        @click:clear="resetExcluir"
        @change="getDatos"
      >
      </v-autocomplete>
    </v-col>
    <v-col cols="3">
      <v-autocomplete
        v-model="idsPersonasIncluir"
        :items="personasOtrosTurnos"
        :item-text="nombrePersona"
        item-value="idPersona"
        multiple
        clearable
        label="Incluir Personas"
        @click:clear="resetIncluir"
        @change="getDatos"
      >
      </v-autocomplete>
    </v-col>
    <v-col cols="3">
      <v-autocomplete
        v-model="idsMaquinasExcluir"
        :items="maquinasIns"
        :item-text="nombreMaquina"
        item-value="idMaquinaIns"
        multiple
        label="Excluir Máquinas"
        clearable
        @click:clear="resetExcluirMaquinas"
        @change="getDatos"
      >
      </v-autocomplete>
    </v-col>
  </v-row>
  <v-data-table
    :items="datos"
    :headers="headers"
    :loading="loading"
    :items-per-page="-1"
    hide-default-footer
    disable-sort
  >
    <template v-slot:item="{ item }">
      <tr>
        <td class="text-center">
          {{item.nombre}}
        </td>
        <td class="text-center">
          <v-chip
            v-for="(habilidad,idx) in item.habilidades"
            :key="'h-'+idx"
            label
          >
            {{habilidad}}
          </v-chip>
        </td>
        <td class="pa-0 ma-0">
          <v-simple-table class="pa-0 ma-0">
            <template v-slot:default>
              <tbody>
                <tr
                  v-for="(persona,idx) in item.personas"
                  :key="'p-'+idx"
                >
                  <td class="text-center" style="width:25% !important;">{{persona.nombre}}</td>
                  <td class="text-center" style="width:25% !important;">
                    <v-progress-linear
                      :value="persona.porcentajeHabilidades"
                      height="25"
                    >
                      <strong>{{ Math.ceil(persona.porcentajeHabilidades) }}%</strong>
                    </v-progress-linear>
                  </td>
                  <td class="text-center" style="width:50% !important;">
                    <v-chip
                      class="ma-1"
                      v-for="(habilidad,idx) in persona.habilidades"
                      :key="'h-'+idx"
                      label
                      x-small
                    >
                      {{habilidad}}
                    </v-chip>
                  </td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
        </td>
      </tr>
    </template>
  </v-data-table>
</v-col>
</template>

<script>
import axios from "axios";

export default {
  data(){
    return {
      turnos:[],
      personas:[],
      maquinasIns:[],
      turno: null,
      idsPersonasExcluir:[],
      idsPersonasIncluir:[],
      idsMaquinasExcluir:[],
      loading: false,
      datos:[],
      headers:[
        {
          text: "Máquina",
          align: "center",
          sort: true,
          value: "nombre",
          width:"25%"
        },
        {
          text: "Habilidades",
          align: "center",
          sort: true,
          value: "habilidades",
          width:"25%"
        },
        {
          text: "Personas",
          align: "center",
          sort: true,
          value: "personas",
        },
      ]
    }
  },
  mounted(){
    this.initialize();
  },
  computed:{
    personasTurno(){
      if(!this.$utils.isValid(this.turno)) return [];
      return this.personas.filter(p => p.idTurno == this.turno.idTurno);
    },
    personaExcluir(){
      if(!this.$utils.isValid(this.turno)) return "Excluir";
      else return `Excluir - ${this.turno.nombre}`;
    },
    personasOtrosTurnos(){
      if(!this.$utils.isValid(this.turno)) return [];
      var idsTurnos = this.$utils.arrays.unique(this.personas.filter(p => p.idTurno != this.turno.idTurno).map(p => p.idTurno));

      var personas = [];

      var primero = true;
      idsTurnos.forEach(idTurno => {
        var posTurno = this.turnos.findIndex(t => t.idTurno == idTurno);
        if(posTurno >= 0){
        
          if(!primero) personas.push({ divider: true });
          else primero = false;

          personas.push({ header: this.turnos[posTurno].nombre });

          personas = personas.concat(
            this.personas.filter(p => p.idTurno == idTurno).map(p => {
              return {
                idPersona:p.idPersona,
                nombre: p.nombre,
                paterno: p.paterno,
                materno: p.materno,
                group:this.turnos[posTurno].nombre,
              }
            })
          );

        } 
      });

      return personas;
    },
    realizarPeticion(){
      return (this.$utils.isValid(this.turno) && !this.loading);
    },
    urlDatos(){
      var url = "/Disponibilidad/DisponibilidadMaquinas";

      if(this.$utils.isValid(this.turno)) url += `?IdTurno=${this.turno.idTurno}`; 
      else url += `?IdTurno=0`; 

      this.idsPersonasExcluir.forEach(idPersona => {
        url += `&IdsPersonasExcluir=${idPersona}`;
      });

      this.idsPersonasIncluir.forEach(idPersona => {
        url += `&IdsPersonasIncluir=${idPersona}`;
      });

      this.idsMaquinasExcluir.forEach(idPersona => {
        url += `&IdsMaquinasExcluir=${idPersona}`;
      });

      return url;
    }
  },
  methods:{
    initialize(){
      this.getTurnos();
      this.getPersonas();
      this.getMaquinaIns();
    },
    nombrePersona: persona => persona.nombre + " " + persona.paterno + " " + persona.materno,
    nombreMaquina: maquina => ` [${maquina.nombre}] - ${maquina.marca} ${maquina.modelo}`,
    getTurnos(){
      axios
      .get("/Turnos")
      .then(response => {
        this.turnos = response.data;
        if(this.turno==null && this.turnos.length>0){
          this.turno = this.turnos[0];
          this.getDatos();
        }
      })
      .catch(error => {
        console.log(error);
      })
    },
    getPersonas(){
      axios
      .get("/Personas/Operadores")
      .then(response => {
        this.personas = response.data;
      })
      .catch(error => {
        console.log(error);
      })
    },
    getMaquinaIns(){
      axios
      .get("/MaquinaInstancias")
      .then(response => {
        this.maquinasIns = response.data;
      })
      .catch(error => {
        console.log(error);
      })
    },
    resetTurno(){
      this.turno = null;
      this.idsPersonasExcluir = [];
      this.idsPersonasIncluir = [];
      this.datos = [];
    },
    resetExcluir(){
      this.idsPersonasExcluir = [];
    },
    resetIncluir(){
      this.idsPersonasIncluir = [];
    },
    resetExcluirMaquinas(){
      this.idsMaquinasExcluir = [];
    },
    getDatos(){
      if(!this.realizarPeticion) return;

      this.loading = true;

      axios
      .get(this.urlDatos)
      .then(response => {
        this.loading = false;
        this.datos = response.data;
      })
      .catch(error => {
        console.log(error);
        this.loading = false;
      })
    }
  },
}
</script>