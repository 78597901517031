<template>
  <v-container>
    <v-tabs vertical>
      <v-tab v-html="area.nombre.replace(' ','<br>')" v-for="area in areas" :key="area.idArea">
      </v-tab>
      <v-tab-item v-for="area in areas" :key="'a'+area.idArea">
        <reglaArea :idArea="area.idArea"></reglaArea>
      </v-tab-item>
    </v-tabs>
  </v-container>
</template>

<script>
import axios from 'axios'
import reglaArea from './Area/ReglaArea'

export default {
  components:{
    reglaArea:reglaArea
  },
  data() {
    return {
      areas:[],
    };
  },
  created () {
    this.initialize()
  },
  methods: {
    initialize() {
      axios
        .get("/Areas")
        .then(response =>  {
          this.areas = response.data.filter(element => {
            return element.idArea > 1;
          });
        })
        .catch(error => {
          console.log(error);
        });
    }
  }
}
</script>