<template>
	<v-dialog v-model="dialogHistorico" persistent max-width="70%">
		<v-card>
			<v-card-title> Tiempo Promedio Histórico </v-card-title>
			<v-card-text>
				<v-container v-show=" tiempoPromedio > 0 && desviacionEstandar > 0 && numFrecuencias > 0 ">
					<v-row>
						<v-col class="text-center" cols="6" sm="6" md="6">
                            <div style="font-size: 18px;">
                                <div class="font-weight-bold" style="font-size: 18px;">Tiempo Promedio:</div> 
                                {{ new Date(tiempoPromedio * 1000).toISOString().substr(11, 8) }}
                            </div>
                        </v-col>
                        <v-col class="text-center" cols="6" sm="6" md="6">
                            <div style="font-size: 18px;">
                                <div class="font-weight-bold" style="font-size: 18px;">Desviación Estandar:</div> 
                                {{ new Date(desviacionEstandar * 1000).toISOString().substr(11, 8) }}
                            </div>
                        </v-col>
					</v-row>
                    <v-row >
                        <v-col cols="12" md="12" sm="12">
                            <HistoricoDisperso ref="ghistoric" />
                        </v-col>
                    </v-row>
				</v-container>
                <v-container v-show=" tiempoPromedio === 0 || desviacionEstandar === 0 || numFrecuencias === 0 ">
					<v-row>
						<v-col cols="6" sm="6" md="6">
                            <h4>
                                Sin Información Disponible
                            </h4>
                        </v-col>
					</v-row>
				</v-container>
			</v-card-text>
			<v-card-actions>
				<v-spacer></v-spacer>
				<v-btn color="blue darken-1" text @click="close()"
					>Cerrar</v-btn
				>
			</v-card-actions>
		</v-card>
	</v-dialog>
</template>

<script>
import axios from 'axios';
import HistoricoDisperso from './HistoricoDisperso.vue';
export default {
	components: { HistoricoDisperso },
    data() {
        return {
            numFrecuencias: 0,
            dialogHistorico: false,
            tiempoPromedio: 0,
            desviacionEstandar: 0,
        }
    },
	methods: {
		mostrar(idPaso) {
            this.dialogHistorico = true;
            this.getTiempoPromDesv(idPaso);
        },
        getTiempoPromDesv(idPaso){
            axios
                .get( `/Recetas/TiempoPromedioDesviacionEstandar/${idPaso}` )
                .then( response => {
                    this.tiempoPromedio = response.data.tiempoPromedio;
                    this.desviacionEstandar = response.data.desviacionEstandar;
                    this.numFrecuencias = response.data.frecuencias.length;
                    const series = [{
                        name: 'Promedio',
                        data: response.data.frecuencias
                    }];

                    this.$refs.ghistoric.show( series );
                })
                .catch( error => {
                    console.log( error );
                })
        },
        close(){
            this.dialogHistorico = false;
        }
	},
};
</script>