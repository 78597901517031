<template>
  <!-- <v-container> -->
    <v-row justify-sm="center">
      <v-card 
        class="mt-4"
        :width="anchoMaximo"
        fill
      >
        <v-tabs show-arrows v-model="tab" :background-color="colorTabs" centered dark icons-and-text>
          <v-tabs-slider></v-tabs-slider>

          <v-tab href="#tab-1" v-if="mostrarVista('reglasareas')">
            Reglas por Área
            <v-icon>mdi-texture-box</v-icon>
          </v-tab>
          <v-tab href="#tab-2" v-if="mostrarVista('reglasarticulos')">
            Reglas por Artículo
            <v-icon>mdi-clipboard-text-outline</v-icon>
          </v-tab>

          <v-tab v-if="mostrarVista('incidencias') || mostrarVista('defectonodesglozados')" v-tooltip.bottom="modoAyuda && $help.calidad.incidencias.title" href="#tab-3" @click="updateParos()">
            Incidencias
            <v-icon>mdi-playlist-remove</v-icon>
          </v-tab>

          <v-tab v-if="mostrarVista('comprobaciones')" v-tooltip.bottom="modoAyuda && $help.calidad.listasComprobacion.title" href="#tab-4" @click="updateComprobaciones()">
            Listas de<br/>Comprobación
            <v-icon>mdi-list-status</v-icon>
          </v-tab>

          <v-tab v-tooltip.bottom="modoAyuda && $help.calidad.procesosRetrabajo" href="#tab-5" v-if="mostrarVista('retrabajo')" @click="updateLiberacionProduccion()">
            Liberación de<br/>producción
            <v-icon>mdi-text-box-check</v-icon>
          </v-tab>

          <v-tab v-if="mostrarVista('bloqueomaquinainasistencia')" v-tooltip.bottom="modoAyuda && $help.calidad.bloqueoMaquinaInasistencia" href="#tab-6" @click="updateBloqueoMaquinaInasistencia()">
            Bloqueo de Máquina<br>por Inasistencia
            <v-icon>mdi-cog-stop</v-icon>
          </v-tab>

          <v-tab v-tooltip.bottom="modoAyuda && $help.calidad.factoresOEE" href="#tab-7" v-if="mostrarVista('oee-factores')" @click="updateFactores()">
            Factores OEE
            <v-icon>mdi-archive</v-icon>
          </v-tab>

          <v-tab v-tooltip.bottom="modoAyuda && $help.calidad.motivosRemanufactura" href="#tab-8" @click="updateRemanufactura()" v-if="mostrarVista('remanufacturamotivos')">
            Motivos <br/>Remanufactura
            <v-icon>mdi-cog-sync</v-icon>
          </v-tab>

          <v-tab v-tooltip.bottom="modoAyuda && $help.calidad.catalogoIncidencias.title" href="#tab-9" v-if="mostrarVista('parotipos')" @click="updateParoTipos()">
            Catálogo<br/>de Incidencias
            <v-icon>mdi-playlist-remove</v-icon>
          </v-tab>

          <v-tab v-tooltip.bottom="modoAyuda && $help.calidad.procesosRetrabajo" href="#tab-10" v-if="mostrarVista('retrabajo')" @click="updateProcesosRetrabajo()">
            Procesos<br/>Retrabajo
            <v-icon>mdi-cog-refresh</v-icon>
          </v-tab>
        </v-tabs>

        

        <v-tabs-items v-model="tab">

          <v-tab-item :key="1" :value="'tab-1'">
            <v-card flat color="gray">
              <v-card-text>
                <calidadArea ref="area"></calidadArea>
              </v-card-text>
            </v-card>
          </v-tab-item>

          <v-tab-item :key="2" :value="'tab-2'">
            <v-card flat color="gray">
              <v-card-text>
                <calidadArticulo ref="articulo"></calidadArticulo>
              </v-card-text>
            </v-card>
          </v-tab-item>

          <v-tab-item :key="3" :value="'tab-3'">
            <v-card flat>
              <v-card-text>
                <OTSIncidencias ref="paros" @hook:mounted="leerParametros()" />
              </v-card-text>
            </v-card>
          </v-tab-item>

          <v-tab-item :key="4" :value="'tab-4'">
            <v-card flat>
              <v-card-text>
                <Comprobacion ref="checklist" />
              </v-card-text>
            </v-card>
          </v-tab-item>

          <v-tab-item :key="5" :value="'tab-5'">
            <v-card flat color="gray">
              <v-card-text>
                <LiberacionProduccion ref="liberacionProduction" />
              </v-card-text>
            </v-card>
          </v-tab-item>

          <v-tab-item :key="6" :value="'tab-6'">
            <v-card flat>
              <v-card-text>
                <BloqueoMaquinaInasistencia ref="bloqueoMaquina" />
              </v-card-text>
            </v-card>
          </v-tab-item>

          <v-tab-item :key="7" :value="'tab-7'" v-if="mostrarVista('oee-factores')">
            <v-card flat color="gray">
              <v-card-text>
                <OEEFactores ref="factor" />
              </v-card-text>
            </v-card>
          </v-tab-item>

          <v-tab-item :key="8" :value="'tab-8'" v-if="mostrarVista('remanufacturamotivos')">
            <v-card flat color="gray">
              <v-card-text>
                <Remanufactura ref="remanufacture" />
              </v-card-text>
            </v-card>
          </v-tab-item>

          <v-tab-item :key="9" :value="'tab-9'" v-if="mostrarVista('parotipos')">
            <v-card flat color="gray">
              <v-card-text>
                <ListaParos ref="parotipos" />
              </v-card-text>
            </v-card>
          </v-tab-item>

          <v-tab-item :key="10" :value="'tab-10'">
            <v-card flat color="gray">
              <v-card-text>
                <ProcesosRetrabajo ref="procesosR" />
              </v-card-text>
            </v-card>
          </v-tab-item>

          

        </v-tabs-items>
      </v-card>
    </v-row>
  <!-- </v-container> -->
</template>

<script>
import Area from "./Calidad/Area.vue";
import Articulo from "./Calidad/Articulo.vue";
import ListaIncidencias from './OTS/ListaIncidencias.vue';
import OEEFactores from './Configuracion/OEEFactores.vue';
import Remanufactura from './Configuracion/Remanufactura.vue';
import ListaParos from './Produccion/ListaParos.vue';
import ProcesosRetrabajo from './Produccion/ProcesosRetrabajo.vue';
import ListasComprobacion from './Calidad/ListasComprobacion/Configuracion.vue';
import { mapState } from 'vuex';
import Comprobacion from './Calidad/Comprobacion.vue';
import BloqueoMaquinaInasistencia from './Calidad/BloqueoMaquinaInasistencia.vue';
import LiberacionProduccion from './Calidad/LiberacionProduccion.vue'

export default {
  data() {
    return {
      tab: null,
      text:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat."
    };
  },
  methods:{
    leerParametros(){
      if (this.$route.query.tab != undefined && this.$route.query.idArea != undefined){
        this.tab = this.$route.query.tab;
      }
    },
    updateBloqueoMaquinaInasistencia(){
      if(typeof this.$refs.bloqueoMaquina === 'undefined') return;
      this.$refs.bloqueoMaquina.initialize();
    },
    updateParos(){
      if(typeof this.$refs.paros === 'undefined') return;
      this.$refs.paros.initialize();
    },
    updateAreas() {
      if(typeof this.$refs.areas === 'undefined') return;
      this.$refs.areas.initialize();
    },
    updateFactores(){
      if(typeof this.$refs.factor === 'undefined') return;
      this.$refs.factor.initialize();
    },
    updateRemanufactura(){
      if(typeof this.$refs.remanufacture === 'undefined') return;
      this.$refs.remanufacture.initialize();
    },
    updateParoTipos(){
      if(typeof this.$refs.parotipos === 'undefined') return;
      this.$refs.parotipos.initialize();
    },
    updateProcesosRetrabajo(){
      if(typeof this.$refs.procesosR === 'undefined') return;
      this.$refs.procesosR.initialize();
    },
    updateComprobaciones(){
      if(typeof this.$refs.checklist === 'undefined') return;
      this.$refs.checklist.initialize();
    },
    updateLiberacionProduccion(){
      if(typeof this.$refs.liberacionProduction === 'undefined') return;
      this.$refs.liberacionProduction.initialize();
    }
  },
  computed: {
    ...mapState(["anchoMaximo","colorTabs","modoAyuda"])
  },
  components: {
    calidadArea: Area,
    calidadArticulo: Articulo,
    OTSIncidencias: ListaIncidencias,
    OEEFactores,
    Remanufactura,
    ListaParos,
    ProcesosRetrabajo,
    ListasComprobacion,
    Comprobacion,
    BloqueoMaquinaInasistencia,
    LiberacionProduccion
  }
};
</script>