<template>
  <!-- <v-container> -->
    <v-row justify-sm="center">
      <v-card 
        class="mt-4"
        :width="anchoMaximo"
        fill
      >
        <v-tabs show-arrows v-model="tab" :background-color="colorTabs" centered dark icons-and-text>
          <v-tabs-slider></v-tabs-slider>

          <v-tab v-tooltip.bottom="modoAyuda && $help.ventas.precioventapublico" href="#tab-1" v-if="mostrarVista('precioventapublico')" @click="updateCostoVenta()">
            Precios de Venta<br>al Público
            <v-icon>mdi-cash-multiple</v-icon>
          </v-tab>

          <v-tab v-tooltip.bottom="modoAyuda && $help.ventas.otssinvendedor" href="#tab-2" v-if="mostrarVista('otssinvendedor')" @click="updateOTSSinVendedor()">
            OTS sin Vendedor
            <v-icon>mdi-cog-outline</v-icon>
          </v-tab>

          <v-tab v-tooltip.bottom="modoAyuda && $help.ventas.publicoventas" href="#tab-3" v-if="mostrarVista('publicoventas')" @click="updateVentasAlPublico()">
            Ventas al Público
            <v-icon>mdi-account-cash</v-icon>
          </v-tab>

          <v-tab v-tooltip.bottom="modoAyuda && $help.ventas.montos" href="#tab-4" v-if="mostrarVista('montos')" @click="updateMontos()">
            Monto de Ventas
            <v-icon>mdi-chart-areaspline</v-icon>
          </v-tab>
          
          <v-tab v-tooltip.bottom="modoAyuda && $help.ventas.lineasComerciales" href="#tab-5" v-if="mostrarVista('lineascomerciales')" @click="updateLineasComerciales()">
            Líneas Comerciales
            <v-icon>mdi-handshake</v-icon>
          </v-tab>

          <v-tab v-tooltip.bottom="modoAyuda && $help.ventas.clientes" href="#tab-6" v-if="mostrarVista('clientes')" @click="updateClientes()">
            Clientes
            <v-icon>mdi-account-tie</v-icon>
          </v-tab>

        </v-tabs>

        <v-tabs-items v-model="tab">

          <v-tab-item :key="1" :value="'tab-1'">
            <v-card flat>
              <v-card-text>
                <CostosVentasProductos ref="costosventas" />
              </v-card-text>
            </v-card>
          </v-tab-item>

          <v-tab-item :key="2" :value="'tab-2'" v-if="mostrarVista('otssinvendedor')">
            <v-card flat>
              <v-card-text>
                <VentasOTSSinVendedor ref="otssinvendedor"></VentasOTSSinVendedor>
              </v-card-text>
            </v-card>
          </v-tab-item>

          <v-tab-item :key="3" :value="'tab-3'" v-if="mostrarVista('publicoventas')">
            <v-card flat>
              <v-card-text>
                <VentasAlPublico ref="ventasPublico" />
              </v-card-text>
            </v-card>
          </v-tab-item>

          <v-tab-item :key="4" :value="'tab-4'" v-if="mostrarVista('montos')">
            <v-card flat>
              <v-card-text>
                <VentasMonto ref="montos"></VentasMonto>
              </v-card-text>
            </v-card>
          </v-tab-item>

           <v-tab-item :key="5" :value="'tab-5'" v-if="mostrarVista('lineascomerciales')">
            <v-card flat>
              <v-card-text>
                <VentasLineasComerciales ref="lineascomerciales"></VentasLineasComerciales>
              </v-card-text>
            </v-card>
          </v-tab-item>

          <v-tab-item :key="6" :value="'tab-6'" v-if="mostrarVista('clientes')">
            <v-card flat>
              <v-card-text>
                <VentasCliente ref="clientes"></VentasCliente>
              </v-card-text>
            </v-card>
          </v-tab-item>
        </v-tabs-items>
      </v-card>
    </v-row>
  <!-- </v-container> -->
</template>

<script>
import { mapState } from 'vuex';
import Cliente from "./Ventas/Cliente";
import CostosVentasProductos from './Ventas/CostosVentasProductos.vue';
import LineasComerciales from "./Ventas/LineasComerciales";
import Monto from "./Ventas/Monto"
import OTSSinVendedor from "./Ventas/OTSSinVendedor";
import VentasAlPublico from './Ventas/VentasAlPublico.vue';

export default {
  data() {
    return {
      tab: null,
      text:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat."
    };
  },
  methods:{
    updateCostoVenta(){
      if(typeof this.$refs.costosventas === 'undefined') return;
      this.$refs.costosventas.initialize();
    },
    updateVentasAlPublico(){
      if(typeof this.$refs.ventasPublico === 'undefined') return;
      this.$refs.ventasPublico.initialize();
    },
    updateClientes(){
      if(typeof this.$refs.clientes === 'undefined') return;
      this.$refs.clientes.initialize();
    },
    updateLineasComerciales(){
      if(typeof this.$refs.lineascomerciales === 'undefined') return;
      this.$refs.lineascomerciales.initialize();
    },
    updateMontos(){
      if(typeof this.$refs.montos === 'undefined') return;
      this.$refs.montos.initialize();
    },
    updateOTSSinVendedor(){
      if(typeof this.$refs.otssinvendedor === 'undefined') return;
      this.$refs.otssinvendedor.initialize();
    },
  },
  computed: {
    ...mapState(["anchoMaximo","colorTabs","modoAyuda"])
  },
  components: {
    VentasCliente:  Cliente,
    VentasLineasComerciales: LineasComerciales,
    VentasMonto: Monto,
    VentasOTSSinVendedor: OTSSinVendedor,
    VentasAlPublico,
    CostosVentasProductos
  }
};
</script>