import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VImg } from 'vuetify/lib/components/VImg';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemSubtitle } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VRow } from 'vuetify/lib/components/VGrid';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c(VContainer,{staticClass:"grey lighten-5"},[_c('div',{staticClass:"text-center"},[_c('h1',[_vm._v("Descargas")]),_c('p',[_vm._v(" Amplía las capacidades de ManagrX con nuestra sección de descargas. "),_c('br'),_vm._v(" Encuentra instaladores que te permitirán integrar otras aplicaciones y añadir nuevas funcionalidades. "),_c('br'),_vm._v("¡Descarga hoy mismo y maximiza tu potencial con ManagrX! ")])]),_c('div',{staticClass:"d-flex flex-sm-row flex-column"},[_c(VRow,[_c(VCol,{attrs:{"cols":"12","md":"12"}},[_c(VList,_vm._l((_vm.itemsDescargas),function(data,index){return _c(VListItem,{key:index,class:("d-flex px-0 " + (index > 0 ? 'mt-4' : ''))},[_c(VImg,{staticClass:"me-3",attrs:{"contain":"","max-height":"30","max-width":"30","src":_vm.showImage(data.imagen)}}),_c('div',{staticClass:"d-flex align-center flex-grow-1 flex-wrap"},[_c('div',{staticClass:"me-auto pe-2"},[_c(VListItemTitle,[_vm._v(_vm._s(data.titulo)+" "),_c('span',{staticClass:"font-weight-bold"},[_vm._v(_vm._s(data.version))])]),_c(VListItemSubtitle,[_vm._v(_vm._s(data.descripcion))])],1),_c('span',{staticClass:"font-weight-semibold success--text text-right"},[_c(VBtn,{staticClass:"ma-2",attrs:{"loading":data.loading,"disabled":data.loading,"color":"info"},on:{"click":function($event){return _vm.getImageURL(data.url, data)}},scopedSlots:_vm._u([{key:"loader",fn:function(){return [_c('span',[_c(VIcon,{attrs:{"light":""}},[_vm._v("mdi-cached")])],1)]},proxy:true}],null,true)},[_vm._v(" Descargar ")])],1)])],1)}),1)],1)],1)],1)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }