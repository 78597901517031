<template>
  <div>
    <v-tabs 
      v-model="tab"
      background-color="transparent"
      grow
    >
      <v-tab v-tooltip.bottom="modoAyuda && $help.configuracion.maquinasHerramientas.categorias" href="#tab-1" v-if="mostrarVista('cate-maquinas')" @click="updateCategoriasMaquinas()">
        Categorías
      </v-tab>

      <v-tab v-tooltip.bottom="modoAyuda && $help.configuracion.maquinasHerramientas.grupos" href="#tab-2" v-if="mostrarVista('cate-maquinas')" @click="updateClasificacionMaquinas()">
        Grupos de<br/>Máquinas
      </v-tab>

      <v-tab v-tooltip.bottom="modoAyuda && $help.configuracion.maquinasHerramientas.catalogo" href="#tab-3" v-if="mostrarVista('cat-maquinas')" @click="updateCatMaquinas()">
        Modelos de<br>Máquinas y Herramientas
      </v-tab>

      <v-tab v-tooltip.bottom="modoAyuda && $help.configuracion.maquinasHerramientas.inventario" href="#tab-4" v-if="mostrarVista('maquinas')" @click="updateMaquinas()">
        Inventario de<br>Máquinas y Herramientas
      </v-tab>

      <v-tab href="#tab-5" v-if="mostrarVista('desconocidos2') && desconocidos" @click="updateDesconocidos()">
        Desconocidos
      </v-tab>

      <v-tab v-tooltip.bottom="modoAyuda && $help.configuracion.maquinasHerramientas.inventario" href="#tab-6" v-if="mostrarVista('alertasysensores')">
        Alertas Y Sensores
      </v-tab>

    </v-tabs>

    <v-tabs-items v-model="tab">

      <v-tab-item :key="1" :value="'tab-1'" v-if="mostrarVista('cate-maquinas')">
        <recursosMaquinaCategoria ref="categoriasMaquinas"></recursosMaquinaCategoria>
      </v-tab-item>


      <v-tab-item eager :key="2" :value="'tab-2'" v-if="mostrarVista('cate-maquinas')">
        <MaquinaClasificacion ref="clasificaciones" />
      </v-tab-item>
      
      <v-tab-item eager :key="3" :value="'tab-3'" v-if="mostrarVista('cat-maquinas')">
        <recursosMaquina ref="catMaquinas"></recursosMaquina>
      </v-tab-item>
      
      <v-tab-item eager :key="4" :value="'tab-4'" v-if="mostrarVista('maquinas')">
        <recursosMaquinaInstancia ref="maquinas"></recursosMaquinaInstancia>
      </v-tab-item>

      <v-tab-item eager :key="5" :value="'tab-5'" v-if="mostrarVista('desconocidos2') && desconocidos">
        <recursosDesconocido ref="desconocidos"></recursosDesconocido>
      </v-tab-item>

      <v-tab-item eager :key="6" :value="'tab-6'" v-if="mostrarVista('alertasysensores')">
        <AlertasYSensores ref="alertasysensores"></AlertasYSensores>
      </v-tab-item>
    </v-tabs-items>
  </div>
</template>

<script>
import MaquinaCategoria from "./Recursos/MaquinaCategoria.vue";
import Maquina from "./Recursos/Maquina.vue";
import MaquinaInstancia from "./Recursos/MaquinaInstancia.vue";
import Desconocido from "./Recursos/Desconocido.vue";
import { mapState } from 'vuex'
import MaquinaClasificacion from './Recursos/MaquinaClasificacion.vue';
import AlertasYSensores from './Reportes/AlertasYCensores.vue'

export default {
  data() {
    return {
      tab: null,
      text:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat."
    };
  },
  computed: {
    ...mapState({
        desconocidos: 'desconocidos',
        modoAyuda: 'modoAyuda'
    })
  },
  methods:{
    initialize(){
      this.updateCategoriasMaquinas();
    },
    updateClasificacionMaquinas(){
      if(typeof this.$refs.clasificaciones === 'undefined') return;
      this.$refs.clasificaciones.initialize();
    },
    updateCategoriasMaquinas(){
      if(typeof this.$refs.categoriasMaquinas === 'undefined') return;
      this.$refs.categoriasMaquinas.initialize();
    },
    updateCatMaquinas(){
      if(typeof this.$refs.catMaquinas === 'undefined') return;
      this.$refs.catMaquinas.initialize();
    },
    updateMaquinas(){
      if(typeof this.$refs.maquinas === 'undefined') return;
      this.$refs.maquinas.initialize();
    },
    updateDesconocidos(){
      if(typeof this.$refs.desconocidos === 'undefined') return;
      this.$refs.desconocidos.initialize();
    },
  },
  components: {
    recursosMaquinaCategoria: MaquinaCategoria,
    recursosMaquina: Maquina,
    recursosMaquinaInstancia: MaquinaInstancia,
    recursosDesconocido: Desconocido,
    MaquinaClasificacion,
    AlertasYSensores
  }
};
</script>