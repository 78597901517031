<template>
  <v-container>
    <v-tabs vertical>
      <v-tab v-html="tipo.nombre.replace(' ','<br>')" v-for="tipo in tipos" :key="tipo.idArticuloTipo">
      </v-tab>
      <v-tab-item v-for="tipo in tipos" :key="'a'+tipo.idArticuloTipo">
        <reglaArticulo :idArticuloTipo="tipo.idArticuloTipo"></reglaArticulo>
      </v-tab-item>
    </v-tabs>
  </v-container>
</template>

<script>
import axios from 'axios'
import reglaArticulo from './Articulo/ReglaArticulo'

export default {
  components:{
    reglaArticulo:reglaArticulo
  },
  data() {
    return {
      tipos:[],
    };
  },
  created () {
    this.initialize()
  },
  methods: {
    initialize() {
      axios
        .get("/ArticuloTipos")
        .then(response =>  {
          this.tipos = response.data.filter(function(element){
            return (element.idArticuloTipo == 2 || element.idArticuloTipo == 3 || element.receta == true);
          }).sort(function(a,b) {
            return a.posicion - b.posicion;
          })
        })
        .catch(error => {
          console.log(error);
        });
    }
  }
}
</script>