import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VTab } from 'vuetify/lib/components/VTabs';
import { VTabItem } from 'vuetify/lib/components/VTabs';
import { VTabs } from 'vuetify/lib/components/VTabs';
import { VTabsItems } from 'vuetify/lib/components/VTabs';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c(VTabs,{attrs:{"background-color":"transparent","grow":"","centered":""},model:{value:(_vm.tab),callback:function ($$v) {_vm.tab=$$v},expression:"tab"}},[(_vm.mostrarVista('proveedores'))?_c(VTab,{on:{"click":function($event){return _vm.updateProveedores()}}},[_vm._v(" Proveedores ")]):_vm._e(),(_vm.mostrarVista('proveedortipos'))?_c(VTab,{on:{"click":function($event){return _vm.updateProveedorTipos()}}},[_vm._v(" Tipos de Proveedores ")]):_vm._e(),_c(VTab,{on:{"click":function($event){return _vm.updateTiempoEntrega()}}},[_vm._v(" Tiempo de Entrega por Proveedor ")]),_c(VTabsItems,{attrs:{"touchless":""},model:{value:(_vm.tab),callback:function ($$v) {_vm.tab=$$v},expression:"tab"}},[(_vm.mostrarVista('proveedores'))?_c(VTabItem,[_c(VCard,{attrs:{"flat":""}},[_c(VCardText,{staticClass:"px-0 pb-0"},[_c('comprasProveedores',{ref:"proveedores"})],1)],1)],1):_vm._e(),(_vm.mostrarVista('proveedortipos'))?_c(VTabItem,[_c(VCard,{attrs:{"flat":""}},[_c(VCardText,{staticClass:"px-0 pb-0"},[_c('comprasProveedorTipos',{ref:"proveedortipos"})],1)],1)],1):_vm._e(),_c(VTabItem,[_c(VCard,{attrs:{"flat":""}},[_c(VCardText,{staticClass:"px-0 pb-0"},[_c('ProveedorTiemposEntrega',{ref:"proveedortiempos"})],1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }