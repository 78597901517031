import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';
import { VToolbar } from 'vuetify/lib/components/VToolbar';
import { VTooltip } from 'vuetify/lib/components/VTooltip';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VCol,{attrs:{"cols":"12"}},[_c(VDialog,{attrs:{"max-width":"900px"},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c(VCard,[_c(VCardTitle,[_vm._v(" Detalles del Excedente ")]),_c(VCardText,[(_vm.permisoVista('excedentes','r'))?_c(VDataTable,{attrs:{"headers":_vm.headersDetalles,"items":_vm.detalles},scopedSlots:_vm._u([{key:"item.cantidad",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.cantidad.toFixed(2))+" "+_vm._s(item.unidad)+" ")]}},{key:"item.inicio",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.inicioLabel)+" ")]}},{key:"item.fin",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.finLabel)+" ")]}}],null,false,3502748698)}):_vm._e()],1),_c(VCardActions,[_c(VSpacer),_c(VBtn,{attrs:{"color":"green","text":""},on:{"click":_vm.cerrarDetalles}},[_vm._v("Cerrar")])],1)],1)],1),(_vm.permisoVista('excedentes','r'))?_c(VDataTable,{attrs:{"headers":_vm.headers,"items":_vm.excedentes,"search":_vm.search,"loading":_vm.loading,"loading-text":"Cargando... Espere, Por Favor."},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c(VToolbar,{attrs:{"flat":"","color":"white"}},[_c(VTextField,{attrs:{"append-icon":"search","label":"Buscar","single-line":"","hide-details":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1)]},proxy:true},{key:"item.cantidad",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.cantidad.toFixed(2))+" "+_vm._s(item.unidad)+" ")]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c(VTooltip,{staticClass:"white--text",attrs:{"bottom":"","color":"black"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c(VIcon,_vm._g({attrs:{"small":""},on:{"click":function($event){return _vm.mostrarDetalles(item)}}},on),[_vm._v(" mdi-eye ")])]}}],null,true)},[_c('span',{staticClass:"white--text"},[_vm._v("Ver detalles")])])]}}],null,false,223840168)}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }