<template>
	<v-col cols="12">
		<v-row>
			<v-col cols="4" md="4" sm="4" class="align-self-end">
				<datePicker
					v-model="desde"
					format="YYYY-MM-DD"
					label="Desde (Obligatorio)"
					clearable
					maxToday
					@input="
						getPersonas();
						realizarPeticion();
					"
					:disabled="cargando"
				></datePicker>
			</v-col>
			<v-col cols="4" md="4" sm="4" class="align-self-end">
				<datePicker
					v-model="hasta"
					:minDate="desde"
					format="YYYY-MM-DD"
					label="Hasta (Obligatorio)"
					clearable
					maxToday
					@input="
						getPersonas();
						realizarPeticion();
					"
					:disabled="cargando"
				></datePicker>
			</v-col>
			<v-col cols="4">
				<v-autocomplete
					v-model="idsPersonas"
					label="Operador(es) (Obligatorio)"
					:items="personas"
					item-value="idPersona"
					:item-text="nombrePersona"
					ref="operadores"
					hide-details
					multiple
					clearable
					chips
					deletable-chips
					@click:clear="clearPersonas"
					@change="realizarPeticion"
					:loading="cargandoPersonas"
                    :disabled="
                        desde == null || 
                        hasta == null || 
                        cargando
                    "
				></v-autocomplete>
			</v-col>
			<v-col cols="4">
				<v-autocomplete
					v-model="idArea"
					label="Área (Opcional)"
					:items="areas"
					item-value="idArea"
					item-text="nombre"
					hide-details
					clearable
					:disabled="
                        desde == null || 
                        hasta == null || 
                        idsPersonas.length == 0 || 
                        cargando
                    "
					@change="realizarPeticion"
				></v-autocomplete>
			</v-col>
			<v-col cols="4">
				<v-autocomplete
					v-model="idArticuloTerminado"
					:items="articulosProducir"
					:item-text="nombreArticulo"
					item-value="idArticulo"
					label="Artículo Terminado (Opcional)"
					hide-details
					clearable
					@change="realizarPeticion"
					:disabled="
                        desde == null || 
                        hasta == null || 
                        idsPersonas.length == 0 ||
                        cargando
                    "
				></v-autocomplete>
			</v-col>
			<v-col cols="4">
				<v-autocomplete
					v-model="idArticuloFase"
					:items="articulosFase"
					:item-text="nombreArticulo"
					item-value="idArticulo"
					label="Artículo Fase (Opcional)"
					hide-details
					clearable
					@change="realizarPeticion"
					:disabled="
                        desde == null || 
                        hasta == null || 
                        idsPersonas.length == 0 ||
                        cargando
                    "
				></v-autocomplete>
			</v-col>
			<v-col cols="4">
				<v-autocomplete
					v-model="idSubpaso"
					:items="subpasos"
					:item-text="nombreSubpaso"
					item-value="idCatSubpaso"
					label="Subpaso (Opcional)"
					hide-details
					clearable
					@change="realizarPeticion"
					:disabled="
                        desde == null || 
                        hasta == null || 
                        idsPersonas.length == 0 ||
                        cargando
                    "
				></v-autocomplete>
			</v-col>
		</v-row>
		<v-row v-if="desde != null && hasta != null && idsPersonas.length > 0">
			<v-col cols="4">
				<download-btn
					color="primary"
					text
					@click="descargarReporte"
					label="Descargar Reporte de<br>Tendencia de Rendimiento"
				/>
			</v-col>
		</v-row>
		<v-row>
			<v-col cols="12" class="ma-0 pa-0 px-6 d-flex justify-center" v-if="cargando">
				<v-progress-linear
					color="primary"
					indeterminate
					rounded
					height="4"
				></v-progress-linear>
			</v-col>
			<v-col cols="12">
				<apexchart
					type="line"
					height="450"
					:options="chartOptions"
					:series="series"
					:key="`graph-${graphKey}`"
				></apexchart>
			</v-col>
		</v-row>
	</v-col>
</template>

<script>
import axios from "axios";
import DownloadBtn from '../../DownloadBtn.vue';
import { mapState } from 'vuex';
import moment from 'moment';
import { debounce } from "debounce";

export default {
	components: { DownloadBtn },
	data() {
		return {
			cargandoPersonas: false,
			subpasos: [],
			idSubpaso: null,
			graphKey: 0,
			articulosFase: [],
            articulosProducir: [],
			categories: [],
			series: [],
			desde: null,
			hasta: null,
			idArticuloTerminado: null,
			idArticuloFase: null,
			areas: [],
			idTurno: null,
			turnos: [],
			idsPersonas: [],
			personas: [],
			cargando: false,
			chartOptions: {
				chart: {
					type: "line",
					height: 450,
					zoom: {
						enabled: true,
					},
				},
				dataLabels: {
					enabled: false,
				},
				markers: {
					size: 1,
				},
				stroke: {
					width: 5,
					curve: 'straight',
					dashArray: []
				},
				annotations: {},
				xaxis: {
					categories: [],
					labels:{
						formatter: function (val) {
							return moment(val).format("YYYY-MM-DD");
						}
					}
				},
				yaxis: {
					max: 120,
					min: 0,
					labels: {
						formatter: function (val) {
							return val.toFixed(2) + " %";
						},
					}
				},
				fill: {
					opacity: 1,
				},
				tooltip: {
					y: {
						formatter: function (val) {
							return val.toFixed(2) + " %";
						},
					},
					x: {
						format: 'yyyy-MM-dd'
					}
				},
			},
			chartOptionsDefault: {
				chart: {
					type: "line",
					height: 450,
					zoom: {
						enabled: true,
					},
				},
				dataLabels: {
					enabled: false,
				},
				markers: {
					size: 1,
				},
				stroke: {
					width: 5,
					curve: 'straight',
					dashArray: []
				},
				annotations: {},
				xaxis: {
					categories: [],
					labels:{
						formatter: function (val) {
							return moment(val).format("YYYY-MM-DD");
						}
					}
				},
				yaxis: {
					max: 120,
					min: 0,
					labels: {
						formatter: function (val) {
							return val.toFixed(2) + " %";
						},
					}
				},
				fill: {
					opacity: 1,
				},
				tooltip: {
					y: {
						formatter: function (val) {
							return val.toFixed(2) + " %";
						},
					},
					x: {
						format: 'yyyy-MM-dd'
					}
				},
			},
			colorsDummy: [
				"#008FFB",
				"#00E396",
				"#FEB019",
				"#FF4560",
				"#775DD0",
				"#4CAF50",
				"#FF9800",
				"#1B998B",
				"#FA4443",
				"#A300D6",
			],
		};
	},
	mounted() {
		this.initialize();
	},
	computed:{
		...mapState(["token"])
	},
	methods: {
		nombreSubpaso: (value) => `${value.codigo?`[${value.codigo}] - `:''}${value.descripcion}`,
		nombreArticulo: ({codigo, nombre}) => `${codigo?`[${codigo}] - `:''}${nombre??''}`,
		cleanFiltrosOpcionales(){
			this.idArea = null;
			this.idTurno = null;
			this.idArticulo = null;
			this.idSubpaso = null;
		},
		clearPersonas(){
			this.resetValues();
			this.cleanFiltrosOpcionales();
		},
		calcularAnotaciones(series = [],chartOptions=null){
			if(series.length == 0 || chartOptions == null) {
				this.$set( chartOptions.annotations, 'yaxis',[]);
				this.$set(chartOptions,'noData',{
					text: "No hay datos disponibles",
					align: 'center',
					verticalAlign: 'middle',
					style: {
						color: 'rgba(0,0,0,.38)',
						fontSize: '14px',
						fontFamily: 'sans-serif'
					}
				});
				return;
			}

			const max = Math.max.apply(Math,series.map( item => Math.max.apply(Math,item.data)));		
			//Dibujar linea de maximo
			this.$set( 
				chartOptions.annotations, 
				'yaxis', 
				[{
					y: max,
					strokeDashArray: 8,
					borderColor: '#00E396',
					label: {
						borderColor: '#00E396',
						style: {
							color: '#fff',
							background: '#00E396',
						},
						text: 'Máximo',
					}
				}]
			);
		},
        getArticulosFase() {
			axios
				.get("/Articulos/ArticulosNombres?componentes=true")
				.then((response) => {
					this.articulosFase = response.data;
				})
				.catch((error) => {
					console.log(error);
				});
		},
		getArticulosProducir() {
			axios
				.get("/Articulos/OTS")
				.then((response) => {
					this.articulosProducir = response.data;
				})
				.catch((error) => {
					console.log(error);
				});
		},
		descargarReporte(){
			var datetime = moment().format('YYYYMMDDHHmmss');
			let url = `/Reportes/XlsReporteRendimientoOperador?access_token=${this.token}`;
			if(this.desde != null ) url += `&desde=${this.desde}`;
			if(this.hasta != null ) url += `&hasta=${this.hasta}`;
			if(this.idArea != null ) url += `&idArea=${this.idArea}`;
			if(this.idTurno != null ) url += `&idTurno=${this.idTurno}`;
			if(this.idArticuloFase != null ) url += `&idArticuloFase=${this.idArticuloFase}`;
			if(this.idArticuloTerminado != null ) url += `&idArticuloTerminado=${this.idArticuloTerminado}`;
			if(this.idSubpaso != null ) url += `&idSubpaso=${this.idSubpaso}`;
			if (this.idsPersonas.length > 0) {
				this.idsPersonas.forEach(persona => {
					url += `&idsOperadores=${persona}`;
				});
			}
      		this.$utils.axios.downloadFile(url,'GET','tendencia-rendimiento-'+datetime+'.xlsx','application/vnd.openxmlformats-officedocument.spreadsheetml.sheet');
		},
		nombrePersona: (persona) =>
			persona.nombre + " " + persona.paterno + " " + persona.materno,
		getPersonas() {
			if (this.desde == null || this.hasta == null) {
				this.personas = [];
				this.resetValues();
				return;
			}
			this.cargandoPersonas = true;
			this.idsPersonas = [];
			this.personas = [];
			axios
				.get("/Personas/OperadoresRendimientoIntervalo", {
					params: {
						desde: this.desde,
						hasta: this.hasta,
					},
				})
				.then((response) => {
					this.personas = response.data;
					this.cargandoPersonas = false;
				})
				.catch((error) => {
					this.cargandoPersonas = false;
					console.log(error);
				});
		},
		realizarPeticion(){
			if(this.idsPersonas.length == 0) {
				this.resetValues();	
				return;
			}

			if (this.desde == null || this.hasta == null) {
				this.personas = [];
				this.resetValues();
				this.cleanFiltrosOpcionales();
				return;
			}
			this.obtenerDatosDebounce();
		},
		obtenerDatosDebounce: debounce( async function(){
			try {
				this.$refs['operadores'].blur();
				await this.obtenerDatos();
			} catch (error) {
				console.error(error);
			}
		}, 1500),
		obtenerDatos() {
			return new Promise( (resolve) => {
				let url = "/Reportes/ReporteRendimientoOperador";
				if (this.idsPersonas.length > 0) {
					this.idsPersonas.forEach((persona, idx) => {
						url += `${idx == 0 ? '?': '&'}idsOperadores=${persona}`;
					});
				}
				
				this.cargando = true;
				
				axios
					.get(url, {
						params: {
							desde: this.desde,
							hasta: this.hasta,
							idArea: this.idArea,
							idTurno: this.idTurno,
							idArticuloTerminado: this.idArticuloTerminado,
							idArticuloFase: this.idArticuloFase,
							idSubpaso: this.idSubpaso
						},
					})
					.then((response) => {
						var chartOptions = Object.assign({},this.chartOptionsDefault);
						this.calcularAnotaciones(response.data.series,chartOptions);
						chartOptions.xaxis.categories = response.data.xaxis.categories;
						this.chartOptions = chartOptions;
						this.series = [];
						this.series = response.data.series;
						this.cargando = false;
						resolve();
					})
					.catch((error) => {
						this.cargando = false;
						this.series = [];
						reject(error);
					});
			});
		},
		resetValues() {
			this.idsPersonas = [];
			this.categories = [];
			this.series = [];
			this.chartOptions = Object.assign({},this.chartOptionsDefault);
			this.$set(this.chartOptions.xaxis,'categories',[]);
			this.$set(this.chartOptions,'annotations',{
				yaxis: []
			});
		},
		initialize() {
			this.getAreas();
			this.getTurnos();
			this.getArticulosFase();
			this.getArticulosProducir();
			this.getSubpasos();
		},
		getSubpasos(){
			axios
				.get('/CatSubpaso')
				.then( response => {
					this.subpasos = response.data;
				})
				.catch( error => {
					console.log(error);
				})
		},
		getAreas() {
			axios
				.get("/Areas")
				.then((response) => {
					this.areas = response.data.filter(
						(area) => area.idArea > 1
					);
				})
				.catch((error) => {
					console.log(error);
				});
		},
		getTurnos() {
			axios
				.get("/Turnos")
				.then((response) => {
					this.turnos = response.data;
				})
				.catch((error) => {
					console.log(error);
				});
		},
	},
};
</script>