import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VImg } from 'vuetify/lib/components/VImg';
import { VProgressCircular } from 'vuetify/lib/components/VProgressCircular';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VTooltip } from 'vuetify/lib/components/VTooltip';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c(VTooltip,{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c(VBtn,_vm._g(_vm._b({attrs:{"icon":""},on:{"click":function($event){return _vm.showImageOriginal(_vm.url)}}},'v-btn',attrs,false),on),[_c(VIcon,{attrs:{"color":"grey lighten-1"}},[_vm._v(" mdi-image-area ")])],1)]}}]),model:{value:(_vm.show),callback:function ($$v) {_vm.show=$$v},expression:"show"}},[_c(VImg,{attrs:{"max-height":"150","max-width":"380","src":_vm.currentImage}})],1),_c(VDialog,{attrs:{"persistent":"","max-height":"500","max-width":"500"},model:{value:(_vm.dialogView),callback:function ($$v) {_vm.dialogView=$$v},expression:"dialogView"}},[_c(VCard,[_c(VCardTitle,{staticClass:"text-h5"}),_c(VCardText,[_c(VImg,{attrs:{"src":_vm.currentImageOriginal,"lazy-src":_vm.currentImage,"contain":"","max-height":"490","max-width":"490","aspect-ratio":"1"},scopedSlots:_vm._u([{key:"placeholder",fn:function(){return [_c(VRow,{staticClass:"fill-height ma-0",attrs:{"align":"center","justify":"center"}},[_c(VProgressCircular,{attrs:{"indeterminate":"","color":"grey lighten-5"}})],1)]},proxy:true}])})],1),_c(VCardActions,[_c(VSpacer),_c(VBtn,{attrs:{"color":"primary darken-1","text":""},on:{"click":_vm.cerrar}},[_vm._v(" CERRAR ")])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }