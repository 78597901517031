<template>
  <v-col cols="12" v-if="permisoVista('reporterendimiento','r')">
    <v-tabs
      v-model="tab"
      background-color="transparent"
      grow
    >
      <v-tab>Por Día</v-tab>
      <v-tab>Por Intervalo</v-tab>

      <v-tabs-items v-model="tab" touchless>
        <v-tab-item eager>
          <reporte-dia></reporte-dia>
        </v-tab-item>
        <v-tab-item eager>
          <reporte-rango></reporte-rango>
        </v-tab-item>
      </v-tabs-items>
    </v-tabs>
  </v-col>
</template>

<script>
import Dia from './TiempoMuerto/Dia';
import Rango from './TiempoMuerto/Rango'

export default {
  components:{
    'reporte-dia': Dia,
    'reporte-rango': Rango,
  },
  data: () => ({
    tab: 0,
  }),
}
</script>